// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video_schedule_media_tab .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: transparent !important;
    color: #000000 !important;
    border-bottom: 2px #0d6efd solid;
    border-radius: 0 !important;
}
.video_schedule_media_tab .nav-pills .nav-link{
    color: #000000 !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Video/Video_Management/AddVideoschedule.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,yBAAyB;IACzB,gCAAgC;IAChC,2BAA2B;AAC/B;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".video_schedule_media_tab .nav-pills .nav-link.active, .nav-pills .show>.nav-link{\n    background-color: transparent !important;\n    color: #000000 !important;\n    border-bottom: 2px #0d6efd solid;\n    border-radius: 0 !important;\n}\n.video_schedule_media_tab .nav-pills .nav-link{\n    color: #000000 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
