import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

function Videochannelpartner() {
  // const [cpp, setCpp] = useState([]);

  // const [data, setData] = useState([]);
  // const [previous, setPrevious] = useState([]);
  // const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  // const [toggle, setToggle] = useState(true);
  // const [isOff, setIsOff] = useState(false);

  const [channel, setChannel] = useState([]);
  // console.log(channel)

  // const [data1, setData1] = useState([]);
  // const [previous1, setPrevious1] = useState([]);
  // const [next1, setNext1] = useState([]);
  const [page1, setPage1] = useState(1);
  // const [toggle1, setToggle1] = useState(true);
  // const [isOff1, setIsOff1] = useState(false);


  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await axios.get(
          `${process.env.REACT_APP_Baseurl}/admin/content_partner/videos-pending`,
          {
            headers: headers,
          }
        );
        var result1 = response1?.data?.pending_videos?.data;
        // handle result1 as needed
  
        const response2 = await axios.get(
          `${process.env.REACT_APP_Baseurl}/admin/channel_partner/videos-pending`,
          {
            headers: headers,
          }
        );
        var result2 = response2?.data?.pending_videos?.data;
        setChannel(result2);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []); 
  
  // useEffect(async () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_Baseurl}/admin/content_partner/videos-pending`,
  //       {
  //         headers: headers,
  //       }
  //     )
  //     .then((response) => {
  //       var result = response?.data?.pending_videos?.data;
  //       // setCpp(result);
  //       // console.log(result)
  //     })
  //     .catch((error) => console.log(error));

  //   axios
  //     .get(
  //       `${process.env.REACT_APP_Baseurl}/admin/channel_partner/videos-pending`,
  //       {
  //         headers: headers,
  //       }
  //     )
  //     .then((response) => {
  //       var result = response?.data?.pending_videos?.data;
  //       setChannel(result);
  //     })
  //     .catch((error) => console.log(error));
  // }, []);

  // const rejected = (id) => {
  //   const access_token = localStorage.getItem("access_token");
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //   };
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_Baseurl}/admin/content_partner/reject-video/` +
  //         id,
  //       { headers: headers }
  //     )
  //     .then((response) => {
  //       alert("Item Rejected Successfully !");
  //       //  navigate('/allvideo')
  //       window.location.reload();
  //     });
  // };

  // const approved = (id) => {
  //   const access_token = localStorage.getItem("access_token");
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //   };
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_Baseurl}/admin/content_partner/approve-video/` +
  //         id,
  //       { headers: headers }
  //     )
  //     .then((response) => {
  //       alert("Item Approved Successfully !");
  //       //  navigate('/allvideo')
  //       window.location.reload();
  //     });
  // };

  // function handlePreClick() {
  //   // console.log('preview');

  //   axios
  //     .get(
  //       `${
  //         process.env.REACT_APP_Baseurl
  //       }/admin/content_partner/videos-pending?page=${page - 1}`,
  //       { headers: headers }
  //     )
  //     .then((res) => {
  //       // console.log(res.data.videos.data);
  //       setPrevious(res?.data?.pending_videos?.data);
  //       setCpp(res?.data?.pending_videos?.data);
  //       setPage(page - 1);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }
  // function handlefirstpage() {
  //   // console.log('handlefirstpage');

  //   axios
  //     .get(
  //       `${
  //         process.env.REACT_APP_Baseurl
  //       }/admin/content_partner/videos-pending?page=${page - 1}`,
  //       { headers: headers }
  //     )
  //     .then((res) => {
  //       // console.log(res.data.videos.data);
  //       setPrevious(res?.data?.pending_videos?.data);
  //       setCpp(res?.data?.pending_videos?.data);
  //       setPage(page - 1);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // function handleNxtClick() {
  //   // console.log('next');

  //   axios
  //     .get(
  //       `${
  //         process.env.REACT_APP_Baseurl
  //       }/admin/content_partner/videos-pending?page=${page + 1}`,
  //       { headers: headers }
  //     )
  //     .then((res) => {
  //       // console.log('next', res.data.videos.data);
  //       setNext(res?.data?.pending_videos?.data);
  //       setCpp(res?.data?.pending_videos?.data);
  //       setPage(page + 1);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // useEffect(async () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_Baseurl}/admin/channel_partner/videos-pending`,
  //       {
  //         headers: headers,
  //       }
  //     )
  //     .then((response) => {
  //       var result = response.data.pending_videos.data;
  //       setChannel(result);
  //       console.log(result)
  //     })
  //     .catch((error) => console.log(error));
  // }, []);

  const rejectedchannel = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/reject-video/` +
          id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Rejected Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  const approvedchannel = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/approve-video/` +
          id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Approved Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClickchannel() {
    // console.log('preview');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/channel_partner/videos-pending?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        // setPrevious1(res?.data?.pending_videos?.data);
        setChannel(res?.data?.pending_videos?.data);
        setPage1(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // function handlefirstpagechannel() {
  //   // console.log('handlefirstpage');

  //   axios
  //     .get(
  //       `${
  //         process.env.REACT_APP_Baseurl
  //       }/admin/channel_partner/videos-pending?page=${page - 1}`,
  //       { headers: headers }
  //     )
  //     .then((res) => {
  //       // console.log(res.data.videos.data);
  //       setPrevious1(res?.data?.pending_videos?.data);
  //       setChannel(res?.data?.pending_videos?.data);
  //       setPage1(page - 1);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  function handleNxtClickchannel() {
    // console.log('next');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/channel_partner/videos-pending?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.videos.data);
        // setNext1(res?.data?.pending_videos?.data);
        setChannel(res?.data?.pending_videos?.data);
        setPage1(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="row">
                <div className="col-sm-12">
                  <div className="py-3">
                    <h4>Channel Uploaded Videos</h4>
                  </div>

                  <div className="iq-card">
                    <div className="iq-card-header justify-content-between">
                      <div className="row ">
                        <div className="" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                          >
                            {" "}
                            <div className="iq-card-body p-0">
                              <hr />

                              <Table>
                                <thead>
                                  <tr>
                                    <th>TITLES</th>
                                    <th>VIDEO UPLOADED BY</th>
                                    <th>VIDEO TYPE</th>
                                    <th>UPLOADED DATE</th>
                                    <th>VIDEO DURATION</th>
                                    {/* <th>Video Category</th> */}
                                    {/* <th>Video Meta</th> */}
                                    {/* <th>Status</th> */}
                                    <th>ACTION</th>
                                  </tr>
                                </thead>
                                {channel?.map((item, key) => (
                                  <tbody>
                                    <tr>
                                      <td>{item?.title}</td>
                                      <td>
                                        {item?.uploaded_by === "1"
                                          ? "Admin"
                                          : item?.uploaded_by === "4"
                                          ? "Channel Partner"
                                          : item?.uploaded_by === "5"
                                          ? "Content Partner"
                                          : ""}
                                      </td>
                                      <td>{item?.type}</td>
                                      <td>{item?.created_at}</td>
                                      <td>{item?.duration}</td>
                                      {/* <td>{item?.video_category_id}</td> */}
                                      {/* <td>{item?.status}</td> */}

                                      {/* <td className="text-center">
                                        {item?.status == 0 ||
                                        item?.status == null ? (
                                          <td className="bg-warning video_active">
                                            Pending Approved Rejected
                                          </td>
                                        ) : item?.status == 1 &&
                                          item?.status == 1 &&
                                          item?.status == 1 ? (
                                          <td className="bg-success video_active">
                                            Approved
                                          </td>
                                        ) : (
                                          <td className="bg-warning video_active">
                                            Draft
                                          </td>
                                        )}
                                      </td> */}
                                      <td colspan="2">
                                        <div className="flex align-items-center list-user-action fs-5">
                                          <Link
                                            className="iq-bg-warning"
                                            to=""
                                            onClick={() =>
                                              approvedchannel(item?.id)
                                            }
                                          >
                                            <i className="fa fa-check-circle"></i>
                                          </Link>
                                          <Link
                                            className="iq-bg-success ms-2"
                                            to=""
                                            onClick={() =>
                                              rejectedchannel(item?.id)
                                            }
                                          >
                                            <i className="fa fa-close"></i>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </Table>

                              {/* <div className="table-view">
                                <table
                                  className="table text-center table-striped table-bordered table movie_table iq-card"
                                  id="videocpp"
                                >
                                  <thead>
                                    <tr>
                                      <th>Title</th>
                                      <th>Video Uploaded By</th>
                                      <th>Video Type</th>
                                      <th>Uploaded Date</th>
                                      <th>Video Duration</th>
                                      <th>Video Category</th>
                                      <th>Video Meta</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>

                                  {channel?.map((item, index) => {
                                    const value = item?.id;
                                    const value2 = item?.id;

                                    if (value && value2) {
                                      return (
                                        <tbody>
                                          <tr>
                                            <td>{item?.title}</td>
                                            <td>{item?.uploaded_by}</td>
                                            <td>{item?.type}</td>
                                            <td>{item?.created_at}</td>
                                            <td>{item?.duration}</td>
                                            <td>{item?.video_category_id}</td>
                                            <td>{item?.status}</td>

                                            <td className="text-center">
                                              {item?.status == 0 ||
                                              item?.status == null ? (
                                                <td className="bg-warning video_active">
                                                  Pending Approved Rejected
                                                </td>
                                              ) : item?.status == 1 &&
                                                item?.status == 1 &&
                                                item?.status == 1 ? (
                                                <td className="bg-success video_active">
                                                  Approved
                                                </td>
                                              ) : (
                                                <td className="bg-warning video_active">
                                                  Draft
                                                </td>
                                              )}
                                            </td>
                                            <td colspan="2">
                                              <div className="flex align-items-center list-user-action fs-5">
                                                <Link
                                                  className="iq-bg-warning"
                                                  to=""
                                                  onClick={() =>
                                                    approvedchannel(item?.id)
                                                  }
                                                >
                                                  <i className="fa fa-check-circle"></i>
                                                </Link>
                                                <Link
                                                  className="iq-bg-success ms-2"
                                                  to=""
                                                  onClick={() =>
                                                    rejectedchannel(item?.id)
                                                  }
                                                >
                                                  <i className="fa fa-close"></i>
                                                </Link>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      );
                                    } else {
                                      return <div></div>;
                                    }
                                  })}
                                </table>
                              </div> */}
                              <div className="row align-items-center text-center d-flex">
                                <div className="col-lg-4">
                                  <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={handlePreClickchannel}
                                  >
                                    {"<< Previous"}
                                  </button>
                                </div>
                                <div className="col-lg-4">
                                  <p className="">{page1}</p>
                                </div>
                                <div className="col-lg-4">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleNxtClickchannel}
                                  >
                                    {"Next >>"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Videochannelpartner;
