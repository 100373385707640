import React, { useEffect, useState } from 'react'
import AdminNavbar from '../../../components/CommonComponents/AdminNavbar'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md'
import axios from 'axios'
import NoRecordTable from '../../../components/CommonComponents/NoRecordTable'
import "./EmailSettings.css";
const EmaiLogs = () => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    const [logs, setLogs] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); //


    const getLogs = async () => {
        await axios.get(`${process.env.REACT_APP_Baseurl}/admin/Email-logs`, { headers: headers, })
            .then((response) => {
                const result = response?.data?.['Email-Logs'];
                setLogs(result.reverse());
            })
            .catch((error) => console.log(error));
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);

        const dateOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        };

        const timeOptions = {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true // AM/PM format
        };

        const formattedDate = date.toLocaleDateString('en-US', dateOptions);
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

        return (
            <>
                <div>{formattedDate}</div>
                <div className='size13'>{formattedTime}</div>
            </>
        );
    }
    const options = {
        totalSize: logs?.length,
        page: currentPage,
        onPageChange: (page) => setCurrentPage(page),
        onSizePerPageChange: (sizePerPage, page) => {
            setPageSize(sizePerPage);
            setCurrentPage(page);
        },
        pageStartIndex: 1,
        paginationSize: 3,
        sizePerPage: pageSize,
        nextPageText: "Next",
        prePageText: "Prev",
        firstPageText: <MdKeyboardDoubleArrowLeft />,
        lastPageText: <MdKeyboardDoubleArrowRight />,
        showTotal: true,
        alwaysShowAllBtns: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }, {
            text: 'All', value: Number(logs?.length)
        }]
    };

    const columns = [
        {
            dataField: "index",
            text: "S.No",
            headerClasses: "serialNumber",
            formatter: (cell, row, rowIndex) => {
                return (currentPage - 1) * pageSize + rowIndex + 1;  // Calculate the serial number
            },
        },
        {
            dataField: "user_id",
            text: "Name",
            headerClasses: "serialNumber",
        },
        {
            dataField: "email_logs",
            text: "Logs",
            formatter: (cell, row) => {
                return <div className="redColorEmail" style={{ color: row?.color }}>{cell}</div>
            },
        },
        {
            dataField: "email_template",
            text: "Email Template",
            headerClasses: "serialNumber"
        },
        {
            dataField: "email_status",
            text: "Status",
            headerClasses: "serialNumber",
        },
        {
            dataField: "user_id",
            text: "User Id",
            headerClasses: "serialNumber"
        },
        {
            dataField: "created_at",
            text: "Created At",
            headerClasses: "serialNumber",
            formatter: (cell) => formatDate(cell),
        },
    ];


    useEffect(async () => {
        getLogs();
    }, []);

    return (
        <div className="bootstrapTable p-3 rounded-2 theme-bg-color">
            <AdminNavbar heading={'Email Logs'} totalDataLength={logs?.length} deleteCount={0} navigation={`/email_settings/`} deleteMethod={null} navText={"Email Setting"} addIconHide />
            <BootstrapTable keyField="id" pagination={paginationFactory(options)} columns={columns} data={logs} className="no-border-table theme-text-color " noDataIndication={<NoRecordTable result={logs?.length} loader={false} />} hover headerClasses="videoWidth" />
        </div>
    )
}

export default EmaiLogs