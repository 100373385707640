import React, { useState, useEffect, useRef } from "react";
import { Link, } from "react-router-dom";
import axios from "axios";
import "./ImportUser.css";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function ImportUser(props) {
  const [user, setUser] = useState("");

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const downloadFile = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/users/import-sample-file`,
        {
          headers: headers,
          method: "GET",
        }
      );

      if (response?.status === 200) {
        const responseData = await response.json();
        const sampleFilePath = responseData?.Sample_file_path;

        const downloadResponse = await fetch(sampleFilePath);
        const fileData = await downloadResponse.blob();
        const downloadUrl = URL.createObjectURL(fileData);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "Sample-Excel.xlsx"; // Set the desired file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Failed to fetch sample file");
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(async () => {
    downloadFile();
  });
  const [validationMessagefile, setValidationMessagefile] = useState("");
  const [isValidationHiddenfile, setIsValidationHiddenfile] = useState(true);

  const fileInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function importuser() {
    let focusInputRef = null;

    if (user === "") {
      setValidationMessagefile("File cannot be empty.");
      setIsValidationHiddenfile(false);
      focusInputRef = fileInputRef;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    const formData = new FormData();

    formData.append("excel_file", user);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    setProcessing(true);
    setShowOverlay(true);

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/users/bulk_import`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        if (response?.data?.status === true) {
          var resultapi = response?.data;

          setValidationMessagefile("");
          props?.setApiresponsealert(resultapi);
          props?.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          if (resultapi === false) {
            var resultError = resultapi;
            props?.setApiresponsealert(resultError);
            props?.setShowalert(true);

            setIsValidationHiddenfile(false);
            setProcessing(false);
            setShowOverlay(false);
          }
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Import User Bulk Data</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={importuser} className="btn btn-primary">
                    Save Import
                  </button>
                </div>
              </div>
            </div>
            <div>
              {showOverlay && (<Response_Processing></Response_Processing>)}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Add Excel File</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">Import Excel file</label>

                    <div className="mt-2">
                      <input
                        type="file"
                        name="file"
                        id="file"
                        className="rs-input form-control-lg"
                        onChange={(e) => setUser(e.target.files[0])}
                        ref={fileInputRef}
                      />
                    </div>

                    <span className="errorred">
                      {!isValidationHiddenfile && (
                        <p>{validationMessagefile}</p>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Sample File</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">Download Sample Excel file</label>

                    <div className="mt-2">
                      <div className="text-white">
                        <Link
                          onClick={downloadFile}
                          className="fa fa-file-excel-o video_pdf"
                          download
                        ></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ImportUser;
