// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mediavideo media-outlet {
  width: 50%;
}

.dropzonestyle {
  border: 2px dashed#cccccc;
  border-radius: 10px;
  height: 100px;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.imagedropseason {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-style: dashed;
  border-color: #cbd5e1;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Video/Series&Episode/Editseason.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,oBAAoB;EACpB,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":[".mediavideo media-outlet {\n  width: 50%;\n}\n\n.dropzonestyle {\n  border: 2px dashed#cccccc;\n  border-radius: 10px;\n  height: 100px;\n  align-items: center;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n.imagedropseason {\n  width: 100%;\n  height: 100px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 5px;\n  border-style: dashed;\n  border-color: #cbd5e1;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
