import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import backarrow from "../../../../components/Images/backarrow.svg";

function App_Setting(props) {

  const [app_settings, setApp_settings] = useState({
    android_url: "",
    ios_url: "",
    android_tv: "",
    status: "",
    Firetv_url: "",
    samsungtv_url: "",
    Lgtv_url: "",
    Rokutv_url: "",
  });

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, {
        headers: headers,
      })
      .then((response) => {
        var app_settings = response?.data?.app_settings;
        setApp_settings(app_settings);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setApp_settings({ ...app_settings, [e.target.name]: e.target.value });
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function handleUpdate() {
    const formData = new FormData();

    formData.append("android_url", app_settings?.android_url);
    formData.append("ios_url", app_settings?.ios_url);
    formData.append("android_tv", app_settings?.android_tv);
    formData.append("status", app_settings?.status);
    formData.append("Firetv_url", app_settings?.Firetv_url);
    formData.append("samsungtv_url", app_settings?.samsungtv_url);
    formData.append("Lgtv_url", app_settings?.Lgtv_url);
    formData.append("Rokutv_url", app_settings?.Rokutv_url);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/app_setting/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        // console.log(resultError);
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <Link to="/settings" className="backarrow">
                <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                <span> Back</span>
              </Link>
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>App URL Settings for Playstore/Appstore</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Save App URL
                  </button>
                </div>
              </div>
            </div>
            
            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                <div className="card p-3">
                  <h5>Create URL</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">Android URL</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        name="android_url"
                        id="android_url"
                        value={app_settings?.android_url}
                        onChange={handleInput}
                        className="rs-input form-control-lg"
                      />
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">IOS URL</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        name="ios_url"
                        id="ios_url"
                        value={app_settings?.ios_url}
                        onChange={handleInput}
                        className="rs-input form-control-lg"
                      />
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Android TV</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        name="android_tv"
                        id="android_tv"
                        value={app_settings?.android_tv}
                        onChange={handleInput}
                        className="rs-input form-control-lg"
                      />
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Fire TV</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        name="Firetv_url"
                        id="Firetv_url"
                        value={app_settings?.Firetv_url}
                        onChange={handleInput}
                        className="rs-input form-control-lg"
                      />
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Samsung TV</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        name="samsungtv_url"
                        id="samsungtv_url"
                        value={app_settings?.samsungtv_url}
                        onChange={handleInput}
                        className="rs-input form-control-lg"
                      />
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Lg TV</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        name="Lgtv_url"
                        id="Lgtv_url"
                        value={app_settings?.Lgtv_url}
                        onChange={handleInput}
                        className="rs-input form-control-lg"
                      />
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Roku TV</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        name="Rokutv_url"
                        id="Rokutv_url"
                        value={app_settings?.Rokutv_url}
                        onChange={handleInput}
                        className="rs-input form-control-lg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default App_Setting;
