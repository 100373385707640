// import React from "react";

// function ViewRegion() {
//   const datas = [
//     {
//       name: "Flicknexs",
//       country: "Subscribed User",
//       ip: "20",
//       views: "30",
//     },
//   ];

//   return (
//     <>
//       <div className=" m-0 p-0">
//         <div className="">
//           <section className="section container-fluid pt-3">
//             <div id="content-page" className="content-page">
//               <div className="iq-card">
//                 <div className="iq-card-header  justify-content-between">
//                   <div className="iq-header-title p-0">
//                     <h4 className="card-title">View By Region</h4>
//                   </div>
//                 </div>

//                 <div className="row p-3">
//                   <hr />
//                   <div className="col-md-12">
//                     <div className="clear"></div>

//                     <div className="row align-items-center mb-5 ">
//                       <h5>Region :</h5>
//                       <div className="col-md-6">
//                         <select className="form-control" id="role" name="role">
//                           <option value="">Choose Country</option>
//                           <option value="registered">Registered Users </option>
//                           <option value="subscriber">Subscriber</option>
//                           <option value="admin">Admin</option>
//                           <option value="ppv_users">PPV Users</option>
//                         </select>
//                       </div>
//                       <div className="col-md-6"></div>
//                     </div>

//                     <div className="row">
//                       <div className="col-md-12">
//                         <table className="table" id="user_tabledss">
//                           <thead>
//                             <tr className="r1">
//                               <th>ID</th>
//                               <th>VIDEO NAME</th>
//                               <th>COUNTRY NAME</th>
//                               <th>IP NUMBER</th>
//                               <th>VIEWS </th>
//                             </tr>
//                           </thead>
//                           {datas.map((item, key) => (
//                             <thead>
//                               <tr  key={item?.id} className="r1">
//                                 <td key={key}> {key + 1} </td>

//                                 <td>{item.name}</td>
//                                 <td>{item.country}</td>
//                                 <td>{item.ip}</td>
//                                 <td>{item.views} </td>
//                               </tr>
//                             </thead>
//                           ))}
//                           <tbody></tbody>
//                         </table>
//                       </div>
//                       <div className="col-md-4"></div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div></div>
//             </div>
//           </section>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ViewRegion;


import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import './ViewRegion.css'
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

const ViewRegion = () => {

  const [datas, setDatas] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedModerator, setSelectedModerator] = useState("");

  const [filtermoderator, setFiltermoderator] = useState([

    {
      'name': 'India',
      'value': 'india'
    },
    {
      'name': 'UAE',
      'value': 'dubai'
    },
    {
      'name': 'America',
      'value': 'america'
    },
  ]);
  const fetchData = async () => {
    try {
      setLoading(true);

      // Replace the following URL with your actual GET API endpoint
      const response = await fetch(
        `https://api.example.com/users?start_date=${startDate}&end_date=${endDate}`,
        {
          method: "GET",
          headers: {
            // Add headers if necessary
          },
        }
      );

      const data = await response.json();
      setDatas(data);
      setUserCount(data.length);
      const filtered = selectedModerator ? data.filter(item => item?.role === selectedModerator) : data;
      setFilteredData(filtered);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    fetchData();
  }, [startDate, endDate, selectedModerator]);

  const handleExport = async () => {
    try {
      // Replace the following URL with your actual POST API endpoint for exporting data
      const exportResponse = await fetch("https://api.example.com/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          startDate,
          endDate,
          selectedData: filteredData,
        }),
      });

      if (exportResponse.ok) {
        const blob = await exportResponse.blob();

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "exported_data.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Export failed:", exportResponse.statusText);
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  const regionData = [
    {
      region: "Region A",
      data: [10, 20, 30, 25, 35]
    },
    {
      region: "Region B",
      data: [15, 25, 35, 30, 45]
    },
    // Add more regions as needed
  ];

  const generateRandomColor = () => {
    return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.7)`;
  };

  const newData = regionData.map(region => ({
    label: region.region,
    data: region.data,
    borderColor: generateRandomColor(),
    backgroundColor: generateRandomColor(),
    borderWidth: 1,
    pointStyle: 'circle',
    pointRadius: 5,
    tension: 0.2
  }));

  const newChartData = {
    labels: ["May", "June", "July", "August", "September", "October"], // Example labels
    datasets: newData
  };

  const newOptionsData = {
    type: "line",
    responsive: true,
    plugins: {
      legend: {
        display: true, // Display legend
      },
    },
    scales: {
      x: {
        grid: {
          display: false // Hide x-axis gridlines
        }
      },
      y: {
        beginAtZero: true // Start y-axis scale at 0
      }
    }
  };
  const columns = [
    {
      dataField: "id",
      text: "ID",
    },
    {
      dataField: "video_name",
      text: "VIDEO NAME",
    },
    {
      dataField: "COUNTRY_name",
      text: "COUNTRY NAME",
    },
    {
      dataField: "ip_number",
      text: "IP NUMBER",
    },
    {
      dataField: "views",
      text: "VIEWS",
    },
    // Add more columns as needed
  ];


  return (
    <div className="m-0 p-0">
      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="iq-card-header justify-content-between">
            <div className="iq-header-title p-0">
              <h4 className="card-title">View By Region</h4>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="row p-3">

              <div className="col-md-12">
                <div className="row mt-2 align-items-center">
                  <div className="col-md-6">
                    <div className="row align-items-center">
                      <h5>Filter Moderator</h5>
                      <div className="col-md-12">
                        <select className="form-select" id="role" name="role"
                          onChange={(e) => setSelectedModerator(e.target.value)}
                          value={selectedModerator}>
                          <option value="0">Choose an Moderator</option>

                          {filtermoderator?.map((item, key) => (
                            <option key={key} value={item?.value}>{item?.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Line options={newOptionsData} data={newChartData} />
                    <p className="text-center">View by Region Count</p>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mt-3">
                    <div className="d-flex mt-3 justify-content-between align-items-center flex-wrap mb-3 gap-3">
                      <div className="row col-lg-12 align-items-center">
                        <div className="col-lg-6 align-items-center">
                          <input
                            type="text"
                            placeholder="Filter all.."
                            className="filter form-control "
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                          />

                          {filter?.length > 0 && (
                            <button
                              type="button"
                              className="closeBtn"
                              onClick={() => setFilter("")}
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          )}

                        </div>
                        <div className="col-lg-6  ">
                        </div>
                      </div>

                      {/* <div className="filterContainer">


                        {filter?.length > 0 && (
                          <button
                            type="button"
                            className="closeBtn"
                            onClick={() => setFilter("")}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        )}
                      </div> */}
                      <PaginationProvider
                        pagination={paginationFactory(newOptionsData)}
                      >
                        {({ paginationTableProps }) => (
                          <div className="bootstrapTable p-2">
                            <BootstrapTable
                              keyField="id"
                              columns={columns}
                              data={filteredData}
                              className="no-border-table"
                              noDataIndication={
                                <div className="noRecord">
                                  {loading ? (
                                    <label>
                                      <Response_Loading_processing></Response_Loading_processing>
                                      Loading...
                                    </label>
                                  ) : (
                                    filteredData?.length <= 0 && (
                                      <p>No Record Found!</p>
                                    )
                                  )}
                                </div>
                              }
                              hover
                              headerClasses="videoWidth"
                              selectAll={selectAll}
                              {...paginationTableProps}
                            />
                          </div>
                        )}
                      </PaginationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ViewRegion;
