import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./AddManageSeries.css";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function AddManageSeries(props) {
  // const [name, setName] = useState("");
  // const [slug, setSlug] = useState("");
  // const [image, setImage] = useState("");
  const [parent_id, setParent_id] = useState("");
  const [in_home, setIn_home] = useState("");
  const [footer, setFooter] = useState("");
  // const [banner, setBanner] = useState("");
  // const [banner_image, setBanner_image] = useState("");
  const [in_menu, setIn_menu] = useState("");
  // const [uploaded_by, setUploaded_by] = useState("");

  // const [series_genre_id, setSeries_genre_id] = useState('');

  const [data, setData] = useState([]);

  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
  });

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    // setValidationMessageslug("");
    // setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setIn_home({ ...in_home, [e.target.name]: 1 });
      setFooter({ ...footer, [e.target.name]: 1 });
      setIn_menu({ ...in_menu, [e.target.name]: 1 });
    } else {
      setIn_home({ ...in_home, [e.target.name]: 0 });
      setFooter({ ...footer, [e.target.name]: 0 });
      setIn_menu({ ...in_menu, [e.target.name]: 0 });
    }
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };

  const handleAutoFillChangetitle = (event) => {
    // setValidationMessageslug("");
    // setIsValidationHiddenslug(true);
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  // const [validationMessageslug, setValidationMessageslug] = useState("");
  // const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const titleInputRef = useRef(null);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function series_genre() {
    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    const editInputin_home = in_home?.in_home;
    const in_homedata = JSON.stringify(editInputin_home);

    const editInputfooter = footer?.footer;
    const footerdata = JSON.stringify(editInputfooter);

    const editInputin_menu = in_menu?.in_menu;
    const in_menudata = JSON.stringify(editInputin_menu);

    const formData = new FormData();

    formData.append("name", editUser?.title);
    formData.append("slug", autoFillValuetitle);
    formData.append("in_home", in_homedata ? parseInt(in_homedata, 10) : 0);
    formData.append("in_menu", in_menudata ? parseInt(in_menudata, 10) : 0);
    formData.append("parent_id", parent_id ? parseInt(parent_id, 10) : 0);
    formData.append("footer", footerdata ? parseInt(footerdata, 10) : 0);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/series-category/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;

        resultapi = response?.data;
        const SeriesGenre_id = response?.data?.SeriesGenre_id;

        const imageFormData = new FormData();
        imageFormData.append("series_genre_id", SeriesGenre_id);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("banner_image", selectedFile2);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-series-category`,
          imageFormData,
          { headers: headers }
        );

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }
      }
    } catch (error) { }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      // setValidationMessageslug("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        navigate("/Series-Genre");
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setIsValidationHiddentitle(false);
        // setValidationMessageslug(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}/admin/series-category/create`,
          {
            headers: headers,
          }
        );

        // Process the response
        var result = response?.data?.Series_Genre;
        setData(result);
        // console.log("result", result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className=" m-0 p-0">
        <section className="container-fluid">
          <div className="col-lg-12 row py-3">
            <div className="col-6 col-sm-6 col-lg-6">
              <h3>New Series Genre</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button onClick={series_genre} className="btn btn-primary">
                  Save Category
                </button>
              </div>
            </div>
          </div>

          <div>
            {showOverlay && <Response_Processing></Response_Processing>}
          </div>

          <div className="row col-lg-12 ">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
              <div className="card p-3">
                <h5>Create Category</h5>
                <hr></hr>
                <div className=" text-start">
                  <label className="m-0">
                    Name<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="title"
                      name="title"
                      placeholder="Enter Name"
                      onChange={handleInput}
                      value={editUser?.title}
                      onBlur={handleBlurtitle}
                      onKeyDown={handleInputKeyDowntitle}
                      ref={titleInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddentitle && (
                      <p>{validationMessagetitle}</p>
                    )}
                  </span>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0">Slug</label>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="slug"
                      name="slug"
                      placeholder="Enter Slug"
                      value={autoFillValuetitle}
                      onChange={handleAutoFillChangetitle}
                    />
                  </div>
                </div>

                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0">Category</label>

                    <div className="mt-2">
                      <select
                        className="form-select"
                        name="parent_id"
                        id="parent_id"
                        onChange={(e) => setParent_id(e.target.value)}
                      >
                        <option value="0">Select </option>
                        {data?.map((item) => (
                          <option value={item?.parent_id}>{item?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card p-3">
                <h5>Status Settings</h5>
                <hr></hr>

                <div className="col-sm-12 row">
                  <div className="col-9 col-sm-9 col-lg-9">
                    <p className="p2">Display In Menu</p>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3">
                    <label className="switch">
                      <input
                        name="in_menu"
                        onChange={handleInput}
                        defaultChecked={in_menu?.in_menu === 1 ? true : false}
                        checked={in_menu?.in_menu === 1 ? true : false}
                        className="rs-input"
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="in_menu"
                        onChange={handleInput}
                        value={in_menu?.in_menu === 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 row mt-2">
                  <div className="col-9 col-sm-9 col-lg-9">
                    <p className="p2">Display In Home</p>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3">
                    <label className="switch">
                      <input
                        name="in_home"
                        onChange={handleInput}
                        className="r-input"
                        defaultChecked={in_home?.in_home === 1 ? true : false}
                        checked={in_home?.in_home === 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="in_home"
                        onChange={handleInput}
                        value={in_home?.in_home === 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 row mt-2">
                  <div className="col-9 col-sm-9 col-lg-9">
                    <p className="p2">Is This Episode Display in Footer</p>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3">
                    <label className="switch">
                      <input
                        name="footer"
                        id="footer"
                        onChange={handleInput}
                        className="r-input"
                        defaultChecked={footer?.footer === 1 ? true : false}
                        checked={footer?.footer === 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="footer"
                        id="footer"
                        onChange={handleInput}
                        value={footer?.footer === 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="text-start mb-3">
                <button onClick={series_genre} className="btn btn-primary">
                  Save Category
                </button>
              </div>
            </div>

            {/* Second Part of Section  */}

            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="card p-3">
                <h5>Thumbnails</h5>
                <hr></hr>

                <div className=" text-start mt-2">
                  <label className="m-0">Image</label>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef1.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef1}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef1,
                                setSelectedFile1
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div>
                          {selectedFile1 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL.createObjectURL(selectedFile1)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() => handleDelete(1)}
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" text-start mt-2">
                  <label className="m-0">Banner</label>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef2.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef2}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef2,
                                setSelectedFile2
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div>
                          {selectedFile2 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL.createObjectURL(selectedFile2)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() => handleDelete(2)}
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="">
          <section className="AddManageSeries container-fluid">
            <div className="modal-content p-2 mt-3">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  New Series Genre
                </h5>
              </div>
              <div className="modal-body">
                <div className="form-group ">
                  <label>Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter name"
                  />

                  <span className="text-red" role="alert">
                    <strong></strong>
                  </span>
                </div>

                <div className="mt-3">
                  <label>Slug:</label>
                  <input
                    type="text"
                    id="slug"
                    name="slug"
                    onChange={(e) => setSlug(e.target.value)}
                    className="form-control"
                    placeholder="Enter Slug"
                  />

                  <span className="text-red" role="alert">
                    <strong></strong>
                  </span>
                </div>

                <div className="col-lg-12 row mt-3">
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <label>Display In Menu :</label>
                  </div>
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <label className="switch">
                      <input
                        name="in_menu"
                        onChange={handleInput}
                        defaultChecked={in_menu?.in_menu === 1 ? true : false}
                        checked={in_menu?.in_menu === 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="in_menu"
                        onChange={handleInput}
                        value={in_menu?.in_menu === 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>

                <div className="col-lg-12 row mt-3">
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <label>Display In Home :</label>
                  </div>
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <label className="switch">
                      <input
                        name="in_home"
                        onChange={handleInput}
                        defaultChecked={in_home?.in_home === 1 ? true : false}
                        checked={in_home?.in_home === 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="in_home"
                        onChange={handleInput}
                        value={in_home?.in_home === 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>

                <div className="col-lg-12 row mt-3">
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <label>Display In Footer :</label>
                  </div>
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <label className="switch">
                      <input
                        name="footer"
                        onChange={handleInput}
                        defaultChecked={footer?.footer === 1 ? true : false}
                        checked={footer?.footer === 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="footer"
                        onChange={handleInput}
                        value={footer?.footer === 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>

                <div className="mt-3">
                  <label>Image:</label>
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={(e) => setImage(e.target.files[0])}
                    id="image"
                  />
                </div>
                <div className="mt-3">
                  <label>Banner Image:</label>
                  <input
                    type="file"
                    className="form-control"
                    name="banner"
                    onChange={(e) => setBanner(e.target.files[0])}
                    id="banner"
                  />
                </div>

                <div className=" mt-3">
                  <label>Category:</label>
                  <select
                    id="parent_id"
                    name="parent_id"
                    className="form-control"
                    onChange={(e) => setParent_id(e.target.value)}
                  >
                    <option value="">Select</option>
                    {data?.map((item) => (
                      <option value={item?.parent_id}>{item?.name}</option>
                    ))}
                  </select>
                </div>

                <div className="modal-footer form-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={series_genre}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div> */}
      </div>
    </>
  );
}

export default AddManageSeries;
