import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import "./AddMobileMenu.css";

function AddMobileMenu(props) {
  const [user_id, setUser_id] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [short_note, setShort_note] = useState("");
  const [order, setOrder] = useState("");
  const [image, setImage] = useState("");

  const [editUser, setEditUser] = useState({
    name: "",
    short_note: "",
  });

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // const [data, setData] = useState([]);

  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = () => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagename("");
    setIsValidationHiddenname(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [validationMessagename, setValidationMessagename] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);

  const nameInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function savemenu() {
    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagename("Name cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("id", id);
    formData.append("name", editUser?.name);
    formData.append("short_note", editUser?.short_note);
    formData.append("order", order);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/mobile_side_menu/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;

        resultapi = response?.data;

        var MobileSideMenu_id = response?.data?.MobileSideMenu_id;

        const imageFormData = new FormData();
        imageFormData.append("mobile_side_menu_id", MobileSideMenu_id);

        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-mobile-menus`,
          imageFormData,
          { headers: headers }
        );

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }
      } else if (response?.data?.status === false) { // Handle the case when the first API response status is false
        resultapifalse = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagename("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        navigate("/mobilemenu");
      }, 3000);
    } else if (resultapifalse) {
      // Check if resultapifalse is defined and not null
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setIsValidationHiddenname(false);
      setProcessing(false);
      setShowOverlay(false);
    }
  }

  // const [user_id, setUser_id] = useState("");
  // const [id, setId] = useState("");
  // const [name, setName] = useState("");
  // const [short_note, setShort_note] = useState("");
  // const [order, setOrder] = useState("");
  // const [image, setImage] = useState("");

  // const navigate = useNavigate();
  // const access_token = localStorage.getItem("access_token");

  // async function Addmobilemenudata() {
  //   const formData = new FormData();

  //   formData.append("user_id", user_id);
  //   formData.append("id", id);
  //   formData.append("name", name);
  //   formData.append("short_note", short_note);
  //   formData.append("order", order);

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };

  //   await axios
  //     .post(
  //       `${process.env.REACT_APP_Baseurl}/admin/mobile_side_menu/store`,
  //       formData,
  //       { headers: headers }
  //     )
  //     .then((response) => {
  //       // console.log('api' , response);
  //       if (response.data.status === true) {
  //         var result = response.data;
  //         // console.log('result', response);
  //         // navigate('/series-list')
  //         // alert("Added successfully  !")
  //         var MobileSideMenu_id = response.data.MobileSideMenu_id;
  //         var resssss = response.data;

  //         // console.log('resssss', Menu_id);
  //         // console.log('series_id', series_id);

  //         const formDatas = new FormData();

  //         formDatas.append("mobile_side_menu_id", MobileSideMenu_id);
  //         formDatas.append("image", image);

  //         axios
  //           .post(
  //             `${process.env.REACT_APP_Baseurl}/admin/Image-upload-mobile-menus`,
  //             formDatas,
  //             { headers: headers }
  //           )
  //           .then((response) => {
  //             // console.log('api' , response);
  //             if (response.data.status === true) {
  //               var result = response.data;
  //               // console.log('result', response);
  //               // navigate('/all-artist')
  //               alert("Added successfully  !");
  //             }
  //           });
  //       } else {
  //         console.log("Error");
  //         alert("Enter Correct Details");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Error");
  //       alert("Enter Correct Details");
  //     });
  // }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Add Mobile Menu</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={savemenu} className="btn btn-primary">
                    Save Menu
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && (<Response_Processing></Response_Processing>)}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Mobile Menu</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Mobile Menu Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="name"
                        name="name"
                        placeholder="Enter Title"
                        onChange={handleInput}
                        value={editUser?.name}
                        ref={nameInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenname && (
                        <p>{validationMessagename}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Short Note</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="name"
                        name="short_note"
                        placeholder="Enter Title"
                        onChange={handleInput}
                        value={editUser?.short_note}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="text-start mb-3">
                  <button onClick={savemenu} className="btn btn-primary">
                    Save Menu
                  </button>
                </div> */}
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Mobile Menu Image</label>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile1)
                                      }
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div id="content-page" className="content-page">
              <div className="mt-4">
                <div className="admin-section-title">
                  <div className="iq-card">
                    <div>
                      <div className="">
                        <h4 className="modal-title">New Mobile Side Menu</h4>
                      </div>
                      <div>
                        <div className="modal-body">
                          <div className="form-group">
                            <label>Upload Icon Image:</label> <br />
                            <input
                              type="file"
                              className="form-control"
                              name="image"
                              id="image"
                              onChange={(e) => setImage(e.target.files[0])}
                            />
                          </div>

                          <div className="form-group">
                            <label>Name:</label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              onChange={(e) => setName(e.target.value)}
                              placeholder="Enter the Name"
                            />
                          </div>

                          <div className="form-group">
                            <label>Short Note:</label>
                            <input
                              type="text"
                              id="short_note"
                              name="short_note"
                              className="form-control"
                              onChange={(e) => setShort_note(e.target.value)}
                              placeholder="Enter the Short Note"
                            />
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary"
                            id="submit-new-mobilemenu"
                            onClick={Addmobilemenudata}
                          >
                            Save Mobile Menu
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default AddMobileMenu;
