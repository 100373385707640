import React, { useState, useEffect } from "react";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import "./MobileMenu.css";

function MobileMenu(props) {
  const [datamenuall, setDatamenuall] = useState([]);
  const [countmenuall, setCountmenuall] = useState();
  const [pagemenuall, setPagemenuall] = useState(1);
  // const navigate = useNavigate();
  // const [message, setMessage] = useState("");
  const [videodatamenuall, setVideodatamenuall] = useState("");
  const [loading, setLoading] = useState(true);
  const [defaultImage, setDefaultimage] = useState();
  const [defaultImageurl, setDefaultimageurl] = useState("");
  const [currentPagemenuall, setCurrentPagemenuall] = useState(1);
  const itemsPerPagemenuall = 10;

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };




  const [showModal, setShowModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [modelClose, setModelClose] = useState(false);

  const [menuItems, setMenuItems] = useState([]);


  const closeModal = () => {
    setShowModal(false);
  };

  const confirmToggle = async (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const videosdeleteOperationmenuall = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/mobile_side_menu/delete/` + id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response?.data?.status == true) {
          var responseData = response?.data;
          props?.setApiresponsealert(responseData);
          props?.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props?.setApiresponsealert(resultError);
            props?.setShowalert(true);
          }
        }
      });
  };

  const [selectedItemsmenuall, setSelectedItemsmenuall] = useState([]);
  const [selectAllmenuall, setSelectAllmenuall] = useState(false);
  const [isConfirmationOpenmenuall, setIsConfirmationOpenmenuall] =
    useState(false);
  const [isSuccessMessageOpenmenuall, setIsSuccessMessageOpenmenuall] =
    useState(false);

  const handleSingleSelectmenuall = (itemId) => {
    setSelectedItemsmenuall([itemId]);
    setSelectAllmenuall(false);
  };

  const handleMultiSelectmenuall = (itemId) => {
    if (selectedItemsmenuall?.includes(itemId)) {
      setSelectedItemsmenuall(
        selectedItemsmenuall?.filter((id) => id !== itemId)
      );
    } else {
      setSelectedItemsmenuall([...selectedItemsmenuall, itemId]);
    }
  };

  const handleSelectAllmenuall = () => {
    setSelectAllmenuall(!selectAllmenuall);
    setSelectedItemsmenuall(
      selectAllmenuall ? [] : videodatamenuall?.map((item) => item?.id)
    );
  };

  const handleDeletemenuall = () => {
    setIsConfirmationOpenmenuall(true);
  };
  const handleCancelDeletemenuall = () => {
    setIsConfirmationOpenmenuall(false);
  };

  const handleConfirmDeletemenuall = () => {
    setIsConfirmationOpenmenuall(false);
    for (const itemId of selectedItemsmenuall) {
      axios
        .delete(
          `${process.env.REACT_APP_Baseurl}/admin/mobile_side_menu/delete/${itemId}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.data.status == true) {
            setVideodatamenuall(
              videodatamenuall?.filter((item) => item?.id !== itemId)
            );
            setSelectedItemsmenuall(
              selectedItemsmenuall?.filter((id) => id !== itemId)
            );
            setIsSuccessMessageOpenmenuall(true);
            setTimeout(() => {
              setIsSuccessMessageOpenmenuall(false);
            }, 2000);
            var responseData = response.data;
            props?.setApiresponsealert(responseData);
            props?.setShowalert(true);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            if (responseData === false) {
              var resultError = responseData;
              props?.setApiresponsealert(resultError);
              props?.setShowalert(true);
            }
          }
        });
    }
  };

  const fetchDatanextpremenuall = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/mobile_side_menu/index?page=${pagemenuall}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const videosData = res?.data?.mobile_side_menus?.data;
        setVideodatamenuall(videosData);
        if (videosData?.length > 0) {
          setDatamenuall(videosData);
          setLoading(false);
        } else {
          setDatamenuall([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchDatamenuall = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/mobile_side_menu/index`,
        { headers: headers }
      );
      setCountmenuall(response?.data?.Total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchdefaultimagemenuall = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/Vertical-Default-Image`,
        { headers: headers }
      );
      setDefaultimage(response?.data?.Vertical_Default_Image);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchdefaultimageurlmenuall = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/Default-image-url`,
        { headers: headers }
      );
      setDefaultimageurl(response?.data?.Default_Image_Url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const indexOfLastItemmenuall = currentPagemenuall * itemsPerPagemenuall;
  const indexOfFirstItemmenuall = indexOfLastItemmenuall - itemsPerPagemenuall;
  const currentItemsmenuall = datamenuall?.slice(
    indexOfFirstItemmenuall,
    indexOfLastItemmenuall
  );

  const handlePreClickmenuall = () => {
    if (pagemenuall > 1) {
      setPagemenuall(pagemenuall - 1);
    }
  };

  const handleNxtClickmenuall = () => {
    setPagemenuall(pagemenuall + 1);
  };

  const [itemToDeletemenuall, setItemToDeletemenuall] = useState(null);
  const [successMessagemenuall, setSuccessMessagemenuall] = useState("");

  const openModalmenuall = (id) => {
    setItemToDeletemenuall(id);
    setSuccessMessagemenuall("");
  };

  const closeModalmenuall = () => {
    setItemToDeletemenuall(null);
  };
  const handleDeletedata = () => {
    closeModalmenuall();
    if (itemToDeletemenuall) {
      videosdeleteOperationmenuall(itemToDeletemenuall);
    }
  };

  const [mobilemenu, setMobilemenu] = useState([]);



  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/mobile_side_menu/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.mobile_side_menus?.data;
        setMobilemenu(result);
        setMenuItems(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(async () => {
    fetchDatamenuall();
    fetchdefaultimagemenuall();
    fetchdefaultimageurlmenuall();
    fetchDatanextpremenuall();
  }, [pagemenuall]);

  const handleConfirmation = async (confirmed) => {
    if (confirmed) {
      try {
        setProcessing(true);
        setShowOverlay(true);

        const newStatus = currentItem.active === 1 ? 0 : 1;
        const response = await fetch(`${process.env.REACT_APP_Baseurl}/admin/active-mobile_side_menu`, {
          method: 'POST',
          body: JSON.stringify({
            menu_id: currentItem.id,
            active: newStatus,
          }),
          headers: headers,
        });

        const responseData = await response.json();
        if (response.ok) {
          const updatedItems = menuItems.map((menuItem) => {
            if (menuItem.id === currentItem.id) {
              return {
                ...menuItem,
                active: newStatus,
              };
            }
            return menuItem;
          });

          setMenuItems(updatedItems);
          props?.setApiresponsealert(responseData);
          props?.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);

        } else {
          props?.setApiresponsealert(responseData);
          props?.setShowalert(true);
        }
      } catch (err) {
        var errorMessage = err.response ? err.response.data.message || 'An error occurred' : 'An error occurred';
        props?.setApiresponsealert({
          status: false,
          message: errorMessage
        });
        props?.setShowalert(true);
      } finally {
        setProcessing(false);
        setShowOverlay(false);
      }
    };

    setCurrentItem(null);
    setShowModal(false);
  };


  return (
    <>

      <div
        className={`modal packageModel ${showModal ? 'show fade' : ''}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: `${showModal ? 'block' : 'none'}` }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirmation</h5>
              <button type="button" className="btn-close" onClick={closeModal}></button>
            </div>
            <div className="modal-body">Are you sure you want to switch the Slider status?</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => handleConfirmation(false)}>
                No
              </button>
              <button type="button" className="btn btn-primary" onClick={() => handleConfirmation(true)}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal ${itemToDeletemenuall !== null ? "show" : ""}`}
        tabIndex="-1"
        style={{
          display: itemToDeletemenuall !== null ? "block" : "none",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModalmenuall}
              ></button>
            </div>
            <div className="modal-body">
              {successMessagemenuall ? (
                <div className="alert alert-success" role="alert">
                  {successMessagemenuall}
                </div>
              ) : (
                <p>Are you sure you want to delete this Mobile Menu?</p>
              )}
            </div>
            <div className="modal-footer">
              {successMessagemenuall ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closeModalmenuall}
                >
                  Close
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModalmenuall}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeletedata}
                  >
                    Yes
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delete_confirm">
          <div className="modal-content text-center">
            <div className="modal-body">
              {isConfirmationOpenmenuall && (
                <div>
                  <h5 className="modal-title p-3" id="exampleModalLabel">
                    <p className="fs-6">
                      Are you sure you want to delete the selected items?
                    </p>
                  </h5>
                  <div className="col-lg-12 row p-4">
                    <div className="col-lg-6">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          handleConfirmDeletemenuall();
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <button
                        className="btn btn-primary"
                        onClick={handleCancelDeletemenuall}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {isSuccessMessageOpenmenuall && (
                <div className="success-message">
                  <div className="p-5">
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </div>
                  <p className="fs-6 fw-bold">
                    Selected items have been successfully deleted.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className=" m-0 p-0">
        <div className="container-fluid">
          <section className="section all-video-stream ">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>
                  All Mobile Menu{" "}
                  <span className="fs-6">
                    (Display all {countmenuall} Menu)
                  </span>
                </h3>
              </div>
              <div className="col-3 col-sm-3 col-lg-3 text-end">
                {selectedItemsmenuall?.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleDeletemenuall}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Delete Selected
                  </button>
                )}
              </div>

              <div className="col-3 col-sm-3 col-lg-3 text-end">
                <Link to="/addmobilemenu">
                  <button type="button" className="btn btn-primary">
                    Add Mobile Menu
                  </button>
                </Link>
              </div>
            </div>

            <div className="col-lg-12 ">
              <div className="card">
                {loading ? (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllmenuall}
                                onChange={handleSelectAllmenuall}
                              />
                            </label>
                          </th>
                          <th>MOBILE MENU</th>
                          <th>SHORT NAME </th>
                          <th>STATUS</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                    </Table>

                    <div className="text-center">
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>{" "}
                        Loading...
                      </label>
                    </div>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickmenuall}
                          disabled={pagemenuall <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pagemenuall}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickmenuall}
                          disabled={datamenuall?.length < itemsPerPagemenuall}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : videodatamenuall.length > 0 ? (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllmenuall}
                                onChange={handleSelectAllmenuall}
                              />
                            </label>
                          </th>
                          <th>MOBILE MENU</th>
                          <th>SHORT NAME </th>
                          <th>STATUS</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      {currentItemsmenuall?.map((item, key) => (
                        <tbody>
                          <tr key={item?.id}>
                            <td key={item?.id}>
                              <input
                                type="checkbox"
                                checked={selectedItemsmenuall?.includes(
                                  item?.id
                                )}
                                onChange={() =>
                                  handleMultiSelectmenuall(item?.id)
                                }
                              />
                            </td>

                            <td>
                              <div className="d-flex text-start align-items-center ">
                                <div>
                                  {loading ? (
                                    <img
                                      src={item?.image}
                                      className="w-100"
                                      height="100"
                                    />
                                  ) : (
                                    <img
                                      src={item?.image}
                                      alt="Image"
                                      width="50"
                                    />
                                  )}
                                </div>
                                <div>
                                  <div className="ms-2">
                                    <div>{item?.name}</div>
                                    <div className="d-flex ">
                                      <div className="">
                                        <span>{item?.upload_on}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>{item?.short_note}</td>

                            <td>
                              <div>
                                <label className="switch">
                                  <input
                                    name="active"
                                    id={`active_${item.id}`}
                                    className={item.active === 0 ? 'inactive' : null}
                                    checked={item.active === 1}
                                    onChange={() => confirmToggle(item)}
                                    type="checkbox"
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="editdropdown">
                                <span className="editdropdown-button">
                                  <span>
                                    <i
                                      className="fa fa-ellipsis-v"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </span>
                                <div className="editdropdown-menu">
                                  <Link
                                    to={"/editmobilemenu/" + item?.id}
                                    className="edit"
                                  >
                                    <span>
                                      <img
                                        src={edititem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">Edit Menu</span>
                                    </span>
                                  </Link>
                                  <Link to="" className="delete">
                                    <span
                                      onClick={() => openModalmenuall(item?.id)}
                                    >
                                      <img
                                        src={deleteitem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">Delete Menu</span>
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </Table>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickmenuall}
                          disabled={pagemenuall <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pagemenuall}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickmenuall}
                          disabled={datamenuall?.length < itemsPerPagemenuall}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllmenuall}
                                onChange={handleSelectAllmenuall}
                              />
                            </label>
                          </th>
                          <th>MOBILE MENU</th>
                          <th>SHORT NAME </th>
                          <th>STATUS</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                    </Table>

                    <div className="text-center">
                      <label>No Mobile Menu Available.</label>
                    </div>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickmenuall}
                          disabled={pagemenuall <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pagemenuall}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickmenuall}
                          disabled={datamenuall?.length < itemsPerPagemenuall}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default MobileMenu;
