import React, { useState, useEffect } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const PaginationCustom = ({ data, handlePage, currentPage, visibleLength }) => {
    const maxVisiblePages = 5;
    const totalPages = Math.ceil(data.length / visibleLength) || 1;

    const [visiblePages, setVisiblePages] = useState(Array.from({ length: Math.min(totalPages, maxVisiblePages) }, (_, index) => index + 1));
    //console.log(Math.min(totalPages, maxVisiblePages))
    const updateVisiblePages = (currentPage) => {
        let newStartPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let newEndPage = Math.min(totalPages, newStartPage + maxVisiblePages - 1);
        setVisiblePages([...Array(newEndPage - newStartPage + 1)].map((_, index) => newStartPage + index));
    };

    useEffect(() => {
        updateVisiblePages(currentPage);
    }, [currentPage, totalPages]);

    
    useEffect(() => {
        if (data) {
            setVisiblePages(Array.from({ length: Math.min(totalPages, maxVisiblePages) }, (_, index) => index + 1));
        }
    }, []);
    return (
        <div className="viewAllPagination d-flex justify-content-end">
            <button onClick={() => handlePage(currentPage - 1)} disabled={currentPage === 1} className="arrowButton  theme-border-color  customPaginationBorder"><IoIosArrowBack /></button>
            {visiblePages.map((pageNumber) => (<button key={pageNumber} onClick={() => handlePage(pageNumber)} className={`mapButton ${currentPage === pageNumber ? 'active theme-bg-color-secondary ' : 'theme-border-color customPaginationBorder'}`} >   {pageNumber}  </button>))}
            <button onClick={() => handlePage(currentPage + 1)} disabled={currentPage === totalPages} className="arrowButton  theme-border-color customPaginationBorder "  ><IoIosArrowForward /></button>
        </div>

    );
};

export default PaginationCustom;