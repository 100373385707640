// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*======================
    404 page
=======================*/

.page_4044 {
  padding: 80px 0;
  height: 100vh;
  background-color: #3d3a2d3b;
}`, "",{"version":3,"sources":["webpack://./src/Error404/Maintances.css"],"names":[],"mappings":"AAAA;;wBAEwB;;AAExB;EACE,eAAe;EACf,aAAa;EACb,2BAA2B;AAC7B","sourcesContent":["/*======================\n    404 page\n=======================*/\n\n.page_4044 {\n  padding: 80px 0;\n  height: 100vh;\n  background-color: #3d3a2d3b;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
