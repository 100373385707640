import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./EditManageseriesgenre.css";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function EditManageseriesgenre(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    order: "",
    parent_id: "",
    name: "",
    slug: "",
    in_home: "",
    footer: "",
    in_menu: "",
    uploaded_by: "",
  });
  const [editname, setEditname] = useState({
    name: "",
  });

  const [message, setMessage] = useState("");

  const [image, setImage] = useState("");
  const [data, setData] = useState([]);
  const [banner, setBanner] = useState("");
  const [loading, setLoading] = useState(true);

  const access_token = localStorage.getItem("access_token");

  // console.log(allvideos);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  useEffect(async () => {
    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/series-category/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var result = response?.data?.Series_Genre;
        // var resultss = response.data.SeriesGenre[0].name
        setData(result);
        // console.log('result', result)
        // console.log('SeriesGenre', result);
      })
      .catch((error) => console.log(error));

    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/series-category/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.SeriesGenre[0];
      // var result = resData.user_access
      setEditUser(res);
      setEditname(res);
      setLoading(false);

      // console.log(res)
      // setUseraccess(result)
      // console.log(resData)
      // console.log(result)
      // console.log(res)
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    // if (e.target.checked === true) {
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    //   setEditUser({ ...editUser, [e.target.id]: 1 });
    //   setEditUser({
    //     ...editUser,
    //     [e.target.name]: e.target.value,
    //     [e.target.name]: 1,
    //   });
    // } else {
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    //   setEditUser({ ...editUser, [e.target.id]: 0 });
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    // }
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const titleInputRef = useRef(null);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagetitle("Name cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      order: editUser?.order,
      parent_id: editUser?.parent_id,
      name: editUser?.name,
      image: editUser?.image,
      slug: editUser?.slug,
      in_home: editUser?.in_home,
      footer: editUser?.footer,
      banner: editUser?.banner,
      uploaded_by: editUser?.uploaded_by,
      in_menu: editUser?.in_menu,
      banner_image: editUser?.banner_image,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/series-category/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        const SeriesGenre_id = resjson?.SeriesGenre_id;
        resultapi = resjson;

        const formData = new FormData();
        formData.append("series_genre_id", SeriesGenre_id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("banner_image", selectedFile2);
        }

        await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-series-category`,
          formData,
          { headers: headers }
        );
        secondApiStatus = true;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson?.status === false) {
        var resultError = resjson;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setIsValidationHiddentitle(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <section className="container-fluid">
          <div className="col-lg-12 row py-3">
            <div className="col-6 col-sm-6 col-lg-6">
              <h3>Edit Series Genre</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Series Genre
                </button>
              </div>
            </div>
          </div>

          <div>
            {showOverlay && <Response_Processing></Response_Processing>}
          </div>

          <div className="row col-lg-12 ">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
              <div className="card p-3">
                <h5>{editname?.name}</h5>
                <hr></hr>
                <div className=" text-start">
                  <label className="m-0">
                    Name<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="name"
                      name="name"
                      placeholder="Enter Name"
                      onChange={handleInput}
                      value={editUser?.name}
                      ref={titleInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddentitle && (
                      <p>{validationMessagetitle}</p>
                    )}
                  </span>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0">Slug</label>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="slug"
                      name="slug"
                      placeholder="Enter Slug"
                      onChange={handleInput}
                      value={editUser?.slug}
                    />
                  </div>
                </div>

                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0">Category</label>

                    <div className="mt-2">
                      <select
                        className="form-select"
                        name="parent_id"
                        id="parent_id"
                        onChange={handleInput}
                        value={editUser?.parent_id}
                      >
                        {data?.map((item) => (
                          <option value={item?.id}>{item?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card p-3">
                <h5>Status Settings</h5>
                <hr></hr>

                <div className="col-sm-12 row">
                  <div className="col-9 col-sm-9 col-lg-9">
                    <p className="p2">Display In Menu</p>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3">
                    <label className="switch">
                      <input
                        name="in_menu"
                        onChange={handleInputenable}
                        defaultChecked={editUser?.in_menu == 1 ? true : false}
                        checked={editUser?.in_menu == 1 ? true : false}
                        className="rs-input"
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="in_menu"
                        onChange={handleInputenable}
                        value={editUser?.in_menu == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 row mt-2">
                  <div className="col-9 col-sm-9 col-lg-9">
                    <p className="p2">Display In Home</p>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3">
                    <label className="switch">
                      <input
                        name="in_home"
                        onChange={handleInputenable}
                        className="r-input"
                        defaultChecked={editUser?.in_home == 1 ? true : false}
                        checked={editUser?.in_home == 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="in_home"
                        onChange={handleInputenable}
                        value={editUser?.in_home == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 row mt-2">
                  <div className="col-9 col-sm-9 col-lg-9">
                    <p className="p2">Is This Episode Display in Footer</p>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3">
                    <label className="switch">
                      <input
                        name="footer"
                        id="footer"
                        onChange={handleInputenable}
                        className="r-input"
                        defaultChecked={editUser?.footer == 1 ? true : false}
                        checked={editUser?.footer == 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="footer"
                        id="footer"
                        onChange={handleInputenable}
                        value={editUser?.footer == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="text-start mb-3">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Series Genre
                </button>
              </div>
            </div>

            {/* Second Part of Section  */}

            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="card p-3">
                <h5>Thumbnails</h5>
                <hr></hr>

                <div className=" text-start mt-2">
                  <label className="m-0">Image</label>

                  {/* <div>
                    <img src={editUser?.image_url} width="150" height="100" />
                  </div> */}

                  <div>
                    {loading ? (
                      <Response_Loading_processing></Response_Loading_processing>
                    ) : (
                      <img
                        src={editUser?.image_url}
                        alt="Image"
                        className="thumbnails-images"
                      />
                    )}
                  </div>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef1.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef1}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef1,
                                setSelectedFile1
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div>
                          {selectedFile1 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL?.createObjectURL(selectedFile1)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() => handleDelete(1)}
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" text-start mt-2">
                  <label className="m-0">Banner</label>

                  {/* <div>
                    <img
                      src={editUser?.banner_image_url}
                      width="150"
                      height="100"
                    />
                  </div> */}

                  <div>
                    {loading ? (
                      <Response_Loading_processing></Response_Loading_processing>
                    ) : (
                      <img
                        src={editUser?.banner_image_url}
                        alt="Image"
                        className="thumbnails-images"
                      />
                    )}
                  </div>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef2.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef2}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef2,
                                setSelectedFile2
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div>
                          {selectedFile2 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL.createObjectURL(selectedFile2)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() => handleDelete(2)}
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="">
          <section className="EditManageSeries container-fluid">
            <div className="modal-content p-2 mt-3">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Series Genre
                </h5>
              </div>
              <div className="modal-body">
                <div className="form-group ">
                  <label>Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    onChange={handleInput}
                    value={editUser?.name}
                    placeholder="Enter name"
                  />

                  <span className="text-red" role="alert">
                    <strong></strong>
                  </span>
                </div>

                <div className="mt-3">
                  <label>Slug:</label>
                  <input
                    type="text"
                    id="slug"
                    name="slug"
                    onChange={handleInput}
                    value={editUser?.slug}
                    className="form-control"
                    placeholder="Enter Slug"
                  />

                  <span className="text-red" role="alert">
                    <strong></strong>
                  </span>
                </div>

                <div className="mt-3">
                  <label>Display In Menu :</label>
                  <select
                    id="parent_id"
                    name="parent_id"
                    className="form-control"
                    onChange={handleInput}
                  >
                    <option value="0">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>

                <div className="mt-3">
                  <label>Display In Home :</label>
                  <select
                    id="in_home"
                    name="in_home"
                    className="form-control"
                    onChange={handleInput}
                  >
                    <option value="0">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
                <div className="mt-3">
                  <label>Display In Footer :</label>
                  <select
                    id="footer"
                    name="footer"
                    className="form-control"
                    onChange={handleInput}
                  >
                    <option value="0">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>

                <div className="mt-3">
                  <label>Image:</label>
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={(e) => setImage(e.target.files[0])}
                    id="image"
                  />
                </div>

                <div className=" mt-3">
                  <label>Category:</label>
                  <select
                    value={editUser?.parent_id}
                    id="parent_id"
                    name="parent_id"
                    className="form-control"
                    onChange={handleInput}
                  >
                     {data?.map((item) => (
                      <option value={item?.parent_id}>{item?.name}</option>
                    ))}
                  </select>
                </div>

                <div className="modal-footer form-group">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div> */}
      </div>
    </>
  );
}

export default EditManageseriesgenre;
