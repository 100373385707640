import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import axios from "axios";

const Footer = () => {

  const currentYear = new Date().getFullYear();

  const [metadetails, setMetadetails] = useState({
    website_name: "",
  });

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reqData = await axios.get(
          `${process.env.REACT_APP_Baseurl}/admin/header`,
          { headers }
        );
        setMetadetails(reqData?.data?.Setting);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  return (
    <div>
      <div className="admin-footer theme-bg-color">
          <div className="row copyrights1">
            <div className="col-md-7">
              {/* <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <Link to="/terms-and-conditions" className="copyrights1Link">
                    Terms and Conditions
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/privacy-policy" className="copyrights1Link">
                    Privacy Policy
                  </Link>
                </li>
              </ul> */}
            </div>
            <div className="col-md-5 text-right copyrights">
              <lable> Copyright {currentYear} </lable>
              <Link
                to={`${process.env.REACT_APP_Baseurl_Frontend}`}
                target="_blank"
                className="wesite ms-1"
              >
                {metadetails?.website_name}
              </Link>
              <lable>. All Rights Reserved.</lable>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Footer;
