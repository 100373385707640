import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
body {
  background-color: ${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.text} !important;
}
header{
  background-color: ${({ theme }) => theme.body};
}
button {
   background-color:${({ theme }) => theme.button} 
}
a:hover{
  color: ${({ theme }) => theme.text};
}
a{
  color: ${({ theme }) => theme.text};
}
input::placeholder,textarea::placeholder {
  color: ${({ theme }) => theme.text} !important;
}
.theme-text-color {
  color: ${({ theme }) => theme.text} !important;
}
.theme-bg-color{
  background-color: ${({ theme }) => theme.body} !important;
}
.theme-bg-color-secondary{
  background-color: ${({ theme }) => theme.secondarybody} !important;
}
.theme-border-primary-color{
  border-color: ${({ theme }) => theme.body} !important;
}
.theme-border-secondary-color{
  border-bottom:2px solid  ${({ theme }) => theme.secondarybody} !important;
}
.theme-button-bg-color {
  color: ${({ theme }) => theme.button};
}
.theme-border-secondary{
  border: 2px solid ${({ theme }) => theme.secondarybody} !important;
}
.theme-border-bottom-secondary{
  border-bottom: 2px solid ${({ theme }) => theme.secondarybody} !important;
}
.theme-border-color{
  border-color: ${({ theme }) => theme.secondarybody} !important;
}

.bootstrapTable table>thead>tr>th{
  border-bottom: 2px solid ${({ theme }) => theme.secondarybody} !important;
  background-color: ${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.text} !important;
}
.bootstrapTable table>tbody>tr{
  border-bottom: 2px solid ${({ theme }) => theme.secondarybody} !important;
}
.bootstrapTable table>tbody>tr>td{
  background-color: ${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.text} !important;
}

.editdropdown-menu a:hover{
  background-color: ${({ theme }) => theme.secondarybody} !important;
}
.commonActionPadding:hover {
  background-color: ${({ theme }) => theme.secondarybody} !important;
}
#pageDropDown{
  background-color: ${({ theme }) => theme.body};
  border: 1px solid ${({ theme }) => theme.secondarybody} !important;
  color: ${({ theme }) => theme.text} !important;
}





.react-bs-table-sizePerPage-dropdown .dropdown-menu {
  background-color: ${({ theme }) => theme.body} !important;
   color: ${({ theme }) => theme.text} !important;
}
.bootstrapTable .page-link {
  background-color: ${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.text} !important;
  border-color:${({ theme }) => theme.secondarybody} !important;
}

.bootstrapTable .page-item.active > .page-link{
  background-color: ${({ theme }) => theme.secondarybody} !important;
}

.adminHeadContainer .dropdown-item:hover, .adminHeadContainer .dropdown-item:focus{
  background-color: ${({ theme }) => theme.secondarybody};
}
.rti--container, .rti--input {
  background-color: ${({ theme }) => theme.secondarybody} !important;
  border:0 !important;
}
.jodit-container *{
  font-family:'Poppins'
}
.jodit-toolbar__box, .jodit-workplace,.jodit-status-bar{
  background-color: ${({ theme }) => theme.secondarybody} !important;
  color: ${({ theme }) => theme.text} !important;
}
.jodit-toolbar__box svg{
  fill: ${({ theme }) => theme.text} !important;
}
.css-1nmdiq5-menu > *{
  background-color: ${({ theme }) => theme.secondarybody} !important;
  color: ${({ theme }) => theme.text} !important;
}
.css-1p3m7a8-multiValue, .css-wsp0cs-MultiValueGeneric{
  background-color: ${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.text};
}
.css-1xc3v61-indicatorContainer >svg{
  fill:${({ theme }) => theme.text};
}
.rti--tag{
  background-color: ${({ theme }) => theme.body};
}
.orderTitle.active::before{
background-color:${({ theme }) => theme.button} 
}
.secondary:hover{
background-color:${({ theme }) => theme.body} !important;
}
.primary:hover{
background-color:${({ theme }) => theme.secondarybody} !important;
}
`;