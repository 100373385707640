import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./MobileSettings.css";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function EditMobileSettings(props) {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [editUser, setEditUser] = useState({
    name: "",
    welcome_images_url: "",
    andriod_Welcome_Screen_url: "",
    AndroidTv_Welcome_Screen_url: "",
    LG_Welcome_Screen_url: "",
    RokuTV_Welcome_Screen_url: "",
    Samsung_Welcome_Screen_url: "",
    Firetv_Welcome_Screen_url: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagename("");
    setIsValidationHiddenname(true);
  };
  useEffect(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/welcome-screen-edit/${id}`,
        { headers: headers }
      )
      .then((res) => {
        var name = res?.data?.welcome_screen[0];
        setEditUser(name);
        setLoading(false);
      });
  }, []);

  const fileInputRef1 = useRef(null);
  const fileInputRef9 = useRef(null);
  const fileInputRef10 = useRef(null);
  const fileInputRef11 = useRef(null);
  const fileInputRef12 = useRef(null);
  const fileInputRef13 = useRef(null);
  const fileInputRef14 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile9, setSelectedFile9] = useState(null);
  const [selectedFile10, setSelectedFile10] = useState(null);
  const [selectedFile11, setSelectedFile11] = useState(null);
  const [selectedFile12, setSelectedFile12] = useState(null);
  const [selectedFile13, setSelectedFile13] = useState(null);
  const [selectedFile14, setSelectedFile14] = useState(null);

  const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };


  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 9:
        setSelectedFile9(null);
        if (fileInputRef9.current) {
          fileInputRef9.current.value = null;
        }
        break;
      case 10:
        setSelectedFile10(null);
        if (fileInputRef10.current) {
          fileInputRef10.current.value = null;
        }
        break;
      case 11:
        setSelectedFile11(null);
        if (fileInputRef11.current) {
          fileInputRef11.current.value = null;
        }
        break;
      case 12:
        setSelectedFile12(null);
        if (fileInputRef12.current) {
          fileInputRef12.current.value = null;
        }
        break;
      case 13:
        setSelectedFile13(null);
        if (fileInputRef13.current) {
          fileInputRef13.current.value = null;
        }
        break;
      case 14:
        setSelectedFile14(null);
        if (fileInputRef14.current) {
          fileInputRef14.current.value = null;
        }
        break;
      default:
        break;
    }
  };


  const [validationMessagename, setValidationMessagename] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);

  const nameInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function handleUpdate() {
    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagename("Name cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const formData = new FormData();

    formData.append("id", id);
    formData.append("welcome_images", selectedFile1);
    formData.append("name", editUser?.name);
    formData.append("andriod_Welcome_Screen", selectedFile9);
    formData.append("AndroidTv_Welcome_Screen", selectedFile10);
    formData.append("LG_Welcome_Screen", selectedFile11);
    formData.append("RokuTV_Welcome_Screen", selectedFile12);
    formData.append("Samsung_Welcome_Screen", selectedFile13);
    formData.append("Firetv_Welcome_Screen", selectedFile14);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/welcome-screen-update/${id}`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;

        resultapi = response.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setValidationMessagename("");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;
        console.log(resultError);
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setIsValidationHiddenname(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  const getFileType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'webp':
      case 'bmp':
      case 'svg':
        return 'image';
      case 'gif':
        return 'image';
      case 'mp4':
        return 'video';
      case 'webm':
        return 'video';
      case 'ogg':
        return 'video';
      case 'm3u8':
        return 'video';
      default:
        return 'application';
    }
  };

  const getFileTypeaddvideo = (file) => {
    if (!file || !file.name) {
      return 'application';
    }
    const extension = file.name.split('.').pop().toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'webp':
      case 'bmp':
      case 'svg':
        return 'image';
      case 'gif':
        return 'image';
      case 'mp4':
        return 'video';
      case 'webm':
        return 'video';
      case 'ogg':
        return 'video';
      case 'm3u8':
        return 'video';
      default:
        return 'application';
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Mobile App Image</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Image
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className=" row col-sm-12 col-md-12 col-lg-12">
              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Welcome Splash</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">
                      Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="name"
                        name="name"
                        placeholder="Enter Name"
                        onChange={handleInput}
                        value={editUser?.name}
                        ref={nameInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenname && <p>{validationMessagename}</p>}
                    </span>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Welcome Images</label>


                    <div>
                      {loading ? (
                        <Response_Loading_processing />
                      ) : (
                        <>
                          {(() => {
                            const fileType = getFileType(editUser?.welcome_images_url);
                            if (fileType === 'image') {
                              return (
                                <img
                                  src={editUser?.welcome_images_url}
                                  alt="Media"
                                  className="thumbnails-images"
                                />
                              );
                            } else if (fileType === 'video') {
                              return (
                                <video
                                  src={editUser?.welcome_images_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else if (fileType === 'm3u8') {
                              return (
                                <video
                                  src={editUser?.welcome_images_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else {
                              return (
                                null
                              );
                            }
                          })()}
                        </>
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    {(() => {
                                      const fileType = getFileTypeaddvideo(selectedFile1);
                                      const fileURL = URL.createObjectURL(selectedFile1);
                                      if (fileType === 'image') {
                                        return (
                                          <img
                                            src={fileURL}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else if (fileType === 'video') {
                                        return (
                                          <video
                                            src={fileURL}
                                            controls
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}

                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(1)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className=" text-start mt-2">
                    <label className="m-0">Splash Screen (Andriod)</label>
                    <div>
                      {loading ? (
                        <Response_Loading_processing />
                      ) : (
                        <>
                          {(() => {
                            const fileType = getFileType(editUser?.andriod_Welcome_Screen_url);
                            if (fileType === 'image') {
                              return (
                                <img
                                  src={editUser?.andriod_Welcome_Screen_url}
                                  alt="Media"
                                  className="thumbnails-images"
                                />
                              );
                            } else if (fileType === 'video') {
                              return (
                                <video
                                  src={editUser?.andriod_Welcome_Screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else if (fileType === 'm3u8') {
                              return (
                                <video
                                  src={editUser?.andriod_Welcome_Screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else {
                              return (
                                null
                              );
                            }
                          })()}
                        </>
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef9.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef9}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef9,
                                  setSelectedFile9
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile9 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">

                                    {(() => {
                                      const fileType = getFileTypeaddvideo(selectedFile9);
                                      const fileURL = URL.createObjectURL(selectedFile9);
                                      if (fileType === 'image') {
                                        return (
                                          <img
                                            src={fileURL}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else if (fileType === 'video') {
                                        return (
                                          <video
                                            src={fileURL}
                                            controls
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}

                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(9)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Splash Screen(Android TV)</label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing />
                      ) : (
                        <>
                          {(() => {
                            const fileType = getFileType(editUser?.AndroidTv_Welcome_Screen_url);
                            if (fileType === 'image') {
                              return (
                                <img
                                  src={editUser?.AndroidTv_Welcome_Screen_url}
                                  alt="Media"
                                  className="thumbnails-images"
                                />
                              );
                            } else if (fileType === 'video') {
                              return (
                                <video
                                  src={editUser?.AndroidTv_Welcome_Screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else if (fileType === 'm3u8') {
                              return (
                                <video
                                  src={editUser?.AndroidTv_Welcome_Screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else {
                              return (
                                null
                              );
                            }
                          })()}
                        </>
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef10.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef10}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef10,
                                  setSelectedFile10
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile10 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    {(() => {
                                      const fileType = getFileTypeaddvideo(selectedFile10);
                                      const fileURL = URL.createObjectURL(selectedFile10);
                                      if (fileType === 'image') {
                                        return (
                                          <img
                                            src={fileURL}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else if (fileType === 'video') {
                                        return (
                                          <video
                                            src={fileURL}
                                            controls
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}

                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(10)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">

                  <div className=" text-start mt-2">
                    <label className="m-0">Splash Screen(LG TV)</label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing />
                      ) : (
                        <>
                          {(() => {
                            const fileType = getFileType(editUser?.LG_Welcome_Screen_url);
                            if (fileType === 'image') {
                              return (
                                <img
                                  src={editUser?.LG_Welcome_Screen_url}
                                  alt="Media"
                                  className="thumbnails-images"
                                />
                              );
                            } else if (fileType === 'video') {
                              return (
                                <video
                                  src={editUser?.LG_Welcome_Screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else if (fileType === 'm3u8') {
                              return (
                                <video
                                  src={editUser?.LG_Welcome_Screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else {
                              return (
                                null
                              );
                            }
                          })()}
                        </>
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef11.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef11}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef11,
                                  setSelectedFile11
                                )
                              }
                              accept="image/*,video/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile11 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">

                                    {(() => {
                                      const fileType = getFileTypeaddvideo(selectedFile11);
                                      const fileURL = URL.createObjectURL(selectedFile11);
                                      if (fileType === 'image') {
                                        return (
                                          <img
                                            src={fileURL}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else if (fileType === 'video') {
                                        return (
                                          <video
                                            src={fileURL}
                                            controls
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}



                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(11)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Splash Screen(ROKU TV)</label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing />
                      ) : (
                        <>
                          {(() => {
                            const fileType = getFileType(editUser?.RokuTV_Welcome_Screen_url);
                            if (fileType === 'image') {
                              return (
                                <img
                                  src={editUser?.RokuTV_Welcome_Screen_url}
                                  alt="Media"
                                  className="thumbnails-images"
                                />
                              );
                            } else if (fileType === 'video') {
                              return (
                                <video
                                  src={editUser?.RokuTV_Welcome_Screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else if (fileType === 'm3u8') {
                              return (
                                <video
                                  src={editUser?.RokuTV_Welcome_Screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else {
                              return (
                                null
                              );
                            }
                          })()}
                        </>
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef12.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef12}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef12,
                                  setSelectedFile12
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile12 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">

                                    {(() => {
                                      const fileType = getFileTypeaddvideo(selectedFile12);
                                      const fileURL = URL.createObjectURL(selectedFile12);
                                      if (fileType === 'image') {
                                        return (
                                          <img
                                            src={fileURL}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else if (fileType === 'video') {
                                        return (
                                          <video
                                            src={fileURL}
                                            controls
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}

                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(12)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Splash Screen(SAMSUNG TV)</label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing />
                      ) : (
                        <>
                          {(() => {
                            const fileType = getFileType(editUser?.Samsung_Welcome_Screen_url);
                            if (fileType === 'image') {
                              return (
                                <img
                                  src={editUser?.Samsung_Welcome_Screen_url}
                                  alt="Media"
                                  className="thumbnails-images"
                                />
                              );
                            } else if (fileType === 'video') {
                              return (
                                <video
                                  src={editUser?.Samsung_Welcome_Screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else if (fileType === 'm3u8') {
                              return (
                                <video
                                  src={editUser?.Samsung_Welcome_Screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else {
                              return (
                                null
                              );
                            }
                          })()}
                        </>
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef13.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef13}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef13,
                                  setSelectedFile13
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile13 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    {(() => {
                                      const fileType = getFileTypeaddvideo(selectedFile13);
                                      const fileURL = URL.createObjectURL(selectedFile13);
                                      if (fileType === 'image') {
                                        return (
                                          <img
                                            src={fileURL}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else if (fileType === 'video') {
                                        return (
                                          <video
                                            src={fileURL}
                                            controls
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}

                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(13)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Splash Screen (FIRE TV)</label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing />
                      ) : (
                        <>
                          {(() => {
                            const fileType = getFileType(editUser?.Firetv_Welcome_Screen_url);
                            if (fileType === 'image') {
                              return (
                                <img
                                  src={editUser?.Firetv_Welcome_Screen_url}
                                  alt="Media"
                                  className="thumbnails-images"
                                />
                              );
                            } else if (fileType === 'video') {
                              return (
                                <video
                                  src={editUser?.Firetv_Welcome_Screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else if (fileType === 'm3u8') {
                              return (
                                <video
                                  src={editUser?.Firetv_Welcome_Screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else {
                              return (
                                null
                              );
                            }
                          })()}
                        </>
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef14.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef14}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef14,
                                  setSelectedFile14
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile14 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">

                                    {(() => {
                                      const fileType = getFileTypeaddvideo(selectedFile14);
                                      const fileURL = URL.createObjectURL(selectedFile14);
                                      if (fileType === 'image') {
                                        return (
                                          <img
                                            src={fileURL}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else if (fileType === 'video') {
                                        return (
                                          <video
                                            src={fileURL}
                                            controls
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}

                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(14)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>


    </>
  );
}

export default EditMobileSettings;
