import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./StorefrontSettings.css";
import RTMP_handle_Updating_URL_Settings_01 from "../../../components/Images/icons/RTMP_handle_Updating_URL_Settings_01.svg";
import Site_Settings_01 from "../../../components/Images/icons/Site_Settings_01.svg";
import PPV_Settings_01 from "../../../components/Images/icons/PPV_Settings_01.svg";
import Video_Settings_01 from "../../../components/Images/icons/Video_Settings_01.svg";
import Registration_Settings_01 from "../../../components/Images/icons/Registration_Settings_01.svg";
import Email_Settings_01 from "../../../components/Images/icons/Email_Settings_01.svg";
import Social_Network_Settings_01 from "../../../components/Images/icons/Social_Network_Settings_01.svg";
import Series_Settings_01 from "../../../components/Images/icons/Series_Settings_01.svg";
import Transcoding_Settings_01 from "../../../components/Images/icons/Transcoding_Settings_01.svg";
import Coupon_Code_Settings_01 from "../../../components/Images/icons/Coupon_Code_Settings_01.svg";
import Login_Page_Settings_01 from "../../../components/Images/icons/Login_Page_Settings_01.svg";
import Advertisment_Settings_01 from "../../../components/Images/icons/Advertisment_Settings_01.svg";
import APP_Settings_01 from "../../../components/Images/icons/APP_Settings_01.svg";
import Script_Settings_01 from "../../../components/Images/icons/Script_Settings_01.svg";
import Default_Image_Settings_01 from "../../../components/Images/icons/Default_Image_Settings_01.svg";
import Payouts_Settings_01 from "../../../components/Images/icons/Payouts_Settings_01.svg";
import Recaptcha_01 from "../../../components/Images/icons/Recaptcha_01.svg";
import TimeZone_Settings_01 from "../../../components/Images/icons/TimeZone_Settings_01.svg";
import geo_01 from "../../../components/Images/icons/geo_01.svg";
import Settings_01 from "../../../components/Images/sidebar_icon/Settings_01.svg";
import Search_setting from "../../../components/Images/sidebar_icon/Search_setting.svg";

function StorefrontSettings() {
  const [message, setMessage] = useState("");
  const [time_zone, setTime_zone] = useState([]);
  const [logo, setLogo] = useState("");
  const [favicon, setFavicon] = useState("");
  const [login_content, setLogin_content] = useState("");
  const [notification_icon, setNotification_icon] = useState("");
  const [default_horizontal_image, setDefault_horizontal_image] = useState("");
  const [default_video_image, setDefault_video_image] = useState("");
  const [watermark, setWatermark] = useState("");
  const [script, setScript] = useState({
    header_script: "",
    footer_script: "",
  });
  const [editrtmp, setEditrtmp] = useState({ rtmp_url: "", hls_url: "" });
  const [capcha, setCapcha] = useState({
    captcha_site_key: "",
    captcha_secret_key: "",
  });
  const [rtmp, setRtmp] = useState([]);
  const [rtmp_url, setRtmp_url] = useState("");
  const [hls_url, setHls_url] = useState("");
  const [app_settings, setApp_settings] = useState({
    android_url: "",
    ios_url: "",
    android_tv: "",
    status: "",
  });
  const [setting, setSetting] = useState({
    website_name: "",
    ppv_status: "",
    ads_on_videos: "",
    transcoding_access: "",
    transcoding_resolution: "",
    google_oauth_key: "",
    series_season: "",
    login_text: "",
    notification_key: "",
    login_content: "",
    discount_percentage: "",
    ppv_price: "",
    ppv_hours: "",
    coupon_status: "",
    google_tracking_id: "",
    website_description: "",
    logo_height: "",
    logo_width: "",
    login_content: "",
    coupon_status: "",
    favicon: "",
    system_email: "",
    earn_amount: "",
    signature: "",
    demo_mode: "",
    enable_https: "",
    theme: "",
    ppv_status: "",
    ppv_hours: "",
    expiry_time_started: "",
    expiry_day_notstarted: "",
    expiry_hours_notstarted: "",
    expiry_min_notstarted: "",
    watermark_right: "",
    ppv_price: "",
    discount_percentage: "",
    new_subscriber_coupon: "",
    login_text: "",
    signature: "",
    coupon_code: "",
    new_subscriber_coupon: "",
    ios_product_id: "",
    ios_plan_price: "",
    expiry_time_started: "",
    expiry_day_notstarted: "",
    expiry_hours_notstarted: "",
    expiry_min_notstarted: "",
    videos_per_page: "",
    posts_per_page: "",
    facebook_page_id: "",
    google_page_id: "",
    twitter_page_id: "",
    instagram_page_id: "",
    linkedin_page_id: "",
    whatsapp_page_id: "",
    skype_page_id: "",
    youtube_page_id: "",
    default_ads_url: "",
    cpc_advertiser: "",
    featured_pre_ad: "",
    featured_mid_ad: "",
    featured_post_ad: "",
    cpc_admin: "",
    cpv_advertiser: "",
    cpv_admin: "",
    free_registration: "",
    activation_email: "",
    premium_upgrade: "",
    access_free: "",
    enable_landing_page: "",
    payout_method: "",
  });

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.TimeZone;
        var setting = response?.data?.settings[0];
        var logo = response?.data?.settings[0]?.logo;
        var favicon = response?.data?.settings[0]?.favicon;
        var login_content = response?.data?.settings[0]?.login_content;
        var notification_icon = response?.data?.settings[0]?.notification_icon;
        var watermark = response?.data?.settings[0]?.watermark;
        var default_video_image =
          response?.data?.settings[0]?.default_video_image;
        var default_horizontal_image =
          response?.data?.settings[0]?.default_horizontal_image;
        setTime_zone(result);
        setSetting(setting);
        setFavicon(favicon);
        setLogin_content(login_content);
        setNotification_icon(notification_icon);
        setLogo(logo);
        setWatermark(watermark);
        setDefault_horizontal_image(default_horizontal_image);
        setDefault_video_image(default_video_image);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, {
        headers: headers,
      })
      .then((response) => {
        var script = response.data.script;
        var app_settings = response.data.app_settings;
        var rtmp_url = response.data.rtmp_url;
        var editrtmp = response.data.rtmp_url;
        var captchas = response.data.captchas;
        setScript(script);
        setApp_settings(app_settings);
        setRtmp(rtmp_url);
        setEditrtmp(editrtmp);
        setCapcha(captchas);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid dashboard p-2">
            <div className="iq-card">
              <div className="col-lg-12 row">
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link className="site-setting" to="/site/rtmpurl">
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className="text-center fonti">
                            <img
                              src={RTMP_handle_Updating_URL_Settings_01}
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">RTMP URL Settings</h5>
                            <p className="card-text">
                              Control the overall RTPM URLS of your website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link className="site-setting" to="/site/site-setting">
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className="text-center fonti">
                            <img src={Site_Settings_01} className="w-100" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Site Settings</h5>
                            <p className="card-text">
                              Control the overall Site Settings of your website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link className="site-setting" to="/site/ppv-setting">
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className="text-center fonti">
                            <img src={PPV_Settings_01} className="w-100" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">PPV Settings</h5>
                            <p className="card-text">
                              Control the overall PPV Settings of your website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link className="site-setting" to="/site/video-setting">
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className="text-center fonti">
                            <img src={Video_Settings_01} className="w-100" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Video Settings</h5>
                            <p className="card-text">
                              Control the overall Video Settings of your website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link
                    className="site-setting"
                    to="/site/registration-setting"
                  >
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className=" text-center fonti">
                            <img
                              src={Registration_Settings_01}
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Registration Settings</h5>
                            <p className="card-text">
                              Control the overall Registration Settings of your
                              website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link className="site-setting" to="/site/email-setting">
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className=" text-center fonti">
                            <img src={Email_Settings_01} className="w-100" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">System Settings</h5>
                            <p className="card-text">
                              Control the overall System Settings of your
                              website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link
                    className="site-setting"
                    to="/site/social-network-setting"
                  >
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className=" text-center fonti">
                            <img
                              src={Social_Network_Settings_01}
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Social Network Settings</h5>
                            <p className="card-text">
                              Control the overall Social Network Settings of
                              your website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link
                    className="site-setting"
                    to="/site/series-setting"
                  >
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className=" text-center fonti">
                            <img src={Series_Settings_01} className="w-100" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Series Settings</h5>
                            <p className="card-text">
                              Control the overall Series Settings of your
                              website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link
                    className="site-setting"
                    to="/site/transcoding-setting"
                  >
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className=" text-center fonti">
                            <img
                              src={Transcoding_Settings_01}
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Transcoding Settings</h5>
                            <p className="card-text">
                              Control the overall Transcoding Settings of your
                              website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link
                    className="site-setting"
                    to="/site/coupon-code-setting"
                  >
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className=" text-center fonti">
                            <img
                              src={Coupon_Code_Settings_01}
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Coupon Code Settings</h5>
                            <p className="card-text">
                              Control the overall Coupon Code Settings of your
                              website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link
                    className="site-setting"
                    to="/site/loginpage-setting"
                  >
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className=" text-center fonti">
                            <img
                              src={Login_Page_Settings_01}
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Login Page Settings</h5>
                            <p className="card-text">
                              Control the overall Login Page Settings of your
                              website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link
                    className="site-setting"
                    to="/site/advertisement-setting"
                  >
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className="text-center fonti">
                            <img
                              src={Advertisment_Settings_01}
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Advertisment Settings</h5>
                            <p className="card-text">
                              Control the overall Advertisment Settings of your
                              website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link className="site-setting" to="/site/app-setting">
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className="text-center fonti">
                            <img src={APP_Settings_01} className="w-100" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">APP Settings</h5>
                            <p className="card-text">
                              Control the overall APP Settings of your website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link
                    className="site-setting"
                    to="/site/script-setting"
                  >
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className="text-center fonti">
                            <img src={Script_Settings_01} className="w-100" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Script Settings</h5>
                            <p className="card-text">
                              Control the overall RTPM URLS of your website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link
                    className="site-setting"
                    to="/site/default-image-setting"
                  >
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className=" text-center fonti">
                            <img
                              src={Default_Image_Settings_01}
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Default Image Settings</h5>
                            <p className="card-text">
                              Control the overall Default Image of your website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link
                    className="site-setting"
                    to="/site/payouts-setting"
                  >
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className=" text-center fonti">
                            <img src={Payouts_Settings_01} className="w-100" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Payouts Settings</h5>
                            <p className="card-text">
                              Control the overall Payouts Settings of your
                              website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link
                    className="site-setting"
                    to="/site/recaptcha-setting"
                  >
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className=" text-center fonti">
                            <img src={Recaptcha_01} className="w-100" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Recaptcha Settings</h5>
                            <p className="card-text">
                              Control the overall Recaptcha Settings of your
                              website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link
                    className="site-setting"
                    to="/site/timezone-setting"
                  >
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className=" text-center fonti">
                            <img src={TimeZone_Settings_01} className="w-100" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">TimeZone Settings</h5>
                            <p className="card-text">
                              Control the overall TimeZone Settings of your
                              website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link
                    className="site-setting"
                    to="/site/search-setting"
                  >
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className=" text-center fonti">
                            <img src={Search_setting} className="w-100" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Search Settings</h5>
                            <p className="card-text">
                              Control the overall Search Settings of your
                              website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                  <Link
                    className="site-setting"
                    to="/geo-fencing-setting"
                  >
                    <div className="sitecard p-2">
                      <div className="row">
                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                          <div className=" text-center fonti">
                            <img src={geo_01} className="w-100" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="card-body p-2">
                            <h5 className="card-title">Geo Fencing Settings</h5>
                            <p className="card-text">
                              Control the overall Fencing Settings of your
                              website
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default StorefrontSettings;
