// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropZoneAds {
    width: 100%;
    height: 300px;
}

.dotted {
    border-style: dotted;
}

.dropZoneUpload {
    top: 0;
    left: 0;
}

.dropText {
    inset: 0;
    margin: auto;
    width: 100px;
    height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Ads_Categories/Ads_Upload/AdsUpload.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,MAAM;IACN,OAAO;AACX;;AAEA;IACI,QAAQ;IACR,YAAY;IACZ,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".dropZoneAds {\n    width: 100%;\n    height: 300px;\n}\n\n.dotted {\n    border-style: dotted;\n}\n\n.dropZoneUpload {\n    top: 0;\n    left: 0;\n}\n\n.dropText {\n    inset: 0;\n    margin: auto;\n    width: 100px;\n    height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
