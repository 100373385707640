import React, { useEffect, useRef, useState } from "react";
import "./AddContentPartner.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function AddContentPartner(props) {
  const usernameRef = useRef(null);
  const emailRef = useRef(null);
  const roleRef = useRef(null);
  const imgRef = useRef(null);
  const mobileRef = useRef(null);
  const navigate = useNavigate();

  const [role, setRole] = useState([]);
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [fileName, setFileName] = useState(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [numberValue, setNumberValue] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [switchTap, setSwitchTap] = useState(0);
  const [chequeFileName, setChequeFileName] = useState(null);
  const [chequePreviewImage, setChequePreviewImage] = useState(null);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    const name = e.target.name;
    const sanitizedInput = input.replace(/\D/g, "").slice(0, 10);
    setNumberValue(sanitizedInput);
    if (input.length == 10) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const fetchRole = () => {
    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/content-partner/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.ContentPartnerRoles;
        setRole(result);
      })
      .catch((error) => console.log(error));
  };
  useEffect(async () => {
    fetchRole();
  }, []);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setFileName(newFile);
        setPreviewImage(reader.result);
        setErrors((prev) => ({ ...prev, image: "" }));
      };
      reader.readAsDataURL(newFile);
    } else {
      setErrors((prev) => ({ ...prev, image: "" }));
    }
  };
  const handleAccountFileChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setChequeFileName(newFile);
        setChequePreviewImage(reader.result);
      };
      reader.readAsDataURL(newFile);
    } else {
      setErrors((prev) => ({ ...prev, image: "" }));
    }
  };
  const handleClearFile = () => {
    setFileName(null);
    setPreviewImage(null);
    if (imgRef.current) {
      imgRef.current.value = "";
    }
  };
  const handleClearCheque = () => {
    setChequeFileName(null);
    setChequePreviewImage(null);
  };

  const newErrors = {};
  const validate = () => {
    if (!fileName) {
      newErrors.image = "Image Required";
      imgRef.current.focus();
    }
    if (!inputs.role_id) {
      newErrors.role_id = "Role name is required";
      roleRef.current.focus();
    }
    var pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!inputs.email) {
      newErrors.email = "Email is required";
      emailRef.current.focus();
    } else {
      if (!pattern.test(inputs.email)) {
        newErrors.email = "Invaild Email Address";
        emailRef.current.focus();
      }
    }
    if (!inputs.username) {
      newErrors.username = "Username is required";
      usernameRef.current.focus();
    }
    if (numberValue.length === 1) {
      newErrors.mobile_number = "Enter a valid number max 10 digits";
      mobileRef.current.focus();
    } else if (numberValue.length > 1 && numberValue.length <= 9) {
      newErrors.mobile_number = "Enter a valid number max 10 digits";
      mobileRef.current.focus();
    } else {
      console.log("fine");
    }
    setErrors(newErrors);
  };
  const handleValidate = () => {
    validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setSwitchTap(1);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    validate();
    let addContentPartnerStatus = false;
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setSwitchTap(0);
    } else {
      try {
        setProcessing(true);
        setShowOverlay(true);
        const formDatas = new FormData();

        for (const key in inputs) {
          formDatas.append(key, inputs[key]);
        }
        formDatas.append("mobile_number", numberValue);
        const response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/content-partner/store`,
          formDatas,
          { headers }
        );
        let result = response?.data;
        if (result.status) {
          let formDataForImage = new FormData();
          formDataForImage.append(
            "ContentPartner_id",
            result.ContentPartnerUserID
          );
          formDataForImage.append("picture", fileName);
          formDataForImage.append("cancelled_cheque", chequeFileName);
          const imageResponse = await axios.post(
            `${process.env.REACT_APP_Baseurl}/admin/Image-upload-content`,
            formDataForImage,
            { headers }
          );
          const imageResult = imageResponse?.data;
          if (imageResult.status && result.status) {
            addContentPartnerStatus = true;
            navigate("/allmoderator");
          }
          if (addContentPartnerStatus) {
            props?.setApiresponsealert(result);
            props?.setShowalert(true);
            setProcessing(false);
            setShowOverlay(false);
          }
        } else {
          props?.setApiresponsealert(result);
          props?.setShowalert(true);
          setSwitchTap(0);
          setProcessing(false);
          setShowOverlay(false);
        }
      } catch (err) {
        setProcessing(false);
        setShowOverlay(false);
        var errorMessage = err.response.data.message;
        props?.setApiresponsealert({ status: false, message: errorMessage });
        props?.setShowalert(true);
      }
    }
  };

  return (
    <>
      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="py-3">
            <h4>Add Moderator Users</h4>
          </div>
          <div className="iq-card p-2 mt-3">
            <div className="p-3">
              <div className="d-flex  gap-5 mb-5">
                <button
                  className={`tabHeading p-0   ${switchTap === 0 && "active"}`}
                  onClick={() => {
                    setSwitchTap(0);
                  }}
                >
                  User
                </button>
                <button
                  type="submit"
                  className={` tabHeading p-0 ${switchTap === 1 && "active"}`}
                  onClick={handleValidate}
                >
                  Account
                </button>
              </div>{" "}
              <form id="Moderator_form" onSubmit={handleSubmit}>
                <>
                  <div
                    className={` row justify-content-between p-3 ${switchTap === 0 ? "d-flex" : "d-none"
                      }`}
                  >
                    <div className="col-md-6">
                      <div className="form-group row position-relative mb-5">
                        <label
                          for="name"
                          className=" col-form-label text-md-right p-0"
                        >
                          User Name
                          <span className="mandatory">*</span>
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="rs-input form-control-lg"
                          name="username"
                          autoComplete="username"
                          autoFocus
                          onChange={handleChange}
                          ref={usernameRef}
                        />

                        {errors.username && (
                          <span className="errorred errorredspan p-0">
                            {errors.username}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group row position-relative mb-5">
                        <label
                          for="email"
                          className=" col-form-label text-md-right p-0"
                        >
                          E-Mail Address
                          <span className="mandatory">*</span>
                        </label>
                        <input
                          id="email"
                          type="email"
                          className="rs-input form-control-lg"
                          name="email"
                          autoComplete="email"
                          onChange={handleChange}
                          ref={emailRef}
                        />
                        {errors.email && (
                          <span className="errorred errorredspan p-0">
                            {errors.email}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group row position-relative mb-5">
                        <label
                          for="mobile_number"
                          className=" col-form-label text-md-right p-0"
                        >
                          Mobile Number
                        </label>
                        <input
                          id="mobile_number"
                          type="text"
                          className="rs-input form-control-lg"
                          name="mobile_number"
                          autoComplete="email"
                          value={numberValue}
                          onChange={handleInputChange}
                          ref={mobileRef}
                        />
                        {errors.mobile_number && (
                          <span className="errorred errorredspan p-0">
                            {errors.mobile_number}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row position-relative mb-5">
                        <label
                          for="role_id"
                          className=" col-form-label text-md-right p-0"
                        >
                          User Roles
                          <span className="mandatory">*</span>
                        </label>
                        <select
                          className="form-select"
                          id="role_id"
                          name="role_id"
                          onChange={handleChange}
                          value={inputs.role_id}
                          ref={roleRef}
                        >
                          <option value="">Select Roles</option>
                          {role?.map((rolename, index) => (
                            <option
                              key={"option" + index + 1}
                              value={rolename.id}
                            >
                              {rolename.role_name}
                            </option>
                          ))}
                        </select>
                        {errors.role_id && (
                          <span className="errorred errorredspan p-0">
                            {errors.role_id}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label
                        for="picture"
                        className=" col-form-label text-md-right p-0"
                      >
                        Picture
                        <span className="mandatory">*</span>
                      </label>
                      <div className="d-flex flex-wrap justify-content-between gap-2">
                        <div className="col-sm-5 col-md-12 col-lg-5 col-12 position-relative pb-2">
                          <div className="imagedrop  mt-1 p-1">
                            <input
                              id="picture"
                              type="file"
                              className="form-control opacity-0 h-100 p-0 m-0 "
                              name="picture"
                              accept="image/*"
                              onChange={(event) => handleFileChange(event)}
                              ref={imgRef}
                              key={fileName ? fileName : "inputKey"}
                            />
                            <label type="button" className="position-absolute">
                              <CameraRetroIcon />
                            </label>
                          </div>

                          <p className="text" id="error_picture"></p>
                          {errors.image && (
                            <span className="errorred errorredspan p-0">
                              {errors.image}
                            </span>
                          )}
                        </div>
                        {fileName && (
                          <div className="col-sm-5 col-12 col-md-12 col-lg-5">
                            <div
                              className="imagedrop  dropzone mt-1 p-1"
                              style={{
                                backgroundImage: `url(${previewImage && previewImage
                                  })`,
                              }}
                            >
                              {fileName && (
                                <button
                                  type="button"
                                  className="btn btn-close"
                                  onClick={handleClearFile}
                                ></button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <hr className="mt-2" />
                    <div className="form-group row mb-0">
                      <div className="col-md-12 col-12 ">
                        <button
                          type="submit"
                          className="btn btn-primary d-block ms-auto fw-bolder"
                        >
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </>
                <>
                  <div
                    className={`row ${switchTap === 0 ? "d-none" : "d-flex"}`}
                  >
                    <div className="col-md-6 mb-4 position-relative pb-2">
                      <label className="m-0" for="bank_name">
                        Bank Name
                      </label>
                      <input
                        type="text"
                        id="bank_name"
                        name="bank_name"
                        autoComplete="bank_name"
                        value={inputs?.bank_name}
                        className="form-control "
                        placeholder="bank_name"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 mb-4 position-relative pb-2">
                      <label className="m-0">Branch Name</label>
                      <input
                        type="text"
                        id="branch_name"
                        name="branch_name"
                        value={inputs?.branch_name}
                        className="form-control"
                        autoComplete="branch_name"
                        placeholder="branch_name"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 mb-4 position-relative pb-2 ">
                      <label className="m-0" for="account_number">
                        Account Number
                      </label>
                      <input
                        type="text"
                        id="account_number"
                        name="account_number"
                        value={inputs?.account_number}
                        className="form-control"
                        autoComplete="account_number"
                        placeholder="account_number"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 mb-4 position-relative pb-2">
                      <label className="m-0">IFSC Code</label>
                      <input
                        type="text"
                        id="IFSC_Code"
                        name="IFSC_Code"
                        value={inputs?.IFSC_Code}
                        className="form-control"
                        autoComplete="IFSC_Code"
                        placeholder="IFSC_Code"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-6 mb-4 position-relative pb-2">
                      <label className="m-0">Upi Id</label>
                      <input
                        type="text"
                        id="upi_id"
                        name="upi_id"
                        value={inputs?.upi_id}
                        autoComplete="upi_id"
                        className="form-control"
                        placeholder="upi_id"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 mb-4 position-relative pb-2">
                      <label className="m-0">Upi Mobile Number</label>
                      <input
                        type="text"
                        id="upi_mobile_number"
                        name="upi_mobile_number"
                        value={inputs?.upi_mobile_number}
                        className="form-control"
                        placeholder="upi_mobile_number"
                        autoComplete="upi_mobile_number"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="form-group position-relative">
                        <label className="m-0">Cancelled Cheque</label>
                        {inputs?.cancelled_cheque && (
                          <img
                            src={inputs?.cancelled_cheque}
                            className="editFormImage"
                          />
                        )}

                        <div className="d-flex flex-wrap justify-content-between gap-2">
                          <div className="col-sm-5 col-md-12 col-lg-5 col-12 position-relative">
                            <div className="imagedrop  mt-1 p-1">
                              <input
                                id="cancelled_cheque"
                                name="cancelled_cheque"
                                type="file"
                                className="form-control opacity-0 h-100 p-0 m-0 "
                                accept="image/*"
                                onChange={(event) =>
                                  handleAccountFileChange(event)
                                }
                                key={
                                  chequeFileName ? chequeFileName : "inputKey"
                                }
                              />
                              <label
                                type="button"
                                className="position-absolute"
                              >
                                <CameraRetroIcon />
                              </label>
                            </div>
                          </div>

                          {chequeFileName && (
                            <div className="col-sm-5 col-12 col-md-12 col-lg-5">
                              <div
                                className="imagedrop  dropzone mt-1 p-1"
                                style={{
                                  backgroundImage: `url(${chequePreviewImage && chequePreviewImage
                                    })`,
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-close"
                                  onClick={handleClearCheque}
                                ></button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr className="mt-2" />
                    <div className="row mt-3">
                      <div className="col-12 text-end">
                        <input
                          type="submit"
                          value="Register"
                          className="btn btn-primary fw-bolder"
                        />
                      </div>
                    </div>
                  </div>
                </>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddContentPartner;
