import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import circle from "../../../components/Images/circle.svg";
import JoditEditor from "jodit-react";
import "./AddLandingPages.css";

const AddLandingPages = (props) => {
  const [body, setBody] = useState("");
  const [css, setCss] = useState("");
  const [bootstrap, setBootstrap] = useState("");
  const [script, setScript] = useState("");
  const [isHeader, setIsHeader] = useState(false);
  const [isFooter, setIsFooter] = useState(false);
  const [editUser, setEditUser] = useState({
    title: "",
  });

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const getFirstNElements = (arr, n) => {
    return arr.slice(0, n);
  };

  // console.log('artist_id',artist_id)
  // const [data, setData] = useState([]);

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };
  const handleAutoFillChangetitle = (event) => {
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const contentFieldChanaged = (data) => {
    setBody(data);
  };

  const checkHandler = () => {
    setIsHeader(!isHeader);
  };
  const checkHandler1 = () => {
    setIsFooter(!isFooter);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessagecontent, setValidationMessagecontent] = useState("");
  const [isValidationHiddencontent, setIsValidationHiddencontent] =
    useState(true);

  const titleInputRef = useRef(null);
  const contentInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function savePage() {
    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (body === "") {
      setValidationMessagecontent("Page Content cannot be empty.");
      setIsValidationHiddencontent(false);
      focusInputRef = contentInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const formData = new FormData();

    formData.append("title", editUser?.title);
    formData.append("slug", autoFillValuetitle);
    formData.append("custom_css", css);
    formData.append("script_content", script);
    formData.append("bootstrap_link", bootstrap);
    formData.append("header", isHeader === true ? 1 : 0);
    formData.append("footer", isFooter === true ? 1 : 0);
    formData.append("section_1", body);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/landing-page/store`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;
        resultapi = response.data;
      } else if (response.data.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response.data;
      }
    } catch (error) {}

    if (firstApiStatus) {
      setValidationMessagetitle("");
      setValidationMessagecontent("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        navigate("/landing-page/index");
      }, 3000);
    } else if (resultapifalse) {
      props.setApiresponsealert(resultapifalse);
      props.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setIsValidationHiddencontent(false);
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Add Landing Page</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={savePage} className="btn btn-primary">
                    Save Page
                  </button>
                </div>
              </div>
            </div>
            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Page</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Title<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.title}
                        onBlur={handleBlurtitle}
                        onKeyDown={handleInputKeyDowntitle}
                        placeholder="Title"
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Slug</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg"
                        value={autoFillValuetitle}
                        onChange={handleAutoFillChangetitle}
                        placeholder="slug"
                      />
                    </div>
                  </div>

                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">
                        Page Content<span className="mandatory">*</span>
                      </label>

                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input"
                          value={body}
                          onChange={(newContent) =>
                            contentFieldChanaged(newContent)
                          }
                          ref={contentInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddencontent && (
                          <p>{validationMessagecontent}</p>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={savePage} className="btn btn-primary">
                    Save Page
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Contents</h5>
                  <hr></hr>

                  <div className="text-start">
                    <label className="m-0">
                      Custom CSS{" "}
                      <div className="tooltip-container">
                        <a className="iq-bg-warning" href="#">
                          <img
                            src={circle}
                            alt="flogo"
                            width={16}
                            height={16}
                          />
                          <span className="tooltip-text">
                            Please add custom CSS as <br /> &lt;style&gt; .....
                            &lt;/style&gt;
                          </span>
                        </a>
                      </div>
                    </label>

                    <div className="mt-2">
                      <textarea
                        className="rs-input form-control-lg"
                        // id="exampleFormControlTextarea1"
                        rows="3"
                        onChange={(e) => setCss(e.target.value)}
                      ></textarea>
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Bootstrap & Script Link{" "}
                      <div className="tooltip-container">
                        <a className="iq-bg-warning" href="#">
                          <img
                            src={circle}
                            alt="flogo"
                            width={16}
                            height={16}
                          />
                          <span className="tooltip-text">
                            Please add Bootstrap or <br /> Script Link as <br />{" "}
                            &lt;links&gt; ..... &lt;links&gt; or
                            <br /> &lt;scripts&gt; .... &lt;scripts&gt;
                          </span>
                        </a>
                      </div>
                    </label>

                    <div className="mt-2">
                      <textarea
                        className="rs-input form-control-lg"
                        id="exampleFormControlTextarea2"
                        rows="3"
                        onChange={(e) => setBootstrap(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Custom Script Content{" "}
                      <div className="tooltip-container">
                        <a className="iq-bg-warning" href="#">
                          <img
                            src={circle}
                            alt="flogo"
                            width={16}
                            height={16}
                          />
                          <span className="tooltip-text">
                            Please add Script Content <br /> Link as
                            &lt;links&gt; ..... &lt;links&gt;{" "}
                          </span>
                        </a>
                      </div>
                    </label>

                    <div className="mt-2">
                      <textarea
                        className="rs-input form-control-lg"
                        id="exampleFormControlTextarea3"
                        rows="3"
                        onChange={(e) => setScript(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Status Settings</h5>
                  <hr></hr>

                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-body d-flex justify-content-between">
                      <label className="">Enable this page Header </label>
                      <label className="switch">
                        <input
                          name="header"
                          // value={item.id}
                          // id="checkbox"
                          type="checkbox"
                          checked={isHeader}
                          onChange={checkHandler}
                        />
                        <span
                          className="slider round"
                          name="header"
                          // value={item.id}
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div className="panel panel-primary mt-2" data-collapsed="0">
                    <div className="panel-body d-flex justify-content-between">
                      <label className="">Enable this page Footer </label>
                      <label className="switch">
                        <input
                          name="footer"
                          // value={item.id}
                          type="checkbox"
                          checked={isFooter}
                          onChange={checkHandler1}
                        />
                        <span
                          className="slider round"
                          name="footer"
                          // value={item.id}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

     
    </>
  );
};

export default AddLandingPages;
