import React, {useState} from 'react'
import JoditEditor from "jodit-react";
import { Link } from 'react-router-dom';



const Add_pages = () => {

    const [content, setContent] = useState('');
  return (
    <div>
        <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="iq-card">
                  <div id="admin-container">
                    <div className="admin-section-title">
                      <h5>
                        <i className="entypo-plus"></i> Add New Page
                      </h5>
                    </div>
                    <hr />
                    <div className="row mt-4">


                      <div className="col-sm-6 col-sm-8 ">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Title</label>
                            </div>{" "}
                            <div className="panel-options">
                              {" "}
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>{" "}
                            </div>
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Add the page title in the textbox below:
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              id="title"
                              placeholder="Page Title"
                            //   onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3  col-sm-4f">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>SEO URL Slug</label>
                            </div>{" "}
                            <div className="panel-options">
                              {" "}
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>{" "}
                            </div>
                          </div>
                          <div className="panel-body">
                            <p className="p1">(example. /page/slug-name)</p>
                            <input
                              type="text"
                              className="form-control"
                              name="slug"
                              id="slug"
                              placeholder="slug-name"
                            //   onChange={(e) => setSlug(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                
                    </div>

                    <div className=" mt-3 form-group">
                      <label>Banner:</label>
                      <input
                        type="file"
                        multiple="true"
                        className="form-control"
                        name="banner"
                        id="banner"
                        // onChange={(e) => setImage(e.target.files[0])}
                      />
                    </div>

                    <div className="panel panel-primary" data-collapsed="0">
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label>Page Content</label>
                        </div>{" "}
                        <div className="panel-options">
                          {" "}
                          <Link to="#" data-rel="collapse">
                            <i className="entypo-down-open"></i>
                          </Link>{" "}
                        </div>
                      </div>
                      <div className="panel-body mt-3">
                        {/* <textarea className="form-control" name="body" id="summary-ckeditor"
													onChange={e => setBody(e.target.value)}></textareLink> */}
                        <JoditEditor
                        //   ref={editor}
                        //   value={body.content}
                          onChange={(newContent) =>
                            setContent(newContent)
                          }
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4 mt-3">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title">
                              <label> Status Settings</label>
                            </div>{" "}
                            <div className="panel-options">
                              {" "}
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>{" "}
                            </div>
                          </div>
                          <div className="panel-body">
                            <div>
                              <label for="active">Is this page Active:</label>
                             
                              <label className="switch">
                                <input
                                  name="active"
                                //   onChange={handleInput}
                                //   defaultChecked={
                                //     active.active == 1 ? true : false
                                //   }
                                //   checked={active.active == 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="active"
                                //   onChange={handleInput}
                                //   value={active.active == 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3">
                          <input
                            type="hidden"
                            name="user_id"
                            id="user_id"
                            value=""
                          />
                          <input type="hidden" id="id" name="id" value="" />
                        </div>
                      </div>
                      <div className="mt-2 p-2">
                        <input
                          type="submit"
                          value="Add New Page"
                          className="btn btn-primary pull-right"
                        //   onClick={AddPages}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Add_pages