import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function AddManageAge(props) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [slug, setSlug] = useState("");
  const [age, setAge] = useState("");
  const [active, setActive] = useState("");

  const access_token = localStorage.getItem("access_token");

  const [editUser, setEditUser] = useState({
    age: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageage("");
    setIsValidationHiddenage(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setActive({ ...active, [e.target.name]: 1 });
    } else {
      setActive({ ...active, [e.target.name]: 0 });
    }
  };

  const [validationMessageage, setValidationMessageage] = useState("");
  const [isValidationHiddenage, setIsValidationHiddenage] = useState(true);

  const ageInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function saveage() {
    let focusInputRef = null;

    if (editUser?.age === "") {
      setValidationMessageage("Age cannot be empty.");
      setIsValidationHiddenage(false);
      focusInputRef = ageInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const editInputvalue = active.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const formData = new FormData();

    formData.append("id", id);
    formData.append("slug", slug);
    formData.append("age", editUser?.age);
    const aactivedatadata = Number.isNaN(parseInt(aactivedata, 10)) ? 0 : parseInt(aactivedata, 10);
    formData.append("active", aactivedatadata);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/age-category/store`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;

        resultapi = response.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      setValidationMessageage("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        navigate("/age/index");
      }, 3000);
    } else {
      if (resultapi && resultapi.status === false) {
        var resultError = resultapi;
        console.log(resultError);
        props.setApiresponsealert(resultError);
        setIsValidationHiddenage(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  // async function saveage() {
  //   console.log("append data", id, slug, age, active);

  //   const formData = new FormData();

  //   formData.append("id", id);
  //   formData.append("slug", slug);
  //   formData.append("age", age);
  //   formData.append("active", active);

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };

  //   await axios
  //     .post(
  //       `${process.env.REACT_APP_Baseurl}/admin/age-category/store`,
  //       formData,
  //       { headers: headers }
  //     )
  //     .then((response) => {
  //       // console.log('api' , response);
  //       if (response.data.status === true) {
  //         var result = response.data;
  //         // console.log('result', response);
  //         navigate("/age/index");
  //         alert("Added successfully  !");
  //       } else {
  //         console.log("Error");
  //         alert("Enter Correct Details");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Error");
  //       alert("Enter Correct Details");
  //     });
  // }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Add Age Category</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={saveage} className="btn btn-primary">
                    Save Age
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Age</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Age<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="age"
                        name="age"
                        placeholder="Enter Name"
                        onChange={handleInput}
                        value={editUser?.age}
                        ref={ageInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenage && <p>{validationMessageage}</p>}
                    </span>
                  </div>
                </div>

                {/* <div className="text-start mb-3">
                  <button onClick={saveage} className="btn btn-primary">
                    Save Age
                  </button>
                </div> */}
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Status Setting</h5>
                  <hr></hr>

                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-body d-flex justify-content-between">
                      <label className="">Status </label>
                      <label className="switch">
                        <input
                          name="active"
                          onChange={handleInput}
                          defaultChecked={active?.active === 1 ? true : false}
                          checked={active?.active === 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="active"
                          onChange={handleInput}
                          value={active?.active === 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="container-fluid p-0">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row p-3">
                    <div className="col-md-4">
                      <h4>
                        <i className="entypo-archive"></i>Add Age Category
                      </h4>
                    </div>
                    <hr />
                  </div>

                  <div className=" p-3 ">
                    <h5 className="">Age: </h5>
                    <div>
                      <input
                        type="text"
                        name="age"
                        onChange={(e) => setAge(e.target.value)}
                        className="form-control"
                        placeholder="age"
                      />
                    </div>
                  </div>
                  <div className=" p-3 ">
                    <h5 className="">Status: </h5>

                    <div name="active">
                      <input
                        type="radio"
                        id="active"
                        name="active"
                        value="1"
                        onChange={(e) => setActive(e.target.value)}
                      />{" "}
                      Active &nbsp;&nbsp;
                      <input
                        type="radio"
                        id="active"
                        name="active"
                        value="0"
                        onChange={(e) => setActive(e.target.value)}
                      />{" "}
                      In Active
                    </div>
                  </div>

                  <div className="text-end p-3">
                    <button className="btn btn-primary " onClick={saveage}>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default AddManageAge;
