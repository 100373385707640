import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import backarrow from "../../../../components/Images/backarrow.svg";

function Advertisement_Setting(props) {

  const [setting, setSetting] = useState({
    // Advertisement_Setting
    ads_on_videos: "",
    cpc_advertiser: "",
    featured_pre_ad: "",
    featured_mid_ad: "",
    featured_post_ad: "",
    cpc_admin: "",
    cpv_advertiser: "",
    cpv_admin: "",
    default_ads_url: "",
    ads_play_unlimited_period: "",
    ads_on_livestream: "",
    ads_on_audios: "",
    ads_on_episodes: "",
    // Advertisement_Setting
    //coupon code
    new_subscriber_coupon: "",
    discount_percentage: "",
    coupon_code: "",
    //coupon code
    //email setting
    google_oauth_key: "",
    coupon_status: "",
    google_tracking_id: "",
    system_email: "",
    system_phone_number: "",
    system_address: "",
    //email setting
    //login setting
    notification_icon: "",
    notification_key: "",
    login_text: "",
    login_content: "",
    signature: "",
    //login setting
    //geo fencing
    id: "",
    geofencing: "",
    geofencing_status: "",
    //geo fencing
    // payout setting
    payout_method: "",
    //payout setting
    //ppv setting
    ppv_status: "",
    enable_ppv_button: "",
    ppv_rent: "",
    ppv_price: "",
    ppv_hours: "",
    ios_product_id: "",
    ios_plan_price: "",
    expiry_time_started: "",
    expiry_day_notstarted: "",
    expiry_hours_notstarted: "",
    expiry_min_notstarted: "",
    PPV_Individual_Contents: "",
    //ppv setting
    // capcha setting
    captcha_site_key: "",
    captcha_secret_key: "",
    captcha: "",
    //capcha setting
    //registration settings
    free_registration: "",
    activation_email: "",
    premium_upgrade: "",
    access_free: "",
    enable_landing_page: "",
    //registration settings
    //search settings
    search_title_status: "",
    search_category_status: "",
    search_tags_status: "",
    search_description_status: "",
    search_details_status: "",
    //search settings
    //series settings
    series_season: "",
    series_networks_status: "",
    //series settings
    //site settings
    website_name: "",
    website_description: "",
    logo_height: "",
    logo_width: "",
    login_content: "",
    favicon: "",
    logo: "",
    //site settings
    //social network settings
    facebook_page_id: "",
    google_page_id: "",
    twitter_page_id: "",
    instagram_page_id: "",
    linkedin_page_id: "",
    whatsapp_page_id: "",
    skype_page_id: "",
    youtube_page_id: "",
    email_page_id: "",
    tiktok_page_id: "",
    //social network settings
    //video settings
    videos_per_page: "",
    posts_per_page: "",
    show_description: "",
    show_Links_and_details: "",
    show_genre: "",
    show_languages: "",
    show_subtitle: "",
    show_artist: "",
    show_recommended_videos: "",
    show_views: "",
    videos_expiry_status: "",
    epg_status: "",
    //video settings
    earn_amount: "",
    video_clip_enable: "",
    enable_ppv_rent: "",
  });

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, {
        headers: headers,
      })
      .then((response) => {
        var setting = response?.data?.settings[0];
        setSetting(setting);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setSetting((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagedefault_ads_url("");
    setIsValidationHiddendefault_ads_url(true);

    setValidationMessagefeatured_pre_ad("");
    setIsValidationHiddenfeatured_pre_ad(true);

    setValidationMessagefeatured_mid_ad("");
    setIsValidationHiddenfeatured_mid_ad(true);

    setValidationMessagefeatured_post_ad("");
    setIsValidationHiddenfeatured_post_ad(true);

    setValidationMessagecpc_advertiser("");
    setIsValidationHiddencpc_advertiser(true);

    setValidationMessagecpc_admin("");
    setIsValidationHiddencpc_admin(true);

    setValidationMessagecpv_advertiser("");
    setIsValidationHiddencpv_advertiser(true);

    setValidationMessagecpv_admin("");
    setIsValidationHiddencpv_admin(true);

    setSetting({ ...setting, [e.target.name]: e.target.value });
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setSetting({ ...setting, [e.target.name]: 1 });
    } else {
      setSetting({ ...setting, [e.target.name]: 0 });
    }
  };

  const [
    validationMessagedefault_ads_url,
    setValidationMessagedefault_ads_url,
  ] = useState("");
  const [
    isValidationHiddendefault_ads_url,
    setIsValidationHiddendefault_ads_url,
  ] = useState(true);

  const [
    validationMessagefeatured_pre_ad,
    setValidationMessagefeatured_pre_ad,
  ] = useState("");
  const [
    isValidationHiddenfeatured_pre_ad,
    setIsValidationHiddenfeatured_pre_ad,
  ] = useState(true);

  const [
    validationMessagefeatured_mid_ad,
    setValidationMessagefeatured_mid_ad,
  ] = useState("");
  const [
    isValidationHiddenfeatured_mid_ad,
    setIsValidationHiddenfeatured_mid_ad,
  ] = useState(true);

  const [
    validationMessagefeatured_post_ad,
    setValidationMessagefeatured_post_ad,
  ] = useState("");
  const [
    isValidationHiddenfeatured_post_ad,
    setIsValidationHiddenfeatured_post_ad,
  ] = useState(true);

  const [validationMessagecpc_advertiser, setValidationMessagecpc_advertiser] =
    useState("");
  const [
    isValidationHiddencpc_advertiser,
    setIsValidationHiddencpc_advertiser,
  ] = useState(true);

  const [validationMessagecpc_admin, setValidationMessagecpc_admin] =
    useState("");
  const [isValidationHiddencpc_admin, setIsValidationHiddencpc_admin] =
    useState(true);

  const [validationMessagecpv_advertiser, setValidationMessagecpv_advertiser] =
    useState("");
  const [
    isValidationHiddencpv_advertiser,
    setIsValidationHiddencpv_advertiser,
  ] = useState(true);

  const [validationMessagecpv_admin, setValidationMessagecpv_admin] =
    useState("");
  const [isValidationHiddencpv_admin, setIsValidationHiddencpv_admin] =
    useState(true);

  const default_ads_urlInputRef = useRef(null);
  const featured_pre_adInputRef = useRef(null);
  const featured_mid_adInputRef = useRef(null);
  const featured_post_adInputRef = useRef(null);
  const cpc_advertiserInputRef = useRef(null);
  const cpc_adminInputRef = useRef(null);
  const cpv_advertiserInputRef = useRef(null);
  const cpv_adminInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    let focusInputRef = null;

    if (setting?.default_ads_url === "") {
      setValidationMessagedefault_ads_url("Default Ads Url cannot be empty.");
      setIsValidationHiddendefault_ads_url(false);
      focusInputRef = default_ads_urlInputRef;
    }
    if (setting?.featured_pre_ad === "") {
      setValidationMessagefeatured_pre_ad("Featured Pre Ad cannot be empty.");
      setIsValidationHiddenfeatured_pre_ad(false);
      focusInputRef = featured_pre_adInputRef;
    }
    if (setting?.featured_mid_ad === "") {
      setValidationMessagefeatured_mid_ad("Featured Mid Ad cannot be empty.");
      setIsValidationHiddenfeatured_mid_ad(false);
      focusInputRef = featured_mid_adInputRef;
    }
    if (setting?.featured_post_ad === "") {
      setValidationMessagefeatured_post_ad("Featured Post Ad cannot be empty.");
      setIsValidationHiddenfeatured_post_ad(false);
      focusInputRef = featured_post_adInputRef;
    }
    if (setting?.cpc_advertiser === "") {
      setValidationMessagecpc_advertiser("CPC Advertiser Cannot be empty.");
      setIsValidationHiddencpc_advertiser(false);
      focusInputRef = cpc_advertiserInputRef;
    }
    if (setting?.cpc_admin === "") {
      setValidationMessagecpc_admin("CPC Admin cannot be empty.");
      setIsValidationHiddencpc_admin(false);
      focusInputRef = cpc_adminInputRef;
    }
    if (setting?.cpv_advertiser === "") {
      setValidationMessagecpv_advertiser("CPC Advertiser cannot be empty.");
      setIsValidationHiddencpv_advertiser(false);
      focusInputRef = featured_pre_adInputRef;
    }
    if (setting?.cpv_admin === "") {
      setValidationMessagecpv_admin("CPC Admin cannot be empty.");
      setIsValidationHiddencpv_admin(false);
      focusInputRef = cpv_adminInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }


    const enable_ppv_rent = Number.isNaN(parseInt(setting?.enable_ppv_rent, 10)) ? 0 : parseInt(setting?.enable_ppv_rent, 10);

    // advertisement setting
    const ads_on_videos = Number.isNaN(parseInt(setting?.ads_on_videos, 10)) ? 0 : parseInt(setting?.ads_on_videos, 10);
    const ads_play_unlimited_period = Number.isNaN(parseInt(setting?.ads_play_unlimited_period, 10)) ? 0 : parseInt(setting?.ads_play_unlimited_period, 10);
    const ads_on_livestream = Number.isNaN(parseInt(setting?.ads_on_livestream, 10)) ? 0 : parseInt(setting?.ads_on_livestream, 10);
    const ads_on_audios = Number.isNaN(parseInt(setting?.ads_on_audios, 10)) ? 0 : parseInt(setting?.ads_on_audios, 10);
    const ads_on_episodes = Number.isNaN(parseInt(setting?.ads_on_episodes, 10)) ? 0 : parseInt(setting?.ads_on_episodes, 10);

    //coupon setting      
    const coupon_code = Number.isNaN(parseInt(setting?.coupon_code, 10)) ? 0 : parseInt(setting?.coupon_code, 10);


    //payout settings   
    const payout_method = Number.isNaN(parseInt(setting?.payout_method, 10)) ? 0 : parseInt(setting?.payout_method, 10);


    //registration settings
    const free_registration = Number.isNaN(parseInt(setting?.free_registration, 10)) ? 0 : parseInt(setting?.free_registration, 10);


    //search settings
    const search_title_status = Number.isNaN(parseInt(setting?.search_title_status, 10)) ? 0 : parseInt(setting?.search_title_status, 10);
    const search_category_status = Number.isNaN(parseInt(setting?.search_category_status, 10)) ? 0 : parseInt(setting?.search_category_status, 10);
    const search_tags_status = Number.isNaN(parseInt(setting?.search_tags_status, 10)) ? 0 : parseInt(setting?.search_tags_status, 10);
    const search_description_status = Number.isNaN(parseInt(setting?.search_description_status, 10)) ? 0 : parseInt(setting?.search_description_status, 10);
    const search_details_status = Number.isNaN(parseInt(setting?.search_details_status, 10)) ? 0 : parseInt(setting?.search_details_status, 10);


    //serires settings
    const series_networks_status = Number.isNaN(parseInt(setting?.series_networks_status, 10)) ? 0 : parseInt(setting?.series_networks_status, 10);

    // video settings 
    const videos_expiry_status = Number.isNaN(parseInt(setting?.videos_expiry_status, 10)) ? 0 : parseInt(setting?.videos_expiry_status, 10);
    const videos_per_page = Number.isNaN(parseInt(setting?.videos_per_page, 10)) ? 0 : parseInt(setting?.videos_per_page, 10);
    const epg_status = Number.isNaN(parseInt(setting?.epg_status, 10)) ? 0 : parseInt(setting?.epg_status, 10);
    const show_genre = Number.isNaN(parseInt(setting?.show_genre, 10)) ? 0 : parseInt(setting?.show_genre, 10);
    const show_languages = Number.isNaN(parseInt(setting?.show_languages, 10)) ? 0 : parseInt(setting?.show_languages, 10);
    const show_subtitle = Number.isNaN(parseInt(setting?.show_subtitle, 10)) ? 0 : parseInt(setting?.show_subtitle, 10);
    const show_artist = Number.isNaN(parseInt(setting?.show_artist, 10)) ? 0 : parseInt(setting?.show_artist, 10);
    const show_recommended_videos = Number.isNaN(parseInt(setting?.show_recommended_videos, 10)) ? 0 : parseInt(setting?.show_recommended_videos, 10);
    const show_views = Number.isNaN(parseInt(setting?.show_views, 10)) ? 0 : parseInt(setting?.show_views, 10);
    const show_Links_and_details = Number.isNaN(parseInt(setting?.show_Links_and_details, 10)) ? 0 : parseInt(setting?.show_Links_and_details, 10);
    const show_description = Number.isNaN(parseInt(setting?.show_description, 10)) ? 0 : parseInt(setting?.show_description, 10);

    const editInputvalue = {

      // advertisement setting
      ads_on_videos: ads_on_videos,
      default_ads_url: setting?.default_ads_url,
      featured_pre_ad: setting?.featured_pre_ad,
      featured_mid_ad: setting?.featured_mid_ad,
      featured_post_ad: setting?.featured_post_ad,
      cpv_admin: setting?.cpv_admin,
      cpv_advertiser: setting?.cpv_advertiser,
      cpc_admin: setting?.cpc_admin,
      cpc_advertiser: setting?.cpc_advertiser,
      ads_play_unlimited_period: ads_play_unlimited_period,
      ads_on_livestream: ads_on_livestream,
      ads_on_audios: ads_on_audios,
      ads_on_episodes: ads_on_episodes,

      //coupon setting      
      discount_percentage: setting?.discount_percentage,
      coupon_code: coupon_code,
      new_subscriber_coupon: setting?.new_subscriber_coupon,

      //email settings
      coupon_status: setting?.coupon_status,
      google_tracking_id: setting?.google_tracking_id,
      google_oauth_key: setting?.google_oauth_key,
      system_email: setting?.system_email,
      system_phone_number: setting?.system_phone_number,

      //login settings      
      login_text: setting?.login_text,
      signature: setting?.signature,
      login_content: setting?.login_content,
      notification_key: setting?.notification_key,

      //payout setting
      payout_method: payout_method,

      //ppv settings
      ppv_status: setting?.ppv_status,
      enable_ppv_button: setting?.enable_ppv_button,
      PPV_Individual_Contents: setting?.PPV_Individual_Contents,
      ppv_price: setting?.ppv_price,
      ppv_hours: setting?.ppv_hours,
      ios_plan_price: setting?.ios_plan_price,
      ios_product_id: setting?.ios_product_id,
      expiry_time_started: setting?.expiry_time_started,
      expiry_day_notstarted: setting?.expiry_day_notstarted,
      expiry_hours_notstarted: setting?.expiry_hours_notstarted,
      expiry_min_notstarted: setting?.expiry_min_notstarted,

      //registration settings
      enable_landing_page: setting?.enable_landing_page,
      free_registration: free_registration,
      activation_email: setting?.activation_email,
      premium_upgrade: setting?.premium_upgrade,
      access_free: setting?.access_free,

      //search settings
      search_title_status: search_title_status,
      search_category_status: search_category_status,
      search_tags_status: search_tags_status,
      search_description_status: search_description_status,
      search_details_status: search_details_status,

      //serires settings
      series_season: setting?.series_season,
      series_networks_status: series_networks_status,

      //site settings
      website_name: setting?.website_name,
      website_description: setting?.website_description,
      logo: setting?.logo,
      logo_height: setting?.logo_height,
      logo_width: setting?.logo_width,
      favicon: setting?.favicon,

      //social network settings
      facebook_page_id: setting?.facebook_page_id,
      google_page_id: setting?.google_page_id,
      twitter_page_id: setting?.twitter_page_id,
      instagram_page_id: setting?.instagram_page_id,
      linkedin_page_id: setting?.linkedin_page_id,
      whatsapp_page_id: setting?.whatsapp_page_id,
      skype_page_id: setting?.skype_page_id,
      youtube_page_id: setting?.youtube_page_id,
      tiktok_page_id: setting?.tiktok_page_id,
      email_page_id: setting?.email_page_id,

      //default_time_zone
      default_time_zone: setting?.default_time_zone,

      // video settings 
      videos_per_page: videos_per_page,
      posts_per_page: setting?.posts_per_page,
      show_description: show_description,
      show_Links_and_details: show_Links_and_details,
      show_genre: show_genre,
      show_languages: show_languages,
      show_subtitle: show_subtitle,
      show_artist: show_artist,
      show_recommended_videos: show_recommended_videos,
      show_views: show_views,
      videos_expiry_status: videos_expiry_status,
      epg_status: epg_status,

      epg_status: enable_ppv_rent,

      earn_amount: setting?.earn_amount ? parseInt(setting?.earn_amount, 10) : 0,
      video_clip_enable: setting?.video_clip_enable ? parseInt(setting?.video_clip_enable, 10) : 0,

    };


    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/setting/store`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res?.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        // secondApiStatus = true;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      setValidationMessagedefault_ads_url("");
      setValidationMessagefeatured_pre_ad("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddendefault_ads_url(false);
        setIsValidationHiddenfeatured_pre_ad(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <Link to="/settings" className="backarrow">
                <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                <span> Back</span>
              </Link>
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Advertisement Settings</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Advertisement
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Advertisement</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Default Ads url<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        // id="default_ads_url"
                        name="default_ads_url"
                        className="rs-input form-control-lg"
                        // id="default_ads_url"
                        onChange={handleInput}
                        value={setting?.default_ads_url}
                        ref={default_ads_urlInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddendefault_ads_url && (
                        <p>{validationMessagedefault_ads_url}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Featured Ad Pre Roll<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="featured_pre_ad"
                        name="featured_pre_ad"
                        value={setting?.featured_pre_ad}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                        ref={featured_pre_adInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenfeatured_pre_ad && (
                        <p>{validationMessagefeatured_pre_ad}</p>
                      )}
                    </span>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Featured Ad Mid Roll<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="featured_pre_ad"
                        name="featured_mid_ad"
                        value={setting?.featured_mid_ad}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                        ref={featured_mid_adInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenfeatured_mid_ad && (
                        <p>{validationMessagefeatured_mid_ad}</p>
                      )}
                    </span>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Featured Ad Post Roll<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="featured_pre_ad"
                        name="featured_post_ad"
                        value={setting?.featured_post_ad}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                        ref={featured_post_adInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenfeatured_post_ad && (
                        <p>{validationMessagefeatured_post_ad}</p>
                      )}
                    </span>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Advertisement
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Cost of Url</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">
                      Cost Per Click Advertiser
                      <span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        // id="default_ads_url"
                        name="cpc_advertiser"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        ref={cpc_advertiserInputRef}
                        value={setting?.cpc_advertiser}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddencpc_advertiser && (
                        <p>{validationMessagecpc_advertiser}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Cost Per Click Admin<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="number"
                        // id="featured_pre_ad"
                        name="cpc_admin"
                        value={setting?.cpc_admin}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                        ref={cpc_adminInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddencpc_admin && (
                        <p>{validationMessagecpc_admin}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Cost Per View Advertiser
                      <span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="number"
                        // id="featured_pre_ad"
                        name="cpv_advertiser"
                        value={setting?.cpv_advertiser}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                        ref={cpv_advertiserInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddencpv_advertiser && (
                        <p>{validationMessagecpv_advertiser}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Cost Per View Admin<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="number"
                        // id="featured_pre_ad"
                        name="cpv_admin"
                        value={setting?.cpv_admin}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                        ref={cpv_adminInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddencpv_admin && (
                        <p>{validationMessagecpv_admin}</p>
                      )}
                    </span>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Status Settings</h5>
                  <hr></hr>
                  <div className="row">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">Enable Ads Play 24/7</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3 text-end">
                      <label className="switch">
                        <input
                          name="ads_play_unlimited_period"
                          id="ads_play_unlimited_period"
                          onChange={handleInputenable}
                          className="rs-input"
                          defaultChecked={
                            setting?.ads_play_unlimited_period == 1
                              ? true
                              : false
                          }
                          checked={
                            setting?.ads_play_unlimited_period == 1
                              ? true
                              : false
                          }
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="ads_play_unlimited_period"
                          id="ads_play_unlimited_period"
                          onChange={handleInputenable}
                          value={
                            setting?.ads_play_unlimited_period == 1 ? "1" : "0"
                          }
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">Enable Advertisement on Videos</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3 text-end">
                      <label className="switch">
                        <input
                          name="ads_on_videos"
                          id="ads_on_videos"
                          onChange={handleInputenable}
                          className="rs-input"
                          defaultChecked={
                            setting?.ads_on_videos == 1 ? true : false
                          }
                          checked={setting?.ads_on_videos == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="ads_on_videos"
                          id="ads_on_videos"
                          onChange={handleInputenable}
                          value={setting?.ads_on_videos == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">Enable Advertisement on Lives</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3 text-end">
                      <label className="switch">
                        <input
                          name="ads_on_livestream"
                          id="ads_on_livestream"
                          onChange={handleInputenable}
                          className="rs-input"
                          defaultChecked={
                            setting?.ads_on_livestream == 1 ? true : false
                          }
                          checked={
                            setting?.ads_on_livestream == 1 ? true : false
                          }
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="ads_on_livestream"
                          id="ads_on_livestream"
                          onChange={handleInputenable}
                          value={setting?.ads_on_livestream == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">Enable Advertisement on Audios</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3 text-end">
                      <label className="switch">
                        <input
                          name="ads_on_audios"
                          id="ads_on_audios"
                          onChange={handleInputenable}
                          className="rs-input"
                          defaultChecked={
                            setting?.ads_on_audios == 1 ? true : false
                          }
                          checked={setting?.ads_on_audios == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="ads_on_audios"
                          id="ads_on_audios"
                          onChange={handleInputenable}
                          value={setting?.ads_on_audios == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">Enable Advertisement on Episodes</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3 text-end">
                      <label className="switch">
                        <input
                          name="ads_on_episodes"
                          id="ads_on_episodes"
                          onChange={handleInputenable}
                          className="rs-input"
                          defaultChecked={
                            setting?.ads_on_episodes == 1 ? true : false
                          }
                          checked={setting?.ads_on_episodes == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="ads_on_episodes"
                          id="ads_on_episodes"
                          onChange={handleInputenable}
                          value={setting?.ads_on_episodes == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section  container-fluid">
            <div className="mt-4">
              <div className="iq-card">
                <div className="" id="advertisement">
                  <div className="panel panel-primary mt-3" data-collapsed="0">
                    <div className="panel-heading">
                      <div className="panel-title">
                        <h4>
                          <Link to="/settings">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                          </Link>
                          Advertisement Settings
                        </h4>
                      </div>
                      <hr />
                    </div>
                  </div>
                  <div className="d-flex align-items-baseline">
                    <div className="col-lg-6">
                      <p className="">Enable Advertisement on Videos:</p>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        >
                          <label className="switch">
                            <input
                              name="ads_on_videos"
                              onChange={handleInput}
                              defaultChecked={
                                setting?.ads_on_videos === 1 ? true : false
                              }
                              checked={
                                setting?.ads_on_videos === 1 ? true : false
                              }
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="ads_on_videos"
                              onChange={handleInput}
                              value={setting?.ads_on_videos === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label>Default Ads url</label>
                        <input
                          id="default_ads"
                          type="text"
                          name="default_ads_url"
                          className="form-control"
                          placeholder="Default Ads in videos"
                          value={setting?.default_ads_url}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="form-group add-profile-pic">
                        <label>Featured Ad Pre Roll:</label>
                        <input
                          id="featured_pre_ad"
                          type="text"
                          name="featured_pre_ad"
                          className="form-control"
                          placeholder="Featured Ad Pre Roll"
                          value={setting?.featured_pre_ad}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group add-profile-pic">
                        <label>Featured Ad Mid Roll:</label>
                        <input
                          id="featured_mid_ad"
                          type="text"
                          name="featured_mid_ad"
                          className="form-control"
                          placeholder="Featured Ad Mid Roll"
                          value={setting?.featured_mid_ad}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group add-profile-pic">
                        <label>Featured Ad Post Roll:</label>
                        <input
                          id="featured_post_ad"
                          type="text"
                          name="featured_post_ad"
                          className="form-control"
                          placeholder="Featured Ad Post Roll"
                          value={setting?.featured_post_ad}
                          onChange={handleInput}
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-6">
                      <div className="form-group add-profile-pic">
                        <label>Cost Per Click Advertiser:</label>
                        <input
                          id="cpc_advertiser"
                          type="text"
                          name="cpc_advertiser"
                          className="form-control"
                          placeholder="Cost Per Click Advertiser"
                          value={setting?.cpc_advertiser}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="form-group add-profile-pic">
                        <label>Cost Per Click Admin:</label>
                        <input
                          id="cpc_admin"
                          type="text"
                          name="cpc_admin"
                          className="form-control"
                          placeholder="Cost Per Click Admin"
                          value={setting?.cpc_admin}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group add-profile-pic">
                        <label>Cost Per View Advertiser:</label>
                        <input
                          id="cpv_advertiser"
                          type="text"
                          name="cpv_advertiser"
                          className="form-control"
                          placeholder="Cost Per View Advertiser"
                          value={setting?.cpv_advertiser}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group add-profile-pic">
                        <label>Cost Per View Admin:</label>
                        <input
                          id="cpv_admin"
                          type="text"
                          name="cpv_admin"
                          className="form-control"
                          placeholder="Cost Per View Admin"
                          value={setting?.cpv_admin}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-end p-3">
                    <input
                      type="submit"
                      id="appupdate"
                      value="Update Setting"
                      onClick={handleUpdate}
                      className="btn btn-primary ml-3 "
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default Advertisement_Setting;
