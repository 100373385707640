import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Table } from "react-bootstrap";
import edititem from "../../../components/Images/edititem.svg";
import stripe_image from "../../../components/Images/payment_logo/stripe.svg";
import paypal_image from "../../../components/Images/payment_logo/paypal.svg";
import paystack_image from "../../../components/Images/payment_logo/paystack.svg";
import razorpay_image from "../../../components/Images/payment_logo/razorpay.svg";
import iyzico_image from "../../../components/Images/payment_logo/iyzico.svg";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import "./PaymentSettings.css";

function PaymentSettings(props) {
  const [loading, setLoading] = useState(true);
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/stripe_payment/index`, {
        headers: headers,
      })
      .then((response) => {
        setLoading(false);
        //   console.log(result)
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/razorpay_payment/index`, {
        headers: headers,
      })
      .then((response) => {
        setLoading(false);
        // console.log(result)
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/paypal_payment/index`, {
        headers: headers,
      })
      .then((response) => {
        setLoading(false);
        //   console.log(result)
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/paystack_payment/index`, {
        headers: headers,
      })
      .then((response) => {
        setLoading(false);
        //   console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <h3>Payment Settings</h3>
              </div>
            </div>

            <div className="col-lg-12 mt-2">
              <div className="card">
                <div className="p-2 text-start iq-card-body">
                  <label className="mt-2">RECOMMENDED SOLUTIONS</label>

                  <hr />
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex text-start align-items-center ">
                            <div>
                              {loading ? (
                                <Response_Loading_processing></Response_Loading_processing>
                              ) : (
                                <img
                                  src={stripe_image}
                                  alt="Image"
                                  className="thumbnails-images"
                                />
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <label>Stripe Checkout</label>
                          <p>
                            Stripe provides a set of programmable APIs and tools
                            to let you facilitate payments and pay out sellers
                            globally.
                          </p>
                        </td>

                        <td>
                          <button className="btn edititem-color-payment">
                            <Link to="/stripe_settings">
                              <div className="d-flex">
                                <div>
                                  <span>
                                    <img
                                      src={edititem}
                                      alt="flogo"
                                      width={20}
                                      height={20}
                                    />
                                  </span>
                                </div>
                                <div>
                                  <span className="ms-2">Configure</span>
                                </div>
                              </div>
                            </Link>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex text-start align-items-center ">
                            <div>
                              {loading ? (
                                <Response_Loading_processing></Response_Loading_processing>
                              ) : (
                                <img
                                  src={paypal_image}
                                  alt="Image"
                                  className="thumbnails-images"
                                />
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <label>Paypal Checkout</label>
                          <p>
                            PayPal is a payment platform with a website and a
                            phone app that enables payments between parties
                            through online money transfers.
                          </p>
                        </td>

                        <td>
                          <button className="btn edititem-color-payment">
                            <Link to="/paypal_settings">
                              <div className="d-flex">
                                <div>
                                  <span>
                                    <img
                                      src={edititem}
                                      alt="flogo"
                                      width={20}
                                      height={20}
                                    />
                                  </span>
                                </div>
                                <div>
                                  <span className="ms-2">Configure</span>
                                </div>
                              </div>
                            </Link>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex text-start align-items-center ">
                            <div>
                              {loading ? (
                                <Response_Loading_processing></Response_Loading_processing>
                              ) : (
                                <img
                                  src={razorpay_image}
                                  alt="Image"
                                  className="thumbnails-images"
                                />
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <label>Razorpay Checkout</label>
                          <p>
                            Razorpay has developed the Standard Checkout method
                            and manages it. You can configure payment methods,
                            orders, company logo and also select custom colour
                            based on your convenience.
                          </p>
                        </td>

                        <td>
                          <button className="btn edititem-color-payment">
                            <Link to="/razorpay_settings">
                              <div className="d-flex">
                                <div>
                                  <span>
                                    <img
                                      src={edititem}
                                      alt="flogo"
                                      width={20}
                                      height={20}
                                    />
                                  </span>
                                </div>
                                <div>
                                  <span className="ms-2">Configure</span>
                                </div>
                              </div>
                            </Link>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex text-start align-items-center ">
                            <div>
                              {loading ? (
                                <Response_Loading_processing></Response_Loading_processing>
                              ) : (
                                <img
                                  src={paystack_image}
                                  alt="Image"
                                  className="thumbnails-images"
                                />
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <label>Paystack Checkout</label>
                          <p>
                            Paystack helps businesses like yours get paid
                            quickly and securely from anyone, anywhere in the
                            world.
                          </p>
                        </td>

                        <td>
                          <button className="btn edititem-color-payment">
                            <Link to="/paystack_settings">
                              <div className="d-flex">
                                <div>
                                  <span>
                                    <img
                                      src={edititem}
                                      alt="flogo"
                                      width={20}
                                      height={20}
                                    />
                                  </span>
                                </div>
                                <div>
                                  <span className="ms-2">Configure</span>
                                </div>
                              </div>
                            </Link>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex text-start align-items-center ">
                            <div>
                              {loading ? (
                                <Response_Loading_processing></Response_Loading_processing>
                              ) : (
                                <img
                                  src={iyzico_image}
                                  alt="Image"
                                  className="thumbnails-images"
                                />
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <label>Iyzico Checkout</label>
                          <p>
                            Iyzico helps businesses like yours get paid
                            quickly and securely from anyone, anywhere in the
                            world.
                          </p>
                        </td>

                        <td>
                          <button className="btn edititem-color-payment">
                            <Link to="/iyzico_settings">
                              <div className="d-flex">
                                <div>
                                  <span>
                                    <img
                                      src={edititem}
                                      alt="flogo"
                                      width={20}
                                      height={20}
                                    />
                                  </span>
                                </div>
                                <div>
                                  <span className="ms-2">Configure</span>
                                </div>
                              </div>
                            </Link>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default PaymentSettings;
