

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import defaultImage from "../../../components/Images/loading/default_image.png";
import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";

function Site_met_settings(props) {
  const [getSite, setSite] = useState([]);
  const [filteredSiteData, setFilteredSiteData] = useState([]);
  const [itemToDeleteSite, setItemToDeleteSite] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [modalCloseSite, setModalCloseSite] = useState(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/site_meta/index`,
        { headers: headers }
      );
      const SiteData = response?.data?.site_meta_setting.data;
      setSite(SiteData);
      setFilteredSiteData(SiteData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(async () => {
    fetchData();
  }, []);

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredSiteData?.length },
    ],
  });

  const imageFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center gap-2">
        <img
          src={row.image_url ? row.image_url : defaultImage}
          className="profile"
        />
        <div className="rightVideoTitle">
          <p>{row?.page_name}</p>
          <span className="dateText">{row?.upload_on}</span>

        </div>
      </div>
    );
  };
  const pagetitle = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center gap-2">

        <div className="rightVideoTitle">
          <p>{row?.page_title}</p>
          <span className="dateText">{row?.upload_on}</span>

        </div>
      </div>
    );
  };
  const sno = (data, row, index) => {
    return (
      <div className="d-flex justify-content-between align-items-center gap-2">

        <div className="rightVideoTitle">
          <p>{index + 1}</p>
        </div>
      </div>
    );
  };
  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/update_sitemeta_settings/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2"> Edit Site </span>
            </span>
          </Link>

          {/* <div
            onClick={() => openModalSingleDeleteSite(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2">Delete Site</span>
          </div> */}
        </div>
      </div>
    );
  };
  const columns = [
    {
      dataField: "s_no",
      text: "S.NO",
      formatter: sno,
      sort: true,
    },
    {
      dataField: "Page_name",
      text: "PAGE NAME",
      formatter: imageFormatter,
      sort: true,
    },
    {
      dataField: "Page_title",
      text: "PAGE TITLE",
      formatter: pagetitle,
      sort: true,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];
  const exportHeadersSite = [
    { label: "ID", key: "id" },
    { label: "PAGE NAME", key: "Page_name" },
    { label: "PAGE TITLE ", key: "Page_title" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD-ON", key: "upload_on" },
  ];

  const conditionalGeneratePDFSite = (get) => {
    const doc = new jsPDF();
    doc.text("SiteS LIST ", 10, 10);
    const columns = ["INDEX", "ID", "Page Name", "Page Title ", "UPLOAD-ON"];
    const allDataPDF = getSite?.map((item, index) => [
      index + 1,
      item?.id,
      item?.page_name,
      item?.page_title,
      item?.upload_on,
    ]);
    const filterPDF = filteredSiteData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.page_name,
      item?.page_title,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("SiteS-LIST.pdf");
  };
  const generateXLSXSite = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? getSite : filteredSiteData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "SiteS-LIST.xlsx");
  };

  const handleFilterChangeSite = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = getSite.filter((item) =>
      ["Page_name", "Page_title", "uploaded_by", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredSiteData(filteredResults);
  };

  const handleClear = () => {
    fetchData();
    setFilter("");
  };
  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDeleteSite(
      selectAll ? [] : filteredSiteData?.map((item) => item?.id)
    );
  };

  const closeSiteModal = () => {
    setModalCloseSite(!modalCloseSite);
  };
  const openModalSingleDeleteSite = (id) => {
    setItemToDeleteSite([id]);
    setModalCloseSite(true);
  };
  const openModalMultiDeleteSite = () => {
    setModalCloseSite(true);
  };
  const handleDeleteSiteData = () => {
    closeSiteModal();
    SiteDeleteOperation();
  };
  const SiteDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteSite + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/song-writer/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };
  return (
    <>
      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-8 col-sm-8 col-8">
              <h4>
                Site Meta Settings
                <span className="fs-6 ms-1">
                  (Display all {getSite?.length} Site)
                </span>
              </h4>
            </div>
            {/* <div className="col-md-4 col-12 mt-3 mt-md-0" align="right">
              {itemToDeleteSite?.length > 0 && (
                <button
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModalMultiDeleteSite}
                >
                  <MdDelete className="exportIcon " />
                  Delete
                </button>
              )}
              <Link
                to="/new-Site-songwriter"
                className="btn btn-primary fw-bolder"
              >
                <i className="fa fa-plus-circle"></i> Add Site
              </Link>
            </div> */}
          </div>
          <div className="iq-card mt-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
              <div className="filterContainer">
                <input
                  type="text"
                  placeholder="Filter all.."
                  className="filter form-control "
                  value={filter}
                  onChange={handleFilterChangeSite}
                />
                {filter?.length > 0 && (
                  <button
                    type="button"
                    className="closeBtn"
                    onClick={handleClear}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                )}
              </div>
              <div className="btn-group">
                <div className="dropdown d-block " key={"2"}>
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export </span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeadersSite}
                        data={filteredSiteData}
                        className="text-dark d-block"
                        filename="SiteS-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFSite(0);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXSite(0);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>

                <div className="dropdown d-block ">
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export All</span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeadersSite}
                        data={getSite}
                        className="text-dark d-block"
                        filename="SiteS-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFSite(1);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXSite(1);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bootstrapTable">
              <BootstrapTable
                keyField="id"
                columns={columns}
                data={filteredSiteData}
                pagination={pagination}
                className="no-border-table"
                noDataIndication={
                  <div className="noRecord">
                    {loading ? (
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>
                        Loading...
                      </label>
                    ) : (
                      filteredSiteData?.length <= 0 && (
                        <p>No Site Available !!!</p>
                      )
                    )}
                  </div>
                }
                hover
                headerClasses="AlbumWidth"
                // selectRow={selectRow}
                selectAll={selectAll}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Site_met_settings;



// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Table } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import loadings from "../../../components/Images/loading/loading.gif";
// import edititem from "../../../components/Images/edititem.svg";

// function Site_met_settings(props) {
//   const [data, setData] = useState([]);
//   const [site_meta, setSite_meta] = useState("");
//   const [loading, setLoading] = useState(true);

//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 10;

//   const [page, setPage] = useState(1);

//   const access_token = localStorage.getItem("access_token");

//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + access_token,
//     Accept: "application/json",
//     "Content-Type": "application/json",
//     "Access-Control-Allow-Headers":
//       "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
//     "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
//     "Access-Control-Allow-Credentials": true,
//     "Access-Control-Allow-Origin": "*",
//     "X-Requested-With": "*",
//   };

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
//   const fetchDatanextpre = () => {
//     axios
//       .get(
//         `${process.env.REACT_APP_Baseurl}/admin/site_meta/index?page=${page}`,
//         {
//           headers: headers,
//         }
//       )
//       .then((res) => {
//         const site_metaData = res?.data?.site_meta_setting.data;
//         setSite_meta(site_metaData);
//         if (site_metaData.length > 0) {
//           setData(site_metaData);
//           setLoading(false);
//         } else {
//           setData([]);
//           setLoading(false);
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   useEffect(async () => {
//     fetchDatanextpre();
//   }, [page]);
//   return (
//     <>
//       <div className=" m-0 p-0">
//         <div className="">
//           <section className="section container-fluid pt-3">
//             <div id="content-page" className="content-page">
//               <div className="col-lg-12 row py-3">
//                 <div className="col-12 col-sm-12 col-lg-12">
//                   <h3>Site Meta Settings</h3>
//                 </div>
//               </div>

//               <div className="col-lg-12 ">
//                 <div className="card">
//                   {loading ? (
//                     <div className="p-2 text-start iq-card-body">
//                       <Table>
//                         <thead>
//                           <tr className="title">
//                             <th>PAGE NAME</th>
//                             <th>PAGE TITLE</th>
//                             <th>ACTION</th>
//                           </tr>
//                         </thead>
//                       </Table>

//                       <div className="text-center">
//                         <label>
//                           {" "}
//                           <img
//                             src={loadings}
//                             width={100}
//                             alt="Animated GIF"
//                           />{" "}
//                           Loading...
//                         </label>
//                       </div>
//                     </div>
//                   ) : site_meta.length > 0 ? (
//                     <div className="p-2 text-start iq-card-body">
//                       {/* Render your table here with site_meta */}
//                       <Table>
//                         <thead>
//                           <tr className="title">
//                             <th>PAGE NAME</th>
//                             <th>PAGE TITLE</th>
//                             <th>ACTION</th>
//                           </tr>
//                         </thead>
//                         {currentItems?.map((item, key) => (
//                           <tbody>
//                             <tr key={item?.id}>
//                               <td>
//                                 <div className="d-flex text-start align-items-center ">
//                                   {/* <div>
//                                     <img
//                                       src={item?.Thumbnail || item?.Thumbnail}
//                                       className="image_thumbanails"
//                                       width="100"
//                                       height="100"
//                                     />
//                                   </div> */}

//                                   <div>
//                                     {loading ? (
//                                       <img
//                                         src={item?.image_url}
//                                         className="thumbnails-images"
//                                       />
//                                     ) : (
//                                       <img
//                                         src={item?.image_url}
//                                         className="thumbnails-images"
//                                       />
//                                     )}
//                                   </div>

//                                   <div>
//                                     <div className="ms-2">
//                                       <div>{item?.page_name}</div>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </td>

//                               {/* <td>{item?.page_name}</td> */}
//                               <td>{item?.page_title}</td>

//                               <td>
//                                 <div className="editdropdown">
//                                   <span className="editdropdown-button">
//                                     <span>
//                                       <i
//                                         className="fa fa-ellipsis-v"
//                                         aria-hidden="true"
//                                       ></i>
//                                     </span>
//                                   </span>
//                                   <div className="editdropdown-menu">
//                                     <Link
//                                       to={
//                                         "/update_sitemeta_settings/" +
//                                         item?.id
//                                       }
//                                       className="edit"
//                                     >
//                                       <span>
//                                         <img
//                                           src={edititem}
//                                           alt="flogo"
//                                           width={20}
//                                           height={20}
//                                         />
//                                         <span className="ms-2">Edit Site</span>
//                                       </span>
//                                     </Link>
//                                   </div>
//                                 </div>
//                               </td>
//                             </tr>
//                           </tbody>
//                         ))}
//                       </Table>
//                     </div>
//                   ) : (
//                     <div className="p-2 text-start iq-card-body">
//                       <Table>
//                         <thead>
//                           <tr className="title">
//                             <th>PAGE NAME</th>
//                             <th>PAGE TITLE</th>
//                             <th>ACTION</th>
//                           </tr>
//                         </thead>
//                       </Table>

//                       <div className="text-center">
//                         <label>No Site Meta Settings Available.</label>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </section>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Site_met_settings;

