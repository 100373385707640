import React, { useRef } from "react";
import { FaCog } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function ManageTranslation(props) {

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [datatranslatelanguage, setDataTranslatelanguage] = useState([]);
  const [defaulttranslatelanguage, setDefaultTranslatelanguage] = useState('');
  const [loading, setLoading] = useState(true);
  const [validationMessagename, setValidationMessagename] = useState("");
  const [validationMessagecode, setValidationMessagecode] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);
  const [isValidationHiddencode, setIsValidationHiddencode] = useState(true);
  const nameInputRef = useRef(null);
  const translateLanguages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/translate-language/index`,
        { headers: headers }
      );
      setDataTranslatelanguage(response?.data?.Language);
      setDefaultTranslatelanguage(response?.data?.site_default_language)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(async () => {
    translateLanguages();
  }, []);
  const [addLanguage, setAddLanguage] = useState({
    name: "", code: "", active: "",
    site_default_language: ""
  });
  const [active, setActive] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddLanguage((prevAddLanguage) => ({
      ...prevAddLanguage,
      [name]: value,
    }));
    setValidationMessagename("");
    setIsValidationHiddenname(true);
    setValidationMessagecode("");
    setIsValidationHiddencode(true);
    setAddLanguage({ ...addLanguage, title: e.target.value });
    setAddLanguage({ ...addLanguage, [e.target.name]: e.target.value });
    if (e.target.checked === true) {
      setActive({ ...active, [e.target.name]: 1 });
    } else {
      setActive({ ...active, [e.target.name]: 0 });
    }
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  let firstApiStatus = false;
  let resultapi = null;

  async function saveTranslatelanguages() {
    let focusInputRef = null;

    if (addLanguage?.name === "") {
      setValidationMessagename("Name cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }
    else if (addLanguage?.code === "") {
      setValidationMessagecode("Code cannot be empty.");
      setIsValidationHiddencode(false);
      focusInputRef = nameInputRef;
    } if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }
    const formData = new FormData();

    formData.append("name", addLanguage?.name);
    formData.append("code", addLanguage?.code);
    formData.append("active", addLanguage?.active);
    formData.append("site_default_language", defaulttranslatelanguage);
    setProcessing(true);
    setShowOverlay(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/translate-language/store`,
        formData,
        { headers: headers }
      );
      if (response?.data?.status === true) {
        // console.log(response?.data)
        firstApiStatus = true;
        resultapi = response?.data;
        setProcessing(false);
        setShowOverlay(false);
      }
    } catch (error) {
      alert(error)
    }
    if (firstApiStatus) {
      setValidationMessagename("");
      setValidationMessagecode("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddenname(false);
        setIsValidationHiddencode(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  const navigate = useNavigate()
  const updateLanguage = (a) => {
    navigate(`/edit-translate-language/${a}`)
  }



  const [itemToDeletevideolanguage, setItemToDeletevideolanguage] = useState(null);
  const [successMessagevideolanguage, setSuccessMessagevideolanguage] = useState("");
  const [isConfirmationOpenLanguage, setIsConfirmationOpenLanguage] =
    useState(false);

  const openModalvideolanguage = (id) => {
    setItemToDeletevideolanguage(id);
    setSuccessMessagevideolanguage("");
  };

  const closeModalvideolanguage = () => {
    setItemToDeletevideolanguage(null);
  };
  const handleDeletedata = () => {
    closeModalvideolanguage();
    if (itemToDeletevideolanguage) {
      deleteLanguages(itemToDeletevideolanguage);
    }
  };


  const deleteLanguages = (id) => {
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/admin/translate-language/delete/${id}`, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          props?.setApiresponsealert(responseData);
          props?.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props?.setApiresponsealert(resultError);
            props?.setShowalert(true);
          }
        }
      });
  };

  // const handleCheckboxChange = (e) => {
  //   if (e.target.checked === true) {
  //     setAddLanguage({ ...addLanguage, [e.target.name]: addLanguage?.code });
  //   } else {
  //     setAddLanguage({ ...addLanguage, [e.target.name]: '' });
  //   }
  // };

  useEffect(() => {
    if (addLanguage.code === defaulttranslatelanguage) {
      setDefaultTranslatelanguage(addLanguage.code);
    }
  }, [addLanguage]);

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setDefaultTranslatelanguage(addLanguage?.code || "");
    } else {
      setDefaultTranslatelanguage("");
    }
  };


  const defaultLanguageObject = datatranslatelanguage.find(lang => lang.code === defaulttranslatelanguage);

  return (
    <>
      <div
        className={`modal ${itemToDeletevideolanguage !== null ? "show" : ""}`}
        tabIndex="-1"
        style={{
          display: itemToDeletevideolanguage !== null ? "block" : "none",
        }}
        id="exampleModalDelete"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={closeModalvideolanguage}
              ></button>
            </div>
            <div className="modal-body">
              {successMessagevideolanguage ? (
                <div className="alert alert-success" role="alert">
                  {successMessagevideolanguage}
                </div>
              ) : (
                <p>Are you sure you want to delete this Language?</p>
              )}
            </div>
            <div className="modal-footer">
              {successMessagevideolanguage ? (
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                  onClick={closeModalvideolanguage}
                >
                  Close
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn btn-secondary"
                    onClick={closeModalvideolanguage}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn btn-danger"
                    onClick={() => deleteLanguages(itemToDeletevideolanguage)}
                  >
                    Yes
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>


      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid section">
            <div className="iq-card mt-4">
              <div className="container table-responsive">
                <div className="row">
                  <div className="col-md-3">
                    <h4>
                      <i className="entypo-archive"></i> Languages
                    </h4>
                  </div>
                  <div className="col-md-9" align="right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="fa fa-plus-circle"></i> Add New
                    </button>
                  </div>
                  <div className="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalLabel"
                          >
                            New Language
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>

                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                              <label>Name <span className="mandatory">*</span></label>
                              <input
                                type="text"
                                id="name"
                                name="name"
                                className="form-control"
                                onChange={handleChange}
                                value={addLanguage?.name}
                                ref={nameInputRef}
                              />
                              <span className="errorred">
                                {!isValidationHiddenname && (
                                  <p>{validationMessagename}</p>
                                )}
                              </span>
                            </div>
                            <div className="form-group">
                              <label>Language Code <span className="mandatory">*</span></label>
                              <input
                                type="text"
                                id="code"
                                name="code"
                                className="form-control"
                                onChange={handleChange}
                                value={addLanguage?.code}
                                ref={nameInputRef}
                              />
                              <span className="errorred">
                                {!isValidationHiddencode && (
                                  <p>{validationMessagecode}</p>
                                )}
                              </span>
                            </div>


                            <div className="p-1">
                              <h6>Status Settings</h6>
                              <div className="">
                                <div className="row mx-0 mt-2">
                                  <div className="col-9 col-sm-9 col-lg-9 ps-0">
                                    <p
                                      className="theme-text-color"
                                      style={{ fontSize: "20px" }}
                                    >
                                      <label>Language Status</label>
                                    </p>
                                  </div>
                                  <div className="col-3 col-sm-3 col-lg-3 ps-0 text-end">
                                    <label class="switch mt-2">
                                      <input type="checkbox" name="active" onChange={handleChange}
                                        defaultChecked={active?.active === 1 ? true : false}
                                        checked={active?.active === 1 ? true : false} />
                                      <span
                                        className="slider round"
                                        name="active"
                                        onChange={handleChange}
                                        value={active?.active === 1 ? "1" : "0"}
                                      ></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <div className="row mx-0 mt-2">
                                  <div className="col-9 col-sm-9 col-lg-9 ps-0">
                                    <p
                                      className="theme-text-color"
                                      style={{ fontSize: "20px" }}
                                    >
                                      <label>Set Default Language</label>
                                    </p>
                                  </div>
                                  <div className="col-3 col-sm-3 col-lg-3 ps-0 text-end">
                                    <label className="switch">
                                      <input
                                        name="site_default_language"
                                        id="site_default_language"
                                        onChange={handleCheckboxChange}
                                        checked={
                                          addLanguage.code === defaulttranslatelanguage
                                        }
                                        type="checkbox"
                                      />
                                      <span
                                        className="slider round"
                                        name="site_default_language"
                                        onChange={handleCheckboxChange}
                                        value={
                                          defaulttranslatelanguage == addLanguage.code
                                            ? "1"
                                            : "0"
                                        }
                                      ></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="form-group">
                              <label>Language Status</label>
                              <div class="mt-1 d-flex align-items-center justify-content-around">
                                <label class="switch mt-2">
                                  <input type="checkbox" name="active" onChange={handleChange}
                                    defaultChecked={active?.active === 1 ? true : false}
                                    checked={active?.active === 1 ? true : false} />
                                  <span
                                    className="slider round"
                                    name="active"
                                    onChange={handleChange}
                                    value={active?.active === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </div> */}
                            <div className="form-group">
                              {/* <div className="d-flex align-items-center position-relative gap-3 checkboxHeight">
                                <input
                                  type="checkbox"
                                  name="site_default_language"
                                  checked={addLanguage?.site_default_language}
                                  className="d-block"
                                  autoComplete="off"
                                  onChange={handleCheckboxChange}
                                />
                                <label className="theme-text-color">{("Set Site Default Language")}</label>
                              </div> */}


                              {/* <label className="switch">
                                <input
                                  name="site_default_language"
                                  id="site_default_language"
                                  onChange={handleCheckboxChange}
                                  checked={
                                    addLanguage.code === defaulttranslatelanguage
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="site_default_language"
                                  onChange={handleCheckboxChange}
                                  value={
                                    defaulttranslatelanguage == addLanguage.code
                                      ? "1"
                                      : "0"
                                  }
                                ></span>
                              </label> */}

                            </div>



                          </form>
                        </div>
                        <div>
                          {showOverlay && (
                            <div className="overlay-container">
                              <span className="overlay-icon">
                                {" "}
                                <Response_Loading_processing></Response_Loading_processing>
                                Processing ...
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>

                          <button type="submit"
                            className="btn btn-primary"
                            onClick={saveTranslatelanguages}

                          >
                            Save changes
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <>
                    <table className="table table-hover table-bordered mt-4">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>

                      </tbody>
                    </table>
                    <div className="text-center">
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>{" "}
                        Loading...
                      </label>
                    </div>
                  </>
                ) : datatranslatelanguage?.length > 0 ? (
                  <>
                    <table className="table table-hover table-bordered mt-4">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datatranslatelanguage?.map((value, index) => (
                          <tr
                            key={value.id}>
                            <td>
                              <a href="#" className="translate">
                                {index + 1}
                              </a>
                            </td>
                            <td>
                              <a href="#" className="translate p-2">
                                {value?.name}
                              </a>
                              {value.code === defaulttranslatelanguage && (
                                "(Default Language)"
                              )}
                            </td>

                            <td>
                              <button className="btn btn-xs remove-key" onClick={() => updateLanguage(value?.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                  <path d="M1 17.6338H15.0688" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M8.67386 13.1577L4.83691 13.8484L5.47641 9.96029L14.084 1.37832C14.2029 1.25844 14.3443 1.16329 14.5002 1.09836C14.656 1.03343 14.8232 1 14.992 1C15.1609 1 15.328 1.03343 15.4839 1.09836C15.6398 1.16329 15.7812 1.25844 15.9001 1.37832L17.2558 2.73404C17.3757 2.85294 17.4709 2.9944 17.5358 3.15025C17.6007 3.30611 17.6342 3.47328 17.6342 3.64212C17.6342 3.81096 17.6007 3.97813 17.5358 4.13399C17.4709 4.28984 17.3757 4.4313 17.2558 4.5502L8.67386 13.1577Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </button>
                              <button className="btn btn-xs remove-key"
                                onClick={() =>
                                  openModalvideolanguage(value?.id)
                                }
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalDelete">
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
                                  <path d="M1 5.31055H18.2414" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M3.15527 5.31055H16.0863V18.2416C16.0863 18.6226 15.9349 18.9881 15.6655 19.2575C15.396 19.527 15.0306 19.6784 14.6495 19.6784H4.59205C4.211 19.6784 3.84555 19.527 3.5761 19.2575C3.30665 18.9881 3.15527 18.6226 3.15527 18.2416V5.31055Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M6.02881 5.31034V4.59195C6.02881 3.63931 6.40725 2.72568 7.08087 2.05206C7.75449 1.37844 8.66812 1 9.62076 1C10.5734 1 11.487 1.37844 12.1607 2.05206C12.8343 2.72568 13.2127 3.63931 13.2127 4.59195V5.31034" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M7.46533 8.18359V16.0859" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M11.7759 8.18359V16.0859" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ) :
                  <><table className="table table-hover table-bordered mt-4">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>

                    </tbody>
                  </table>
                    <div className="text-center">
                      <label>No Languages Available.</label>
                    </div>
                  </>
                }
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ManageTranslation;

