import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function ViewTemplate() {
  const [text, setText] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    template_type: "",
    heading: "",
    description: "",
    role_type: "",
  });
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Email-Template/view/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Email_template;
      setEditUser(res);
      // console.log(resData)
      // console.log(res)
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      template_type: editUser.template_type,
      heading: editUser.heading,
      description: editUser.description,
      role_type: editUser.role_type,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Email-Template/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      setTimeout(() => {
        navigate("/email_settings");
      }, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="p-0">
              <div className="iq-card">
                <div id="admin-container">
                  <div className="admin-section-title">
                    <h4>
                      <i className="entypo-globe"></i> Template{" "}
                    </h4>{" "}
                    <hr />
                  </div>

                  <form>
                    <div className="row mt-4">
                      <div className="col-lg-12">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Template Type</label>
                            </div>
                          </div>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="template_type"
                              id="template_type"
                              value={editUser?.template_type}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Subject</label>
                            </div>
                          </div>
                          <div className="panel-body">
                            <input
                              type="text"
                              className="form-control"
                              name="heading"
                              id="heading"
                              value={editUser?.heading}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 mt-3">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>Content</label>
                              </div>
                            </div>
                            <div className="col-lg-12 mt-3">
                              <p
                                className="p-2"
                                contentEditable="true"
                                dangerouslySetInnerHTML={{
                                  __html: editUser.description,
                                }}
                                value={editUser?.description}
                                name="description"
                                onChange={handleInput}
                              ></p>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ViewTemplate;
