import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
// import "./Addnewaudio.css";
import Select from "react-select";

const AddnewAudios = () => {
 


  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [file, setFile] = useState("");
  const [audio_id, setAudio_id] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [ppv_status, setPpv_status] = useState("1");
  const [ppv_price, setPpv_price] = useState("");
  const [type, setType] = useState("");
  const [access, setAccess] = useState("");
  const [album_id, setAlbum_id] = useState("");
  const [artists, setArtists] = useState("");
  const [artistss, setArtistss] = useState("");
  const [rating, setRating] = useState("");
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState("");
  const [status, setStatus] = useState("");
  const [draft, setDraft] = useState("");
  const [featured, setFeatured] = useState("");
  const [banner, setBanner] = useState("");
  const [duration, setDuration] = useState("");
  const [views, setViews] = useState("");
  const [year, setYear] = useState("");
  const [age_restrict, setAge_restrict] = useState("");
  const [mp3_url, setMp3_url] = useState("");
  const [mp3_live_url, setMp3_live_url] = useState("");
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");
  const [search_tags, setSearch_tags] = useState("");
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [image_url, setImage_url] = useState("");
  const [Player_thumbnail, setPlayer_thumbnail] = useState("");
  const [TV_Thumbnail, setTV_Thumbnail] = useState("");

  const [order, setOrder] = useState({});
  const [data, setData] = useState([]);
  const [publish, setPublish] = useState([]);
  const [audio_albums, setAudio_albums] = useState([]);
  const [order1, setOrder1] = useState({});
  const [order2, setOrder2] = useState({});

  const [category, setCategory] = useState([]);
  const [language, setLanguage] = useState([]);

  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const handleInput = (e) => {
    setPpv_status({ ...ppv_status, [e.target.name]: e.target.value });
    setBanner({ ...banner, [e.target.name]: e.target.value });
    setFeatured({ ...featured, [e.target.name]: e.target.value });
    setActive({ ...active, [e.target.name]: e.target.value });
  };

  // async function audiosubmit() {
  //   // console.log('append data', title, image, url_type, player_image, fb_streamkey, twitter_streamkey, youtube_streamkey, fb_restream_url, twitter_restream_url, youtube_restream_url, ppv_price, Tv_live_image, search_tags, embed_url, m3u_url, details, year, banner, active, publish_type, featured, enable_restream, duration, access, live_stream_video, mp4_url, rating, mp4_url, description, category, artist, language);

  //   const formData = new FormData();

  //   formData.append('file', file);

  //   const headers = {
  //     'Content-Type': 'application/json',
  //     Authorization: 'Bearer ' + access_token,
  //     'Content-Type': 'application/json',
  //     "Accept": 'application/json',
  //     'Access-Control-Allow-Origin': '*',
  //   };

  //   await axios.post('https://api.flicknexs.com/admin/uploadAudio', formData, { headers: headers })
  //     .then(response => {
  //       // console.log('api' , response);
  //       if (response.data.status == true) {
  //         var result = response.data;
  //         var result = response.data.Audio_id;
  //         console.log('result', result);
  //         console.log('result', result);
  //         setAudio_id(result)
  //         // navigate('/admin/livestream')
  //         alert("Added successfully  !")
  //       }
  //       else {
  //         console.log('Error');
  //         alert('Enter Correct Details')
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Error');
  //       alert('Enter Correct Details')
  //     });
  // };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const uploadFile = ({ target: { files } }) => {
    let data = new FormData();
    data.append("file", files[0]);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setUploadPercentage(percent);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
    };
    axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/uploadAudio`, data, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == true) {
          var data = res.data;
          var Audio_id = res.data.Audio_id;
          // console.log(data)
          // console.log(Audio_id)
          setAudio_id(Audio_id);
          setUploadPercentage(100);

          setTimeout(() => {
            setUploadPercentage(0);
          }, 1000);
        } else {
          setMessage("Some error Occured");
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);

        if (isCancel(err)) {
          alert(err.message);
        }
        setUploadPercentage(0);
      });
  };

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const [uploadPercentagefile, setUploadPercentagefile] = useState(0);
  const cancelFileUploadfile = useRef(null);

  const uploadFilefile = ({ target: { mp3_url } }) => {
    let data = new FormData();
    data.append("mp3_url", mp3_url);

    console.log("mp3_url", mp3_url);

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setUploadPercentagefile(percent);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
    };
    axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/Audio_URL`, data, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == true) {
          var data = res.data;
          var Audio_id = res.data.Audio_id;
          // console.log(data)
          // console.log(Audio_id)
          setAudio_id(Audio_id);
          setUploadPercentagefile(100);

          setTimeout(() => {
            setUploadPercentagefile(0);
          }, 1000);
        } else {
          setMessage("Some error Occured");
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);

        if (isCancel(err)) {
          alert(err.message);
        }
        setUploadPercentagefile(0);
      });
  };

  const cancelUploadfile = () => {
    if (cancelFileUploadfile.current)
      cancelFileUploadfile.current("User has canceled the file upload.");
  };

  async function audiofilesubmit() {
    // console.log('append data', title, image, url_type, player_image, fb_streamkey, twitter_streamkey, youtube_streamkey, fb_restream_url, twitter_restream_url, youtube_restream_url, ppv_price, Tv_live_image, search_tags, embed_url, m3u_url, details, year, banner, active, publish_type, featured, enable_restream, duration, access, live_stream_video, mp4_url, rating, mp4_url, description, category, artist, language);

    const formData = new FormData();

    formData.append("mp3_url", mp3_url);
    console.log("append data", mp3_url);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/Audio_URL`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status == true) {
          var result = response.data;
          var result = response.data.Audio_id;
          console.log("result", result);
          console.log("result", result);
          setAudio_id(result);
          // navigate('/admin/livestream')
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  async function audiolivefilesubmit() {
    // console.log('append data', title, image, url_type, player_image, fb_streamkey, twitter_streamkey, youtube_streamkey, fb_restream_url, twitter_restream_url, youtube_restream_url, ppv_price, Tv_live_image, search_tags, embed_url, m3u_url, details, year, banner, active, publish_type, featured, enable_restream, duration, access, live_stream_video, mp4_url, rating, mp4_url, description, category, artist, language);

    const formData = new FormData();

    formData.append("mp3_live_url", mp3_live_url);
    console.log("mp3_live_url data", mp3_live_url);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/LiveAudioURL`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status == true) {
          var result = response.data;
          var result = response.data.Audio_id;
          console.log("result", result);
          console.log("result", result);
          setAudio_id(result);
          // navigate('/admin/livestream')
          alert("Added successfully  !");
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  async function Audio() {
    // console.log('append data', title, image, url_type, player_image, fb_streamkey, twitter_streamkey, youtube_streamkey, fb_restream_url, twitter_restream_url, youtube_restream_url, ppv_price, Tv_live_image, search_tags, embed_url, m3u_url, details, year, banner, active, publish_type, featured, enable_restream, duration, access, live_stream_video, mp4_url, rating, mp4_url, description, category, artist, language);

    const formData = new FormData();

    formData.append("title", title);
    formData.append("audio_id", audio_id);
    formData.append("ppv_status", ppv_status);
    formData.append("ppv_price", ppv_price);
    formData.append("slug", slug);
    formData.append("type", type);
    formData.append("access", access);
    formData.append("album_id", album_id);
    formData.append("artists", artists);
    formData.append("category", category);
    formData.append("language", language);
    formData.append("audio_albums", audio_albums);
    formData.append("rating", rating);
    formData.append("details", details);
    formData.append("description", description);
    formData.append("active", active);
    formData.append("status", status);
    formData.append("draft", draft);
    formData.append("featured", featured);
    formData.append("banner", banner);
    formData.append("duration", duration);
    formData.append("views", views);
    formData.append("year", year);
    formData.append("age_restrict", age_restrict);
    formData.append("mp3_url", mp3_url);
    // formData.append('image', image);
    // formData.append('player_image', player_image);
    // formData.append('tv_image', tv_image);
    formData.append("search_tags", search_tags);
    formData.append("ios_ppv_price", ios_ppv_price);
    formData.append("uploaded_by", uploaded_by);
    // formData.append('image_url', image_url);
    // formData.append('Player_thumbnail', Player_thumbnail);
    // formData.append('TV_Thumbnail', TV_Thumbnail);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    await axios
      .post(`${process.env.REACT_APP_Baseurl}/admin/audio/store`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api' , response);
        if (response.data.status == true) {
          var result = response.data;
          // console.log('result', response);
          // navigate('/admin/livestream')
          alert("Added successfully  !");

          var audio_id = response.data.Audio_id;
          var resssss = response.data;
          console.log("resssss", resssss);
          console.log("audio_id", audio_id);

          const formDatas = new FormData();

          formDatas.append("audio_id", audio_id);
          formDatas.append("image", image);
          formDatas.append("player_image", player_image);
          formDatas.append("tv_image", tv_image);

          axios
            .post(
              `${process.env.REACT_APP_Baseurl}/admin/Image-upload-audio`,
              formDatas,
              { headers: headers }
            )
            .then((response) => {
              // console.log('api' , response);
              if (response.data.status == true) {
                var result = response.data;
                // console.log('result', response);
                // navigate('/admin/all-artist')
                alert("Added successfully  !");
              }
            });
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const getcategoryname = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_category)
        var result = response.data.audio_categories;
        setOrder(result);
        // console.log('result', result)
        // console.log('result', result);
        for (let i = 0; i < result.length; i++) {
          getcategoryname.push(result[i].name);
        }
        setCategory(getcategoryname);
      })
      .catch((error) => console.log(error));

    const getartistname = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_artist)
        var result = response.data.artists;
        setOrder1(result);
        // console.log('result', result)
        // console.log('artist', result);
        for (let i = 0; i < result.length; i++) {
          getartistname.push(result[i].artist_name);
        }
        setArtistss(getartistname);
      })
      .catch((error) => console.log(error));

    const getlanguagename = [];

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_language)
        var result = response.data.languages;
        setOrder2(result);
        // console.log('result', result)
        // console.log('livestream_language', result);
        for (let i = 0; i < result.length; i++) {
          getlanguagename.push(result[i].name);
        }
        setLanguage(getlanguagename);
      })
      .catch((error) => console.log(error));

    const getaudio_albums = [];
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_language)
        var result = response.data.audio_albums;
        setAudio_albums(result);
        // console.log('result', result)
        for (let i = 0; i < result.length; i++) {
          getaudio_albums.push(result[i].albumname);
        }
        setAudio_albums(getaudio_albums);
        // console.log('livestream_language', getaudio_albums);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response.data.user_access;
        setData(result);
        // console.log('result', result)
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));
  }, []);

  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");

  function proceednext() {
    switch (enable_restream) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div>
            <label>Upload Audio </label>

            <div className=" text-center file-drag mt-2">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div>
                  <p>Drag and drop your file here or</p>
                  <p>
                    <input
                      type="file"
                      className="form-control-file"
                      onChange={uploadFile}
                      accept="audio/mp3"
                      id="input-file-upload"
                    />
                  </p>
                </div>
                <br />
              </label>
              <div className="mb-5">
                {uploadPercentage > 0 && (
                  <div className="row mt-3">
                    <div className="col pt-1">
                      <ProgressBar
                        now={uploadPercentage}
                        striped={true}
                        label={`${uploadPercentage}%`}
                      />
                    </div>
                    <div className="col-auto">
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => cancelUpload()}
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                )}
                <br />
                <div className="text-center">
                  <button onClick={handleNext} className="btn btn-primary">
                    Proceed to Next{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case "2":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Mp3 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mp3_url"
                    onChange={(e) => setMp3_url(e.target.value)}
                    placeholder="Mp3 File URL"
                  />
                </div>
                <div className="mt-4 text-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      audiofilesubmit();
                      handleNext();
                    }}
                  >
                    Proceed to Next{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case "3":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1"> Live Mp3 File URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    name="live_mp3_url"
                    placeholder="Live Mp3 File URL"
                    onChange={(e) => setMp3_live_url(e.target.value)}
                  />
                </div>
                <div className="mt-4 text-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      audiolivefilesubmit();
                      handleNext();
                    }}
                  >
                    Proceed to Next{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const steps = [
    {
      label: "Select campaign settings",
      description: (
        <div>
          <div
            onClick={(event) => {
              setEnablestreamvisible(event.target.value);
            }}
          >
            {proceednext()}
          </div>
          <div>
            {/* <input type='checkbox' value='1' onClick={(event) => {setEnable_restream(event.target.value); }} />
	<input type='checkbox' value='2' onClick={(event) => {setEnable_restream(event.target.value); }} />
	<input type='checkbox' value='3' onClick={(event) => {setEnable_restream(event.target.value); }} /> */}

            <div className="row d-flex mt-5">
              <div className="col-md-10 offset-md-2 mt-3">
                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="1"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault1"
                >
                  Audio Upload
                </label>

                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="2"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault2"
                >
                  Audio File
                </label>

                <input
                  className="form-check-input ms-5"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  value="3"
                  onClick={(event) => {
                    setEnable_restream(event.target.value);
                  }}
                />
                <label
                  className="form-check-label ms-1"
                  for="flexRadioDefault3"
                >
                  Live Audio File
                </label>
              </div>
            </div>

            {/* <select className="form-select url_type mt-3" onClick={(event) => {setEnable_restream(event.target.value); }}>
  <option defaultValue="0">Choose Enable</option>
  <option value="1" >Yes</option>
  <option value="0">No</option>
</select> */}
          </div>
        </div>
      ),
    },
    {
      label: "Create an ad group",
      description: (
        <div className="iq-card-body ">
          <h5>Audio Info Details</h5>
          <div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="panel panel-primary " data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Title</label>
                    </div>{" "}
                    <div className="panel-options">
                      {" "}
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="panel-body">
                    <p className="p1">
                      Add the audio title in the textbox below:
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      id="title"
                      placeholder="Audio Title"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <input
                      type="text"
                      hidden
                      className="form-control"
                      value={file}
                      name="title"
                      id="title"
                      placeholder="Audio Title"
                      onChange={(e) => setFile(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Slug</label>
                    </div>{" "}
                    <div className="panel-options">
                      {" "}
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="panel-body">
                    <p className="p1">Add the Audio slug:</p>
                    <input
                      type="text"
                      className="form-control"
                      name="slug"
                      id="slug"
                      placeholder=""
                      onChange={(e) => setSlug(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-md-6">
            <div className="panel panel-primary" data-collapsed="0"> <div className="panel-heading">
              <div className="panel-title"><label>Created Date</label></div> <div className="panel-options"> <Link to="#" data-rel="collapse"><i className="entypo-down-open"></i></Link> </div></div>
              <div className="panel-body" >
                <p className="p1">Select Date/Time Below</p>
                <input type="text" className="form-control" name="created_at" id="created_at" placeholder="" value="" />
              </div>
            </div>
          </div> */}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div
                  className="panel panel-primary  p-0 mt-3"
                  data-collapsed="0"
                >
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Audio Image Cover</label>
                    </div>{" "}
                    <div className="panel-options">
                      {" "}
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="panel-body">
                    <img src="" className="audio-img" width="200" />

                    <p className="p1">
                      Select the audio image ( 9:16 Ratio or 1080X1920px ):
                    </p>
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="image"
                      id="image"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="panel panel-primary  p-0 mt-3"
                  data-collapsed="0"
                >
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Player Image Cover</label>
                    </div>{" "}
                    <div className="panel-options">
                      {" "}
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="panel-body">
                    <img src="" className="audio-img" width="200" />

                    <p className="p1">
                      Select the audio image ( 16:9 Ratio or 1280X720px ):
                    </p>
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="player_image"
                      onChange={(e) => setPlayer_image(e.target.files[0])}
                      id="player_image"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div
                className="panel panel-primary col-sm-6 mt-3"
                data-collapsed="0"
              >
                {" "}
                <div className="panel-heading">
                  <div className="panel-title">
                    <label>Country</label>
                  </div>{" "}
                  <div className="panel-options">
                    {" "}
                    <Link to="#" data-rel="collapse">
                      <i className="entypo-down-open"></i>
                    </Link>{" "}
                  </div>
                </div>
                <div className="panel-body">
                  <p className="p1">Block the Audio for Selected Country:</p>
                  {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={artists}
                  onChange={e => setArtists(e.target.value)}
                  showCheckbox
                  value='1'
                /> */}
                </div>
              </div>

              <div className="col-sm-6 mt-3">
                <div className="">
                  <label className="m-0">Search Tags</label>

                  <div className="panel-body mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="search_tags"
                      onChange={(e) => setSearch_tags(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="panel panel-primary mt-3" data-collapsed="0">
              {" "}
              <div className="panel-heading">
                <div className="panel-title">
                  <label>Audio Details, Links, and Info</label>
                </div>{" "}
                <div className="panel-options">
                  {" "}
                  <Link to="#" data-rel="collapse">
                    <i className="entypo-down-open"></i>
                  </Link>{" "}
                </div>
              </div>
              <div className="panel-body">
                <textarea
                  className="form-control"
                  name="details"
                  id="details"
                  onChange={(e) => setDetails(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="panel panel-primary mt-3" data-collapsed="0">
              {" "}
              <div className="panel-heading">
                <div className="panel-title">
                  <label>Short Description</label>
                </div>{" "}
                <div className="panel-options">
                  {" "}
                  <Link to="#" data-rel="collapse">
                    <i className="entypo-down-open"></i>
                  </Link>{" "}
                </div>
              </div>
              <div className="panel-body">
                <p className="p1">
                  Add a short description of the audio below:
                </p>
                <textarea
                  className="form-control"
                  name="description"
                  id="description"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Cast and Crew</label>{" "}
                    </div>{" "}
                    <div className="panel-options">
                      {" "}
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="panel-body">
                    <p>Add artists for the audio below:</p>
                    {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={artists}
                  onChange={e => setArtists(e.target.value)}
                  showCheckbox
                  value='1'
                /> */}
                  </div>
                  {/* <input  name="album_id" onChange={e => setAlbum_id(e.target.value)} />  */}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Album</label>
                    </div>{" "}
                    <div className="panel-options">
                      {" "}
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="panel-body">
                    <p className="p1">Select a Audio Album Below:</p>
                    {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={audio_albums}
                  onChange={e => setAudio_albums(e.target.value)}
                  value='1'
                  showCheckbox
                /> */}
                    <input
                      name="album_id"
                      onChange={(e) => setAlbum_id(e.target.value)}
                    />
                    <input
                      name="album_id"
                      onChange={(e) => setArtists(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row p-0 mt-3 align-items-center">
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Category</label>
                    </div>{" "}
                    <div className="panel-options">
                      {" "}
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="panel-body">
                    <p className="p1">Select a Audio Category Below:</p>

                    {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={category}
                  onChange={e => setCategory(e.target.value)}
                  value='1'
                  showCheckbox
                /> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Audio Ratings</label>
                    </div>{" "}
                    <div className="panel-options">
                      {" "}
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="panel-body">
                    <p className="p1"> IMDB Ratings 10 out of 10</p>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setRating(e.target.value)}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Language</label>
                    </div>{" "}
                    <div className="panel-options">
                      {" "}
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="panel-body">
                    <p className="p1">Select a Audio Language Below:</p>
                    {/* <Multiselect
                  isObject={false}
                  // onRemove={(event) => { console.log(event) }}
                  // onSelect={(event) => { console.log(event) }}
                  options={language}
                  onChange={e => setLanguage(e.target.value)}
                  value='1'
                  showCheckbox
                /> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="panel panel-primary" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div className="panel-title">
                      <label>Audio Year</label>
                    </div>{" "}
                    <div className="panel-options">
                      {" "}
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="panel-body">
                    <p className="p1">Audio Released Year</p>
                    <input
                      className="form-control"
                      name="year"
                      id="year"
                      onChange={(e) => setYear(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="clear"></div>

            <div className="row mt-3 align-items-center">
              <div className="col-sm-4">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-heading">
                    {" "}
                    <div className="panel-title">
                      <label> Duration</label>
                    </div>{" "}
                    <div className="panel-options">
                      {" "}
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="panel-body">
                    <p className="p1">
                      Enter the audio duration in the following format (Hours :
                      Minutes : Seconds)
                    </p>
                    <input
                      className="form-control"
                      name="duration"
                      id="duration"
                      maxlength="12"
                      onChange={(e) => setDuration(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-heading">
                    {" "}
                    <div className="panel-title">
                      {" "}
                      <label>User Access</label>
                    </div>{" "}
                    <div className="panel-options">
                      {" "}
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="panel-body">
                    <p className="p1">Who is allowed to view this audio?</p>
                    <select id="access" name="access" className="form-control">
                      {data.map((item) => (
                        <option value={item.value}>{item.name}</option>
                      ))}
                    </select>
                    <div className="clear"></div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-heading">
                    {" "}
                    <div className="panel-title">
                      <label> Status Settings</label>
                    </div>{" "}
                    <div className="panel-options">
                      {" "}
                      <Link to="#" data-rel="collapse">
                        <i className="entypo-down-open"></i>
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="panel-body">
                    <div className="d-flex align-items-baseline">
                      <label className="p2" for="featured">
                        Is this audio Featured:
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setFeatured(e.target.value)}
                      />
                      {/* <label className="switch">
                            <input name="featured" onChange={handleInput}  value={(featured.featured == 1 || featured.featured == null) ? '0' : (featured.featured == 1) ? '0' : '1'} type="checkbox" />
                            <span className="slider round" onChange={handleInput}  name="featured" value={(featured.featured == 1 || featured.featured == null) ? '0' : (featured.featured == 1) ? '0' : '1'}></span>
                          </label> */}
                    </div>
                    <div className="clear"></div>
                    <div className="d-flex align-items-baseline">
                      <label className="p2" for="banner">
                        Is this Audio display in Banner:
                      </label>
                      <input
                        type="text"
                        onChange={(e) => setBanner(e.target.value)}
                      />
                      {/* <label className="switch">
                            <input name="banner" onChange={handleInput}  value={(banner.banner == 1 || banner.banner == null) ? '0' : (banner.banner == 1) ? '0' : '1'} type="checkbox" />
                            <span className="slider round" onChange={handleInput}  name="banner" value={(banner.banner == 1 || banner.banner == null) ? '0' : (banner.banner == 1) ? '0' : '1'}></span>
                          </label> */}
                    </div>
                  </div>
                  <div className="clear"></div>
                  <div className="d-flex align-items-baseline">
                    <label className="p2" for="active">
                      Is this audio Active:
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setActive(e.target.value)}
                    />
                    {/* <label className="switch">
                            <input name="active" onChange={handleInput}  value={(active.active == 1 || active.active == null) ? '0' : (active.active == 1) ? '0' : '1'} type="checkbox" />
                            <span className="slider round" onChange={handleInput}  name="active" value={(active.active == 1 || active.active == null) ? '0' : (active.active == 1) ? '0' : '1'}></span>
                          </label> */}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row col-sm-12" id="ppv_price">
            <div className="col-sm-6">
              <label className="p2">PPV Price:</label>
              <input type="text" className="form-control" placeholder="PPV Price" name="ppv_price" id="price" 
              onChange={e => setPpv_price(e.target.value)} />
            </div>
  
            <div className="col-sm-6">
              <label className="p2"> IOS PPV Price:</label>
              <select name="ios_ppv_price" className="form-control" id="ios_ppv_price" onChange={e => setIos_ppv_price(e.target.value)} >
                <option value="0" >Select IOS PPV Price: </option>
                <option value="123" >123</option>
  
              </select>
            </div>
          </div> */}

            <div className="row">
              <div className="row mt-3 align-items-center ml-3">
                <div className="col-sm-12">
                  {/* <label className="p2" for="global_ppv">Is this video Is Global PPV:</label> */}
                  <input
                    type="text"
                    name="ppv_status"
                    id="ppv_status"
                    onChange={(e) => setPpv_status(e.target.value)}
                  />
                  <input
                    type="text"
                    name="status"
                    id="status"
                    onChange={(e) => setStatus(e.target.value)}
                  />
                  <input
                    type="text"
                    name="draft"
                    id="draft"
                    onChange={(e) => setDraft(e.target.value)}
                  />
                  <input
                    type="text"
                    name="views"
                    id="views"
                    onChange={(e) => setViews(e.target.value)}
                  />
                  <input
                    type="text"
                    name="age_restrict"
                    id="age_restrict"
                    onChange={(e) => setAge_restrict(e.target.value)}
                  />

                  <div className="global_ppv_status">
                    <label className="p2" for="global_ppv">
                      Is this video Is Global PPV:
                    </label>
                    {/* <label className="switch ">
                            <input name="ppv_status" onChange={handleInput}  value={(ppv_status.ppv_status == 1 || ppv_status.ppv_status == null) ? '0' : (ppv_status.ppv_status == 1) ? '0' : '1'} type="checkbox" />
                            <input name="ppv_status" onChange={handleInput}  value={(ppv_status.ppv_status == 1 || ppv_status.ppv_status == null) ? '0' : (ppv_status.ppv_status == 1) ? '0' : '1'} type="checkbox" />
                            <span className="slider round" onChange={handleInput}  name="ppv_status" value={(ppv_status.ppv_status == 1 || ppv_status.ppv_status == null) ? '0' : (ppv_status.ppv_status == 1) ? '0' : '1'}></span>
                          </label> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 p-2 d-flex justify-content-end">
              <input
                type="submit"
                value="Update"
                onClick={Audio}
                className="btn btn-primary"
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid ">
          <div className="pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div className="iq-card addaudio">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">Add Audio</h4>
                    </div>
                  </div>
                  <hr />

                  <Stepper activeStep={activeStep} orientation="horizontal">
                    {steps.map((step, index) => (
                      <Step key={step.label}>
                        <StepContent>
                          <Typography>{step.description}</Typography>
                          <div>
                            <div className="text-end mt-3">
                              {/* <button onClick={handleNext} className='btn btn-primary' hidden={index === 1}>
                                Proceed to Next
                              </button> */}
                              {/* <button
                                hidden={index === 0} className='btn btn-primary'
                                onClick={handleBack}
                              >
                                Back
                              </button> */}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}


export default AddnewAudios