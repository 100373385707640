// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentname{
  border-radius: 10px;
    border: 1px solid #e0e0e0;
    background: #fcfcfc;
    height: 100%;
    width: 100%;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.content-setting {
  color: #000000;
}
.content-setting:hover {
  color: #000000;
}

.libraries .modal-dialog {
  max-width: 60%;
  margin: 3.00rem auto;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Content_Libraries/Main.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;IACjB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,4CAA4C;AAChD;;AAEA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB","sourcesContent":[".contentname{\n  border-radius: 10px;\n    border: 1px solid #e0e0e0;\n    background: #fcfcfc;\n    height: 100%;\n    width: 100%;\n    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);\n}\n\n.content-setting {\n  color: #000000;\n}\n.content-setting:hover {\n  color: #000000;\n}\n\n.libraries .modal-dialog {\n  max-width: 60%;\n  margin: 3.00rem auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
