import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";

function EditTranslationLanguage(props) {
  const { id } = useParams();
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const [editLanguage, setEditLanguage] = useState({
    name: "",
    code: "",
    active: "",
    site_default_language: "",
  });
  const [defaultLanguage, setDefaultLanguage] = useState("");
  const [editsiteLanguage, setEditSiteLanguage] = useState("");
  const [loading, setLoading] = useState(true);
  const [validationMessagename, setValidationMessagename] = useState("");
  const [validationMessagecode, setValidationMessagecode] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);
  const [isValidationHiddencode, setIsValidationHiddencode] = useState(true);
  const nameInputRef = useRef(null);
  const getLanguageData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/translate-language/edit/${id}`,
        { headers: headers }
      );
      setEditLanguage(response?.data?.Languages[0]);
      setActive(response?.data?.Languages[0].active);
      setDefaultLanguage(response?.data?.site_default_language);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(async () => {
    getLanguageData();
  }, []);
  //console.log(editLanguage);
  const [active, setActive] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditLanguage((prevEditLanguage) => ({
      ...prevEditLanguage,
      [name]: value,
    }));
    setValidationMessagename("");
    setIsValidationHiddenname(true);
    setValidationMessagecode("");
    setIsValidationHiddencode(true);
    setEditLanguage({ ...editLanguage, title: e.target.value });
    setEditLanguage({ ...editLanguage, [e.target.name]: e.target.value });
  };
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editLanguage?.name === "") {
      setValidationMessagename("Name cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }
    if (editLanguage?.code === "") {
      setValidationMessagecode("Code cannot be empty.");
      setIsValidationHiddencode(false);
      focusInputRef = nameInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      name: editLanguage?.name,
      code: editLanguage?.code,
      active: editLanguage?.active,
      site_default_language: defaultLanguage,
    };
    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/translate-language/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
        console.log(resjson);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      setValidationMessagename("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddenname(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  const handleCheckbox = (e) => {
    if (e.target.checked === true) {
      setEditLanguage({ ...editLanguage, [e.target.name]: 1 });
    } else {
      setEditLanguage({ ...editLanguage, [e.target.name]: 0 });
    }
  };
  useEffect(() => {
    if (editLanguage.code === defaultLanguage) {
      setDefaultLanguage(editLanguage.code);
    }
  }, [editLanguage]);
  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setDefaultLanguage(editLanguage?.code || "");
    } else {
      setDefaultLanguage("");
    }
  };

  console.log(defaultLanguage);

  // const handleCheckboxChange = (e) => {
  //   if (e.target.checked === true) {
  //     setEditLanguage({  ...editLanguage, [e.target.name]: editLanguage?.code });
  //   } else {
  //     setEditLanguage({  ...editLanguage, [e.target.name]: '' });
  //   }
  // };
  // const handleCheckboxChange1 = (e) => {
  //   const { name, checked } = e.target;

  //   setDefaultLanguage((prevState) => {
  //     if (checked) {
  //       return { ...prevState, [name]: editLanguage?.code }  ;

  //     } else {
  //       const { [name]: removed, ...rest } = prevState;
  //       return rest;
  //     }
  //   });
  // };
  // console.log(defaultLanguage)

  //console.log(editLanguage?.code == defaultLanguage)
  return (
    <>
      <div className="m-0 p-0">
        <div className="">
          <section className="section container-fluid mt-2">
            {/* <Link to="/translate-languages">
              {" "}
              <button className="btn btn-primary">Back</button>
            </Link> */}
            <div className="ms-2 pb-4">
              <button
                type="button"
                style={{ "float": "right" }}
                className="btn btn-primary "
                id="submit-update-cat"
                onClick={handleUpdate}
              >
                {" "}
                Update
              </button>
            </div>
            <div className="mt-4">


              <div className="row">

                <div className="iq-card container-fluid col-md-6">
                  <h4>Edit Translation Languages</h4>
                  <form>
                    <div className="form-group">
                      <label className="p-2">
                        Name <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        onChange={handleChange}
                        value={editLanguage?.name}
                        ref={nameInputRef}
                      />
                      <span className="errorred">
                        {!isValidationHiddenname && (
                          <p>{validationMessagename}</p>
                        )}
                      </span>
                    </div>
                    <div className="form-group">
                      <label className="p-2">
                        Language Code <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="code"
                        name="code"
                        className="form-control"
                        onChange={handleChange}
                        value={editLanguage?.code}
                        ref={nameInputRef}
                      />
                      <span className="errorred">
                        {!isValidationHiddencode && (
                          <p>{validationMessagecode}</p>
                        )}
                      </span>
                    </div>
                  </form>
                </div>
                <div className="iq-card container-fluid col-md-5">
                  <h4>Language Settings</h4>
                  <form>
                    <div className="form-group">
                      <div className="row mx-0 mt-4 ">
                        <div className="col-9 col-sm-9 col-lg-9 ps-0">
                          <p
                            className="theme-text-color"
                            style={{ fontSize: "20px" }}
                          >
                            Language Status
                          </p>
                        </div>
                        <div className="col-3 col-sm-3 col-lg-3 ps-0 text-end">
                          <label className="switch">
                            <input
                              name="active"
                              id="active"
                              onChange={handleCheckbox}
                              defaultChecked={
                                editLanguage?.active === 1 ? true : false
                              }
                              checked={
                                editLanguage?.active === 1 ? true : false
                              }
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="active"
                              onChange={handleCheckbox}
                              value={editLanguage?.active === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row mx-0 mt-4 ">
                        <div className="col-9 col-sm-9 col-lg-9 ps-0">
                          <p
                            className="theme-text-color"
                            style={{ fontSize: "20px" }}
                          >
                            Set Default Language
                          </p>
                        </div>
                        <div className="col-3 col-sm-3 col-lg-3 ps-0 text-end">
                          <label className="switch">
                            <input
                              name="site_default_language"
                              id="site_default_language"
                              onChange={handleCheckboxChange}
                              checked={
                                editLanguage.code === defaultLanguage
                              }
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="site_default_language"
                              onChange={handleCheckboxChange}
                              value={
                                defaultLanguage == editLanguage.code
                                  ? "1"
                                  : "0"
                              }
                            ></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>


              </div>
            </div>

          </section>
        </div>
      </div>
    </>
  );
}

export default EditTranslationLanguage;
