import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import AdminNavbar from "../../../components/CommonComponents/AdminNavbar";
import AdsCategories from "../Ads_Categories/AdsCategories";
import NoRecordTable from "../../../components/CommonComponents/NoRecordTable";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaEllipsisVertical } from "react-icons/fa6";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import AdminHeader from "../../../components/CommonComponents/AdminHeader";

function AdsList(props) {

  const [adsList, setAdsList] = useState([
    {
      adsId: 1,
      adsName: "Test",
      adsCategories: "Test",
      adsStatus: 0,
      adsType: "Pre",
      adsFormat: "url",
      adsDate: "24/02/02"
    },
    {
      adsId: 2,
      adsName: "Test",
      adsCategories: "Test",
      adsStatus: 0,
      adsType: "Post",
      adsFormat: "url",
      adsDate: "24/02/02"
    },
    {
      adsId: 3,
      adsName: "Test",
      adsCategories: "Test",
      adsStatus: 0,
      adsType: "Mid",
      adsFormat: "Video",
      adsDate: "24/02/02"
    }
  ])
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [itemToDelete, setItemToDelete] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filteredData, setFilteredData] = useState([]);


  const options = {
    totalSize: adsList?.length,
    page: currentPage,
    onPageChange: (page) => setCurrentPage(page),
    onSizePerPageChange: (sizePerPage, page) => {
      setPageSize(sizePerPage);
      setCurrentPage(page);
    },
    pageStartIndex: 1,
    paginationSize: 3,
    sizePerPage: pageSize,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: 'All', value: Number(adsList?.length)
    }]
  };

  const columns = [
    {
      dataField: "adsName",
      text: "Ad Name",
      headerClasses: "",
    },
    {
      dataField: "adsType",
      text: "Ad Type",
    },
    {
      dataField: "adsFormat",
      text: "Ad Format",
    },
    {
      dataField: "adsCategories",
      text: "Ad Category",
    },
    {
      dataField: "adsStatus",
      text: "Status",
      formatter: (cell, row) => {
        return <div className="redColorEmail" >{cell == 1 ? "Publish" : "Unpulish"}</div>
      },
    },
    {
      dataField: "adsDate",
      text: "Created At",
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: (cell, row) => <div className="editdropdown rounded-2 ">
        <span className="editdropdown-button">
          <FaEllipsisVertical className="theme-text-color" />
        </span>
        <div className="editdropdown-menu theme-bg-color rounded-2">
          <Link to={`/ads-edit/${row?.adsId}`} className="theme-text-color  theme-bg-color">
            <span className="theme-text-color">
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g>
                  <path class="svgIcon" d="M10.3,18.5l-4.74.64A.49.49,0,0,1,5,18.59l.64-4.74a.42.42,0,0,1,.14-.28L17.31,2a.59.59,0,0,1,.85,0L22.11,6a.6.6,0,0,1,0,.84L10.58,18.36A.53.53,0,0,1,10.3,18.5Z" />
                  <line class="svgIcon" x1="1.01" y1="21.58" x2="18.89" y2="21.58" />
                </g>
              </svg>
              <MessageBox text="Edit" classname='ms-2 theme-text-color'></MessageBox>
            </span>
          </Link>
        </div>
      </div>,
    },
  ];

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDelete([...itemToDelete, row.id]);
        } else {
          setItemToDelete(itemToDelete?.filter((id) => id !== row?.id));
        }
      }
    },
    style: { width: '30px' },
    selected: itemToDelete,
    selectionHeaderRenderer: ({ mode, checked }) => (
      <div>
        <input type="checkbox" checked={checked} onChange={handleAll} />
      </div>
    ),
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDelete(selectAll ? [] : adsList?.map((item) => item?.id));
  };
  const handleFilterChange = (data) => {
    setFilteredData(data);
  };


  const [dataadsList, setDataadsList] = useState([]);
  const [adsListdataadsList, setVideodataadsList] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [defaultImage, setDefaultimage] = useState();
  const [defaultImageurl, setDefaultimageurl] = useState("");

  const [countadsList, setCountadsList] = useState();
  const [currentPageadsList, setCurrentPageadsList] = useState(1);
  const itemsPerPageadsList = 10;

  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [pageadsList, setPageadsList] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  useEffect(() => {
    fetchDataadsList();
    fetchDatanextpreadsList();
  }, [pageadsList]);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers":
      "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "*",
  };

  const adsListsdeleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisers/delete/` + id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          props.setApiresponsealert(responseData);
          props.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props.setApiresponsealert(resultError);
            props.setShowalert(true);
          }
        }
      });
  };

  const [selectedItemsadsList, setSelectedItemsadsList] = useState([]);
  const [selectAlladsList, setSelectAlladsList] = useState(false);
  const [isConfirmationOpenadsList, setIsConfirmationOpenadsList] =
    useState(false);
  const [isSuccessMessageOpenadsList, setIsSuccessMessageOpenadsList] =
    useState(false);

  const handleSingleSelectadsList = (itemId) => {
    setSelectedItemsadsList([itemId]);
    setSelectAlladsList(false);
  };

  const handleMultiSelectadsList = (itemId) => {
    if (selectedItemsadsList.includes(itemId)) {
      setSelectedItemsadsList(
        selectedItemsadsList.filter((id) => id !== itemId)
      );
    } else {
      setSelectedItemsadsList([...selectedItemsadsList, itemId]);
    }
  };

  const handleSelectAlladsList = () => {
    setSelectAlladsList(!selectAlladsList);
    setSelectedItemsadsList(
      selectAlladsList ? [] : adsListdataadsList.map((item) => item?.id)
    );
  };

  const handleDeleteadsList = () => {
    setIsConfirmationOpenadsList(true);
  };
  const handleCancelDeleteadsList = () => {
    setIsConfirmationOpenadsList(false);
  };

  const handleConfirmDeleteadsList = () => {
    setIsConfirmationOpenadsList(false);
    for (const itemId of selectedItemsadsList) {
      axios
        .delete(
          `${process.env.REACT_APP_Baseurl}/admin/Advertisers/delete/${itemId}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.data.status == true) {
            setVideodataadsList(
              adsListdataadsList.filter((item) => item?.id !== itemId)
            );
            setSelectedItemsadsList(
              selectedItemsadsList.filter((id) => id !== itemId)
            );
            setIsSuccessMessageOpenadsList(true);
            setTimeout(() => {
              setIsSuccessMessageOpenadsList(false);
            }, 2000);
            var responseData = response.data;
            props.setApiresponsealert(responseData);
            props.setShowalert(true);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            if (responseData === false) {
              var resultError = responseData;
              props.setApiresponsealert(resultError);
              props.setShowalert(true);
            }
          }
        });
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleButtonClick = (status) => {
    setSelectedStatus(status);
    setShowModal(true);
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function handleConfirm() {
    // if (!selectedStatus) {
    //   // Handle the case where no status is selected
    //   return;
    // }

    // const filteredItems = currentItemsadsList?.filter((item) =>
    //   item?.Adverister_Status.some((itemdata) => itemdata?.name === selectedStatus)
    // );

    // const updateData = filteredItems.flatMap((item) =>
    //   item?.Adverister_Status
    //     .filter(
    //       (itemdata) =>
    //         itemdata?.name === selectedStatus && itemdata?.adverister_status !== null
    //     )
    //     .map((itemdata) => ({
    //       adsList_id: item?.id,
    //       status_update: itemdata?.adverister_status,
    //     }))
    // );

    if (!selectedStatus) {
      // Handle the case where no status is selected
      return;
    }

    let updateData = null;

    for (const item of currentItemsadsList) {
      for (const itemdata of item?.Adverister_Status) {
        if (
          itemdata?.name === selectedStatus &&
          itemdata?.adverister_status !== null
        ) {
          updateData = {
            adsList_id: item?.id,
            adverister_status: itemdata.adverister_status,
          };
          break; // Exit the loop once a match is found
        }
      }
      if (updateData) {
        break; // Exit the outer loop once a match is found
      }
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Advertiserss/status_update`,
        updateData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;
        setShowModal(false);

        // Reset the selected status
        setSelectedStatus(null);
        resultapi = response.data;
      } else if (response.data.status === false) {
        resultapifalse = response.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        // window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      props.setApiresponsealert(resultapifalse);
      props.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  }

  const fetchDatanextpreadsList = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/advertiserss_list?page=${pageadsList}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const adsListsData = res?.data?.Advertiser_pagination?.data;
        setVideodataadsList(adsListsData);
        if (adsListsData.length > 0) {
          setDataadsList(adsListsData);
          setLoading(false);
        } else {
          setDataadsList([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchDataadsList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/advertiserss_list`,
        { headers: headers }
      );
      setCountadsList(response?.data?.Total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const indexOfLastItemadsList = currentPageadsList * itemsPerPageadsList;
  const indexOfFirstItemadsList = indexOfLastItemadsList - itemsPerPageadsList;
  const currentItemsadsList = dataadsList?.slice(
    indexOfFirstItemadsList,
    indexOfLastItemadsList
  );

  const handlePreClickadsList = () => {
    if (pageadsList > 1) {
      setPageadsList(pageadsList - 1);
    }
  };

  const handleNxtClickadsList = () => {
    setPageadsList(pageadsList + 1);
  };

  const [itemToDeleteadsList, setItemToDeleteadsList] = useState(null);
  const [successMessageadsList, setSuccessMessageadsList] = useState("");

  const openModaladsList = (id) => {
    setItemToDeleteadsList(id);
    setSuccessMessageadsList("");
  };

  const closeModaladsList = () => {
    setItemToDeleteadsList(null);
  };
  const handleDeletedataadsList = () => {
    closeModaladsList();
    if (itemToDeleteadsList) {
      adsListsdeleteOperation(itemToDeleteadsList);
    }
  };

  return (
    <>
      <>
        <div
          className={`modal ${itemToDeleteadsList !== null ? "show" : ""}`}
          tabIndex="-1"
          style={{
            display: itemToDeleteadsList !== null ? "block" : "none",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Deletion</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModaladsList}
                ></button>
              </div>
              <div className="modal-body">
                {successMessageadsList ? (
                  <div className="alert alert-success" role="alert">
                    {successMessageadsList}
                  </div>
                ) : (
                  <p>Are you sure you want to delete this adsList?</p>
                )}
              </div>
              <div className="modal-footer">
                {successMessageadsList ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={closeModaladsList}
                  >
                    Close
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={closeModaladsList}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={handleDeletedataadsList}
                    >
                      Yes
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog delete_confirm">
            <div className="modal-content text-center">
              <div className="modal-body">
                {isConfirmationOpenadsList && (
                  <div>
                    <h5 className="modal-title p-3" id="exampleModalLabel">
                      <p className="fs-6">
                        Are you sure you want to delete the selected items?
                      </p>
                    </h5>
                    <div className="col-lg-12 row p-4">
                      <div className="col-lg-6">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            handleConfirmDeleteadsList();
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                      <div className="col-lg-6">
                        <button
                          className="btn btn-primary"
                          onClick={handleCancelDeleteadsList}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {isSuccessMessageOpenadsList && (
                  <div className="success-message">
                    <div className="p-5">
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <p className="fs-6 fw-bold">
                      Selected items have been successfully deleted.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <div
            className="modal fade show"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirmation</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowModal(false)}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <p>
                    Are you sure you want to set the status to "{selectedStatus}
                    "?
                  </p>
                </div>
                <div className="modal-footer">
                  <button onClick={handleConfirm} className="btn btn-primary">
                    Yes
                  </button>
                  <button
                    onClick={() => setShowModal(false)}
                    className="btn btn-secondary"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <AdminNavbar heading={'Advertisement List'} totalDataLength={adsList?.length} deleteCount={0} navigation={'/ads-upload'} deleteMethod={null} navText={'Add Ads'} />
        <div className="bootstrapTable p-3 rounded-2 theme-bg-color">
          <AdminHeader searchMethod={handleFilterChange} reduceData={adsList} originalData={adsList} fileName={'Advertisement'} />
          <BootstrapTable keyField="id" pagination={paginationFactory(options)} columns={columns} data={adsList} className="no-border-table theme-text-color " noDataIndication={<NoRecordTable result={adsList?.length} loader={false} />} selectRow={selectRow} selectAll={selectAll} hover headerClasses="videoWidth" />
        </div>
      </>
    </>
  );
}

export default AdsList;