import React from 'react'

const About_MyProfile = () => {
  return (
    <div>
        <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="iq-card">
                  <div id="moderator-container">
                    <div className="moderator-section-title">
                      <h4>
                        <i className="entypo-globe"></i> My Profile
                      </h4>
                    </div>
                    <div className="clear"></div>
                    <hr />

                    <form
                      method="POST"
                      action=""
                      accept-charset="UTF-8"
                      file="1"
                      enctype="multipart/form-data"
                      id="Moderator_form">

                      <div className="row justify-content-between p-3">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="name"
                              className=" col-form-label text-md-right"
                            >
                              User Name:
                            </label>
                            <input
                              id="name"
                              type="text"
                              className="form-control"
                              name="username"
                              // value="{{ old('username') }}"
                              autocomplete="username"
                              autofocus
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="email"
                              className=" col-form-label text-md-right"
                            >
                             Email:
                            </label>
                            <input
                              id="email_id"
                              type="email"
                              className="form-control "
                              name="email_id"
                              // value="{{ old('email_id') }}"
                              autocomplete="email"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="mobile_number"
                              className=" col-form-label text-md-right"
                            >
                              Phone Number:
                            </label>
                            <input
                              id="mobile_number"
                              type="number"
                              className="form-control "
                              name="mobile_number"
                              // value="{{ old('mobile_number') }}"
                              autocomplete="email"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="name"
                              className=" col-form-label text-md-right"
                            >
                              Description:
                            </label>
                            <input
                              id="name"
                              type="text"
                              className="form-control"
                              name="username"
                              // value="{{ old('username') }}"
                              autocomplete="username"
                              autofocus
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="mobile_number"
                              className=" col-form-label text-md-right"
                            >
                              UPI ID:
                            </label>
                            <input
                              id="mobile_number"
                              type="number"
                              className="form-control "
                              name="mobile_number"
                              // value="{{ old('mobile_number') }}"
                              autocomplete="email"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="mobile_number"
                              className=" col-form-label text-md-right"
                            >
                             UPI Phone Number:
                            </label>
                            <input
                              id="mobile_number"
                              type="number"
                              className="form-control "
                              name="mobile_number"
                              // value="{{ old('mobile_number') }}"
                              autocomplete="email"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="picture"
                              className=" col-form-label text-md-right"
                            >
                              Banner :
                            </label>
                            <input
                              id="picture"
                              type="file"
                              className="form-control"
                              name="picture"
                            />
                            <p className="text" id="error_picture">
                              {" "}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="picture"
                              className=" col-form-label text-md-right"
                            >
                             Profile Picture:
                            </label>
                            <input
                              id="picture"
                              type="file"
                              className="form-control"
                              name="picture"
                            />
                            <p className="text" id="error_picture">
                              {" "}
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              for="picture"
                              className=" col-form-label text-md-right"
                            >
                              Intro Video:
                            </label>
                            <input
                              id="picture"
                              type="file"
                              className="form-control"
                              name="picture"
                            />
                            <p className="text" id="error_picture">
                              {" "}
                            </p>
                          </div>
                        </div>
                         <div className="form-group row mb-0">
                          <div className="col-md-12 ">
                            <button type="submit" className="btn btn-primary">
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default About_MyProfile