import React from 'react'
import { Link } from 'react-router-dom'

const All_pages = () => {
  return (
    <div>
        <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card">
              <div className="admin-section-title">
                <div className="">
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <h4>
                        <i className="entypo-newspaper"></i> Page
                      </h4>
                    </div>
                    <div className="col-md-8" align="right">
                      <Link to="/admin/content/add-pages" className="btn btn-primary">
                        <i className="fa fa-plus-circle"></i> Add New
                      </Link>
                    </div>
                  </div>
                  <div className="gallery-env">
                    <div className="row p-3 justify-content-center">
                      <table className="table table-bordered genres-table text-center allpage">
                        <thead>
                          <tr className="table-header r1">
                            <th>Page</th>
                            <th>URL</th>
                            <th>Active</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {allpages.map((item, key) => (
                            <tr>
                              <td>
                                <Link className="title" to="" target="_blank">
                                  {item.title}
                                </Link>
                              </td>
                              <td>
                                <p>{item.slug}</p>
                              </td>
                              <td className="activeinactive">
                                {item.active === 0 || item.active === null ? (
                                  <td className="bg-warning video_active">
                                    Inactive
                                  </td>
                                ) : item.active === 1 &&
                                  item.active === 1 &&
                                  item.active === 1 ? (
                                  <td className="bg-success video_active">
                                    Active
                                  </td>
                                ) : (
                                  <td className="bg-warning video_active">
                                    Draft
                                  </td>
                                )}
                              </td>

                             
                              <td>
                                
                                <Link
                                  to={"/admin/editpages/" + item.id}
                                  className="edit ms-2"
                                >
                                  <i
                                    className="fa fa-pencil-square"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                                <Link to="" className="delete ms-2">
                                  <span
                                    onClick={() => deleteOperation(item.id)}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          ))} */}
                        </tbody>
                      </table>
                    </div>
                    <div className="row text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                        //   onClick={handlePreClick}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">
                            {/* {page} */}
                            </p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                        //   onClick={handleNxtClick}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default All_pages