import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import JoditEditor from "jodit-react";
import "./AddSubscriptionPlan.css";

const AddSubscriptionPlan = (props) => {
  const [payment_type, setPayment_type] = useState("recurring");

  const handlePaymentTypeChange = (e) => {
    setPayment_type(e.target.value);
  };

  const [paymentsettings, setPaymentsettings] = useState([]);
  const [plan_id, setPlan_id] = useState("");
  const [user_id, setUser_id] = useState("");
  const [plans_name, setPlans_name] = useState("");
  const [plan_idstripe, setPlan_idstripe] = useState("");
  const [plan_idpaypal, setPlan_idpaypal] = useState("");
  const [plan_idrazropay, setPlan_idrazropay] = useState("");
  const [plan_idpaystack, setPlan_idpaystack] = useState("");
  const [billing_interval, setBilling_interval] = useState("");
  const [billing_type, setBilling_type] = useState("");
  // const [payment_type, setPayment_type] = useState("");
  const [type, setType] = useState("");
  const [days, setDays] = useState("");
  const [price, setPrice] = useState("");
  const [andriod_paystack_url, setAndriod_paystack_url] = useState("");
  const [video_quality, setVideo_quality] = useState("");
  const [plan_content, setPlan_content] = useState("");
  const [resolution, setResolution] = useState("");
  const [devices, setDevices] = useState([]);
  const [subscription_plan_name, setSubscription_plan_name] = useState("");
  const [ios_plan_price, setIos_plan_price] = useState("");
  const [plan_trail_days, setPlan_trail_days] = useState("");
  const [ios_product_id, setIos_product_id] = useState("");
  const [ads_status, setAds_status] = useState("");
  const [plan_trail_status, setPlan_trail_status] = useState("");

  const [body, setBody] = useState("");

  const [number, setNumber] = useState([]);
  const [user, setUser] = useState([]);

  const [editUser, setEditUser] = useState({
    plan_name: "",
    plan_id: "",
    days: "",
    price: "",
    billing_interval: "",
    billing_type: "",
    video_quality: "",
    resolution: "",
  });

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleInputs = (e) => {
    if (e.target.checked === true) {
      setAds_status({ ...ads_status, [e.target.name]: 1 });
      setPlan_trail_status({ ...plan_trail_status, [e.target.name]: 1 });
    } else {
      setAds_status({ ...ads_status, [e.target.name]: 0 });
      setPlan_trail_status({ ...plan_trail_status, [e.target.name]: 0 });
    }
  };

  const handleInputChange = (event) => {
    const deviceIds = event.target.value.split(",");
    setDevices(deviceIds);
    setSelectedDevice(event.target.value);
  };

  // console.log('artist_id',artist_id)
  const [data, setData] = useState([]);
  const [symbol, setSymbol] = useState("");

  const contentFieldChanaged = (data) => {
    setBody(data);
  };

  const handleInputdays = (e) => {
    const inputValue = e.target.value;
    if (/^[1-9]\d*$/.test(inputValue) || inputValue === "") {
      setPlan_trail_days(inputValue);
    }
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageplan_name("");
    setIsValidationHiddenplan_name(true);

    setValidationMessageplan_id("");
    setIsValidationHiddenplan_id(true);

    setValidationMessageprice("");
    setIsValidationHiddenprice(true);

    setValidationMessagedays("");
    setIsValidationHiddendays(true);

    setValidationMessageresolution("");
    setIsValidationHiddenresolution(true);

    setValidationMessagebilling_interval("");
    setIsValidationHiddenbilling_interval(true);

    setValidationMessagebilling_type("");
    setIsValidationHiddenbilling_type(true);

    setValidationMessagevideo_quality("");
    setIsValidationHiddenvideo_quality(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [validationMessageplan_name, setValidationMessageplan_name] =
    useState("");
  const [isValidationHiddenplan_name, setIsValidationHiddenplan_name] =
    useState(true);

  const [validationMessageplan_id, setValidationMessageplan_id] = useState("");
  const [isValidationHiddenplan_id, setIsValidationHiddenplan_id] =
    useState(true);

  const [validationMessageprice, setValidationMessageprice] = useState("");
  const [isValidationHiddenprice, setIsValidationHiddenprice] = useState(true);

  const [validationMessagedays, setValidationMessagedays] = useState("");
  const [isValidationHiddendays, setIsValidationHiddendays] = useState(true);

  const [validationMessageresolution, setValidationMessageresolution] =
    useState("");
  const [isValidationHiddenresolution, setIsValidationHiddenresolution] =
    useState(true);

  const [validationMessagebilling_type, setValidationMessagebilling_type] =
    useState("");
  const [isValidationHiddenbilling_type, setIsValidationHiddenbilling_type] =
    useState(true);

  const [
    validationMessagebilling_interval,
    setValidationMessagebilling_interval,
  ] = useState("");
  const [
    isValidationHiddenbilling_interval,
    setIsValidationHiddenbilling_interval,
  ] = useState(true);

  const [validationMessagevideo_quality, setValidationMessagevideo_quality] =
    useState("");
  const [isValidationHiddenvideo_quality, setIsValidationHiddenvideo_quality] =
    useState(true);

  const plan_nameInputRef = useRef(null);
  const plan_idInputRef = useRef(null);
  const daysInputRef = useRef(null);
  const priceInputRef = useRef(null);
  const video_qualityInputRef = useRef(null);
  const billing_intervalInputRef = useRef(null);
  const billing_typeInputRef = useRef(null);
  const resolutionInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const [selectedDevice, setSelectedDevice] = useState("");
  const [isValidationHiddenDevice, setIsValidationHiddenDevice] =
    useState(true);

  async function savePage() {
    let focusInputRef = null;

    if (editUser?.plan_name === "") {
      setValidationMessageplan_name("Plan Name cannot be empty.");
      setIsValidationHiddenplan_name(false);
      focusInputRef = plan_nameInputRef;
    }
    if (plan_id === "") {
      setValidationMessageplan_id(
        "Please Enable Payment Plan or Plan ID cannot be empty."
      );
      setIsValidationHiddenplan_id(false);
      focusInputRef = plan_idInputRef;
    }
    if (editUser?.billing_interval === "") {
      setValidationMessagebilling_interval("Billing Interval cannot be empty.");
      setIsValidationHiddenbilling_interval(false);
      focusInputRef = billing_intervalInputRef;
    }
    if (editUser?.billing_type === "") {
      setValidationMessagebilling_type("Billing Type cannot be empty.");
      setIsValidationHiddenbilling_type(false);
      focusInputRef = billing_typeInputRef;
    }
    if (editUser?.price === "") {
      setValidationMessageprice("Price cannot be empty.");
      setIsValidationHiddenprice(false);
      focusInputRef = priceInputRef;
    }
    if (editUser?.days === "") {
      setValidationMessagedays("Days cannot be empty.");
      setIsValidationHiddendays(false);
      focusInputRef = daysInputRef;
    }
    if (editUser?.video_quality === "") {
      setValidationMessagevideo_quality("Video Quality cannot be empty.");
      setIsValidationHiddenvideo_quality(false);
      focusInputRef = video_qualityInputRef;
    }
    if (editUser?.resolution === "") {
      setValidationMessageresolution("Resolution cannot be empty.");
      setIsValidationHiddenresolution(false);
      focusInputRef = resolutionInputRef;
    }
    if (selectedDevice) {
      setIsValidationHiddenDevice(true); // Hide validation message
      // Perform other actions or submit data if needed
    } else {
      setIsValidationHiddenDevice(false); // Show validation message
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    const editInputvalue = ads_status?.ads_status;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;
    const editInputplan_trail_status = plan_trail_status?.plan_trail_status;
    var data = JSON.stringify(editInputplan_trail_status);
    var plan_trail_statusdata = data;

    const editInputdevices = devices?.devices;
    var datadevices = JSON.stringify(editInputdevices);
    var aactivedatadevices = datadevices;

    const formData = new FormData();

    formData.append("plans_name", editUser?.plan_name);
    paymentsettings?.forEach((item, index) => {
      if (typeof item?.payment_type === "string") {
        var data = item?.payment_type;
        formData.append(`plan_id[${data}]`, plan_id);
      }
    });
    formData.append("billing_interval", editUser?.billing_interval);
    formData.append("billing_type", editUser?.billing_type);
    formData.append("payment_type", payment_type);
    formData.append("type", type);
    const daysdataa = Number.isNaN(parseInt(editUser?.days, 10)) ? 0 : parseInt(editUser?.days, 10);
    formData.append("days", daysdataa);
    formData.append("price", editUser?.price);
    formData.append("andriod_paystack_url", andriod_paystack_url);
    formData.append("video_quality", editUser?.video_quality);
    formData.append("plan_content", body);
    formData.append("resolution", editUser?.resolution);
    formData.append("devices", devices?.join(","));
    formData.append("subscription_plan_name", subscription_plan_name);
    formData.append("ios_plan_price", ios_plan_price);
    formData.append("ios_product_id", ios_product_id);
    const aactivedataactive = Number.isNaN(parseInt(aactivedata, 10)) ? 0 : parseInt(aactivedata, 10);
    formData.append("ads_status", aactivedataactive);
    const statusplan_trail_days = Number.isNaN(parseInt(plan_trail_days, 10)) ? 0 : parseInt(plan_trail_days, 10);
    formData.append("plan_trail_days", statusplan_trail_days);
    const statusplan_trail_statusdata = Number.isNaN(parseInt(plan_trail_statusdata, 10)) ? 0 : parseInt(plan_trail_statusdata, 10);
    formData.append("plan_trail_status", statusplan_trail_statusdata);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/subscription/store_plan`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;
        resultapi = response.data;
      }
    } catch (error) {}

    if (firstApiStatus) {
      setValidationMessageplan_name("");
      setValidationMessageplan_id("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        navigate("/subscription-plans");
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        console.log(resultError);
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddenplan_name(false);
        setIsValidationHiddenplan_id(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/subscription/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.Devices;
        setData(result);
        var results = response?.data?.country;
        var paymentsettings = response?.data?.PaymentSetting;
        setNumber(results);
        setPaymentsettings(paymentsettings);
      })
      .catch((error) => console.log(error));
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/currency_setting/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.CurrencySetting[0].symbol;
        setSymbol(result);
      })
      .catch((error) => console.log(error));
  }, []);

  const myArray = [];

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Add Plan</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={savePage} className="btn btn-primary">
                    Save Page
                  </button>
                </div>

                <div className="row text-end">
                  <div className="col-lg-12">
                    <div className="mt-2">
                      <span className="errorred">
                        {!isValidationHiddenplan_id && (
                          <p>{validationMessageplan_id}</p>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3 ">
                  <h5>Create Page</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Plan Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        // id="plan_name"
                        name="plan_name"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.plan_name}
                        placeholder=""
                        ref={plan_nameInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenplan_name && (
                        <p>{validationMessageplan_name}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    {/* <label className="m-0">Slug</label> */}

                    <div className="mt-2">
                      {paymentsettings?.map((item, index) => {
                        const value = item?.payment_type;
                        const value2 = item?.payment_type;

                        if (value && value2) {
                          return (
                            <div className="mt-2">
                              <label>
                                {value} Plan ID
                                <span className="mandatory">*</span>
                              </label>
                              <div className="mt-2">
                                {" "}
                                <input
                                  type="text"
                                  id={value2}
                                  name={value2}
                                  onChange={(e) => setPlan_id(e.target.value)}
                                  className="rs-input form-control-lg"
                                  placeholder=""
                                  ref={plan_idInputRef}
                                />
                              </div>
                              <span className="errorred">
                                {!isValidationHiddenplan_id && (
                                  <p>{validationMessageplan_id}</p>
                                )}
                              </span>
                            </div>
                          );
                        } else {
                          return <div></div>;
                        }
                      })}
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">
                      Billing Interval<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="billing_interval"
                        name="billing_interval"
                        onChange={handleInput}
                        value={editUser?.billing_interval}
                        // onChange={(e) => setBilling_interval(e.target.value)}
                        placeholder=""
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenbilling_interval && (
                        <p>{validationMessagebilling_interval}</p>
                      )}
                    </span>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">
                      Billing Type<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="billing_type"
                        name="billing_type"
                        onChange={handleInput}
                        value={editUser?.billing_type}
                        // onChange={(e) => setBilling_type(e.target.value)}
                        placeholder=""
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenbilling_type && (
                        <p>{validationMessagebilling_type}</p>
                      )}
                    </span>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Andriod Paystack Url</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="billing_type"
                        name="andriod_paystack_url"
                        onChange={(e) =>
                          setAndriod_paystack_url(e.target.value)
                        }
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">Page Content</label>

                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input"
                          value={body}
                          onChange={(newContent) =>
                            contentFieldChanaged(newContent)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3 mb-0">
                  <h5>Status Settings</h5>
                  <hr></hr>

                  <div className="row mb-3">
                    <div className="col-lg-10">
                      <div className="">
                        <label>Enable this Page Ads </label>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div>
                        <label className="switch">
                          <input
                            name="ads_status"
                            onChange={handleInputs}
                            defaultChecked={
                              ads_status?.ads_status === 1 ? true : false
                            }
                            checked={
                              ads_status?.ads_status === 1 ? true : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="ads_status"
                            onChange={handleInputs}
                            value={ads_status?.ads_status === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-10">
                      <div className="">
                        <label>Enable this Plan Trail </label>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div>
                        <label className="switch">
                          <input
                            name="plan_trail_status"
                            onChange={handleInputs}
                            defaultChecked={
                              plan_trail_status?.plan_trail_status === 1
                                ? true
                                : false
                            }
                            checked={
                              plan_trail_status?.plan_trail_status === 1
                                ? true
                                : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="plan_trail_status"
                            onChange={handleInputs}
                            value={
                              plan_trail_status?.plan_trail_status === 1
                                ? "1"
                                : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row text-start">
                  <div className="col-lg-12">
                    <div className="mt-2">
                      <span className="errorred">
                        {!isValidationHiddenplan_id && (
                          <p>{validationMessageplan_id}</p>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="text-start mt-2 mb-3">
                  <button onClick={savePage} className="btn btn-primary">
                    Save Page
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Plan Description</h5>
                  <hr></hr>

                  <div className="text-start">
                    <label className="m-0">
                      Price ( {symbol} )<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="price"
                        name="price"
                        className="rs-input form-control-lg"
                        placeholder=""
                        onChange={handleInput}
                        value={editUser?.price}
                        ref={priceInputRef}
                      />
                    </div>
                    <div className="mt-2">
                      <span className="errorred">
                        {!isValidationHiddenprice && (
                          <p>{validationMessageprice}</p>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Days<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="days"
                        name="days"
                        className="rs-input form-control-lg"
                        placeholder=""
                        onChange={handleInput}
                        value={editUser?.days}
                        ref={daysInputRef}
                      />
                    </div>
                    <div className="mt-2">
                      <span className="errorred">
                        {!isValidationHiddendays && (
                          <p>{validationMessagedays}</p>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Plan Trail Days</label>

                    <div className="mt-2">
                      {/* <input
                        type="number"
                        // id="plan_trail_days"
                        name="plan_trail_days"
                        className="rs-input form-control-lg"
                        placeholder=""
                        onChange={(e) => setPlan_trail_days(e.target.value)}
                      /> */}
                      <input
                        type="text"
                        name="plan_trail_days"
                        className="rs-input form-control-lg"
                        placeholder=""
                        onChange={handleInputdays}
                        value={plan_trail_days}
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Video Quality<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="days"
                        name="video_quality"
                        className="rs-input form-control-lg"
                        placeholder=""
                        onChange={handleInput}
                        value={editUser?.video_quality}
                        // onChange={(e) => setVideo_quality(e.target.value)}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenvideo_quality && (
                        <p>{validationMessagevideo_quality}</p>
                      )}
                    </span>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Resolution<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="days"
                        name="resolution"
                        onChange={handleInput}
                        value={editUser?.resolution}
                        className="rs-input form-control-lg"
                        placeholder=""
                        // onChange={(e) => setResolution(e.target.value)}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenresolution && (
                        <p>{validationMessageresolution}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">IOS Product ID</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="days"
                        name="ios_product_id"
                        className="rs-input form-control-lg"
                        placeholder=""
                        onChange={(e) => setIos_product_id(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">IOS Plan Price ( {symbol} )</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="days"
                        name="ios_plan_price"
                        className="rs-input form-control-lg"
                        placeholder=""
                        onChange={(e) => setIos_plan_price(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Payment Status</h5>
                  <hr></hr>

                  <div className="text-start">
                    <label className="m-0">Payment Type</label>

                    <div className="mt-2">
                      <div
                        className="row text-start"
                        onChange={handlePaymentTypeChange}
                      >
                        {/* <div className="form-check  col-md-6 mt-2 ps-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="one_time"
                            checked={payment_type === "one_time"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            One Time Payment
                          </label>
                        </div> */}
                        <div className="form-check col-md-6 mt-2 ps-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="recurring"
                            checked={payment_type === "recurring"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            Recurring
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3 ">
                  <h5>Devices </h5>

                  <hr></hr>

                  <div className="text-start">
                    {/* <h5>Devices</h5> */}

                    {/* <hr></hr> */}
                    <div
                      className=""
                      name="device"
                      onChange={handleInput}
                      value={editUser?.device}
                    >
                      {data?.map((item, index) => {
                        const value = item?.id;
                        const devices_name = item?.devices_name;
                        // const deviceIds = devices.split(",");
                        // const devices = deviceIds.filter((val) =>
                        //   val.includes(item?.id)
                        // );
                        // const array =
                        //   myArray.filter((item) => item !=== null).length ====
                        //   0;

                        return (
                          <div className="row">
                            <div className="col-lg-10">
                              <div className="mt-1">
                                <label>{devices_name}</label>
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <div className="mt-2">
                                <label className="switch">
                                  <input
                                    name="active"
                                    value={value}
                                    onChange={handleInputChange}
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="active"
                                    value={value}
                                    onChange={handleInputChange}
                                  ></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenDevice && (
                        <p>Please select a device.</p>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card mt-4">
              <div id="content-page" className="content-page">
                <div className="container-fluid">
                  <div className="admin-section-title">
                    <div className="">
                      <div className="row">
                        <div className="col-md-3">
                          <h4>
                            <i className="entypo-archive"></i> Add Plan
                          </h4>
                        </div>
                      </div>

                      <hr></hr>

                      <div className="form-group ">
                        <label>Plan Name:</label>
                        <input
                          type="text"
                          id="plans_name"
                          name="plans_name"
                          onChange={(e) => setPlans_name(e.target.value)}
                          className="form-control"
                          placeholder="Plan Name"
                        />
                      </div>

                      <div className="form-group pt-0">
                        {paymentsettings?.map((item, index) => {
                          const value = item?.payment_type;
                          const value2 = item?.payment_type;

                          if (value && value2) {
                            return (
                              <div className="mt-2">
                                <label>{value} Plan ID:</label>
                                <input
                                  type="text"
                                  id={value2}
                                  name={value2}
                                  onChange={(e) => setPlan_id(e.target.value)}
                                  className="form-control mt-2"
                                  placeholder="Plan ID"
                                />
                              </div>
                            );
                          } else {
                            return <div></div>;
                          }
                        })}
                      </div>

                      <div className="form-group ">
                        <label>Stripe Plan ID:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) => setPlan_idstripe(e.target.value)}
                          className="form-control"
                          placeholder="Plan ID"
                        />
                        <small>* Get Plan Key From Stripe</small>
                      </div>

                      <div className="form-group ">
                        <label>Paystack Plan ID:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          onChange={(e) => setPlan_idpaystack(e.target.value)}
                          placeholder="Plan ID"
                        />
                        <small>* Get Plan Key From Paystack</small>
                      </div>

                      <div className="form-group ">
                        <label>Paypal Plan ID:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) => setPlan_idpaypal(e.target.value)}
                          className="form-control"
                          placeholder="Plan ID"
                        />
                        <small>* Get Plan Key From Paypal</small>
                      </div>

                      <div className="form-group ">
                        <label>Razropay Plan ID:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) => setPlan_idrazropay(e.target.value)}
                          className="form-control"
                          placeholder="Plan ID"
                        />
                        <small>* Get Plan Key From Razropay</small>
                      </div>

                      <div className="form-group ">
                        <label>Billing Interval:</label>
                        <input
                          type="text"
                          id="billing_interval"
                          name="billing_interval"
                          onChange={(e) => setBilling_interval(e.target.value)}
                          className="form-control"
                          placeholder="etc..Monthly, Yearly, 3 Month.."
                        />
                      </div>

                      <div className="form-group ">
                        <label>Billing Type:</label>
                        <input
                          type="text"
                          id="billing_type"
                          name="billing_type"
                          onChange={(e) => setBilling_type(e.target.value)}
                          className="form-control"
                          placeholder="Example... Non Refundable"
                        />
                      </div>

                      <div className="panel panel-primary" data-collapsed="0">
                        <div className="panel-heading">
                          <div className="panel-title">
                            <label>Page Content</label>
                          </div>

                          <div className="panel-options">
                            <Link
                              to="#"
                              data-rel="collapse"
                              name="plan_content"
                            >
                              <i className="entypo-down-open"></i>
                            </Link>
                          </div>
                        </div>
                       
                        <div className="mt-2">
                          <JoditEditor
                            // ref={editor}
                            value={plan_content}
                            onChange={(newContent) =>
                              contentFieldChanaged(newContent)
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group ">
                        <label>Andriod Paystack Url</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) =>
                            setAndriod_paystack_url(e.target.value)
                          }
                          className="form-control"
                        />
                      </div>

                      <div className="row" onChange={handlePaymentTypeChange}>
                        <label>Payment Type:</label>

                        <div className="form-check col-md-4 mt-2 ps-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="one_time"
                            checked={payment_type === "one_time"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            One Time Payment
                          </label>
                        </div>
                        <div className="form-check col-md-4 mt-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="recurring"
                            checked={payment_type === "recurring"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            Recurring
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        <label>Payment Type:</label>

                        <div className="form-check col-md-4 mt-2 ps-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            value="one_time"
                            onChange={(e) => setPayment_type(e.target.value)}
                            checked
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault1"
                          >
                            One Time Payment
                          </label>
                        </div>
                        <div className="form-check col-md-4 mt-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            value="recurring"
                            id="flexRadioDefault2"
                            onChange={(e) => setPayment_type(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault2"
                          >
                            Recurring
                          </label>
                        </div>
                      </div>

                      <div className="form-group ">
                        <label>Price ( $ ):</label>
                        <input
                          type="text"
                          id="price"
                          name="price"
                          className="form-control"
                          placeholder="Price"
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Days :</label>
                        <input
                          type="text"
                          id="days"
                          name="days"
                          className="form-control"
                          placeholder="Days"
                          onChange={(e) => setDays(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Video Quality:</label>
                        <input
                          type="text"
                          id="video_quality"
                          name="video_quality"
                          className="form-control"
                          placeholder="Quality"
                          onChange={(e) => setVideo_quality(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Resolution :</label>
                        <input
                          type="text"
                          id="resolution"
                          name="resolution"
                          className="form-control"
                          placeholder="Resolution"
                          onChange={(e) => setResolution(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>IOS Product ID :</label>
                        <input
                          type="text"
                          id="ios_product_id"
                          name="ios_product_id"
                          className="form-control"
                          onChange={(e) => setIos_product_id(e.target.value)}
                          placeholder="IOS Product ID"
                        />
                      </div>

                      <div className="form-group ">
                        <label>IOS Plan Price ( $ ):</label>
                        <input
                          type="text"
                          id="ios_plan_price"
                          name="ios_plan_price"
                          className="form-control"
                          onChange={(e) => setIos_plan_price(e.target.value)}
                          placeholder="IOS Plan Price"
                        />
                      </div>

                      <div className="row mt-3 form-group">
                        <div className="col-lg-6">
                          <div className="row">
                            <div className="col-lg-6">
                              <label>Enable Ads</label>
                            </div>
                            <div className="col-lg-6">
                              <label className="switch">
                                <input
                                  name="ads_status"
                                  onChange={handleInputs}
                                  defaultChecked={
                                    ads_status?.ads_status === 1 ? true : false
                                  }
                                  checked={
                                    ads_status?.ads_status === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="ads_status"
                                  onChange={handleInputs}
                                  value={
                                    ads_status?.ads_status === 1 ? "1" : "0"
                                  }
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3 row form-group">
                        <h5>Devices :</h5>

                        {data?.map((item, index) => {
                          const value = item?.id;
                          const devices_name = item?.devices_name;
                          // const deviceIds = devices.split(",");
                          // const devices = deviceIds.filter((val) =>
                          //   val.includes(item?.id)
                          // );
                          // const array =
                          //   myArray.filter((item) => item !=== null).length ====
                          //   0;

                          return (
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="">
                                  <label>{devices_name}</label>
                                </div>
                              </div>
                              <div className="col-lg-3">
                                <div>
                                  <label className="switch">
                                    <input
                                      name="active"
                                      value={value}
                                      onChange={handleInputChange}
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="active"
                                      value={value}
                                      onChange={handleInputChange}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={submitsub}
                      >
                        Add plan
                      </button>

                      <div className="clear"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
};

export default AddSubscriptionPlan;
