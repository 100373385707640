import React, { useState, useEffect, useRef } from "react";
import "./signin.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import MessageLabel from "../CommonComponents/MessageLabel";
import { MdOutlineMailOutline } from "react-icons/md";
import MessageBox from "../CommonComponents/MessageBox";

const Forgot = ({ logo }) => {

  const emailRef = useRef(null)
  const passwordRef = useRef(null)


  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputField, setInputField] = useState({
    email: "",
    password: "",
  });
  const [errField, setErrField] = useState({
    email: "",
    passworderr: "",
  });

  function handleChange(e) {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  }


  useEffect(async () => {
    if (localStorage.getItem("access_token") !== null) {
      await navigate("/");
    }
  }, []);


  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  async function login() {
    setLoading(true);
    setErrorMessage("");

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_Baseurl}/users/forgot`,
        { email: inputField?.email, password: inputField?.password },
        { headers: headers }
      );

      const status = res?.data?.status;

      if (status) {
        const role = res?.data?.user?.role;
        if (role === "1" || role === 1) {
          const { username, id, image_url } = res?.data?.user;
          const { access_token, expires_in } = res?.data;

          localStorage.setItem("access_token", access_token);
          localStorage.setItem("user_id", id);
          localStorage.setItem("role", role);
          localStorage.setItem("username", username);
          localStorage.setItem("image_url", image_url);
          localStorage.setItem("expires_in", expires_in);
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        } else {
          setErrorMessage("Login failed. Please check your credentials*");
          // alert("Please Enter Correct Details");
        }
      } else {
        setErrorMessage("Login failed. Please check your credentials*");
        // alert("Please Enter Correct Details");
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrorMessage("Login failed. Please check your credentials*");
      // alert("Please Enter Correct Details");
      console.log(err);
    }
  }

  const validForm = (e) => {
    e.preventDefault();

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    let formIsValid = true;
    setErrField({
      email: "",
      passworderr: "",
    });
    if (inputField?.email === "") {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        email: "Please Enter Email*",
      }));
    } else if (!validateEmail(inputField?.email)) {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        email: "Please Enter Valid Email ID",
      }));
    }

    if (inputField?.password === "") {
      formIsValid = false;
      setErrField((prevState) => ({
        ...prevState,
        passworderr: "Please Enter Password*",
      }));
    }

    if (inputField?.email !== "" && inputField?.password !== "") {
      login();
    }
    return formIsValid;
  };

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const [logoimage, setLogoimage] = useState(null); // Initialize logoimage with null or a placeholder image URL

  useEffect(() => {
    const fetchData = async () => {
      const formData = new FormData();
      formData.append("Login_user_type", 9);
      formData.append("Login_user_id", null);
      formData.append("Login_device_type", "web");

      try {
        const reqData = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Header-index`, formData);
        setLogoimage(reqData?.data?.theme_mode_details?.logo_url);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div class="modal fade model-x-forgot" id="exampleModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="model-x-button">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-4">
              <img src={logo} alt="SignInLogo" className="d-block mb-3 mx-auto signin-logo-images w-50 object-fit-contain" />
              <form autoComplete="off"
              //onSubmit={handleSubmit}
              >
                <MessageLabel text="Email" className="" />
                <div className="position-relative">
                  <MdOutlineMailOutline className="lockIcon position-absolute" />
                  <input type="email" name="email" value={inputField?.email} onChange={handleChange} placeholder="Enter  email" ref={emailRef} className="rs-input py-3 px-5 form-control-lg custom-placeholder theme-text-color  mb-3" />
                  {errField?.email && (<MessageBox text={`${errField?.email}`} classname='errorred mb-2 d-block' />)}
                </div>

                <MessageBox classname="signinLabelText py-3 mb-0 text-center theme-text-color" text="We will send you an email with instructions on how to reset your password" />
                <div className="d-flex my-3  align-items-center justify-content-end">
                  <button disabled={false} onClick={null} type="submit" className={` btn button-color button-bg-color admin-button-text text-white rounded-2  py-2 px-3 `}>{false ? <div className='custom-loading mx-auto'></div> : <MessageBox text={'Send Password Reset Link'} />}</button>
                </div>

              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
