import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./Update_Tv_Settings.css";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function Update_Tv_Settings(props) {
  const { id } = useParams();
  const [editUser, setEditUser] = useState({
    name: "",
    enable_id: "",
    page_id: "",
    user_id: "",
  });
  const [editname, setEditname] = useState({
    name: "",
  });
  const [enablepage, setEnablepage] = useState([]);

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagename("");
    setIsValidationHiddenname(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/tv-settings/edit/${id}`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.TVSetting;
        var resultPages = response?.data?.Pages;
        setEditUser(result);
        setEditname(result);
        setEnablepage(resultPages);
      })
      .catch((error) => console.log(error));
  }, []);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const [validationMessagename, setValidationMessagename] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);

  const nameInputRef = useRef(null);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagename("Name cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      name: editUser?.name,
      enable_id: editUser?.enable_id,
      page_id: editUser?.page_id,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/tv-settings/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      setValidationMessagename("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setIsValidationHiddenname(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <section className="container-fluid">
          <div className="col-lg-12 row py-3">
            <div className="col-6 col-sm-6 col-lg-6">
              <h3>Edit TV Settings</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update TV Settings
                </button>
              </div>
            </div>
          </div>

          <div>
            {showOverlay && <Response_Processing></Response_Processing>}
          </div>

          <div className="row col-lg-12 ">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="card p-3">
                <h5>{editname?.name}</h5>
                <hr></hr>
                <div className=" text-start">
                  <label className="m-0">
                    Name<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="name"
                      name="name"
                      placeholder="Enter Slug"
                      onChange={handleInput}
                      value={editUser?.name}
                      ref={nameInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddenname && <p>{validationMessagename}</p>}
                  </span>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0">Page Enable</label>

                  <div className="mt-2">
                    <select
                      className="form-select"
                      name="page_id"
                      id="page_id"
                      onChange={handleInput}
                      value={editUser?.page_id}
                    >
                      <option value="">Select an Page Enable </option>
                      {enablepage?.map((item) => (
                        <option value={item?.id}>{item?.title}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {/* <div className="text-start mb-3">
                <button
                  onClick={submitcategory}
                  className="btn btn-primary"
                >
                 Save Audio Category
                </button>
              </div> */}
            </div>

            {/* Second Part of Section  */}

            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="card p-3">
                <h5>Status Settings</h5>
                <hr></hr>

                <div className="col-sm-12 row">
                  <div className="col-9 col-sm-9 col-lg-9">
                    <p className="p2">Enable Setting</p>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3">
                    <label className="switch">
                      <input
                        name="enable_id"
                        onChange={handleInputenable}
                        className="rs-input"
                        defaultChecked={editUser?.enable_id == 1 ? true : false}
                        checked={editUser?.enable_id == 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="enable_id"
                        onChange={handleInputenable}
                        value={editUser?.enable_id == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Update_Tv_Settings;
