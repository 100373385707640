import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import backarrow from "../../../../components/Images/backarrow.svg";

function Script_Setting(props) {

  const [script, setScript] = useState({
    header_script: "",
    footer_script: "",
  });

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/script_setting/index`, {
        headers: headers,
      })
      .then((response) => {
        var script = response?.data?.Script_setting;
        setScript(script);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setScript({ ...script, [e.target.name]: e.target.value });
  };

  const [validationMessageheader_script, setValidationMessageheader_script] =
    useState("");
  const [isValidationHiddenheader_script, setIsValidationHiddenheader_script] =
    useState(true);

  const [validationMessagefooter_script, setValidationMessagefooter_script] =
    useState("");
  const [isValidationHiddenfooter_script, setIsValidationHiddenfooter_script] =
    useState(true);

  const header_scriptInputRef = useRef(null);
  const footer_scriptInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function handleUpdate() {
    let focusInputRef = null;

    if (script?.header_script === "") {
      setValidationMessageheader_script("Header Script cannot be empty.");
      setIsValidationHiddenheader_script(false);
      focusInputRef = header_scriptInputRef;
    } if (script?.footer_script === "") {
      setValidationMessagefooter_script("Footer Script cannot be empty.");
      setIsValidationHiddenfooter_script(false);
      focusInputRef = footer_scriptInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const formData = new FormData();

    formData.append("header_script", script?.header_script);
    formData.append("footer_script", script?.footer_script);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/script_setting/store`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;
        resultapi = response.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      setValidationMessageheader_script("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        // window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        // console.log(resultError);
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddenheader_script(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <Link to="/settings" className="backarrow" >
                <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                <span> Back</span>
              </Link>
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Header/Footer Scripts</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Save Scripts
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                <div className="card p-3">
                  <h5>Create Scripts</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Header Script CDN<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="header_script"
                        name="header_script"
                        value={script?.header_script}
                        onChange={handleInput}
                        className="rs-input form-control-lg"
                        // placeholder="Title"
                        ref={header_scriptInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenheader_script && (
                        <p>{validationMessageheader_script}</p>
                      )}
                    </span>
                  </div>
                  <div className=" text-start">
                    <label className="m-0">
                      Footer Script CDN<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="footer_script"
                        name="footer_script"
                        value={script?.footer_script}
                        onChange={handleInput}
                        className="rs-input form-control-lg"
                        // placeholder="Title"
                        ref={footer_scriptInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenfooter_script && (
                        <p>{validationMessagefooter_script}</p>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    </>
  );
}

export default Script_Setting;
