import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";


function LinkSettings(props) {
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    ios_app_store_id: "",
    ios_url: "",
    ipad_app_store_id: "",
    ipad_url: "",
    android_url: "",
    windows_phone_app_store_id: "",
    android_app_store_id: "",
    windows_phone_url: "",
    user_id: "",
  });
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/deep_linking_setting/index`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      var res = resData?.Deep_Linking_Setting;
      setEditUser(res);
      //    console.log(resData)
      //    console.log(res)
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const editInputvalue = {
      ios_app_store_id: editUser?.ios_app_store_id,
      ios_url: editUser?.ios_url,
      ipad_app_store_id: editUser?.ipad_app_store_id,
      ipad_url: editUser?.ipad_url,
      android_app_store_id: editUser?.android_app_store_id,
      android_url: editUser?.android_url,
      windows_phone_app_store_id: editUser?.windows_phone_app_store_id,
      windows_phone_url: editUser?.windows_phone_url,
      user_id: editUser?.user_id,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/deep_linking_setting/store`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   const editInputvalue = {
  //     ios_app_store_id: editUser?.ios_app_store_id,
  //     ios_url: editUser?.ios_url,
  //     ipad_app_store_id: editUser?.ipad_app_store_id,
  //     ipad_url: editUser?.ipad_url,
  //     android_app_store_id: editUser?.android_app_store_id,
  //     android_url: editUser?.android_url,
  //     windows_phone_app_store_id: editUser?.windows_phone_app_store_id,
  //     windows_phone_url: editUser?.windows_phone_url,
  //     user_id: editUser?.user_id,
  //   };
  //   // console.log(editInputvalue);
  //   let res = await fetch(
  //     `${process.env.REACT_APP_Baseurl}/admin/deep_linking_editUser/store`,
  //     {
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify(editInputvalue),
  //     }
  //   );

  //   let resjson = await res.json();
  //   // console.log(resjson)
  //   if (res.status === 200) {
  //     setMessage(resjson.success);
  //     alert("Success");
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 2000);
  //   } else {
  //     setMessage("Some error Occured");
  //     console.log("Error");
  //     alert("Enter Correct Details");
  //   }
  // };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Link Settings</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Settings
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Link</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">IOS App Store ID</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        // id="ios_app_store_id"
                        name="ios_app_store_id"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.ios_app_store_id}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">IPAD App Store ID</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="ipad_app_store_id"
                        name="ipad_app_store_id"
                        value={editUser?.ipad_app_store_id}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Android App Store ID</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="android_app_store_id"
                        name="android_app_store_id"
                        value={editUser?.android_app_store_id}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Windows Phone App Store ID</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="windows_phone_app_store_id"
                        name="windows_phone_app_store_id"
                        value={editUser?.windows_phone_app_store_id}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Settings
                  </button>
                </div> */}
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>URL Settings</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">IOS URL</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        // id="ios_url"
                        name="ios_url"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.ios_url}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">IPAD URL</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="ipad_url"
                        name="ipad_url"
                        value={editUser?.ipad_url}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Android URL</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="android_url"
                        name="android_url"
                        value={editUser?.android_url}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Windows Phone URL</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="windows_phone_url"
                        name="windows_phone_url"
                        value={editUser?.windows_phone_url}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid ">
            <div className=" p-2">
              <div className="iq-card">
                <div className="">
                  <form
                    id="update-menu-form"
                    accept-charset="UTF-8"
                    action=""
                    method="post"
                  >
                    <div>
                      <h4>
                        <i className="entypo-globe"></i> Link Settings
                      </h4>
                      <hr />
                    </div>
                    <div className="">
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">IOS App Store ID</label>
                            <input
                              name="ios_app_store_id"
                              id="ios_app_store_id"
                              placeholder="IOS App Store ID"
                              className="form-control"
                              onChange={handleInput}
                              value={editUser?.ios_app_store_id}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">IOS URL</label>
                            <input
                              name="ios_url"
                              id="ios_url"
                              placeholder="IOS URL"
                              className="form-control"
                              onChange={handleInput}
                              value={editUser?.ios_url}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">IPAD App Store ID</label>
                            <input
                              name="ipad_app_store_id"
                              onChange={handleInput}
                              value={editUser?.ipad_app_store_id}
                              id="ipad_app_store_id"
                              placeholder="IPAD App Store ID"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">IPAD URL</label>
                            <input
                              name="ipad_url"
                              id="ipad_url"
                              placeholder="IPAD URL"
                              className="form-control"
                              onChange={handleInput}
                              value={editUser?.ipad_url}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">Android App Store ID</label>
                            <input
                              name="android_app_store_id"
                              id="android_app_store_id"
                              onChange={handleInput}
                              value={editUser?.android_app_store_id}
                              placeholder="Android App Store ID"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">Android URL</label>
                            <input
                              name="android_url"
                              id="android_url"
                              onChange={handleInput}
                              value={editUser?.android_url}
                              placeholder="Android URL"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">Windows Phone App Store ID</label>
                            <input
                              name="windows_phone_app_store_id"
                              onChange={handleInput}
                              value={editUser?.windows_phone_app_store_id}
                              id="windows_phone_app_store_id"
                              placeholder="Windows Phone App Store ID"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="home">
                            <label for="slug">Windows Phone URL</label>
                            <input
                              name="windows_phone_url"
                              id="windows_phone_url"
                              onChange={handleInput}
                              value={editUser?.windows_phone_url}
                              placeholder="Windows Phone URL"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 text-right home d-flex justify-content-end">
                      <Link
                        type="submit"
                        className="btn btn-primary"
                        id="submit-update-menu"
                        onClick={handleUpdate}
                      >
                        Update
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default LinkSettings;
