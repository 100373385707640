import React, { createContext, useContext } from 'react';
import { useState } from 'react';
const MyContext = createContext();
export function MyProvider({ children }) {
    const [themeMode, setThemeMode] = useState(false)
    const themeSwitcher = () => {
        setThemeMode(!themeMode)
    };
    return <MyContext.Provider value={{ themeSwitcher, themeMode }}>{children}</MyContext.Provider>;
}
export function useMyContext() {
    return useContext(MyContext);
}