import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";

function AllLiveStream(props) {
  const [getLiveData, setLiveData] = useState([]);
  const [filteredLiveData, setFilteredLiveData] = useState([]);
  const [itemToDeleteLive, setItemToDeleteLive] = useState([]);
  const [modalCloseLive, setModalCloseLive] = useState(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/list`,
        { headers: headers }
      );
      const liveData = response?.data?.LiveStream;
      const filterHelp = liveData?.map((item) => {
        const UploadStatus =
          item?.uploaded_by === "1"
            ? "Admin"
            : item?.uploaded_by === "4"
              ? "Channel Partner"
              : item?.uploaded_by === "5"
                ? "Content Partner"
                : "";
        const statusText =
          item?.active === 1 &&
            item?.publish_type === "publish_now" &&
            item?.status === 1
            ? // &&
            // item?.draft === 1
            "PUBLISHED"
            : item?.active == null &&
              item?.publish_type === "publish_later" &&
              item?.status == null
              ? //  &&
              // item?.draft == null
              "SCHEDULED"
              : "DRAFT";

        return { ...item, uploaded_by: UploadStatus, active: statusText };
      });
      setLiveData(filterHelp);
      setFilteredLiveData(filterHelp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(async () => {
    fetchData();
  }, []);
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredLiveData?.length },
    ],
  });

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteLive([...itemToDeleteLive, row.id]);
        } else {
          setItemToDeleteLive(itemToDeleteLive?.filter((id) => id !== row?.id));
        }
      }
    },
    selected: itemToDeleteLive,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAll}
        />
      </div>
    ),
  };

  const videoFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <img src={row?.image} className="profile" />
        <div className="rightVideoTitle">
          <p>{data}</p>
          <span className="me-1 text-dark dateText"> {row?.duration}</span>
          <span className="dateText">{row?.upload_on}</span>

          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`${process.env.REACT_APP_Baseurl_Frontend}/live/${row?.slug}`}
                      >
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const statusFormatter = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "SCHEDULED"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };

  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit/add-livestream/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Edit Live</span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteLive(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2"> Delete Live</span>
          </div>
        </div>
      </div>
    );
    
  };

  const sliderFormatter = (data, row) => {
    return (
      <div>
        <label className="switch">
          <input
            name="banner"
            id="banner"
            className={data === 0 ? "inactive" : null}
            checked={data === 1}
            onChange={() => handleToggle(row)}
            onClick={() => handleToggle(row)}
            type="checkbox"
          />
          <span className="slider round"></span>
        </label>
      </div>
    );
  };

  const columns = [
    {
      dataField: "title",
      text: "Videos",
      sort: true,
      formatter: videoFormatter,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded by",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusFormatter,
    },
    {
      dataField: "banner",
      text: "Slider",
      formatter: sliderFormatter,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const exportHeadersLive = [
    { label: "ID", key: "id" },
    { label: "VIDEOS", key: "title" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "STATUS", key: "active" },
    { label: "SLIDER", key: "banner" },
    { label: "UPLOAD ON", key: "upload_on" },
  ];

  const conditionalGeneratePDFLive = (get) => {
    const doc = new jsPDF();
    doc.text("ALL LIVE LIST ", 10, 10);
    const columns = [
      "Index",
      "ID",
      "VIDEOS",
      "UPLOADED BY",
      "STATUS",
      "BANNER",
      "UPLOAD ON",
    ];
    const allDataPDF = getLiveData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.uploaded_by,
      item?.active,
      item?.banner,
      item?.upload_on,
    ]);
    const filterPDF = filteredLiveData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.uploaded_by,
      item?.active,
      item?.banner,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("ALL-LIVE-LIST.pdf");
  };

  const generateXLSXLIVE = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? getLiveData : filteredLiveData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "ALL-LIVE-LIST.xlsx");
  };

  const handleFilterChangeLive = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = getLiveData.filter((item) =>
      ["id", "title", "uploaded_by", "active", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredLiveData(filteredResults);
  };

  const handleClear = () => {
    fetchData();
    setFilter("");
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDeleteLive(
      selectAll ? [] : filteredLiveData?.map((item) => item?.id)
    );
  };

  const closeLiveModal = () => {
    setModalCloseLive(!modalCloseLive);
  };

  const openModalSingleDeleteLive = (id) => {
    setItemToDeleteLive([id]);
    setModalCloseLive(true);
  };

  const openModalMultiDeleteLive = () => {
    setModalCloseLive(true);
  };

  const handleDeleteLiveData = () => {
    closeLiveModal();
    liveDeleteOperation();
  };

  const handleToggle = async (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const confirmToggle = async () => {
    try {
      setShowModal(false);
      const newStatus = currentItem?.banner === 1 ? 0 : 1;
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/livestream-slider`,
        {
          method: "POST",
          body: JSON.stringify({
            live_id: currentItem?.id,
            banner_status: newStatus,
          }),
          headers: headers,
        }
      );
      setCurrentItem(null);
      props?.setApiresponsealert({
        status: true,
        message: "Switched the Slider status successfully",
      });
      props?.setShowalert(true);
      fetchData();
      setProcessing(false);
      setShowOverlay(false);
    } catch (err) {
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const closeModalslider = () => {
    setShowModal(false);
    setCurrentItem(null);
  };

  const liveDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteLive + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <>
      <div
        className={`modal  packageModel ${modalCloseLive ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: modalCloseLive ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeLiveModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteLive?.length === 1 ? (
                <p className="fs-6">
                  {" "}
                  Are you sure you want to delete this live ?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected lives ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={closeLiveModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteLiveData}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal packageModel ${showModal ? "show fade" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: `${showModal ? "block" : "none"}` }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Slider </h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModalslider}
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to Switch the Slider status?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModalslider}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={confirmToggle}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-6">
              <h4>
                All Live Stream{" "}
                <span className="fs-6">
                  (Display all {getLiveData?.length} Live Stream)
                </span>
              </h4>
            </div>
            <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
              {itemToDeleteLive?.length > 0 && (
                <button
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModalMultiDeleteLive}
                >
                  <MdDelete className="exportIcon " />
                  Delete
                </button>
              )}
              <Link
                to="/add-livestream"
                className="btn btn-primary fw-bolder"
              >
                <i className="fa fa-plus-circle"></i> Add LiveStream
              </Link>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
              <div className="filterContainer">
                <input
                  type="text"
                  placeholder="Filter all.."
                  className="filter form-control "
                  value={filter}
                  onChange={handleFilterChangeLive}
                />
                {filter?.length > 0 && (
                  <button
                    type="button"
                    className="closeBtn"
                    onClick={handleClear}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                )}
              </div>
              <div className="btn-group">
                <div className="dropdown d-block " key={"2"}>
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export </span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeadersLive}
                        data={filteredLiveData}
                        className="text-dark d-block"
                        filename="LIVE-FILTERED-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFLive(0);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXLIVE(0);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>

                <div className="dropdown d-block ">
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export All</span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeadersLive}
                        data={getLiveData}
                        className="text-dark d-block"
                        filename="ALL-LIVES-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFLive(1);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXLIVE(1);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bootstrapTable">
              <BootstrapTable
                keyField="id"
                columns={columns}
                data={filteredLiveData}
                pagination={pagination}
                className="no-border-table"
                noDataIndication={
                  <div className="noRecord">
                    {loading ? (
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>
                        Loading...
                      </label>
                    ) : (
                      filteredLiveData?.length <= 0 && (
                        <p>No Live Streams Available. !!!</p>
                      )
                    )}
                  </div>
                }
                hover
                headerClasses="videoWidth"
                selectRow={selectRow}
                selectAll={selectAll}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AllLiveStream;
