import { useState, useEffect } from "react";
import axios from "axios";

const useCompressImageConfig = () => {
    const [compressResolutionFormat, setCompressResolutionFormat] = useState({
        audios: "",
        episode: "",
        season: "",
        series: "",
        live: "",
        videos: "",
    });

    const fetchCompressImageConfig = async () => {
        const access_token = localStorage.getItem("access_token");
        const adminApiHeader = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        };

        try {
            const response = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/compress_image`, {
                headers: adminApiHeader,
            });
            setCompressResolutionFormat(response?.data?.Compress_image);
        } catch (error) {
            console.error('Error fetching compress image config:', error);
        }
    };

    useEffect(() => {
        fetchCompressImageConfig();
    }, []);

    return { compressResolutionFormat };
};

export default useCompressImageConfig;
