import React from "react";

function Manageschedule() {
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">Manageschedule</section>
        </div>
      </div>
    </>
  );
}

export default Manageschedule;
