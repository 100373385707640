// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .user-count
{
    display: flex;
    align-items: center;
} */

.user_reg_analytics_count{
    font-size: 18px !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Analytics/UsersAnalytics.css"],"names":[],"mappings":"AAAA;;;;GAIG;;AAEH;IACI,0BAA0B;AAC9B","sourcesContent":["/* .user-count\n{\n    display: flex;\n    align-items: center;\n} */\n\n.user_reg_analytics_count{\n    font-size: 18px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
