import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdDelete } from "react-icons/md";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";

function AllVideo_Playlist(props) {
  const [getVideo_Playlist, setVideo_Playlist] = useState([]);
  const [itemToDeleteVideo_Playlist, setItemToDeleteVideo_Playlist] = useState(
    []
  );
  const [selectAllVideo_Playlist, setSelectAllVideo_Playlist] = useState(false);
  const [modalCloseVideo_Playlist, setModalCloseVideo_Playlist] =
    useState(false);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [loading, setLoading] = useState(true);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Headers":
      "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "*",
  };

  const fetchDatavideo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/video/playlist-index`,
        { headers: headers }
      );
      setVideo_Playlist(response?.data?.AdminVideoPlayList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(async () => {
    fetchDatavideo();
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(getVideo_Playlist);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setVideo_Playlist(reorderedItems);
    // saveShuffleOrder(reorderedItems);
  };

  // const saveShuffleOrder = async (updatedItems) => {
  //   setProcessing(true);
  //   setShowOverlay(true);

  //   if (!updatedItems || updatedItems?.length === 0) {
  //     console.error("Menu items are empty");
  //     return;
  //   }

  //   const positions = updatedItems?.map((menuItem) => menuItem?.id);
  //   const ids = updatedItems?.map((_, index) => index + 1);

  //   const requestData = {
  //     id: JSON.stringify(positions),
  //     position: JSON.stringify(ids),
  //   };

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_Baseurl}/admin/videos-category/order-video-category`,
  //       requestData,
  //       { headers: headers }
  //     );

  //     const result = response?.data;
  //     if (result?.status === true) {
  //       props?.setApiresponsealert(result);
  //       props?.setShowalert(true);
  //       setProcessing(false);
  //       setShowOverlay(false);
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 2000);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //     var errorMessage = err?.response?.data?.message;
  //     props?.setApiresponsealert({ status: false, message: errorMessage });
  //     props?.setShowalert(true);
  //     setProcessing(false);
  //     setShowOverlay(false);
  //   }
  // };
  const handleSelectAllVideofile = () => {
    setSelectAllVideo_Playlist(!selectAllVideo_Playlist);
    setItemToDeleteVideo_Playlist(
      selectAllVideo_Playlist ? [] : getVideo_Playlist?.map((item) => item?.id)
    );
  };

  const handleMultiSelectVideoFile = (itemId) => {
    if (itemToDeleteVideo_Playlist?.includes(itemId)) {
      setItemToDeleteVideo_Playlist(
        itemToDeleteVideo_Playlist?.filter((id) => id !== itemId)
      );
    } else {
      setItemToDeleteVideo_Playlist([...itemToDeleteVideo_Playlist, itemId]);
    }
  };

  const singleDeleteVideo_Playlist = (id) => {
    setItemToDeleteVideo_Playlist([id]);
    setModalCloseVideo_Playlist(true);
    setSelectAllVideo_Playlist(false);
  };

  const closeVideo_PlaylistModal = () => {
    setModalCloseVideo_Playlist(!modalCloseVideo_Playlist);
  };

  const openModalMultiDeleteVideo_Playlist = () => {
    setModalCloseVideo_Playlist(true);
  };

  const handleDeleteVideo_PlaylistData = () => {
    closeVideo_PlaylistModal();
    videoPlaylistDeleteOperation();
  };

  const videoPlaylistDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteVideo_Playlist + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/video/playlist-multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <>
      <div
        className={`modal  packageModel ${
          modalCloseVideo_Playlist ? "show" : ""
        }`}
        tabIndex="-1"
        style={{ display: modalCloseVideo_Playlist ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeVideo_PlaylistModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteVideo_Playlist?.length === 1 ? (
                <p className="fs-6">
                  Are you sure you want to delete this video category?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected video category ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={closeVideo_PlaylistModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteVideo_PlaylistData}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    
      <div>
            {showOverlay && <Response_Processing></Response_Processing>}
          </div>

      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-6">
              <h4>
                Video Playlist{" "}
                <span className="fs-6">
                  (Display all {getVideo_Playlist?.length} Playlist)
                </span>
              </h4>
            </div>

            <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
              {itemToDeleteVideo_Playlist?.length > 0 && (
                <button
                  type="button"
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModalMultiDeleteVideo_Playlist}
                >
                  <MdDelete className="exportIcon " />
                  Delete
                </button>
              )}
              <Link
                to="/add-playlist"
                className="btn btn-primary fw-bolder"
              >
                <i className="fa fa-plus-circle"></i> Add Video Playlist
              </Link>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="bootstrapTable ">
              <div className="col-12 p-0">
                <div className="row m-0 p-0">
                  <div className="col-lg-2 col-4 data">
                    {" "}
                    <label>
                      <input
                        type="checkbox"
                        checked={selectAllVideo_Playlist}
                        onChange={handleSelectAllVideofile}
                      />
                    </label>
                  </div>
                  <div className="col-lg-4 col-4 data">
                    {" "}
                    <div>Playlist</div>
                  </div>
                  <div className="col-lg-3 col-4 data">
                    {" "}
                    {/* <div>Uploaded by</div> */}
                  </div>
                  <div className="col-lg-3 col-4 data">
                    {" "}
                    <div>Actions</div>
                  </div>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="menu-items">
                    {(provided) => (
                      <ul {...provided.droppableProps} ref={provided.innerRef}>
                        {loading ? (
                          <div className="noRecord">
                            {" "}
                            <Response_Loading_processing></Response_Loading_processing>
                            Loading...
                          </div>
                        ) : getVideo_Playlist?.length <= 0 ? (
                          <div className="noRecord">
                            {" "}
                            <p>No Video Playlist Available !!! </p>
                          </div>
                        ) : (
                          getVideo_Playlist?.map((item, index) => (
                            <Draggable
                              key={item?.id.toString()}
                              draggableId={item?.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`bg-white orderborder ${
                                    item?.order
                                  } index ${index + 1}w-100`}
                                >
                                  <div className="row m-0 p-0 align-items-center">
                                    <div className="col-lg-2 col-4 data_th">
                                      <input
                                        type="checkbox"
                                        checked={itemToDeleteVideo_Playlist.includes(
                                          item?.id
                                        )}
                                        onChange={() =>
                                          handleMultiSelectVideoFile(item?.id)
                                        }
                                      />
                                    </div>
                                    <div className="col-lg-4 col-4 data_th">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <img
                                          src={item?.Thumbnail}
                                          className="profile"
                                          alt="image_all"
                                        />
                                        <div className="rightVideoTitle">
                                          <p>{item?.title}</p>
                                          <span className="dateText">
                                            {item?.upload_on}
                                          </span>
                                          <div>
                                            {item?.slug !== null ? (
                                              <div className="text-start">
                                                <label className="m-0">
                                                  <div className="tooltip-container">
                                                    <p className="iq-bg-warning">
                                                      <Link
                                                        to={`${process.env.REACT_APP_Baseurl_Frontend}/playlist/${item?.slug}`}
                                                      >
                                                        <i
                                                          className="fa fa-share-alt"
                                                          aria-hidden="true"
                                                        ></i>
                                                      </Link>
                                                      <span className="tooltip-text">
                                                        View
                                                      </span>
                                                    </p>
                                                  </div>
                                                </label>
                                              </div>
                                            ) : (
                                              <>
                                                <div className="text-start">
                                                  <label className="m-0">
                                                    <div className="tooltip-container">
                                                      <p className="iq-bg-warning">
                                                        <i
                                                          className="fa fa-share-alt"
                                                          aria-hidden="true"
                                                          disabled
                                                        ></i>
                                                        <span className="tooltip-text">
                                                          Diable View
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </label>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>{" "}
                                    <div className="col-lg-3 col-4 data_th">
                                      {/* <div>
                                        {" "}
                                        {item?.uploaded_by === "1"
                                          ? "Admin"
                                          : item?.uploaded_by === "4"
                                          ? "Channel Partner"
                                          : item?.uploaded_by === "5"
                                          ? "Content Partner"
                                          : ""}
                                      </div> */}
                                    </div>
                                    <div className="col-lg-3 col-4 data_th">
                                      <div className="editdropdown ">
                                        <span className="editdropdown-button">
                                          <span>
                                            <i
                                              className="fa fa-ellipsis-v"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </span>
                                        <div className="editdropdown-menu ">
                                          <Link
                                            to={
                                              "/edit-playlist/" + item?.id
                                            }
                                          >
                                            <span>
                                              <img
                                                src={edititem}
                                                alt="flogo"
                                                width={20}
                                                height={20}
                                              />
                                              <span className="ms-2">
                                                Edit Playlist
                                              </span>
                                            </span>
                                          </Link>
                                          <div
                                            onClick={() =>
                                              singleDeleteVideo_Playlist(
                                                item?.id
                                              )
                                            }
                                            className="commonActionPadding"
                                          >
                                            <img
                                              src={deleteitem}
                                              alt="flogo"
                                              width={20}
                                              height={20}
                                            />
                                            <span className="ms-2">
                                              Delete Playlist
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AllVideo_Playlist;










// import React, { useState, useEffect } from "react";
// import { DndProvider, useDrag, useDrop } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import axios from "axios";
// import DatePicker from "react-datepicker";
// import TimePicker from "react-bootstrap-time-picker";
// import Select from "react-select";
// import moment from "moment-timezone";
// import "react-datepicker/dist/react-datepicker.css";
// import "./EditManageCalendarschedule.css";

// const videoList = [
//   {
//     id: 1,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
//   {
//     id: 2,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
// ];

// const currentDate = moment();

// const DragDrop = () => {
//   const [boardData, setBoardData] = useState([
//     {
//       id: 1,
//       videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//       scheduledDate: "2024-01-10T07:49:36.206Z",
//       scheduledTime: 0,
//       StartTime: "12:12:12",
//       EndTime: "12:12:12",
//     },
//     {
//       id: 3,
//       videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//       scheduledDate: "2024-01-09T07:49:36.206Z",
//       scheduledTime: 0,
//       StartTime: "12:12:12",
//       EndTime: "12:12:12",
//     },
//   ]);

//   const [selectedDate, setSelectedDate] = useState(currentDate.toDate());
//   const [selectedTime, setSelectedTime] = useState(new Date().setHours(12, 0, 0, 0));
//   const [currentTimeZone, setCurrentTimeZone] = useState("Asia/Kolkata");
//   const [editingIds, setEditingIds] = useState([]);
//   const [selectedDateselectedDate, setSelectedDateselectedDate] = useState("");

//   useEffect(() => {
//     setSelectedDateselectedDate(selectedDate);
//   }, []);

//   console.log(selectedDate, selectedDateselectedDate, "selectedDateselectedDateselectedDateselectedDate");

//   const [{ isOver }, drop] = useDrop(() => ({
//     accept: "video",
//     drop: (item) => addVideoToBoard(item.id, selectedDate),
//     collect: (monitor) => ({
//       isOver: !!monitor.isOver(),
//     }),
//   }));

//   const updateSelectedDate = (newDate) => {
//     setSelectedDate(newDate);
//   };

//   const Video = ({ id, videourl, scheduledDate }) => {
//     const [{ isDragging }, drag] = useDrag(() => ({
//       type: "video",
//       item: { id, scheduledDate },
//       canDrag: () => !editingIds.includes(id),
//       collect: (monitor) => ({
//         isDragging: !!monitor.isDragging(),
//       }),
//     }));

//     return (
//       <div className={`video ${isDragging ? "dragging" : ""}`} ref={drag} onClick={() => updateSelectedDate(scheduledDate)}>
//         <img width={150} height={150} src={videourl} alt={`Video ${id}`} />
//         <p>{`Video ID: ${id}`}</p>
//       </div>
//     );
//   };

//   const handleDateChange = (newDate) => {
//     setSelectedDate(newDate);
//   };

//   const handleTimeChange = (time) => {
//     setSelectedTime(time);
//   };

//   const handleNext = () => {
//     const nextDate = moment(selectedDate).add(1, "days").toDate();
//     setSelectedDate(nextDate);
//     clearBoardDataIfNoData(nextDate);
//   };

//   const handlePrevious = () => {
//     const prevDate = moment(selectedDate).subtract(1, "days").toDate();
//     setSelectedDate(prevDate);
//     clearBoardDataIfNoData(prevDate);
//   };

//   const clearBoardDataIfNoData = (date) => {
//     if (!hasBoardData(date)) {
//       setBoardData([]);
//     }
//   };

//   const fetchDataForDate = (date) => {
//     return boardData.filter((video) => moment(video.scheduledDate).isSame(date, "day"));
//   };

//   const hasBoardData = (date) => {
//     return fetchDataForDate(date).length > 0;
//   };

//   useEffect(() => {
//     if (!hasBoardData(selectedDate)) {
//       setBoardData([]);
//     }
//   }, [selectedDate]);

//   const handleTimeZoneChange = (selectedOption) => {
//     if (selectedOption && selectedOption.value) {
//       setCurrentTimeZone(selectedOption.value);
//     } else {
//       console.error("Invalid selected option:", selectedOption);
//     }
//   };

//   const getTimeZoneOptions = () => {
//     const timeZones = moment.tz.names();
//     return timeZones.map((zone) => ({ value: zone, label: zone }));
//   };

//   const handleEditClick = (id) => {
//     setEditingIds((prevIds) => {
//       if (prevIds.includes(id)) {
//         return prevIds.filter((editingId) => editingId !== id);
//       } else {
//         return [...prevIds, id];
//       }
//     });
//   };

//   const handleDeleteClick = (id) => {
//     setEditingIds((prevIds) => prevIds.filter((editingId) => editingId !== id));
//   };

//   const addVideoToBoard = (id) => {
//     const video = videoList.find((v) => v.id === id);
  
//     // Replace with your actual API endpoint
//     axios
//       .post("YOUR_API_ENDPOINT", {
//         videoId: id,
//         scheduledDate: selectedDate,
//         scheduledTime: selectedTime,
//       })
//       .then((response) => {
//         console.log("Video added successfully!", response.data);
//       })
//       .catch((error) => {
//         console.error("Error adding video:", error);
//       });
  
//     setBoardData((prevBoardData) => [
//       ...prevBoardData,
//       {
//         ...video,
//         scheduledDate: selectedDate,
//         scheduledTime: selectedTime,
//         id: generateUniqueId(),
//       },
//     ]);
//   };
  
//   // Helper function to generate a unique ID
//   const generateUniqueId = () => {
//     return new Date().getTime(); // Use a more robust method for production
//   };
  

//   return (
//     <div className="newdraganddrop_schedule p-3">
//       <div className="card">
//         <div className="col-lg-12 row py-3 p-2">
//           <div className="col-4 col-sm-4 col-md-4 col-lg-4">
//             <div className="d-flex">
//               <label>Scheduled Date:</label>
//               <DatePicker selected={selectedDate} onSelect={setSelectedDate} onChange={handleDateChange} dateFormat="yyyy-MM-dd" />
//             </div>
//           </div>

//           <div className="col-3 col-sm-3 col-md-3 col-lg-3">
//             <div className="d-flex">
//               <label>Scheduled Time:</label>
//               <TimePicker value={selectedTime} onChange={handleTimeChange} />
//             </div>
//           </div>

//           <div className="col-3 col-sm-3 col-md-3 col-lg-3">
//             <div className="d-flex">
//               <label>Time Zone:</label>
//               <Select
//                 className="react-select-container"
//                 classNamePrefix="react-select"
//                 value={{ value: currentTimeZone, label: currentTimeZone }}
//                 onChange={handleTimeZoneChange}
//                 options={getTimeZoneOptions()}
//                 isSearchable={true}
//                 placeholder="Search Time Zone"
//               />
//             </div>
//           </div>
//           <div className="col-2 col-sm-2 col-md-2 col-lg-2">
//             <div className="d-flex">
//               <button onClick={handlePrevious}>Previous</button>
//               <button onClick={handleNext}>Next</button>
//             </div>
//           </div>
//         </div>

//         <div className="col-lg-12 row">
//           <div ref={drop} className="col-7 col-sm-7 col-md-7 col-lg-7 scroll_video_draganddrop">
//             <div className={`board ${isOver ? "highlight" : ""}`} ref={drop}>
//               <h2>Board</h2>
//               {fetchDataForDate(selectedDate).map((video) => (
//                 <>
//                   <div key={video.id}>
//                     <div className="col-lg-12 row p-2" style={{ borderBottom: "1px solid #e5e5ea" }}>
//                       <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0">
//                         <Video
//                           id={video.id}
//                           videourl={video.videourl}
//                           scheduledDate={video.scheduledDate}
//                           scheduledTime={video.scheduledTime}
//                         />
//                       </div>
//                       <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                         <div>
//                           <p>{video.StartTime}</p>
//                         </div>
//                       </div>
//                       <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                         <p>{video.StartTime}</p>
//                       </div>

//                       <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                         <div className="d-flex">
//                           <button onClick={() => handleEditClick(video.id)}>Edit</button>
//                           <button onClick={() => handleDeleteClick(video.id)}>Delete</button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>


//                   <div>
//                     {editingIds.includes(video?.id) && (
//                       <div className="col-lg-12 row p-2" style={{ borderBottom: "1px solid #e5e5ea" }}>
//                         <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0">
//                           <div className="col-lg-12 row">
//                             <Video
//                               id={video?.id}
//                               videourl={video?.videourl}
//                               scheduledDate={video?.scheduledDate}
//                               scheduledTime={video?.scheduledTime}
//                             />
//                           </div>
//                         </div>
//                         <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                           <div>
//                             <p>{video?.StartTime}</p>
//                           </div>
//                         </div>
//                         <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                           <div>
//                             <p>{video?.EndTime}</p>
//                           </div>
//                         </div>
//                         <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                           <div>
//                             {/* <button onClick={() => handleEditClick(video?.id)}>edit</button> */}
//                             <button onClick={() => handleDeleteClick(video?.id)}>delete</button>
//                           </div>
//                         </div>
//                       </div>
//                     )}
//                   </div>

//                 </>
//               ))}
//             </div>
//           </div>
//           <div className="col-5 col-sm-5 col-md-5 col-lg-5 scroll_video_draganddrop">
//             <div className="videos">
//               <h2>Available Videos</h2>
//               <div className="col-lg-12 row" style={{ display: "flex", flexWrap: "wrap" }}>
//                 {videoList.map((video) => (
//                   <div className="col-lg-4" key={video.id} style={{ flex: "1 0 33.33%" }}>
//                     <Video id={video.id} videourl={video.videourl} />
//                     <hr />
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const EditManageCalendarschedule = () => {
//   return (
//     <DndProvider backend={HTML5Backend}>
//       <DragDrop />
//     </DndProvider>
//   );
// };

// export default EditManageCalendarschedule;





