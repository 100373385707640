import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./ViewSubcription.css";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function ViewSubcription(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    username: "pradeep",
    payment_id: "Cus_IVKRYwYv1Kj4IH",
    paid_amount: "₹ 12",
    status: "Active",
    subscription_title: "subscription title",
    payment_mode: "Online",
    expire_date: "2022-01-29 19:42:07",
  });

  const [loading, setLoading] = useState(true);
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/artist/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.artist;
      //   setEditUser(res);
      //   setEditname(res);
      setLoading(false);
      // console.log(resData)
      // console.log(res);
    };
    getUser();
  }, [id]);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className=" col-6 col-sm-6 col-lg-6">
                <h3>View Subscription</h3>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>User Details</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">Username</label>
                    <div className="mt-2">
                      <p>{editUser?.username}</p>
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Subscription Title</label>

                    <div className="mt-2">
                      <p>{editUser?.subscription_title}</p>
                    </div>
                  </div>

                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">Status</label>

                      <div className="mt-2">
                        <p>{editUser?.status}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">Expiry Date</label>

                      <div className="mt-2">
                        <p>{editUser?.expire_date}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Payment Details</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Paid Amount</label>

                    <div className="mt-2">
                      <p>{editUser?.paid_amount}</p>
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Payment ID</label>

                    <div className="mt-2">
                      <p>{editUser?.payment_id}</p>
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Payment Mode</label>

                    <div className="mt-2">
                      <p>{editUser?.payment_mode}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default ViewSubcription;
