import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

import "./SubscriptionPlan.css";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";

function SubscriptionPlan(props) {
  const [datasubscription, setDatasubscription] = useState([]);
  const [filteredSubscriptionData, setFilteredSubscriptionData] = useState([]);
  const [itemToDeleteSubscription, setItemToDeleteSubscription] = useState([]);
  const [modalCloseSubscription, setModalCloseSubscription] = useState(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchDatasubscription = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/subscription/index`,
        { headers: headers }
      );
      setDatasubscription(response?.data?.AllSubscriptionPlan);
      setFilteredSubscriptionData(response?.data?.AllSubscriptionPlan);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(async () => {
    fetchDatasubscription();
  }, []);
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredSubscriptionData?.length },
    ],
  });

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteSubscription([...itemToDeleteSubscription, row?.id]);
        } else {
          setItemToDeleteSubscription(
            itemToDeleteSubscription.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteSubscription,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAll}
        />
      </div>
    ),
  };

  const planFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="rightVideoTitle">
          <p>{data}</p>
          <span className="dateText">{row?.upload_on}</span>
        </div>
      </div>
    );
  };

  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit-subscription-plans/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2"> Edit Subscription</span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteSubscription(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2">Delete Subscription</span>
          </div>
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "plans_name",
      text: "Plan Name",
      formatter: planFormatter,
      sort: true,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const exportHeadersSubscription = [
    { label: "ID", key: "id" },
    { label: "PLAN NAME", key: "plans_name" },
    { label: "UPLOAD ON", key: "upload_on" },
  ];

  const conditionalGeneratePDFSubscription = (get) => {
    const doc = new jsPDF();
    doc.text("ALL Subscription LIST ", 10, 10);
    const columns = ["INDEX", "ID", "PLAN NAME", "UPLOAD ON"];
    const allDataPDF = datasubscription?.map((item, index) => [
      index + 1,
      item?.id,
      item?.plans_name,
      item?.upload_on,
    ]);
    const filterPDF = filteredSubscriptionData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.plans_name,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save(
      get === 1 ? "ALL-SUBSCRIPTION-LIST.pdf" : "SUBSCRIPTION-FILTERED-LIST.pdf"
    );
  };

  const generateXLSXSUBSCRIPTION = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(
        get === 1 ? datasubscription : filteredSubscriptionData
      );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(
      wb,
      get === 1
        ? "ALL-SUBSCRIPTION-LIST.xlsx"
        : "SUBSCRIPTION-FILTERED-LIST.xlsx"
    );
  };

  const handleFilterChangeSubscription = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = datasubscription?.filter((item) =>
      ["plans_name", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredSubscriptionData(filteredResults);
  };

  const handleClear = () => {
    fetchDatasubscription();
    setFilter("");
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDeleteSubscription(
      selectAll ? [] : filteredSubscriptionData?.map((item) => item?.id)
    );
  };

  const closeSubscriptionModal = () => {
    setModalCloseSubscription(!modalCloseSubscription);
  };

  const openModalSingleDeleteSubscription = (id) => {
    setItemToDeleteSubscription([id]);
    setModalCloseSubscription(true);
  };

  const openModalMultiDeleteSubscription = () => {
    setModalCloseSubscription(true);
  };

  const handleDeleteSubscription = () => {
    closeSubscriptionModal();
    subscriptionDeleteOperation();
  };

  const subscriptionDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteSubscription + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/subscription/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <>
      <div
        className={`modal packageModel  ${modalCloseSubscription ? "show" : ""
          }`}
        tabIndex="-1"
        style={{
          display: modalCloseSubscription ? "block" : "none",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeSubscriptionModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteSubscription?.length === 1 ? (
                <p className="fs-6">
                  {" "}
                  Are you sure you want to delete this Subscription ?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected Subscriptions ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={closeSubscriptionModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteSubscription}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-6">
              <h4>
                All Subscriptions{" "}
                <span className="fs-6">
                  (Display all {datasubscription?.length} Subscription)
                </span>
              </h4>
            </div>
            <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
              {itemToDeleteSubscription?.length > 0 && (
                <button
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModalMultiDeleteSubscription}
                >
                  <MdDelete className="exportIcon " />
                  Delete
                </button>
              )}
              <Link
                to="/add-subscription-plans"
                className="btn btn-primary fw-bolder"
              >
                <i className="fa fa-plus-circle"></i> Add Subscription
              </Link>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
              <div className="filterContainer">
                <input
                  type="text"
                  placeholder="Filter all.."
                  className="filter form-control "
                  value={filter}
                  onChange={handleFilterChangeSubscription}
                />
                {filter?.length > 0 && (
                  <button
                    type="button"
                    className="closeBtn"
                    onClick={handleClear}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                )}
              </div>
              <div className="btn-group">
                <div className="dropdown d-block " key={"2"}>
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export </span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeadersSubscription}
                        data={filteredSubscriptionData}
                        className="text-dark d-block"
                        filename="SUBSCRIPTION-FILTERED-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFSubscription(0);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXSUBSCRIPTION(0);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>

                <div className="dropdown d-block ">
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export All</span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeadersSubscription}
                        data={datasubscription}
                        className="text-dark d-block"
                        filename="ALL-SUBSCRIPTION-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFSubscription(1);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXSUBSCRIPTION(1);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bootstrapTable">
              <BootstrapTable
                keyField="id"
                columns={columns}
                data={filteredSubscriptionData}
                pagination={pagination}
                className="no-border-table"
                noDataIndication={
                  <div className="noRecord">
                    {loading ? (
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>
                        Loading...
                      </label>
                    ) : (
                      filteredSubscriptionData?.length <= 0 && (
                        <p>No Subscription Available !!!</p>
                      )
                    )}
                  </div>
                }
                hover
                headerClasses="videoWidth"
                selectRow={selectRow}
                selectAll={selectAll}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SubscriptionPlan;
