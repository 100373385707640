import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./ContentAddRole.css";
import { useNavigate } from "react-router-dom";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function ContentAddRole(props) {
  const navigate = useNavigate();
  const nameRef = useRef();
  const [permissions, setPermissions] = useState([]);
  const [roleName, setRoleName] = useState(null);
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const [errors, setErrors] = useState();

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(async () => {
    const initialCheckedPermissions = permissions.map(
      (permission) => permission.id
    );
    setCheckedPermissions(initialCheckedPermissions);
  }, [permissions]);
  const handleCheckboxChange = (id) => {
    if (checkedPermissions.includes(id)) {
      setCheckedPermissions(
        checkedPermissions.filter((permissionId) => permissionId !== id)
      );
    } else {
      setCheckedPermissions([...checkedPermissions, id]);
    }
  };
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/content-partner/role-index`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.ContentPartnerPermissions;
        setPermissions(result);
      })
      .catch((error) => console.log(error));
  }, []);
  const handleRoleName = (e) => {
    setRoleName(e.target.value);
    setErrors("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!roleName) {
      setErrors("Role name is required");
      nameRef.current.focus();
      return;
    }

    const formDatas = new FormData();
    formDatas.append("role_name", roleName);
    formDatas.append("user_permission", checkedPermissions.join(","));
    setProcessing(true);
    setShowOverlay(true);

    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/content-partner/role-store`,
        formDatas,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data;
        if (result.status === true) {
          props?.setApiresponsealert(result);
          props?.setShowalert(true);
          setProcessing(false);
          setShowOverlay(false);
          navigate("/moderator/view");
        }
      })
      .catch((err) => {
        console.error(err);
        var errorMessage = "An error occurred while processing your request.";
        props?.setApiresponsealert({ status: false, message: errorMessage });
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      });
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Roles & Permission</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleSubmit} className="btn btn-primary">
                    Save Roles
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Page</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Role Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="role_name"
                        name="role_name"
                        className="rs-input form-control-lg"
                        onChange={handleRoleName}
                        autoFocus
                        ref={nameRef}
                      />
                    </div>
                    <span className="errorred">
                      {errors && (
                        <span className="errorred errorredspan p-0">
                          {errors}
                        </span>
                      )}
                    </span>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleSubmit} className="btn btn-primary">
                    Save Roles
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Status Settings</h5>
                  <hr></hr>

                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-body justify-content-between">
                      <label className="">Enable this page Header </label>
                      {permissions &&
                        permissions?.map((permission, index) => {
                          return (
                            <div
                              className="col-md-12 col-sm-12 col-lg-12"
                              key={index}
                            >
                              <div className="">
                                <div className="row col-lg-12 mt-2">
                                  <div className="col-9 col-md-9 col-sm-9 col-lg-9">
                                    <p className=""> {permission?.name}</p>
                                  </div>
                                  <div className="col-3 col-md-3 col-sm-3 col-lg3 text-end">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={checkedPermissions.includes(
                                          permission?.id
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(permission?.id)
                                        }
                                        name="user_permission"
                                        value=""
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    </>
  );
}

export default ContentAddRole;
