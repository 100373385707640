// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import './Main_labels.css'
// import { Link } from "react-router-dom";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import Response_Loading_processing from "../../../../layout/Others/Response_Loading_processing";
// import backarrow from "../../../../components/Images/backarrow.svg";

// function Audio_labels(props) {

//     const [getAudiolists, setAudiolists] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [processing, setProcessing] = useState(false);
//     const [showOverlay, setShowOverlay] = useState(false);

//     const access_token = localStorage.getItem("access_token");
//     const headers = {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + access_token,
//         Accept: "application/json",
//         "Access-Control-Allow-Origin": "*",
//     };

//     useEffect(() => {
//         const fetchDataAudio = async () => {
//             try {
//                 const response = await axios.get(
//                     `${process.env.REACT_APP_Baseurl}/admin/videos-category/index`,
//                     { headers: headers }
//                 );
//                 setAudiolists(response?.data?.AllVideoCategory);
//                 setLoading(false);
//             } catch (error) {
//                 console.error("Error fetching data:", error);
//                 setLoading(false);
//             }
//         };

//         fetchDataAudio();
//     }, []);

//     async function handleUpdateAudio(updatedItem) {
//         const formData = new FormData();

//         formData.append("id", updatedItem?.id);
//         formData.append("slug", updatedItem?.slug);
//         formData.append("name", updatedItem?.name);

//         let firstApiStatus = false;
//         let resultapi = null;
//         setProcessing(true);
//         setShowOverlay(true);

//         try {
//             const response = await axios.post(
//                 `${process.env.REACT_APP_Baseurl}/admin/Audio/update/test`,
//                 formData,
//                 { headers: headers }
//             );

//             if (response.data.status === true) {
//                 firstApiStatus = true;

//                 resultapi = response.data;

//                 // Update the local state with the updated data
//                 const updatedList = [...getAudiolists];
//                 const itemIndex = updatedList.findIndex((item) => item.id === updatedItem.id);

//                 if (itemIndex !== -1) {
//                     updatedList[itemIndex] = { ...updatedList[itemIndex], ...updatedItem };
//                     setAudiolists(updatedList);
//                 }
//             }
//         } catch (error) { }

//         if (firstApiStatus) {
//             props.setApiresponsealert(resultapi);
//             props.setShowalert(true);
//         } else {
//             if (resultapi && resultapi.status === false) {
//                 var resultError = resultapi;
//                 props.setApiresponsealert(resultError);
//                 props.setShowalert(false);
//                 setProcessing(false);
//                 setShowOverlay(false);
//             }
//         }
//     }


//     const [getAudiocategory, setAudiocategory] = useState([]);
//     useEffect(() => {
//         const fetchDataAudiocategoty = async () => {
//             try {
//                 const response = await axios.get(
//                     `${process.env.REACT_APP_Baseurl}/admin/videos-category/index`,
//                     { headers: headers }
//                 );
//                 setAudiocategory(response?.data?.AllVideoCategory);
//                 setLoading(false);
//             } catch (error) {
//                 console.error("Error fetching data:", error);
//                 setLoading(false);
//             }
//         };

//         fetchDataAudiocategoty();
//     }, []);


//     async function handleUpdateAudiocategory(updatedItem) {
//         const formData = new FormData();

//         formData.append("id", updatedItem?.id);
//         formData.append("slug", updatedItem?.slug);
//         formData.append("name", updatedItem?.name);

//         let firstApiStatus = false;
//         let resultapi = null;
//         setProcessing(true);
//         setShowOverlay(true);

//         try {
//             const response = await axios.post(
//                 `${process.env.REACT_APP_Baseurl}/admin/Audio-category/update/test`,
//                 formData,
//                 { headers: headers }
//             );

//             if (response.data.status === true) {
//                 firstApiStatus = true;

//                 resultapi = response.data;

//                 // Update the local state with the updated data
//                 const updatedList = [...getAudiolists];
//                 const itemIndex = updatedList.findIndex((item) => item.id === updatedItem.id);

//                 if (itemIndex !== -1) {
//                     updatedList[itemIndex] = { ...updatedList[itemIndex], ...updatedItem };
//                     setAudiocategory(updatedList);
//                 }
//             }
//         } catch (error) { }

//         if (firstApiStatus) {
//             props.setApiresponsealert(resultapi);
//             props.setShowalert(true);
//         } else {
//             if (resultapi && resultapi.status === false) {
//                 var resultError = resultapi;
//                 props.setApiresponsealert(resultError);
//                 props.setShowalert(false);
//                 setProcessing(false);
//                 setShowOverlay(false);
//             }
//         }
//     }


//     return (
//         <>

//             <Link to="/labels" className="backarrow">
//                 <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
//                 <span> Back</span>
//             </Link>

//             <section className="section Label_settings container-fluid pt-3">
//                 <div className="row">
//                     <div className="col-sm-12">

//                         <div className="iq-card-body table-responsive iq-card">
//                             <div className="table-view">
//                                 <ul
//                                     className="nav nav-pills justify-content-center row"
//                                     id="pills-tab "
//                                     role="tablist"
//                                 >
//                                     <li
//                                         className="nav-item col-lg-4 col-12 col-sm-6 col-md-3 "
//                                         role="presentation"
//                                     >
//                                         <button
//                                             className="nav-link active fs-6 fw-bold px-4 w-100  mx-auto "
//                                             id="pills-home-tab"
//                                             data-bs-toggle="pill"
//                                             data-bs-target="#pills-home"
//                                             type="button"
//                                             role="tab"
//                                             aria-controls="pills-home"
//                                             aria-selected="true"
//                                         >
//                                             Audio Lists
//                                         </button>
//                                     </li>
//                                     <li
//                                         className="nav-item col-lg-4 col-12 col-sm-6 col-md-3 "
//                                         role="presentation"
//                                     >
//                                         <button
//                                             className="nav-link fs-6 fw-bold px-4 w-100  mx-auto"
//                                             id="pills-profile-tab"
//                                             data-bs-toggle="pill"
//                                             data-bs-target="#pills-profile"
//                                             type="button"
//                                             role="tab"
//                                             aria-controls="pills-profile"
//                                             aria-selected="false"
//                                         >
//                                             Audio Category Lists
//                                         </button>
//                                     </li>

//                                 </ul>
//                                 <div className="tab-content p-0" id="pills-tabContent">
//                                     <div
//                                         className="tab-pane fade show active"
//                                         id="pills-home"
//                                         role="tabpanel"
//                                         aria-labelledby="pills-home-tab"
//                                     >

//                                         <section className="section container-fluid">
//                                             <div id="content-page" className="content-page">
//                                                 <div className="mt-3">
//                                                     <div className="bootstrapTable ">
//                                                         <div className="col-12 p-0">
//                                                             <div className="row m-0 p-0">
//                                                                 <div className="col-lg-4 col-sm-4 col-4 data">
//                                                                     {" "}
//                                                                     <div>Text</div>
//                                                                 </div>
//                                                                 <div className="col-lg-4 col-sm-4 col-4 data">
//                                                                     {" "}
//                                                                     <div>Help Text</div>
//                                                                 </div>
//                                                                 <div className="col-lg-4 col-sm-4 col-4 data">
//                                                                     {" "}
//                                                                     <div>Actions</div>
//                                                                 </div>
//                                                             </div>
//                                                             <DragDropContext >
//                                                                 <Droppable droppableId="menu-items">
//                                                                     {(provided) => (
//                                                                         <ul {...provided.droppableProps} ref={provided.innerRef}>
//                                                                             {loading ? (
//                                                                                 <div className="noRecord">
//                                                                                     {" "}
//                                                                                     <Response_Loading_processing></Response_Loading_processing>
//                                                                                     Loading...
//                                                                                 </div>
//                                                                             ) : getAudiolists?.length <= 0 ? (
//                                                                                 <div className="noRecord">
//                                                                                     {" "}
//                                                                                     <p>No Audio Lists Available !!! </p>
//                                                                                 </div>
//                                                                             ) : (
//                                                                                 getAudiolists?.map((item, index) => (
//                                                                                     <Draggable
//                                                                                     >
//                                                                                         {(provided) => (
//                                                                                             <li
//                                                                                                 ref={provided.innerRef}
//                                                                                                 {...provided.draggableProps}
//                                                                                                 {...provided.dragHandleProps}
//                                                                                                 className={`bg-white orderborder ${item?.order
//                                                                                                     } index ${index + 1}w-100`}
//                                                                                             >
//                                                                                                 <div className="row m-0 p-0 align-items-center">

//                                                                                                     <div className="col-lg-4 col-sm-4 col-4 data_th">
//                                                                                                         <div className="d-flex justify-content-between align-items-center">
//                                                                                                             <div className="rightAudioTitle">
//                                                                                                                 <input
//                                                                                                                     type="text"
//                                                                                                                     className="rs-input form-control-lg"
//                                                                                                                     value={item?.name}
//                                                                                                                     onChange={(e) => {
//                                                                                                                         const newName = e.target.value;
//                                                                                                                         const updatedList = [...getAudiolists];
//                                                                                                                         updatedList[index].name = newName;
//                                                                                                                         setAudiolists(updatedList);
//                                                                                                                     }}
//                                                                                                                 />
//                                                                                                             </div>
//                                                                                                         </div>
//                                                                                                     </div>

//                                                                                                     <div className="col-lg-4 col-sm-4 col-4 data_th">
//                                                                                                         <div className="d-flex justify-content-between align-items-center">
//                                                                                                             <div className="rightAudioTitle">
//                                                                                                                 <input
//                                                                                                                     type="text"
//                                                                                                                     className="rs-input form-control-lg"
//                                                                                                                     value={item?.slug}
//                                                                                                                     onChange={(e) => {
//                                                                                                                         const newSlug = e.target.value;
//                                                                                                                         const updatedList = [...getAudiolists];
//                                                                                                                         updatedList[index].slug = newSlug;
//                                                                                                                         setAudiolists(updatedList);
//                                                                                                                     }}
//                                                                                                                 />
//                                                                                                             </div>
//                                                                                                         </div>
//                                                                                                     </div>
//                                                                                                     <div className="col-lg-4 col-sm-4 col-4 data_th">
//                                                                                                         <div className="d-flex justify-content-between align-items-center">

//                                                                                                             <div className="rightAudioTitle">
//                                                                                                                 <button className="btn btn-primary" onClick={() => handleUpdateAudio(item)}>Update</button>
//                                                                                                             </div>

//                                                                                                         </div>
//                                                                                                     </div>
//                                                                                                 </div>
//                                                                                             </li>
//                                                                                         )}
//                                                                                     </Draggable>
//                                                                                 ))
//                                                                             )}
//                                                                             {provided.placeholder}
//                                                                         </ul>
//                                                                     )}
//                                                                 </Droppable>
//                                                             </DragDropContext>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </section>
//                                     </div>

//                                     <div
//                                         className="tab-pane fade"
//                                         id="pills-profile"
//                                         role="tabpanel"
//                                         aria-labelledby="pills-profile-tab"
//                                     >
//                                         <section className="section container-fluid">
//                                             <div id="content-page" className="content-page">
//                                                 <div className="mt-3">
//                                                     <div className="bootstrapTable ">
//                                                         <div className="col-12 p-0">
//                                                             <div className="row m-0 p-0">
//                                                                 <div className="col-lg-4 col-sm-4 col-4 data">
//                                                                     {" "}
//                                                                     <div>Text</div>
//                                                                 </div>
//                                                                 <div className="col-lg-4 col-sm-4 col-4 data">
//                                                                     {" "}
//                                                                     <div>Help Text</div>
//                                                                 </div>
//                                                                 <div className="col-lg-4 col-sm-4 col-4 data">
//                                                                     {" "}
//                                                                     <div>Actions</div>
//                                                                 </div>
//                                                             </div>
//                                                             <DragDropContext >
//                                                                 <Droppable droppableId="menu-items">
//                                                                     {(provided) => (
//                                                                         <ul {...provided.droppableProps} ref={provided.innerRef}>
//                                                                             {loading ? (
//                                                                                 <div className="noRecord">
//                                                                                     {" "}
//                                                                                     <Response_Loading_processing></Response_Loading_processing>
//                                                                                     Loading...
//                                                                                 </div>
//                                                                             ) : getAudiocategory?.length <= 0 ? (
//                                                                                 <div className="noRecord">
//                                                                                     {" "}
//                                                                                     <p>No Audio Category Lists Available !!! </p>
//                                                                                 </div>
//                                                                             ) : (
//                                                                                 getAudiocategory?.map((item, index) => (
//                                                                                     <Draggable
//                                                                                     >
//                                                                                         {(provided) => (
//                                                                                             <li
//                                                                                                 ref={provided.innerRef}
//                                                                                                 {...provided.draggableProps}
//                                                                                                 {...provided.dragHandleProps}
//                                                                                                 className={`bg-white orderborder ${item?.order
//                                                                                                     } index ${index + 1}w-100`}
//                                                                                             >
//                                                                                                 <div className="row m-0 p-0 align-items-center">

//                                                                                                     <div className="col-lg-4 col-sm-4 col-4 data_th">
//                                                                                                         <div className="d-flex justify-content-between align-items-center">
//                                                                                                             <div className="rightAudioTitle">
//                                                                                                                 <input
//                                                                                                                     type="text"
//                                                                                                                     className="rs-input form-control-lg"
//                                                                                                                     value={item?.name}
//                                                                                                                     onChange={(e) => {
//                                                                                                                         const newName = e.target.value;
//                                                                                                                         const updatedList = [...getAudiolists];
//                                                                                                                         updatedList[index].name = newName;
//                                                                                                                         setAudiocategory(updatedList);
//                                                                                                                     }}
//                                                                                                                 />
//                                                                                                             </div>
//                                                                                                         </div>
//                                                                                                     </div>

//                                                                                                     <div className="col-lg-4 col-sm-4 col-4 data_th">
//                                                                                                         <div className="d-flex justify-content-between align-items-center">
//                                                                                                             <div className="rightAudioTitle">
//                                                                                                                 <input
//                                                                                                                     type="text"
//                                                                                                                     className="rs-input form-control-lg"
//                                                                                                                     value={item?.slug}
//                                                                                                                     onChange={(e) => {
//                                                                                                                         const newSlug = e.target.value;
//                                                                                                                         const updatedList = [...getAudiolists];
//                                                                                                                         updatedList[index].slug = newSlug;
//                                                                                                                         setAudiocategory(updatedList);
//                                                                                                                     }}
//                                                                                                                 />
//                                                                                                             </div>
//                                                                                                         </div>
//                                                                                                     </div>
//                                                                                                     <div className="col-lg-4 col-sm-4 col-4 data_th">
//                                                                                                         <div className="d-flex justify-content-between align-items-center">

//                                                                                                             <div className="rightAudioTitle">
//                                                                                                                 <button className="btn btn-primary" onClick={() => handleUpdateAudiocategory(item)}>Update</button>
//                                                                                                             </div>

//                                                                                                         </div>
//                                                                                                     </div>
//                                                                                                 </div>
//                                                                                             </li>
//                                                                                         )}
//                                                                                     </Draggable>
//                                                                                 ))
//                                                                             )}
//                                                                             {provided.placeholder}
//                                                                         </ul>
//                                                                     )}
//                                                                 </Droppable>
//                                                             </DragDropContext>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </section>
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }


// export default Audio_labels;


import React, { useState, useEffect } from "react";
import axios from "axios";
import './Main_labels.css'
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Response_Loading_processing from "../../../../layout/Others/Response_Loading_processing";
import backarrow from "../../../../components/Images/backarrow.svg";

function Audio_labels(props) {

    const [getAudiolists, setAudiolists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    const access_token = localStorage.getItem("access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    useEffect(() => {
        const fetchDataAudio = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_Baseurl}/admin/videos-category/index`,
                    { headers: headers }
                );
                setAudiolists(response?.data?.AllVideoCategory);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchDataAudio();
    }, []);

    async function handleUpdateAudio(updatedItem) {
        const formData = new FormData();

        formData.append("id", updatedItem?.id);
        formData.append("slug", updatedItem?.slug);
        formData.append("name", updatedItem?.name);

        let firstApiStatus = false;
        let resultapi = null;
        setProcessing(true);
        setShowOverlay(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_Baseurl}/admin/Audio/update/test`,
                formData,
                { headers: headers }
            );

            if (response.data.status === true) {
                firstApiStatus = true;

                resultapi = response.data;

                // Update the local state with the updated data
                const updatedList = [...getAudiolists];
                const itemIndex = updatedList.findIndex((item) => item.id === updatedItem.id);

                if (itemIndex !== -1) {
                    updatedList[itemIndex] = { ...updatedList[itemIndex], ...updatedItem };
                    setAudiolists(updatedList);
                }
            }
        } catch (error) { }

        if (firstApiStatus) {
            props.setApiresponsealert(resultapi);
            props.setShowalert(true);
        } else {
            if (resultapi && resultapi.status === false) {
                var resultError = resultapi;
                props.setApiresponsealert(resultError);
                props.setShowalert(false);
                setProcessing(false);
                setShowOverlay(false);
            }
        }
    }


    const [getAudiocategory, setAudiocategory] = useState([]);
    useEffect(() => {
        const fetchDataAudiocategoty = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_Baseurl}/admin/videos-category/index`,
                    { headers: headers }
                );
                setAudiocategory(response?.data?.AllVideoCategory);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchDataAudiocategoty();
    }, []);


    async function handleUpdateAudiocategory(updatedItem) {
        const formData = new FormData();

        formData.append("id", updatedItem?.id);
        formData.append("slug", updatedItem?.slug);
        formData.append("name", updatedItem?.name);

        let firstApiStatus = false;
        let resultapi = null;
        setProcessing(true);
        setShowOverlay(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_Baseurl}/admin/Audio-category/update/test`,
                formData,
                { headers: headers }
            );

            if (response.data.status === true) {
                firstApiStatus = true;

                resultapi = response.data;

                // Update the local state with the updated data
                const updatedList = [...getAudiolists];
                const itemIndex = updatedList.findIndex((item) => item.id === updatedItem.id);

                if (itemIndex !== -1) {
                    updatedList[itemIndex] = { ...updatedList[itemIndex], ...updatedItem };
                    setAudiocategory(updatedList);
                }
            }
        } catch (error) { }

        if (firstApiStatus) {
            props.setApiresponsealert(resultapi);
            props.setShowalert(true);
        } else {
            if (resultapi && resultapi.status === false) {
                var resultError = resultapi;
                props.setApiresponsealert(resultError);
                props.setShowalert(false);
                setProcessing(false);
                setShowOverlay(false);
            }
        }
    }


    return (
        <>

            <Link to="/labels" className="backarrow">
                <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                <span> Back</span>
            </Link>
            
            <section className="section Label_settings container-fluid pt-3">
                <div className="row">
                    <div className="col-sm-12">

                        <div className="iq-card-body table-responsive iq-card">
                            <div className="table-view">
                                <ul
                                    className="nav nav-pills justify-content-center row"
                                    id="pills-tab "
                                    role="tablist"
                                >
                                    <li
                                        className="nav-item col-lg-4 col-12 col-sm-6 col-md-3 "
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link active fs-6 fw-bold px-4 w-100  mx-auto "
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-home"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                        >
                                            Audio Lists
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item col-lg-4 col-12 col-sm-6 col-md-3 "
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link fs-6 fw-bold px-4 w-100  mx-auto"
                                            id="pills-profile-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                        >
                                            Audio Category Lists
                                        </button>
                                    </li>

                                </ul>
                                <div className="tab-content p-0" id="pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-home"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                    >

                                        <section className="section container-fluid">
                                            <div id="content-page" className="content-page">
                                                <div className="mt-3">
                                                    <div className="bootstrapTable ">
                                                        <div className="col-12 p-0">
                                                            <div className="row m-0 p-0">
                                                                <div className="col-lg-4 col-sm-4 col-4 data">
                                                                    {" "}
                                                                    <div>Text</div>
                                                                </div>
                                                                <div className="col-lg-4 col-sm-4 col-4 data">
                                                                    {" "}
                                                                    <div>Help Text</div>
                                                                </div>
                                                                <div className="col-lg-4 col-sm-4 col-4 data">
                                                                    {" "}
                                                                    <div>Actions</div>
                                                                </div>
                                                            </div>
                                                            <DragDropContext >
                                                                <Droppable droppableId="menu-items">
                                                                    {(provided) => (
                                                                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                                                                            {loading ? (
                                                                                <div className="noRecord">
                                                                                    {" "}
                                                                                    <Response_Loading_processing></Response_Loading_processing>
                                                                                    Loading...
                                                                                </div>
                                                                            ) : getAudiolists?.length <= 0 ? (
                                                                                <div className="noRecord">
                                                                                    {" "}
                                                                                    <p>No Audio Lists Available !!! </p>
                                                                                </div>
                                                                            ) : (
                                                                                getAudiolists?.map((item, index) => (
                                                                                    <Draggable
                                                                                    >
                                                                                        {(provided) => (
                                                                                            <li
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                className={`bg-white orderborder ${item?.order
                                                                                                    } index ${index + 1}w-100`}
                                                                                            >
                                                                                                <div className="row m-0 p-0 align-items-center">

                                                                                                    <div className="col-lg-4 col-sm-4 col-4 data_th">
                                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                                            <div className="rightAudioTitle">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="rs-input form-control-lg"
                                                                                                                    value={item?.name}
                                                                                                                    onChange={(e) => {
                                                                                                                        const newName = e.target.value;
                                                                                                                        const updatedList = [...getAudiolists];
                                                                                                                        updatedList[index].name = newName;
                                                                                                                        setAudiolists(updatedList);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="col-lg-4 col-sm-4 col-4 data_th">
                                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                                            <div className="rightAudioTitle">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="rs-input form-control-lg"
                                                                                                                    value={item?.slug}
                                                                                                                    onChange={(e) => {
                                                                                                                        const newSlug = e.target.value;
                                                                                                                        const updatedList = [...getAudiolists];
                                                                                                                        updatedList[index].slug = newSlug;
                                                                                                                        setAudiolists(updatedList);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-lg-4 col-sm-4 col-4 data_th">
                                                                                                        <div className="d-flex justify-content-between align-items-center">

                                                                                                            <div className="rightAudioTitle">
                                                                                                                <button className="btn btn-primary" onClick={() => handleUpdateAudio(item)}>Update</button>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        )}
                                                                                    </Draggable>
                                                                                ))
                                                                            )}
                                                                            {provided.placeholder}
                                                                        </ul>
                                                                    )}
                                                                </Droppable>
                                                            </DragDropContext>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                    <div
                                        className="tab-pane fade"
                                        id="pills-profile"
                                        role="tabpanel"
                                        aria-labelledby="pills-profile-tab"
                                    >
                                        <section className="section container-fluid">
                                            <div id="content-page" className="content-page">
                                                <div className="mt-3">
                                                    <div className="bootstrapTable ">
                                                        <div className="col-12 p-0">
                                                            <div className="row m-0 p-0">
                                                                <div className="col-lg-4 col-sm-4 col-4 data">
                                                                    {" "}
                                                                    <div>Text</div>
                                                                </div>
                                                                <div className="col-lg-4 col-sm-4 col-4 data">
                                                                    {" "}
                                                                    <div>Help Text</div>
                                                                </div>
                                                                <div className="col-lg-4 col-sm-4 col-4 data">
                                                                    {" "}
                                                                    <div>Actions</div>
                                                                </div>
                                                            </div>
                                                            <DragDropContext >
                                                                <Droppable droppableId="menu-items">
                                                                    {(provided) => (
                                                                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                                                                            {loading ? (
                                                                                <div className="noRecord">
                                                                                    {" "}
                                                                                    <Response_Loading_processing></Response_Loading_processing>
                                                                                    Loading...
                                                                                </div>
                                                                            ) : getAudiocategory?.length <= 0 ? (
                                                                                <div className="noRecord">
                                                                                    {" "}
                                                                                    <p>No Audio Category Lists Available !!! </p>
                                                                                </div>
                                                                            ) : (
                                                                                getAudiocategory?.map((item, index) => (
                                                                                    <Draggable
                                                                                    >
                                                                                        {(provided) => (
                                                                                            <li
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                className={`bg-white orderborder ${item?.order
                                                                                                    } index ${index + 1}w-100`}
                                                                                            >
                                                                                                <div className="row m-0 p-0 align-items-center">

                                                                                                    <div className="col-lg-4 col-sm-4 col-4 data_th">
                                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                                            <div className="rightAudioTitle">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="rs-input form-control-lg"
                                                                                                                    value={item?.name}
                                                                                                                    onChange={(e) => {
                                                                                                                        const newName = e.target.value;
                                                                                                                        const updatedList = [...getAudiolists];
                                                                                                                        updatedList[index].name = newName;
                                                                                                                        setAudiocategory(updatedList);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="col-lg-4 col-sm-4 col-4 data_th">
                                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                                            <div className="rightAudioTitle">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="rs-input form-control-lg"
                                                                                                                    value={item?.slug}
                                                                                                                    onChange={(e) => {
                                                                                                                        const newSlug = e.target.value;
                                                                                                                        const updatedList = [...getAudiolists];
                                                                                                                        updatedList[index].slug = newSlug;
                                                                                                                        setAudiocategory(updatedList);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-lg-4 col-sm-4 col-4 data_th">
                                                                                                        <div className="d-flex justify-content-between align-items-center">

                                                                                                            <div className="rightAudioTitle">
                                                                                                                <button className="btn btn-primary" onClick={() => handleUpdateAudiocategory(item)}>Update</button>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        )}
                                                                                    </Draggable>
                                                                                ))
                                                                            )}
                                                                            {provided.placeholder}
                                                                        </ul>
                                                                    )}
                                                                </Droppable>
                                                            </DragDropContext>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}


export default Audio_labels;






