import React from "react";
import { Link } from "react-router-dom";

function Debug() {
  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid pt-3">
          <div id="content-page" className="content-page">
            <div className="container-fluid p-0">
              <div id="webhomesetting">
                <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">ENV APP DEBUG Management</h4>
                    </div>
                  </div>
                  <hr />
                  <div className="panel panel-primary mt-3" data-collapsed="0">
                    <div className="panel-body">
                      <div className="row align-items-center p-2">
                        <div className="col-sm-3">
                          <button
                            className="clear-btn"
                            role="button"
                            id="clear_cache"
                          >
                            {" "}
                            App Debug True{" "}
                          </button>
                        </div>
                        <div className="col-sm-4">
                          <button
                            className="clear-btn"
                            role="button"
                            id="clear_cache"
                          >
                            {" "}
                            App Debug False{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Debug;
