import React from 'react'

const SeriesLists = () => {
  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card p-2 mt-3">
              <div className="row align-items-center p-2">
                <div className="col-md-5">
                  <h4>
                    <i className="entypo-movie"></i> Series
                  </h4>
                </div>
              </div>

              <div className="clear"></div>

              <div className="gallery-env">
                <div className="row mt-3 p-2">
                  <table className="table text-center iq-card">
                    <thead>
                      <tr className="table-header r1">
                        <th>
                          <label>S.No</label>
                        </th>
                        <th>
                          <label>Image</label>
                        </th>
                        <th>
                          <label>Series Title</label>
                        </th>
                        <th>
                          <label>Genre</label>
                        </th>
                        <th>
                          <label>Slider</label>
                        </th>
                        <th>
                          <label>Operation</label>
                        </th>
                      </tr>
                    </thead>
                    {/* {series.map((item, key) => (
                      <thead>
                        <tr>
                          <td key={key}> {key + 1} </td>
                          <td>
                            <img
                              src={item.Thumbnail}
                              alt="Thumbnail"
                              width={200}
                            />
                          </td>
                          <td>{item.title}</td>
                          <td>{item.genre_id}</td>
                          <td>
                            <label className="switch">
                              <input
                                name="ppv_status"
                                defaultChecked={
                                  item.ppv_status == 1 ? true : false
                                }
                                checked={item.ppv_status == 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="ppv_status"
                                value={item.ppv_status == 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </td>
                          <td>
                            <Link
                              to={"/admin/edit-series/" + item.id}
                              className="edit ms-1"
                            >
                              <i
                                className="fa fa-pencil-square"
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link to="" className="delete ms-1">
                              <span onClick={() => deleteOperation(item.id)}>
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </Link>
                          </td>
                        </tr>
                      </thead>
                    ))} */}
                  </table>

                  <div className="row text-center d-flex">
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        // onClick={handlePreClick}
                      >
                        {"<< Previous"}
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <p className="">
                        {/* {page} */}
                        </p>
                    </div>
                    <div className="col-lg-4">
                      <button
                        type="button"
                        className="btn btn-primary"
                        // onClick={handleNxtClick}
                      >
                        {"Next >>"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default SeriesLists