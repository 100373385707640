// import React from "react";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import { Line } from "react-chartjs-2";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// function CPPRevenue() {
//   const datas = [
//     {
//       partner: "Admin",
//       views: "1",
//       shared: "Zootopia",
//     },
//   ];

//   const options = {
//     type: "line",
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "top",
//       },
//     },
//   };

//   const labels = [
//     "July",
//     "December",
//     "December",
//     "April",
//     "May",
//     "May",
//     "May",
//     "May",
//     "May",
//     "May",
//     "May",
//   ];

//   const data = {
//     labels,
//     datasets: [
//       {
//         label: "Purchased Videos",

//         data: [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 0.0, 2.0],
//         borderColor: "rgb(53, 162, 235)",
//         lineTension: 0.4,
//       },
//     ],
//   };

//   return (
//     <>
//       <div className=" m-0 p-0">
//         <div className="">
//           <section className="section container-fluid pt-3">
//             <div id="content-page" className="content-page">
//               <div className="iq-card">
//                 <div className="iq-card-header  justify-content-between">
//                   <div className="iq-header-title p-0">
//                     <h4 className="card-title">Content Partners Revenue :</h4>
//                   </div>
//                 </div>

//                 <div className="row p-3">
//                   <hr />
//                   <div className="col-md-12">
//                     <div className="row mt-3">
//                       <div className="col-md-3">
//                         <label for="start_time"> Start Date: </label>
//                         <input
//                           type="date"
//                           className="form-control"
//                           id="start_time"
//                           name="start_time"
//                         />
//                       </div>
//                       <div className="col-md-3">
//                         <label for="start_time"> End Date: </label>
//                         <input
//                           type="date"
//                           className="form-control"
//                           id="end_time"
//                           name="end_time"
//                         />
//                       </div>

//                       <div className="col-md-3 d-flex align-items-end">
//                         <div className="dropdown">
//                           <button
//                             className="btn btn-outline-dark filter-btn dropdown-toggle"
//                             type="button"
//                             data-bs-toggle="dropdown"
//                             aria-expanded="false"
//                           >
//                             Filter
//                           </button>
//                           <ul className="dropdown-menu">
//                             <li>
//                               <a className="dropdown-item" href="#">
//                                 1 Day
//                               </a>
//                             </li>
//                             <li>
//                               <a className="dropdown-item" href="#">
//                                 1 Week
//                               </a>
//                             </li>
//                             <li>
//                               <a className="dropdown-item" href="#">
//                                 1 Month{" "}
//                               </a>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>

//                       <div className="col-md-3 d-flex align-items-end">
//                         <span
//                           id="export"
//                           className="btn btn-success download-btn btn-sm"
//                         >
//                           Download CSV
//                         </span>
//                       </div>
//                     </div>
//                     <div className="clear"></div>

//                     <div className="mt-5">
//                       <h4>Graph :</h4>
//                       <h4>No Revenue Data Found</h4>
//                     </div>
//                     <div>
//                       <div className="row mt-5">
//                         <div className="col-md-2"></div>
//                         <div className="col-md-7">
//                           {/* <Line options={options} data={data} />
//                           <p className='text-center'>Purchased Videos </p> */}
//                         </div>
//                         <div className="col-md-3">
//                           <h5>Currency Used : $</h5>
//                           <h5>Australia</h5>
//                           <h5>Total Views : 0</h5>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="d-flex justify-content-between p-3">
//                       <div className="d-flex">
//                         <p>Show 10</p>
//                         <p>&nbsp; &nbsp; entries</p>
//                       </div>
//                       <div className="d-flex">
//                         <p>Search:</p>
//                         <input
//                           type="search"
//                           id="gsearch"
//                           name="gsearch"
//                           className="border-0 search-rev"
//                         />
//                       </div>
//                     </div>

//                     <div className="row">
//                       <div className="col-md-12">
//                         <table className="table" id="user_tabledss">
//                           <thead>
//                             <tr className="r1">
//                               <th>#</th>
//                               <th>Content Partner</th>
//                               <th>Total Views</th>
//                               <th>% Shared Commission</th>
//                             </tr>
//                           </thead>
//                           {/* {datas.map((item, key) => (
//                             <thead>
//                               <tr className="r1">
//                                 <td key={key}> {key + 1} </td>
//                                 <td>{item.partner}</td>
//                                 <td>{item.views}</td>
//                                 <td>{item.shared}</td>


//                               </tr>
//                             </thead>
//                           ))} */}

//                           <tr className="r1">
//                             <td></td>
//                             <td></td>
//                             <td>No data available in table</td>
//                             <td></td>
//                           </tr>

//                           <tbody></tbody>
//                         </table>
//                       </div>
//                       <div className="col-md-4"></div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div></div>
//             </div>
//           </section>
//         </div>
//       </div>
//     </>
//   );
// }

// export default CPPRevenue;



import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import './CPPRevenue.css'
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

const CPPRevenue = () => {
  const [datas, setDatas] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);

      // Replace the following URL with your actual GET API endpoint
      const response = await fetch(
        `https://api.example.com/users?start_date=${startDate}&end_date=${endDate}`,
        {
          method: "GET",
          headers: {
            // Add headers if necessary
          },
        }
      );

      const data = await response.json();
      setDatas(data);
      setUserCount(data.length); // Update this based on your data structure

      setFilteredData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(async () => {
    fetchData();
  }, [startDate, endDate]);

  const handleExport = async () => {
    try {
      // Replace the following URL with your actual POST API endpoint for exporting data
      const exportResponse = await fetch("https://api.example.com/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          startDate,
          endDate,
          selectedData: filteredData,
        }),
      });

      if (exportResponse.ok) {
        const blob = await exportResponse.blob();

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "exported_data.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Export failed:", exportResponse.statusText);
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const columns = [
    {
      dataField: "sno",
      text: "S.NO",
    },
    {
      dataField: "content_partner",
      text: "Content Partner",
    },
    {
      dataField: "total_views",
      text: "TOTAL VIEWS",
    },
    {
      dataField: "shared_commission",
      text: "SHARED COMMISSION",
    },
  ];

  
const chartData = {
  labels: ["January", "February", "March"],
  datasets: [
    {
      label: "Revenue",
      data: [1000, 1500, 2000],
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 2,
      pointBackgroundColor: 'rgba(255, 99, 132, 1)',
      pointBorderColor: '#fff',
      pointBorderWidth: 2,
      pointRadius: 4,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
      pointHoverBorderWidth: 2,
      lineTension: 0.4,
    },
  ],
};

const options = {
  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Revenue ($)'
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Month'
      }
    }]
  },
  legend: {
    display: true,
    position: 'bottom',
    labels: {
      fontColor: 'rgb(0, 0, 0)'
    }
  },
};

  return (
    <div className="m-0 p-0">
      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="iq-card-header justify-content-between">
            <div className="iq-header-title p-0">
              <h4 className="card-title">Content Partners Revenue</h4>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="row p-3">
              <div className="col-md-12">
                <div>
                  <div className="row mt-2 align-items-center">
                    <div className="col-md-6 user-count">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                          <label className="user_reg_analytics_count">Currency Used</label>
                        </div>
                        <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                          <label className="user_reg_analytics_count">{userCount} United States of America</label>
                        </div>
                      </div>
                      <div className="col-lg-12 row">
                        <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                          <label className="user_reg_analytics_count">Total Views</label>
                        </div>
                        <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                          <label className="user_reg_analytics_count">{userCount}</label>
                        </div>
                      </div>

                    </div>
                    <div className="col-md-6">
                      <Line options={options} data={chartData} />
                      <p className="text-center">Content Partners Count</p>
                    </div>

                  </div>
                </div>

                <div className="col-lg-12">
                  <div className=" mt-4">

                    <div className="row align-items-center mb-3">
                      <div className="col-md-4">
                        <label htmlFor="start_time"> Start Date: </label>
                        <input
                          type="date"
                          className="rs-input form-control-lg"
                          id="start_time"
                          name="start_time"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="end_time"> End Date: </label>
                        <input
                          type="date"
                          className="rs-input form-control-lg"
                          id="end_time"
                          name="end_time"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                      {/* <div className="col-md-3 d-flex align-items-end">
                    <div className="dropdown">
                      <button
                        className="btn btn-outline-dark filter-btn dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Filter
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              const currentDate = new Date();
                              setEndDate(
                                currentDate.toISOString().split("T")[0]
                              );
                              setStartDate(
                                new Date(
                                  currentDate.setDate(currentDate.getDate() - 1)
                                )
                                  .toISOString()
                                  .split("T")[0]
                              );
                            }}
                          >
                            1 Day
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              const currentDate = new Date();
                              setEndDate(
                                currentDate.toISOString().split("T")[0]
                              );
                              setStartDate(
                                new Date(
                                  currentDate.setDate(currentDate.getDate() - 7)
                                )
                                  .toISOString()
                                  .split("T")[0]
                              );
                            }}
                          >
                            1 Week
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              const currentDate = new Date();
                              setEndDate(
                                currentDate.toISOString().split("T")[0]
                              );
                              setStartDate(
                                new Date(
                                  currentDate.setMonth(
                                    currentDate.getMonth() - 1
                                  )
                                )
                                  .toISOString()
                                  .split("T")[0]
                              );
                            }}
                          >
                            1 Month{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                      <div className="col-md-4 text-right">
                        <span
                          id="export"
                          className="btn btn-success w-50"
                          onClick={handleExport}
                        >
                          Export
                        </span>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                      <div className="filterContainer">
                        <input
                          type="text"
                          placeholder="Filter all.."
                          className="filter form-control "
                          value={filter}
                          onChange={(e) => setFilter(e.target.value)}
                        />
                        {filter?.length > 0 && (
                          <button
                            type="button"
                            className="closeBtn"
                            onClick={() => setFilter("")}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        )}
                      </div>
                      <PaginationProvider
                        pagination={paginationFactory(options)}
                      >
                        {({ paginationTableProps }) => (
                          <div className="bootstrapTable p-2">
                            <BootstrapTable
                              keyField="id"
                              columns={columns}
                              data={filteredData}
                              className="no-border-table"
                              noDataIndication={
                                <div className="noRecord">
                                  {loading ? (
                                    <label>
                                      <Response_Loading_processing></Response_Loading_processing>
                                      Loading...
                                    </label>
                                  ) : (
                                    filteredData?.length <= 0 && (
                                      <p>No Record Found!</p>
                                    )
                                  )}
                                </div>
                              }
                              hover
                              headerClasses="videoWidth"
                              selectAll={selectAll}
                              {...paginationTableProps}
                            />
                          </div>
                        )}
                      </PaginationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CPPRevenue;
