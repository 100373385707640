import React, { useState, useEffect } from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { MdDelete, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, } from "react-icons/md";
import { RxReload } from "react-icons/rx";
import "bootstrap/dist/css/bootstrap.min.css";
import "./AllUser.css";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import AdminDeleteModal from "../../../components/CommonComponents/AdminDeleteModal";
import AdminNavbar from "../../../components/CommonComponents/AdminNavbar";
import AdminHeader from "../../../components/CommonComponents/AdminHeader";
import { getItemToken } from "../../../Utils/localStorageUtils";
import { toast } from "react-toastify";
import { ActionFormatterComponent, statusFormatter } from "../../../components/CommonMethod/CommonBoostrapFormatter";
import NoRecordTable from "../../../components/CommonComponents/NoRecordTable";

function AllUser(props) {
  const [apiData, setApiData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [modelClose, setModelClose] = useState(false);
  const [itemToDelete, setItemToDelete] = useState([]);
  const [withOutRefresh, setWithoutRefresh] = useState(false)
  const [analytics, setAnalytics] = useState({
    totalSubscriber: 0,
    totalTodaySubscriber: 0,
    totalRevenue: 0
  })

  const userAccessToken = getItemToken('access_token');

  const [getUser, setUser] = useState([]);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [itemToDeleteUser, setItemToDeleteUser] = useState([]);
  const [modalCloseUser, setModalCloseUser] = useState(false);
  const [filter, setFilter] = useState("");
  const [processing, setProcessing] = useState(false);
  const [total_users_count, settotal_users_count] = useState("");
  const [total_user_subscription, settotal_user_subscription] = useState("");
  const [total_recent_subscription, settotal_recent_subscription] = useState("");
  const [total_revene, settotal_revene] = useState("");

  const userFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between gap-3 align-items-center">
        <img key={row?.user_avatar} src={row.user_avatar} alt={row?.data ? row?.data : row?.data} className="userProfileImageSize rounded-circle object-fit-cover" />
        <div className="rightUserTitle">
          <p className="userNameProfile text-break">{data}</p>
          <span className="userDateText">{row?.upload_on}</span>
        </div>
      </div>
    );
  };

  const columns = [
    // {
    //   dataField: "index",
    //   text: "S.No",
    //   headerClasses: "serialNumber",
    //   formatter: (cell, row, rowIndex) => {
    //     return rowIndex + 1;
    //   }
    // },
    {
      dataField: "name",
      text: "Users",
      formatter: userFormatter,
      sort: true,
      classes: "userDesignBox",
      headerClasses: "userDesignBox"
    },
    {
      dataField: "mobile",
      text: "Contact",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return <span className="text-break  d-flex align-items-center justify-content-center w-100">{cell}</span>
      }
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerClasses: "userEmailBox"
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      sort: true,
      formatter: (cell, row) => statusFormatter(cell, row),
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: (cell, row) => <ActionFormatterComponent cell={cell} row={row} link={'/edit-users/'} action={singleDelete} />,
    },
  ];

  const options = {
    pageStartIndex: 1,
    paginationSize: 4,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: 'All', value: Number(filteredData?.length)
    }]
  };

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDelete([...itemToDelete, row.id]);
        } else {
          setItemToDelete(itemToDelete?.filter((id) => id !== row?.id));
        }
      }
    },
    style: { width: '30px' },
    selected: itemToDelete,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input type="checkbox" checked={checked} indeterminate={indeterminate} onChange={handleAll} />
      </div>
    ),
  };

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/users/list`, { headers: userAccessToken });
      const userData = response?.data?.Allusers;
      const res = response?.data
      const FilterHelp = userData.map((item) => {
        const Role =
          item?.role == 1
            ? "Admin"
            : item?.role == 2
              ? "Register"
              : item?.role == 3
                ? "Subscriber"
                : item?.role == 4
                  ? "Channel Partner"
                  : item?.role == 5
                    ? "Content Partner"
                    : item?.role == 6
                      ? "Advertiser Partner"
                      : item?.role == 7
                        ? "Multi User"
                        : item?.role == 8
                          ? "PPV User"
                          : item?.role == 9
                            ? "Guest"
                            : "";
        const statusText =
          item?.active === 1
            ? "PUBLISHED"
            : item?.active === 0 || item?.active === null
              ? "UNPUBLISHED"
              : "DRAFT";
        return { ...item, role: Role, active: statusText };
      });
      setApiData(FilterHelp);
      setFilteredData(FilterHelp);
      setAnalytics((prev) => ({
        ...prev,
        totalSubscriber: res?.total_user_subscription,
        totalTodaySubscriber: res?.total_recent_subscription,
        totalRevenue: res?.total_revene
      }))
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    setShowOverlay(true);
    try {
      const formData = new FormData();
      formData.append("id", "[" + itemToDelete + "]");
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/users/multi-delete`, formData, { headers: userAccessToken });
      const result = response?.data;
      if (result?.status) {
        setWithoutRefresh(!withOutRefresh);
        toast.success(result?.message);
        setShowOverlay(false);
        setItemToDelete([])
      }
      else {
        toast.error(result?.message);
        setShowOverlay(false);
      }
    } catch (err) {
      var errorMessage = err?.response?.data?.message;
      toast.error(errorMessage);
      setShowOverlay(false);
    }
  };



  // Api links end 


  const closeModal = () => {
    setModelClose(!closeModal);
    setShowModal(false);
  };

  const openModal = () => {
    setModelClose(true);
  };

  const singleDelete = (id) => {
    setModelClose(true);
    setItemToDelete([id]);
  };

  const handleDeletedata = () => {
    handleConfirmDelete();
    closeModal();
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDelete(selectAll ? [] : filteredData?.map((item) => item?.id));
  };

  const handleFilterChange = (data) => {
    setFilteredData(data);
  };

  useEffect(async () => {
    fetchData();
  }, [withOutRefresh]);


  const handleClear = () => {
    fetchData();
    setFilter("");
  };

  const handleReload = () => {
    setWithoutRefresh(!withOutRefresh);
    setApiData([])
    setAnalytics((prev) => ({
      ...prev,
      totalSubscriber: 0,
      totalTodaySubscriber: 0,
      totalRevenue: 0
    }))
  }


  return (
    <>
      {showOverlay && <Response_Processing />}
      {modelClose && <AdminDeleteModal modelClose={modelClose} deleteClose={closeModal} deleteAction={handleDeletedata} buttonText={itemToDelete?.length} />}
      <section className="pb-4">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="col-12 col-md-6 col-lg-4  p-2">
            <div className="text-center theme-bg-color p-3 rounded-3">
              <p className="countHead mb-2"> {analytics?.totalSubscriber}</p>
              <p className="countHead mb-1">Total Subcribers</p>
              <span className="countDes">The Total Amount Of Subscribers On Your Site.</span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4  p-2">
            <div className="text-center theme-bg-color p-3 rounded-3">
              <p className="countHead mb-2"> {analytics?.totalTodaySubscriber}</p>
              <p className="countHead mb-1">New Subcribers</p>
              <span className="countDes">New Subscribers For Today.</span>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4  p-2">
            <div className="text-center theme-bg-color p-3  rounded-3 ">
              <p className="countHead mb-2"> {analytics?.totalRevenue}</p>
              <p className="countHead mb-1">Revenue</p>
              <span className="countDes">Total Revenue</span>
            </div>
          </div>
        </div>
      </section>

      <AdminNavbar heading={'All Users'} totalDataLength={apiData?.length} deleteCount={itemToDelete?.length} navigation={'/add-users'} deleteMethod={openModal} navText={'Add Users'} />
      <div className="theme-bg-color p-3 rounded-2 bootstrapTable  multi-delete-width" >
        <AdminHeader searchMethod={handleFilterChange} reduceData={filteredData} originalData={apiData} fileName={'USER-LIST'} customUser reloadAction={handleReload} reload loading={loading} />
        <BootstrapTable keyField="id" pagination={paginationFactory(options)} columns={columns} data={loading ? [] : filteredData} className="no-border-table theme-text-color " noDataIndication={<NoRecordTable result={filteredData?.length} loader={loading} />} hover headerClasses="videoWidth" selectRow={selectRow} selectAll={selectAll} />
      </div>
    </>
  );
}

export default AllUser;