import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Calendar from 'react-calendar';
import "./AdsCampaigns.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

function AdsCampaigns() {
  // const [value, onChange] = useState(new Date());

  const handleDateClick = (arg) => {
    alert(arg.dateStr);
  };

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid pt-3">
          <div id="content-page" className="content-page">
            <div className="col-lg-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Ads Campaign</h4>
                  </div>
                </div>

                {/* <Calendar onChange={onChange} value={value} /> */}

                <FullCalendar
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  // weekends={false}
                  headerToolbar={{
                    left: "prev, next, today",
                    center: "title",
                    right: "dayGridMonth, dayGridWeek, dayGridDay",
                  }}
                  dateClick={(e) => handleDateClick(e)}
                  events={[
                    { title: "event 1", date: "2021-05-07" },
                    { title: "event 2", date: "2021-05-17" },
                  ]}
                  // eventContent={renderEventContent}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AdsCampaigns;
