import React, { useState, useEffect, useRef } from "react";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import { useNavigate, useParams } from "react-router-dom";

function Editorderhome(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    header_name: "",
    video_name: "",
    order_id: "",
    url: "",
    user_id: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Web_HomePage_Order;
      setEditUser(res);
      // console.log(res);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [validationMessageheader_name, setValidationMessageheader_name] =
    useState("");
  const [isValidationHiddenheader_name, setIsValidationHiddenheader_name] =
    useState(true);

  const header_nameInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.header_name === "") {
      setValidationMessageheader_name("Header Name cannot be empty.");
      setIsValidationHiddenheader_name(false);
      focusInputRef = header_nameInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      order_id: editUser?.order_id,
      url: editUser?.url,
      video_name: editUser?.video_name,
      header_name: editUser?.header_name,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/update`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      setValidationMessageheader_name("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setIsValidationHiddenheader_name(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Order Home</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Order
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-12 col-md-12 col-lg-12 ">
                <div className="card p-3">
                  {/* <h5>{editname?.plans_name}</h5> */}
                  {/* <hr></hr> */}
                  <div className=" text-start">
                    <label className="m-0">
                      Home Page Header<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="username"
                        name="header_name"
                        placeholder=""
                        value={editUser?.header_name}
                        onChange={handleInput}
                        ref={header_nameInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenheader_name && (
                        <p>{validationMessageheader_name}</p>
                      )}
                    </span>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">URL (ex. /site/url)</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="username"
                        name="url"
                        placeholder=""
                        value={editUser?.url}
                        onChange={handleInput}
                      />
                    </div>
                  </div>

                  {/* <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Plan
                  </button>
                </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>


    </>
  );
}

export default Editorderhome;
