import { finalRouteApi } from "./CommonApiUrl"


const movieOrderDefaultURL = {
    listApi: `${finalRouteApi}/Movies-HomePage-Order/index`,
    orderApi: `${finalRouteApi}/Movies-HomePage-Order/ordering`,
    editApi: `${finalRouteApi}/Movies-HomePage-Order/edit`,
    updateApi: `${finalRouteApi}/Movies-HomePage-Order/update`,
    editRouteLink: `${finalRouteApi}/Audio_URL`,
    enableSettingURL: `${finalRouteApi}/Web-MoviesHomePage/index`,
    updateSettingURL: `${finalRouteApi}/Web-MoviesHomePage/update`,
    enableSettingMobileURL: `${finalRouteApi}/Mobile-MoviesHomePage/index`,
}

export { movieOrderDefaultURL }