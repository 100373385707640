import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from 'react-router-dom'
import "./layout.css";
import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

const Layout = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const location = useLocation()

  useEffect(() => {
    setIsOpen(false)
  }, [location])

  return (
    <>
      <Sidebar toggleClassName={isOpen} toggleAction={toggle} />
      <div className="adminSideBarRight theme-bg-color-secondary">
        <Header props={props} toggleAction={toggle} />
        <div className="p-md-4 p-sm-2 p-0 adminHeadContainer">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
