import React from 'react'

const Wrapper = ({ children, className }) => {
    return (
        <div className={`rounded-3 mb-4 theme-bg-color p-3 ${className}`}>
            {children}
        </div>

    )
}

export default Wrapper