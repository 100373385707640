import Multiselect from "multiselect-react-dropdown";
import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";

const EditLiveEventArtist = () => {
  const options = [
    {
      value: 0,
      text: "Drama",
    },
    {
      value: 1,
      text: "News",
    },
    {
      value: 2,
      text: "Kids",
    },
    {
      value: 3,
      text: "Music",
    },
  ];

  return (
    <div>
      <div>
        <div className=" m-0 p-0">
          <div className="">
            <section className="container-fluid">
              <div className="col-lg-12 p-2">
                <div className="card ">
                  <div className="card-body">
                    <div className="container-fluid p-0">
                      <div className="p-3">
                        <div>
                          <div className="row">
                            <div className="col-md-6">
                              <h4>
                                <i className="entypo-archive"></i> Edit Live
                                Event Artist
                              </h4>
                            </div>
                            <div className="col-md-6" align="right">
                              <Link to="/add-live-event-artist">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  <AiOutlineEye /> Preview
                                </button>
                              </Link>
                            </div>
                          </div>
                          <hr />
                          <div>
                            <label>Embed Link:</label>
                            <p>
                              Click <Link className="text-primary"> Here </Link>{" "}
                              To Get The Embedded URL
                            </p>
                          </div>
                          <div className="row mt-5 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Title</label>
                              <p className="">
                                Add the Live stream title in the textbox below:
                              </p>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Title"
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <label className="m-0">Published Date</label>
                              <p className="">
                                Video Published On Date/Time Below
                              </p>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Slug</label>
                              <p className="">
                                Add the Live stream slug in the textbox below:
                              </p>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="slug"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6"></div>
                          </div>
                          <div className="row mt-3 text-start align-items-center">
                            <div className="col-md-6">
                              <label className="m-0">Video Image Cover</label>
                              <p className="p1">
                                Select The Video Image ( 9:16 Ratio Or
                                1080X1920px ):
                              </p>
                              <div>
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder="Live_stream_video"
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row">
                                <label className="m-0">
                                  Player Image Cover
                                </label>
                                <p className="">
                                  Select The Video Image( 16:9 Ratio Or
                                  1280X720px ):
                                </p>
                                <div>
                                  <input
                                    type="file"
                                    className="form-control"
                                    placeholder="player_image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Video Source</label>
                              <p className="p1">
                                Select The Live Stream Source
                              </p>
                              <div className="">
                                <div>
                                  <select className="form-select url_type">
                                    <option defaultValue="0" selected>
                                      Choose URL Format
                                    </option>
                                    <option value="mp4" selected>
                                      mp4
                                    </option>
                                    <option value="Embed_url">Embed_url</option>
                                    <option value="Mp3">Mp3</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <label className="m-0">TV Image Cover</label>
                              <div className="new-video-upload" id="">
                                <p className="p1">
                                  Select The Live Stream Image (1920 X 1080 Or
                                  16:9 Ratio) :
                                </p>
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder="Tv_live_image"
                                />
                              </div>
                              <div></div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Enable ReStream</label>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="enable_restream"
                                    className="enable_restream"
                                    id="enable_restream"
                                    type="checkbox"
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div></div>
                              <div></div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">Enable Tips</label>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="enable_restream"
                                    className="enable_restream"
                                    id="enable_restream"
                                    type="checkbox"
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Search Tags</label>
                              <div className="">
                                <input
                                  className="form-control me-2"
                                  type="search"
                                  placeholder="search_tags"
                                  aria-label="Search"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">Donations label</label>
                              <div className="">
                                <input
                                  className="form-control me-2"
                                  type="search"
                                  placeholder="Donations label"
                                  aria-label="Search"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3 text-start">
                            <div className="col-md-6">
                              <label className="m-0">Short Description</label>
                              <p className="">
                                Add a short description of the Livestream below:
                              </p>
                              <div className="">
                                <textarea
                                  className="form-control"
                                  placeholder="Description"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">Enable Chat</label>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="enable_restream"
                                    className="enable_restream"
                                    id="enable_restream"
                                    type="checkbox"
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">
                                Video Details, Links, and Info
                              </label>
                              <div className="">
                                <input
                                  className="form-control me-2"
                                  type="search"
                                  placeholder=""
                                  aria-label="Search"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6"></div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Category</label>
                              <p className="p1">
                                Select A Live Stream Category Below:
                              </p>

                              <div className="">
                                <div className="text-dark">
                                  <Multiselect
                                    options={options}
                                    displayValue="text"
                                    showCheckbox
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">Language</label>
                              <p className="p1">
                                Select A Live Stream Language Below:
                              </p>
                              <div className="">
                                <div className="text-dark">
                                  <Multiselect
                                    options={options}
                                    displayValue="text"
                                    showCheckbox
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Video Ratings</label>
                              <p className="p1">
                                Livestream Ratings 10 Out Of 10
                              </p>

                              <div className="">
                                <div className="text-dark">
                                  <Multiselect
                                    options={options}
                                    displayValue="text"
                                    showCheckbox
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">Video Year</label>
                              <p className="p1">Video Released Year</p>
                              <div className="">
                                <div className="text-dark">
                                  <div className="">
                                    <input
                                      className="form-control me-2"
                                      type="search"
                                      placeholder=""
                                      aria-label="Search"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Duration</label>
                              <p className="p1">
                                Enter The Video Duration In (HH : MM : SS)
                              </p>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="duration"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">User Access</label>
                              <p className="p1">
                                Who Is Allowed To View This Video?
                              </p>
                              <div>
                                <select
                                  id="in_name"
                                  name="in_name"
                                  className="form-control"
                                >
                                  <option value="0">Guest (everyone)</option>
                                  <option value="1">
                                    Subscriber (only paid subscription user)
                                  </option>
                                  <option value="0">
                                    PPV Users (Pay per movie)
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">PPV Price</label>
                              <p className="p1">
                                Apply PPV Price From Global Settings?
                              </p>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">IOS PPV Price</label>
                              <p className="p1">
                                Apply IOS PPV Price From Global
                              </p>
                              <div>
                                <select
                                  id="in_name"
                                  name="in_name"
                                  className="form-control"
                                >
                                  <option value="0">
                                    Select IOS PPV Price
                                  </option>
                                  <option value="1">349</option>
                                  <option value="0">179</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-4">
                              <label className="m-0">Publish Type</label>
                              <div className="p2">
                                <div className="form-check  mt-2 ms-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexRadioDefault1"
                                  >
                                    Publish Now
                                  </label>
                                </div>
                                <div className="form-check  mt-2 ms-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexRadioDefault1"
                                  >
                                    Publish Later
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-3"></div>

                            <div className="col-sm-5" id="publishlater">
                              <label className="m-0">Status Settings</label>
                              <div>
                                <p className="p2">
                                  Is this Live stream Featured:
                                  <label className="switch">
                                    <input
                                      name="featured"
                                      className="featured form-group"
                                      id="featured"
                                      type="checkbox"
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </p>
                              </div>
                              <div>
                                <p className="p2">
                                  Is this Live stream Active:
                                  <label className="switch">
                                    <input
                                      name="active"
                                      className="active form-group"
                                      id="active"
                                      type="checkbox"
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </p>
                              </div>

                              <div>
                                <p className="p2">
                                  Is this Live stream display in Banner:
                                  <label className="switch">
                                    <input
                                      name="banner"
                                      className="banner form-group"
                                      id="banner"
                                      type="checkbox"
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="text-end">
                            <button className="btn btn-primary">Updated</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLiveEventArtist;
