import { CiShare1 } from "react-icons/ci";
import { Link } from "react-router-dom";

import { FaEllipsisVertical } from "react-icons/fa6";
import MessageBox from "../CommonComponents/MessageBox";

//BoostrapTable formatter
const statusFormatter = (cell, row) => {
    return (
        <div className={`d-inline-flex justify-content-center px-3 py-1 text-center ${cell === "PUBLISHED" ? "PUBLISHED_active" : cell === "SCHEDULED" ? " DISABLED_active" : "UNPUBLISHED_active"}`} > {cell} </div>
    );
};

const videoFormatter = (cell, row, link) => {
    return (
        <div className="d-flex justify-content-between align-items-center">
            {row?.image_url || row?.Thumbnail ? <img key={row?.image_url} src={row.image_url ? row.image_url : row.Thumbnail} alt={row?.title ? row?.title : row?.title} className="profile theme-bg-color-secondary " /> : <div className="profile theme-bg-color-secondary text-dark d-flex align-items-center justify-content-center admin-input-title">{row?.title?.slice(0, 1).toUpperCase()}</div>}
            <div className="rightVideoTitle">
                <Link className="theme-text-color table-heading d-flex  align-items-center" target="_blank" to={`${process.env.REACT_APP_Baseurl_Frontend}/${link}/${row.slug ? row?.slug : row?.artist_slug}`}> <span>{`${row.title ? row?.title?.slice(0, 15) : row?.albumname ? row?.albumname?.slice(0, 15) : row?.artist_name?.slice(0, 15)}...`} </span><CiShare1 style={{ width: "15px", height: "15px", strokeWidth: '1px', marginLeft: "6px" }} /> </Link>
                {row?.duration != "0" ? <span className="me-1  dateText theme-text-color table-subheading"> {row?.duration}</span> : ""}
                <span className="dateText table-subheading theme-text-color">{row?.upload_on}</span>
            </div>
        </div>
    );
};

const ActionFormatterComponent = ({ cell, row, link, action }) => {
    return (
        <div className="editdropdown ">
            <span className="editdropdown-button">
                <FaEllipsisVertical className="theme-text-color" />
            </span>
            <div className="editdropdown-menu theme-bg-color">
                <Link to={`${link}${row?.id}`} className="theme-text-color  theme-bg-color">
                    <span className="theme-text-color">
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g>
                                <path class="svgIcon" d="M10.3,18.5l-4.74.64A.49.49,0,0,1,5,18.59l.64-4.74a.42.42,0,0,1,.14-.28L17.31,2a.59.59,0,0,1,.85,0L22.11,6a.6.6,0,0,1,0,.84L10.58,18.36A.53.53,0,0,1,10.3,18.5Z" />
                                <line class="svgIcon" x1="1.01" y1="21.58" x2="18.89" y2="21.58" />
                            </g>
                        </svg>
                        <MessageBox text="Edit" classname='ms-2 theme-text-color'></MessageBox>
                    </span>
                </Link>
                <div
                    onClick={() => action(row?.id)}
                    className="commonActionPadding theme-bg-color"
                >
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g>
                            <rect class="svgIcon" x="4.38" y="5.54" width="14.49" height="17.71" />
                            <line class="svgIcon" x1="1.16" y1="5.54" x2="22.09" y2="5.54" />
                            <g>
                                <line class="svgIcon" x1="9.21" y1="9.56" x2="9.21" y2="19.06" />
                                <line class="svgIcon" x1="14.04" y1="9.56" x2="14.04" y2="19.06" />
                            </g>
                            <path class="svgIcon" d="M7.45,5.22a2.72,2.72,0,0,1,0-.28,4.19,4.19,0,1,1,8.37,0c0,.09,0,.19,0,.29" />
                        </g>
                    </svg>
                    <MessageBox text="Delete" classname='ms-2 theme-text-color'></MessageBox>
                </div>
            </div>
        </div>
    );
};
const ActionFormatterTemplateComponent = ({ cell, row, link }) => {
    return (
        <div className="editdropdown rounded-2 ">
            <span className="editdropdown-button">
                <FaEllipsisVertical className="theme-text-color" />
            </span>
            <div className="editdropdown-menu theme-bg-color rounded-2">
                <Link to={`${link}${row?.id}`} className="theme-text-color  theme-bg-color">
                    <span className="theme-text-color">
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g>
                                <path class="svgIcon" d="M10.3,18.5l-4.74.64A.49.49,0,0,1,5,18.59l.64-4.74a.42.42,0,0,1,.14-.28L17.31,2a.59.59,0,0,1,.85,0L22.11,6a.6.6,0,0,1,0,.84L10.58,18.36A.53.53,0,0,1,10.3,18.5Z" />
                                <line class="svgIcon" x1="1.01" y1="21.58" x2="18.89" y2="21.58" />
                            </g>
                        </svg>
                        <MessageBox text="Edit" classname='ms-2 theme-text-color'></MessageBox>
                    </span>
                </Link>
            </div>
        </div>
    );
};


const SliderFormatterComponent = ({ cell, row, action }) => {
    return (
        <div>
            <label className="switch">
                <input
                    name="banner"
                    id="banner"
                    className={`${Number(cell) == 0 ? "inactive" : "active"} ${cell}`}
                    checked={cell == 1}
                    onChange={() => action(row)}
                    onClick={() => action(row)}
                    type="checkbox"
                />
                <span className="slider round"></span>
            </label>
        </div>
    )
}


export { ActionFormatterTemplateComponent, statusFormatter, videoFormatter, ActionFormatterComponent, SliderFormatterComponent }