import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify"
import axios from "axios"
import { movieOrderDefaultURL } from '../../../components/CommonApis/CommonMovieOrderApi';
import { adminApiHeader } from '../../../components/CommonApis/CommonApiUrl';
import Response_Processing from '../../../layout/Others/Response_Processing';
import AdminSaveHeader from '../../../components/CommonComponents/AdminSaveHeader';
import Wrapper from '../../../components/CommonComponents/Wrapper';
import MessageLabel from '../../../components/CommonComponents/MessageLabel';

const MovieOrderEdit = () => {
    const { id } = useParams();
    const [withOutRefresh, setWithOutRefresh] = useState(false)
    const [apiData, setApiData] = useState([]);
    const [showOverlay, setShowOverlay] = useState(false);
    useEffect(() => {
        fetData()
    }, [withOutRefresh])

    const fetData = async () => {
        try {
            setShowOverlay(true)
            const response = await axios.get(`${movieOrderDefaultURL?.editApi}/${id}`, { headers: adminApiHeader })
            const result = response?.data?.Web_MoviesHomePage_Order;
            if (response?.data?.status) {
                setApiData(result)
                setShowOverlay(false)
            }
            else {
                toast.error(response?.data?.message)
                setShowOverlay(false)
            }

        }
        catch (error) {
            const errMessage = error?.response?.data?.message
            console.error(errMessage)
        }
    }

    const handleInput = (e) => {
        const { name, value } = e.target;
        setApiData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleUpdate = async () => {
        try {
            setShowOverlay(true)
            const formData = new FormData();
            for (const key in apiData) {
                if (['id', 'header_name', 'url'].includes(key)) {
                    formData.append(key, apiData[key]);
                }
            }
            const response = await axios.post(`${movieOrderDefaultURL?.updateApi}`, formData, { headers: adminApiHeader })
            if (response?.data?.status) {
                toast.success(response?.data?.message)
                setShowOverlay(false)
                setWithOutRefresh(!withOutRefresh)
            }
            else {
                toast.error(response?.data?.message)
                setShowOverlay(false)
            }

        }
        catch (error) {
            const errMessage = error?.response?.data?.message
            console.error(errMessage)
        }
    }
    return (
        <div>
            {showOverlay && (<Response_Processing />)}
            <AdminSaveHeader heading="Update Order Movie " saveText="Update Order" saveMethod={handleUpdate} />
            <Wrapper>
                <MessageLabel text="Movie Header Name" />
                <input type="text" name="header_name" value={apiData?.header_name} onChange={handleInput} placeholder="header name" className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0 mb-3" />
                <MessageLabel text='URL (ex. /site/url)' />
                <input type="text" name="url" value={apiData?.url} onChange={handleInput} placeholder="Site url" className="mb-3 rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0" />
            </Wrapper>
        </div>
    )
}

export default MovieOrderEdit