import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

const EditContentPartnerView = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const nameRef = useRef();
  const [errors, setErrors] = useState();
  const [checkboxData, setCheckboxData] = useState([]);
  const [checkedStatus, setCheckedStatus] = useState({});

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleCheckboxChange = (id) => {
    if (
      Array.isArray(checkedStatus.ContentPartnerPermissions) &&
      checkedStatus.ContentPartnerPermissions.includes(id)
    ) {
      setCheckedStatus((prevState) => {
        return {
          ...prevState,
          ContentPartnerPermissions: prevState.ContentPartnerPermissions.filter(
            (permissionId) => permissionId !== id
          ),
        };
      });
    } else {
      setCheckedStatus((prevState) => {
        return {
          ...prevState,
          ContentPartnerPermissions: [
            ...prevState.ContentPartnerPermissions,
            id,
          ],
        };
      });
    }
  };

  useEffect(async () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/content-partner/role-edit/${id}`,
        { headers: headers }
      )
      .then((response) => {
        setCheckboxData(response?.data?.AllContentPartnerPermissions);
        setCheckedStatus(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching data from the first API:", error);
      });
  }, []);

  const handleUpdate = (e) => {
    e.preventDefault();

    if (!checkedStatus?.ContentPartnerRoles?.role_name) {
      setErrors("Role is required");
      nameRef.current.focus();
      return;
    }
    const formDatas = new FormData();
    formDatas.append(
      "role_name",
      checkedStatus?.ContentPartnerRoles?.role_name
    );
    formDatas.append(
      "user_permission",
      checkedStatus?.ContentPartnerPermissions.join(",")
    );
    setProcessing(true);
    setShowOverlay(true);
    axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/content-partner/role-update/${id}`,
        formDatas,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data;
        if (result?.status === true) {
          props?.setApiresponsealert(result);
          props?.setShowalert(true);
          setProcessing(true);
          setShowOverlay(true);
          window.location.reload();
        }
      })
      .catch((err) => {
        console.error(err);
        var errorMessage = "An error occurred while processing your request.";
        props?.setApiresponsealert({ status: false, message: errorMessage });
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      });
  };
  const handleInputChange = (e) => {
    setCheckedStatus((prevState) => {
      return {
        ...prevState,
        ContentPartnerRoles: {
          role_name: e.target.value,
        },
      };
    });
    setErrors("");
  };

  return (
    <div className=" m-0 p-0">
      {/* <div>
        {showOverlay && (
          <div className="overlay-container">
            <span className="overlay-icon">
              {" "}
              <img src={loadings} width={50} height={50} alt="Animated GIF" />
              Processing ...
            </span>
          </div>
        )}
      </div> */}

      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Update Roles</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Roles
                  </button>
                </div>
              </div>
            </div>
            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  {/* <hr></hr> */}
                  <div className=" text-start">
                    <label className="m-0">
                      Role Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="username"
                        name="role_name"
                        onChange={handleInputChange}
                        ref={nameRef}
                        value={checkedStatus?.ContentPartnerRoles?.role_name}
                        autoFocuss
                      />
                    </div>
                    <span className="errorred">
                      {errors && (
                        <span className="errorred errorredspan p-0">
                          {errors}
                        </span>
                      )}
                    </span>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Page
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Status Settings</h5>
                  <hr></hr>

                  <div className="panel panel-primary" data-collapsed="0">
                    <label className="">Enable this page Header </label>
                    <div className="panel-body justify-content-between">
                      {checkboxData?.map((data, id) => {
                        const isChecked =
                          checkedStatus &&
                          Array.isArray(
                            checkedStatus.ContentPartnerPermissions
                          ) &&
                          checkedStatus.ContentPartnerPermissions.includes(
                            id + 1
                          );

                        return (
                          <div
                            className="col-md-12 col-sm-12 col-lg-12"
                            key={id}
                          >
                            <div className="mt-2">
                              <div className="row col-lg-12">
                                <div className="col-9 col-md-9 col-sm-9 col-lg-9">
                                  <p className=""> {data.name}</p>
                                </div>
                                <div className="col-3 col-md-3 col-sm-3 col-lg3 text-end">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="user_permission[]"
                                      value="permissions"
                                      checked={isChecked}
                                      onChange={() =>
                                        handleCheckboxChange(data.id)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditContentPartnerView;
