// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("museosans_700-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("museosans_700-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("museosans-100-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("museosans-100-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("museosans-300-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("museosans-300-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("museosans_500-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("museosans_500-webfont.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 26, 2022 */



@font-face {
    font-family: 'museo_sans700';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
         url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'museo_sans100';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
         url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'museo_sans300';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2'),
         url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'museo_sans500';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('woff2'),
         url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('woff');
    font-weight: normal;
    font-style: normal;

}`, "",{"version":3,"sources":["webpack://./src/components/museo_font/stylesheet.css"],"names":[],"mappings":"AAAA,oFAAoF;;;;AAIpF;IACI,4BAA4B;IAC5B;+DACqD;IACrD,mBAAmB;IACnB,kBAAkB;;AAEtB;;;;;AAKA;IACI,4BAA4B;IAC5B;+DACqD;IACrD,mBAAmB;IACnB,kBAAkB;;AAEtB;;;;;AAKA;IACI,4BAA4B;IAC5B;+DACqD;IACrD,mBAAmB;IACnB,kBAAkB;;AAEtB;;;;;AAKA;IACI,4BAA4B;IAC5B;+DACqD;IACrD,mBAAmB;IACnB,kBAAkB;;AAEtB","sourcesContent":["/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 26, 2022 */\n\n\n\n@font-face {\n    font-family: 'museo_sans700';\n    src: url('museosans_700-webfont.woff2') format('woff2'),\n         url('museosans_700-webfont.woff') format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}\n\n\n\n\n@font-face {\n    font-family: 'museo_sans100';\n    src: url('museosans-100-webfont.woff2') format('woff2'),\n         url('museosans-100-webfont.woff') format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}\n\n\n\n\n@font-face {\n    font-family: 'museo_sans300';\n    src: url('museosans-300-webfont.woff2') format('woff2'),\n         url('museosans-300-webfont.woff') format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}\n\n\n\n\n@font-face {\n    font-family: 'museo_sans500';\n    src: url('museosans_500-webfont.woff2') format('woff2'),\n         url('museosans_500-webfont.woff') format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
