import React, { useState, useEffect, useRef } from "react";
import "./signin.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Forgot from "./Forgot";
import Defaultloadinggif from "../../layout/Others/Defaultloadinggif";
import MessageLabel from "../CommonComponents/MessageLabel";
import { TbEyeClosed } from "react-icons/tb";
import MessageBox from "../CommonComponents/MessageBox";
import { MdOutlineMailOutline } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { VscEye } from "react-icons/vsc";
import { toast } from "react-toastify";


const Signin = () => {
  const prevLocation = localStorage.getItem('location');
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };


  const emailRef = useRef(null)
  const passwordRef = useRef(null)

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [details, setDetails] = useState({
    logo_url: "",
  });
  const [inputField, setInputField] = useState({
    email: "",
    password: "",
  });
  const [errField, setErrField] = useState({
    email: "",
    password: "",
  });

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  function handleChange(e) {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
    setErrField((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: "",
    }));
  }

  const handleValidate = async (e) => {
    e.preventDefault()
    if (formValidation()) {
      handleLogin()
    }
    else {
      console.log("Form validation failed ");
    }
  }

  const formValidation = () => {
    let formIsValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!inputField?.email) {
      setErrField((prevFormData) => ({
        ...prevFormData,
        email: "Email cannot be empty.",
      }));
      emailRef.current.focus();
      formIsValid = false;
    } else if (!regex.test(inputField?.email)) {
      setErrField((prevFormData) => ({
        ...prevFormData,
        email: "Email format is incorrect.",
      }));
      emailRef.current.focus();
      formIsValid = false;
    }
    if (!inputField?.password) {
      setErrField((prevFormData) => ({
        ...prevFormData,
        password: "Passsword cannot be empty",
      }));
      passwordRef.current.focus();
      formIsValid = false;
    }
    return formIsValid
  }

  const handleLogin = async () => {
    setLoading(true);
    const formData = { email: inputField?.email, password: inputField?.password }
    await axios.post(`${process.env.REACT_APP_Baseurl}/users/signin`, formData, { headers: headers }).then((res) => {
      const resData = res?.data
      if (resData?.status) {
        if (resData?.user?.role == "1") {
          localStorage.setItem("access_token", resData?.access_token);
          localStorage.setItem("user_id", resData?.user?.id);
          localStorage.setItem("role", resData?.user?.role);
          localStorage.setItem("username", resData?.user?.username);
          localStorage.setItem("image_url", resData?.user?.image_url);
          localStorage.setItem("expires_in", resData?.expires_in);
          setTimeout(() => {
            window.location.href = (prevLocation ? prevLocation : '/');
            // window.location.reload();
          }, 500);
          setLoading(false);
        }
        else {
          toast.error("Login failed. Please check your credentials*");
          setLoading(false);
        }
      }
      else {
        toast.error("Login failed. Please check your credentials*");
        setLoading(false);
      }
    }).catch(() => {
      toast.error("Login failed. Please check your credentials*");
      setLoading(false);
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      const formData = new FormData();
      formData.append("Login_user_type", 9);
      formData.append("Login_user_id", null);
      formData.append("Login_device_type", "web");

      try {
        const reqData = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Header-index`, formData);
        setDetails(reqData?.data?.admin_theme_mode_details);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(async () => {
    if (localStorage.getItem("access_token") !== null) {
      await navigate("/");
    }
  }, []);

  return (
    <div className="theme-bg-color">
      {/* <Navbar />  */}
      <Forgot logo={details?.logo_url}></Forgot>
      <form onSubmit={handleValidate} >
        <div className="d-flex justify-content-center align-items-center min-vh-100">
          <div className="shadow border border-1 theme-border-color p-4 rounded-3 col-11 col-lg-6 col-xl-4 mx-auto">
            <div className="mt-2 mb-4">
              {details?.logo_url === null ? (<Defaultloadinggif ></Defaultloadinggif>)
                : (<img src={details?.logo_url} alt="SignInLogo" className="d-block mx-auto signin-logo-images w-50 object-fit-contain" />)}
            </div>

            <MessageLabel text="Email" className="" />
            <div className="position-relative">
              <MdOutlineMailOutline className="lockIcon position-absolute" />
              <input type="email" name="email" value={inputField?.email} onChange={handleChange} placeholder="Enter  email" ref={emailRef} className="rs-input py-3 px-5 form-control-lg custom-placeholder theme-text-color  mb-3" />
              {errField?.email && (<MessageBox text={`${errField?.email}`} classname='errorred mb-2 d-block' />)}
            </div>

            <MessageLabel text="Password" className="" />
            <div className="position-relative">
              <input type={passwordType} name="password" value={inputField?.password} onChange={handleChange} placeholder="Enter Password" ref={passwordRef} className="rs-input py-3 px-5 form-control-lg custom-placeholder theme-text-color  mb-3" />
              <button onClick={togglePassword} type="button" className="position-absolute passWordIcon bg-transparent">
                {passwordType == "password" ? <TbEyeClosed className="eyeClosed " /> : <VscEye className="eyeOpen " />}
              </button>
              <RiLockPasswordLine className="lockIcon position-absolute" />
              {errField?.password && (<MessageBox text={`${errField?.password}`} classname='errorred mb-2 d-block' />)}
              <span className="forgot" data-bs-toggle="modal" data-bs-target="#exampleModal"><MessageBox text={`Forgot Password?`} classname='' /></span>
            </div>

            <div className="d-flex my-3  align-items-center justify-content-end">
              <button disabled={loading} type="submit" className={` btn button-color button-bg-color admin-button-text text-white rounded-2  py-2 px-3 `}>{loading ? <div className='custom-loading mx-auto'></div> : <MessageBox text={'login'} />}</button>
            </div>
            {errorMessage && (<MessageBox text={`${errorMessage}`} classname='errorred mb-2 d-block text-center' />)}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Signin;
