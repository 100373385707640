import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import "./Editalbum.css";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import { albumDefaultURL } from "../../../components/CommonApis/CommonAudioApis";
import AdminSaveHeader from "../../../components/CommonComponents/AdminSaveHeader";
import FormContainer from "../../../components/CommonComponents/FormContainer";
import LeftWrapper from "../../../components/CommonComponents/LeftWrapper";
import Wrapper from "../../../components/CommonComponents/Wrapper";
import MessageHeading from "../../../components/CommonComponents/MessageHeading";
import MessageLabel from "../../../components/CommonComponents/MessageLabel";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import MessageSubHeading from "../../../components/CommonComponents/MessageSubHeading";
import { adminApiHeader } from "../../../components/CommonApis/CommonApiUrl";
import { toast } from "react-toastify";
import FetchLoader from "../../../components/CommonComponents/FetchLoader";
import RightWrapper from "../../../components/CommonComponents/RightWrapper";
import { IoClose } from "react-icons/io5";
import { FaFileAlt } from "react-icons/fa";



function Editalbum() {
  const { id } = useParams();
  const [formData, setFormData] = useState()
  const [formDataError, setFormDataError] = useState({})
  const [defaultOption, setDefaultOption] = useState()
  const [coverImageFile, setCoverImageFile] = useState(null);
  const [copyRightFile, setCopyRightFile] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [withOutRefresh, setWithOutRefresh] = useState(false)
  const [loading, setLoading] = useState(true)

  const titleInputRef = useRef(null);
  const slugInputRef = useRef(null);
  const coverImageRef = useRef(null)
  const copyRightRef = useRef(null)


  // api start
  const fetchData = async () => {
    const response = await axios.get(`${albumDefaultURL?.editAlbumApi}/${id}`, { headers: adminApiHeader });
    const result = response?.data;
    const editResult = result?.audio_album;
    if (response?.data?.status) {
      setFormData(editResult)
      setLoading(false);
    }
    else {
      console.error(result?.message)
      setLoading(false);
    }
  };

  const handleGetCategory = async () => {
    try {
      const response = await axios.get(`${albumDefaultURL?.createListApi}`, { headers: adminApiHeader })
      const result = response?.data?.audio_albums;
      if (response?.data?.status) {
        setDefaultOption(result)
      }
    }
    catch (err) {
      console.error(err)
    }
  }

  const handleAdd = async () => {
    setShowOverlay(true);
    try {
      const formDataAdd = new FormData();
      for (const key in formData) {
        if (['albumname', 'slug', 'parent_id'].includes(key)) {
          formDataAdd.append(key, formData[key]);
        }
      }

      const response = await axios.post(`${albumDefaultURL?.updateAlbumApi}/${id}`, formDataAdd, { headers: adminApiHeader });
      const result = response?.data;
      if (result?.status) {
        if (coverImageFile) {
          await handleImageUpload(result?.AudioAlbum_id)
        }
        if (copyRightFile) {
          await handleCopyRightUpload(result?.AudioAlbum_id)
        }
        setWithOutRefresh(!withOutRefresh)
        setShowOverlay(false);
        toast.success(result?.message)
      }
      else {
        setShowOverlay(false);
        toast.error(result?.message)
      }
    }
    catch (err) {
      setShowOverlay(false);
      const errorMessage = err?.response?.data?.message
      toast.error(errorMessage)
      console.error("error", err)
    }
  }

  const handleImageUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("album_id", id);
      formData.append("album", coverImageFile);
      const response = await axios.post(`${albumDefaultURL?.uploadImageURL}`, formData, { headers: adminApiHeader });
      if (response?.data?.status) {
        setCoverImageFile(null)
      } else {
        toast.error(response?.data?.message)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleCopyRightUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("album_id", id);
      formData.append("copyrights", copyRightFile);
      const response = await axios.post(`${albumDefaultURL?.uploadCopyRight}`, formData, { headers: adminApiHeader });
      if (response?.data?.status) {
        setCopyRightFile(null)
      } else {
        toast.error(response?.data?.message)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleStore = async () => {
    if (formValidation()) {
      await handleAdd();
    } else {
      console.log("Form validation failed ");
    }
  }
  // api end

  // funtion start
  const generateSlug = (albumname) => {
    albumname = albumname?.trim();
    const slug = albumname?.toLowerCase().replace(/\s+/g, "-");
    setFormData((prevFormData) => ({
      ...prevFormData,
      slug: slug,
    }));
  };

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(formData?.name);
    }
  };

  const handleAutoFillChangetitle = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      slug: event.target.value,
    }));
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: "",
    }));
  };

  const handleBlurTitleEdit = () => {
    generateSlug(formData?.slug);
  };

  const handleInput = async (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      albumname: "",
      slug: "",
    }));
  }

  //Handle Image upload
  const handleCoverImageChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setCoverImageFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };

  const handleCopyRightChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setCopyRightFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };

  const formValidation = () => {
    let formIsValid = true;
    if (!formData?.albumname) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        albumname: "Title cannot be empty.",
      }));
      titleInputRef.current.focus();
      formIsValid = false;
    } else if (!formData?.slug) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        slug: "Slug cannot be empty.",
      }));
      slugInputRef.current.focus();
      formIsValid = false;
    }
    return formIsValid;
  }

  // function end
  // function end
  useEffect(() => {
    handleGetCategory()
    fetchData()
  }, [withOutRefresh])


  return (
    <>
      {showOverlay && (<Response_Processing></Response_Processing>)}
      {loading && (<FetchLoader />)}
      <AdminSaveHeader heading="Edit Album" saveText="Update" saveMethod={handleStore} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text={formData?.albumname} />
            <MessageLabel text="Name" className="mandatorySimple" />
            <input type="text" name="albumname" value={formData?.albumname} onChange={handleInput} placeholder="Title" ref={titleInputRef} onKeyDown={handleInputKeyDowntitle} className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0 mb-3" />
            {formDataError?.albumname && (<MessageBox text={`${formDataError?.albumname}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text='Slug' />
            <input type="text" name="slug" id="slug" value={formData?.slug} onChange={handleAutoFillChangetitle} ref={slugInputRef} onBlur={handleBlurTitleEdit} placeholder="slug" className="mb-3 rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0" />
            {formDataError?.slug && (<MessageBox text={`${formDataError?.slug}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text="Category" />
            <select id="parent_id" name="parent_id" defaultValue={formData?.albumname} onChange={handleInput} className="form-select mb-3 custom-placeholder  theme-bg-color-secondary theme-text-color border-0" >
              <option value="0" className="theme-bg-color theme-text-color"> Choose  category</option>
              {defaultOption?.map((item) => {
                let defaultValue = formData?.parent_id == item?.id ? item?.id : 0;
                return (<option value={item.id} selected={defaultValue} className="theme-bg-color theme-text-color">{item.albumname}</option>)
              })}
            </select>
          </Wrapper>
        </LeftWrapper>
        <RightWrapper>
          <Wrapper>
            <MessageHeading text='Thumbnails' />
            <MessageLabel text="Image" />
            <MessageSubHeading text="Select the Album image( 9:16 Ratio or 1080X1920px )" />
            {loading ? (<Response_Loading_processing></Response_Loading_processing>) : (<img src={formData?.image_url} alt="Image" className="thumbnails-images" />)}
            <div className="mx-0 my-3 row">
              <div className="col-6 ps-0">
                <div className="imagedrop theme-border-color" >
                  <input type="file" ref={coverImageRef} onChange={(event) => handleCoverImageChange(event)} accept="image/*" className="opacity-0 w-100 h-100" key={coverImageFile ? coverImageFile : "inputKey"} />
                  <label className="theme-text-color position-absolute"><CameraRetroIcon className=" theme-text-color" /> </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {coverImageFile && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0">
                        <div className="col-10 p-0 ">
                          <img src={URL?.createObjectURL(coverImageFile)} alt="Uploaded" className="thumbnails-upload-images" />
                        </div>
                        <div className="col-2 p-0" style={{ alignSelf: "center" }}>
                          <button onClick={() => setCoverImageFile(null)} type="button" className="bg-transparent theme-text-color"><IoClose className="custom-close-btn"></IoClose></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <MessageLabel text="Copy Rights Document" />
            {formData?.copyrights ? (<a href={`${formData?.copyrights}`} target="_blank" download> <div className="d-flex  gap-2"><FaFileAlt className="mt-1" /><MessageLabel text="Download" /> </div></a>) : null}
            <div className="mx-0 my-3 row">
              <div className="col-6 ps-0">
                <div className="imagedrop theme-border-color" >
                  <input type="file" ref={copyRightRef} onChange={(event) => handleCopyRightChange(event)} accept=".pdf, image/*" className="opacity-0 w-100 h-100" key={copyRightFile ? copyRightFile : "inputKey"} />
                  <label className="theme-text-color position-absolute"><FaFileAlt className=" theme-text-color" /> </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {copyRightFile && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0 h-100 align-items-center">
                        <div className="col-10 p-0 text-center ">
                          <MessageBox text="Name:" classname="d-inline-block theme-text-color" /><span className="text-break">{copyRightFile?.name?.slice(0, 5)}</span>
                          <MessageBox text="Type:." classname="d-inline-block theme-text-color" /><span className="text-break">{copyRightFile?.type?.slice(0, 5)}</span>
                        </div>
                        <div className="col-2 p-0" style={{ alignSelf: "center" }}>
                          <button onClick={() => setCopyRightFile(null)} type="button" className="bg-transparent theme-text-color"><IoClose className="custom-close-btn"></IoClose></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
        </RightWrapper>
      </FormContainer>
    </>
  );
}

export default Editalbum;
