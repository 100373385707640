import React, { useState } from "react";
import { FaPlus, FaTrashAlt, FaThLarge, FaBars } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"; // Import Drag and Drop
import axios from "axios"; // Assuming you use axios for API requests
import "./EditTvpayouts.css"; // Assuming CSS is imported
import { Link } from "react-router-dom";
import backarrow from "../../components/Images/backarrow.svg";
import ReactPlayer from "react-player";

function EditTvpayouts() {

    return (
        <div className="m-0 p-0">
            <section className="container-fluid iq-card">


                <Link to="/tv-payouts" className="backarrow py-3">
                    <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                    <span> Back</span>
                </Link>

                <div className="row py-3 col-lg-12">

                    <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                        <div>
                            <label className="m-0">Duration</label>
                            <div className="row mt-2 text-start">
                                <div className="col-12">
                                    <div className="mt-2">
                                        <select
                                            className="form-select"
                                            id="type"
                                            name="type"
                                            size="lg"
                                        >
                                            <option value="0">Choose an Option</option>
                                            <option value="1">Schedule Payouts</option>
                                            <option value="2">Loop Payouts</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2 text-start">
                                <div className="col-12">
                                    <div className="mt-2">
                                        <input
                                            type="datetime-local"
                                            name="date"
                                            id="date"
                                            className="rs-input form-control-lg"
                                            placeholder="Year"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row mt-2 text-start">
                                <ReactPlayer
                                    url="https:\/\/bitdash-a.akamaihd.net\/content\/sintel\/hls\/playlist.m3u8"
                                    controls={true} // Enable player controls
                                    width="100%"
                                    height="230px"
                                // config={{
                                //     file: {
                                //         attributes: {
                                //             poster: editUser?.Player_thumbnail
                                //         }
                                //     }
                                // }}
                                />
                            </div>
                        </div>
                        <div className="mt-2">
                            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Home</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Profile</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">Home</div>
                                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">Profile</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-8">hello</div>
                </div>
            </section>
        </div>
    );
}

export default EditTvpayouts;
