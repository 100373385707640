// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabHeading{
    font-family: "museo_sans500" !important;
    font-style: normal;
    font-size: 16px !important;
    color: #424242 !important;
    background-color: transparent;
    position: relative;
    display: block;
}
.tabHeading.active{
font-family: "museo_sans700" !important ;
}
.tabHeading.active::after{
    content: "";
    position: absolute;
    width: 100%;
    bottom: -9px;
    left: 0;
    border-radius: 10px;
    height: 4px;
    background-color: rgb(77, 77, 247);

}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Content_Partner/EditContentPartner.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,kBAAkB;IAClB,0BAA0B;IAC1B,yBAAyB;IACzB,6BAA6B;IAC7B,kBAAkB;IAClB,cAAc;AAClB;AACA;AACA,wCAAwC;AACxC;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,OAAO;IACP,mBAAmB;IACnB,WAAW;IACX,kCAAkC;;AAEtC","sourcesContent":[".tabHeading{\n    font-family: \"museo_sans500\" !important;\n    font-style: normal;\n    font-size: 16px !important;\n    color: #424242 !important;\n    background-color: transparent;\n    position: relative;\n    display: block;\n}\n.tabHeading.active{\nfont-family: \"museo_sans700\" !important ;\n}\n.tabHeading.active::after{\n    content: \"\";\n    position: absolute;\n    width: 100%;\n    bottom: -9px;\n    left: 0;\n    border-radius: 10px;\n    height: 4px;\n    background-color: rgb(77, 77, 247);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
