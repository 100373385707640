import React, { useState, useEffect, useRef } from "react";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function AdsPlan(props) {
  const [dataadscate, setDataadscate] = useState([]);
  const [countadscate, setCountadscate] = useState();
  const [pageadscate, setPageadscate] = useState(1);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [videodataadscate, setVideodataadscate] = useState("");
  const [loading, setLoading] = useState(true);
  const [defaultImage, setDefaultimage] = useState();
  const [defaultImageurl, setDefaultimageurl] = useState("");
  const [currentPageadscate, setCurrentPageadscate] = useState(1);
  const itemsPerPageadscate = 10;

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    fetchDataadscate();
    fetchDatanextpreadscate();
  }, [pageadscate]);

  const videosdeleteOperationadscate = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisement-Plans-delete/` +
          id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          props.setApiresponsealert(responseData);
          props.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props.setApiresponsealert(resultError);
            props.setShowalert(true);
          }
        }
      });
  };

  const [selectedItemsadscate, setSelectedItemsadscate] = useState([]);
  const [selectAlladscate, setSelectAlladscate] = useState(false);
  const [isConfirmationOpenadscate, setIsConfirmationOpenadscate] =
    useState(false);
  const [isSuccessMessageOpenadscate, setIsSuccessMessageOpenadscate] =
    useState(false);

  const handleSingleSelectadscate = (itemId) => {
    setSelectedItemsadscate([itemId]);
    setSelectAlladscate(false);
  };

  const handleMultiSelectadscate = (itemId) => {
    if (selectedItemsadscate.includes(itemId)) {
      setSelectedItemsadscate(
        selectedItemsadscate.filter((id) => id !== itemId)
      );
    } else {
      setSelectedItemsadscate([...selectedItemsadscate, itemId]);
    }
  };

  const handleSelectAlladscate = () => {
    setSelectAlladscate(!selectAlladscate);
    setSelectedItemsadscate(
      selectAlladscate ? [] : videodataadscate.map((item) => item.id)
    );
  };

  const handleDeleteadscate = () => {
    setIsConfirmationOpenadscate(true);
  };
  const handleCancelDeleteadscate = () => {
    setIsConfirmationOpenadscate(false);
  };

  const handleConfirmDeleteadscate = () => {
    setIsConfirmationOpenadscate(false);
    for (const itemId of selectedItemsadscate) {
      axios
        .delete(
          `${process.env.REACT_APP_Baseurl}/admin/Advertisement-Plans-delete/${itemId}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.data.status == true) {
            setVideodataadscate(
              videodataadscate.filter((item) => item.id !== itemId)
            );
            setSelectedItemsadscate(
              selectedItemsadscate.filter((id) => id !== itemId)
            );
            setIsSuccessMessageOpenadscate(true);
            setTimeout(() => {
              setIsSuccessMessageOpenadscate(false);
            }, 2000);
            var responseData = response.data;
            props.setApiresponsealert(responseData);
            props.setShowalert(true);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            if (responseData === false) {
              var resultError = responseData;
              props.setApiresponsealert(resultError);
              props.setShowalert(true);
            }
          }
        });
    }
  };

  const fetchDatanextpreadscate = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisement-Plans-list?page=${pageadscate}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const videosData = res?.data?.Advertisement_Plans_pagination?.data;
        setVideodataadscate(videosData);
        if (videosData?.length > 0) {
          setDataadscate(videosData);
          setLoading(false);
        } else {
          setDataadscate([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchDataadscate = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisement-Plans-list`,
        { headers: headers }
      );
      setCountadscate(response?.data?.Total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const indexOfLastItemadscate = currentPageadscate * itemsPerPageadscate;
  const indexOfFirstItemadscate = indexOfLastItemadscate - itemsPerPageadscate;
  const currentItemsadscate = dataadscate?.slice(
    indexOfFirstItemadscate,
    indexOfLastItemadscate
  );

  const handlePreClickadscate = () => {
    if (pageadscate > 1) {
      setPageadscate(pageadscate - 1);
    }
  };

  const handleNxtClickadscate = () => {
    setPageadscate(pageadscate + 1);
  };

  const [itemToDeleteadscate, setItemToDeleteadscate] = useState(null);
  const [successMessageadscate, setSuccessMessageadscate] = useState("");

  const openModaladscate = (id) => {
    setItemToDeleteadscate(id);
    setSuccessMessageadscate("");
  };

  const closeModaladscate = () => {
    setItemToDeleteadscate(null);
  };
  const handleDeletedata = () => {
    closeModaladscate();
    if (itemToDeleteadscate) {
      videosdeleteOperationadscate(itemToDeleteadscate);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageplan_name("");
    setIsValidationHiddenplan_name(true);
    setValidationMessageplan_amount("");
    setIsValidationHiddenplan_amount(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [editUser, setEditUser] = useState({
    plan_name: "",
    plan_amount: "",
    Ads_upload_count_limit: "",
    Ads_views_count_limit: "",
  });

  const [validationMessageplan_name, setValidationMessageplan_name] =
    useState("");
  const [isValidationHiddenplan_name, setIsValidationHiddenplan_name] =
    useState(true);

  const [validationMessageplan_amount, setValidationMessageplan_amount] =
    useState("");
  const [isValidationHiddenplan_amount, setIsValidationHiddenplan_amount] =
    useState(true);

  const plan_nameInputRef = useRef(null);
  const plan_amountInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function handleUpdate() {
    let focusInputRef = null;

    if (editUser?.plan_name === "") {
      setValidationMessageplan_name("Plan Name cannot be empty.");
      setIsValidationHiddenplan_name(false);
      focusInputRef = plan_nameInputRef;
    }
    if (editUser?.plan_amount === "") {
      setValidationMessageplan_amount("Plan Amount cannot be empty.");
      setIsValidationHiddenplan_amount(false);
      focusInputRef = plan_amountInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const formData = new FormData();

    formData.append("plan_name", editUser?.plan_name);
    formData.append("plan_amount", editUser?.plan_amount);
    formData.append("Ads_upload_count_limit", editUser?.Ads_upload_count_limit);
    formData.append("Ads_views_count_limit", editUser?.Ads_views_count_limit);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisement-Plans-store`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;
        resultapi = response.data;
      }
    } catch (error) {}

    if (firstApiStatus) {
      setValidationMessageplan_name("");
      setValidationMessageplan_amount("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setIsValidationHiddenplan_name(false);
        setIsValidationHiddenplan_amount(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  return (
    <>
      <div
        className={`modal ${itemToDeleteadscate !== null ? "show" : ""}`}
        tabIndex="-1"
        style={{
          display: itemToDeleteadscate !== null ? "block" : "none",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModaladscate}
              ></button>
            </div>
            <div className="modal-body">
              {successMessageadscate ? (
                <div className="alert alert-success" role="alert">
                  {successMessageadscate}
                </div>
              ) : (
                <p>Are you sure you want to delete this Ads?</p>
              )}
            </div>
            <div className="modal-footer">
              {successMessageadscate ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closeModaladscate}
                >
                  Close
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModaladscate}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeletedata}
                  >
                    Yes
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delete_confirm">
          <div className="modal-content text-center">
            <div className="modal-body">
              {isConfirmationOpenadscate && (
                <div>
                  <h5 className="modal-title p-3" id="exampleModalLabel">
                    <p className="fs-6">
                      Are you sure you want to delete the selected items?
                    </p>
                  </h5>
                  <div className="col-lg-12 row p-4">
                    <div className="col-lg-6">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          handleConfirmDeleteadscate();
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <button
                        className="btn btn-primary"
                        onClick={handleCancelDeleteadscate}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {isSuccessMessageOpenadscate && (
                <div className="success-message">
                  <div className="p-5">
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </div>
                  <p className="fs-6 fw-bold">
                    Selected items have been successfully deleted.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div>
        {showOverlay && (
          <div className="overlay-container">
            <span className="overlay-icon">
              {" "}
              <Response_Loading_processing></Response_Loading_processing>
              Processing ...
            </span>
          </div>
        )}
      </div>

      <div className=" m-0 p-0">
        <div className="container-fluid">
          <section className="section all-video-stream ">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>
                  Ads Plans List{" "}
                  <span className="fs-6">
                    (Display all {countadscate} Ads Plans)
                  </span>
                </h3>
              </div>
              <div className="col-3 col-sm-3 col-lg-3 text-end">
                {selectedItemsadscate.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleDeleteadscate}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Delete Selected
                  </button>
                )}
              </div>

              <div
                className="modal fade"
                id="exampleModalads"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Create Ads Plan
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div>
                        <label>
                          Plan Name
                          <span className="mandatory">*</span>
                        </label>

                        <div className="mt-2">
                          <input
                            type="text"
                            name="plan_name"
                            className="rs-input form-control-lg"
                            value={editUser?.plan_name}
                            onChange={handleInput}
                            ref={plan_nameInputRef}
                          />
                        </div>
                        <span className="errorred">
                          {!isValidationHiddenplan_name && (
                            <p>{validationMessageplan_name}</p>
                          )}
                        </span>
                      </div>
                      <div className="mt-2">
                        <label>
                          Plan Amount
                          <span className="mandatory">*</span>
                        </label>

                        <div className="mt-2">
                          <input
                            type="text"
                            name="plan_amount"
                            className="rs-input form-control-lg"
                            value={editUser?.plan_amount}
                            onChange={handleInput}
                            ref={plan_amountInputRef}
                          />
                        </div>
                        <span className="errorred">
                          {!isValidationHiddenplan_amount && (
                            <p>{validationMessageplan_amount}</p>
                          )}
                        </span>
                      </div>
                      <div className="mt-2">
                        <label>Ads Upload Count Limit</label>

                        <div className="mt-2">
                          <input
                            type="text"
                            name="Ads_upload_count_limit"
                            className="rs-input form-control-lg"
                            value={editUser?.Ads_upload_count_limit}
                            onChange={handleInput}
                          />
                        </div>
                        {/* <span className="errorred">
                          {!isValidationHiddenplan_amount && (
                            <p>{validationMessageplan_amount}</p>
                          )}
                        </span> */}
                      </div>
                    </div>
                    <div className="modal-footer">
                      {/* <button  onClick={handleUpdate} >submit</button> */}
                      <input
                        type="submit"
                        id="appupdate"
                        value="Update Ads"
                        onClick={handleUpdate}
                        className="btn btn-primary ml-3 "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-3 col-sm-3 col-lg-3 text-end">
                <Link>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalads"
                  >
                    Add Ads
                  </button>
                </Link>
              </div>
            </div>

            <div className="col-lg-12 ">
              <div className="card">
                {loading ? (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAlladscate}
                                onChange={handleSelectAlladscate}
                              />
                            </label>
                          </th>
                          <th>PLAN NAME</th>
                          <th>PLAN AMOUNT</th>
                          <th>NO OF ADS VIEW COUNT</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                    </Table>

                    <div className="text-center">
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>{" "}
                        Loading...
                      </label>
                    </div>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickadscate}
                          disabled={pageadscate <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pageadscate}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickadscate}
                          disabled={dataadscate.length < itemsPerPageadscate}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : videodataadscate.length > 0 ? (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAlladscate}
                                onChange={handleSelectAlladscate}
                              />
                            </label>
                          </th>
                          <th>PLAN NAME</th>
                          <th>PLAN AMOUNT</th>
                          <th>NO OF ADS VIEW COUNT</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      {currentItemsadscate?.map((item, key) => (
                        <tbody>
                          <tr key={item.id}>
                            <td key={item.id}>
                              <input
                                type="checkbox"
                                checked={selectedItemsadscate.includes(item.id)}
                                onChange={() =>
                                  handleMultiSelectadscate(item.id)
                                }
                              />
                            </td>

                            <td>
                              <td>
                                <div className="d-flex text-start ">
                                  <div>
                                    <div className="">
                                      <div>{item?.plan_name}</div>
                                      <div className="d-flex ">
                                        <div className="">
                                          <span>{item?.upload_on}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </td>

                            <td>{item?.plan_amount}</td>
                            <td>{item?.Ads_upload_count_limit}</td>

                            <td>
                              <div className="editdropdown">
                                <span className="editdropdown-button">
                                  <span>
                                    <i
                                      className="fa fa-ellipsis-v"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </span>
                                <div className="editdropdown-menu">
                                  <Link
                                    to={"/edit-adsplan/" + item?.id}
                                    className="edit"
                                  >
                                    <span>
                                      <img
                                        src={edititem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">Edit Ads</span>
                                    </span>
                                  </Link>
                                  <Link to="" className="delete">
                                    <span
                                      onClick={() => openModaladscate(item?.id)}
                                    >
                                      <img
                                        src={deleteitem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">Delete Ads</span>
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </Table>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickadscate}
                          disabled={pageadscate <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pageadscate}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickadscate}
                          disabled={dataadscate.length < itemsPerPageadscate}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAlladscate}
                                onChange={handleSelectAlladscate}
                              />
                            </label>
                          </th>
                          <th>PLAN NAME</th>
                          <th>PLAN AMOUNT</th>
                          <th>NO OF ADS VIEW COUNT</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                    </Table>

                    <div className="text-center">
                      <label>No Ads Available.</label>
                    </div>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickadscate}
                          disabled={pageadscate <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pageadscate}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickadscate}
                          disabled={dataadscate.length < itemsPerPageadscate}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section  container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="iq-card">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Ads Plans List</h4>
                        </div>
                        <div className="iq-card-header-toolbar d-flex align-items-center">
                          <Link
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            className="btn btn-primary create_category"
                          >
                            Create Ads Plan
                          </Link>
                        </div>
                        <div
                          className="modal fade"
                          id="exampleModal"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div>
                                  <label>Plan Name</label>

                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="Ads Plan Name"
                                    className="form-control rtmp_urls"
                                  />
                                </div>
                                <div>
                                  <label>Plan Amount</label>

                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="Ads Plan Amount"
                                    className="form-control rtmp_urls"
                                  />
                                </div>
                                <div>
                                  <label>No of Ads view count </label>

                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="No of Ads view count"
                                    className="form-control rtmp_urls"
                                  />
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="submit"
                                  className="btn btn-primary ml-3 "
                                >
                                  Add Plan
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="iq-card-body table-responsive mt-3">
                        <div className="table-view">
                          <table className="table table-striped table-bordered table movie_table ">
                            <thead>
                              <tr className="r1">
                                <th>#</th>
                                <th>Plan Name</th>
                                <th>Plan Amount</th>
                                <th>No of Ads View Count</th>
                                <th>Created at</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="r1">
                                <td>1</td>
                                <td>plan_name </td>
                                <td>plan_amount </td>
                                <td>no_of_ads</td>
                                <td>created_at </td>
                                <td>
                                  <td className="table-content">
                                    <Link to={"/edit_ads_list"}>
                                      <i
                                        className="fa fa-pencil-square"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                    <Link>
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </td>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default AdsPlan;
