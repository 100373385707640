import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import "./EditMenu.css";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function EditMenu(props) {
  const [menu, setMenu] = useState("");
  const [menuvisible, setMenuvisible] = useState("");
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    parent_id: "",
    order: "",
    in_menu: "",
    name: "",
    url: "",
    type: "",
    select_url: "",
    custom_url: "",
    image: "",
    in_side_menu: "",
  });
  const [editname, setEditname] = useState({
    name: "",
  });

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/menu/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Menu;
      var result = resData?.categories;
      setEditUser(res);
      setEditname(res);
      setCategories(result);
    };
    getUser();
  }, [id]);

  const [parent_id, setParent_id] = useState("0");

  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = () => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagename("");
    setIsValidationHiddenname(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagename, setValidationMessagename] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);

  const [validationMessageurl_type, setValidationMessageurl_type] =
    useState("");
  const [isValidationHiddenurl_type, setIsValidationHiddenurl_type] =
    useState(true);

  const nameInputRef = useRef(null);
  const url_typeInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagename("Title cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }
    if (editUser?.url_type === "") {
      setValidationMessageurl_type("Menu URL cannot be empty.");
      setIsValidationHiddenurl_type(false);
      focusInputRef = url_typeInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      parent_id: editUser?.parent_id,
      order: editUser?.order,
      in_menu: editUser?.in_menu,
      name: editUser?.name,
      url: editUser?.url,
      type: editUser?.type,
      select_url: editUser?.select_url,
      custom_url: editUser?.custom_url,
      in_side_menu: editUser?.in_side_menu,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resjson = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/menu/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();
        formData.append("menu_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }

        await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-menus`,
          formData,
          { headers: headers }
        );
        secondApiStatus = true;
      } else if (resjson?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagename("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddenname(false);
    }
  };

  function enablestreams() {
    switch (menu) {
      case "0":
        return <div></div>;
      case "site_url":
        return (
          <div className="mt-2">
            <div id="div_Site">
              <label for="url">Menu Item URL (ex. /site/url)</label>
              <div className="mt-2">
                <input
                  name="select_url"
                  id="select_url"
                  placeholder="URL"
                  className="rs-input form-control-lg"
                  value={editUser?.select_url}
                  onChange={handleInput}
                />
              </div>
            </div>
          </div>
        );
      case "custom_url":
        return (
          <div className="mt-2">
            <div id="div_Custom">
              <label for="url">Custom URL (ex. full url)</label>
              <div className="mt-2">
                <input
                  name="custom_url"
                  id="custom_url"
                  placeholder="Custom URL"
                  className="rs-input form-control-lg"
                  value={editUser?.custom_url}
                  onChange={handleInput}
                />
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Menu</h3>
              </div>
            </div>
            <div>
                {showOverlay && (<Response_Processing></Response_Processing>)}
              </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>{editname?.name}</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Menu Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="name"
                        name="name"
                        placeholder="Enter Title"
                        onChange={handleInput}
                        value={editUser?.name}
                        ref={nameInputRef}
                      />
                      <input
                        name="name"
                        id="name"
                        placeholder="Menu Item Name"
                        className="form-control"
                        onChange={(e) => setParent_id(e.target.value)}
                        hidden
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenname && (
                        <p>{validationMessagename}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Menu Item URL</label>

                    <div className="mt-2">
                      <select
                        className="form-select url_type "
                        id="url_type"
                        name="url"
                        value={editUser?.url}
                        ref={url_typeInputRef}
                        onClick={(event) => {
                          setMenu(event.target.value);
                        }}
                        onChange={handleInput}
                      >
                        <option defaultValue="">Select URL Type</option>
                        <option value="site_url">Site URL</option>
                        <option value="custom_url">Custom URL</option>
                      </select>
                    </div>

                    <div
                      onClick={(event) => {
                        setMenuvisible(event.target.value);
                      }}
                    >
                      {enablestreams()}
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenurl_type && (
                        <p>{validationMessageurl_type}</p>
                      )}
                    </span>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">or Dropdown for</label>

                    <div className="mt-2">
                      <select
                        id="url_type"
                        name="type"
                        value={editUser?.type}
                        className="form-select"
                        onChange={handleInput}
                      >
                        {categories?.map((item, key) => (
                          <option
                            key={item?.id}
                            className="menu-dropdown-radio"
                            value={item?.value}
                          >
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card p-3">
                  <h5>Status Setting</h5>
                  <hr></hr>

                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-body d-flex justify-content-between">
                      <label className="">Show In-Home </label>
                      <label className="switch">
                        <input
                          name="in_menu"
                          onChange={handleInputenable}
                          defaultChecked={
                            editUser?.in_menu === 1 ? true : false
                          }
                          checked={editUser?.in_menu === 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="in_menu"
                          onChange={handleInputenable}
                          value={editUser?.in_menu === 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-body d-flex justify-content-between">
                      <label className="">Show In-Side Menu</label>
                      <label className="switch">
                        <input
                          name="in_side_menu"
                          onChange={handleInputenable}
                          defaultChecked={
                            editUser?.in_side_menu === 1 ? true : false
                          }
                          checked={editUser?.in_side_menu === 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="in_side_menu"
                          onChange={handleInputenable}
                          value={editUser?.in_side_menu === 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6 position-relative pb-5 pb-sm-0">
                <div className="card p-3">
                  <h5>Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Menu Image</label>

                    <div>
                      {editUser?.image ? (
                        <img
                          src={editUser?.image}
                          alt="Default Image"
                          className="thumbnails-images"
                        />
                      ) : (
                        <Response_Loading_processing></Response_Loading_processing>
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6 ">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile1)
                                      }
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-start btnPosition ">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Menu
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    </>
  );
}

export default EditMenu;
