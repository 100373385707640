const defaultDomain = `${process.env.REACT_APP_Baseurl}`
const defaultPreFix = 'admin'
const defaultRoute = '/'
const finalRouteApi = `${defaultDomain}/${defaultPreFix}`
const audioCategoryRoute = `${process.env.REACT_APP_Baseurl_Frontend}/en/music/genre`

const access_token = localStorage.getItem("access_token");
const adminApiHeader = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
};

const videoDefaultURL = {
    listApi: `${finalRouteApi}/video-list`,
    MultiDeleteApi: `${finalRouteApi}/videos/multi-videos-delete`,
    sliderApi: `${finalRouteApi}/videos-slider`,
    addRouteLink: `${defaultRoute}add-video`,
    editRouteLink: `${defaultRoute}edit-video/`,
    viewTab: 'videos',
    headingText: "All Videos",
    buttonText: "Add Video",
    exportFileName: "VIDEO-LIST",
    fetchArrayName: "Allvideos",
    reqBodyName: {
        sliderName: "video_id",
        deleteName: "video_id"
    }
}

const seriesDefaultURL = {
    listApi: `${finalRouteApi}/series/index`,
    MultiDeleteApi: `${finalRouteApi}/series/multi-delete`,
    sliderApi: `${finalRouteApi}/series/slider-update`,
    addRouteLink: `${defaultRoute}add-series`,
    editRouteLink: `${defaultRoute}edit-series/`,
    viewTab: 'shows',
    headingText: "All Series",
    buttonText: "Add Series",
    exportFileName: "SERIES-LIST",
    fetchArrayName: "AllSeries",
    reqBodyName: {
        sliderName: "series_id",
        deleteName: "id"
    }
}

const siteThemApi = `${finalRouteApi}/Site-ThemeSetting/index`;
export { videoDefaultURL, seriesDefaultURL, adminApiHeader, finalRouteApi, siteThemApi, audioCategoryRoute }