import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./ManageGeoFencing.css";

function ManageGeoFencing() {
  const [editUser, seteditUser] = useState({
    id: "",
    geofencing: "",
    geofencing_status: "",
  });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Geo-fencing/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.geo_fencing[0];
        seteditUser(result);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    if (e.target.checked === true) {
      seteditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      seteditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser?.id,
      geofencing: editUser?.geofencing,
      geofencing_status: editUser?.geofencing_status,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Geo-fencing/update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    if (res.status === 200) {
      setMessage(resjson.success);
      alert("Success");
      setTimeout(() => {}, 2000);
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid">
          <div className="pt-3">
            <div className="admin-section-title">
              <div className="iq-card">
                <div className="row">
                  <div className="col-md-4">
                    <h4>
                      <i className="entypo-archive"></i>Geofencing Setting{" "}
                    </h4>
                  </div>
                </div>
                <hr />

                <div className="  col-md-12 p-4">
                  <div className="d-flex  align-items-center justify-content-center">
                    <span className="mr-3">Disable</span>

                    <label className="switch">
                      <input
                        name="geofencing_status"
                        onChange={handleInput}
                        defaultChecked={
                          editUser?.geofencing_status === 1 ? true : false
                        }
                        checked={
                          editUser?.geofencing_status === 1 ? true : false
                        }
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="geofencing_status"
                        onChange={handleInput}
                        value={editUser?.geofencing_status === 1 ? "1" : "0"}
                      ></span>
                    </label>
                    <span className="ml-3">Enable</span>
                  </div>

                  <div className=" mt-5 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleUpdate}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ManageGeoFencing;
