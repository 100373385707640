import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import backarrow from "../../../../components/Images/backarrow.svg";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../../src/layout/Others/Response_Loading_processing";
import deleteitem from "../../../../components/Images/deleteitem.svg";
import "./Manage_Geo_Fencing.css";
import { Table } from "react-bootstrap";

function Manage_Geo_Fencing(props) {
  const [editUser, setEditUser] = useState({
    id: "",
    geofencing: "",
    geofencing_status: "",
  });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Geo-fencing/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.home_editUser)
        var result = response?.data?.geo_fencing[0];
        // setOrder(result)
        setEditUser(result);
        // console.log("result", result);
        // console.log('orders', order);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    // console.log(e.target.checked, e.target.value);
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      id: editUser?.id,
      geofencing: editUser?.geofencing,
      geofencing_status: editUser?.geofencing_status,
    };

    setProcessing(true);
    setShowOverlay(true);

    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Geo-fencing/update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );
    let resjson = await res?.json();

    if (resjson?.status === true) {
      var resultapi = resjson;

      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson?.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   const editInputvalue = {
  //     id: editUser.id,
  //     geofencing: editUser.geofencing,
  //     geofencing_status: editUser.geofencing_status,
  //   };
  //   // console.log(editInputvalue);
  //   let res = await fetch(
  //     `${process.env.REACT_APP_Baseurl}/admin/Geo-fencing/update`,
  //     {
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify(editInputvalue),
  //     }
  //   );

  //   let resjson = await res.json();
  //   // console.log(resjson)
  //   if (res.status === 200) {
  //     setMessage(resjson.success);
  //     alert('Success')
  //     // window.location.reload();
  //     setTimeout(() => {}, 2000);
  //   } else {
  //     setMessage("Some error Occured");
  //     console.log("Error");
  //     alert("Enter Correct Details");
  //   }
  // };

  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const handleToggle = (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const confirmToggle = async () => {
    try {
      setShowModal(false);
      const newStatus = editUser?.geofencing_status === 1 ? 0 : 1;

      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Geo-fencing/update`,
        {
          method: "POST",
          body: JSON.stringify({
            id: currentItem?.id,
            geofencing: editUser?.geofencing,
            geofencing_status: newStatus,
          }),
          headers: headers,
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        setEditUser({ ...editUser, geofencing_status: newStatus }); // Update the local state
        props?.setApiresponsealert(responseData);
        props?.setShowalert(true);
        // setMessage(responseData.message);
      } else {
        props?.setApiresponsealert(responseData);
        props?.setShowalert(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closeModalslider = () => {
    setShowModal(false);
    setCurrentItem(null);
  };

  const [addCountryselect, setAddCountryselect] = useState();
  const [countriesdrop, setCountriesdrop] = useState([]);
  const [datacountry, setDatacountry] = useState([]);
  const [countcountry, setCountcountry] = useState();
  const [pagecountry, setPagecountry] = useState(1);
  const [countrydatacountry, setVideodatacountry] = useState("");
  const [loading, setLoading] = useState(true);
  const [defaultImage, setDefaultimage] = useState();
  const [defaultImageurl, setDefaultimageurl] = useState("");
  const [currentPagecountry, setCurrentPagecountry] = useState(1);
  const itemsPerPagecountry = 10;

  const deleteOperationcountry = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/Block-countries/delete/` + id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response?.data?.status == true) {
          var responseData = response?.data;
          props?.setApiresponsealert(responseData);
          props?.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props?.setApiresponsealert(resultError);
            props?.setShowalert(true);
          }
        }
      });
  };

  const [selectedItemscountry, setSelectedItemscountry] = useState([]);
  const [selectAllcountry, setSelectAllcountry] = useState(false);
  const [isConfirmationOpencountry, setIsConfirmationOpencountry] =
    useState(false);
  const [isSuccessMessageOpencountry, setIsSuccessMessageOpencountry] =
    useState(false);

  const handleSingleSelectcountry = (itemId) => {
    setSelectedItemscountry([itemId]);
    setSelectAllcountry(false);
  };

  const handleMultiSelectcountry = (itemId) => {
    if (selectedItemscountry?.includes(itemId)) {
      setSelectedItemscountry(
        selectedItemscountry?.filter((id) => id !== itemId)
      );
    } else {
      setSelectedItemscountry([...selectedItemscountry, itemId]);
    }
  };

  const handleSelectAllcountry = () => {
    setSelectAllcountry(!selectAllcountry);
    setSelectedItemscountry(
      selectAllcountry ? [] : countrydatacountry?.map((item) => item?.id)
    );
  };

  const handleDeletecountry = () => {
    setIsConfirmationOpencountry(true);
  };
  const handleCancelDeletecountry = () => {
    setIsConfirmationOpencountry(false);
  };

  const handleConfirmDeletecountry = () => {
    setIsConfirmationOpencountry(false);
    for (const itemId of selectedItemscountry) {
      axios
        .delete(
          `${process.env.REACT_APP_Baseurl}/admin/Block-countries/delete/${itemId}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.data.status == true) {
            setVideodatacountry(
              countrydatacountry?.filter((item) => item?.id !== itemId)
            );
            setSelectedItemscountry(
              selectedItemscountry?.filter((id) => id !== itemId)
            );
            setIsSuccessMessageOpencountry(true);
            setTimeout(() => {
              setIsSuccessMessageOpencountry(false);
            }, 2000);
            var responseData = response.data;
            props?.setApiresponsealert(responseData);
            props?.setShowalert(true);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            if (responseData === false) {
              var resultError = responseData;
              props?.setApiresponsealert(resultError);
              props?.setShowalert(true);
            }
          }
        });
    }
  };

  const fetchDatanextprecountry = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Block-countries/index?page=${pagecountry}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const countryData = res?.data?.countries?.data;
        setVideodatacountry(countryData);
        if (countryData?.length > 0) {
          setDatacountry(countryData);
          setLoading(false);
        } else {
          setDatacountry([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchDatanextprecountryselect = () => {
    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Block-countries/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.countries?.data;
        setCountriesdrop(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchDatacountry = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/Block-countries/index`,
        { headers: headers }
      );
      setCountcountry(response?.data?.Total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const indexOfLastItemcountry = currentPagecountry * itemsPerPagecountry;
  const indexOfFirstItemcountry = indexOfLastItemcountry - itemsPerPagecountry;
  const currentItemscountry = datacountry?.slice(
    indexOfFirstItemcountry,
    indexOfLastItemcountry
  );

  const handlePreClickcountry = () => {
    if (pagecountry > 1) {
      setPagecountry(pagecountry - 1);
    }
  };

  const handleNxtClickcountry = () => {
    setPagecountry(pagecountry + 1);
  };

  const [itemToDeletecountry, setItemToDeletecountry] = useState(null);
  const [successMessagecountry, setSuccessMessagecountry] = useState("");

  const openModalcountry = (id) => {
    setItemToDeletecountry(id);
    setSuccessMessagecountry("");
  };

  const closeModalcountry = () => {
    setItemToDeletecountry(null);
  };
  const handleDeletedata = () => {
    closeModalcountry();
    if (itemToDeletecountry) {
      deleteOperationcountry(itemToDeletecountry);
    }
  };
  useEffect(() => {
    fetchDatacountry();
    fetchDatanextprecountry();
    fetchDatanextprecountryselect();
  }, [pagecountry]);
  const [editUsercountry, setEditUsercountry] = useState({
    country: "",
  });

  const handleInputcountry = (e) => {
    const { name, value } = e.target;
    setEditUsercountry((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagecountry("");
    setIsValidationHiddencountry(true);

    setEditUsercountry({ ...editUsercountry, [e.target.name]: e.target.value });
  };

  const [validationMessagecountry, setValidationMessagecountry] = useState("");
  const [isValidationHiddencountry, setIsValidationHiddencountry] =
    useState(true);

  const countryInputRef = useRef(null);

  async function addcountries() {
    let focusInputRef = null;

    if (editUsercountry?.country === "") {
      setValidationMessagecountry("Country cannot be empty.");
      setIsValidationHiddencountry(false);
      focusInputRef = countryInputRef;
    }

    const formData = new FormData();

    formData.append("id", editUsercountry?.country);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Block-countries/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;

        resultapi = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      setValidationMessagecountry("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        setProcessing(false);
        setShowOverlay(false);
        var resultError = resultapi;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddencountry(false);
      }
    }
  }

  return (
    <>
      <div className="banner-slider">
        {showModal && (
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Slider </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModalslider}
                  ></button>
                </div>
                <div className="modal-body">
                  Are you sure you want to Switch the Geo-Fencing Status?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModalslider}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={confirmToggle}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className="modal fade"
        id="deleteexampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delete_confirm">
          <div className="modal-content text-center">
            <div className="modal-body">
              {isConfirmationOpencountry && (
                <div>
                  <h5 className="modal-title p-3" id="exampleModalLabel">
                    <p className="fs-6">
                      Are you sure you want to delete the selected items?
                    </p>
                  </h5>
                  <div className="col-lg-12 row p-4">
                    <div className="col-lg-6">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          handleConfirmDeletecountry();
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <button
                        className="btn btn-primary"
                        onClick={handleCancelDeletecountry}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {isSuccessMessageOpencountry && (
                <div className="success-message">
                  <div className="p-5">
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </div>
                  <p className="fs-6 fw-bold">
                    Selected items have been successfully deleted.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className=" m-0 p-0">
        <div className="">
          <section className="section  container-fluid">
            <div className="">
              <div className="col-lg-12 row py-3">
                <Link to="/settings" className="backarrow">
                  <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                  <span> Back</span>
                </Link>
                <div className="col-6 col-sm-6 col-lg-6">
                  <h3>Geo-Fencing Settings</h3>
                </div>
                {/* <div className="col-6 col-sm-6 col-lg-6">
                  <div className="text-end">
                    <button onClick={handleUpdate} className="btn btn-primary">
                      Save Geofencing
                    </button>
                  </div>
                </div> */}
              </div>

              <div>
                {showOverlay && <Response_Processing></Response_Processing>}
              </div>

              <div className="iq-card">
                <div className="" id="cpp_payouts">
                  <div className="row" data-collapsed="0">
                    <div className="panel-body">
                      <div className="row">
                        <div>
                          <p className="">
                            {" "}
                            <label>Geo-Fencing Status</label>{" "}
                          </p>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-lg-4 text-end">
                            <div className="mr-2">Disable</div>
                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="geofencing_status"
                                    id="geofencing_status"
                                    className={
                                      editUser?.geofencing_status === 0
                                        ? "inactive"
                                        : null
                                    }
                                    checked={editUser?.geofencing_status === 1}
                                    onChange={() => handleToggle(editUser)}
                                    type="checkbox"
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 text-start">
                            <div className="ml-2">Enable</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="text-end p-3">
              <input
                type="submit"
                id="appupdate"
                onClick={handleUpdate}
                value="Update Setting"
                className="btn btn-primary ml-3 "
              />
            </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className=" m-0 p-0">
        <div className="container-fluid">
          <section className="section ">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>
                  Blocked Countries{" "}
                  <span className="fs-6">
                    (Display all {countcountry} Blocked Countries)
                  </span>
                </h3>
              </div>
              <div className="col-3 col-sm-3 col-lg-3 text-end">
                {selectedItemscountry?.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleDeletecountry}
                    data-bs-toggle="modal"
                    data-bs-target="#deleteexampleModal"
                  >
                    Delete Selected
                  </button>
                )}
              </div>

              <div
                className="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Select a Country to Block
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div>
                        <select
                          className="selectpicker countrypicker form-select"
                          name="country"
                          value={editUsercountry?.country}
                          onChange={handleInputcountry}
                          ref={countryInputRef}
                        >
                          <option value={""}>Select an Block Country</option>
                          {countriesdrop?.map((item, key) => (
                            <option key={item?.id} value={item?.id}>
                              {item?.country_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <span className="errorred">
                        {!isValidationHiddencountry && (
                          <p>{validationMessagecountry}</p>
                        )}
                      </span>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={addcountries}
                      >
                        Add Country
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-3 col-sm-3 col-lg-3 text-end">
                {" "}
                <Link>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Add Countries
                  </button>
                </Link>
              </div>
            </div>

            <div
              className={`modal ${itemToDeletecountry !== null ? "show" : ""}`}
              tabIndex="-1"
              style={{
                display: itemToDeletecountry !== null ? "block" : "none",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Confirm Deletion</h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={closeModalcountry}
                    ></button>
                  </div>
                  <div className="modal-body">
                    {successMessagecountry ? (
                      <div className="alert alert-success" role="alert">
                        {successMessagecountry}
                      </div>
                    ) : (
                      <p>
                        Are you sure you want to delete this Blocked Country?
                      </p>
                    )}
                  </div>
                  <div className="modal-footer">
                    {successMessagecountry ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={closeModalcountry}
                      >
                        Close
                      </button>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={closeModalcountry}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={handleDeletedata}
                        >
                          Yes
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog delete_confirm">
                <div className="modal-content text-center">
                  <div className="modal-body">
                    {isConfirmationOpencountry && (
                      <div>
                        <h5 className="modal-title p-3" id="exampleModalLabel">
                          <p className="fs-6">
                            Are you sure you want to delete the selected items?
                          </p>
                        </h5>
                        <div className="col-lg-12 row p-4">
                          <div className="col-lg-6">
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                handleConfirmDeletecountry();
                              }}
                            >
                              Confirm
                            </button>
                          </div>
                          <div className="col-lg-6">
                            <button
                              className="btn btn-primary"
                              onClick={handleCancelDeletecountry}
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {isSuccessMessageOpencountry && (
                      <div className="success-message">
                        <div className="p-5">
                          <i className="fa fa-check" aria-hidden="true"></i>
                        </div>
                        <p className="fs-6 fw-bold">
                          Selected items have been successfully deleted.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 ">
              <div className="card">
                {loading ? (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllcountry}
                                onChange={handleSelectAllcountry}
                              />
                            </label>
                          </th>
                          <th>COUNTRY NAME</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                    </Table>

                    <div className="text-center">
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>{" "}
                        Loading...
                      </label>
                    </div>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickcountry}
                          disabled={pagecountry <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pagecountry}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickcountry}
                          disabled={datacountry?.length < itemsPerPagecountry}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : countrydatacountry?.length > 0 ? (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllcountry}
                                onChange={handleSelectAllcountry}
                              />
                            </label>
                          </th>
                          <th>COUNTRY NAME</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      {currentItemscountry?.map((item, key) => (
                        <tbody>
                          <tr key={item?.id}>
                            <td key={item?.id}>
                              <input
                                type="checkbox"
                                checked={selectedItemscountry.includes(
                                  item?.id
                                )}
                                onChange={() =>
                                  handleMultiSelectcountry(item?.id)
                                }
                              />
                            </td>

                            <td>
                              <div className="d-flex text-start align-items-center ">
                                <div>
                                  <div className="ms-2">
                                    <div>{item?.name}</div>
                                    <div className="d-flex ">
                                      <div className="">
                                        <span>{item?.upload_on}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div className="editdropdown">
                                <span className="editdropdown-button">
                                  <span>
                                    <i
                                      className="fa fa-ellipsis-v"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </span>
                                <div className="editdropdown-menu">
                                  <Link to="" className="delete">
                                    <span
                                      onClick={() => openModalcountry(item?.id)}
                                    >
                                      <img
                                        src={deleteitem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">
                                        Delete Country
                                      </span>
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </Table>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickcountry}
                          disabled={pagecountry <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pagecountry}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickcountry}
                          disabled={datacountry?.length < itemsPerPagecountry}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllcountry}
                                onChange={handleSelectAllcountry}
                              />
                            </label>
                          </th>
                          <th>COUNTRY NAME</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                    </Table>

                    <div className="text-center">
                      <label>No Blocked Countries Available.</label>
                    </div>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickcountry}
                          disabled={pagecountry <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pagecountry}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickcountry}
                          disabled={datacountry?.length < itemsPerPagecountry}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Manage_Geo_Fencing;
