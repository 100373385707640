import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";

function ContentAudio() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    ppv_status: "",
    ppv_price: "",
    type: "",
    access: "",
    album_id: "",
    artists: "",
    rating: "",
    details: "",
    description: "",
    active: "",
    status: "",
    draft: "",
    featured: "",
    albumname: "",
    banner: "",
    duration: "",
    views: "",
    year: "",
    age_restrict: "",
    mp3_url: "",
    image: "",
    player_image: "",
    tv_image: "",
    search_tags: "",
    ios_ppv_price: "",
    uploaded_by: "",
    image_url: "",
    Player_thumbnail: "",
    TV_Thumbnail: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");
  const [data, setData] = useState([]);

  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [tv_image, setTv_image] = useState("");

  const [user_access, setUser_access] = useState([]);
  const [livestream_source, setLivestream_source] = useState([]);
  const [useraccess, setUserAccess] = useState("");
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [album, setAlbum] = useState([]);
  // console.log(age_restrictdata)
  const [age_restrict, setAge_restrict] = useState([]);
  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(editUser.ppv_price);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleChangeFirstdetails = (e) => {
    setEditUser({ ...editUser, details: e });
    // console.log(e, "onchnageddddd");
  };

  // MULTI SELECT NEW
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [optionsrelated_video, setOptionsrelated_video] = useState([]);
  const [selectedValuesrelated_video, setSelectedValuesrelated_video] =
    useState([]);

  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);

  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);

  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);

  const [optionsavailable_country, setOptionsAvailable_country] = useState([]);
  const [selectedValuesavailable_country, setSelectedValuesAvailable_country] =
    useState([]);

  const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
    []
  );

  const [formData, setFormData] = useState({
    block_country: [],
    Available_country: [],
    related_video: [],
    artists: [],
    categories: [],
    languages: [],
    Age_Restrict: [],
    albumname: editUser.albumname,
    // ...editInputvalue,
    // other form data properties...
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/audio/edit/${id}`,
        { headers: headers }
      );
      // var res = response?.data?.Live_Stream[0];
      // console.log(res)

      const data = response?.data?.selected_Blocklivestream;
      const dataavailable_country =
        response?.data?.selected_available_countries;
      const dataartists = response?.data?.selected_artist;
      const datacategories = response?.data?.selected_category;
      const datalanguages = response?.data?.selected_language;
      const dataAge_Restrict = response?.data?.selected_Age_Restrict;
      // const datarelated_video = response?.data?.selected_related_video;

      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      // console.log(result);

      // var res = response?.data?.videos[0];
      // setEditUser(res);

      // var res = response?.data?.videos[0];
      var globelset = response?.data?.ppv_gobal_price;

      setInputValueAPI(globelset);

      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.country_name,
      }));

      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      // const formattedOptionsrelated_video = datarelated_video?.map((item) => ({
      //   value: item?.id,
      //   label: item?.id,
      // }));

      // Set the initial selected values
      setSelectedValues(formattedOptions);
      setOptions(formattedOptions);

      // setSelectedValuesrelated_video(formattedOptionsrelated_video);
      // setOptionsrelated_video(formattedOptionsrelated_video);

      setSelectedValuesAvailable_country(formattedOptionsavailable_country);
      setOptionsAvailable_country(formattedOptionsavailable_country);

      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);

      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);

      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);

      setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/audio/create`,
        { headers: headers }
      );
      const data = response?.data?.Block_Country;
      const dataavailable_country = response?.data?.Available_country;
      const dataartists = response?.data?.artists;
      const datacategories = response?.data?.audio_categories;
      const datalanguages = response?.data?.languages;
      const dataAge_Restrict = response?.data?.Age_Restrict;
      // setSelectedLanguages(data)
      // console.log(data);
      setAlbum(response?.data?.audio_albums);
      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);
      setLivestream_source(response?.data?.Livestream_source);
      // console.log(data);
      // console.log(data);
      // Extract the selected data from the response
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.country_name,
      }));
      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      // const formattedOptionsrelated_video = datarelated_video.map((item) => ({
      //   value: item?.id,
      //   label: item?.id,
      // }));

      // Set the options for the multi-select component
      setOptions(formattedOptions);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // setOptionsrelated_video(formattedOptionsrelated_video);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(async () => {
    fetchData();
  }, []);
  // const handleLanguageChange = (event) => {
  //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  //   setSelectedLanguages(selectedOptions);
  // };

  const handleSelectChange = (selectedOptions) => {
    const block_country = selectedOptions?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      block_country: block_country,
    });

    setSelectedValues(selectedOptions);
  };
  const handleSelectChangeavailable_country = (
    selectedOptionsavailable_country
  ) => {
    const available_country = selectedOptionsavailable_country?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      available_country: available_country,
    });

    setSelectedValuesAvailable_country(selectedOptionsavailable_country);
  };
  const handleSelectChangeartists = (selectedOptionsartists) => {
    const artists = selectedOptionsartists?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      artists: artists,
    });

    setSelectedValuesartists(selectedOptionsartists);
  };

  const handleSelectChangecategories = (selectedOptionscategories) => {
    const categories = selectedOptionscategories?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      categories: categories,
    });

    setSelectedValuescategories(selectedOptionscategories);
  };

  const handleSelectChangerelated_video = (selectedOptionsrelated_video) => {
    const related_video = selectedOptionsrelated_video?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      related_video: related_video,
    });

    setSelectedValuesrelated_video(selectedOptionsrelated_video);
  };

  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    const languages = selectedOptionslanguages?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      languages: languages,
    });

    setSelectedValueslanguages(selectedOptionslanguages);
  };
  const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
    const Age_Restrict = selectedOptionsAge_Restrict?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      Age_Restrict: Age_Restrict,
    });

    setSelectedValueslanguages(selectedOptionsAge_Restrict);
  };

  // MULTI SELECT NEW

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/audio/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.audios[0];
      setEditUser(res);
      // console.log(resData)
      // console.log(res);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      title: editUser.title,
      slug: editUser.slug,
      ppv_status: editUser.ppv_status,
      ppv_price: editUser.ppv_price,
      type: editUser.type,
      access: editUser.access,
      album_id: editUser.album_id,
      artists: editUser.artists,
      rating: editUser.rating,
      details: editUser.details,
      description: editUser.description,
      active: editUser.active,
      status: editUser.status,
      draft: editUser.draft,
      featured: editUser.featured,
      banner: editUser.banner,
      ads_position: editUser.ads_position,
      duration: editUser.duration,
      views: editUser.views,
      year: editUser.year,
      albumname: editUser.albumname,
      age_restrict: editUser.age_restrict,
      mp3_url: editUser.mp3_url,
      image: editUser.image,
      player_image: editUser.player_image,
      tv_image: editUser.tv_image,
      search_tags: editUser.search_tags,
      ios_ppv_price: editUser.ios_ppv_price,
      uploaded_by: editUser.uploaded_by,
      image_url: editUser.image_url,
      Player_thumbnail: editUser.Player_thumbnail,
      TV_Thumbnail: editUser.TV_Thumbnail,
      pre_ads_category: editUser.pre_ads_category,
      mid_ads_category: editUser.mid_ads_category,
      post_ads_category: editUser.post_ads_category,
      ...formData,
    };
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/audio/update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();

    if (res.status === 200) {
      // setMessage(resjson.success);

      var audio_id = resjson.audio_id;

      const formData = new FormData();
      formData.append("audio_id", audio_id);
      if (selectedFile1) {
        formData.append("image", selectedFile1);
      }
      if (selectedFile2) {
        formData.append("player_image", selectedFile2);
      }
      if (selectedFile3) {
        formData.append("tv_image", selectedFile3);
      }

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-audio`,
          formData,
          { headers: headers }
        )
        .then((response) => { })
        .catch((error) => {
          console.error("Error uploading file(s):", error);
        });

      const formDataseo = new FormData();
      formDataseo.append("audio_id", audio_id);
      formDataseo.append("website_page_title", editUser.website_page_title);
      formDataseo.append("website_URL", editUser.website_URL);
      formDataseo.append("Meta_description", editUser.Meta_description);

      axios
        .post(`${process.env.REACT_APP_Baseurl}/admin/audio/SEO`, formDataseo, {
          headers: headers,
        })
        .then((response) => {
          console.log("File(s) uploaded successfully:", response.data);
        });

      window.location.reload();
      alert(res.data.message + "!");
      // navigate("/audio-list");
      // alert("Added Successfully" + res.data.message, "!");
    } else {
      // setMessage("Some error Occured");
      // console.log("Error");
      // alert("Enter Correct Details");
    }
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/audio/create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.user_access;
        setData(result);
        //   console.log('result', result)
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(async () => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/audio/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();
  }, []);

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function alllive_streamuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="col-sm-12">
              <label className="m-0">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p className="p1">Apply PPV Price from Global Settings? </p>
                </div>
                {/* <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      className="rs-input"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div> */}
              </div>

              <div className="panel-body">
                <input
                  type="text"
                  className="rs-input"
                  placeholder="PPV Price"
                  value={editUser.ppv_price}
                  id="ppv_price"
                  name="ppv_price"
                  onChange={handleInput}
                />
              </div>
            </div>

            <div className="col-sm-12 mt-2">
              <label className="m-0"> IOS PPV Price</label>
              <p className="p1">Apply IOS PPV Price from Global Settings?</p>
              <div className="panel-body ppv_price_ios mt-2">
                <select
                  // onChange={(e) => setIos_ppv_price(e.target.value)}
                  name="ios_ppv_price"
                  onChange={handleInput}
                  value={editUser.ios_ppv_price}
                  className="rs-input"
                >
                  {inapppurchase?.map((item, key) => (
                    <option value={item?.product_id}>{item?.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <section className="container-fluid">
          <div className="col-lg-12 row py-3">
            <div className="col-6 col-sm-6 col-lg-6">
              <h3>Add Audio</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Add Audio
                </button>
              </div>
            </div>
          </div>
          <div className="row col-lg-12 ">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
              <div className="card p-3">
                <h5>Create Audio</h5>
                <hr></hr>
                <div className=" text-start">
                  <label className="m-0">Title</label>
                  <p className="t-1">
                    Add the audio title in the textbox below:
                  </p>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="title"
                      name="title"
                      value={editUser.title}
                      onChange={handleInput}
                      className="rs-input form-control-lg"
                      placeholder="Title"
                    />
                  </div>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0">Slug</label>
                  <p className="t-1">Add the Audio slug:</p>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="slug"
                      id="slug"
                      onChange={handleInput}
                      value={editUser?.slug}
                      className="rs-input form-control-lg"
                      placeholder="slug"
                    />
                  </div>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0">Short Description</label>
                  <p className="t-1">
                    Add a short description of the audio below:
                  </p>
                  <div className="mt-2">
                    <textarea
                      className="rs-input short-description"
                      id="description"
                      name="description"
                      placeholder="Description"
                      onChange={handleInput}
                      value={editUser?.description}
                    />
                  </div>
                </div>
                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0">
                      Audio Details, Links, and Info
                    </label>
                    <div className="mt-2">
                      <JoditEditor
                        className="rs-input"
                        value={editUser?.details}
                        onChange={(e) => {
                          handleChangeFirstdetails(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0">Duration</label>
                    <p className="t-1">
                      Enter the audio duration in the following format (Hours :
                      Minutes : Seconds)
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        placeholder="duration"
                        name="duration"
                        onChange={handleInput}
                        value={editUser?.duration}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0">Year</label>
                    <p className="t-1">Audio Released Year</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="year"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.year}
                        placeholder="Year"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                <div className="card p-3">
                  <h5>Organize</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">Audio Ratings :</label>
                    <p className="t-1">IMDb Ratings 10 out of 10:</p>
                    <div className="mt-2">
                      <select
                        className="rs-input form-control-lg "
                        id="rating"
                        name="rating"
                        size="lg"
                        onChange={handleInput}
                        value={editUser?.rating}
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Age Restrict :</label>
                    <p className="t-1">Select a Audio age Below:</p>
                    <div className="mt-2">
                      <select
                        className="rs-input form-control-lg "
                        id="age_restrict"
                        name="age_restrict"
                        onChange={handleInput}
                        value={editUser?.age_restrict}
                      >
                        {age_restrictdata.map((item) => (
                          <option selected value={item?.id}>
                            {item?.slug}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Album</label>
                    <p className="t-1">Select A Audio Album Below:</p>
                    <div className="mt-2">
                      <select
                        className="rs-input form-control-lg "
                        onChange={handleInput}
                        value={editUser.albumname}
                      >
                        {album?.map((item, key) => (
                          <option value={item?.id}>{item?.albumname}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Cast and Crew</label>
                    <p className="t-1">Add artists for the audio below:</p>

                    <div className="mt-2">
                      <Select
                        options={optionsartists}
                        isMulti
                        className="rs-input "
                        onChange={handleSelectChangeartists}
                        value={selectedValuesartists}
                      />
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Category</label>
                    <p className="t-1">Select a Audio Category Below:</p>

                    <div className="mt-2">
                      <Select
                        options={optionscategories}
                        isMulti
                        className="rs-input "
                        onChange={handleSelectChangecategories}
                        value={selectedValuescategories}
                      />
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Language</label>
                    <p className="t-1">Select a Audio Language Below:</p>
                    <div className="text-dark">
                      <Select
                        options={optionslanguages}
                        isMulti
                        className="rs-input"
                        onChange={handleSelectChangelanguages}
                        value={selectedValueslanguages}
                      />
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Country</label>
                    <p className="t-1">Select a Audio country Below:</p>
                    <div className="text-dark">
                      <Select
                        options={options}
                        isMulti
                        className="rs-input"
                        onChange={handleSelectChange}
                        value={selectedValues}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card p-3">
                <h5>SEO</h5>
                <hr></hr>

                <div className="col-sm-12">
                  <label className="m-0">Website Page Title</label>

                  <div className=" mt-2">
                    <input
                      type="text"
                      value={editUser?.website_page_title}
                      name="website_page_title"
                      className="rs-input form-control-lg"
                      onChange={handleInput}
                      placeholder="website title"
                    />
                  </div>
                </div>

                <div className="col-sm-12 mt-2">
                  <label className="m-0">Website URL</label>

                  <div className=" mt-2">
                    <input
                      type="text"
                      value={editUser?.website_URL}
                      name="website_URL"
                      className="rs-input form-control-lg"
                      onChange={handleInput}
                      placeholder="website url"
                    />
                  </div>
                </div>
                <div className="col-sm-12 mt-2">
                  <label className="m-0">Meta Description</label>

                  <div className=" mt-2">
                    <textarea
                      type="text"
                      value={editUser?.Meta_description}
                      name="Meta_description"
                      className="rs-input form-control-lg"
                      onChange={handleInput}
                      placeholder="meta description"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="text-start mb-3">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Add Audio
                </button>
              </div>
            </div>

            {/* Second Part of Section  */}

            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="card p-3">
                <h5>Thumbnails</h5>
                <hr></hr>

                <div className=" text-start mt-2">
                  <label className="m-0">Audio Image Cover</label>
                  <p className="t-1">
                    Select the Audio image( 9:16 Ratio or 1080X1920px ):
                  </p>
                  <img src={editUser?.image_url} width="150" height="100" />
                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef1.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef1}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef1,
                                setSelectedFile1
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div>
                          {selectedFile1 && (
                            <div className="imagedropcopy text-end">
                              <img
                                src={URL.createObjectURL(selectedFile1)}
                                alt="Uploaded"
                                width="150"
                                height="100"
                              />
                              <button
                                onClick={() => handleDelete(setSelectedFile1)}
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>

                              {/* <button onClick={() => handleDelete(setSelectedFile1)}>Delete</button> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" text-start mt-2">
                  <label className="m-0">Player Audio Thumbnail </label>
                  <p className="t-1">
                    Select the Audio image (16:9 Ratio or 1280X720px):
                  </p>
                  <img
                    src={editUser?.Player_thumbnail}
                    width="150"
                    height="100"
                  />
                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef2.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef2}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef2,
                                setSelectedFile2
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div>
                          {selectedFile2 && (
                            <div className="imagedropcopy text-end">
                              <img
                                src={URL.createObjectURL(selectedFile2)}
                                alt="Uploaded"
                                width="150"
                                height="100"
                              />
                              <button
                                onClick={() => handleDelete(setSelectedFile2)}
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" text-start mt-2">
                  <label className="m-0">TV Thumbnail Image Cover</label>
                  <p className="t-1">
                    Select the audio image ( 16:9 Ratio or 1280X720px ):
                  </p>
                  <img src={editUser?.TV_Thumbnail} width="150" height="100" />
                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef3.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef3}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef3,
                                setSelectedFile3
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div>
                          {selectedFile3 && (
                            <div className="imagedropcopy text-end">
                              <img
                                src={URL.createObjectURL(selectedFile3)}
                                alt="Uploaded"
                                width="150"
                                height="100"
                              />
                              <button
                                onClick={() => handleDelete(setSelectedFile3)}
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="card p-3">
                  <h5>Access</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">User Access</label>
                    <p className="p1">Who Is Allowed To View This audio ?</p>
                    <select
                      onChange={handleInput}
                      name="access"
                      value={editUser.access}
                      className="rs-input mt-2 form-control-lg"
                      onClick={(event) => {
                        setallliveuseraccess(event.target.value);
                      }}
                    >
                      {user_access?.map((item, key) => (
                        <option value={item?.role}>{item?.name}</option>
                      ))}
                    </select>
                  </div>

                  <div>{alllive_streamuseraccess()}</div>
                </div>

                <div className="card p-3">
                  <h5>Status Settings</h5>
                  <hr></hr>

                  <div className="col-sm-12 row">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">Is this Audio Featured:</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="featured"
                          onChange={handleInput}
                          className="rs-input"
                          defaultChecked={editUser.featured == 1 ? true : false}
                          checked={editUser.featured == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="featured"
                          onChange={handleInput}
                          value={editUser.featured == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12 row mt-2">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">Is this Audio Active:</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="active"
                          onChange={handleInput}
                          defaultChecked={editUser.active == 1 ? true : false}
                          checked={editUser.active == 1 ? true : false}
                          className="rs-input"
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="active"
                          onChange={handleInput}
                          value={editUser.active == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 row mt-2">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">Is this Audio display in Banner:</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="banner"
                          onChange={handleInput}
                          className="r-input"
                          defaultChecked={editUser.banner == 1 ? true : false}
                          checked={editUser.banner == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="banner"
                          onChange={handleInput}
                          value={editUser.banner == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Search Tags</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <p className="p2">You don't have any search keywords.</p>

                    <div className="mt-2">
                      {/* <TagsInput
                          value={search_tags}
                          className="rs-input"
                          onChange={setSearch_tags}
                          name="search_tags"
                        /> */}
                      {/* <input value={editUser.search_tags} 
                        onChange={handleInput}
                        name="search_tags"/> */}
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Choose Ads Settings</h5>
                  <hr></hr>

                  <div className="col-sm-12 mt-2">
                    <label className="m-0">Choose Ads Position</label>

                    <div className=" mt-2">
                      <Select
                        options={adsPositions}
                        className="rs-input"
                        onChange={(e) => handleAdsPositionChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 mt-2">
                    <label className="m-0">Choose Advertisement</label>

                    <div className=" mt-2">
                      <Select
                        options={secondSelectOptions}
                        className="rs-input"
                        onChange={(e) => setSecondSelectOptions(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Edit Audio</h4>
                      </div>
                    </div>
                    <hr />
                    <div className="iq-card-body">
                      <form>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary "
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Title</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Add the audio title in the textbox below:
                                </p>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  id="title"
                                  value={editUser?.title}
                                  placeholder="Audio Title"
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Slug</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">Add the Audio slug:</p>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="slug"
                                  id="slug"
                                  placeholder=""
                                  onChange={handleInput}
                                  value={editUser?.slug}
                                  readonly
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary  p-0 mt-3"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Audio Image Cover</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select the audio image ( 9:16 Ratio or
                                  1080X1920px ):
                                </p>
                                <img
                                  src={editUser?.image_url}
                                  className="audio-img"
                                  width={150}
                                  height={150}
                                />

                                <input
                                  type="file"
                                  multiple="true"
                                  className="form-control"
                                  name="image"
                                  id="image"
                                  onChange={(e) => setImage(e.target.files[0])}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="panel panel-primary  p-0 mt-3"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Player Image Cover</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select the audio image ( 16:9 Ratio or
                                  1280X720px ):
                                </p>
                                <img
                                  src={editUser?.Player_thumbnail}
                                  className="audio-img"
                                  width={150}
                                  height={150}
                                />

                                <input
                                  type="file"
                                  multiple="true"
                                  className="form-control"
                                  name="player_image"
                                  id="player_image"
                                  onChange={(e) =>
                                    setPlayer_image(e.target.files[0])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div
                            className="panel panel-primary col-sm-6  mt-3"
                            data-collapsed="0"
                          >
                            {" "}
                            <div className="panel-heading">
                              <div className="panel-title">
                                <label>Country</label>
                              </div>{" "}
                            </div>
                            <div className="panel-body">
                              <p className="p1">
                                Block the Audio for Selected Country:
                              </p>
                             
                            </div>
                          </div>

                          <div className="col-sm-6 mt-3">
                            <div className="">
                              <label className="m-0">Search Tags</label>

                              <div className="panel-body mt-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="tag-input1"
                                  onChange={handleInput}
                                  value={editUser?.search_tags}
                                  name="search_tags"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="panel panel-primary mt-3"
                          data-collapsed="0"
                        >
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Audio Details, Links, and Info</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <textarea
                              className="form-control"
                              onChange={handleInput}
                              value={editUser?.details}
                              name="details"
                              id="details"
                            ></textarea>
                          </div>
                        </div>

                        <div
                          className="panel panel-primary mt-3"
                          data-collapsed="0"
                        >
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>Short Description</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Add a short description of the audio below:
                            </p>
                            <textarea
                              className="form-control"
                              name="description"
                              onChange={handleInput}
                              value={editUser?.description}
                              id="description"
                            ></textarea>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Cast and Crew</label>{" "}
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p>Add artists for the audio below:</p>
                               
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Album</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Album Below:
                                </p>
                                <select
                                  id="album_id"
                                  name="album_id"
                                  className="form-control"
                                  onChange={handleInput}
                                >
                                  <option value={editUser?.album_id}>
                                    {editUser?.album_id}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              <div className="panel-heading">
                                <label className="p2" for="global_ppv">
                                  Age Restrict:
                                </label>
                              </div>
                              <div className="panel-body">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="age_restrict"
                                  id="age_restrict"
                                  onChange={editUser?.age_restrict}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Artists</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Artists Below:
                                </p>
                                <select
                                  id="artists"
                                  name="artists"
                                  className="form-control"
                                  onChange={handleInput}
                                >
                                  <option value={editUser?.artists}>
                                    {editUser?.artists}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row p-0 mt-3 align-items-center">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Category</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Category Below:
                                </p>

                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Audio Ratings</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1"> IMDB Ratings 10 out of 10</p>
                                <select
                                  value={editUser?.rating}
                                  className="form-select"
                                  aria-label="Default select example"
                                  onChange={handleInput}
                                >
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                                 </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Language</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Select a Audio Language Below:
                                </p>
                              
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 ">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              {" "}
                              <div className="panel-heading">
                                <div className="panel-title">
                                  <label>Audio Year</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">Audio Released Year</p>
                                <input
                                  className="form-control"
                                  name="year"
                                  id="year"
                                  onChange={handleInput}
                                  value={editUser?.year}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 align-items-center">
                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              <div className="panel-heading">
                                {" "}
                                <div className="panel-title">
                                  <label> Duration</label>
                                </div>
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Enter the audio duration in the following
                                  format (Hours : Minutes : Seconds)
                                </p>
                                <input
                                  className="form-control"
                                  name="duration"
                                  id="duration"
                                  maxlength="12"
                                  onChange={handleInput}
                                  value={editUser?.duration}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div
                              className="panel panel-primary"
                              data-collapsed="0"
                            >
                              <div className="panel-heading">
                                {" "}
                                <div className="panel-title">
                                  {" "}
                                  <label>User Access</label>
                                </div>{" "}
                              </div>
                              <div className="panel-body">
                                <p className="p1">
                                  Who is allowed to view this audio?
                                </p>
                                <select
                                  value={editUser?.user_access}
                                  id="access"
                                  name="access"
                                  className="form-control"
                                >
                                 
                                  {data?.map((item) => (
                                    <option value={item?.value}>
                                      {item?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mt-3 row ">
                          <div className="col-lg-6 ">
                            <div className="col-lg-12 row">
                              <div className="col-lg-10 col-10">
                                <label className="p2" for="global_ppv">
                                  Is this video Is Global PPV:
                                </label>
                              </div>
                              <div className="col-lg-2 col-2">
                                <label className="switch">
                                  <input
                                    name="ppv_status"
                                    id="ppv_status"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.ppv_status === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.ppv_status === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="ppv_status"
                                    id="ppv_status"
                                    onChange={handleInput}
                                    value={
                                      editUser?.ppv_status === 1 ? "1" : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div>
                              <div className="panel-heading">
                                {" "}
                                <div className="panel-title">
                                  <label> Status Settings</label>
                                </div>{" "}
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="featured">
                                    Is this audio Featured:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="featured"
                                      id="featured"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.featured === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.featured === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="featured"
                                      id="featured"
                                      onChange={handleInput}
                                      value={
                                        editUser?.featured === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="banner">
                                    Is this Audio display in Banner:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="banner"
                                      id="banner"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.banner === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.banner === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="banner"
                                      id="banner"
                                      onChange={handleInput}
                                      value={editUser?.banner === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="active">
                                    Is this audio Active:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="active"
                                      id="active"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.active === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.active === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="active"
                                      id="active"
                                      onChange={handleInput}
                                      value={editUser?.active === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-12 row">
                                <div className="col-lg-10 col-10">
                                  <label className="p2" for="active">
                                    Is this audio Status:
                                  </label>
                                </div>
                                <div className="col-lg-2 col-2">
                                  <label className="switch">
                                    <input
                                      name="status"
                                      id="status"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.status === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.status === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="status"
                                      id="status"
                                      onChange={handleInput}
                                      value={editUser?.status === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="row mt-3 align-items-center ml-3">
                            <div className="col-sm-12">
                              <label className="switch" hidden>
                                <input
                                  name="draft"
                                  id="draft"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.draft === 1 ? true : false
                                  }
                                  checked={editUser?.draft === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="draft"
                                  id="draft"
                                  onChange={handleInput}
                                  value={editUser?.draft === 1 ? "1" : "0"}
                                ></span>
                              </label>

                              <label className="switch" hidden>
                                <input
                                  name="views"
                                  id="views"
                                  onChange={handleInput}
                                  defaultChecked={
                                    editUser?.views === 1 ? true : false
                                  }
                                  checked={editUser?.views === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="views"
                                  id="views"
                                  onChange={handleInput}
                                  value={editUser?.views === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="mt-2 p-2 d-flex justify-content-end">
                          <input
                            type="submit"
                            value="Update"
                            className="btn btn-primary "
                            onClick={handleUpdate}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div> */}
      </div>
    </>
  );
}

export default ContentAudio;
