// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CPP_ANALYTICS .nav-link.active {
  background-color: #0d6efd;
  padding: 10px 60px;
  color: #ffff;
}

.CPP_ANALYTICS .nav-link {
  background-color: transparent;
  padding: 10px 60px;
  color: #000000;
  border-color: #0d6efd;
  border-radius: 5px;
}

.CPP_ANALYTICS .nav-tabs .nav-link:hover {
  border-color: #0d6efd;
  isolation: isolate;
}

.user_reg_analytics_count {
  font-size: 18px !important;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Analytics/CPPAnalytics.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,cAAc;EACd,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".CPP_ANALYTICS .nav-link.active {\n  background-color: #0d6efd;\n  padding: 10px 60px;\n  color: #ffff;\n}\n\n.CPP_ANALYTICS .nav-link {\n  background-color: transparent;\n  padding: 10px 60px;\n  color: #000000;\n  border-color: #0d6efd;\n  border-radius: 5px;\n}\n\n.CPP_ANALYTICS .nav-tabs .nav-link:hover {\n  border-color: #0d6efd;\n  isolation: isolate;\n}\n\n.user_reg_analytics_count {\n  font-size: 18px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
