import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, } from "react-router-dom";
import "./MobileSettings.css";
import { Table } from "react-bootstrap";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import edititem from "../../../components/Images/edititem.svg";
import deleteitem from "../../../components/Images/deleteitem.svg";

function MobileSettings(props) {
  const [data, setData] = useState([]);
  const [splash, setSplash] = useState([]);
  const [splash_image_url, setSplash_image_url] = useState([]);
  const [loading, setLoading] = useState(true);

  const [welcome_images_url, setWelcome_images_url] = useState("");

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [editUser, setEditUser] = useState({
    name: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagename("");
    setIsValidationHiddenname(true);
  };

  useEffect(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/welcome-screen-index`,
        { headers: headers }
      )
      .then((res) => {
        setData(res?.data?.Welcome_Screen);
        setWelcome_images_url(res?.data?.Welcome_Screen[0]?.welcome_images_url);
        setLoading(false);
      });

    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/splash-screen-index`,
        { headers: headers }
      )
      .then((res) => {
        setSplash(res?.data?.splash_Screen);
        setSplash_image_url(res?.data?.splash_Screen[0]?.splash_image_url);
        setLoading(false);
      });
  }, []);

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);
  const fileInputRef6 = useRef(null);
  const fileInputRef7 = useRef(null);
  const fileInputRef8 = useRef(null);
  const fileInputRef9 = useRef(null);
  const fileInputRef10 = useRef(null);
  const fileInputRef11 = useRef(null);
  const fileInputRef12 = useRef(null);
  const fileInputRef13 = useRef(null);
  const fileInputRef14 = useRef(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [selectedFile5, setSelectedFile5] = useState(null);
  const [selectedFile6, setSelectedFile6] = useState(null);
  const [selectedFile7, setSelectedFile7] = useState(null);
  const [selectedFile8, setSelectedFile8] = useState(null);
  const [selectedFile9, setSelectedFile9] = useState(null);
  const [selectedFile10, setSelectedFile10] = useState(null);
  const [selectedFile11, setSelectedFile11] = useState(null);
  const [selectedFile12, setSelectedFile12] = useState(null);
  const [selectedFile13, setSelectedFile13] = useState(null);
  const [selectedFile14, setSelectedFile14] = useState(null);

  const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      case 3:
        setSelectedFile3(null);
        if (fileInputRef3.current) {
          fileInputRef3.current.value = null;
        }
        break;
      case 4:
        setSelectedFile4(null);
        if (fileInputRef4.current) {
          fileInputRef4.current.value = null;
        }
        break;
      case 5:
        setSelectedFile5(null);
        if (fileInputRef5.current) {
          fileInputRef5.current.value = null;
        }
        break;
      case 6:
        setSelectedFile6(null);
        if (fileInputRef6.current) {
          fileInputRef6.current.value = null;
        }
        break;
      case 7:
        setSelectedFile7(null);
        if (fileInputRef7.current) {
          fileInputRef7.current.value = null;
        }
        break;
      case 8:
        setSelectedFile8(null);
        if (fileInputRef8.current) {
          fileInputRef8.current.value = null;
        }
        break;
      case 9:
        setSelectedFile9(null);
        if (fileInputRef9.current) {
          fileInputRef9.current.value = null;
        }
        break;
      case 10:
        setSelectedFile10(null);
        if (fileInputRef10.current) {
          fileInputRef10.current.value = null;
        }
        break;
      case 11:
        setSelectedFile11(null);
        if (fileInputRef11.current) {
          fileInputRef11.current.value = null;
        }
        break;
      case 12:
        setSelectedFile12(null);
        if (fileInputRef12.current) {
          fileInputRef12.current.value = null;
        }
        break;
      case 13:
        setSelectedFile13(null);
        if (fileInputRef13.current) {
          fileInputRef13.current.value = null;
        }
        break;
      case 14:
        setSelectedFile14(null);
        if (fileInputRef14.current) {
          fileInputRef14.current.value = null;
        }
        break;
      default:
        break;
    }
  };


  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function savesplash() {
    const formData = new FormData();

    formData.append("splash_image", selectedFile1);
    formData.append("andriod_splash_image", selectedFile2);
    formData.append("AndroidTv_splash_screen", selectedFile4);
    formData.append("LG_splash_screen", selectedFile5);
    formData.append("RokuTV_splash_screen", selectedFile6);
    formData.append("Samsung_splash_screen", selectedFile7);
    formData.append("Firetv_splash_Screen", selectedFile8);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/splash-screen-store`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;

        resultapi = response.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        console.log(resultError);
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  const [validationMessagename, setValidationMessagename] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);

  const nameInputRef = useRef(null);

  async function savewelcome() {
    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagename("Name cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const formData = new FormData();

    formData.append("welcome_images", selectedFile3);
    formData.append("name", editUser?.name);
    formData.append("andriod_Welcome_Screen", selectedFile9);
    formData.append("AndroidTv_Welcome_Screen", selectedFile10);
    formData.append("LG_Welcome_Screen", selectedFile11);
    formData.append("RokuTV_Welcome_Screen", selectedFile12);
    formData.append("Samsung_Welcome_Screen", selectedFile13);
    formData.append("Firetv_Welcome_Screen", selectedFile14);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/welcome-screen-store`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;

        resultapi = response.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setValidationMessagename("");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;
        console.log(resultError);
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setIsValidationHiddenname(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  const splashdelete = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/splash-screen-delete/` +
        id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          props.setApiresponsealert(responseData);
          props.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props.setApiresponsealert(resultError);
            props.setShowalert(true);
          }
        }
      });
  };

  const [itemToDeletesplash, setItemToDeletesplash] = useState(null);
  const [successMessagesplash, setSuccessMessagesplash] = useState("");

  const openModalsplash = (id) => {
    setItemToDeletesplash(id);
    setSuccessMessagesplash("");
  };

  const closeModalsplash = () => {
    setItemToDeletesplash(null);
  };
  const handleDeletedatasplash = () => {
    closeModalsplash();
    if (itemToDeletesplash) {
      splashdelete(itemToDeletesplash);
    }
  };

  const welcomedelete = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/welcome-screen-delete/` +
        id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          props.setApiresponsealert(responseData);
          props.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props.setApiresponsealert(resultError);
            props.setShowalert(true);
          }
        }
      });
  };

  const [itemToDeletewelcome, setItemToDeletewelcome] = useState(null);
  const [successMessagewelcome, setSuccessMessagewelcome] = useState("");

  const openModalwelcome = (id) => {
    setItemToDeletewelcome(id);
    setSuccessMessagewelcome("");
  };

  const closeModalwelcome = () => {
    setItemToDeletewelcome(null);
  };
  const handleDeletedata = () => {
    closeModalwelcome();
    if (itemToDeletewelcome) {
      welcomedelete(itemToDeletewelcome);
    }
  };


  const getFileType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'webp':
      case 'bmp':
      case 'svg':
        return 'image';
      case 'gif':
        return 'image';
      case 'mp4':
        return 'video';
      case 'webm':
        return 'video';
      case 'ogg':
        return 'video';
      case 'm3u8':
        return 'video';
      default:
        return 'application';
    }
  };


  return (
    <>
      <div
        className={`modal ${itemToDeletesplash !== null ? "show" : ""}`}
        tabIndex="-1"
        style={{
          display: itemToDeletesplash !== null ? "block" : "none",
        }}
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModalsplash}
              ></button>
            </div>
            <div className="modal-body">
              {successMessagesplash ? (
                <div className="alert alert-success" role="alert">
                  {successMessagesplash}
                </div>
              ) : (
                <p>Are you sure you want to delete this Splash?</p>
              )}
            </div>
            <div className="modal-footer">
              {successMessagesplash ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closeModalsplash}
                >
                  Close
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModalsplash}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeletedatasplash}
                  >
                    Yes
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="modal fade"
        id="splash"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-name" id="staticBackdropLabel">
                Add Splash Screen
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className=" text-start mt-2">
                <label className="m-0">Splash Image (IOS) </label>

                <div className="mt-2">
                  <div className="col-lg-12 row">
                    <div className=" col-sm-6 col-lg-6">
                      <div
                        className="imagedrop"
                        onClick={() => fileInputRef1.current.click()}
                      >
                        <input
                          type="file"
                          ref={fileInputRef1}
                          onChange={(event) =>
                            handleFileChangesss(
                              event,
                              fileInputRef1,
                              setSelectedFile1
                            )
                          }
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                        <label>
                          <CameraRetroIcon />
                        </label>
                      </div>
                    </div>
                    <div className=" col-sm-6 col-lg-6">
                      <div>
                        {selectedFile1 && (
                          <div className="imagedropcopy text-end">
                            <div className="col-lg-12 row p-0 m-0">
                              <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                <img
                                  src={URL?.createObjectURL(selectedFile1)}
                                  alt="Uploaded"
                                  className="thumbnails-upload-images"
                                />
                              </div>
                              <div
                                className="col-sm-2 col-lg-2 p-0 m-0"
                                style={{ alignSelf: "center" }}
                              >
                                <button
                                  onClick={() => handleDelete(setSelectedFile1)}
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" text-start mt-2">
                <label className="m-0">Splash Image (Andriod)</label>
                <div className="mt-2">
                  <div className="col-lg-12 row">
                    <div className=" col-sm-6 col-lg-6">
                      <div
                        className="imagedrop"
                        onClick={() => fileInputRef2.current.click()}
                      >
                        <input
                          type="file"
                          ref={fileInputRef2}
                          onChange={(event) =>
                            handleFileChangesss(
                              event,
                              fileInputRef2,
                              setSelectedFile2
                            )
                          }
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                        <label>
                          <CameraRetroIcon />
                        </label>
                      </div>
                    </div>
                    <div className=" col-sm-6 col-lg-6">
                      <div>
                        {selectedFile2 && (
                          <div className="imagedropcopy text-end">
                            <div className="col-lg-12 row p-0 m-0">
                              <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                <img
                                  src={URL?.createObjectURL(selectedFile2)}
                                  alt="Uploaded"
                                  className="thumbnails-upload-images"
                                />
                              </div>
                              <div
                                className="col-sm-2 col-lg-2 p-0 m-0"
                                style={{ alignSelf: "center" }}
                              >
                                <button
                                  onClick={() => handleDelete(setSelectedFile2)}
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={savesplash}
              >
                Save Splash Screen
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <div
        className={`modal ${itemToDeletewelcome !== null ? "show" : ""}`}
        tabIndex="-1"
        style={{
          display: itemToDeletewelcome !== null ? "block" : "none",
        }}
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModalwelcome}
              ></button>
            </div>
            <div className="modal-body">
              {successMessagewelcome ? (
                <div className="alert alert-success" role="alert">
                  {successMessagewelcome}
                </div>
              ) : (
                <p>Are you sure you want to delete this Welcome Screen?</p>
              )}
            </div>
            <div className="modal-footer">
              {successMessagewelcome ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closeModalwelcome}
                >
                  Close
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModalwelcome}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeletedata}
                  >
                    Yes
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Mobile App Settings</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  {/* <button onClick={handleUpdate} className="btn btn-primary">
                    Update Settings
                  </button> */}
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12">

              <div className="card p-4">
                <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Splash Screen</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Welcome Screen</button>
                  </li>
                </ul>

                <div className="tab-content p-0" id="pills-tabContent">

                  <div
                    className="modal fade"
                    id="splash"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-name" id="staticBackdropLabel">
                            Add Splash Screen
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className=" text-start mt-2">
                            <label className="m-0">Splash Screen(IOS) </label>

                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className=" col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() => fileInputRef1.current.click()}
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef1}
                                      onChange={(event) =>
                                        handleFileChangesss(
                                          event,
                                          fileInputRef1,
                                          setSelectedFile1
                                        )
                                      }
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    <label>
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile1 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            <img
                                              src={URL?.createObjectURL(selectedFile1)}
                                              alt="Uploaded"
                                              className="thumbnails-upload-images"
                                            />
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() => handleDelete(1)}
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className=" text-start mt-2">
                            <label className="m-0">Splash Screen (Andriod)</label>
                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className=" col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() => fileInputRef2.current.click()}
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef2}
                                      onChange={(event) =>
                                        handleFileChangesss(
                                          event,
                                          fileInputRef2,
                                          setSelectedFile2
                                        )
                                      }
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    <label>
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile2 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            <img
                                              src={URL?.createObjectURL(selectedFile2)}
                                              alt="Uploaded"
                                              className="thumbnails-upload-images"
                                            />
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() => handleDelete(2)}
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" text-start mt-2">
                            <label className="m-0">Splash Screen(Android TV)</label>
                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className=" col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() => fileInputRef4.current.click()}
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef4}
                                      onChange={(event) =>
                                        handleFileChangesss(
                                          event,
                                          fileInputRef4,
                                          setSelectedFile4
                                        )
                                      }
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    <label>
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile4 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            <img
                                              src={URL?.createObjectURL(selectedFile4)}
                                              alt="Uploaded"
                                              className="thumbnails-upload-images"
                                            />
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() => handleDelete(4)}
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" text-start mt-2">
                            <label className="m-0">Splash Screen(LG TV)</label>
                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className=" col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() => fileInputRef5.current.click()}
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef5}
                                      onChange={(event) =>
                                        handleFileChangesss(
                                          event,
                                          fileInputRef5,
                                          setSelectedFile5
                                        )
                                      }
                                      accept="image/*,video/*"
                                      style={{ display: "none" }}
                                    />
                                    <label>
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile5 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            {selectedFile5.type.startsWith('image/') ? (
                                              <img
                                                src={URL.createObjectURL(selectedFile5)}
                                                alt="Uploaded"
                                                className="thumbnails-upload-images"
                                              />
                                            ) : selectedFile5.type.startsWith('video/') ? (
                                              <video
                                                src={URL.createObjectURL(selectedFile5)}
                                                controls
                                                className="thumbnails-upload-images"
                                              />
                                            ) : (
                                              <p>File type not supported for preview</p>
                                            )}
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() => handleDelete(5)}
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" text-start mt-2">
                            <label className="m-0">Splash Screen(ROKU TV)</label>
                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className=" col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() => fileInputRef6.current.click()}
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef6}
                                      onChange={(event) =>
                                        handleFileChangesss(
                                          event,
                                          fileInputRef6,
                                          setSelectedFile6
                                        )
                                      }
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    <label>
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile6 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            <img
                                              src={URL?.createObjectURL(selectedFile6)}
                                              alt="Uploaded"
                                              className="thumbnails-upload-images"
                                            />
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() => handleDelete(6)}
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" text-start mt-2">
                            <label className="m-0">Splash Screen(SAMSUNG TV)</label>
                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className=" col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() => fileInputRef7.current.click()}
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef7}
                                      onChange={(event) =>
                                        handleFileChangesss(
                                          event,
                                          fileInputRef7,
                                          setSelectedFile7
                                        )
                                      }
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    <label>
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile7 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            <img
                                              src={URL?.createObjectURL(selectedFile7)}
                                              alt="Uploaded"
                                              className="thumbnails-upload-images"
                                            />
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() => handleDelete(7)}
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" text-start mt-2">
                            <label className="m-0">Splash Screen (FIRE TV)</label>
                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className=" col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() => fileInputRef8.current.click()}
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef8}
                                      onChange={(event) =>
                                        handleFileChangesss(
                                          event,
                                          fileInputRef8,
                                          setSelectedFile8
                                        )
                                      }
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    <label>
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile8 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            <img
                                              src={URL?.createObjectURL(selectedFile8)}
                                              alt="Uploaded"
                                              className="thumbnails-upload-images"
                                            />
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() => handleDelete(8)}
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          {/* <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button> */}
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={savesplash}
                          >
                            Save Splash Screen
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="col-lg-12 row">
                      <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                        {/* <h5>Splash Screen</h5> */}
                      </div>
                      <div className="col-6 col-sm-6 col-md-6 col-lg-6 text-end">
                        <button
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#splash"
                        >
                          Add Splash
                        </button>
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <div className="p-2 text-start iq-card-body">
                        <Table>
                          <thead>
                            <tr>
                              <th>IOS</th>
                              <th>ANDROID</th>
                              <th>ANDROID TV</th>
                              <th>LG TV</th>
                              <th>ROKU TV</th>
                              <th>SAMSUNG TV</th>
                              <th>FIRE TV</th>
                              <th>ACTION</th>
                            </tr>
                          </thead>
                          <tbody>
                            {splash && splash.length > 0 ? (
                              splash.map((datas, key) => (
                                <tr key={key}>
                                  <td>
                                    <div>

                                      {loading ? (
                                        <Response_Loading_processing />
                                      ) : (
                                        <>
                                          {(() => {
                                            const fileType = getFileType(datas?.splash_image_url);
                                            if (fileType === 'image') {
                                              return (
                                                <img
                                                  src={datas?.splash_image_url}
                                                  alt="Media"
                                                  className="thumbnails-images-mobilesetting"
                                                />
                                              );
                                            } else if (fileType === 'video') {
                                              return (
                                                <video
                                                  src={datas?.splash_image_url}
                                                  controls
                                                  className="thumbnails-video-mobilesetting"
                                                >
                                                </video>
                                              );
                                            } else if (fileType === 'm3u8') {
                                              return (
                                                <video
                                                  src={datas?.splash_image_url}
                                                  controls
                                                  className="thumbnails-video-mobilesetting"
                                                >
                                                </video>
                                              );
                                            } else {
                                              return (
                                                null
                                              );
                                            }
                                          })()}
                                        </>
                                      )}
                                    </div>
                                  </td>

                                  <td>
                                    <div>
                                      {loading ? (
                                        <Response_Loading_processing />
                                      ) : (
                                        <>
                                          {(() => {
                                            const fileType = getFileType(datas?.andriod_splash_image_url);
                                            if (fileType === 'image') {
                                              return (
                                                <img
                                                  src={datas?.andriod_splash_image_url}
                                                  alt="Media"
                                                  className="thumbnails-images-mobilesetting"
                                                />
                                              );
                                            } else if (fileType === 'm3u8') {
                                              return (
                                                <video
                                                  src={datas?.andriod_splash_image_url}
                                                  controls
                                                  className="thumbnails-video-mobilesetting"
                                                >
                                                </video>
                                              );
                                            } else if (fileType === 'video') {
                                              return (
                                                <video
                                                  src={datas?.andriod_splash_image_url}
                                                  controls
                                                  className="thumbnails-video-mobilesetting"
                                                >
                                                </video>
                                              );
                                            } else {
                                              return (
                                                null
                                              );
                                            }
                                          })()}
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      {loading ? (
                                        <Response_Loading_processing></Response_Loading_processing>
                                      ) : (
                                        <>
                                          {(() => {
                                            const fileType = getFileType(datas?.AndroidTv_splash_screen_url);
                                            if (fileType === 'image') {
                                              return (
                                                <img
                                                  src={datas?.AndroidTv_splash_screen_url}
                                                  alt="Media"
                                                  className="thumbnails-images-mobilesetting"
                                                />
                                              );
                                            } else if (fileType === 'video') {
                                              return (
                                                <video
                                                  src={datas?.AndroidTv_splash_screen_url}
                                                  controls
                                                  className="thumbnails-video-mobilesetting"
                                                >
                                                </video>
                                              );
                                            }
                                            else if (fileType === 'm3u8') {
                                              return (
                                                <video
                                                  src={datas?.AndroidTv_splash_screen_url}
                                                  controls
                                                  className="thumbnails-video-mobilesetting"
                                                >
                                                </video>
                                              );
                                            } else {
                                              return (
                                                null
                                              );
                                            }
                                          })()}
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      {loading ? (
                                        <Response_Loading_processing></Response_Loading_processing>
                                      ) : (

                                        <>
                                          {(() => {
                                            const fileType = getFileType(datas?.LG_splash_screen_url);
                                            if (fileType === 'image') {
                                              return (
                                                <img
                                                  src={datas?.LG_splash_screen_url}
                                                  alt="Media"
                                                  className="thumbnails-images-mobilesetting"
                                                />
                                              );
                                            } else if (fileType === 'video') {
                                              return (
                                                <video
                                                  src={datas?.LG_splash_screen_url}
                                                  controls
                                                  className="thumbnails-video-mobilesetting"
                                                >
                                                </video>
                                              );
                                            } else if (fileType === 'm3u8') {
                                              return (
                                                <video
                                                  src={datas?.LG_splash_screen_url}
                                                  controls
                                                  className="thumbnails-video-mobilesetting"
                                                >
                                                </video>
                                              );
                                            } else {
                                              return (
                                                null
                                              );
                                            }
                                          })()}
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      {loading ? (
                                        <Response_Loading_processing></Response_Loading_processing>
                                      ) : (
                                        <>
                                          {(() => {
                                            const fileType = getFileType(datas?.RokuTV_splash_screen_url);
                                            if (fileType === 'image') {
                                              return (
                                                <img
                                                  src={datas?.RokuTV_splash_screen_url}
                                                  alt="Media"
                                                  className="thumbnails-images-mobilesetting"
                                                />
                                              );
                                            } else if (fileType === 'video') {
                                              return (
                                                <video
                                                  src={datas?.RokuTV_splash_screen_url}
                                                  controls
                                                  className="thumbnails-video-mobilesetting"
                                                >
                                                </video>
                                              );
                                            } else if (fileType === 'm3u8') {
                                              return (
                                                <video
                                                  src={datas?.RokuTV_splash_screen_url}
                                                  controls
                                                  className="thumbnails-video-mobilesetting"
                                                >
                                                </video>
                                              );
                                            } else {
                                              return (
                                                null
                                              );
                                            }
                                          })()}
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      {loading ? (
                                        <Response_Loading_processing></Response_Loading_processing>
                                      ) : (
                                        <>
                                          {(() => {
                                            const fileType = getFileType(datas?.Samsung_splash_screen_url);
                                            if (fileType === 'image') {
                                              return (
                                                <img
                                                  src={datas?.Samsung_splash_screen_url}
                                                  alt="Media"
                                                  className="thumbnails-images-mobilesetting"
                                                />
                                              );
                                            } else if (fileType === 'video') {
                                              return (
                                                <video
                                                  src={datas?.Samsung_splash_screen_url}
                                                  controls
                                                  className="thumbnails-video-mobilesetting"
                                                >
                                                </video>
                                              );
                                            } else if (fileType === 'm3u8') {
                                              return (
                                                <video
                                                  src={datas?.Samsung_splash_screen_url}
                                                  controls
                                                  className="thumbnails-video-mobilesetting"
                                                >
                                                </video>
                                              );
                                            } else {
                                              return (
                                                null
                                              );
                                            }
                                          })()}
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      {loading ? (
                                        <Response_Loading_processing></Response_Loading_processing>
                                      ) : (
                                        <>
                                          {(() => {
                                            const fileType = getFileType(datas?.Firetv_splash_screen_url);
                                            if (fileType === 'image') {
                                              return (
                                                <img
                                                  src={datas?.Firetv_splash_screen_url}
                                                  alt="Media"
                                                  className="thumbnails-images-mobilesetting"
                                                />
                                              );
                                            } else if (fileType === 'video') {
                                              return (
                                                <video
                                                  src={datas?.Firetv_splash_screen_url}
                                                  controls
                                                  className="thumbnails-video-mobilesetting"
                                                >
                                                </video>
                                              );
                                            } else if (fileType === 'm3u8') {
                                              return (
                                                <video
                                                  src={datas?.Firetv_splash_screen_url}
                                                  controls
                                                  className="thumbnails-video-mobilesetting"
                                                >
                                                </video>
                                              );
                                            } else {
                                              return (
                                                null
                                              );
                                            }
                                          })()}
                                        </>
                                      )}
                                    </div>
                                  </td>


                                  <td>
                                    <div className="editdropdown">
                                      <span className="editdropdown-button">
                                        <span>
                                          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </span>
                                      </span>
                                      <div className="editdropdown-menu">
                                        <Link
                                          to={"/mobileapp/splash/edit/" + datas?.id}
                                          className="edit"
                                        >
                                          <span>
                                            <img
                                              src={edititem}
                                              alt="flogo"
                                              width={20}
                                              height={20}
                                            />
                                            <span className="ms-2">Edit Splash</span>
                                          </span>
                                        </Link>
                                        <Link to="" className="delete">
                                          <span onClick={() => openModalsplash(datas?.id)}>
                                            <img
                                              src={deleteitem}
                                              alt="flogo"
                                              width={20}
                                              height={20}
                                            />
                                            <span className="ms-2">Delete Splash</span>
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="8" style={{ textAlign: 'center' }} className="nodata-available">
                                  No Splash Screen available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>

                      </div>

                    </div>
                  </div>



                  <div
                    className="modal fade"
                    id="welcome"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-name" id="staticBackdropLabel">
                            Add Welcome Screen
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className=" text-start">
                            <label className="m-0">
                              Title<span className="mandatory">*</span>
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                id="name"
                                name="name"
                                className="rs-input form-control-lg"
                                onChange={handleInput}
                                value={editUser?.name}
                                placeholder="Title"
                                ref={nameInputRef}
                              />
                            </div>
                            <span className="errorred">
                              {!isValidationHiddenname && (
                                <p>{validationMessagename}</p>
                              )}
                            </span>
                          </div>

                          <div className=" text-start mt-2">
                            <label className="m-0">Welcome Images</label>

                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className=" col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() => fileInputRef3.current.click()}
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef3}
                                      onChange={(event) =>
                                        handleFileChangesss(
                                          event,
                                          fileInputRef3,
                                          setSelectedFile3
                                        )
                                      }
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    <label>
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile3 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            <img
                                              src={URL?.createObjectURL(
                                                selectedFile3
                                              )}
                                              alt="Uploaded"
                                              className="thumbnails-upload-images"
                                            />
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() => handleDelete(3)}
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className=" text-start mt-2">
                            <label className="m-0">Splash Screen (Andriod)</label>
                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className=" col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() => fileInputRef9.current.click()}
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef9}
                                      onChange={(event) =>
                                        handleFileChangesss(
                                          event,
                                          fileInputRef9,
                                          setSelectedFile9
                                        )
                                      }
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    <label>
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile9 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            <img
                                              src={URL?.createObjectURL(selectedFile9)}
                                              alt="Uploaded"
                                              className="thumbnails-upload-images"
                                            />
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() => handleDelete(9)}
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" text-start mt-2">
                            <label className="m-0">Splash Screen(Android TV)</label>
                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className=" col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() => fileInputRef10.current.click()}
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef10}
                                      onChange={(event) =>
                                        handleFileChangesss(
                                          event,
                                          fileInputRef10,
                                          setSelectedFile10
                                        )
                                      }
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    <label>
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile10 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            <img
                                              src={URL?.createObjectURL(selectedFile10)}
                                              alt="Uploaded"
                                              className="thumbnails-upload-images"
                                            />
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() => handleDelete(10)}
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" text-start mt-2">
                            <label className="m-0">Splash Screen(LG TV)</label>
                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className=" col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() => fileInputRef11.current.click()}
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef11}
                                      onChange={(event) =>
                                        handleFileChangesss(
                                          event,
                                          fileInputRef11,
                                          setSelectedFile11
                                        )
                                      }
                                      accept="image/*,video/*"
                                      style={{ display: "none" }}
                                    />
                                    <label>
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile11 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            {selectedFile11.type.startsWith('image/') ? (
                                              <img
                                                src={URL.createObjectURL(selectedFile11)}
                                                alt="Uploaded"
                                                className="thumbnails-upload-images"
                                              />
                                            ) : selectedFile11.type.startsWith('video/') ? (
                                              <video
                                                src={URL.createObjectURL(selectedFile11)}
                                                controls
                                                className="thumbnails-upload-images"
                                              />
                                            ) : (
                                              <p>File type not supported for preview</p>
                                            )}
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() => handleDelete(11)}
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className=" text-start mt-2">
                            <label className="m-0">Splash Screen(ROKU TV)</label>
                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className=" col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() => fileInputRef12.current.click()}
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef12}
                                      onChange={(event) =>
                                        handleFileChangesss(
                                          event,
                                          fileInputRef12,
                                          setSelectedFile12
                                        )
                                      }
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    <label>
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile12 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            <img
                                              src={URL?.createObjectURL(selectedFile12)}
                                              alt="Uploaded"
                                              className="thumbnails-upload-images"
                                            />
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() => handleDelete(12)}
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" text-start mt-2">
                            <label className="m-0">Splash Screen(SAMSUNG TV)</label>
                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className=" col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() => fileInputRef13.current.click()}
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef13}
                                      onChange={(event) =>
                                        handleFileChangesss(
                                          event,
                                          fileInputRef13,
                                          setSelectedFile13
                                        )
                                      }
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    <label>
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile13 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            <img
                                              src={URL?.createObjectURL(selectedFile13)}
                                              alt="Uploaded"
                                              className="thumbnails-upload-images"
                                            />
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() => handleDelete(13)}
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" text-start mt-2">
                            <label className="m-0">Splash Screen (FIRE TV)</label>
                            <div className="mt-2">
                              <div className="col-lg-12 row">
                                <div className=" col-sm-6 col-lg-6">
                                  <div
                                    className="imagedrop"
                                    onClick={() => fileInputRef14.current.click()}
                                  >
                                    <input
                                      type="file"
                                      ref={fileInputRef14}
                                      onChange={(event) =>
                                        handleFileChangesss(
                                          event,
                                          fileInputRef14,
                                          setSelectedFile14
                                        )
                                      }
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    <label>
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>
                                <div className=" col-sm-6 col-lg-6">
                                  <div>
                                    {selectedFile14 && (
                                      <div className="imagedropcopy text-end">
                                        <div className="col-lg-12 row p-0 m-0">
                                          <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                            <img
                                              src={URL?.createObjectURL(selectedFile14)}
                                              alt="Uploaded"
                                              className="thumbnails-upload-images"
                                            />
                                          </div>
                                          <div
                                            className="col-sm-2 col-lg-2 p-0 m-0"
                                            style={{ alignSelf: "center" }}
                                          >
                                            <button
                                              onClick={() => handleDelete(14)}
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={savewelcome}
                          >
                            Save Welcome Screen
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div className="">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                          {/* <h5>Welcome Screen</h5> */}
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 text-end">
                          <button
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#welcome"
                          >
                            Add Welcome Screen
                          </button>
                        </div>
                      </div>

                      <div className=" text-start mt-2">
                        <div className="p-2 text-start iq-card-body">
                          <Table>
                            <thead>
                              <tr>
                                <th>NAME</th>
                                <th>WELCOME SCREEN</th>
                                <th>ANDROID TV</th>
                                <th>LG TV</th>
                                <th>ROKU TV</th>
                                <th>SAMSUNG TV</th>
                                <th>FIRE TV</th>
                                <th>ACTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data && data.length > 0 ? (
                                data.map((datas, key) => (
                                  <tr key={key}>
                                    <td>
                                      <div>{datas?.name}</div>
                                    </td>
                                    <td>
                                      <div>
                                        {loading ? (
                                          <Response_Loading_processing></Response_Loading_processing>
                                        ) : (
                                          <>
                                            {(() => {
                                              const fileType = getFileType(datas?.welcome_images_url);
                                              if (fileType === 'image') {
                                                return (
                                                  <img
                                                    src={datas?.welcome_images_url}
                                                    alt="Media"
                                                    className="thumbnails-images-mobilesetting"
                                                  />
                                                );
                                              } else if (fileType === 'video') {
                                                return (
                                                  <video
                                                    src={datas?.welcome_images_url}
                                                    controls
                                                    className="thumbnails-video-mobilesetting"
                                                  >
                                                  </video>
                                                );
                                              } else if (fileType === 'm3u8') {
                                                return (
                                                  <video
                                                    src={datas?.welcome_images_url}
                                                    controls
                                                    className="thumbnails-video-mobilesetting"
                                                  >
                                                  </video>
                                                );
                                              } else {
                                                return (
                                                  null
                                                );
                                              }
                                            })()}
                                          </>)}
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        {loading ? (
                                          <Response_Loading_processing></Response_Loading_processing>
                                        ) : (
                                          <>
                                            {(() => {
                                              const fileType = getFileType(datas?.andriod_Welcome_Screen_url);
                                              if (fileType === 'image') {
                                                return (
                                                  <img
                                                    src={datas?.andriod_Welcome_Screen_url}
                                                    alt="Media"
                                                    className="thumbnails-images-mobilesetting"
                                                  />
                                                );
                                              } else if (fileType === 'video') {
                                                return (
                                                  <video
                                                    src={datas?.andriod_Welcome_Screen_url}
                                                    controls
                                                    className="thumbnails-video-mobilesetting"
                                                  >
                                                  </video>
                                                );
                                              } else if (fileType === 'm3u8') {
                                                return (
                                                  <video
                                                    src={datas?.andriod_Welcome_Screen_url}
                                                    controls
                                                    className="thumbnails-video-mobilesetting"
                                                  >
                                                  </video>
                                                );
                                              } else {
                                                return (
                                                  null
                                                );
                                              }
                                            })()}
                                          </>

                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        {loading ? (
                                          <Response_Loading_processing></Response_Loading_processing>
                                        ) : (

                                          <>
                                            {(() => {
                                              const fileType = getFileType(datas?.AndroidTv_Welcome_Screen_url);
                                              if (fileType === 'image') {
                                                return (
                                                  <img
                                                    src={datas?.AndroidTv_Welcome_Screen_url}
                                                    alt="Media"
                                                    className="thumbnails-images-mobilesetting"
                                                  />
                                                );
                                              } else if (fileType === 'video') {
                                                return (
                                                  <video
                                                    src={datas?.AndroidTv_Welcome_Screen_url}
                                                    controls
                                                    className="thumbnails-video-mobilesetting"
                                                  >
                                                  </video>
                                                );
                                              } else if (fileType === 'm3u8') {
                                                return (
                                                  <video
                                                    src={datas?.AndroidTv_Welcome_Screen_url}
                                                    controls
                                                    className="thumbnails-video-mobilesetting"
                                                  >
                                                  </video>
                                                );
                                              } else {
                                                return (
                                                  null
                                                );
                                              }
                                            })()}
                                          </>

                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        {loading ? (
                                          <Response_Loading_processing></Response_Loading_processing>
                                        ) : (

                                          <>
                                            {(() => {
                                              const fileType = getFileType(datas?.LG_Welcome_Screen_url);
                                              if (fileType === 'image') {
                                                return (
                                                  <img
                                                    src={datas?.LG_Welcome_Screen_url}
                                                    alt="Media"
                                                    className="thumbnails-images-mobilesetting"
                                                  />
                                                );
                                              } else if (fileType === 'video') {
                                                return (
                                                  <video
                                                    src={datas?.LG_Welcome_Screen_url}
                                                    controls
                                                    className="thumbnails-video-mobilesetting"
                                                  >
                                                  </video>
                                                );
                                              } else if (fileType === 'm3u8') {
                                                return (
                                                  <video
                                                    src={datas?.LG_Welcome_Screen_url}
                                                    controls
                                                    className="thumbnails-video-mobilesetting"
                                                  >
                                                  </video>
                                                );
                                              } else {
                                                return (
                                                  null
                                                );
                                              }
                                            })()}
                                          </>



                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        {loading ? (
                                          <Response_Loading_processing></Response_Loading_processing>
                                        ) : (


                                          <>
                                            {(() => {
                                              const fileType = getFileType(datas?.RokuTV_Welcome_Screen_url);
                                              if (fileType === 'image') {
                                                return (
                                                  <img
                                                    src={datas?.RokuTV_Welcome_Screen_url}
                                                    alt="Media"
                                                    className="thumbnails-images-mobilesetting"
                                                  />
                                                );
                                              } else if (fileType === 'video') {
                                                return (
                                                  <video
                                                    src={datas?.RokuTV_Welcome_Screen_url}
                                                    controls
                                                    className="thumbnails-video-mobilesetting"
                                                  >
                                                  </video>
                                                );
                                              } else if (fileType === 'm3u8') {
                                                return (
                                                  <video
                                                    src={datas?.RokuTV_Welcome_Screen_url}
                                                    controls
                                                    className="thumbnails-video-mobilesetting"
                                                  >
                                                  </video>
                                                );
                                              } else {
                                                return (
                                                  null
                                                );
                                              }
                                            })()}
                                          </>


                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        {loading ? (
                                          <Response_Loading_processing></Response_Loading_processing>
                                        ) : (

                                          <>
                                            {(() => {
                                              const fileType = getFileType(datas?.Samsung_Welcome_Screen_url);
                                              if (fileType === 'image') {
                                                return (
                                                  <img
                                                    src={datas?.Samsung_Welcome_Screen_url}
                                                    alt="Media"
                                                    className="thumbnails-images-mobilesetting"
                                                  />
                                                );
                                              } else if (fileType === 'video') {
                                                return (
                                                  <video
                                                    src={datas?.Samsung_Welcome_Screen_url}
                                                    controls
                                                    className="thumbnails-video-mobilesetting"
                                                  >
                                                  </video>
                                                );
                                              } else if (fileType === 'm3u8') {
                                                return (
                                                  <video
                                                    src={datas?.Samsung_Welcome_Screen_url}
                                                    controls
                                                    className="thumbnails-video-mobilesetting"
                                                  >
                                                  </video>
                                                );
                                              } else {
                                                return (
                                                  null
                                                );
                                              }
                                            })()}
                                          </>


                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        {loading ? (
                                          <Response_Loading_processing></Response_Loading_processing>
                                        ) : (

                                          <>
                                            {(() => {
                                              const fileType = getFileType(datas?.Firetv_Welcome_Screen_url);
                                              if (fileType === 'image') {
                                                return (
                                                  <img
                                                    src={datas?.Firetv_Welcome_Screen_url}
                                                    alt="Media"
                                                    className="thumbnails-images-mobilesetting"
                                                  />
                                                );
                                              } else if (fileType === 'video') {
                                                return (
                                                  <video
                                                    src={datas?.Firetv_Welcome_Screen_url}
                                                    controls
                                                    className="thumbnails-video-mobilesetting"
                                                  >
                                                  </video>
                                                );
                                              } else if (fileType === 'm3u8') {
                                                return (
                                                  <video
                                                    src={datas?.Firetv_Welcome_Screen_url}
                                                    controls
                                                    className="thumbnails-video-mobilesetting"
                                                  >
                                                  </video>
                                                );
                                              } else {
                                                return (
                                                  null
                                                );
                                              }
                                            })()}
                                          </>
                                        )}
                                      </div>
                                    </td>


                                    <td>
                                      <div className="editdropdown">
                                        <span className="editdropdown-button">
                                          <span>
                                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                          </span>
                                        </span>
                                        <div className="editdropdown-menu">
                                          <Link
                                            to={"/mobileapp/edit/" + datas?.id}
                                            className="edit"
                                          >
                                            <span>
                                              <img
                                                src={edititem}
                                                alt="flogo"
                                                width={20}
                                                height={20}
                                              />
                                              <span className="ms-2">Edit Welcome</span>
                                            </span>
                                          </Link>
                                          <Link to="" className="delete">
                                            <span
                                              onClick={() =>
                                                openModalwelcome(datas?.id)
                                              }
                                            >
                                              <img
                                                src={deleteitem}
                                                alt="flogo"
                                                width={20}
                                                height={20}
                                              />
                                              <span className="ms-2">
                                                Delete Welcome
                                              </span>
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="8" style={{ textAlign: 'center' }} className="nodata-available">
                                    No Welcome Screen available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>

                          {/* <Table>
                            <th>NAME</th>
                            <th>WELCOME SCREEN</th>
                            <th>ACTION</th>

                            {data?.map((datas) => (
                              <tbody>
                                <td>
                                  <div>{datas?.name}</div>
                                </td>

                                <td>
                                  <div>
                                    {" "}
                                    {loading ? (
                                      <Response_Loading_processing></Response_Loading_processing>
                                    ) : (
                                      <img
                                        src={datas?.welcome_images_url}
                                        alt="Image"
                                        className="thumbnails-images-mobilesetting"
                                      />
                                    )}{" "}
                                  </div>
                                </td>

                                <td>
                                  <div className="editdropdown">
                                    <span className="editdropdown-button">
                                      <span>
                                        <i
                                          className="fa fa-ellipsis-v"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </span>
                                    <div className="editdropdown-menu">
                                      <Link
                                        to={"/mobileapp/edit/" + datas?.id}
                                        className="edit"
                                      >
                                        <span>
                                          <img
                                            src={edititem}
                                            alt="flogo"
                                            width={20}
                                            height={20}
                                          />
                                          <span className="ms-2">Edit Welcome</span>
                                        </span>
                                      </Link>
                                      <Link to="" className="delete">
                                        <span
                                          onClick={() =>
                                            openModalwelcome(datas?.id)
                                          }
                                        >
                                          <img
                                            src={deleteitem}
                                            alt="flogo"
                                            width={20}
                                            height={20}
                                          />
                                          <span className="ms-2">
                                            Delete Welcome
                                          </span>
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                              </tbody>
                            ))}
                          </Table> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>




              {/* <div className="col-sm-6 col-md-6 col-lg-6">

                <div className="card p-3">
                  <div className="col-lg-12 row">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                      <h5>Splash Screen</h5>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 text-end">
                      <button
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#splash"
                      >
                        Add Splash
                      </button>
                    </div>
                  </div>
                  <hr />

                  <div className=" text-start mt-2">
                    <div className="p-2 text-start iq-card-body">
                      <Table>
                        <th>IOS</th>
                        <th>ANDROID</th>
                        <th>ACTION</th>

                        {splash?.map((datas, key) => (
                          <tbody>
                            <td>
                              <div>
                                {" "}
                                {loading ? (
                                  <Response_Loading_processing></Response_Loading_processing>
                                ) : (
                                  <img
                                    src={datas?.splash_image_url}
                                    alt="Image"
                                    className="thumbnails-images-mobilesetting"
                                  />
                                )}{" "}
                              </div>
                            </td>

                            <td>
                              <div>
                                {" "}
                                {loading ? (
                                  <Response_Loading_processing></Response_Loading_processing>
                                ) : (
                                  <img
                                    src={datas?.andriod_splash_image_url}
                                    alt="Image"
                                    className="thumbnails-images-mobilesetting"
                                  />
                                )}{" "}
                              </div>
                            </td>

                            <td>
                              <div className="editdropdown">
                                <span className="editdropdown-button">
                                  <span>
                                    <i
                                      className="fa fa-ellipsis-v"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </span>
                                <div className="editdropdown-menu">
                                  <Link
                                    to={
                                      "/mobileapp/splash/edit/" +
                                      datas?.id
                                    }
                                    className="edit"
                                  >
                                    <span>
                                      <img
                                        src={edititem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">Edit Splash</span>
                                    </span>
                                  </Link>
                                  <Link to="" className="delete">
                                    <span
                                      onClick={() => openModalsplash(datas?.id)}
                                    >
                                      <img
                                        src={deleteitem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">
                                        Delete Splash
                                      </span>
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tbody>
                        ))}
                      </Table>
                    </div>

                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="welcome"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-name" id="staticBackdropLabel">
                        Add Welcome Screen
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className=" text-start">
                        <label className="m-0">
                          Title<span className="mandatory">*</span>
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            value={editUser?.name}
                            placeholder="Title"
                            ref={nameInputRef}
                          />
                        </div>
                        <span className="errorred">
                          {!isValidationHiddenname && (
                            <p>{validationMessagename}</p>
                          )}
                        </span>
                      </div>

                      <div className=" text-start mt-2">
                        <label className="m-0">Welcome Images</label>

                        <div className="mt-2">
                          <div className="col-lg-12 row">
                            <div className=" col-sm-6 col-lg-6">
                              <div
                                className="imagedrop"
                                onClick={() => fileInputRef3.current.click()}
                              >
                                <input
                                  type="file"
                                  ref={fileInputRef3}
                                  onChange={(event) =>
                                    handleFileChangesss(
                                      event,
                                      fileInputRef3,
                                      setSelectedFile3
                                    )
                                  }
                                  accept="image/*"
                                  style={{ display: "none" }}
                                />
                                <label>
                                  <CameraRetroIcon />
                                </label>
                              </div>
                            </div>
                            <div className=" col-sm-6 col-lg-6">
                              <div>
                                {selectedFile3 && (
                                  <div className="imagedropcopy text-end">
                                    <div className="col-lg-12 row p-0 m-0">
                                      <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                        <img
                                          src={URL?.createObjectURL(
                                            selectedFile3
                                          )}
                                          alt="Uploaded"
                                          className="thumbnails-upload-images"
                                        />
                                      </div>
                                      <div
                                        className="col-sm-2 col-lg-2 p-0 m-0"
                                        style={{ alignSelf: "center" }}
                                      >
                                        <button
                                          onClick={() =>
                                            handleDelete(setSelectedFile3)
                                          }
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={savewelcome}
                      >
                        Save Welcome Screen
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <div className="col-lg-12 row">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                      <h5>Welcome Screen</h5>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 text-end">
                      <button
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#welcome"
                      >
                        Add Welcome
                      </button>
                    </div>
                  </div>

                  <hr />

                  <div className=" text-start mt-2">
                    <div className="p-2 text-start iq-card-body">
                      <Table>
                        <th>NAME</th>
                        <th>WELCOME SCREEN</th>
                        <th>ACTION</th>

                        {data?.map((datas) => (
                          <tbody>
                            <td>
                              <div>{datas?.name}</div>
                            </td>

                            <td>
                              <div>
                                {" "}
                                {loading ? (
                                  <Response_Loading_processing></Response_Loading_processing>
                                ) : (
                                  <img
                                    src={datas?.welcome_images_url}
                                    alt="Image"
                                    className="thumbnails-images-mobilesetting"
                                  />
                                )}{" "}
                              </div>
                            </td>

                            <td>
                              <div className="editdropdown">
                                <span className="editdropdown-button">
                                  <span>
                                    <i
                                      className="fa fa-ellipsis-v"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </span>
                                <div className="editdropdown-menu">
                                  <Link
                                    to={"/mobileapp/edit/" + datas?.id}
                                    className="edit"
                                  >
                                    <span>
                                      <img
                                        src={edititem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">Edit Welcome</span>
                                    </span>
                                  </Link>
                                  <Link to="" className="delete">
                                    <span
                                      onClick={() =>
                                        openModalwelcome(datas?.id)
                                      }
                                    >
                                      <img
                                        src={deleteitem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">
                                        Delete Welcome
                                      </span>
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tbody>
                        ))}
                      </Table>
                    </div>


                  </div>
                </div>
              </div> */}
            </div>
          </section>
        </div>
      </div>


    </>
  );
}

export default MobileSettings;
