import React, { useEffect, useState } from "react";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import axios from "axios";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function Player(props) {
  const [playerData, setPlayerData] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const fetchEdit = () => {
    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/playerui/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.player_ui_settings[0];
        setPlayerData(result);
      })
      .catch((error) => {
        console.error("Error fetching data from the  API:", error);
      });
  };

  useEffect(async () => {
    fetchEdit();
  }, []);
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const handleToggle = (e) => {
    const name = e.target.name;
    setPlayerData((prevState) => {
      return {
        ...prevState,
        [name]: prevState[name] === 0 || prevState[name] === null ? 1 : 0,
      };
    });
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setPlayerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile?.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setFileName(newFile);
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(newFile);
    }
  };
  const handleClearFile = () => {
    setFileName(null);
    setPreviewImage(null);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let Status = false;
    try {
      setProcessing(true);
      setShowOverlay(true);
      const formDatas = new FormData();
      for (const key in playerData) {
        formDatas.append(key, playerData[key]);
      }
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/playerui/store`,
        formDatas,
        { headers }
      );
      let result = response?.data;
      if (result?.status) {
        let formDataForImage = new FormData();
        formDataForImage.append("playerui_id", result?.PlayerUI_ID);
        formDataForImage.append("image", fileName);
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/playerui/image-upload`,
          formDataForImage,
          { headers }
        );
        const imageResult = imageResponse?.data;
        if (imageResult?.status && result?.status) {
          Status = true;
        }
        if (Status) {
          props?.setApiresponsealert(result);
          props?.setShowalert(true);
          setProcessing(false);
          setShowOverlay(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } else {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    } catch (err) {
      setProcessing(false);
      setShowOverlay(false);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
    }
  };

  return (
    <>
      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>
      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="row pb-3">
            <div className="col-lg-6 col-8">
              {" "}
              <h4 className="">Player Settings</h4>
            </div>
            <div className="col-lg-6 col-4">
              {" "}
              <button
                type="submit"
                className="btn btn-primary d-block ms-auto fw-bolder"
                onClick={handleSubmit}
              >
                Update Player
              </button>
            </div>
          </div>
          <form id="playerForm" className="p-0">
            <div className="row ">
              <div className="col-lg-6 col-md-6">
                <div className="card p-3">
                  <h5>Video Player Watermark Settings</h5>
                  <hr></hr>

                  <div className="mb-2">
                    <label>Top:</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        name="watermark_top"
                        id="watermark_top"
                        onChange={handleInputChange}
                        value={playerData?.watermark_top}
                      />
                    </div>
                  </div>
                  <div className="mb-2">
                    <label> Bottom:</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        name="watermark_bottom"
                        id="watermark_bottom"
                        onChange={handleInputChange}
                        value={playerData?.watermark_bottom}
                      />
                    </div>
                  </div>
                  <div className="mb-2">
                    <label>Left:</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        name="watermark_left"
                        id="watermark_left"
                        onChange={handleInputChange}
                        value={playerData?.watermark_left}
                      />
                    </div>
                  </div>
                  <div className="">
                    <label> Right:</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        name="watermark_right"
                        id="watermark_right"
                        onChange={handleInputChange}
                        value={playerData?.watermark_right}
                      />
                    </div>
                  </div>
                </div>
                <div className="card p-3">
                  <div className="d-flex justify-content-between mb-4">
                    <div>
                      <label className="mb-0">Skip intro</label>
                      <p className="p1 col-md-8 p-0">
                        (Allows end users to skip some portion of thr video
                        opening credits)
                      </p>
                    </div>
                    <div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="skip_intro"
                          onChange={handleToggle}
                          checked={playerData?.skip_intro === 1}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <div>
                      <label className="mb-0">
                        Allow Embed player on specfic domians
                      </label>
                      <p className="p1">
                        (Note: Saving without a domain name will restrict your
                        embed player for all the domains.)
                      </p>
                    </div>
                    <div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="embed_player"
                          onChange={handleToggle}
                          checked={playerData?.embed_player === 1}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <div>
                      <label> Show logos on player</label>
                    </div>
                    <div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="show_logo"
                          onChange={handleToggle}
                          checked={playerData?.show_logo === 1}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <div>
                      <label>Add Watermark on the player</label>
                    </div>
                    <div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="watermark"
                          onChange={handleToggle}
                          checked={playerData?.watermark === 1}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <div>
                      <label>Playback speed control</label>
                    </div>
                    <div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="speed_control"
                          onChange={handleToggle}
                          checked={playerData?.speed_control === 1}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="card p-3">
                  <div className="mb-2">
                    <label> Opacity:</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        name="watermark_opacity"
                        id="watermark_opacity"
                        onChange={handleInputChange}
                        value={playerData?.watermark_opacity}
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <label> Link:</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        name="watermar_link"
                        id="watermar_link"
                        onChange={handleInputChange}
                        value={playerData?.watermar_link}
                      />
                    </div>
                  </div>

                  <div className="">
                    <label> Width:</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        name="watermar_width"
                        id="watermar_width"
                        onChange={handleInputChange}
                        value={playerData?.watermar_width}
                      />
                    </div>
                  </div>
                </div>
                <div className="card p-3">
                  <h5
                    for="picture"
                    className=" col-form-label text-md-right p-0"
                  >
                    Upload Watermark:
                  </h5>
                  <hr></hr>
                  <img src={playerData?.image} className="editFormImage" />
                  <div className="d-flex flex-wrap justify-content-between gap-2">
                    <div className="col-sm-5 col-md-12 col-lg-5 col-12 position-relative pb-2">
                      <div className="imagedrop  mt-1 p-1">
                        <input
                          id="watermark_logo"
                          type="file"
                          className="rs-input form-control-lg opacity-0 h-100 p-0 m-0 "
                          name="watermark_logo"
                          accept="image/*"
                          onChange={(event) => handleFileChange(event)}
                          key={fileName ? fileName : "inputKey"}
                        />
                        <label type="button" className="position-absolute">
                          <CameraRetroIcon />
                        </label>
                      </div>
                    </div>
                    {fileName && (
                      <div className="col-sm-5 col-12 col-md-12 col-lg-5">
                        <div
                          className="imagedrop  dropzone mt-1 p-1"
                          style={{
                            backgroundImage: `url(${previewImage && previewImage
                              })`,
                          }}
                        >
                          {fileName && (
                            <button
                              type="button"
                              className="btn btn-close"
                              onClick={handleClearFile}
                            ></button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="card p-3">
                  <h5>Subtitle option</h5>
                  <hr></hr>
                  <div className="d-flex justify-content-between mb-4">
                    <div>
                      <label>Subtitle on as Default</label>
                    </div>
                    <div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="subtitle"
                          onChange={handleToggle}
                          checked={playerData?.subtitle === 1}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <div>
                      <label>Remeber subtitle preferences</label>
                    </div>
                    <div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="subtitle_preference"
                          onChange={handleToggle}
                          checked={playerData?.subtitle_preference === 1}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary d-block me-auto fw-bolder mb-3"
              onClick={handleSubmit}
            >
              Update Player
            </button>
          </form>
          {/* <div className="iq-card p-2 mt-3">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="d-flex justify-content-between mb-4">
                  <div>
                    <label>Thumbnails on seekbar</label>
                  </div>
                  <div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="thumbnail"
                        onChange={handleToggle}
                        checked={playerData?.thumbnail === 1}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="d-flex justify-content-between mb-4">
                  <div>
                    <label>
                      Advanced player{" "}
                      <span className="span1">
                        (Allows you to change add html code)
                      </span>
                    </label>
                  </div>
                  <div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="advance_player"
                        onChange={handleToggle}
                        checked={playerData?.advance_player === 1}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-4">
                  <div>
                    <label>Enable video cards</label>
                  </div>
                  <div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="video_card"
                        onChange={handleToggle}
                        checked={playerData?.video_card === 1}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default Player;
