// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-container {
  position: relative;
  display: inline-block;
}

.iq-bg-warning {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

.iq-bg-warning .las {
  margin-right: 5px;
}

.tooltip-text {
  position: absolute;
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 1;
  top: 25px;
  left: 0px;
  font-size: 12px;
}

.iq-bg-warning:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Site_Pages/EditLandingPages.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;EACV,oCAAoC;EACpC,UAAU;EACV,SAAS;EACT,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,UAAU;AACZ","sourcesContent":[".tooltip-container {\n  position: relative;\n  display: inline-block;\n}\n\n.iq-bg-warning {\n  position: relative;\n  display: inline-block;\n  text-decoration: none;\n  color: inherit;\n}\n\n.iq-bg-warning .las {\n  margin-right: 5px;\n}\n\n.tooltip-text {\n  position: absolute;\n  background-color: black;\n  color: white;\n  padding: 5px;\n  border-radius: 4px;\n  white-space: nowrap;\n  visibility: hidden;\n  opacity: 0;\n  transition: opacity 0.2s ease-in-out;\n  z-index: 1;\n  top: 25px;\n  left: 0px;\n  font-size: 12px;\n}\n\n.iq-bg-warning:hover .tooltip-text {\n  visibility: visible;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
