// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload {
    margin: 80px auto;
}

.thumbnails-images-mobilesetting {
    width: 50px !important;
    height: 75px !important;
}
.thumbnails-video-mobilesetting {
    width: auto !important;
    height: 75px !important;
}


.nodata-available{
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    align-items: center;
    top: le;
    left: 100vh;
    border-bottom: 0;
    font-size: 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Settings/MobileSettings.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;AACA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;;;AAGA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;IACnB,OAAO;IACP,WAAW;IACX,gBAAgB;IAChB,0BAA0B;AAC9B","sourcesContent":[".upload {\n    margin: 80px auto;\n}\n\n.thumbnails-images-mobilesetting {\n    width: 50px !important;\n    height: 75px !important;\n}\n.thumbnails-video-mobilesetting {\n    width: auto !important;\n    height: 75px !important;\n}\n\n\n.nodata-available{\n    text-align: center;\n    align-items: center;\n    justify-content: center;\n    position: absolute;\n    align-items: center;\n    top: le;\n    left: 100vh;\n    border-bottom: 0;\n    font-size: 16px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
