import React from "react";

const Privacy_policy = () => {
  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid">
          <div id="content-page" className="content-page">
            <div className=" mt-4">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="text-center">
                    <h4>Privacy Policy</h4>
                  </div>

                  <div className="mt-2">
                    <p>
                      If you go through this privacy policy carefully, you will
                      get some clarity on why Flicknexs collects certain
                      personal data from you, and how it is utilized. You will
                      also learn, how you can keep all your data safe and
                      secure, modify or update the same. Flicknexs needs your
                      personal details — Why? When you visit our website and
                      gain some details or purchase certain services, we will
                      ask you some information from you. These may include name,
                      email address, postal address, phone numbers and billing
                      information such as credit card number and billing
                      address. We also ask you for unique identifiers which are
                      things like a user name, an account number and a password.
                      If you are registering on behalf of a company, then you
                      will need to give information related to the company like
                      its name, address and other associated information. Our
                      systems will gather other information like IP address,
                      browser type and operating system, on its own. Now, it’s
                      quite obvious that you may eager to know why we are
                      collecting this information, and some of the reasons are:
                      To respond to requests, queries and other services asked
                      by the clients Maintaining basic administration over your
                      account Ensure constant improvement of our website Tally
                      and collaborate with information gained from third parties
                      We promise you that the information that we take from you,
                      will be utilized only and only for the purpose for which
                      it has been collected. We also ensure you that the given
                      information will not be shared with any outside parties,
                      without your explicit permission. However, we might share
                      your information with such companies, with whom we work in
                      close collaboration with, in order to offer better
                      services and customer care to you. Another situation,
                      wherein we might have to share information would be one,
                      where there are legal aspects involved. Should the court
                      or any special body of law ask us to release the
                      information, we will have to do the same. In addition to
                      that, if Flicknexs is engaged in a merger, sale or
                      acquisition, information given out by clients, might be
                      released. However, the information regarding the same will
                      be sent to you, either via email or telephonic message.
                      Importance of Security At Flicknexs, we understand how
                      important your personal details are, that’s why we pay
                      utmost attention to protecting the same. This is one of
                      the reasons why, we ensure the encryption of super
                      sensitive information like credit card details. With
                      encryption, you can be assured that there can be minimal
                      chances of cyber theft, during transmission. The
                      technology that we use is known as SSL or secure socket
                      layer technology. Apart from this, we practice all the
                      standards procedures to ensure safety of the information
                      provided to us. However, knowing that nothing is
                      completely secure on the internet, we cannot guarantee
                      absolute security of your personal details. We also
                      provide linking to our website via social networking sites
                      like Facebook and Twitter, which allow us to authenticate
                      your information. The option to share information related
                      to user id, name and email addresses is left with you. If
                      you allow the sharing, your network will also know about
                      your activities. With your permission, we will also post
                      your testimonials on our site, allowing us to serve more
                      clients. If you have any questions related to the security
                      on our site, or wish to have your testimonials removed,
                      please feel free to contact us at
                      mailto:support@flicknexs.com In case you wish to have of
                      any your information updated or modified, all you have to
                      do is log into your account. Alternatively, you could also
                      send us a mail at mailto:support@flicknexs.com, and we
                      will make the changes for you. As a standard practice, we
                      do make changes to our privacy policy, as these have to be
                      keeping with the industry specifications. However, if
                      there are any such changes, we will inform via email or by
                      posting a notice on the site itself. If there is anything
                      else you wish to know more about, please feel free to
                      contact us at: If there is anything else you wish to know
                      more about, please feel free to contact us at: Phone:
                      +15155199991 Email: mailto:support@flicknexs.com In case
                      you have questions or grievances in regards with our
                      privacy policy or practices, please do contact us at
                      mailto:support@flicknexs.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Privacy_policy;
