import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import { writerDefaultURL } from "../../../components/CommonApis/CommonArtistApis";
import { adminApiHeader } from "../../../components/CommonApis/CommonApiUrl";
import Wrapper from "../../../components/CommonComponents/Wrapper";
import MessageHeading from "../../../components/CommonComponents/MessageHeading";
import MessageLabel from "../../../components/CommonComponents/MessageLabel";
import { IoClose } from "react-icons/io5";
import MessageSubHeading from "../../../components/CommonComponents/MessageSubHeading";
import RightWrapper from "../../../components/CommonComponents/RightWrapper";
import AdminSaveHeader from "../../../components/CommonComponents/AdminSaveHeader";
import FormContainer from "../../../components/CommonComponents/FormContainer";
import LeftWrapper from "../../../components/CommonComponents/LeftWrapper";
import { toast } from "react-toastify";
import MessageBox from "../../../components/CommonComponents/MessageBox";

function AddPublisher() {
  const [formData, setFormData] = useState({ artist_name: "", artist_slug: "", artist_type: "", artist_email: "", description: "" })
  const [formDataError, setFormDataError] = useState({})
  // const [defaultOption, setDefaultOption] = useState()
  const [coverImageFile, setCoverImageFile] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);

  const titleInputRef = useRef(null);
  const slugInputRef = useRef(null);
  const emailRef = useRef(null)
  const typeRef = useRef(null)
  const coverImageRef = useRef(null)
  const navigate = useNavigate();


  // api start
  // const handleGetCategory = async () => {
  //   try {
  //     const response = await axios.get(`${writerDefaultURL?.createApi}`, { headers: adminApiHeader })
  //     const result = response?.data?.artists;
  //     if (response?.data?.status) {
  //       setDefaultOption(result)
  //     }
  //   }
  //   catch (err) {
  //     console.error(err)
  //   }
  // }

  const handleAdd = async () => {
    setShowOverlay(true);
    try {
      const formDataAdd = new FormData();
      for (const key in formData) {
        formDataAdd.append(key, formData[key]);
      }
      const response = await axios.post(`${writerDefaultURL?.storeApi}`, formDataAdd, { headers: adminApiHeader });
      const result = response?.data;
      if (result?.status) {
        if (coverImageFile) {
          await handleImageUpload(result?.artist_id)
        }
        setShowOverlay(false);
        toast.success(result?.message)
        navigate("/artists-songwriter");
      }
      else {
        setShowOverlay(false);
        toast.error(result?.message)
      }
    }
    catch (err) {
      setShowOverlay(false);
      const errorMessage = err?.response?.data?.message
      toast.error(errorMessage)
      console.error("error", err)
    }
  }

  const handleImageUpload = async (id) => {
    try {
      const formData = new FormData();
      formData.append("artist_id", id);
      formData.append("image", coverImageFile);
      const response = await axios.post(`${writerDefaultURL?.uploadImageApi}`, formData, { headers: adminApiHeader });
      if (!response?.data?.status) {
        toast.error(response?.data?.message)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleStore = async () => {
    if (formValidation()) {
      await handleAdd();
    } else {
      console.log("Form validation failed ");
    }
  }
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const formValidation = () => {
    let formIsValid = true;
    if (!formData?.artist_name) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        artist_name: "Artist Musician cannot be empty.",
      }));
      titleInputRef.current.focus();
      formIsValid = false;
    } else if (!formData?.artist_slug) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        artist_slug: "Slug  Musician cannot be empty.",
      }));
      slugInputRef.current.focus();
      formIsValid = false;
    }
    if (!formData?.artist_email) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        artist_email: "Email cannot be empty.",
      }));
      emailRef.current.focus();
      formIsValid = false;
    } else if (!regex.test(formData?.artist_email)) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        artist_email: "Email format is incorrect.",
      }));
      emailRef.current.focus();
      formIsValid = false;
    }
    // if (!formData?.artist_type) {
    //   setFormDataError((prevFormData) => ({
    //     ...prevFormData,
    //     artist_type: "Type cannot be empty.",
    //   }));
    //   typeRef.current.focus();
    //   formIsValid = false;
    // }
    return formIsValid;
  }

  // api end
  // function start
  const handleBlurtitle = () => {
    generateSlug(formData?.artist_name);
  };

  const generateSlug = (artist_name) => {
    artist_name = artist_name?.trim();
    const slug = artist_name?.toLowerCase().replace(/\s+/g, "-");
    setFormData((prevFormData) => ({
      ...prevFormData,
      artist_slug: slug,
    }));
  };

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(formData?.artist_name);
    }
  };

  const handleAutoFillChangetitle = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      artist_slug: event.target.value,
    }));
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: "",
    }));
  };

  const handleBlurTitleEdit = () => {
    generateSlug(formData?.artist_slug);
  };

  const handleInput = async (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      artist_name: "",
      artist_slug: "",
      artist_email: "",
      artist_type: ""
    }));
  }

  //Handle Image upload
  const handleCoverImageChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setCoverImageFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };
  // function end
  useEffect(() => {
    // handleGetCategory()
  }, []);
  return (
    <>
      {showOverlay && (<Response_Processing />)}
      <AdminSaveHeader heading="Create Publisher / Song Writer" saveText="Add" saveMethod={handleStore} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text="Create Artist" />
            <MessageLabel text="Artist Musician" className="mandatorySimple" />
            <input type="text" name="artist_name" value={formData?.artist_name} onChange={handleInput} placeholder="Artist Musician" ref={titleInputRef} onBlur={handleBlurtitle} onKeyDown={handleInputKeyDowntitle} className="rs-input form-control-lg custom-placeholder  theme-text-color mb-3" />
            {formDataError?.artist_name && (<MessageBox text={`${formDataError?.artist_name}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text='Artist Slug' />
            <input type="text" name="artist_slug" id="artist_slug" value={formData?.artist_slug} onChange={handleAutoFillChangetitle} ref={slugInputRef} onBlur={handleBlurTitleEdit} placeholder="slug" className="mb-3 rs-input form-control-lg custom-placeholder  theme-text-color " />
            {formDataError?.artist_slug && (<MessageBox text={`${formDataError?.artist_slug}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text="Artist Email" className="mandatorySimple" />
            <input type="text" name="artist_email" value={formData?.artist_email} ref={emailRef} onChange={handleInput} placeholder="Email" className="rs-input form-control-lg custom-placeholder  theme-text-color  mb-3" />
            {formDataError?.artist_email && (<MessageBox text={`${formDataError?.artist_email}`} classname='errorred mb-2 d-block' />)}

            {/* <MessageLabel text="Artist Type" className="mandatorySimple" />
          <select id="artist_type" name="artist_type" onChange={handleInput} className="form-select mb-3 custom-placeholder   theme-text-color " ref={typeRef} >
            <option value="0" className="theme-bg-color theme-text-color"> Select artist type</option>
            {defaultOption?.map((item) => (<option value={item.value} className="theme-bg-color theme-text-color">{item.name}</option>))}
          </select>
          {formDataError?.artist_type && (<MessageBox text={`${formDataError?.artist_type}`} classname='errorred mb-2 d-block' />)} */}
          </Wrapper>
        </LeftWrapper>
        <RightWrapper>
          <Wrapper>
            <MessageHeading text='Thumbnails' />
            <MessageLabel text="Image" />
            <MessageSubHeading text="Select the artist image( 9:16 Ratio or 1080X1920px )" />
            <div className="mx-0 my-3 row">
              <div className="col-6 ps-0">
                <div className="imagedrop theme-border-color" >
                  <input type="file" ref={coverImageRef} onChange={(event) => handleCoverImageChange(event)} accept="image/*" className="opacity-0 w-100 h-100" key={coverImageFile ? coverImageFile : "inputKey"} />
                  <label className="theme-text-color position-absolute"><CameraRetroIcon className=" theme-text-color" /> </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {coverImageFile && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0">
                        <div className="col-10 p-0 ">
                          <img src={URL?.createObjectURL(coverImageFile)} alt="Uploaded" className="thumbnails-upload-images" />
                        </div>
                        <div className="col-2 p-0" style={{ alignSelf: "center" }}>
                          <button onClick={() => setCoverImageFile(null)} type="button" className="bg-transparent theme-text-color"><IoClose className="custom-close-btn"></IoClose></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
          <Wrapper>
            <MessageHeading text='Description' />
            <MessageLabel text="Artist Description" />
            <textarea rows="5" type="text" name="description" value={formData?.description} onChange={handleInput} placeholder="Artist Description" className="rs-input form-control-lg custom-placeholder  theme-text-color " />
          </Wrapper>
        </RightWrapper>
      </FormContainer>
    </>
  );
}
export default AddPublisher;