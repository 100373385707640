import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import "./EditVideoschedule.css";
import JoditEditor from "jodit-react";

function EditVideoschedule(props) {
  const handleChangeFirstdes = (e) => {
    setEditUser({ ...editUser, description: e });
  };
  const { id } = useParams();
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const [editUser, setEditUser] = useState({
    name: "",
    slug: "",
    description: "",
    in_home: "",
    image: "",
    player_image: "",
  });
  const [editname, setEditname] = useState({
    name: "",
  });

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const reqData = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/video-schedule/edit/${id}`,
          { headers: headers }
        );
        const resData = await reqData?.json();
        var res = resData?.VideoSchedules[0];
        var resdtat = resData?.VideoSchedules;
        setEditUser(res);
        setEditname(res);
        setData(resdtat);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, [id]);

  // useEffect(async () => {
  //   const getUser = async () => {
  //     const reqData = await fetch(
  //       `${process.env.REACT_APP_Baseurl}/admin/video-schedule/edit/${id}`,
  //       { headers: headers }
  //     );
  //     const resData = await reqData?.json();
  //     var res = resData?.VideoSchedules[0];
  //     var resdtat = resData?.VideoSchedules;
  //     setEditUser(res);
  //     setEditname(res);
  //     setData(resdtat);
  //     setLoading(false);
  //   };
  //   getUser();
  // }, [id]);


  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagename("");
    setIsValidationHiddenname(true);
    setValidationMessagetype("");
    setIsValidationHiddentype(true);
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagename, setValidationMessagename] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);

  const nameInputRef = useRef(null);

  
  const [validationMessagetype, setValidationMessagetype] = useState("");
  const [isValidationHiddentype, setIsValidationHiddentype] = useState(true);

  const typeInputRef = useRef(null);




  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagename("Name cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }
    if (editUser?.type === "") {
      setValidationMessagetype("Type cannot be empty.");
      setIsValidationHiddentype(false);
      focusInputRef = typeInputRef;
    }


    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    e.preventDefault();

    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      name: editUser?.name,
      slug: editUser?.slug,
      type: editUser?.type,
      description: editUser?.description,
      in_home: editUser?.in_home,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resjson = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/video-schedule/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();
        formData.append("VideoSchedule_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          formData.append("selectedFile3", selectedFile3);
        }
        if (selectedFile4) {
          formData.append("selectedFile4", selectedFile4?.name);
        }

        await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-video-scheduless`,
          formData,
          { headers: headers }
        );
        secondApiStatus = true;
      } else if (resjson?.status === false) {
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagename("");
      setValidationMessagetype("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      props.setApiresponsealert(resultapifalse);
      props.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddenname(false);
      setIsValidationHiddentype(false);
    }
  };


  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  // const fileInputRef5 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  // const [selectedFile5, setSelectedFile5] = useState(null);


  const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch(index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      case 3:
        setSelectedFile3(null);
        if (fileInputRef3.current) {
          fileInputRef3.current.value = null;
        }
        break;
      case 4:
        setSelectedFile4(null);
        if (fileInputRef4.current) {
          fileInputRef4.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  const handleLibraryItemClick = (item) => {
    setSelectedFile4(item);
  };

 const datavideotest = [ {
    id: 17,
    name: "https://api.flicknexs.com/storage/app/public/video_uploads/oTYqbhyCoqQH2NKTFqZyDz9tGUrLitApWggGUzLf.mp4",
    // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 17,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/oTYqbhyCoqQH2NKTFqZyDz9tGUrLitApWggGUzLf.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 17,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/oTYqbhyCoqQH2NKTFqZyDz9tGUrLitApWggGUzLf.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 17,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/oTYqbhyCoqQH2NKTFqZyDz9tGUrLitApWggGUzLf.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 17,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/oTYqbhyCoqQH2NKTFqZyDz9tGUrLitApWggGUzLf.mp4",
  // name: "test",
},
{
  id: 20,
  name: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
  // name: "test",
},
{
  id: 17,
    name: "https://api.flicknexs.com/storage/app/public/video_uploads/oTYqbhyCoqQH2NKTFqZyDz9tGUrLitApWggGUzLf.mp4",
    // name: "test",
},
]

  return (
    <>
      <div className=" m-0 p-0">
        <div className="video_schedule_media_tab">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Video Channel</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Video Channel
                  </button>
                </div>
              </div>
            </div>
            
            <div>
            {showOverlay && <Response_Processing></Response_Processing>}
          </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>{editname?.name}</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="name"
                        name="name"
                        placeholder="Enter Name"
                        onChange={handleInput}
                        value={editUser?.name}
                        ref={nameInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenname && (
                        <p>{validationMessagename}</p>
                      )}
                    </span>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Slug</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="name"
                        name="slug"
                        placeholder="Enter Slug"
                        onChange={handleInput}
                        value={editUser?.slug}
                      />
                    </div>
                  </div>

                  

                  <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0">Type<span className="mandatory">*</span></label>

                    <div className="mt-2">
                      <select
                        className="form-select"
                        name="parent_id"
                        id="parent_id"
                        value={editUser?.parent_id}
                        onChange={handleInput}
                        ref={typeInputRef}
                      >
                        {data?.map((item) => (
                          <option value={item?.id}>{item?.name}</option>
                        ))}
                      </select>

                      <span className="errorred">
                        {!isValidationHiddentype && (
                          <p>{validationMessagetype}</p>
                        )}
                      </span>

                    </div>

                    <div className="mt-2 text-start">
                      <label className="m-0">Description</label>

                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input"
                          value={editUser?.description}
                          onChange={(e) => {
                            handleChangeFirstdes(e);
                          }}
                        />
                      </div>
                    </div>

                  </div>
                </div>
                </div>

                <div className="card p-3">
                  <h5>Status Setting</h5>
                  <hr></hr>

                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-description d-flex justify-content-between">
                      <label className="">Display on Home page </label>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="in_home"
                            id="in_home"
                            onChange={handleInputenable}
                            defaultChecked={
                              editUser?.in_home === 1 ? true : false
                            }
                            checked={editUser?.in_home === 1 ? true : false}
                            className="rs-input"
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="in_home"
                            id="in_home"
                            onChange={handleInputenable}
                            value={editUser?.in_home === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Video Channel
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Home Thumbnail</label>
                    <p className="t-1">
                      Select the Home image( 16:9 Ratio or 1280X720px )
                    </p>
                    {/* <img
                      src={editUser?.Video_thumbnail}
                      width="150"
                      height="100"
                    /> */}

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.image}
                          alt="image_all"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                  <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(1)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Player Thumbnail</label>
                    <p className="t-1">
                      Select the Player image( 16:9 Ratio or 1280X720px )
                    </p>

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.player_image}
                          className="thumbnails-images"
                          alt="image_all"
                        />
                      )}
                    </div>

                    <div hidden className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                     onClick={() => handleDelete(2)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(2)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                  
                  </div>
                </div>




     <div className="card p-3">
        <h5>Media</h5>
        <hr />

        <div className="mt-2 text-start">
          <label className="m-0">Filler Media</label>

          <div className="mt-2">
            <div className="col-lg-12 row">
              <div className=" col-sm-6 col-lg-6">
                <div
                  className="imagedrop"
                  data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                >
                  <input
                    type="file"
                    ref={fileInputRef4}
                    onChange={(event) =>
                      handleFileChangesss(
                        event,
                        fileInputRef4,
                        setSelectedFile4
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <button>
                    <AddOutlineIcon />
                  </button>
                </div>
              </div>
              <div className=" col-sm-6 col-lg-6">

              <div>
                          {selectedFile3 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  {/* <img
                                    src={URL?.createObjectURL(selectedFile3)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  /> */}

                          <media-player src={URL?.createObjectURL(selectedFile3)} controls >
                                    <media-outlet></media-outlet>
                                  </media-player>
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() => handleDelete(3)}
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        
                <div>
                  {selectedFile4 && (
                    <div className="imagedropcopy text-end">
                      <div className="col-lg-12 row p-0 m-0">
                        <div className=" col-sm-10 col-lg-10 p-0 m-0">
                            <media-player src={(selectedFile4?.name)} controls>
                              <media-outlet></media-outlet>
                            </media-player>
                        </div>
                        <div
                          className="col-sm-2 col-lg-2 p-0 m-0"
                          style={{ alignSelf: "center" }}
                        >
                          <button
                           onClick={() => handleDelete(4)}
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Upload</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row mt-2 text-start">
                <div className="col-12">
                  <label className="m-0">Type</label>
                  <div className="mt-2">
                    <select
                      className="form-select"
                      name="parent_id"
                      id="parent_id"
                      value={editUser?.parent_id}
                      onChange={handleInput}
                    >
                      {data?.map((item) => (
                        <option key={item?.id} value={item?.id}>{item?.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 mt-2 ">


              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="pills-computers-tab" data-bs-toggle="pill" data-bs-target="#pills-computers" type="button" role="tab" aria-controls="pills-computers" aria-selected="true">Upload From Computer</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="pills-librarys-tab" data-bs-toggle="pill" data-bs-target="#pills-librarys" type="button" role="tab" aria-controls="pills-librarys" aria-selected="false">Choose From Library</button>
  </li>
</ul>
<div className="tab-content" id="pills-tabContent">
  <div className="tab-pane fade show active" id="pills-computers" role="tabpanel" aria-labelledby="pills-computers-tab">
  <div className="col-lg-12 row">
                      <div className=" col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef3.current.click()}
                        >
                          <div data-bs-dismiss="modal" aria-label="Close">
                          <input
                            type="file"
                            ref={fileInputRef3}
                            onChange={(event) =>
                              handleFileChangesss(
                                event,
                                fileInputRef3,
                                setSelectedFile3
                              )
                            }
                            accept="video/*"
                            style={{ display: "none" }}
                          />
                          <label>
                        <CameraRetroIcon />
                      </label>
                        </div>
                      </div>
                      </div>
                      <div className=" col-sm-6 col-lg-6">
                        <div>
                          {selectedFile3 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  {/* <img
                                    src={URL?.createObjectURL(selectedFile3)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  /> */}

                          <media-player src={URL?.createObjectURL(selectedFile3)} controls >
                                    <media-outlet></media-outlet>
                                  </media-player>
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() => handleDelete(3)}
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
  </div>
  <div className="tab-pane fade" id="pills-librarys" role="tabpanel" aria-labelledby="pills-librarys-tab">
  <div data-bs-dismiss="modal" aria-label="Close">
  <div className="col-lg-12 row overflow-media-outlet-page">
    {datavideotest?.map((item) => (
      <div key={item?.id} className="col-lg-4 mb-2" >
        <media-player src={item?.name} controls onClick={() => handleLibraryItemClick(item)}>
          <media-outlet></media-outlet>
        </media-player>
      </div>
    ))}
  </div>
</div>
  </div>
  </div>

              </div>
            </div>
          </div>
        </div>
      </div>

                
                
{/* 
              <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="staticBackdropLabel">Upload</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <div className="row mt-2 text-start">
                        <div className="col-12">
                          <label className="m-0">Type</label>

                          <div className="mt-2">
                            <select
                              className="form-select"
                              name="parent_id"
                              id="parent_id"
                              value={editUser?.parent_id}
                              onChange={handleInput}
                            >
                              {data?.map((item) => (
                                <option value={item?.id}>{item?.name}</option>
                              ))}
                            </select>

                          </div>
                        </div>
                      </div>


                      <div className="col-lg-12 mt-2 ">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-computer-tab" data-bs-toggle="pill" data-bs-target="#pills-computer" type="button" role="tab" aria-controls="pills-computer" aria-selected="true">Upload From Computer</button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-library-tab" data-bs-toggle="pill" data-bs-target="#pills-library" type="button" role="tab" aria-controls="pills-library" aria-selected="false">Choose From Library</button>
                          </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                          <div className="tab-pane fade show active" id="pills-computer" role="tabpanel" aria-labelledby="pills-computer-tab">
                            <div className="">
                            <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile3)
                                      }
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>            
                            </div>
                          </div>
                          <div className="tab-pane fade" id="pills-library" role="tabpanel" aria-labelledby="pills-library-tab">
                            
                          {datavideotest?.map((item) => (
                                <media-player
                                src={item?.name}
                                poster=""
                                controls
                              >
                                <media-outlet></media-outlet>
                              </media-player>

                              ))}

                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
              
              <div className="card p-3">
                <h5>Media</h5>
                <hr></hr>

                <div className="mt-2 text-start">
                  <label className="m-0">Filler Media</label>

                  <div className="mt-2">


                  <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                          >
                            <input
                              type="file"
                              ref={fileInputRef4}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef4,
                                  setSelectedFile4
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <button>
                            <AddOutlineIcon />
                            </button>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile4 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile4)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile4)
                                      }
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                  </div>
                </div>

              </div> */}

              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default EditVideoschedule;






// import React, { useState, useEffect, useRef } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
// import AddOutlineIcon from '@rsuite/icons/AddOutline';
// import loadings from "../../../components/Images/loading/loading.gif";
// import JoditEditor from "jodit-react";

// function EditManageCalendarschedule(props) {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const [editUser, setEditUser] = useState({
//     id: "",
//     user_id: "",
//     name: "",
//     type: "",
//     description: "",
//   });
//   const [editname, setEditname] = useState({
//     name: "",
//   });

//   const access_token = localStorage.getItem("access_token");
//   const [loading, setLoading] = useState(true);
//   const [data, setData] = useState([]);

//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + access_token,
//     Accept: "application/json",
//     "Access-Control-Allow-Origin": "*",
//   };

//   const fileInputRef1 = useRef(null);
//   const fileInputRef2 = useRef(null);

//   const [selectedFile1, setSelectedFile1] = useState(null);
//   const [selectedFile2, setSelectedFile2] = useState(null);

//   const handleFileChange = (event, fileInputRef, setSelectedFile) => {
//     const file = event.target.files[0];
//     setSelectedFile(file);
//   };

//   const handleDelete = (setSelectedFile1, setSelectedFile2) => {
//     setSelectedFile1(null);
//     if (fileInputRef1.current) {
//       fileInputRef1.current.value = null;
//     }
//     setSelectedFile2(null);
//     if (fileInputRef2.current) {
//       fileInputRef2.current.value = null;
//     }
//   };

//   useEffect(async () => {
//     const getUser = async () => {
//       const reqData = await fetch(
//         `${process.env.REACT_APP_Baseurl}/admin/video-schedule/edit/${id}`,
//         { headers: headers }
//       );
//       const resData = await reqData?.json();
//       var res = resData?.VideoSchedules[0];
//       setEditUser(res);
//       setEditname(res);
//       setLoading(false);
//     };
//     getUser();

//     axios
//       .get(`${process.env.REACT_APP_Baseurl}/admin/manage/schedule/create`, {
//         headers: headers,
//       })
//       .then((response) => {
//         var result = response?.data?.Video_Video_Schedule;
//         setData(result);
//         setLoading(false);
//       })
//       .catch((error) => console.log(error));
//   }, [id]);


//   const handleChangeFirstdes = (e) => {
//     setEditUser({ ...editUser, description: e });
//     // console.log(e, "onchnageddddd");
//   };
//   const handleInput = (e) => {
//     const { name, value } = e.target;
//     setEditUser((prevEditUser) => ({
//       ...prevEditUser,
//       [name]: value,
//     }));

//     setValidationMessagetitle("");
//     setIsValidationHiddentitle(true);
//     setEditUser({ ...editUser, [e.target.name]: e.target.value });

//   };

//   const handleInputenable = (e) => {
//     if (e.target.checked === true) {
//       setEditUser({ ...editUser, [e.target.name]: 1 });
//     } else {
//       setEditUser({ ...editUser, [e.target.name]: 0 });
//     }
//   };

//   const [validationMessagetitle, setValidationMessagetitle] = useState("");
//   const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

//   const [validationMessagetype, setValidationMessagetype] = useState("");
//   const [isValidationHiddentype, setIsValidationHiddentype] = useState(true);

//   const titleInputRef = useRef(null);
//   const typeInputRef = useRef(null);


//   const [processing, setProcessing] = useState(false);
//   const [showOverlay, setShowOverlay] = useState(false);

//   const handleUpdate = async (e) => {

//     let focusInputRef = null;

//     if (editUser?.name === "") {
//       setValidationMessagetitle("Name cannot be empty.");
//       setIsValidationHiddentitle(false);
//       focusInputRef = titleInputRef;
//     }
//     if (editUser?.type === "") {
//       setValidationMessagetype("Type cannot be empty.");
//       setIsValidationHiddentype(false);
//       focusInputRef = typeInputRef;
//     }

//     if (focusInputRef) {
//       focusInputRef?.current?.focus();
//       return;
//     }

//     e.preventDefault();

//     const editInputvalue = {
//       id: editUser?.id,
//       user_id: editUser?.user_id,
//       name: editUser?.name,
//       slug: editUser?.slug,
//       description: editUser?.description,
//     };

//     let firstApiStatus = false;
//     let secondApiStatus = false;
//     let resultapi = null;
//     let resultapifalse = null;
//     let resjson = null;
//     setProcessing(true);
//     setShowOverlay(true);

//     try {
//       const res = await fetch(
//         `${process.env.REACT_APP_Baseurl}/admin/manage/schedule/update/${id}`,
//         {
//           method: "POST",
//           headers: headers,
//           body: JSON.stringify(editInputvalue),
//         }
//       );

//       resjson = await res.json();

//       if (resjson?.status === true) {
//         firstApiStatus = true;

//         resultapi = resjson;

//         const formData = new FormData();
//         formData.append("manage_schedule_id", id);
//         if (selectedFile1) {
//           formData.append("image", selectedFile1);
//         }
//         if (selectedFile2) {
//           formData.append("banner_image", selectedFile2);
//         }

//         await axios.post(
//           `${process.env.REACT_APP_Baseurl}/admin/manage/schedule//image-upload`,
//           formData,
//           { headers: headers }
//         );
//         secondApiStatus = true;
//       } else if (resjson?.status === false) {
//         // Handle the case when the first API response status is false
//         resultapifalse = resjson;
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }

//     if (firstApiStatus && secondApiStatus) {
//       setValidationMessagetitle("");
//       setValidationMessagetype("");
//       props?.setApiresponsealert(resultapi);
//       props?.setShowalert(true);
//       setTimeout(() => {
//         window.location.reload();
//       }, 3000);
//     } else if (resultapifalse) {
//       // Check if resultapifalse is defined and not null
//       props?.setApiresponsealert(resultapifalse);
//       props?.setShowalert(true);
//       setProcessing(false);
//       setShowOverlay(false);
//       setIsValidationHiddentitle(false);
//       setValidationMessagetype(false);
//     }
//   };

//   return (
//     <>
//       <div className=" m-0 p-0">
//         <section className="container-fluid">
//           <div className="col-lg-12 row py-3">
//             <div className="col-6 col-sm-6 col-lg-6">
//               <h3>Edit Video Schedule</h3>
//             </div>
//             <div className="col-6 col-sm-6 col-lg-6">
//               <div className="text-end">
//                 <button onClick={handleUpdate} className="btn btn-primary">
//                   Update Video Schedule
//                 </button>
//               </div>
//             </div>
//           </div>

//           <div>
//             {showOverlay && (
//               <div className="overlay-container">
//                 <span className="overlay-icon">
//                   {" "}
//                   <img
//                     src={loadings}
//                     width={100}
//                   // alt="Animated GIF"
//                   />
//                   Processing ...
//                 </span>
//               </div>
//             )}
//           </div>

//           <div className="row col-lg-12 ">
//             <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
//               <div className="card p-3">
//                 <h5>{editname?.name}</h5>
//                 <hr></hr>
//                 <div className=" text-start">
//                   <label className="m-0">
//                     Name<span className="mandatory">*</span>
//                   </label>
//                   <div className="mt-2">
//                     <input
//                       type="text"
//                       className="rs-input form-control-lg"
//                       id="name"
//                       name="name"
//                       placeholder=""
//                       onChange={handleInput}
//                       value={editUser?.name}
//                       ref={titleInputRef}
//                     />
//                   </div>
//                   <span className="errorred">
//                     {!isValidationHiddentitle && (
//                       <p>{validationMessagetitle}</p>
//                     )}
//                   </span>
//                 </div>


//                 <div className=" text-start mt-2">
//                   <label className="m-0">Slug</label>
//                   <div className="mt-2">                      <input
//                     type="text"
//                     className="rs-input form-control-lg"
//                     // id="name"
//                     name="slug"
//                     placeholder=""
//                     onChange={handleInput}
//                     value={editUser?.slug}
//                   />
//                   </div>
//                 </div>



//                 <div className="row mt-2 text-start">
//                   <div className="col-12">
//                     <label className="m-0">Type<span className="mandatory">*</span></label>

//                     <div className="mt-2">
//                       <select
//                         className="form-select"
//                         name="parent_id"
//                         id="parent_id"
//                         value={editUser?.parent_id}
//                         onChange={handleInput}
//                         ref={typeInputRef}
//                       >
//                         {data?.map((item) => (
//                           <option value={item?.id}>{item?.name}</option>
//                         ))}
//                       </select>

//                       <span className="errorred">
//                         {!isValidationHiddentype && (
//                           <p>{validationMessagetype}</p>
//                         )}
//                       </span>

//                     </div>

//                     <div className="mt-2 text-start">
//                       <label className="m-0">Description</label>

//                       <div className="mt-2">
//                         <JoditEditor
//                           className="rs-input"
//                           value={editUser?.description}
//                           onChange={(e) => {
//                             handleChangeFirstdes(e);
//                           }}
//                         />
//                       </div>
//                     </div>

//                   </div>
//                 </div>
//               </div>


              


//               <div className="card p-3">
//                 <h5>Status Setting</h5>
//                 <hr></hr>

//                 <div className="panel panel-primary" data-collapsed="0">
//                   <div className="panel-description d-flex justify-content-between">                       <label className="">Display on Home page </label>
//                     <div className="col-3 col-sm-3 col-lg-3">
//                       <label className="switch">
//                         <input
//                           name="in_home"
//                           id="in_home"
//                           onChange={handleInputenable}
//                           defaultChecked={
//                             editUser?.in_home == 1 ? true : false
//                           }
//                           checked={editUser?.in_home == 1 ? true : false}
//                           className="rs-input"
//                           type="checkbox"
//                         />
//                         <span
//                           className="slider round"
//                           name="in_home"
//                           id="in_home"
//                           onChange={handleInputenable}
//                           value={editUser?.in_home == 1 ? "1" : "0"}
//                         ></span>
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//               </div>



//               <div className="text-start mb-3">
//                 <button onClick={handleUpdate} className="btn btn-primary">
//                   Update Video Schedule
//                 </button>
//               </div>
//             </div>

//             {/* Second Part of Section  */}

//             <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//               <div className="card p-3">
//                 <h5>Thumbnails</h5>
//                 <hr></hr>

//                 <div className=" text-start mt-2">
//                   <label className="m-0">Image</label>

//                   <div>
//                     {loading ? (
//                       <img src={loadings} className="thumbnails-images" />
//                     ) : (
//                       <img
//                         src={editUser?.image}
//                         alt="Image"
//                         className="thumbnails-images"
//                       />
//                     )}
//                   </div>

//                   <div className="mt-2">
//                     <div className="col-lg-12 row">
//                       <div className="col-6 col-sm-6 col-lg-6">
//                         <div
//                           className="imagedrop"
//                           onClick={() => fileInputRef1.current.click()}
//                         >
//                           <input
//                             type="file"
//                             ref={fileInputRef1}
//                             onChange={(event) =>
//                               handleFileChange(
//                                 event,
//                                 fileInputRef1,
//                                 setSelectedFile1
//                               )
//                             }
//                             accept="image/*"
//                             style={{ display: "none" }}
//                           />
//                           <button>
//                             <CameraRetroIcon />
//                           </button>
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-lg-6">
//                         <div>
//                           {selectedFile1 && (
//                             <div className="imagedropcopy text-end">
//                               <div className="col-lg-12 row p-0 m-0">
//                                 <div className=" col-sm-10 col-lg-10 p-0 m-0">
//                                   <img
//                                     src={URL?.createObjectURL(selectedFile1)}
//                                     alt="Uploaded"
//                                     className="thumbnails-upload-images"
//                                   />
//                                 </div>
//                                 <div
//                                   className="col-sm-2 col-lg-2 p-0 m-0"
//                                   style={{ alignSelf: "center" }}
//                                 >
//                                   <button
//                                     onClick={() =>
//                                       handleDelete(setSelectedFile1)
//                                     }
//                                     type="button"
//                                     className="btn-close"
//                                     data-bs-dismiss="modal"
//                                     aria-label="Close"
//                                   ></button>
//                                 </div>
//                               </div>
//                             </div>
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className=" text-start mt-2">
//                   <label className="m-0">Banner</label>

//                   {/* <div>
//                     <img
//                       src={editUser?.banner_image_url}
//                       width="150"
//                       height="100"
//                     />
//                   </div> */}

//                   <div>
//                     {loading ? (
//                       <img src={loadings} className="thumbnails-images" />
//                     ) : (
//                       <img
//                         src={editUser?.image}
//                         alt="Image"
//                         className="thumbnails-images"
//                       />
//                     )}
//                   </div>

//                   <div className="mt-2">
//                     <div className="col-lg-12 row">
//                       <div className="col-6 col-sm-6 col-lg-6">
//                         <div
//                           className="imagedrop"
//                           onClick={() => fileInputRef2.current.click()}
//                         >
//                           <input
//                             type="file"
//                             ref={fileInputRef2}
//                             onChange={(event) =>
//                               handleFileChange(
//                                 event,
//                                 fileInputRef2,
//                                 setSelectedFile2
//                               )
//                             }
//                             accept="image/*"
//                             style={{ display: "none" }}
//                           />
//                           <button onClick={() => fileInputRef2.current.click()}>
//                             <CameraRetroIcon />
//                           </button>
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-lg-6">
//                         <div>
//                           {selectedFile2 && (
//                             <div className="imagedropcopy text-end">
//                               <div className="col-lg-12 row p-0 m-0">
//                                 <div className=" col-sm-10 col-lg-10 p-0 m-0">
//                                   <img
//                                     src={URL?.createObjectURL(selectedFile2)}
//                                     alt="Uploaded"
//                                     className="thumbnails-upload-images"
//                                   />
//                                 </div>
//                                 <div
//                                   className="col-sm-2 col-lg-2 p-0 m-0"
//                                   style={{ alignSelf: "center" }}
//                                 >
//                                   <button
//                                     onClick={() =>
//                                       handleDelete(setSelectedFile2)
//                                     }
//                                     type="button"
//                                     className="btn-close"
//                                     data-bs-dismiss="modal"
//                                     aria-label="Close"
//                                   ></button>
//                                 </div>
//                               </div>
//                             </div>
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>




//               <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
//                 <div className="modal-dialog">
//                   <div className="modal-content">
//                     <div className="modal-header">
//                       <h5 className="modal-title" id="staticBackdropLabel">Upload</h5>
//                       <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                     </div>
//                     <div className="modal-body">
//                       <div className="row mt-2 text-start">
//                         <div className="col-12">
//                           <label className="m-0">Type</label>

//                           <div className="mt-2">
//                             <select
//                               className="form-select"
//                               name="parent_id"
//                               id="parent_id"
//                               value={editUser?.parent_id}
//                               onChange={handleInput}
//                             >
//                               {data?.map((item) => (
//                                 <option value={item?.id}>{item?.name}</option>
//                               ))}
//                             </select>

//                           </div>
//                         </div>
//                       </div>


//                       <div className="col-lg-12 mt-2 ">
//                         <ul className="nav nav-pills" id="pills-tab" role="tablist">
//                           <li className="nav-item" role="presentation">
//                             <button className="nav-link active" id="pills-computer-tab" data-bs-toggle="pill" data-bs-target="#pills-computer" type="button" role="tab" aria-controls="pills-computer" aria-selected="true">Home</button>
//                           </li>
//                           <li className="nav-item" role="presentation">
//                             <button className="nav-link" id="pills-library-tab" data-bs-toggle="pill" data-bs-target="#pills-library" type="button" role="tab" aria-controls="pills-library" aria-selected="false">Profile</button>
//                           </li>
//                         </ul>
//                         <div className="tab-content" id="pills-tabContent">
//                           <div className="tab-pane fade show active" id="pills-computer" role="tabpanel" aria-labelledby="pills-computer-tab">
//                             <div className="">
//                               <div className="col-lg-12 row">
//                                 <div className="col-12 col-sm-12 col-lg-12">
//                                   <div
//                                     className="imagedrop"
//                                   >
//                                     <input
//                                       type="file"
//                                       accept="video/*"
//                                       style={{ display: "none" }}
//                                     />
//                                     <button>
//                                       <CameraRetroIcon />
//                                     </button>
//                                   </div>
//                                 </div>

//                               </div>
//                             </div>
//                           </div>
//                           <div className="tab-pane fade" id="pills-library" role="tabpanel" aria-labelledby="pills-library-tab">testing</div>
//                         </div>
//                       </div>

//                     </div>

//                   </div>
//                 </div>
//               </div>

//               <div className="card p-3">
//                 <h5>Media</h5>
//                 <hr></hr>

//                 <div className="mt-2 text-start">
//                   <label className="m-0">Filler Media</label>

//                   <div className="mt-2">
//                     <div className="col-lg-12 row">
//                       <div className="col-6 col-sm-6 col-lg-6">
//                         <div
//                           className="imagedrop"
//                           data-bs-toggle="modal" data-bs-target="#staticBackdrop"
//                         >
//                           <input
//                             type="file"
//                             style={{ display: "none" }}
//                           />
//                           <button>
//                             <AddOutlineIcon />
//                           </button>
//                         </div>
//                       </div>

//                     </div>
//                   </div>
//                 </div>

//               </div>





//             </div>

//           </div>
//         </section>
//       </div>
//     </>
//   );
// }

// export default EditManageCalendarschedule;
