// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-rev {
  border: none;
  height: 25px;
}
.search-rev:focus-visible {
  outline: none;
}
.filter-btn {
  padding: 8px 50px;
  /* background-color: white; */
}
.filter-btn:active {
  background-color: #3791ed !important;
}
.filter-btn:hover {
  background-color: #3791ed !important;
}
.filter-btn:focus {
  background-color: #3791ed !important;
  outline: none;
}
.download-btn {
  padding: 8px;
}
.r1 > th {
  text-align: center;
}
.r1 > td {
  text-align: center;
}
.user_reg_analytics_count {
  font-size: 18px !important;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Analytics/UsersRevenue.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,6BAA6B;AAC/B;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;EACpC,aAAa;AACf;AACA;EACE,YAAY;AACd;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".search-rev {\n  border: none;\n  height: 25px;\n}\n.search-rev:focus-visible {\n  outline: none;\n}\n.filter-btn {\n  padding: 8px 50px;\n  /* background-color: white; */\n}\n.filter-btn:active {\n  background-color: #3791ed !important;\n}\n.filter-btn:hover {\n  background-color: #3791ed !important;\n}\n.filter-btn:focus {\n  background-color: #3791ed !important;\n  outline: none;\n}\n.download-btn {\n  padding: 8px;\n}\n.r1 > th {\n  text-align: center;\n}\n.r1 > td {\n  text-align: center;\n}\n.user_reg_analytics_count {\n  font-size: 18px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
