import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import axios from "axios";
import { toast } from "react-toastify"
import FormContainer from "../../../components/CommonComponents/FormContainer";
import LeftWrapper from "../../../components/CommonComponents/LeftWrapper";
import Wrapper from "../../../components/CommonComponents/Wrapper";
import MessageHeading from "../../../components/CommonComponents/MessageHeading";
import MessageLabel from "../../../components/CommonComponents/MessageLabel";
import { audioDefaultURL } from "../../../components/CommonApis/CommonAudioApis";
import { adminApiHeader } from "../../../components/CommonApis/CommonApiUrl";
import AdminSaveHeader from "../../../components/CommonComponents/AdminSaveHeader";
import RightWrapper from "../../../components/CommonComponents/RightWrapper";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import FetchLoader from "../../../components/CommonComponents/FetchLoader";

function Updatesong_Writer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({})
  const [apiData, setApiData] = useState(null)
  const [formDataError, setFormDataError] = useState({})
  const [holder, setHolder] = useState(null)
  const [splitValues, setSplitValues] = useState()
  const [loading, setLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);

  const PercentageRef = useRef(null)


  //api start
  const fetchData = async () => {
    try {
      const response = await axios.get(`${audioDefaultURL?.writerApiEdit}/${id}`, { headers: adminApiHeader });
      const result = response?.data;
      const resultAudio = result?.audio[0];
      if (result?.status) {
        setApiData(resultAudio)
        setHolder(resultAudio?.publisher_songwriter);
        setFormData(resultAudio?.musician_content_rights)
        const totalPercentage = parseFloat(resultAudio?.master_songwriter_percentage);
        const mappingLength = resultAudio?.publisher_songwriter?.length;
        const splitValuesData = Array.from({ length: mappingLength }, (_, index) => {
          const percentage = (totalPercentage / mappingLength).toFixed(2);
          return {
            name: resultAudio?.publisher_songwriter[index]?.name,
            artist_id: resultAudio?.publisher_songwriter[index]?.artist_id,
            value: parseFloat(percentage),
          };
        }
        );
        setSplitValues(splitValuesData);
        setLoading(false);
      }
      else {
        console.log(result?.message)
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    if (formValidation()) {
      handleAdd();
    } else {
      console.log("Form validation failed ");
    }
  }
  const handleAdd = async () => {
    setShowOverlay(true);
    try {
      const formDataAdd = new FormData();
      for (const key in formData) { formDataAdd.append(key, formData[key]); }
      formDataAdd.append("audio_id", apiData?.id);
      splitValues.forEach((item) => {
        formDataAdd.append(`song_perentage_hodling[${item.artist_id}]`, item.value);
      });
      const response = await axios.post(`${audioDefaultURL?.writerStoreApiUpdate}/${id}`, formDataAdd, { headers: adminApiHeader });
      const result = response?.data;
      if (result?.status) {
        toast.success(result?.message)
        setShowOverlay(false);
        navigate("/audio-lists");
      }
      else {
        setShowOverlay(false);
        toast.error(result?.message)
      }
    }
    catch (err) {
      setShowOverlay(false);
      const errorMessage = err?.response?.data?.message
      toast.error(errorMessage)
      console.error("error", err)
    }
  }


  const formValidation = () => {
    let formIsValid = true;
    if (!holder) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        percentage: "Please select the percentage",
      }));
      PercentageRef.current.focus();
      formIsValid = false;
    }
    return formIsValid;
  }

  const handleInput = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputSplit = (e, index) => {
    const { name, value } = e.target;
    // Update the individual input field value
    const updatedSplitValues = splitValues?.map((item, i) => i === index ? { ...item, value } : item);
    // Calculate the new total percentage based on updated split values
    const newTotalPercentage = updatedSplitValues.reduce((total, item) => total + parseFloat(item?.value), 0);
    setFormData((prevEditUser) => ({
      ...prevEditUser,
      master_songwriter_percentage: newTotalPercentage.toFixed(2),
    }));
    setSplitValues(updatedSplitValues);
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div className="">
      {showOverlay && <Response_Processing />}
      {loading && (<FetchLoader />)}
      <AdminSaveHeader heading="Song Writer Percentage Holdings" saveText="Update" saveMethod={handleUpdate} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text='Product Details' />
            <MessageLabel text="Product Title" />
            <input type="text" name="Product_Title" value={formData?.Product_Title} onChange={handleInput} placeholder="Product Title" className="rs-input form-control-lg custom-placeholder theme-text-color  mb-3" />
            <MessageLabel text="Label Name" />
            <input type="text" name="Label_Name" value={formData?.Label_Name} onChange={handleInput} placeholder="Label Name" className="rs-input form-control-lg custom-placeholder theme-text-color  mb-3" />
            <MessageLabel text="ISRC" />
            <input type="text" name="ISRC" value={formData?.ISRC} onChange={handleInput} placeholder="ISRC" className="rs-input form-control-lg custom-placeholder theme-text-color  mb-3" />
            <MessageLabel text="ISWC" />
            <input type="text" name="ISWC" value={formData?.ISWC} onChange={handleInput} placeholder="ISWC" className="rs-input form-control-lg custom-placeholder theme-text-color  mb-3" />
            <MessageLabel text="ICPN" />
            <input type="text" name="ICPN" value={formData?.ICPN} onChange={handleInput} placeholder="ICPN" className="rs-input form-control-lg custom-placeholder theme-text-color  mb-3" />
            <MessageLabel text="Work Title" />
            <input type="text" name="Work_Title" value={formData?.Work_Title} onChange={handleInput} placeholder="Work Title" className="rs-input form-control-lg custom-placeholder theme-text-color  mb-3" />
            <MessageLabel text="Work Alternative Titles" />
            <input type="text" name="Work_Alternative_Titles" value={formData?.Work_Alternative_Titles} onChange={handleInput} placeholder="Work Alternative Titles" className="rs-input form-control-lg custom-placeholder theme-text-color  mb-3" />
            <MessageLabel text="Believed Public Domain" />
            <input type="text" name="Believed_Public_Domain" value={formData?.Believed_Public_Domain} onChange={handleInput} placeholder="Believed Public Domain" className="rs-input form-control-lg custom-placeholder theme-text-color  " />
          </Wrapper>
        </LeftWrapper>
        <RightWrapper>
          <Wrapper>
            <MessageHeading text='Recording Details' />
            <MessageLabel text="Recording Title" />
            <input type="text" name="Recording_Title" value={formData?.Recording_Title} onChange={handleInput} placeholder="Recording Title" className="rs-input form-control-lg custom-placeholder theme-text-color  mb-3" />
            <MessageLabel text="Recording Version Title" />
            <input type="text" name="Recording_Version_Title" value={formData?.Recording_Version_Title} onChange={handleInput} placeholder="Recording Version Title" className="rs-input form-control-lg custom-placeholder theme-text-color  mb-3" />
            <MessageLabel text="Recording Alternative Title" />
            <input type="text" name="Recording_Alternative_Title" value={formData?.Recording_Alternative_Title} onChange={handleInput} placeholder="Recording Alternative Title" className="rs-input form-control-lg custom-placeholder theme-text-color  mb-3" />
            <MessageLabel text="Recording Artist" />
            <input type="text" name="Recording_Artist" value={formData?.Recording_Artist} onChange={handleInput} placeholder="Recording Artist" className="rs-input form-control-lg custom-placeholder theme-text-color  mb-3" />
            <MessageLabel text="Recording Studio Producer" />
            <input type="text" name="Recording_Studio_Producer" value={formData?.Recording_Studio_Producer} onChange={handleInput} placeholder="Recording Studio Producer" className="rs-input form-control-lg custom-placeholder theme-text-color  " />
          </Wrapper>
          <Wrapper>
            <MessageHeading text='Writers Details' />
            <MessageLabel text="Writers" />
            <input type="text" name="Writers" value={formData?.Writers} onChange={handleInput} placeholder="Writers" className="rs-input form-control-lg custom-placeholder theme-text-color  mb-3" />
            <MessageLabel text="Writer IPI" />
            <input type="text" name="Writer_IPI" value={formData?.Writer_IPI} onChange={handleInput} placeholder="Writer IPI" className="rs-input form-control-lg custom-placeholder theme-text-color  mb-3" />
            <MessageLabel text="Writer ISNI" />
            <input type="text" name="Writer_ISNI" value={formData?.Writer_ISNI} onChange={handleInput} placeholder="Writer ISNI" className="rs-input form-control-lg custom-placeholder theme-text-color  mb-3" />
            {splitValues?.map((item, index) => (
              <div key={index} className="mt-2 text-start">
                <MessageLabel text={`Publisher/Song writer, % of ${item?.name} ownership`} />
                <input type="number" className="rs-input form-control-lg custom-placeholder theme-text-color " id={`song_perentage_hodling_${index}`} name={`song_perentage_hodling_${index}`} placeholder={`song_perentage_hodling ${index + 1}`} onChange={(e) => { handleInputSplit(e, index); }} value={item?.value} ref={PercentageRef} />
                {formDataError?.percentage && (<MessageBox text={`${formDataError?.percentage}`} classname='errorred mb-2 d-block' />)}
              </div>
            ))}
          </Wrapper>
        </RightWrapper>
      </FormContainer>
    </div>
  );
}

export default Updatesong_Writer;
