import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./ViewChannelPartner.css";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.css";

import edititem from "../../../components/Images/edititem.svg";
import deleteitem from "../../../components/Images/deleteitem.svg";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function ViewChannelPartner(props) {
  const [channelPartner, setChannelPartner] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [itemToDelete, setItemToDelete] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [modelClose, setModelClose] = useState(false);
  const [loading, setLoading] = useState(true);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const generateXLSX = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(channelPartner);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "CHANNEL-PARTNER-LIST.xlsx");
  };

  const openModal = () => {
    setModelClose(true);
  };
  const singleDelete = (id) => {
    setModelClose(true);
    setItemToDelete([id]);
  };
  const closeModal = () => {
    setModelClose(!closeModal);
  };
  const handleDeletedata = () => {
    handleDelete();
    closeModal();
  };
  const conditionalGeneratePDF = (get) => {
    const doc = new jsPDF();
    doc.text("CHANNEL PARTNER LIST", 10, 10);

    const columns = ["Index", "ID", "EMAIL ID", "MOBILE NUMBER", "STATUS"];
    const allDataPDF = channelPartner.map((item, index) => [
      index + 1,
      item?.id,
      item?.email,
      item?.mobile_number,
      item?.status,
    ]);
    const filterPDF = filteredData.map((item, index) => [
      index + 1,
      item?.id,
      item?.email,
      item?.mobile_number,
      item?.status,
    ]);
    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("CHANNEL-PARTNER-LIST.pdf");
  };

  const statusFormatter = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center  ${data === "Pending"
            ? "DISABLED_active"
            : data === "Approved"
              ? "PUBLISHED_active"
              : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };
  const fetchRole = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/channel-partner/index`,
        {
          headers: headers,
        }
      );

      var result = response?.data?.Channel;
      const updatedChannelList = result.map((item) => {
        const statusText =
          item?.status === 0 || item?.status === null
            ? "Pending"
            : item?.status === 1
              ? "Approved"
              : "Rejected";
        return {
          ...item,
          status: statusText,
          created_at: formatDate(item?.created_at),
        };
      });
      setChannelPartner(updatedChannelList);
      setFilteredData(updatedChannelList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(async () => {
    fetchRole();
  }, []);
  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/channel/edit-view-channel-members/${row.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Edit</span>
            </span>
          </Link>

          <div
            onClick={() => singleDelete(row.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2">Delete</span>
          </div>
        </div>
      </div>
    );
  };
  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
    return `${formattedDate} ${formattedTime}`;
  }
  const imageFormatter = (data, row) => {
    return <img src={data} className="profile" />;
  };
  const videoFormatter = (data, row) => {
    return (
      data && (
        <video
          width="100"
          height="100"
          id="videoPlayer"
          className=""
          controls
          data-setup='{"controls": true, "aspectRatio":"16:9", "fluid": true}'
          src={data}
          type="video/mp4"
        ></video>
      )
    );
  };
  const userFormatter = (data, row) => {
    return (
      <div className="d-flex flex-column align-items-start">
        <span>{data}</span>
        <span className="dateText"> {formatDate(row.created_at)}</span>
      </div>
    );
  };
  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDelete(selectAll ? [] : filteredData.map((item) => item?.id));
  };
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDelete([...itemToDelete, row.id]);
        } else {
          setItemToDelete(itemToDelete.filter((id) => id !== row?.id));
        }
      }
    },
    selected: itemToDelete,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAll}
        />
      </div>
    ),
  };
  const exportHeaders = [
    { label: "Id", key: "id" },
    { label: "Channel Name", key: "channel_name" },
    { label: "Email", key: "email" },
    { label: "Mobile Number", key: "mobile_number" },
    { label: "Status", key: "status" },
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredData.length },
    ],
  });

  const handleFilterChange = (event) => {
    const searchTerm = event.target.value;
    setFilter(searchTerm);
    const searchTermLower = searchTerm.toLowerCase();

    const filteredResults = channelPartner.filter((item) => {
      return (
        (typeof item?.created_at === "string" &&
          item?.created_at.toLowerCase().includes(searchTermLower)) ||
        (typeof item?.channel_name === "string" &&
          item?.channel_name.toLowerCase().includes(searchTermLower)) ||
        (typeof item?.email === "string" &&
          item?.email.toLowerCase().includes(searchTermLower)) ||
        (typeof item?.status === "string" &&
          item?.status.toLowerCase().includes(searchTermLower)) ||
        (typeof item?.id === "number" &&
          item?.id.toString().toLowerCase().includes(searchTermLower)) ||
        (typeof item?.mobile_number == "string" &&
          item?.mobile_number.toLowerCase().includes(searchTermLower))
      );
    });
    setFilteredData(filteredResults);
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      headerClasses: "idClass",
    },
    {
      dataField: "channel_logo",
      text: "Profile",
      formatter: imageFormatter,
    },
    {
      dataField: "channel_name",
      text: "Channel Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email Id",
      sort: true,
      formatter: userFormatter,
    },
    {
      dataField: "mobile_number",
      text: "Mobile Number",
      sort: true,
      formatter: (cell) => {
        return cell || "";
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: statusFormatter,
    },
    {
      dataField: "intro_video",
      text: "Intro Video",
      formatter: videoFormatter,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];
  const handleClear = () => {
    fetchRole();
    setFilter("");
  };

  const handleDelete = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("channel_partner_id", "[" + itemToDelete + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/channel-partner/multi-delete`,
        formData,
        { headers: headers }
      );
      const result = response.data;
      if (result.status === true) {
        fetchRole();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <>
      <div
        className={`modal packageModel ${modelClose ? "show fade" : ""}`}
        tabindex="-1"
        style={{ display: `${modelClose ? "block" : "none"}` }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDelete.length === 1 ? (
                <p> Are you sure you want to delete this item ?</p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected items ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeletedata}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card-header d-flex justify-content-between pb-3 flex-wrap">
                <div className="col-md-6 col-12">
                  <h4 className="">
                    Channel Partner Lists
                    <span className="fs-6">
                      {" "}
                      (Display all {channelPartner.length} )
                    </span>
                  </h4>
                </div>
                <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
                  {itemToDelete.length > 0 && (
                    <button
                      className="btn btn-danger d-inline-flex  fw-bolder"
                      onClick={openModal}
                    >
                      <MdDelete className="exportIcon " />
                      Delete
                    </button>
                  )}
                  <Link
                    to="/channel/user/create"
                    className="btn btn-primary fw-bolder"
                  >
                    <i className="fa fa-plus-circle"></i> Add New
                  </Link>
                </div>
              </div>
              <div className="iq-card">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="">
                      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                        <div className="filterContainer">
                          <input
                            type="text"
                            placeholder="Filter all.."
                            className="filter form-control "
                            value={filter}
                            onChange={handleFilterChange}
                          />
                          {filter.length > 0 && (
                            <button
                              type="button"
                              className="closeBtn"
                              onClick={handleClear}
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          )}
                        </div>
                        <div className="btn-group">
                          <div className="dropdown d-block " key={"2"}>
                            <button
                              className="btn btn-success dropdown-toggle d-flex"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="fw-bolder">Export </span>
                              <img
                                src={ExportIcon}
                                className="ms-2 exportIcon"
                              />
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="dropdown-item">
                                <CSVLink
                                  headers={exportHeaders}
                                  data={filteredData}
                                  className="text-dark d-block"
                                  filename="CHANNEL-PARTNER-LIST.csv"
                                >
                                  CSV
                                </CSVLink>
                              </li>
                              <li
                                className="dropdown-item"
                                onClick={() => {
                                  conditionalGeneratePDF(0);
                                }}
                              >
                                PDF
                              </li>
                              <li
                                className="dropdown-item"
                                onClick={() => {
                                  generateXLSX(0);
                                }}
                              >
                                XLSX
                              </li>
                            </ul>
                          </div>

                          <div className="dropdown d-block ">
                            <button
                              className="btn btn-success dropdown-toggle d-flex"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="fw-bolder">Export All</span>
                              <img
                                src={ExportIcon}
                                className="ms-2 exportIcon"
                              />
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="dropdown-item">
                                <CSVLink
                                  headers={exportHeaders}
                                  data={channelPartner}
                                  className="text-darkd d-block"
                                  filename="CHANNEL-PARTNER-LIST.csv"
                                >
                                  CSV
                                </CSVLink>
                              </li>
                              <li
                                className="dropdown-item"
                                onClick={() => {
                                  conditionalGeneratePDF(1);
                                }}
                              >
                                PDF
                              </li>
                              <li
                                className="dropdown-item"
                                onClick={() => {
                                  generateXLSX(1);
                                }}
                              >
                                XLSX
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="bootstrapTable">
                        <BootstrapTable
                          keyField="id"
                          columns={columns}
                          data={filteredData}
                          pagination={pagination}
                          noDataIndication={
                            <div className="noRecord">
                              {loading ? (
                                <label>
                                  {" "}
                                  <Response_Loading_processing></Response_Loading_processing>
                                  Loading...
                                </label>
                              ) : (
                                filteredData.length <= 0 && (
                                  <p>No Record Found!</p>
                                )
                              )}
                            </div>
                          }
                          hover
                          headerClasses="custom-header"
                          selectRow={selectRow}
                          selectAll={selectAll}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ViewChannelPartner;
