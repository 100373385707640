import React from "react";
import { Link } from "react-router-dom";

function AdsRevenue() {
  const sub = [
    {
      name: "Sanjai Kumar",
      plan: "Basic",
      amount: "10",
      create: "2022-05-09",
    },
  ];

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid pt-3">
          <div id="content-page" className="content-page">
            <div className="">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Ads Revenue List</h4>
                      </div>
                    </div>

                    <div className=" col-md-10 col-md-offset-1 mt-3">
                      <div className="form-group col-md-3">
                        <label for="month">Total Revenue</label>
                      </div>
                      <div className="form-group col-md-5">
                        <input
                          type="text"
                          id="Revenue"
                          value=""
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-between p-3">
                      <div className="d-flex">
                        <p>Show 10</p>
                        <p>&nbsp; &nbsp; entries</p>
                      </div>
                      <div className="d-flex">
                        <p>Search:</p>
                        <input
                          type="search"
                          id="gsearch"
                          name="gsearch"
                          className="border-0 search-rev"
                        />
                      </div>
                    </div>
                    <div className="iq-card-body table-responsive mt-3">
                      <div className="table-view">
                        <table className="table table-striped table-bordered table movie_table ">
                          <thead>
                            <tr className="r1">
                              <th>#</th>
                              <th>Advertiser Company Name</th>
                              <th>Plan Name</th>
                              <th>Plan Amount</th>
                              <th>Created at</th>
                            </tr>
                          </thead>
                          {sub.map((item, key) => (
                            <thead>
                              <tr className="r1">
                                <td key={key}> {key + 1} </td>

                                <td>{item.name}</td>
                                <td>{item.plan}</td>
                                <td>{item.amount} </td>
                                <td>{item.create} </td>
                              </tr>
                            </thead>
                          ))}
                        </table>
                        <div className="clear"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AdsRevenue;
