// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editvideo #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
}

.editvideo {
    height: 16rem;
    max-width: 100%;
    text-align: center;
    position: relative;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Video/Series&Episode/EditEpisodeVideo.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,mBAAmB;IACnB,oBAAoB;IACpB,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".editvideo #label-file-upload {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-width: 2px;\n    border-radius: 1rem;\n    border-style: dashed;\n    border-color: #cbd5e1;\n    background-color: #f8fafc;\n}\n\n.editvideo {\n    height: 16rem;\n    max-width: 100%;\n    text-align: center;\n    position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
