import React, { useState, useEffect } from "react";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function ManageAge(props) {
  const [data, setData] = useState([]);
  
  useEffect(async (id) => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/age-category/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response)
        var result = response?.data?.Age_Categories;
        setData(result);
        // console.log(results)
      })
      .catch((error) => console.log(error));
  }, []);

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/age-category/delete/` + id,
        { headers: headers }
      )
      .then((response) => {
        if (response.data.status == true) {
          alert("Item" + response.data.message + "!");
          window.location.reload();
        } else {
          alert("Item" + response.data.message + "!");
        }
      });
  };

  const [dataage, setDataage] = useState([]);
  const [countage, setCountage] = useState();
  const [pageage, setPageage] = useState(1);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [videodataage, setVideodataage] = useState("");
  const [loading, setLoading] = useState(true);
  const [defaultImage, setDefaultimage] = useState();
  const [defaultImageurl, setDefaultimageurl] = useState("");
  const [currentPageage, setCurrentPageage] = useState(1);
  const itemsPerPageage = 10;

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const videosdeleteOperationage = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/age-category/delete/` + id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          props.setApiresponsealert(responseData);
          props.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props.setApiresponsealert(resultError);
            props.setShowalert(true);
          }
        }
      });
  };

  const [selectedItemsage, setSelectedItemsage] = useState([]);
  const [selectAllage, setSelectAllage] = useState(false);
  const [isConfirmationOpenage, setIsConfirmationOpenage] = useState(false);
  const [isSuccessMessageOpenage, setIsSuccessMessageOpenage] = useState(false);

  const handleSingleSelectage = (itemId) => {
    setSelectedItemsage([itemId]);
    setSelectAllage(false);
  };

  const handleMultiSelectage = (itemId) => {
    if (selectedItemsage.includes(itemId)) {
      setSelectedItemsage(selectedItemsage.filter((id) => id !== itemId));
    } else {
      setSelectedItemsage([...selectedItemsage, itemId]);
    }
  };

  const handleSelectAllage = () => {
    setSelectAllage(!selectAllage);
    setSelectedItemsage(
      selectAllage ? [] : videodataage.map((item) => item?.id)
    );
  };

  const handleDeleteage = () => {
    setIsConfirmationOpenage(true);
  };
  const handleCancelDeleteage = () => {
    setIsConfirmationOpenage(false);
  };

  const handleConfirmDeleteage = () => {
    setIsConfirmationOpenage(false);
    for (const itemId of selectedItemsage) {
      axios
        .delete(
          `${process.env.REACT_APP_Baseurl}/admin/age-category/delete/${itemId}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.data.status == true) {
            setVideodataage(videodataage.filter((item) => item?.id !== itemId));
            setSelectedItemsage(selectedItemsage.filter((id) => id !== itemId));
            setIsSuccessMessageOpenage(true);
            setTimeout(() => {
              setIsSuccessMessageOpenage(false);
            }, 2000);
            var responseData = response.data;
            props.setApiresponsealert(responseData);
            props.setShowalert(true);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            if (responseData === false) {
              var resultError = responseData;
              props.setApiresponsealert(resultError);
              props.setShowalert(true);
            }
          }
        });
    }
  };

  const fetchDatanextpreage = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/age-category/index?page=${pageage}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const videosData = res?.data?.Age_Categories;
        setVideodataage(videosData);
        if (videosData.length > 0) {
          setDataage(videosData);
          setLoading(false);
        } else {
          setDataage([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchDataage = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/age-category/index`,
        { headers: headers }
      );
      setCountage(response?.data?.Total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const [showModalage, setShowModalage] = useState(false);
  const [currentItemage, setCurrentItemage] = useState(null);

  const handleToggleage = async (item) => {
    setCurrentItemage(item);
    setShowModalage(true);
  };

  const confirmToggleage = async () => {
    try {
      setShowModalage(false);
      const newStatus = currentItemage.banner === 1 ? 0 : 1;

      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/livestream-slider`,
        {
          method: "POST",
          body: JSON.stringify({
            live_id: currentItemage.id,
            banner_status: newStatus,
          }),
          headers: headers,
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        const updatedItems = dataage.map((currItem) => {
          if (currItem.id === currentItemage.id) {
            return { ...currItem, banner: newStatus };
          }
          return currItem;
        });

        setDataage(updatedItems);

        props.setApiresponsealert(responseData);
        props.setShowalert(true);
        setMessage(responseData.message);
      } else {
        props.setApiresponsealert(responseData);
        props.setShowalert(true);
      }
    } catch (error) {}
  };

  const closeModalsliderage = () => {
    setShowModalage(false);
    setCurrentItemage(null);
  };

  const fetchdefaultimageage = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/Vertical-Default-Image`,
        { headers: headers }
      );
      setDefaultimage(response?.data?.Vertical_Default_Image);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchdefaultimageurlage = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/Default-image-url`,
        { headers: headers }
      );
      setDefaultimageurl(response?.data?.Default_Image_Url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const indexOfLastItemage = currentPageage * itemsPerPageage;
  const indexOfFirstItemage = indexOfLastItemage - itemsPerPageage;
  const currentItemsage = dataage?.slice(
    indexOfFirstItemage,
    indexOfLastItemage
  );

  const handlePreClickage = () => {
    if (pageage > 1) {
      setPageage(pageage - 1);
    }
  };

  const handleNxtClickage = () => {
    setPageage(pageage + 1);
  };

  const [itemToDeleteage, setItemToDeleteage] = useState(null);
  const [successMessageage, setSuccessMessageage] = useState("");

  const openModalage = (id) => {
    setItemToDeleteage(id);
    setSuccessMessageage("");
  };

  const closeModalage = () => {
    setItemToDeleteage(null);
  };
  const handleDeletedata = () => {
    closeModalage();
    if (itemToDeleteage) {
      videosdeleteOperationage(itemToDeleteage);
    }
  };

  useEffect(() => {
    fetchDataage();
    fetchdefaultimageage();
    fetchdefaultimageurlage();
    fetchDatanextpreage();
  }, [pageage]);
  return (
    <>
      <div
        className={`modal ${itemToDeleteage !== null ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: itemToDeleteage !== null ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModalage}
              ></button>
            </div>
            <div className="modal-body">
              {successMessageage ? (
                <div className="alert alert-success" role="alert">
                  {successMessageage}
                </div>
              ) : (
                <p>Are you sure you want to delete this video?</p>
              )}
            </div>
            <div className="modal-footer">
              {successMessageage ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closeModalage}
                >
                  Close
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModalage}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeletedata}
                  >
                    Yes
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delete_confirm">
          <div className="modal-content text-center">
            <div className="modal-body">
              {isConfirmationOpenage && (
                <div>
                  <h5 className="modal-title p-3" id="exampleModalLabel">
                    <p className="fs-6">
                      Are you sure you want to delete the selected items?
                    </p>
                  </h5>
                  <div className="col-lg-12 row p-4">
                    <div className="col-lg-6">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          handleConfirmDeleteage();
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <button
                        className="btn btn-primary"
                        onClick={handleCancelDeleteage}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {isSuccessMessageOpenage && (
                <div className="success-message">
                  <div className="p-5">
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </div>
                  <p className="fs-6 fw-bold">
                    Selected items have been successfully deleted.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className=" m-0 p-0">
        <div className="container-fluid">
          <section className="section all-video-stream ">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>
                  Age Category{" "}
                  <span className="fs-6">
                    (Display all {countage} Age Category)
                  </span>
                </h3>
              </div>
              <div className="col-3 col-sm-3 col-lg-3 text-end">
                {selectedItemsage.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleDeleteage}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Delete Selected
                  </button>
                )}
              </div>
              <div className="col-3 col-sm-3 col-lg-3 text-end">
                {" "}
                <Link to="/add-age">
                  <button type="button" className="btn btn-primary">
                    Add Age
                  </button>
                </Link>
              </div>
            </div>

            <div className="col-lg-12 ">
              <div className="card">
                {loading ? (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllage}
                                onChange={handleSelectAllage}
                              />
                            </label>
                          </th>
                          <th>AGE</th>
                          <th>AGE SLUG NAME</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                    </Table>

                    <div className="text-center">
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>{" "}
                        Loading...
                      </label>
                    </div>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickage}
                          disabled={pageage <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pageage}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickage}
                          disabled={dataage.length < itemsPerPageage}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : videodataage.length > 0 ? (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllage}
                                onChange={handleSelectAllage}
                              />
                            </label>
                          </th>
                          <th>AGE</th>
                          <th>AGE SLUG NAME</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      {currentItemsage?.map((item, key) => (
                        <tbody>
                          <tr key={item?.id}>
                            <td key={item?.id}>
                              <input
                                type="checkbox"
                                checked={selectedItemsage.includes(item?.id)}
                                onChange={() => handleMultiSelectage(item?.id)}
                              />
                            </td>

                            <td>{item?.age}</td>
                            <td>{item?.slug}</td>

                            <td>
                              <div className="editdropdown">
                                <span className="editdropdown-button">
                                  <span>
                                    <i
                                      className="fa fa-ellipsis-v"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </span>
                                <div className="editdropdown-menu">
                                  <Link
                                    to={"/edit/manage-age/" + item?.id}
                                    className="edit"
                                  >
                                    <span>
                                      <img
                                        src={edititem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">Edit Age</span>
                                    </span>
                                  </Link>
                                  <Link to="" className="delete">
                                    <span
                                      onClick={() => openModalage(item?.id)}
                                    >
                                      <img
                                        src={deleteitem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">Delete Age</span>
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </Table>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickage}
                          disabled={pageage <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pageage}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickage}
                          disabled={dataage.length < itemsPerPageage}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllage}
                                onChange={handleSelectAllage}
                              />
                            </label>
                          </th>
                          <th>AGE</th>
                          <th>AGE SLUG NAME</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                    </Table>

                    <div className="text-center">
                      <label>No Age Available.</label>
                    </div>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickage}
                          disabled={pageage <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pageage}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickage}
                          disabled={dataage.length < itemsPerPageage}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ManageAge;
