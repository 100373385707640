import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import "./SocialLoginSettings.css";

function SocialLoginSettings(props) {
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    facebook: "",
    facebook_client_id: "",
    google: "",
    facebook_secrete_key: "",
    facebook_callback: "",
    google_client_id: "",
    google_secrete_key: "",
    google_callback: "",
  });
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/social_setting`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.social_setting;
      setEditUser(res);
      // console.log(resData)
      // console.log(res)
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    // if (e.target.checked === true) {
    //   setEditUser({ ...editUser, [e.target.name]: 1 });
    // } else {
    //   setEditUser({ ...editUser, [e.target.name]: 0 });
    // }
  };
  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const facebook = Number.isNaN(parseInt(editUser?.facebook, 10)) ? 0 : parseInt(editUser?.facebook, 10);
    const google = Number.isNaN(parseInt(editUser?.google, 10)) ? 0 : parseInt(editUser?.google, 10);

    const editInputvalue = {
      id: editUser?.id,
      facebook: facebook,
      facebook_client_id: editUser?.facebook_client_id,
      facebook_secrete_key: editUser?.facebook_secrete_key,
      facebook_callback: editUser?.facebook_callback,
      google: google,
      google_client_id: editUser?.google_client_id,
      google_secrete_key: editUser?.google_secrete_key,
      google_callback: editUser?.google_callback,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/social_setting_store`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   const editInputvalue = {
  //     id: editUser?.id,
  //     facebook: editUser?.facebook,
  //     facebook_client_id: editUser?.facebook_client_id,
  //     facebook_secrete_key: editUser?.facebook_secrete_key,
  //     facebook_callback: editUser?.facebook_callback,
  //     google: editUser?.google,
  //     google_client_id: editUser?.google_client_id,
  //     google_secrete_key: editUser?.google_secrete_key,
  //     google_callback: editUser?.google_callback,
  //   };
  //   // console.log(editInputvalue);
  //   let res = await fetch(
  //     `${process.env.REACT_APP_Baseurl}/admin/social_setting_store`,
  //     {
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify(editInputvalue),
  //     }
  //   );

  //   let resjson = await res.json();
  //   // console.log(resjson)
  //   if (res.status === 200) {
  //     setMessage(resjson.success);
  //     alert("Success");
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 2000);
  //   } else {
  //     setMessage("Some error Occured");
  //     console.log("Error");
  //     alert("Enter Correct Details");
  //   }
  // };

  const facebook = () => {
    window.open(
      "https://developers.facebook.com/docs/development/create-an-app",
      "_blank"
    );
  };
  const google = () => {
    window.open(
      "https://developers.google.com/identity/sign-in/web/sign-in",
      "_blank"
    );
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Social Login Settings</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Settings
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Facebook Login Details</h5>
                  <small>
                    To Create Facebook{" "}
                    <span className="fs-6 fglogin" onClick={facebook}>
                      Click Here
                    </span>
                    .
                  </small>
                  <hr></hr>

                  <div className=" text-start">
                    <div className="row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <label className="m-0">Enable Facebook</label>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3 text-end">
                        <label className="switch">
                          <input
                            name="facebook"
                            id="facebook"
                            onChange={handleInputenable}
                            className="rs-input"
                            defaultChecked={
                              editUser?.facebook == 1 ? true : false
                            }
                            checked={editUser?.facebook == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="facebook"
                            id="facebook"
                            onChange={handleInputenable}
                            value={editUser?.facebook == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Client ID</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="facebook_client_id"
                        name="facebook_client_id"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.facebook_client_id}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Secrete Key</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="facebook_secrete_key"
                        name="facebook_secrete_key"
                        value={editUser?.facebook_secrete_key}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Call Back URL</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="facebook_callback"
                        name="facebook_callback"
                        value={editUser?.facebook_callback}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Settings
                  </button>
                </div> */}
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Google Login Details</h5>
                  <small>
                    To Create Google{" "}
                    <span className="fs-6 fglogin" onClick={google}>
                      Click Here
                    </span>
                    .
                  </small>
                  <hr></hr>

                  <div className=" text-start">
                    <div className="row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <label className="m-0">Enable Google</label>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3 text-end">
                        <label className="switch">
                          <input
                            name="google"
                            id="google"
                            onChange={handleInputenable}
                            className="rs-input"
                            defaultChecked={
                              editUser?.google == 1 ? true : false
                            }
                            checked={editUser?.google == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="google"
                            id="google"
                            onChange={handleInputenable}
                            value={editUser?.google == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Client ID</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="google_client_id"
                        name="google_client_id"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.google_client_id}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Secrete Key</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="google_secrete_key"
                        name="google_secrete_key"
                        value={editUser?.google_secrete_key}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Call Back URL</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="google_callback"
                        name="google_callback"
                        value={editUser?.google_callback}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section dashboard pt-2">
            <div className="container-fluid  p-3">
              <div className="iq-card">
                <div id="admin-container">
                  <div className="admin-section-title">
                    <h4>
                      <i className="entypo-credit-card"></i> Social Login
                      Settings
                    </h4>
                  </div>

                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className="panel panel-primary mt-3"
                          data-collapsed="0"
                        >
                          <div className="panel-heading">
                            <h6 className="panel-title">
                              Facebook Login Details{" "}
                            </h6>
                            <div className="panel-options">
                              <small>
                                To Create Facebook{" "}
                                <span
                                  className="fs-6 fglogin"
                                  onClick={facebook}
                                >
                                  Click Here
                                </span>
                                .
                              </small>
                            </div>
                            <hr />
                          </div>
                          <div className="panel-body">
                            <div className="row">
                              <p className="col-md-6">
                                <label>Enable Facebook:</label>
                              </p>
                              <div className=" col-md-6 d-flex justify-content-around align-items-center">
                                <div>
                                  <label>ON</label>
                                </div>
                                <div>
                                  <label className="switch">
                                    <input
                                      name="facebook"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.facebook === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.facebook === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="facebook"
                                      onChange={handleInput}
                                      value={
                                        editUser?.facebook === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                </div>
                                <div>
                                  <label>OFF</label>
                                </div>
                              </div>
                            </div>

                            <label>Client ID:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="facebook_client_id"
                              id="facebook_client_id"
                              placeholder="Client ID"
                              onChange={handleInput}
                              value={editUser?.facebook_client_id}
                            />

                            <br />
                            <label>Secrete Key:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="facebook_secrete_key"
                              id="facebook_secrete_key"
                              placeholder="Secrete Key"
                              onChange={handleInput}
                              value={editUser?.facebook_secrete_key}
                            />

                            <br />
                            <label>Call Back URL:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="facebook_callback"
                              id="facebook_callback"
                              placeholder="Call Back URL"
                              onChange={handleInput}
                              value={editUser?.facebook_callback}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <h6 className="panel-title">
                              Google Login Details{" "}
                            </h6>
                            <div className="panel-options">
                              <small>
                                To Create Google{" "}
                                <span
                                  className="fs-6  fglogin"
                                  onClick={google}
                                >
                                  Click Here
                                </span>
                                .
                              </small>
                            </div>
                            <hr />
                          </div>
                          <div className="panel-body">
                            <div className="row">
                              <p className="col-md-6">
                                <label>Enable Google:</label>
                              </p>
                              <div className="col-md-6 d-flex justify-content-around align-items-center">
                                <div>
                                  <label>ON</label>
                                </div>
                                <div>
                                  <label className="switch">
                                    <input
                                      name="google"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.google === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.google === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="google"
                                      onChange={handleInput}
                                      value={editUser?.google === 1 ? "1" : "0"}
                                    ></span>
                                  </label>
                                </div>
                                <div>
                                  <label>OFF</label>
                                </div>
                              </div>
                            </div>

                            <label>Client ID:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="google_client_id"
                              id="google_client_id"
                              placeholder="Client ID"
                              onChange={handleInput}
                              value={editUser?.google_client_id}
                            />

                            <br />
                            <label>Secrete Key:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="google_secrete_key"
                              id="google_secrete_key"
                              placeholder="Secrete Key"
                              onChange={handleInput}
                              value={editUser?.google_secrete_key}
                            />

                            <br />
                            <label>Call Back URL:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="google_callback"
                              id="google_callback"
                              placeholder="Call Back URL"
                              onChange={handleInput}
                              value={editUser?.google_callback}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <input type="hidden" name="_token" value="" />
                    <div className="panel-body mt-3 text-end p-3">
                      <input
                        type="submit"
                        value="Update Settings"
                        className="btn btn-primary "
                        onClick={handleUpdate}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default SocialLoginSettings;
