import { useState, useEffect } from "react";
import axios from "axios";

const usePpvGlobalImageConfig = () => {
    const [allCurrencylistfromappi, setAllCurrencylistfromappi] = useState([]);
    const [globalPpvStatusActive, setGlobalPpvStatusActive] = useState({
        ppv_status: "",
        PPV_Individual_Contents: "",
    });

    const fetchCurrencySettingImageConfig = async () => {
        const access_token = localStorage.getItem("access_token");
        const adminApiHeader = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        };

        try {
            const response = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/currency_setting/index`, {
                headers: adminApiHeader,
            });
            setAllCurrencylistfromappi(response?.data?.allCurrency);
        } catch (error) {
            console.error('Error fetching currency setting image config:', error);
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, {
                headers: adminApiHeader,
            });
            setGlobalPpvStatusActive(response?.data?.settings[0]);
        } catch (error) {
            console.error('Error fetching global PPV status:', error);
        }
    };

    useEffect(() => {
        fetchCurrencySettingImageConfig();
    }, []);

    return { allCurrencylistfromappi, globalPpvStatusActive };
};

export default usePpvGlobalImageConfig;
