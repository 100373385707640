import "./Header.css";
import React, { useState, useEffect } from "react";
import { CgProfile } from "react-icons/cg";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { FaBars, FaRegUser } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../components/Provider/CommonProvider";
import { toast } from "react-toastify"

function Header(props) {
  const { themeSwitcher, themeMode } = useMyContext();
  const navigate = useNavigate();
  // const [data, setData] = useState([]);
  const access_token = localStorage.getItem("access_token");
  const expires_in = localStorage.getItem("expires_in");
  let username = localStorage.getItem("username");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
  };

  const themeStyle = {
    left: `${!themeMode ? "3px" : "24px"}`,
  };

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  // Use the capitalized username in your component
  const capitalizedUsername = capitalizeFirstLetter(username);

  // console.log(userName);

  // const headers = {
  //   "Content-Type": "application/json",
  //   Authorization: "Bearer " + access_token,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // const [data1, setData1] = useState(null);
  // const [data2, setData2] = useState(null);
  // const [data3, setData3] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_Baseurl}/admin/Site-ThemeSetting/index`, {
  //       headers: headers,
  //     })
  //     .then((response) => {
  //       setData1(response?.data?.Site_theme_setting[0]?.dark_mode_logo);
  //       setData2(response?.data?.Site_theme_setting[0]?.light_mode_logo);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //       setIsLoading(false);
  //     });
  //   axios
  //     .get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, {
  //       headers: headers,
  //     })
  //     .then((response) => {
  //       setData3(response?.data?.settings[0]?.logo);
  //       console.log(response?.data?.settings[0]?.logo)
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //       setIsLoading(false);
  //     });
  // }, []);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  // Track the last interaction time and the automatic logout time (in milliseconds)
  const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());
  const automaticLogoutTime = expires_in; // Adjust this time as needed (e.g., 15 minutes)

  // Function to reset the last interaction time
  const resetInteractionTime = () => {
    setLastInteractionTime(Date.now());
  };

  // Check for automatic logout when the component mounts
  // useEffect(async () => {
  //   const checkForAutomaticLogout = () => {
  //     const currentTime = Date.now();
  //     if (currentTime - lastInteractionTime >= automaticLogoutTime) {
  //       handleLogout();
  //     }
  //   };
  //   const intervalId = setInterval(checkForAutomaticLogout, 10000); // Check every 10 seconds
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [lastInteractionTime]);

  // useEffect(() => {
  //   const checkForAutomaticLogout = () => {
  //     const currentTime = Date.now();
  //     if (currentTime - lastInteractionTime >= automaticLogoutTime) {
  //       handleLogout();
  //     }
  //   };

  //   const intervalId = setInterval(checkForAutomaticLogout, 10000); // Check every 10 seconds

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [lastInteractionTime, automaticLogoutTime, handleLogout]);


  // async function handleLogout() {
  //   setProcessing(true);
  //   setShowOverlay(true);

  //   try {
  //     const access_token = localStorage.getItem("access_token");

  //     const headers = {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + access_token,
  //       Accept: "application/json",
  //     };

  //     const response = await axios.post(
  //       `${process.env.REACT_APP_Baseurl}/users/logout`,
  //       {},
  //       { headers: headers }
  //     );

  //     if (response?.data?.status === true) {
  //       localStorage.removeItem("access_token");
  //       localStorage.clear();

  //       setTimeout(() => {
  //         navigate("/");
  //         window.location.reload();
  //         localStorage.clear();
  //       }, 10);

  //       props.props.setApiresponsealert(response?.data);
  //       props.props.setShowalert(true);
  //     } else {
  //       props.props.setApiresponsealert(response?.data);
  //       props.props.setShowalert(true);
  //       setProcessing(false);
  //       setShowOverlay(false);
  //     }
  //   } catch (error) {
  //     console.error("Error during logout:", error);
  //     setProcessing(false);
  //     setShowOverlay(false);
  //   }
  // }

  // const search = (id) => {
  //   axios
  //     .get("", { headers: headers })
  //     .then((response) => {
  //       var result = response?.data.data.data;
  //       // console.warn("result", result);
  //       // setData(result);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // function logout() {
  //   setTimeout(() => {
  //     window.location.href = '/';
  //     localStorage.clear();
  //   }, 100);
  // }

  // const logout = async () => {
  //   localStorage.clear();
  //   await setItem('location', "/");
  //   navigate("/login");
  // }

  const logout = async () => {
    try {
      const access_token = localStorage.getItem("access_token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
      };

      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/users/logout`,
        { headers: headers }
      );

      if (response.data.status === true) {
        toast.success(response.data.message);
        localStorage.clear();
        // navigate("/login");
        window.location.href = '/login';
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Logout error:', error);
      // toast.error('Logout failed due to an error.');
    } finally {
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const handleLogoutClick = async () => {
    await logout();
  };

  useEffect(() => {
    const loginTime = localStorage.getItem('loginTime');
    let remainingTime = expires_in;

    if (loginTime) {
      const elapsedTime = Date.now() - new Date(loginTime).getTime();
      remainingTime = expires_in - elapsedTime;
    } else {
      localStorage.setItem('loginTime', new Date().toISOString());
    }

    if (remainingTime <= 0) {
      logout();
    } else {
      const expirationTimeout = setTimeout(() => {
        logout();
      }, remainingTime);

      return () => clearTimeout(expirationTimeout);
    }
  }, []);

  // function logout() {
  //   localStorage.removeItem('expires_in');
  //   localStorage.clear(); // Clear expiry time on logout
  //   window.location.href = '/';
  // }

  // function checkLogout() {
  //   const expiresIn = localStorage.getItem('expires_in');
  //   if (expiresIn) {
  //     const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  //     if (currentTime >= parseInt(expiresIn)) {
  //       logout(); // Logout if expiry time has passed
  //     }
  //   }
  // }

  // Call checkLogout periodically, for example every hour
  // setInterval(checkLogout, 1000 * 60 * 60); // Check every hour



  // function logout() {
  //   // localStorage.clear();
  //   // navigate("/");

  //   setTimeout(() => {
  //     window.location.reload();
  //     localStorage.clear();
  //   }, 0);
  //   // setTimeout(() => {
  //   //   localStorage.clear();
  //   //   navigate("/");
  //   // }, 3000);
  //   // window.location.reload();
  //   // setTimeout(() => {}, 500); // delay execution by 1 second (1000 milliseconds)
  // }

  // const [theme, setTheme] = useState('light');
  // const toggleTheme = () => {
  //   if (theme === 'light') {
  //     setTheme('dark');
  //   } else {
  //     setTheme('light');
  //   }
  // };
  // useEffect(() => {
  //   document.body.className = theme;
  // }, [theme]);

  // <div className={`App ${theme}`}>
  //   <button onClick={toggleTheme}>Toggle Theme</button>
  // </div>

  // useEffect(async () => {
  //   console.log(props?.props)
  // }, [props?.props?.apiresponsealert]);

  // const MyAlert = ({ message }) => {
  //   const [visible, setVisible] = useState(true);

  //   // console.log(' MyAlert= ',props)
  //   // console.log(' MyAlertmessage= ',props?.props?.apiresponsealert.status)

  //   const onDismiss = () => {
  //     setVisible(false);
  //   };

  //   var color = "";
  //   if (props?.props?.apiresponsealert.status === true) {
  //     color = "success";
  //   } else {
  //     color = "danger";
  //   }

  //   setTimeout(() => {
  //     props?.props?.setShowalert(false);
  //     setVisible(false);
  //   }, 5000);

  //   // console.log(color)
  //   return (
  //     <Alert
  //       className="liveAlert"
  //       color={color}
  //       isOpen={visible}
  //       toggle={onDismiss}
  //     >
  //       {props?.props?.apiresponsealert?.message ?? "Field Error"}
  //     </Alert>
  //   );
  // };

  const MyAlert = ({ message, setShowalert, apiresponsealert }) => {
    const [visible, setVisible] = useState(true);

    // useEffect(async () => {
    //   setTimeout(() => {
    //     props?.props?.setShowalert(false);
    //     setVisible(false);
    //   }, 6000);
    // }, [setVisible, setShowalert]);

    useEffect(() => {
      const timeoutFunction = async () => {
        setTimeout(() => {
          props?.props?.setShowalert(false);
          setVisible(false);
        }, 6000);
      };

      timeoutFunction();
    }, [setVisible, setShowalert]);


    let color = "error";
    if (props?.props?.apiresponsealert?.status === true) {
      color = "success";
    }

    const onDismiss = () => {
      setVisible(false);
      props?.props?.setShowalert(false);
    };

    return (
      <Stack sx={{ width: "100%" }} spacing={2}>
        {visible && (
          <Alert
            severity={color}
            onClose={onDismiss}
            variant="filled"
            className="liveAlert"
          >
            <strong>
              {props?.props?.apiresponsealert.status === true
                ? props?.props?.apiresponsealert?.message
                : props?.props?.apiresponsealert?.message}
              {apiresponsealert?.message}
            </strong>

            {/* <strong>
  {props?.props?.apiresponsealert.status === true
    ? props?.props?.apiresponsealert?.message
    : apiresponsealert?.message}
</strong> */}

            {/* <strong>
              {" "}
              {props?.props?.apiresponsealert?.message ?? "Field Error"}
            </strong> */}
          </Alert>
        )}
      </Stack>
    );
  };
  const handleToggle = () => {
    props.toggleAction();
  };
  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "in",
    },
    {
      code: "fr",
      name: "French",
      country_code: "fr",
    },
    {
      code: "it",
      name: "Italy",
      country_code: "it",
    },
    {
      code: "pl",
      name: "Polish",
      country_code: "pl",
    },


    {
      code: "ar",
      name: "Arabic",
      country_code: "sa",
    },

  ];

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();


  return (
    <>
      {props?.props ? (
        <div className="row spacing_bottom alertNotification">
          <div className="d-flex justify-content-center">
            <div id="liveAlert">
              {props?.props && props?.props?.showalert === true && (
                <MyAlert message={props?.props?.apiresponsealert?.message} />
              )}
            </div>
          </div>
        </div>
      ) : (
        {}
      )}

      <header className="adminRightNavbar">
        <nav className="d-flex justify-content-between align-items-center px-2 px-sm-3 ">
          <div
            className="toggleButton d-flex align-items-center justify-content-center"
            onClick={handleToggle}
          >
            <FaBars
              className="d-lg-none "
              style={{ width: "25px", height: "25px" }}
            />{" "}
          </div>
          <ul className=" justify-content-between align-items-center gap-3 list-unstyled mb-0  d-flex">
            <li className="visitSite p-1 px-sm-3 py-sm-2">
              {" "}
              <a
                href={`${process.env.REACT_APP_Baseurl_Content}`}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <BiWorld className="d-md-none" />{" "}
                <span className="d-none d-md-block tex-color-name">{t("CPP Portal")}</span>
              </a>{" "}
            </li>
            <li className="visitSite p-1 px-sm-3 py-sm-2">
              <a
                href={`${process.env.REACT_APP_Baseurl_Channel}`}
                target="_blank"
                rel="noreferrer"
              >
                <BiWorld className="d-md-none" />
                <span className="d-none d-md-block tex-color-name">{t("Channel Portal")}</span>
              </a>
            </li>
            <li className="visitSite p-1  px-sm-3 py-sm-2">
              {" "}
              <a
                href={`${process.env.REACT_APP_Baseurl_Frontend}`}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <BiWorld className="d-md-none" />{" "}
                <span className="d-none d-md-block tex-color-name">{t("Website")}</span>
              </a>
            </li>
            {/* <div className="d-flex gap-2 align-items-center mx-2 dropdown">
              <button className="btn btn-link p-0 me-lg-2 me-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
                <BsTranslate style={{ width: "25px", height: "25px" }} className="theme-text-color" />
              </button>
              <ul className="dropdown-menu" style={{ maxWidth: "5rem" }} aria-labelledby="dropdownMenuButton1" >
                {languages?.map(({ code, name, country_code }) => (
                  <li key={country_code}>
                    <span className={classNames("dropdown-item", { disabled: currentLanguageCode === code, })}
                      style={{ cursor: currentLanguageCode === code ? "" : "pointer", }}
                      onClick={() => { i18next.changeLanguage(code); }}
                    >
                      <span className={`flag-icon flag-icon-${country_code} mx-2`}
                        style={{ opacity: currentLanguageCode === code ? 0.5 : 1, }}
                      ></span>
                      {name}
                    </span>
                  </li>
                ))}
              </ul>
            </div> */}

          </ul>



          <div className="dropdown position-relative adminDropDownContainer py-3">
            <button className="d-flex align-items-center px-3 py-2 bg-transparent shadow rightSideButton">
              <FaRegUser style={{ fill: "currentColor", width: "20px", height: "20px" }} />
              <p className="mb-0 userText">{t(`${capitalizedUsername}`)}</p>
            </button>
            <ul className="adminSideDropDown list-unstyled theme-bg-color">
              <li><Link className=" d-flex align-items-center px-3 py-3 theme-border-bottom-secondary theme-text-color" to="/users"  > <CgProfile className="exportIcon me-2" />My Profile</Link></li>
              <li><div className=" d-flex align-items-center px-3 py-3 theme-border-bottom-secondary theme-text-color" onClick={handleLogoutClick} > <RiLogoutCircleRLine className="exportIcon me-2" />Logout</div></li>
              <li>
                <div className="d-flex justify-content-between align-items-center  px-3 py-3" onClick={themeSwitcher} >
                  <p className="headerSubLink theme-text-color  d-block mb-0"> {!themeMode ? "Light" : "Dark"}  </p>
                  <div className="position-relative toggleButtonTheme toggleButton-border theme-bg-color-secondary ">
                    <div className={`toggleCircleButton bgButton `} style={themeStyle}  ></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
