import React from 'react'

const Video_ViewRegion = () => {

    const datas = [
        {
          name: "Flicknexs",
          country: "Subscribed User",
          ip: "20",
          views: "30",
        },
      ];
      
  return (
    <div>
         <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card">
                <div className="iq-card-header  justify-content-between">
                  <div className="iq-header-title p-0">
                    <h4 className="card-title">View By Region</h4>
                  </div>
                </div>

                <div className="row p-3">
                  <hr />
                  <div className="col-md-12">
                    <div className="clear"></div>

                    <div className="row align-items-center mb-5 ">
                      <h5>Region :</h5>
                      <div className="col-md-6">
                        <select className="form-control" id="role" name="role">
                          <option value="">Choose Country</option>
                          <option value="registered">Registered Users </option>
                          <option value="subscriber">Subscriber</option>
                          <option value="admin">Admin</option>
                          <option value="ppv_users">PPV Users</option>
                        </select>
                      </div>
                      <div className="col-md-6"></div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <table className="table" id="user_tabledss">
                          <thead>
                            <tr className="r1">
                              <th>ID</th>
                              <th>VIDEO NAME</th>
                              <th>COUNTRY NAME</th>
                              <th>IP NUMBER</th>
                              <th>VIEWS</th>
                            </tr>
                          </thead>
                          {datas.map((item, key) => (
                            <thead>
                              <tr className="r1">
                                <td key={key}> {key + 1} </td>

                                <td>{item.name}</td>
                                <td>{item.country}</td>
                                <td>{item.ip}</td>
                                <td>{item.views} </td>
                              </tr>
                            </thead>
                          ))}
                          <tbody></tbody>
                        </table>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Video_ViewRegion