import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function ThumbnailSettings(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editUser, setEditUser] = useState({
    title: "",
    reels_videos: "",
    trailer: "",
    age: "",
    rating: "",
    published_year: "",
    published_on: "",
    duration: "",
    category: "",
    featured: "",
    play_button: "",
    free_or_cost_label: "",
    play_button_path: "",
  });
  const [message, setMessage] = useState("");

  const [setting, setSetting] = useState({
    title: "",
    age: "",
    rating: "",
    published_year: "",
    published_on: "",
    duration: "",
    category: "",
    featured: "",
    free_or_cost_label: "",
    reels_videos: "",
    trailer: "",
    play_button: "",
    play_button_path: "",
  });

  const [play_button, setPlay_button] = useState("");
  const [play_button_path, setPlay_button_path] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Thumbnail-Setting/index`, {
        headers: headers,
      })
      .then((response) => {
        var script = response?.data?.Thumbnail_Setting[0];
        var play_button = response?.data?.Thumbnail_Setting[0]?.play_button;
        setPlay_button(play_button);
        setEditUser(script);
        setSetting(script);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  const handleInput = (e) => {
    setSetting({ ...setting, [e.target.name]: e.target.value });
    if (e.target.checked === true) {
      setSetting({ ...setting, [e.target.name]: 1 });
    } else {
      setSetting({ ...setting, [e.target.name]: 0 });
    }
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function handleUpdate() {
    const formData = new FormData();

    const title = Number.isNaN(parseInt(setting?.title, 10)) ? 0 : parseInt(setting?.title, 10);
    formData.append("title", title);
    const reels_videos = Number.isNaN(parseInt(setting?.reels_videos, 10)) ? 0 : parseInt(setting?.reels_videos, 10);
    formData.append("reels_videos", reels_videos);
    const trailer = Number.isNaN(parseInt(setting?.trailer, 10)) ? 0 : parseInt(setting?.trailer, 10);
    formData.append("trailer", trailer);
    const age = Number.isNaN(parseInt(setting?.age, 10)) ? 0 : parseInt(setting?.age, 10);
    formData.append("age", age);
    const rating = Number.isNaN(parseInt(setting?.rating, 10)) ? 0 : parseInt(setting?.rating, 10);
    formData.append("rating", rating);
    const published_year = Number.isNaN(parseInt(setting?.published_year, 10)) ? 0 : parseInt(setting?.published_year, 10);
    formData.append("published_year", published_year);
    const published_on = Number.isNaN(parseInt(setting?.published_on, 10)) ? 0 : parseInt(setting?.published_on, 10);
    formData.append("published_on", published_on);
    const duration = Number.isNaN(parseInt(setting?.duration, 10)) ? 0 : parseInt(setting?.duration, 10);
    formData.append("duration", duration);
    const category = Number.isNaN(parseInt(setting?.category, 10)) ? 0 : parseInt(setting?.category, 10);
    formData.append("category", category);
    const featured = Number.isNaN(parseInt(setting?.featured, 10)) ? 0 : parseInt(setting?.featured, 10);
    formData.append("featured", featured);
    formData.append("play_button", selectedFile1);
    const free_or_cost_label = Number.isNaN(parseInt(setting?.free_or_cost_label, 10)) ? 0 : parseInt(setting?.free_or_cost_label, 10);
    formData.append("free_or_cost_label", free_or_cost_label);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Thumbnail-Setting/update`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;

        resultapi = response.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        setProcessing(false);
        setShowOverlay(false);
        var resultError = resultapi;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
      }
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Thumbnail Settings</h3>
              </div>

              <div className="col-6 col-sm-6 col-lg-6 text-end">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Thumbnail
                </button>
              </div>
            </div>

            <div className="iq-card ">

              <div>
                {showOverlay && <Response_Processing></Response_Processing>}
              </div>

              <div className="col-lg-12">
                <div className="">
                  <Table>
                    <thead>
                      <tr className="title">
                        <th>NAME</th>
                        <th>STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td>Enable for Title </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="title"
                              onChange={handleInput}
                              defaultChecked={
                                setting?.title === 1 ? true : false
                              }
                              checked={setting?.title === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="title"
                              onChange={handleInput}
                              value={setting?.title === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td>Enable for Age </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="age"
                              onChange={handleInput}
                              defaultChecked={setting?.age === 1 ? true : false}
                              checked={setting?.age === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="age"
                              onChange={handleInput}
                              value={setting?.age === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td>Enable for Rating </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="rating"
                              onChange={handleInput}
                              defaultChecked={
                                setting?.rating === 1 ? true : false
                              }
                              checked={setting?.rating === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="rating"
                              onChange={handleInput}
                              value={setting?.rating === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td>Enable for Published Year </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="published_year"
                              onChange={handleInput}
                              defaultChecked={
                                setting?.published_year === 1 ? true : false
                              }
                              checked={
                                setting?.published_year === 1 ? true : false
                              }
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="published_year"
                              onChange={handleInput}
                              value={setting?.published_year === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td>Enable for Published ON </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="published_on"
                              onChange={handleInput}
                              defaultChecked={
                                setting?.published_on === 1 ? true : false
                              }
                              checked={
                                setting?.published_on === 1 ? true : false
                              }
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="published_on"
                              onChange={handleInput}
                              value={setting?.published_on === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td>Enable for Duration </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="duration"
                              onChange={handleInput}
                              defaultChecked={
                                setting?.duration === 1 ? true : false
                              }
                              checked={setting?.duration === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="duration"
                              onChange={handleInput}
                              value={setting?.duration === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td>Enable for Featured </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="featured"
                              onChange={handleInput}
                              defaultChecked={
                                setting?.featured === 1 ? true : false
                              }
                              checked={setting?.featured === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="featured"
                              onChange={handleInput}
                              value={setting?.featured === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td>Enable for Free or Cost Label </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="free_or_cost_label"
                              onChange={handleInput}
                              defaultChecked={
                                setting?.free_or_cost_label === 1 ? true : false
                              }
                              checked={
                                setting?.free_or_cost_label === 1 ? true : false
                              }
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="free_or_cost_label"
                              onChange={handleInput}
                              value={
                                setting?.free_or_cost_label === 1 ? "1" : "0"
                              }
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td>Enable for Category </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="category"
                              onChange={handleInput}
                              defaultChecked={
                                setting?.category === 1 ? true : false
                              }
                              checked={setting?.category === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="category"
                              onChange={handleInput}
                              value={setting?.category === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td>Enable for Trailer </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="trailer"
                              onChange={handleInput}
                              defaultChecked={
                                setting?.trailer === 1 ? true : false
                              }
                              checked={setting?.trailer === 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="trailer"
                              onChange={handleInput}
                              value={setting?.trailer === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td>Enable for Reels Videos </td>
                      <td>
                        <div className="mt-1">
                          <label className="switch">
                            <input
                              name="reels_videos"
                              onChange={handleInput}
                              defaultChecked={
                                setting?.reels_videos === 1 ? true : false
                              }
                              checked={
                                setting?.reels_videos === 1 ? true : false
                              }
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="reels_videos"
                              onChange={handleInput}
                              value={setting?.reels_videos === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </td>
                    </tbody>

                    <tbody>
                      <td>Play Button Image</td>
                      <td>
                        <div className="mt-1 text-center">
                          <div className="">
                            {loading ? (
                              <Response_Loading_processing></Response_Loading_processing>
                            ) : (
                              <img
                                src={setting?.play_button_path}
                                // alt="Image"
                                className="thumbnails-images"
                              />
                            )}
                          </div>

                          <div className="col-lg-12 row mt-2">
                            <div className="col-6 col-sm-6 col-lg-6">
                              <div
                                className="imagedrop"
                                onClick={() => fileInputRef1.current.click()}
                              >
                                <input
                                  type="file"
                                  ref={fileInputRef1}
                                  onChange={(event) =>
                                    handleFileChange(
                                      event,
                                      fileInputRef1,
                                      setSelectedFile1
                                    )
                                  }
                                  accept="image/*"
                                  style={{ display: "none" }}
                                />
                                <label>
                                  <CameraRetroIcon />
                                </label>
                              </div>
                            </div>
                            <div className="col-6 col-sm-6 col-lg-6">
                              <div>
                                {selectedFile1 && (
                                  <div className="imagedropcopy text-end">
                                    <div className="col-lg-12 row p-0 m-0">
                                      <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                        <img
                                          src={URL.createObjectURL(
                                            selectedFile1
                                          )}
                                          alt="Uploaded"
                                          className="thumbnails-upload-images"
                                        />
                                      </div>
                                      <div
                                        className="col-sm-2 col-lg-2 p-0 m-0"
                                        style={{ alignSelf: "center" }}
                                      >
                                        <button
                                          onClick={() => handleDelete(1)}
                                          type="button"
                                          className="btn-close"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="p-2">
              <div className="row">
                <div className="col-sm-12 card">
                  <div className="">
                    <div className="iq-card-header text-center">
                      <div className="iq-header-title">
                        <h4 className="card-title p-3">Thumbnail Setting </h4>
                      </div>
                    </div>

                    <div className="iq-card-body table-responsive p-0">
                      <div className="table-view">
                        <table
                          className="table table-striped table-bordered table movie_table iq-card text-center"
                          id="Thumbnail"
                        >
                          <thead>
                            <tr className="r1">
                              <th>S.No</th>
                              <th>Name</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            <td> 1 </td>
                            <td> Title </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="title"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.title === 1 ? true : false
                                    }
                                    checked={
                                      setting?.title === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="title"
                                    onChange={handleInput}
                                    value={setting?.title === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 2 </td>
                            <td> Age </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="age"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.age === 1 ? true : false
                                    }
                                    checked={setting?.age === 1 ? true : false}
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="age"
                                    onChange={handleInput}
                                    value={setting?.age === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 3 </td>
                            <td> Rating </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="rating"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.rating === 1 ? true : false
                                    }
                                    checked={
                                      setting?.rating === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="rating"
                                    onChange={handleInput}
                                    value={setting?.rating === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 4 </td>
                            <td> Published Year </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="published_year"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.published_year === 1
                                        ? true
                                        : false
                                    }
                                    checked={
                                      setting?.published_year === 1
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="published_year"
                                    onChange={handleInput}
                                    value={
                                      setting?.published_year === 1 ? "1" : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 5 </td>
                            <td> Published ON </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="published_on"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.published_on === 1 ? true : false
                                    }
                                    checked={
                                      setting?.published_on === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="published_on"
                                    onChange={handleInput}
                                    value={
                                      setting?.published_on === 1 ? "1" : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 6 </td>
                            <td> Duration </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="duration"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.duration === 1 ? true : false
                                    }
                                    checked={
                                      setting?.duration === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="duration"
                                    onChange={handleInput}
                                    value={setting?.duration === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 7 </td>
                            <td> Featured </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="featured"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.featured === 1 ? true : false
                                    }
                                    checked={
                                      setting?.featured === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="featured"
                                    onChange={handleInput}
                                    value={setting?.featured === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 8 </td>
                            <td> Free or Cost Label </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="free_or_cost_label"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.free_or_cost_label === 1
                                        ? true
                                        : false
                                    }
                                    checked={
                                      setting?.free_or_cost_label === 1
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="free_or_cost_label"
                                    onChange={handleInput}
                                    value={
                                      setting?.free_or_cost_label === 1
                                        ? "1"
                                        : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 9 </td>
                            <td> Play button </td>
                            <td>
                              <div className="mt-1 text-center">
                                <div>
                                  <img
                                    src={play_button_path}
                                    htmlfor="play_button"
                                    name="play_button_path"
                                  />
                                </div>
                                <div className="col-md-12 mt-2 tex-center">
                                  <input
                                    name="play_button"
                                    onChange={(e) =>
                                      setPlay_button(e.target.files[0])
                                    }
                                    type="file"
                                    id="play_button"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 10 </td>
                            <td> Category </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="category"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.category === 1 ? true : false
                                    }
                                    checked={
                                      setting?.category === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="category"
                                    onChange={handleInput}
                                    value={setting?.category === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 11 </td>
                            <td> Trailer </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="trailer"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.trailer === 1 ? true : false
                                    }
                                    checked={
                                      setting?.trailer === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="trailer"
                                    onChange={handleInput}
                                    value={setting?.trailer === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>

                          <tbody>
                            <td> 12 </td>
                            <td> Reels Videos </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="reels_videos"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.reels_videos === 1 ? true : false
                                    }
                                    checked={
                                      setting?.reels_videos === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="reels_videos"
                                    onChange={handleInput}
                                    value={
                                      setting?.reels_videos === 1 ? "1" : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tbody>
                        </table>

                        <div className="col-md-12 form-group p-3" align="right">
                          <input
                            type="submit"
                            value="Update Settings"
                            onClick={handleUpdate}
                            className="btn btn-primary "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default ThumbnailSettings;
