import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
// import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import "./AddNewLiveStream.css";
import Select from "react-select";
// import Dropzone from "react-dropzone";
import { Modal, Form, Table } from 'react-bootstrap';
import { toast } from "react-toastify"
import Button from "@mui/material/Button";
import { TagsInput } from "react-tag-input-component";
import JoditEditor from "jodit-react";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import facebook from "../../../components/Images/liveicon/facebook.png";
import twitter from "../../../components/Images/liveicon/twitter.png";
import youtube from "../../../components/Images/liveicon/youtube.png";
import useCompressImageConfig from "../../../components/CommonApis/Compressimageapi";
import usePpvGlobalImageConfig from "../../../components/CommonApis/ppv_global_change";

// const MultiSelectDropdown = ({ options, onChange }) => {
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const handleChange = (selected) => {
//     setSelectedOptions(selected);
//     const selectedValues = selected?.map((option) => option.value);
//     onChange(selectedValues);
//   };
//   return (
//     <Select
//       options={options}
//       isMulti
//       defaultValue={selectedOptions}
//       onChange={handleChange}
//     />
//   );
// };

const AddNewLiveStream = (props) => {
  // const [title, setTitle] = useState("");
  // const [slug, setSlug] = useState("");
  const [url_type, setUrl_type] = useState("");
  // const [image, setImage] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [embed_url, setEmbed_url] = useState("");
  const [m3u_url, setM3u_url] = useState("");
  const [live_stream_video, setLive_stream_video] = useState("");
  const [encode_stream_video, setEncode_stream_video] = useState("");
  const [details, setDetails] = useState("");
  const [rating, setRating] = useState("");
  // const [year, setYear] = useState("");
  const [donations_label, setDonations_label] = useState("");
  // const [duration, setDuration] = useState("");
  // const [access, setAccess] = useState("");
  // const [publish_type, setPublish_type] = useState("");
  // console.log(publish_type, "pp");
  // const [publish_time, setPublish_time] = useState("");
  // console.log(publish_time, "time");

  // const [enable_restream, setEnable_restream] = useState("");
  // console.log(enable_restream, "ee");
  // const [showModal, setShowModal] = useState(false);
  // const [selectedButtons, setSelectedButtons] = useState([]);
  // const [showInputs, setShowInputs] = useState(false);
  const [featured, setFeatured] = useState(0);
  // console.log(featured, "featured");

  const [banner, setBanner] = useState(0);
  const [enable_Tips, setEnable_Tips] = useState(0);
  const [enable_chats, setEnable_chats] = useState(0);
  // console.log(banner, "banner");
  const [active, setActive] = useState(0);
  // const [player_image, setPlayer_image] = useState("");
  // const [Tv_image, setTv_Image] = useState("");
  // const [livestream_id, setLivestream_id] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  // console.log(search_tags, "ser");
  // const [ppv_price, setPpv_price] = useState("");
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  // console.log(ios_ppv_price, "ios_ppv_price");
  const [fb_restream_url, setFb_restream_url] = useState("");
  const [youtube_restream_url, setYoutube_restream_url] = useState("");
  const [twitter_restream_url, setTwitter_restream_url] = useState("");
  const [fb_streamkey, setFb_streamkey] = useState("");
  const [youtube_streamkey, setYoutube_streamkey] = useState("");
  const [twitter_streamkey, setTwitter_streamkey] = useState("");
  const [description, setDescription] = useState("");

  const [free_duration_status, setFree_duration_status] = useState("");
  const [websitetitle, setWebsitetitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [websiteurl, setWebsiteurl] = useState("");

  // const [publish, setPublish] = useState([]);

  const [user_access, setUser_access] = useState([]);
  const [livestream_source, setLivestream_source] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [age_restrict, setAge_restrict] = useState([]);

  // const [artist, setArtist] = useState([]);
  // const [art, setArt] = useState([]);
  // const [cate, setCate] = useState([]);
  // const [selectedLanguages, setSelectedLanguages] = useState([]);
  // const [age_res, setAge_res] = useState([]);
  // console.log(user_access, "user_access");
  // const [blockCountries, setBlockCountries] = useState([]);
  // const [selectedBlockCountries, setSelectedBlockCountries] = useState([]);
  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  // const [secondOptions, setSecondOptions] = useState([]);
  // console.log(secondOptions, "live_ads");
  // const [live_ads, setLive_ads] = useState("");
  // console.log(live_ads, "live_ads");

  const handleTagsChange = (newTags) => {
    // Convert tags to lowercase, remove duplicates, and filter out empty tags
    const cleanedTags = newTags
      .map((tag) => tag.trim().toLowerCase())
      .filter((tag) => tag !== "")
      .filter((value, index, self) => self.indexOf(value) === index);
    setSearchTags(cleanedTags);
  };

  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");
  // const [formDatastore, setFormData] = useState(new FormData());

  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPublishType(selectedValue);

    if (selectedValue === "publish_later") {
      setPublishTime(""); // Reset the publish time when switching to "Publish Later"
    }
  };

  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setPublishTime(timeValue);
  };

  const [inputValueyear, setInputValueyear] = useState("");

  // const handleChangeyear = (event) => {
  //   const value = event.target.value;
  //   if (/^\d{0,4}$/.test(value)) {
  //     setInputValueyear(value);
  //   }
  // };
  const handleChangeyear = (event) => {
    const value = event.target.value;
    setEditUser({ ...editUser, year: value });
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(editUser?.title);
    }
  };

  const handleAutoFillChangetitle = (event) => {
    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setAutoFillValuetitle(event.target.value);
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };
  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    duration: "",
    year: "",
    ppv_price: "",
    source_type: "",
    PPVType: "",
    global_ppv: "",
    ios_ppv_price: "",
  });

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");


  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  // const handleCheckbox = () => {
  //   setEnable_restream(!enable_restream);
  //   if (!enable_restream) {
  //     setShowModal(true);
  //   } else {
  //     setShowInputs(false);
  //     setSelectedButtons([]);
  //   }
  // };

  const [enableRestream, setEnableRestream] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [showInputs, setShowInputs] = useState(false);
  const [enableRestreamtoggle, setEnableRestreamtoggle] = useState("");

  const handleButtonClick = (platform) => {
    setSelectedPlatforms((prevPlatforms) => {
      if (prevPlatforms.includes(platform)) {
        return prevPlatforms.filter((p) => p !== platform);
      } else {
        return [...prevPlatforms, platform];
      }
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    setShowInputs(true);
    setShowModal(false);
  };

  const handleCheckbox = () => {
    setEnableRestream(!enableRestream);
    const enableRestreamValue = enableRestream ? 0 : 1;

    setEnableRestreamtoggle(enableRestreamValue);

    if (!enableRestream) {
      setShowModal(true); // Open the modal
    } else {
      setShowInputs(false); // Hide the input fields
    }
  };

  const [subtitlefile, setSubtitlefile] = useState([]);

  const handleDropreels = (acceptedFiles) => {
    setSubtitlefile(acceptedFiles);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    // setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setFeatured({ ...featured, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setEnable_chats({ ...enable_chats, [e.target.name]: 1 });
      setEnable_Tips({ ...enable_Tips, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setFree_duration_status({
        ...free_duration_status,
        [e.target.name]: 1,
      });
    } else {
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setEnable_Tips({ ...enable_Tips, [e.target.name]: 0 });
      setEnable_chats({ ...enable_chats, [e.target.name]: 0 });
      setFree_duration_status({
        ...free_duration_status,
        [e.target.name]: 0,
      });
    }
  };

  const { compressResolutionFormat } = useCompressImageConfig();

  const [error, setError] = useState({
    file1: '',
    file2: '',
    file3: '',
  });

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);

  // const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  const validateImageRatio = (file, expectedRatio, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const ratio = width / height;
        const expectedRatioPrecision = 0.01; // Allow for some minor floating-point precision issues
        const ratioMatch = Math.abs(ratio - expectedRatio) < expectedRatioPrecision;
        const dimensionMatch = (width === expectedWidth && height === expectedHeight);
        if (ratioMatch || dimensionMatch) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.onerror = () => {
        reject(new Error('Error loading image'));
      };
    });
  };

  const handleFileChange = async (event, fileInputRef, setSelectedFile, errorKey) => {
    const file = event.target.files[0];
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));

    if (compressResolutionFormat?.live === 1) {
      let isValid = false;

      if (fileInputRef === fileInputRef1) {
        isValid = await validateImageRatio(file, 9 / 16, 1080, 1920);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file1: 'Image must be in 9:16 Ratio or 1080x1920px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef2) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file2: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef3) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file3: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      }
    }

    setSelectedFile(file);
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));
  };

  const handleDelete = (index) => {
    setError('');
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
  };




  const [ppv_gobal_price, setppv_gobal_price] = useState();
  const [allCurrencyglobal, setAllCurrencyglobal] = useState([]);
  const [allCurrencycustoms, setAllCurrencycustoms] = useState([]);
  const [allCurrencylistfromappichoosed, setAllCurrencylistfromappichoosed] = useState();
  const { allCurrencylistfromappi, globalPpvStatusActive } = usePpvGlobalImageConfig();
  const global_ppv_status_active = globalPpvStatusActive

  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [available_country, setAvailable_country] = useState([]);
  const [
    selectedOptionsAvailable_country,
    setSelectedOptionsAvailable_country,
  ] = useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);

  // const [audio_albums, setAudio_albums] = useState([]);
  const [selectedOptionsaudio_albums, setSelectedOptionsaudio_albums] =
    useState([]);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/create`,
        { headers: headers }
      );
      setBlock_Country(response?.data?.Block_Country);
      setAvailable_country(response?.data?.Available_country);
      setLanguage(response?.data?.livestream_language);
      setArtists(response?.data?.livestream_artist);
      setUser_access(response?.data?.user_access);
      setInappPurchase(response?.data?.InappPurchase);
      setLivestream_source(response?.data?.Livestream_source);
      setCategory(response?.data?.livestream_category);
      setAge_restrictdata(response?.data?.Age_Restrict);

      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);


      var Defaultppv_gobal_price = response?.data?.ppv_gobal_price;
      setppv_gobal_price(Defaultppv_gobal_price)
      var DefaultCurrencytest = response?.data?.DefaultCurrency[0];
      setAllCurrencylistfromappichoosed(DefaultCurrencytest);
      var DefaultGlobalContentPriceRates = response?.data?.GlobalContentPriceRates;
      setAllCurrencyglobal(DefaultGlobalContentPriceRates);
      var DefaultContentPriceRates = response?.data?.ContentPriceRates;
      setAllCurrencycustoms(DefaultContentPriceRates);

    } catch (error) {
      console.error(error);
    }
  };
  useEffect(async () => {
    fetchOptions();
  }, []);
  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeAvailable_country = (selectedValues) => {
    setSelectedOptionsAvailable_country(selectedValues);
  };

  const handleSelectChangeaudio_albums = (selectedValues) => {
    setSelectedOptionsaudio_albums(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    // setValidationMessagelanguage("");
    // setIsValidationHiddenlanguage(true);
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setValidationMessagecategory("");
    setIsValidationHiddencategory(true);
    setSelectedOptionscategory(selectedValues);
  };

  // Multiselect

  // const handleButtonClick = (button) => {
  //   setSelectedButtons((prevSelectedButtons) => [
  //     ...prevSelectedButtons,
  //     button,
  //   ]);
  // };

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  // const handleSave = () => {
  //   setShowModal(false);
  //   setShowInputs(true);
  // };

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const contentFieldChanaged = (data) => {
    setDetails(data);
    // console.log("datasssssss", data);
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguage, setValidationMessagelanguage] =
    useState("");
  const [isValidationHiddenlanguage, setIsValidationHiddenlanguage] =
    useState(true);

  const [validationMessageAccess, setValidationMessageAccess] =
    useState("");
  const [isValidationHiddenAccess, setIsValidationHiddenAccess] =
    useState(true);

  const handleAccess = (data) => {
    setStoreliveuseraccess(data)
    setValidationMessageAccess("");
    setIsValidationHiddenAccess(true);
  }

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);
  const accessInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function stream() {
    let focusInputRef = null;


    if (editUser?.title === "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (selectedOptionscategory?.length === 0) {
      setValidationMessagecategory("Please select at least one Category.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    // if (selectedOptionslanguage?.length === 0) {
    //   setValidationMessagelanguage("Please select at least one Language.");
    //   setIsValidationHiddenlanguage(false);
    //   focusInputRef = languageInputRef;
    // }
    if (!storeliveuseraccess) {
      setValidationMessageAccess("Please select user access.");
      setIsValidationHiddenAccess(false);
      focusInputRef = accessInputRef;
    }

    let valid = true;

    if (compressResolutionFormat?.live === 1) {
      if (!selectedFile1) {
        setError(prevErrors => ({ ...prevErrors, file1: 'Image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile2) {
        setError(prevErrors => ({ ...prevErrors, file2: 'Player image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile3) {
        setError(prevErrors => ({ ...prevErrors, file3: 'Video TV image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }
    }

    if (!valid) {
      return;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    const editInputfeatured = featured?.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const editInputactive = active?.active;
    var dataactive = JSON.stringify(editInputactive);
    var activedata = dataactive;

    const editInputfree_duration_statusdata =
      free_duration_status?.free_duration_status;
    var data = JSON.stringify(editInputfree_duration_statusdata);
    var free_duration_statusdata = data;

    const editInputenable_Tips = enable_chats?.enable_Tips;
    var dataenable_Tips = JSON.stringify(editInputenable_Tips);
    var enable_Tipsdata = dataenable_Tips;

    const editInputenable_chats = enable_chats?.enable_chats;
    var dataenable_chats = JSON.stringify(editInputenable_chats);
    var enable_chatsdata = dataenable_chats;

    const editInputbanner = banner?.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;



    const formData = new FormData();

    const formDatatest = modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice : formDatacustompriceformDatacustomprice;

    // if (!formDatatest || formDatatest?.convertedPrices.length === 0) {
    // if (!formDatatest || formDatatest?.convertedPrices.length) {
    //   return;
    // }

    formData.append("title", editUser?.title);
    formData.append("duration", editUser?.duration);
    formData.append("mp4_url", mp4_url);
    formData.append("slug", autoFillValuetitle);
    formData.append("url_type", url_type);
    formData.append("embed_url", embed_url);
    formData.append("m3u_url", m3u_url);
    formData.append("Encode_stream_video", encode_stream_video);
    formData.append("details", details);
    // formData.append("rating", rating);
    formData.append("rating", rating ? parseInt(rating, 10) : 0);

    const enableage_restrict = Number.isNaN(parseInt(age_restrict, 10)) ? 0 : parseInt(age_restrict, 10);
    formData.append("age_restrict", enableage_restrict);

    // formData.append("year", inputValueyear);
    formData.append("year", editUser?.year);

    formData.append(
      "artist_id",
      JSON.stringify(selectedOptionsartists?.map((option) => option?.value))
    );
    formData.append(
      "block_country_id",
      JSON.stringify(selectedOptions?.map((option) => option?.value))
    );
    formData.append(
      "category_id",
      JSON.stringify(selectedOptionscategory?.map((option) => option?.value))
    );
    formData.append(
      "languages_id",
      JSON.stringify(selectedOptionslanguage?.map((option) => option?.value))
    );
    formData.append(
      "available_countries_id",
      JSON.stringify(
        selectedOptionsAvailable_country?.map((option) => option?.value)
      )
    );
    // formData.append("duration", editUser?.title);
    formData.append("access", storeliveuseraccess);
    formData.set("publish_time", publishTime);
    formData.set("publish_type", publishType);
    const enableRestreamtoggleddata = Number.isNaN(parseInt(enableRestreamtoggle, 10)) ? 0 : parseInt(enableRestreamtoggle, 10);

    formData.append("enable_restream", enableRestreamtoggleddata);
    // formData.append("featured", featureddata);
    // formData.append("banner", bannerdata);    
    const enablefeatureddata = Number.isNaN(parseInt(featureddata, 10)) ? 0 : parseInt(featureddata, 10);
    formData.append("featured", enablefeatureddata);
    const enablebannerdata = Number.isNaN(parseInt(bannerdata, 10)) ? 0 : parseInt(bannerdata, 10);
    formData.append("banner", enablebannerdata);
    formData.append("footer", 1);
    const enableenable_Tipsdata = Number.isNaN(parseInt(enable_Tipsdata, 10)) ? 0 : parseInt(enable_Tipsdata, 10);
    formData.append("enable_Tips", enableenable_Tipsdata);
    const enableenable_chatsdata = Number.isNaN(parseInt(enable_chatsdata, 10)) ? 0 : parseInt(enable_chatsdata, 10);
    formData.append("enable_chats", enableenable_chatsdata);
    // formData.append("active", activedata);
    formData.append("active", activedata ? parseInt(activedata, 10) : 0);
    formData.append("status", 1);
    formData.append("search_tags", searchTags);
    formData.append("ppv_price", inputValue);
    formData.append("global_ppv", checkboxChecked === true ? 1 : 0 || editUser?.global_ppv);
    formData.append("ios_ppv_price", editUser?.ios_ppv_price);

    const enablefree_duration_statusdata = Number.isNaN(parseInt(free_duration_statusdata, 10)) ? 0 : parseInt(free_duration_statusdata, 10);
    formData.append("free_duration_status", enablefree_duration_statusdata);
    formData.append("free_duration_time", editUser?.free_duration_time || '');
    formData.append("fb_restream_url", fb_restream_url);
    formData.append("youtube_restream_url", youtube_restream_url);
    formData.append("twitter_restream_url", twitter_restream_url);
    formData.append("fb_streamkey", fb_streamkey);
    formData.append("youtube_streamkey", youtube_streamkey);
    formData.append("twitter_streamkey", twitter_streamkey);
    formData.append("description", description);
    formData.append("donations_label", donations_label);
    formData.append("ads_position", selectedAdsPosition?.value);
    formData.append("live_ads", secondSelectOptions?.value);

    formData.append("source_type", 2);
    // formData.append("source_id", series_id);
    formData.append("PPVType", modalTypemodalType);
    formData.append("PriceType", formDatatest?.priceType || '');
    formData.append("IOSPrice", editUser?.ios_ppv_price || '');
    formData.append("ActualPrice", formDatatest?.price || '');

    // Serialize convertedPriceRates into a JSON string
    // Format and serialize convertedPriceRates
    if (formDatatest?.convertedPrices && formDatatest?.convertedPrices.length > 0) {
      formDatatest.convertedPrices.forEach((item, index) => {
        formData.append(`convertedPriceRates[${index}][Code]`, item?.Code || '');
        formData.append(`convertedPriceRates[${index}][Country]`, item?.country_name || item?.Country || '');
        formData.append(`convertedPriceRates[${index}][RateDifference]`, item?.difference || item?.RateDifference || '');
        formData.append(`convertedPriceRates[${index}][ConvertedPriceRate]`, item?.editPrice || item?.convertedPrice || '');
        formData.append(`convertedPriceRates[${index}][ChangedPrice]`, item?.editPrice || item?.convertedPrice || '');
        formData.append(`convertedPriceRates[${index}][Symbol]`, item?.Symbol || item?.symbol);
      });
    } else {
      formData.append('convertedPriceRates', JSON.stringify([]));
    }


    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;
    let ninethApiStatus = false;

    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;

        resultapi = response?.data;
        var livestream_id = response?.data?.LiveStream.id;

        const imageFormData = new FormData();
        imageFormData.append("livestream_id", livestream_id);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          imageFormData.append("TV_image", selectedFile3);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/live-stream/Image-upload`,
          imageFormData,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("livestream_id", livestream_id);
        formDataseo.append("website_page_title", websitetitle);
        formDataseo.append("website_URL", websiteurl);
        formDataseo.append("Meta_description", metadescription);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/live-stream/SEO`,
          formDataseo,
          { headers: headers }
        );

        const formDtrailervideo = new FormData();

        formDtrailervideo.append("livestream_id", livestream_id);
        formDtrailervideo.append("live_stream_video", live_stream_video);

        const formDtrailervideoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/live-stream/video-update`,
          formDtrailervideo,
          { headers: headers }
        );

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse?.data?.status === true) {
          seventhApiStatus = true;
        }
        if (formDtrailervideoresponse?.data?.status === true) {
          ninethApiStatus = true;
        }
      } else if (response?.data?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
      }
    } catch (error) { }

    if (
      firstApiStatus &&
      secondApiStatus &&
      seventhApiStatus &&
      ninethApiStatus
    ) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        navigate("/livestream");
      }, 3000);
    } else if (
      firstApiStatus &&
      secondApiStatus &&
      seventhApiStatus &&
      ninethApiStatus
    ) {
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  }


  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchAdsPositions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/create`,
        {
          headers: headers,
        }
      );

      if (response.ok) {
        const data = await response?.json();
        const adsPositionOptions = data?.ads_position?.map((position) => ({
          value: position?.position,
          label: position?.name,
        }));
        setAdsPositions(adsPositionOptions);
      } else {
        throw new Error("Request failed with status: " + response?.status);
      }
    } catch (error) {
      console.log("Error fetching ads positions:", error);
    }
  };

  useEffect(async () => {
    fetchAdsPositions();
  }, []);

  useEffect(async () => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response?.data;
          // console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [alllivestream_source, setalllivestream_source] = useState("");
  // const [storelive_source, setStorelive_source] = useState("");

  function alllive_stream_source() {
    switch (alllivestream_source) {
      case "0":
        return <div></div>;
      case "mp4":
        return (
          <div className="mt-2">
            <label>Mp4/M3U8 URL</label>
            <input
              type="text"
              className="rs-input mt-2 form-control-lg"
              onChange={(e) => setMp4_url(e.target.value)}
              placeholder="Mp4_url"
            />
          </div>
        );
      case "embed":
        return (
          <div className="mt-2">
            <label>Embed Url</label>

            <input
              type="text"
              className="rs-input mt-2 form-control-lg"
              onChange={(e) => setEmbed_url(e.target.value)}
              placeholder="Embed_url"
            />
          </div>
        );
      case "live_stream_video":
        return (
          <div className="mt-2">
            <label>Live Stream Video</label>

            <input
              type="file"
              accept="video/mp4"
              className="rs-input mt-2 form-control-lg"
              onChange={(e) => setLive_stream_video(e.target.files[0])}
              placeholder="live_stream_video"
            />
          </div>
        );
      case "m3u_url":
        return (
          <div className="mt-2">
            <label>M3u_url</label>

            <input
              type="text"
              className="rs-input mt-2 form-control-lg"
              onChange={(e) => setM3u_url(e.target.value)}
              placeholder="m3u_url"
            />
          </div>
        );

      case "Encode_stream_video":
        return (
          <div className="mt-2">
          </div>
        );

      default:
        return null;
    }
  }

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function alllive_streamuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <>
            {global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0 ? (

              <div className="row mt-3 text-start ">
                <div className="col-sm-12">
                  <label className="m-0">PPV Price</label>
                  <div className="col-lg-12 row">
                    <div className="col-8 col-lg-8">
                      <p className="p1">Apply PPV Price from Global Settings? </p>
                    </div>
                    <div className="col-4 col-lg-4">
                      <label className="switch">
                        <input
                          name="ppv_price"
                          defaultChecked={checkboxChecked}
                          onChange={handleCheckboxChange}
                          type="checkbox"
                          className="rs-input"
                        />
                        <span
                          className="slider round"
                          name="ppv_price"
                          checked={checkboxChecked}
                          onChange={handleCheckboxChange}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="panel-body">
                    <input
                      type="number"
                      className="rs-input"
                      placeholder="PPV Price"
                      name="ppv_price"
                      id="price"
                      // onChange={(e) => setPpv_price(e.target.value)}
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-sm-12 mt-2">
                  <label className="m-0"> IOS PPV Price</label>
                  <p className="p1">Apply IOS PPV Price from Global Settings?</p>
                  <div className="panel-body ppv_price_ios  mt-2">
                    <select
                      // onChange={(e) => setIos_ppv_price(e.target.value)} 
                      onChange={handleInput}
                      value={editUser?.ios_ppv_price}
                      className="form-select"
                      id="ios_ppv_price"
                      name="ios_ppv_price"
                    >
                      <option value="">Choose a PPV Price</option>
                      {inapppurchase?.map((item, key) => (
                        <option value={item?.id}>{item?.plan_price}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ) : <div className="mt-2">
              <div className="col-sm-12">
                <div className='modalofglobalppv'>
                  <Form>
                    <>
                      {global_ppv_status_active?.ppv_status === "1" || global_ppv_status_active?.ppv_status === 1 ? (
                        <Form.Check
                          type="radio"
                          label="Global Price"
                          name="priceOption"
                          value="1"
                          checked={editUser?.global_ppv === "1" || editUser?.global_ppv === 1}
                          // onChange={handleChange}
                          onClick={() => handleChangehandleChange({ target: { value: '1' } })}
                        />
                      ) : null}
                    </>
                    <Form.Check
                      type="radio"
                      label="Custom Price"
                      name="priceOption"
                      value="2"
                      checked={editUser?.global_ppv === null || (editUser?.global_ppv !== "1" && editUser?.global_ppv !== 1)}
                      // onChange={handleChange}
                      onClick={() => handleChangehandleChange({ target: { value: '2' } })}
                    />
                  </Form>
                  <Modal show={showModalshowModal} onHide={handleClosehandleClose} dialogClassName="modal-lg modal-dialog-scrollable" backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                      <Modal.Title>{modalTypemodalType === '1' ? 'Set Global Price' : 'Set Custom Price'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-custom">
                      {stepstep === 1 ? (
                        <Form>
                          <Form.Group>
                            <Form.Label
                              className="mandatorySimple">{modalTypemodalType === '1' ? 'Global Price' : 'Custom Price'}</Form.Label>
                            <Form.Control
                              type="number"
                              name="price"
                              value={modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice.price : formDatacustompriceformDatacustomprice.price}
                              onChange={(e) => handlePriceChangehandlePriceChange(e, modalTypemodalType === '1' ? 'global' : 'custom')}
                              disabled={modalTypemodalType === '1'}
                            />
                          </Form.Group>
                          {modalTypemodalType === '2' && (
                            <Form.Group>
                              <Form.Label
                                className="mandatorySimple">Country</Form.Label>
                              <Form.Control
                                as="select"
                                name="priceType"
                                value={formDatacustompriceformDatacustomprice.priceType}
                                onChange={handleCustomPriceTypeChangehandleCustomPriceTypeChange}
                                disabled
                              >
                                <option value="">Select Countries</option>
                                {allCurrencylistfromappi?.map(item => (
                                  <option key={item?.code} value={item?.code}>{item?.country}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          )}
                          {modalTypemodalType === '1' && (
                            <Form.Group>
                              <Form.Label
                                className="mandatorySimple">Country</Form.Label>
                              <Form.Control
                                as="select"
                                name="priceType"
                                value={formDatacustompriceformDatacustomprice.priceType}
                                onChange={handleCustomPriceTypeChangehandleCustomPriceTypeChange}
                                disabled
                              >
                                <option value="">Select Countries</option>
                                {allCurrencylistfromappi?.map(item => (
                                  <option key={item?.code} value={item?.code}>{item?.country}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          )}
                          {/* <div className='text-end mt-2'>
                         <Button variant="primary" onClick={handleNexthandleNext}>
Next
</Button>
</div> */}

                          <div className="col-sm-12 mt-2">
                            <label className="m-0"> IOS PPV Price</label>
                            <p className="p1">Apply IOS PPV Price from Global Settings?</p>
                            <div className="panel-body ppv_price_ios mt-2">
                              <select
                                name="ios_ppv_price"
                                id="ios_ppv_price"
                                onChange={handleInput}
                                value={editUser?.ios_ppv_price}
                                className="form-select"
                              >
                                <option value="">Select IOS Price</option>
                                {inapppurchase?.map((item, key) => (
                                  <option value={item?.product_id}>{item?.plan_price}</option>
                                ))}
                              </select>
                            </div>
                          </div>

                        </Form>
                      ) : (
                        <>
                          <div className="table-responsive">
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>Country</th>
                                  <th>Current Price</th>
                                  <th>Converted Price</th>
                                  <th>Difference</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice.convertedPrices : formDatacustompriceformDatacustomprice.convertedPrices).map((item, index) => (
                                  <tr key={index}>
                                    <td>{item?.country_name || item?.Country} ({item?.Code})</td>
                                    <td>{item?.Symbol || item?.symbol} {item?.price || item?.ActualPrice}</td>
                                    <td>
                                      <Form.Control
                                        type="number"
                                        value={item?.editPrice !== undefined ? item?.editPrice : item?.convertedPrice}
                                        onChange={(e) => handleInputChangeeditablehandleInputChangeeditable(index, 'editPrice', e.target.value)}
                                        disabled={modalTypemodalType === '1'}
                                      />
                                    </td>
                                    <td>{item?.difference || item?.RateDifference}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          {/* <div className='text-end mt-2'>
<Button variant="secondary" onClick={() => setStepstep(1)}>
Previous
</Button>
<Button variant="primary" onClick={handleSubmithandleSubmit}>
Submit
</Button>
</div> */}
                        </>
                      )}
                    </Modal.Body>

                    <hr></hr>
                    {stepstep === 1 ? (

                      <div className='text-end p-2'>
                        <Button variant="primary" onClick={handleNexthandleNext}>
                          Next
                        </Button>
                      </div>
                    ) : (
                      <div className='text-end p-2'>
                        <Button variant="secondary" onClick={() => setStepstep(1)}>
                          Previous
                        </Button>
                        <Button variant="primary" onClick={handleClosehandleClosehandleClosehandleClose} className='ms-2'>
                          Processed
                        </Button>
                      </div>
                    )}
                  </Modal>
                </div>
              </div>
            </div>
            }
          </>
        );
      default:
        return null;
    }
  }






  // {ppv price with global and customs} 
  // {ppv price with global and customs} 

  const [showModalshowModal, setShowModalshowModal] = useState(false);
  const [modalTypemodalType, setModalTypemodalType] = useState('');
  const [stepstep, setStepstep] = useState(1);
  const [formDataglobalpriceformDataglobalprice, setFormDataglobalpriceformDataglobalprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for global price
    price: ppv_gobal_price,
    convertedPrices: []
  });
  const [formDatacustompriceformDatacustomprice, setFormDatacustompriceformDatacustomprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for custom price
    price: editUser?.ppv_price,
    convertedPrices: []
  });



  const handleChangehandleChange = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: "1",
      }));
    } else if (value === "2") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: null,
      }));
    }

    setModalTypemodalType(e.target.value);
    setShowModalshowModal(true);
    setStepstep(1);
  };

  const handleClosehandleClose = () => {
    setShowModalshowModal(false);
    setFormDataglobalpriceformDataglobalprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: ppv_gobal_price,
      convertedPrices: []
    });
    setFormDatacustompriceformDatacustomprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: editUser?.ppv_price,
      convertedPrices: []
    });
  };

  const handleClosehandleClosehandleClosehandleClose = () => {
    setShowModalshowModal(false);
  };

  const handleNexthandleNext = async () => {
    if (stepstep === 1) {
      const formData = modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice : formDatacustompriceformDatacustomprice;

      if (!formData?.priceType || !formData?.price) {
        toast.error('Please fill in the required fields.')
        return;
      }

      try {
        if (modalTypemodalType === '2' && formData?.price !== editUser?.ppv_price) {
          const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${formData?.priceType}`);
          const rates = response?.data.rates;

          const convertedPrices = allCurrencylistfromappi?.map(item => {
            let country_name = item?.country;
            let Symbol = item?.Symbol || item?.symbol;
            let Code = item?.code;

            const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
            if (matchingCountry) {
              country_name = matchingCountry?.country;
              Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
            }

            return {
              Code: Code,
              country_name: country_name,
              Symbol: Symbol,
              convertedPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              price: formData?.price,
              difference: ((formData?.price * (rates[item?.code] || 0)) - formData?.price).toFixed(2),
              editPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              isEditable: true,
            };
          });

          setFormDatacustompriceformDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: convertedPrices
          }));
        } else if (modalTypemodalType === '2' && formData?.price === editUser?.ppv_price) {
          const customPrices = allCurrencycustoms?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            difference: item?.RateDifference || 0,
            isEditable: true
          }));

          setFormDatacustompriceformDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: customPrices
          }));
        } else if (modalTypemodalType === '1') {
          const globalPrices = allCurrencyglobal?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            isEditable: false
          }));
          setFormDataglobalpriceformDataglobalprice(prevState => ({
            ...prevState,
            convertedPrices: globalPrices
          }));
        }

        setStepstep(2);
      } catch (error) {
        console.error('Error converting currency:', error);
      }
    }
  };


  const handleSubmithandleSubmit = async (series_id) => {
    const formData = modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice : formDatacustompriceformDatacustomprice;

    if (!formData || formData?.convertedPrices.length === 0) {
      alert('Please fill in all the fields.');
      return;
    }

    // Prepare data to submit
    const dataToSubmit = {
      source_type: 3, // Note - 1 (Videos) or 2 (Livestream) or 3 (Series) or 4 (Episode) or 5 (Audios) or 6 season or 7 golbal
      source_id: series_id,
      PPVType: modalTypemodalType,
      PriceType: formData?.priceType, // Separate priceType outside of convertedPrices
      IOSPrice: editUser?.ios_ppv_price,
      ActualPrice: formData?.price,
      convertedPriceRates: formData?.convertedPrices.map(item => ({
        Code: item?.Code,
        Country: item?.country_name || item?.Country,
        RateDifference: item?.difference || item?.RateDifference,
        ConvertedPriceRate: item?.editPrice || item?.convertedPrice,
        ChangedPrice: item?.editPrice || item?.convertedPrice,
        Symbol: item?.Symbol || item?.symbol,
      }))
    };

    let firstApiStatus = false;
    let resultapi = {};
    let resjson = {};

    try {
      const res = await fetch(`${process.env.REACT_APP_Baseurl}/admin/Episode/PayPerViewPrice/StoreUpdate`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(dataToSubmit),
      });

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
      // Optionally show a user-friendly error message
      alert('An error occurred while submitting the form. Please try again.');
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      handleClosehandleClose();
      setStepstep(1);
    } else {
      if (resjson?.status === false) {
        props?.setApiresponsealert(resjson);
        props?.setShowalert(true);
      }
    }
  };


  const handleInputChangeeditablehandleInputChangeeditable = (index, fieldName, value) => {
    const updatedConvertedPrices = [...(modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice.convertedPrices : formDatacustompriceformDatacustomprice.convertedPrices)];
    updatedConvertedPrices[index][fieldName] = value;
    if (modalTypemodalType === '1') {
      setFormDataglobalpriceformDataglobalprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    } else {
      setFormDatacustompriceformDatacustomprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    }
  };

  const handleCustomPriceTypeChangehandleCustomPriceTypeChange = async (event) => {
    const selectedCountryCode = event.target.value;

    if (selectedCountryCode && selectedCountryCode !== allCurrencylistfromappichoosed?.code) {
      try {
        // Fetch conversion rates from API
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${selectedCountryCode}`);
        const rates = response.data.rates;

        // Convert and update prices based on the API data
        const convertedPrices = allCurrencylistfromappi?.map(item => {
          let country_name = item?.country;
          let Symbol = item?.Symbol || item?.symbol;
          let Code = item?.code;

          // Find matching country data
          const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
          if (matchingCountry) {
            country_name = matchingCountry?.country;
            Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
          }

          return {
            Code: Code,
            Country: country_name,
            Symbol: Symbol,
            convertedPrice: (formDatacustompriceformDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            price: formDatacustompriceformDatacustomprice.price,
            difference: ((formDatacustompriceformDatacustomprice.price * (rates[item?.code] || 0)) - formDatacustompriceformDatacustomprice.price).toFixed(2),
            editPrice: (formDatacustompriceformDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            isEditable: true,
          };
        });

        setFormDatacustompriceformDatacustomprice(prevState => ({
          ...prevState,
          priceType: selectedCountryCode,
          convertedPrices: convertedPrices
        }));
      } catch (error) {
        console.error('Error fetching currency conversion rates:', error);
      }
    } else {
      // Set the existing data if allCurrencylistfromappichoosed?.code is selected
      const customPrices = allCurrencycustoms?.map(item => ({
        ...item,
        convertedPrice: item?.ChangedPrice || (formDatacustompriceformDatacustomprice.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
        difference: item?.RateDifference || 0,
        isEditable: true
      }));

      setFormDatacustompriceformDatacustomprice(prevState => ({
        ...prevState,
        priceType: allCurrencylistfromappichoosed?.code,
        convertedPrices: customPrices
      }));
    }
  };

  const handlePriceChangehandlePriceChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === 'global') {
      setFormDataglobalpriceformDataglobalprice(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else if (formType === 'custom') {
      setFormDatacustompriceformDatacustomprice(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };


  // {ppv price with global and customs} 
  // {ppv price with global and customs} 




  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Add LiveStream</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={stream} className="btn btn-primary">
                    Save LiveStream
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className="col-12 col-md-6 ">
                <div className="card p-3">
                  <h5>Create LiveStream</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Title<span className="mandatory">*</span>
                    </label>
                    <p className="t-1">
                      Add the Live stream title in the textbox below
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.title}
                        onBlur={handleBlurtitle}
                        onKeyDown={handleInputKeyDowntitle}
                        placeholder="Title"
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Slug</label>
                    <p className="t-1">
                      Add the Live stream slug in the textbox below
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg"
                        value={autoFillValuetitle}
                        onChange={handleAutoFillChangetitle}
                        placeholder="slug"
                        onBlur={handleBlurtitle}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenslug && (
                        <p>{validationMessageslug}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Short Description</label>
                    <p className="t-1">
                      Add a short description of the Livestream below
                    </p>
                    <div className="mt-2">
                      <textarea
                        className="rs-input short-description"
                        id="description"
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">
                        Live Stream Details, Links, and Info
                      </label>
                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input"
                          value={details}
                          onChange={(newContent) =>
                            contentFieldChanaged(newContent)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">Duration</label>
                      <p className="t-1">
                        Enter The Live Stream Duration In (HH : MM : SS)
                      </p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="duration"
                          maxLength="8"
                          className="rs-input form-control-lg"
                          onChange={handleInputdata}
                          value={editUser?.duration}
                          placeholder="duration"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">Publish Year</label>
                      <p className="t-1">Enter The Live Stream Year</p>
                      <div className="mt-2">
                        <input
                          type="date"
                          name="date"
                          id="date"
                          value={editUser?.year || ''}
                          onChange={handleChangeyear}
                          className="rs-input form-control-lg"
                          placeholder="Year"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>Organize</h5>
                    <hr></hr>

                    <div className=" text-start">
                      <label className="m-0">Livestream Ratings</label>
                      <p className="t-1">IMDb Ratings 10 out of 10</p>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={(e) => setRating(e.target.value)}
                        >
                          {/* <option value="0">0</option> */}
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0">Age Restrict</label>
                      <p className="t-1">Select A Live Stream Age Below</p>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          id="age_restrict"
                          name="age_restrict"
                          size="lg"
                          onChange={(e) => setAge_restrict(e.target.value)}
                        >
                          <option selected value="">
                            Choose an Age
                          </option>
                          {age_restrictdata?.map((item) => (
                            <option value={item?.id}>{item?.slug}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">Artists</label>
                      <p className="t-1">Select A Live Stream Artist Below</p>

                      <div className="mt-2">
                        <Select
                          options={artists?.map((option) => ({
                            value: option?.id,
                            label: option?.artist_name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionsartists}
                          onChange={handleSelectChangeartists}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="m-0">
                        Category<span className="mandatory">*</span>
                      </label>
                      <p className="t-1">Select A Live Stream Category Below</p>

                      <div className="mt-2">
                        <Select
                          options={category?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionscategory}
                          onChange={handleSelectChangecategory}
                          ref={categoryInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddencategory && (
                          <p>{validationMessagecategory}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">
                        Language
                        {/* <span className="mandatory">*</span> */}
                      </label>
                      <p className="t-1">Select A Live Stream Language Below</p>
                      <div className="text-dark">
                        <Select
                          options={language?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionslanguage}
                          onChange={handleSelectChangelanguage}
                        // ref={languageInputRef}
                        />
                      </div>
                      {/* <span className="errorred">
                        {!isValidationHiddenlanguage && (
                          <p>{validationMessagelanguage}</p>
                        )}
                      </span> */}
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">Block Country</label>
                      <p className="t-1">Select A Live Stream country Below</p>
                      <div className="text-dark">
                        <Select
                          options={block_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptions}
                          onChange={handleSelectChange}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">Available Country</label>
                      <p className="t-1">
                        Block the Audio for Selected Country
                      </p>
                      <div className="text-dark">
                        <Select
                          options={available_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionsAvailable_country}
                          onChange={handleSelectChangeAvailable_country}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>SEO</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">Website Page Title</label>

                    <div className=" mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        onChange={(e) => setWebsitetitle(e.target.value)}
                      // placeholder="websitetitle"
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 mt-2">
                    <label className="m-0">Website URL</label>

                    <div className=" mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        onChange={(e) => setWebsiteurl(e.target.value)}
                      // placeholder="websitetitle"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-2">
                    <label className="m-0">Meta Description</label>

                    <div className=" mt-2">
                      <textarea
                        type="text"
                        className="rs-input form-control-lg"
                        onChange={(e) => setMetadescription(e.target.value)}
                      // placeholder="metadescription"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={stream} className="btn btn-primary">
                    Save LiveStream
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className="col-12 col-md-6">
                <div className="card p-3">
                  <h5>Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">
                      Live Stream Image Cover
                      {compressResolutionFormat?.live === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      Select the Live stream image( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1,
                                  'file1'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile1)
                                      }
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file1 && <div className="errorred mt-1">{error?.file1}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Player Image Cover
                      {compressResolutionFormat?.live === 1 && (
                        <span className="mandatory">*</span>
                      )}</label>
                    <p className="t-1">
                      Select the Live stream image( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2,
                                  'file2'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile2)
                                      }
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file2 && <div className="errorred mt-1">{error?.file2}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">TV Image Cover
                      {compressResolutionFormat?.live === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      Select the Live stream image( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3,
                                  'file3'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile3)
                                      }
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file3 && <div className="errorred mt-1">{error?.file3}</div>}
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Live Stream Source</h5>
                  <hr></hr>

                  <div className=" text-start">
                    {/* <label className="m-0">Title</label> */}
                    <p className="t-1">Select the Live Stream Source</p>
                    <div className="mt-2">
                      <select
                        onChange={(e) => setUrl_type(e.target.value)}
                        className="form-select"
                        id="url_type"
                        onClick={(event) => {
                          setalllivestream_source(event.target.value);
                        }}
                      >
                        <option value="0">Choose Live Source </option>
                        {livestream_source?.map((item, key) => (
                          <option value={item?.value}>{item?.name}</option>
                        ))}
                      </select>
                    </div>

                    <div>{alllive_stream_source()}</div>
                  </div>
                </div>

                <div className="card p-3">
                  {" "}
                  <h5>ReStream</h5>
                  <hr></hr>
                  <div>
                    <div className="row  text-start">
                      <div className="col-sm-9">
                        <label className="m-0">Enable ReStream</label>
                      </div>
                      <div className="col-sm-3 text-end">
                        <label className="switch">
                          <input
                            name="featured"
                            checked={enableRestream}
                            onChange={handleCheckbox}
                            type="checkbox"
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    {/* <Modal show={showModal} onHide={handleCloseModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>Choose Platforms</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      
                        <div className="row">
                          <div className="col-md-4">
                            <button
                              className={`btn ${
                                selectedPlatforms.includes("YouTube")
                                  ? "btn-primary"
                                  : "btn-outline-primary"
                              }`}
                              onClick={() => handleButtonClick("YouTube")}
                            >
                              <img
                                src={youtube}
                                className="w-100"
                                alt="Animated GIF"
                              />
                              YouTube
                            </button>
                          </div>
                          <div className="col-md-4">
                            <button
                              className={`btn ${
                                selectedPlatforms.includes("Facebook")
                                  ? "btn-primary"
                                  : "btn-outline-primary"
                              }`}
                              onClick={() => handleButtonClick("Facebook")}
                            >
                              <img
                                src={facebook}
                                className="w-100"
                                alt="Animated GIF"
                              />
                              Facebook
                            </button>
                          </div>
                          <div className="col-md-4">
                            <button
                              className={`btn ${
                                selectedPlatforms.includes("Twitter")
                                  ? "btn-primary"
                                  : "btn-outline-primary"
                              }`}
                              onClick={() => handleButtonClick("Twitter")}
                            >
                              <img
                                src={twitter}
                                className="w-100"
                                alt="Animated GIF"
                              />
                              Twitter
                            </button>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={handleSave}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal> */}

                    {enableRestreamtoggle && enableRestreamtoggle === 1 && (
                      <>
                        <div
                          className="accordion mt-3"
                          id="accordionSocialExample"
                        >
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button collapsed p-3"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                <img
                                  src={youtube}
                                  className="accordionImage"
                                  alt="Animated GIF"
                                />
                                <strong className="lh-1 ms-1">YouTube</strong>
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse "
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionSocialExample"
                            >
                              <div className="accordion-body py-3 px-0">
                                <div className="row text-start m-0 p-0">
                                  <div className="col-12 mb-2">
                                    <div className="">
                                      <label className="mb-1">
                                        YouTube Stream <small>(RTMP URL)</small>
                                      </label>

                                      <input
                                        type="text"
                                        className="rs-input form-control-lg"
                                        onChange={(e) =>
                                          setYoutube_restream_url(
                                            e.target.value
                                          )
                                        }
                                      // placeholder="youtube_restream_url"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 ">
                                    <div className="" id="">
                                      <div>
                                        <label className="mb-1">
                                          YouTube Stream Key
                                        </label>
                                      </div>{" "}
                                      <input
                                        type="text"
                                        className="rs-input form-control-lg"
                                        onChange={(e) =>
                                          setYoutube_streamkey(e.target.value)
                                        }
                                      // placeholder="youtube_streamkey"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                              <button
                                className="accordion-button collapsed p-3"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                {" "}
                                <img
                                  src={facebook}
                                  className="accordionImage"
                                  alt="Animated GIF"
                                />
                                <strong className="lh-1 ms-1"> Facebook</strong>
                              </button>
                            </h2>
                            <div
                              id="collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingTwo"
                              data-bs-parent="#accordionSocialExample"
                            >
                              <div className="accordion-body py-3 px-0">
                                <div className="row  text-start p-0 m-0">
                                  <div className="col-12 mb-2 ">
                                    <div className="">
                                      <label className="mb-1">
                                        FaceBook Stream{" "}
                                        <small>(RTMP URL)</small>
                                      </label>
                                      <input
                                        type="text"
                                        className="rs-input form-control-lg"
                                        onChange={(e) =>
                                          setFb_restream_url(e.target.value)
                                        }
                                      // placeholder="fb_restream_url"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 ">
                                    <div className="" id="">
                                      <div>
                                        <label className="mb-1">
                                          FaceBook Stream Key
                                        </label>
                                      </div>{" "}
                                      <input
                                        type="text"
                                        className="rs-input form-control-lg mt-2"
                                        onChange={(e) =>
                                          setFb_streamkey(e.target.value)
                                        }
                                      // placeholder="fb_streamkey"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button
                                className="accordion-button collapsed p-3"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                <img
                                  src={twitter}
                                  className="accordionImage"
                                  alt="Animated GIF"
                                />
                                <strong className="lh-1 ms-1"> Twitter</strong>
                              </button>
                            </h2>
                            <div
                              id="collapseThree"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingThree"
                              data-bs-parent="#accordionSocialExample"
                            >
                              <div className="accordion-body py-3 px-0">
                                <div className="row  text-start m-0 p-0">
                                  <div className="col-12 mb-2">
                                    <div className="">
                                      <label className="mb-1">
                                        Twitter Stream <small>(RTMP URL)</small>
                                      </label>
                                      <input
                                        type="text"
                                        className="rs-input form-control-lg"
                                        onChange={(e) =>
                                          setTwitter_restream_url(
                                            e.target.value
                                          )
                                        }
                                      // placeholder="twitter_restream_url"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12">
                                    <div className="mt-2">
                                      <label className="mb-1">
                                        {" "}
                                        Twitter Stream Key{" "}
                                      </label>

                                      <input
                                        type="text"
                                        className="rs-input form-control-lg"
                                        onChange={(e) =>
                                          setTwitter_streamkey(e.target.value)
                                        }
                                      // placeholder="twitter_streamkey"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {/* {showInputs && selectedPlatforms.length > 0 && (
                      <div> */}
                    {/* {selectedPlatforms.includes("YouTube") && (
                          <div className="row mt-3 text-start">
                            <div className="col-sm-6 ">
                              <div className="">
                                <label className="">
                                  YouTube Stream <small>(RTMP URL)</small>
                                </label>

                                <input
                                  type="text"
                                  className="rs-input form-control-lg"
                                  onChange={(e) =>
                                    setYoutube_restream_url(e.target.value)
                                  }
                                  // placeholder="youtube_restream_url"
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="" id="">
                                <div>
                                  <label className="">YouTube Stream Key</label>
                                </div>
                                <div>
                                  <label className="">
                                    <br />
                                  </label>
                                </div>

                                <input
                                  type="text"
                                  className="rs-input form-control-lg"
                                  onChange={(e) =>
                                    setYoutube_streamkey(e.target.value)
                                  }
                                  // placeholder="youtube_streamkey"
                                />
                              </div>
                            </div>
                          </div>
                        )} */}
                    {/* {selectedPlatforms.includes("Facebook") && (
                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <div className="mt-2">
                                <label className="">
                                  FaceBook Stream <small>(RTMP URL)</small>
                                </label>
                                <input
                                  type="text"
                                  className="rs-input form-control-lg"
                                  onChange={(e) =>
                                    setFb_restream_url(e.target.value)
                                  }
                                  // placeholder="fb_restream_url"
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="" id="">
                                <div>
                                  <label className="">
                                    FaceBook Stream Key
                                  </label>
                                </div>
                                <div>
                                  <label className="">
                                    <br />
                                  </label>
                                </div>

                                <input
                                  type="text"
                                  className="rs-input form-control-lg mt-2"
                                  onChange={(e) =>
                                    setFb_streamkey(e.target.value)
                                  }
                                  // placeholder="fb_streamkey"
                                />
                              </div>
                            </div>
                          </div>
                        )} */}
                    {/* {selectedPlatforms.includes("Twitter") && (
                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <div className="mt-2">
                                <label className="">
                                  Twitter Stream <small>(RTMP URL)</small>
                                </label>
                                <input
                                  type="text"
                                  className="rs-input form-control-lg"
                                  onChange={(e) =>
                                    setTwitter_restream_url(e.target.value)
                                  }
                                  // placeholder="twitter_restream_url"
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="mt-2">
                                <label className=""> Twitter Stream Key </label>

                                <input
                                  type="text"
                                  className="rs-input form-control-lg"
                                  onChange={(e) =>
                                    setTwitter_streamkey(e.target.value)
                                  }
                                  // placeholder="twitter_streamkey"
                                />
                              </div>
                            </div>
                          </div>
                        )} */}
                    {/* </div>
                    )} */}
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card p-3">
                    <h5>Visibility</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="mb-2">Publish Type</label>
                      <div>
                        <input
                          type="radio"
                          id="publish_now"
                          name="publish_type"
                          value="publish_now"
                          checked={publishType === "publish_now"}
                          onChange={handlePublishTypeChange}
                        />
                        <label htmlFor="publish_now" className="mt-2 ms-1">
                          Publish Now
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="publish_later"
                          name="publish_type"
                          value="publish_later"
                          checked={publishType === "publish_later"}
                          onChange={handlePublishTypeChange}
                        />
                        <label htmlFor="publish_later" className="mt-2 ms-1">
                          Publish Later
                        </label>
                        {publishType === "publish_later" && (
                          <div className="mb-3">
                            <label className="mt-2">Publish Time</label>
                            <input
                              type="datetime-local"
                              className=" mt-2"
                              id="publish_time"
                              name="publish_time"
                              value={publishTime}
                              onChange={handlePublishTimeChange}
                            />
                          </div>
                        )}
                      </div>
                      {/* <button onClick={handleFormSubmit}>Submit</button> */}
                    </div>

                    {/* <div className="col-sm-12">
                      <label className="mb-2">Publish Type</label>
                      <div
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                      >
                        <input
                          type="radio"
                          id="publish_now"
                          name="publish_type"
                          onClick={(event) => {
                            setEnable_restreampublic(event.target.value);
                          }}
                          onChange={(e) => setPublish_type(e.target.value)}
                          value="publish_now"
                          checked
                        />{" "}
                        <span
                          className="mt-2"
                          onClick={(event) => {
                            setEnable_restreampublic(event.target.value);
                          }}
                          onChange={(e) => setPublish_type(e.target.value)}
                          checked
                        >
                          Publish Now
                        </span>
                      </div>
                      <div
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                      >
                        <input
                          type="radio"
                          id="publish_later"
                          value="publish_later"
                          name="publish_type"
                          className="mt-2"
                          onClick={(event) => {
                            setEnable_restreampublic(event.target.value);
                          }}
                          onChange={(e) => setPublish_type(e.target.value)}
                        />{" "}
                        <span
                          value="publish_later"
                          name="publish_type"
                          onClick={(event) => {
                            setEnable_restreampublic(event.target.value);
                          }}
                          onChange={(e) => setPublish_type(e.target.value)}
                        >
                          Publish Later
                        </span>{" "}
                      </div>
                    </div>

                    <div className="col-sm-12" id="publishlater">
                      <div
                        className="mt-2"
                        onClick={(event) => {
                          setEnablestreamvisiblepublic(event.target.value);
                        }}
                      >
                        {proceednextpublic()}
                      </div>
                    </div> */}
                  </div>
                  <div className="card p-3">
                    <h5>Access</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="m-0 mandatorySimple">User Access</label>
                      <p className="p1">
                        Who Is Allowed To View This Live Stream ?
                      </p>
                      <select
                        onChange={(e) => handleAccess(e.target.value)}
                        ref={accessInputRef}
                        className="form-select"
                        id="access"
                        name="access"
                        onClick={(event) => {
                          setallliveuseraccess(event.target.value);
                        }}
                      >
                        <option value="">Select User Access</option>
                        {/* <option value={item.role}>{item.name}</option> */}
                        {user_access?.map((item, key) => (
                          <option value={item?.value}>{item?.name}</option>
                        ))}
                      </select>
                      <span className="errorred mt-2 d-block">
                        {!isValidationHiddenAccess && (
                          <p>{validationMessageAccess}</p>
                        )}
                      </span>
                    </div>

                    <div>{alllive_streamuseraccess()}</div>
                  </div>

                  <div className="card p-3">
                    <h5>Free Duration</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <div className="col-sm-12 row">
                        <div className="col-9 col-sm-9 col-lg-9">
                          <p className="p2">Enable Free Duration Status</p>
                        </div>
                        <div className="col-3 col-sm-3 col-lg-3">
                          <label className="switch">
                            <input
                              name="free_duration_status"
                              id="free_duration_status"
                              onChange={handleInput}
                              defaultChecked={
                                free_duration_status?.free_duration_status === 1
                                  ? true
                                  : false
                              }
                              checked={
                                free_duration_status?.free_duration_status === 1
                                  ? true
                                  : false
                              }
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              id="free_duration_status"
                              name="free_duration_status"
                              onChange={handleInput}
                              value={
                                free_duration_status?.free_duration_status === 1
                                  ? "1"
                                  : "0"
                              }
                            ></span>
                          </label>
                        </div>
                      </div>

                      <div className="row mt-2 text-start">
                        <div className="col-12">
                          <label className="m-0">Free Duration Time</label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="free_duration_time"
                              className="rs-input form-control-lg"
                              maxLength="8"
                              placeholder="HH:MM:SS"
                              value={editUser?.free_duration_time}
                              onChange={handleInputdata}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card p-3">
                    <h5>Status Settings</h5>
                    <hr></hr>

                    <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">Is this Live stream Featured</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="featured"
                            onChange={handleInput}
                            className="rs-input"
                            defaultChecked={
                              featured?.featured === 1 ? true : false
                            }
                            checked={featured?.featured === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="featured"
                            onChange={handleInput}
                            value={featured?.featured === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">Is this Live stream Active</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="active"
                            onChange={handleInput}
                            defaultChecked={active?.active === 1 ? true : false}
                            checked={active?.active === 1 ? true : false}
                            className="rs-input"
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="active"
                            onChange={handleInput}
                            value={active?.active === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">
                          Is this Live stream display in Banner
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="banner"
                            onChange={handleInput}
                            className="r-input"
                            defaultChecked={banner?.banner === 1 ? true : false}
                            checked={banner?.banner === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            onChange={handleInput}
                            value={banner?.banner === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">
                          Is this Live stream in Enable Chats
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="enable_chats"
                            onChange={handleInput}
                            className="r-input"
                            defaultChecked={
                              enable_chats?.enable_chats === 1 ? true : false
                            }
                            checked={
                              enable_chats?.enable_chats === 1 ? true : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="enable_chats"
                            onChange={handleInput}
                            value={enable_chats?.enable_chats === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="card p-3">
                    <h5>Live Event Artist</h5>
                    <hr></hr>

                    <div className="col-sm-12 row ">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">Is this Live stream in Enable Tips</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="enable_Tips"
                            onChange={handleInput}
                            className="r-input"
                            defaultChecked={
                              enable_Tips?.enable_Tips === 1 ? true : false
                            }
                            checked={
                              enable_Tips?.enable_Tips === 1 ? true : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="enable_Tips"
                            onChange={handleInput}
                            value={enable_Tips?.enable_Tips === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-2 text-start">
                      <div className="col-12">
                        <label className="m-0">Donations</label>

                        <div className="mt-2">
                          <input
                            type="number"
                            name="donations_label"
                            id="donations_label"
                            className="rs-input form-control-lg"
                            onChange={(e) => setDonations_label(e.target.value)}
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card p-3">
                    <h5>Search Tags</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <p className="p2">You don't have any search keywords.</p>

                      <div className="mt-2">
                        <TagsInput
                          value={searchTags}
                          className="rs-input"
                          onChange={handleTagsChange}
                          name="search_tags"
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="card p-3">
                    <h5>Choose Ads Settings</h5>
                    <hr></hr>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Ads Position</label>

                      <div className=" mt-2">
                        <Select
                          options={adsPositions}
                          className="rs-input"
                          onChange={(e) => handleAdsPositionChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Advertisement</label>

                      <div className=" mt-2">
                        <Select
                          options={secondSelectOptions}
                          className="rs-input"
                          onChange={(e) => setSecondSelectOptions(e)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          {/* <section className="container-fluid">
            <div className="col-lg-12 p-2">
              <div className="card text-center ">
                <div className="card-body">
                  <div className="container-fluid p-0">
                    <div className="p-3">
                      <div>
                        <h4 className="">Add New LiveStream</h4>
                        <hr />

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Title</label>
                            <p className="">
                              Add the Live stream title in the textbox below:
                            </p>
                            <div>
                              <input
                                type="text"
                                id="title"
                                name="title"
                                className="form-control"
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Title"
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Slug</label>
                            <p className="">
                              Add the Live stream slug in the textbox below:
                            </p>
                            <div>
                              <input
                                type="text"
                                id="slug"
                                name="slug"
                                className="form-control"
                                onChange={(e) => setSlug(e.target.value)}
                                placeholder="slug"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start align-items-center">
                          <div className="col-md-6">
                            <label className="m-0">
                              Live Stream Image Cover
                            </label>
                            <p className="p1">
                              Select the Live stream image ( 9:16 Ratio or
                              1080X1920px ):
                            </p>
                            <div>
                              <input
                                type="file"
                                className="form-control"
                                name="image"
                                id="image"
                                onChange={(e) => setImage(e.target.files[0])}
                                placeholder="Live_stream_video"
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <label className="m-0">Player Image Cover</label>
                              <p className="">
                                Select the Live stream image( 16:9 Ratio or
                                1280X720px ):
                              </p>
                              <div>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="player_image"
                                  id="player_image"
                                  onChange={(e) =>
                                    setPlayer_image(e.target.files[0])
                                  }
                                  placeholder="player_image"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Live Stream Source</label>
                            <p className="p1">
                              Select the Live Stream Source :
                            </p>
                            <select
                              onChange={(e) =>
                                setStorelive_source(e.target.value)
                              }
                              onClick={(event) => {
                                setalllivestream_source(event.target.value);
                              }}
                            >
                              <option value="0">Choose Live Source </option>
                              {livestream_source?.map((item, key) => (
                                <option value={item.value}>{item.name}</option>
                              ))}
                            </select>
                          </div>

                          <div>{alllive_stream_source()}</div>

                          <div className="col-sm-6">
                            <label className="m-0">TV Image Cover</label>
                            <div className="new-video-upload" id="">
                              <p className="p1">
                                Select The Live Stream TV Image (1920 X 1080 Or
                                16:9 Ratio) :
                              </p>
                              <input
                                type="file"
                                className="form-control"
                                name="Tv_image"
                                id="Tv_image"
                                onChange={(e) => setTv_Image(e.target.files[0])}
                                placeholder="Tv_live_image"
                              />
                            </div>
                            <div></div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Enable ReStream</label>

                            <Form.Check
                              type="switch"
                              checked={enable_restream}
                              onChange={handleCheckbox}
                            />

                            <Modal show={showModal} onHide={handleCloseModal}>
                              <Modal.Header closeButton>
                                <Modal.Title>Choose A Platfrom</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="row">
                                  <div className="col-md-4 youtube_col">
                                    <a className="youtube_btn btn  btn-primary active">
                                      <img
                                        className="w-50"
                                        src="https://dev-flick.webnexs.org/assets/img/you.png"
                                      />
                                      <p
                                        onClick={() =>
                                          handleButtonClick("YouTube")
                                        }
                                      >
                                        YouTube
                                      </p>
                                    </a>
                                  </div>
                                  <div className="col-md-4 facebook_col">
                                    <a
                                      className="facebook_btn btn btn-outline-primary"
                                      defaultValue="ss"
                                    >
                                      <img
                                        className="w-100"
                                        src="https://dev-flick.webnexs.org/assets/img/face.jpg"
                                      />
                                      <p
                                        onClick={() =>
                                          handleButtonClick("Facebook")
                                        }
                                      >
                                        Facebook
                                      </p>
                                    </a>
                                  </div>

                                  <div className="col-md-4 twitter_col">
                                    <a className="twitter_btn btn btn-outline-primary">
                                      <img
                                        className="w-50"
                                        src="https://dev-flick.webnexs.org/assets/img/twitter.png"
                                      />
                                      <p
                                        onClick={() =>
                                          handleButtonClick("Twitter")
                                        }
                                      >
                                        Twitter
                                      </p>
                                    </a>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleCloseModal}
                                >
                                  Close
                                </Button>
                                <Button variant="primary" onClick={handleSave}>
                                  Save
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>
                        {showInputs && (
                          <div>
                            {selectedButtons?.includes("YouTube") && (
                              <div className="row mt-3 text-start">
                                <div className="col-sm-6 ">
                                  <div className="">
                                    <label className="mb-1">
                                      {" "}
                                      YouTube Stream (RTMP URL){" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setYoutube_restream_url(e.target.value)
                                      }
                                      placeholder="youtube_restream_url"
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-6">
                                  <div className="" id="">
                                    <label className="mb-1">
                                      {" "}
                                      YouTube Stream Key{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setYoutube_streamkey(e.target.value)
                                      }
                                      placeholder="youtube_streamkey"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {selectedButtons?.includes("Facebook") && (
                              <div className="row mt-3 text-start">
                                <div className="col-sm-6">
                                  <div className="mt-2">
                                    <label className="mb-1">
                                      {" "}
                                      FaceBook Stream (RTMP URL){" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setFb_restream_url(e.target.value)
                                      }
                                      placeholder="fb_restream_url"
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-6">
                                  <div className="mt-2" id="">
                                    <label className="mb-1">
                                      {" "}
                                      FaceBook Stream Key{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setFb_streamkey(e.target.value)
                                      }
                                      placeholder="fb_streamkey"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {selectedButtons?.includes("Twitter") && (
                              <div className="row mt-3 text-start">
                                <div className="col-sm-6">
                                  <div className="mt-2">
                                    <label className="mb-1">
                                      {" "}
                                      Twitter Stream (RTMP URL){" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setTwitter_restream_url(e.target.value)
                                      }
                                      placeholder="twitter_restream_url"
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-6">
                                  <div className="mt-2">
                                    <label className="mb-1">
                                      {" "}
                                      Twitter Stream Key{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setTwitter_streamkey(e.target.value)
                                      }
                                      placeholder="twitter_streamkey"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        <div className="row mt-3 text-start">
                          <div className="col-sm-12">
                            <label className="m-0">Short Description</label>
                            <p className="">
                              Add a short description of the Livestream below:
                            </p>
                            <div className="">
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Description"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Search Tags</label>
                          
                            <TagsInput
                              value={search_tags}
                              onChange={setSearch_tags}
                              name="search_tags"
                            />
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Artists</label>
                            <div className=" form-control">
                              <div className="text-dark"></div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-12">
                            <label className="m-0">
                              Live Stream Details, Links, and Info
                            </label>
                            <div className="">
                            

                              <JoditEditor
                                value={details}
                                onChange={(newContent) =>
                                  contentFieldChanaged(newContent)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Category</label>
                            <p className="p1">
                              Select A Live Stream Category Below:
                            </p>

                            <div className=" form-control">
                              <div className="text-dark"></div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <label className="m-0">Language</label>
                            <p className="p1">
                              Select A Live Stream Language Below:
                            </p>
                            <div className="form-control">
                              <div className="text-dark"></div>
                            </div>
                          </div>
                        </div>
                        <div className="row text-start mt-4">
                          <div className="col-sm-6">
                            <label className="m-0">Age Restriction</label>

                            <div className="form-control">
                              <div className="text-dark">
                             

                                <Select
                                  options={age_res}
                                  onChange={(e) => setAge_res(e)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Block Country</label>

                            <div className="form-control">
                              <div className="text-dark"></div>
                            </div>
                          </div>
                        </div>

                        <div className="row text-start mt-4">
                          <div className="col-sm-6">
                            <label className="m-0">Live Stream Ratings</label>
                            <p className="p1">
                              Live Stream Ratings 10 Out Of 10{" "}
                            </p>
                            <div className="form-control">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => setRating(e.target.value)}
                              >
                                <option defaultValue="0">0</option>
                                <option defaultValue="1">1</option>
                                <option defaultValue="2">2</option>
                                <option defaultValue="3">3</option>
                                <option defaultValue="4">4</option>
                                <option defaultValue="5">5</option>
                                <option defaultValue="6">6</option>
                                <option defaultValue="7">7</option>
                                <option defaultValue="8">8</option>
                                <option defaultValue="9">9</option>
                                <option defaultValue="10">10</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Live Stream Year</label>
                            <p className="p1">Live Stream Released Year</p>
                            <div className="">
                              <input
                                type="text"
                                name="year"
                                className="form-control"
                                onChange={(e) => setYear(e.target.value)}
                                placeholder="Set Year"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row text-start mt-4">
                          <div className="col-sm-6">
                            <label className="m-0">Choose Ads Position</label>

                            <div className="form-control">
                            

                              <Select
                                options={adsPositions}
                                onChange={(e) => handleAdsPositionChange(e)}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <label className="m-0">Choose Advertisement</label>

                            <div className="form-control">
                              <Select
                                options={secondSelectOptions}
                                onChange={(e) => setSecondSelectOptions(e)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 text-start">
                          <div className="col-sm-6">
                            <label className="m-0">Duration</label>
                            <p className="p1">
                              Enter The Live Stream Duration In (HH : MM : SS)
                            </p>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) => setDuration(e.target.value)}
                                placeholder="duration"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 ">
                            <label className="m-0">User Access</label>
                            <p className="p1">
                              Who Is Allowed To View This Live Stream ?
                            </p>
                            <div className="form-control">
                              <Select
                                options={userAccesses}
                                onChange={(e) => handleAccessChange(e)}
                              />
                            </div>
                          </div>

                          <div className="card p-3">
                            <h5>Access</h5>
                            <hr></hr>

                            <div className="col-sm-12">
                              <label className="m-0">User Access</label>
                              <p className="p1">
                                Who Is Allowed To View This Live Stream ?
                              </p>
                              <select
                                onChange={(e) =>
                                  setStoreliveuseraccess(e.target.value)
                                }
                                onClick={(event) => {
                                  setallliveuseraccess(event.target.value);
                                }}
                              >
                                 {user_access?.map((item, key) => (
                                  <option value={item?.name}>
                                    {item?.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div>{alllive_streamuseraccess()}</div>
                          </div>
                        </div>
                        {userAccessesValue?.value === "ppv" ? (
                          <>
                            <div className="row mt-3 text-start ">
                              <div className="col-sm-4">
                                <label className="m-0">PPV Price</label>
                                <p className="p1 d-flex align-items-center">
                                  Apply PPV Price from Global Settings?
                                 
                                  <label className="switch">
                                    <input
                                      name="featured"
                                      checked={checkboxChecked}
                                      onChange={handleCheckboxChange}
                                      className="featured form-group"
                                      id="featured"
                                      type="checkbox"
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </p>

                                <div className="panel-body">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="PPV Price"
                                    name="ppv_price"
                                    id="price"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                  />
                                  <div className="clear"></div>
                                </div>
                              </div>

                              <div className="col-sm-4">
                                <label className="m-0"> IOS PPV Price</label>
                                <p className="p1">
                                  Apply IOS PPV Price from Global Settings?
                                </p>
                                <div className="panel-body form-control">
                                  <Select
                                    options={ios_ppv_price}
                                    onChange={(e) => setIos_ppv_price(e)}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}

                        <div className="row mt-3 text-start">
                          <div className="col-sm-4">
                            <label className="m-0">Publish Type</label>

                            <div className="p2">
                              <div>
                                <input
                                  // className="active"
                                  type="radio"
                                  id="publish_now"
                                  name="publish_type"
                                  defaultValue="1"
                                  checked={publish_type === "1"}
                                  onChange={() => handlePublishType("1")}
                                />
                                <label> Publish Now</label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id="publish_later"
                                  name="publish_type"
                                  defaultValue="0"
                                  checked={publish_type === "0"}
                                  onChange={() => handlePublishType("0")}
                                />
                                <label> Publish Later </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-4">
                            {publish_type === "0" && (
                              <div>
                                <label>Publish Time:</label>
                                <input
                                  type="datetime-local"
                                  id="publish_time"
                                  name="publish_time"
                                  defaultValue={publish_time}
                                  onChange={(e) =>
                                    handlePublishTime(e.target.value)
                                  }
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-sm-4" id="publishlater">
                            <label className="m-0">Status Settings</label>
                            <div>
                              <p className="p2">
                                Is this Live stream Featured:
                                <label className="switch">
                                  <input
                                    name="featured"
                                    onChange={handleInput}
                                    className="rs-input"
                                    defaultChecked={
                                      featured.featured == 1 ? true : false
                                    }
                                    checked={
                                      featured.featured == 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="featured"
                                    onChange={handleInput}
                                    value={featured.featured == 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </p>
                            </div>
                            <div>
                              <p className="p2">
                                Is this Live stream Active:
                                <label className="switch">
                                  <input
                                    name="active"
                                    onChange={handleActiveChange}
                                    className="active form-group"
                                    id="active"
                                    type="checkbox"
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </p>
                            </div>

                            <div>
                              <p className="p2">
                                Is this Live stream display in Banner:
                                <label className="switch">
                                  <input
                                    name="banner"
                                    onChange={handleBannerChange}
                                    className="banner form-group"
                                    id="banner"
                                    type="checkbox"
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="text-end">
                          <button onClick={stream} className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </div>
    </div>
  );
};

export default AddNewLiveStream;
