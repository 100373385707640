import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import "./components/museo_font/stylesheet.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18next from 'i18next'
import { initReactI18next, I18nextProvider } from 'react-i18next';
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector';
import { BrowserRouter } from "react-router-dom";
import i18n from "i18next";
import { Suspense } from "react";
import { MyProvider } from './components/Provider/CommonProvider';
// import 
i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar', 'fr', 'it', 'pl'],
    fallbackLng: 'en',
    //lng: "en",
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })
const loadingMarkup = (
  <div className="py-4 text-center">
    <h3>Loading..</h3>
  </div>
)

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <MyProvider>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter> <App /> </BrowserRouter>
        </I18nextProvider>
      </MyProvider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
