import React, { useState } from 'react'
import { Navigate } from 'react-router-dom';
import { setItem } from '../../Utils/localStorageUtils';

const PrivateRoute = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("access_token"));
    setItem('location', window.location.pathname);
    if (!isLoggedIn) {
        return <Navigate to="/login" />;
    }

    return children;
}

export default PrivateRoute