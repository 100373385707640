import React from 'react'

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const ManageVideoCategories = () => {
  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>
                        <i className="entypo-archive"></i> Video Categories
                      </h4>
                    </div>

                    <div className="col-md-6" align="right">
                      <Link
                        to="/admin/add-categories"
                        className="btn btn-primary"
                      >
                        <i className="fa fa-plus-circle"></i> Add New
                      </Link>
                    </div>
                  </div>
                  <hr />

                  <div
                    className="panel panel-primary category-panel"
                    data-collapsed="0"
                  >
                    <div className="panel-heading">
                      <div className="panel-title">
                        <p>Organize the Categories below: </p>
                      </div>

                      <div className="panel-options">
                        <Link to="#" data-rel="collapse">
                          <i className="entypo-down-open"></i>
                        </Link>
                      </div>
                    </div>

                    <div className="panel-body">
                      <div
                        id="nestable"
                        className="nested-list dd with-margins"
                      >
                        <table
                          className="table table-bordered iq-card text-center"
                          id="categorytbl"
                        >
                          <tr className="table-header r1">
                            <th>
                              <label>Category Image</label>
                            </th>
                            <th>
                              <label>Video Category Name</label>
                            </th>
                            <th>
                              <label>Operation</label>
                            </th>
                          </tr>

                          {/* {manage.map((item, key) => (
                            <tr id="">
                              <td valign="bottom" className="">
                                <img
                                  src={item.image_url}
                                  width={100}
                                  height={100}
                                />
                              </td>
                              <td valign="bottom">
                                <p>{item.name}</p>
                              </td>
                              <td>
                                <Link to="" className="view"><i className="fa fa-eye" aria-hidden="true"></i></Link>
                                <Link
                                  to={"/admin/edit-categories/" + item.id}
                                  className="edit ms-2"
                                >
                                  <i
                                    className="fa fa-pencil-square"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                                <Link to="" className="delete ms-2">
                                  <span
                                   
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          ))} */}
                        </table>

                        <div className="row text-center d-flex">
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary "
                              // onClick={handlePreClick}
                            >
                              {"<< Previous"}
                            </button>
                          </div>
                          <div className="col-lg-4">
                            <p className="">
                              {/* {page} */}
                              </p>
                          </div>
                          <div className="col-lg-4">
                            <button
                              type="button"
                              className="btn btn-primary"
                              // onClick={handleNxtClick}
                            >
                              {"Next >>"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <input type="hidden" id="_token" name="_token" value="update" />
            </div>
          </section>
        </div>
      </div>
 

    </div>
  )
}

export default ManageVideoCategories