import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import "./AddFooterMenu.css";

function AddFooterMenu(props) {
  // const [user_id, setUser_id] = useState("");
  // const [id, setId] = useState("");
  // const [name, setName] = useState("");
  // const [link, setLink] = useState("");
  // const [order, setOrder] = useState("");
  // const [column_position, setColumn_position] = useState("");
  const [column, setColumn] = useState([]);
  const [parent_id, setParent_id] = useState("0");
  const access_token = localStorage.getItem("access_token");
  const [editUser, setEditUser] = useState({
    name: "",
    link: "",
    column_position: "",
  });

  const navigate = useNavigate();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagename("");
    setIsValidationHiddenname(true);
    setValidationMessagelink("");
    setIsValidationHiddenlink(true);
    setValidationMessagecolumn_position("");
    setIsValidationHiddencolumn_position(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/footer_menu/create`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.columns;
      setColumn(res);
    };
    getUser();
  }, []);

  // async function Addfootermenudata() {
  //   const formData = new FormData();

  //   formData.append("user_id", user_id);
  //   formData.append("id", id);
  //   formData.append("name", name);
  //   formData.append("link", link);
  //   formData.append("column_position", column_position);
  //   formData.append("order", order);

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };

  //   await axios
  //     .post(
  //       `${process.env.REACT_APP_Baseurl}/admin/footer_menu/store`,
  //       formData,
  //       { headers: headers }
  //     )
  //     .then((response) => {
  //       // console.log('api' , response);
  //       if (response.data.status === true) {
  //         var result = response.data;
  //         alert("Added Successfully");
  //       } else {
  //         console.log("Error");
  //         alert("Enter Correct Details");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Error");
  //       alert("Enter Correct Details");
  //     });
  // }

  const [validationMessagename, setValidationMessagename] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);

  const nameInputRef = useRef(null);

  const [validationMessagelink, setValidationMessagelink] = useState("");
  const [isValidationHiddenlink, setIsValidationHiddenlink] = useState(true);

  const linkInputRef = useRef(null);

  const [
    validationMessagecolumn_position,
    setValidationMessagecolumn_position,
  ] = useState("");
  const [
    isValidationHiddencolumn_position,
    setIsValidationHiddencolumn_position,
  ] = useState(true);

  const column_positionInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function savemenu() {
    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagename("Name cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }
    if (editUser?.link === "") {
      setValidationMessagelink("URL cannot be empty.");
      setIsValidationHiddenlink(false);
      focusInputRef = linkInputRef;
    }
    if (editUser?.column_position === "") {
      setValidationMessagecolumn_position("Column Position cannot be empty.");
      setIsValidationHiddencolumn_position(false);
      focusInputRef = column_positionInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    // const editInputvalue = in_menu.in_menu;
    // var data = JSON.stringify(editInputvalue);
    // var aactivedata = data;

    const formData = new FormData();

    // formData.append("user_id", user_id);
    // formData.append("id", id);
    formData.append("name", editUser?.name);
    formData.append("link", editUser?.link);
    formData.append("column_position", editUser?.column_position);
    // formData.append("order", order);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/footer_menu/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;

        resultapi = response?.data;
      } else if (response?.data?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      setValidationMessagename("");
      setValidationMessagecolumn_position("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        navigate("/footermenu");
      }, 3000);
    } else if (resultapifalse) {
      // Check if resultapifalse is defined and not null
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setIsValidationHiddenname(false);
      setIsValidationHiddencolumn_position(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Add Footer Menu</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={savemenu} className="btn btn-primary">
                    Save Menu
                  </button>
                </div>
              </div>
            </div>
            <div>
              {showOverlay && (<Response_Processing></Response_Processing>)}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-12 col-md-12 col-lg-12 ">
                <div className="card p-3">
                  {/* <h5>Create Menu</h5>
                  <hr></hr> */}

                  <div className=" text-start">
                    <label className="m-0">
                      Menu Footer Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="name"
                        name="name"
                        placeholder="Enter Title"
                        onChange={handleInput}
                        value={editUser?.name}
                        ref={nameInputRef}
                      />
                      <input
                        name="name"
                        id="name"
                        placeholder="Menu Item Name"
                        className="form-control"
                        onChange={(e) => setParent_id(e.target.value)}
                        hidden
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenname && (
                        <p>{validationMessagename}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Menu Item URL<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        placeholder="Enter Title"
                        id="link"
                        name="link"
                        onChange={handleInput}
                        value={editUser?.link}
                        ref={linkInputRef}
                      // onChange={(e) => setLink(e.target.value)}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenlink && (
                        <p>{validationMessagelink}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Column position</label>

                    <div className="mt-2">
                      <select
                        name="column_position"
                        id="column_position"
                        className="form-select"
                        value={editUser?.column_position}
                        ref={column_positionInputRef}
                        onChange={handleInput}
                      // onChange={(e) => setColumn_position(e.target.value)}
                      >
                        <option value="">Choose an Column</option>
                        {column?.map((item, key) => (
                          <option key={item?.id} value={item?.name}>
                            {" "}
                            {item?.Column}
                          </option>
                        ))}
                      </select>
                    </div>
                    <span className="errorred">
                      {!isValidationHiddencolumn_position && (
                        <p>{validationMessagecolumn_position}</p>
                      )}
                    </span>
                  </div>
                </div>

                {/* <div className="text-start mb-3">
                  <button onClick={savemenu} className="btn btn-primary">
                    Save Footer Menu
                  </button>
                </div> */}
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div id="content-page" className="content-page">
              <div className="mt-4">
                <div className="admin-section-title">
                  <div className="iq-card">
                    <div className="modal-header">
                      <h4 className="modal-title">New Footer Link</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        &times;
                      </button>
                    </div>
                    <div>
                      <div className="modal-body">
                        <input type="hidden" name="_token" value="" />

                        <div className="form-group">
                          <label>Name:</label>
                          <input
                            type="text"
                            id="footer_name"
                            name="footer_name"
                            onChange={(e) => setName(e.target.value)}
                            className="form-control"
                            placeholder="Enter the Name"
                          />
                          <input
                            type="text"
                            id="parent_id"
                            name="parent_id"
                            onChange={(e) => setParent_id(e.target.value)}
                            className="form-control"
                            placeholder="Enter the parent_id"
                            hidden
                          />
                        </div>

                        <div className="form-group">
                          <label>Link:</label>
                          <input
                            type="text"
                            id="footer_link"
                            name="footer_link"
                            onChange={(e) => setLink(e.target.value)}
                            className="form-control"
                            placeholder="Enter the Link"
                          />
                        </div>

                        <div className="form-group">
                          <label>Column position :</label>

                          <select
                            name="column_position"
                            id="column_position"
                            className="form-control"
                            onChange={(e) => setColumn_position(e.target.value)}
                          >
                            {column?.map((item, key) => (
                              <option key={item?.id} value={item?.name}>
                                {" "}
                                {item?.Column}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          id="submit-new-footer"
                          onClick={Addfootermenudata}
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default AddFooterMenu;
