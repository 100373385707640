import React from "react";

const Dash_Board = () => {
  return (
    <div>
      <div className="row pt-3 m-0">
        <div className="col-md-8">
          <div className="row">
            <div className="col-lg-3">
              <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-body1">
                  <div className="iq-cart-text d-flex align-items-center justify-content-between ">
                    <div>
                      <p className="mb-0  fw-bold">View</p>
                    </div>
                    <div>
                      <i className="fa fa-eye fs-2" aria-hidden="true"></i>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div>
                      <h4 className=" mb-0">4.798k</h4>
                    </div>
                    <div>
                      <h6 className="mb-0 text-primary">
                        <span>
                          <i className="fa fa-caret-down mr-2"></i>
                        </span>
                        35%
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-body1">
                  <div className="iq-cart-text d-flex align-items-center justify-content-between ">
                    <div>
                      <p className="mb-0  fw-bold">Rated This App</p>
                    </div>
                    <div>
                      <i className="fa fa-eye fs-2" aria-hidden="true"></i>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-1">
                    <div>
                      <h4 className=" mb-0">+55K</h4>
                    </div>
                    <div>
                      <h6 className="mb-0 text-primary">
                        <span>
                          <i className="fa fa-caret-down mr-2"></i>
                        </span>
                        50%
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-body1">
                  <div className="iq-cart-text d-flex align-items-center justify-content-between ">
                    <div>
                      <p className="mb-0  fw-bold">Downloaded</p>
                    </div>
                    <div>
                      <i className="fa fa-eye fs-2" aria-hidden="true"></i>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div>
                      <h4 className=" mb-0">+1M</h4>
                    </div>
                    <div>
                      <h6 className="mb-0 text-primary">
                        <span>
                          <i className="fa fa-caret-down mr-2"></i>
                        </span>
                        80%
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                <div className="iq-card-body1">
                  <div className="iq-cart-text d-flex align-items-center justify-content-between ">
                    <div>
                      <p className="mb-0  fw-bold">Visitors</p>
                    </div>
                    <div>
                      <i className="fa fa-eye fs-2" aria-hidden="true"></i>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div>
                      <h4 className=" mb-0">10</h4>
                    </div>
                    <div>
                      <h6 className="mb-0 text-primary">
                        <span>
                          <i className="fa fa-caret-down mr-2"></i>
                        </span>
                        100%
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card info-card user-webnexs">
            <div className="card-body">
              <h5 className="card-title fw-bold text-center">
                User's Of Webnexs
              </h5>
              <div className="d-flex align-items-center p-3">
                <div className="col-lg-2 text-center">
                  {" "}
                  <span className="small pt-1 fs-6 fw-bold">375</span>
                </div>
                <div className="col-lg-2 text-center">
                  {" "}
                  <span> | </span>{" "}
                </div>
                <div className="col-lg-8">
                  {" "}
                  <span className="text-muted small fs-6 pt-2 ps-1">
                    Total Subscriber's
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center p-3">
                <div className="col-lg-2 text-center">
                  {" "}
                  <span className="small pt-1 fs-6 fw-bold">5</span>
                </div>
                <div className="col-lg-2 text-center">
                  {" "}
                  <span> | </span>{" "}
                </div>
                <div className="col-lg-8">
                  {" "}
                  <span className="text-muted small fs-6 pt-2 ps-1">
                    New Subscriber's
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center p-3">
                <div className="col-lg-2 text-center">
                  {" "}
                  <span className="small pt-1 fs-6 fw-bold">75</span>
                </div>
                <div className="col-lg-2 text-center">
                  {" "}
                  <span> | </span>{" "}
                </div>
                <div className="col-lg-8">
                  {" "}
                  <span className="text-muted small fs-6 pt-2 ps-1">
                    Total Video's
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center p-3">
                <div className="col-lg-2 text-center">
                  {" "}
                  <span className="small pt-1 fs-6 fw-bold">75</span>
                </div>
                <div className="col-lg-2 text-center">
                  {" "}
                  <span> | </span>{" "}
                </div>
                <div className="col-lg-8">
                  {" "}
                  <span className="text-muted small fs-6 pt-2 ps-1">
                    Total Visitor's
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dash_Board;
