import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../src/layout/Others/Response_Loading_processing";

function Edit_FAQ(props) {
  const { id } = useParams();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    question: "",
    answer: "",
    genre_id: "",
  });
  const [editname, setEditname] = useState({
    name: "",
  });

  const access_token = localStorage.getItem("access_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange = (event, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = () => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/faq/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.FAQ;
      setEditUser(res);
      setEditname(res);
      setLoading(false);
    };
    getUser();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/faq/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.GenreFAQ;
        setData(result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagequestion("");
    setIsValidationHiddenquestion(true);
    setValidationMessageanswer("");
    setIsValidationHiddenanswer(true);
    setvalidationMessagegenre("");
    setisValidationHiddengenre(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };


  const [validationMessagequestion, setValidationMessagequestion] =
    useState("");
  const [isValidationHiddenquestion, setIsValidationHiddenquestion] =
    useState(true);

  const [validationMessageanswer, setValidationMessageanswer] =
    useState("");
  const [isValidationHiddenanswer, setIsValidationHiddenanswer] =
    useState(true);

  const [validationMessagegenre, setvalidationMessagegenre] =
    useState("");
  const [isValidationHiddengenre, setisValidationHiddengenre] =
    useState(true);


  const questionInputRef = useRef(null);
  const answerInputRef = useRef(null);
  const genreInputRef = useRef(null);


  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.question === "") {
      setValidationMessagequestion("FAQ Question cannot be empty.");
      setIsValidationHiddenquestion(false);
      focusInputRef = questionInputRef;
    }
    if (editUser?.answer === "") {
      setValidationMessageanswer("FAQ Answer cannot be empty.");
      setIsValidationHiddenanswer(false);
      focusInputRef = answerInputRef;
    }
    if (editUser?.genre_id === "") {
      setvalidationMessagegenre("Please select Genre.");
      setisValidationHiddengenre(false);
      focusInputRef = genreInputRef;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      question: editUser?.question,
      answer: editUser?.answer,
      genre_id: editUser?.genre_id,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/faq/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      } else if (resjson?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      setValidationMessagequestion("");
      setValidationMessageanswer("");
      setvalidationMessagegenre("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      // Check if resultapifalse is defined and not null
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddenquestion(false);
      setIsValidationHiddenanswer(false);
      setisValidationHiddengenre(false);
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <section className="container-fluid">
          <div className="col-lg-12 row py-3">
            <div className="col-6 col-sm-6 col-lg-6">
              <h3>Edit Category</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Category
                </button>
              </div>
            </div>
          </div>

          <div>
            {showOverlay && <Response_Processing></Response_Processing>}
          </div>

          <div className="row col-lg-12 ">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
              <div className="card p-3">
                <h5>{editname?.name}</h5>
                <hr></hr>
                <div className=" text-start">
                  <label className="m-0">
                    FAQ Question
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="question"
                      name="question"
                      onChange={handleInput}
                      value={editUser?.question}
                      ref={questionInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddenquestion && (
                      <p>{validationMessagequestion}</p>
                    )}
                  </span>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0">FAQ Answer</label>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="answer"
                      name="answer"
                      onChange={handleInput}
                      value={editUser?.answer}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddenanswer && (
                      <p>{validationMessageanswer}</p>
                    )}
                  </span>
                </div>

                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0">FAQ Genre</label>

                    <div className="mt-2">
                      <select
                        className="form-select"
                        name="genre_id"
                        id="genre_id"
                        value={editUser?.genre_id}
                        onChange={handleInput}
                      >
                        <option value="">Select a Genre</option>
                        {data?.map((item) => (
                          <option value={item?.id}>{item?.name}</option>
                        ))}
                      </select>
                    </div>


                    <span className="errorred">
                      {!isValidationHiddengenre && (
                        <p>{validationMessagegenre}</p>
                      )}
                    </span>

                  </div>
                </div>
              </div>

              <div className="text-start mb-3">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Category
                </button>
              </div>
            </div>

          </div>
        </section>
      </div>
    </>
  );
}

export default Edit_FAQ;
