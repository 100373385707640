import React, { useState } from 'react'
import MessageBox from './MessageBox'
import AdminCustomButton from './AdminCustomButton'
import { IoClose } from 'react-icons/io5'

const AdminSelectOptionModal = ({ modelClose, closeModal, action, buttonText, message, modalHead, option, selectId }) => {

    const [id, setId] = useState({})
    const handleInput = (e) => {
        const { name, value } = e.target
        setId((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    return (
        <div class={`modal  packageModel fade ${modelClose && 'd-block show'}`} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content  theme-bg-color ">
                    <div className="modal-header theme-border-bottom-secondary">
                        <MessageBox text={modalHead} classname="modal-title theme-text-color admin-modal-title fw-bold" />
                        <button type="button" className='bg-transparent' onClick={() => closeModal()} ><IoClose fill='currentColor' style={{ width: "25px", height: "25px" }} /></button>
                    </div>
                    <div className="modal-body">
                        <select name="single_series_id" size="lg" onChange={handleInput} value={id?.single_series_id} defaultValue={selectId} className="form-select custom-placeholder  mb-3 theme-bg-color-secondary theme-text-color border-0">
                            <option value="" className="theme-bg-color theme-text-color"> Choose an Series</option>
                            {option?.map((data, index) => { return <option value={data?.id} className="theme-bg-color theme-text-color">{data?.title}</option> })}
                        </select>
                    </div>
                    <div className="modal-footer  border-0">
                        <button type="button" className="bg-transparent theme-text-color sliderBtnText px-2" onClick={() => closeModal()} ><MessageBox text="Cancel" /> </button>
                        <AdminCustomButton className='px-3 py-1 m-0 ' saveMethod={() => action(id?.single_series_id)} saveText='Update' ></AdminCustomButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminSelectOptionModal