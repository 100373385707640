import { finalRouteApi } from "./CommonApiUrl"


const showOrderDefaultURL = {
    listApi: `${finalRouteApi}/TvShows-HomePage-Order/index`,
    orderApi: `${finalRouteApi}/TvShows-HomePage-Order/ordering`,
    editApi: `${finalRouteApi}/TvShows-HomePage-Order/edit`,
    updateApi: `${finalRouteApi}/TvShows-HomePage-Order/update`,
    editRouteLink: `${finalRouteApi}/Audio_URL`,
    enableSettingURL: `${finalRouteApi}/Web-TvShowsHomePage/index`,
    updateSettingURL: `${finalRouteApi}/Web-TvShowsHomePage/update`,
    enableSettingMobileURL: `${finalRouteApi}/Mobile-TvShowsHomePage/index`,
    singleSeriesURL: `${finalRouteApi}/Web-TvShowsHomePage/Single_Series_Update`,
}

export { showOrderDefaultURL }