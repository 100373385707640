// import React, { useState } from "react";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import { Line } from "react-chartjs-2";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   // PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// function CPPAnalytics() {
//   const sub = [
//     {
//       email: "demos@webnexs.in",
//       name: "Sanjai Kumar",
//       uploads: "1",
//       views: "1",
//       comments: "",
//     },
//   ];
//   const [isTrue, setIsTrue] = useState(true);

//   const handleChange = () => {
//     setIsTrue(false);
//   };
//   const handleOpen = () => {
//     setIsTrue(true);
//   };

//   const options = {
//     type: "line",
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "top",
//       },
//     },
//   };

//   const label = [];

//   const data = {
//     label,
//     datasets: [
//       {
//         label: "Moderator Users Analytics",

//         data: [0.0, 0.0, 0.0, -1.0, 1.0],
//         borderColor: "rgb(53, 162, 235)",
//         lineTension: 0.4,
//       },
//     ],
//   };

//   const labels = ["Audio", "Video", "Live Stream"];

//   const datas = {
//     labels,
//     datasets: [
//       {
//         label: "Content Partners Uploded Content",

//         data: [0.0, 0.0, 0.0, -1.0, 1.0],
//         borderColor: "rgb(53, 162, 235)",
//         lineTension: 0.4,
//       },
//     ],
//   };
//   return (
//     <>
//       <div className=" m-0 p-0">
//         <div className="">
//           <section className="section container-fluid pt-3">
//             <div id="content-page" className="content-page">
//               <div className="iq-card-header  justify-content-between">
//                 <div className="iq-header-title">
//                   <h4 className="card-title">
//                     Content Partners Analytics
//                   </h4>
//                 </div>
//               </div>
//               <div className="iq-card mt-3">
//                 <div className="col-md-12">
//                   <div className="row p-3">
//                     <div className="col-md-12">
//                       <div className="row mt-3">
//                         <div className="col-md-3">
//                           <label for="start_time"> Start Date: </label>
//                           <input
//                             type="date"
//                             className="form-control"
//                             id="start_time"
//                             name="start_time"
//                           />
//                         </div>
//                         <div className="col-md-3">
//                           <label for="start_time"> End Date: </label>
//                           <input
//                             type="date"
//                             className="form-control"
//                             id="end_time"
//                             name="end_time"
//                           />
//                         </div>

//                         <div className="col-md-3 d-flex align-items-end">
//                           <div className="dropdown">
//                             <button
//                               className="btn btn-outline-dark filter-btn dropdown-toggle"
//                               type="button"
//                               data-bs-toggle="dropdown"
//                               aria-expanded="false"
//                             >
//                               Filter
//                             </button>
//                             <ul className="dropdown-menu">
//                               <li>
//                                 <a className="dropdown-item" href="#">
//                                   1 Day
//                                 </a>
//                               </li>
//                               <li>
//                                 <a className="dropdown-item" href="#">
//                                   1 Week
//                                 </a>
//                               </li>
//                               <li>
//                                 <a className="dropdown-item" href="#">
//                                   1 Month{" "}
//                                 </a>
//                               </li>
//                             </ul>
//                           </div>
//                         </div>

//                         <div className="col-md-3 d-flex align-items-end">
//                           <span
//                             id="export"
//                             className="btn btn-primary download-btn btn-sm"
//                           >
//                             Download CSV
//                           </span>
//                         </div>
//                       </div>

//                       <div>
//                         <div className="row mt-5">
//                           <h5>Content Partners Content</h5>
//                           <div className="col-md-6 mt-3">
//                             <div>
//                               <h5>Total No. Of Video Content : 0</h5>
//                               <h5>Total No. Of Live Video Content : 0</h5>
//                               <h5>Total No. Of Audio Content : 0</h5>
//                             </div>
//                           </div>
//                           <div className="col-md-6">
//                             <div className="form-check">
//                               <input
//                                 type="radio"
//                                 className="form-check-input"
//                                 id="validationFormCheck2"
//                                 name="radio-stacked"
//                                 checked="checked"
//                                 required
//                                 onClick={handleOpen}
//                               />
//                               <p
//                                 className="form-check-label"
//                                 for="validationFormCheck2"
//                               >
//                                 Total Content By Users
//                               </p>
//                             </div>
//                             <div className="form-check mb-3">
//                               <input
//                                 type="radio"
//                                 className="form-check-input"
//                                 id="validationFormCheck3"
//                                 name="radio-stacked"
//                                 required
//                                 onClick={handleChange}
//                               />
//                               <p
//                                 className="form-check-label"
//                                 for="validationFormCheck3"
//                               >
//                                 {" "}
//                                 Filtered Content By Users
//                               </p>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       {isTrue ? (
//                         <>
//                           <div className="mt-5">
//                             <h5>Content View Through Graph :</h5>
//                           </div>
//                           <div>
//                             <div className="row mt-5">
//                               <div className="col-md-2"></div>
//                               <div className="col-md-7">
//                                 <Line options={options} data={data} />
//                                 <p className="text-center">
//                                   Moderator Users Analytics
//                                 </p>
//                               </div>
//                               <div className="col-md-3"></div>
//                             </div>
//                           </div>
//                         </>
//                       ) : (
//                         <div>
//                           <div className="row mt-5">
//                             <div className="col-md-2"></div>
//                             <div className="col-md-7">
//                               <Line options={options} data={datas} />
//                               <p className="text-center">Time of Day</p>
//                             </div>
//                             <div className="col-md-3"></div>
//                           </div>
//                         </div>
//                       )}

//                       <div id="PayPerView_content">
//                         <div className="row ">
//                           <div className="col-md-12">
//                             <table className="table" id="cpp_revenue_table">
//                               <thead>
//                                 <tr className="r1">
//                                   <th>#</th>
//                                   <th>Email </th>
//                                   <th>Uploader Name</th>
//                                   <th>No Of Uploads</th>
//                                   <th>Total Views</th>
//                                   <th>Total Comments</th>
//                                 </tr>
//                               </thead>
//                               {sub.map((item, key) => (
//                                 <thead>
//                                   <tr key={item?.id} className="r1">
//                                     <td key={key}> {key + 1} </td>
//                                     <td>{item.email}</td>
//                                     <td>{item.name}</td>
//                                     <td>{item.uploads}</td>
//                                     <td>{item.views} </td>
//                                     <td>{item.comments} </td>
//                                   </tr>
//                                 </thead>
//                               ))}
//                               <tbody></tbody>
//                             </table>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </div>
//       </div>
//     </>
//   );
// }

// export default CPPAnalytics;


import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import './CPPAnalytics.css'
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

const CPPAnalytics = () => {
  // const [datas, setDatas] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);

      // Replace the following URL with your actual GET API endpoint
      const response = await fetch(
        `https://api.example.com/users?start_date=${startDate}&end_date=${endDate}`,
        {
          method: "GET",
          headers: {
            // Add headers if necessary
          },
        }
      );

      const data = await response.json();
      // setDatas(data);
      setUserCount(data.length); // Update this based on your data structure

      setFilteredData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(async () => {
    fetchData();
  }, [startDate, endDate]);

  const handleExport = async () => {
    try {
      // Replace the following URL with your actual POST API endpoint for exporting data
      const exportResponse = await fetch("https://api.example.com/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          startDate,
          endDate,
          selectedData: filteredData,
        }),
      });

      if (exportResponse.ok) {
        const blob = await exportResponse.blob();

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "exported_data.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Export failed:", exportResponse.statusText);
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const optionsdata = {
    type: "line",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const label = [];

  const data = {
    label,
    datasets: [
      {
        label: "Moderator Users Analytics",

        data: [0.0, 0.0, 0.0, -1.0, 1.0],
        borderColor: "rgb(53, 162, 235)",
        lineTension: 0.4,
      },
    ],
  };

  const labels = ["Audio", "Video", "Live Stream"];

  const datas = {
    labels,
    datasets: [
      {
        label: "Content Partners Uploded Content",

        data: [0.0, 0.0, 0.0, -1.0, 1.0],
        borderColor: "rgb(53, 162, 235)",
        lineTension: 0.4,
      },
    ],
  };

  const columns = [
    {
      dataField: "user",
      text: "USER",
    },
    {
      dataField: "email",
      text: "EMAIL",
    },
    {
      dataField: "uploader_name",
      text: "UPLOADER NAME",
    },
    {
      dataField: "no_Of_uploads",
      text: "NO OF UPLOADS",
    },
    {
      dataField: "total_views",
      text: "TOTAL VIEWS",
    },
    {
      dataField: "Total_comments",
      text: "TOTAL COMMENTS",
    },
    // Add more columns as needed
  ];

  const options = {
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: Number(filteredData?.length) },
    ],
  };

  return (
    <div className="m-0 p-0">
      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="iq-card-header justify-content-between">
            <div className="iq-header-title p-0">
              <h4 className="card-title">Content Partners Analytics</h4>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="row p-3">
              <div className="col-md-12">
                <div className="row align-items-center">
                  <div class="row">
                    <div class="col-md-6 offset-md-3">
                      <div className="col-md-12 user-count">
                        <div className="col-lg-12 row">
                          <div className="col-10 col-md-10 col-sm-10 col-lg-10">
                            <label className="user_reg_analytics_count">Total No. Of Video Content </label>
                          </div>
                          <div className="col-2 col-md-2 col-sm-2 col-lg-2">
                            <label className="user_reg_analytics_count">{userCount}</label>
                          </div>
                        </div>
                        <div className="col-lg-12 row">
                          <div className="col-10 col-md-10 col-sm-10 col-lg-10">
                            <label className="user_reg_analytics_count">Total No. Of Live Video Content</label>
                          </div>
                          <div className="col-2 col-md-2 col-sm-2 col-lg-2">
                            <label className="user_reg_analytics_count">{userCount}</label>
                          </div>
                        </div>
                        <div className="col-lg-12 row">
                          <div className="col-10 col-md-10 col-sm-10 col-lg-10">
                            <label className="user_reg_analytics_count">Total No. Of Audio Content</label>
                          </div>
                          <div className="col-2 col-md-2 col-sm-2 col-lg-2">
                            <label className="user_reg_analytics_count">{userCount}</label>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 CPP_ANALYTICS mt-3">
                    <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Moderator Analytics</button>
                      </li>
                      <li className="nav-item ms-2" role="presentation">
                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">CPP Content</button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <Line options={optionsdata} data={data} /></div>
                      <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <Line options={optionsdata} data={datas} /></div>
                    </div>


                    <p className="text-center">Register Users Count</p>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mt-3">
                    <div className="row align-items-center">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                        <label htmlFor="start_time"> Start Date: </label>
                        <input
                          type="date"
                          className="rs-input form-control-lg"
                          id="start_time"
                          name="start_time"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                        <label htmlFor="end_time"> End Date: </label>
                        <input
                          type="date"
                          className="rs-input form-control-lg"
                          id="end_time"
                          name="end_time"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                      {/* <div className="col-md-3 d-flex align-items-end">
                      <div className="dropdown">
                      <button
                        className="btn btn-outline-dark filter-btn dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Filter
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              const currentDate = new Date();
                              setEndDate(
                                currentDate.toISOString().split("T")[0]
                              );
                              setStartDate(
                                new Date(
                                  currentDate.setDate(currentDate.getDate() - 1)
                                )
                                  .toISOString()
                                  .split("T")[0]
                              );
                            }}
                          >
                            1 Day
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              const currentDate = new Date();
                              setEndDate(
                                currentDate.toISOString().split("T")[0]
                              );
                              setStartDate(
                                new Date(
                                  currentDate.setDate(currentDate.getDate() - 7)
                                )
                                  .toISOString()
                                  .split("T")[0]
                              );
                            }}
                          >
                            1 Week
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              const currentDate = new Date();
                              setEndDate(
                                currentDate.toISOString().split("T")[0]
                              );
                              setStartDate(
                                new Date(
                                  currentDate.setMonth(
                                    currentDate.getMonth() - 1
                                  )
                                )
                                  .toISOString()
                                  .split("T")[0]
                              );
                            }}
                          >
                            1 Month{" "}
                          </a>
                        </li>
                      </ul>
                      </div>
                      </div> */}
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-right">
                        <span
                          id="export"
                          className="btn btn-success w-50"
                          onClick={handleExport}
                        >
                          Export
                        </span>
                      </div>
                    </div>

                    <div className="d-flex mt-2 justify-content-between align-items-center flex-wrap mb-3 gap-3">
                      <div className="filterContainer">
                        <input
                          type="text"
                          placeholder="Filter all.."
                          className="filter form-control "
                          value={filter}
                          onChange={(e) => setFilter(e.target.value)}
                        />
                        {filter?.length > 0 && (
                          <button
                            type="button"
                            className="closeBtn"
                            onClick={() => setFilter("")}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        )}
                      </div>
                      <PaginationProvider
                        pagination={paginationFactory(options)}
                      >
                        {({ paginationTableProps }) => (
                          <div className="bootstrapTable p-2">
                            <BootstrapTable
                              keyField="id"
                              columns={columns}
                              data={filteredData}
                              className="no-border-table"
                              noDataIndication={
                                <div className="noRecord">
                                  {loading ? (
                                    <label>
                                      <Response_Loading_processing></Response_Loading_processing>
                                      Loading...
                                    </label>
                                  ) : (
                                    filteredData?.length <= 0 && (
                                      <p>No Record Found!</p>
                                    )
                                  )}
                                </div>
                              }
                              hover
                              headerclassNamees="videoWidth"
                              selectAll={selectAll}
                              {...paginationTableProps}
                            />
                          </div>
                        )}
                      </PaginationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CPPAnalytics;

