import React, { useState, useEffect } from "react";
import axios from "axios";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import "./Stripe.css";
import { BiLinkExternal } from "react-icons/bi";

function Stripe(props) {
  const [loading, setLoading] = useState(true);
  const [stripe, setStripe] = useState({
    id: "",
    user_id: "",
    payment_type: "",
    payment_mode: "",
    test_secret_key: "",
    test_publishable_key: "",
    live_secret_key: "",
    live_publishable_key: "",
    plan_name: "",
    stripe_label: "",
    status: "",
    coupon_status: "",
  });
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/stripe_payment/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var result = response?.data?.stripe_payment;
        setStripe(result);
        setLoading(false);
        //   console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setStripe({ ...stripe, [e.target.name]: 1 });
    } else {
      setStripe({ ...stripe, [e.target.name]: 0 });
    }
  };

  const handleInput = (e) => {
    setStripe({ ...stripe, [e.target.name]: e.target.value });
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const stripepayment = async (e) => {
    e.preventDefault();

    const payment_mode = Number.isNaN(parseInt(stripe?.payment_mode, 10)) ? 0 : parseInt(stripe?.payment_mode, 10);
    const status = Number.isNaN(parseInt(stripe?.status, 10)) ? 0 : parseInt(stripe?.status, 10);

    const editInputvalue = {
      id: stripe?.id,
      user_id: stripe?.user_id,
      payment_type: stripe?.payment_type,
      payment_mode: payment_mode,
      test_secret_key: stripe?.test_secret_key,
      test_publishable_key: stripe?.test_publishable_key,
      live_secret_key: stripe?.live_secret_key,
      live_publishable_key: stripe?.live_publishable_key,
      // plan_name: stripe.plan_name,
      stripe_label: stripe?.stripe_label,
      status: status,
      subscription_trail_days: stripe?.subscription_trail_days,
      subscription_trail_status: stripe?.subscription_trail_status,
      coupon_status: stripe && stripe.coupon_status !== null && stripe.coupon_status !== undefined ? parseInt(stripe.coupon_status, 10) : 0,
      payment_type: "Stripe",
      // plan_name: "default",
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/stripe_payment/store`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res?.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson?.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  const stripe_redirect = () => {
    window.open("https://stripe.com/docs", "_blank");
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row mt-3">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3" >
                <h3>Stripe Payment</h3>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row  ">
              <div className="col-md-6 col-12 ">
                <div className="card p-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5>Stripe Configuration</h5>
                    <div className="card mb-0">
                      <a
                        href="https://stripe.com/docs"
                        target="_blank"
                        title="Stripe Payment Plan"
                        className=" px-3 py-2"
                      >
                        https://stripe.com/docs
                        <BiLinkExternal className="text-dark ms-2" />
                      </a>
                    </div>
                  </div>
                  <hr></hr>
                  <div className=" text-start">
                    <div className="row mt-2">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <label className="m-0">Enable Payment Mode</label>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6 text-end">
                        <div className="col-lg-12 row">
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            <p> TEST </p>
                          </div>
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            {" "}
                            <label className="switch">
                              <input
                                name="payment_mode"
                                id="payment_mode"
                                onChange={handleInputenable}
                                className="rs-input"
                                defaultChecked={
                                  stripe?.payment_mode == 1 ? true : false
                                }
                                checked={
                                  stripe?.payment_mode == 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="payment_mode"
                                id="payment_mode"
                                onChange={handleInputenable}
                                value={stripe?.payment_mode == 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            <p> LIVE </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <label className="m-0">Enable Stripe Mode</label>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6 text-end">
                        <div className="col-lg-12 row">
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            <p> OFF </p>
                          </div>
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            {" "}
                            <label className="switch">
                              <input
                                name="status"
                                id="status"
                                onChange={handleInputenable}
                                className="rs-input"
                                defaultChecked={
                                  stripe?.status == 1 ? true : false
                                }
                                checked={stripe?.status == 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                id="status"
                                onChange={handleInputenable}
                                value={stripe?.status == 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            <p> ON </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <label className="m-0">Enable Coupon Status</label>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6 text-end">
                        <div className="col-lg-12 row">
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            <p> OFF </p>
                          </div>
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            {" "}
                            <label className="switch">
                              <input
                                name="coupon_status"
                                id="coupon_status"
                                onChange={handleInputenable}
                                className="rs-input"
                                defaultChecked={
                                  stripe?.coupon_status == 1 ? true : false
                                }
                                checked={stripe?.coupon_status == 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="coupon_status"
                                id="coupon_status"
                                onChange={handleInputenable}
                                value={stripe?.coupon_status == 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            <p> ON </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className=" text-start mt-2">
                    <div className="row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <label className="m-0">
                          Enable Stripe Subscription Trail Status
                        </label>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3 text-end">
                        <p>Test</p>
                        <label className="switch">
                          <input
                            name="subscription_trail_status"
                            id="subscription_trail_status"
                            onChange={handleInputenable}
                            className="rs-input"
                            defaultChecked={
                              stripe?.subscription_trail_status == 1
                                ? true
                                : false
                            }
                            checked={
                              stripe?.subscription_trail_status == 1
                                ? true
                                : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="subscription_trail_status"
                            id="subscription_trail_status"
                            onChange={handleInputenable}
                            value={
                              stripe?.subscription_trail_status == 1 ? "1" : "0"
                            }
                          ></span>
                        </label>
                        <p>Live</p>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="mt-2 text-start">
                    <label className="m-0">Stripe Payment Plan</label>

                    <div className="mt-2">
                      <Link onClick={stripe_redirect}>
                        (https://www.stripe.com/docs)
                      </Link>
                    </div>
                  </div> */}

                  {/* <div className=" text-start mt-2">
                    <label className="m-0">Stripe Name</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="plan_name"
                        name="plan_name"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={stripe?.plan_name}
                      />
                    </div>
                  </div> */}
                  <div className=" text-start mt-2">
                    <label className="m-0">Stripe Label</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="stripe_label"
                        name="stripe_label"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={stripe?.stripe_label}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="text-start mb-3">
                  <button onClick={stripepayment} className="btn btn-primary">
                    Update Stripe
                  </button>
                </div> */}
              </div>
              {/* Second Part of Section  */}
              <div className="col-md-6 col-12">
                <div className="card p-3">
                  <h5>Stripe Key Details</h5>

                  <hr></hr>

                  <div className="mt-2 text-start">
                    <label className="m-0">Live Publishable Key</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="live_publishable_key"
                        name="live_publishable_key"
                        value={stripe?.live_publishable_key}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Live Secret Key</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="live_secret_key"
                        name="live_secret_key"
                        value={stripe?.live_secret_key}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Test Publishable Key</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="test_publishable_key"
                        name="test_publishable_key"
                        value={stripe?.test_publishable_key}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Test Secret Key</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="test_secret_key"
                        name="test_secret_key"
                        value={stripe?.test_secret_key}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                  {/* <div className="mt-2 text-start">
                    <label className="m-0">
                      Stripe Subscription Trail Days
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="subscription_trail_days"
                        name="subscription_trail_days"
                        value={stripe?.subscription_trail_days}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="text-end mb-3">
                <button onClick={stripepayment} className="btn btn-primary">
                  Update Stripe
                </button>
              </div>
            </div>

          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className=" p-0">
              <div className="iq-card">
                <div id="admin-container">
                  <div className="admin-section-title">
                    <h4>
                      <i className="entypo-globe"></i> Payment Settings
                    </h4>
                    <hr />
                  </div>
                  <div>
                    <p>
                      <h3>Stripe Payment</h3>
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <label for="">Payment Mode</label>
                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>Disable</div>

                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="status"
                                onChange={handleInput}
                                defaultChecked={
                                  stripe?.status === 1 ? true : false
                                }
                                checked={stripe?.status === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                onChange={handleInput}
                                value={stripe?.status === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>

                          <div>Enable</div>
                        </div>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6">
                        <label for="">Stripe Mode</label>
                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>OFF</div>

                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="payment_mode"
                                onChange={handleInput}
                                defaultChecked={
                                  stripe?.payment_mode === 1 ? true : false
                                }
                                checked={
                                  stripe?.payment_mode === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="payment_mode"
                                onChange={handleInput}
                                value={stripe?.payment_mode === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>

                          <div>ON</div>
                        </div>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6">
                        <div className="panel-title">
                          Stripe Plan (
                          <Link
                            to="https://stripe.com/docs/tutorials/dashboard"
                            target="_blank"
                          >
                            https://stripe.com/docs/tutorials/dashboard
                          </Link>
                          )
                        </div>
                        <div className="panel-body">
                          <label>Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="plan_name"
                            placeholder="Test Secret Key"
                            value={stripe?.plan_name}
                            onChange={handleInput}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Stripe Label:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="stripe_label"
                          placeholder="Stripe Lable"
                          value={stripe?.stripe_label}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Test Publishable Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="test_publishable_key"
                          placeholder="Test Publishable Key"
                          value={stripe?.test_publishable_key}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Test Secret Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="test_secret_key"
                          value={stripe?.test_secret_key}
                          placeholder="Test Secret Key"
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Live Publishable Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="live_publishable_key"
                          value={stripe?.live_publishable_key}
                          placeholder="Live Publishable Key"
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Live Secret Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="live_secret_key"
                          placeholder="Live Secret Key"
                          value={stripe?.live_secret_key}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label for="">
                          {" "}
                          Stripe Subscription Trail Status:{" "}
                        </label>
                        <div className="d-flex  align-items-center">
                          <div> Disable </div>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="subscription_trail_status"
                                onChange={handleInput}
                                defaultChecked={
                                  stripe?.subscription_trail_status === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  stripe?.subscription_trail_status === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="subscription_trail_status"
                                onChange={handleInput}
                                value={
                                  stripe?.subscription_trail_status === 1
                                    ? "1"
                                    : "0"
                                }
                              ></span>
                            </label>
                          </div>
                          <div> Enable </div>
                          <div
                            className="make-switch"
                            data-on="success"
                            data-off="warning"
                          ></div>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <label> Stripe Subscription Trail Days: </label>
                        <input
                          type="number"
                          min="1"
                          className="form-control"
                          name="subscription_trail_days"
                          id="subscription_trail_days"
                          placeholder=" 1 - 100 "
                          value={stripe?.subscription_trail_days}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <br />
                    <p>
                      <h3>PayPal Payment</h3>
                    </p>
                    <div className="row">
                      <div className="col-md-6 mt-3">
                        <label for="">Payment Mode</label>

                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>Disable</div>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="status"
                                onChange={handleInput}
                                defaultChecked={
                                  paypal_payment?.status === 1 ? true : false
                                }
                                checked={
                                  paypal_payment?.status === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                onChange={handleInput}
                                value={paypal_payment?.status === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                          <div>Enable</div>
                        </div>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <label for="">PayPal Mode</label>

                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>OFF</div>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="payment_mode"
                                onChange={handleInput}
                                defaultChecked={
                                  paypal_payment?.payment_mode === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  paypal_payment?.payment_mode === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="payment_mode"
                                onChange={handleInput}
                                value={
                                  paypal_payment?.payment_mode === 1 ? "1" : "0"
                                }
                              ></span>
                            </label>
                          </div>
                          <div>ON</div>
                        </div>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label>
                                Paypal Payment API Keys (
                                <Link
                                  to="https://www.paypal.com/us/home"
                                  target="_blank"
                                >
                                  https://www.paypal.com/us/home
                                </Link>
                                )
                              </label>
                            </div>{" "}
                            <div className="panel-options">
                              {" "}
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>{" "}
                            </div>
                          </div>
                          <div className="panel-body">
                            <label>Test PayPal Username:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="test_paypal_username"
                              placeholder="Test PayPal Username"
                              value={paypal_payment?.test_paypal_username}
                              onChange={handleInput}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Test PayPal Password:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="test_paypal_password"
                          placeholder="Test PayPal Password"
                          value={paypal_payment?.test_paypal_password}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Test PayPal Signature:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="test_paypal_signature"
                          placeholder="Test PayPal Signature"
                          value={paypal_payment?.test_paypal_signature}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Live PayPal Username:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="live_paypal_username"
                          placeholder="Live PayPal Username"
                          value={paypal_payment?.live_paypal_username}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Live PayPal Password:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="live_paypal_password"
                          placeholder="Live PayPal Password"
                          value={paypal_payment?.live_paypal_password}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Live PayPal Signature:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="live_paypal_signature"
                          placeholder="Live PayPal Signature"
                          value={paypal_payment?.live_paypal_signature}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>PayPal Label:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="paypal_lable"
                          placeholder="PayPal Label"
                          value={paypal_payment?.paypal_lable}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <br />
                    <br />
                    <p>
                      <h3>Paystack Payment</h3>
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <label for="">Payment Mode</label>

                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>Disable</div>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="status"
                                onChange={handleInput}
                                defaultChecked={
                                  paystack_payment?.status === 1 ? true : false
                                }
                                checked={
                                  paystack_payment?.status === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                onChange={handleInput}
                                value={
                                  paystack_payment?.status === 1 ? "1" : "0"
                                }
                              ></span>
                            </label>
                          </div>
                          <div>Enable</div>
                        </div>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6">
                        <label for=""> Paystack Mode </label>

                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>OFF</div>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="payment_mode"
                                onChange={handleInput}
                                defaultChecked={
                                  paystack_payment?.payment_mode === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  paystack_payment?.payment_mode === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="payment_mode"
                                onChange={handleInput}
                                value={
                                  paystack_payment?.payment_mode === 1
                                    ? "1"
                                    : "0"
                                }
                              ></span>
                            </label>
                          </div>
                          <div>ON</div>
                        </div>

                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6">
                        <div className="panel-title">
                          {" "}
                          Paystack Plan (
                          <Link to="https://paystack.com/" target="_blank">
                            {" "}
                            https://paystack.com/{" "}
                          </Link>
                          )
                        </div>
                        <label>Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="paystack_name"
                          placeholder="Paystack Name"
                          value={paystack_payment?.paystack_name}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label> Paystack Callback URL: </label>
                        <input
                          type="text"
                          className="form-control"
                          name="paystack_callback_url"
                          placeholder="Paystack Callback URL"
                          value={paystack_payment?.paystack_callback_url}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label> Paystack Test Publishable Key: </label>
                        <input
                          type="text"
                          className="form-control"
                          name="paystack_test_publishable_key"
                          placeholder="Paystack Test Publishable Key"
                          value={
                            paystack_payment?.paystack_test_publishable_key
                          }
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label> Paystack Test Secret Key: </label>
                        <input
                          type="text"
                          className="form-control"
                          name="paystack_test_secret_key"
                          placeholder="Paystack Test Secret Key"
                          value={paystack_payment?.paystack_test_secret_key}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label> Paystack Live Publishable Key: </label>
                        <input
                          type="text"
                          className="form-control"
                          name="paystack_live_publishable_key"
                          placeholder="Paystack Live Publishable Key"
                          value={
                            paystack_payment?.paystack_live_publishable_key
                          }
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label> Paystack Live Secret Key: </label>
                        <input
                          type="text"
                          className="form-control"
                          name="paystack_live_secret_key"
                          placeholder="Paystack Live Secret Key"
                          value={paystack_payment?.paystack_live_secret_key}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label> Paystack Label: </label>
                        <input
                          type="text"
                          className="form-control"
                          name="paystack_lable"
                          placeholder="paystack Label"
                          value={paystack_payment?.paystack_lable}
                          onChange={handleInput}
                        />
                      </div>
                    </div>{" "}
                    <br />
                    <br />
                    <p>
                      <h3>Razorpay Payment</h3>
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <label for="">Payment Mode</label>
                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>Disable</div>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="status"
                                onChange={handleInput}
                                defaultChecked={
                                  razorpay_payment?.status === 1 ? true : false
                                }
                                checked={
                                  razorpay_payment?.status === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                onChange={handleInput}
                                value={
                                  razorpay_payment?.status === 1 ? "1" : "0"
                                }
                              ></span>
                            </label>
                          </div>
                          <div>Enable</div>
                        </div>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6">
                        <label for="">Razorpay Mode</label>
                        <div className="d-flex justify-content-around align-items-center col-sm-4">
                          <div>OFF</div>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="payment_mode"
                                onChange={handleInput}
                                defaultChecked={
                                  razorpay_payment?.payment_mode === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  razorpay_payment?.payment_mode === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="payment_mode"
                                onChange={handleInput}
                                value={
                                  razorpay_payment?.payment_mode === 1
                                    ? "1"
                                    : "0"
                                }
                              ></span>
                            </label>
                          </div>
                          <div>ON</div>
                        </div>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        ></div>
                      </div>

                      <div className="col-md-6">
                        <div className="panel-title">
                          Razorpay Plan (
                          <Link to="https://razorpay.com/" target="_blank">
                            {" "}
                            https://razorpay.com/{" "}
                          </Link>
                          )
                        </div>
                        <div className="panel-body">
                          <label>Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="razorpay_name"
                            value={razorpay_payment?.razorpay_name}
                            onChange={handleInput}
                            placeholder="Razorpay Test Secret Key"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Razorpay Test Secret Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Razorpay_Testkeyid"
                          placeholder="Razorpay Test Secret Key"
                          value={razorpay_payment?.Razorpay_Testkeyid}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Razorpay Test Publishable Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Razorpay_test_publishable_key"
                          placeholder="Razorpay Test Publishable Key"
                          value={
                            razorpay_payment?.Razorpay_test_publishable_key
                          }
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Razorpay Live Secret Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Razorpay_LivekeySecret"
                          placeholder="Razorpay Live Secret Key"
                          value={razorpay_payment?.Razorpay_LivekeySecret}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Razorpay Live Publishable Key:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Razorpay_live_publishable_key"
                          placeholder="Razorpay Live Publishable Key"
                          value={
                            razorpay_payment?.Razorpay_live_publishable_key
                          }
                          onChange={handleInput}
                        />
                      </div>

                      <div className="col-md-6 mt-3">
                        <label>Razorpay Label:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Razorpay_label"
                          placeholder="Razorpay Label"
                          value={razorpay_payment?.Razorpay_lable}
                          onChange={handleInput}
                        />
                      </div>
                    </div>{" "}
                    <br />
                    <br />
                    <div className="panel-body mt-3 d-flex justify-content-end">
                      <input
                        type="submit"
                        value="Update Payment Settings"
                        className="btn btn-primary "
                        onClick={handleUpdate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default Stripe;
