import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../../src/layout/Others/Response_Loading_processing";
import backarrow from "../../../../components/Images/backarrow.svg";

function Default_Image_Setting(props) {
  const [time_zone, setTime_zone] = useState([]);
  const [logo, setLogo] = useState("");
  const [favicon, setFavicon] = useState("");
  const [login_content, setLogin_content] = useState("");
  const [notification_icon, setNotification_icon] = useState("");
  const [default_horizontal_image, setDefault_horizontal_image] = useState("");
  const [default_video_image, setDefault_video_image] = useState("");
  const [watermark, setWatermark] = useState("");

  const [loading, setLoading] = useState(true);
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var result = response?.data?.TimeZone;
        var logo = response?.data?.settings[0]?.logo;
        var favicon = response?.data?.settings[0]?.favicon;
        var login_content = response?.data?.settings[0]?.login_content;
        var notification_icon = response?.data?.settings[0]?.notification_icon;
        var watermark = response?.data?.settings[0]?.watermark;
        var default_video_image =
          response?.data?.settings[0]?.default_video_image;
        var default_horizontal_image =
          response?.data?.settings[0]?.default_horizontal_image;
        setTime_zone(result);
        setFavicon(favicon);
        setLogin_content(login_content);
        setNotification_icon(notification_icon);
        setLogo(logo);
        setWatermark(watermark);
        setDefault_horizontal_image(default_horizontal_image);
        setDefault_video_image(default_video_image);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function handleUpdate() {
    const formData = new FormData();

    formData.append("default_video_image", selectedFile1);
    formData.append("default_horizontal_image", selectedFile2);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/setting/store_image`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        // console.log(resultError);
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <Link to="/settings" className="backarrow" >
                <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                <span> Back</span>
              </Link>
              <div className="col-6 col-sm-6 col-lg-6">
                <h3> Default Image Settings</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Save Images
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Vertical Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">Vertical Default Image</label>
                    <p className="t-1">
                      Select the image( 9:16 Ratio or 1080X1920px ) Format .png
                    </p>

                    <div>
                      {loading ? (

                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={default_video_image}
                          // alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    {/* <img src={editUser?.image_url} width="150" height="100" /> */}
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(1)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Save Email
                  </button>
                </div> */}
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Horizontal Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">Horizontal Default Image </label>
                    <p className="t-1">
                      Select the  image (16:9 Ratio or 1280X720px) Format .png
                    </p>

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={default_horizontal_image}
                          // alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(2)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    </>
  );
}

export default Default_Image_Setting;
