import { Link, useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import React, { useState, useRef, useEffect, useCallback } from "react";
import axios, { CancelToken, isCancel } from "axios";
import Box from "@mui/material/Box";
import Select from "react-select";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
// import "./ContentVideo.css";

import "vidstack/styles/defaults.css";
import { defineCustomElements } from "vidstack/elements";
import "vidstack/styles/base.css";
import "vidstack/styles/ui/buttons.css";
import "vidstack/styles/ui/buffering.css";
import "vidstack/styles/ui/captions.css";
import "vidstack/styles/ui/tooltips.css";
import "vidstack/styles/ui/live.css";
import "vidstack/styles/ui/sliders.css";
import "vidstack/styles/ui/menus.css";
import "vidstack/define/media-player.js";

export default function ContentVideo() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const { id } = useParams();

  const [videoUrl, setVideoUrl] = useState("");

  const [pre_ads_category, setPre_ads_category] = useState("");
  const [mid_ads_category, setMid_ads_category] = useState("");
  const [post_ads_category, setPost_ads_category] = useState("");
  const [Block_Country, setBlock_Country] = useState([]);
  const [Available_country, setAvailable_country] = useState([]);
  const [trailer, setTrailer] = useState("");
  const [Video_Title_Thumbnail, setVideo_Title_Thumbnail] = useState("");
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [video_tv_image, setVideo_tv_image] = useState("");
  const [pdf_files, setPdf_files] = useState("");
  const [reels_thumbnail, setReels_thumbnail] = useState("");
  const [reelvideo, setReelvideo] = useState("");
  const [trailer_type, setTrailer_type] = useState("");
  const [video_title_image, setVideo_title_image] = useState("");

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter") {
      setAutoFillValuetitle(editUser.title);
    } else if (event.key === "Tab") {
      setAutoFillValuetitle(editUser.title);
    }
  };

  const handleAutoFillChangetitle = (event) => {
    setAutoFillValuetitle(event.target.value);
  };

  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    id: "",
    draft: "",
    user_id: "",
    type: "",
    status: "",
    active: "",
    rating: "",
    duration: "",
    description: "",
    details: "",
    image: "",
    mobile_image: "",
    tablet_image: "",
    player_image: "",
    video_tv_image: "",
    enable_video_title_image: "",
    video_title_image: "",
    android_tv: "",
    video_gif: "",
    search_tags: "",
    access: "",
    global_ppv: "",
    ppv_price: "",
    ios_ppv_price: "",
    featured: "",
    banner: "",
    enable: "",
    embed_code: "",
    mp4_url: "",
    m3u8_url: "",
    webm_url: "",
    ogg_url: "",
    disk: "",
    original_name: "",
    path: "",
    stream_path: "",
    processed_low: "",
    converted_for_streaming_at: "",
    views: "",
    year: "",
    age_restrict: "",
    Recommendation: "",
    country: "",
    publish_status: "",
    publish_type: "",
    publish_time: "",
    skip_recap: "",
    skip_intro: "",
    recap_start_time: "",
    recap_end_time: "",
    intro_start_time: "",
    intro_end_time: "",
    pdf_files: "",
    reels_thumbnail: "",
    reelvideo: "",
    url: "",
    url_link: "",
    url_linktym: "",
    url_linksec: "",
    urlEnd_linksec: "",
    trailer_type: "",
    trailer: "",
    trailer_description: "",
    ads_status: "",
    default_ads: "",
    pre_ads_category: "",
    mid_ads_category: "",
    post_ads_category: "",
    mid_ads: "",
    pre_ads: "",
    post_ads: "",
    ads_tag_url_id: "",
    time_zone: "",
    home_genre: "",
    in_menu: "",
    footer: "",
    uploaded_by: "",
    language: "",
    video_category_id: "",
    Video_reels_video_url: "",
    video_categories: "",
    video_block_countries: "",
    artists: "",
    realted_videos: "",
    Thumbnail: "",
    Player_thumbnail: "",
    TV_Thumbnail: "",
    Video_Title_Thumbnail: "",
    Video_reels_Thumbnail: "",
    Video_upload_url: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
    Video_reels_video: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [publish_type, setPublish_type] = useState("");
  const [user_access, setUser_access] = useState([]);
  const [useraccess, setUserAccess] = useState("");
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [age_restrict, setAge_restrict] = useState([]);
  const navigate = useNavigate();

  // const [tags, setTags] = useState([]);
  // const [newTag, setNewTag] = useState("");

  // const handleTagsChange = (newTags) => {
  //   setTags(newTags);
  // };

  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");

  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };

  const handleInputChangetag = (input) => {
    setNewTag(input);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault(); // Prevent the default behavior (adding space or newline)

      // Only add the tag if it's not empty
      if (newTag.trim() !== "") {
        setTags([...tags, newTag]);
        setNewTag("");
      }
    }
  };

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(editUser?.ppv_price);
    } else {
      setInputValue("");
    }
  };

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, details: e });
    // console.log(e, "onchnageddddd");
  };
  const handleChangeFirstdes = (e) => {
    setEditUser({ ...editUser, trailer_description: e });
    // console.log(e, "onchnageddddd");
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const [videosreels, setVideosreels] = useState([]);
  const [uploadedFileNames, setUploadedFileNames] = useState([]);

  const handleDropreels = (acceptedFiles) => {
    setVideosreels(acceptedFiles);
  };

  const [selectedFiles, setSelectedFiles] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "video/*",
  });

  const [selectedFilesreels, setSelectedFilesreels] = useState(null);

  const onDropreels = useCallback((acceptedFiles) => {
    setSelectedFiles(acceptedFiles[0]);
  }, []);

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [selectedFile5, setSelectedFile5] = useState(null);

  const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3,
    setSelectedFile4,
    setSelectedFile5
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
    setSelectedFile4(null);
    if (fileInputRef4.current) {
      fileInputRef4.current.value = null;
    }
    setSelectedFile5(null);
    if (fileInputRef5.current) {
      fileInputRef5.current.value = null;
    }
  };

  // MULTI SELECT NEW
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [optionsrelated_video, setOptionsrelated_video] = useState([]);
  const [selectedValuesrelated_video, setSelectedValuesrelated_video] =
    useState([]);

  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);

  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);

  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);

  const [optionsavailable_country, setOptionsAvailable_country] = useState([]);
  const [selectedValuesavailable_country, setSelectedValuesAvailable_country] =
    useState([]);

  const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
    []
  );

  const [formData, setFormData] = useState({
    block_country: [],
    Available_country: [],
    related_video: [],
    artists: [],
    categories: [],
    languages: [],
    Age_Restrict: [],
    // ...editInputvalue,
    // other form data properties...
  });

  // const [languages, setLanguages] = useState([]);
  // const [selectedLanguages, setSelectedLanguages] = useState([]);

  const [selectedOptionaccess, setSelectedOptionaccess] = useState(null);

  const handleChangeaccess = (selected) => {
    setSelectedOptionaccess(selected);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/videos-edit/${id}`,
        { headers: headers }
      );
      const data = response?.data?.selected_Video_block_countries;
      const dataavailable_country =
        response?.data?.selected_Video_available_countries;
      const dataartists = response?.data?.selected_Video_artists;
      const datacategories = response?.data?.selected_Video_categories;
      const datalanguages = response?.data?.selected_languages;
      const dataAge_Restrict = response?.data?.selected_Age_Restrict;
      const datarelated_video = response?.data?.selected_related_video;

      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;

      setInappPurchase(InappPurchase);
      setUser_access(result);
      // console.log(response?.data?.user_access);

      var res = response?.data?.videos[0];
      var globelset = response?.data?.ppv_gobal_price;
      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      setInputValueAPI(globelset);
      setEditUser(res);
      setTags(
        response.data.videos[0].search_tags.split(",").map((tag) => tag.trim())
      );

      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.country_name,
      }));

      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionsrelated_video = datarelated_video?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));

      // Set the initial selected values
      setSelectedValues(formattedOptions);
      setOptions(formattedOptions);

      setSelectedValuesrelated_video(formattedOptionsrelated_video);
      setOptionsrelated_video(formattedOptionsrelated_video);

      setSelectedValuesAvailable_country(formattedOptionsavailable_country);
      setOptionsAvailable_country(formattedOptionsavailable_country);

      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);

      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);

      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);

      setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/videos-create`,
        { headers: headers }
      );
      const data = response?.data?.Block_country;
      const dataavailable_country = response?.data?.Available_country;
      const datarelated_video = response?.data?.Related_video;
      const dataartists = response?.data?.artists;
      const datacategories = response?.data?.audio_categories;
      const datalanguages = response?.data?.languages;
      const dataAge_Restrict = response?.data?.Age_Restrict;
      // setSelectedLanguages(data)
      // console.log(data);

      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);
      // console.log(data);
      // console.log(data);
      // Extract the selected data from the response
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.country_name,
      }));
      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      const formattedOptionsrelated_video = datarelated_video?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));

      // Set the options for the multi-select component
      setOptions(formattedOptions);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsrelated_video(formattedOptionsrelated_video);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(async () => {
    fetchData();
  }, []);
  // const handleLanguageChange = (event) => {
  //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  //   setSelectedLanguages(selectedOptions);
  // };

  const handleSelectChange = (selectedOptions) => {
    const block_country = selectedOptions.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      block_country: block_country,
    });

    setSelectedValues(selectedOptions);
  };
  const handleSelectChangeavailable_country = (
    selectedOptionsavailable_country
  ) => {
    const available_country = selectedOptionsavailable_country?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      available_country: available_country,
    });

    setSelectedValuesAvailable_country(selectedOptionsavailable_country);
  };
  const handleSelectChangeartists = (selectedOptionsartists) => {
    const artists = selectedOptionsartists?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      artists: artists,
    });

    setSelectedValuesartists(selectedOptionsartists);
  };

  const handleSelectChangecategories = (selectedOptionscategories) => {
    const categories = selectedOptionscategories?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      categories: categories,
    });

    setSelectedValuescategories(selectedOptionscategories);
  };

  const handleSelectChangerelated_video = (selectedOptionsrelated_video) => {
    const related_video = selectedOptionsrelated_video?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      related_video: related_video,
    });

    setSelectedValuesrelated_video(selectedOptionsrelated_video);
  };

  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    const languages = selectedOptionslanguages?.map((option) => option.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      languages: languages,
    });

    setSelectedValueslanguages(selectedOptionslanguages);
  };
  const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
    const Age_Restrict = selectedOptionsAge_Restrict?.map(
      (option) => option.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      Age_Restrict: Age_Restrict,
    });

    setSelectedValueslanguages(selectedOptionsAge_Restrict);
  };

  // MULTI SELECT NEW

  useEffect(async () => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/videos-create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/videos-create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_language)
        var age_restrictdata = response?.data?.Age_Restrict;
        var ads_category = response?.data?.ads_category;
        var Block_Country = response?.data?.Block_Country;
        var Available_country = response?.data?.Available_country;
        var user_access = response?.data?.user_access;
        setAge_restrictdata(age_restrictdata);
        setPre_ads_category(ads_category);
        setMid_ads_category(ads_category);
        setPost_ads_category(ads_category);

        // console.log(response)
        // console.log(age_restrictdata)
        // console.log(user_access)
        // console.log(Available_country)

        setBlock_Country(Block_Country);
        setAvailable_country(Available_country);
      })
      .catch((error) => console.log(error));
  }, [id]);

  useEffect(async () => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response.data;
          // console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
    useState("");

  function proceednextpublic() {
    switch (enable_restreampublic) {
      case "publish_now":
        return <div></div>;
      case "publish_later":
        return (
          <div className="mb-3">
            <label className="m-0">Publish Time</label>
            <input
              type="datetime-local"
              className="form-control"
              id="publish_time"
              name="publish_time"
              onChange={handleInput}
              value={editUser?.publish_time}
            />
          </div>
        );
      default:
        return null;
    }
  }

  const [enable_restreamtrailer, setEnable_restreamtrailer] = useState("");
  const [enablestreamvisibletrailer, setEnablestreamvisibletrailer] =
    useState("");

  function proceednexttrailer() {
    switch (enable_restreamtrailer) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="col-lg-12 row mt-2">
            <div className="">
              <label className="mb-1"> Trailer Video :</label>

              <div>
                <media-player
                  // title={editUser?.Video_reels_video}
                  src={editUser?.trailer}
                  poster=""
                  controls
                >
                  <media-outlet></media-outlet>
                </media-player>
              </div>
            </div>
            <div className="">
              <label className="mb-1"> Upload Trailer : </label>

              <div className="file-draganddrop rs-input">
                <div
                  {...getRootProps()}
                  className={`mt-3 drop-area ${isDragActive ? "active" : ""}`}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the video file here...</p>
                  ) : (
                    <p>Drag and drop your video file here</p>
                  )}
                </div>
                {selectedFiles && (
                  <div>
                    <p>{selectedFiles.name}</p>
                  </div>
                )}
              </div>

              {/* <div className=" text-center file-drag mt-2">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div>
                  <p>Drag and drop your file here or</p>
                  <p>
                    <input
                      type="file"
                      className="form-control-file"
                      multiple
                      accept="video/mp4"
                      id="input-file-upload"
                      onChange={(e) => setTrailer(e.target.files[0])}
                    />
                  </p>
                </div>
                <br />
              </label>
            </div> */}
            </div>
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="col-lg-12 row mt-2">
              <div className="">
                <label className="mb-1"> M3u8 Video :</label>

                <div>
                  <media-player
                    // title={editUser?.Video_reels_video}
                    src={editUser?.trailer}
                    poster=""
                    controls
                  >
                    <media-outlet></media-outlet>
                  </media-player>
                </div>
              </div>
              <div className="col-sm-6 mt-3 text-start">
                <div className="">
                  <label className="mb-1">Trailer m3u8 URL: </label>
                  <input
                    type="text"
                    className="form-control-lg rs-input"
                    name="mp3_url"
                    onChange={handleInput}
                    value={editUser.mp4_url}
                    placeholder="m3u8 URL"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="col-lg-12 row mt-2">
              <div className="">
                <label className="mb-1"> Mp4 Video :</label>

                <div>
                  <media-player
                    // title={editUser?.Video_reels_video}
                    src={editUser?.trailer}
                    poster=""
                    controls
                  >
                    <media-outlet></media-outlet>
                  </media-player>
                </div>
              </div>
              <div className="col-sm-6 mt-3 text-start">
                <div className="">
                  <label className="mb-1"> Trailer Mp4 File URL: </label>
                  <input
                    type="text"
                    className="form-control-lg rs-input"
                    name="mp4_url"
                    onChange={handleInput}
                    value={editUser.mp4_url}
                    placeholder="mp4 Trailer"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case "embed_url":
        return (
          <div>
            <div className="col-lg-12 row mt-2">
              <div className="">
                <label className="mb-1"> Embed Video :</label>

                <div>
                  <media-player
                    // title={editUser?.Video_reels_video}
                    src={editUser?.trailer}
                    poster=""
                    controls
                  >
                    <media-outlet></media-outlet>
                  </media-player>
                </div>
              </div>

              <div className="col-sm-6 mt-3 text-start">
                <div className="">
                  <label className="mb-1"> Trailer Embed URL: </label>
                  <input
                    type="text"
                    className="form-control-lg rs-input"
                    name="live_mp3_url"
                    placeholder="Embed URL"
                    onChange={handleInput}
                    value={editUser?.embed_code}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function alllive_streamuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="col-sm-12">
              <label className="m-0">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p className="p1">Apply PPV Price from Global Settings? </p>
                </div>
                {/* <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      className="rs-input"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div> */}
              </div>

              <div className="panel-body">
                <input
                  type="text"
                  className="rs-input"
                  placeholder="PPV Price"
                  value={editUser.ppv_price}
                  id="ppv_price"
                  name="ppv_price"
                  onChange={handleInput}
                />
              </div>
            </div>

            <div className="col-sm-12 mt-2">
              <label className="m-0"> IOS PPV Price</label>
              <p className="p1">Apply IOS PPV Price from Global Settings?</p>
              <div className="panel-body ppv_price_ios mt-2">
                <select
                  name="ios_ppv_price"
                  onChange={handleInput}
                  value={editUser.ios_ppv_price}
                  className="rs-input"
                >
                  {inapppurchase?.map((item, key) => (
                    <option value={item?.product_id}>{item?.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  const [selectedValue, setSelectedValue] = useState("");
  const [apiResponse, setApiResponse] = useState("");

  const handleSelectChanges = async (event) => {
    const { value } = event.target;
    setSelectedValue(value);

    const formData = new FormData();

    formData.append("value", value);

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        console.log("apiasas", response);
        if (response.data.status == true) {
          var result = response.data;

          console.log("api", result);
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const insertColons = (time) => {
    if (time?.length >= 6) {
      const hours = time.substr(0, 2);
      const minutes = time.substr(2, 2);
      const seconds = time.substr(4, 2);

      return `${hours}:${minutes}:${seconds}`;
    }
    return time;
  };

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const updatedTagsString = tags.join(", ");
    const selectedValueaccess = selectedOptionaccess.value;

    const editInputvalue = {
      title: editUser.title,
      type: editUser.type,
      slug: editUser.slug,
      id: editUser.id,
      active: editUser.active,
      user_id: editUser.user_id,
      status: editUser.status,
      draft: editUser.draft,
      rating: editUser.rating,
      duration: editUser.duration,
      details: editUser.details,
      description: editUser.description,
      image: editUser.image,
      mobile_image: editUser.mobile_image,
      tablet_image: editUser.tablet_image,
      player_image: editUser.player_image,
      video_tv_image: editUser.video_tv_image,
      enable_video_title_image: editUser.enable_video_title_image,
      video_title_image: editUser.video_title_image,
      android_tv: editUser.android_tv,
      video_gif: editUser.video_gif,
      search_tags: updatedTagsString,
      access: selectedValueaccess,
      global_ppv: editUser.global_ppv,
      ppv_price: inputValue,
      ios_ppv_price: editUser.ios_ppv_price,
      featured: editUser.featured,
      banner: editUser.banner,
      enable: editUser.enable,
      embed_code: editUser.embed_code,
      mp4_url: editUser.mp4_url,
      m3u8_url: editUser.m3u8_url,
      webm_url: editUser.webm_url,
      ogg_url: editUser.ogg_url,
      disk: editUser.disk,
      original_name: editUser.original_name,
      path: editUser.path,
      stream_path: editUser.stream_path,
      processed_low: editUser.processed_low,
      converted_for_streaming_at: editUser.converted_for_streaming_at,
      views: editUser.views,
      year: editUser.year,
      age_restrict: editUser.age_restrict,
      Recommendation: editUser.Recommendation,
      country: editUser.country,
      publish_status: editUser.publish_status,
      publish_type: editUser.publish_type,
      publish_time: editUser.publish_time,
      skip_recap: editUser.skip_recap,
      skip_intro: editUser.skip_intro,
      recap_start_time: editUser.recap_start_time,
      recap_end_time: editUser.recap_end_time,
      intro_start_time: editUser.intro_start_time,
      intro_end_time: editUser.intro_end_time,
      pdf_files: editUser.pdf_files,
      reels_thumbnail: editUser.reels_thumbnail,
      reelvideo: editUser.reelvideo,
      url: editUser.url,
      url_link: editUser.url_link,
      url_linktym: editUser.url_linktym,
      url_linksec: editUser.url_linksec,
      urlEnd_linksec: editUser.urlEnd_linksec,
      trailer_type: editUser.trailer_type,
      trailer: editUser.trailer,
      trailer_description: editUser.trailer_description,
      ads_status: editUser.ads_status,
      default_ads: editUser.default_ads,
      pre_ads_category: editUser.pre_ads_category,
      mid_ads_category: editUser.mid_ads_category,
      post_ads_category: editUser.post_ads_category,
      pre_ads: editUser.pre_ads,
      mid_ads: editUser.mid_ads,
      post_ads: editUser.post_ads,
      ads_tag_url_id: editUser.ads_tag_url_id,
      time_zone: editUser.time_zone,
      home_genre: editUser.home_genre,
      in_menu: editUser.in_menu,
      footer: editUser.footer,
      uploaded_by: editUser.uploaded_by,
      language: editUser.language,
      video_category_id: editUser.video_category_id,
      Thumbnail: editUser.Thumbnail,
      Player_thumbnail: editUser.Player_thumbnail,
      TV_Thumbnail: editUser.TV_Thumbnail,
      Video_Title_Thumbnail: editUser.Video_Title_Thumbnail,
      Video_reels_Thumbnail: editUser.Video_reels_Thumbnail,
      Video_reels_video_url: editUser.Video_reels_video_url,
      Video_upload_url: editUser.Video_upload_url,
      video_category_id: editUser.video_category_id,
      ...formData,
    };
    // console.log(editInputvalue);
    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/videos-update/` + id,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();
    // console.log(resjson)
    if (resjson.status === true) {
      // setMessage(resjson.success);
      // setTimeout(() => {}, 2000);
      var result = resjson.videos.id;

      const formData1 = new FormData();
      formData1.append("video_id", id);
      videosreels.forEach((video, index) => {
        formData1.append(`reels_videos[${index}]`, video);
      });
      if (selectedFile5) {
        formData1.append("reels_thumbnail", selectedFile5);
      }

      axios
        .post(`${process.env.REACT_APP_Baseurl}/admin/video-reels`, formData1, {
          headers: headers,
        })
        .then((response) => {
          console.log("File(s) uploaded successfully:", response.message);
          // Perform any actions with the response data as needed.
          //       // navigate('/all-artist')
          // navigate("/allvideo");
          // alert(response.data.message + "!");
        })
        .catch((error) => {
          console.error("Error uploading file(s):", error);
          // Handle any errors that occurred during the upload process.
        });

      const formDatass = new FormData();

      formDatass.append("video_id", id);
      formDatass.append("trailer", editUser?.embed_code);
      formDatass.append("trailer", editUser?.m3u8_url);
      formDatass.append("trailer", editUser?.mp4_url);
      formDatass.append("trailer_type", trailer_type);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/video-Trailer-url`,
          formDatass,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status == true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/all-artist')
            // alert("Added successfully  !")
          }
        });

      const formD = new FormData();

      formD.append("video_id", id);
      formD.append("trailer", selectedFiles);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/video-Trailer-video`,
          formD,
          { headers: headers }
        )
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status == true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/all-artist')
          }
        });

      const formDataseo = new FormData();
      formDataseo.append("video_id", id);
      formDataseo.append("website_page_title", editUser.website_page_title);
      formDataseo.append("website_URL", editUser.website_URL);
      formDataseo.append("Meta_description", editUser.Meta_description);

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/videos-SEO`,
          formDataseo,
          { headers: headers }
        )
        .then((response) => {
          console.log("File(s) uploaded successfully:", response.data);
        });

      const formDa = new FormData();

      formDa.append("video_id", id);
      formDa.append("pdf_file", pdf_files);

      axios
        .post(`${process.env.REACT_APP_Baseurl}/admin/Epaper-video`, formDa, {
          headers: headers,
        })
        .then((response) => {
          // console.log('api' , response);
          if (response.data.status == true) {
            var result = response.data;
            // console.log('result', response);
            // navigate('/all-artist')
          }
        });

      const formData = new FormData();
      formData.append("video_id", id);
      if (selectedFile1) {
        formData.append("image", selectedFile1);
      }
      if (selectedFile2) {
        formData.append("player_image", selectedFile2);
      }
      if (selectedFile3) {
        formData.append("video_tv_image", selectedFile3);
      }
      if (selectedFile4) {
        formData.append("video_title_image", selectedFile4);
      }

      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-video`,
          formData,
          { headers: headers }
        )
        .then((response) => {
          console.log("File(s) uploaded successfully:", response.message);
          // Perform any actions with the response data as needed.
          //       // navigate('/all-artist')
          // navigate("/allvideo");
          // alert(response.data.message + "!");
        });

      // navigate("/allvideo");
      // window.location.reload();
      // alert("Added successfully  !");
    } else {
      setMessage("Some error Occured");
      console.log("Error");
      alert("Enter Correct Details");
    }
  };

  const steps = [
    {
      label: "Video ",
      description: (
        <div className="">
          <section className="">
            <div className="col-lg-12 row py-3">
              <div className=" col-sm-6 col-lg-6">
                <h3>Add Video</h3>
              </div>
              <div className=" col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Add Video
                  </button>
                </div>
              </div>
            </div>
            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Video</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Title<span className="mandatory">*</span>
                    </label>
                    <p className="t-1">
                      Add the Video title in the textbox below:
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser.title}
                        placeholder="Title"
                        onKeyDown={handleInputKeyDowntitle}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Slug<span className="mandatory">*</span>
                    </label>
                    <p className="t-1">Add the Video slug:</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg"
                        // onChange={handleInput}
                        // value={editUser.slug}
                        placeholder="slug"
                        value={autoFillValuetitle}
                        onChange={handleAutoFillChangetitle}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Short Description</label>
                    <p className="t-1">
                      Add a short description of the Video below:
                    </p>
                    <div className="mt-2">
                      <textarea
                        className="rs-input short-description"
                        id="description"
                        name="description"
                        onChange={handleInput}
                        value={editUser.description}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">
                        Video Details, Links, and Info
                      </label>
                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input"
                          value={editUser?.details}
                          onChange={(e) => {
                            handleChangeFirst(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">
                        Duration<span className="mandatory">*</span>
                      </label>
                      <p className="t-1">
                        Enter the Video duration in the following format (Hours
                        : Minutes : Seconds)
                      </p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="duration"
                          className="rs-input form-control-lg"
                          value={editUser.duration}
                          maxLength="8"
                          placeholder="duration"
                          onChange={handleInputdata}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">Publish Year</label>
                      <p className="t-1">Video Released Year</p>
                      <div className="mt-2">
                        <input
                          type="number"
                          maxLength="4"
                          pattern="\d{4}"
                          name="year"
                          className="rs-input form-control-lg"
                          onChange={handleInput}
                          value={editUser.year}
                          placeholder="Year"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>Organize</h5>
                    <hr></hr>

                    <div className=" text-start">
                      <label className="m-0">Video Ratings :</label>
                      <p className="t-1">IMDb Ratings 10 out of 10:</p>
                      <div className="mt-2">
                        <select
                          className="rs-input form-control-lg "
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={handleInput}
                          value={editUser.rating}
                        >
                          {/* <option value="0">0</option> */}
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0">Age Restrict :</label>
                      <p className="t-1">Select a Video Age Below:</p>
                      <div className="mt-2">
                        <select
                          className="rs-input form-control-lg "
                          id="age_restrict"
                          name="age_restrict"
                          size="lg"
                          onChange={handleInput}
                          value={editUser.age_restrict}
                        >
                          {age_restrictdata?.map((item) => (
                            <option selected value={item?.id}>
                              {item?.slug}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/* <div className="mt-2 text-start">
                      <label className="m-0">Album</label>
                      <p className="t-1">Select A Audio Album Below:</p>
                      <div className="mt-2">
                        <select
                          className="rs-input form-control-lg "
                          onChange={(e) => setAlbum_id(e.target.value)}
                        >
                          <option value="">Choose a Album</option>
                          {album?.map((item, key) => (
                            <option value={item?.id}>{item?.albumname}</option>
                          ))}
                        </select>
                      </div>
                    </div> */}

                    <div className=" text-start mt-2">
                      <label className="m-0">Cast and Crew</label>
                      <p className="t-1">Add artists for the Video below:</p>

                      <div className="mt-2">
                        <Select
                          options={optionsartists}
                          isMulti
                          className="rs-input "
                          onChange={handleSelectChangeartists}
                          value={selectedValuesartists}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="m-0">
                        Category<span className="mandatory">*</span>
                      </label>
                      <p className="t-1">Select a Video Category Below:</p>

                      <div className="mt-2">
                        <Select
                          options={optionscategories}
                          isMulti
                          className="rs-input "
                          onChange={handleSelectChangecategories}
                          value={selectedValuescategories}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">
                        Language<span className="mandatory">*</span>
                      </label>
                      <p className="t-1">Select a Video Language Below:</p>
                      <div className="text-dark">
                        <Select
                          options={optionslanguages}
                          isMulti
                          className="rs-input"
                          onChange={handleSelectChangelanguages}
                          value={selectedValueslanguages}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">Block Country</label>
                      <p className="t-1">
                        Block the Video for Selected Country:
                      </p>
                      <div className="text-dark">
                        <Select
                          options={options}
                          isMulti
                          className="rs-input"
                          onChange={handleSelectChange}
                          value={selectedValues}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">Available Country</label>
                      <p className="t-1">
                        Available the Video for Selected Country:
                      </p>
                      <div className="text-dark">
                        <Select
                          options={optionsavailable_country}
                          isMulti
                          className="rs-input"
                          onChange={handleSelectChangeavailable_country}
                          value={selectedValuesavailable_country}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="m-0">Related Videos:</label>
                      <p className="t-1">Select a Related Video Below:</p>
                      <div className="text-dark">
                        <Select
                          options={optionsrelated_video}
                          isMulti
                          className="rs-input"
                          onChange={handleSelectChangerelated_video}
                          value={selectedValuesrelated_video}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>
                      Intro Time <small>(Optional)</small>
                    </h5>
                    <hr></hr>

                    <div className="col-lg-12 row">
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            Skip Intro Time{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              className="rs-input form-control-lg "
                              name="skip_intro"
                              id="skip_intro"
                              maxLength="8"
                              placeholder="HH:MM:SS"
                              value={editUser.skip_intro}
                              onChange={handleInputdata}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            Skip Recap Time{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser.skip_recap}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="skip_recap"
                              id="skip_recap"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 row mt-2">
                      <div className="col-lg-6">
                        {" "}
                        <div className="text-start">
                          <label className="m-0">
                            Intro Start Time{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser.intro_start_time}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="intro_start_time"
                              id="intro_start_time"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            Intro End Time{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser.intro_end_time}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="intro_end_time"
                              id="intro_end_time"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 row mt-2">
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            Recap Start Time{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser.recap_start_time}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="recap_start_time"
                              id="recap_start_time"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0">
                            Recap End Time{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser.recap_end_time}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg "
                              name="recap_end_time"
                              id="recap_end_time"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Category</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">E-Paper: </label>
                    <p className="t-1">(Upload your PDF file)</p>
                    <div className="mt-2">
                      <div className="">
                        <input
                          type="file"
                          className="form-control-lg rs-input"
                          name="pdf_file"
                          accept="application/pdf"
                          onChange={(e) => setPdf_files(e.target.files[0])}
                          multiple
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">URL Link</label>
                    <p className="t-1">Select the URL Link</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="form-control-lg rs-input"
                        name="url_link"
                        onChange={handleInput}
                        value={editUser.url_link}
                        id="url_link"
                      />
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">URL Start Time Format </label>
                    <p className="t-1">(HH:MM:SS):</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="form-control-lg rs-input"
                        name="url_linktym"
                        maxLength="8"
                        onChange={handleInputdata}
                        value={editUser.url_linktym}
                        id="url_linktym"
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>SEO</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">Website Page Title</label>

                    <div className=" mt-2">
                      <input
                        type="text"
                        value={editUser?.website_page_title}
                        name="website_page_title"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 mt-2">
                    <label className="m-0">Website URL</label>

                    <div className=" mt-2">
                      <input
                        type="text"
                        value={editUser?.website_URL}
                        name="website_URL"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-2">
                    <label className="m-0">Meta Description</label>

                    <div className=" mt-2">
                      <textarea
                        type="text"
                        value={editUser?.Meta_description}
                        name="Meta_description"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Search Tags</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <p className="p2">You don't have any search keywords.</p>

                    <div className="mt-2">
                      {/* <TagsInput
                        value={tags}
                        onChange={handleTagsChange}
                        inputValue={newTag}
                        onChangeInput={(tag) => setNewTag(tag)}
                      /> */}
                      <TagsInput
                        value={tags}
                        onChange={handleTagsChange}
                        inputValue={newTag}
                        onChangeInput={handleInputChangetag}
                        inputProps={{ onKeyPress: handleInputKeyPress }} // Adding key press event handler
                      />
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Add Video
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Video Thumbnail</label>
                    <p className="t-1">
                      Select the Video image ( 9:16 Ratio or 1080X1920px ):
                    </p>
                    <img src={editUser?.Thumbnail} width="150" height="100" />
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <img
                                  src={URL.createObjectURL(selectedFile1)}
                                  alt="Uploaded"
                                  width="150"
                                  height="100"
                                />
                                <button
                                  onClick={() => handleDelete(setSelectedFile1)}
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Player Thumbnail</label>
                    <p className="t-1">
                      Select the Video image ( 16:9 Ratio or 1280X720px ):
                    </p>
                    <img
                      src={editUser?.Player_thumbnail}
                      width="150"
                      height="100"
                    />
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <img
                                  src={URL.createObjectURL(selectedFile2)}
                                  alt="Uploaded"
                                  width="150"
                                  height="100"
                                />
                                <button
                                  onClick={() => handleDelete(setSelectedFile2)}
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Video TV Thumbnail</label>
                    <p className="t-1">
                      Select the Video image ( 16:9 Ratio or 1280X720px ):
                    </p>
                    <img
                      src={editUser?.TV_Thumbnail}
                      width="150"
                      height="100"
                    />
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <img
                                  src={URL.createObjectURL(selectedFile3)}
                                  alt="Uploaded"
                                  width="150"
                                  height="100"
                                />
                                <button
                                  onClick={() => handleDelete(setSelectedFile3)}
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Video Title Thumbnail</label>
                    <p className="t-1">
                      Select the Video Title image ( 16:9 Ratio or 1280X720px ):
                    </p>
                    <img
                      src={editUser?.Video_Title_Thumbnail}
                      width="150"
                      height="100"
                    />
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef4.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef4}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef4,
                                  setSelectedFile4
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile4 && (
                              <div className="imagedropcopy text-end">
                                <img
                                  src={URL.createObjectURL(selectedFile4)}
                                  alt="Uploaded"
                                  width="150"
                                  height="100"
                                />
                                <button
                                  onClick={() => handleDelete(setSelectedFile4)}
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Reels</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">Reels Video</label>
                    <p className="t-1">Select the Reels Video</p>
                    <div className="mt-2 file-draganddrop">
                      {/* <input
                        type="file"
                        className="form-control-lg rs-input"
                        name="reels_videos"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={(e) => setReelvideo(e.target.files[0])}
                        multiple
                      /> */}

                      <Dropzone onDrop={handleDropreels} accept="video/*">
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            <p className="p-2">
                              Drag and drop reels videos here
                            </p>
                            {videosreels.length > 0 && (
                              <p>
                                {videosreels.length}{" "}
                                {videosreels.length === 1 ? "file" : "files"}{" "}
                                selected
                              </p>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Reels Video Thumbnail</label>
                    <p className="t-1">
                      Select the Reels Video image ( 16:9 Ratio or 1280X720px ):
                    </p>
                    <img
                      src={editUser?.Video_reels_Thumbnail}
                      width="150"
                      height="100"
                    />
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef5.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef5}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef5,
                                  setSelectedFile5
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile5 && (
                              <div className="imagedropcopy text-end">
                                <img
                                  src={URL.createObjectURL(selectedFile5)}
                                  alt="Uploaded"
                                  width="150"
                                  height="100"
                                />
                                <button
                                  onClick={() => handleDelete(setSelectedFile5)}
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Trailers Upload</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">Video Trailer Type: </label>
                    <p className="t-1">Upload trailer video in type</p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-12 col-sm-12 col-lg-12">
                          <select
                            className="trailer_type form-control-lg rs-input"
                            name="trailer_type"
                            id="trailer_type"
                            onChange={handleInput}
                            value={editUser.trailer_type}
                            onClick={(event) => {
                              setEnable_restreamtrailer(event.target.value);
                            }}
                          >
                            <option value="null">
                              {" "}
                              Select the Video Trailer Type{" "}
                            </option>
                            <option value="1">Video Upload </option>
                            <option value="m3u8_url">M3u8 Url </option>
                            <option value="mp4_url">Mp4 Url</option>
                            <option value="embed_url">Embed Code</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start">
                    <div
                      className=""
                      onClick={(event) => {
                        setEnablestreamvisibletrailer(event.target.value);
                      }}
                    >
                      {proceednexttrailer()}
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Trailer Description:</label>

                    <div className="mt-2">
                      <JoditEditor
                        className="rs-input"
                        value={editUser?.trailer_description}
                        onChange={(e) => {
                          handleChangeFirstdes(e);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Visibility</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="mb-2">Publish Type</label>
                    <div
                      onClick={(event) => {
                        setEnable_restreampublic(event.target.value);
                      }}
                    >
                      <input
                        type="radio"
                        id="publish_now"
                        name="publish_type"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                        value="publish_now"
                        checked="checked"
                      />{" "}
                      <span
                        className="mt-2"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                      >
                        Publish Now
                      </span>
                    </div>
                    <div
                      onClick={(event) => {
                        setEnable_restreampublic(event.target.value);
                      }}
                    >
                      <input
                        type="radio"
                        id="publish_later"
                        value="publish_later"
                        name="publish_type"
                        className="mt-2"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                      />{" "}
                      <span
                        value="publish_later"
                        name="publish_type"
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                        onChange={(e) => setPublish_type(e.target.value)}
                      >
                        Publish Later
                      </span>{" "}
                    </div>
                  </div>

                  <div className="col-sm-12" id="publishlater">
                    <div
                      className="mt-2"
                      onClick={(event) => {
                        setEnablestreamvisiblepublic(event.target.value);
                      }}
                    >
                      {proceednextpublic()}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card p-3">
                    <h5>Access</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="m-0">User Access</label>
                      <p className="p1">Who Is Allowed To View This Video ?</p>

                      <select
                        onChange={handleInput}
                        value={editUser.access}
                        name="access"
                        id="access"
                        className="rs-input mt-2 form-control-lg"
                        onClick={(event) => {
                          setallliveuseraccess(event.target.value);
                        }}
                      >
                        {user_access?.map((item, key) => (
                          <option value={item?.role}>{item?.name}</option>
                        ))}
                      </select>
                    </div>

                    <div>{alllive_streamuseraccess()}</div>
                  </div>

                  <div className="card p-3">
                    <h5>Status Settings</h5>
                    <hr></hr>
                    <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">Enable this video as Featured:</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="featured"
                            id="featured"
                            onChange={handleInput}
                            className="rs-input"
                            defaultChecked={
                              editUser.featured == 1 ? true : false
                            }
                            checked={editUser.featured == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="featured"
                            id="featured"
                            onChange={handleInput}
                            value={editUser.featured == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">Enable this Video:</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="active"
                            id="active"
                            onChange={handleInput}
                            defaultChecked={editUser.active == 1 ? true : false}
                            checked={editUser.active == 1 ? true : false}
                            className="rs-input"
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="active"
                            id="active"
                            onChange={handleInput}
                            value={editUser.active == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">Enable this Video as Slider:</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="banner"
                            id="banner"
                            onChange={handleInput}
                            className="r-input"
                            defaultChecked={editUser.banner == 1 ? true : false}
                            checked={editUser.banner == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            id="banner"
                            onChange={handleInput}
                            value={editUser.banner == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">Enable Video Title Thumbnail:</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="enable_video_title_image"
                            id="enable_video_title_image"
                            onChange={handleInput}
                            defaultChecked={
                              editUser.enable_video_title_image == 1
                                ? true
                                : false
                            }
                            checked={
                              editUser.enable_video_title_image == 1
                                ? true
                                : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            id="enable_video_title_image"
                            name="enable_video_title_image"
                            onChange={handleInput}
                            value={
                              editUser.enable_video_title_image == 1 ? "1" : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="card p-3">
                    <h5>Choose Ads Settings</h5>
                    <hr></hr>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Ads Position</label>

                      <div className=" mt-2">
                        <Select
                          options={adsPositions}
                          className="rs-input"
                          onChange={(e) => handleAdsPositionChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Advertisement</label>

                      <div className=" mt-2">
                        <Select
                          options={secondSelectOptions}
                          className="rs-input"
                          onChange={(e) => setSecondSelectOptions(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ),
    },
  ];

  return (
    <div className="m-0 p-0">
      <div className="">
        <section className="section container-fluid ">
          <div className="pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div className=" addvideo">
                  <Box>
                    <Stepper
                      className="video"
                      activeStep={activeStep}
                      nonLinear
                    >
                      {steps?.map((step, index) => (
                        <Step key={step?.label}>
                          <StepContent>
                            <Typography>{step?.description}</Typography>
                            <Box sx={{ mb: 2 }}>
                              <div></div>
                            </Box>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>

                    {activeStep === steps.length && (
                      <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>
                          All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                          Reset
                        </Button>
                      </Paper>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
