import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import AdminSaveHeader from "../../../components/CommonComponents/AdminSaveHeader";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import MessageLabel from "../../../components/CommonComponents/MessageLabel";
import MessageHeading from "../../../components/CommonComponents/MessageHeading";
import Wrapper from "../../../components/CommonComponents/Wrapper";
import LeftWrapper from "../../../components/CommonComponents/LeftWrapper";
import FormContainer from "../../../components/CommonComponents/FormContainer";
import MessageSubHeading from "../../../components/CommonComponents/MessageSubHeading";
import { adminApiHeader, audioDefaultURL } from "../../../components/CommonApis/CommonAudioApis";
import RightWrapper from "../../../components/CommonComponents/RightWrapper";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";

function Addcategory() {
  const [formData, setFormData] = useState()
  const [formDataError, setFormDataError] = useState({})
  const [defaultOption, setDefaultOption] = useState()
  const [coverImageFile, setCoverImageFile] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);


  const titleInputRef = useRef(null);
  const slugInputRef = useRef(null);
  const coverImageRef = useRef(null)
  const navigate = useNavigate();

  // api start
  const handleGetCategory = async () => {
    try {
      const response = await axios.get(`${audioDefaultURL?.categoryAudio}`, { headers: adminApiHeader })
      const result = response?.data?.AllAudioGenre;
      if (response?.data?.status) {
        setDefaultOption(result)
      }
    }
    catch (err) {
      console.error(err)
    }
  }

  const handleAdd = async () => {
    setShowOverlay(true);
    try {
      const formDataAdd = new FormData();
      for (const key in formData) {
        formDataAdd.append(key, formData[key]);
      }
      const response = await axios.post(`${audioDefaultURL?.storeAudioCategory}`, formDataAdd, { headers: adminApiHeader });
      const result = response?.data;
      if (result?.status) {
        if (coverImageFile) {
          await handleImageUpload(result?.AudioGenreid)
        }
        setShowOverlay(false);
        toast.success(result?.message)
        navigate("/audio-categories");
      }
      else {
        setShowOverlay(false);
        toast.error(result?.message)
      }
    }
    catch (err) {
      setShowOverlay(false);
      const errorMessage = err?.response?.data?.message
      toast.error(errorMessage)
      console.error("error", err)
    }
  }

  // api end
  const handleStore = async () => {
    if (formValidation()) {
      await handleAdd();
    } else {
      console.log("Form validation failed ");
    }
  }

  const formValidation = () => {
    let formIsValid = true;
    if (!formData?.name) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        name: "Title cannot be empty.",
      }));
      titleInputRef.current.focus();
      formIsValid = false;
    } else if (!formData?.slug) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        slug: "Slug cannot be empty.",
      }));
      slugInputRef.current.focus();
      formIsValid = false;
    }
    return formIsValid;
  }

  const handleImageUpload = async (id) => {
    try {
      const formData = new FormData();
      formData.append("audio_genre_id", id);
      formData.append("image", coverImageFile);
      const response = await axios.post(`${audioDefaultURL?.uploadImageURLCategory}`, formData, { headers: adminApiHeader });
      if (!response?.data?.status) {
        toast.error(response?.data?.message)
      }
    } catch (err) {
      console.error(err)
    }
  }

  // function start
  const handleBlurtitle = () => {
    generateSlug(formData?.name);
  };
  const generateSlug = (name) => {
    name = name?.trim();
    const slug = name?.toLowerCase().replace(/\s+/g, "-");
    setFormData((prevFormData) => ({
      ...prevFormData,
      slug: slug,
    }));
  };

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(formData?.name);
    }
  };

  const handleAutoFillChangetitle = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      slug: event.target.value,
    }));
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: "",
    }));
  };

  const handleBlurTitleEdit = () => {
    generateSlug(formData?.slug);
  };

  const handleInput = async (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      name: "",
      slug: "",
    }));
    console.log("Fomdata", formData)
  }
  //Handle Image upload
  const handleCoverImageChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setCoverImageFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };
  // function end

  useEffect(() => {
    handleGetCategory()
  }, [])


  return (
    <>
      {showOverlay && (<Response_Processing></Response_Processing>)}
      <AdminSaveHeader heading="Add Category" saveText="Add" saveMethod={handleStore} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text="Create category" />
            <MessageLabel text="Name" className="mandatorySimple" />
            <input type="text" name="name" value={formData?.name} onChange={handleInput} placeholder="Title" ref={titleInputRef} onBlur={handleBlurtitle} onKeyDown={handleInputKeyDowntitle} className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0 mb-3" />
            {formDataError?.name && (<MessageBox text={`${formDataError?.name}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text='Slug' />
            <input type="text" name="slug" id="slug" value={formData?.slug} onChange={handleAutoFillChangetitle} ref={slugInputRef} onBlur={handleBlurTitleEdit} placeholder="slug" className="mb-3 rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0" />
            {formDataError?.slug && (<MessageBox text={`${formDataError?.slug}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text="Category" />
            <select id="parent_id" name="parent_id" onChange={handleInput} className="form-select mb-3 custom-placeholder  theme-bg-color-secondary theme-text-color border-0" >
              <option value="0" className="theme-bg-color theme-text-color"> Choose  category</option>
              {defaultOption?.map((item) => (<option value={item.id} className="theme-bg-color theme-text-color">{item.name}</option>))}
            </select>
          </Wrapper>
        </LeftWrapper>
        <RightWrapper>
          <Wrapper>
            <MessageHeading text='Thumbnails' />
            <MessageLabel text="Image" />
            <MessageSubHeading text="Select the Audio image( 9:16 Ratio or 1080X1920px )" />
            <div className="mx-0 my-3 row">
              <div className="col-6 ps-0">
                <div className="imagedrop theme-border-color" >
                  <input type="file" ref={coverImageRef} onChange={(event) => handleCoverImageChange(event)} accept="image/*" className="opacity-0 w-100 h-100" key={coverImageFile ? coverImageFile : "inputKey"} />
                  <label className="theme-text-color position-absolute"><CameraRetroIcon className=" theme-text-color" /> </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {coverImageFile && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0">
                        <div className="col-10 p-0 ">
                          <img src={URL?.createObjectURL(coverImageFile)} alt="Uploaded" className="thumbnails-upload-images" />
                        </div>
                        <div className="col-2 p-0" style={{ alignSelf: "center" }}>
                          <button onClick={() => setCoverImageFile(null)} type="button" className="bg-transparent theme-text-color"><IoClose className="custom-close-btn"></IoClose></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
        </RightWrapper>
      </FormContainer>
    </>
  );
}

export default Addcategory;