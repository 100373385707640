import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./EditAdvertiser.css";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

const EditAdvertiser = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    company_name: "",
    license_number: "",
    ccode: "",
    email_id: "",
    mobile_number: "",
    address: "",
    password: "",
    status: "",
    stripe_id: "",
    card_brand: "",
    card_last_four: "",
  });
  const [editUsername, setEditUsername] = useState({
    company_name: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [data, setData] = useState([]);
  // console.log("data", data)
  const [number, setNumber] = useState([]);
  const [user, setUser] = useState("");

  // console.log(avatar)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisers/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData.json();
      var res = resData.Advertiser;
      setEditUser(res);
      console.log(res);
      setEditUsername(res);
      // var result = res.data.role;
      // setData(result);
      var results = resData.CountryCode;
      setNumber(results);
    };
    getUser();
  }, [id]);
  const handleInputcode = (event) => {
    const { name, value } = event.target;
    const intValue = parseInt(value); // Parse value as an integer with base 10

    setEditUser({
      ...editUser,
      [name]: intValue, // Assign the integer value to editUser's property
    });
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagecompany_name("");
    setIsValidationHiddencompany_name(true);
    setEditUser({ ...editUser, [e.target.company_name]: e.target.value });
  };

  const [validationMessagecompany_name, setValidationMessagecompany_name] =
    useState("");
  const [isValidationHiddencompany_name, setIsValidationHiddencompany_name] =
    useState(true);

  const [validationMessagelicense_number, setValidationMessagelicense_number] =
    useState("");
  const [
    isValidationHiddenlicense_number,
    setIsValidationHiddenlicense_number,
  ] = useState(true);

  const [validationMessageemail, setValidationMessageemail] = useState("");
  const [isValidationHiddenemail, setIsValidationHiddenemail] = useState(true);
  const [focusInputRef, setFocusInputRef] = useState(null); // Initialize focusInputRef to null

  const [validationMessagemobile_number, setValidationMessagemobile_number] =
    useState("");
  const [isValidationHiddenmobile_number, setIsValidationHiddenmobile_number] =
    useState(true);

  const [validationMessageccode, setValidationMessageccode] = useState("");
  const [isValidationHiddenccode, setIsValidationHiddenccode] = useState(true);

  const [validationMessagepassword, setValidationMessagepassword] =
    useState("");
  const [isValidationHiddenpassword, setIsValidationHiddenpassword] =
    useState(true);

  const company_nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const mobile_numberInputRef = useRef(null);
  const ccodeInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    setValidationMessageemail("");
    setIsValidationHiddenemail(true);

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    let focusInputRef = null;

    if (editUser?.company_name === "") {
      setValidationMessagecompany_name("Name cannot be empty.");
      setIsValidationHiddencompany_name(false);
      focusInputRef = company_nameInputRef;
    }

    if (editUser?.ccode === "") {
      setValidationMessageccode("");
      setIsValidationHiddenccode(false);
      focusInputRef = ccodeInputRef;
    }
    if (editUser?.mobile_number === "") {
      setValidationMessagemobile_number(
        "Code and Mobile Number cannot be empty."
      );
      setIsValidationHiddenmobile_number(false);
      focusInputRef = mobile_numberInputRef;
    }
    // if (editUser?.password === "") {
    //   setValidationMessagepassword("Passwords cannot be empty.");
    //   setIsValidationHiddenpassword(false);
    //   focusInputRef = passwordInputRef;
    // }
    if (!editUser?.email_id || !emailPattern.test(editUser?.email_id)) {
      setValidationMessageemail("Invalid email format.");
      setIsValidationHiddenemail(false);
      emailInputRef.current.focus();
      return;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      company_name: editUser.company_name,
      license_number: editUser.license_number,
      ccode: editUser.ccode,
      email_id: editUser.email_id,
      mobile_number: editUser.mobile_number,
      address: editUser.address,
      password: editUser.password,
      status: editUser.status,
      stripe_id: editUser.stripe_id,
      card_brand: editUser.card_brand,
      card_last_four: editUser.card_last_four,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisers/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      } else if (resjson.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      setValidationMessagecompany_name("");
      setValidationMessagelicense_number("");
      setValidationMessageemail("");
      setValidationMessagepassword("");
      setValidationMessagemobile_number("");
      setValidationMessageccode("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      // Check if resultapifalse is defined and not null
      props.setApiresponsealert(resultapifalse);
      props.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddencompany_name(false);
      setValidationMessagelicense_number(false);
      setIsValidationHiddenpassword(true);
      setIsValidationHiddenmobile_number(true);
      setIsValidationHiddenccode(true);
    }
  };

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   const editInputvalue = {
  //     company_name: editUser.company_name,
  //     license_number: editUser.license_number,
  //     ccode: editUser.ccode,
  //     email_id: editUser.email_id,
  //     mobile_number: editUser.mobile_number,
  //     address: editUser.address,
  //     password: editUser.password,
  //     status: editUser.status,
  //     stripe_id: editUser.stripe_id,
  //     card_brand: editUser.card_brand,
  //     card_last_four: editUser.card_last_four,
  //   };
  //   // console.log(editInputvalue);
  //   let res = await fetch(
  //     `${process.env.REACT_APP_Baseurl}/admin/Advertisers/update/` + id,
  //     {
  //       method: "PUT",
  //       headers: headers,
  //       body: JSON.stringify(editInputvalue),
  //     }
  //   );

  //   let resjson = await res.json();
  //   // console.log(resjson)
  //   if (res.status === 200) {
  //     setMessage(resjson.success);
  //     alert("Success");
  //   } else {
  //     setMessage("Some error Occured");
  //     console.log("Error");
  //     alert("Enter Correct Details");
  //   }
  // };

  return (
    <>
      <div className=" m-0 p-0">
        <section className="container-fluid">
          <div className="col-lg-12 row py-3">
            <div className="col-6 col-sm-6 col-lg-6">
              <h3>Edit Advertiser</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Advertiser
                </button>
              </div>
            </div>
          </div>

          <div>
            {showOverlay && (
              <div className="overlay-container">
                <span className="overlay-icon">
                  {" "}
                  <Response_Loading_processing></Response_Loading_processing>
                  Processing ...
                </span>
              </div>
            )}
          </div>

          <div className="row col-lg-12 ">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
              <div className="card p-3">
                <h5>{editUsername.company_name}</h5>
                <hr></hr>
                <div className=" text-start">
                  <label className="m-0">
                    Company Name<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="company_name"
                      name="company_name"
                      placeholder="Enter Name"
                      onChange={handleInput}
                      value={editUser?.company_name}
                      ref={company_nameInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddencompany_name && (
                      <p>{validationMessagecompany_name}</p>
                    )}
                  </span>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0">
                    User Email Address<span className="mandatory">*</span>
                  </label>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="email"
                      name="email"
                      placeholder="Enter Email"
                      value={editUser?.email}
                      onChange={handleInput}
                      ref={emailInputRef}
                    />
                  </div>

                  <span className="errorred">
                    {!isValidationHiddenemail && (
                      <p>{validationMessageemail}</p>
                    )}
                  </span>
                </div>

                <div className="mt-2 text-start">
                  <label className="m-0">
                    User Mobile Number<span className="mandatory">*</span>
                  </label>

                  <div className="mt-2">
                    <div className="row">
                      <div className="col-sm-4">
                        <select
                          className="form-select"
                          name="ccode"
                          id="ccode"
                          value={editUser?.ccode}
                          onChange={handleInputcode}
                          ref={ccodeInputRef}
                        >
                          {number?.map((item) => (
                            <option key={item?.id} value={item?.id}>
                              {item?.country_name} ({item?.phonecode})
                            </option>
                          ))}
                        </select>

                        <span className="errorred">
                          {!isValidationHiddenccode && (
                            <p>{validationMessageccode}</p>
                          )}
                        </span>
                      </div>
                      <div className="col-sm-8">
                        <input
                          type="number"
                          style={{ height: "50px" }}
                          className="rs-input form-control-lg"
                          id="mobile_number"
                          name="mobile_number"
                          placeholder="Enter Mobile"
                          value={editUser?.mobile_number}
                          onChange={handleInput}
                          ref={mobile_numberInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddenmobile_number && (
                          <p>{validationMessagemobile_number}</p>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0">
                      User Password<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <span
                        className="eyepassworduserad"
                        aria-hidden="true"
                        onClick={togglePassword}
                      >
                        {" "}
                        {passwordType === "password" ? (
                          <i className="fa fa-eye-slash"></i>
                        ) : (
                          <i className="fa fa-eye"></i>
                        )}
                      </span>

                      <input
                        type={passwordType}
                        autoComplete="off"
                        className="rs-input form-control-lg"
                        id="password"
                        name="password"
                        placeholder=""
                        // value={editUser?.password}
                        onChange={handleInput}
                        ref={passwordInputRef}
                      />
                      <span className="errorred">
                        {!isValidationHiddenpassword && (
                          <p>{validationMessagepassword}</p>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-2 text-start">
                  <label className="m-0">License Number</label>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="license_number"
                      name="license_number"
                      placeholder="Enter Slug"
                      onChange={handleInput}
                      value={editUser?.license_number}
                    />
                  </div>
                </div>

                <div className="mt-2 text-start">
                  <label className="m-0">Address</label>

                  <div className="mt-2">
                    <textarea
                      type="text"
                      className="rs-input form-control-lg"
                      id="address"
                      name="address"
                      placeholder="Enter Slug"
                      onChange={handleInput}
                      value={editUser?.address}
                    ></textarea>
                  </div>
                </div>
              </div>

              {/* <div className="text-start mb-3">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Advertiser
                </button>
              </div> */}
            </div>
          </div>
        </section>
      </div>

      {/* <div>
        <div className=" m-0 p-0">
          <div className="">
            <section className="section container-fluid pt-3">
              <div className="iq-card p-2 mt-3">
                <h4 className="p-2">
                  <i className="entypo-plus"></i> Update Advertiser
                </h4>
                <hr />
                <div className="p-3">
                  <div className="col-md-12 mb-3">
                    <label className="m-0">Company Name:</label>

                    <input
                      type="text"
                      className="form-control"
                      name="company_name"
                      id="company_name"
                      value={editUser.company_name}
                      placeholder="Enter Company Name"
                      onChange={handleInput}
                    />
                  </div>

                  <div className="col-md-12 mb-3" data-collapsed="0">
                    <label className="m-0">License Number:</label>
                    <div className="panel-body">
                      <input
                        type="number"
                        className="form-control"
                        name="license_number"
                        id="license_number"
                        value={editUser.license_number}
                        onChange={handleInput}
                        placeholder="Enter License Number"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="m-0">Address:</label>

                    <input
                      type="address"
                      className="form-control"
                      name="address"
                      value={editUser.address}
                      onChange={handleInput}
                      id="address"
                      placeholder="Enter Address"
                    />
                  </div>

                  <div className="col-md-12 mb-3" data-collapsed="0">
                    <label className="m-0">Mobile Number:</label>
                    <div className="panel-body">
                      <input
                        type="number"
                        className="form-control"
                        name="mobile_number"
                        id="mobile_number"
                        onChange={handleInput}
                        value={editUser.mobile_number}
                        placeholder="Enter Number"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="m-0">Email Name:</label>

                    <input
                      type="email_id"
                      className="form-control"
                      name="email_id"
                      onChange={handleInput}
                      id="email_id"
                      value={editUser.email_id}
                      placeholder="Enter Email"
                    />
                  </div>

                  <hr />

                  <div className="row mt-3">
                    <div className="col-sm-12 text-end">
                      <input
                        type="submit"
                        value="Upload"
                        onClick={handleUpdate}
                        className="btn btn-primary "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default EditAdvertiser;
