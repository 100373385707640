import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

import "./SubcriptionPayment.css";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";

function SubcriptionPayment(props) {
  const [getSubcriptionPayment, setSubcriptionPayment] = useState([]);
  const [filteredSubcriptionPayment, setFilteredSubcriptionPayment] = useState(
    []
  );
  const [itemToDeletesubcription, setItemToDeletesubcription] = useState([]);
  const [modalClosesubcription, setModalClosesubcription] = useState(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/list`,
        { headers: headers }
      );
      const liveData = response?.data?.LiveStream;
      const filterHelp = liveData?.map((item) => {
        const UploadStatus = "test";
        const statusText = "EMAIL";
        const SUBSCRIPTION_TITLE = "SUBSCRIPTION TITLE";
        const PAYMENT_ID = "PAYMENT_ID";
        const PAYMENT_MODE = "PAYMENT_MODE";
        const PAID_AMOUNT = "PAID_AMOUNT";
        const EXPIRY_DATE = "EXPIRY_DATE";
        const STATUS = "STATUS";

        return {
          ...item,
          USERNAME: UploadStatus,
          EMAIL: statusText,
          SUBSCRIPTION_TITLE: SUBSCRIPTION_TITLE,
          PAYMENT_ID: PAYMENT_ID,
          PAYMENT_MODE: PAYMENT_MODE,
          PAID_AMOUNT: PAID_AMOUNT,
          EXPIRY_DATE: EXPIRY_DATE,
          STATUS: STATUS,
        };
      });
      setSubcriptionPayment(filterHelp);
      setFilteredSubcriptionPayment(filterHelp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(async () => {
    fetchData();
  }, []);
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredSubcriptionPayment?.length },
    ],
  });

  const subcriptionFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="rightVideoTitle">
          <p>{data}</p>
        </div>
      </div>
    );
  };

  const statusFormatter = (data, row) => {
    return <div>{data}</div>;
  };
  const USERNAMEFormatter = (data, row) => {
    return <div>{data}</div>;
  };
  const SUBSCRIPTION_TITLEFormatter = (data, row) => {
    return <div>{data}</div>;
  };
  const PAYMENT_IDFormatter = (data, row) => {
    return <div>{data}</div>;
  };
  const PAYMENT_MODEFormatter = (data, row) => {
    return <div>{data}</div>;
  };
  const PAID_AMOUNTFormatter = (data, row) => {
    return <div>{data}</div>;
  };
  const EXPIRY_DATEFormatter = (data, row) => {
    return <div>{data}</div>;
  };
  const STATUSFormatter = (data, row) => {
    return <div>{data}</div>;
  };

  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <Link
          to={`/payment/subscription-view/${data}`}
          className="edit ms-1"
        >
          <AiOutlineEye />
        </Link>
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      formatter: subcriptionFormatter,
    },
    {
      dataField: "USERNAME",
      text: "USERNAME",
      sort: true,
      formatter: USERNAMEFormatter,
    },
    {
      dataField: "EMAIL",
      text: "EMAIL",
      formatter: statusFormatter,
    },
    {
      dataField: "SUBSCRIPTION_TITLE",
      text: "SUBSCRIPTION TITLE",
      formatter: SUBSCRIPTION_TITLEFormatter,
    },
    {
      dataField: "PAYMENT_ID",
      text: "PAYMENT ID",
      formatter: PAYMENT_IDFormatter,
    },
    {
      dataField: "PAYMENT_MODE",
      text: "PAYMENT MODE",
      formatter: PAYMENT_MODEFormatter,
    },
    {
      dataField: "PAID_AMOUNT",
      text: "PAID AMOUNT",
      formatter: PAID_AMOUNTFormatter,
    },
    {
      dataField: "EXPIRY_DATE",
      text: "EXPIRY DATE",
      formatter: EXPIRY_DATEFormatter,
    },
    {
      dataField: "STATUS",
      text: "STATUS",
      formatter: STATUSFormatter,
    },
    {
      dataField: "id",
      text: "ACTIONS",
      formatter: actionFormatter,
    },
  ];

  const exportHeaderssubcription = [
    { label: "ID", key: "id" },
    { label: "ID", key: "id" },
    { label: "USERNAME", key: "USERNAME" },
    { label: "EMAIL", key: "EMAIL" },
    { label: "SUBSCRIPTION TITLE", key: "SUBSCRIPTION_TITLE" },
    { label: "PAYMENT ID", key: "PAYMENT_ID" },
    { label: "PAYMENT MODE", key: "PAYMENT_MODE" },
    { label: "PAID AMOUNT", key: "PAID_AMOUNT" },
    { label: "EXPIRY DATE", key: "EXPIRY_DATE" },
    { label: "STATUS", key: "STATUS" },
    { label: "ACTIONS", key: "ACTIONS" },
  ];

  const conditionalGeneratePDFsubcription = (get) => {
    const doc = new jsPDF();
    doc.text("ALL LIVE LIST ", 10, 10);
    const columns = [
      "Index",
      "ID",
      "USERNAME",
      "EMAIL",
      "SUBSCRIPTION TITLE",
      "PAYMENT ID",
      "PAYMENT MODE",
      "PAID AMOUNT",
      "STATUS",
      "EXPIRY DATE",
      "ACTIONS",
    ];
    const allDataPDF = getSubcriptionPayment?.map((item, index) => [
      index + 1,
      item?.id,
      item?.USERNAME,
      item?.EMAIL,
      item?.SUBSCRIPTION_TITLE,
      item?.PAYMENT_ID,
      item?.PAYMENT_MODE,
      item?.PAID_AMOUNT,
      item?.EXPIRY_DATE,
      item?.STATUS,
      item?.ACTIONS,
    ]);
    const filterPDF = filteredSubcriptionPayment?.map((item, index) => [
      index + 1,
      item?.id,
      item?.USERNAME,
      item?.EMAIL,
      item?.SUBSCRIPTION_TITLE,
      item?.PAYMENT_ID,
      item?.PAYMENT_MODE,
      item?.PAID_AMOUNT,
      item?.EXPIRY_DATE,
      item?.STATUS,
      item?.ACTIONS,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("ALL-LIVE-LIST.pdf");
  };

  const generateXLSXLIVE = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(
        get === 1 ? getSubcriptionPayment : filteredSubcriptionPayment
      );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "ALL-LIVE-LIST.xlsx");
  };

  const handleFilterChangesubcription = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = getSubcriptionPayment.filter((item) =>
      [
        "id",
        "USERNAME",
        "EMAIL",
        "SUBSCRIPTION TITLE",
        "PAYMENT ID",
        "PAYMENT MODE",
        "PAID AMOUNT",
        "STATUS",
        "EXPIRY DATE",
        "ACTIONS",
      ].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredSubcriptionPayment(filteredResults);
  };

  const handleClear = () => {
    fetchData();
    setFilter("");
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDeletesubcription(
      selectAll ? [] : filteredSubcriptionPayment?.map((item) => item?.id)
    );
  };

  const closesubcriptionModal = () => {
    setModalClosesubcription(!modalClosesubcription);
  };

  const openModalSingleDeletesubcription = (id) => {
    setItemToDeletesubcription([id]);
    setModalClosesubcription(true);
  };

  const openModalMultiDeletesubcription = () => {
    setModalClosesubcription(true);
  };

  const handleDeleteSubcriptionPayment = () => {
    closesubcriptionModal();
    liveDeleteOperation();
  };

  const handleToggle = async (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const confirmToggle = async () => {
    try {
      setShowModal(false);
      const newStatus = currentItem?.banner === 1 ? 0 : 1;
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/livestream-slider`,
        {
          method: "POST",
          body: JSON.stringify({
            live_id: currentItem?.id,
            banner_status: newStatus,
          }),
          headers: headers,
        }
      );
      setCurrentItem(null);
      props?.setApiresponsealert({
        status: true,
        message: "Switched the Slider status successfully",
      });
      props?.setShowalert(true);
      fetchData();
      setProcessing(false);
      setShowOverlay(false);
    } catch (err) {
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const closeModalslider = () => {
    setShowModal(false);
    setCurrentItem(null);
  };

  const liveDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeletesubcription + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <>
      <div
        className={`modal  packageModel ${modalClosesubcription ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: modalClosesubcription ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closesubcriptionModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeletesubcription?.length === 1 ? (
                <p className="fs-6">
                  {" "}
                  Are you sure you want to delete this live ?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected lives ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={closesubcriptionModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteSubcriptionPayment}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal packageModel ${showModal ? "show fade" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: `${showModal ? "block" : "none"}` }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Slider </h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModalslider}
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to Switch the Slider status?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModalslider}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={confirmToggle}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-8">
              <h4>
                All Subcription Payment{" "}
                <span className="fs-6">
                  (Display all {getSubcriptionPayment?.length} Payment)
                </span>
              </h4>
            </div>
            <div className="col-md-4 col-12 mt-3 mt-md-0" align="right">
              {itemToDeletesubcription?.length > 0 && (
                <button
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModalMultiDeletesubcription}
                >
                  <MdDelete className="exportIcon " />
                  Delete
                </button>
              )}
              {/* <Link
                to="/add-livestream"
                className="btn btn-primary fw-bolder"
              >
                <i className="fa fa-plus-circle"></i> Add subcriptionStream
              </Link> */}
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
              <div className="filterContainer">
                <input
                  type="text"
                  placeholder="Filter all.."
                  className="filter form-control "
                  value={filter}
                  onChange={handleFilterChangesubcription}
                />
                {filter?.length > 0 && (
                  <button
                    type="button"
                    className="closeBtn"
                    onClick={handleClear}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                )}
              </div>
              <div className="btn-group">
                <div className="dropdown d-block " key={"2"}>
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export </span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeaderssubcription}
                        data={filteredSubcriptionPayment}
                        className="text-dark d-block"
                        filename="LIVE-FILTERED-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFsubcription(0);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXLIVE(0);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>

                <div className="dropdown d-block ">
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export All</span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeaderssubcription}
                        data={getSubcriptionPayment}
                        className="text-dark d-block"
                        filename="ALL-LIVES-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFsubcription(1);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXLIVE(1);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bootstrapTable">
              <BootstrapTable
                keyField="id"
                columns={columns}
                data={filteredSubcriptionPayment}
                pagination={pagination}
                className="no-border-table"
                noDataIndication={
                  <div className="noRecord">
                    {loading ? (
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>
                        Loading...
                      </label>
                    ) : (
                      filteredSubcriptionPayment?.length <= 0 && (
                        <p>No Subcription Payment Available. !!!</p>
                      )
                    )}
                  </div>
                }
                hover
                headerClasses="videoWidth"
                selectAll={selectAll}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SubcriptionPayment;
