import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../src/layout/Others/Response_Processing";

function Add_Faq_category(props) {
  const [parent_id, setParent_id] = useState("");
  const [banner, setBanner] = useState("");

  const [data, setData] = useState([]);

  const [editUser, setEditUser] = useState({
    name: "",
    slug: "",
    genre_id: "",
  });

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    // setValidationMessageslug("");
    // setIsValidationHiddenslug(true);
    setValidationMessagename("");
    setIsValidationHiddenname(true);
    setvalidationMessagegenre("");
    setisValidationHiddengenre(true);

    setEditUser({ ...editUser, name: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    setBanner({ ...banner, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setBanner({ ...banner, [e.target.name]: 1 });
    } else {
      setBanner({ ...banner, [e.target.name]: 0 });
    }
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = () => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
  };

  const [autoFillValuename, setAutoFillValuename] = useState("");

  const handleInputKeyDownname = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(editUser?.name);
    }
  };

  const handleAutoFillChangename = (event) => {
    // setValidationMessageslug("");
    // setIsValidationHiddenslug(true);
    setAutoFillValuename(event.target.value);
  };
  const handleBlurname = () => {
    generateSlug(editUser?.name);
  };

  const generateSlug = (name) => {
    name = name.trim();
    const slug = name.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuename(slug);
  };

  const [validationMessagename, setValidationMessagename] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);


  const [validationMessagegenre, setvalidationMessagegenre] =
    useState("");
  const [isValidationHiddengenre, setisValidationHiddengenre] =
    useState(true);

  const genreInputRef = useRef(null);
  const nameInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function Add_Faq_category() {

    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagename("Name cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }
    if (editUser?.genre_id === "") {
      setvalidationMessagegenre("Please select Genre.");
      setisValidationHiddengenre(false);
      focusInputRef = genreInputRef;
    }
    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }


    const formData = new FormData();

    formData.append("name", editUser?.name);
    formData.append("slug", autoFillValuename);
    formData.append("parent_id", parent_id ? parseInt(parent_id, 10) : 0);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/faq-genre/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;
      } else if (response?.data?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      setValidationMessagename("");
      setvalidationMessagegenre("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        navigate("/faq-category");
      }, 3000);
    } else {
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setisValidationHiddengenre(false);
      setIsValidationHiddenname(false);
      setProcessing(false);
      setShowOverlay(false);
    }
  }

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/faq-genre/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.FAQ_Genre;
        setData(result);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div className=" m-0 p-0">
        <section className="container-fluid">
          <div className="col-lg-12 row py-3">
            <div className="col-6 col-sm-6 col-lg-6">
              <h3>Add New Category</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button
                  onClick={Add_Faq_category}
                  className="btn btn-primary"
                >
                  Save Category
                </button>
              </div>
            </div>
          </div>

          <div>
            {showOverlay && <Response_Processing></Response_Processing>}
          </div>

          <div className="row col-lg-12 ">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
              <div className="card p-3">
                <h5>Create Category</h5>
                <hr></hr>
                <div className=" text-start">
                  <label className="m-0">
                    Name<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="name"
                      name="name"
                      placeholder="Enter Name"
                      onChange={handleInput}
                      value={editUser?.name}
                      onKeyDown={handleInputKeyDownname}
                      ref={nameInputRef}
                      onBlur={handleBlurname}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddenname && (
                      <p>{validationMessagename}</p>
                    )}
                  </span>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0">Slug</label>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="slug"
                      name="slug"
                      placeholder="Enter Slug"
                      value={autoFillValuename}
                      onChange={handleAutoFillChangename}
                    />
                  </div>
                </div>

                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0">Geners</label>

                    <div className="mt-2">
                      <select
                        className="form-select"
                        id="genre_id"
                        name="genre_id"
                        ref={genreInputRef}
                        onChange={handleInput}
                        onClick={(event) => {
                          setParent_id(event.target.value);
                        }}
                      >
                        <option value="0">Select a Geners</option>
                        {data?.map((item) => (
                          <option value={item?.id}>{item?.name}</option>
                        ))}
                      </select>
                    </div>
                    <span className="errorred">
                      {!isValidationHiddengenre && (
                        <p>{validationMessagegenre}</p>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className="text-start mb-3">
                <button
                  onClick={Add_Faq_category}
                  className="btn btn-primary"
                >
                  Save Category
                </button>
              </div>
            </div>

            {/* Second Part of Section  */}

            {/* <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="card p-3">
                <h5>Thumbnails</h5>
                <hr></hr>

                <div className=" text-start mt-2">
                  <label className="m-0">Image</label>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef1.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef1}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef1,
                                setSelectedFile1
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div>
                          {selectedFile1 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL.createObjectURL(selectedFile1)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() =>
                                      handleDelete(setSelectedFile1)
                                    }
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div> */}
          </div>
        </section>

        {/* <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="admin-section-name">
                <div className="iq-card">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>
                        <i className=""></i> Add Video Categories
                      </h4>
                    </div>
                  </div>
                  <hr />

                  <div className="">
                    <div>
                      <div className="form-group ">
                        <label>Name:</label>

                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          className="form-control"
                          placeholder="Enter Name"
                        />
                      </div>

                      <div className="form-group ">
                        <label>Slug:</label>

                        <input
                          type="text"
                          id="slug"
                          name="slug"
                          onChange={(e) => setSlug(e.target.value)}
                          className="form-control"
                          placeholder="Enter Slug"
                        />
                      </div>
                      <div className="form-group">
                        <label>Home Page Genre Name:</label>
                        <input
                          type="text"
                          id="home_genre"
                          name="home_genre"
                          onChange={(e) => setHome_genre(e.target.value)}
                          className="form-control"
                          placeholder="Enter Home Page Genre Name"
                        />
                      </div>
                      <div className="form-group ">
                        <label>Display In Home page:</label>

                        <label className="switch">
                          <input
                            name="in_home"
                            onChange={handleInput}
                            defaultChecked={
                              in_home?.in_home === 1 ? true : false
                            }
                            checked={in_home?.in_home === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="in_home"
                            onChange={handleInput}
                            value={in_home?.in_home === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>

                      <div className="form-group ">
                        <label>Display In Menu :</label>
                        <label className="switch">
                          <input
                            name="in_menu"
                            onChange={handleInput}
                            defaultChecked={
                              in_menu?.in_menu === 1 ? true : false
                            }
                            checked={in_menu?.in_menu === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="in_menu"
                            onChange={handleInput}
                            value={in_menu?.in_menu === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                      <div className="form-group ">
                        <label>Image:</label>
                        <input
                          type="file"
                          multiple="true"
                          className="form-control"
                          name="image"
                          onChange={(e) => setImage(e.target.files[0])}
                          id="image"
                        />
                      </div>

                      <div className="form-group ">
                        <label>Banner Image:</label>
                        <input
                          type="file"
                          multiple="true"
                          className="form-control"
                          name="banner_image"
                          onChange={(e) => setBanner_image(e.target.files[0])}
                          id="banner_image"
                        />
                      </div>

                      <div className="form-group ">
                        <label>Geners</label>
                        <select
                          id="parent_id"
                          name="parent_id"
                          className="form-control"
                          onChange={(e) => setParent_id(e.target.value)}
                        >
                          {data?.map((item, key) => (
                            <option key={item?.id} value={item?.parent_id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group ">
                        <label>Display In Home Banner:</label>
                        <label className="switch">
                          <input
                            name="banner"
                            onChange={handleInput}
                            defaultChecked={banner?.banner === 1 ? true : false}
                            checked={banner?.banner === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            onChange={handleInput}
                            value={banner?.banner === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>

                      <div className=" text-end form-group">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          id="submit-new-cat"
                          onClick={Add_Faq_category}
                        >
                          Add Category
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div> */}
      </div>
    </>
  );
}

export default Add_Faq_category;
