import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Livecontentpartner() {
  const [cpp, setCpp] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/content_partner/livestream-pending`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.pending_videos?.data;
        setCpp(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const rejected = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/content_partner/livestream-video/` +
          id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Rejected Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  const approved = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/content_partner/livestream-video/` +
          id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Approved Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/content_partner/videos-pending?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious(res?.data?.pending_videos?.data);
        setCpp(res?.data?.pending_videos?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/content_partner/videos-pending?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious(res?.data?.pending_videos?.data);
        setCpp(res?.data?.pending_videos?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/content_partner/videos-pending?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.videos.data);
        setNext(res?.data?.pending_videos?.data);
        setCpp(res?.data?.pending_videos?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="">
              <div className="row">
                <div className="col-sm-12">
                  <div className="py-3">
                    <h4>CPP Uploaded Live Stream</h4>
                  </div>
                  <div className="iq-card">
                    <div className="iq-card-header">
                      <div className="row ">
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                          >
                            {" "}
                            <div className="iq-card-body  p-0">
                              <hr />
                              <Table>
                                <thead>
                                  <tr>
                                    <th>TITLE</th>
                                    <th>USER NAME</th>
                                    <th>YEAR</th>
                                    <th>VIDEO ACCESS</th>
                                    <th>STATUS</th>
                                    <th>ACTION</th>
                                  </tr>
                                </thead>
                                {cpp?.map((item, key) => (
                                  <tbody>
                                    <tr>
                                      <td
                                        key={item?.id}
                                        className="table-content"
                                      >
                                        <p>{item?.title}</p>
                                      </td>
                                      <td className="table-content">
                                        <p>{item?.username}</p>
                                      </td>
                                      <td className="table-content">
                                        <p>{item?.year}</p>
                                      </td>
                                      <td className="table-content">
                                        <p>{item?.access}</p>
                                      </td>
                                      <td className="text-center">
                                        {item?.ppv_status === 0 ||
                                        item?.ppv_status === null ? (
                                          <td className="bg-warning video_active">
                                            Pending Approved Rejected
                                          </td>
                                        ) : item?.ppv_status === 1 &&
                                          item?.ppv_status === 1 &&
                                          item?.ppv_status === 1 ? (
                                          <td className="bg-success video_active">
                                            Approved
                                          </td>
                                        ) : (
                                          <td className="bg-warning video_active">
                                            Draft
                                          </td>
                                        )}
                                      </td>
                                      <td colSpan="2">
                                        <div className="flex align-items-center list-user-action fs-5">
                                          <Link
                                            className="iq-bg-warning"
                                            to=""
                                            onClick={() => approved(item?.id)}
                                          >
                                            <i className="fa fa-check-circle"></i>
                                          </Link>
                                          <Link
                                            className="iq-bg-success ms-2"
                                            to=""
                                            onClick={() => rejected(item?.id)}
                                          >
                                            <i className="fa fa-close"></i>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </Table>

                              {/* <div className="table-view">
                                <table
                                  className="table text-center table-striped table-bordered table movie_table iq-card"
                                  id="videocpp"
                                >
                                  <thead>
                                    <tr>
                                      <th>Title</th>
                                      <th>Video Uploaded By</th>
                                      <th>Video Type</th>
                                      <th>Uploaded Date</th>
                                      <th>Video Duration</th>
                                      <th>Video Category</th>
                                      <th>Video Meta</th>
                                      <th>Status</th>
                                      <th>ACTION</th>
                                    </tr>
                                  </thead>

                                  {cpp?.map((item, index) => {
                                    const value = item?.id;
                                    const value2 = item?.id;

                                    if (value && value2) {
                                      return (
                                        <tbody>
                                          <tr key={item?.id}>
                                            <td>{item?.title}</td>
                                            <td>{item?.uploaded_by}</td>
                                            <td>{item?.type}</td>
                                            <td>{item?.created_at}</td>
                                            <td>{item?.duration}</td>
                                            <td>{item?.video_category_id}</td>
                                            <td>{item?.status}</td>

                                            <td className="text-center">
                                              {item?.status === 0 ||
                                              item?.status === null ? (
                                                <td className="bg-warning video_active">
                                                  Pending Approved Rejected
                                                </td>
                                              ) : item?.status === 1 &&
                                                item?.status === 1 &&
                                                item?.status === 1 ? (
                                                <td className="bg-success video_active">
                                                  Approved
                                                </td>
                                              ) : (
                                                <td className="bg-warning video_active">
                                                  Draft
                                                </td>
                                              )}
                                            </td>
                                            <td colSpan="2">
                                              <div className="flex align-items-center list-user-action fs-5">
                                                <Link
                                                  className="iq-bg-warning"
                                                  to=""
                                                  onClick={() =>
                                                    approved(item?.id)
                                                  }
                                                >
                                                  <i className="fa fa-check-circle"></i>
                                                </Link>
                                                <Link
                                                  className="iq-bg-success ms-2"
                                                  to=""
                                                  onClick={() =>
                                                    rejected(item?.id)
                                                  }
                                                >
                                                  <i className="fa fa-close"></i>
                                                </Link>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      );
                                    } else {
                                      return <div></div>;
                                    }
                                  })}

                                 
                                </table>
                              </div> */}
                              <div className="row align-items-center text-center d-flex">
                                <div className="col-lg-4">
                                  <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={handlePreClick}
                                  >
                                    {"<< Previous"}
                                  </button>
                                </div>
                                <div className="col-lg-4">
                                  <p className="">{page}</p>
                                </div>
                                <div className="col-lg-4">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleNxtClick}
                                  >
                                    {"Next >>"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Livecontentpartner;
