import React from 'react'

const Add_Albums = () => {
  return (
    <div>
        <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid">
          <div className="mt-4">
            <div className="iq-card">
              <h4>Add Album</h4>
              <hr />
              <div className="modal-body">
                <div>
                  <div className="form-group ">
                    <label>Name:</label>
                    <input
                      type="text"
                      id="albumname"
                      name="albumname"
                      className="form-control"
                    //   onChange={(e) => setAlbumname(e.target.value)}
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-group add-profile-pic mt-2">
                    <label>Cover Image:</label>
                    <input
                      id="f02"
                      type="file"
                      name="album"
                      className="ms-3"
                      placeholder="Add profile picture"
                    //   onChange={(e) => setAlbum(e.target.files[0])}
                    />
                    <p className="">
                      Must be JPEG, PNG, or GIF and cannot exceed 10MB.
                    </p>
                  </div>
                  <div className="form-group">
                    <label>Slug:</label>
                    <input
                      type="text"
                      id="slug"
                      name="slug"
                      className="form-control"
                    //   onChange={(e) => setSlug(e.target.value)}
                      placeholder="Enter Slug"
                    />
                    <input
                      type="text"
                      hidden
                      id="uploaded_by"
                      name="uploaded_by"
                      className="form-control"
                    //   onChange={(e) => setUploaded_by(e.target.value)}
                    />
                  </div>
                  <div className="form-group ">
                    <label>Category:</label>
                    <select
                      className="form-control"
                      name="parent_id"
                      id="parent_id"
                    //   onChange={(e) => setParent_id(e.target.value)}
                    >
                      <option value="0">Select</option>
                      {/* {user.map((item) => (
                        <option value={item.parent_id}>{item.albumname}</option>
                      ))} */}
                    </select>
                  </div>
                  <div className="text-end p-3 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      id="submit-new-cat"
                    //   onClick={submitalbum}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    </div>
  )
}

export default Add_Albums