import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";

function Advertiser(props) {
  const [dataadvertiser, setDataadvertiser] = useState([]);
  const [advertiserdataadvertiser, setVideodataadvertiser] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [defaultImage, setDefaultimage] = useState();
  const [defaultImageurl, setDefaultimageurl] = useState("");

  const [countadvertiser, setCountadvertiser] = useState();
  const [currentPageadvertiser, setCurrentPageadvertiser] = useState(1);
  const itemsPerPageadvertiser = 10;

  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [pageadvertiser, setPageadvertiser] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  useEffect(() => {
    fetchDataadvertiser();
    fetchDatanextpreadvertiser();
  }, [pageadvertiser]);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers":
      "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "*",
  };

  const advertisersdeleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisers/delete/` + id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          props.setApiresponsealert(responseData);
          props.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props.setApiresponsealert(resultError);
            props.setShowalert(true);
          }
        }
      });
  };

  const [selectedItemsadvertiser, setSelectedItemsadvertiser] = useState([]);
  const [selectAlladvertiser, setSelectAlladvertiser] = useState(false);
  const [isConfirmationOpenadvertiser, setIsConfirmationOpenadvertiser] =
    useState(false);
  const [isSuccessMessageOpenadvertiser, setIsSuccessMessageOpenadvertiser] =
    useState(false);

  const handleSingleSelectadvertiser = (itemId) => {
    setSelectedItemsadvertiser([itemId]);
    setSelectAlladvertiser(false);
  };

  const handleMultiSelectadvertiser = (itemId) => {
    if (selectedItemsadvertiser.includes(itemId)) {
      setSelectedItemsadvertiser(
        selectedItemsadvertiser.filter((id) => id !== itemId)
      );
    } else {
      setSelectedItemsadvertiser([...selectedItemsadvertiser, itemId]);
    }
  };

  const handleSelectAlladvertiser = () => {
    setSelectAlladvertiser(!selectAlladvertiser);
    setSelectedItemsadvertiser(
      selectAlladvertiser ? [] : advertiserdataadvertiser.map((item) => item.id)
    );
  };

  const handleDeleteadvertiser = () => {
    setIsConfirmationOpenadvertiser(true);
  };
  const handleCancelDeleteadvertiser = () => {
    setIsConfirmationOpenadvertiser(false);
  };

  const handleConfirmDeleteadvertiser = () => {
    setIsConfirmationOpenadvertiser(false);
    for (const itemId of selectedItemsadvertiser) {
      axios
        .delete(
          `${process.env.REACT_APP_Baseurl}/admin/Advertisers/delete/${itemId}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.data.status == true) {
            setVideodataadvertiser(
              advertiserdataadvertiser.filter((item) => item.id !== itemId)
            );
            setSelectedItemsadvertiser(
              selectedItemsadvertiser.filter((id) => id !== itemId)
            );
            setIsSuccessMessageOpenadvertiser(true);
            setTimeout(() => {
              setIsSuccessMessageOpenadvertiser(false);
            }, 2000);
            var responseData = response.data;
            props.setApiresponsealert(responseData);
            props.setShowalert(true);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            if (responseData === false) {
              var resultError = responseData;
              props.setApiresponsealert(resultError);
              props.setShowalert(true);
            }
          }
        });
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleButtonClick = (status) => {
    setSelectedStatus(status);
    setShowModal(true);
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function handleConfirm() {
    // if (!selectedStatus) {
    //   // Handle the case where no status is selected
    //   return;
    // }

    // const filteredItems = currentItemsadvertiser?.filter((item) =>
    //   item.Adverister_Status.some((itemdata) => itemdata?.name === selectedStatus)
    // );

    // const updateData = filteredItems.flatMap((item) =>
    //   item.Adverister_Status
    //     .filter(
    //       (itemdata) =>
    //         itemdata?.name === selectedStatus && itemdata?.adverister_status !== null
    //     )
    //     .map((itemdata) => ({
    //       advertiser_id: item.id,
    //       status_update: itemdata?.adverister_status,
    //     }))
    // );

    if (!selectedStatus) {
      // Handle the case where no status is selected
      return;
    }

    let updateData = null;

    for (const item of currentItemsadvertiser) {
      for (const itemdata of item.Adverister_Status) {
        if (
          itemdata?.name === selectedStatus &&
          itemdata?.adverister_status !== null
        ) {
          updateData = {
            advertiser_id: item.id,
            adverister_status: itemdata.adverister_status,
          };
          break; // Exit the loop once a match is found
        }
      }
      if (updateData) {
        break; // Exit the outer loop once a match is found
      }
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisers/status_update`,
        updateData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;
        setShowModal(false);

        // Reset the selected status
        setSelectedStatus(null);
        resultapi = response.data;
      } else if (response.data.status === false) {
        resultapifalse = response.data;
      }
    } catch (error) {}

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        // window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      props.setApiresponsealert(resultapifalse);
      props.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  }

  // const handleConfirm = () => {
  //   // Make a POST API request here to update the status
  //   // You can use a library like Axios or fetch for API requests

  //   // After the API request is successful, you can close the modal
  //   setShowModal(false);

  //   // Reset the selected status
  //   setSelectedStatus(null);
  // };

  const fetchDatanextpreadvertiser = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/advertisers_list?page=${pageadvertiser}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const advertisersData = res?.data?.Advertiser_pagination?.data;
        setVideodataadvertiser(advertisersData);
        if (advertisersData.length > 0) {
          setDataadvertiser(advertisersData);
          setLoading(false);
        } else {
          setDataadvertiser([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchDataadvertiser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/advertisers_list`,
        { headers: headers }
      );
      setCountadvertiser(response?.data?.Total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const indexOfLastItemadvertiser =
    currentPageadvertiser * itemsPerPageadvertiser;
  const indexOfFirstItemadvertiser =
    indexOfLastItemadvertiser - itemsPerPageadvertiser;
  const currentItemsadvertiser = dataadvertiser?.slice(
    indexOfFirstItemadvertiser,
    indexOfLastItemadvertiser
  );

  const handlePreClickadvertiser = () => {
    if (pageadvertiser > 1) {
      setPageadvertiser(pageadvertiser - 1);
    }
  };

  const handleNxtClickadvertiser = () => {
    setPageadvertiser(pageadvertiser + 1);
  };

  const [itemToDeleteadvertiser, setItemToDeleteadvertiser] = useState(null);
  const [successMessageadvertiser, setSuccessMessageadvertiser] = useState("");

  const openModaladvertiser = (id) => {
    setItemToDeleteadvertiser(id);
    setSuccessMessageadvertiser("");
  };

  const closeModaladvertiser = () => {
    setItemToDeleteadvertiser(null);
  };
  const handleDeletedataadvertiser = () => {
    closeModaladvertiser();
    if (itemToDeleteadvertiser) {
      advertisersdeleteOperation(itemToDeleteadvertiser);
    }
  };

  return (
    <>
      <>
        <div
          className={`modal ${itemToDeleteadvertiser !== null ? "show" : ""}`}
          tabIndex="-1"
          style={{
            display: itemToDeleteadvertiser !== null ? "block" : "none",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Deletion</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModaladvertiser}
                ></button>
              </div>
              <div className="modal-body">
                {successMessageadvertiser ? (
                  <div className="alert alert-success" role="alert">
                    {successMessageadvertiser}
                  </div>
                ) : (
                  <p>Are you sure you want to delete this advertiser?</p>
                )}
              </div>
              <div className="modal-footer">
                {successMessageadvertiser ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={closeModaladvertiser}
                  >
                    Close
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={closeModaladvertiser}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={handleDeletedataadvertiser}
                    >
                      Yes
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog delete_confirm">
            <div className="modal-content text-center">
              <div className="modal-body">
                {isConfirmationOpenadvertiser && (
                  <div>
                    <h5 className="modal-title p-3" id="exampleModalLabel">
                      <p className="fs-6">
                        Are you sure you want to delete the selected items?
                      </p>
                    </h5>
                    <div className="col-lg-12 row p-4">
                      <div className="col-lg-6">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            handleConfirmDeleteadvertiser();
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                      <div className="col-lg-6">
                        <button
                          className="btn btn-primary"
                          onClick={handleCancelDeleteadvertiser}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {isSuccessMessageOpenadvertiser && (
                  <div className="success-message">
                    <div className="p-5">
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <p className="fs-6 fw-bold">
                      Selected items have been successfully deleted.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <div
            className="modal fade show"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirmation</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowModal(false)}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <p>
                    Are you sure you want to set the status to "{selectedStatus}
                    "?
                  </p>
                </div>
                <div className="modal-footer">
                  <button onClick={handleConfirm} className="btn btn-primary">
                    Yes
                  </button>
                  <button
                    onClick={() => setShowModal(false)}
                    className="btn btn-secondary"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className=" m-0 p-0">
          <div className="container-fluid">
            <section className="section all-advertiser-stream ">
              <div className="col-lg-12 row py-3">
                <div className="col-6 col-sm-6 col-lg-6">
                  <h3>
                    All Advertiser{" "}
                    <span className="fs-6">
                      (Display all {countadvertiser} Advertiser)
                    </span>
                  </h3>
                </div>
                <div className="col-6 col-sm-6 col-lg-6 text-end">
                  {selectedItemsadvertiser.length > 0 && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={handleDeleteadvertiser}
                    >
                      Delete Selected
                    </button>
                  )}
                </div>
              </div>

              <div className="col-lg-12 ">
                <div className="card">
                  {loading ? (
                    <div className="p-2 text-start iq-card-body">
                      <Table>
                        <thead>
                          <tr className="title">
                            <th>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={selectAlladvertiser}
                                  onChange={handleSelectAlladvertiser}
                                />
                              </label>
                            </th>
                            <th>Company Name</th>
                            <th>Lisence Number</th>
                            <th>Address</th>
                            <th>Mobile Number</th>
                            <th>Email ID</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                      </Table>

                      <div className="text-center">
                        <label>
                          {" "}
                          <Response_Loading_processing></Response_Loading_processing>{" "}
                          Loading...
                        </label>
                      </div>

                      <div className="row align-items-center text-center d-flex">
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={handlePreClickadvertiser}
                            disabled={pageadvertiser <= 1}
                          >
                            {"<< Previous"}
                          </button>
                        </div>
                        <div className="col-lg-4">
                          <p className="">{pageadvertiser}</p>
                        </div>
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleNxtClickadvertiser}
                            disabled={
                              dataadvertiser?.length < itemsPerPageadvertiser
                            }
                          >
                            {"Next >>"}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : advertiserdataadvertiser.length > 0 ? (
                    <div className="p-2 text-start iq-card-body">
                      <Table>
                        <thead>
                          <tr className="title">
                            <th>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={selectAlladvertiser}
                                  onChange={handleSelectAlladvertiser}
                                />
                              </label>
                            </th>
                            <th>Company Name</th>
                            <th>Lisence Number</th>
                            <th>Address</th>
                            <th>Mobile Number</th>
                            <th>Email ID</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {currentItemsadvertiser?.map((item, key) => (
                          <tbody>
                            <tr key={item.id}>
                              <td key={item.id}>
                                <input
                                  type="checkbox"
                                  checked={selectedItemsadvertiser.includes(
                                    item.id
                                  )}
                                  onChange={() =>
                                    handleMultiSelectadvertiser(item.id)
                                  }
                                />
                              </td>
                              <td>
                                <div className="d-flex text-start align-items-center ">
                                  <div>
                                    <div className="ms-2">
                                      <div>{item?.company_name}</div>
                                      <div className="d-flex ">
                                        <div className="">
                                          <span>{item?.upload_on}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>{item.license_number} </td>
                              <td>{item.address} </td>
                              <td>{item.mobile_number} </td>
                              <td>{item.email_id}</td>
                              <td>
                                {/* {item?.Adverister_Status?.map((itemdata, key) => (

                                  <button className="p-2" style={{ background: itemdata?.bg_color, color: itemdata?.color, }}>
                                  {itemdata.name}
                                </button>
                                
                               
                                ))} */}

                                {item?.Adverister_Status?.map(
                                  (itemdata, key) => (
                                    <button
                                      key={key}
                                      className="p-2 rounded-2"
                                      onClick={() =>
                                        itemdata?.adverister_status !== null
                                          ? handleButtonClick(itemdata.name)
                                          : null
                                      }
                                      style={{
                                        background: itemdata?.bg_color,
                                        color: itemdata?.color,
                                        margin: "2px",
                                      }}
                                    >
                                      {itemdata.name}
                                    </button>
                                  )
                                )}

                                {/* {item?.Adverister_Status?.map(
                                  (itemdata, key) => (
                                    <button
                                      key={key}
                                      className="p-2 "
                                      onClick={
                                        itemdata?.adverister_status !== null
                                          ? advertiserstatus_update
                                          : null
                                      }
                                      style={{
                                        background: itemdata?.bg_color,
                                        color: itemdata?.color,
                                        margin: "2px"
                                       
                                      }}
                                    >
                                      {itemdata?.adverister_status !== null
                                        ? itemdata.name
                                        : itemdata.name}
                                    </button>
                                  )
                                )} */}
                              </td>

                              <td>
                                <div className="editdropdown">
                                  <span className="editdropdown-button">
                                    <span>
                                      <i
                                        className="fa fa-ellipsis-v"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </span>
                                  <div className="editdropdown-menu">
                                    <Link
                                      to={"/editadvertiser/" + item?.id}
                                      className="edit"
                                    >
                                      <span>
                                        <img
                                          src={edititem}
                                          alt="flogo"
                                          width={20}
                                          height={20}
                                        />
                                        <span className="ms-2">
                                          Edit Advertiser
                                        </span>
                                      </span>
                                    </Link>
                                    <Link to="" className="delete">
                                      <span
                                        onClick={() =>
                                          openModaladvertiser(item?.id)
                                        }
                                      >
                                        <img
                                          src={deleteitem}
                                          alt="flogo"
                                          width={20}
                                          height={20}
                                        />
                                        <span className="ms-2">
                                          Delete Advertiser
                                        </span>
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>

                      <div className="row align-items-center text-center d-flex">
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={handlePreClickadvertiser}
                            disabled={pageadvertiser <= 1}
                          >
                            {"<< Previous"}
                          </button>
                        </div>
                        <div className="col-lg-4">
                          <p className="">{pageadvertiser}</p>
                        </div>
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleNxtClickadvertiser}
                            disabled={
                              dataadvertiser.length < itemsPerPageadvertiser
                            }
                          >
                            {"Next >>"}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="p-2 text-start iq-card-body">
                      <Table>
                        <thead>
                          <tr className="title">
                            <th>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={selectAlladvertiser}
                                  onChange={handleSelectAlladvertiser}
                                />
                              </label>
                            </th>
                            <th>Company Name</th>
                            <th>Lisence Number</th>
                            <th>Address</th>
                            <th>Mobile Number</th>
                            <th>Email ID</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                      </Table>

                      <div className="text-center">
                        <label>No Advertiser Available.</label>
                      </div>

                      <div className="row align-items-center text-center d-flex">
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={handlePreClickadvertiser}
                            disabled={pageadvertiser <= 1}
                          >
                            {"<< Previous"}
                          </button>
                        </div>
                        <div className="col-lg-4">
                          <p className="">{pageadvertiser}</p>
                        </div>
                        <div className="col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleNxtClickadvertiser}
                            disabled={
                              dataadvertiser.length < itemsPerPageadvertiser
                            }
                          >
                            {"Next >>"}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </>

      {/* <div className="m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="iq-card">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Advertisers Lists</h4>
                        </div>
                      </div>
                      <div className="iq-card-body table-responsive">
                        <div className="table-view">
                          <table className="table table-striped table-bordered table movie_table ">
                            <thead>
                              <tr className="r1">
                                <th>#</th>
                                <th>Company Name</th>
                                <th>Lisence Number</th>
                                <th>Address</th>
                                <th>Mobile Number</th>
                                <th>Email ID</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {advertiser?.map((item, key) => (
                                <tr className="r1" key={item?.id}>
                                  <td key={key}> {key + 1} </td>
                                  <td>{item.company_name} </td>
                                  <td>{item.license_number} </td>
                                  <td>{item.address} </td>
                                  <td>{item.mobile_number} </td>
                                  <td>{item.email_id}</td>
                                  <td>
                                    <button className="font-weight-bold btn btn-success">
                                      Approved
                                    </button>

                                    <button className="font-weight-bold btn btn-danger mt-2 ">
                                      Disapproved
                                    </button>
                                  </td>

                                  <td>
                                    <Link
                                      to={"/editadvertiser/" + item.id}
                                      className="edit ms-1"
                                    >
                                      <i
                                        className="fa fa-pencil-square"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                    <Link to="" className="delete ms-3">
                                      <span
                                        onClick={() => deleteOperation(item.id)}
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default Advertiser;
