// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.fa.fa-file-excel-o.video_pdf{
    font-size: 48px;
    color: #1fa113 !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Account/User/ImportUser.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":["a.fa.fa-file-excel-o.video_pdf{\n    font-size: 48px;\n    color: #1fa113 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
