// import React from "react";

// function RevenueRegion() {
//   const datas = [
//     {
//       user: "Flicknexs",
//       plan: "Subscribed User",
//     },
//   ];

//   return (
//     <>
//       <div className=" m-0 p-0">
//         <div className="">
//           <section className="section container-fluid pt-3">
//             <div id="content-page" className="content-page">
//               <div className="iq-card">
//                 <div className="iq-card-header  justify-content-between">
//                   <div className="iq-header-title p-0">
//                     <h4 className="card-title">Revenue By Region</h4>
//                   </div>
//                 </div>

//                 <div className="row p-3">
//                   <hr />
//                   <div className="col-md-12">
//                     <div className="clear"></div>

//                     <div className="row align-items-center mb-5 mt-3">
//                       <div className="col-md-4">
//                         <h5>Country :</h5>
//                         <select className="form-control" id="role" name="role">
//                           <option value="">Choose Country</option>
//                           <option value="registered">Registered Users </option>
//                           <option value="subscriber">Subscriber</option>
//                           <option value="admin">Admin</option>
//                           <option value="ppv_users">PPV Users</option>
//                         </select>
//                       </div>

//                       <div className="col-md-4">
//                         <h5>State</h5>
//                         <select className="form-control" id="role" name="role">
//                           <option value="">Choose State</option>
//                           <option value="registered">Registered Users </option>
//                           <option value="subscriber">Subscriber</option>
//                           <option value="admin">Admin</option>
//                           <option value="ppv_users">PPV Users</option>
//                         </select>
//                       </div>

//                       <div className="col-md-4">
//                         <h5>City</h5>
//                         <select className="form-control" id="role" name="role">
//                           <option value="">Choose City</option>
//                           <option value="registered">Registered Users </option>
//                           <option value="subscriber">Subscriber</option>
//                           <option value="admin">Admin</option>
//                           <option value="ppv_users">PPV Users</option>
//                         </select>
//                       </div>
//                     </div>

//                     <div className="row">
//                       <div className="col-md-12">
//                         <table className="table" id="user_tabledss">
//                           <thead>
//                             <tr className="r1">
//                               <th className="text-center">ID</th>
//                               <th className="text-center">USER NAME</th>
//                               <th className="text-center">PLAN NAME</th>
//                             </tr>
//                           </thead>
//                           {datas.map((item, key) => (
//                             <thead>
//                               <tr key={item?.id}  className="r1">
//                                 <td key={key}> {key + 1} </td>

//                                 <td>{item.user}</td>
//                                 <td>{item.plan}</td>
//                               </tr>
//                             </thead>
//                           ))}
//                           <tbody></tbody>
//                         </table>
//                       </div>
//                       <div className="col-md-4"></div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div></div>
//             </div>
//           </section>
//         </div>
//       </div>
//     </>
//   );
// }

// export default RevenueRegion;



// import React from "react";

// function RevenueRegion() {
//   const datas = [
//     {
//       name: "Flicknexs",
//       country: "Subscribed User",
//       ip: "20",
//       views: "30",
//     },
//   ];

//   return (
//     <>
//       <div className=" m-0 p-0">
//         <div className="">
//           <section className="section container-fluid pt-3">
//             <div id="content-page" className="content-page">
//               <div className="iq-card">
//                 <div className="iq-card-header  justify-content-between">
//                   <div className="iq-header-title p-0">
//                     <h4 className="card-title">View By Region</h4>
//                   </div>
//                 </div>

//                 <div className="row p-3">
//                   <hr />
//                   <div className="col-md-12">
//                     <div className="clear"></div>

//                     <div className="row align-items-center mb-5 ">
//                       <h5>Region :</h5>
//                       <div className="col-md-6">
//                         <select className="form-control" id="role" name="role">
//                           <option value="">Choose Country</option>
//                           <option value="registered">Registered Users </option>
//                           <option value="subscriber">Subscriber</option>
//                           <option value="admin">Admin</option>
//                           <option value="ppv_users">PPV Users</option>
//                         </select>
//                       </div>
//                       <div className="col-md-6"></div>
//                     </div>

//                     <div className="row">
//                       <div className="col-md-12">
//                         <table className="table" id="user_tabledss">
//                           <thead>
//                             <tr className="r1">
//                               <th>ID</th>
//                               <th>VIDEO NAME</th>
//                               <th>COUNTRY NAME</th>
//                               <th>IP NUMBER</th>
//                               <th>VIEWS </th>
//                             </tr>
//                           </thead>
//                           {datas.map((item, key) => (
//                             <thead>
//                               <tr  key={item?.id} className="r1">
//                                 <td key={key}> {key + 1} </td>

//                                 <td>{item.name}</td>
//                                 <td>{item.country}</td>
//                                 <td>{item.ip}</td>
//                                 <td>{item.views} </td>
//                               </tr>
//                             </thead>
//                           ))}
//                           <tbody></tbody>
//                         </table>
//                       </div>
//                       <div className="col-md-4"></div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div></div>
//             </div>
//           </section>
//         </div>
//       </div>
//     </>
//   );
// }

// export default  ;



import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import './RevenueRegion.css'
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

const RevenueRegion = () => {
  const [datas, setDatas] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");


  const [filterCountry, setFilterCountry] = useState([
    {
      'name': 'India',
      'value': 'india'
    },
    {
      'name': 'UAE',
      'value': 'dubai'
    },
    {
      'name': 'America',
      'value': 'america'
    },
  ]);


  const [filterState, setFilterState] = useState([

    {
      'name': 'TamilNadu',
      'value': 'TamilNadu'
    },
    {
      'name': 'Pondy',
      'value': 'Pondy'
    },
  ]);
  const [filterCity, setFilterCity] = useState([

    {
      'name': 'Chennai',
      'value': 'Chennai'
    },
    {
      'name': 'Trichy',
      'value': 'Trichy'
    },
    {
      'name': 'Madurai',
      'value': 'Madurai'
    },
  ]);

  const newOptionsData = {
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: Number(filteredData?.length) },
    ],
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://api.example.com/users?start_date=${startDate}&end_date=${endDate}`,
        { method: "GET" }
      );

      const data = await response.json();
      setDatas(data);
      setUserCount(data.length);

      let filtered = data;
      if (selectedCountry)
        filtered = filtered.filter(item => item.country === selectedCountry);
      if (selectedState)
        filtered = filtered.filter(item => item.state === selectedState);
      if (selectedCity)
        filtered = filtered.filter(item => item.city === selectedCity);

      setFilteredData(filtered);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, selectedCountry, selectedState, selectedCity]);

  const handleExport = async () => {
    try {
      const exportResponse = await fetch("https://api.example.com/export", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ startDate, endDate, selectedData: filteredData })
      });

      if (exportResponse.ok) {
        const blob = await exportResponse.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "exported_data.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Export failed:", exportResponse.statusText);
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleCountryChange = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    // Fetch states based on the selected country, and setFilterState with the response
    // Example: fetchStates(country).then(states => setFilterState(states))
  };

  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    // Fetch cities based on the selected state, and setFilterCity with the response
    // Example: fetchCities(state).then(cities => setFilterCity(cities))
  };

  const columns = [
    { dataField: "id", text: "ID" },
    { dataField: "video_name", text: "VIDEO NAME" },
    { dataField: "COUNTRY_name", text: "COUNTRY NAME" },
    { dataField: "ip_number", text: "IP NUMBER" },
    { dataField: "views", text: "VIEWS" }
  ];

  return (
    <div className="m-0 p-0">
      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="iq-card-header justify-content-between">
            <div className="iq-header-title p-0">
              <h4 className="card-title">Revenue By Region</h4>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="row p-3">
              <div className="col-md-12">
                <div className="row mt-2 align-items-center">
                  <div className="col-md-4">
                    <h5>Country</h5>
                    <select className="form-select" id="country" name="country"
                      onChange={handleCountryChange} value={selectedCountry}>
                      <option value="">Choose a Country</option>
                      {filterCountry.map((item, key) => (
                        <option key={key} value={item.value}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <h5>State</h5>
                    <select className="form-select" id="state" name="state"
                      onChange={handleStateChange} value={selectedState}>
                      <option value="">Choose a State</option>
                      {filterState.map((item, key) => (
                        <option key={key} value={item.value}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <h5>City</h5>
                    <select className="form-select" id="city" name="city"
                      onChange={(e) => setSelectedCity(e.target.value)}
                      value={selectedCity}>
                      <option value="">Choose a City</option>
                      {filterCity.map((item, key) => (
                        <option key={key} value={item.value}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mt-3">
                    <div className="d-flex mt-3 justify-content-between align-items-center flex-wrap mb-3 gap-3">
                      <div className="row col-lg-12 align-items-center">
                        <div className="col-lg-6 align-items-center">
                          <input type="text" placeholder="Filter all.."
                            className="filter form-control "
                            value={filter} onChange={(e) => setFilter(e.target.value)} />

                          {filter.length > 0 && (
                            <button type="button" className="closeBtn"
                              onClick={() => setFilter("")}>
                              <i className="fa fa-times"></i>
                            </button>
                          )}
                        </div>
                      </div>
                      <PaginationProvider pagination={paginationFactory(newOptionsData)}>
                        {({ paginationTableProps }) => (
                          <div className="bootstrapTable p-2">
                            <BootstrapTable keyField="id" columns={columns}
                              data={filteredData} className="no-border-table"
                              noDataIndication={
                                <div className="noRecord">
                                  {loading ? (
                                    <label>
                                      <Response_Loading_processing></Response_Loading_processing>
                                      Loading...
                                    </label>
                                  ) : (
                                    filteredData.length <= 0 && <p>No Record Found!</p>
                                  )}
                                </div>
                              }
                              hover headerClasses="videoWidth"
                              selectAll={selectAll} {...paginationTableProps} />
                          </div>
                        )}
                      </PaginationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RevenueRegion;