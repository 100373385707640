import React, { useState, useEffect } from "react";
import axios from "axios";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import "./Iyzico.css";
import { BiLinkExternal } from "react-icons/bi";

function Iyzico(props) {
  const [loading, setLoading] = useState(true);
  const [Iyzico, setIyzico] = useState({
    id: "",
    user_id: "",
    payment_type: "",
    payment_mode: "",
    test_iyzico_label: "",
    test_apiKey_key: "",
    test_secretKey_key: "",
    live_iyzico_label: "",
    live_apiKey_key: "",
    live_secretKey_key: "",
    status: "",
    sandbox_iyzico_test_url: "",
    iyzico_live_url: "",
  });
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Iyzico_payment/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.Publish_type)
        var result = response?.data?.Iyzico_payment;
        setIyzico(result);
        setLoading(false);
        //   console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setIyzico({ ...Iyzico, [e.target.name]: 1 });
    } else {
      setIyzico({ ...Iyzico, [e.target.name]: 0 });
    }
  };

  const handleInput = (e) => {
    setIyzico({ ...Iyzico, [e.target.name]: e.target.value });
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const Iyzicopayment = async (e) => {
    e.preventDefault();

    const payment_mode = Number.isNaN(parseInt(Iyzico?.payment_mode, 10)) ? 0 : parseInt(Iyzico?.payment_mode, 10);
    const status = Number.isNaN(parseInt(Iyzico?.status, 10)) ? 0 : parseInt(Iyzico?.status, 10);

    const editInputvalue = {
      id: Iyzico?.id,
      user_id: Iyzico?.user_id,
      payment_type: Iyzico?.payment_type,
      payment_mode: payment_mode,
      test_iyzico_label: Iyzico?.test_iyzico_label,
      test_apiKey_key: Iyzico?.test_apiKey_key,
      test_secretKey_key: Iyzico?.test_secretKey_key,
      live_iyzico_label: Iyzico?.live_iyzico_label,
      live_apiKey_key: Iyzico?.live_apiKey_key,
      status: status,
      live_secretKey_key: Iyzico?.live_secretKey_key,
      sandbox_iyzico_test_url: Iyzico?.sandbox_iyzico_test_url,
      iyzico_live_url: Iyzico?.iyzico_live_url,
      payment_type: "Iyzico",
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Iyzico_payment/store`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res?.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson?.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  const Iyzico_redirect = () => {
    window.open("https://Iyzico.com/docs", "_blank");
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row mt-3">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3" >
                <h3>Iyzico Payment</h3>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row  ">
              <div className="col-md-6 col-12 ">
                <div className="card p-3 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5>Iyzico Test Mode</h5>
                    <div className="card mb-0">
                      <a
                        href="https://Iyzico.com/docs"
                        target="_blank"
                        title="Iyzico Payment Plan"
                        className=" px-3 py-2"
                      >
                        https://Iyzico.com/docs
                        <BiLinkExternal className="text-dark ms-2" />
                      </a>
                    </div>
                  </div>
                  <hr></hr>
                  <div className=" text-start">
                    <div className="row mt-2">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <label className="m-0">Enable Payment</label>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6 text-end">
                        <div className="col-lg-12 row">
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            <p> TEST </p>
                          </div>
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            {" "}
                            <label className="switch">
                              <input
                                name="payment_mode"
                                id="payment_mode"
                                onChange={handleInputenable}
                                className="rs-input"
                                defaultChecked={
                                  Iyzico?.payment_mode == 1 ? true : false
                                }
                                checked={
                                  Iyzico?.payment_mode == 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="payment_mode"
                                id="payment_mode"
                                onChange={handleInputenable}
                                value={Iyzico?.payment_mode == 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            <p> LIVE </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-4">
                    <label className="m-0">Test Sandbox Iyzico Name</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="test_iyzico_label"
                        name="test_iyzico_label"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={Iyzico?.test_iyzico_label}
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Test Sandbox API Key</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="test_apiKey_key"
                        name="test_apiKey_key"
                        value={Iyzico?.test_apiKey_key}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Test Sandbox Secret Key</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="test_secretKey_key"
                        name="test_secretKey_key"
                        value={Iyzico?.test_secretKey_key}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Test Sandbox Iyzico URL </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="sandbox_iyzico_test_url"
                        name="sandbox_iyzico_test_url"
                        value={Iyzico?.sandbox_iyzico_test_url}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                </div>
                {/* <div className="text-start mb-3">
                  <button onClick={Iyzicopayment} className="btn btn-primary">
                    Update Iyzico
                  </button>
                </div> */}
              </div>
              {/* Second Part of Section  */}
              <div className="col-md-6 col-12">
                <div className="card p-3">
                  <h5>Iyzico Live Mode</h5>

                  <hr></hr>

                  <div className="row mt-2">
                    <div className="col-6 col-sm-6 col-lg-6">
                      <label className="m-0">Enable Iyzico </label>
                    </div>
                    <div className="col-6 col-sm-6 col-lg-6 text-end">
                      <div className="col-lg-12 row">
                        <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                          <p> OFF </p>
                        </div>
                        <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                          {" "}
                          <label className="switch">
                            <input
                              name="status"
                              id="status"
                              onChange={handleInputenable}
                              className="rs-input"
                              defaultChecked={
                                Iyzico?.status == 1 ? true : false
                              }
                              checked={Iyzico?.status == 1 ? true : false}
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="status"
                              id="status"
                              onChange={handleInputenable}
                              value={Iyzico?.status == 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                        <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                          <p> ON </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-4">
                    <label className="m-0">Live Iyzico Name</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="live_iyzico_label"
                        name="live_iyzico_label"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={Iyzico?.live_iyzico_label}
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Live API Key </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="live_apiKey_key"
                        name="live_apiKey_key"
                        value={Iyzico?.live_apiKey_key}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Live Secret Key</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="live_secretKey_key"
                        name="live_secretKey_key"
                        value={Iyzico?.live_secretKey_key}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Live Iyzico URL </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="iyzico_live_url"
                        name="iyzico_live_url"
                        value={Iyzico?.iyzico_live_url}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-end mb-3">
                <button onClick={Iyzicopayment} className="btn btn-primary">
                  Update Iyzico
                </button>
              </div>
            </div>

          </section>
        </div>
      </div>

    </>
  );
}

export default Iyzico;
