// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.currentActive {
  background: #d3ffd3;
  color: green;
  padding: 5px !important;
  border-radius: 5px;
  font-weight: 600;
}
.inActive {
  background: #ffd9d9;
  color: red;
  padding: 5px !important;
  border-radius: 5px;
  font-weight: 600;
}
.dropdown-toggle::after {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Channel_Partner/ChannelPackage.css"],"names":[],"mappings":";AACA;EACE,mBAAmB;EACnB,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,UAAU;EACV,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,wBAAwB;AAC1B","sourcesContent":["\n.currentActive {\n  background: #d3ffd3;\n  color: green;\n  padding: 5px !important;\n  border-radius: 5px;\n  font-weight: 600;\n}\n.inActive {\n  background: #ffd9d9;\n  color: red;\n  padding: 5px !important;\n  border-radius: 5px;\n  font-weight: 600;\n}\n.dropdown-toggle::after {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
