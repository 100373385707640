import React from "react";
import { Link } from "react-router-dom";

function ContentSeries() {
  return (
    <div className="m-0 p-0">
      <div className="">
        <section className="section container-fluid">ContentSeries</section>
      </div>
    </div>
  );
}

export default ContentSeries;