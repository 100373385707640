// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eyepasswordemail {
  position: absolute;
  padding: 33px;
  right: 0%;
  font-size: 18px !important;
  top: 75%;
}

.lockIcon,
.eyeOpen,
.eyeClosed {
  font-size: 23px;
}

.lockIcon {
  top: 15px;
  left: 10px;
}

.passWordIcon {
  top: 15px;
  right: 10px;
}

.serialNumber {
  width: 40px !important;
}

.redColorEmail {
  font-size: 13px;
  line-height: 23px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Settings/EmailSettings.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,SAAS;EACT,0BAA0B;EAC1B,QAAQ;AACV;;AAEA;;;EAGE,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".eyepasswordemail {\n  position: absolute;\n  padding: 33px;\n  right: 0%;\n  font-size: 18px !important;\n  top: 75%;\n}\n\n.lockIcon,\n.eyeOpen,\n.eyeClosed {\n  font-size: 23px;\n}\n\n.lockIcon {\n  top: 15px;\n  left: 10px;\n}\n\n.passWordIcon {\n  top: 15px;\n  right: 10px;\n}\n\n.serialNumber {\n  width: 40px !important;\n}\n\n.redColorEmail {\n  font-size: 13px;\n  line-height: 23px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
