// import React from "react";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import { Line } from "react-chartjs-2";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// function PlayerVideoAnalytics() {
//   const datas = [
//     {
//       user: "Admin",
//       mail: "admin@admin.com",
//       video: "Zootopia",
//       amount: "$",
//       date: "02 Jul 2021",
//     },
//     {
//       user: "Admin",
//       mail: "admin@admin.com",
//       video: "Zootopia",
//       amount: "$",
//       date: "02 Jul 2021",
//     },
//   ];

//   const options = {
//     type: "line",
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "top",
//       },
//     },
//   };

//   const labels = [
//     "June",
//     "June",
//     "June",
//     "June",
//     "August",
//     "August",
//     "August",
//     "August",
//     "August",
//     "September",
//     "September",
//     "October",
//     "November",
//     "December",
//     "February",
//   ];

//   const data = {
//     labels,
//     datasets: [
//       {
//         label: "Player Videos",

//         data: [5, 0, 40, 0, 10, 20, 0, 30, 0, 80, 0, 60, 0, 10, 0 - 20, 100],
//         borderColor: "rgb(53, 162, 235)",
//         lineTension: 0.4,
//       },
//     ],
//   };

//   return (
//     <>
//       <div className=" m-0 p-0">
//         <div className="">
//           <section className="section container-fluid pt-3">
//             <div id="content-page" className="content-page">
//               <div className="iq-card">
//                 <div className="iq-card-header  justify-content-between">
//                   <div className="iq-header-title p-0">
//                     <h4 className="card-title">Player Video Analytics :</h4>
//                   </div>
//                 </div>

//                 <div className="row p-3">
//                   <hr />
//                   <div className="col-md-12">
//                     <div className="row mt-3">
//                       <div className="col-md-3">
//                         <label for="start_time"> Start Date: </label>
//                         <input
//                           type="date"
//                           className="form-control"
//                           id="start_time"
//                           name="start_time"
//                         />
//                       </div>
//                       <div className="col-md-3">
//                         <label for="start_time"> End Date: </label>
//                         <input
//                           type="date"
//                           className="form-control"
//                           id="end_time"
//                           name="end_time"
//                         />
//                       </div>

//                       <div className="col-md-3 d-flex align-items-end">
//                         <div className="dropdown">
//                           <button
//                             className="btn btn-outline-dark filter-btn dropdown-toggle"
//                             type="button"
//                             data-bs-toggle="dropdown"
//                             aria-expanded="false"
//                           >
//                             Filter
//                           </button>
//                           <ul className="dropdown-menu">
//                             <li>
//                               <a className="dropdown-item" href="#">
//                                 1 Day
//                               </a>
//                             </li>
//                             <li>
//                               <a className="dropdown-item" href="#">
//                                 1 Week
//                               </a>
//                             </li>
//                             <li>
//                               <a className="dropdown-item" href="#">
//                                 1 Month{" "}
//                               </a>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>

//                       <div className="col-md-3 d-flex align-items-end">
//                         <span
//                           id="export"
//                           className="btn btn-primary download-btn btn-sm"
//                         >
//                           Download CSV
//                         </span>
//                       </div>
//                     </div>
//                     <div className="clear"></div>

//                     <div className="mt-5">
//                       <h4>Player Video Graph :</h4>
//                     </div>
//                     <div>
//                       <div className="row mt-5">
//                         <div className="col-md-2"></div>
//                         <div className="col-md-7">
//                           <Line options={options} data={data} />
//                           <p className="text-center">Player Videos</p>
//                         </div>
//                         <div className="col-md-3"></div>
//                       </div>
//                     </div>

//                     <div className="d-flex justify-content-between p-3">
//                       <div className="d-flex">
//                         <p>Show 10</p>
//                         <p>&nbsp; &nbsp; entries</p>
//                       </div>
//                       <div className="d-flex">
//                         <p>Search:</p>
//                         <input
//                           type="search"
//                           id="gsearch"
//                           name="gsearch"
//                           className="border-0 search-rev"
//                         />
//                       </div>
//                     </div>

//                     <div className="row">
//                       <div className="col-md-12">
//                         <table className="table" id="user_tabledss">
//                           <thead>
//                             <tr className="r1">
//                               <th>#</th>
//                               <th>Video Name</th>
//                               <th>Viewed Count</th>
//                               <th>Watch Hours (H:M:S)</th>
//                               <th>Seek Time (Seconds)</th>
//                               <th>Buffer Time (Seconds)</th>
//                             </tr>
//                           </thead>
//                           {datas.map((item, key) => (
//                             <thead>
//                               <tr key={item?.id}  className="r1">
//                                 <td key={key}> {key + 1} </td>
//                                 <td>{item.user}</td>
//                                 <td>{item.mail}</td>
//                                 <td>{item.video}</td>
//                                 <td>{item.amount} </td>
//                                 <td>{item.date}</td>
//                               </tr>
//                             </thead>
//                           ))}
//                           <tbody></tbody>
//                         </table>
//                       </div>
//                       <div className="col-md-4"></div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div></div>
//             </div>
//           </section>
//         </div>
//       </div>
//     </>
//   );
// }

// export default PlayerVideoAnalytics;




// import React from "react";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import { Line } from "react-chartjs-2";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// function PlayerVideoAnalytics() {
//   const datas = [
//     {
//       name: "Flicknexs",
//       uploader: "Subscribed User",
//       commission: "20",
//       admin: "30",
//       moderator: "50",
//       views: "10",
//       date: "12-02-20023",
//     },
//   ];

//   const options = {
//     type: "line",
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "top",
//       },
//     },
//   };

//   const labels = ["March", "February", "January"];

//   const data = {
//     labels,
//     datasets: [
//       {
//         label: "Content Partners Video Analytics",

//         data: [4, 2, 7, 0],
//         borderColor: "rgb(53, 162, 235)",
//         lineTension: 0.4,
//       },
//     ],
//   };

//   return (
//     <>
//       <div className=" m-0 p-0">
//         <div className="">
//           <section className="section container-fluid pt-3">
//             <div id="content-page" className="content-page">
//               <div className="iq-card">
//                 <div className="iq-card-header  justify-content-between">
//                   <div className="iq-header-title p-0">
//                     <h4 className="card-title">
//                       Content Partners Video Analytics :
//                     </h4>
//                   </div>
//                 </div>

//                 <div className="row p-3">
//                   <hr />
//                   <div className="col-md-12">
//                     <div className="row mt-3">
//                       <div className="col-md-3">
//                         <label for="start_time"> Start Date: </label>
//                         <input
//                           type="date"
//                           className="form-control"
//                           id="start_time"
//                           name="start_time"
//                         />
//                       </div>
//                       <div className="col-md-3">
//                         <label for="start_time"> End Date: </label>
//                         <input
//                           type="date"
//                           className="form-control"
//                           id="end_time"
//                           name="end_time"
//                         />
//                       </div>
//                       <div className="col-md-3 d-flex align-items-end">
//                         <div className="dropdown">
//                           <button
//                             className="btn btn-outline-dark filter-btn dropdown-toggle"
//                             type="button"
//                             data-bs-toggle="dropdown"
//                             aria-expanded="false"
//                           >
//                             Filter
//                           </button>
//                           <ul className="dropdown-menu">
//                             <li>
//                               <a className="dropdown-item" href="#">
//                                 1 Day
//                               </a>
//                             </li>
//                             <li>
//                               <a className="dropdown-item" href="#">
//                                 1 Week
//                               </a>
//                             </li>
//                             <li>
//                               <a className="dropdown-item" href="#">
//                                 1 Month{" "}
//                               </a>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                       <div className="col-md-3 d-flex align-items-end">
//                         <span
//                           id="export"
//                           className="btn btn-primary download-btn btn-sm"
//                         >
//                           Download CSV
//                         </span>
//                       </div>
//                     </div>
//                     <div className="clear"></div>

//                     <div className="mt-5">
//                       <h4>CPP Video View Through Graph :</h4>
//                     </div>
//                     <div>
//                       <div className="row mt-5">
//                         <div className="col-md-2"></div>
//                         <div className="col-md-7">
//                           <Line options={options} data={data} />
//                           <p className="text-center">
//                             Content Partners Video Analytics{" "}
//                           </p>
//                         </div>
//                         <div className="col-md-3"></div>
//                       </div>
//                     </div>

//                     <div className="row align-items-center mb-5 ">
//                       <h5>Filter Moderator</h5>
//                       <div className="col-md-6">
//                         <select className="form-control" id="role" name="role">
//                           <option value="">Choose Moderator</option>
//                           <option value="registered">Registered Users </option>
//                           <option value="subscriber">Subscriber</option>
//                           <option value="admin">Admin</option>
//                           <option value="ppv_users">PPV Users</option>
//                         </select>
//                       </div>
//                       <div className="col-md-6"></div>
//                     </div>

//                     <div className="row">
//                       <div className="col-md-12">
//                         <table className="table" id="user_tabledss">
//                           <thead>
//                             <tr className="r1">
//                               <th>#</th>
//                               <th>Video Name</th>
//                               <th>Uploader Name</th>
//                               <th>Total Commission</th>
//                               <th>Admin Commission </th>
//                               <th>Moderator Commission</th>
//                               <th>Total Views</th>
//                               <th>Purchased Date</th>
//                             </tr>
//                           </thead>
//                           {datas.map((item, key) => (
//                             <thead>
//                               <tr  key={item?.id}  className="r1">
//                                 <td key={key}> {key + 1} </td>

//                                 <td>{item.name}</td>
//                                 <td>{item.uploader}</td>
//                                 <td>{item.commission}</td>
//                                 <td>{item.admin} </td>
//                                 <td>{item.moderator}</td>
//                                 <td>{item.views}</td>
//                                 <td>{item.date}</td>
//                               </tr>
//                             </thead>
//                           ))}
//                           <tbody></tbody>
//                         </table>
//                       </div>
//                       <div className="col-md-4"></div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div></div>
//             </div>
//           </section>
//         </div>
//       </div>
//     </>
//   );
// }

// export default PlayerVideoAnalytics;



import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import './PlayerVideoAnalytics.css'
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

const PlayerVideoAnalytics = () => {

  const [datas, setDatas] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedModerator, setSelectedModerator] = useState("");

  const [filtermoderator, setFiltermoderator] = useState([

    {
      'name': 'Registered Users',
      'value': 'registered'
    },
    {
      'name': 'Subscriber',
      'value': 'subscriber'
    },
    {
      'name': 'Admin',
      'value': 'admin'
    },
    {
      'name': 'PPV Users',
      'value': 'ppv_users'
    },
  ]);
  const fetchData = async () => {
    try {
      setLoading(true);

      // Replace the following URL with your actual GET API endpoint
      const response = await fetch(
        `https://api.example.com/users?start_date=${startDate}&end_date=${endDate}`,
        {
          method: "GET",
          headers: {
            // Add headers if necessary
          },
        }
      );

      const data = await response.json();
      setDatas(data);
      setUserCount(data.length); // Update this based on your data structure

      // Filter data based on selected moderator
      const filtered = selectedModerator ? data.filter(item => item?.role === selectedModerator) : data;
      setFilteredData(filtered);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    fetchData();
  }, [startDate, endDate, selectedModerator]);

  const handleExport = async () => {
    try {
      // Replace the following URL with your actual POST API endpoint for exporting data
      const exportResponse = await fetch("https://api.example.com/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          startDate,
          endDate,
          selectedData: filteredData,
        }),
      });

      if (exportResponse.ok) {
        const blob = await exportResponse.blob();

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "exported_data.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Export failed:", exportResponse.statusText);
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const newLabel = ["May", "June", "July", "August", "September", "October"]; // Example labels
  const newData = [15, 25, 35, 30, 45]; // Example count values

  const newRandomColors = newData.map(() => {
    return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
      Math.random() * 256
    )}, ${Math.floor(Math.random() * 256)}, 0.7)`;
  });


  const columns = [
    {
      dataField: "user",
      text: "S.NO",
    },
    {
      dataField: "video_name",
      text: "VIDEO NAME",
    },
    {
      dataField: "uploader_name",
      text: "UPLOADER NAME",
    },
    {
      dataField: "total_commission",
      text: "TOTAL COMMISSION",
    },
    {
      dataField: "admin_commission",
      text: "ADMIN COMMISSION",
    },
    {
      dataField: "moderator_commission",
      text: "MODERATOR COMMISSION",
    },
    {
      dataField: "total_views",
      text: "TOTAL VIEWS",
    },
    {
      dataField: "purchase_date",
      text: "PURCHASE DATE",
    },
    // Add more columns as needed
  ];


  const optionstest = {
    type: "line",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const labels = [
    "June",
    "June",
    "June",
    "June",
    "August",
    "August",
    "August",
    "August",
    "August",
    "September",
    "September",
    "October",
    "November",
    "December",
    "February",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Player Videos",

        data: [5, 0, 40, 0, 10, 20, 0, 30, 0, 80, 0, 60, 0, 10, 0 - 20, 100],
        borderColor: "rgb(53, 162, 235)",
        lineTension: 0.4,
      },
    ],
  };

  const options = {
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: Number(filteredData?.length) },
    ],
  };

  return (
    <div className="m-0 p-0">
      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="iq-card-header justify-content-between">
            <div className="iq-header-title p-0">
              <h4 className="card-title">Player Video Analytics</h4>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="row p-3">
              <div className="col-md-12">
                <div className="row mt-2 align-items-center">

                  <div className="col-md-12">
                    <Line options={optionstest} data={data} />
                    <p className="text-center">Player Video Count</p>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mt-3">
                    <div className="row align-items-center">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                        <label htmlFor="start_time"> Start Date: </label>
                        <input
                          type="date"
                          className="rs-input form-control-lg"
                          id="start_time"
                          name="start_time"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                        <label htmlFor="end_time"> End Date: </label>
                        <input
                          type="date"
                          className="rs-input form-control-lg"
                          id="end_time"
                          name="end_time"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-right">
                        <span
                          id="export"
                          className="btn btn-success w-50"
                          onClick={handleExport}
                        >
                          Export
                        </span>
                      </div>
                    </div>

                    <div className="d-flex mt-3 justify-content-between align-items-center flex-wrap mb-3 gap-3">
                      <div className="row col-lg-12 align-items-center">
                        <div className="col-lg-6 align-items-center">

                          <input
                            type="text"
                            placeholder="Filter all.."
                            className="filter form-control "
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                          />

                          {filter?.length > 0 && (
                            <button
                              type="button"
                              className="closeBtn"
                              onClick={() => setFilter("")}
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          )}

                        </div>
                        <div className="col-lg-6  ">

                          <div className="row align-items-center">
                            <h5>Filter Moderator</h5>
                            <div className="col-md-12">
                              <select className="form-select" id="role" name="role"
                                onChange={(e) => setSelectedModerator(e.target.value)}
                                value={selectedModerator}>
                                <option value="0">Choose an Moderator</option>

                                {filtermoderator?.map((item, key) => (
                                  <option key={key} value={item?.value}>{item?.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>

                        </div>
                      </div>

                      {/* <div className="filterContainer">


                        {filter?.length > 0 && (
                          <button
                            type="button"
                            className="closeBtn"
                            onClick={() => setFilter("")}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        )}
                      </div> */}
                      <PaginationProvider
                        pagination={paginationFactory(options)}
                      >
                        {({ paginationTableProps }) => (
                          <div className="bootstrapTable p-2">
                            <BootstrapTable
                              keyField="id"
                              columns={columns}
                              data={filteredData}
                              className="no-border-table"
                              noDataIndication={
                                <div className="noRecord">
                                  {loading ? (
                                    <label>
                                      <Response_Loading_processing></Response_Loading_processing>
                                      Loading...
                                    </label>
                                  ) : (
                                    filteredData?.length <= 0 && (
                                      <p>No Record Found!</p>
                                    )
                                  )}
                                </div>
                              }
                              hover
                              headerClasses="videoWidth"
                              selectAll={selectAll}
                              {...paginationTableProps}
                            />
                          </div>
                        )}
                      </PaginationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PlayerVideoAnalytics;
