import React, { useRef, useState } from 'react'
import "./AdsUpload.css"
import AdminSaveHeader from '../../../components/CommonComponents/AdminSaveHeader'
import MessageLabel from '../../../components/CommonComponents/MessageLabel'
import FormContainer from '../../../components/CommonComponents/FormContainer'
import LeftWrapper from '../../../components/CommonComponents/LeftWrapper'
import Wrapper from '../../../components/CommonComponents/Wrapper'
import MessageBox from '../../../components/CommonComponents/MessageBox'
import RightWrapper from '../../../components/CommonComponents/RightWrapper'
import AdminCustomButton from '../../../components/CommonComponents/AdminCustomButton'
import MessageHeading from '../../../components/CommonComponents/MessageHeading'
const AdsUpload = () => {

    const combinedRef = useRef([]);
    const setRef = (element, index) => {
        if (element) {
            combinedRef.current[index] = element;
        }
    };
    // combinedRef.current['name'].focus();


    const [formData, setFormData] = useState({
        active: 1
    })
    const [formDataError, setFormDataError] = useState({})


    //#region StoreApi
    const handleStore = () => {

    }

    const handleInput = (e) => {
        const { name, value } = e.target
        if (e.target.checked == true) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [e.target.name]: prevFormData?.formData?.[e.target.name] == 1 ? 0 : 1,
            }));
        }
        else {
            setFormData((formData) => ({
                ...formData,
                [name]: value
            }))
        }
    }

    return (
        <div>
            <AdminSaveHeader heading="Add Ad Tag" saveText="Save" saveMethod={handleStore} />
            <form>
                <FormContainer>
                    <LeftWrapper>
                        <Wrapper>

                            <MessageLabel text="Ads Name" className="mb-3" />
                            <input type="text" name="title" value={formData?.name} onChange={handleInput} placeholder="Title" ref={(el) => setRef(el, 'name')} className="rs-input p-3 custom-placeholder  theme-text-color border border-2 theme-border-color mb-3" />
                            {formDataError?.name && (<MessageBox text={`${formDataError?.name}`} classname='errorred mb-2 d-block' />)}
                            <MessageLabel text="Ads Format" className="mb-3" />
                            <select onChange={handleInput} value={formData?.source} className='rs-input px-3 py-2 custom-placeholder  theme-text-color border border-2 theme-border-color mb-3' ref={(el) => setRef(el, 'source')}>
                                <option value='video'>Video</option>
                                <option value='audio'>Audio</option>
                            </select>

                            <MessageLabel text="Ads Position" className="mb-3" />
                            <select onChange={handleInput} value={formData?.position} className='rs-input px-3 py-2 custom-placeholder  theme-text-color border border-2 theme-border-color mb-3' ref={(el) => setRef(el, 'position')}>
                                <option value='pre'>Pre</option>
                                <option value='mid'>Mid</option>
                                <option value='post'>Post</option>
                                <option value='all'> All Position (Only for Ads Type - Tag URL)</option>
                            </select>



                            {/* <div className="row mx-0">
                                <div className="col-9 ps-0">
                                    <MessageLabel text="Ads Status Settings" />
                                </div>
                                <div className="col-3 text-end">
                                    <label className="switch">
                                        <input name="active" onChange={handleInput} defaultChecked={formData?.active == 1 ? true : false} checked={formData?.active == 1 ? true : false} type="checkbox" className="rs-input" />
                                        <span name="active" onChange={handleInput} value={formData?.active == 1 ? "1" : "0"} className="slider round" ></span>
                                    </label>
                                </div>
                            </div> */}

                        </Wrapper>
                        <Wrapper>
                            <MessageHeading text="Status Settings" />
                            <div className="row mx-0">
                                <div className="col-9 ps-0">
                                    <MessageLabel text="Ads Publish" />
                                </div>
                                <div className="col-3 text-end">
                                    <label className="switch">
                                        <input name="active" onChange={handleInput} defaultChecked={formData?.active == 1 ? true : false} checked={formData?.active == 1 ? true : false} type="checkbox" className="rs-input" />
                                        <span name="active" onChange={handleInput} value={formData?.active == 1 ? "1" : "0"} className="slider round" ></span>
                                    </label>
                                </div>
                            </div>
                        </Wrapper>
                    </LeftWrapper>
                    <RightWrapper>
                        <Wrapper>
                            <MessageLabel text="Ads Categories" className="mb-3" />
                            <select onChange={handleInput} value={formData?.category} className='rs-input px-3 py-2 custom-placeholder  theme-text-color border border-2 theme-border-color mb-3' ref={(el) => setRef(el, 'category')}>
                                <option value='kids'>Kids</option>
                                <option value='anime'>Anime </option>
                            </select>
                            <MessageLabel text="Ads Upload Type" className="mb-3" />
                            <select onChange={handleInput} value={formData?.type} name="type" className='rs-input px-3 py-2 custom-placeholder  theme-text-color border border-2 theme-border-color mb-3' ref={(el) => setRef(el, 'type')}>
                                <option value='null'>Select Ads Type</option>
                                <option value='url'>Ad Tag Url </option>
                                <option value='video'> Ads Video Upload </option>
                            </select>

                            {formData?.type == "video" &&
                                <div className='dropZoneAds border-2 dotted theme-border-color rounded-3 position-relative'>
                                    <input type="file" className='opacity-0 dropZoneUpload position-absolute w-100 h-100' />
                                    <span className='position-absolute dropText'>Drop Here</span>
                                </div>}

                            {formData?.type == "url" && <><MessageLabel text="Ads Url" className="mb-3" />
                                <input type="text" name="title" value={formData?.name} onChange={handleInput} placeholder="Paste your url here" ref={(el) => setRef(el, 'name')} className="rs-input p-3 custom-placeholder  theme-text-color border border-2 theme-border-color mb-3" />
                                {formDataError?.name && (<MessageBox text={`${formDataError?.name}`} classname='errorred mb-2 d-block' />)}
                            </>}
                        </Wrapper>
                    </RightWrapper>
                    <AdminCustomButton saveMethod={handleStore} saveText="Save" className="ms-auto" />
                </FormContainer>
            </form>
        </div >
    )
}

export default AdsUpload