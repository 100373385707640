import React from "react";
import { Link } from "react-router-dom";
import { TbPlus, TbClockHour4 } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import "./AdsTimeSlot.css";

function AdsTimeSlot() {
  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid">
          <div id="content-page" className="content-page">
            <div className="col-lg-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">Ads Time Slot</h4>
                  </div>
                </div>
                <hr />

                <div>
                  <h5>Set your weekly hours</h5>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked
                  />
                  <label className="form-check-label" for="flexCheckChecked">
                    Monday
                    <TbPlus />
                  </label>
                </div>

                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row mt-3">
                        <div className="col-md-3 timer timing">
                          <input
                            type="time"
                            name="Monday_Start_time[]"
                            className="form-control "
                            value="09:00"
                          />
                        </div>

                        <div className="col-md-3 timing">
                          <input
                            type="time"
                            name="Monday_Start_time[]"
                            className="form-control"
                            value="11:00"
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 d-flex align-items-center">
                          <RiDeleteBinLine />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-3 timer timing">
                          <input
                            type="time"
                            name="Monday_Start_time[]"
                            className="form-control "
                            value="14:00"
                          />
                        </div>

                        <div className="col-md-3 timing">
                          <input
                            type="time"
                            name="Monday_Start_time[]"
                            className="form-control"
                            value="16:00"
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 d-flex align-items-center">
                          <RiDeleteBinLine />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-3 timer timing">
                          <input
                            type="time"
                            name="Monday_Start_time[]"
                            className="form-control "
                            value="18:00"
                          />
                        </div>

                        <div className="col-md-3 timing">
                          <input
                            type="time"
                            name="Monday_Start_time[]"
                            className="form-control"
                            value="22:00"
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 d-flex align-items-center">
                          <RiDeleteBinLine />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                </div>

                <div className="form-check mt-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked
                  />
                  <label className="form-check-label" for="flexCheckChecked">
                    Tuesday
                    <TbPlus />
                  </label>
                </div>

                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row mt-3">
                        <div className="col-md-3 timer timing">
                          <input
                            type="time"
                            name="Monday_Start_time[]"
                            className="form-control "
                            value="09:00"
                          />
                        </div>

                        <div className="col-md-3 timing">
                          <input
                            type="time"
                            name="Monday_Start_time[]"
                            className="form-control"
                            value="11:00"
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 d-flex align-items-center">
                          <RiDeleteBinLine />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-3 timer timing">
                          <input
                            type="time"
                            name="Monday_Start_time[]"
                            className="form-control "
                            value="14:00"
                          />
                        </div>

                        <div className="col-md-3 timing">
                          <input
                            type="time"
                            name="Monday_Start_time[]"
                            className="form-control"
                            value="16:00"
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 d-flex align-items-center">
                          <RiDeleteBinLine />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-3 timer timing">
                          <input
                            type="time"
                            name="Monday_Start_time[]"
                            className="form-control "
                            value="18:00"
                          />
                        </div>

                        <div className="col-md-3 timing">
                          <input
                            type="time"
                            name="Monday_Start_time[]"
                            className="form-control"
                            value="22:00"
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 d-flex align-items-center">
                          <RiDeleteBinLine />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AdsTimeSlot;
