import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Audiochannelpartner() {
  const [channel, setChannel] = useState([]);
  const [data1, setData1] = useState([]);
  const [previous1, setPrevious1] = useState([]);
  const [next1, setNext1] = useState([]);
  const [page1, setPage1] = useState(1);
  const [toggle1, setToggle1] = useState(true);
  const [isOff1, setIsOff1] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/audios-pending`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.pending_audio?.data;
        setChannel(result);
      })
      .catch((error) => console.log(error));
  }, []);

  const rejectedchannel = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/reject-audio/` +
          id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Rejected Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  const approvedchannel = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/approve-audio/` +
          id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Approved Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClickchannel() {
    // console.log('preview');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/channel_partner/audios-pending?page=${page1 - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious1(res?.data?.pending_audio?.data);
        setChannel(res?.data?.pending_audio?.data);
        setPage1(page1 - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpagechannel() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/channel_partner/audios-pending?page=${page1 - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious1(res?.data?.pending_audio?.data);
        setChannel(res?.data?.pending_audio?.data);
        setPage1(page1 - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClickchannel() {
    // console.log('next');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/channel_partner/audios-pending?page=${page1 + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.videos.data);
        setNext1(res?.data?.pending_audio?.data);
        setChannel(res?.data?.pending_audio?.data);
        setPage1(page1 + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <section className="section container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="py-3">
                <h4>Channel Uploaded Audios</h4>
              </div>
              <div className="iq-card">
                <div className="iq-card-header justify-content-between">
                  <div className="row ">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        {" "}
                        <div className="iq-card-body table-responsive p-0">
                          <hr />
                          <div className="table-view">
                            <table
                              className="table text-center table-striped table-bordered table movie_table iq-card"
                              id="videocpp"
                            >
                              <thead>
                                <tr>
                                  <th>TITLES</th>
                                  <th>USER NAME</th>
                                  <th>YEAR</th>
                                  <th>VIDEO ACCESS</th>
                                  <th>STATUS</th>
                                  <th>ACTION</th>
                                </tr>
                              </thead>

                              {channel?.map((item, index) => {
                                const value = item?.id;
                                const value2 = item?.id;

                                if (value && value2) {
                                  return (
                                    <tbody>
                                      <tr key={item?.id}>
                                        <td>{item?.title}</td>
                                        <td>{item?.username}</td>
                                        <td>{item?.year}</td>
                                        <td>{item?.access}</td>

                                        <td className="text-center">
                                          {item?.status === 0 ||
                                          item?.status === null ? (
                                            <td className="bg-warning video_active">
                                              Pending Approved Rejected
                                            </td>
                                          ) : item?.status === 1 &&
                                            item?.status === 1 &&
                                            item?.status === 1 ? (
                                            <td className="bg-success video_active">
                                              Approved
                                            </td>
                                          ) : (
                                            <td className="bg-warning video_active">
                                              Draft
                                            </td>
                                          )}
                                        </td>
                                        <td colspan="2">
                                          <div className="flex align-items-center list-user-action fs-5">
                                            <Link
                                              className="iq-bg-warning"
                                              to=""
                                              onClick={() =>
                                                approvedchannel(item?.id)
                                              }
                                            >
                                              <i className="fa fa-check-circle"></i>
                                            </Link>
                                            <Link
                                              className="iq-bg-success ms-2"
                                              to=""
                                              onClick={() =>
                                                rejectedchannel(item?.id)
                                              }
                                            >
                                              <i className="fa fa-close"></i>
                                            </Link>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                } else {
                                  return <div></div>;
                                }
                              })}
                            </table>
                          </div>
                          <div className="row align-items-center text-center d-flex">
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn btn-primary "
                                onClick={handlePreClickchannel}
                              >
                                {"<< Previous"}
                              </button>
                            </div>
                            <div className="col-lg-4">
                              <p className="">{page1}</p>
                            </div>
                            <div className="col-lg-4">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleNxtClickchannel}
                              >
                                {"Next >>"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Audiochannelpartner;
