import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import backarrow from "../../../../components/Images/backarrow.svg";

function Recaptcha_Setting(props) {
  const [message, setMessage] = useState("");

  const [capcha, setCapcha] = useState({
    captcha_site_key: "",
    captcha_secret_key: "",
    captcha: "",
  });

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, {
        headers: headers,
      })
      .then((response) => {
        var captchas = response?.data?.captchas;

        setCapcha(captchas);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setCapcha((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagecaptcha_secret_key("");
    setIsValidationHiddencaptcha_secret_key(true);
    setValidationMessagecaptcha_site_key("");
    setIsValidationHiddencaptcha_site_key(true);
    setCapcha({ ...capcha, [e.target.name]: e.target.value });

    // if (e.target.checked === true) {
    //   setCapcha({ ...capcha, [e.target.name]: e.target.value });
    //   setCapcha({ ...capcha, [e.target.id]: 1 });
    //   setCapcha({
    //     ...capcha,
    //     [e.target.name]: e.target.value,
    //     [e.target.name]: 1,
    //   });
    // } else {
    //   setCapcha({ ...capcha, [e.target.name]: e.target.value });
    //   setCapcha({ ...capcha, [e.target.id]: 0 });
    //   setCapcha({ ...capcha, [e.target.name]: e.target.value });
    // }
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setCapcha({ ...capcha, [e.target.name]: 1 });
    } else {
      setCapcha({ ...capcha, [e.target.name]: 0 });
    }
  };


  const [validationMessagecaptcha_site_key, setValidationMessagecaptcha_site_key] =
    useState("");
  const [isValidationHiddencaptcha_site_key, setIsValidationHiddencaptcha_site_key] =
    useState(true);

  const [validationMessagecaptcha_secret_key, setValidationMessagecaptcha_secret_key] =
    useState("");
  const [isValidationHiddencaptcha_secret_key, setIsValidationHiddencaptcha_secret_key] =
    useState(true);

  const captcha_site_keyInputRef = useRef(null);
  const captcha_secret_keyInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    let focusInputRef = null;

    if (capcha?.captcha_site_key === "") {
      setValidationMessagecaptcha_site_key("Site Key cannot be empty.");
      setIsValidationHiddencaptcha_site_key(false);
      focusInputRef = captcha_site_keyInputRef;
    } if (capcha?.captcha_secret_key === "") {
      setValidationMessagecaptcha_secret_key("Secret Key cannot be empty.");
      setIsValidationHiddencaptcha_secret_key(false);
      focusInputRef = captcha_secret_keyInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const editInputvalue = {
      captcha_site_key: capcha?.captcha_site_key,
      captcha_secret_key: capcha?.captcha_secret_key,
      enable_captcha: capcha?.enable_captcha,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/captcha/store`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res?.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        // secondApiStatus = true;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setValidationMessagecaptcha_site_key("");
      setValidationMessagecaptcha_secret_key("");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddencaptcha_secret_key(false);
        setIsValidationHiddencaptcha_site_key(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   const editInputcapcha = {
  //     captcha_site_key: capcha.captcha_site_key,
  //     captcha_secret_key: capcha.captcha_secret_key,
  //     enable_captcha: capcha.enable_captcha,
  //   };
  //   // console.log(editInputvalue);
  //   let capchas = await fetch(
  //     `${process.env.REACT_APP_Baseurl}/admin/captcha/store`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + access_token,
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //       cache: "no-cache",
  //       credentials: "same-origin",
  //       redirect: "follow",
  //       referrer: "no-referrer",
  //       body: JSON.stringify(editInputcapcha),
  //     }
  //   );

  //   // console.log(resjson)
  //   if (capchas.status === 200) {
  //     setMessage(capchas.success);
  //     alert("Success");
  //     // window.location.reload();
  //     setTimeout(() => {}, 2000);
  //   } else {
  //     setMessage("Some error Occured");
  //     console.log("Error");
  //     alert("Enter Correct Details");
  //   }
  // };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <Link to="/settings" className="backarrow" >
                <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                <span> Back</span>
              </Link>
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Re-captcha Settings</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Save Re-captcha
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Site</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">Captcha Site Key<span className="mandatory">*</span></label>
                    <div className="mt-2">
                      <input
                        type="text"
                        // id="captcha_site_key"
                        name="captcha_site_key"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        ref={captcha_site_keyInputRef}
                        value={capcha?.captcha_site_key}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddencaptcha_site_key && (
                        <p>{validationMessagecaptcha_site_key}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Captcha Secret Key<span className="mandatory">*</span></label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="captcha_secret_key"
                        name="captcha_secret_key"
                        value={capcha?.captcha_secret_key}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        ref={captcha_secret_keyInputRef}
                        placeholder=""
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddencaptcha_secret_key && (
                        <p>{validationMessagecaptcha_secret_key}</p>
                      )}
                    </span>
                  </div>
                </div>

                {/* <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Save PPV
                  </button>
                </div> */}
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Status Settings</h5>
                  <hr></hr>
                  <div className="row">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">Enable Captcha</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3 text-end">
                      <label className="switch">
                        <input
                          name="enable_captcha"
                          id="enable_captcha"
                          onChange={handleInputenable}
                          className="rs-input"
                          defaultChecked={
                            capcha?.ppv_status == 1 ? true : false
                          }
                          checked={capcha?.enable_captcha == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="enable_captcha"
                          id="enable_captcha"
                          onChange={handleInputenable}
                          value={capcha?.enable_captcha == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid">
          <div className="mt-4">
            <div className="iq-card">
              <div className="" id="recpatcha_capchas">
                <h4>Re-captcha Settings</h4>
                <hr />
                <div className="row">
                  <form>
                    <div className="row mt-3">
                      <div className="col-sm-6" id="">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label> Captcha Site Key</label>
                            </div>
                          </div>

                          <div className="panel-body">
                            <div className="form-group">
                              <div
                                className="make-switch"
                                data-on="success"
                                data-off="warning"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  name="captcha_site_key"
                                  placeholder="Captcha Site Key"
                                  value={capcha?.captcha_site_key}
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6" id="">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            <div className="panel-title">
                              <label> Captcha Secret Key </label>{" "}
                            </div>
                          </div>

                          <div className="panel-body">
                            <div className="form-group">
                              <div
                                className="make-switch"
                                data-on="success"
                                data-off="warning"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  name="captcha_secret_key"
                                  s
                                  placeholder="Captcha Secret Key"
                                  required
                                  value={capcha?.captcha_secret_key}
                                  onChange={handleInput}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6" id="">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading mt-2">
                            <div className="panel-title">
                              <label>Enable Captcha </label>
                            </div>
                          </div>

                          <div className="panel-body">
                            <div className="form-group mt-2">
                              <div className="d-flex ">
                                <div>OFF</div>
                                <div className="">
                                  <label className="switch">
                                    <input
                                      name="enable_captcha"
                                      onChange={handleInput}
                                      defaultChecked={
                                        capcha?.enable_captcha === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        capcha?.enable_captcha === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="enable_captcha"
                                      onChange={handleInput}
                                      value={
                                        capcha?.enable_captcha === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                </div>
                                <div>ON</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end mt-3">
                        <input
                          type="submit"
                          id="appupdate"
                          value="Update Setting"
                          onClick={handleUpdate}
                          className="btn btn-primary ml-3 "
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div> */}
    </>
  );
}

export default Recaptcha_Setting;
