import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./Translation.css";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import AdminNavbar from "../../../components/CommonComponents/AdminNavbar";
import MessageLabel from "../../../components/CommonComponents/MessageLabel";
import AdminCustomButton from "../../../components/CommonComponents/AdminCustomButton";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import Response_Processing from "../../../layout/Others/Response_Processing";
import { toast } from "react-toastify";
import { BiCheck, BiX } from "react-icons/bi";
import AdminDeleteModal from "../../../components/CommonComponents/AdminDeleteModal";
import PaginationCustom from "../../../components/CommonComponents/PaginationCustom";
import { getItemToken } from "../../../Utils/localStorageUtils";

function ManageTranslationLanguage() {
  const accessTokenHeader = getItemToken("access_token")
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [apiData, setApiData] = useState({
    languages: [],
    columns: [],
    response: [],
    keyData: []
  });
  const [formData, setFormData] = useState()
  const [formDataError, setFormDataError] = useState({})
  const [showOverlay, setShowOverlay] = useState(false);
  const [editField, setEditField] = useState({});
  const [editKeyField, setEditKeyField] = useState({
    key: null, oldKey: null, index: null
  })

  const [itemToDeletekey, setItemToDeletekey] = useState(null);
  const [modelClose, setModelClose] = useState(false);
  const [loading, setLoading] = useState(true);

  const keyInputRef = useRef(null)
  const valueInputRef = useRef(null)

  const keyValueStoreApi = `${process.env.REACT_APP_Baseurl}/admin/translate/language/store`;
  const getApi = `${process.env.REACT_APP_Baseurl}/admin/translate/languages`
  const deleteApi = `${process.env.REACT_APP_Baseurl}/admin/translate/language/destroy`
  const updateApi = `${process.env.REACT_APP_Baseurl}/admin/translate/language/update`
  const updateKeyApi = `${process.env.REACT_APP_Baseurl}/admin/translate/language/updateKey`

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormDataError((prev) => ({ ...prev, [name]: '' }));
  };

  const handleTextChange = (e, find, rowIndex, columnIndex, lang) => {
    const newValue = e.target.value;
    setApiData((prev) => {
      const newColumns = prev.columns.map((column) => {
        if (column.lang == lang) {
          const columnData = { ...column.data };
          if (!(find in columnData)) {
            columnData[find] = newValue;
          } else {
            columnData[find] = newValue;
          }
          return { ...column, data: columnData };
        }
        return column;
      });
      return {
        ...prev,
        columns: newColumns,
      };
    });
  }

  const handleKeyChange = (e, parentKey, rowIndex) => {
    const newKey = e.target.value;
    setApiData(prevData => {
      const newData = [...prevData.keyData];
      newData[rowIndex] = newKey;
      return { ...prevData, keyData: newData };
    });
  };

  const handleUpdateKey = async (sideKey, index) => {
    setEditKeyField({
      key: sideKey,
      oldKey: sideKey,
      index: index
    });
    setEditField({});
  };

  const openModalDeleteKey = (key) => {
    setItemToDeletekey(key);
    setModelClose(true);
  };

  const handleEditClick = (rowIndex, columnIndex) => {
    setEditField(() => ({
      [rowIndex]: columnIndex,
    }));
    setEditKeyField({})
  };

  const handleStore = () => {
    if (formValidation()) {
      handleAdd();
    } else {
      console.error("Form validation failed ");
    }
  }

  const formValidation = () => {
    let formIsValid = true;
    if (!formData?.key) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        key: "Key cannot be empty.",
      }));
      keyInputRef.current.focus();
      formIsValid = false;
    }
    if (!formData?.value) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        value: "Value cannot be empty.",
      }));
      valueInputRef.current.focus();
      formIsValid = false;
    }
    return formIsValid;
  }

  const handleAdd = async () => {
    setShowOverlay(true);
    try {
      const formDataAdd = new FormData();
      for (const key in formData) {
        formDataAdd.append(key, formData[key]);
      }
      const response = await axios.post(`${keyValueStoreApi}`, formDataAdd, { headers: accessTokenHeader });
      const result = response?.data;
      if (result) {
        setShowOverlay(false);
        toast.success(result?.message)
        setFormData((prev) => ({
          ...prev,
          key: '',
          value: ''
        }));
        setApiData((prevFormData) => ({
          ...prevFormData,
          response: result
        }));
      }
      else {
        setShowOverlay(false);
        toast.error(result?.message)
      }
    }
    catch (err) {
      setShowOverlay(false);
      const errorMessage = err?.response?.data?.message
      toast.error(errorMessage)
      console.error("error", err)
    }
  }

  const getAllTranslateLanguages = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${getApi}`, { headers: accessTokenHeader });
      const result = await response?.data;
      setApiData({
        languages: result?.languages,
        columns: result?.columns,
        keyData: Object.keys(result.columns[0])
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  console.log(apiData?.keyData?.length)

  const handleUpdate = async (newkey, parentKey, lang) => {
    setShowOverlay(true);
    const editInputvalue = {
      pk: parentKey,
      value: newkey,
      code: lang,
    };
    try {
      const response = await axios.post(updateApi, editInputvalue, { headers: accessTokenHeader });
      const result = response?.data
      if (result.status) {
        setShowOverlay(false);
        toast.success(result?.message)
        setEditField({});
      }
      else {
        setShowOverlay(false);
        toast.error(result?.message)
      }
    }
    catch (err) {
      setShowOverlay(false);
      const errorMessage = err?.response?.data?.message
      toast.error(errorMessage)
      console.error("error", err)
    }
  };

  const handleUpdateKeyStore = async () => {
    setShowOverlay(true);
    const editInputvalue = {
      pk: editKeyField?.oldKey,
      value: apiData?.keyData[editKeyField?.index],
    };
    try {
      const response = await axios.post(updateKeyApi, editInputvalue, { headers: accessTokenHeader });
      const result = response?.data
      if (result.status) {
        setShowOverlay(false);
        toast.success(result?.message)
        setApiData((prevFormData) => ({
          ...prevFormData,
          response: result
        }));
        setEditKeyField({});
      }
      else {
        setShowOverlay(false);
        toast.error(result?.message)
      }
    }
    catch (err) {
      setShowOverlay(false);
      const errorMessage = err?.response?.data?.message
      toast.error(errorMessage)
      console.error("error", err)
    }
  }

  const handleClose = () => {
    setEditField({});
    setEditKeyField({})
  }

  const closeModal = () => {
    setModelClose(!closeModal)
  }

  const deleteAction = async () => {
    setShowOverlay(true);
    if (itemToDeletekey) {
      try {
        const response = await axios.delete(`${deleteApi}/${itemToDeletekey}`, { headers: accessTokenHeader })
        const result = response?.data
        if (result?.status) {
          toast.success(result?.message)
          setShowOverlay(false);
          closeModal()
          setApiData((prevFormData) => ({
            ...prevFormData,
            response: result
          }));
          setCurrentPage(1);
        } else {
          toast.error(result?.message)
          setShowOverlay(false);
        }
      } catch (err) {
        setShowOverlay(false);
        const errorMessage = err?.response?.data?.message
        toast.error(errorMessage)
        console.error("error", err)
      }
    }
    else {
      alert()
    }
  }

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchInput(value.toLowerCase());
    setCurrentPage(1);
  };

  //pagination controller
  const filteredKeys = Object.keys(apiData?.columns?.[0] || {}).filter(key =>
    apiData?.columns.slice(1).some(column =>
      column?.data[key]?.toLowerCase().includes(searchInput)
    )
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredKeys.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(async () => {
    setApiData("")
    await getAllTranslateLanguages();
  }, [apiData?.response]);

  useEffect(() => {
    setApiData(prevData => {
      return { ...prevData, keyData: currentItems };
    });
  }, [searchInput]);


  const handleSort = (columnIndex) => {
    if (columnIndex === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(columnIndex);
      setSortOrder('asc');
    }
  };
  // onClick={() => handleSort(index)}

  const sortedColumns = apiData?.columns?.slice(1).sort((a, b) => {
    const aValue = a.data[currentItems[sortColumn]] || '';
    const bValue = b.data[currentItems[sortColumn]] || '';
    if (sortOrder === 'asc') {
      return aValue.localeCompare(bValue);
    } else {
      return bValue.localeCompare(aValue);
    }
  });

  const handlePageLength = (length) => {
    setItemsPerPage(length)
    setCurrentPage(1);
  }
  return (
    <>
      {modelClose && <AdminDeleteModal modelClose={modelClose} deleteClose={closeModal} deleteAction={deleteAction} buttonText={1} />}
      {showOverlay && (<Response_Processing></Response_Processing>)}
      <AdminNavbar heading={'Language Translation'} />
      <div className="theme-bg-color p-4 rounded-2" >
        <div className="d-flex align-items-center mb-4  ">
          <div className="col-3 pe-2 position-relative">
            <MessageLabel text="Key:" />
            <input type="text" name="key" value={formData?.key} onChange={handleChange} ref={keyInputRef} placeholder="Enter key" className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0" />
            {formDataError?.key && (<MessageBox text={`${formDataError?.key}`} classname='errorred my-2 d-block' />)}
          </div>
          <div className="col-3 ps-2 position-relative">
            <MessageLabel text="Value:" />
            <input type="text" name="value" value={formData?.value} onChange={handleChange} ref={valueInputRef} placeholder="Enter value" className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0" />
            {formDataError?.value && (<MessageBox text={`${formDataError?.value}`} classname='errorred my-2 d-block' />)}
          </div>
          <div className="col-2 d-flex mt-4 pt-1">
            <AdminCustomButton saveMethod={handleStore} saveText="Add" className="me-auto ms-2" />
          </div>
          <div className="col-4 ps-2 position-relative">
            <MessageLabel text="Search:" />
            <input
              id="searchInput"
              type="text"
              placeholder="Search by language"
              value={searchInput}
              onChange={handleInputChange}
              className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
            />
          </div>
        </div>
        {loading ? <label className="text-center d-block"> <Response_Loading_processing></Response_Loading_processing> Loading... </label>
          : apiData?.languages?.length > 0 ?
            <div className="languageTable">
              <table className="table table-bordered table-hover theme-border-color ">
                <thead>
                  <tr>
                    <th className="p-3 theme-bg-color theme-text-color theme-border-color">Key</th>
                    {apiData?.languages.map((lang, index) => (
                      <th key={index} className="p-3 theme-bg-color theme-text-color theme-border-color" >{`${lang.name} (${lang.code})`}</th>
                    ))}
                    <th className="p-3 theme-bg-color theme-text-color theme-border-color">Action</th>
                  </tr>

                </thead>
                <tbody>
                  {currentItems.map((parentKey, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        {rowIndex == editKeyField?.index ? <td className="p-2 theme-bg-color theme-text-color theme-border-color position-relative">
                          <input
                            type="text"
                            value={apiData?.keyData[(currentPage - 1) * itemsPerPage + rowIndex]}
                            className="input-fieldtranslate w-100 theme-bg-color-secondary"
                            onChange={(e) => handleKeyChange(e, parentKey, (currentPage - 1) * itemsPerPage + rowIndex)}
                          />
                          <div className="actionTable position-absolute" >
                            <button type="button" className="bg-transparent" onClick={() => handleUpdateKeyStore(parentKey)}><BiCheck className="save-icon theme-text-color" /></button>
                            <button type="button" className="bg-transparent" onClick={handleClose} ><BiX className="save-icon theme-text-color" /></button>
                          </div>
                        </td> :
                          <td className="p-2 theme-bg-color theme-text-color theme-border-color" onClick={() => handleUpdateKey(parentKey, rowIndex)}><strong className="theme-text-color">{parentKey}</strong></td>}
                        {sortedColumns.map((column, columnIndex) => (
                          editField[rowIndex] == columnIndex ? <td className="p-2 theme-bg-color theme-text-color theme-border-color position-relative">
                            <input type="text" id="value" name="value" class="input-fieldtranslate w-100 theme-bg-color-secondary" value={column.data[parentKey]} placeholder={column.data[parentKey]} onChange={(e) => handleTextChange(e, parentKey, rowIndex, columnIndex, column.lang)} />
                            <div className="actionTable position-absolute">
                              <button type="button" className="bg-transparent" onClick={(e) => handleUpdate(column.data[parentKey], parentKey, column.lang,)}><BiCheck className="save-icon theme-text-color" /></button>
                              <button type="button" className="bg-transparent" onClick={handleClose}  ><BiX className="save-icon theme-text-color" /></button>
                            </div>
                          </td> : <td key={columnIndex} className="p-2 theme-bg-color theme-text-color theme-border-color" onClick={(e) => handleEditClick(rowIndex, columnIndex)}>{column.data[parentKey] ? column.data[parentKey] : "Empty"}</td>
                        ))}
                        <td className="p-2 theme-bg-color theme-text-color theme-border-color"><button type="button" onClick={() => openModalDeleteKey(parentKey)} className="btn btn-danger " >Delete</button> </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <div classname="d-flex align-items-center justify-content-between">
                {/* <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {itemsPerPage}
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li onClick={() => handlePageLength(10)}><a class="dropdown-item" >10</a></li>
                    <li onClick={() => handlePageLength(50)}><a class="dropdown-item">50</a></li>
                    <li onClick={() => handlePageLength(searchInput ? currentItems?.length : Object.keys(apiData?.columns?.[0])?.length)}><a class="dropdown-item" >All{searchInput ? currentItems?.length : Object.keys(apiData?.columns?.[0])?.length}</a></li>
                  </ul>
                </div> */}
                <PaginationCustom
                  data={filteredKeys}
                  handlePage={paginate}
                  currentPage={currentPage}
                  visibleLength={itemsPerPage}
                />
              </div>
            </div>
            : <div className="text-center">
              <label>No Languages Available.</label>
            </div>}
      </div >
    </>
  );
}

export default ManageTranslationLanguage;
