// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order_home li {
    list-style:none !important;
}

.order_home ol, ul {
    padding-left: 0;
}

li:first-child {
    list-style:none;
}


.data {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #161616;
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.data_th {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Content_Partner/ContentCommission.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;;AAGA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,6BAA6B;IAC7B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,sBAAsB;IACtB,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,6BAA6B;IAC7B,gCAAgC;IAChC,aAAa;AACjB","sourcesContent":[".order_home li {\n    list-style:none !important;\n}\n\n.order_home ol, ul {\n    padding-left: 0;\n}\n\nli:first-child {\n    list-style:none;\n}\n\n\n.data {\n    padding: 0.75rem;\n    vertical-align: middle;\n    border-top: 1px solid #dee2e6;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 22px;\n    color: #161616;\n    vertical-align: bottom;\n    border-bottom: 2px solid #dee2e6;\n}\n\n.data_th {\n    padding: 0.75rem;\n    vertical-align: middle;\n    border-top: 1px solid #dee2e6;\n    border-bottom: 1px solid #dee2e6;\n    height: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
