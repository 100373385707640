
import React, { useState } from "react";
import "./signup.css";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../Images/flicklogo.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function Signup() {
  const navigate = useNavigate();
  const [input, setInput] = useState({
    email: "",
    username: "",
    phone: "",
    password: "",
    number: "",
    check: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    emailerr: "",
    usernameerr: "",
    passworderr: "",
    numbererr: "",
    checkerr: "",
    confirmPassworderr: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const saveFormData = () => {
    console.log(input);

    axios
      .post(`${process.env.REACT_APP_Baseurl}/users/signup`, {
        name: input?.username,
        email: input?.email,
        password: input?.password,
        mobile: input?.number,
        confirm_password: input?.confirmPassword,
      })
      .then((res) => {
        navigate("/");
      })
      .catch((err) => {
        console.log("Error");
      });
  };

  const validateInput = (e) => {
    let { name, value } = e.target;

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const validateNumber = (number) => {
      return String(number)
        .toLowerCase()
        .match(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
    };

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      let validated = true;

      setError({
        emailerr: "",
        usernameerr: "",
        passworderr: "",
        numbererr: "",
        checkerr: "",
        confirmPassworderr: "",
      });

      if (input?.username === "") {
        validated = false;
        setError((prevState) => ({
          ...prevState,
          emailerr: "Please Enter Username*",
        }));
      }

      if (input?.email === "") {
        validated = false;
        setError((prevState) => ({
          ...prevState,
          emailerr: "Please Enter Email*",
        }));
      } else if (!validateEmail(input?.email)) {
        validated = false;
        setError((prevState) => ({
          ...prevState,
          emailerr: "Please Enter Valid Email ID",
        }));
      }

      if (input?.number === "") {
        validated = false;
        setError((prevState) => ({
          ...prevState,
          numbererr: "Please Enter Number*",
        }));
      } else if (!validateNumber(input?.number)) {
        validated = false;
        setError((prevState) => ({
          ...prevState,
          numbererr: "Please Enter Only Number",
        }));
      }

      if (input?.username === "") {
        validated = false;
      }
      if (input?.email === "") {
        validated = false;
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input?.email)
      ) {
        validated = false;
      }

      switch (name) {
        case "email":
          if (!value) {
            stateObj[name] = "Please Enter Email ID*";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input?.email)
          ) {
            stateObj.email = "Invalid Email ID*";
          }
          break;

        case "username":
          if (!value) {
            stateObj[name] = "Please Enter Fullname*";
          } else if (!/^[A-Za-z]+$/gi.test(input?.username)) {
            stateObj.username = "Invalid Enter Fullname*";
          }
          break;

        case "check":
          if (!value) {
            stateObj[name] = "Please Tick CheckBox*";
          }
          break;

        case "number":
          if (!value) {
            stateObj[name] = "Please Enter Mobile Number*";
          } else if (
            !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(
              input?.number
            )
          ) {
            stateObj.number = "Please Enter Valid Number*";
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = "Please Enter Password*";
          } else if (
            input?.confirmPassword &&
            value !== input?.confirmPassword
          ) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match*";
          } else {
            stateObj["confirmPassword"] = input?.confirmPassword
              ? ""
              : error?.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please Enter Confirm Password*";
          } else if (input?.password && value !== input?.password) {
            stateObj[name] = "Password and Confirm Password does not match*";
          }
          break;

        default:
          break;
      }

      if (validated) {
        saveFormData();
      }

      return stateObj;
    });
  };

  // password eye
  // const [passwordType, setPasswordType] = useState("password");
  // const [passwordInput, setPasswordInput] = useState("");
  // const handlePasswordChange = (evnt) => {
  //   setPasswordInput(evnt.target.value);
  // };
  // const togglePassword = () => {
  //   if (passwordType === "password") {
  //     setPasswordType("text");
  //     return;
  //   }
  //   setPasswordType("password");
  // };

  // const [passwordType1, setPasswordType1] = useState("password");
  // const [passwordInput1, setPasswordInput1] = useState("");
  // const handlePasswordChange1 = (evnt) => {
  //   setPasswordInput1(evnt.target.value);
  // };
  // const togglePassword1 = () => {
  //   if (passwordType1 === "password") {
  //     setPasswordType1("text");
  //     return;
  //   }
  //   setPasswordType1("password");
  // };
  // password eye

  const handlePhoneChange = (phone) => {
    console.log(phone);
    setInput({
      ...input,
      number: phone,
    });
  };

  return (
    <div>
      <Header />

      <form>
        <section className="signup mt-4">
          <div className="container">
            <div className="d-flex justify-content-center text-center">
              <div className="box-signup">
                <h1 className="text-center mt-3"> Sign Up</h1>
                <div>
                  <input
                    className="mt-4 w-75 p-2 input"
                    type="text"
                    name="username"
                    placeholder="Enter Fullname*"
                    value={input?.username}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  ></input>
                  {error?.username && (
                    <span className="signuperror">{error?.username}</span>
                  )}
                </div>

                <div>
                  <input
                    className="mt-4 w-75 p-2 input"
                    type="text"
                    name="email"
                    placeholder="Enter Email ID*"
                    value={input?.email}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  ></input>
                  {error?.email && (
                    <span className="signuperror">{error?.email}</span>
                  )}
                </div>

                <div className="mt-4 p-2 input">
                  <div
                    checked={input?.number}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  >
                    <PhoneInput
                      className="ccodee"
                      placeholder="Enter Number +91 9876543210*"
                      value={input?.number}
                      checked={input?.number}
                      onInput={onInputChange}
                      onChange={handlePhoneChange}
                      onBlur={validateInput}
                    />
                  </div>
                  {error?.number && (
                    <span className="signuperror">{error?.number}</span>
                  )}
                </div>

                <div>
                  <input
                    className="mt-4 w-75 p-2 input"
                    // type={passwordType1}
                    name="password"
                    placeholder="Enter Password*"
                    value={input?.password}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  ></input>
                  {error?.password && (
                    <span className="signuperror">{error?.password}</span>
                  )}
                </div>

                <div>
                  <input
                    className="mt-4 w-75 p-2 input"
                    // type={passwordType}
                    name="confirmPassword"
                    placeholder="Enter Confirm Password*"
                    value={input?.confirmPassword}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  ></input>
                </div>
                {error?.confirmPassword && (
                  <span className="signuperror">{error?.confirmPassword}</span>
                )}

                <button
                  type="button"
                  className="btn btn mt-4 w-75 button"
                  onClick={validateInput}
                >
                  Sign Up
                </button>
                <Link to="/" className="text-decoration-none ">
                  <h6 className="accountsign mb-5 mt-4">
                    Already have an account? <span>Sign in</span>
                  </h6>
                </Link>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog ">
              <div className="modal-content agree-model">
                <div className="modal-header">
                  <img src={logo} alt="flogo" className="text-center" />
                  <button
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    X
                  </button>
                </div>
                <div className="modal-body">
                  I Agree to Terms and Conditions
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
}

export default Signup;

// import React, { useState } from 'react';

// function RegistrationView() {
//   const [inputValues, setInputValue] = useState({
//     fName: '',
//     lName: '',
//     email: '',
//     password: '',
//     confirmPassword: '',
//   });

//   const [validation, setValidation] = useState({
//     fName: '',
//     lName: '',
//     email: '',
//     password: '',
//     confirmPassword: '',
//   });

//   //handle submit updates
//   function handleChange(event) {
//     const { name, value } = event.target;
//     setInputValue({ ...inputValues, [name]: value });
//   }

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     let errors = validation;

//     //first Name validation
//     if (!inputValues.fName.trim()) {
//       errors.fName = 'First name is required';
//     } else {
//       errors.fName = '';
//     }
//     //last Name validation
//     if (!inputValues.lName.trim()) {
//       errors.lName = 'Last name is required';
//     } else {
//       errors.lName = '';
//     }

//     // email validation
//     const emailCond =
//       "/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/";
//     if (!inputValues.email.trim()) {
//       errors.email = 'Email is required';
//     } else if (!inputValues.email.match(emailCond)) {
//       errors.email = 'Please ingress a valid email address';
//     } else {
//       errors.email = '';
//     }

//     //password validation
//     const cond1 = '/^(?=.*[a-z]).{6,20}$/';
//     const cond2 = '/^(?=.*[A-Z]).{6,20}$/';
//     const cond3 = '/^(?=.*[0-9]).{6,20}$/';
//     const password = inputValues.password;
//     if (!password) {
//       errors.password = 'password is required';
//     } else if (password.length < 6) {
//       errors.password = 'Password must be longer than 6 characters';
//     } else if (password.length >= 20) {
//       errors.password = 'Password must shorter than 20 characters';
//     } else if (!password.match(cond1)) {
//       errors.password = 'Password must contain at least one lowercase';
//     } else if (!password.match(cond2)) {
//       errors.password = 'Password must contain at least one capital letter';
//     } else if (!password.match(cond3)) {
//       errors.password = 'Password must contain at least a number';
//     } else {
//       errors.password = '';
//     }

//     //matchPassword validation
//     if (!inputValues.confirmPassword) {
//       errors.confirmPassword = 'Password confirmation is required';
//     } else if (inputValues.confirmPassword !== inputValues.Password) {
//       errors.confirmPassword = 'Password does not match confirmation password';
//     } else {
//       errors.password = '';
//     }

//     return setValidation(errors);
//   };

//   return (
//     <div>
//       <div className='sign-up-form'>
//         <form
//           id='registrationForm'
//           action='/'
//           method='POST'
//           onSubmit={handleSubmit}
//         >
//           <div className='form-control'>
//             <input
//               placeholder='First Name'
//               type='string'
//               name='fName'
//               id='fName'
//               className='input-field'
//               onChange={(e) => handleChange(e)}
//               value={inputValues.fName}
//             />
//             {validation.fName && <p>{validation.fName}</p>}
//             {validation.fName && console.log(validation)}
//           </div>
//           <div className='form-control'>
//             <input
//               placeholder='Last Name'
//               type='string'
//               id='lName'
//               name='lName'
//               className='input-field'
//               onChange={(e) => handleChange(e)}
//               value={inputValues.lName}
//             />
//             {validation.lName && <p>{validation.lName}</p>}
//           </div>
//           <div className='form-control'>
//             <input
//               placeholder='email'
//               type='email'
//               name='email'
//               className='input-field'
//               onChange={(e) => handleChange(e)}
//               value={inputValues.email}
//             />
//           </div>
//           {validation.email && <p>{validation.email}</p>}

//           <div className='form-control'>
//             <input
//               placeholder='password'
//               type='password'
//               name='password'
//               className='input-field'
//               onChange={(e) => handleChange(e)}
//               value={inputValues.password}
//               required
//             />
//             {validation.password && <p>{validation.password}</p>}
//           </div>
//           <div className='form-control'>
//             <input
//               placeholder='confirm password'
//               type='password'
//               name='confirmPassword'
//               className='input-field'
//               onChange={(e) => handleChange(e)}
//               value={inputValues.confirmPassword}
//               required
//             />
//           </div>
//           <button type='submit' id='submit-button'>
//             submit
//           </button>
//           <span className='form-input-login'>
//             Already have an account? Login <Link to='#'>here</Link>
//           </span>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default RegistrationView;
