import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import "./EditMobileMenu.css";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function EditMobileMenu(props) {
  const { id } = useParams();
  // const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    user_id: "",
    id: "",
    image: "",
    name: "",
    short_note: "",
    order: "",
    parent_id: "",
  });
  const [editname, setEditname] = useState({
    name: "",
  });

  // const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [image, setImage] = useState("");

  // console.log(imageurl)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/mobile_side_menu/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.mobile_side_menus[0];
      // console.log(res);
      setEditUser(res);
      setEditname(res);
      // {result.map((item) =>
      //   setImageurl(item.image_url),
      //   // console.log(setImageurl)
      //   )}
      // console.log(res)
    };
    getUser();
  }, [id]);

  // const handleInput = (e) => {
  //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
  // };
  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   const editInputvalue = {
  //     id: editUser?.id,
  //     user_id: editUser?.user_id,
  //     name: editUser?.name,
  //     parent_id: editUser?.parent_id,
  //     short_note: editUser?.short_note,
  //     order: editUser?.order,
  //   };
  //   // console.log(editInputvalue);
  //   let res = await fetch(
  //     `${process.env.REACT_APP_Baseurl}/admin/mobile_side_menu/update/` + id,
  //     {
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify(editInputvalue),
  //     }
  //   );

  //   let resjson = await res.json();
  //   console.log(resjson);
  //   if (res.status === 200) {
  //     setMessage(resjson.success);
  //     // alert('Success')
  //     var MobileSideMenu_id = resjson.MobileSideMenu_id;

  //     console.log(MobileSideMenu_id);

  //     // console.log('slider_id', slider_id)
  //     const formDatas = new FormData();

  //     formDatas.append("mobile_side_menu_id", MobileSideMenu_id);
  //     formDatas.append("image", image);

  //     axios
  //       .post(
  //         `${process.env.REACT_APP_Baseurl}/admin/Image-upload-mobile-menus`,
  //         formDatas,
  //         { headers: headers }
  //       )
  //       .then((response) => {
  //         // console.log('api' , response);
  //         if (response.data.status === true) {
  //           var result = response.data;
  //           // console.log('result', response);
  //           navigate("/sliders");
  //           alert("Added successfully  !");
  //         }
  //       });
  //   } else {
  //     setMessage("Some error Occured");
  //     console.log("Error");
  //     alert("Enter Correct Details");
  //   }
  // };

  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = () => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagename("");
    setIsValidationHiddenname(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const [validationMessagename, setValidationMessagename] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);

  const nameInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagename("Name cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      name: editUser?.name,
      parent_id: editUser?.parent_id,
      short_note: editUser?.short_note,
      order: editUser?.order,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/mobile_side_menu/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();
        formData.append("mobile_side_menu_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }

        await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-mobile-menus`,
          formData,
          { headers: headers }
        );
        secondApiStatus = true;
      } else if (resjson?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagename("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddenname(false);
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Mobile Menu</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Menu
                  </button>
                </div>
              </div>
            </div>
            <div>
                {showOverlay && (<Response_Processing></Response_Processing>)}
              </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>{editname?.name}</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Mobile Menu Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="name"
                        name="name"
                        placeholder="Enter Title"
                        onChange={handleInput}
                        value={editUser?.name}
                        ref={nameInputRef}
                      />
                      <input
                        type="text"
                        id="parent_id"
                        name="parent_id"
                        className="form-control"
                        placeholder="Enter the parent_id"
                        value={editUser?.parent_id}
                        hidden
                        onChange={handleInput}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenname && (
                        <p>{validationMessagename}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Short Note</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="name"
                        name="short_note"
                        placeholder="Enter Title"
                        onChange={handleInput}
                        value={editUser?.short_note}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Menu
                  </button>
                </div> */}
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Menu Image</label>

                    <div>
                      {editUser?.image ? (
                        <img
                          src={editUser?.image}
                          alt="Default Image"
                          className="thumbnails-images"
                        />
                      ) : (
                        <Response_Loading_processing></Response_Loading_processing>
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile1)
                                      }
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div id="content-page" className="content-page">
              <div className="mt-4">
                <div className="admin-section-title">
                  <div className="iq-card">
                    <div>
                      <div className="">
                        <h4 className="modal-title">New Mobile Side Menu</h4>
                      </div>
                      <div>
                        <div className="modal-body">
                          <div className="form-group">
                            <label>Upload Icon Image:</label> <br />
                            <img
                              src={editUser?.image}
                              width="100"
                              height="100"
                            />
                            <input
                              type="file"
                              className="form-control"
                              name="image"
                              id="image"
                            />
                          </div>

                          <div className="form-group">
                            <label>Name:</label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              className="form-control"
                              placeholder="Enter the Name"
                              value={editUser?.name}
                              onChange={handleInput}
                            />
                            <input
                              type="text"
                              id="parent_id"
                              name="parent_id"
                              className="form-control"
                              placeholder="Enter the parent_id"
                              value={editUser?.parent_id}
                              hidden
                              onChange={handleInput}
                            />
                          </div>

                          <div className="form-group">
                            <label>Short Note:</label>
                            <input
                              type="text"
                              id="short_note"
                              name="short_note"
                              className="form-control"
                              placeholder="Enter the Short Note"
                              value={editUser?.short_note}
                              onChange={handleInput}
                            />
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary"
                            id="submit-new-mobilemenu"
                            onClick={handleUpdate}
                          >
                            Update Mobile Menu
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default EditMobileMenu;
