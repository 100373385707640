// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme_image {
    border: 2px solid gainsboro;
    border-radius: 15px;
    padding: 10px;
    margin: 15px;
}

.orderTitle {
    font-size: 17px;
    font-weight: bold;
    line-height: 30px;
}

.orderTitle.active::before {
    position: absolute;
    bottom: -8px;
    width: 50%;
    height: 6px;
    content: "";
    left: 0;
    right: 0;
    border-radius: 10px;
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Settings/Theme.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,mBAAmB;IACnB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,WAAW;IACX,WAAW;IACX,OAAO;IACP,QAAQ;IACR,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".theme_image {\n    border: 2px solid gainsboro;\n    border-radius: 15px;\n    padding: 10px;\n    margin: 15px;\n}\n\n.orderTitle {\n    font-size: 17px;\n    font-weight: bold;\n    line-height: 30px;\n}\n\n.orderTitle.active::before {\n    position: absolute;\n    bottom: -8px;\n    width: 50%;\n    height: 6px;\n    content: \"\";\n    left: 0;\n    right: 0;\n    border-radius: 10px;\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
