import React, { useEffect, useRef, useState } from "react";
import "./AddChannelPartner.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";

function EditChannelPartner(props) {
  const { id } = useParams();

  const channelnameRef = useRef(null);
  const emailRef = useRef(null);
  const imgRef = useRef(null);
  const mobileRef = useRef(null);
  const statusRef = useRef(null);
  const videoRef = useRef(null);

  const [switchTap, setSwitchTap] = useState(0);
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [fileName, setFileName] = useState(null);
  const [videoName, setVideoName] = useState(null);
  const [chequeName, setChequeName] = useState(null);
  const [status, setStatus] = useState(["Pending", "Approve", "Reject"]);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);
  const [previewCheque, setPreviewCheque] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  let defaultvalue = "";

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    const name = e.target.name;
    const sanitizedInput = input.replace(/\D/g, "").slice(0, 10);
    setInputs((prevState) => {
      return { ...prevState, [name]: sanitizedInput };
    });
    if (input.length == 10) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const fetchRole = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel-partner/edit/${id}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.Channel;
        setInputs(result);
      })
      .catch((error) => console.log(error));
  };
  useEffect(async () => {
    fetchRole();
  }, []);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((prevState) => {
      return { ...prevState, [name]: value };
    });
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };
  const handleAccountChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((prevState) => {
      return { ...prevState, [name]: value };
    });
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setFileName(newFile);
        setPreviewImage(reader.result);
        setErrors((prev) => ({ ...prev, image: "" }));
      };
      reader.readAsDataURL(newFile);
    } else {
      setErrors((prev) => ({ ...prev, image: "" }));
    }
  };
  const handleChequeFileChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setChequeName(newFile);
        setPreviewCheque(reader.result);
        setErrors((prev) => ({ ...prev, image: "" }));
      };
      reader.readAsDataURL(newFile);
    } else {
      setErrors((prev) => ({ ...prev, image: "" }));
    }
  };
  const handleClearFile = () => {
    setFileName(null);
    setPreviewImage(null);
    if (imgRef.current) {
      imgRef.current.value = "";
    }
  };
  const handleClearChequeFile = () => {
    setChequeName(null);
    setPreviewCheque(null);
  };

  const handleVideChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setVideoName(newFile);
        setPreviewVideo(reader.result);
      };
      reader.readAsDataURL(newFile);
    }
  };
  const handleClearVideoFile = () => {
    setVideoName(null);
    setPreviewVideo(null);
    if (videoRef.current) {
      videoRef.current.value = "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    var pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!inputs.email) {
      newErrors.email = "Email is required";
      emailRef.current.focus();
    } else {
      if (!pattern.test(inputs.email)) {
        newErrors.email = "Invaild Email Address";
        emailRef.current.focus();
      }
    }
    if (!inputs.channel_name) {
      newErrors.channel_name = "Channel name is required";
      channelnameRef.current.focus();
    }

    if (inputs.mobile_number.length === 1) {
      newErrors.mobile_number = "Enter a valid number max 10 digits";
      mobileRef.current.focus();
    } else if (
      inputs.mobile_number.length > 1 &&
      inputs.mobile_number.length <= 9
    ) {
      newErrors.mobile_number = "Enter a valid number max 10 digits";
      mobileRef.current.focus();
    } else {
      console.log("empty");
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        setProcessing(true);
        setShowOverlay(true);
        const formDatas = new FormData();
        formDatas.append("channel_name", inputs.channel_name);
        formDatas.append("email", inputs.email);
        formDatas.append("mobile_number", inputs.mobile_number);
        formDatas.append("status", inputs.status);
        const response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/channel-partner/update/${id}`,
          formDatas,
          { headers }
        );

        let result = response?.data;
        let imageResponse, videoResponse;

        if (fileName) {
          let formDataForImage = new FormData();
          formDataForImage.append("channel_logo", fileName);
          formDataForImage.append("channel_id", inputs.id);
          imageResponse = await axios.post(
            `${process.env.REACT_APP_Baseurl}/admin/Image-upload-channel`,
            formDataForImage,
            { headers }
          );
        }

        if (videoName) {
          let formDataForVideo = new FormData();
          formDataForVideo.append("channel_id", inputs.id);
          formDataForVideo.append("intro_video", videoName);
          videoResponse = await axios.post(
            `${process.env.REACT_APP_Baseurl}/admin/Intro-video-upload-channel`,
            formDataForVideo,
            { headers }
          );
        }
        if (result) {
          props?.setApiresponsealert(result);
          props?.setShowalert(true);
          setProcessing(false);
          setShowOverlay(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } catch (err) {
        console.error(err);
        var errorMessage = err.response.data.message;
        props?.setApiresponsealert({ status: false, message: errorMessage });
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  const handleAccountUpdate = async (e) => {
    e.preventDefault();
    let updateContentPartnerStatus = false;
    try {
      setProcessing(true);
      setShowOverlay(true);

      const updateFormData = new FormData();
      updateFormData.append("channel_name", inputs.channel_name);
      updateFormData.append("email", inputs.email);
      updateFormData.append("mobile_number", inputs.mobile_number);
      updateFormData.append("status", inputs.status);
      updateFormData.append("bank_name", inputs.bank_name);
      updateFormData.append("branch_name", inputs.branch_name);
      updateFormData.append("account_number", inputs.account_number);
      updateFormData.append("IFSC_Code", inputs.IFSC_Code);
      updateFormData.append("upi_id", inputs.upi_id);
      updateFormData.append("upi_mobile_number", inputs.upi_mobile_number);

      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/channel-partner/update/${id}`,
        updateFormData,
        { headers }
      );
      const result = response.data;
      let formDataForImage = new FormData();
      formDataForImage.append("channel_id", inputs.id);
      formDataForImage.append("cancelled_cheque", chequeName);
      const imageResponse = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Image-upload-channel`,
        formDataForImage,
        { headers }
      );
      const imageResult = imageResponse?.data;
      if (imageResult.status && result.status) {
        updateContentPartnerStatus = true;
        window.location.reload();
      }
      if (updateContentPartnerStatus) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <>
      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="iq-card p-2">
                  <div id="moderator-container " className="p-3">
                    <div className="moderator-section-title">
                      <h4>
                        <i className="entypo-globe"></i> Update Channel Partner
                      </h4>
                    </div>
                    <div className="clear"></div>
                    <hr />
                    <div className="d-flex  gap-5 mb-4">
                      <button
                        className={`tabHeading p-0   ${switchTap === 0 && "active"
                          }`}
                        onClick={() => {
                          setSwitchTap(0);
                        }}
                      >
                        Update
                      </button>
                      <button
                        className={` tabHeading p-0 ${switchTap === 1 && "active"
                          }`}
                        onClick={() => {
                          setSwitchTap(1);
                        }}
                      >
                        Account
                      </button>
                    </div>

                    {switchTap === 0 ? (
                      <form id="Moderator_form" onSubmit={handleSubmit}>
                        <div className="row justify-content-between ">
                          <div className="col-md-6">
                            <div className="form-group row position-relative mb-4">
                              <label
                                for="channel_name"
                                className=" col-form-label text-md-right p-0"
                              >
                                Channel Name
                                <span className="mandatory">*</span>
                              </label>
                              <input
                                id="name"
                                type="text"
                                className="form-control"
                                name="channel_name"
                                autoComplete="channel_name"
                                autofocus
                                placeholder="Enter channel name"
                                onChange={handleChange}
                                ref={channelnameRef}
                                value={inputs.channel_name}
                              />

                              {errors.channel_name && (
                                <span className="errorred errorredspan p-0">
                                  {errors.channel_name}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group row position-relative mb-4">
                              <label
                                for="email"
                                className=" col-form-label text-md-right p-0"
                              >
                                E-Mail Address
                                <span className="mandatory">*</span>
                              </label>
                              <input
                                id="email"
                                type="email"
                                className="form-control "
                                name="email"
                                autoComplete="email"
                                placeholder="Enter your email"
                                onChange={handleChange}
                                ref={emailRef}
                                value={inputs.email}
                              />
                              {errors.email && (
                                <span className="errorred errorredspan p-0">
                                  {errors.email}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group row position-relative mb-4">
                              <label
                                for="mobile_number"
                                className=" col-form-label text-md-right p-0"
                              >
                                Mobile Number
                              </label>
                              <input
                                id="mobile_number"
                                type="text"
                                className="form-control "
                                name="mobile_number"
                                // autoComplete="mobile_number"
                                placeholder="Enter your mobile number"
                                onChange={handleInputChange}
                                ref={mobileRef}
                                value={inputs.mobile_number}
                              />
                              {errors.mobile_number && (
                                <span className="errorred errorredspan p-0">
                                  {errors.mobile_number}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group row position-relative mb-4">
                              <label
                                for="role_id"
                                className=" col-form-label text-md-right p-0"
                              >
                                Status
                                <span className="mandatory">*</span>
                              </label>
                              <select
                                className="form-control"
                                id="status"
                                name="status"
                                onChange={handleChange}
                                ref={statusRef}
                              >
                                {status?.map((status, index) => {
                                  defaultvalue =
                                    inputs.status === index && status;
                                  return (
                                    <option
                                      key={"option" + index + 1}
                                      value={index}
                                      selected={defaultvalue}
                                    >
                                      {status}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.status && (
                                <span className="errorred errorredspan p-0">
                                  {errors.status}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group row position-relative mb-4">
                              <label
                                for="channel_logo"
                                className=" col-form-label text-md-right p-0"
                              >
                                Channel Logo
                                <span className="mandatory">*</span>
                              </label>

                              <img
                                src={inputs?.channel_logo}
                                className="editFormImage"
                              />

                              <div className="d-flex flex-wrap justify-content-between gap-2">
                                <div className="col-sm-5 col-md-12 col-lg-5 col-12 position-relative pb-2">
                                  <div className="imagedrop  mt-1 p-1">
                                    <input
                                      id="channel_logo"
                                      type="file"
                                      accept="image/*"
                                      className="form-control opacity-0 h-100 p-0 m-0"
                                      name="channel_logo"
                                      onChange={(event) =>
                                        handleFileChange(event)
                                      }
                                      ref={imgRef}
                                      key={fileName ? fileName : "inputKey"}
                                    />
                                    <label
                                      type="button"
                                      className="position-absolute"
                                    >
                                      <CameraRetroIcon />
                                    </label>
                                    <p className="text" id="error_picture"></p>
                                    {errors.image && (
                                      <span className="errorred errorredspan p-0">
                                        {errors.image}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                {fileName && (
                                  <div className="col-sm-5 col-12 col-md-12 col-lg-5">
                                    <div
                                      className="imagedrop  dropzone mt-1 p-1"
                                      style={{
                                        backgroundImage: `url(${previewImage && previewImage
                                          })`,
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-close"
                                        onClick={handleClearFile}
                                      ></button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label
                              for="intro_video"
                              className=" col-form-label text-md-right p-0"
                            >
                              Upload your best work ( Intro Video ) :
                            </label>
                            {inputs.intro_video && (
                              <video
                                className="editFormImage"
                                controls
                                src={inputs.intro_video}
                                type="video/mp4"
                              ></video>
                            )}
                            <div className="d-flex flex-wrap justify-content-between gap-2 position-relative">
                              <div className="col-sm-5 col-md-12 col-lg-5 col-12">
                                <div className="imagedrop  mt-1 p-1">
                                  <input
                                    id="intro_video"
                                    type="file"
                                    accept="video/*"
                                    className="form-control opacity-0 h-100 p-0 m-0 "
                                    name="intro_video"
                                    onChange={handleVideChange}
                                  />

                                  <label
                                    type="button"
                                    className="position-absolute"
                                  >
                                    <CameraRetroIcon />
                                  </label>
                                </div>
                              </div>
                              {videoName && (
                                <div className="col-sm-5 col-12 col-md-12 col-lg-5">
                                  <div className="imagedrop  dropzone mt-1 p-1">
                                    <video
                                      src={previewVideo}
                                      className="videoPreview"
                                      controls
                                    ></video>
                                    {videoName && (
                                      <button
                                        type="button"
                                        className="btn btn-close"
                                        onClick={handleClearVideoFile}
                                      ></button>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="form-group row mb-0">
                            <div className="col-md-12 ">
                              <button
                                type="submit"
                                className="btn btn-primary d-block ms-auto"
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    ) : (
                      <form autoComplete="off" onSubmit={handleAccountUpdate}>
                        <div className="row justify-content-between ">
                          <div className="col-md-6 mb-4 position-relative pb-2">
                            <label className="m-0" for="bank_name">
                              Bank Name
                            </label>
                            <input
                              type="text"
                              id="bank_name"
                              name="bank_name"
                              autoComplete="bank_name"
                              value={inputs?.bank_name}
                              className="form-control "
                              placeholder="bank_name"
                              onChange={handleAccountChange}
                            />
                          </div>
                          <div className="col-md-6 mb-4 position-relative pb-2">
                            <label className="m-0">Branch Name</label>
                            <input
                              type="text"
                              id="branch_name"
                              name="branch_name"
                              value={inputs?.branch_name}
                              className="form-control"
                              autoComplete="branch_name"
                              placeholder="branch_name"
                              onChange={handleAccountChange}
                            />
                          </div>
                          <div className="col-md-6 mb-4 position-relative pb-2 ">
                            <label className="m-0" for="account_number">
                              Account Number
                            </label>
                            <input
                              type="text"
                              id="account_number"
                              name="account_number"
                              value={inputs?.account_number}
                              className="form-control"
                              autoComplete="account_number"
                              placeholder="account_number"
                              onChange={handleAccountChange}
                            />
                          </div>
                          <div className="col-md-6 mb-4 position-relative pb-2">
                            <label className="m-0">IFSC Code</label>
                            <input
                              type="text"
                              id="IFSC_Code"
                              name="IFSC_Code"
                              value={inputs?.IFSC_Code}
                              className="form-control"
                              autoComplete="IFSC_Code"
                              placeholder="IFSC_Code"
                              onChange={handleAccountChange}
                            />
                          </div>

                          <div className="col-md-6 mb-4 position-relative pb-2">
                            <label className="m-0">Upi Id</label>
                            <input
                              type="text"
                              id="upi_id"
                              name="upi_id"
                              value={inputs?.upi_id}
                              autoComplete="upi_id"
                              className="form-control"
                              placeholder="upi_id"
                              onChange={handleAccountChange}
                            />
                          </div>
                          <div className="col-md-6 mb-4 position-relative pb-2">
                            <label className="m-0">Upi Mobile Number</label>
                            <input
                              type="text"
                              id="upi_mobile_number"
                              name="upi_mobile_number"
                              value={inputs?.upi_mobile_number}
                              className="form-control"
                              placeholder="upi_mobile_number"
                              autoComplete="upi_mobile_number"
                              onChange={handleAccountChange}
                            />
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-group position-relative">
                              <label className="m-0">Cancelled Cheque</label>
                              {inputs?.cancelled_cheque && (
                                <img
                                  src={inputs?.cancelled_cheque}
                                  className="editFormImage"
                                />
                              )}

                              <div className="d-flex flex-wrap justify-content-between gap-2">
                                <div className="col-sm-5 col-md-12 col-lg-5 col-12 position-relative">
                                  <div className="imagedrop  mt-1 p-1">
                                    <input
                                      id="cancelled_cheque"
                                      name="cancelled_cheque"
                                      type="file"
                                      className="form-control opacity-0 h-100 p-0 m-0 "
                                      accept="image/*"
                                      onChange={(event) =>
                                        handleChequeFileChange(event)
                                      }
                                      key={chequeName ? chequeName : "inputKey"}
                                    />
                                    <label
                                      type="button"
                                      className="position-absolute"
                                    >
                                      <CameraRetroIcon />
                                    </label>
                                  </div>
                                </div>

                                {chequeName && (
                                  <div className="col-sm-5 col-12 col-md-12 col-lg-5">
                                    <div
                                      className="imagedrop  dropzone mt-1 p-1"
                                      style={{
                                        backgroundImage: `url(${previewCheque && previewCheque
                                          })`,
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-close"
                                        onClick={handleClearChequeFile}
                                      ></button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-sm-12 text-end">
                            <input
                              type="submit"
                              value="Update"
                              className="btn btn-primary "
                            />
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default EditChannelPartner;
