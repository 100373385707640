import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Menu.css";
import Response_Processing from "../../../layout/Others/Response_Processing";
import AdminSliderModal from "../../../components/CommonComponents/AdminSliderModal";
import AdminDeleteModal from "../../../components/CommonComponents/AdminDeleteModal";
import AdminNavbar from "../../../components/CommonComponents/AdminNavbar";
import BootstrapTable from "react-bootstrap-table-next";
import { getItemToken } from "../../../Utils/localStorageUtils";
import paginationFactory from "react-bootstrap-table2-paginator";
import NoRecordTable from "../../../components/CommonComponents/NoRecordTable";
import { toast } from "react-toastify";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { ActionFormatterComponent, SliderFormatterComponent } from "../../../components/CommonMethod/CommonBoostrapFormatter";

function Menu() {
  const [apiData, setApiData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [modelClose, setModelClose] = useState(false);
  const [itemToDelete, setItemToDelete] = useState([]);
  const [withOutRefresh, setWithoutRefresh] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const userAccessToken = getItemToken('access_token');
  const [totalSize, setTotalSize] = useState(0);


  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const dateOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    return (<p className="m-0 menuDate">{formattedDate}</p>);
  }

  //#region Bootstrap 

  const columns = [
    {
      dataField: "id",
      text: "Menu Name",
      formatter: (cell, row) =>
        <div className="d-flex align-items-center justify-content-start gap-3">
          <img src={row?.image} className="menuImage object-fit-cover rounded-2" />
          <div>
            <p className="menuName mb-1">{row?.name}</p>
            {formatDate(row?.updated_at)}
          </div>

        </div>,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: (cell, row) => <SliderFormatterComponent cell={cell} row={row} action={handleToggle} />,
    },
    {
      dataField: "Action",
      text: "Action",
      formatter: (cell, row) => <ActionFormatterComponent cell={cell} row={row} link={'/editmenu/'} action={singleDelete} />,
    },
  ];

  const options = {
    pageStartIndex: 1,
    paginationSize: 4,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    totalSize,
    alwaysShowAllBtns: true,
    hideSizePerPage: true,
    withFirstAndLast: false,
    onPageChange: (page) => setCurrentPage(page),
  };

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDelete([...itemToDelete, row.id]);
        } else {
          setItemToDelete(itemToDelete?.filter((id) => id !== row?.id));
        }
      }
    },
    style: { width: '30px' },
    selected: itemToDelete,
    selectionHeaderRenderer: ({ mode, checked }) => (
      <div>
        <input type="checkbox" checked={checked} onChange={handleAll} />
      </div>
    ),
  };

  //#endregion Bootstrap 

  //#region Api

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/menu/index?page=${currentPage}`, { headers: userAccessToken });
      const result = response?.data?.menus?.data
      setApiData(result);
      setFilteredData(result);
      setTotalSize(response?.data?.Total_count)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    setShowOverlay(true);
    try {
      const formData = new FormData();
      formData.append("id", "[" + itemToDelete + "]");
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/menu/multi-delete`, formData, { headers: userAccessToken });
      const result = response?.data;
      if (result?.status) {
        setWithoutRefresh(!withOutRefresh);
        toast.success(result?.message);
        setShowOverlay(false);
        setItemToDelete([])
      }
      else {
        toast.error(result?.message);
        setShowOverlay(false);
        setWithoutRefresh(!withOutRefresh);
        setItemToDelete([])
      }
    } catch (err) {
      var errorMessage = err?.response?.data?.message;
      toast.error(errorMessage);
      setShowOverlay(false);
    }
  };

  const confirmToggle = async () => {
    setShowOverlay(true);
    setShowModal(false);
    try {
      const formData = new FormData();
      const newStatus = currentItem?.active === 1 ? 0 : 1;
      formData.append("menu_id", currentItem?.id);
      formData.append("active", newStatus,);
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/active-menu`, formData, { headers: userAccessToken });
      const result = response.data
      if (result.status) {
        setWithoutRefresh(!withOutRefresh);
        toast.success(result?.message);
        setShowOverlay(false);
        setCurrentItem(null);
      }
      else {
        toast.error(result?.message);
        setShowOverlay(false);
      }
    } catch (err) {
      var errorMessage = err?.response?.data?.message;
      toast.error(errorMessage);
      setShowOverlay(false);
    }
  };

  //#endregion Api

  //#region Method

  const handleToggle = async (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setModelClose(!closeModal);
    setShowModal(false);
  };

  const openModal = () => {
    setModelClose(true);
  };

  const singleDelete = (id) => {
    setModelClose(true);
    setItemToDelete([id]);
  };

  const handleDeletedata = () => {
    handleConfirmDelete();
    closeModal();
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDelete(selectAll ? [] : filteredData?.map((item) => item?.id));
  };

  //#endregion Method


  useEffect(async () => {
    fetchData();
  }, [withOutRefresh, currentPage]);

  return (
    <>
      <div className="">
        {showOverlay && <Response_Processing />}
        {showModal && <AdminSliderModal modelClose={showModal} sliderClose={closeModal} sliderAction={confirmToggle} buttonText={currentItem?.active} />}
        {modelClose && <AdminDeleteModal modelClose={modelClose} deleteClose={closeModal} deleteAction={handleDeletedata} buttonText={itemToDelete?.length} />}
        <AdminNavbar heading={'All Menus'} totalDataLength={apiData?.length} deleteCount={itemToDelete?.length} navigation={'/addmenu'} deleteMethod={openModal} navText={'Add Menu'} />
        <div className="theme-bg-color p-3 rounded-2 bootstrapTable  multi-delete-width menuTable" >
          <BootstrapTable keyField="id" pagination={paginationFactory(options)} columns={columns} data={filteredData} className="no-border-table theme-text-color " noDataIndication={<NoRecordTable result={filteredData?.length} loader={loading} />} hover headerClasses="" selectRow={selectRow} selectAll={selectAll} remote onTableChange={(type, { page }) => setCurrentPage(page)} />
        </div>
      </div>

    </>
  );
}

export default Menu;
