// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminSideBarLeft {
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 990;
    height: 100vh;
    width: 275px;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.adminSideBarRight {
    margin-left: auto;
    width: calc(100% - 275px);
}

.adminSideBarLeft::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #424242;
}

::-webkit-scrollbar {
    width: 6px;
}

@media screen and (max-width:991px) {
    .adminSideBarRight {
        width: 100%;
    }

    .adminSideBarLeft {
        left: -100%;
        transition: left 0.4s linear;
        width: 100%;
    }

    .adminSideBarLeft.active {
        left: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/layout/layout.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,OAAO;IACP,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,WAAW;QACX,4BAA4B;QAC5B,WAAW;IACf;;IAEA;QACI,OAAO;IACX;AACJ","sourcesContent":[".adminSideBarLeft {\n    position: fixed;\n    top: 0px;\n    left: 0;\n    z-index: 990;\n    height: 100vh;\n    width: 275px;\n    overflow-y: scroll;\n    scroll-behavior: smooth;\n}\n\n.adminSideBarRight {\n    margin-left: auto;\n    width: calc(100% - 275px);\n}\n\n.adminSideBarLeft::-webkit-scrollbar {\n    width: 2px;\n}\n\n::-webkit-scrollbar-thumb {\n    border-radius: 10px;\n    background: #424242;\n}\n\n::-webkit-scrollbar {\n    width: 6px;\n}\n\n@media screen and (max-width:991px) {\n    .adminSideBarRight {\n        width: 100%;\n    }\n\n    .adminSideBarLeft {\n        left: -100%;\n        transition: left 0.4s linear;\n        width: 100%;\n    }\n\n    .adminSideBarLeft.active {\n        left: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
