import React, { useState, useEffect, useRef } from "react";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import JoditEditor from "jodit-react";
import circle from "../../../components/Images/circle.svg";
import { useNavigate, useParams } from "react-router-dom";
import "./EditLandingPages.css";

const EditLandingPages = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [editUser, setEditUser] = useState({
    landing_page_id: "",
    title: "",
    slug: "",
    custom_css: "",
    script_content: "",
    bootstrap_link: "",
    content: "",
    header: false,
    footer: false,
  });
  const [editname, setEditname] = useState({
    title: "",
  });
  // console.log(editUser)
  // const [avatar, setAvatar] = useState({});
  // const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);

  const [loading, setLoading] = useState(true);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/landing-page/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.section_1[0];
      var results = resData?.section_1;
      // setEditLanding(res);
      setIsChecked(res?.header);
      setIsChecked1(res?.footer);
      setEditUser(res);
      setEditname(res);
      setLoading(false);
      // console.log("result", res);
    };
    getUser();
  }, [id]);

  const handleChangeFirstdes = (e) => {
    setEditUser({ ...editUser, content: e });
    // console.log(e, "onchnageddddd");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const handleChecked = (event) => {
    setEditUser({
      ...editUser,
      header: event.value,
    });
    setIsChecked(!isChecked);
  };
  // console.log(isChecked, "!checking.....");

  const handleChecked1 = (event) => {
    setEditUser({
      ...editUser,
      footer: event.value,
    });
    setIsChecked1(!isChecked1);
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessagecontent, setValidationMessagecontent] = useState("");
  const [isValidationHiddencontent, setIsValidationHiddencontent] =
    useState(true);

  const titleInputRef = useRef(null);
  const contentInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitle("Name cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (editUser?.content === "") {
      setValidationMessagecontent("Page Content cannot be empty.");
      setIsValidationHiddencontent(false);
      focusInputRef = contentInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      landing_page_id: editUser?.landing_page_id,
      title: editUser?.title,
      slug: editUser?.slug,
      custom_css: editUser?.custom_css,
      script_content: editUser?.script_content,
      bootstrap_link: editUser?.bootstrap_link,
      header: isChecked === true ? 1 : 0,
      footer: isChecked1 === true ? 1 : 0,
      section_1: editUser?.content,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/landing-page/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      } else if (resjson.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      setValidationMessagetitle("");
      setValidationMessagecontent("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      props.setApiresponsealert(resultapifalse);
      props.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setIsValidationHiddencontent(false);
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Page</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Page
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>{editname?.title}</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Title<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="username"
                        name="title"
                        placeholder="Enter Name"
                        onChange={handleInput}
                        value={editUser?.title}
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Slug</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="username"
                        name="slug"
                        placeholder="Enter Slug"
                        onChange={handleInput}
                        value={editUser?.slug}
                      />
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">
                      Page Content<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <JoditEditor
                        className="rs-input"
                        value={editUser?.content}
                        onChange={(e) => {
                          handleChangeFirstdes(e);
                        }}
                        ref={contentInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddencontent && (
                        <p>{validationMessagecontent}</p>
                      )}
                    </span>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Page
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Contents</h5>
                  <hr></hr>

                  <div className="text-start">
                    <label className="m-0">
                      Custom CSS{" "}
                      <div className="tooltip-container">
                        <a className="iq-bg-warning" href="#">
                          <img
                            src={circle}
                            alt="flogo"
                            width={16}
                            height={16}
                          />
                          <span className="tooltip-text">
                            Please add custom CSS as <br /> &lt;style&gt; .....
                            &lt;/style&gt;
                          </span>
                        </a>
                      </div>
                    </label>

                    <div className="mt-2">
                      <textarea
                        className="rs-input form-control-lg"
                        name="custom_css"
                        rows="3"
                        value={editUser?.custom_css}
                        onChange={handleInput}
                      ></textarea>
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Bootstrap & Script Link{" "}
                      <div className="tooltip-container">
                        <a className="iq-bg-warning" href="#">
                          <img
                            src={circle}
                            alt="flogo"
                            width={16}
                            height={16}
                          />
                          <span className="tooltip-text">
                            Please add Bootstrap or <br /> Script Link as <br />{" "}
                            &lt;links&gt; ..... &lt;links&gt; or
                            <br /> &lt;scripts&gt; .... &lt;scripts&gt;
                          </span>
                        </a>
                      </div>
                    </label>

                    <div className="mt-2">
                      <textarea
                        className="rs-input form-control-lg"
                        id="exampleFormControlTextarea2"
                        rows="3"
                        name="bootstrap_link"
                        value={editUser?.bootstrap_link}
                        onChange={handleInput}
                      ></textarea>
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Custom Script Content{" "}
                      <div className="tooltip-container">
                        <a className="iq-bg-warning" href="#">
                          <img
                            src={circle}
                            alt="flogo"
                            width={16}
                            height={16}
                          />
                          <span className="tooltip-text">
                            Please add Script Content <br /> Link as
                            &lt;links&gt; ..... &lt;links&gt;{" "}
                          </span>
                        </a>
                      </div>
                    </label>
                    <div className="mt-2">
                      <textarea
                        className="rs-input form-control-lg"
                        id="exampleFormControlTextarea3"
                        rows="3"
                        name="script_content"
                        value={editUser?.script_content}
                        onChange={handleInput}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Status Settings</h5>
                  <hr></hr>

                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-body d-flex justify-content-between">
                      <label className="">Enable this page Header </label>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="header"
                            type="checkbox"
                            value={editUser?.header}
                            checked={isChecked}
                            onChange={handleChecked}
                          />

                          <span className="slider round" name="active"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-primary mt-2" data-collapsed="0">
                    <div className="panel-body d-flex justify-content-between">
                      <label className="">Enable this page Footer </label>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="footer"
                            value={editUser?.footer}
                            type="checkbox"
                            checked={isChecked1}
                            onChange={handleChecked1}
                          />
                          <span
                            className="slider round"
                            name="active"
                            // value={item.id}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    </>
  );
};

export default EditLandingPages;
