const getMenuDatalabel = async () => {
    const access_token = localStorage.getItem("access_token");

    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    try {
        const reqData = await fetch(
            `${process.env.REACT_APP_Baseurl}/admin/Menu-Label/index`,
            { headers: headers }
        );
        const resData = await reqData.json();
        return resData;
    } catch (error) {
        console.error("Error fetching menu data:", error);
        return [];
    }
};

export default getMenuDatalabel;
