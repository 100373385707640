import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./EditContentPartner.css";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";

const EditContentPartner = (props) => {
  const { id } = useParams();
  const [switchTap, setSwitchTap] = useState(0);

  const usernameRef = useRef(null);
  const emailRef = useRef(null);
  const roleRef = useRef(null);
  const imgRef = useRef(null);
  const mobileRef = useRef(null);
  const statusRef = useRef(null);

  const [role, setRole] = useState();
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [fileName, setFileName] = useState(null);
  const [status, setStatus] = useState(["Pending", "Approve", "Reject"]);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [chequeFileName, setChequeFileName] = useState(null);
  const [chequePreviewImage, setChequePreviewImage] = useState(null);

  let defaultvalue = "";

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((prevState) => {
      return { ...prevState, [name]: value };
    });
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };
  const handleAccountInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((prevState) => {
      return { ...prevState, [name]: value };
    });
  };
  const handleInputChange = (e) => {
    const input = e.target.value;
    const name = e.target.name;
    const sanitizedInput = input.replace(/\D/g, "").slice(0, 10);
    setInputs((prevState) => {
      return { ...prevState, [name]: sanitizedInput };
    });
    if (input.length == 10) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setFileName(newFile);
        setPreviewImage(reader.result);
        setErrors((prev) => ({ ...prev, image: "" }));
      };
      reader.readAsDataURL(newFile);
    } else {
      setErrors((prev) => ({ ...prev, image: "" }));
    }
  };
  const handleAccountFileChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setChequeFileName(newFile);
        setChequePreviewImage(reader.result);
        setErrors((prev) => ({ ...prev, image: "" }));
      };
      reader.readAsDataURL(newFile);
    } else {
      setErrors((prev) => ({ ...prev, image: "" }));
    }
  };

  const handleClearFile = () => {
    setFileName(null);
    setPreviewImage(null);
    if (imgRef.current) {
      imgRef.current.value = "";
    }
  };
  const handleClearCheque = () => {
    setChequeFileName(null);
    setChequePreviewImage(null);
  };
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchEdit = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/content-partner/edit/${id}`,
        { headers: headers }
      )
      .then((response) => {
        var result = response?.data;
        setInputs(result?.ContentPartner);
        setRole(result?.ContentPartnerRoles);
      })
      .catch((error) => {
        console.error("Error fetching data from the  API:", error);
      });
  };
  useEffect(async () => {
    fetchEdit();
  }, []);
  const handleUpdate = async (e) => {
    e.preventDefault();
    let updateContentPartnerStatus = false;
    const newErrors = {};

    var pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!inputs?.email) {
      newErrors.email = "Email is required";
      emailRef.current.focus();
    } else {
      if (!pattern.test(inputs?.email)) {
        newErrors.email = "Invaild Email Address";
        emailRef.current.focus();
      }
    }
    if (!inputs?.username) {
      newErrors.username = "Username is required";
      usernameRef.current.focus();
    }
    if (inputs?.mobile_number?.length === 1) {
      newErrors.mobile_number = "Enter a valid number max 10 digits";
      mobileRef.current.focus();
    } else if (
      inputs.mobile_number.length > 1 &&
      inputs.mobile_number.length <= 9
    ) {
      newErrors.mobile_number = "Enter a valid number max 10 digits";
      mobileRef.current.focus();
    } else {
      console.log("empty");
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        setProcessing(true);
        setShowOverlay(true);

        const updateFormData = new FormData();
        updateFormData.append("username", inputs?.username);
        updateFormData.append("email", inputs?.email);
        updateFormData.append("mobile_number", inputs?.mobile_number);
        updateFormData.append("role_id", inputs?.user_role);
        updateFormData.append("status", inputs?.status);

        const response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/content-partner/update/${id}`,
          updateFormData,
          { headers }
        );
        const result = response?.data;
        let formDataForImage = new FormData();
        formDataForImage.append("ContentPartner_id", inputs?.id);
        formDataForImage.append("picture", fileName);
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-content`,
          formDataForImage,
          { headers }
        );
        const imageResult = imageResponse?.data;
        if (imageResult?.status && result?.status) {
          updateContentPartnerStatus = true;
          window.location.reload();
        }
        if (updateContentPartnerStatus) {
          props?.setApiresponsealert(result);
          props?.setShowalert(true);
          setProcessing(false);
          setShowOverlay(false);
        }
      } catch (err) {
        console.error(err);
        var errorMessage = err.response.data.message;
        props?.setApiresponsealert({ status: false, message: errorMessage });
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };
  const handleAccountUpdate = async (e) => {
    e.preventDefault();
    let updateContentPartnerStatus = false;
    try {
      setProcessing(true);
      setShowOverlay(true);

      const updateFormData = new FormData();
      updateFormData.append("username", inputs?.username);
      updateFormData.append("email", inputs?.email);
      updateFormData.append("mobile_number", inputs?.mobile_number);
      updateFormData.append("role_id", inputs?.user_role);
      updateFormData.append("bank_name", inputs?.bank_name);
      updateFormData.append("branch_name", inputs?.branch_name);
      updateFormData.append("account_number", inputs?.account_number);
      updateFormData.append("IFSC_Code", inputs?.IFSC_Code);
      updateFormData.append("upi_id", inputs?.upi_id);
      updateFormData.append("upi_mobile_number", inputs?.upi_mobile_number);

      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/content-partner/update/${id}`,
        updateFormData,
        { headers }
      );
      const result = response.data;
      let formDataForImage = new FormData();
      formDataForImage.append("ContentPartner_id", inputs?.id);
      formDataForImage.append("cancelled_cheque", chequeFileName);
      const imageResponse = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Image-upload-content`,
        formDataForImage,
        { headers }
      );
      const imageResult = imageResponse?.data;
      if (imageResult?.status && result?.status) {
        updateContentPartnerStatus = true;
        window.location.reload();
      }
      if (updateContentPartnerStatus) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <div>
      <div>{showOverlay && <Response_Processing></Response_Processing>}</div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card p-2 mt-3">
              <div className="p-3">
                <h4>
                  <i className="entypo-archive"></i> Update Moderator Users
                </h4>
                <hr />
                <div className="d-flex  gap-5 mb-5">
                  <button
                    className={`tabHeading p-0   ${
                      switchTap === 0 && "active"
                    }`}
                    onClick={() => {
                      setSwitchTap(0);
                    }}
                  >
                    Update
                  </button>
                  <button
                    className={` tabHeading p-0 ${switchTap === 1 && "active"}`}
                    onClick={() => {
                      setSwitchTap(1);
                    }}
                  >
                    Account
                  </button>
                </div>

                {switchTap === 0 ? (
                  <form onSubmit={handleUpdate} autoComplete="off" key={"1"}>
                    <div className="row">
                      <div className="col-md-6 mb-4 position-relative pb-2">
                        <label className="m-0" for="username">
                          {" "}
                          User Name
                          <span className="mandatory">*</span>
                        </label>

                        <input
                          type="text"
                          id="username"
                          name="username"
                          value={inputs?.username}
                          className="form-control "
                          placeholder="Enter the username"
                          onChange={handleChange}
                          ref={usernameRef}
                        />
                        {errors?.username && (
                          <span className="errorred errorredspan p-0">
                            {errors?.username}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6 mb-4 position-relative pb-2">
                        <label className="m-0">
                          {" "}
                          E-Mail Address
                          <span className="mandatory">*</span>
                        </label>

                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={inputs?.email}
                          className="form-control"
                          placeholder="Enter the email address"
                          onChange={handleChange}
                          ref={emailRef}
                        />
                        {errors?.email && (
                          <span className="errorred errorredspan p-0">
                            {errors?.email}
                          </span>
                        )}
                      </div>

                      <div className="col-md-6 mb-4 position-relative pb-2 ">
                        <label className="m-0" for="mobile_number">
                          {" "}
                          Mobile Number
                        </label>

                        <input
                          type="text"
                          id="mobile_number"
                          name="mobile_number"
                          value={inputs?.mobile_number}
                          className="form-control"
                          placeholder="Enter phone number"
                          onChange={handleInputChange}
                          ref={mobileRef}
                        />
                        {errors?.mobile_number && (
                          <span className="errorred errorredspan p-0">
                            {errors?.mobile_number}
                          </span>
                        )}
                      </div>

                      <div className="col-md-6 mb-4">
                        <label className="m-0">
                          User Roles <span className="mandatory">*</span>
                        </label>

                        <select
                          id="user_role"
                          name="user_role"
                          className="form-control"
                          onChange={handleChange}
                          ref={roleRef}
                        >
                          {role?.map((rolename, index) => {
                            if (inputs?.user_role) {
                              defaultvalue =
                                inputs?.user_role === rolename?.id &&
                                rolename?.role_name;
                            } else {
                              console.log("inputs.user_role is not an array.");
                            }

                            return (
                              <>
                                {" "}
                                <option
                                  key={"option" + index + 1}
                                  value={rolename?.id}
                                  selected={defaultvalue}
                                >
                                  {rolename?.role_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-group position-relative">
                          <label>
                            Picture <span className="mandatory">*</span>
                          </label>
                          <img
                            src={inputs?.picture}
                            className="editFormImage"
                          />
                          <div className="d-flex flex-wrap justify-content-between gap-2">
                            <div className="col-sm-5 col-md-12 col-lg-5 col-12 position-relative">
                              <div className="imagedrop  mt-1 p-1">
                                <input
                                  id="picture"
                                  type="file"
                                  className="form-control opacity-0 h-100 p-0 m-0 "
                                  name="picture"
                                  accept="image/*"
                                  onChange={(event) => handleFileChange(event)}
                                  ref={imgRef}
                                  key={fileName ? fileName : "inputKey"}
                                />
                                <label
                                  type="button"
                                  className="position-absolute"
                                >
                                  <CameraRetroIcon />
                                </label>
                              </div>

                              <p className="text" id="error_picture"></p>
                              {errors?.image && (
                                <span className="errorred errorredspan p-0">
                                  {errors?.image}
                                </span>
                              )}
                            </div>

                            {fileName && (
                              <div className="col-sm-5 col-12 col-md-12 col-lg-5">
                                <div
                                  className="imagedrop  dropzone mt-1 p-1"
                                  style={{
                                    backgroundImage: `url(${
                                      previewImage && previewImage
                                    })`,
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-close"
                                    onClick={handleClearFile}
                                  ></button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="m-0">Approve/Reject</label>
                        <select
                          className="form-control"
                          id="status"
                          name="status"
                          onChange={handleChange}
                          ref={statusRef}
                        >
                          {status?.map((status, index) => {
                            defaultvalue = inputs?.status === index && status;
                            return (
                              <option
                                key={status}
                                value={index}
                                selected={defaultvalue}
                              >
                                {status}
                              </option>
                            );
                          })}
                        </select>
                        {errors?.status && (
                          <span className="errorred errorredspan p-0">
                            {errors?.status}
                          </span>
                        )}
                      </div>
                    </div>

                    <hr />

                    <div className="row mt-3">
                      <div className="col-sm-12 text-end">
                        <input
                          type="submit"
                          value="Update"
                          className="btn btn-primary "
                        />
                      </div>
                    </div>
                  </form>
                ) : (
                  <form
                    onSubmit={handleAccountUpdate}
                    autoComplete="off"
                    key={"2"}
                  >
                    <div className="row">
                      <div className="col-md-6 mb-4 position-relative pb-2">
                        <label className="m-0" for="bank_name">
                          Bank Name
                        </label>
                        <input
                          type="text"
                          id="bank_name"
                          name="bank_name"
                          autoComplete="bank_name"
                          value={inputs?.bank_name}
                          className="form-control "
                          placeholder="bank_name"
                          onChange={handleAccountInputChange}
                        />
                      </div>
                      <div className="col-md-6 mb-4 position-relative pb-2">
                        <label className="m-0">Branch Name</label>
                        <input
                          type="text"
                          id="branch_name"
                          name="branch_name"
                          value={inputs?.branch_name}
                          className="form-control"
                          autoComplete="branch_name"
                          placeholder="branch_name"
                          onChange={handleAccountInputChange}
                        />
                      </div>
                      <div className="col-md-6 mb-4 position-relative pb-2 ">
                        <label className="m-0" for="account_number">
                          Account Number
                        </label>
                        <input
                          type="text"
                          id="account_number"
                          name="account_number"
                          value={inputs?.account_number}
                          className="form-control"
                          autoComplete="account_number"
                          placeholder="account_number"
                          onChange={handleAccountInputChange}
                        />
                      </div>
                      <div className="col-md-6 mb-4 position-relative pb-2">
                        <label className="m-0">IFSC Code</label>
                        <input
                          type="text"
                          id="IFSC_Code"
                          name="IFSC_Code"
                          value={inputs?.IFSC_Code}
                          className="form-control"
                          autoComplete="IFSC_Code"
                          placeholder="IFSC_Code"
                          onChange={handleAccountInputChange}
                        />
                      </div>

                      <div className="col-md-6 mb-4 position-relative pb-2">
                        <label className="m-0">Upi Id</label>
                        <input
                          type="text"
                          id="upi_id"
                          name="upi_id"
                          value={inputs?.upi_id}
                          autoComplete="upi_id"
                          className="form-control"
                          placeholder="upi_id"
                          onChange={handleAccountInputChange}
                        />
                      </div>
                      <div className="col-md-6 mb-4 position-relative pb-2">
                        <label className="m-0">Upi Mobile Number</label>
                        <input
                          type="text"
                          id="upi_mobile_number"
                          name="upi_mobile_number"
                          value={inputs?.upi_mobile_number}
                          className="form-control"
                          placeholder="upi_mobile_number"
                          autoComplete="upi_mobile_number"
                          onChange={handleAccountInputChange}
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-group position-relative">
                          <label className="m-0">Cancelled Cheque</label>
                          {inputs?.cancelled_cheque && (
                            <img
                              src={inputs?.cancelled_cheque}
                              className="editFormImage"
                            />
                          )}

                          <div className="d-flex flex-wrap justify-content-between gap-2">
                            <div className="col-sm-5 col-md-12 col-lg-5 col-12 position-relative">
                              <div className="imagedrop  mt-1 p-1">
                                <input
                                  id="cancelled_cheque"
                                  name="cancelled_cheque"
                                  type="file"
                                  className="form-control opacity-0 h-100 p-0 m-0 "
                                  accept="image/*"
                                  onChange={(event) =>
                                    handleAccountFileChange(event)
                                  }
                                  key={
                                    chequeFileName ? chequeFileName : "inputKey"
                                  }
                                />
                                <label
                                  type="button"
                                  className="position-absolute"
                                >
                                  <CameraRetroIcon />
                                </label>
                              </div>
                            </div>

                            {chequeFileName && (
                              <div className="col-sm-5 col-12 col-md-12 col-lg-5">
                                <div
                                  className="imagedrop  dropzone mt-1 p-1"
                                  style={{
                                    backgroundImage: `url(${
                                      chequePreviewImage && chequePreviewImage
                                    })`,
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-close"
                                    onClick={handleClearCheque}
                                  ></button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    <hr />
                    <div className="row mt-3">
                      <div className="col-sm-12 text-end">
                        <input
                          type="submit"
                          value="Update"
                          className="btn btn-primary "
                        />
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditContentPartner;
