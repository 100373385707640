import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdDelete } from "react-icons/md";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";

function ManageLiveCategories(props) {
  const [getLiveCategory, setLiveCategory] = useState([]);
  const [itemToDeleteLiveCategory, setItemToDeleteLiveCategory] = useState([]);
  const [selectAllLiveCategory, setSelectAllLiveCategory] = useState(false);
  const [modalCloseLiveCategory, setModalCloseLiveCategory] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [loading, setLoading] = useState(true);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchDatalivevideo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream-category/index`,
        { headers: headers }
      );
      setLiveCategory(response?.data?.AllLiveCategory);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(async () => {
    fetchDatalivevideo();
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(getLiveCategory);
    const [movedItem] = reorderedItems?.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setLiveCategory(reorderedItems);
    saveShuffleOrder(reorderedItems);
  };

  const saveShuffleOrder = async (updatedItems) => {
    setProcessing(true);
    setShowOverlay(true);

    if (!updatedItems || updatedItems?.length === 0) {
      console.error("Menu items are empty");
      return;
    }

    const positions = updatedItems?.map((menuItem) => menuItem?.id);
    const ids = updatedItems?.map((_, index) => index + 1);

    const requestData = {
      id: JSON.stringify(positions),
      position: JSON.stringify(ids),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream-category/order-live-category`,
        requestData,
        { headers: headers }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const handleSelectAllLiveCategory = () => {
    setSelectAllLiveCategory(!selectAllLiveCategory);
    setItemToDeleteLiveCategory(
      selectAllLiveCategory ? [] : getLiveCategory?.map((item) => item?.id)
    );
  };

  const handleMultiSelectLiveCategory = (itemId) => {
    if (itemToDeleteLiveCategory?.includes(itemId)) {
      setItemToDeleteLiveCategory(
        itemToDeleteLiveCategory?.filter((id) => id !== itemId)
      );
    } else {
      setItemToDeleteLiveCategory([...itemToDeleteLiveCategory, itemId]);
    }
  };

  const singleDeleteLiveCategory = (id) => {
    setItemToDeleteLiveCategory([id]);
    setModalCloseLiveCategory(true);
    setSelectAllLiveCategory(false);
  };

  const closeLiveCategoryModal = () => {
    setModalCloseLiveCategory(!modalCloseLiveCategory);
  };

  const openModalMultiDeleteLiveCategory = () => {
    setModalCloseLiveCategory(true);
  };

  const handleDeleteLiveCategoryData = () => {
    closeLiveCategoryModal();
    liveCategoryDeleteOperation();
  };

  const liveCategoryDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteLiveCategory + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream-category/multi-delete
        `,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <>
      <div
        className={`modal  packageModel ${modalCloseLiveCategory ? "show" : ""
          }`}
        tabIndex="-1"
        style={{ display: modalCloseLiveCategory ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeLiveCategoryModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteLiveCategory?.length === 1 ? (
                <p className="fs-6">
                  Are you sure you want to delete this live stream category?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected live stream
                  category ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={closeLiveCategoryModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteLiveCategoryData}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-6">
              <h4>
                Live Categories{" "}
                <span className="fs-6">
                  (Display all {getLiveCategory?.length} Live Categories)
                </span>
              </h4>
            </div>

            <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
              {itemToDeleteLiveCategory?.length > 0 && (
                <button
                  type="button"
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModalMultiDeleteLiveCategory}
                >
                  <MdDelete className="exportIcon " />
                  Delete
                </button>
              )}
              <Link
                to="/livestream-add-categories"
                className="btn btn-primary fw-bolder"
              >
                <i className="fa fa-plus-circle"></i> Add Categories
              </Link>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="bootstrapTable ">
              <div className="col-12 p-0">
                <div className="row m-0 p-0">
                  <div className="col-lg-2 col-4 data">
                    {" "}
                    <label>
                      <input
                        type="checkbox"
                        checked={selectAllLiveCategory}
                        onChange={handleSelectAllLiveCategory}
                      />
                    </label>
                  </div>
                  <div className="col-lg-4 col-4 data">
                    {" "}
                    <div>Live Stream Categories</div>
                  </div>
                  <div className="col-lg-3 col-4 data">
                    {" "}
                    <div>Uploaded by</div>
                  </div>
                  <div className="col-lg-3 col-4 data">
                    {" "}
                    <div>Actions</div>
                  </div>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="menu-items">
                    {(provided) => (
                      <ul {...provided.droppableProps} ref={provided.innerRef}>
                        {loading ? (
                          <div className="noRecord">
                            {" "}
                            <Response_Loading_processing></Response_Loading_processing>
                            Loading...
                          </div>
                        ) : getLiveCategory?.length <= 0 ? (
                          <div className="noRecord">
                            {" "}
                            <p>No Live Streams Available. </p>
                          </div>
                        ) : (
                          getLiveCategory?.map((item, index) => (
                            <Draggable
                              key={item?.id.toString()}
                              draggableId={item?.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`theme-bg-color orderborder ${item?.order
                                    } index ${index + 1}w-100`}
                                >
                                  <div className="row m-0 p-0 align-items-center">
                                    <div className="col-lg-2 col-4 data_th">
                                      <input
                                        type="checkbox"
                                        checked={itemToDeleteLiveCategory.includes(
                                          item?.id
                                        )}
                                        onChange={() =>
                                          handleMultiSelectLiveCategory(
                                            item?.id
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col-lg-4 col-4 data_th">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <img
                                          src={item?.image_url}
                                          className="profile"
                                        />
                                        <div className="rightVideoTitle">
                                          <p>{item?.name}</p>
                                          <span className="dateText">
                                            {item?.upload_on}
                                          </span>
                                          <div>
                                            {item?.slug !== null ? (
                                              <div className="text-start">
                                                <label className="m-0">
                                                  <div className="tooltip-container">
                                                    <p className="iq-bg-warning">
                                                      <Link
                                                        to={`${process.env.REACT_APP_Baseurl_Frontend}/live/category/${item?.slug}`}
                                                      >
                                                        <i
                                                          className="fa fa-share-alt"
                                                          aria-hidden="true"
                                                        ></i>
                                                      </Link>
                                                      <span className="tooltip-text">
                                                        View
                                                      </span>
                                                    </p>
                                                  </div>
                                                </label>
                                              </div>
                                            ) : (
                                              <>
                                                <div className="text-start">
                                                  <label className="m-0">
                                                    <div className="tooltip-container">
                                                      <p className="iq-bg-warning">
                                                        <i
                                                          className="fa fa-share-alt"
                                                          aria-hidden="true"
                                                          disabled
                                                        ></i>
                                                        <span className="tooltip-text">
                                                          Diable View
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </label>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>{" "}
                                    <div className="col-lg-3 col-4 data_th">
                                      <div>
                                        {" "}
                                        {item?.uploaded_by === "1"
                                          ? "Admin"
                                          : item?.uploaded_by === "4"
                                            ? "Channel Partner"
                                            : item?.uploaded_by === "5"
                                              ? "Content Partner"
                                              : ""}
                                      </div>
                                    </div>
                                    <div className="col-lg-3 col-4 data_th">
                                      <div className="editdropdown ">
                                        <span className="editdropdown-button">
                                          <span>
                                            <i
                                              className="fa fa-ellipsis-v"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </span>
                                        <div className="editdropdown-menu ">
                                          <Link
                                            to={
                                              "/livestream-edit-categories/" +
                                              item?.id
                                            }
                                          >
                                            <span>
                                              <img
                                                src={edititem}
                                                alt="flogo"
                                                width={20}
                                                height={20}
                                              />
                                              <span className="ms-2">
                                                Edit Categories
                                              </span>
                                            </span>
                                          </Link>
                                          <div
                                            onClick={() =>
                                              singleDeleteLiveCategory(item?.id)
                                            }
                                            className="commonActionPadding"
                                          >
                                            <img
                                              src={deleteitem}
                                              alt="flogo"
                                              width={20}
                                              height={20}
                                            />
                                            <span className="ms-2">
                                              Delete Categories
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ManageLiveCategories;
