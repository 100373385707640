import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { CiShare1 } from "react-icons/ci";
import { Link } from "react-router-dom";
import { FaEllipsisVertical } from "react-icons/fa6";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { toast } from "react-toastify";
import Response_Processing from "../../layout/Others/Response_Processing";
import AdminHeader from "../../components/CommonComponents/AdminHeader";
import AdminSliderModal from "../../components/CommonComponents/AdminSliderModal";
import AdminDeleteModal from "../../components/CommonComponents/AdminDeleteModal";
import NoRecordTable from "../../components/CommonComponents/NoRecordTable";
import AdminNavbar from "../../components/CommonComponents/AdminNavbar";
import { adminApiHeader, videoDefaultURL } from "../../components/CommonApis/CommonApiUrl";
import { ActionFormatterComponent } from "../../components/CommonMethod/CommonBoostrapFormatter";


function FAQ(props) {
  const [apiData, setApiData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [modelClose, setModelClose] = useState(false);
  const [itemToDelete, setItemToDelete] = useState([]);
  const [withOutRefresh, setWithoutRefresh] = useState(false)
  const [genreapi, setGenreapi] = useState([]);
  const [allgenre, setallgenre] = useState("");

  const videoFormatter = (cell, row, link) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="rightVideoTitle">
          <Link className="theme-text-color table-heading d-flex  align-items-center" target="_blank" to={`${process.env.REACT_APP_Baseurl_Frontend}/${link}/${row.slug ? row?.slug : row?.artist_slug}`}> <span>{`${row.question ? row?.question?.slice(0, 20) : row?.question ? row?.question?.slice(0, 20) : row?.question?.slice(0, 20)}...`} </span> </Link>
          {row?.duration != "0" ? <span className="me-1  dateText theme-text-color table-subheading"> {row?.duration}</span> : ""}
          <span className="dateText table-subheading theme-text-color">{row?.upload_on}</span>
        </div>
      </div>
    );
  };
  const statusFormatter = (cell, row, link) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="rightVideoTitle">
          <Link className="theme-text-color table-heading d-flex  align-items-center" target="_blank"> <span>{`${row.answer ? row?.answer?.slice(0, 15) : row?.answer ? row?.answer?.slice(0, 15) : row?.answer?.slice(0, 15)}...`} </span> </Link>
        </div>
      </div>
    );
  };

  //Bootstrap Table util start

  const columns = [
    {
      dataField: "FAQ",
      text: "FAQ QUESTION",
      formatter: videoFormatter,
      sort: true,
      headerClasses: 'video-width-table'
    },
    {
      dataField: "active",
      text: "FAQ ANSWER",
      formatter: statusFormatter,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: (cell, row) => <ActionFormatterComponent cell={cell} row={row} link={"/edit-faq/"} action={singleDelete} />,
    },
  ];



  const options = {
    pageStartIndex: 1,
    paginationSize: 4,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: 'All', value: Number(filteredData?.length)
    }]
  };

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDelete([...itemToDelete, row.id]);
        } else {
          setItemToDelete(itemToDelete?.filter((id) => id !== row?.id));
        }
      }
    },
    style: { width: '30px' },
    selected: itemToDelete,
    selectionHeaderRenderer: ({ mode, checked }) => (
      <div>
        <input type="checkbox" checked={checked} onChange={handleAll} />
      </div>
    ),
  };

  //Bootstrap Table util end

  // Api links start 

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/faq/index`, { headers: adminApiHeader });
      const result = response?.data?.AllAdminFAQ;
      setApiData(result);
      setFilteredData(result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    setShowOverlay(true);
    try {
      const formData = new FormData();
      formData.append("id", "[" + itemToDelete + "]");
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/faq/multi-delete`, formData, { headers: adminApiHeader });
      const result = response?.data;
      if (result?.status) {
        setWithoutRefresh(!withOutRefresh);
        toast.success(result?.message);
        setShowOverlay(false);
        setItemToDelete([])
      }
      else {
        toast.error(result?.message);
        setShowOverlay(false);
      }
    } catch (err) {
      var errorMessage = err?.response?.data?.message;
      toast.error(errorMessage);
      setShowOverlay(false);
    }
  };


  const fetchDatagenre = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/faq/create`, { headers: adminApiHeader });
      const result = response?.data?.GenreFAQ;
      setGenreapi(result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  // Api links end 

  const handleToggle = async (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setModelClose(!closeModal);
    setShowModal(false);
  };

  const openModal = () => {
    setModelClose(true);
  };

  const singleDelete = (id) => {
    setModelClose(true);
    setItemToDelete([id]);
  };

  const handleDeletedata = () => {
    handleConfirmDelete();
    closeModal();
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDelete(selectAll ? [] : filteredData?.map((item) => item?.id));
  };

  const handleFilterChange = (data) => {
    setFilteredData(data);
  };


  useEffect(async () => {
    fetchData();
    fetchDatagenre();
  }, [withOutRefresh]);


  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagequestion("");
    setIsValidationHiddenquestion(true);
    setValidationMessageanswer("");
    setIsValidationHiddenanswer(true);
    setvalidationMessagegenre("");
    setisValidationHiddengenre(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [editUser, setEditUser] = useState({
    question: "",
    answer: "",
    genre_id: "",
  });

  const [validationMessagequestion, setValidationMessagequestion] =
    useState("");
  const [isValidationHiddenquestion, setIsValidationHiddenquestion] =
    useState(true);

  const [validationMessageanswer, setValidationMessageanswer] =
    useState("");
  const [isValidationHiddenanswer, setIsValidationHiddenanswer] =
    useState(true);

  const [validationMessagegenre, setvalidationMessagegenre] =
    useState("");
  const [isValidationHiddengenre, setisValidationHiddengenre] =
    useState(true);


  const questionInputRef = useRef(null);
  const answerInputRef = useRef(null);
  const genreInputRef = useRef(null);

  async function handleUpdate() {
    let focusInputRef = null;

    if (editUser?.question === "") {
      setValidationMessagequestion("FAQ Question cannot be empty.");
      setIsValidationHiddenquestion(false);
      focusInputRef = questionInputRef;
    }
    if (editUser?.answer === "") {
      setValidationMessageanswer("FAQ Answer cannot be empty.");
      setIsValidationHiddenanswer(false);
      focusInputRef = answerInputRef;
    }
    if (editUser?.genre_id === "") {
      setvalidationMessagegenre("Please select Genre.");
      setisValidationHiddengenre(false);
      focusInputRef = genreInputRef;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    const formData = new FormData();

    formData.append("question", editUser?.question);
    formData.append("answer", editUser?.answer);
    formData.append("genre_id", allgenre ? parseInt(allgenre, 10) : 0);

    let firstApiStatus = false;
    let resultapi = null;
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/faq/store`,
        formData,
        { headers: adminApiHeader }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      setValidationMessagequestion("");
      setValidationMessageanswer("");
      setvalidationMessagegenre("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddenquestion(false);
        setIsValidationHiddenanswer(false);
        setisValidationHiddengenre(false);
        setShowOverlay(false);
      }
    }
  }


  return (
    <div className="">


      <div className="panel-heading">
        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="exampleModalLabel"
                >
                  ADD FAQ
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div>
                  <label>
                    FAQ Question
                    <span className="mandatory">*</span>
                  </label>

                  <div className="mt-2">
                    <input
                      type="text"
                      name="question"
                      // placeholder="faq://75.119.145.126:1935/show/"
                      className="rs-input form-control-lg"
                      // onChange={(e) =>
                      //   setquestion(e.target.value)
                      // }
                      value={editUser?.question}
                      onChange={handleInput}
                      ref={questionInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddenquestion && (
                      <p>{validationMessagequestion}</p>
                    )}
                  </span>
                </div>
                <div className="mt-2">
                  <label>
                    FAQ Answer
                    <span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="answer"
                      className="rs-input form-control-lg"
                      value={editUser?.answer}
                      onChange={handleInput}
                      ref={answerInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddenanswer && (
                      <p>{validationMessageanswer}</p>
                    )}
                  </span>
                </div>
                <div className="mt-2">
                  <label>
                    FAQ Genre
                    <span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <select
                      onChange={handleInput}
                      onClick={(event) => {
                        setallgenre(event.target.value);
                      }}
                      className="form-select"
                      id="genre_id"
                      name="genre_id"
                      ref={genreInputRef}
                    >
                      <option value="0">Select a Genre</option>
                      {genreapi?.map((item) => (
                        <option value={item?.id}>{item?.name}</option>
                      ))}
                    </select>
                  </div>
                  <span className="errorred mt-2 d-block">
                    {!isValidationHiddengenre && (
                      <p>{validationMessagegenre}</p>
                    )}
                  </span>
                </div>
              </div>
              <div className="modal-footer">
                {/* <button  onClick={handleUpdate} >submit</button> */}
                <input
                  type="submit"
                  id="appupdate"
                  value="Update faq Setting"
                  onClick={handleUpdate}
                  className="btn btn-primary ml-3 "
                />
              </div>
            </div>
          </div>
        </div>

      </div>

      {showOverlay && <Response_Processing />}
      {showModal && <AdminSliderModal modelClose={showModal} sliderClose={closeModal} buttonText={currentItem?.banner} />}
      {modelClose && <AdminDeleteModal modelClose={modelClose} deleteClose={closeModal} deleteAction={handleDeletedata} buttonText={itemToDelete?.length} />}
      <div className="row d-flex col-lg-12">
        <div className="col-lg-10"><AdminNavbar heading="All FAQ" totalDataLength={apiData?.length} deleteCount={itemToDelete?.length} deleteMethod={openModal} />
        </div>
        <div className="col-lg-2 text-end p-0"><button className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal">Add FAQ</button></div>
      </div>

      <div className="theme-bg-color p-3 rounded-2 bootstrapTable  multi-delete-width" >
        <AdminHeader searchMethod={handleFilterChange} reduceData={filteredData} originalData={apiData} fileName={videoDefaultURL?.exportFileName} />
        <BootstrapTable keyField="id" pagination={paginationFactory(options)} columns={columns} data={filteredData} className="no-border-table theme-text-color " noDataIndication={<NoRecordTable result={filteredData?.length} loader={loading} />} hover headerClasses="videoWidth" selectRow={selectRow} selectAll={selectAll} />
      </div>
    </div>
  );
}

export default FAQ;



