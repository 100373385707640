
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import axios from "axios";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import './SeeLogActivity.css';


function SeeLogActivity(props) {

  const access_token = localStorage.getItem("access_token");


  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };


  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [defaultImage, setDefaultimage] = useState();
  const [loading, setLoading] = useState(true);
  const [logactivityData, setlogactivityData] = useState([]);
  const [filteredlogactivityData, setFilteredlogactivityData] = useState([]);
  const [selectAlllogactivity, setSelectAlllogactivity] = useState(false);
  const [filterlogactivity, setFilterlogactivity] = useState("");
  const [itemToDeletelogactivity, setItemToDeletelogactivity] = useState([]);
  const [modalCloselogactivity, setModalCloselogactivity] = useState(false);

  const fetchApilogactivity = async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/LogActivity/index`, {
        headers: headers,
      })
      .then((response) => {
        const logactivityList = response?.data?.LogActivity;
        const updatedlogactivityList = logactivityList?.map((item) => {
          const uploadBy =
            item?.uploaded_by === "1"
              ? "Admin"
              : item?.uploaded_by === "4"
                ? "Channel Partner"
                : item?.uploaded_by === "5"
                  ? "Content Partner"
                  : "";
          const statusText =
            item?.active === 1
              ? "PUBLISHED"
              : item?.active === 0 || item?.active === null
                ? "UNPUBLISHED"
                : "DRAFT";

          return { ...item, uploaded_by: uploadBy, active: statusText };
        });
        setlogactivityData(updatedlogactivityList);
        setFilteredlogactivityData(updatedlogactivityList);
        setLoading(false);
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  useEffect(async () => {
    fetchApilogactivity();
  }, []);

  const commonPaginationConfigLogactivity = (sizePerPageList) => {
    return paginationFactory({
      page: 1,
      sizePerPage: 10,
      nextPageText: "Next",
      prePageText: "Prev",
      firstPageText: <MdKeyboardDoubleArrowLeft />,
      lastPageText: <MdKeyboardDoubleArrowRight />,
      showTotal: true,
      alwaysShowAllBtns: true,
      sizePerPageList: sizePerPageList,
    });
  };
  const logactivitySizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "All", value: filteredlogactivityData?.length },
  ];


  /////Logactivity start


  const logactivityFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="rightVideoTitle">
          <p>{row?.subject}</p>
        </div>
      </div>
    );
  };
  const SNoFormatter = (data, row, index) => {
    return (
      <div className="testtestvideo d-flex justify-content-between align-items-center" style={{ width: '10% !important' }}>
        <div className="rightVideoTitle">
          <p>{index + 1}</p>
        </div>
      </div>
    );
  };
  const aser_agentFormatterlogactivity = (data, row) => {
    return (
      <div className="testtestvideo d-flex justify-content-between align-items-center" style={{ width: '10% !important' }}>
        <div className="rightVideoTitle">
          <p>{row?.agent}</p>
        </div>
      </div>
    );
  };
  const created_atFormatterlogactivity = (data, row) => {

    const dateObj = new Date(row?.created_at);

    // Format the date and time
    const formattedDate = dateObj.toLocaleDateString('en-US'); // Adjust the locale as needed
    const formattedTime = dateObj.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }); // Adjust the options as needed

    return (
      <div className="testtestvideo d-flex justify-content-between align-items-center" style={{ width: '10% !important' }}>
        <div className="rightVideoTitle">
          <p>{formattedDate} {formattedTime}</p>
        </div>
      </div>
    );
  };

  const logactivityFormatterusername = (data, row) => {

    const uploadBy =
      row?.uploaded_by === "1"
        ? "Admin"
        : row?.uploaded_by === "4"
          ? "Channel Partner"
          : row?.uploaded_by === "5"
            ? "Content Partner"
            : "";
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="rightVideoTitle">
          <p>Admin</p>
        </div>
      </div>
    );
  };

  const actionFormatterlogactivity = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit-Logactivity/manage/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2"> Edit Logactivity </span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeletelogactivity(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2"> Delete Logactivity</span>
          </div>
        </div>
      </div>
    );
  };
  const columnslogactivity = [
    {
      dataField: "S.No",
      text: "S.No",
      formatter: SNoFormatter,
      sort: true,
    },
    {
      dataField: "Subject",
      text: "Subject",
      formatter: logactivityFormatter,
      sort: true,
    },
    {
      dataField: "UserName",
      text: "User Name",
      formatter: logactivityFormatterusername,
      sort: true,
    },
    {
      dataField: "UserAgent",
      text: "User Agent",
      formatter: aser_agentFormatterlogactivity,
    },
    {
      dataField: "Created",
      text: "Created",
      formatter: created_atFormatterlogactivity,
    },

    // {
    //   dataField: "banner",
    //   text: "Slider",
    //   // formatter: (cell, row) => <SliderFormatterComponent cell={cell} row={row} action={handleToggle} />,
    // },
    // {
    //   dataField: "ACTIONS",
    //   text: "Actions",
    //   formatter: actionFormatterlogactivity,
    // },
  ];
  const exportHeaderslogactivity = [
    { label: "ID", key: "id" },
    { label: "Subject", key: "Subject" },
    { label: "User Name", key: "UserName" },
    { label: "User Agent", key: "UserAgent" },
    { label: "Created", key: "Created" },
    { label: "Action", key: "upload_on" },
  ];
  const conditionalGeneratePDFlogactivity = (get) => {
    const doc = new jsPDF();
    doc.text("ALL Logactivity LIST ", 10, 10);
    const columns = ["Subject", "UserName", "UserAgent", "Created", "Action"];
    const allDataPDF = logactivityData?.map((item, index) => [
      index + 1,
      item?.user_id,
      item?.subject,
      item?.url,
      item?.agent,
      item?.video_id,
      item?.created_at,
    ]);
    const filterPDF = filteredlogactivityData?.map((item, index) => [
      index + 1,
      item?.user_id,
      item?.subject,
      item?.url,
      item?.agent,
      item?.video_id,
      item?.created_at,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save(get === 1 ? "ALL-Logactivity-LIST.pdf" : "FILTERED-Logactivity-LIST");
  };

  const generateXLSXlogactivity = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(
        get === 1 ? logactivityData : filteredlogactivityData
      );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(
      wb,
      get === 1 ? "ALL-Logactivity-LIST.xlsx" : "FILTERED-Logactivity-LIST.xlsx"
    );
  };

  const handleFilterChangelogactivity = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilterlogactivity(searchTerm);
    const filteredResults = logactivityData?.filter((item) =>
      ["Subject", "UserName", "UserAgent", "Created", "Action"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredlogactivityData(filteredResults);
  };

  const closelogactivityModal = () => {
    setModalCloselogactivity(!modalCloselogactivity);
  };

  const openModalSingleDeletelogactivity = (id) => {
    setItemToDeletelogactivity([id]);
    setModalCloselogactivity(true);
  };

  const openModalMultiDeletelogactivity = () => {
    setModalCloselogactivity(true);
  };

  const handleDeletelogactivityData = () => {
    closelogactivityModal();
    logactivityDeleteOperation();
  };

  const logactivityDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeletelogactivity + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Logactivity/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };
  const handleClearlogactivity = () => {
    fetchApilogactivity();
    setFilterlogactivity("");
  };
  const handleAlllogactivity = () => {
    setSelectAlllogactivity(!selectAlllogactivity);
    setItemToDeletelogactivity(
      selectAlllogactivity ? [] : filteredlogactivityData?.map((item) => item?.id)
    );
  };


  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid pt-3">
          <div id="content-page" className="content-page">
            <div className="iq-card">
              <div className="iq-card-header  justify-content-between">
                <div className="iq-header-title p-0">
                  <h4>Log Activity Lists</h4>
                </div>
              </div>

              <div className="col-md-12 mt-3 ">
                <section className="section container-fluid">
                  <div id="content-page" className="content-page">
                    <div className="iq-card shadow-none p-0">
                      {/* <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                        <div className="filterContainer">
                          <input
                            type="text"
                            placeholder="Filter all.."
                            className="filter form-control "
                            value={filterlogactivity}
                            onChange={handleFilterChangelogactivity}
                          />
                          {filterlogactivity?.length > 0 && (
                            <button
                              type="button"
                              className="closeBtn"
                              onClick={handleClearlogactivity}
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          )}
                        </div>
                        <div className="btn-group flex-wrap justify-content-between justify-content-sm-end">
                          {itemToDeletelogactivity?.length > 0 && (
                            <button
                              className="btn btn-danger d-inline-flex  fw-bolder dropdown-toggle mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                              onClick={openModalMultiDeletelogactivity}
                            >
                              <MdDelete className="exportIcon " />
                              Delete
                            </button>
                          )}
                          <div
                            className="dropdown d-block mb-2 mb-sm-0 mx-0 "
                            key={"2"}
                          >
                            <button
                              className="btn btn-success dropdown-toggle d-flex mx-0 "
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="fw-bolder">Export </span>
                              <img
                                src={ExportIcon}
                                alt="image_all"
                                className="ms-2 exportIcon"
                              />
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="dropdown-item">
                                <CSVLink
                                  headers={exportHeaderslogactivity}
                                  data={filteredlogactivityData}
                                  className="text-dark d-block"
                                  filename="FILTERED-Logactivity-LIST.csv"
                                >
                                  CSV
                                </CSVLink>
                              </li>
                              <li
                                className="dropdown-item"
                                onClick={() => {
                                  conditionalGeneratePDFlogactivity(0);
                                }}
                              >
                                PDF
                              </li>
                              <li
                                className="dropdown-item"
                                onClick={() => {
                                  generateXLSXlogactivity(0);
                                }}
                              >
                                XLSX
                              </li>
                            </ul>
                          </div>

                          <div className="dropdown d-block ms-2">
                            <button
                              className="btn btn-success dropdown-toggle d-flex"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="fw-bolder">Export All</span>
                              <img
                                src={ExportIcon}
                                alt="image_all"
                                className="ms-2 exportIcon"
                              />
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="dropdown-item">
                                <CSVLink
                                  headers={exportHeaderslogactivity}
                                  data={logactivityData}
                                  className="text-dark d-block"
                                  filename="ALL-Logactivity-LIST.csv"
                                >
                                  CSV
                                </CSVLink>
                              </li>
                              <li
                                className="dropdown-item"
                                onClick={() => {
                                  conditionalGeneratePDFlogactivity(1);
                                }}
                              >
                                PDF
                              </li>
                              <li
                                className="dropdown-item"
                                onClick={() => {
                                  generateXLSXlogactivity(1);
                                }}
                              >
                                XLSX
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> */}
                      <div className="bootstrapTable">
                        <BootstrapTable
                          keyField="id"
                          columns={columnslogactivity}
                          data={filteredlogactivityData}
                          pagination={commonPaginationConfigLogactivity(
                            logactivitySizePerPageList
                          )}
                          className="no-border-table"
                          noDataIndication={
                            <div className="noRecord">
                              {loading ? (
                                <label>
                                  {" "}
                                  <Response_Loading_processing></Response_Loading_processing>
                                  Loading...
                                </label>
                              ) : (
                                filteredlogactivityData?.length <= 0 && (
                                  <p>No Log Activity Available !!!</p>
                                )
                              )}
                            </div>
                          }
                          hover
                          headerClasses="videoWidth testtestvideo"
                          // selectRow={selectRowlogactivity}
                          selectAll={selectAlllogactivity}
                        />
                      </div>
                    </div>
                  </div>
                </section>



                {/* <div className="row align-items-center">
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table" id="logtable">
                        <thead>
                          <tr className="r1">
                            <th>No</th>
                            <th>Subject</th>

                            <th>User Agent</th>

                            <th>User Name</th>
                            <th>Video Title</th>
                            <th>Video Category</th>
                            <th>Video Language</th>
                            <th>Video Cast Crew</th>
                            <th>Created</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td></td>

                            <td className="text-danger"></td>

                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default SeeLogActivity;
