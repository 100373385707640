// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.checkBoxContainer {
    max-width: 215px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.checkBoxText{
    width: 160px;
    color: #000000;
    font-size: 14px;
    font-family: "museo_sans300" !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Content_Partner/ContentAddRole.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,cAAc;IACd,eAAe;IACf,uCAAuC;AAC3C","sourcesContent":["\n.checkBoxContainer {\n    max-width: 215px;\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 10px;\n}\n.checkBoxText{\n    width: 160px;\n    color: #000000;\n    font-size: 14px;\n    font-family: \"museo_sans300\" !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
