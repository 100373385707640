// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t-1{
    font-size: 12px;
}
.imagedrop{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border-style: dashed;
    border-color: #cbd5e1;
    cursor: pointer;
}
textarea#description {
    height: 120px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Video/Series&Episode/Addnewseries.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,oBAAoB;IACpB,qBAAqB;IACrB,eAAe;AACnB;AACA;IACI,aAAa;AACjB","sourcesContent":[".t-1{\n    font-size: 12px;\n}\n.imagedrop{\n    width: 100%;\n    height: 100px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 5px;\n    border-style: dashed;\n    border-color: #cbd5e1;\n    cursor: pointer;\n}\ntextarea#description {\n    height: 120px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
