import React from "react";
import { Link } from "react-router-dom";

function ContentLive() {
  return (
    <div className="m-0 p-0">
      <div className="">
        <section className="section container-fluid">ContentLive</section>
      </div>
    </div>
  );
}

export default ContentLive;