import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.css";

import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";
import edititem from "../../../components/Images/edititem.svg";
import deleteitem from "../../../components/Images/deleteitem.svg";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function ViewContentPartner(props) {
  const [contentPartner, setContentPartner] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [itemToDelete, setItemToDelete] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [modelClose, setModelClose] = useState(false);

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDelete(selectAll ? [] : filteredData?.map((item) => item?.id));
  };
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDelete([...itemToDelete, row?.id]);
        } else {
          setItemToDelete(itemToDelete.filter((id) => id !== row?.id));
        }
      }
    },
    selected: itemToDelete,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAll}
        />
      </div>
    ),
  };

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchRole = () => {
    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/content-partner/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.ContentPartner;
        const updatedChannelList = result?.map((item) => {
          const statusText =
            item?.status === 0
              ? "Pending"
              : item?.status === 1
                ? "Approved"
                : "Rejected";
          return {
            ...item,
            status: statusText,
            created_at: formatDate(item?.created_at),
          };
        });
        setFilteredData(updatedChannelList);
        setContentPartner(updatedChannelList);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  useEffect(async () => {
    fetchRole();
  }, []);
  const handleSave = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("content_partner_id", "[" + itemToDelete + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/content-partner/multi-delete`,
        formData,
        { headers: headers }
      );
      const result = response?.data;
      if (result?.status === true) {
        fetchRole();
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error(error);
      var errorMessage = error?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const conditionalGeneratePDF = (get) => {
    const doc = new jsPDF();
    doc.text("CONTENT PARTNER LIST", 10, 10);
    const columns = [
      "Index",
      "ID",
      "NAME",
      "EMAIL ID",
      "MOBILE NUMBER",
      "STATUS",
    ];
    const allDataPDF = contentPartner?.map((item, index) => [
      index + 1,
      item?.id,
      item?.username,
      item?.email,
      item?.mobile_number,
      item?.status,
    ]);
    const filterPDF = filteredData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.username,
      item?.email,
      item?.mobile_number,
      item?.status,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("CONTENT-PARTNER-LIST.pdf");
  };

  const generateXLSX = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? contentPartner : filteredData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "CONTENT-PARTNER-LIST.xlsx");
  };
  const openModal = () => {
    setModelClose(true);
  };
  const singleDelete = (id) => {
    setModelClose(true);
    setItemToDelete([id]);
  };
  const closeModal = () => {
    setModelClose(!closeModal);
  };
  const handleDeletedata = async () => {
    handleSave();
    closeModal();
  };
  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit-allmoderator/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Edit</span>
            </span>
          </Link>

          <div
            onClick={() => singleDelete(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2">Delete</span>
          </div>
        </div>
      </div>
    );
  };
  const imageFormatter = (data, row) => {
    return <img src={data} className="profile" />;
  };

  const userFormatter = (data, row) => {
    return (
      <div className="d-flex flex-column align-items-start">
        <span>{data}</span>
        <span className="dateText"> {formatDate(row?.created_at)}</span>
      </div>
    );
  };
  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
    return `${formattedDate} ${formattedTime}`;
  }

  const exportHeaders = [
    { label: "ID", key: "id" },
    { label: "NAME", key: "username" },
    { label: "EMAIL", key: "email" },
    { label: "MOBILE NUMBER", key: "mobile_number" },
    { label: "STATUS", key: "status" },
  ];
  const handleFilterChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = contentPartner.filter((item) =>
      ["created_at", "username", "email", "status", "mobile_number"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredData(filteredResults);
  };

  const statusFormatter = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder d-flex justify-content-center  ${data === "Pending"
          ? "DISABLED_active"
          : data === "Approved"
            ? "PUBLISHED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };
  const columns = [
    {
      dataField: "picture",
      text: "Profile",
      formatter: imageFormatter,
    },
    {
      dataField: "username",
      text: "Name",
      sort: true,
      formatter: userFormatter,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "mobile_number",
      text: "Mobile number",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: statusFormatter,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredData.length },
    ],
  });

  const handleClear = () => {
    fetchRole();
    setFilter("");
  };

  return (
    <>
      <div
        className={`modal packageModel ${modelClose ? "show fade" : ""}`}
        tabindex="-1"
        style={{ display: `${modelClose ? "block" : "none"}` }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDelete?.length === 1 ? (
                <p className="fs-6">
                  {" "}
                  Are you sure you want to delete this item ?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected items ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeletedata}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-6">
              <h4>
                <i className="entypo-archive"></i> All Moderators
                <span className="fs-6">
                  {" "}
                  (Display all {contentPartner.length} Moderators)
                </span>
              </h4>
            </div>

            <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
              {itemToDelete.length > 0 && (
                <button
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModal}
                >
                  <MdDelete className="exportIcon " />
                  Delete
                </button>
              )}
              <Link to="/moderator" className="btn btn-primary fw-bolder">
                <i className="fa fa-plus-circle"></i> Add New
              </Link>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
              <div className="filterContainer">
                <input
                  type="text"
                  placeholder="Filter all.."
                  className="filter form-control "
                  value={filter}
                  onChange={handleFilterChange}
                />
                {filter.length > 0 && (
                  <button
                    type="button"
                    className="closeBtn"
                    onClick={handleClear}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                )}
              </div>
              <div className="btn-group">
                <div className="dropdown d-block " key={"2"}>
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export </span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeaders}
                        data={filteredData}
                        className="text-dark d-block"
                        filename="CONTENT-PARTNER-FILTERED-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDF(0);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSX(0);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>

                <div className="dropdown d-block ">
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export All</span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeaders}
                        data={contentPartner}
                        className="text-dark d-block"
                        filename="CONTENT-PARTNER-ALL-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDF(1);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSX(1);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bootstrapTable">
              <BootstrapTable
                keyField="id"
                columns={columns}
                data={filteredData}
                pagination={pagination}
                className="no-border-table"
                noDataIndication={
                  <div className="noRecord">
                    {loading ? (
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>
                        Loading...
                      </label>
                    ) : (
                      filteredData.length <= 0 && <p>No Record Found!</p>
                    )}
                  </div>
                }
                hover
                headerClasses="custom-header"
                selectRow={selectRow}
                selectAll={selectAll}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ViewContentPartner;
