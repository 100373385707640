import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function StorageSettings(props) {
  // const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    site_storage: "",
    aws_storage: "",
    aws_access_key: "",
    aws_secret_key: "",
    aws_region: "",
    aws_bucket: "",
    aws_storage_path: "",
    aws_video_trailer_path: "",
    aws_season_trailer_path: "",
    aws_episode_path: "",
    aws_live_path: "",
    aws_audio_path: "",
    aws_transcode_path: "",

    site_key: "",
    site_action: "",
    site_user: "",
    site_IPSERVERAPI: "",


    bunny_cdn_storage: "",
    bunny_cdn_region: "",
    bunny_cdn_hostname: "",
    bunny_cdn_ftp_access_key: "",
    bunny_cdn_storage_zone_name: "",
    bunny_cdn_file_linkend_hostname: "",
    bunny_cdn_access_key: "",

  });
  const [showSiteDetails, setShowSiteDetails] = useState(true); // Toggle for showing site details
  const [showAwsDetails, setShowAwsDetails] = useState(false);
  const [showBunnyCDNDetails, setShowBunnyCDNDetails] = useState(false);
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/storage_index`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Storage_setting;
      setEditUser(res);
    };
    getUser();
  }, []);

  const handleInputenable = (e) => {
    // Handle changes to site_storage, aws_storage, and bunny_cdn_storage based on toggle logic
    if (e.target.name === "site_storage" && e.target.checked) {
      Swal.fire({
        title: "Switching to Site Storage",
        text: "AWS Storage and Bunny CDN Storage will be disabled. Do you want to proceed?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          setEditUser((prevUser) => ({
            ...prevUser,
            site_storage: e.target.name === "site_storage" ? 1 : 0,
            aws_storage: e.target.name === "aws_storage" ? 1 : 0,
            bunny_cdn_storage: e.target.name === "bunny_cdn_storage" ? 1 : 0,
          }));
          setShowSiteDetails(true);
          setShowAwsDetails(false);
          setShowBunnyCDNDetails(false);
        } else {
          e.target.checked = false; // Revert the toggle state
        }
      });
    } else if (e.target.name === "aws_storage" && e.target.checked) {
      Swal.fire({
        title: "Switching to AWS Storage",
        text: "Site Storage and Bunny CDN Storage will be disabled. Do you want to proceed?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          setEditUser((prevUser) => ({
            ...prevUser,
            site_storage: e.target.name === "site_storage" ? 1 : 0,
            aws_storage: e.target.name === "aws_storage" ? 1 : 0,
            bunny_cdn_storage: e.target.name === "bunny_cdn_storage" ? 1 : 0,
          }));
          setShowSiteDetails(false);
          setShowAwsDetails(true);
          setShowBunnyCDNDetails(false);
        } else {
          e.target.checked = false; // Revert the toggle state
        }
      });
    } else if (e.target.name === "bunny_cdn_storage" && e.target.checked) {
      Swal.fire({
        title: "Switching to Bunny CDN Storage",
        text: "Site Storage and AWS Storage will be disabled. Do you want to proceed?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          setEditUser((prevUser) => ({
            ...prevUser,
            site_storage: e.target.name === "site_storage" ? 1 : 0,
            aws_storage: e.target.name === "aws_storage" ? 1 : 0,
            bunny_cdn_storage: e.target.name === "bunny_cdn_storage" ? 1 : 0,
          }));
          setShowSiteDetails(false);
          setShowAwsDetails(false);
          setShowBunnyCDNDetails(true);
        } else {
          e.target.checked = false; // Revert the toggle state
        }
      });
    } else {
      setEditUser((prevUser) => ({
        ...prevUser,
        site_storage: e.target.name === "site_storage" ? 1 : 0,
        aws_storage: e.target.name === "aws_storage" ? 1 : 0,
        bunny_cdn_storage: e.target.name === "bunny_cdn_storage" ? 1 : 0,
      }));
      setShowSiteDetails(false);
      setShowAwsDetails(false);
      setShowBunnyCDNDetails(false);
    }
  };


  const handleInput = (e) => {
    // Handle changes to input fields and update the editUser state accordingly
    setEditUser((prevUser) => ({
      ...prevUser,
      [e.target.name]: e.target.value,
    }));
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      site_storage: editUser?.site_storage,
      aws_storage: editUser?.aws_storage,
      aws_access_key: editUser?.aws_access_key,
      aws_secret_key: editUser?.aws_secret_key,
      aws_region: editUser?.aws_region,
      aws_bucket: editUser?.aws_bucket,
      aws_storage_path: editUser?.aws_storage_path,
      aws_video_trailer_path: editUser?.aws_video_trailer_path,
      aws_season_trailer_path: editUser?.aws_season_trailer_path,
      aws_episode_path: editUser?.aws_episode_path,
      aws_live_path: editUser?.aws_live_path,
      aws_audio_path: editUser?.aws_audio_path,
      aws_transcode_path: editUser?.aws_transcode_path,
      site_key: editUser?.site_key,
      site_action: editUser?.site_action,
      site_user: editUser?.site_user,
      site_IPSERVERAPI: editUser?.site_IPSERVERAPI,
      bunny_cdn_storage: editUser?.bunny_cdn_storage,
      bunny_cdn_region: editUser?.bunny_cdn_region,
      bunny_cdn_hostname: editUser?.bunny_cdn_hostname,
      bunny_cdn_ftp_access_key: editUser?.bunny_cdn_ftp_access_key,
      bunny_cdn_storage_zone_name: editUser?.bunny_cdn_storage_zone_name,
      bunny_cdn_access_key: editUser?.bunny_cdn_access_key,
      bunny_cdn_file_linkend_hostname: editUser?.bunny_cdn_file_linkend_hostname,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/storage_update`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Storage Settings</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Settings
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12">
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="card p-3">
                  <div className="text-start">
                    <div className="row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <label className="m-0">Site Storage</label>
                      </div>

                      <div className="col-3 col-sm-3 col-lg-3 text-end">
                        <label className="switch">
                          {Number.isInteger(
                            parseInt(editUser?.site_storage)
                          ) ? (
                            <input
                              name="site_storage"
                              id="site_storage"
                              onChange={handleInputenable}
                              className="rs-input"
                              defaultChecked={
                                parseInt(editUser?.site_storage) === 1
                              }
                              type="checkbox"
                              value={
                                parseInt(editUser?.site_storage) === 1
                                  ? "1"
                                  : "0"
                              }
                            />
                          ) : (
                            <input
                              name="site_storage"
                              id="site_storage"
                              onChange={handleInputenable}
                              className="rs-input"
                              type="checkbox"
                            />
                          )}
                          <span
                            className="slider round"
                            name="site_storage"
                            id="site_storage"
                            value={
                              parseInt(editUser?.site_storage) === 1 ? "1" : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="card p-3">
                  <div className="text-start">
                    <div className="row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <label className="m-0">Enable AWS Storage</label>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3 text-end">
                        <label className="switch">
                          {Number.isInteger(parseInt(editUser?.aws_storage)) ? (
                            <input
                              name="aws_storage"
                              id="aws_storage"
                              onChange={handleInputenable}
                              className="rs-input"
                              defaultChecked={
                                parseInt(editUser?.aws_storage) === 1
                              }
                              type="checkbox"
                              value={
                                parseInt(editUser?.aws_storage) === 1
                                  ? "1"
                                  : "0"
                              }
                            />
                          ) : (
                            <input
                              name="aws_storage"
                              id="aws_storage"
                              onChange={handleInputenable}
                              className="rs-input"
                              type="checkbox"
                            />
                          )}
                          <span
                            className="slider round"
                            name="aws_storage"
                            id="aws_storage"
                            value={
                              parseInt(editUser?.aws_storage) === 1 ? "1" : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="card p-3">
                  <div className="text-start">
                    <div className="row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <label className="m-0">Bunny CDN Storage</label>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3 text-end">
                        <label className="switch">
                          {Number.isInteger(parseInt(editUser?.bunny_cdn_storage)) ? (
                            <input
                              name="bunny_cdn_storage"
                              id="bunny_cdn_storage"
                              onChange={handleInputenable}
                              className="rs-input"
                              defaultChecked={
                                parseInt(editUser?.bunny_cdn_storage) === 1
                              }
                              type="checkbox"
                              value={
                                parseInt(editUser?.bunny_cdn_storage) === 1
                                  ? "1"
                                  : "0"
                              }
                            />
                          ) : (
                            <input
                              name="bunny_cdn_storage"
                              id="bunny_cdn_storage"
                              onChange={handleInputenable}
                              className="rs-input"
                              type="checkbox"
                            />
                          )}
                          <span
                            className="slider round"
                            name="bunny_cdn_storage"
                            id="bunny_cdn_storage"
                            value={
                              parseInt(editUser?.bunny_cdn_storage) === 1 ? "1" : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row col-lg-12">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">

                  {editUser?.site_storage === 1 && (
                    <div>
                      <div>
                        <h5>Create Site Storage Details</h5>
                        <hr ></hr>
                      </div>

                      <div className="mt-2 text-start">
                        <label className="m-0">Site KEY</label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="site_key"
                            name="site_key"
                            value={editUser?.site_key}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="mt-2 text-start">
                        <label className="m-0">Site Action(Ex:(list))</label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="site_action"
                            name="site_action"
                            value={editUser?.site_action}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {editUser?.aws_storage === 1 && (
                    <div>
                      <div>
                        <h5>Create AWS Details</h5>
                        <hr ></hr>
                      </div>

                      <div className=" text-start mt-2">
                        <label className="m-0">AWS ACCESS KEY</label>
                        <div className="mt-2">
                          <input
                            type="text"
                            id="aws_access_key"
                            name="aws_access_key"
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            value={editUser?.aws_access_key}
                          />
                        </div>
                      </div>
                      <div className="mt-2 text-start">
                        <label className="m-0">AWS REGION</label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="aws_region"
                            name="aws_region"
                            value={editUser?.aws_region}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>

                      <div className="mt-2 text-start">
                        <label className="m-0">
                          AWS Storage Video Path (Ex: /path/to )
                        </label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="aws_storage_path"
                            name="aws_storage_path"
                            value={editUser?.aws_storage_path}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="mt-2 text-start">
                        <label className="m-0">
                          AWS Storage Season Trailer Path (Ex: /path/to )
                        </label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="aws_season_trailer_path"
                            name="aws_season_trailer_path"
                            value={editUser?.aws_season_trailer_path}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="mt-2 text-start">
                        <label className="m-0">
                          AWS Storage Live Path (Ex: /path/to )
                        </label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="aws_live_path"
                            name="aws_live_path"
                            value={editUser?.aws_live_path}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {editUser?.bunny_cdn_storage === 1 && (
                    <div>
                      <div>
                        <h5>Create Bunny Details</h5>
                        <hr ></hr>
                      </div>
                      <div className=" text-start mt-2">
                        <label className="m-0">Bunny CDN Linked Hostname</label>
                        <div className="mt-2">
                          <input
                            type="text"
                            id="bunny_cdn_file_linkend_hostname"
                            name="bunny_cdn_file_linkend_hostname"
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            value={editUser?.bunny_cdn_file_linkend_hostname}
                          />
                        </div>
                      </div>
                      <div className="mt-2 text-start">
                        <label className="m-0">Bunny CDN Hostname</label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="bunny_cdn_hostname"
                            name="bunny_cdn_hostname"
                            value={editUser?.bunny_cdn_hostname}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>

                      <div className="mt-2 text-start">
                        <label className="m-0">
                          Bunny CDN Storage Zone Name
                        </label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="bunny_cdn_storage_zone_name"
                            name="bunny_cdn_storage_zone_name"
                            value={editUser?.bunny_cdn_storage_zone_name}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* {editUser?.aws_storage === 1 && (
                  <div className="text-start">
                    <button onClick={handleUpdate} className="btn btn-primary">
                      Update Settings
                    </button>
                  </div>
                )} */}
              </div>

              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">


                  {editUser?.site_storage === 1 && (
                    <div>
                      <div className="mt-2 text-start">
                        <label className="m-0">Site User</label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="site_user"
                            name="site_user"
                            value={editUser?.site_user}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="mt-2 text-start">
                        <label className="m-0">Site Server IP</label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="site_IPSERVERAPI"
                            name="site_IPSERVERAPI"
                            value={editUser?.site_IPSERVERAPI}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {editUser?.aws_storage === 1 && (
                    <div>
                      <div className=" text-start mt-2">
                        <label className="m-0">AWS SCRETE KEY</label>
                        <div className="mt-2">
                          <input
                            type="text"
                            id="aws_secret_key"
                            name="aws_secret_key"
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            value={editUser?.aws_secret_key}
                          />
                        </div>
                      </div>
                      <div className="mt-2 text-start">
                        <label className="m-0">AWS BUCKET</label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="aws_bucket"
                            name="aws_bucket"
                            value={editUser?.aws_bucket}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="mt-2 text-start">
                        <label className="m-0">
                          AWS Storage Video Trailer Path (Ex: /path/to )
                        </label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="aws_video_trailer_path"
                            name="aws_video_trailer_path"
                            value={editUser?.aws_video_trailer_path}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="mt-2 text-start">
                        <label className="m-0">
                          AWS Storage Season Episode Path (Ex: /path/to )
                        </label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="aws_episode_path"
                            name="aws_episode_path"
                            value={editUser?.aws_episode_path}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="mt-2 text-start">
                        <label className="m-0">
                          AWS Storage Audios Path (Ex: /path/to )
                        </label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="aws_audio_path"
                            name="aws_audio_path"
                            value={editUser?.aws_audio_path}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="mt-2 text-start">
                        <label className="m-0">
                          AWS Transcode Path (Ex: /path/to )
                        </label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="aws_transcode_path"
                            name="aws_transcode_path"
                            value={editUser?.aws_transcode_path}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {editUser?.bunny_cdn_storage === 1 && (
                    <div>
                      <div className=" text-start mt-2">
                        <label className="m-0">Bunny CDN Region</label>
                        <div className="mt-2">
                          <input
                            type="text"
                            id="bunny_cdn_region"
                            name="bunny_cdn_region"
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            value={editUser?.bunny_cdn_region}
                          />
                        </div>
                      </div>
                      <div className="mt-2 text-start">
                        <label className="m-0">Bunny CDN FTP Access Key</label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="bunny_cdn_ftp_access_key"
                            name="bunny_cdn_ftp_access_key"
                            value={editUser?.bunny_cdn_ftp_access_key}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>

                      <div className="mt-2 text-start">
                        <label className="m-0">
                          Bunny CDN Account Access Key
                        </label>

                        <div className="mt-2">
                          <input
                            type="text"
                            id="bunny_cdn_access_key"
                            name="bunny_cdn_access_key"
                            value={editUser?.bunny_cdn_access_key}
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default StorageSettings;
