// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video_schedule_calender a {
  color: #000000;
}
.video_schedule_calender a:hover {
  color: #000000;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Video/Video_Management/Videoschedule.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB","sourcesContent":[".video_schedule_calender a {\n  color: #000000;\n}\n.video_schedule_calender a:hover {\n  color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
