import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { MdDelete } from "react-icons/md";
import "./Videoschedule.css";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";

function Videoschedule(props) {
  const [getVideoSchedule, setVideoSchedule] = useState([]);
  console.log(getVideoSchedule);
  const [itemToDeleteVideoSchedule, setItemToDeleteVideoSchedule] = useState(
    []
  );
  const [selectAllVideoSchedule, setSelectAllVideoSchedule] = useState(false);
  const [modalCloseVideoSchedule, setModalCloseVideoSchedule] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [loading, setLoading] = useState(true);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Headers":
      "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "*",
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}/admin/video-schedule/index`,
          { headers: headers }
        );
        setVideoSchedule(response?.data?.VideoSchedules?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // const fetchDatavideo = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_Baseurl}/admin/video-schedule/index`,
  //       { headers: headers }
  //     );
  //     setVideoSchedule(response?.data?.VideoSchedules?.data);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   }
  // };

  // useEffect(async () => {
  //   fetchDatavideo();
  // }, []);
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(getVideoSchedule);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setVideoSchedule(reorderedItems);
  };

  const handleSelectAllVideofile = () => {
    setSelectAllVideoSchedule(!selectAllVideoSchedule);
    setItemToDeleteVideoSchedule(
      selectAllVideoSchedule ? [] : getVideoSchedule?.map((item) => item?.id)
    );
  };

  const handleMultiSelectVideoFile = (itemId) => {
    if (itemToDeleteVideoSchedule?.includes(itemId)) {
      setItemToDeleteVideoSchedule(
        itemToDeleteVideoSchedule?.filter((id) => id !== itemId)
      );
    } else {
      setItemToDeleteVideoSchedule([...itemToDeleteVideoSchedule, itemId]);
    }
  };

  const singleDeleteVideoSchedule = (id) => {
    setItemToDeleteVideoSchedule([id]);
    setModalCloseVideoSchedule(true);
    setSelectAllVideoSchedule(false);
  };

  const closeVideoScheduleModal = () => {
    setModalCloseVideoSchedule(!modalCloseVideoSchedule);
  };

  const openModalMultiDeleteVideoSchedule = () => {
    setModalCloseVideoSchedule(true);
  };

  const handleDeleteVideoScheduleData = () => {
    closeVideoScheduleModal();
    VideoScheduleDeleteOperation();
  };

  const VideoScheduleDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteVideoSchedule + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/video-schedule/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <>
      <div
        className={`modal  packageModel ${modalCloseVideoSchedule ? "show" : ""
          }`}
        tabIndex="-1"
        style={{ display: modalCloseVideoSchedule ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeVideoScheduleModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteVideoSchedule?.length === 1 ? (
                <p className="fs-6">
                  Are you sure you want to delete this video category?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected video category ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={closeVideoScheduleModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteVideoScheduleData}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-6">
              <h4>
                Video Schedule{" "}
                <span className="fs-6">
                  (Display all {getVideoSchedule?.length} Video Schedule)
                </span>
              </h4>
            </div>

            <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
              {itemToDeleteVideoSchedule?.length > 0 && (
                <button
                  type="button"
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModalMultiDeleteVideoSchedule}
                >
                  <MdDelete className="exportIcon " />
                  Delete
                </button>
              )}
              <Link
                to="/add-schedule"
                className="btn btn-primary fw-bolder"
              >
                <i className="fa fa-plus-circle"></i> Add Video Schedule
              </Link>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="bootstrapTable ">
              <div className="col-12 p-0">
                <div className="row m-0 p-0">
                  <div className="col-1 col-sm-1 col-lg-1 data">
                    {" "}
                    <label>
                      <input
                        type="checkbox"
                        checked={selectAllVideoSchedule}
                        onChange={handleSelectAllVideofile}
                      />
                    </label>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3 data">
                    {" "}
                    <div>Name</div>
                  </div>
                  <div className="col-3 col-md-3 col-lg-3  data">
                    {" "}
                    <div>Description</div>
                  </div>
                  <div className="col-4 col-md-4 col-lg-4  data">
                    {" "}
                    <div>Video Schedule</div>
                  </div>
                  <div className="col-1 col-md-1 col-lg-1   data">
                    {" "}
                    <div>Actions</div>
                  </div>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="menu-items">
                    {(provided) => (
                      <ul {...provided.droppableProps} ref={provided.innerRef}>
                        {loading ? (
                          <div className="noRecord">
                            {" "}
                            <Response_Loading_processing></Response_Loading_processing>
                            Loading...
                          </div>
                        ) : getVideoSchedule?.length <= 0 ? (
                          <div className="noRecord">
                            {" "}
                            <p>No Video Schedule Available !!! </p>
                          </div>
                        ) : (
                          getVideoSchedule?.map((item, index) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`bg-white orderborder ${item?.order
                                } index ${index + 1}w-100`}
                            >
                              <div className="row m-0 p-0 align-items-center">
                                <div className="col-1 col-sm-1 col-lg-1 data_th">
                                  <input
                                    type="checkbox"
                                    checked={itemToDeleteVideoSchedule.includes(
                                      item?.id
                                    )}
                                    onChange={() =>
                                      handleMultiSelectVideoFile(item?.id)
                                    }
                                  />
                                </div>
                                <div className="col-3 col-sm-3 col-lg-3 data_th">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <img
                                      src={item?.image}
                                      alt="image"
                                      className="profile"
                                    />
                                    <div className="rightVideoTitle ms-2">
                                      <p>{item?.name}</p>
                                      <span className="dateText">
                                        {item?.upload_on}
                                      </span>
                                    </div>
                                  </div>
                                </div>{" "}
                                <div className="col-3 col-md-3 col-lg-3 data_th">
                                  <div>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item?.description,
                                      }}
                                    ></p>
                                  </div>
                                </div>
                                <div className="col-4 col-md-4 col-lg-4  data_th">
                                  <div className="video_schedule_calender">
                                    <Link
                                      to={"/manage/schedule/" + item?.id}
                                    >
                                      <i
                                        className="fa fa-calendar"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Manage Schedule
                                    </Link>
                                  </div>
                                </div>
                                <div className="col-1 col-md-1 col-lg-1  data_th">
                                  <div className="editdropdown ">
                                    <span className="editdropdown-button">
                                      <span>
                                        <i
                                          className="fa fa-ellipsis-v"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </span>
                                    <div className="editdropdown-menu ">
                                      <Link
                                        to={"/edit-schedule/" + item?.id}
                                      >
                                        <span>
                                          <img
                                            src={edititem}
                                            alt="flogo"
                                            width={20}
                                            height={20}
                                          />
                                          <span className="ms-2">
                                            Edit Schedule
                                          </span>
                                        </span>
                                      </Link>
                                      <div
                                        onClick={() =>
                                          singleDeleteVideoSchedule(item?.id)
                                        }
                                        className="commonActionPadding"
                                      >
                                        <img
                                          src={deleteitem}
                                          alt="flogo"
                                          width={20}
                                          height={20}
                                        />
                                        <span className="ms-2">
                                          Delete Schedule
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))
                        )}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Videoschedule;
