import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import JoditEditor from "jodit-react";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import "./PopupSettings.css";

function PopupSettings(props) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [popup_enable, setPopup_enable] = useState("");
  const [popup_header, setPopup_header] = useState("");
  const [popup_footer, setPopup_footer] = useState("");
  const [popup_content, setPopup_content] = useState("");
  const [popup_image, setPopup_image] = useState("");
  const [after_login_link, setAfter_login_link] = useState("");
  const [before_login_link, setBefore_login_link] = useState("");
  const [popup_image_link, setPopup_image_link] = useState("");
  const [loading, setLoading] = useState(true);

  const [editUser, setEditUser] = useState({
    popup_header: "",
    popup_footer: "",
    popup_content: "",
    editUser: "",
    before_login_link: "",
    after_login_link: "",
    popup_image: "",
    popup_image_link: "",
  });

  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/home-popup`, {
        headers: headers,
      })
      .then((res) => {
        setEditUser(res?.data?.Home_pop_up[0]);
        setPopup_image_link(res?.data?.Home_pop_up[0]?.popup_image_link);
        setLoading(false);
      });
  }, []);

  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  const handleChangeFirstdes = (e) => {
    setEditUser({ ...editUser, popup_content: e });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagepopup_header("");
    setIsValidationHiddenpopup_header(true);
    setValidationMessagepopup_footer("");
    setIsValidationHiddenpopup_footer(true);
    setValidationMessageafter_login_link("");
    setIsValidationHiddenafter_login_link(true);
    setValidationMessagebefore_login_link("");
    setIsValidationHiddenbefore_login_link(true);
    setValidationMessagepopup_content("");
    setIsValidationHiddenpopup_content(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagepopup_header, setValidationMessagepopup_header] =
    useState("");
  const [isValidationHiddenpopup_header, setIsValidationHiddenpopup_header] =
    useState(true);

  const [validationMessagepopup_footer, setValidationMessagepopup_footer] =
    useState("");
  const [isValidationHiddenpopup_footer, setIsValidationHiddenpopup_footer] =
    useState(true);
  const [
    validationMessagebefore_login_link,
    setValidationMessagebefore_login_link,
  ] = useState("");
  const [
    isValidationHiddenbefore_login_link,
    setIsValidationHiddenbefore_login_link,
  ] = useState(true);

  const [
    validationMessageafter_login_link,
    setValidationMessageafter_login_link,
  ] = useState("");
  const [
    isValidationHiddenafter_login_link,
    setIsValidationHiddenafter_login_link,
  ] = useState(true);
  const [validationMessagepopup_content, setValidationMessagepopup_content] =
    useState("");
  const [isValidationHiddenpopup_content, setIsValidationHiddenpopup_content] =
    useState(true);

  const popup_headerInputRef = useRef(null);
  const popup_footerInputRef = useRef(null);
  const after_login_linkInputRef = useRef(null);
  const before_login_linkInputRef = useRef(null);
  const popup_contentInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function updatesettings() {
    let focusInputRef = null;

    if (editUser?.popup_header === "") {
      setValidationMessagepopup_header("Header cannot be empty.");
      setIsValidationHiddenpopup_header(false);
      focusInputRef = popup_headerInputRef;
    }
    if (editUser?.popup_footer === "") {
      setValidationMessagepopup_footer("Footer cannot be empty.");
      setIsValidationHiddenpopup_footer(false);
      focusInputRef = popup_footerInputRef;
    }
    if (editUser?.before_login_link === "") {
      setValidationMessagebefore_login_link("Before Login cannot be empty.");
      setIsValidationHiddenbefore_login_link(false);
      focusInputRef = before_login_linkInputRef;
    }
    if (editUser?.after_login_link === "") {
      setValidationMessageafter_login_link("After Login cannot be empty.");
      setIsValidationHiddenafter_login_link(false);
      focusInputRef = after_login_linkInputRef;
    }
    if (editUser?.popup_content === "") {
      setValidationMessagepopup_content("Page Content cannot be empty.");
      setIsValidationHiddenpopup_content(false);
      focusInputRef = popup_contentInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const formData = new FormData();
    const popup_enabledata = Number.isNaN(parseInt(editUser?.popup_enable, 10)) ? 0 : parseInt(editUser?.popup_enable, 10);

    formData.append("id", id);
    formData.append("popup_enable", popup_enabledata);
    formData.append("popup_header", editUser?.popup_header);
    formData.append("popup_footer", editUser?.popup_footer);
    formData.append("popup_content", editUser?.popup_content);
    formData.append("popup_image", editUser?.popup_image);
    formData.append("after_login_link", editUser?.after_login_link);
    formData.append("before_login_link", editUser?.before_login_link);
    formData.append("popup_image_link", editUser?.popup_image_link);
    formData.append("popup_image", selectedFile1);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/home-popup-update`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;

        resultapi = response.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      setValidationMessagepopup_header("");
      setValidationMessagepopup_footer("");
      setValidationMessageafter_login_link("");
      setValidationMessagebefore_login_link("");
      setValidationMessagepopup_content("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi && resultapi.status === false) {
        var resultError = resultapi;
        console.log(resultError);
        props.setApiresponsealert(resultError);

        // Clear individual validation messages
        setIsValidationHiddenpopup_header(true);
        setIsValidationHiddenafter_login_link(true);
        setIsValidationHiddenbefore_login_link(true);
        setIsValidationHiddenpopup_content(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  // async function stream() {
  //   console.log(
  //     "append data",
  //     id,
  //     editUser,
  //     popup_header,
  //     popup_footer,
  //     popup_content,
  //     popup_image,
  //     after_login_link,
  //     before_login_link,
  //     popup_image_link
  //   );

  //   const formData = new FormData();

  //   formData.append("id", id);
  //   formData.append("editUser", editUser);
  //   formData.append("popup_header", popup_header);
  //   formData.append("popup_footer", popup_footer);
  //   formData.append("popup_content", popup_content);
  //   formData.append("popup_image", popup_image);
  //   formData.append("after_login_link", after_login_link);
  //   formData.append("before_login_link", before_login_link);
  //   formData.append("popup_image_link", popup_image_link);

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };

  //   await axios
  //     .post(
  //       `${process.env.REACT_APP_Baseurl}/admin/home-popup-update`,
  //       formData,
  //       { headers: headers }
  //     )
  //     .then((response) => {
  //       // console.log('api' , response);
  //       if (response.data.status === true) {
  //         var result = response.data;
  //         // console.log('result', response);
  //         // navigate('/age/index')
  //         window.location.reload();
  //         alert("Added successfully  !");
  //       } else {
  //         console.log("Error");
  //         alert("Enter Correct Details");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Error");
  //       alert("Enter Correct Details");
  //     });
  // }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Pop-Up Settings</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={updatesettings} className="btn btn-primary">
                    Update Settings
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Pop-Up</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Header<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="popup_header"
                        name="popup_header"
                        placeholder="Enter Name"
                        onChange={handleInput}
                        value={editUser?.popup_header}
                        ref={popup_headerInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenpopup_header && (
                        <p>{validationMessagepopup_header}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Footer <span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="popup_footer"
                        name="popup_footer"
                        placeholder="Enter Email"
                        value={editUser?.popup_footer}
                        onChange={handleInput}
                        ref={popup_footerInputRef}
                      />
                    </div>

                    <span className="errorred">
                      {!isValidationHiddenpopup_footer && (
                        <p>{validationMessagepopup_footer}</p>
                      )}
                    </span>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Before Login Link<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="before_login_link"
                        name="before_login_link"
                        placeholder="Enter Mobile"
                        value={editUser?.before_login_link}
                        onChange={handleInput}
                        ref={before_login_linkInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenbefore_login_link && (
                        <p>{validationMessagebefore_login_link}</p>
                      )}
                    </span>
                  </div>

                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">
                        After Login Link<span className="mandatory">*</span>
                      </label>

                      <div className="mt-2">
                        <input
                          type="text"
                          autoComplete="off"
                          className="rs-input form-control-lg"
                          id="after_login_link"
                          name="after_login_link"
                          placeholder="Enter Email"
                          value={editUser?.after_login_link}
                          onChange={handleInput}
                          ref={after_login_linkInputRef}
                        />
                        <span className="errorred">
                          {!isValidationHiddenafter_login_link && (
                            <p>{validationMessageafter_login_link}</p>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">
                      Page Content<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <JoditEditor
                        className="rs-input"
                        value={editUser?.popup_content}
                        onChange={(e) => {
                          handleChangeFirstdes(e);
                        }}
                        ref={popup_contentInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenpopup_content && (
                        <p>{validationMessagepopup_content}</p>
                      )}
                    </span>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={updatesettings} className="btn btn-primary">
                    Update Settings
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Pop-up Image</label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.popup_image_link}
                          alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(1)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card p-3">
                  <h5>Status Setting</h5>
                  <hr></hr>

                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-body d-flex justify-content-between">
                      <label className="">Enable Home Page Pop Up </label>
                      <label className="switch">
                        <input
                          name="popup_enable"
                          onChange={handleInputenable}
                          defaultChecked={
                            editUser?.popup_enable === 1 ? true : false
                          }
                          checked={editUser?.popup_enable === 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="popup_enable"
                          onChange={handleInputenable}
                          value={editUser?.popup_enable === 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div id="content-page" className="p-2">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title p-1"> Pop-up </h4>
                      </div>
                    </div>
                    <hr />

                    <div className="iq-card-body ">
                      <div className="form-card">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="row py-3">
                              <div className="col-sm-6 form-group">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-3">
                                      {" "}
                                      Enable Home Page Pop Up :{" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-3 form-group">
                                <div className="mt-1 d-flex align-items-center ">
                                 
                                  <label className="switch">
                                    <input
                                      name="editUser"
                                      onChange={handleInput}
                                      defaultChecked={
                                        editUser?.editUser === 1 ? true : false
                                      }
                                      checked={
                                        editUser?.editUser === 1 ? true : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="editUser"
                                      onChange={handleInput}
                                      value={
                                        editUser?.editUser === 1 ? "1" : "0"
                                      }
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-sm-6 form-group">
                                <label className="m-0"> Header :</label>
                                <input
                                  type="text"
                                  className="form-control summary-ckeditor"
                                  name="popup_header"
                                  id="popup_header"
                                  placeholder="Pop-up Header"
                                  onChange={(e) =>
                                    setPopup_header(e.target.value)
                                  }
                                />
                              </div>

                              <div className="col-sm-6 form-group">
                                <label className="m-0">Footer :</label>
                                <input
                                  type="text"
                                  className="form-control summary-ckeditor"
                                  name="popup_footer"
                                  id="popup_footer"
                                  placeholder="Pop-up Footer"
                                  onChange={(e) =>
                                    setPopup_footer(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-sm-6 form-group">
                                <label className="m-0">
                                  {" "}
                                  Before Login Link :
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="before_login_link"
                                  id="before_login_link"
                                  placeholder="Before login link"
                                  onChange={(e) =>
                                    setBefore_login_link(e.target.value)
                                  }
                                />
                              </div>

                              <div className="col-sm-6 form-group">
                                <label className="m-0">
                                  {" "}
                                  After Login Link :{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="after_login_link"
                                  id="after_login_link"
                                  placeholder="After login link"
                                  onChange={(e) =>
                                    setAfter_login_link(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-sm-6 form-group">
                                <label className="m-0"> Pop-up Image : </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="popup_image"
                                  onChange={(e) =>
                                    setPopup_image(e.target.files[0])
                                  }
                                  id="popup_image"
                                />
                              </div>

                              <div className="col-sm-6 d-flex">
                                <img
                                  src={popup_image_link}
                                  width="100"
                                  alt="pop-up Image"
                                />
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-sm-12 form-group">
                                <label className="m-0"> Content :</label>
                               
                                <textarea
                                  name="popup_content"
                                  className="form-control summary-ckeditor"
                                  id="popup_content"
                                  onChange={(e) =>
                                    setPopup_content(e.target.value)
                                  }
                                >
                                  {" "}
                                </textarea>
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-sm-12 text-end">
                                <input
                                  type="submit"
                                  className="btn btn-primary"
                                  id=""
                                  defaultValue="update"
                                  onClick={stream}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default PopupSettings;
