import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import axios from "axios";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.css";

import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";
import edititem from "../../../components/Images/edititem.svg";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function ChannelPartnerApproval() {
  const [pendingUser, setPendingUser] = useState([]);
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchRole = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel-partner/pending-index`,
        { headers: headers }
      )
      .then((response) => {
        var result = response?.data?.pending_users;
        const updatedList = result.map((item) => {
          return { ...item, created_at: formatDate(item?.created_at) };
        });
        setPendingUser(updatedList);
        setFilteredData(updatedList);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(async () => {
    fetchRole();
  }, []);
  const conditionalGeneratePDF = (get) => {
    const doc = new jsPDF();
    doc.text("CHANNEL PARTNER PENDING LIST", 10, 10);

    const columns = [
      "Index",
      "ID",
      "CHANEL NAME",
      "EMAIL ID",
      "MOBILE NUMBER",
      "STATUS",
    ];
    const allDataPDF = pendingUser.map((item, index) => [
      index + 1,
      item?.id,
      item?.channel_name,
      item?.email,
      item?.mobile_number,
      item?.status,
    ]);
    const filterPDF = filteredData.map((item, index) => [
      index + 1,
      item?.id,
      item?.channel_name,
      item?.email,
      item?.mobile_number,
      item?.status,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("CHANNEL-PARTNER-PENDING-LIST.pdf");
  };

  const generateXLSX = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(
        get === 1 ? pendingUser && pendingUser : filteredData && filteredData
      );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "CHANNEL-PARTNER-PENDING-LIST.xlsx");
  };

  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/channel/edit-view-channel-members/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Edit Channel</span>
            </span>
          </Link>
        </div>
      </div>
    );
  };

  const handleFilterChange = (event) => {
    const searchTerm = event.target.value;
    setFilter(searchTerm);
    const searchTermLower = searchTerm.toLowerCase();
    const filteredResults = pendingUser?.filter((item) => {
      return (
        (typeof item?.created_at === "string" &&
          item?.created_at.toLowerCase().includes(searchTermLower)) ||
        item?.channel_name.toLowerCase().includes(searchTermLower) ||
        (typeof item?.email === "string" &&
          item?.email.toLowerCase().includes(searchTermLower)) ||
        (typeof item?.mobile_number == "string" &&
          item?.mobile_number.toLowerCase().includes(searchTermLower)) ||
        (typeof item?.id === "number" &&
          item?.id.toString().includes(searchTerm))
      );
    });
    setFilteredData(filteredResults);
  };

  const imageFormatter = (data, row) => {
    return <img src={data} className="profile" />;
  };

  const statusFormatter = (data, row) => {
    return (
      <div
        className={`font-weight-bolder   d-flex justify-content-center ${
          data === 0 && "DISABLED_active"
        }`}
      >
        {data == 0 && "PENDING"}
      </div>
    );
  };
  const videoFormatter = (data, row) => {
    return (
      <video
        width="100"
        height="100"
        id="videoPlayer"
        className=""
        controls
        data-setup='{"controls": true, "aspectRatio":"16:9", "fluid": true}'
        src={data}
        type="video/mp4"
      ></video>
    );
  };

  const userFormatter = (data, row) => {
    return (
      <div className="d-flex flex-column align-items-start">
        <span>{data}</span>
        <span className="dateText"> {formatDate(row.created_at)}</span>
      </div>
    );
  };
  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
    return `${formattedDate} ${formattedTime}`;
  }

  const exportHeaders = [
    { label: "ID", key: "id" },
    { label: "CHANNEL NAME", key: "channel_name" },
    { label: "EMAIL", key: "email" },
    { label: "MOBILE NUMBER", key: "mobile_number" },
    { label: "PROFILE", key: "channel_logo" },
    { label: "STATUS", key: "status" },
  ];
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "channel_logo",
      text: "Profile",
      formatter: imageFormatter,
    },
    {
      dataField: "channel_name",
      text: "Channel Name",
      formatter: userFormatter,
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "mobile_number",
      text: "Mobile Number",
      sort: true,
      formatter: (cell) => {
        return cell || "";
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: statusFormatter,
    },
    {
      dataField: "intro_video",
      text: "Intro Video",
      formatter: videoFormatter,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredData.length },
    ],
  });

  const handleClear = () => {
    fetchRole();
    setFilter("");
  };
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card-header d-flex justify-content-between pb-3">
                <div className="iq-header-title">
                  <h4 className="">
                    Channel Pending Lists
                    <span className="fs-6">
                      {" "}
                      (Display all {pendingUser?.length} Moderators)
                    </span>
                  </h4>
                </div>
              </div>

              <div className="iq-card">
                <div
                  className="panel panel-primary category-panel"
                  data-collapsed="0"
                >
                  <div className="panel-body mt-3">
                    <div id="nestable" className="nested-list dd with-margins">
                      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                        <div className="filterContainer">
                          <input
                            type="text"
                            placeholder="Filter all.."
                            className="filter form-control "
                            value={filter}
                            onChange={handleFilterChange}
                          />
                          {filter?.length > 0 && (
                            <button
                              type="button"
                              className="closeBtn"
                              onClick={handleClear}
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          )}
                        </div>
                        <div className="btn-group">
                          <div className="dropdown d-block " key={"2"}>
                            <button
                              className="btn btn-success dropdown-toggle d-flex"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="fw-bolder">Export </span>
                              <img
                                src={ExportIcon}
                                className="ms-2 exportIcon"
                              />
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="dropdown-item">
                                <CSVLink
                                  headers={exportHeaders}
                                  data={filteredData}
                                  className="text-dark d-block"
                                  filename="CHANNEL-PARTNER-PENDING-LIST.csv"
                                >
                                  CSV
                                </CSVLink>
                              </li>
                              <li
                                className="dropdown-item"
                                onClick={() => {
                                  conditionalGeneratePDF(0);
                                }}
                              >
                                PDF
                              </li>
                              <li
                                className="dropdown-item"
                                onClick={() => {
                                  generateXLSX(0);
                                }}
                              >
                                XLSX
                              </li>
                            </ul>
                          </div>

                          <div className="dropdown d-block ">
                            <button
                              className="btn btn-success dropdown-toggle d-flex"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="fw-bolder">Export All</span>
                              <img
                                src={ExportIcon}
                                className="ms-2 exportIcon"
                              />
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="dropdown-item">
                                <CSVLink
                                  headers={exportHeaders}
                                  data={pendingUser}
                                  className="text-dark d-block"
                                  filename="CHANNEL-PARTNER-PENDING-LIST.csv"
                                >
                                  CSV
                                </CSVLink>
                              </li>
                              <li
                                className="dropdown-item"
                                onClick={() => {
                                  conditionalGeneratePDF(1);
                                }}
                              >
                                PDF
                              </li>
                              <li
                                className="dropdown-item"
                                onClick={() => {
                                  generateXLSX(1);
                                }}
                              >
                                XLSX
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="bootstrapTable">
                        <BootstrapTable
                          keyField="id"
                          columns={columns}
                          data={filteredData}
                          pagination={pagination}
                          className="no-border-table"
                          noDataIndication={
                            <div className="noRecord">
                              {loading ? (
                                <label>
                                  {" "}
                                  <Response_Loading_processing></Response_Loading_processing>
                                  Loading...
                                </label>
                              ) : (
                                filteredData.length <= 0 && (
                                  <p>No Record Found!</p>
                                )
                              )}
                            </div>
                          }
                          hover
                          headerClasses="custom-header"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ChannelPartnerApproval;
