import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import "./Media_Library.css";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

import deleteitem from "../../components/Images/deleteitem.svg";
import edititem from "../../components/Images/edititem.svg";
import Response_Loading_processing from "../../../src/layout/Others/Response_Loading_processing";
import ExportIcon from "../../components/Images/icons/Export_Icon_01.png";
import { useTranslation } from 'react-i18next';

function Media_Library(props) {
  const { t } = useTranslation();
  const [media_library, setMedia_library] = useState([
    {
      id: 166,
      title: "video (2160p).mp4",
      slug: "video-2160pmp4",
      type: "mp4_video_upload",
      uploaded_by: "Admin",
      draft: 1,
      active: 1,
      duration: "00:02:00",
      status: 1,
      publish_type: "publish_now",
      mp4_url:
        "https://api.flicknexs.com/storage/app/public/video_uploads/F7kS4ckGstLVsYhiPl5i1Tis3sQUO8zZeYsUxNFd.mp4",

      Thumbnail:
        "https://api.flicknexs.com/public/uploads/images/video-image-1707116114.webp",
    },
    {
      id: 165,
      title: "sample-mp4-file.mp4",
      slug: null,
      type: "mp4_video_upload",
      uploaded_by: "Admin",
      draft: 1,
      active: 0,
      status: 0,
      duration: "00:02:00",
      publish_type: "publish_later",
      mp4_url:
        "https://api.flicknexs.com/storage/app/public/video_uploads/wjMqAzsvASuNkQQJbAC7KOqWIjIu8g5GNMo5qQqL.mp4",
      Thumbnail:
        "https://api.flicknexs.com/public/uploads/images/default_vertical_image.png",
    },
    {
      id: 164,
      title: "sample-mp4-file.mp4",
      slug: null,
      type: "mp4_video_upload",
      uploaded_by: "Admin",
      draft: 1,
      duration: "00:02:00",
      active: 0,
      status: 0,
      publish_type: "publish_later",
      mp4_url:
        "https://api.flicknexs.com/storage/app/public/video_uploads/il5cpXTysr3XrncdYNPEh8woWpDVnMfvlKy5QP79.mp4",
      Thumbnail:
        "https://api.flicknexs.com/public/uploads/images/default_vertical_image.png",
    },
  ]);
  const [filteredData, setFilteredData] = useState([
   {
      id: 166,
      title: "video (2160p).mp4",
      slug: "video-2160pmp4",
      type: "mp4_video_upload",
      uploaded_by: "Admin",
      draft: 1,
      active: 1,
      duration: "00:02:00",
      status: 1,
      publish_type: "publish_now",
      mp4_url:
        "https://api.flicknexs.com/storage/app/public/video_uploads/F7kS4ckGstLVsYhiPl5i1Tis3sQUO8zZeYsUxNFd.mp4",

      Thumbnail:
        "https://api.flicknexs.com/public/uploads/images/video-image-1707116114.webp",
    },
    {
      id: 165,
      title: "sample-mp4-file.mp4",
      slug: null,
      type: "mp4_video_upload",
      uploaded_by: "Admin",
      draft: 1,
      active: 0,
      duration: "00:02:00",
      status: 0,
      publish_type: "publish_later",
      mp4_url:
        "https://api.flicknexs.com/storage/app/public/video_uploads/wjMqAzsvASuNkQQJbAC7KOqWIjIu8g5GNMo5qQqL.mp4",
      Thumbnail:
        "https://api.flicknexs.com/public/uploads/images/default_vertical_image.png",
    },
    {
      id: 164,
      title: "sample-mp4-file.mp4",
      slug: null,
      type: "mp4_video_upload",
      uploaded_by: "Admin",
      draft: 1,
      duration: "00:02:00",
      active: 0,
      status: 0,
      publish_type: "publish_later",
      mp4_url:
        "https://api.flicknexs.com/storage/app/public/video_uploads/il5cpXTysr3XrncdYNPEh8woWpDVnMfvlKy5QP79.mp4",
      Thumbnail:
        "https://api.flicknexs.com/public/uploads/images/default_vertical_image.png",
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [modelClose, setModelClose] = useState(false);
  const [filter, setFilter] = useState("");

  // const [defaultImage, setDefaultimage] = useState();
  // const [defaultImageurl, setDefaultimageurl] = useState("");
  const [itemToDelete, setItemToDelete] = useState([]);
  const [page, setPage] = useState(1);
  const access_token = localStorage.getItem("access_token");

  const handleClear = () => {
    fetchData();
    setFilter("");
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDelete(selectAll ? [] : filteredData?.map((item) => item?.id));
  };
  const handleFilterChange = (event) => {
    const searchTerm = event.target.value;
    setFilter(searchTerm);
    const searchTermLower = searchTerm?.toLowerCase();
    const filteredResults = media_library?.filter((item) => {
      return (
        (typeof item?.title === "string" &&
          item?.title.toLowerCase().includes(searchTermLower)) ||
        (typeof item?.uploaded_by === "string" &&
          item?.uploaded_by.toLowerCase().includes(searchTermLower)) ||
        (typeof item?.active === "string" &&
          item?.active.toLowerCase().includes(searchTermLower)) ||
        (typeof item?.upload_on === "string" &&
          item?.upload_on.toLowerCase().includes(searchTermLower))
      );
    });
    setFilteredData(filteredResults);
  };

  const conditionalGeneratePDF = (get) => {
    const doc = new jsPDF();
    doc.text("VIDEO-LIST", 10, 10);
    const columns = ["Index", "ID", "uploaded_by", ];
    const allDataPDF = media_library?.map((item, index) => [
      index + 1,
      item?.id,
      item?.uploaded_by,
      item?.active,
      item?.banner,
    ]);
    const filterPDF = filteredData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.uploaded_by,
      item?.active,
      item?.banner,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save("VIDEO-LIST.pdf");
  };

  const generateXLSX = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? media_library : filteredData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "VIDEO-LIST.xlsx");
  };

  const exportHeaders = [
    { label: "ID", key: "id" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "STATUS", key: "active" },
    { label: "SLIDER", key: "banner" },
  ];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/view-library/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2">View Video</span>
            </span>
          </Link>
          <div
            onClick={() => singleDelete(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2">Delete Video</span>
          </div>
        </div>
      </div>
    );
  };

  const videoFormatter = (data, row) => {
    return (
      <div className="d-flex align-items-center">
        <img src={data} className="profile" alt="image_all" />
        <div className="rightVideoTitle-library" style={{marginLeft: "10px"}}>
          <p>{row?.title} </p>
          <span className="me-1 text-dark dateText ">{row?.duration}</span>
          <span className="dateText"> {row?.upload_on}</span>
          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`/view-library/${row?.id}`}
                      >
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-eye"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  // const statusFormatter = (data, row) => {
  //   return (
  //     <div
  //       className={`  font-weight-bolder  d-flex justify-content-center ${
  //         data === "PUBLISHED"
  //           ? "PUBLISHED_active"
  //           : data === "SCHEDULED"
  //           ? " DISABLED_active"
  //           : "UNPUBLISHED_active"
  //       }`}
  //     >
  //       {data}
  //     </div>
  //   );
  // };
  // const sliderFormatter = (data, row) => {
  //   return (
  //     <div>
  //       <label className="switch">
  //         <input
  //           name="banner"
  //           id="banner"
  //           className={data === 0 ? "inactive" : null}
  //           checked={data === 1}
  //           onChange={() => handleToggle(row)}
  //           onClick={() => handleToggle(row)}
  //           type="checkbox"
  //         />
  //         <span className="slider round"></span>
  //       </label>
  //     </div>
  //   );
  // };
  const columns = [
    {
      dataField: "Thumbnail",
      text: "Videos",
      formatter: videoFormatter,
      sort: true,
    },
    {
      dataField: "uploaded_by",
      text: "Upload by",
      sort: true,
    },
    // {
    //   dataField: "active",
    //   text: "Status",
    //   formatter: statusFormatter,
    // },
    // {
    //   dataField: "banner",
    //   text: "Slider",
    //   formatter: sliderFormatter,
    // },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const options = {
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: Number(filteredData?.length) },
    ],
  };
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDelete([...itemToDelete, row.id]);
        } else {
          setItemToDelete(itemToDelete?.filter((id) => id !== row?.id));
        }
      }
    },
    selected: itemToDelete,
    selectionHeaderRenderer: ({ mode, checked }) => (
      <div>
        <input type="checkbox" checked={checked} onChange={handleAll} />
      </div>
    ),
  };

  const handleConfirmDelete = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("video_id", "[" + itemToDelete + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/videos/multi-videos-delete`,
        formData,
        { headers: headers }
      );
      const result = response?.data;
      if (result?.status === true) {
        fetchData();
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/video-list`,
        { headers: headers }
      );
      const videosData = response?.data?.videos?.data;
      const updatedList = videosData?.map((item) => {
        const UploadStatus =
          item?.uploaded_by === "1"
            ? "Admin"
            : item?.uploaded_by === "4"
            ? "Channel Partner"
            : item?.uploaded_by === "5"
            ? "Content Partner"
            : "";
        const statusText =
          item?.active === 1 &&
          item?.publish_type === "publish_now" &&
          item?.status === 1 &&
          item?.draft === 1
            ? "PUBLISHED"
            : item?.active == null &&
              item?.publish_type === "publish_later" &&
              item?.status == null &&
              item?.draft == null
            ? "SCHEDULED"
            : "DRAFT";

        return { ...item, uploaded_by: UploadStatus, active: statusText };
      });
      // setMedia_library(updatedList);
      // setFilteredData(updatedList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleToggle = async (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const confirmToggle = async () => {
    setProcessing(true);
    setShowOverlay(true);
    try {
      setShowModal(false);
      const newStatus = currentItem?.banner === 1 ? 0 : 1;
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/videos-slider`,
        {
          method: "POST",
          body: JSON.stringify({
            video_id: currentItem?.id,
            banner_status: newStatus,
          }),
          headers: headers,
        }
      );
      setCurrentItem(null);
      props?.setApiresponsealert({
        status: true,
        message: "Switched the Slider status successfully",
      });
      props?.setShowalert(true);
      fetchData();
      setProcessing(false);
      setShowOverlay(false);
    } catch (err) {
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const closeModal = () => {
    setModelClose(!closeModal);
    setShowModal(false);
  };

  useEffect(async () => {
    return fetchData();
  }, [page]);

  const openModal = () => {
    setModelClose(true);
  };
  const singleDelete = (id) => {
    setModelClose(true);
    setItemToDelete([id]);
  };
  const handleDeletedata = () => {
    handleConfirmDelete();
    closeModal();
  };

  return (
    <>
      <div
        className={`modal packageModel ${showModal ? "show fade" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: `${showModal ? "block" : "none"}` }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Slider </h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body">
              Are you sure you want to Switch the Slider status?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={confirmToggle}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        {showOverlay && (
          <div className="overlay-container">
            <span className="overlay-icon">
              {" "}
              <Response_Loading_processing></Response_Loading_processing>
              Processing ...
            </span>
          </div>
        )}
      </div>
      <div
        className={`modal packageModel ${modelClose ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: `${modelClose ? "block" : "none"}` }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDelete?.length === 1 ? (
                <p> Are you sure you want to delete this item ?</p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected items ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeletedata}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className=" m-0 p-0">
        <div className="container-fluid">
          <section className="section all_media_library ">
            <div className="col-lg-12 row py-3">
              <div className="col-12 col-sm-6 col-lg-6">
                <h3>
                  {t("All Video")}
                  <span className="fs-6">
                    (Display all {media_library?.length} Videos)
                  </span>
                </h3>
              </div>

              <div className="col-md-6 col-12 col-sm-6  mt-3 mt-sm-0 text-end">
                {itemToDelete?.length > 0 && (
                  <button
                    className="btn btn-danger d-flex d-inline-flex  fw-bolder  "
                    onClick={openModal}
                  >
                    <MdDelete className="exportIcon " />
                    Delete
                  </button>
                )}
                {/* <Link to="/addnewvideo">
                  <button type="button" className="btn btn-primary fw-bolder">
                    <i className="fa fa-plus-circle"></i> Add Video
                  </button>
                </Link> */}
              </div>
            </div>

            <div className="col-lg-12 ">
              <div className="iq-card">
                <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                  <div className="filterContainer">
                    <input
                      type="text"
                      placeholder="Filter all.."
                      className="filter form-control "
                      value={filter}
                      onChange={handleFilterChange}
                    />
                    {filter?.length > 0 && (
                      <button
                        type="button"
                        className="closeBtn"
                        onClick={handleClear}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    )}
                  </div>
                  <div className="btn-group">
                    <div className="dropdown d-block " key={"2"}>
                      <button
                        className="btn btn-success dropdown-toggle d-flex"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="fw-bolder">Export </span>
                        <img
                          src={ExportIcon}
                          className="ms-2 exportIcon"
                          alt="exportIcon"
                        />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li className="dropdown-item">
                          <CSVLink
                            headers={exportHeaders}
                            data={filteredData}
                            className="text-dark d-block"
                            filename="VIDEO-LIST.csv"
                          >
                            CSV
                          </CSVLink>
                        </li>
                        <li
                          className="dropdown-item"
                          onClick={() => {
                            conditionalGeneratePDF(0);
                          }}
                        >
                          PDF
                        </li>
                        <li
                          className="dropdown-item"
                          onClick={() => {
                            generateXLSX(0);
                          }}
                        >
                          XLSX
                        </li>
                      </ul>
                    </div>

                    <div className="dropdown d-block ">
                      <button
                        className="btn btn-success dropdown-toggle d-flex"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="fw-bolder">Export All</span>
                        <img
                          src={ExportIcon}
                          className="ms-2 exportIcon"
                          alt="exportAllIcon"
                        />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li
                          className="dropdown-item"
                          onClick={() => setPage(0)}
                        >
                          <CSVLink
                            headers={exportHeaders}
                            data={media_library}
                            className="text-dark d-block"
                            filename="VIDEO-LIST.csv"
                          >
                            CSV
                          </CSVLink>
                        </li>
                        <li
                          className="dropdown-item"
                          onClick={() => {
                            conditionalGeneratePDF(1);
                          }}
                        >
                          PDF
                        </li>
                        <li
                          className="dropdown-item"
                          onClick={() => {
                            generateXLSX(1);
                          }}
                        >
                          XLSX
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <PaginationProvider pagination={paginationFactory(options)}>
                  {({ paginationTableProps }) => (
                    <div className="bootstrapTable">
                      <BootstrapTable
                        keyField="id"
                        columns={columns}
                        data={filteredData}
                        // pagination={pagination}
                        className="no-border-table"
                        noDataIndication={
                          <div className="noRecord">
                            {loading ? (
                              <label>
                                {" "}
                                <Response_Loading_processing></Response_Loading_processing>
                                Loading...
                              </label>
                            ) : (
                              filteredData?.length <= 0 && (
                                <p>No Record Found!</p>
                              )
                            )}
                          </div>
                        }
                        hover
                        headerClasses="videoWidth-library"
                        selectRow={selectRow}
                        selectAll={selectAll}
                        {...paginationTableProps}
                      />
                    </div>
                  )}
                </PaginationProvider>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Media_Library;
