// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video_schedule_media_tab .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
  color: #000000 !important;
  border-bottom: 2px #0d6efd solid;
  border-radius: 0 !important;
}
.video_schedule_media_tab .nav-pills .nav-link {
  color: #000000 !important;
}
/* .video_schedule_media_tab media-outlet {
  width: 180px !important;
} */
/* .video_schedule_media_tab media-player {
  overflow: hidden;
} */

.video_schedule_media_tab .overflow-media-outlet-page {
  overflow: scroll;
  max-height: 200px;
  overflow-x: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Video/Video_Management/EditVideoschedule.css"],"names":[],"mappings":"AAAA;;EAEE,wCAAwC;EACxC,yBAAyB;EACzB,gCAAgC;EAChC,2BAA2B;AAC7B;AACA;EACE,yBAAyB;AAC3B;AACA;;GAEG;AACH;;GAEG;;AAEH;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".video_schedule_media_tab .nav-pills .nav-link.active,\n.nav-pills .show > .nav-link {\n  background-color: transparent !important;\n  color: #000000 !important;\n  border-bottom: 2px #0d6efd solid;\n  border-radius: 0 !important;\n}\n.video_schedule_media_tab .nav-pills .nav-link {\n  color: #000000 !important;\n}\n/* .video_schedule_media_tab media-outlet {\n  width: 180px !important;\n} */\n/* .video_schedule_media_tab media-player {\n  overflow: hidden;\n} */\n\n.video_schedule_media_tab .overflow-media-outlet-page {\n  overflow: scroll;\n  max-height: 200px;\n  overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
