// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backarrow{
text-decoration: none;
color: #000000 !important;
font-size: 16px;
font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Settings/Site_Setting/site_setting.css"],"names":[],"mappings":"AAAA;AACA,qBAAqB;AACrB,yBAAyB;AACzB,eAAe;AACf,gBAAgB;AAChB","sourcesContent":[".backarrow{\ntext-decoration: none;\ncolor: #000000 !important;\nfont-size: 16px;\nfont-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
