import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.css";

import edititem from "../../../components/Images/edititem.svg";
import deleteitem from "../../../components/Images/deleteitem.svg";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
function ContentPayout() {
  const filteredData = [
    {
      id: 1,
      username: "admin",
      purchasecount: 404,
      totalcommission: 404,
      admincommission: 404,
      moderatorcommission: 404,
      paidamount: 404,
    },
    {
      id: 2,
      username: "Test",
      purchasecount: 500,
      totalcommission: 500,
      admincommission: 500,
      moderatorcommission: 500,
      paidamount: 500,
    },
  ];
  const openModal = (id, index) => {};
  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={""}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Edit</span>
            </span>
          </Link>
          <Link>
            <span onClick={() => openModal(row.id, 0)}>
              <img src={deleteitem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Delete</span>
            </span>
          </Link>
        </div>
      </div>
    );
  };
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredData.length },
    ],
  });

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "username",
      text: "Username Name",
      sort: true,
    },
    {
      dataField: "purchasecount",
      text: "Purchases Count",
    },
    {
      dataField: "totalcommission",
      text: "Total Commission",
      sort: true,
    },
    {
      dataField: "admincommission",
      text: "Admin Commission",
      sort: true,
    },
    {
      dataField: "moderatorcommission",
      text: "Moderator Commission",
      sort: true,
    },
    {
      dataField: "paidamount",
      text: "Paid Amount",
      sort: true,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-card-header d-flex justify-content-between pb-3 flex-wrap">
                <div className="col-md-6 col-12">
                  <h4 className="">
                    Channel Partners Payouts
                    <span className="fs-6"> (Display all {filteredData?.length})</span>
                  </h4>
                </div>
              </div>
              <div className="iq-card">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="">
                      <div className="bootstrapTable">
                        <BootstrapTable
                          keyField="id"
                          columns={columns}
                          data={filteredData}
                          pagination={pagination}
                          noDataIndication={"No Record Found!"}
                          hover
                          headerClasses="custom-header"
                          // selectRow={selectRow}
                          // selectAll={selectAll}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ContentPayout;
