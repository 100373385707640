import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function EmailSettingsTemplate(props) {

  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    template_type: "",
    heading: "",
    description: "",
    role_type: "",
  });
  const handleChangeFirstdes = (e) => {
    setEditUser({ ...editUser, description: e });
  };
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");


  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Email-Template/view/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Email_template;
      setEditUser(res);
      // console.log(resData)
      // console.log(res)
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };


  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const editInputvalue = {
      template_type: editUser.template_type,
      heading: editUser.heading,
      description: editUser.description,
      role_type: editUser.role_type,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Email-Template/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };



  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   const editInputvalue = {
  //     template_type: editUser.template_type,
  //     heading: editUser.heading,
  //     description: editUser.description,
  //     role_type: editUser.role_type,
  //   };
  //   // console.log(editInputvalue);
  //   let res = await fetch(
  //     `${process.env.REACT_APP_Baseurl}/admin/Email-Template/update/` + id,
  //     {
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify(editInputvalue),
  //     }
  //   );

  //   let resjson = await res.json();
  //   // console.log(resjson)
  //   if (res.status === 200) {
  //     setMessage(resjson.success);
  //     alert("Success");
  //     setTimeout(() => {
  //       navigate("/email_settings");
  //     }, 2000);
  //   } else {
  //     setMessage("Some error Occured");
  //     console.log("Error");
  //     alert("Enter Correct Details");
  //   }
  // };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Template</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Template
                  </button>
                </div>
              </div>
            </div>
            <div>
              {showOverlay && (<Response_Processing></Response_Processing>)}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-12 col-md-12 col-lg-12 ">
                <div className="card p-3">
                  {/* <h5>Template</h5>
                  <hr></hr> */}
                  <div className=" text-start">
                    <label className="m-0">Template Type</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="username"
                        name="template_type"
                        placeholder=""
                        value={editUser?.template_type}
                        onChange={handleInput}
                      />
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Subject</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="username"
                        name="heading"
                        placeholder=""
                        onChange={handleInput}
                        value={editUser?.heading}
                      />
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Page Content</label>
                    <div className="mt-2">
                      <JoditEditor
                        className="rs-input"
                        value={editUser?.description}
                        onChange={(e) => {
                          handleChangeFirstdes(e);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Template
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>


    </>
  );
}

export default EmailSettingsTemplate;
