import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import deleteitem from "../../../../components/Images/deleteitem.svg";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import backarrow from "../../../../components/Images/backarrow.svg";

function Rtmpurl(props) {

  const [editrtmp, setEditrtmp] = useState({ rtmp_url: "", hls_url: "" });

  const [rtmp, setRtmp] = useState([]);

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, {
        headers: headers,
      })
      .then((response) => {
        var rtmp_url = response?.data?.rtmp_url;
        var editrtmp = response?.data?.rtmp_url;
        setRtmp(rtmp_url);
        setEditrtmp(editrtmp);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagertmp_url("");
    setIsValidationHiddenrtmp_url(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [editUser, setEditUser] = useState({
    rtmp_url: "",
    hls_url: "",
  });

  const [validationMessagertmp_url, setValidationMessagertmp_url] =
    useState("");
  const [isValidationHiddenrtmp_url, setIsValidationHiddenrtmp_url] =
    useState(true);
  const [validationMessagehls_url, setValidationMessagehls_url] = useState("");
  const [isValidationHiddenhls_url, setIsValidationHiddenhls_url] =
    useState(true);

  const rtmp_urlInputRef = useRef(null);
  const hls_urlInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function handleUpdate() {
    let focusInputRef = null;

    if (editUser?.rtmp_url === "") {
      setValidationMessagertmp_url("RTMP URL cannot be empty.");
      setIsValidationHiddenrtmp_url(false);
      focusInputRef = rtmp_urlInputRef;
    } if (editUser?.hls_url === "") {
      setValidationMessagehls_url("HLS URL cannot be empty.");
      setIsValidationHiddenhls_url(false);
      focusInputRef = hls_urlInputRef;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    const formData = new FormData();

    formData.append("rtmp_url", editUser?.rtmp_url);
    formData.append("hls_url", editUser?.hls_url);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/setting/RTMP/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      setValidationMessagertmp_url("");
      setValidationMessagehls_url("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        // console.log(resultError);
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddenrtmp_url(false);
        setIsValidationHiddenhls_url(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  const deleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/setting/RTMP/delete/` + id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response?.data?.status === true) {
          var responseData = response?.data;
          props?.setApiresponsealert(responseData);
          props?.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props?.setApiresponsealert(resultError);
            props?.setShowalert(true);
          }
        }
      });
  };

  const [itemToDeletesubscription, setItemToDeletesubscription] =
    useState(null);
  const [successMessagesubscription, setSuccessMessagesubscription] =
    useState("");

  const openModalsubscription = (id) => {
    setItemToDeletesubscription(id);
    setSuccessMessagesubscription("");
  };

  const closeModalsubscription = () => {
    setItemToDeletesubscription(null);
  };
  const handleDeletedata = () => {
    closeModalsubscription();
    if (itemToDeletesubscription) {
      deleteOperation(itemToDeletesubscription);
    }
  };

  return (
    <div className=" m-0 p-0">
      <div
        className={`modal ${itemToDeletesubscription !== null ? "show" : ""}`}
        tabIndex="-1"
        style={{
          display: itemToDeletesubscription !== null ? "block" : "none",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModalsubscription}
              ></button>
            </div>
            <div className="modal-body">
              {successMessagesubscription ? (
                <div className="alert alert-success" role="alert">
                  {successMessagesubscription}
                </div>
              ) : (
                <p>Are you sure you want to delete this RTMP URL?</p>
              )}
            </div>
            <div className="modal-footer">
              {successMessagesubscription ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closeModalsubscription}
                >
                  Close
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModalsubscription}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeletedata}
                  >
                    Yes
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <section className="section  container-fluid">
          <div className="">
            <div className="col-lg-12 row py-3">
              <Link to="/settings" className="backarrow">
                <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                <span> Back</span>
              </Link>
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>RTMP Video Updating</h3>
              </div>
            </div>

            <div className="iq-card">
              <div className="" id="rtmp_url">

                <div>
                  {showOverlay && <Response_Processing></Response_Processing>}
                </div>

                <div className="">
                  <div className="row ">
                    <div className="col-md-12">
                      <div
                        className="panel panel-primary p-0"
                        data-collapsed="0"
                      >
                        {" "}
                        <div className="col-lg-12 row">
                          <div className="col-6 col-sm-6 col-lg-6">
                            <h3>RTMP URL</h3>
                          </div>

                          <div className="col-6 col-sm-6 col-lg-6 text-end">
                            <Link>
                              <button
                                type="button"
                                className="btn btn-success add"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                Add RTMP
                              </button>
                            </Link>
                          </div>
                        </div>
                        <div className="panel-heading">
                          <div
                            className="modal fade"
                            id="exampleModal"
                            tabindex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLabel"
                                  >
                                    ADD RTMP
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <div>
                                    <label>
                                      RTMP URL
                                      <span className="mandatory">*</span>
                                    </label>

                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="rtmp_url"
                                        placeholder="rtmp://75.119.145.126:1935/show/"
                                        className="rs-input form-control-lg"
                                        // onChange={(e) =>
                                        //   setRtmp_url(e.target.value)
                                        // }
                                        value={editUser?.rtmp_url}
                                        onChange={handleInput}
                                        ref={rtmp_urlInputRef}
                                      />
                                    </div>
                                    <span className="errorred">
                                      {!isValidationHiddenrtmp_url && (
                                        <p>{validationMessagertmp_url}</p>
                                      )}
                                    </span>
                                  </div>
                                  <div className="mt-2">
                                    <label>
                                      HLS URL
                                      <span className="mandatory">*</span>
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        name="hls_url"
                                        placeholder="http://75.119.145.126:9090/hls/handleUpdatekey/index.m3u8"
                                        className="rs-input form-control-lg"
                                        onChange={handleInput}
                                        value={editUser?.hls_url}
                                        ref={hls_urlInputRef}
                                      />
                                    </div>
                                    <span className="errorred">
                                      {!isValidationHiddenhls_url && (
                                        <p>{validationMessagehls_url}</p>
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  {/* <button  onClick={handleUpdate} >submit</button> */}
                                  <input
                                    type="submit"
                                    id="appupdate"
                                    value="Update RTMP Setting"
                                    onClick={handleUpdate}
                                    className="btn btn-primary ml-3 "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <table className="table mt-4" id="dynamicTable">

                            {rtmp?.map((item) => (
                              <thead key={item?.id}>
                                <tr className="mt-2">
                                  <td className="col-md-4">
                                    <input
                                      type="text"
                                      name="rtmp_url"
                                      value={item?.rtmp_url}
                                      // placeholder="rtmp://75.119.145.126:1935/show/"
                                      className="rs-input form-control-lg"
                                      readOnly
                                    />
                                  </td>
                                  <td className="col-md-4">
                                    <input
                                      type="text"
                                      name="hls_url"
                                      value={item?.hls_url}
                                      // placeholder="rtmp://75.119.145.126:1935/show/"
                                      className="rs-input form-control-lg"
                                      readOnly
                                    />
                                  </td>

                                  <td className="col-md-4">
                                    <div
                                      type="button"
                                      name="remove_url"
                                      onClick={() =>
                                        openModalsubscription(item?.id)
                                      }
                                      className="text-start "
                                    >
                                      <img
                                        src={deleteitem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </thead>
                            ))}
                          </table>
                        </div>
                        <div
                          className="modal fade"
                          id="staticBackdrop"
                          data-bs-backdrop="static"
                          data-bs-keyboard="false"
                          tabindex="-1"
                          aria-labelledby="staticBackdropLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="staticBackdropLabel"
                                >
                                  EDIT RTMP
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div>
                                  <label>RTMP URL</label>

                                  <input
                                    type="text"
                                    name="rtmp_url"
                                    placeholder="rtmp://75.119.145.126:1935/show/"
                                    value={editrtmp?.rtmp_url}
                                    className="form-control rtmp_urls"
                                  // onChange={(e) =>
                                  //   setRtmp_url(e.target.value)
                                  // }
                                  />
                                </div>
                                <div>
                                  <label>HLS URL</label>
                                  <input
                                    type="text"
                                    name="hls_url"
                                    placeholder="http://75.119.145.126:9090/hls/handleUpdatekey/index.m3u8"
                                    value={editrtmp?.hls_url}
                                    className="form-control rtmp_urls"
                                  // onChange={(e) => setHls_url(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="modal-footer">
                                {/* <button  onClick={handleUpdate} >submit</button> */}
                                <input
                                  type="submit"
                                  id="appupdate"
                                  value="Update RTMP URL Settings"
                                  onClick={handleUpdate}
                                  className="btn btn-primary ml-3 "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Rtmpurl;