import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";
import axios from "axios"
import { adminApiHeader, siteThemApi } from "../CommonApis/CommonApiUrl";
const URLGenerater = (URLNAME) => {
    return URLNAME.replace(/ /g, '-').replace(/_/g, '-').toLowerCase();
}
const URLDashRemover = (URLNAME) => {
    return URLNAME.replace(/-/g, '  ');
}
const LogOut = (PATH) => {
    setTimeout(() => {
        localStorage.clear();
        window.location.href = PATH;
    }, 1000);
}
const capsFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
};

const adminThemeApiAction = async () => {
    try {
        const response = await axios.get(`${siteThemApi}`, { headers: adminApiHeader })
        const result = response?.data;
        if (response?.data?.status) {
            return result?.Site_theme_setting[0];
        }
        else {
            return null
        }

    }
    catch (err) {
        console.error(err)
    }
}


const PDFGenerator = (data, fileName) => {
    const doc = new jsPDF();
    const PDFColumns = ["INDEX", "ID", "TITLE", "UPLOADED BY", "STATUS", "BANNER", "UPLOAD ON"];
    const database = data?.map((item, index) => [
        index + 1,
        item?.id,
        item?.title,
        item?.uploaded_by,
        item?.active,
        item?.banner,
        item?.upload_on
    ]);
    doc.autoTable({
        startY: 10,
        startX: 10,
        head: [PDFColumns],
        body: database,
        margin: { top: 5, right: 5, bottom: 5, left: 5 },
        styles: {
            cellPadding: 3,
            fontSize: 10,
            lineColor: [240, 240, 240],
            lineWidth: 0.1,
        }
    });
    doc.save(`${fileName}.pdf`);
};

const XLSXGenerator = (data, fileName) => {
    const extractedData = data.map((item, index) => {
        return {
            Index: index + 1,
            ID: item?.id,
            TITLE: item?.title,
            UPLOADED_BY: item?.uploaded_by,
            STATUS: item?.active,
            BANNER: item?.banner,
            UPLOAD_ON: item?.upload_on
        };
    });
    var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(extractedData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
};

const CSVColumn = [
    { label: "ID", key: "id" },
    { label: "TITLE", key: "title" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "STATUS", key: "active" },
    { label: "BANNER", key: "banner" },
    { label: "UPLOAD ON", key: "upload_on" },
];


const albumPDFGenerator = (data, fileName) => {
    const doc = new jsPDF();
    const PDFColumns = ["INDEX", "ID", "TITLE", "UPLOADED BY", "UPLOAD ON"];
    const database = data?.map((item, index) => [
        index + 1,
        item?.id,
        item?.albumname,
        item?.uploaded_by,
        item?.upload_on
    ]);
    doc.autoTable({
        startY: 10,
        startX: 10,
        head: [PDFColumns],
        body: database,
        margin: { top: 5, right: 5, bottom: 5, left: 5 },
        styles: {
            cellPadding: 3,
            fontSize: 10,
            lineColor: [240, 240, 240],
            lineWidth: 0.1,
        }
    });
    doc.save(`${fileName}.pdf`);
};

const albumXLSXGenerator = (data, fileName) => {
    const extractedData = data.map((item, index) => {
        return {
            Index: index + 1,
            ID: item?.id,
            TITLE: item?.albumname,
            UPLOADED_BY: item?.uploaded_by,
            UPLOAD_ON: item?.upload_on
        };
    });
    var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(extractedData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
};

const albumCSVColumn = [
    { label: "ID", key: "id" },
    { label: "TITLE", key: "albumname" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD ON", key: "upload_on" },
];

// artists 

const artistPDFGenerator = (data, fileName) => {
    const doc = new jsPDF();
    const PDFColumns = ["INDEX", "ID", "ARTISTS", "ARTISTS TYPE", "UPLOADED BY", "UPLOAD ON"];
    const database = data?.map((item, index) => [
        index + 1,
        item?.id,
        item?.artist_name,
        item?.artist_type,
        item?.uploaded_by,
        item?.upload_on
    ]);
    doc.autoTable({
        startY: 10,
        startX: 10,
        head: [PDFColumns],
        body: database,
        margin: { top: 5, right: 5, bottom: 5, left: 5 },
        styles: {
            cellPadding: 3,
            fontSize: 10,
            lineColor: [240, 240, 240],
            lineWidth: 0.1,
        }
    });
    doc.save(`${fileName}.pdf`);
};

const artistXLSXGenerator = (data, fileName) => {
    const extractedData = data.map((item, index) => {
        return {
            Index: index + 1,
            ID: item?.id,
            NAME: item?.artist_name,
            TYPE: item?.artist_type,
            UPLOADED_BY: item?.uploaded_by,
            UPLOAD_ON: item?.upload_on
        };
    });
    var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(extractedData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
};

const artistCSVColumn = [
    { label: "ID", key: "id" },
    { label: "NAME", key: "artist_name" },
    { label: "ARTISTS TYPE", key: "artist_type" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD ON", key: "upload_on" },
];

const defaultLightTheme = {
    body: 'rgb(255, 255, 255)',
    text: `rgb(0, 0, 0)`,
    button: `#0d6efd`,
    // secondarybody: `rgb(240, 240, 240)`,
    secondarybody: '#e5e5ea',
}
const defaultDarkTheme = {
    body: '#1f222a',
    text: '#fdfcfc',
    button: '#0d6efd',
    secondarybody: '#2d3038',
};

const PDFGeneratorUser = (data, fileName) => {
    const doc = new jsPDF();
    const PDFColumns = ["INDEX", "ID", "USERNAME", "CONTACT", "EMAIL", "ROLE", "STATUS", "UPLOAD ON"];
    const database = data?.map((item, index) => [
        index + 1,
        item?.id,
        item?.name,
        item?.mobile,
        item?.email,
        item?.role,
        item?.active,
        item?.upload_on
    ]);
    doc.autoTable({
        startY: 10,
        startX: 10,
        head: [PDFColumns],
        body: database,
        margin: { top: 5, right: 5, bottom: 5, left: 5 },
        styles: {
            cellPadding: 3,
            fontSize: 10,
            lineColor: [240, 240, 240],
            lineWidth: 0.1,
        }
    });
    doc.save(`${fileName}.pdf`);
};

const XLSXGeneratorUser = (data, fileName) => {
    const extractedData = data.map((item, index) => {
        return {
            Index: index + 1,
            ID: item?.id,
            USERNAME: item?.name,
            CONTACT: item?.mobile,
            EMAIL: item?.email,
            ROLE: item?.role,
            STATUS: item?.active,
            UPLOAD_ON: item?.upload_on
        };
    });
    var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(extractedData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
};

const CSVColumnUser = [
    { label: "ID", key: "id" },
    { label: "USERS", key: "name" },
    { label: "CONTACT", key: "mobile" },
    { label: "EMAIL", key: "email" },
    { label: "ROLE", key: "role" },
    { label: "STATUS", key: "active" },
    { label: "UPLOAD ON", key: "upload_on" },
];

export { PDFGeneratorUser, XLSXGeneratorUser, CSVColumnUser, URLGenerater, URLDashRemover, LogOut, capsFirstLetter, PDFGenerator, XLSXGenerator, CSVColumn, albumCSVColumn, albumPDFGenerator, albumXLSXGenerator, artistPDFGenerator, artistXLSXGenerator, artistCSVColumn, defaultLightTheme, defaultDarkTheme, adminThemeApiAction }