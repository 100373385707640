import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import useCompressImageConfig from "../../../components/CommonApis/Compressimageapi";

function EditManagevideocategories(props) {
  const { id } = useParams();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    name: "",
    parent_id: "",
    image: "",
    order: "",
    slug: "",
    in_home: "",
    banner: "",
    banner_image: "",
    banner_image_url: "",
    in_menu: "",
    home_genre: "",
  });
  const [editname, setEditname] = useState({
    name: "",
  });

  const access_token = localStorage.getItem("access_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const { compressResolutionFormat } = useCompressImageConfig();

  const [error, setError] = useState({
    file1: '',
    file2: '',
    file3: '',
    file4: '',
    file5: ''
  });

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [selectedFile5, setSelectedFile5] = useState(null);

  // const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  const validateImageRatio = (file, expectedRatio, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const ratio = width / height;
        const expectedRatioPrecision = 0.01; // Allow for some minor floating-point precision issues
        const ratioMatch = Math.abs(ratio - expectedRatio) < expectedRatioPrecision;
        const dimensionMatch = (width === expectedWidth && height === expectedHeight);
        if (ratioMatch || dimensionMatch) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.onerror = () => {
        reject(new Error('Error loading image'));
      };
    });
  };

  const handleFileChangesss = async (event, fileInputRef, setSelectedFile, errorKey) => {
    const file = event.target.files[0];
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));

    if (compressResolutionFormat?.videos === 1) {
      let isValid = false;

      if (fileInputRef === fileInputRef1) {
        isValid = await validateImageRatio(file, 9 / 16, 1080, 1920);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file1: 'Image must be in 9:16 Ratio or 1080x1920px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef2) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file2: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef3) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file3: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef4) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file4: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef5) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file5: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      }
    }

    setSelectedFile(file);
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));
  };


  const handleDelete = (index) => {
    setError('');
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      case 3:
        setSelectedFile3(null);
        if (fileInputRef3.current) {
          fileInputRef3.current.value = null;
        }
        break;
      case 4:
        setSelectedFile4(null);
        if (fileInputRef4.current) {
          fileInputRef4.current.value = null;
        }
        break;
      case 5:
        setSelectedFile5(null);
        if (fileInputRef5.current) {
          fileInputRef5.current.value = null;
        }
        break;
      default:
        break;
    }
  };


  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/videos-category/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.VideoCategory;
      setEditUser(res);
      setEditname(res);
      setLoading(false);
    };
    getUser();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/videos-category/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.Video_Category;
        setData(result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [id]);

  // const handleInput = (e) => {
  //   const { name, value } = e.target;
  //   setEditUser((prevEditUser) => ({
  //     ...prevEditUser,
  //     [name]: value,
  //   }));

  //   setValidationMessageslug("");
  //   setIsValidationHiddenslug(true);
  //   setValidationMessagetitle("");
  //   setIsValidationHiddentitle(true);

  //   setEditUser({ ...editUser, [e.target.name]: e.target.value });

  //   if (e.target.checked == true) {
  //     setEditUser({ ...editUser, [e.target.name]: e.target.value });
  //     setEditUser({ ...editUser, [e.target.id]: 1 });
  //     setEditUser({
  //       ...editUser,
  //       [e.target.name]: e.target.value,
  //       [e.target.name]: 1,
  //     });
  //   } else {
  //     setEditUser({ ...editUser, [e.target.name]: e.target.value });
  //     setEditUser({ ...editUser, [e.target.id]: 0 });
  //     setEditUser({ ...editUser, [e.target.name]: e.target.value });
  //   }
  // };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    // if (e.target.checked === true) {
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    //   setEditUser({ ...editUser, [e.target.id]: 1 });
    //   setEditUser({
    //     ...editUser,
    //     [e.target.name]: e.target.value,
    //     [e.target.name]: 1,
    //   });
    // } else {
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    //   setEditUser({ ...editUser, [e.target.id]: 0 });
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    // }
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  // const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const titleInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    // if (editUser?.name === "") {
    //   setValidationMessagetitle("Name cannot be empty.");
    //   setIsValidationHiddentitle(false);
    //   return;
    // }

    // if (editUser?.slug === "") {
    //   setValidationMessageslug("Slug cannot be empty.");
    //   setIsValidationHiddenslug(false);
    //   return;
    // }

    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagetitle("Name cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      name: editUser?.name,
      slug: editUser?.slug,
      in_home: editUser?.in_home,
      banner: editUser?.banner,
      in_menu: editUser?.in_menu,
      home_genre: editUser?.home_genre,
      parent_id: editUser?.parent_id,
      uploaded_by: editUser?.uploaded_by,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/videos-category/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();
        formData.append("VideoCategory_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("banner_image", selectedFile2);
        }

        await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/videos-category/image-upload`,
          formData,
          { headers: headers }
        );
        secondApiStatus = true;
      } else if (resjson?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      // Check if resultapifalse is defined and not null
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <section className="container-fluid">
          <div className="col-lg-12 row py-3">
            <div className="col-6 col-sm-6 col-lg-6">
              <h3>Edit Category</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Category
                </button>
              </div>
            </div>
          </div>

          <div>
            {showOverlay && <Response_Processing></Response_Processing>}
          </div>

          <div className="row col-lg-12 ">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
              <div className="card p-3">
                <h5>{editname?.name}</h5>
                <hr></hr>
                <div className=" text-start">
                  <label className="m-0">
                    Name<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="name"
                      name="name"
                      placeholder="Enter Name"
                      onChange={handleInput}
                      value={editUser?.name}
                      ref={titleInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddentitle && (
                      <p>{validationMessagetitle}</p>
                    )}
                  </span>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0">Slug</label>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="slug"
                      name="slug"
                      placeholder="Enter Slug"
                      onChange={handleInput}
                      value={editUser?.slug}
                    />
                  </div>
                </div>

                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0">Category</label>

                    <div className="mt-2">
                      <select
                        className="form-select"
                        name="parent_id"
                        id="parent_id"
                        value={editUser?.parent_id}
                        onChange={handleInput}
                      >
                        <option value="">Choose a Category</option>
                        {data?.map((item) => (
                          <option value={item?.id}>{item?.name}</option>
                        ))}
                      </select>
                    </div>

                    {/* <div className="mt-2">
                      <select
                        className="form-select"
                        name="parent_id"
                        id="parent_id"
                        onChange={handleInput}
                        value={editUser?.parent_id}
                      >
                        {data?.map((item) => (
                          <option value={item?.parent_id}>{item?.name}</option>
                        ))}
                      </select>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="card p-3">
                <h5>Status Settings</h5>
                <hr></hr>

                <div className="col-sm-12 row">
                  <div className="col-9 col-sm-9 col-lg-9">
                    <p className="p2">Display In Menu</p>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3">
                    <label className="switch">
                      <input
                        name="in_menu"
                        id="in_menu"
                        onChange={handleInputenable}
                        defaultChecked={
                          editUser?.in_menu == 1 ? true : false
                        }
                        checked={editUser?.in_menu == 1 ? true : false}
                        className="rs-input"
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="in_menu"
                        id="in_menu"
                        onChange={handleInputenable}
                        value={editUser?.in_menu == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 row mt-2">
                  <div className="col-9 col-sm-9 col-lg-9">
                    <p className="p2">Display In Home</p>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3">
                    <label className="switch">
                      <input
                        name="in_home"
                        id="in_home"
                        onChange={handleInputenable}
                        className="r-input"
                        defaultChecked={editUser?.in_home === 1 ? true : false}
                        checked={editUser?.in_home === 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="in_home"
                        id="in_home"
                        onChange={handleInputenable}
                        value={editUser?.in_home === 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 row mt-2">
                  <div className="col-9 col-sm-9 col-lg-9">
                    <p className="p2">Display In Home Banner</p>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3">
                    <label className="switch">
                      <input
                        name="banner"
                        id="banner"
                        onChange={handleInputenable}
                        className="r-input"
                        defaultChecked={editUser?.banner === 1 ? true : false}
                        checked={editUser?.banner === 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="banner"
                        id="banner"
                        onChange={handleInputenable}
                        value={editUser?.banner === 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="text-start mb-3">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Category
                </button>
              </div>
            </div>

            {/* Second Part of Section  */}

            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="card p-3">
                <h5>Thumbnails</h5>
                <hr></hr>

                <div className=" text-start mt-2">
                  <label className="m-0">Image</label>

                  {/* <div>
                    <img src={editUser?.image_url} width="150" height="100" />
                  </div> */}

                  <div>
                    {loading ? (
                      <Response_Loading_processing></Response_Loading_processing>
                    ) : (
                      <img
                        src={editUser?.image_url}
                        alt="image_all"
                        className="thumbnails-images"
                      />
                    )}
                  </div>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className=" col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef1.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef1}
                            id="video_thumbnail"
                            onChange={(event) =>
                              handleFileChangesss(
                                event,
                                fileInputRef1,
                                setSelectedFile1,
                                'file1'
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className=" col-sm-6 col-lg-6">
                        <div>
                          {selectedFile1 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL?.createObjectURL(selectedFile1)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() => handleDelete(1)}
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {error?.file1 && <div className="errorred mt-1">{error?.file1}</div>}
                </div>
                <div className=" text-start mt-2">
                  <label className="m-0">Banner</label>

                  {/* <div>
                    <img
                      src={editUser?.banner_image_url}
                      width="150"
                      height="100"
                    />
                  </div> */}

                  <div>
                    {loading ? (
                      <Response_Loading_processing></Response_Loading_processing>
                    ) : (
                      <img
                        src={editUser?.banner_image_url}
                        alt="image_all"
                        className="thumbnails-images"
                      />
                    )}
                  </div>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className=" col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef2.current.click()}
                        >
                          <input
                            type="file"
                            id="player_thumbnail"
                            ref={fileInputRef2}
                            onChange={(event) =>
                              handleFileChangesss(
                                event,
                                fileInputRef2,
                                setSelectedFile2,
                                'file2'
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className=" col-sm-6 col-lg-6">
                        <div>
                          {selectedFile2 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL?.createObjectURL(selectedFile2)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() => handleDelete(2)}
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {error?.file2 && <div className="errorred mt-1">{error?.file2}</div>}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>
                        <i className=""></i> Edit Video Categories
                      </h4>
                    </div>
                  </div>
                  <hr />

                  <div className="">
                    <div>
                      <div className="form-group ">
                        <label>Name:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={editUser?.name}
                          onChange={handleInput}
                          className="form-control"
                          placeholder="Enter Name"
                        />
                      </div>

                      <div className="form-group ">
                        <label>Slug:</label>

                        <input
                          type="text"
                          id="slug"
                          name="slug"
                          value={editUser?.slug}
                          onChange={handleInput}
                          className="form-control"
                          placeholder="Enter Slug"
                        />
                      </div>
                      <div className="form-group">
                        <label>Home Page Genre Name:</label>
                        <input
                          type="text"
                          id="home_genre"
                          name="home_genre"
                          onChange={handleInput}
                          value={editUser?.home_genre}
                          className="form-control"
                          placeholder="Enter Home Page Genre Name"
                        />
                      </div>
                      <div className="form-group ">
                        <label>Display In Home page:</label>
                        <label className="switch">
                          <input
                            name="in_home"
                            onChange={handleInput}
                            defaultChecked={
                              editUser?.in_home == 1 ? true : false
                            }
                            checked={editUser?.in_home == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="in_home"
                            onChange={handleInput}
                            value={editUser?.in_home == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>

                      <div className="form-group ">
                        <label>Display In Menu :</label>

                        <label className="switch">
                          <input
                            name="in_menu"
                            onChange={handleInput}
                            defaultChecked={
                              editUser?.in_menu == 1 ? true : false
                            }
                            checked={editUser?.in_menu == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="in_menu"
                            onChange={handleInput}
                            value={editUser?.in_menu == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                      <div className="form-group ">
                        <label>Image:</label>
                        <img src={editUser?.image_url} alt="image" width={200} height={200}/>
                        <input
                          type="file"
                          multiple="true"
                          className="form-control"
                          name="image"
                          onChange={(e) => setImage(e.target.files[0])}
                          id="image"
                        />
                      </div>

                      <div className="form-group ">
                        <label>Banner Image:</label>
                        <img src={editUser?.banner_image_url} alt="banner_image" width={200} height={200}/>

                        <input
                          type="file"
                          multiple="true"
                          className="form-control"
                          name="banner_image"
                          onChange={(e) => setBanner_image(e.target.files[0])}
                          id="banner_image"
                        />
                      </div>

                      <div className="form-group ">
                        <label>Geners</label>
                        <select
                          id="parent_id"
                          name="parent_id"
                          className="form-control"
                          value={editUser?.parent_id}
                          onChange={handleInput}
                        >
                          {data?.map((item, key) => (
                            <option value={item?.parent_id}>{item?.name}</option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group ">
                        <label>Display In Home Banner:</label>

                        <label className="switch">
                          <input
                            name="banner"
                            onChange={handleInput}
                            defaultChecked={editUser?.banner == 1 ? true : false}
                            checked={editUser?.banner == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            onChange={handleInput}
                            value={editUser?.banner == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>

                      <div className=" text-end form-group">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          id="submit-new-cat"
                          onClick={handleUpdate}
                        >
                          Update Category
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div> */}
      </div>
    </>
  );
}

export default EditManagevideocategories;
