import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function ChannelCommission(props) {
  const [commission, setCommission] = useState();
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCommission((prevState) => {
      return { ...prevState, percentage: value };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setShowOverlay(true);
    const formDatas = new FormData();
    for (const key in commission) {
      formDatas.append(key, commission[key]);
    }

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/channel-partner/add-commission`,
        formDatas,
        { headers }
      )
      .then((response) => {
        let result = response?.data;
        if (result?.status) {
          props?.setApiresponsealert(result);
          props?.setShowalert(true);
          setProcessing(false);
          setShowOverlay(false);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
        var errorMessage = "An error occurred while processing your request.";
        props?.setApiresponsealert({ status: false, message: errorMessage });
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      });
  };
  const fetchRole = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel-partner/index-commission`,
        { headers: headers }
      )
      .then((response) => {
        var result = response?.data;
        setCommission(result?.Commission);
      })
      .catch((error) => console.log(error));
  };

  useEffect(async () => {
    fetchRole();
  }, []);
  return (
    <>
      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="col-lg-12 row py-3">
                <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                  {" "}
                  <h4 className="card-title">Add Commission</h4>
                </div>
                <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                  <input
                    type="submit"
                    onClick={handleSubmit}
                    value="Update Percentage"
                    className="btn btn-primary pull-right"
                  />
                </div>
              </div>

              <div className="iq-card">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="">
                      <div className="iq-card-body container-fluid">
                        <h5></h5>
                        <form onSubmit={handleSubmit}>
                          <div className="row mt-12 align-items-center">
                            <div className="col-md-6 p-0">
                              <div
                                className="panel panel-primary "
                                data-collapsed="0"
                              >
                                {" "}
                                <div className="panel-heading">
                                  <div className="panel-title">
                                    <label className="fs-5 mb-1">
                                      Percentage
                                    </label>
                                  </div>{" "}
                                  <div className="panel-options">
                                    {" "}
                                    <Link to="#" data-rel="collapse">
                                      <i className="entypo-down-open"></i>
                                    </Link>{" "}
                                  </div>
                                </div>
                                <div className="panel-body">
                                  <p className="p1">
                                    Add the Commissiom Account For Videos:
                                  </p>
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      className="rs-input form-control-lg"
                                      name="percentage"
                                      id="percentage"
                                      placeholder="Add Commission"
                                      defaultValue=""
                                      value={commission?.percentage}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-md-6 mt-3">
                              <input
                                type="submit"
                                defaultValue="Update Percentage"
                                className="btn btn-primary pull-right"
                              />
                            </div> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ChannelCommission;
