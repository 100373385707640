import React, { useState, useEffect } from "react";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function MultiUserManagement(props) {
  const [editUser, setEditUser] = useState({
    Multi_user_limit: "",
    choose_profile_status: "",
  });
  // const [message, setMessage] = useState("");
  // const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Multi-User-limit/index`, {
        headers: headers,
      })
      .then((response) => {
        //   console.log('api checks', response.data.Multi_User_limit)
        var result = response?.data;
        setEditUser(result);
        // console.log("result", response.data.Multi_user_limit);
      })
      .catch((error) => console.log(error));
  }, []);

  // const handleInput = (e) => {
  //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
  // };

  // const handleInput = (e) => {
  //   const { name, value } = e.target;
  //   setEditUser((prevEditUser) => ({
  //     ...prevEditUser,
  //     [name]: value,
  //   }));

  //   if (e.target.checked === true) {
  //     setEditUser({ ...editUser, [e.target.name]: e.target.value });
  //     setEditUser({ ...editUser, [e.target.id]: 1 });
  //   } else {
  //     setEditUser({ ...editUser, [e.target.name]: e.target.value });
  //     setEditUser({ ...editUser, [e.target.id]: 0 });
  //   }
  // };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const editInputvalue = {
      multiuser_limit: editUser?.Multi_user_limit ? parseInt(editUser.Multi_user_limit, 10) : 0,
      choose_profile_status: editUser && editUser.choose_profile_status !== null && editUser.choose_profile_status !== undefined ? parseInt(editUser.choose_profile_status, 10) : 0,
    };
    // console.log(editInputvalue);

    setProcessing(true);
    setShowOverlay(true);

    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Multi-User-limit/update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );

    let resjson = await res.json();

    if (resjson.status === true) {
      var resultapi = resjson;

      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Multi User Limit Management</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Setting
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && (<Response_Processing></Response_Processing>)}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Sessions Number</h5>
                  <hr></hr>

                  <div className="text-start">
                    <label className="">
                      Number of simultaneous Sessions{" "}
                      <small>(Give in Number)</small>{" "}
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        className="rs-input form-control-lg"
                        name="Multi_user_limit"
                        // id="Multi_user_limit"
                        value={editUser?.Multi_user_limit}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>

                  {/* <div className=" text-start">
                    <label className="m-0">Import Excel file</label>

                    <div className="mt-2">
                      <input
                        type="file"
                        name="file"
                        id="file"
                        className="rs-input form-control-lg"
                        onChange={(e) => setUser(e.target.files[0])}
                      />
                    </div>
                  </div> */}
                </div>
              </div>

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Profile Screen Setting</h5>
                  <hr></hr>

                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-body d-flex justify-content-between">
                      <label className="">User Active Status </label>
                      <label className="switch">
                        <input
                          name="choose_profile_status"
                          onChange={handleInputenable}
                          defaultChecked={
                            editUser?.choose_profile_status === 1 ? true : false
                          }
                          checked={
                            editUser?.choose_profile_status === 1 ? true : false
                          }
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="choose_profile_status"
                          onChange={handleInputenable}
                          value={
                            editUser?.choose_profile_status === 1 ? "1" : "0"
                          }
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default MultiUserManagement;
