import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import JoditEditor from "jodit-react";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import "./Update_Site_met_settings.css";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function Update_Site_met_settings(props) {
  const { id } = useParams();
  const [site_select_name, setSite_select_name] = useState([]);
  const [editUser, setEditUser] = useState({
    page_name: "",
    page_page_name: "",
    meta_description: "",
    meta_keyword: "",
    image_url: "",
    page_title: "",
  });
  const [editname, setEditname] = useState({
    page_title: "",
  });

  const [loading, setLoading] = useState(true);
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  const handleChangeFirstdetails = (e) => {
    setEditUser({ ...editUser, meta_description: e });
    // console.log(e, "onchnageddddd");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/site_meta/edit/${id}`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.SiteMetaSetting[0];
        var page_nameresult = response?.data?.page_name;
        setSite_select_name(page_nameresult);
        setEditUser(result);
        setEditname(result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
    // await axios
    //   .get(`${process.env.REACT_APP_Baseurl}/admin/site_meta/index`, {
    //     headers: headers,
    //   })
    //   .then((response) => {
    //     var result = response?.data?.site_meta_setting.data;
    //     setSite_select_name(result);
    //     setLoading(false);
    //   })
    //   .catch((error) => console.log(error));
  }, []);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const [validationMessagepage_name, setValidationMessagepage_name] =
    useState("");
  const [isValidationHiddenpage_name, setIsValidationHiddenpage_name] =
    useState(true);

  const [validationMessagepage_title, setValidationMessagepage_title] =
    useState("");
  const [isValidationHiddenpage_title, setIsValidationHiddenpage_title] =
    useState(true);

  const page_nameInputRef = useRef(null);
  const page_titleInputRef = useRef(null);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.page_name === "") {
      setValidationMessagepage_name("Name cannot be empty.");
      setIsValidationHiddenpage_name(false);
      focusInputRef = page_nameInputRef;
    }
    if (editUser?.page_title === "") {
      setValidationMessagepage_title("Title cannot be empty.");
      setIsValidationHiddenpage_title(false);
      focusInputRef = page_titleInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      page_name: editUser?.page_name,
      page_page_title: editUser?.page_title,
      page_page_name: editUser?.page_page_name,
      meta_description: editUser?.meta_description,
      meta_keyword: editUser?.meta_keyword,
      page_title: editUser?.page_title,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/site_meta/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        var site_metaid = resjson.updated_SiteMeta_Setting.id;

        const formData = new FormData();
        formData.append("site_meta_id", site_metaid);
        if (selectedFile1) {
          formData.append("meta_image", selectedFile1);
        }

        await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-site-meta`,
          formData,
          { headers: headers }
        );
        secondApiStatus = true;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagepage_name("");
      setValidationMessagepage_title("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setIsValidationHiddenpage_name(false);
        setValidationMessagepage_title(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <section className="container-fluid">
          <div className="col-lg-12 row py-3">
            <div className="col-6 col-sm-6 col-lg-6">
              <h3>Edit Site Meta Settings</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Site
                </button>
              </div>
            </div>
          </div>

          <div>
            {showOverlay && <Response_Processing></Response_Processing>}
          </div>

          <div className="row col-lg-12 ">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
              <div className="card p-3">
                <h5>{editname?.page_title}</h5>
                <hr></hr>
                <div className=" text-start">
                  <label className="m-0">
                    Page Name<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <select
                      className="form-select"
                      name="page_name"
                      id="page_name"
                      onChange={handleInput}
                      ref={page_nameInputRef}
                      value={editUser?.page_name}
                    >
                      <option value="">Select an Page Name </option>

                      {site_select_name.map((item, index) => (
                        <option key={index} value={Object.keys(item)[0]}>
                          {Object.values(item)[0]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <span className="errorred">
                    {!isValidationHiddenpage_name && (
                      <p>{validationMessagepage_name}</p>
                    )}
                  </span>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0">Page Title</label>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="page_title"
                      name="page_title"
                      placeholder=""
                      onChange={handleInput}
                      ref={page_titleInputRef}
                      value={editUser?.page_title}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddenpage_title && (
                      <p>{validationMessagepage_title}</p>
                    )}
                  </span>
                </div>

                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0">Meta Keyword</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="meta_keyword"
                        name="meta_keyword"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.meta_keyword}
                        placeholder=""
                      // ref={meta_keywordInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {/* {!isValidationHiddenmeta_keyword && <p>{validationMessagemeta_keyword}</p>} */}
                    </span>
                  </div>
                </div>

                <div className="mt-2 text-start">
                  <label className="m-0">Page Meta Description</label>

                  <div className="mt-2">
                    <JoditEditor
                      className="rs-input"
                      value={editUser?.meta_description}
                      onChange={(e) => {
                        handleChangeFirstdetails(e);
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="text-start mb-3">
                <button
                  onClick={submitcategory}
                  className="btn btn-primary"
                >
                 Save Audio Category
                </button>
              </div> */}
            </div>

            {/* Second Part of Section  */}

            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="card p-3">
                <h5>Thumbnails</h5>
                <hr></hr>

                <div className=" text-start mt-2">
                  <label className="m-0">Cover Image</label>

                  {/* <div>
                  <img src={editUser?.image_url} width="100" height="100" />
                </div> */}

                  <div>
                    {loading ? (
                      <Response_Loading_processing></Response_Loading_processing>
                    ) : (
                      <img
                        src={editUser?.image_url}
                        // alt="Image"
                        className="thumbnails-images"
                      />
                    )}
                  </div>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className=" col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef1.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef1}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef1,
                                setSelectedFile1
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className=" col-sm-6 col-lg-6">
                        <div>
                          {selectedFile1 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL.createObjectURL(selectedFile1)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() => handleDelete(1)}
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Update_Site_met_settings;
