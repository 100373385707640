import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify"
import "./ThemeSettings.css";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";

import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import AdminSaveHeader from "../../../components/CommonComponents/AdminSaveHeader"
import FormContainer from "../../../components/CommonComponents/FormContainer";
import LeftWrapper from "../../../components/CommonComponents/LeftWrapper";
import RightWrapper from "../../../components/CommonComponents/RightWrapper";
import Wrapper from "../../../components/CommonComponents/Wrapper";
import MessageHeading from "../../../components/CommonComponents/MessageHeading";
import MessageLabel from "../../../components/CommonComponents/MessageLabel";
import MessageSubHeading from "../../../components/CommonComponents/MessageSubHeading";
import AdminCustomButton from "../../../components/CommonComponents/AdminCustomButton";
import { IoClose } from "react-icons/io5";

function ThemeSettings() {

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const [loading, setLoading] = useState(true);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [withOutRefresh, setWithOutRefresh] = useState(false)
  const [themeList, setThemeList] = useState([])
  const [editUser, setEditUser] = useState({
    button_bg_color: '#000',
    light_bg_color: '#000',
    dark_bg_color: '#000',
    dark_text_color: '#000',
    light_text_color: '#000',
    admin_dark_bg_color: '#000',
    admin_light_bg_color: '#000',
    admin_dark_text_color: '#000',
    admin_light_text_color: '#000',
    light_bg_secondary_color: '#000',
    dark_bg_secondary_color: '#000',
    admin_dark_button_bg_color: '#000',
    admin_light_button_bg_color: '#000',
    admin_dark_button_text_color: '#000',
    admin_light_button_text_color: '#000',
    dark_button_bg_color: '#000',
    light_button_bg_color: '#000',
    dark_button_text_color: '#000',
    light_button_text_color: '#000',
    signup_step2_title: 0,
    signup_theme: 0,
    signup_payment_content: 0,
    prevent_inspect: 0,
    loader_setting: 0,
    style_sheet_link: 0,
    typography_link: 0,
    my_profile_theme: 0,
    audio_page_checkout: 0,
    signin_header: 0,
    choose_player: 0,
    Vod_Aod_checkout: 0,
    translate_checkout: 0,
    audio_content_right_checkout: 0,
    enable_slider: 0,
  });

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      case 3:
        setSelectedFile3(null);
        if (fileInputRef3.current) {
          fileInputRef3.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const fetchData = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_Baseurl}/admin/Site-ThemeSetting/index`, { headers: headers })
        .then((res) => {
          var theme = res?.data?.Site_theme_setting[0];
          setEditUser(theme);
          setThemeList(res?.data?.themes);
          setLoading(false);
        });
    }
    catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchData()
  }, [withOutRefresh]);

  const handleSave = async () => {
    setShowOverlay(true);
    const formData = new FormData();
    for (const key in editUser) {
      formData.append(key, editUser[key]);
    }
    formData.append("dark_mode_logo", selectedFile1);
    formData.append("light_mode_logo", selectedFile2);
    formData.append("loading_gif_video", selectedFile3);
    try {
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/Site-ThemeSetting/update`, formData, { headers: headers });
      const result = response?.data
      if (result?.status) {
        toast.success(result?.message)
        setShowOverlay(false);
        setSelectedFile1(null)
        setSelectedFile2(null)
        setSelectedFile3(null)
        setWithOutRefresh(!withOutRefresh)
      }
      else {
        setShowOverlay(false);
        toast.error(result?.message)
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
      toast.error(errorMessage)
    }
  }
  return (
    <>
      {showOverlay && <Response_Processing></Response_Processing>}
      <AdminSaveHeader heading="Theme Settings" saveText="Update" saveMethod={handleSave} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text="Checkout Theme" />
            <MessageLabel text="Signup page step 2 title" className='' />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              id="signup_step2_title"
              name="signup_step2_title"
              onChange={handleInput}
              value={editUser?.signup_step2_title}
            />
            <MessageLabel text="Signup agree message" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder  theme-text-color border-0"
              id="signup_payment_content"
              name="signup_payment_content"
              onChange={handleInput}
              value={editUser?.signup_payment_content}
            />
          </Wrapper>

          {/* Site Bg color start */}
          <Wrapper>
            <MessageHeading text="Site Background color" />
            <div className="d-flex align-items-center justify-content-between flex-wrap  ">
              <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                <MessageLabel text="Dark Mode" className='text-sm-start pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-start pe-sm-2">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0 border colorPicker"
                        id="dark_bg_color"
                        name="dark_bg_color"
                        onChange={handleInput}
                        value={editUser?.dark_bg_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary  d-block  w-100 rounded-1 p-2" name="dark_bg_color" onChange={handleInput} value={editUser?.dark_bg_color}></input>
                    </div>
                  </div>

                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Secondary" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="dark_bg_secondary_color"
                        name="dark_bg_secondary_color"
                        onChange={handleInput}
                        value={editUser?.dark_bg_secondary_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="dark_bg_secondary_color" onChange={handleInput} value={editUser?.dark_bg_secondary_color}></input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <MessageLabel text="Light Mode" className='text-sm-end pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-end ps-sm-2">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="light_bg_color"
                        name="light_bg_color"
                        onChange={handleInput}
                        value={editUser?.light_bg_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="light_bg_color" onChange={handleInput} value={editUser?.light_bg_color}></input>
                    </div>
                  </div>
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Secondary" />
                    <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0 border colorPicker"
                        id="light_bg_secondary_color"
                        name="light_bg_secondary_color"
                        onChange={handleInput}
                        value={editUser?.light_bg_secondary_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary  d-block  w-100 rounded-1 p-2" name="light_bg_secondary_color" onChange={handleInput} value={editUser?.light_bg_secondary_color}></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
          {/* Site Bg color end */}

          {/* Site Text color start */}
          <Wrapper>
            <MessageHeading text="Site Text color" />
            <div className="d-flex align-items-center justify-content-between flex-wrap  ">
              <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                <MessageLabel text="Dark Mode" className='text-sm-start pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-start pe-sm-2">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0 border colorPicker"
                        id="dark_text_color"
                        name="dark_text_color"
                        onChange={handleInput}
                        value={editUser?.dark_text_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary  d-block  w-100 rounded-1 p-2" name="dark_text_color" onChange={handleInput} value={editUser?.dark_text_color}></input>
                    </div>
                  </div>

                  {/* <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Secondary" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="dark_bg_secondary_color"
                        name="dark_bg_secondary_color"
                        onChange={handleInput}
                        value={editUser?.dark_bg_secondary_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="dark_bg_secondary_color" onChange={handleInput} value={editUser?.dark_bg_secondary_color}></input>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <MessageLabel text="Light Mode" className='text-sm-end pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-end ps-sm-2">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="light_text_color"
                        name="light_text_color"
                        onChange={handleInput}
                        value={editUser?.light_text_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="light_text_color" onChange={handleInput} value={editUser?.light_text_color}></input>
                    </div>
                  </div>
                  {/* <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Secondary" />
                    <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0 border colorPicker"
                        id="light_bg_secondary_color"
                        name="light_bg_secondary_color"
                        onChange={handleInput}
                        value={editUser?.light_bg_secondary_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary  d-block  w-100 rounded-1 p-2" name="light_bg_secondary_color" onChange={handleInput} value={editUser?.light_bg_secondary_color}></input>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

          </Wrapper>
          {/* Site Text color end */}
          {/* site Bg  button color start */}
          <Wrapper>
            <MessageHeading text="Site Button Bg color" />
            <div className="d-flex align-items-center justify-content-between flex-wrap  ">
              <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                <MessageLabel text="Dark Mode" className='text-sm-start pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-start pe-sm-2 ">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker"
                        id="dark_button_bg_color"
                        name="dark_button_bg_color"
                        onChange={handleInput}
                        value={editUser?.dark_button_bg_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="dark_button_bg_color" onChange={handleInput} value={editUser?.dark_button_bg_color}></input>
                    </div>
                  </div>

                  {/* <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Secondary color" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="admin_dark_bg_secondary_color"
                        name="admin_dark_bg_secondary_color"
                        onChange={handleInput}
                        value={editUser?.admin_dark_bg_secondary_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="admin_dark_bg_secondary_color" onChange={handleInput} value={editUser?.admin_dark_bg_secondary_color}></input>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <MessageLabel text="Light Mode" className='text-sm-end pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-end ps-sm-2">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="light_button_bg_color"
                        name="light_button_bg_color"
                        onChange={handleInput}
                        value={editUser?.light_button_bg_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="light_button_bg_color" onChange={handleInput} value={editUser?.light_button_bg_color}></input>
                    </div>
                  </div>
                  {/* <div className="pickerWidth">
                   <MessageSubHeading className="colorText text-center" text="Secondary" />
                  <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                    <input
                      type="color"
                      className="rs-input form-control-lg p-0  colorPicker"
                      id="admin_light_bg_secondary_color"
                      name="admin_light_bg_secondary_color"
                      onChange={handleInput}
                      value={editUser?.admin_light_bg_secondary_color}
                    />
                    <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="admin_light_bg_secondary_color" onChange={handleInput} value={editUser?.admin_light_bg_secondary_color}></input>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </Wrapper>

          {/* site Bg button color end */}


          {/* site button Text color start */}
          <Wrapper>
            <MessageHeading text="Site Button Text color" />
            <div className="d-flex align-items-center justify-content-between flex-wrap  ">
              <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                <MessageLabel text="Dark Mode" className='text-sm-start pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-start pe-sm-2">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0 border colorPicker"
                        id="dark_button_text_color"
                        name="dark_button_text_color"
                        onChange={handleInput}
                        value={editUser?.dark_button_text_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary  d-block  w-100 rounded-1 p-2" name="dark_button_text_color" onChange={handleInput} value={editUser?.dark_button_text_color}></input>
                    </div>
                  </div>

                  {/* <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Secondary" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="dark_bg_secondary_color"
                        name="dark_bg_secondary_color"
                        onChange={handleInput}
                        value={editUser?.dark_bg_secondary_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="dark_bg_secondary_color" onChange={handleInput} value={editUser?.dark_bg_secondary_color}></input>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <MessageLabel text="Light Mode" className='text-sm-end pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-end ps-sm-2">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="light_button_text_color"
                        name="light_button_text_color"
                        onChange={handleInput}
                        value={editUser?.light_button_text_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="light_button_text_color" onChange={handleInput} value={editUser?.light_button_text_color}></input>
                    </div>
                  </div>
                  {/* <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Secondary" />
                    <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0 border colorPicker"
                        id="light_bg_secondary_color"
                        name="light_bg_secondary_color"
                        onChange={handleInput}
                        value={editUser?.light_bg_secondary_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary  d-block  w-100 rounded-1 p-2" name="light_bg_secondary_color" onChange={handleInput} value={editUser?.light_bg_secondary_color}></input>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

          </Wrapper>
          {/* site button Text color end */}

          {/* Admin Bg color start */}
          <Wrapper>
            <MessageHeading text="Admin Background color" />
            <div className="d-flex align-items-center justify-content-between flex-wrap  ">
              <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                <MessageLabel text="Dark Mode" className='text-sm-start pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-start pe-sm-2 ">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker"
                        id="admin_dark_bg_color"
                        name="admin_dark_bg_color"
                        onChange={handleInput}
                        value={editUser?.admin_dark_bg_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="admin_dark_bg_color" onChange={handleInput} value={editUser?.admin_dark_bg_color}></input>
                    </div>
                  </div>

                  {/* <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Secondary color" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="admin_dark_bg_secondary_color"
                        name="admin_dark_bg_secondary_color"
                        onChange={handleInput}
                        value={editUser?.admin_dark_bg_secondary_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="admin_dark_bg_secondary_color" onChange={handleInput} value={editUser?.admin_dark_bg_secondary_color}></input>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <MessageLabel text="Light Mode" className='text-sm-end pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-end ps-sm-2">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="admin_light_bg_color"
                        name="admin_light_bg_color"
                        onChange={handleInput}
                        value={editUser?.admin_light_bg_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="admin_light_bg_color" onChange={handleInput} value={editUser?.admin_light_bg_color}></input>
                    </div>
                  </div>
                  {/* <div className="pickerWidth">
                   <MessageSubHeading className="colorText text-center" text="Secondary" />
                  <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                    <input
                      type="color"
                      className="rs-input form-control-lg p-0  colorPicker"
                      id="admin_light_bg_secondary_color"
                      name="admin_light_bg_secondary_color"
                      onChange={handleInput}
                      value={editUser?.admin_light_bg_secondary_color}
                    />
                    <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="admin_light_bg_secondary_color" onChange={handleInput} value={editUser?.admin_light_bg_secondary_color}></input>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </Wrapper>

          {/* Admin Bg color end */}
          {/* Admin Text color start */}
          <Wrapper>
            <MessageHeading text="Admin Text color" />
            <div className="d-flex align-items-center justify-content-between flex-wrap  ">
              <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                <MessageLabel text="Dark Mode" className='text-sm-start pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-start pe-sm-2">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0 border colorPicker"
                        id="admin_dark_text_color"
                        name="admin_dark_text_color"
                        onChange={handleInput}
                        value={editUser?.admin_dark_text_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary  d-block  w-100 rounded-1 p-2" name="admin_dark_text_color" onChange={handleInput} value={editUser?.admin_dark_text_color}></input>
                    </div>
                  </div>

                  {/* <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Secondary" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="dark_bg_secondary_color"
                        name="dark_bg_secondary_color"
                        onChange={handleInput}
                        value={editUser?.dark_bg_secondary_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="dark_bg_secondary_color" onChange={handleInput} value={editUser?.dark_bg_secondary_color}></input>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <MessageLabel text="Light Mode" className='text-sm-end pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-end ps-sm-2">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="admin_light_text_color"
                        name="admin_light_text_color"
                        onChange={handleInput}
                        value={editUser?.admin_light_text_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="admin_light_text_color" onChange={handleInput} value={editUser?.admin_light_text_color}></input>
                    </div>
                  </div>
                  {/* <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Secondary" />
                    <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0 border colorPicker"
                        id="light_bg_secondary_color"
                        name="light_bg_secondary_color"
                        onChange={handleInput}
                        value={editUser?.light_bg_secondary_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary  d-block  w-100 rounded-1 p-2" name="light_bg_secondary_color" onChange={handleInput} value={editUser?.light_bg_secondary_color}></input>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

          </Wrapper>
          {/* Admin Text color end */}

          {/* Admin Bg  button color start */}
          <Wrapper>
            <MessageHeading text="Admin Button Bg color" />
            <div className="d-flex align-items-center justify-content-between flex-wrap  ">
              <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                <MessageLabel text="Dark Mode" className='text-sm-start pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-start pe-sm-2 ">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker"
                        id="admin_dark_button_bg_color"
                        name="admin_dark_button_bg_color"
                        onChange={handleInput}
                        value={editUser?.admin_dark_button_bg_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="admin_dark_button_bg_color" onChange={handleInput} value={editUser?.admin_dark_button_bg_color}></input>
                    </div>
                  </div>

                  {/* <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Secondary color" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="admin_dark_bg_secondary_color"
                        name="admin_dark_bg_secondary_color"
                        onChange={handleInput}
                        value={editUser?.admin_dark_bg_secondary_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="admin_dark_bg_secondary_color" onChange={handleInput} value={editUser?.admin_dark_bg_secondary_color}></input>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <MessageLabel text="Light Mode" className='text-sm-end pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-end ps-sm-2">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="admin_light_button_bg_color"
                        name="admin_light_button_bg_color"
                        onChange={handleInput}
                        value={editUser?.admin_light_button_bg_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="admin_light_button_bg_color" onChange={handleInput} value={editUser?.admin_light_button_bg_color}></input>
                    </div>
                  </div>
                  {/* <div className="pickerWidth">
                   <MessageSubHeading className="colorText text-center" text="Secondary" />
                  <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                    <input
                      type="color"
                      className="rs-input form-control-lg p-0  colorPicker"
                      id="admin_light_bg_secondary_color"
                      name="admin_light_bg_secondary_color"
                      onChange={handleInput}
                      value={editUser?.admin_light_bg_secondary_color}
                    />
                    <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="admin_light_bg_secondary_color" onChange={handleInput} value={editUser?.admin_light_bg_secondary_color}></input>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </Wrapper>

          {/* Admin Bg button color end */}


          {/* Admin button Text color start */}
          <Wrapper>
            <MessageHeading text="Admin Button Text color" />
            <div className="d-flex align-items-center justify-content-between flex-wrap  ">
              <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                <MessageLabel text="Dark Mode" className='text-sm-start pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-start pe-sm-2">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0 border colorPicker"
                        id="admin_dark_button_text_color"
                        name="admin_dark_button_text_color"
                        onChange={handleInput}
                        value={editUser?.admin_dark_button_text_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary  d-block  w-100 rounded-1 p-2" name="admin_dark_button_text_color" onChange={handleInput} value={editUser?.admin_dark_button_text_color}></input>
                    </div>
                  </div>

                  {/* <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Secondary" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="dark_bg_secondary_color"
                        name="dark_bg_secondary_color"
                        onChange={handleInput}
                        value={editUser?.dark_bg_secondary_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="dark_bg_secondary_color" onChange={handleInput} value={editUser?.dark_bg_secondary_color}></input>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <MessageLabel text="Light Mode" className='text-sm-end pb-2' />
                <div className="d-flex align-items-center gap-3 justify-content-sm-end ps-sm-2">
                  <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Primary" />
                    <div className="mt-2  overflow-hidden shadow  rounded-2">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0  colorPicker "
                        id="admin_light_button_text_color"
                        name="admin_light_button_text_color"
                        onChange={handleInput}
                        value={editUser?.admin_light_button_text_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="admin_light_button_text_color" onChange={handleInput} value={editUser?.admin_light_button_text_color}></input>
                    </div>
                  </div>
                  {/* <div className="pickerWidth">
                    <MessageSubHeading className="colorText text-center" text="Secondary" />
                    <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                      <input
                        type="color"
                        className="rs-input form-control-lg p-0 border colorPicker"
                        id="light_bg_secondary_color"
                        name="light_bg_secondary_color"
                        onChange={handleInput}
                        value={editUser?.light_bg_secondary_color}
                      />
                      <input type="text" className="showCode theme-bg-color-secondary  d-block  w-100 rounded-1 p-2" name="light_bg_secondary_color" onChange={handleInput} value={editUser?.light_bg_secondary_color}></input>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

          </Wrapper>
          {/* Admin button Text color end */}






          <Wrapper>
            <MessageHeading text="Admin Button color" />
            <div className="d-flex align-items-center justify-content-between  ">
              <MessageLabel text="Dark Mode" />
              <MessageLabel text="Light Mode" />
            </div>
            <div className="d-flex align-items-center justify-content-between gap-2  mb-4 ">
              <div className="d-flex align-items-center gap-3">
                <div className="pickerWidth">
                  <label className="m-0 colorText d-block text-center text-secondary">Button color</label>
                  <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                    <input
                      type="color"
                      className="rs-input form-control-lg p-0 border colorPicker"
                      id="button_bg_color"
                      name="button_bg_color"
                      onChange={handleInput}
                      value={editUser?.button_bg_color}
                    />
                    <input type="text" className="showCode theme-bg-color-secondary  d-block  w-100 rounded-1 p-2" name="button_bg_color" onChange={handleInput} value={editUser?.button_bg_color}></input>
                  </div>
                </div>

                {/* <div className="pickerWidth">
                  <label className="m-0 colorText d-block text-center text-secondary">Text color</label>
                  <div className="mt-2  overflow-hidden shadow  rounded-2">
                    <input
                      type="color"
                      className="rs-input form-control-lg p-0  colorPicker "
                      id="admin_dark_button_text_color"
                      name="admin_dark_button_text_color"
                      onChange={handleInput}
                      value={editUser?.admin_dark_button_text_color}
                    />
                    <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="admin_dark_button_text_color" onChange={handleInput} value={editUser?.admin_dark_button_text_color}></input>
                  </div>
                </div> */}
              </div>
              {/* <div className="d-flex align-items-center gap-3">
                <div className="pickerWidth">
                  <label className="m-0 colorText d-block text-center text-secondary">Button color</label>
                  <div className="mt-2  overflow-hidden shadow  rounded-2">
                    <input
                      type="color"
                      className="rs-input form-control-lg p-0  colorPicker "
                      id="admin_light_button_bg_color"
                      name="admin_light_button_bg_color"
                      onChange={handleInput}
                      value={editUser?.admin_light_button_bg_color}
                    />
                    <input type="text" className="showCode theme-bg-color-secondary d-block  w-100 rounded-1 p-2" name="admin_light_button_bg_color" onChange={handleInput} value={editUser?.admin_light_button_bg_color}></input>
                  </div>
                </div>
                <div className="pickerWidth">
                  <label className="m-0 colorText d-block text-center text-secondary">Text color</label>
                  <div className="mt-2 rounded-3 overflow-hidden shadow  rounded-2 ">
                    <input
                      type="color"
                      className="rs-input form-control-lg p-0 border colorPicker"
                      id="admin_light_button_text_color"
                      name="admin_light_button_text_color"
                      onChange={handleInput}
                      value={editUser?.admin_light_button_text_color}
                    />
                    <input type="text" className="showCode theme-bg-color-secondary  d-block  w-100 rounded-1 p-2" name="admin_light_button_text_color" onChange={handleInput} value={editUser?.admin_light_button_text_color}></input>
                  </div>
                </div>
              </div> */}
            </div>
          </Wrapper>
        </LeftWrapper>
        <RightWrapper>
          <Wrapper>
            <MessageHeading text="Site Logo" />
            <MessageLabel text="Dark Mode" />
            {loading ? (<Response_Loading_processing></Response_Loading_processing>) : (<img src={editUser?.dark_mode_logo} alt="Image" className="thumbnails-images-theme-setting" />)}
            <div className="mx-0 row mb-3">
              <div className="col-6 ps-0">
                <div className="imagedrop theme-border-color" onClick={() => fileInputRef1.current.click()} >
                  <input type="file" name="dark_mode_logo" ref={fileInputRef1} onChange={(event) => handleFileChange(event, fileInputRef1, setSelectedFile1)} accept="image/*" style={{ display: "none" }} />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div>
                  {selectedFile1 && (
                    <div className="imagedropcopy theme-border-color  text-end">
                      <div className="row m-0">
                        <div className="col-10 p-0">
                          <img src={URL.createObjectURL(selectedFile1)} alt="Uploaded" className="thumbnails-upload-images object-fit-contain" />
                        </div>
                        <div className="col-2 p-0" style={{ alignSelf: "center" }} >
                          <button onClick={() => handleDelete(1)} type="button" className=" theme-text-color bg-transparent " ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <MessageLabel text="Light Mode" />
            {loading ? (<Response_Loading_processing></Response_Loading_processing>) : (<img src={editUser?.light_mode_logo} alt="Image" className="thumbnails-images-theme-setting" />)}
            <div className="mx-0 row mb-3">
              <div className="col-6 ps-0">
                <div className="imagedrop theme-border-color" onClick={() => fileInputRef2.current.click()} >
                  <input type="file" name="light_mode_logo" ref={fileInputRef2} onChange={(event) => handleFileChange(event, fileInputRef2, setSelectedFile2)} accept="image/*" style={{ display: "none" }} />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div>
                  {selectedFile2 && (
                    <div className="imagedropcopy theme-border-color  text-end">
                      <div className="row m-0">
                        <div className="col-10 p-0">
                          <img src={URL.createObjectURL(selectedFile2)} alt="Uploaded" className="thumbnails-upload-images object-fit-contain" />
                        </div>
                        <div className="col-2 p-0" style={{ alignSelf: "center" }} >
                          <button onClick={() => handleDelete(2)} type="button" className=" theme-text-color bg-transparent " ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <MessageLabel text="Gif Loader" />
            {loading ? (<Response_Loading_processing></Response_Loading_processing>) : (<img src={editUser?.loading_gif_video_url} alt="Image" className="thumbnails-images-theme-setting" />)}
            <div className="mx-0 row">
              <div className="col-6 ps-0">
                <div className="imagedrop theme-border-color" onClick={() => fileInputRef3.current.click()} >
                  <input type="file" name="loading_gif_video" ref={fileInputRef3} onChange={(event) => handleFileChange(event, fileInputRef3, setSelectedFile3)} accept="image/*" style={{ display: "none" }} />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className="theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div>
                  {selectedFile3 && (
                    <div className="imagedropcopy theme-border-color  text-end">
                      <div className="row m-0">
                        <div className="col-10 p-0">
                          <img src={URL.createObjectURL(selectedFile3)} alt="Uploaded" className="thumbnails-upload-images object-fit-contain" />
                        </div>
                        <div className="col-2 p-0" style={{ alignSelf: "center" }} >
                          <button onClick={() => handleDelete(3)} type="button" className=" theme-text-color bg-transparent " ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>


            {/* 
            <div className="text-start mt-2">
              <label className="m-0">Gif Video</label>

              <div className="">
                {loading ? (
                  <Response_Loading_processing></Response_Loading_processing>
                ) : (
                  <img
                    src={editUser?.loading_gif_video_url}
                    // alt="Image"
                    className="thumbnails-images-theme-setting"
                  />
                )}
              </div>
              <div className="mt-2">
                <div className="col-lg-12 row">
                  <div className="col-6 col-sm-6 col-lg-6">
                    <input
                      type="file"
                      style={{ width: "auto" }}
                      className="form-control-lg rs-input"
                      name="loading_gif_video_url"
                      onChange={(e) => setloading_gif_video_url(e.target.files[0])}
                      multiple
                    />
                  </div>
                </div>
              </div>
            </div> */}

          </Wrapper >
          <Wrapper>
            <MessageHeading text="Theme Switcher" />
            <select name="current_theme" value={editUser?.current_theme} onChange={handleInput} className="form-select mb-3 custom-placeholder   border border-1 theme-border-color  theme-text-color" >
              {themeList?.map((item) => <option value={item?.theme_slug} className="theme-bg-color theme-text-color">{item?.theme_name}</option>)}
            </select>
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Status Settings" />
            <div className="row mx-0 mb-3">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable Developer Tools" />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input name="prevent_inspect" id="prevent_inspect" onChange={handleInputenable} className="rs-input" defaultChecked={editUser?.prevent_inspect == 1 ? true : false} checked={editUser?.prevent_inspect == 1 ? true : false} type="checkbox" />
                  <span className="slider round" name="prevent_inspect" id="prevent_inspect" onChange={handleInputenable} value={editUser?.prevent_inspect == 1 ? "1" : "0"}></span>
                </label>
              </div>
            </div>
            <div className="row mx-0 mb-3">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable Loader" />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input name="loader_setting" id="loader_setting" onChange={handleInputenable} className="rs-input" defaultChecked={editUser?.loader_setting == 1 ? true : false} checked={editUser?.loader_setting == 1 ? true : false} type="checkbox" />
                  <span className="slider round" name="loader_setting" id="loader_setting" onChange={handleInputenable} value={editUser?.loader_setting == 1 ? "1" : "0"}></span>
                </label>
              </div>
            </div>
            <div className="row mx-0 mb-3">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable Audio Page Checkout" />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input name="audio_page_checkout" id="audio_page_checkout" onChange={handleInputenable} className="rs-input" defaultChecked={editUser?.audio_page_checkout == 1 ? true : false} checked={editUser?.audio_page_checkout == 1 ? true : false} type="checkbox" />
                  <span className="slider round" name="audio_page_checkout" id="audio_page_checkout" onChange={handleInputenable} value={editUser?.audio_page_checkout == 1 ? "1" : "0"}></span>
                </label>
              </div>
            </div>

            <div className="row mx-0 mb-3">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable Signin Header" />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input name="signin_header" id="signin_header" onChange={handleInputenable} className="rs-input" defaultChecked={editUser?.signin_header == 1 ? true : false} checked={editUser?.signin_header == 1 ? true : false} type="checkbox" />
                  <span className="slider round" name="signin_header" id="signin_header" onChange={handleInputenable} value={editUser?.signin_header == 1 ? "1" : "0"}></span>
                </label>
              </div>
            </div>

            <div className="row mx-0 mb-3">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable Translate Checkout" />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input name="translate_checkout" id="translate_checkout" onChange={handleInputenable} className="rs-input" defaultChecked={editUser?.translate_checkout == 1 ? true : false} checked={editUser?.translate_checkout == 1 ? true : false} type="checkbox" />
                  <span className="slider round" name="translate_checkout" id="translate_checkout" onChange={handleInputenable} value={editUser?.translate_checkout == 1 ? "1" : "0"}></span>
                </label>
              </div>
            </div>

            <div className="row mx-0 mb-3">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable Audio Content Right Checkout" />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input name="audio_content_right_checkout" id="audio_content_right_checkout" onChange={handleInputenable} className="rs-input" defaultChecked={editUser?.audio_content_right_checkout == 1 ? true : false} checked={editUser?.audio_content_right_checkout == 1 ? true : false} type="checkbox" />
                  <span className="slider round" name="audio_content_right_checkout" id="audio_content_right_checkout" onChange={handleInputenable} value={editUser?.audio_content_right_checkout == 1 ? "1" : "0"}></span>
                </label>
              </div>
            </div>

            <div className="row mx-0 mb-3">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable Content Partner Page" />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input name="content_partner_checkout" id="content_partner_checkout" onChange={handleInputenable} className="rs-input" defaultChecked={editUser?.content_partner_checkout == 1 ? true : false} checked={editUser?.content_partner_checkout == 1 ? true : false} type="checkbox" />
                  <span className="slider round" name="content_partner_checkout" id="content_partner_checkout" onChange={handleInputenable} value={editUser?.content_partner_checkout == 1 ? "1" : "0"}></span>
                </label>
              </div>
            </div>


            <div className="row mx-0 mb-3">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable Partner VOD/AOD" />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input name="Vod_Aod_checkout" id="Vod_Aod_checkout" onChange={handleInputenable} className="rs-input" defaultChecked={editUser?.Vod_Aod_checkout == 1 ? true : false} checked={editUser?.Vod_Aod_checkout == 1 ? true : false} type="checkbox" />
                  <span className="slider round" name="Vod_Aod_checkout" id="Vod_Aod_checkout" onChange={handleInputenable} value={editUser?.Vod_Aod_checkout == 1 ? "1" : "0"}></span>
                </label>
              </div>
            </div>

            <div className="row mx-0 mb-3">
              <div className="col-9 ps-0">
                <MessageLabel text="Header Top Position" />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input name="header_top_position" id="header_top_position" onChange={handleInputenable} className="rs-input" defaultChecked={editUser?.header_top_position == 1 ? true : false} checked={editUser?.header_top_position == 1 ? true : false} type="checkbox" />
                  <span className="slider round" name="header_top_position" id="header_top_position" onChange={handleInputenable} value={editUser?.header_top_position == 1 ? "1" : "0"}></span>
                </label>
              </div>
            </div>

            <div className="row mx-0 mb-3">
              <div className="col-9 ps-0">
                <MessageLabel text="Header Side Position" />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input name="header_side_position" id="header_side_position" onChange={handleInputenable} className="rs-input" defaultChecked={editUser?.header_side_position == 1 ? true : false} checked={editUser?.header_side_position == 1 ? true : false} type="checkbox" />
                  <span className="slider round" name="header_side_position" id="header_side_position" onChange={handleInputenable} value={editUser?.header_side_position == 1 ? "1" : "0"}></span>
                </label>
              </div>
            </div>

            <div className="row mx-0 mb-3">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable Extract Images" />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input name="enable_extract_image" id="enable_extract_image" onChange={handleInputenable} className="rs-input" defaultChecked={editUser?.enable_extract_image == 1 ? true : false} checked={editUser?.enable_extract_image == 1 ? true : false} type="checkbox" />
                  <span className="slider round" name="enable_extract_image" id="enable_extract_image" onChange={handleInputenable} value={editUser?.enable_extract_image == 1 ? "1" : "0"}></span>
                </label>
              </div>
            </div>

            <div className="row mx-0 mb-3">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable Bunny CDN Option" />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input name="enable_bunny_cdn" id="enable_bunny_cdn" onChange={handleInputenable} className="rs-input" defaultChecked={editUser?.enable_bunny_cdn == 1 ? true : false} checked={editUser?.enable_bunny_cdn == 1 ? true : false} type="checkbox" />
                  <span className="slider round" name="enable_bunny_cdn" id="enable_bunny_cdn" onChange={handleInputenable} value={editUser?.enable_bunny_cdn == 1 ? "1" : "0"}></span>
                </label>
              </div>
            </div>
            <div className="row mx-0 mb-3">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable Frontend Hover Theme Slider " />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input name="enable_slider" id="enable_slider" onChange={handleInputenable} className="rs-input" defaultChecked={editUser?.enable_slider == 1 ? true : false} checked={editUser?.enable_slider == 1 ? true : false} type="checkbox" />
                  <span className="slider round" name="enable_slider" id="enable_slider" onChange={handleInputenable} value={editUser?.enable_slider == 1 ? "1" : "0"}></span>
                </label>
              </div>
            </div>

            {/* <div className="row mx-0 mb-3">
              <div className="col-9 ps-0">
                <MessageLabel text="Enable Profile Settings" />
              </div>
              <div className="col-3 text-end pe-0">
                <label className="switch">
                  <input name="my_profile_theme" id="my_profile_theme" onChange={handleInputenable} className="rs-input" defaultChecked={editUser?.my_profile_theme == 1 ? true : false} checked={editUser?.my_profile_theme == 1 ? true : false} type="checkbox" />
                  <span className="slider round" name="my_profile_theme" id="my_profile_theme" onChange={handleInputenable} value={editUser?.my_profile_theme == 1 ? "1" : "0"}></span>
                </label>
              </div>
            </div> */}
          </Wrapper>
        </RightWrapper >
        <AdminCustomButton saveMethod={handleSave} saveText="Update" className="ms-auto" />
      </FormContainer >
    </>
  );
}

export default ThemeSettings;