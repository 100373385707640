import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./MobileSettings.css";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function EditSplashMobileSettings(props) {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [editUser, setEditUser] = useState({
    splash_image_url: "",
    andriod_splash_image_url: "",
    AndroidTv_splash_screen_url: "",
    LG_splash_screen_url: "",
    RokuTV_splash_screen_url: "",
    Samsung_splash_screen_url: "",
    Firetv_splash_screen_url: "",
  });

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/splash-screen-edit/${id}`,
        { headers: headers }
      )
      .then((res) => {
        setEditUser(res?.data?.splash_screen[0]);
        setLoading(false);
      });
  }, []);

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);
  const fileInputRef6 = useRef(null);
  const fileInputRef7 = useRef(null);
  const fileInputRef8 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [selectedFile5, setSelectedFile5] = useState(null);
  const [selectedFile6, setSelectedFile6] = useState(null);
  const [selectedFile7, setSelectedFile7] = useState(null);
  const [selectedFile8, setSelectedFile8] = useState(null);

  const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      case 4:
        setSelectedFile4(null);
        if (fileInputRef4.current) {
          fileInputRef4.current.value = null;
        }
        break;
      case 5:
        setSelectedFile5(null);
        if (fileInputRef5.current) {
          fileInputRef5.current.value = null;
        }
        break;
      case 6:
        setSelectedFile6(null);
        if (fileInputRef6.current) {
          fileInputRef6.current.value = null;
        }
        break;
      case 7:
        setSelectedFile7(null);
        if (fileInputRef7.current) {
          fileInputRef7.current.value = null;
        }
        break;
      case 8:
        setSelectedFile8(null);
        if (fileInputRef8.current) {
          fileInputRef8.current.value = null;
        }
        break;
      default:
        break;
    }
  };


  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function handleUpdate() {
    const formData = new FormData();

    formData.append("id", id);
    formData.append("splash_image", selectedFile1);
    formData.append("andriod_splash_image", selectedFile2);
    formData.append("AndroidTv_splash_screen", selectedFile4);
    formData.append("LG_splash_screen", selectedFile5);
    formData.append("RokuTV_splash_screen", selectedFile6);
    formData.append("Samsung_splash_screen", selectedFile7);
    formData.append("Firetv_splash_Screen", selectedFile8);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/splash-screen-update/${id}`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;

        resultapi = response.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        console.log(resultError);
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  const getFileType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'webp':
      case 'bmp':
      case 'svg':
        return 'image';
      case 'gif':
        return 'image';
      case 'mp4':
        return 'video';
      case 'webm':
        return 'video';
      case 'ogg':
        return 'video';
      case 'm3u8':
        return 'video';
      default:
        return 'application';
    }
  };


  const getFileTypeaddvideo = (file) => {
    if (!file || !file.name) {
      return 'application';
    }
    const extension = file.name.split('.').pop().toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'webp':
      case 'bmp':
      case 'svg':
        return 'image';
      case 'gif':
        return 'image';
      case 'mp4':
        return 'video';
      case 'webm':
        return 'video';
      case 'ogg':
        return 'video';
      case 'm3u8':
        return 'video';
      default:
        return 'application';
    }
  };

  // function editProduct() {
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //     Accept: "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };

  //   // console.log(splash_image, andriod_splash_image)
  //   const formData = new FormData();
  //   formData.append("splash_image", splash_image);
  //   formData.append("andriod_splash_image", andriod_splash_image);
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_Baseurl}/admin/Mobile-App-Setting/splash-screen-store`,
  //       formData,
  //       {
  //         headers: headers,
  //       }
  //     )
  //     .then((response) => {
  //       console.log("response", response.data);
  //       alert("success");
  //       navigate("/mobileapp");
  //     })
  //     .catch((error) => {
  //       console.log("error", error.response);
  //     });
  // }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Mobile App Image</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Splash
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Splash Screen</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Splash Screen(IOS) </label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing />
                      ) : (
                        <>
                          {(() => {
                            const fileType = getFileType(editUser?.splash_image_url);
                            if (fileType === 'image') {
                              return (
                                <img
                                  src={editUser?.splash_image_url}
                                  alt="Media"
                                  className="thumbnails-images"
                                />
                              );
                            } else if (fileType === 'video') {
                              return (
                                <video
                                  src={editUser?.splash_image_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else if (fileType === 'm3u8') {
                              return (
                                <video
                                  src={editUser?.splash_image_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else {
                              return (
                                null
                              );
                            }
                          })()}
                        </>
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">

                                    {(() => {
                                      const fileType = getFileTypeaddvideo(selectedFile1);
                                      const fileURL = URL.createObjectURL(selectedFile1);
                                      if (fileType === 'image') {
                                        return (
                                          <img
                                            src={fileURL}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else if (fileType === 'video') {
                                        return (
                                          <video
                                            src={fileURL}
                                            controls
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}

                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(1)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Splash Screen (Andriod) </label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing />
                      ) : (
                        <>
                          {(() => {
                            const fileType = getFileType(editUser?.andriod_splash_image_url);
                            if (fileType === 'image') {
                              return (
                                <img
                                  src={editUser?.andriod_splash_image_url}
                                  alt="Media"
                                  className="thumbnails-images"
                                />
                              );
                            } else if (fileType === 'video') {
                              return (
                                <video
                                  src={editUser?.andriod_splash_image_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else if (fileType === 'm3u8') {
                              return (
                                <video
                                  src={editUser?.andriod_splash_image_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else {
                              return (
                                null
                              );
                            }
                          })()}
                        </>
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">

                                    {(() => {
                                      const fileType = getFileTypeaddvideo(selectedFile2);
                                      const fileURL = URL.createObjectURL(selectedFile2);
                                      if (fileType === 'image') {
                                        return (
                                          <img
                                            src={fileURL}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else if (fileType === 'video') {
                                        return (
                                          <video
                                            src={fileURL}
                                            controls
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}


                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(2)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Splash Screen(Android TV)</label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing />
                      ) : (
                        <>
                          {(() => {
                            const fileType = getFileType(editUser?.AndroidTv_splash_screen_url);
                            if (fileType === 'image') {
                              return (
                                <img
                                  src={editUser?.AndroidTv_splash_screen_url}
                                  alt="Media"
                                  className="thumbnails-images"
                                />
                              );
                            } else if (fileType === 'video') {
                              return (
                                <video
                                  src={editUser?.AndroidTv_splash_screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else if (fileType === 'm3u8') {
                              return (
                                <video
                                  src={editUser?.AndroidTv_splash_screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else {
                              return (
                                null
                              );
                            }
                          })()}
                        </>
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef4.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef4}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef4,
                                  setSelectedFile4
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile4 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">

                                    {(() => {
                                      const fileType = getFileTypeaddvideo(selectedFile4);
                                      const fileURL = URL.createObjectURL(selectedFile4);
                                      if (fileType === 'image') {
                                        return (
                                          <img
                                            src={fileURL}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else if (fileType === 'video') {
                                        return (
                                          <video
                                            src={fileURL}
                                            controls
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}


                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(4)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Splash Screen(LG TV)</label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing />
                      ) : (
                        <>
                          {(() => {
                            const fileType = getFileType(editUser?.LG_splash_screen_url);
                            if (fileType === 'image') {
                              return (
                                <img
                                  src={editUser?.LG_splash_screen_url}
                                  alt="Media"
                                  className="thumbnails-images"
                                />
                              );
                            } else if (fileType === 'video') {
                              return (
                                <video
                                  src={editUser?.LG_splash_screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else if (fileType === 'm3u8') {
                              return (
                                <video
                                  src={editUser?.LG_splash_screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else {
                              return (
                                null
                              );
                            }
                          })()}
                        </>
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef5.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef5}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef5,
                                  setSelectedFile5
                                )
                              }
                              accept="image/*,video/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile5 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    {(() => {
                                      const fileType = getFileTypeaddvideo(selectedFile5);
                                      const fileURL = URL.createObjectURL(selectedFile5);
                                      if (fileType === 'image') {
                                        return (
                                          <img
                                            src={fileURL}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else if (fileType === 'video') {
                                        return (
                                          <video
                                            src={fileURL}
                                            controls
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}

                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(5)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Splash Screen </h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Splash Screen(ROKU TV)</label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing />
                      ) : (
                        <>
                          {(() => {
                            const fileType = getFileType(editUser?.RokuTV_splash_screen_url);
                            if (fileType === 'image') {
                              return (
                                <img
                                  src={editUser?.RokuTV_splash_screen_url}
                                  alt="Media"
                                  className="thumbnails-images"
                                />
                              );
                            } else if (fileType === 'video') {
                              return (
                                <video
                                  src={editUser?.RokuTV_splash_screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else if (fileType === 'm3u8') {
                              return (
                                <video
                                  src={editUser?.RokuTV_splash_screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else {
                              return (
                                null
                              );
                            }
                          })()}
                        </>
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef6.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef6}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef6,
                                  setSelectedFile6
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile6 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">

                                    {(() => {
                                      const fileType = getFileTypeaddvideo(selectedFile6);
                                      const fileURL = URL.createObjectURL(selectedFile6);
                                      if (fileType === 'image') {
                                        return (
                                          <img
                                            src={fileURL}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else if (fileType === 'video') {
                                        return (
                                          <video
                                            src={fileURL}
                                            controls
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}


                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(6)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div className=" text-start mt-2">
                    <label className="m-0">Splash Screen(SAMSUNG TV)</label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing />
                      ) : (
                        <>
                          {(() => {
                            const fileType = getFileType(editUser?.Samsung_splash_screen_url);
                            if (fileType === 'image') {
                              return (
                                <img
                                  src={editUser?.Samsung_splash_screen_url}
                                  alt="Media"
                                  className="thumbnails-images"
                                />
                              );
                            } else if (fileType === 'video') {
                              return (
                                <video
                                  src={editUser?.Samsung_splash_screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else if (fileType === 'm3u8') {
                              return (
                                <video
                                  src={editUser?.Samsung_splash_screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else {
                              return (
                                null
                              );
                            }
                          })()}
                        </>
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef7.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef7}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef7,
                                  setSelectedFile7
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile7 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    {(() => {
                                      const fileType = getFileTypeaddvideo(selectedFile7);
                                      const fileURL = URL.createObjectURL(selectedFile7);
                                      if (fileType === 'image') {
                                        return (
                                          <img
                                            src={fileURL}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else if (fileType === 'video') {
                                        return (
                                          <video
                                            src={fileURL}
                                            controls
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}


                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(7)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div className=" text-start mt-2">
                    <label className="m-0">Splash Screen(FIRE TV)</label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing />
                      ) : (
                        <>
                          {(() => {
                            const fileType = getFileType(editUser?.Firetv_splash_screen_url);
                            if (fileType === 'image') {
                              return (
                                <img
                                  src={editUser?.Firetv_splash_screen_url}
                                  alt="Media"
                                  className="thumbnails-images"
                                />
                              );
                            } else if (fileType === 'video') {
                              return (
                                <video
                                  src={editUser?.Firetv_splash_screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else if (fileType === 'm3u8') {
                              return (
                                <video
                                  src={editUser?.Firetv_splash_screen_url}
                                  controls
                                  className="thumbnails-images"
                                >
                                </video>
                              );
                            } else {
                              return (
                                null
                              );
                            }
                          })()}
                        </>
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef8.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef8}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef8,
                                  setSelectedFile8
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile8 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    {(() => {
                                      const fileType = getFileTypeaddvideo(selectedFile8);
                                      const fileURL = URL.createObjectURL(selectedFile8);
                                      if (fileType === 'image') {
                                        return (
                                          <img
                                            src={fileURL}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else if (fileType === 'video') {
                                        return (
                                          <video
                                            src={fileURL}
                                            controls
                                            className="thumbnails-upload-images"
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    })()}


                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(8)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card p-2">
              <h4>Edit Mobile Setting</h4>
              <hr />

              <div className="container-fluid row" id="rtmp_url">
                <h4 className="mt-3 ">Splash Image</h4>

                <div className="col-lg-7 ">
                  <div className="mt-2">
                    <img src={splash_image_url} width={100} />
                  </div>
                  <div className="mt-3">
                    <input
                      type="file"
                      onChange={(e) => setSplash_image(e.target.files[0])}
                    />
                  </div>
                </div>

                <h4 className="mt-3 ">Andriod Splash Image</h4>

                <div className="col-lg-7 ">
                  <div className="mt-2">
                    <img src={andriod_splash_image_url} width={100} />
                  </div>
                  <div className="mt-3">
                    <input
                      type="file"
                      onChange={(e) =>
                        setAndriod_splash_image(e.target.files[0])
                      }
                    />
                  </div>
                </div>
                <div className="text-end mt-4">
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                    onClick={editProduct}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default EditSplashMobileSettings;
