import React from "react";

const Terms_and_condition = () => {
  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid">
          <div id="content-page" className="content-page">
            <div className=" mt-4">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="text-center">
                    <h4>Terms and Conditions</h4>
                  </div>

                  <div className="mt-2">
                    <p>
                      Service Level Agreement Coverage: This website Service
                      Level Agreement is applicable to you if you have purchased
                      any hosting plans, shared hosting services, dedicated
                      servers, or any outlined service provided by us on
                      Flicknexs website [“Service”] and you are in good
                      financial standing with Flicknexs. Service Level Flicknexs
                      strives to have network connectivity available for http
                      access by third parties 99.9% of the time [“Web Site
                      Availability”]. Availability of Services Subject to the
                      terms and conditions of this agreement Flicknexs shall try
                      its level best to offer the dedicated services through the
                      term of this Agreement. You, however, agree that from time
                      to time the services may be inaccessible/ inactive for any
                      reason, including, without any limitation: Equipment
                      malfunctions and causes beyond the control of Flicknexs or
                      that are not reasonably foreseeable by Flicknexs,
                      including, without limitation, failure or interruption of
                      telecommunication or digital transmission links, network
                      congestion, hostile network attacks or other failures; and
                      periodic repair procedures or maintenance that Flicknexs
                      may undertake from time to time. You agree Flicknexs has
                      no control of availability of services on a continuous
                      and/ or uninterrupted basis. You further agree that as a
                      normal course of its business, it may be really necessary
                      for Flicknexs to migrate its servers. As a result, even
                      though you may have a dedicated IP, you may be assigned a
                      different IP number. Flicknexs does not warrant that you
                      will be able to consistently maintain your allotted IP
                      numbers. Flicknexs offers a service uptime guarantee for
                      the Services of 99.9% (“Service Uptime”) of available
                      time. If Flicknexs fails to maintain this level of service
                      availability, you may contact Flicknexs for further follow
                      up for that month. The credit may be used only for the
                      purchase of further services and products from Flicknexs,
                      (excluding applicable taxes), this service is not
                      available for our dedicated server clients. The credit,
                      however, does not apply to service interruptions caused
                      by: Errors caused by You from custom coding or scripting,
                      at your end; or causes beyond control of Flicknexs or that
                      are not reasonably foreseeable by Flicknexs; or periodic
                      scheduled repairs or maintenance Flicknexs may undertake
                      from time to time; and outages that do not affect the
                      appearance of the website but simply affect access to the
                      site such as email and FTP; or outages related to the
                      reliability of certain programming environments. FTP
                      Backup You agree and acknowledge that from time-to-time
                      the FTP Backup services may be inoperable or inaccessible
                      for any reason, including without any limitations
                      whatsoever : periodic repairs or maintenance procedures
                      that Flicknexs may undertake from time to time, equipment
                      mal-functioning’s causes beyond control of Flicknexs or
                      that are not reasonably foreseeable by Flicknexs ,
                      including, without limitation, failure, interruption of
                      digital transmission or telecommunication links, network
                      congestion, hostile network attacks, or other failures.
                      Storage & Security At all times, you shall bear full risk
                      of damage and loss to your website and all of your website
                      content. You are entirely responsible for maintaining the
                      confidentiality of your password and account information.
                      You agree you are solely and exclusively responsible for
                      all acts, omissions and use under and charges incurred
                      with respect to your account or password or in connection
                      with the Site or any of your web site content displayed,
                      transmitted, linked through or stored on the Server. You
                      shall be also being solely responsible for undertaking
                      measures to : maintain independent archival & backup
                      copies of Your site content, ensure the confidentiality,
                      security and integrity of all Your site content
                      transmitted through/stored on Flicknexs servers, Ensure
                      the confidentiality of your password. Prevent any
                      damage/loss to Your site content. Flicknexs RIGHTS.
                      Flicknexs explicitly reserves the right and sole
                      discretion to modify its pricing, if desired by Flicknexs
                      from time to time; establish guidelines and limits
                      concerning the use of the services. Terminate your use of
                      services for use of our services to
                      unnecessarily/illegally harass Flicknexs or third parties,
                      non-payment of fees for our services, activities designed
                      to embarrass, defame, harm, threaten, abuse, slander,
                      activities prohibited by the laws of India and/or foreign
                      territories in which You conduct business, activities
                      designed to encourage unlawful behavior by others, such as
                      terrorism, child pornography, hate crimes, activities that
                      are tortious, obscene, vulgar, invasive of the privacy of
                      a third party, ethnically, racially, or otherwise
                      objectionable in the sole opinion of Flicknexs or declared
                      by the law of land, activities designed to harm minors in
                      any way, activities designed to impersonate the identity
                      of a third party, and other activities whether
                      lawful/unlawful that Flicknexs determines, in its sole
                      discretion, to be harmful to its other customers,
                      reputation or operations. Terminate Your use of services
                      if Your use of services results in, or is the subject of,
                      legal action or threatened or proposed legal action,
                      against Flicknexs or any of its affiliates/partners,
                      without considering for whether such legal action or
                      threats or proposed legal action is eventually determined
                      to be with/without merit; and terminate Your use of
                      services at any time and for any reason if deemed
                      reasonably necessary by Flicknexs. Credits: Credits shall
                      not be granted to you by Flicknexs in the event that you
                      have no Website Availability as a consequence of (a)
                      Scheduled maintenance, (b) Your behavior or the
                      performance or failure of your
                      equipment/facilities/applications or (c) Circumstances
                      beyond Flicknexs’s reasonable control, including, without
                      limitation, acts of war, any governmental body, sabotage,
                      insurrection, embargo, fire, flood, strike, act of god or
                      other labor disturbance, delay or interruption in
                      transportation, interruption, delay or unavailability of
                      telecommunications or third party services (including DNS
                      propagation), failure of third party hardware/software or
                      inability to obtain raw materials, supplies or power used
                      in or equipment needed for provision of your website.
                      Flicknexs forbids the use of scripts or software run on
                      its servers that cause the server to load beyond a
                      reasonable level, as determined by Flicknexs. You shall at
                      all times use the services as a conventional and/or
                      traditional web site Particularly by way of example and
                      not as a limitation, you shall not employ our services as
                      a storage or instrument for storing or placing archived
                      files and/or material that can be downloaded through other
                      web sites. Support Boundaries: The Skype Chat with
                      customers/clients is only for basic help. Please do not
                      provide your sensitive information to our
                      agents/executives etc like login details, passwords,
                      banking info etc on Skype Chat. You may also not make any
                      commitments on Skype chat. All such commitments,
                      disclosures and interactions with our team would solely be
                      at your own risk and Flicknexs do not deem valid or vouch
                      for the authenticity of all such interactions. Flicknexs
                      recommends that for all complex and advanced issues
                      customer duly raise a ticket by logging into their member
                      control panels. Online issues and problems occur
                      constantly. There might be occasions when you are unable
                      to access your website or any other service. This is not
                      necessarily due to Flicknexs. Possibly your Internet
                      Services Provider (ISP) is experiencing technical
                      difficulties, or there might be a routing problem between
                      your ISP and Flicknexs, making communication difficult or
                      even impossible. We cannot bear the responsibility of such
                      problems and are not at all liable to lend ourselves to
                      redress of any sort. Our team of monitoring agents
                      determines the uptime of our service, and not any single
                      client’s experience. Note: We reserve the right at our
                      discretion to make changes to this policy at any time.
                      Please review this page periodically for changes. If you
                      have any queries concerning the above-stated terms and
                      conditions then please Contact us. Terms and Conditions
                      Email Marketing Solution By visiting this website
                      (https://www.flicknexs.com) or purchasing the product from
                      Flicknexs you are accepting the terms & conditions of the
                      company. USER/BUYER/PURCHASER/VISITOR – Generally we call
                      USER/USERS. Important Note: If you
                      (USER/BUYER/PURCHASER/VISITOR) are not agreeing or
                      accepting https://www.flicknexs.com company terms &
                      conditions please do not use this website and leave this
                      website and also do not purchase any product or offer from
                      this website. The “email id database” purchaser/buyer from
                      https://www.flicknexs.com is called as “USER”. The email
                      id database provided by Flicknexs.com was verified from
                      our end using our own verification and validation method
                      and techniques. The email ids are valid at the time of
                      validation done by Flicknexs.com and never give any
                      guarantee to “USERS” for accuracy of email ids. Email ids
                      may be not valid or not live or inactive in near future.
                      Flicknexs never gives any guarantee for whether the email
                      ids provided by Flicknexs.com will be valid or active for
                      some period of time. After purchasing the email ids from
                      Flicknexs.com “USER” has to use the email ids only for
                      their product promotion and USER should not use for
                      spamming or other illegal purpose. If USER doing any
                      illegal activity or spamming, then any third party has
                      rights to take action against USER. Flicknexs.com is not
                      selling any softwares or tools. Flicknexs.com has full
                      rights to modify or stop the existing offers/free
                      gifts/any other free itmes at anytime without any prior
                      notice. All the offers/free gifts/any other free items are
                      subject to availability. Flicknexs.com never gives any
                      warranty or any guarantee or any other promise to USERs
                      for email id database correctness, accuracy, adequacy,
                      usefulness, timeliness, reliability or otherwise. After
                      purchasing the email id database product, money is not
                      refundable/ transferable at any cause for any reasons. Due
                      to the nature of the data sold, all sales are final and
                      non-refundable. Any database or custom database or email
                      list sold by Flicknexs.com comes with no guarantee of
                      response rate or accuracy. Flicknexs.com is never
                      answerable, liable, questionable for accuracy of the
                      database. Spam Policy At Flicknexs, we’ve crafted a
                      strong, zero-tolerance anti-spam policy to protect the
                      delivery rates and reputations of our customers. If you
                      feel one of our customers has sent you spam, please email
                      us and we’ll immediately take steps to solve the problem.
                      Our Anti-Spam Policy. Simply stated, we at Email are
                      anti-spam. We hold the privacy of all users in the highest
                      regard, as well as the recipients of emails sent by our
                      customers. “SPAM” has proven to be a major problem for
                      many of our customers and the Internet at large. Spam is a
                      common slang term referring to the sending of unsolicited
                      email, instant messaging, or other communication to a
                      large group of people at once. Variations on this practice
                      include off-topic (and usually promotional) messages on
                      bulletin boards, guest books, Usenet newsgroups, or chat
                      rooms. Spam is strictly against our Terms and Conditions
                      and will result in legal action to be taken by a number of
                      third parties, including ISP services, User’s, and Other
                      involved parties. The Federal CAN-SPAM Act, put into law
                      in 2003, dictates rules in sending commercial email. We at
                      vigorously follow those rules. They include: If you are
                      sending commercial email, non-personal, business-focused
                      email, you must have specific permission to email your
                      recipients. You can do this by using opt-in or closed loop
                      opt-in to sign up subscribers. You must hang on to
                      evidence that subscribers have given you permission, and
                      must be able to produce that evidence within 72 hours of
                      request. You must tell your recipients, in your email,
                      where you got their info. This might include a line that
                      says “you received this email because you signed up for
                      our newsletter at https://www.yourdomain.com You must
                      honor all unsubscribe requests within 10 days of receipt
                      and provide a reasonable way for customers to unsubscribe.
                      The domain attached to your email must have its own
                      privacy policy. You must be able to track down anonymous
                      complaints. You must tell the truth in your email, from
                      where your office is located to who is sending the actual
                      communication. Warranty Disclaimer Flicknexs.com never
                      answerable, liable, questionable for accuracy of the inbox
                      delivery of mails as inbox delivery guarantee depends on
                      SMTP and the techniques applied, Content of Mail, Email
                      Database, High amount of mailing to Gmail/yahoo in a
                      instant and different location ISP network. We do take
                      guarantee of SMTP and solution we are going to apply like
                      IP Rotation/ Domain Rotation. This site and the materials
                      and products on this site are provided “as is” and without
                      warranties of any kind, whether express or implied. To the
                      fullest extent permissible pursuant to applicable law,
                      Flicknexs.com disclaims all warranties, express or
                      implied, including, but not limited to, implied warranties
                      of merchantability and fitness for a particular purpose
                      and non-infringement.
                      FlicknexsFlicknexsFlicknexsFlicknexsreit.in does not
                      represent or warrant that the functions contained in the
                      site will be uninterrupted or error-free, that the defects
                      will be corrected, or that this site or the server that
                      makes the site available are free of viruses or other
                      harmful components. Flicknexs.com does not make any
                      warrantees or representations regarding the use of the
                      materials in this site in terms of their correctness,
                      accuracy, adequacy, usefulness, timeliness, reliability or
                      otherwise. By purchasing a database on our site you
                      understand that all fields may not be present on certain
                      databases. You expressly agree that use of the
                      Database/Service is at your sole risk. The
                      Database/Service is provided by Flicknexs.com on an “as
                      is” and “as available” basis. Flicknexs.com expressly
                      disclaims all warranties of any kind, whether express or
                      implied, including, but not limited to, the implied
                      warranties of merchantability, fitness for a particular
                      purpose and non-infringement. Flicknexs.com makes no
                      warranties that the Database/Service will meet your
                      requirements and/or be uninterrupted, timely, secure or
                      error-free, nor does Flicknexs.com make any warranty as to
                      the accuracy or reliability of any information obtained
                      and/or downloaded through the use of the Database/Service
                      or that defects in the system and software will be
                      corrected. Flicknexs.com makes no warranty regarding any
                      goods or Database/Services purchased or downloaded through
                      the use of the Database/Service or any third-party links
                      or any information obtained or transactions entered into
                      through the use of the Database/Service. No advice or
                      information, whether oral or written, obtained by you from
                      Flicknexs.com shall create a warranty not expressly stated
                      herein. Furthermore, Flicknexs.com makes no warranties
                      concerning the suitability of the content provided through
                      the Database/Service for any purpose. Accuracy Disclaimer
                      Any database or custom database or email list sold by
                      Flicknexs.com comes with no guarantee of response rate or
                      accuracy. The database sold by Flicknexs.com comes with no
                      guarantee of correctness, accuracy, adequacy, usefulness,
                      timeliness, reliability or otherwise. USER Disclaimer
                      Flicknexs.com does not and cannot review all
                      communications and materials posted to or created by users
                      accessing the site, and is not in any manner responsible
                      for the content of these communications and materials. You
                      acknowledge that by providing you with the ability to view
                      and distribute user-generated content on the site,
                      Flicknexs.com is merely acting as a passive conduit for
                      such distribution and is not undertaking any obligation or
                      liability relating to any contents or activities on the
                      site. However, Flicknexs.com reserves the right to block
                      or remove communications or materials that it determines
                      to be (a) abusive, defamatory, or obscene, (b) fraudulent,
                      deceptive, or misleading, (c) in violation of a copyright,
                      trademark or; other intellectual property right of another
                      or (d) offensive or otherwise unacceptable to
                      Flicknexs.com in its sole discretion. Liability Disclaimer
                      Flicknexs.com shall not be liable for any damages
                      whatsoever, whether direct, indirect, punitive,
                      incidental, special or consequential, arising out of or in
                      any way connected to the use of the Database/Service or
                      with the delay or inability to use the Database/Service,
                      or for any information, products and Database/Services
                      obtained through use of the Database/Service, or otherwise
                      arising out of the utilization of the Database/Service,
                      whether based in contract, tort, strict liability or
                      otherwise, even if Flicknexs.com has been advised of the
                      possibility of said damages. Furthermore, Flicknexs.com
                      shall not be liable for any damages arising from the
                      interruption, suspension or termination of the
                      Database/Service, including, but not limited to, direct,
                      indirect, incidental, special, consequential or exemplary
                      damages, whether or not such interruption, suspension or
                      termination was justified, negligent or intentional.
                      Refund Policy Due to the nature of the data sold, all
                      sales are final and non-refundable. If you have any
                      questions regarding our terms of use or refund policy
                      please call or email mailto:support@flicknexs.com before
                      placing your order. By placing your order and submitting
                      your information you are entering into a binding purchase
                      contract. This has been done to protect the integrity of
                      our databases from any fraudulent activity on our site. I
                      give full authorization to Flicknexs.com to charge me in
                      full for any and all products purchased from
                      Flicknexs.com. I understand that any and all products
                      purchased from this site do not have any guarantee of
                      response rate or accuracy. I understand that all sales are
                      final, and there are no refunds. Further, any database or
                      custom database or email list sold by Flicknexs.com comes
                      with no guarantee of response rate or accuracy. There is
                      no refund applicable in mailing servers. If we receive any
                      spam or abuse complains then we can suspend or terminate
                      the server immediately. If you do not agree with these
                      terms and our Refund policy please leave our site. End
                      User Conduct By accessing and/or using the
                      Database/Service, you hereby represent and warrant that
                      you will not utilize the Database/Service for any purpose
                      that is unlawful and/or prohibited by the terms and
                      conditions in this Agreement. The Service provided by
                      Flicknexs makes use of the Internet to compose, send and
                      receive Messages; therefore, each End User`s conduct is
                      subject to Internet law, regulations, policies and
                      procedures. By utilizing the Service, you shall not engage
                      in unlawful or unethical activities through your use of
                      the Service. Modifications Flicknexs.com reserves the
                      right to modify and/or discontinue the Database/Service,
                      whether temporarily or permanently, with or without notice
                      to the users, and Flicknexs.com shall not be liable to you
                      or any third party for any such modification and/or
                      discontinuance of the Database/Service. Flicknexs.com
                      further reserves the right to make revisions,
                      modifications, additions, deletions or any such changes to
                      this Agreement at its sole discretion, at any time, with
                      or without notice to its users. Indemnification You agree
                      to indemnify and hold Flicknexs.com, subsidiaries,
                      affiliates, officers, agents, representatives, successors,
                      members, principals and other partners and employees
                      harmless from any loss, liability, claim or demand,
                      including reasonable attorney`s fees, made by any third
                      party due to or arising out of your use of the Site or
                      Database/Services set forth in this Agreement and/or
                      arising from a breach of this Agreement and/or any breach
                      of your representations, obligations and warranties set
                      forth above. Flicknexs.com reserves the right to assume
                      the exclusive defense and control of any matter otherwise
                      subject to indemnification by you, and you shall not, in
                      any event, settle any matter without the written consent
                      of Flicknexs.com. Jurisdiction If there is any dispute
                      about or involving the Database/Service provided by
                      Flicknexs.com, by using the Database/Service, you agree
                      that the dispute will be governed by the laws of the
                      Chennai Court without regard to its conflict of law
                      provisions. You agree to personal jurisdiction by an
                      arbitrator or mediator and the Chennai courts. Any
                      controversy or claim arising out of or relating to this
                      Agreement, or the breach thereof, shall be settled by
                      arbitration administered by the Indian Arbitration
                      Association in accordance with its Commercial (or other)
                      Arbitration Rules (including the Emergency Interim Relief
                      Procedures), and judgment on the award rendered by the
                      arbitrator(s) may be entered in any court having
                      jurisdiction thereof. Severability If any term or
                      provision of this Agreement is determined to be illegal,
                      unenforceable or invalid in whole or in part for any
                      reason, such illegal, unenforceable or invalid
                      provision(s) or part thereof shall be stricken from this
                      Agreement, and such provision(s) shall not affect the
                      legality, enforceability or validity of the remainder of
                      this Agreement. If any provision or part thereof is
                      stricken in accordance with the provisions of this
                      section, then this stricken provision shall be replaced,
                      to the extent possible, with a legal, enforceable and
                      valid provision that is as similar in tenor to the
                      stricken provision as is legally possible. Entire
                      Agreement This Agreement represents the entire agreement
                      between you and Flicknexs.com with respect to your use of
                      the Database/Service, and it supersedes all prior or
                      contemporaneous communications and proposals, whether
                      electronic, oral or written, between you and Flicknexs.com
                      with respect to your use of the Database/Service. All the
                      databases are collected through various sources around the
                      world. The quality of the database is defined on each and
                      every page of the related database on
                      http://www.flicknexs.com. However quality may be differ at
                      any time. So it may be different. The discounts and offer
                      provided by the company can change any time without any
                      prior notice. There is no money back guarantee provided by
                      the company either directly or indirectly. So in any
                      condition there will not be any money back given. Buyers
                      of the database should mention their complete requirement
                      while communicating with us. A buyer should first pay to
                      us for a specified database, then we will verify the
                      payment and then we will process your product delivery
                      operations. We usually send the download link to download
                      the database file. We send the download link by email to
                      the buyer. However, the download link will remain active
                      for a maximum 24 hours only. The download link will remain
                      for only one-time download. After payment for any product
                      you are automatically abided by the terms and conditions
                      of the company. Once you have received the download link,
                      you should not forward the link to anyone else by any mean
                      for any reason. Once the download link becomes inactive,
                      it will not be reactivated thereafter for any one. If we
                      felt that any buyer who is/are intentionally trying to
                      purchase the database and after getting the database
                      he/she may create a dispute about the payment and/or about
                      the product, the company reserves the right to terminate
                      all the relation with that particular buyer and company
                      may forfeit the purchased amount paid or company may
                      refuse to accept the payment for any reason. All Rights
                      are reserved by the company. Any or all the terms and
                      conditions issued by the company may change at any time.
                      All disputes are subject to Jaipur Jurisdiction Only. User
                      Agreement By visiting this website/ purchasing the product
                      from Flicknexs, I (USER/BUYER/PURCHASER/VISITOR) hereby
                      declare and accepting the below. I clearly know that any
                      database or custom database or email list sold by
                      Flicknexs.com comes with no guarantee of response rate or
                      accuracy. I understand that all sales are final, and there
                      are no refunds. I hereby declare that I will not use the
                      email ids for any illegal purpose after purchasing I
                      hereby declare that I will not send any spam mails I
                      clearly understand that Flicknexs.com did not give me any
                      guarantee or warranty for the accuracy of the database. I
                      clearly know that all offers/free gifts/any other free
                      items provided by Flicknexs.com will be stopped or
                      modifiable at anytime without any prior notice. I clearly
                      understand and know that Flicknexs.com not selling any
                      software or tools. Please send email to
                      mailto:support@flicknexs.com for any questions or
                      clarification related to terms & conditions, disclaimers
                      and policy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Terms_and_condition;
