import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import './UsersAnalytics.css'
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

const UsersAnalytics = () => {
  const [datas, setDatas] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);

      // Replace the following URL with your actual GET API endpoint
      const response = await fetch(
        `https://api.example.com/users?start_date=${startDate}&end_date=${endDate}`,
        {
          method: "GET",
          headers: {
            // Add headers if necessary
          },
        }
      );

      const data = await response.json();
      setDatas(data);
      setUserCount(data.length); // Update this based on your data structure

      setFilteredData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(async () => {
    fetchData();
  }, [startDate, endDate]);

  const handleExport = async () => {
    try {
      // Replace the following URL with your actual POST API endpoint for exporting data
      const exportResponse = await fetch("https://api.example.com/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          startDate,
          endDate,
          selectedData: filteredData,
        }),
      });

      if (exportResponse.ok) {
        const blob = await exportResponse.blob();

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "exported_data.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Export failed:", exportResponse.statusText);
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const chartData = {
    labels: ["March", "February", "January"],
    datasets: [
      {
        label: "Register Users Month Wise",
        data: [4, 2, 7, 0],
        borderColor: "rgb(53, 162, 235)",
        lineTension: 0.4,
      },
    ],
  };

  const columns = [
    {
      dataField: "user",
      text: "USER",
    },
    {
      dataField: "name",
      text: "ACC TYPE",
    },
    {
      dataField: "country",
      text: "COUNTRY",
    },
    {
      dataField: "register_on",
      text: "REGISTERED ON",
    },
    {
      dataField: "dob",
      text: "DOB",
    },
    {
      dataField: "source",
      text: "SOURCE",
    },
    {
      dataField: "status",
      text: "STATUS",
    },
    // Add more columns as needed
  ];

  const options = {
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: Number(filteredData?.length) },
    ],
  };

  return (
    <div className="m-0 p-0">
      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="iq-card-header justify-content-between">
            <div className="iq-header-title p-0">
              <h4 className="card-title">Users Analytics</h4>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="row p-3">
              <div className="col-md-12">
                <div>
                  <div className="row mt-2 align-items-center">
                    <div className="col-md-6 user-count">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                          <label className="user_reg_analytics_count">Registered User</label>
                        </div>
                        <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                          <label className="user_reg_analytics_count">{userCount}</label>
                        </div>
                      </div>
                      <div className="col-lg-12 row">
                        <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                          <label className="user_reg_analytics_count">Subscribed User</label>
                        </div>
                        <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                          <label className="user_reg_analytics_count">{userCount}</label>
                        </div>
                      </div>
                      <div className="col-lg-12 row">
                        <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                          <label className="user_reg_analytics_count">Admin User</label>
                        </div>
                        <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                          <label className="user_reg_analytics_count">{userCount}</label>
                        </div>
                      </div>
                      <div className="col-lg-12 row">
                        <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                          <label className="user_reg_analytics_count">PPV User</label>
                        </div>
                        <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                          <label className="user_reg_analytics_count">{userCount}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Line options={options} data={chartData} />
                      <p className="text-center">Register Users Count</p>
                    </div>

                  </div>
                </div>

                <div className="col-lg-12">
                  <div className=" mt-4">

                    <div className="row align-items-center mb-3">
                      <div className="col-md-4">
                        <label htmlFor="start_time"> Start Date: </label>
                        <input
                          type="date"
                          className="rs-input form-control-lg"
                          id="start_time"
                          name="start_time"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="end_time"> End Date: </label>
                        <input
                          type="date"
                          className="rs-input form-control-lg"
                          id="end_time"
                          name="end_time"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                      {/* <div className="col-md-3 d-flex align-items-end">
                    <div className="dropdown">
                      <button
                        className="btn btn-outline-dark filter-btn dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Filter
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              const currentDate = new Date();
                              setEndDate(
                                currentDate.toISOString().split("T")[0]
                              );
                              setStartDate(
                                new Date(
                                  currentDate.setDate(currentDate.getDate() - 1)
                                )
                                  .toISOString()
                                  .split("T")[0]
                              );
                            }}
                          >
                            1 Day
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              const currentDate = new Date();
                              setEndDate(
                                currentDate.toISOString().split("T")[0]
                              );
                              setStartDate(
                                new Date(
                                  currentDate.setDate(currentDate.getDate() - 7)
                                )
                                  .toISOString()
                                  .split("T")[0]
                              );
                            }}
                          >
                            1 Week
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              const currentDate = new Date();
                              setEndDate(
                                currentDate.toISOString().split("T")[0]
                              );
                              setStartDate(
                                new Date(
                                  currentDate.setMonth(
                                    currentDate.getMonth() - 1
                                  )
                                )
                                  .toISOString()
                                  .split("T")[0]
                              );
                            }}
                          >
                            1 Month{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                      <div className="col-md-4 text-right">
                        <span
                          id="export"
                          className="btn btn-success w-50"
                          onClick={handleExport}
                        >
                          Export
                        </span>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                      <div className="filterContainer">
                        <input
                          type="text"
                          placeholder="Filter all.."
                          className="filter form-control "
                          value={filter}
                          onChange={(e) => setFilter(e.target.value)}
                        />
                        {filter?.length > 0 && (
                          <button
                            type="button"
                            className="closeBtn"
                            onClick={() => setFilter("")}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        )}
                      </div>
                      <PaginationProvider
                        pagination={paginationFactory(options)}
                      >
                        {({ paginationTableProps }) => (
                          <div className="bootstrapTable p-2">
                            <BootstrapTable
                              keyField="id"
                              columns={columns}
                              data={filteredData}
                              className="no-border-table"
                              noDataIndication={
                                <div className="noRecord">
                                  {loading ? (
                                    <label>
                                      <Response_Loading_processing></Response_Loading_processing>
                                      Loading...
                                    </label>
                                  ) : (
                                    filteredData?.length <= 0 && (
                                      <p>No Record Found!</p>
                                    )
                                  )}
                                </div>
                              }
                              hover
                              headerClasses="videoWidth"
                              selectAll={selectAll}
                              {...paginationTableProps}
                            />
                          </div>
                        )}
                      </PaginationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UsersAnalytics;
