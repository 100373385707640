// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thumbnails-images-theme-setting {
    width: 200px;
    height: 36px;
    object-fit: contain;
    margin: 10px 0px;
}

.colorPicker {
    height: 60px;
    -webkit-appearance: none;
    border-radius: 6px;
    overflow: hidden;
}

.colorPicker:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

.colorText {
    font-size: 12px !important;
    font-weight: 600;
    margin: 0 !important;
}

.pickerWidth {
    width: 100px;
}

.showCode {
    font-size: 12px !important;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
}

.showCode:focus {
    box-shadow: 0px 0px 1px 1px #ccc;
}

.showCode:focus-visible {
    box-shadow: 0px 0px 1px 1px #ccc;
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Settings/ThemeSettings.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,wBAAwB;IACxB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;IACxB,2BAA2B;IAC3B,uBAAuB;AAC3B;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;IAChC,aAAa;AACjB","sourcesContent":[".thumbnails-images-theme-setting {\n    width: 200px;\n    height: 36px;\n    object-fit: contain;\n    margin: 10px 0px;\n}\n\n.colorPicker {\n    height: 60px;\n    -webkit-appearance: none;\n    border-radius: 6px;\n    overflow: hidden;\n}\n\n.colorPicker:focus {\n    outline: none !important;\n    box-shadow: none !important;\n    border: none !important;\n}\n\n.colorText {\n    font-size: 12px !important;\n    font-weight: 600;\n    margin: 0 !important;\n}\n\n.pickerWidth {\n    width: 100px;\n}\n\n.showCode {\n    font-size: 12px !important;\n    font-weight: 600;\n    text-transform: uppercase;\n    border: none;\n}\n\n.showCode:focus {\n    box-shadow: 0px 0px 1px 1px #ccc;\n}\n\n.showCode:focus-visible {\n    box-shadow: 0px 0px 1px 1px #ccc;\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
