import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import backarrow from "../../../../components/Images/backarrow.svg";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import Select from "react-select";

function Transcoding_Setting(props) {
  const [setting, setSetting] = useState({
    // Advertisement_Setting
    ads_on_videos: "",
    cpc_advertiser: "",
    featured_pre_ad: "",
    featured_mid_ad: "",
    featured_post_ad: "",
    cpc_admin: "",
    cpv_advertiser: "",
    cpv_admin: "",
    default_ads_url: "",
    ads_play_unlimited_period: "",
    ads_on_livestream: "",
    ads_on_audios: "",
    ads_on_episodes: "",
    // Advertisement_Setting
    //coupon code
    new_subscriber_coupon: "",
    discount_percentage: "",
    coupon_code: "",
    //coupon code
    //email setting
    google_oauth_key: "",
    coupon_status: "",
    google_tracking_id: "",
    system_email: "",
    system_phone_number: "",
    system_address: "",
    //email setting
    //login setting
    notification_icon: "",
    notification_key: "",
    login_text: "",
    login_content: "",
    signature: "",
    //login setting
    //geo fencing
    id: "",
    geofencing: "",
    geofencing_status: "",
    //geo fencing
    // payout setting
    payout_method: "",
    //payout setting
    //ppv setting
    ppv_status: "",
    ppv_rent: "",
    ppv_price: "",
    ppv_hours: "",
    ios_product_id: "",
    ios_plan_price: "",
    expiry_time_started: "",
    expiry_day_notstarted: "",
    expiry_hours_notstarted: "",
    expiry_min_notstarted: "",
    PPV_Individual_Contents: "",
    //ppv setting
    // capcha setting
    captcha_site_key: "",
    captcha_secret_key: "",
    captcha: "",
    //capcha setting
    //registration settings
    free_registration: "",
    activation_email: "",
    premium_upgrade: "",
    access_free: "",
    enable_landing_page: "",
    //registration settings
    //search settings
    search_title_status: "",
    search_category_status: "",
    search_tags_status: "",
    search_description_status: "",
    search_details_status: "",
    //search settings
    //series settings
    series_season: "",
    series_networks_status: "",
    //series settings
    //site settings
    website_name: "",
    website_description: "",
    logo_height: "",
    logo_width: "",
    login_content: "",
    favicon: "",
    logo: "",
    //site settings
    //social network settings
    facebook_page_id: "",
    google_page_id: "",
    twitter_page_id: "",
    instagram_page_id: "",
    linkedin_page_id: "",
    whatsapp_page_id: "",
    skype_page_id: "",
    youtube_page_id: "",
    email_page_id: "",
    tiktok_page_id: "",
    //social network settings
    //video settings
    videos_per_page: "",
    posts_per_page: "",
    show_description: "",
    show_Links_and_details: "",
    show_genre: "",
    show_languages: "",
    show_subtitle: "",
    show_artist: "",
    show_recommended_videos: "",
    show_views: "",
    videos_expiry_status: "",
    epg_status: "",
    //video settings
    transcoding_access: "",
    transcoding_resolution: "",
    video_clip_enable: "",
    video_clip_image: "",
    earn_amount: "",
    enable_ppv_rent: "",
  });

  const [loading, setLoading] = useState(true);

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // const [resolution, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const [optionstranscoding_resolution, setOptionstranscoding_resolution] = useState([]);
  const [selectedValuesresolution, setSelectedValuesresolution] = useState([]);

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, {
        headers: headers,
      })
      .then((response) => {
        var setting = response?.data?.settings[0];
        var dataresolution = response?.data?.resolution;
        setSetting(setting);
        setLoading(false);
        const formattedOptionsresolution = dataresolution?.map((item) => ({
          value: item?.value,
          label: item?.name,
        }));
        setSelectedValuesresolution(formattedOptionsresolution);
        setOptionstranscoding_resolution(formattedOptionsresolution);
        const datatranscoding_resolution = response?.data?.transcoding_resolution;
        const formattedOptionstranscoding_resolution = datatranscoding_resolution?.map((item) => ({
          value: item?.value,
          label: item?.name,
        }));
        setOptionstranscoding_resolution(formattedOptionstranscoding_resolution);
      })
      .catch((error) => console.log(error));
  }, []);

  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = () => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
  };


  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setSetting({ ...setting, [e.target.name]: 1 });
    } else {
      setSetting({ ...setting, [e.target.name]: 0 });
    }
  };

  const [formData, setFormData] = useState({
  });

  const handleSelectChangeresolution = (selectedOptionsresolution) => {
    // const resolution = selectedOptionsresolution?.map((option) => option?.value);

    setFormData({
      // ...formData,
      // ...editInputvalue,
      // resolution: resolution,
    });
    setSelectedValuesresolution(selectedOptionsresolution);
  };


  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function handleUpdate() {

    const formData = new FormData();

    formData.append(
      "transcoding_resolution",
      JSON.stringify(selectedValuesresolution?.map((option) => option?.value))
    );
    formData.append("transcoding_access", setting?.transcoding_access ? parseInt(setting?.transcoding_access, 10) : 0);
    formData.append("video_clip_enable", setting?.video_clip_enable ? parseInt(setting?.video_clip_enable, 10) : 0);
    formData.append("ads_on_videos", setting?.ads_on_videos ? parseInt(setting?.ads_on_videos, 10) : 0);
    formData.append("default_ads_url", setting?.default_ads_url);
    formData.append("featured_pre_ad", setting?.featured_pre_ad);
    formData.append("featured_mid_ad", setting?.featured_mid_ad);
    formData.append("featured_post_ad", setting?.featured_post_ad);
    formData.append("cpv_admin", setting?.cpv_admin);
    formData.append("cpv_advertiser", setting?.cpv_advertiser);
    formData.append("cpc_admin", setting?.cpc_admin);
    formData.append("cpc_advertiser", setting?.cpc_advertiser);
    formData.append("ads_play_unlimited_period", setting?.ads_play_unlimited_period ? parseInt(setting?.ads_play_unlimited_period, 10) : 0);
    formData.append("ads_on_livestream", setting?.ads_on_livestream ? parseInt(setting?.ads_on_livestream, 10) : 0);
    formData.append("ads_on_audios", setting?.ads_on_audios ? parseInt(setting?.ads_on_audios, 10) : 0);
    formData.append("ads_on_episodes", setting?.ads_on_episodes ? parseInt(setting?.ads_on_episodes, 10) : 0);
    formData.append("discount_percentage", setting?.discount_percentage);
    formData.append("coupon_code", setting?.coupon_code);
    formData.append("new_subscriber_coupon", setting?.new_subscriber_coupon);
    formData.append("coupon_status", setting?.coupon_status);
    formData.append("google_tracking_id", setting?.google_tracking_id);
    formData.append("google_oauth_key", setting?.google_oauth_key);
    formData.append("system_email", setting?.system_email);
    formData.append("system_phone_number", setting?.system_phone_number);
    formData.append("system_phone_number", setting?.system_phone_number);
    formData.append("login_text", setting?.login_text);
    formData.append("signature", setting?.signature);
    formData.append("login_content", setting?.login_content);
    formData.append("notification_key", setting?.notification_key);
    formData.append("payout_method", setting?.payout_method ? parseInt(setting?.payout_method, 10) : 0);
    formData.append("ppv_status", setting?.ppv_status);
    formData.append("PPV_Individual_Contents", setting?.PPV_Individual_Contents);
    formData.append("ppv_price", setting?.ppv_price);
    formData.append("ppv_hours", setting?.ppv_hours);
    formData.append("ios_plan_price", setting?.ios_plan_price);
    formData.append("ios_product_id", setting?.ios_product_id);
    formData.append("expiry_time_started", setting?.expiry_time_started);
    formData.append("expiry_day_notstarted", setting?.expiry_day_notstarted);
    formData.append("expiry_hours_notstarted", setting?.expiry_hours_notstarted);
    formData.append("expiry_min_notstarted", setting?.expiry_min_notstarted);
    formData.append("enable_landing_page", setting?.enable_landing_page);
    formData.append("free_registration", setting?.free_registration ? parseInt(setting?.free_registration, 10) : 0);
    formData.append("activation_email", setting?.activation_email);
    formData.append("premium_upgrade", setting?.premium_upgrade);
    formData.append("access_free", setting?.access_free);
    formData.append("search_title_status", setting?.search_title_status ? parseInt(setting?.search_title_status, 10) : 0);
    formData.append("search_category_status", setting?.search_category_status ? parseInt(setting?.search_category_status, 10) : 0);
    formData.append("search_tags_status", setting?.search_tags_status ? parseInt(setting?.search_tags_status, 10) : 0);
    formData.append("search_description_status", setting?.search_description_status ? parseInt(setting?.search_description_status, 10) : 0);
    formData.append("search_details_status", setting?.search_details_status ? parseInt(setting?.search_details_status, 10) : 0);
    formData.append("series_season", setting?.series_season);
    formData.append("series_networks_status", setting?.series_networks_status ? parseInt(setting?.series_networks_status, 10) : 0);
    formData.append("website_name", setting?.website_name);
    formData.append("website_description", setting?.website_description);
    formData.append("logo", setting?.logo);
    formData.append("logo_height", setting?.logo_height);
    formData.append("logo_width", setting?.logo_width);
    formData.append("favicon", setting?.favicon);
    formData.append("facebook_page_id", setting?.facebook_page_id);
    formData.append("google_page_id", setting?.google_page_id);
    formData.append("twitter_page_id", setting?.twitter_page_id);
    formData.append("instagram_page_id", setting?.instagram_page_id);
    formData.append("linkedin_page_id", setting?.linkedin_page_id);
    formData.append("whatsapp_page_id", setting?.whatsapp_page_id);
    formData.append("skype_page_id", setting?.skype_page_id);
    formData.append("youtube_page_id", setting?.youtube_page_id);
    formData.append("tiktok_page_id", setting?.tiktok_page_id);
    formData.append("email_page_id", setting?.email_page_id);
    formData.append("default_time_zone", setting?.default_time_zone);
    formData.append("videos_per_page", setting?.videos_per_page ? parseInt(setting?.videos_per_page, 10) : 0);
    formData.append("posts_per_page", setting?.posts_per_page);
    formData.append("show_description", setting?.show_description ? parseInt(setting?.show_description, 10) : 0);
    formData.append("show_Links_and_details", setting?.show_Links_and_details ? parseInt(setting?.show_Links_and_details, 10) : 0);
    formData.append("show_genre", setting?.show_genre ? parseInt(setting?.show_genre, 10) : 0);
    formData.append("show_languages", setting?.show_languages ? parseInt(setting?.show_languages, 10) : 0);
    formData.append("show_subtitle", setting?.show_subtitle ? parseInt(setting?.show_subtitle, 10) : 0);
    formData.append("show_artist", setting?.show_artist ? parseInt(setting?.show_artist, 10) : 0);
    formData.append("show_recommended_videos", setting?.show_recommended_videos ? parseInt(setting?.show_recommended_videos, 10) : 0);
    formData.append("show_views", setting?.show_views ? parseInt(setting?.show_views, 10) : 0);
    formData.append("videos_expiry_status", setting?.videos_expiry_status ? parseInt(setting?.videos_expiry_status, 10) : 0);
    formData.append("epg_status", setting?.epg_status ? parseInt(setting?.epg_status, 10) : 0);
    formData.append("earn_amount", setting?.earn_amount ? parseInt(setting?.earn_amount, 10) : 0);
    formData.append("enable_ppv_rent", setting?.enable_ppv_rent ? parseInt(setting?.enable_ppv_rent, 10) : 0);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/setting/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        // console.log(resultError);
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }


  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <Link to="/settings" className="backarrow">
                <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                <span> Back</span>
              </Link>
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Transcoding Settings</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Save Transcoding
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 col-12">
                <div className="card p-3">
                  <h5>Create Resolution</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Select Transcoding Resolution
                      <span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <div className="text-dark">
                        <Select
                          options={optionstranscoding_resolution}
                          isMulti
                          className=""
                          onChange={handleSelectChangeresolution}
                          value={selectedValuesresolution}
                        />
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              <div className=" col-sm-6 col-md-6 col-lg-6 col-12">
                <div className="card p-3">
                  <h5>Status Settings</h5>
                  <hr></hr>

                  <div className="row">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">Enable Transcoding Setting</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3 text-end">
                      <label className="switch">
                        <input
                          name="transcoding_access"
                          id="transcoding_access"
                          onChange={handleInputenable}
                          className="rs-input"
                          defaultChecked={
                            parseInt(setting?.transcoding_access) == 1
                              ? true
                              : false
                          }
                          checked={
                            parseInt(setting?.transcoding_access) == 1
                              ? true
                              : false
                          }
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="transcoding_access"
                          id="transcoding_access"
                          onChange={handleInputenable}
                          value={
                            parseInt(setting?.transcoding_access) == 1
                              ? "1"
                              : "0"
                          }
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2">Add Video Clip On Transcoding</p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3 text-end">
                      <label className="switch">
                        <input
                          name="video_clip_enable"
                          id="video_clip_enable"
                          onChange={handleInputenable}
                          className="rs-input"
                          defaultChecked={
                            parseInt(setting?.video_clip_enable) == 1
                              ? true
                              : false
                          }
                          checked={
                            parseInt(setting?.video_clip_enable) == 1
                              ? true
                              : false
                          }
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="video_clip_enable"
                          id="video_clip_enable"
                          onChange={handleInputenable}
                          value={
                            parseInt(setting?.video_clip_enable) == 1
                              ? "1"
                              : "0"
                          }
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>

                {/* <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Save Email
                  </button>
                </div> */}
              </div>

              {/* Second Part of Section  */}

              {/* <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Video Clip</label>

                    <div>
                      {loading ? (
                        <img src={loadings} className="thumbnails-images" />
                      ) : (
                        <img
                          src={setting?.video_clip_image}
                          alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile1)
                                      }
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section  container-fluid">
            <div className="mt-4">
              <div className="iq-card">
                <div className="" id="transcodingsetting">
                  <div className="panel panel-primary mt-3" data-collapsed="0">
                    <div className="panel-heading">
                      <div className="panel-title">
                        <h4>Transcoding Setting</h4>
                      </div>
                      <hr />
                    </div>
                    <div className="panel-body">
                      <div className="row">
                        <div className="col-md-12 align-center">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="mt-1 d-flex align-items-center justify-content-around">
                                <div className="mr-2">OFF</div>
                                <label className="switch">
                                  <input
                                    name="transcoding_access"
                                    onChange={handleInput}
                                    defaultChecked={
                                      setting?.transcoding_access === 1
                                        ? true
                                        : false
                                    }
                                    checked={
                                      setting?.transcoding_access === 1
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="transcoding_access"
                                    onChange={handleInput}
                                    value={
                                      setting?.transcoding_access === 1
                                        ? "1"
                                        : "0"
                                    }
                                  ></span>
                                </label>
                                <div className="ml-2">ON</div>
                              </div>
                            </div>

                            <div className="mt-3  align-items-center justify-content-around">
                              <label className="m-0">
                                Select Transcoding Resolution :
                              </label>

                              <Multiselect
                                onChange={handleInput}
                                options={options}
                                name="transcoding_resolution"
                                displayValue={"value"}
                                showCheckbox
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-end p-3">
                    <input
                      type="submit"
                      id="appupdate"
                      value="Update Setting"
                      onClick={handleUpdate}
                      className="btn btn-primary ml-3 "
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default Transcoding_Setting;
