import React, { useState, useEffect, useRef } from "react";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function LandingPages(props) {
  const [datapages, setDatapages] = useState([]);
  const [countpages, setCountpages] = useState();
  const [pagepages, setPagepages] = useState(1);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [videodatapages, setVideodatapages] = useState("");
  const [loading, setLoading] = useState(true);
  const [defaultImage, setDefaultimage] = useState();
  const [defaultImageurl, setDefaultimageurl] = useState("");
  const [currentPagepages, setCurrentPagepages] = useState(1);
  const itemsPerPagepages = 10;

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = () => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
  };

  const videosdeleteOperationpages = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/admin/landing-page/delete/` + id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          props.setApiresponsealert(responseData);
          props.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props.setApiresponsealert(resultError);
            props.setShowalert(true);
          }
        }
      });
  };

  const [selectedItemspages, setSelectedItemspages] = useState([]);
  const [selectAllpages, setSelectAllpages] = useState(false);
  const [isConfirmationOpenpages, setIsConfirmationOpenpages] = useState(false);
  const [isSuccessMessageOpenpages, setIsSuccessMessageOpenpages] =
    useState(false);

  const handleSingleSelectpages = (itemId) => {
    setSelectedItemspages([itemId]);
    setSelectAllpages(false);
  };

  const handleMultiSelectpages = (itemId) => {
    if (selectedItemspages.includes(itemId)) {
      setSelectedItemspages(selectedItemspages.filter((id) => id !== itemId));
    } else {
      setSelectedItemspages([...selectedItemspages, itemId]);
    }
  };

  const handleSelectAllpages = () => {
    setSelectAllpages(!selectAllpages);
    setSelectedItemspages(
      selectAllpages ? [] : videodatapages.map((item) => item?.id)
    );
  };

  const handleDeletepages = () => {
    setIsConfirmationOpenpages(true);
  };
  const handleCancelDeletepages = () => {
    setIsConfirmationOpenpages(false);
  };

  const handleConfirmDeletepages = () => {
    setIsConfirmationOpenpages(false);
    for (const itemId of selectedItemspages) {
      axios
        .delete(
          `${process.env.REACT_APP_Baseurl}/admin/landing-page/delete/${itemId}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.data.status == true) {
            setVideodatapages(
              videodatapages.filter((item) => item?.id !== itemId)
            );
            setSelectedItemspages(
              selectedItemspages.filter((id) => id !== itemId)
            );
            setIsSuccessMessageOpenpages(true);
            setTimeout(() => {
              setIsSuccessMessageOpenpages(false);
            }, 2000);
            var responseData = response.data;
            props.setApiresponsealert(responseData);
            props.setShowalert(true);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            if (responseData === false) {
              var resultError = responseData;
              props.setApiresponsealert(resultError);
              props.setShowalert(true);
            }
          }
        });
    }
  };

  const fetchDatanextprepages = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/landing-page/index?page=${pagepages}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const videosData = res?.data?.landing_pages;
        setVideodatapages(videosData);
        if (videosData?.length > 0) {
          setDatapages(videosData);
          setLoading(false);
        } else {
          setDatapages([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchDatapages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/landing-page/index`,
        { headers: headers }
      );
      setCountpages(response?.data?.Total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchdefaultimagepages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/Vertical-Default-Image`,
        { headers: headers }
      );
      setDefaultimage(response?.data?.Vertical_Default_Image);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchdefaultimageurlpages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/Default-image-url`,
        { headers: headers }
      );
      setDefaultimageurl(response?.data?.Default_Image_Url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const indexOfLastItempages = currentPagepages * itemsPerPagepages;
  const indexOfFirstItempages = indexOfLastItempages - itemsPerPagepages;
  const currentItemspages = datapages?.slice(
    indexOfFirstItempages,
    indexOfLastItempages
  );

  const handlePreClickpages = () => {
    if (pagepages > 1) {
      setPagepages(pagepages - 1);
    }
  };

  const handleNxtClickpages = () => {
    setPagepages(pagepages + 1);
  };

  const [itemToDeletepages, setItemToDeletepages] = useState(null);
  const [successMessagepages, setSuccessMessagepages] = useState("");

  const openModalpages = (id) => {
    setItemToDeletepages(id);
    setSuccessMessagepages("");
  };

  const closeModalpages = () => {
    setItemToDeletepages(null);
  };
  const handleDeletedata = () => {
    closeModalpages();
    if (itemToDeletepages) {
      videosdeleteOperationpages(itemToDeletepages);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const handleToggle = async (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const confirmToggle = async () => {
    try {
      setShowModal(false);
      const newStatus = currentItem.banner === 1 ? 0 : 1;

      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/landing-page/update_status`,
        {
          method: "POST",
          body: JSON.stringify({
            landing_page_id: currentItem.id,
            status: newStatus,
          }),
          headers: headers,
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        const updatedItems = datapages.map((currItem) => {
          if (currItem.id === currentItem.id) {
            return { ...currItem, banner: newStatus };
          }
          return currItem;
        });

        setDatapages(updatedItems);

        props.setApiresponsealert(responseData);
        props.setShowalert(true);
        setMessage(responseData.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        props.setApiresponsealert(responseData);
        props.setShowalert(true);
      }
    } catch (error) { }
  };

  const closeModalslider = () => {
    setShowModal(false);
    setCurrentItem(null);
  };

  useEffect(async () => {
    fetchDatapages();
    fetchdefaultimagepages();
    fetchdefaultimageurlpages();
    fetchDatanextprepages();
  }, [pagepages]);

  return (
    <>
      <div className="banner-slider">
        {showModal && (
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Slider </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModalslider}
                  ></button>
                </div>
                <div className="modal-body">
                  Are you sure you want to Switch the Slider status?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModalslider}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={confirmToggle}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className={`modal ${itemToDeletepages !== null ? "show" : ""}`}
        tabIndex="-1"
        style={{
          display: itemToDeletepages !== null ? "block" : "none",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModalpages}
              ></button>
            </div>
            <div className="modal-body">
              {successMessagepages ? (
                <div className="alert alert-success" role="alert">
                  {successMessagepages}
                </div>
              ) : (
                <p>Are you sure you want to delete this Pages?</p>
              )}
            </div>
            <div className="modal-footer">
              {successMessagepages ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closeModalpages}
                >
                  Close
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModalpages}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeletedata}
                  >
                    Yes
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delete_confirm">
          <div className="modal-content text-center">
            <div className="modal-body">
              {isConfirmationOpenpages && (
                <div>
                  <h5 className="modal-title p-3" id="exampleModalLabel">
                    <p className="fs-6">
                      Are you sure you want to delete the selected items?
                    </p>
                  </h5>
                  <div className="col-lg-12 row p-4">
                    <div className="col-lg-6">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          handleConfirmDeletepages();
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <button
                        className="btn btn-primary"
                        onClick={handleCancelDeletepages}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {isSuccessMessageOpenpages && (
                <div className="success-message">
                  <div className="p-5">
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </div>
                  <p className="fs-6 fw-bold">
                    Selected items have been successfully deleted.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className=" m-0 p-0">
        <div className="container-fluid">
          <section className="section all-video-stream ">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>
                  All Landing Page{" "}
                  <span className="fs-6">(Display all {countpages} Page)</span>
                </h3>
              </div>
              <div className="col-3 col-sm-3 col-lg-3 text-end">
                {selectedItemspages?.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleDeletepages}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Delete Selected
                  </button>
                )}
              </div>

              <div className="col-3 col-sm-3 col-lg-3 text-end">
                <Link to="/add-landing-page/index">
                  <button type="button" className="btn btn-primary">
                    Add Page
                  </button>
                </Link>
              </div>
            </div>

            <div className="col-lg-12 ">
              <div className="card">
                {loading ? (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllpages}
                                onChange={handleSelectAllpages}
                              />
                            </label>
                          </th>
                          <th>PAGES</th>
                          <th>URL</th>
                          <th>SET AS FRONT PAGE</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                    </Table>

                    <div className="text-center">
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>{" "}
                        Loading...
                      </label>
                    </div>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickpages}
                          disabled={pagepages <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pagepages}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickpages}
                          disabled={datapages?.length < itemsPerPagepages}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : videodatapages?.length > 0 ? (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllpages}
                                onChange={handleSelectAllpages}
                              />
                            </label>
                          </th>
                          {/* <th>S.NO</th> */}
                          <th>PAGES</th>
                          <th>URL</th>
                          <th>SET AS FRONT PAGE</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      {currentItemspages?.map((item, key) => (
                        <tbody>
                          <tr key={item?.id}>
                            <td key={item?.id}>
                              <input
                                type="checkbox"
                                checked={selectedItemspages.includes(item?.id)}
                                onChange={() =>
                                  handleMultiSelectpages(item?.id)
                                }
                              />
                            </td>
                            {/* <td className="table-content" key={item?.id}>
                              {key + 1}
                            </td> */}

                            <td>
                              <div className="d-flex text-start align-items-center ">
                                <div>
                                  <div className="">
                                    <div>{item?.title}</div>
                                    <div className="d-flex ">
                                      <div className="">
                                        <span>{item?.upload_on}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td>
                              <p>{item?.slug}</p>
                            </td>

                            <td>
                              <div key={key}>
                                <label className="switch">
                                  <input
                                    name="status"
                                    id="status"
                                    className={
                                      item?.status === 0 ? "inactive" : null
                                    }
                                    checked={item?.status === 1}
                                    onChange={() => handleToggle(item)}
                                    onClick={() => handleToggle(item)}
                                    type="checkbox"
                                  />
                                  <span className="slider round"></span>
                                </label>
                                {/* <div className="popup">
                                  {message && <p>{message}</p>}
                                </div> */}
                              </div>
                            </td>

                            <td>
                              <div className="editdropdown">
                                <span className="editdropdown-button">
                                  <span>
                                    <i
                                      className="fa fa-ellipsis-v"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </span>
                                <div className="editdropdown-menu">
                                  <Link
                                    to={"/edit-landing-page/" + item?.id}
                                    className="edit"
                                  >
                                    <span>
                                      <img
                                        src={edititem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">Edit Page</span>
                                    </span>
                                  </Link>
                                  <Link to="" className="delete">
                                    <span
                                      onClick={() => openModalpages(item?.id)}
                                    >
                                      <img
                                        src={deleteitem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">Delete Page</span>
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </Table>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickpages}
                          disabled={pagepages <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pagepages}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickpages}
                          disabled={datapages?.length < itemsPerPagepages}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllpages}
                                onChange={handleSelectAllpages}
                              />
                            </label>
                          </th>
                          <th>PAGES</th>
                          <th>URL</th>
                          <th>SET AS FRONT PAGE</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                    </Table>

                    <div className="text-center">
                      <label>No Pages Available.</label>
                    </div>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickpages}
                          disabled={pagepages <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pagepages}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickpages}
                          disabled={datapages?.length < itemsPerPagepages}
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default LandingPages;
