import React, { useState, useEffect } from "react";
import axios from "axios";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function SeriesApproval(props) {
  const [videos, setVideos] = useState([]);
  const [channel, setChannel] = useState([]);
  const [video_id, setVideo_id] = useState([]);
  const [channel_partner_id, setChannel_partner_id] = useState([]);

  const [videos1, setVideos1] = useState([]);
  const [content, setContent] = useState([]);
  const [video_id1, setVideo_id1] = useState([]);
  const [content_partner_id, setContent_partner_id] = useState([]);
  // console.log(content_partner_id);
  // console.log(video_id1);

  // console.log(editUser)
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const getUsers = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/content_partner/move_series/index`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.ContentPartner;
      var result = resData?.Series;
      setVideos1(result);
      setContent(res);
      // console.log(res);
      // console.log(result);
    };
    getUsers();

    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/move_series/index`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Channel;
      var result = resData?.Series;
      setVideos(result);
      setChannel(res);
      // console.log(res);
      // console.log(result);
      // console.log(res)
    };
    getUser();
  }, []);
  


  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);


  async function submitchannel() {
    const formData = new FormData();

    formData.append("Series_id", video_id);
    formData.append("Channel_partner_id", channel_partner_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/move_series`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;

      } else if (response?.data?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  }



  async function submitcontent() {

    const formData = new FormData();

    formData.append("Series_id", video_id1);
    formData.append("content_partner_id", content_partner_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/content_partner/move_series`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;

      } else if (response?.data?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  }


  return (
    <>
      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <div id="rating" className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid Videopartner">
            <div className="py-3">
              <h4>Content Series Video Lists</h4>
            </div>
            <div className="iq-card">
              <ul
                className="nav nav-tabs justify-content-around"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    CPP Uploaded Series Videos
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Channel Uploaded Series Videos
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <label for="">Choose Content Partner</label>
                          <select
                            name="channel_users"
                            className="form-select"
                            onChange={(e) =>
                              setContent_partner_id(e.target.value)
                            }
                            id="channel_users"
                          >
                             <option value="0">Choose a Channel partner</option>
                            {content?.map((item, key) => (
                              <option key={item?.id} value={item?.id}>
                                {item?.username}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12">
                          <label for="">Choose Video (*To Be Moved)</label>
                          <select
                            name="video_data"
                            className="form-select"
                            id="video_data"
                            onChange={(e) => setVideo_id1(e.target.value)}
                          >
                             <option value="0">Choose an Series</option>
                            {videos1?.map((item, key) => (
                              <option key={item?.id} value={item?.id}>
                                {item?.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="col-md-12 text-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        value="submit"
                        onClick={submitcontent}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="mb-3">
                          <h4>Channel Series Video Lists</h4>
                        </div>

                        <div className="col-md-12">
                          <label for="">Choose Channel Partner</label>
                          <select
                            name="channel_users"
                            className="form-select"
                            onChange={(e) =>
                              setChannel_partner_id(e.target.value)
                            }
                            id="channel_users"
                          >
                             <option value="0">Choose a Channel partner</option>
                            {channel?.map((item, key) => (
                              <option key={item?.id} value={item?.id}>
                                {item?.channel_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12">
                          <label for="">Choose Video (*To Be Moved)</label>
                          <select
                            name="video_data"
                            className="form-select"
                            id="video_data"
                            onChange={(e) => setVideo_id(e.target.value)}
                          >
                             <option value="0">Choose an Series</option>
                            {videos?.map((item, key) => (
                              <option key={item?.id} value={item?.id}>
                                {item?.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="col-md-12 text-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        value="submit"
                        onClick={submitchannel}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default SeriesApproval;
