import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdDelete } from "react-icons/md";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";

function Managevideocategories(props) {
  const [getVideoCategory, setVideoCategory] = useState([]);
  const [itemToDeleteVideoCategory, setItemToDeleteVideoCategory] = useState(
    []
  );
  const [selectAllVideoCategory, setSelectAllVideoCategory] = useState(false);
  const [modalCloseVideoCategory, setModalCloseVideoCategory] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [loading, setLoading] = useState(true);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const fetchDatavideo = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}/admin/videos-category/index`,
          { headers: headers }
        );
        setVideoCategory(response?.data?.AllVideoCategory);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchDatavideo();
  }, []);

  // const fetchDatavideo = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_Baseurl}/admin/videos-category/index`,
  //       { headers: headers }
  //     );
  //     setVideoCategory(response?.data?.AllVideoCategory);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   }
  // };

  // useEffect(async () => {
  //   fetchDatavideo();
  // }, []);
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(getVideoCategory);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setVideoCategory(reorderedItems);
    saveShuffleOrder(reorderedItems);
  };

  const saveShuffleOrder = async (updatedItems) => {
    setProcessing(true);
    setShowOverlay(true);

    if (!updatedItems || updatedItems?.length === 0) {
      console.error("Menu items are empty");
      return;
    }

    const positions = updatedItems?.map((menuItem) => menuItem?.id);
    const ids = updatedItems?.map((_, index) => index + 1);

    const requestData = {
      id: JSON.stringify(positions),
      position: JSON.stringify(ids),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/videos-category/order-video-category`,
        requestData,
        { headers: headers }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };
  const handleSelectAllVideofile = () => {
    setSelectAllVideoCategory(!selectAllVideoCategory);
    setItemToDeleteVideoCategory(
      selectAllVideoCategory ? [] : getVideoCategory?.map((item) => item?.id)
    );
  };

  const handleMultiSelectVideoFile = (itemId) => {
    if (itemToDeleteVideoCategory?.includes(itemId)) {
      setItemToDeleteVideoCategory(
        itemToDeleteVideoCategory?.filter((id) => id !== itemId)
      );
    } else {
      setItemToDeleteVideoCategory([...itemToDeleteVideoCategory, itemId]);
    }
  };

  const singleDeleteVideoCategory = (id) => {
    setItemToDeleteVideoCategory([id]);
    setModalCloseVideoCategory(true);
    setSelectAllVideoCategory(false);
  };

  const closeVideoCategoryModal = () => {
    setModalCloseVideoCategory(!modalCloseVideoCategory);
  };

  const openModalMultiDeleteVideoCategory = () => {
    setModalCloseVideoCategory(true);
  };

  const handleDeleteVideoCategoryData = () => {
    closeVideoCategoryModal();
    videoCategoryDeleteOperation();
  };

  const videoCategoryDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteVideoCategory + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/videos-category/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <>
      <div
        className={`modal  packageModel ${modalCloseVideoCategory ? "show" : ""
          }`}
        tabIndex="-1"
        style={{ display: modalCloseVideoCategory ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeVideoCategoryModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteVideoCategory?.length === 1 ? (
                <p className="fs-6">
                  Are you sure you want to delete this video category?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected video category ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={closeVideoCategoryModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteVideoCategoryData}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-6">
              <h4>
                Video Categories{" "}
                <span className="fs-6">
                  (Display all {getVideoCategory?.length} Category)
                </span>
              </h4>
            </div>

            <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
              {itemToDeleteVideoCategory?.length > 0 && (
                <button
                  type="button"
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModalMultiDeleteVideoCategory}
                >
                  <MdDelete className="exportIcon " />
                  Delete
                </button>
              )}
              <Link
                to="/add-categories"
                className="btn btn-primary fw-bolder"
              >
                <i className="fa fa-plus-circle"></i> Add Video Categories
              </Link>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="bootstrapTable ">
              <div className="col-12 p-0">
                <div className="row m-0 p-0">
                  <div className="col-lg-2 col-4 data">
                    {" "}
                    <label>
                      <input
                        type="checkbox"
                        checked={selectAllVideoCategory}
                        onChange={handleSelectAllVideofile}
                      />
                    </label>
                  </div>
                  <div className="col-lg-4 col-4 data">
                    {" "}
                    <div>Category</div>
                  </div>
                  <div className="col-lg-3 col-4 data">
                    {" "}
                    <div>Uploaded by</div>
                  </div>
                  <div className="col-lg-3 col-4 data">
                    {" "}
                    <div>Actions</div>
                  </div>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="menu-items">
                    {(provided) => (
                      <ul {...provided.droppableProps} ref={provided.innerRef}>
                        {loading ? (
                          <div className="noRecord">
                            {" "}
                            <Response_Loading_processing></Response_Loading_processing>
                            Loading...
                          </div>
                        ) : getVideoCategory?.length <= 0 ? (
                          <div className="noRecord">
                            {" "}
                            <p>No Video Category Available !!! </p>
                          </div>
                        ) : (
                          getVideoCategory?.map((item, index) => (
                            <Draggable
                              key={item?.id.toString()}
                              draggableId={item?.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`bg-white orderborder ${item?.order
                                    } index ${index + 1}w-100`}
                                >
                                  <div className="row m-0 p-0 align-items-center">
                                    <div className="col-lg-2 col-4 data_th">
                                      <input
                                        type="checkbox"
                                        checked={itemToDeleteVideoCategory.includes(
                                          item?.id
                                        )}
                                        onChange={() =>
                                          handleMultiSelectVideoFile(item?.id)
                                        }
                                      />
                                    </div>
                                    <div className="col-lg-4 col-4 data_th">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <img
                                          src={item?.image_url}
                                          className="profile"
                                        />
                                        <div className="rightVideoTitle">
                                          <p>{item?.name}</p>
                                          <span className="dateText">
                                            {item?.upload_on}
                                          </span>
                                          <div>
                                            {item?.slug !== null ? (
                                              <div className="text-start">
                                                <label className="m-0">
                                                  <div className="tooltip-container">
                                                    <p className="iq-bg-warning">
                                                      <Link
                                                        to={`${process.env.REACT_APP_Baseurl_Frontend}/video-category/${item?.slug}`}
                                                      >
                                                        <i
                                                          className="fa fa-share-alt"
                                                          aria-hidden="true"
                                                        ></i>
                                                      </Link>
                                                      <span className="tooltip-text">
                                                        View
                                                      </span>
                                                    </p>
                                                  </div>
                                                </label>
                                              </div>
                                            ) : (
                                              <>
                                                <div className="text-start">
                                                  <label className="m-0">
                                                    <div className="tooltip-container">
                                                      <p className="iq-bg-warning">
                                                        <i
                                                          className="fa fa-share-alt"
                                                          aria-hidden="true"
                                                          disabled
                                                        ></i>
                                                        <span className="tooltip-text">
                                                          Diable View
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </label>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>{" "}
                                    <div className="col-lg-3 col-4 data_th">
                                      <div>
                                        {" "}
                                        {item?.uploaded_by === "1"
                                          ? "Admin"
                                          : item?.uploaded_by === "4"
                                            ? "Channel Partner"
                                            : item?.uploaded_by === "5"
                                              ? "Content Partner"
                                              : ""}
                                      </div>
                                    </div>
                                    <div className="col-lg-3 col-4 data_th">
                                      <div className="editdropdown ">
                                        <span className="editdropdown-button">
                                          <span>
                                            <i
                                              className="fa fa-ellipsis-v"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </span>
                                        <div className="editdropdown-menu ">
                                          <Link
                                            to={
                                              "/edit-categories/" +
                                              item?.id
                                            }
                                          >
                                            <span>
                                              <img
                                                src={edititem}
                                                alt="flogo"
                                                width={20}
                                                height={20}
                                              />
                                              <span className="ms-2">
                                                Edit Categories
                                              </span>
                                            </span>
                                          </Link>
                                          <div
                                            onClick={() =>
                                              singleDeleteVideoCategory(
                                                item?.id
                                              )
                                            }
                                            className="commonActionPadding"
                                          >
                                            <img
                                              src={deleteitem}
                                              alt="flogo"
                                              width={20}
                                              height={20}
                                            />
                                            <span className="ms-2">
                                              Delete Categories
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Managevideocategories;
