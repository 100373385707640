import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function ProfileScreen(props) {
  const [data, setData] = useState({ profile_name: "" });
  const [profile_name, setProfile_name] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [editUser, setEditUser] = useState({
    profile_name: "",
    choose_profile_screen_image: "",
  });

  useEffect(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/Choose-Profile-Screen-Setting/index`,
        { headers: headers }
      )
      .then((res) => {
        setEditUser(res?.data?.ChooseProfileScene_Setting[0]);
        setData(res?.data?.ChooseProfileScene_Setting[0]);
        setProfile_name(res?.data?.ChooseProfileScene_Setting[0]?.profile_name);
        setImage(res?.data?.ChooseProfileScene_Setting[0]?.image);
        setLoading(false);
      });
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  const [validationMessageprofile_name, setValidationMessageprofile_name] =
    useState("");
  const [isValidationHiddenprofile_name, setIsValidationHiddenprofile_name] =
    useState(true);

  const profile_nameInputRef = useRef(null);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function handleSave() {
    let focusInputRef = null;

    if (editUser?.profile_name === "") {
      setValidationMessageprofile_name("Profile Name cannot be empty.");
      setIsValidationHiddenprofile_name(false);
      focusInputRef = profile_nameInputRef;
    }
    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const formData = new FormData();

    formData.append("profile_name", editUser?.profile_name);
    if (selectedFile1) {
      formData.append("image", selectedFile1);
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Choose-Profile-Screen-Setting/update`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;

        resultapi = response.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      setValidationMessageprofile_name("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        setProcessing(false);
        setShowOverlay(false);
        var resultError = resultapi;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setIsValidationHiddenprofile_name(false);
      }
    }
  }

  // function editProduct() {
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //     Accept: "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };

  //   // console.log(profile_name, image);
  //   const formData = new FormData();
  //   formData.append("image", image);
  //   formData.append("profile_name", profile_name);
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_Baseurl}/admin/Choose-Profile-Screen-Setting/update`,
  //       formData,
  //       {
  //         headers: headers,
  //       }
  //     )
  //     .then((response) => {
  //       console.log("response", response.data);
  //       window.location.reload();
  //       alert("success");
  //     })
  //     .catch((error) => {
  //       console.log("error", error.response);
  //     });
  // }

  return (
    <>
      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-profile_name" id="staticBackdropLabel">
                Profile Name
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className=" text-start">
                <label className="m-0">
                  Profile Name<span className="mandatory">*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    className="rs-input form-control-lg"
                    id="profile_name"
                    placeholder="Enter Name"
                    name="profile_name"
                    onChange={handleInput}
                    value={editUser?.profile_name}
                    ref={profile_nameInputRef}
                  />
                </div>
                <span className="errorred">
                  {!isValidationHiddenprofile_name && (
                    <p>{validationMessageprofile_name}</p>
                  )}
                </span>
              </div>

              <div className="mt-2">
                <label className="m-0">Image</label>

                <div className="">
                  {loading ? (
                    <Response_Loading_processing></Response_Loading_processing>
                  ) : (
                    <img
                      src={editUser?.choose_profile_screen_image}
                      alt="choose_profile_screen_image"
                      className="thumbnails-images"
                    />
                  )}
                </div>

                <div className="col-lg-12 row mt-2">
                  <div className="col-6 col-sm-6 col-lg-6">
                    <div
                      className="imagedrop"
                      onClick={() => fileInputRef1.current.click()}
                    >
                      <input
                        type="file"
                        ref={fileInputRef1}
                        onChange={(event) =>
                          handleFileChange(
                            event,
                            fileInputRef1,
                            setSelectedFile1
                          )
                        }
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                      <label>
                        <CameraRetroIcon />
                      </label>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-lg-6">
                    <div>
                      {selectedFile1 && (
                        <div className="imagedropcopy text-end">
                          <div className="col-lg-12 row p-0 m-0">
                            <div className=" col-sm-10 col-lg-10 p-0 m-0">
                              <img
                                src={URL.createObjectURL(selectedFile1)}
                                alt="Uploaded"
                                className="thumbnails-upload-images"
                              />
                            </div>
                            <div
                              className="col-sm-2 col-lg-2 p-0 m-0"
                              style={{ alignSelf: "center" }}
                            >
                              <button
                                onClick={() => handleDelete(1)}
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                              ></button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {/* <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button> */}
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSave}
              >
                Update Settings
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className=" m-0 p-0">
          <div className="">
            <section className="container-fluid">
              <div className="col-lg-12 row py-3">
                <div className="col-6 col-sm-6 col-lg-6">
                  <h3>Profile Image Settings</h3>
                </div>
                <div className="col-6 col-sm-6 col-lg-6">
                  <div className="text-end">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      className="btn btn-primary"
                    >
                      Update Image
                    </button>
                  </div>
                </div>
              </div>

              <div className="row col-lg-12 ">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card p-3">
                    <h5>Screen Image</h5>
                    <hr></hr>

                    <div className=" text-start">
                      <label className="m-0">{data?.profile_name}</label>

                      <div className=" mt-2">
                        {loading ? (
                          <Response_Loading_processing></Response_Loading_processing>
                        ) : (
                          <img
                            src={editUser?.choose_profile_screen_image}
                            // alt="Image"
                            className="thumbnails-images"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className=" p-0">
              <div className="iq-card">
                <h4>Profile image</h4>
                <hr />
                {data?.map((item) => (
                  <div key={item?.id} className="row mt-4">
                    <div className="col-md-6">
                      <label>Profile Name</label>
                      <div className="col-lg-12 ">
                        <input
                          type="text"
                          className="form-control"
                          name="profile_name"
                          defaultValue={item?.profile_name}
                          onChange={(e) => setProfile_name(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label>Choose Profile Image</label>
                      <div className="mt-3">
                        <img
                          src={item?.choose_profile_screen_image}
                          width={100}
                        />
                      </div>
                      <div className="col-lg-12 mt-3">
                        <input
                          type="file"
                          name="image"
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                      </div>
                    </div>

                    <div className="text-end mt-4">
                      <input
                        type="submit"
                        value=" Upload!"
                        onClick={editProduct}
                        className="btn btn-primary "
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default ProfileScreen;
