import React from 'react'
import { Link } from 'react-router-dom'
import Select from "react-select";


const AddNewSeries = () => {
  return (
    <div>
        <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card p-2 mt-3">
              <h4 className="p-2">
                <i className="entypo-plus"></i> Add New Series
              </h4>
              <hr />
              <div className="p-3">
                <div className="row mt-1">
                  <div className="col-sm-6 mb-3">
                    <label className="m-0">Title</label>
                    <p className="p1">
                      Add the series title in the textbox below.
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      id="title"
                      placeholder="Series Title"
                     
                    />
                  </div>


                  <div className="col-sm-6" data-collapsed="0">
                    <label className="m-0">Slug</label>
                    <div className="panel-body">
                      <p className="p1">Add a URL Slug</p>
                      <input
                        type="text"
                        className="form-control"
                        name="slug"
                        id="slug"
                        placeholder="Series Slug"
                       
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Series Image Cover</label>
                        </div>
                        <div className="panel-options">
                          {" "}
                          <Link to="#" data-rel="collapse">
                            <i className="entypo-down-open"></i>
                          </Link>{" "}
                        </div>
                      </div>

                      <div className="panel-body col-sm-8 p-0">
                        <p className="p1">
                          Select the series image ( 9:16 Ratio or 1080X1920px ):
                        </p>

                        <img src="" className="series-img" width="200" />

                        <input
                          type="file"
                          multiple="true"
                          className="form-group image series_image"
                          name="image"
                          id="image"
                     
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Series Player Image </label>
                        </div>
                        <div className="panel-options">
                          {" "}
                          <Link to="#" data-rel="collapse">
                            <i className="entypo-down-open"></i>
                          </Link>{" "}
                        </div>
                      </div>
                      <div className="panel-body col-sm-8 p-0">
                        <p className="p1">
                          Select the Player image ( 16:9 Ratio or 1280X720px ):
                        </p>

                        <img src="" className="series-img" width="200" />

                        <input
                          type="file"
                          multiple="true"
                          className="form-group"
                          name="player_image"
                          id="player_image"

                        />
                      </div>
                    </div>
                  </div>
                </div>

                

                <div className="panel panel-primary mt-3" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">
                            Series Details, Links, and Info
                          </label>
                        </div>{" "}
                        <div className="panel-options">
                          {" "}
                          <Link to="#" data-rel="collapse">
                            <i className="entypo-down-open"></i>
                          </Link>{" "}
                        </div>
                      </div>
                      <div className="panel-body col-sm-12 p-0">
                        <textarea
                          className="form-control"
                          name="details"
                          id="summary-ckeditor"

                        ></textarea>
                      </div>
                    </div>

                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Short Description</label>
                        </div>{" "}
                        <div className="panel-options">
                          {" "}
                          <Link to="#" data-rel="collapse">
                            <i className="entypo-down-open"></i>
                          </Link>{" "}
                        </div>
                      </div>
                      <div className="panel-body col-sm-12 p-0">
                        <p className="p1">
                          Add a short description of the series below:
                        </p>
                        <textarea
                          className="form-control"
                          name="description"
                          id="description"

                        ></textarea>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Cast and Crew</label>{" "}
                            </div>{" "}
                            <div className="panel-options">
                              {" "}
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>{" "}
                            </div>
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Add artists for the series below:
                            </p>
 

                            {/* <Multiselect
                              isObject={false}

                              options={artist}
                              onChange={(e) => setArtists(e.target.value)}
                              showCheckbox
                            /> */}
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Category</label>
                            </div>{" "}
                            <div className="panel-options">
                              {" "}
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>{" "}
                            </div>
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Select a Series Category Below:
                            </p>
/

                            {/* <Multiselect
                              isObject={false}
/
                              options={category}
                              onChange={(e) => setCategory(e.target.value)}
                              showCheckbox
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Series Ratings</label>
                            </div>{" "}
                            <div className="panel-options">
                              {" "}
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>{" "}
                            </div>
                          </div>
                          <div className="panel-body p-0 ">
                            <p className="p1">IMDb Ratings 10 out of 10</p>
                            <select
                              className="form-control"
                              name="rating"
                              id="rating"
                       
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Language</label>
                            </div>{" "}
                            <div className="panel-options">
                              {" "}
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>{" "}
                            </div>
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Select a Series Language Below:
                            </p>
 

                            {/* <Multiselect
                              isObject={false}

                              options={language}
                              onChange={(e) => setLanguage(e.target.value)}
                              showCheckbox
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Series Year</label>
                            </div>{" "}
                            <div className="panel-options">
                              {" "}
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>{" "}
                            </div>
                          </div>
                          <div className="panel-body  p-0">
                            <p className="p1">Series Created Year</p>
                            <input
                              className="form-control"
                              name="year"
                              id="year"

                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6" data-collapsed="0">
                        <div className="panel-heading">
                          <div className="panel-title font-weight-bold">
                            <label className="m-0">Search Tags</label>
                          </div>

                          <div className="">
                            <input
                              className="form-control "
                              type="search"
                              placeholder="search_tags"
                              aria-label="Search"
      
                            />
                          </div>
                        </div>
 
                      </div>
                    </div>

                

                    <div className="row mt-3">
                      <div className="col-sm-4">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title font-weight-bold">
                              {" "}
                              <label className="m-0">Duration</label>
                            </div>{" "}
                            <div className="panel-options">
                              {" "}
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>{" "}
                            </div>
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Enter the duration in the (HH: MM : SS) format{" "}
                            </p>
                            <input
                              className="form-control"
                              name="duration"
                              id="duration"
                         
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label className="m-0">User Access</label>
                        <p className="p1">
                          Who Is Allowed To View This Live Stream ?
                        </p>
                        {/* <Select
                          options={userAccesses}
                          onChange={(e) => handleAccessChange(e)}
                          className="abcd"
                        /> */}
                      </div>

                      {/* {userAccessesValue?.value === "ppv" ? (
                        <>
                          <div className="row mt-3 text-start ">
                            <div className="col-sm-6">
                              <label className="m-0">PPV Price</label>
                              <div className="col-lg-12 row">
                                <div className="col-6 col-lg-6">
                                  <p className="p1">
                                    Apply PPV Price from Global Settings?{" "}
                                  </p>
                                </div>
                                <div className="col-6 col-lg-6">
                                  <label className="switch">
                                    <input
                                      name="ppv_status"
                                      defaultChecked={checkboxChecked}
                                      onChange={handleCheckboxChange}
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="ppv_status"
                                      checked={checkboxChecked}
                                      onChange={handleCheckboxChange}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                              

                              <div className="panel-body">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="PPV Price"
                                  name="ppv_price"
                                  id="price"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                />
                                <div className="clear"></div>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <label className="m-0"> IOS PPV Price</label>
                              <p className="p1">
                                Apply IOS PPV Price from Global Settings?
                              </p>
                              <div className="panel-body ppv_price_ios">
                                <Select
                                  name="ios_ppv_price"
                                  className="form-control"
                                  options={ios_ppv_price}
                                  onChange={(e) =>
                                    setIos_ppv_price(e.target.value)
                                  }
                                />

                             
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null} */}

                      <div className="col-sm-6 mt-4">
                        <div className="d-flex justify-content-between align-items-baseline">
                          {/* <label className="m-0 p2">
                            Apply Global PPV Price:
                          </label> */}
                    

                          {/* <label className="switch">
                            <input
                              name="ppv_status"
                              onChange={handleInput}
                              defaultChecked={
                                ppv_status.ppv_status == 1 ? true : false
                              }
                              checked={
                                ppv_status.ppv_status == 1 ? true : false
                              }
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="ppv_status"
                              onChange={handleInput}
                              value={ppv_status.ppv_status == 1 ? "1" : "0"}
                            ></span>
                          </label> */}
                        </div>
                      </div>
                      <div className="col-sm-6 mt-4">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Status Settings</label>
                            </div>
                            <div className="panel-options">
                              {" "}
                              <Link to="#" data-rel="collapse">
                                <i className="entypo-down-open"></i>
                              </Link>
                            </div>
                          </div>
                          <div className="panel-body mt-3">
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="featured">
                                Is this series Featured:
                              </label>

                              <label className="switch">
                                <input
                                  name="featured"
                                //   onChange={handleInput}
                                //   defaultChecked={
                                //     featured.featured == 1 ? true : false
                                //   }
                                //   checked={
                                //     featured.featured == 1 ? true : false
                                //   }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="featured"
                                //   onChange={handleInput}
                                //   value={featured.featured == 1 ? "1" : "0"}
                                ></span>
                              </label>

                          
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="banner" name="banner">
                                Banner :
                              </label>

                              <label className="switch">
                                <input
                                  name="banner"
                                //   onChange={handleInput}
                                //   defaultChecked={
                                //     banner.banner == 1 ? true : false
                                //   }
                                //   checked={banner.banner == 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="banner"
                                //   onChange={handleInput}
                                //   value={banner.banner == 1 ? "1" : "0"}
                                ></span>
                              </label>

                        
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="active">
                                Is this series Active:
                              </label>

                              <label className="switch">
                                <input
                                  name="active"
                                //   onChange={handleInput}
                                //   defaultChecked={
                                //     active.active == 1 ? true : false
                                //   }
                                //   checked={active.active == 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="active"
                                //   onChange={handleInput}
                                //   value={active.active == 1 ? "1" : "0"}
                                ></span>
                              </label>

                            </div>
                          
                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div>
                                <label className="p2" for="trailer">
                                  Season Trailer:
                                </label>
                              </div>

                              <label className="switch">
                                <input
                                  name="trailer"
                                //   onChange={handleInput}
                                //   defaultChecked={
                                //     trailer.trailer == 1 ? true : false
                                //   }
                                //   checked={trailer.trailer == 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="trailer"
                                //   onChange={handleInput}
                                //   value={trailer.trailer == 1 ? "1" : "0"}
                                ></span>
                              </label>

                 
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div>
                                <label className="p2" for="active">
                                  Series Trailer:
                                </label>
                              </div>

                              <label className="switch">
                                <input
                                  name="series_trailer"
                                //   onChange={handleInput}
                                //   defaultChecked={
                                //     series_trailer.series_trailer == 1
                                //       ? true
                                //       : false
                                //   }
                                //   checked={
                                //     series_trailer.series_trailer == 1
                                //       ? true
                                //       : false
                                //   }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="series_trailer"
                                //   onChange={handleInput}
                                //   value={
                                //     series_trailer.series_trailer == 1
                                //       ? "1"
                                //       : "0"
                                //   }
                                ></span>
                              </label>

             
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div className="">
                                <label className="p2" for="active">
                                  Season 1 :
                                </label>
                              </div>

                              <label className="switch">
                                <input
                                  name="season_trailer"
                                //   onChange={handleInput}
                                //   defaultChecked={
                                //     season_trailer.season_trailer == 1
                                //       ? true
                                //       : false
                                //   }
                                //   checked={
                                //     season_trailer.season_trailer == 1
                                //       ? true
                                //       : false
                                //   }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="season_trailer"
                                //   onChange={handleInput}
                                //   value={
                                //     season_trailer.season_trailer == 1
                                //       ? "1"
                                //       : "0"
                                //   }
                                ></span>
                              </label>
                    
                            </div>

                            <div className="d-flex justify-content-between align-items-baseline mr-2 views">
                              <div className="">
                                <label className="p2" for="active">
                                  Views :
                                </label>
                              </div>

                              <label className="switch">
                                <input
                                  name="views"
                                //   onChange={handleInput}
                                //   defaultChecked={
                                //     views.views == 1 ? true : false
                                //   }
                                //   checked={views.views == 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="views"
                                  // onChange={handleInput}
                                  // value={views.views == 1 ? "1" : "0"}
                                ></span>
                              </label>

                      
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

            
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-12 text-end">
                      <input
                        type="submit"
                        value="Upload"
                        // onClick={series}
                        className="btn btn-primary "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default AddNewSeries