const defaultDomain = `${process.env.REACT_APP_Baseurl}`
const defaultPreFix = 'admin'
const finalRouteApi = `${defaultDomain}/${defaultPreFix}`

const access_token = localStorage.getItem("access_token");
const adminApiHeader = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
};

const audioDefaultURL = {
    listApi: `${finalRouteApi}/audio/index`,
    MultiDeleteApi: `${finalRouteApi}/audio/multi-delete`,
    sliderApi: `${finalRouteApi}/audio/slider-update`,
    uploadAudio: `${finalRouteApi}/uploadAudio`,
    uploadURL: `${finalRouteApi}/Audio_URL`,
    uploadLiveURL: `${finalRouteApi}/LiveAudioURL`,
    optionURL: `${finalRouteApi}/audio/create`,
    storeAudio: `${finalRouteApi}/audio/store`,
    uploadImageURL: `${finalRouteApi}/Image-upload-audio`,
    uploadSEO: `${finalRouteApi}/audio/SEO`,
    uploadLyrics: `${finalRouteApi}/audio/lyrics-upload`,
    validateLyrics: `${finalRouteApi}/audio/lyrics-validation`,
    editAudioApi: `${finalRouteApi}/audio/edit`,
    updateAudio: `${finalRouteApi}/audio/update`,
    categoryAudio: `${finalRouteApi}/audio-category/index`,
    categoryMultiDelete: `${finalRouteApi}/audio-category/multi-delete`,
    categoryAudioOrderUpdate: `${finalRouteApi}/audio-category/order-audio-genre`,
    storeAudioCategory: `${finalRouteApi}/audio-category/store`,
    uploadImageURLCategory: `${finalRouteApi}/Image-upload-audiogenre`,
    editAudioApiCategory: `${finalRouteApi}/audio-category/edit`,
    updateAudioCategory: `${finalRouteApi}/audio-category/update`,
    writerApi: `${finalRouteApi}/audio/musician-content-right`,
    writerStoreApi: `${finalRouteApi}/audio/musician-content-right/Store`,
    writerApiEdit: `${finalRouteApi}/audio/musician-content-right/edit`,
    writerStoreApiUpdate: `${finalRouteApi}/audio/musician-content-right/update`,
    contentPartners: `${finalRouteApi}/content_partner/move_audio/index`,
    channelPartners: `${finalRouteApi}/channel_partner/move_audio/index`,
    contentPartnerMove: `${finalRouteApi}/content_partner/move_audio`,
    channelPartnerMove: `${finalRouteApi}/channel_partner/move_audio`,
    addRouteLink: `/add-audio`,
    editRouteLink: `/edit-audio/`,
    addRouteLinkCategory: `/add-audio-categories`,
    editRouteLinkCategory: `/edit-audio-categories/`,
    viewTab: 'en/music/track',
    headingText: "All Audios",
    buttonText: "Add Audios",
    exportFileName: "AUDIO-LIST",
    fetchArrayName: "AllAudio",
    reqBodyName: {
        sliderName: "audio_id",
        deleteName: "id"
    }

}

const albumDefaultURL = {
    listApi: `${finalRouteApi}/album/index`,
    MultiDeleteApi: `${finalRouteApi}/album/multi-delete`,
    createListApi: `${finalRouteApi}/album/create`,
    storeAlbum: `${finalRouteApi}/album/store`,
    uploadImageURL: `${finalRouteApi}/Image-upload-album`,
    uploadCopyRight: `${finalRouteApi}/album/copyrights`,
    editAlbumApi: `${finalRouteApi}/album/edit`,
    updateAlbumApi: `${finalRouteApi}/album/update`,
    addRouteLink: `/add-album`,
    editRouteLink: `/edit-album/`,
    viewTab: 'en/music/album',
    headingText: "All Album",
    buttonText: "Add Album",
    exportFileName: "Album-LIST",
    fetchArrayName: "AllAudioAlbum",
    reqBodyName: {
        deleteName: "id"
    }
}


export { audioDefaultURL, adminApiHeader, albumDefaultURL }