import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import loadings from "../../../components/Images/loading/loading.gif";

const EditAdsPlan = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    plan_name: "",
    plan_amount: "",
    Ads_upload_count_limit: "",
    Ads_views_count_limit: "",
  });
  const [editname, setEditname] = useState({
    plan_name: "",
    plan_amount: "",
    Ads_upload_count_limit: "",
    Ads_views_count_limit: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisement-Plans-edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData.json();
      var res = resData.Advertisement_Plans;
      setEditUser(res);
      setEditname(res);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageplan_name("");
    setIsValidationHiddenplan_name(true);
    setValidationMessageplan_amount("");
    setIsValidationHiddenplan_amount(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const [validationMessageplan_name, setValidationMessageplan_name] =
    useState("");
  const [isValidationHiddenplan_name, setIsValidationHiddenplan_name] =
    useState(true);

  const [validationMessageplan_amount, setValidationMessageplan_amount] =
    useState("");
  const [isValidationHiddenplan_amount, setIsValidationHiddenplan_amount] =
    useState(true);

  const plan_nameInputRef = useRef(null);
  const plan_amountInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.plan_name === "") {
      setValidationMessageplan_name("Plan Name cannot be empty.");
      setIsValidationHiddenplan_name(false);
      focusInputRef = plan_nameInputRef;
    }
    if (editUser?.plan_amount === "") {
      setValidationMessageplan_amount("Plan Amount cannot be empty.");
      setIsValidationHiddenplan_amount(false);
      focusInputRef = plan_amountInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      advertisement_Plans_id: id,
      plan_name: editUser?.plan_name,
      plan_amount: editUser?.plan_amount,
      Ads_upload_count_limit: editUser?.Ads_upload_count_limit,
      Ads_views_count_limit: editUser?.Ads_views_count_limit,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisement-Plans-update`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      } else if (resjson.status === false) {
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      setValidationMessageplan_name("");
      setValidationMessageplan_amount("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      props.setApiresponsealert(resultapifalse);
      props.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddenplan_name(false);
      setIsValidationHiddenplan_amount(false);
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Ads Plan</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Plan
                  </button>
                </div>
              </div>
            </div>
            <div>
              {showOverlay && (
                <div className="overlay-container">
                  <span className="overlay-icon">
                    {" "}
                    <img src={loadings} width={100} alt="Animated GIF" />
                    Processing ...
                  </span>
                </div>
              )}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-12 col-md-12 col-lg-12 ">
                <div className="card p-3">
                  <h5>{editname?.plan_name}</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Plan Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="plan_name"
                        name="plan_name"
                        placeholder="Enter Title"
                        value={editUser?.plan_name}
                        onChange={handleInput}
                        ref={plan_nameInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenplan_name && (
                        <p>{validationMessageplan_name}</p>
                      )}
                    </span>
                  </div>

                  <div className="mt-2">
                    <label>
                      Plan Amount
                      <span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        name="plan_amount"
                        className="rs-input form-control-lg"
                        value={editUser?.plan_amount}
                        onChange={handleInput}
                        ref={plan_amountInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenplan_amount && (
                        <p>{validationMessageplan_amount}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2">
                    <label>Ads Upload Count Limit</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        name="Ads_upload_count_limit"
                        className="rs-input form-control-lg"
                        value={editUser?.Ads_upload_count_limit}
                        onChange={handleInput}
                      />
                    </div>
                    {/* <span className="errorred">
                          {!isValidationHiddenplan_amount && (
                            <p>{validationMessageplan_amount}</p>
                          )}
                        </span> */}
                  </div>
                </div>

                {/* <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Menu
                  </button>
                </div> */}
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section  container-fluid pt-3">
            <div className="iq-card iq-card-header ">
              <div className="iq-header-title">
                <h4 className="card-title">Ads Plan List</h4>
              </div>
              <div className="mt-3">
                <div>
                  <label>Plan Name </label>

                  <input
                    type="text"
                    name="name"
                    value={editUser.name}
                    onChange={handleInput}
                    placeholder="Ads Plan Name"
                    className="form-control rtmp_urls"
                  />
                </div>
              </div>
              <div className="text-end py-3">
                <button
                  type="submit"
                  className="btn btn-primary ml-3 "
                  onClick={handleUpdate}
                >
                  Update Plan
                </button>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
};

export default EditAdsPlan;
