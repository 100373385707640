import React from 'react'
import Response_Loading_processing from "../../../src/layout/Others/Response_Loading_processing";

const NoRecordTable = ({ result, loader }) => {
  return (
    <div className="noRecord">
      {loader ? (
        <label> <Response_Loading_processing></Response_Loading_processing> Loading... </label>) :
        (result <= 0 && (<p className=" theme-text-color"> No data Available!  </p>))}
    </div>
  )
}

export default NoRecordTable