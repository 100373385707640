// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideBarBrand {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}

.sideMenu {
  padding: 10px 15px !important;
  cursor: pointer;
  color: inherit;
}

.sideMenu.active {
  background-color: rgb(45 48 56 / 8%);
  border-radius: 8px;
  color: inherit;
}

.headingActive {
  background-color: rgb(45 48 56 / 8%) !important;
  border-radius: 8px;
}

.sideMenu>div>svg {
  width: 20px;
  height: 20px;
}

.sideMenu.active svg,
.sideMenu.active span {
  color: #345afa;
}

.sideMenu:active,
.sideMenu:focus,
.sideMenu:hover {
  color: inherit !important;
  text-decoration: none;
}

/* .adminSideBarLeft [aria-expanded='true'] {
  background-color: rgb(45 48 56 / 8%);
  border-radius: 8px;
} */

.sideBarHeading {
  font-size: 16px;
  font-weight: 600;
  color: #192528;
}

.visitSite {
  background-color: #0d6efd;
  color: #000;
  font-weight: bold;
  border-radius: 5px;
}

.visitSite>a {
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/layout/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,MAAM;EACN,OAAO;EACP,UAAU;AACZ;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,+CAA+C;EAC/C,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;;EAEE,cAAc;AAChB;;AAEA;;;EAGE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;;;GAGG;;AAEH;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".sideBarBrand {\n  position: sticky;\n  top: 0;\n  left: 0;\n  z-index: 1;\n}\n\n.sideMenu {\n  padding: 10px 15px !important;\n  cursor: pointer;\n  color: inherit;\n}\n\n.sideMenu.active {\n  background-color: rgb(45 48 56 / 8%);\n  border-radius: 8px;\n  color: inherit;\n}\n\n.headingActive {\n  background-color: rgb(45 48 56 / 8%) !important;\n  border-radius: 8px;\n}\n\n.sideMenu>div>svg {\n  width: 20px;\n  height: 20px;\n}\n\n.sideMenu.active svg,\n.sideMenu.active span {\n  color: #345afa;\n}\n\n.sideMenu:active,\n.sideMenu:focus,\n.sideMenu:hover {\n  color: inherit !important;\n  text-decoration: none;\n}\n\n/* .adminSideBarLeft [aria-expanded='true'] {\n  background-color: rgb(45 48 56 / 8%);\n  border-radius: 8px;\n} */\n\n.sideBarHeading {\n  font-size: 16px;\n  font-weight: 600;\n  color: #192528;\n}\n\n.visitSite {\n  background-color: #0d6efd;\n  color: #000;\n  font-weight: bold;\n  border-radius: 5px;\n}\n\n.visitSite>a {\n  color: #fff;\n  font-size: 15px;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
