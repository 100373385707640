import { finalRouteApi } from "./CommonApiUrl"


const audioOrderDefaultURL = {
    listApi: `${finalRouteApi}/AudioHomePage-Order/index`,
    orderApi: `${finalRouteApi}/AudioHomePage-Order/ordering`,
    editApi: `${finalRouteApi}/AudioHomePage-Order/edit`,
    updateApi: `${finalRouteApi}/AudioHomePage-Order/update`,
    editRouteLink: `${finalRouteApi}/Audio_URL`,
    enableSettingURL: `${finalRouteApi}/AudioHomePage/index`,
    updateSettingURL: `${finalRouteApi}/AudioHomePage/update`,
}

export { audioOrderDefaultURL }