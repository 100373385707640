// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-icon
{
   
    margin-top: -33px;
    font-size: 25px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Payment_Management/TotalRevenue.css"],"names":[],"mappings":"AAAA;;;IAGI,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".menu-icon\n{\n   \n    margin-top: -33px;\n    font-size: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
