import React from "react";
import { Link } from "react-router-dom";
import "./error404.css";

function error404() {
  return (
    <div className=" m-0 p-0">
        <section className="page_404">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 ">
                <div className="col-sm-12 col-sm-offset-1  text-center">
                  <div className="four_zero_four_bg">
                    <h1 className="text-center d404">404</h1>
                  </div>

                  <div className="contant_box_404">
                    <h3 className="h2 d404">Look like you're lost</h3>

                    <p className="d404">the page you are looking for not avaible!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  );
}

export default error404;
