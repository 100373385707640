import React, { useState, useEffect } from "react";
import axios from "axios";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import Wrapper from "../../../components/CommonComponents/Wrapper";
import FormContainer from "../../../components/CommonComponents/FormContainer";
import MessageLabel from "../../../components/CommonComponents/MessageLabel";
import { adminApiHeader, audioDefaultURL } from "../../../components/CommonApis/CommonAudioApis";
import AdminCustomButton from "../../../components/CommonComponents/AdminCustomButton";
import { toast } from "react-toastify";
import MessageHeading from "../../../components/CommonComponents/MessageHeading";

const AudioApproval = () => {
  const [formData, setFormData] = useState({})
  const [defaultOption, setDefaultOption] = useState({})
  const [showOverlay, setShowOverlay] = useState(false);
  const [withOutRefresh, setWithOutRefresh] = useState(false)

  // api start

  const handleContentMove = async () => {
    setShowOverlay(true);
    try {
      const formDataAdd = new FormData();
      for (const key in formData) {
        if (['Audio_id', 'content_partner_id'].includes(key)) {
          formDataAdd.append(key, formData[key]);
        }
      }
      const response = await axios.post(`${audioDefaultURL?.contentPartnerMove}`, formDataAdd, { headers: adminApiHeader });
      const result = response?.data;
      if (result?.status) {
        setWithOutRefresh(!withOutRefresh)
        setShowOverlay(false);
        toast.success(result?.message)
      }
      else {
        setShowOverlay(false);
        toast.error(result?.message)
      }
    }
    catch (err) {
      setShowOverlay(false);
      const errorMessage = err?.response?.data?.message
      toast.error(errorMessage)
      console.error("error", err)
    }
  }
  const handleChannelMove = async () => {
    setShowOverlay(true);
    try {
      const formDataAdd = new FormData();
      for (const key in formData) {
        if (['Audio_id', 'Channel_partner_id'].includes(key)) {
          formDataAdd.append(key, formData[key]);
        }
      }
      const response = await axios.post(`${audioDefaultURL?.channelPartnerMove}`, formDataAdd, { headers: adminApiHeader });
      const result = response?.data;
      if (result?.status) {
        setWithOutRefresh(!withOutRefresh)
        setShowOverlay(false);
        toast.success(result?.message)
      }
      else {
        setShowOverlay(false);
        toast.error(result?.message)
      }
    }
    catch (err) {
      setShowOverlay(false);
      const errorMessage = err?.response?.data?.message
      toast.error(errorMessage)
      console.error("error", err)
    }
  }
  const getContentPartners = async () => {
    try {
      const response = await axios.get(`${audioDefaultURL?.contentPartners}`, { headers: adminApiHeader });
      const result = response?.data
      setDefaultOption((prevFormData) => ({
        ...prevFormData,
        contentOption: result?.ContentPartner,
        audioContentOption: result?.Audio
      }));

    } catch (error) {
      console.error(error);
    }
  };
  const getChannelPartners = async () => {
    try {
      const response = await axios.get(`${audioDefaultURL?.channelPartners}`, { headers: adminApiHeader });
      const result = response?.data
      setDefaultOption((prevFormData) => ({
        ...prevFormData,
        channelOption: result?.Channel,
        audioChannelOption: result?.Audio
      }));

    } catch (error) {
      console.error(error);
    }
  };
  // api end

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  useEffect(() => {
    getContentPartners()
    getChannelPartners()
    setFormData({
      Audio_id: "",
      content_partner_id: "",
      Channel_partner_id: ''
    })
  }, [withOutRefresh]);

  return (
    <>
      {showOverlay && <Response_Processing />}
      <h4 className="admin-title mb-4 theme-text-color text-md-start ">
        <MessageBox text="Move Audios Lists" classname="theme-text-color" />
      </h4>
      <FormContainer>
        <Wrapper className="w-100">
          <ul className="nav nav-tabs justify-content-around theme-border-secondary-color " id="myTab" role="tablist"  >
            <li className="nav-item" role="presentation">
              <button className="nav-link active bg-transparent border-0" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true" >
                <MessageHeading text="CPP Uploaded Audios" border padding />
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"  >
                <MessageHeading text="Channel Uploaded Audios" border padding/>
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" >
              <MessageLabel text="Choose Content Partner" className="mb-3" />
              <select id="content_partner_id" name="content_partner_id" onChange={handleInput} value={formData?.content_partner_id} className="form-select custom-placeholder theme-border-color  border-width mb-3  theme-text-color "  >
                <option value="0" className="theme-bg-color theme-text-color">Choose a content partner</option>
                {defaultOption?.contentOption?.map((item, key) => (<option value={item?.id} className="theme-bg-color theme-text-color">{item?.username}</option>))}
              </select>
              <MessageLabel text="Choose Audios (*To Be Moved)" className="mb-3" />
              <select id="Audio_id" name="Audio_id" onChange={handleInput} value={formData?.Audio_id} className="form-select  custom-placeholder mb-3 theme-border-color border-width theme-text-color "  >
                <option value="0" className="theme-bg-color theme-text-color">Choose a audio</option>
                {defaultOption?.audioContentOption?.map((item, key) => (<option value={item?.id} className="theme-bg-color theme-text-color">{item?.title}</option>))}
              </select>
              <AdminCustomButton saveMethod={handleContentMove} saveText="Submit" className="float-end d-block" />
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"  >
              <MessageLabel text="Choose channel Partner" className="mb-3" />
              <select id="Channel_partner_id" name="Channel_partner_id" onChange={handleInput} value={formData?.Channel_partner_id} className="form-select custom-placeholder theme-border-color  border-width mb-3  theme-text-color "  >
                <option value="0" className="theme-bg-color theme-text-color">Choose a channel partner</option>
                {defaultOption?.channelOption?.map((item, key) => (<option value={item?.id} className="theme-bg-color theme-text-color">{item?.channel_name}</option>))}
              </select>
              <MessageLabel text="Choose Audios (*To Be Moved)" className="mb-3" />
              <select id="Audio_id" name="Audio_id" onChange={handleInput} value={formData?.Audio_id} className="form-select  custom-placeholder mb-3 theme-border-color border-width theme-text-color "  >
                <option value="0" className="theme-bg-color theme-text-color">Choose a audio</option>
                {defaultOption?.audioChannelOption?.map((item, key) => (<option value={item?.id} className="theme-bg-color theme-text-color">{item?.title}</option>))}
              </select>
              <AdminCustomButton saveMethod={handleChannelMove} saveText="Submit" className="float-end d-block" />
            </div>
          </div>
        </Wrapper>
      </FormContainer>
    </>
  );
};
export default AudioApproval;