import React from "react";
import "./Theme.css";

function Theme() {
  const data = [
    {
      image: "https://dev-flick.webnexs.org/public/images/Theme-1.png",
      title: "Theme1",
    },
    {
      image: "https://dev-flick.webnexs.org/public/images/Theme-2.png",
      title: "Theme2",
    },
    {
      image: "https://dev-flick.webnexs.org/public/images/Theme-3.png",
      title: "Theme3",
    },
  ];
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="iq-card mt-4">
              <div id="content-page" className="content-page">
                <div className="container-fluid">
                  <div className="admin-section-title">
                    <div className="row">
                      <div className="col-md-3">
                        <h4>
                          <i className="entypo-archive"></i> Themes
                        </h4>
                      </div>
                      <div className="col-md-9" align="right">
                       
                          <button
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="fa fa-plus-circle"></i> Add New
                          </button>
                        
                      </div>

                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                New Themes
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <div>
                                <label>Enter the New Themes Name below </label>

                                <input
                                  type="text"
                                  name="name"
                                  placeholder="Theme Name"
                                  className="form-control rtmp_urls"
                                  
                                />
                              </div>
                              <div className="mt-3">
                                
                                <div className="form-group ">
                                  <label>Theme Preview Images</label>
                                  <input
                                    type="file"
                                    multiple="true"
                                    className="form-control"
                                    name="image"
                                    id="image"
                                    
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="submit"
                                className="btn btn-primary ml-3 "
                                
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sign-in-from  m-auto">
                    <div className="row data">
                      <div className="theme_image col-md-3 mb-3">
                        <div className="zoom themes">
                          <img
                            className="theme_img w-100"
                            src="https://dev-flick.webnexs.org/public/images/Default-Theme.png"
                            alt="theme"
                            id="1"
                          />
                        </div>
                        <div className="theme_name d-flex">
                          <span className="active text-start">
                            <img
                              height="20"
                              width="20"
                              className=""
                              src="https://dev-flick.webnexs.org/assets/img/yes.png"
                            />{" "}
                            Active
                          </span>
                          <div className="theme_name text-center ms-3">
                            Default
                          </div>
                        </div>
                      </div>
                      {data.map((item) => (
                        <div className="theme_image col-md-3 mb-3">
                          <div className="zoom themes">
                            <img
                              className="theme_img w-100"
                              src={item?.image}
                              alt="theme"
                              id="2"
                            />
                          </div>
                          <div className="theme_name text-center">
                            {item?.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Theme;
