import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function EditSubtitleLanguage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    language: "",
    short_code: "",
    id: "",
  });
  const [editname, setEditname] = useState({
    language: "",
  });

  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/subtitles/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Subtitle;
      // console.log(res);
      setEditUser(res);
      setEditname(res);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagelanguage("");
    setIsValidationHiddenlanguage(true);
    setValidationMessageshort_code("");
    setIsValidationHiddenshort_code(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [validationMessagelanguage, setValidationMessagelanguage] =
    useState("");
  const [isValidationHiddenlanguage, setIsValidationHiddenlanguage] =
    useState(true);

  const [validationMessageshort_code, setValidationMessageshort_code] =
    useState("");
  const [isValidationHiddenshort_code, setIsValidationHiddenshort_code] =
    useState(true);

  const languageInputRef = useRef(null);
  const short_codeInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.language === "") {
      setValidationMessagelanguage("Name cannot be empty.");
      setIsValidationHiddenlanguage(false);
      focusInputRef = languageInputRef;
    }
    if (editUser?.short_code === "") {
      setValidationMessageshort_code("Slug cannot be empty.");
      setIsValidationHiddenshort_code(false);
      focusInputRef = short_codeInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      language: editUser?.language,
      short_code: editUser?.short_code,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/subtitles/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      setValidationMessagelanguage("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddenlanguage(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Subtitle Language</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Language
                  </button>
                </div>
              </div>
            </div>
            <div>
              {showOverlay && (
                <div className="overlay-container">
                  <span className="overlay-icon">
                    {" "}
                    <Response_Loading_processing></Response_Loading_processing>
                    Processing ...
                  </span>
                </div>
              )}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-12 col-md-12 col-lg-12">
                <div className="card p-3">
                  <h5>{editname?.language}</h5>
                  <hr></hr>

                  <div className="">
                    <div>
                      <label>Name </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="language"
                          value={editUser?.language}
                          onChange={handleInput}
                          placeholder="Enter Language Name"
                          className="rs-input form-control-lg"
                          ref={languageInputRef}
                        />
                      </div>
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenlanguage && (
                        <p>{validationMessagelanguage}</p>
                      )}
                    </span>
                  </div>

                  <div className="mt-2">
                    <div>
                      <label>Slug </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="short_code"
                          value={editUser?.short_code}
                          onChange={handleInput}
                          placeholder="Enter Short Code"
                          className="rs-input form-control-lg"
                          ref={short_codeInputRef}
                        />
                      </div>
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenshort_code && (
                        <p>{validationMessageshort_code}</p>
                      )}
                    </span>
                  </div>
                </div></div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default EditSubtitleLanguage;
