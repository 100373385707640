
import { React } from "react";
import loadings from "../../../src/components/Images/loading/gif_loadings.gif";

function Defaultloadinggif() {

    return (
        <>
        <img
            src={loadings} style={{ width: "80px", height: "80px", objectFit: "contain" }}
            className="thumbnails-images" alt="processing"
        />
        </>
    );
}

export default Defaultloadinggif;
