import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../../src/layout/Others/Response_Loading_processing";
import backarrow from "../../../../components/Images/backarrow.svg";

function Site_Setting(props) {
  const [loading, setLoading] = useState(true);
  // const [message, setMessage] = useState("");
  const [logo, setLogo] = useState("");
  const [favicon, setFavicon] = useState("");

  const [setting, setSetting] = useState({
    // Advertisement_Setting
    ads_on_videos: "",
    cpc_advertiser: "",
    featured_pre_ad: "",
    featured_mid_ad: "",
    featured_post_ad: "",
    cpc_admin: "",
    cpv_advertiser: "",
    cpv_admin: "",
    default_ads_url: "",
    ads_play_unlimited_period: "",
    ads_on_livestream: "",
    ads_on_audios: "",
    ads_on_episodes: "",
    // Advertisement_Setting
    //coupon code
    new_subscriber_coupon: "",
    discount_percentage: "",
    coupon_code: "",
    //coupon code
    //email setting
    google_oauth_key: "",
    coupon_status: "",
    google_tracking_id: "",
    system_email: "",
    system_phone_number: "",
    system_address: "",
    //email setting
    //login setting
    notification_icon: "",
    notification_key: "",
    login_text: "",
    login_content: "",
    signature: "",
    //login setting
    //geo fencing
    id: "",
    geofencing: "",
    geofencing_status: "",
    //geo fencing
    // payout setting
    payout_method: "",
    //payout setting
    //ppv setting
    ppv_status: "",
    ppv_rent: "",
    ppv_price: "",
    ppv_hours: "",
    ios_product_id: "",
    ios_plan_price: "",
    expiry_time_started: "",
    expiry_day_notstarted: "",
    expiry_hours_notstarted: "",
    expiry_min_notstarted: "",
    PPV_Individual_Contents: "",
    //ppv setting
    // capcha setting
    captcha_site_key: "",
    captcha_secret_key: "",
    captcha: "",
    //capcha setting
    //registration settings
    free_registration: "",
    activation_email: "",
    premium_upgrade: "",
    access_free: "",
    enable_landing_page: "",
    //registration settings
    //search settings
    search_title_status: "",
    search_category_status: "",
    search_tags_status: "",
    search_description_status: "",
    search_details_status: "",
    //search settings
    //series settings
    series_season: "",
    series_networks_status: "",
    //series settings
    //site settings
    website_name: "",
    website_description: "",
    logo_height: "",
    logo_width: "",
    login_content: "",
    favicon: "",
    logo: "",
    //site settings
    //social network settings
    facebook_page_id: "",
    google_page_id: "",
    twitter_page_id: "",
    instagram_page_id: "",
    linkedin_page_id: "",
    whatsapp_page_id: "",
    skype_page_id: "",
    youtube_page_id: "",
    email_page_id: "",
    tiktok_page_id: "",
    //social network settings
    //video settings
    videos_per_page: "",
    posts_per_page: "",
    show_description: "",
    show_Links_and_details: "",
    show_genre: "",
    show_languages: "",
    show_subtitle: "",
    show_artist: "",
    show_recommended_videos: "",
    show_views: "",
    videos_expiry_status: "",
    epg_status: "",
    //video settings
    earn_amount: "",
    video_clip_enable: "",

    enable_ppv_rent: "",
  });


  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, {
        headers: headers,
      })
      .then((response) => {
        var setting = response?.data?.settings[0];
        var logo = response?.data?.settings[0]?.logo;
        var favicon = response?.data?.settings[0]?.favicon;
        setSetting(setting);
        setFavicon(favicon);
        setLogo(logo);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setValidationMessagewebsite_name("");
    setIsValidationHiddenwebsite_name(true);
    setValidationMessagelogo_height("");
    setIsValidationHiddenlogo_height(true);
    setValidationMessagelogo_width("");
    setIsValidationHiddenlogo_width(true);
    setSetting({ ...setting, [e.target.name]: e.target.value });
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  const [validationMessagewebsite_name, setValidationMessagewebsite_name] =
    useState("");
  const [isValidationHiddenwebsite_name, setIsValidationHiddenwebsite_name] =
    useState(true);

  const [validationMessagelogo_width, setValidationMessagelogo_width] =
    useState("");
  const [isValidationHiddenlogo_width, setIsValidationHiddenlogo_width] =
    useState(true);

  const [validationMessagelogo_height, setValidationMessagelogo_height] =
    useState("");
  const [isValidationHiddenlogo_height, setIsValidationHiddenlogo_height] =
    useState(true);

  const website_nameInputRef = useRef(null);
  const logo_widthInputRef = useRef(null);
  const logo_heightInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    let focusInputRef = null;

    if (setting?.website_name === "") {
      setValidationMessagewebsite_name("Website Name cannot be empty.");
      setIsValidationHiddenwebsite_name(false);
      focusInputRef = website_nameInputRef;
    } if (setting?.logo_width === "") {
      setValidationMessagelogo_width("Logo Width cannot be empty.");
      setIsValidationHiddenlogo_width(false);
      focusInputRef = website_nameInputRef;
    } if (setting?.logo_height === "") {
      setValidationMessagelogo_height("Logo Height cannot be empty.");
      setIsValidationHiddenlogo_height(false);
      focusInputRef = logo_heightInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }


    const enable_ppv_rent = Number.isNaN(parseInt(setting?.enable_ppv_rent, 10)) ? 0 : parseInt(setting?.enable_ppv_rent, 10);

    // advertisement setting
    const ads_on_videos = Number.isNaN(parseInt(setting?.ads_on_videos, 10)) ? 0 : parseInt(setting?.ads_on_videos, 10);
    const ads_play_unlimited_period = Number.isNaN(parseInt(setting?.ads_play_unlimited_period, 10)) ? 0 : parseInt(setting?.ads_play_unlimited_period, 10);
    const ads_on_livestream = Number.isNaN(parseInt(setting?.ads_on_livestream, 10)) ? 0 : parseInt(setting?.ads_on_livestream, 10);
    const ads_on_audios = Number.isNaN(parseInt(setting?.ads_on_audios, 10)) ? 0 : parseInt(setting?.ads_on_audios, 10);
    const ads_on_episodes = Number.isNaN(parseInt(setting?.ads_on_episodes, 10)) ? 0 : parseInt(setting?.ads_on_episodes, 10);

    //coupon setting      
    const coupon_code = Number.isNaN(parseInt(setting?.coupon_code, 10)) ? 0 : parseInt(setting?.coupon_code, 10);


    //payout settings   
    const payout_method = Number.isNaN(parseInt(setting?.payout_method, 10)) ? 0 : parseInt(setting?.payout_method, 10);


    //registration settings
    const free_registration = Number.isNaN(parseInt(setting?.free_registration, 10)) ? 0 : parseInt(setting?.free_registration, 10);


    //search settings
    const search_title_status = Number.isNaN(parseInt(setting?.search_title_status, 10)) ? 0 : parseInt(setting?.search_title_status, 10);
    const search_category_status = Number.isNaN(parseInt(setting?.search_category_status, 10)) ? 0 : parseInt(setting?.search_category_status, 10);
    const search_tags_status = Number.isNaN(parseInt(setting?.search_tags_status, 10)) ? 0 : parseInt(setting?.search_tags_status, 10);
    const search_description_status = Number.isNaN(parseInt(setting?.search_description_status, 10)) ? 0 : parseInt(setting?.search_description_status, 10);
    const search_details_status = Number.isNaN(parseInt(setting?.search_details_status, 10)) ? 0 : parseInt(setting?.search_details_status, 10);


    //serires settings
    const series_networks_status = Number.isNaN(parseInt(setting?.series_networks_status, 10)) ? 0 : parseInt(setting?.series_networks_status, 10);

    // video settings 
    const videos_expiry_status = Number.isNaN(parseInt(setting?.videos_expiry_status, 10)) ? 0 : parseInt(setting?.videos_expiry_status, 10);
    const videos_per_page = Number.isNaN(parseInt(setting?.videos_per_page, 10)) ? 0 : parseInt(setting?.videos_per_page, 10);
    const epg_status = Number.isNaN(parseInt(setting?.epg_status, 10)) ? 0 : parseInt(setting?.epg_status, 10);
    const show_genre = Number.isNaN(parseInt(setting?.show_genre, 10)) ? 0 : parseInt(setting?.show_genre, 10);
    const show_languages = Number.isNaN(parseInt(setting?.show_languages, 10)) ? 0 : parseInt(setting?.show_languages, 10);
    const show_subtitle = Number.isNaN(parseInt(setting?.show_subtitle, 10)) ? 0 : parseInt(setting?.show_subtitle, 10);
    const show_artist = Number.isNaN(parseInt(setting?.show_artist, 10)) ? 0 : parseInt(setting?.show_artist, 10);
    const show_recommended_videos = Number.isNaN(parseInt(setting?.show_recommended_videos, 10)) ? 0 : parseInt(setting?.show_recommended_videos, 10);
    const show_views = Number.isNaN(parseInt(setting?.show_views, 10)) ? 0 : parseInt(setting?.show_views, 10);
    const show_Links_and_details = Number.isNaN(parseInt(setting?.show_Links_and_details, 10)) ? 0 : parseInt(setting?.show_Links_and_details, 10);
    const show_description = Number.isNaN(parseInt(setting?.show_description, 10)) ? 0 : parseInt(setting?.show_description, 10);

    const editInputvalue = {

      // advertisement setting
      ads_on_videos: ads_on_videos,
      default_ads_url: setting?.default_ads_url,
      featured_pre_ad: setting?.featured_pre_ad,
      featured_mid_ad: setting?.featured_mid_ad,
      featured_post_ad: setting?.featured_post_ad,
      cpv_admin: setting?.cpv_admin,
      cpv_advertiser: setting?.cpv_advertiser,
      cpc_admin: setting?.cpc_admin,
      cpc_advertiser: setting?.cpc_advertiser,
      ads_play_unlimited_period: ads_play_unlimited_period,
      ads_on_livestream: ads_on_livestream,
      ads_on_audios: ads_on_audios,
      ads_on_episodes: ads_on_episodes,

      //coupon setting      
      discount_percentage: setting?.discount_percentage,
      coupon_code: coupon_code,
      new_subscriber_coupon: setting?.new_subscriber_coupon,

      //email settings
      coupon_status: setting?.coupon_status,
      google_tracking_id: setting?.google_tracking_id,
      google_oauth_key: setting?.google_oauth_key,
      system_email: setting?.system_email,
      system_phone_number: setting?.system_phone_number,

      //login settings      
      login_text: setting?.login_text,
      signature: setting?.signature,
      login_content: setting?.login_content,
      notification_key: setting?.notification_key,

      //payout setting
      payout_method: payout_method,

      //ppv settings
      ppv_status: setting?.ppv_status,
      PPV_Individual_Contents: setting?.PPV_Individual_Contents,
      ppv_price: setting?.ppv_price,
      ppv_hours: setting?.ppv_hours,
      ios_plan_price: setting?.ios_plan_price,
      ios_product_id: setting?.ios_product_id,
      expiry_time_started: setting?.expiry_time_started,
      expiry_day_notstarted: setting?.expiry_day_notstarted,
      expiry_hours_notstarted: setting?.expiry_hours_notstarted,
      expiry_min_notstarted: setting?.expiry_min_notstarted,

      //registration settings
      enable_landing_page: setting?.enable_landing_page,
      free_registration: free_registration,
      activation_email: setting?.activation_email,
      premium_upgrade: setting?.premium_upgrade,
      access_free: setting?.access_free,

      //search settings
      search_title_status: search_title_status,
      search_category_status: search_category_status,
      search_tags_status: search_tags_status,
      search_description_status: search_description_status,
      search_details_status: search_details_status,

      //serires settings
      series_season: setting?.series_season,
      series_networks_status: series_networks_status,

      //site settings
      website_name: setting?.website_name,
      website_description: setting?.website_description,
      logo: setting?.logo,
      logo_height: setting?.logo_height,
      logo_width: setting?.logo_width,
      favicon: setting?.favicon,

      //social network settings
      facebook_page_id: setting?.facebook_page_id,
      google_page_id: setting?.google_page_id,
      twitter_page_id: setting?.twitter_page_id,
      instagram_page_id: setting?.instagram_page_id,
      linkedin_page_id: setting?.linkedin_page_id,
      whatsapp_page_id: setting?.whatsapp_page_id,
      skype_page_id: setting?.skype_page_id,
      youtube_page_id: setting?.youtube_page_id,
      tiktok_page_id: setting?.tiktok_page_id,
      email_page_id: setting?.email_page_id,

      //default_time_zone
      default_time_zone: setting?.default_time_zone,

      // video settings 
      videos_per_page: videos_per_page,
      posts_per_page: setting?.posts_per_page,
      show_description: show_description,
      show_Links_and_details: show_Links_and_details,
      show_genre: show_genre,
      show_languages: show_languages,
      show_subtitle: show_subtitle,
      show_artist: show_artist,
      show_recommended_videos: show_recommended_videos,
      show_views: show_views,
      videos_expiry_status: videos_expiry_status,
      epg_status: epg_status,

      epg_status: enable_ppv_rent,

      earn_amount: setting?.earn_amount ? parseInt(setting?.earn_amount, 10) : 0,
      video_clip_enable: setting?.video_clip_enable ? parseInt(setting?.video_clip_enable, 10) : 0,

    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/setting/store`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res?.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();

        // formData.append("video_id", id);
        if (selectedFile1) {
          formData.append("favicon", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("logo", selectedFile2);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/setting/store_image`,
          formData,
          { headers: headers }
        );

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }
        // secondApiStatus = true;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagewebsite_name("");
      setValidationMessagelogo_width("");
      setValidationMessagelogo_height("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson?.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddenwebsite_name(false);
        setIsValidationHiddenlogo_height(false);
        setIsValidationHiddenlogo_width(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <Link to="/settings" className="backarrow">
                <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                <span> Back</span>
              </Link>
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Site Description</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Save Site
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Site</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Website Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="website_name"
                        name="website_name"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={setting?.website_name}
                        ref={website_nameInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenwebsite_name && (
                        <p>{validationMessagewebsite_name}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Website Description</label>

                    <div className="mt-2">
                      <textarea
                        type="text"
                        id="website_description"
                        name="website_description"
                        value={setting?.website_description}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      ></textarea>
                    </div>
                  </div>

                  <div className="row mt-2 text-start">
                    <div className="col-lg-12">
                      <label className="m-0">
                        Logo(Dimensions: 180px X 29px)
                      </label>

                      <div className="mt-2">
                        <div className="mt-2">
                          <small className="m-0">Enter Your Logo Width<span className="mandatory">*</span></small>

                          <div className="mt-2">
                            <input
                              type="number"
                              id="logo_width"
                              name="logo_width"
                              value={setting?.logo_width}
                              ref={logo_widthInputRef}
                              className="rs-input form-control-lg"
                              onChange={handleInput}
                              placeholder=""
                            />
                          </div>
                          <span className="errorred">
                            {!isValidationHiddenlogo_width && (
                              <p>{validationMessagelogo_width}</p>
                            )}
                          </span>
                        </div>

                        <div className="mt-2">
                          <small className="m-0">Enter Your Logo Height<span className="mandatory">*</span></small>
                          <div className="mt-2">
                            <input
                              type="number"
                              id="logo_height"
                              name="logo_height"
                              value={setting?.logo_height}
                              ref={logo_heightInputRef}
                              className="rs-input form-control-lg"
                              onChange={handleInput}
                              placeholder=""
                            />
                          </div>
                          <span className="errorred">
                            {!isValidationHiddenlogo_height && (
                              <p>{validationMessagelogo_height}</p>
                            )}
                          </span>
                        </div>

                        {/* <div className="row">
                          <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                            <small className="m-0">Enter Your Logo Height</small>
                            <div className="mt-2">
                              <input
                                type="text"
                                id="logo_width"
                                name="logo_width"
                                value={setting?.logo_width}
                                className="rs-input form-control-lg"
                                onChange={handleInput}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                            <small className="m-0">
                              Enter Your Logo Height
                            </small>
                            <div className="mt-2">
                              <input
                                type="text"
                                id="logo_height"
                                name="logo_height"
                                value={setting?.logo_height}
                                className="rs-input form-control-lg"
                                onChange={handleInput}
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Save Site
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Site Favicon</label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={favicon}
                          alt="thumbnails-images"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(1)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Site Logo</label>

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={logo}
                          alt="thumbnails-images"
                          className="thumbnails-images h-25"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(2)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Site_Setting;
