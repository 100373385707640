// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all_media_library .view {
  color: #000000;
}
.all_media_library .edit {
  color: #000000;
}
.all_media_library .delete {
  color: #000000;
}
.videoWidth-library > th:nth-child(2) {
  width: 260px;
}
.videoWidth-library > th {
  width: 100px;
}
.rightVideoTitle-library{
  width: 200px;
}
.slug_diable_icon{
  color: #000000 !important;
}

`, "",{"version":3,"sources":["webpack://./src/Pages/Content_Libraries/Media_Library.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".all_media_library .view {\n  color: #000000;\n}\n.all_media_library .edit {\n  color: #000000;\n}\n.all_media_library .delete {\n  color: #000000;\n}\n.videoWidth-library > th:nth-child(2) {\n  width: 260px;\n}\n.videoWidth-library > th {\n  width: 100px;\n}\n.rightVideoTitle-library{\n  width: 200px;\n}\n.slug_diable_icon{\n  color: #000000 !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
