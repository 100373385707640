// import React from "react";
// import { Link } from "react-router-dom";

// function FileManager() {
//   return (
//     <div className="m-0 p-0">
//       <div className="">
//         <section className="section container-fluid">FileManager</section>
//       </div>
//     </div>
//   );
// }

// export default FileManager;



import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SubscriptionPage = () => {


      const [formDataiyzicopayment, setFormDataiyzicopayment] = useState({
        locale: 'TR',
        conversationId: '12309',
        price: '45',
        paidPrice: '45',
        currency: 'TRY',
        basketId: 'B67832',
        paymentGroup: 'PRODUCT',
        callbackUrl: 'http://localhost:7004/callback', // Ensure this matches your server configuration
        enabledInstallments: [2, 3, 6, 9],
        buyer: {
            id: 'BY789',
            name: 'Pradeepraj',
            surname: 'S',
            gsmNumber: '+919876543210',
            email: 'pradeep@webnexs.in',
            identityNumber: '74300864791',
            lastLoginDate: '2015-10-05 12:43:35',
            registrationDate: '2013-04-21 15:12:09',
            registrationAddress: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            ip: '85.34.78.112',
            city: 'Istanbul',
            country: 'Turkey',
            zipCode: '34732'
        },
        shippingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
        },
        billingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
        },
        basketItems: [
            {
                id: 'BI101',
                name: 'Binocular',
                category1: 'Collectibles',
                category2: 'Accessories',
                itemType: 'PHYSICAL',
                price: '45'
            }
        ]
    });

    // const initializeCheckout = async () => {
    //     try {
    //         const response = await axios.post('http://localhost:7004/initialize-checkout', formDataiyzicopayment);
    //         const paymentPageUrl = response.data.paymentPageUrl;
    //         // Redirect the user to the payment page
    //         window.location.href = paymentPageUrl;
    //     } catch (error) {
    //         console.error('Error initializing checkout:', error);
    //     }
    // };



    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const request = {
      locale: "EN",
      conversationId: '123456789',
      callbackUrl: 'http://localhost:7001',
      pricingPlanReferenceCode: '895431e5-1be5-4499-9b2e-97be87aa7815',
      subscriptionInitialStatus: "ACTIVE",
      customer: {
          name: 'name',
          surname: 'surname',
          identityNumber: '11111111111',
          email: 'test123@test.com',
          gsmNumber: '+9005555555555',
          billingAddress: {
              contactName: 'Jane Doe',
              city: 'Istanbul',
              district: 'altunizade',
              country: 'Turkey',
              address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
              zipCode: '34742'
          },
          shippingAddress: {
              contactName: 'Jane Doe',
              city: 'Istanbul',
              district: 'altunizade',
              country: 'Turkey',
              address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
              zipCode: '34742'
          }
      }
  };

// final o/p 
// final o/p 

    // const handleSubscription = async () => {
    //   setLoading(true);
    //   setError(null);
    //   try {
    //     // Make the API call to create a subscription
    //     const response = await axios.post('http://localhost:7004/create-subscription-subscription', request);
    //     const scriptContent = response.data.checkoutFormContent;

    //     // Extract individual script tags
    //     const scripts = scriptContent.match(/<script[^>]*>([\s\S]*?)<\/script>/g) || [];
        
    //     // Append each script to the document
    //     scripts.forEach((script) => {
    //       const scriptElement = document.createElement('script');
    //       scriptElement.textContent = script.replace(/<\/?script[^>]*>/g, ''); // Remove <script> tags
    //       document.body.appendChild(scriptElement);
    //     });
  
    //     // Initialize the scripts if necessary
    //     if (window.iyziInit && typeof window.iyziInit.createTag === 'function') {
    //       window.iyziInit.createTag();
    //     } else if (window.iyziUcsInit && typeof window.iyziUcsInit.createTag === 'function') {
    //       window.iyziUcsInit.createTag();
    //     } else {
    //       throw new Error('Iyzico script initialization failed.');
    //     }
    //   } catch (err) {
    //     console.error('Subscription Error:', err);
    //     setError('An error occurred while creating the subscription.');
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    // final o/p 
    // final o/p 

    // const handleSubscription = async () => {
    //   setLoading(true);
    //   setError(null);
    //   try {
    //     const response = await axios.post('http://localhost:7004/create-subscription', request);
    //     const scriptContent = response.data.checkoutFormContent;
    
    //     // Inject and initialize Iyzico script
    //     const scripts = scriptContent.match(/<script[^>]*>([\s\S]*?)<\/script>/g) || [];
    //     scripts.forEach((script) => {
    //       const scriptElement = document.createElement('script');
    //       scriptElement.textContent = script.replace(/<\/?script[^>]*>/g, '');
    //       document.body.appendChild(scriptElement);
    //     });
    
    //     if (window.iyziInit && typeof window.iyziInit.createTag === 'function') {
    //       window.iyziInit.createTag();
    //     } else if (window.iyziUcsInit && typeof window.iyziUcsInit.createTag === 'function') {
    //       window.iyziUcsInit.createTag();
    //     } else if (window.iyziSubscriptionInit && typeof window.iyziSubscriptionInit.createTag === 'function') {
    //       window.iyziSubscriptionInit.createTag();
    //     } else {
    //       throw new Error('Iyzico script initialization failed.');
    //     }
    
    //   } catch (err) {
    //     console.error('Payment Error:', err);
    //   }
    // };
  
    // Function to load script content dynamically
    const loadScriptContent = (scriptContent) => {
      return new Promise((resolve, reject) => {
        try {
          const blob = new Blob([scriptContent], { type: 'text/javascript' });
          const url = URL.createObjectURL(blob);
          const script = document.createElement('script');
          script.src = url;
          script.onload = () => {
            URL.revokeObjectURL(url); // Clean up the URL object
            resolve();
          };
          script.onerror = () => {
            URL.revokeObjectURL(url); // Clean up the URL object
            reject(new Error('Failed to load script content'));
          };
          document.body.appendChild(script);
        } catch (err) {
          reject(new Error(`Error handling script content: ${err.message}`));
        }
      });
    };
    

  





    const [formDataiyzico, setFormDataiyzico] = useState([
      {
        locale: "EN",
        conversationId: '123456789',
        callbackUrl: 'http://localhost:7001',
        pricingPlanReferenceCode: "895431e5-1be5-4499-9b2e-97be87aa7815", // Adjust based on your data structure
        subscriptionInitialStatus: "ACTIVE",
        customer: {
          name: 'name',
          surname: 'raj',
          identityNumber: '11111111111',
          email: 'raj@test.com',
          gsmNumber: '+905051234567',
          billingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            district: 'altunizade',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
          },
          shippingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            district: 'altunizade',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
          }
        },
        pricingPlanPrice: "444"
      },
      {
        locale: "EN",
        conversationId: '123456789',
        callbackUrl: 'http://localhost:7001',
        pricingPlanReferenceCode: "a375b1fb-a074-4ddb-a80f-f685ce9abc9e", // Adjust based on your data structure
        subscriptionInitialStatus: "ACTIVE",
        price: '123',
        paidPrice: '123',
        customer: {
          name: 'pradeep',
          surname: 'surname',
          identityNumber: '11111111111',
          email: 'pradeep@test.in',
          gsmNumber: '+905051234567',
          billingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            district: 'altunizade',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
          },
          shippingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            district: 'altunizade',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
          }
        },
        pricingPlanPrice: "123"
      },
    ]);

    const [selectedProduct, setSelectedProduct] = useState(null);
  
    const handleProductClick = async (product) => {
      setSelectedProduct(product);
  
      const successpagesuccesspage = `http://localhost:7001`
      console.log(successpagesuccesspage, "successpagesuccesspage")

      const request = {
        locale: "EN",
        conversationId: '123456789',
        callbackUrl: 'http://localhost:7004/payment-callback',
        successpage: successpagesuccesspage,
        pricingPlanReferenceCode: product.pricingPlanReferenceCode, // Adjust based on your data structure
        subscriptionInitialStatus: "ACTIVE",
        price: product.price,
        paidPrice: product.paidPrice,
        customer: {
          name: product.customer.name,
          surname: product.customer.surname,
          identityNumber: '11111111111',
          email: product.customer.email,
          gsmNumber: product.customer.gsmNumber,
          billingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            district: 'altunizade',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
          },
          shippingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            district: 'altunizade',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
          }
        },
    pricingPlanPrice: product.pricingPlanPrice // Ensure this is set correctly
      };
  
      setLoading(true);
      setError(null);
      try {
        const response = await axios.post('http://localhost:7004/create-subscription', request);
        const scriptContent = response.data.checkoutFormContent;
    
        // Inject and initialize Iyzico script
        const scripts = scriptContent.match(/<script[^>]*>([\s\S]*?)<\/script>/g) || [];
        scripts.forEach((script) => {
          const scriptElement = document.createElement('script');
          scriptElement.textContent = script.replace(/<\/?script[^>]*>/g, '');
          document.body.appendChild(scriptElement);
        });
    
        if (window.iyziInit && typeof window.iyziInit.createTag === 'function') {
          window.iyziInit.createTag();
        } else if (window.iyziUcsInit && typeof window.iyziUcsInit.createTag === 'function') {
          window.iyziUcsInit.createTag();
        } else if (window.iyziSubscriptionInit && typeof window.iyziSubscriptionInit.createTag === 'function') {
          window.iyziSubscriptionInit.createTag();
        } else {
          throw new Error('Iyzico script initialization failed.');
        }
    
      } catch (err) {
        console.error('Payment Error:', err);
      }
    };

useEffect(() => {
  let showAlert = true; // Flag to track if alert has been shown

  const retrievePaymentDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/retrieve-payment-details-subcription`);

      if (response.data.status === 'success' && showAlert) {
        // Show success toast
        // toast.success(`PAYMENT ${response?.data?.paymentStatus}`);
        showAlert = false; // Set flag to false after showing alert

        const result = response.data;
        const user_id = localStorage.getItem("user_id");

        // Send user_id along with result to the API endpoint
        await axios.post('http://localhost:7001/payment/details', {
          ...result,
          user_id
        });
      } else {
        // Show error toast
        // toast.error(`PAYMENT ${response?.data?.paymentStatus}`);
      }
    } catch (error) {
      console.error('Error retrieving payment details:', error);
    }
  };

  retrievePaymentDetails();
}, []);

    
    // const handleSubscriptionformDataiyzico = async (product) => {
    //   setSelectedProduct(product);
  
    //   const request = {
    //     locale: selectedProduct.locale,
    //     conversationId: '123456789',
    //     callbackUrl: 'http://localhost:7001',
    //     pricingPlanReferenceCode: selectedProduct.pricingPlanReferenceCode, // Adjust based on your data structure
    //     subscriptionInitialStatus: selectedProduct.subscriptionInitialStatus,
    //     customer: {
    //       name: selectedProduct.customer.name,
    //       surname: selectedProduct.customer.surname,
    //       identityNumber: '11111111111',
    //       email: 'test123@test.com',
    //       gsmNumber: '+9005555555555',
    //       billingAddress: {
    //         contactName: 'Jane Doe',
    //         city: 'Istanbul',
    //         district: 'altunizade',
    //         country: 'Turkey',
    //         address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
    //         zipCode: '34742'
    //       },
    //       shippingAddress: {
    //         contactName: 'Jane Doe',
    //         city: 'Istanbul',
    //         district: 'altunizade',
    //         country: 'Turkey',
    //         address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
    //         zipCode: '34742'
    //       }
    //     }
    //   };
  
    //   setLoading(true);
    //   setError(null);
    //   try {
    //     const response = await axios.post('http://localhost:7004/create-subscription-subscription', request);
    //     const scriptContent = response.data.checkoutFormContent;
  
    //     const scripts = scriptContent.match(/<script[^>]*>([\s\S]*?)<\/script>/g) || [];
    //     scripts.forEach((script) => {
    //       const scriptElement = document.createElement('script');
    //       scriptElement.textContent = script.replace(/<\/?script[^>]*>/g, '');
    //       document.body.appendChild(scriptElement);
    //     });
  
    //     if (window.iyziInit && typeof window.iyziInit.createTag === 'function') {
    //       window.iyziInit.createTag();
    //     } else if (window.iyziUcsInit && typeof window.iyziUcsInit.createTag === 'function') {
    //       window.iyziUcsInit.createTag();
    //     } else {
    //       throw new Error('Iyzico script initialization failed.');
    //     }
    //   } catch (err) {
    //     console.error('Subscription Error:', err);
    //     setError('An error occurred while creating the subscription.');
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    return (
      <>
        <div>

{/* <button onClick={initializeCheckout}>Initialize Checkout</button> */}

            {/* <button onClick={handleSubscription} disabled={loading}>
                {loading ? 'Processing...' : 'Subscribe Now'}
            </button>
            {error && <p>{error}</p>} */}
        </div>


        <div>
      <h1>Available formDataiyzico</h1>
      <ul>
        {formDataiyzico.map((product) => (
          <li key={product.id}>
            <span>{product.name}</span> {/* Adjust based on your product data */}
            <button onClick={() => handleProductClick(product)}>Select</button>
          </li>
        ))}
      </ul>
      {selectedProduct && (
        <div>
          <h2>Selected Product: {selectedProduct.name}</h2>
          {/* <button onClick={handleSubscription}>Pay</button> */}
        </div>
      )}
    </div>
    
        </>
    );
};

export default SubscriptionPage;



// import React, { useEffect } from 'react';

// const IyzicoPayment = () => {
//   useEffect(() => {
//     // Function to load an external script
//     const loadScript = (src) => {
//       return new Promise((resolve, reject) => {
//         const script = document.createElement('script');
//         script.src = src;
//         script.async = true;
//         script.onload = () => resolve();
//         script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
//         document.body.appendChild(script);
//       });
//     };

//     // Function to initialize Iyzico scripts
//     const initializeIyzico = async () => {
//       try {
//         // Load the Iyzico checkout script
//         await loadScript('https://sandbox-static.iyzipay.com/checkoutform/v2/bundle.js?v=1722860802734');
        
//         // Initialize the Iyzico Checkout
//         if (typeof window.iyziInit === 'undefined') {
//           window.iyziInit = {
//             currency: "TRY",
//             token: "e0a25722-f8ce-4336-bca4-dc367531d06b",
//             price: 1.00, // Ensure this is the correct amount
//             pwiPrice: 1.00,
//             locale: "en",
//             baseUrl: "https://sandbox-api.iyzipay.com",
//             merchantGatewayBaseUrl: "https://sandbox-merchantgw.iyzipay.com",
//             registerCardEnabled: false,
//             bkmEnabled: false,
//             bankTransferEnabled: false,
//             bankTransferTimeLimit: { "value": 5, "type": "day" },
//             bankTransferRedirectUrl: "",
//             bankTransferCustomUIProps: {},
//             campaignEnabled: false,
//             campaignMarketingUiDisplay: null,
//             paymentSourceName: "",
//             plusInstallmentResponseList: null,
//             payWithIyzicoSingleTab: false,
//             payWithIyzicoSingleTabV2: false,
//             payWithIyzicoOneTab: false,
//             newDesignEnabled: false,
//             mixPaymentEnabled: true,
//             creditCardEnabled: true,
//             bankTransferAccounts: [],
//             userCards: [],
//             fundEnabled: false,
//             memberCheckoutOtpData: {},
//             force3Ds: false,
//             isSandbox: true,
//             storeNewCardEnabled: true,
//             paymentWithNewCardEnabled: true,
//             enabledApmTypes: [],
//             payWithIyzicoUsed: false,
//             payWithIyzicoEnabled: false,
//             payWithIyzicoCustomUI: {},
//             buyerName: "John",
//             buyerSurname: "Doe",
//             merchantInfo: "",
//             merchantName: "Sandbox Merchant Name - mailto:3398477",
//             cancelurl: "",
//             buyerprotectionenabled: false,
//             hide3ds: true,
//             gsmnumber: "+905555555555",
//             email: "johndoe@iyzico.com",
//             checkConsumerDetail: {},
//             subscriptionPaymentEnabled: true,
//             ucsEnabled: true,
//             fingerprintEnabled: false,
//             payWithIyzicoFirstTab: false,
//             creditEnabled: false,
//             payWithIyzicoLead: false,
//             goBackUrl: "",
//             metadata: { "debitCardAllowed": "false" },
//             createTag: function () {
//               var iyziJSTag = document.createElement('script');
//               iyziJSTag.setAttribute('src', 'https://sandbox-static.iyzipay.com/checkoutform/v2/bundle.js?v=1722860802734');
//               document.head.appendChild(iyziJSTag);
//             }
//           };
//           window.iyziInit.createTag();
//         }
        
//         // Load UCS script
//         if (typeof window.iyziUcsInit === 'undefined') {
//           window.iyziUcsInit = {
//             baseUrl: "https://sandbox-api.iyzipay.com",
//             buyerProtectedConsumer: false,
//             ucsToken: "126ae5b8-0a83-4486-8df2-c911ed17b57c",
//             scriptType: "CONSUMER_WITH_CARD_EXIST",
//             buyerProtectedMerchant: false,
//             maskedGsmNumber: "+90********55",
//             ucsConsentRequiredConsumer: false,
//             gsmNumber: "+905555555555",
//             merchantName: "Sandbox Merchant Name - 3398477",
//             createTag: function () {
//               var iyziUcsJSTag = document.createElement('script');
//               iyziUcsJSTag.setAttribute('src', 'https://sandbox-static.iyzipay.com/checkoutform/v2/bundle.js?v=1722860802760');
//               document.head.appendChild(iyziUcsJSTag);
//             }
//           };
//           window.iyziUcsInit.createTag();
//         }

//         // Load Subscription script
//         if (typeof window.iyziSubscriptionInit === 'undefined') {
//           window.iyziSubscriptionInit = {
//             daysOfTrialPeriod: "54",
//             intervalCount: "1",
//             subscriptionState: "START_WITH_TRIAL_PERIOD",
//             interval: "MONTHLY",
//             pricingPlanPrice: "54.98"
//           };
//         }

//       } catch (error) {
//         console.error('Error initializing Iyzico:', error);
//       }
//     };

//     initializeIyzico();

//     return () => {
//       // Cleanup scripts when component unmounts
//       const scripts = document.querySelectorAll('script[src*="iyzipay"]');
//       scripts.forEach(script => document.body.removeChild(script));
//     };
//   }, []);

//   const handlePayment = () => {
//     // Trigger Iyzico payment process
//     if (typeof window.iyziInit !== 'undefined') {
//       // Open Iyzico payment form
//       window.iyziInit.createTag();
//     } else {
//       alert('Iyzico payment scripts are not loaded yet.');
//     }
//   };

//   return (
//     <div>
//       <h1>Payment Page</h1>
//       <button onClick={handlePayment}>Pay with Iyzico</button>
//     </div>
//   );
// };

// export default IyzicoPayment;




// import React, { useState } from 'react';
// import axios from 'axios';

// const IyzicoPayment = () => {
//   const [isScriptLoaded, setIsScriptLoaded] = useState(false);
//   const [scriptData, setScriptData] = useState(null);

//   // Function to load an external script
//   const loadScript = (src) => {
//     return new Promise((resolve, reject) => {
//       const existingScript = document.querySelector(`script[src="${src}"]`);
//       if (existingScript) {
//         resolve(); // Script already loaded
//         return;
//       }
//       const script = document.createElement('script');
//       script.src = src;
//       script.async = true;
//       script.onload = () => resolve();
//       script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
//       document.body.appendChild(script);
//     });
//   };

//   // Fetch script data from API
//   const fetchScriptData = async () => {
//     try {
//       const response = await axios.get('YOUR_API_ENDPOINT'); // Replace with your API endpoint
//       setScriptData(response.data);
//     } catch (error) {
//       console.error('Error fetching script data:', error);
//     }
//   };

//   // Initialize Iyzico scripts
//   const initializeIyzico = async () => {
//     if (!scriptData) {
//       await fetchScriptData();
//     }

//     if (scriptData) {
//       try {
//         // Load the Iyzico checkout script
//         await loadScript(scriptData.checkoutScriptUrl); // URL from the API response

//         // Initialize the Iyzico Checkout
//         if (typeof window.iyziInit === 'undefined') {
//           window.iyziInit = scriptData.iyziInitConfig;
//           window.iyziInit.createTag();
//         }

//         // Load UCS script
//         if (typeof window.iyziUcsInit === 'undefined') {
//           window.iyziUcsInit = scriptData.iyziUcsConfig;
//           window.iyziUcsInit.createTag();
//         }

//         // Load Subscription script
//         if (typeof window.iyziSubscriptionInit === 'undefined') {
//           window.iyziSubscriptionInit = scriptData.iyziSubscriptionConfig;
//         }

//         setIsScriptLoaded(true); // Indicate that the script is loaded
//       } catch (error) {
//         console.error('Error initializing Iyzico:', error);
//       }
//     }
//   };

//   const handlePayment = () => {
//     if (!isScriptLoaded) {
//       initializeIyzico();
//     } else {
//       // Trigger the payment if scripts are already loaded
//       if (typeof window.iyziInit !== 'undefined') {
//         window.iyziInit.createTag();
//       } else {
//         alert('Iyzico payment scripts are not loaded yet.');
//       }
//     }
//   };

//   return (
//     <div>
//       <h1>Payment Page</h1>
//       <button onClick={handlePayment}>Pay with Iyzico</button>
//     </div>
//   );
// };

// export default IyzicoPayment;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Modal, Form, Button, Table } from 'react-bootstrap';
// import './FileManager.css'

// const FileManager = () => {
//   const video_id = 270;
//   const access_token = localStorage.getItem("access_token");

//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${access_token}`,
//     Accept: "application/json",
//     "Access-Control-Allow-Origin": "*",
//   };

//   const [allCurrencylistfromappi, setAllCurrencylistfromappi] = useState([
//     {
//       "id": 1,
//       "country": "Albania",
//       "currencies": "Leke",
//       "code": "ALL",
//       "symbol": "Lek",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 2,
//       "country": "America",
//       "currencies": "Dollars",
//       "code": "USD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 3,
//       "country": "Afghanistan",
//       "currencies": "Afghanis",
//       "code": "AFN",
//       "symbol": "؋",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 4,
//       "country": "Argentina",
//       "currencies": "Pesos",
//       "code": "ARS",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 5,
//       "country": "Aruba",
//       "currencies": "Guilders",
//       "code": "AWG",
//       "symbol": "ƒ",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 6,
//       "country": "Australia",
//       "currencies": "Dollars",
//       "code": "AUD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 7,
//       "country": "Azerbaijan",
//       "currencies": "New Manats",
//       "code": "AZN",
//       "symbol": "ман",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 8,
//       "country": "Bahamas",
//       "currencies": "Dollars",
//       "code": "BSD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 9,
//       "country": "Barbados",
//       "currencies": "Dollars",
//       "code": "BBD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 10,
//       "country": "Belarus",
//       "currencies": "Rubles",
//       "code": "BYR",
//       "symbol": "p.",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 11,
//       "country": "Belgium",
//       "currencies": "Euro",
//       "code": "EUR",
//       "symbol": "€",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 12,
//       "country": "Beliz",
//       "currencies": "Dollars",
//       "code": "BZD",
//       "symbol": "BZ$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 13,
//       "country": "Bermuda",
//       "currencies": "Dollars",
//       "code": "BMD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 14,
//       "country": "Bolivia",
//       "currencies": "Bolivianos",
//       "code": "BOB",
//       "symbol": "$b",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 15,
//       "country": "Bosnia and Herzegovina",
//       "currencies": "Convertible Marka",
//       "code": "BAM",
//       "symbol": "KM",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 16,
//       "country": "Botswana",
//       "currencies": "Pula",
//       "code": "BWP",
//       "symbol": "P",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 17,
//       "country": "Bulgaria",
//       "currencies": "Leva",
//       "code": "BGN",
//       "symbol": "лв",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 18,
//       "country": "Brazil",
//       "currencies": "Reais",
//       "code": "BRL",
//       "symbol": "R$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 19,
//       "country": "Britain (United Kingdom)",
//       "currencies": "Pounds",
//       "code": "GBP",
//       "symbol": "£",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 20,
//       "country": "Brunei Darussalam",
//       "currencies": "Dollars",
//       "code": "BND",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 21,
//       "country": "Cambodia",
//       "currencies": "Riels",
//       "code": "KHR",
//       "symbol": "៛",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 22,
//       "country": "Canada",
//       "currencies": "Dollars",
//       "code": "CAD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 23,
//       "country": "Cayman Islands",
//       "currencies": "Dollars",
//       "code": "KYD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 24,
//       "country": "Chile",
//       "currencies": "Pesos",
//       "code": "CLP",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 25,
//       "country": "China",
//       "currencies": "Yuan Renminbi",
//       "code": "CNY",
//       "symbol": "¥",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 26,
//       "country": "Colombia",
//       "currencies": "Pesos",
//       "code": "COP",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 27,
//       "country": "Costa Rica",
//       "currencies": "Colón",
//       "code": "CRC",
//       "symbol": "₡",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 28,
//       "country": "Croatia",
//       "currencies": "Kuna",
//       "code": "HRK",
//       "symbol": "kn",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 29,
//       "country": "Cuba",
//       "currencies": "Pesos",
//       "code": "CUP",
//       "symbol": "₱",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 30,
//       "country": "Cyprus",
//       "currencies": "Euro",
//       "code": "EUR",
//       "symbol": "€",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 31,
//       "country": "Czech Republic",
//       "currencies": "Koruny",
//       "code": "CZK",
//       "symbol": "Kč",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 32,
//       "country": "Denmark",
//       "currencies": "Kroner",
//       "code": "DKK",
//       "symbol": "kr",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 33,
//       "country": "Dominican Republic",
//       "currencies": "Pesos",
//       "code": "DOP ",
//       "symbol": "RD$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 34,
//       "country": "East Caribbean",
//       "currencies": "Dollars",
//       "code": "XCD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 35,
//       "country": "Egypt",
//       "currencies": "Pounds",
//       "code": "EGP",
//       "symbol": "£",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 36,
//       "country": "El Salvador",
//       "currencies": "Colones",
//       "code": "SVC",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 37,
//       "country": "England array('id' =>United Kingdom)",
//       "currencies": "Pounds",
//       "code": "GBP",
//       "symbol": "£",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 38,
//       "country": "Euro",
//       "currencies": "Euro",
//       "code": "EUR",
//       "symbol": "€",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 39,
//       "country": "Falkland Islands",
//       "currencies": "Pounds",
//       "code": "FKP",
//       "symbol": "£",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 40,
//       "country": "Fiji",
//       "currencies": "Dollars",
//       "code": "FJD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 41,
//       "country": "France",
//       "currencies": "Euro",
//       "code": "EUR",
//       "symbol": "€",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 42,
//       "country": "Ghana",
//       "currencies": "Cedis",
//       "code": "GHC",
//       "symbol": "¢",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 43,
//       "country": "Gibraltar",
//       "currencies": "Pounds",
//       "code": "GIP",
//       "symbol": "£",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 44,
//       "country": "Greece",
//       "currencies": "Euro",
//       "code": "EUR",
//       "symbol": "€",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 45,
//       "country": "Guatemala",
//       "currencies": "Quetzales",
//       "code": "GTQ",
//       "symbol": "Q",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 46,
//       "country": "Guernsey",
//       "currencies": "Pounds",
//       "code": "GGP",
//       "symbol": "£",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 47,
//       "country": "Guyana",
//       "currencies": "Dollars",
//       "code": "GYD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 48,
//       "country": "Holland array('id' =>Netherlands)",
//       "currencies": "Euro",
//       "code": "EUR",
//       "symbol": "€",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 49,
//       "country": "Honduras",
//       "currencies": "Lempiras",
//       "code": "HNL",
//       "symbol": "L",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 50,
//       "country": "Hong Kong",
//       "currencies": "Dollars",
//       "code": "HKD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 51,
//       "country": "Hungary",
//       "currencies": "Forint",
//       "code": "HUF",
//       "symbol": "Ft",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 52,
//       "country": "Iceland",
//       "currencies": "Kronur",
//       "code": "ISK",
//       "symbol": "kr",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 53,
//       "country": "India",
//       "currencies": "Rupees",
//       "code": "INR",
//       "symbol": "₹",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 54,
//       "country": "Indonesia",
//       "currencies": "Rupiahs",
//       "code": "IDR",
//       "symbol": "Rp",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 55,
//       "country": "Iran",
//       "currencies": "Rials",
//       "code": "IRR",
//       "symbol": "﷼",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 56,
//       "country": "Ireland",
//       "currencies": "Euro",
//       "code": "EUR",
//       "symbol": "€",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 57,
//       "country": "Isle of Man",
//       "currencies": "Pounds",
//       "code": "IMP",
//       "symbol": "£",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 58,
//       "country": "Israel",
//       "currencies": "New Shekels",
//       "code": "ILS",
//       "symbol": "₪",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 59,
//       "country": "Italy",
//       "currencies": "Euro",
//       "code": "EUR",
//       "symbol": "€",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 60,
//       "country": "Jamaica",
//       "currencies": "Dollars",
//       "code": "JMD",
//       "symbol": "J$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 61,
//       "country": "Japan",
//       "currencies": "Yen",
//       "code": "JPY",
//       "symbol": "¥",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 62,
//       "country": "Jersey",
//       "currencies": "Pounds",
//       "code": "JEP",
//       "symbol": "£",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 63,
//       "country": "Kazakhstan",
//       "currencies": "Tenge",
//       "code": "KZT",
//       "symbol": "лв",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 64,
//       "country": "Korea array('id' =>North)",
//       "currencies": "Won",
//       "code": "KPW",
//       "symbol": "₩",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 65,
//       "country": "Korea array('id' =>South)",
//       "currencies": "Won",
//       "code": "KRW",
//       "symbol": "₩",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 66,
//       "country": "Kyrgyzstan",
//       "currencies": "Soms",
//       "code": "KGS",
//       "symbol": "лв",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 67,
//       "country": "Laos",
//       "currencies": "Kips",
//       "code": "LAK",
//       "symbol": "₭",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 68,
//       "country": "Latvia",
//       "currencies": "Lati",
//       "code": "LVL",
//       "symbol": "Ls",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 69,
//       "country": "Lebanon",
//       "currencies": "Pounds",
//       "code": "LBP",
//       "symbol": "£",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 70,
//       "country": "Liberia",
//       "currencies": "Dollars",
//       "code": "LRD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 71,
//       "country": "Liechtenstein",
//       "currencies": "Switzerland Francs",
//       "code": "CHF",
//       "symbol": "CHF",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 72,
//       "country": "Lithuania",
//       "currencies": "Litai",
//       "code": "LTL",
//       "symbol": "Lt",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 73,
//       "country": "Luxembourg",
//       "currencies": "Euro",
//       "code": "EUR",
//       "symbol": "€",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 74,
//       "country": "Macedonia",
//       "currencies": "Denars",
//       "code": "MKD",
//       "symbol": "ден",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 75,
//       "country": "Malaysia",
//       "currencies": "Ringgits",
//       "code": "MYR",
//       "symbol": "RM",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 76,
//       "country": "Malta",
//       "currencies": "Euro",
//       "code": "EUR",
//       "symbol": "€",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 77,
//       "country": "Mauritius",
//       "currencies": "Rupees",
//       "code": "MUR",
//       "symbol": "₨",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 78,
//       "country": "Mexico",
//       "currencies": "Pesos",
//       "code": "MXN",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 79,
//       "country": "Mongolia",
//       "currencies": "Tugriks",
//       "code": "MNT",
//       "symbol": "₮",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 80,
//       "country": "Mozambique",
//       "currencies": "Meticais",
//       "code": "MZN",
//       "symbol": "MT",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 81,
//       "country": "Namibia",
//       "currencies": "Dollars",
//       "code": "NAD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 82,
//       "country": "Nepal",
//       "currencies": "Rupees",
//       "code": "NPR",
//       "symbol": "₨",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 83,
//       "country": "Netherlands Antilles",
//       "currencies": "Guilders",
//       "code": "ANG",
//       "symbol": "ƒ",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 84,
//       "country": "Netherlands",
//       "currencies": "Euro",
//       "code": "EUR",
//       "symbol": "€",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 85,
//       "country": "New Zealand",
//       "currencies": "Dollars",
//       "code": "NZD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 86,
//       "country": "Nicaragua",
//       "currencies": "Cordobas",
//       "code": "NIO",
//       "symbol": "C$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 87,
//       "country": "Nigeria",
//       "currencies": "Nairas",
//       "code": "NGN",
//       "symbol": "₦",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 88,
//       "country": "North Korea",
//       "currencies": "Won",
//       "code": "KPW",
//       "symbol": "₩",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 89,
//       "country": "Norway",
//       "currencies": "Krone",
//       "code": "NOK",
//       "symbol": "kr",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 90,
//       "country": "Oman",
//       "currencies": "Rials",
//       "code": "OMR",
//       "symbol": "﷼",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 91,
//       "country": "Pakistan",
//       "currencies": "Rupees",
//       "code": "PKR",
//       "symbol": "₨",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 92,
//       "country": "Panama",
//       "currencies": "Balboa",
//       "code": "PAB",
//       "symbol": "B/.",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 93,
//       "country": "Paraguay",
//       "currencies": "Guarani",
//       "code": "PYG",
//       "symbol": "Gs",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 94,
//       "country": "Peru",
//       "currencies": "Nuevos Soles",
//       "code": "PEN",
//       "symbol": "S/.",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 95,
//       "country": "Philippines",
//       "currencies": "Pesos",
//       "code": "PHP",
//       "symbol": "Php",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 96,
//       "country": "Poland",
//       "currencies": "Zlotych",
//       "code": "PLN",
//       "symbol": "zł",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 97,
//       "country": "Qatar",
//       "currencies": "Rials",
//       "code": "QAR",
//       "symbol": "﷼",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 98,
//       "country": "Romania",
//       "currencies": "New Lei",
//       "code": "RON",
//       "symbol": "lei",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 99,
//       "country": "Russia",
//       "currencies": "Rubles",
//       "code": "RUB",
//       "symbol": "руб",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 100,
//       "country": "Saint Helena",
//       "currencies": "Pounds",
//       "code": "SHP",
//       "symbol": "£",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 101,
//       "country": "Saudi Arabia",
//       "currencies": "Riyals",
//       "code": "SAR",
//       "symbol": "﷼",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 102,
//       "country": "Serbia",
//       "currencies": "Dinars",
//       "code": "RSD",
//       "symbol": "Дин.",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 103,
//       "country": "Seychelles",
//       "currencies": "Rupees",
//       "code": "SCR",
//       "symbol": "₨",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 104,
//       "country": "Singapore",
//       "currencies": "Dollars",
//       "code": "SGD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 105,
//       "country": "Slovenia",
//       "currencies": "Euro",
//       "code": "EUR",
//       "symbol": "€",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 106,
//       "country": "Solomon Islands",
//       "currencies": "Dollars",
//       "code": "SBD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 107,
//       "country": "Somalia",
//       "currencies": "Shillings",
//       "code": "SOS",
//       "symbol": "S",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 108,
//       "country": "South Africa",
//       "currencies": "Rand",
//       "code": "ZAR",
//       "symbol": "R",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 109,
//       "country": "South Korea",
//       "currencies": "Won",
//       "code": "KRW",
//       "symbol": "₩",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 110,
//       "country": "Spain",
//       "currencies": "Euro",
//       "code": "EUR",
//       "symbol": "€",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 111,
//       "country": "Sri Lanka",
//       "currencies": "Rupees",
//       "code": "LKR",
//       "symbol": "₨",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 112,
//       "country": "Sweden",
//       "currencies": "Kronor",
//       "code": "SEK",
//       "symbol": "kr",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 113,
//       "country": "Switzerland",
//       "currencies": "Francs",
//       "code": "CHF",
//       "symbol": "CHF",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 114,
//       "country": "Suriname",
//       "currencies": "Dollars",
//       "code": "SRD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 115,
//       "country": "Syria",
//       "currencies": "Pounds",
//       "code": "SYP",
//       "symbol": "£",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 116,
//       "country": "Taiwan",
//       "currencies": "New Dollars",
//       "code": "TWD",
//       "symbol": "NT$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 117,
//       "country": "Thailand",
//       "currencies": "Baht",
//       "code": "THB",
//       "symbol": "฿",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 118,
//       "country": "Trinidad and Tobago",
//       "currencies": "Dollars",
//       "code": "TTD",
//       "symbol": "TT$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 119,
//       "country": "Turkey",
//       "currencies": "Lira",
//       "code": "TRY",
//       "symbol": "TL",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 120,
//       "country": "Turkey",
//       "currencies": "Liras",
//       "code": "TRL",
//       "symbol": "£",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 121,
//       "country": "Tuvalu",
//       "currencies": "Dollars",
//       "code": "TVD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 122,
//       "country": "Ukraine",
//       "currencies": "Hryvnia",
//       "code": "UAH",
//       "symbol": "₴",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 123,
//       "country": "United Kingdom",
//       "currencies": "Pounds",
//       "code": "GBP",
//       "symbol": "£",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 124,
//       "country": "United States of America",
//       "currencies": "Dollars",
//       "code": "USD",
//       "symbol": "$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 125,
//       "country": "Uruguay",
//       "currencies": "Pesos",
//       "code": "UYU",
//       "symbol": "$U",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 126,
//       "country": "Uzbekistan",
//       "currencies": "Sums",
//       "code": "UZS",
//       "symbol": "лв",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 127,
//       "country": "Vatican City",
//       "currencies": "Euro",
//       "code": "EUR",
//       "symbol": "€",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 128,
//       "country": "Venezuela",
//       "currencies": "Bolivares Fuertes",
//       "code": "VEF",
//       "symbol": "Bs",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 129,
//       "country": "Vietnam",
//       "currencies": "Dong",
//       "code": "VND",
//       "symbol": "₫",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 130,
//       "country": "Yemen",
//       "currencies": "Rials",
//       "code": "YER",
//       "symbol": "﷼",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 131,
//       "country": "Zimbabwe",
//       "currencies": "Zimbabwe Dollars",
//       "code": "ZWD",
//       "symbol": "Z$",
//       "created_at": null,
//       "updated_at": null
//     },
//     {
//       "id": 132,
//       "country": "India",
//       "currencies": "Rupees",
//       "code": "INR",
//       "symbol": "₹",
//       "created_at": null,
//       "updated_at": null
//     }
//   ]);

//   const [allCurrencyglobal, setAllCurrencyglobal] = useState([
//     {
//       "id": 812,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "America",
//       "Code": "USD",
//       "ConvertedPriceRate": "5678.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "567845.00",
//       "RateDifference": "0.00",
//       "Symbol": "$",
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 813,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "AED",
//       "ConvertedPriceRate": "20838.26",
//       "ActualPrice": "5678",
//       "ChangedPrice": "20838.26",
//       "RateDifference": "15160.26",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 814,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "AFN",
//       "ConvertedPriceRate": "402002.40",
//       "ActualPrice": "5678",
//       "ChangedPrice": "402002.40",
//       "RateDifference": "396324.40",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 815,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ALL",
//       "ConvertedPriceRate": "522943.80",
//       "ActualPrice": "5678",
//       "ChangedPrice": "522943.80",
//       "RateDifference": "517265.80",
//       "Symbol": "Lek",
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 816,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "AMD",
//       "ConvertedPriceRate": "2204597.06",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2204597.06",
//       "RateDifference": "2198919.06",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 817,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ANG",
//       "ConvertedPriceRate": "10163.62",
//       "ActualPrice": "5678",
//       "ChangedPrice": "10163.62",
//       "RateDifference": "4485.62",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 818,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "AOA",
//       "ConvertedPriceRate": "5011232.46",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5011232.46",
//       "RateDifference": "5005554.46",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 819,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ARS",
//       "ConvertedPriceRate": "5262086.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5262086.50",
//       "RateDifference": "5256408.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 820,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "AUD",
//       "ConvertedPriceRate": "8460.22",
//       "ActualPrice": "5678",
//       "ChangedPrice": "8460.22",
//       "RateDifference": "2782.22",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 821,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "AWG",
//       "ConvertedPriceRate": "10163.62",
//       "ActualPrice": "5678",
//       "ChangedPrice": "10163.62",
//       "RateDifference": "4485.62",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 822,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "AZN",
//       "ConvertedPriceRate": "9652.60",
//       "ActualPrice": "5678",
//       "ChangedPrice": "9652.60",
//       "RateDifference": "3974.60",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 823,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BAM",
//       "ConvertedPriceRate": "10220.40",
//       "ActualPrice": "5678",
//       "ChangedPrice": "10220.40",
//       "RateDifference": "4542.40",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 824,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BBD",
//       "ConvertedPriceRate": "11356.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "11356.00",
//       "RateDifference": "5678.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 825,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BDT",
//       "ConvertedPriceRate": "667221.78",
//       "ActualPrice": "5678",
//       "ChangedPrice": "667221.78",
//       "RateDifference": "661543.78",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 826,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BGN",
//       "ConvertedPriceRate": "10220.40",
//       "ActualPrice": "5678",
//       "ChangedPrice": "10220.40",
//       "RateDifference": "4542.40",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 827,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BHD",
//       "ConvertedPriceRate": "2134.93",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2134.93",
//       "RateDifference": "-3543.07",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 828,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BIF",
//       "ConvertedPriceRate": "16354513.74",
//       "ActualPrice": "5678",
//       "ChangedPrice": "16354513.74",
//       "RateDifference": "16348835.74",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 829,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BMD",
//       "ConvertedPriceRate": "5678.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5678.00",
//       "RateDifference": "0.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 830,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BND",
//       "ConvertedPriceRate": "7608.52",
//       "ActualPrice": "5678",
//       "ChangedPrice": "7608.52",
//       "RateDifference": "1930.52",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 831,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BOB",
//       "ConvertedPriceRate": "39291.76",
//       "ActualPrice": "5678",
//       "ChangedPrice": "39291.76",
//       "RateDifference": "33613.76",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 832,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BRL",
//       "ConvertedPriceRate": "31456.12",
//       "ActualPrice": "5678",
//       "ChangedPrice": "31456.12",
//       "RateDifference": "25778.12",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 833,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BSD",
//       "ConvertedPriceRate": "5678.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5678.00",
//       "RateDifference": "0.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 834,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BTN",
//       "ConvertedPriceRate": "475532.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "475532.50",
//       "RateDifference": "469854.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 835,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BWP",
//       "ConvertedPriceRate": "76823.34",
//       "ActualPrice": "5678",
//       "ChangedPrice": "76823.34",
//       "RateDifference": "71145.34",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 836,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BYN",
//       "ConvertedPriceRate": "18510.28",
//       "ActualPrice": "5678",
//       "ChangedPrice": "18510.28",
//       "RateDifference": "12832.28",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 837,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BZD",
//       "ConvertedPriceRate": "11356.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "11356.00",
//       "RateDifference": "5678.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 838,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CAD",
//       "ConvertedPriceRate": "7778.86",
//       "ActualPrice": "5678",
//       "ChangedPrice": "7778.86",
//       "RateDifference": "2100.86",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 839,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CDF",
//       "ConvertedPriceRate": "16073168.84",
//       "ActualPrice": "5678",
//       "ChangedPrice": "16073168.84",
//       "RateDifference": "16067490.84",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 840,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CHF",
//       "ConvertedPriceRate": "5047.74",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5047.74",
//       "RateDifference": "-630.26",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 841,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CLP",
//       "ConvertedPriceRate": "5350379.40",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5350379.40",
//       "RateDifference": "5344701.40",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 842,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CNY",
//       "ConvertedPriceRate": "41335.84",
//       "ActualPrice": "5678",
//       "ChangedPrice": "41335.84",
//       "RateDifference": "35657.84",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 843,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "COP",
//       "ConvertedPriceRate": "22880125.58",
//       "ActualPrice": "5678",
//       "ChangedPrice": "22880125.58",
//       "RateDifference": "22874447.58",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 844,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CRC",
//       "ConvertedPriceRate": "2992873.80",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2992873.80",
//       "RateDifference": "2987195.80",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 845,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CUP",
//       "ConvertedPriceRate": "136272.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "136272.00",
//       "RateDifference": "130594.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 846,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CVE",
//       "ConvertedPriceRate": "574897.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "574897.50",
//       "RateDifference": "569219.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 847,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CZK",
//       "ConvertedPriceRate": "131559.26",
//       "ActualPrice": "5678",
//       "ChangedPrice": "131559.26",
//       "RateDifference": "125881.26",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 848,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "DJF",
//       "ConvertedPriceRate": "1009094.16",
//       "ActualPrice": "5678",
//       "ChangedPrice": "1009094.16",
//       "RateDifference": "1003416.16",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 849,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "DKK",
//       "ConvertedPriceRate": "38894.30",
//       "ActualPrice": "5678",
//       "ChangedPrice": "38894.30",
//       "RateDifference": "33216.30",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 850,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "DOP",
//       "ConvertedPriceRate": "335342.68",
//       "ActualPrice": "5678",
//       "ChangedPrice": "335342.68",
//       "RateDifference": "329664.68",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 851,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "DZD",
//       "ConvertedPriceRate": "764372.36",
//       "ActualPrice": "5678",
//       "ChangedPrice": "764372.36",
//       "RateDifference": "758694.36",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 852,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "EGP",
//       "ConvertedPriceRate": "274474.52",
//       "ActualPrice": "5678",
//       "ChangedPrice": "274474.52",
//       "RateDifference": "268796.52",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 853,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ERN",
//       "ConvertedPriceRate": "85170.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "85170.00",
//       "RateDifference": "79492.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 854,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ETB",
//       "ConvertedPriceRate": "328188.40",
//       "ActualPrice": "5678",
//       "ChangedPrice": "328188.40",
//       "RateDifference": "322510.40",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 855,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "EUR",
//       "ConvertedPriceRate": "5212.40",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5212.40",
//       "RateDifference": "-465.60",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 856,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "FJD",
//       "ConvertedPriceRate": "12661.94",
//       "ActualPrice": "5678",
//       "ChangedPrice": "12661.94",
//       "RateDifference": "6983.94",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 857,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "FKP",
//       "ConvertedPriceRate": "4394.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4394.77",
//       "RateDifference": "-1283.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 858,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "FOK",
//       "ConvertedPriceRate": "38894.30",
//       "ActualPrice": "5678",
//       "ChangedPrice": "38894.30",
//       "RateDifference": "33216.30",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 859,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GBP",
//       "ConvertedPriceRate": "4394.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4394.77",
//       "RateDifference": "-1283.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 860,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GEL",
//       "ConvertedPriceRate": "15330.60",
//       "ActualPrice": "5678",
//       "ChangedPrice": "15330.60",
//       "RateDifference": "9652.60",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 861,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GGP",
//       "ConvertedPriceRate": "4394.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4394.77",
//       "RateDifference": "-1283.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 862,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GHS",
//       "ConvertedPriceRate": "87781.88",
//       "ActualPrice": "5678",
//       "ChangedPrice": "87781.88",
//       "RateDifference": "82103.88",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 863,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GIP",
//       "ConvertedPriceRate": "4394.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4394.77",
//       "RateDifference": "-1283.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 864,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GMD",
//       "ConvertedPriceRate": "387069.26",
//       "ActualPrice": "5678",
//       "ChangedPrice": "387069.26",
//       "RateDifference": "381391.26",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 865,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GNF",
//       "ConvertedPriceRate": "49418359.44",
//       "ActualPrice": "5678",
//       "ChangedPrice": "49418359.44",
//       "RateDifference": "49412681.44",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 866,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GTQ",
//       "ConvertedPriceRate": "44004.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "44004.50",
//       "RateDifference": "38326.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 867,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GYD",
//       "ConvertedPriceRate": "1187724.04",
//       "ActualPrice": "5678",
//       "ChangedPrice": "1187724.04",
//       "RateDifference": "1182046.04",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 868,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "HKD",
//       "ConvertedPriceRate": "44345.18",
//       "ActualPrice": "5678",
//       "ChangedPrice": "44345.18",
//       "RateDifference": "38667.18",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 869,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "HNL",
//       "ConvertedPriceRate": "140530.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "140530.50",
//       "RateDifference": "134852.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 870,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "HRK",
//       "ConvertedPriceRate": "39291.76",
//       "ActualPrice": "5678",
//       "ChangedPrice": "39291.76",
//       "RateDifference": "33613.76",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 871,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "HTG",
//       "ConvertedPriceRate": "748473.96",
//       "ActualPrice": "5678",
//       "ChangedPrice": "748473.96",
//       "RateDifference": "742795.96",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 872,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "HUF",
//       "ConvertedPriceRate": "2035676.56",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2035676.56",
//       "RateDifference": "2029998.56",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 873,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "IDR",
//       "ConvertedPriceRate": "92009889.14",
//       "ActualPrice": "5678",
//       "ChangedPrice": "92009889.14",
//       "RateDifference": "92004211.14",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 874,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ILS",
//       "ConvertedPriceRate": "20667.92",
//       "ActualPrice": "5678",
//       "ChangedPrice": "20667.92",
//       "RateDifference": "14989.92",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 875,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "IMP",
//       "ConvertedPriceRate": "4394.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4394.77",
//       "RateDifference": "-1283.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 876,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "INR",
//       "ConvertedPriceRate": "475532.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "475532.50",
//       "RateDifference": "469854.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 877,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "IQD",
//       "ConvertedPriceRate": "7446810.56",
//       "ActualPrice": "5678",
//       "ChangedPrice": "7446810.56",
//       "RateDifference": "7441132.56",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 878,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "IRR",
//       "ConvertedPriceRate": "238730942.20",
//       "ActualPrice": "5678",
//       "ChangedPrice": "238730942.20",
//       "RateDifference": "238725264.20",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 879,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ISK",
//       "ConvertedPriceRate": "781008.90",
//       "ActualPrice": "5678",
//       "ChangedPrice": "781008.90",
//       "RateDifference": "775330.90",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 880,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "JEP",
//       "ConvertedPriceRate": "4394.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4394.77",
//       "RateDifference": "-1283.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 881,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "JMD",
//       "ConvertedPriceRate": "887755.30",
//       "ActualPrice": "5678",
//       "ChangedPrice": "887755.30",
//       "RateDifference": "882077.30",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 882,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "JOD",
//       "ConvertedPriceRate": "4025.70",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4025.70",
//       "RateDifference": "-1652.30",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 883,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "JPY",
//       "ConvertedPriceRate": "894171.44",
//       "ActualPrice": "5678",
//       "ChangedPrice": "894171.44",
//       "RateDifference": "888493.44",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 884,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KES",
//       "ConvertedPriceRate": "742455.28",
//       "ActualPrice": "5678",
//       "ChangedPrice": "742455.28",
//       "RateDifference": "736777.28",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 885,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KGS",
//       "ConvertedPriceRate": "485469.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "485469.00",
//       "RateDifference": "479791.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 886,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KHR",
//       "ConvertedPriceRate": "23439011.12",
//       "ActualPrice": "5678",
//       "ChangedPrice": "23439011.12",
//       "RateDifference": "23433333.12",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 887,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KID",
//       "ConvertedPriceRate": "8460.22",
//       "ActualPrice": "5678",
//       "ChangedPrice": "8460.22",
//       "RateDifference": "2782.22",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 888,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KMF",
//       "ConvertedPriceRate": "2565036.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2565036.50",
//       "RateDifference": "2559358.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 889,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KRW",
//       "ConvertedPriceRate": "7885947.08",
//       "ActualPrice": "5678",
//       "ChangedPrice": "7885947.08",
//       "RateDifference": "7880269.08",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 890,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KWD",
//       "ConvertedPriceRate": "1737.47",
//       "ActualPrice": "5678",
//       "ChangedPrice": "1737.47",
//       "RateDifference": "-3940.53",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 891,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KYD",
//       "ConvertedPriceRate": "4729.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4729.77",
//       "RateDifference": "-948.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 892,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KZT",
//       "ConvertedPriceRate": "2702273.76",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2702273.76",
//       "RateDifference": "2696595.76",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 893,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "LAK",
//       "ConvertedPriceRate": "124343714.38",
//       "ActualPrice": "5678",
//       "ChangedPrice": "124343714.38",
//       "RateDifference": "124338036.38",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 894,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "LBP",
//       "ConvertedPriceRate": "508181000.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "508181000.00",
//       "RateDifference": "508175322.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 895,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "LKR",
//       "ConvertedPriceRate": "1721967.06",
//       "ActualPrice": "5678",
//       "ChangedPrice": "1721967.06",
//       "RateDifference": "1716289.06",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 896,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "LRD",
//       "ConvertedPriceRate": "1106755.76",
//       "ActualPrice": "5678",
//       "ChangedPrice": "1106755.76",
//       "RateDifference": "1101077.76",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 897,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "LSL",
//       "ConvertedPriceRate": "103509.94",
//       "ActualPrice": "5678",
//       "ChangedPrice": "103509.94",
//       "RateDifference": "97831.94",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 898,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "LYD",
//       "ConvertedPriceRate": "27367.96",
//       "ActualPrice": "5678",
//       "ChangedPrice": "27367.96",
//       "RateDifference": "21689.96",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 899,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MAD",
//       "ConvertedPriceRate": "55871.52",
//       "ActualPrice": "5678",
//       "ChangedPrice": "55871.52",
//       "RateDifference": "50193.52",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 900,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MDL",
//       "ConvertedPriceRate": "100387.04",
//       "ActualPrice": "5678",
//       "ChangedPrice": "100387.04",
//       "RateDifference": "94709.04",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 901,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MGA",
//       "ConvertedPriceRate": "25526925.28",
//       "ActualPrice": "5678",
//       "ChangedPrice": "25526925.28",
//       "RateDifference": "25521247.28",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 902,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MKD",
//       "ConvertedPriceRate": "320523.10",
//       "ActualPrice": "5678",
//       "ChangedPrice": "320523.10",
//       "RateDifference": "314845.10",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 903,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MMK",
//       "ConvertedPriceRate": "11936461.94",
//       "ActualPrice": "5678",
//       "ChangedPrice": "11936461.94",
//       "RateDifference": "11930783.94",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 904,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MNT",
//       "ConvertedPriceRate": "19255347.16",
//       "ActualPrice": "5678",
//       "ChangedPrice": "19255347.16",
//       "RateDifference": "19249669.16",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 905,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MOP",
//       "ConvertedPriceRate": "45651.12",
//       "ActualPrice": "5678",
//       "ChangedPrice": "45651.12",
//       "RateDifference": "39973.12",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 906,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MRU",
//       "ConvertedPriceRate": "225814.06",
//       "ActualPrice": "5678",
//       "ChangedPrice": "225814.06",
//       "RateDifference": "220136.06",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 907,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MUR",
//       "ConvertedPriceRate": "264538.02",
//       "ActualPrice": "5678",
//       "ChangedPrice": "264538.02",
//       "RateDifference": "258860.02",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 908,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MVR",
//       "ConvertedPriceRate": "87611.54",
//       "ActualPrice": "5678",
//       "ChangedPrice": "87611.54",
//       "RateDifference": "81933.54",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 909,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MWK",
//       "ConvertedPriceRate": "9870578.42",
//       "ActualPrice": "5678",
//       "ChangedPrice": "9870578.42",
//       "RateDifference": "9864900.42",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 910,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MXN",
//       "ConvertedPriceRate": "102147.22",
//       "ActualPrice": "5678",
//       "ChangedPrice": "102147.22",
//       "RateDifference": "96469.22",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 911,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MYR",
//       "ConvertedPriceRate": "26629.82",
//       "ActualPrice": "5678",
//       "ChangedPrice": "26629.82",
//       "RateDifference": "20951.82",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 912,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MZN",
//       "ConvertedPriceRate": "362710.64",
//       "ActualPrice": "5678",
//       "ChangedPrice": "362710.64",
//       "RateDifference": "357032.64",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 913,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "NAD",
//       "ConvertedPriceRate": "103509.94",
//       "ActualPrice": "5678",
//       "ChangedPrice": "103509.94",
//       "RateDifference": "97831.94",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 914,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "NGN",
//       "ConvertedPriceRate": "9053173.54",
//       "ActualPrice": "5678",
//       "ChangedPrice": "9053173.54",
//       "RateDifference": "9047495.54",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 915,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "NIO",
//       "ConvertedPriceRate": "208893.62",
//       "ActualPrice": "5678",
//       "ChangedPrice": "208893.62",
//       "RateDifference": "203215.62",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 916,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "NOK",
//       "ConvertedPriceRate": "61833.42",
//       "ActualPrice": "5678",
//       "ChangedPrice": "61833.42",
//       "RateDifference": "56155.42",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 917,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "NPR",
//       "ConvertedPriceRate": "760908.78",
//       "ActualPrice": "5678",
//       "ChangedPrice": "760908.78",
//       "RateDifference": "755230.78",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 918,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "NZD",
//       "ConvertedPriceRate": "9425.48",
//       "ActualPrice": "5678",
//       "ChangedPrice": "9425.48",
//       "RateDifference": "3747.48",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 919,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "OMR",
//       "ConvertedPriceRate": "2180.35",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2180.35",
//       "RateDifference": "-3497.65",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 920,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "PAB",
//       "ConvertedPriceRate": "5678.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5678.00",
//       "RateDifference": "0.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 921,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "PEN",
//       "ConvertedPriceRate": "21235.72",
//       "ActualPrice": "5678",
//       "ChangedPrice": "21235.72",
//       "RateDifference": "15557.72",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 922,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "PGK",
//       "ConvertedPriceRate": "22087.42",
//       "ActualPrice": "5678",
//       "ChangedPrice": "22087.42",
//       "RateDifference": "16409.42",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 923,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "PHP",
//       "ConvertedPriceRate": "331368.08",
//       "ActualPrice": "5678",
//       "ChangedPrice": "331368.08",
//       "RateDifference": "325690.08",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 924,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "PKR",
//       "ConvertedPriceRate": "1578484.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "1578484.00",
//       "RateDifference": "1572806.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 925,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "PLN",
//       "ConvertedPriceRate": "22371.32",
//       "ActualPrice": "5678",
//       "ChangedPrice": "22371.32",
//       "RateDifference": "16693.32",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 926,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "PYG",
//       "ConvertedPriceRate": "43075352.08",
//       "ActualPrice": "5678",
//       "ChangedPrice": "43075352.08",
//       "RateDifference": "43069674.08",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 927,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "QAR",
//       "ConvertedPriceRate": "20667.92",
//       "ActualPrice": "5678",
//       "ChangedPrice": "20667.92",
//       "RateDifference": "14989.92",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 928,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "RON",
//       "ConvertedPriceRate": "25948.46",
//       "ActualPrice": "5678",
//       "ChangedPrice": "25948.46",
//       "RateDifference": "20270.46",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 929,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "RSD",
//       "ConvertedPriceRate": "610498.56",
//       "ActualPrice": "5678",
//       "ChangedPrice": "610498.56",
//       "RateDifference": "604820.56",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 930,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "RUB",
//       "ConvertedPriceRate": "498187.72",
//       "ActualPrice": "5678",
//       "ChangedPrice": "498187.72",
//       "RateDifference": "492509.72",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 931,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "RWF",
//       "ConvertedPriceRate": "7470431.04",
//       "ActualPrice": "5678",
//       "ChangedPrice": "7470431.04",
//       "RateDifference": "7464753.04",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 932,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SAR",
//       "ConvertedPriceRate": "21292.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "21292.50",
//       "RateDifference": "15614.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 933,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SBD",
//       "ConvertedPriceRate": "48319.78",
//       "ActualPrice": "5678",
//       "ChangedPrice": "48319.78",
//       "RateDifference": "42641.78",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 934,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SCR",
//       "ConvertedPriceRate": "78980.98",
//       "ActualPrice": "5678",
//       "ChangedPrice": "78980.98",
//       "RateDifference": "73302.98",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 935,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SDG",
//       "ConvertedPriceRate": "2602738.42",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2602738.42",
//       "RateDifference": "2597060.42",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 936,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SEK",
//       "ConvertedPriceRate": "60527.48",
//       "ActualPrice": "5678",
//       "ChangedPrice": "60527.48",
//       "RateDifference": "54849.48",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 937,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SGD",
//       "ConvertedPriceRate": "7608.52",
//       "ActualPrice": "5678",
//       "ChangedPrice": "7608.52",
//       "RateDifference": "1930.52",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 938,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SHP",
//       "ConvertedPriceRate": "4394.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4394.77",
//       "RateDifference": "-1283.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 939,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SLE",
//       "ConvertedPriceRate": "133035.54",
//       "ActualPrice": "5678",
//       "ChangedPrice": "133035.54",
//       "RateDifference": "127357.54",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 940,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SLL",
//       "ConvertedPriceRate": "133036675.60",
//       "ActualPrice": "5678",
//       "ChangedPrice": "133036675.60",
//       "RateDifference": "133030997.60",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 941,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SOS",
//       "ConvertedPriceRate": "3243614.28",
//       "ActualPrice": "5678",
//       "ChangedPrice": "3243614.28",
//       "RateDifference": "3237936.28",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 942,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SRD",
//       "ConvertedPriceRate": "167217.10",
//       "ActualPrice": "5678",
//       "ChangedPrice": "167217.10",
//       "RateDifference": "161539.10",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 943,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SSP",
//       "ConvertedPriceRate": "11396938.38",
//       "ActualPrice": "5678",
//       "ChangedPrice": "11396938.38",
//       "RateDifference": "11391260.38",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 944,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "STN",
//       "ConvertedPriceRate": "127755.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "127755.00",
//       "RateDifference": "122077.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 945,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SYP",
//       "ConvertedPriceRate": "73023452.06",
//       "ActualPrice": "5678",
//       "ChangedPrice": "73023452.06",
//       "RateDifference": "73017774.06",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 946,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SZL",
//       "ConvertedPriceRate": "103509.94",
//       "ActualPrice": "5678",
//       "ChangedPrice": "103509.94",
//       "RateDifference": "97831.94",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 947,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "THB",
//       "ConvertedPriceRate": "206168.18",
//       "ActualPrice": "5678",
//       "ChangedPrice": "206168.18",
//       "RateDifference": "200490.18",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 948,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TJS",
//       "ConvertedPriceRate": "60584.26",
//       "ActualPrice": "5678",
//       "ChangedPrice": "60584.26",
//       "RateDifference": "54906.26",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 949,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TMT",
//       "ConvertedPriceRate": "19873.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "19873.00",
//       "RateDifference": "14195.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 950,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TND",
//       "ConvertedPriceRate": "17601.80",
//       "ActualPrice": "5678",
//       "ChangedPrice": "17601.80",
//       "RateDifference": "11923.80",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 951,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TOP",
//       "ConvertedPriceRate": "13229.74",
//       "ActualPrice": "5678",
//       "ChangedPrice": "13229.74",
//       "RateDifference": "7551.74",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 952,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TRY",
//       "ConvertedPriceRate": "187941.80",
//       "ActualPrice": "5678",
//       "ChangedPrice": "187941.80",
//       "RateDifference": "182263.80",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 953,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TTD",
//       "ConvertedPriceRate": "38440.06",
//       "ActualPrice": "5678",
//       "ChangedPrice": "38440.06",
//       "RateDifference": "32762.06",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 954,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TVD",
//       "ConvertedPriceRate": "8460.22",
//       "ActualPrice": "5678",
//       "ChangedPrice": "8460.22",
//       "RateDifference": "2782.22",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 955,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TWD",
//       "ConvertedPriceRate": "186124.84",
//       "ActualPrice": "5678",
//       "ChangedPrice": "186124.84",
//       "RateDifference": "180446.84",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 956,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TZS",
//       "ConvertedPriceRate": "15172467.70",
//       "ActualPrice": "5678",
//       "ChangedPrice": "15172467.70",
//       "RateDifference": "15166789.70",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 957,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "UAH",
//       "ConvertedPriceRate": "235580.22",
//       "ActualPrice": "5678",
//       "ChangedPrice": "235580.22",
//       "RateDifference": "229902.22",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 958,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "UGX",
//       "ConvertedPriceRate": "20923941.02",
//       "ActualPrice": "5678",
//       "ChangedPrice": "20923941.02",
//       "RateDifference": "20918263.02",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 959,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "UYU",
//       "ConvertedPriceRate": "228709.84",
//       "ActualPrice": "5678",
//       "ChangedPrice": "228709.84",
//       "RateDifference": "223031.84",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 960,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "UZS",
//       "ConvertedPriceRate": "71314260.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "71314260.50",
//       "RateDifference": "71308582.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 961,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "VES",
//       "ConvertedPriceRate": "207871.58",
//       "ActualPrice": "5678",
//       "ChangedPrice": "207871.58",
//       "RateDifference": "202193.58",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 962,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "VND",
//       "ConvertedPriceRate": "143889604.80",
//       "ActualPrice": "5678",
//       "ChangedPrice": "143889604.80",
//       "RateDifference": "143883926.80",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 963,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "VUV",
//       "ConvertedPriceRate": "673694.70",
//       "ActualPrice": "5678",
//       "ChangedPrice": "673694.70",
//       "RateDifference": "668016.70",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 964,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "WST",
//       "ConvertedPriceRate": "15557.72",
//       "ActualPrice": "5678",
//       "ChangedPrice": "15557.72",
//       "RateDifference": "9879.72",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 965,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "XAF",
//       "ConvertedPriceRate": "3420086.52",
//       "ActualPrice": "5678",
//       "ChangedPrice": "3420086.52",
//       "RateDifference": "3414408.52",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 966,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "XCD",
//       "ConvertedPriceRate": "15330.60",
//       "ActualPrice": "5678",
//       "ChangedPrice": "15330.60",
//       "RateDifference": "9652.60",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 967,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "XDR",
//       "ConvertedPriceRate": "4286.89",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4286.89",
//       "RateDifference": "-1391.11",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 968,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "XOF",
//       "ConvertedPriceRate": "3420086.52",
//       "ActualPrice": "5678",
//       "ChangedPrice": "3420086.52",
//       "RateDifference": "3414408.52",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 969,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "XPF",
//       "ConvertedPriceRate": "622195.24",
//       "ActualPrice": "5678",
//       "ChangedPrice": "622195.24",
//       "RateDifference": "616517.24",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 970,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "YER",
//       "ConvertedPriceRate": "1419783.90",
//       "ActualPrice": "5678",
//       "ChangedPrice": "1419783.90",
//       "RateDifference": "1414105.90",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 971,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ZAR",
//       "ConvertedPriceRate": "103509.94",
//       "ActualPrice": "5678",
//       "ChangedPrice": "103509.94",
//       "RateDifference": "97831.94",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 972,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ZMW",
//       "ConvertedPriceRate": "145186.46",
//       "ActualPrice": "5678",
//       "ChangedPrice": "145186.46",
//       "RateDifference": "139508.46",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 973,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ZWL",
//       "ConvertedPriceRate": "77902.16",
//       "ActualPrice": "5678",
//       "ChangedPrice": "77902.16",
//       "RateDifference": "72224.16",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     }
//   ]);

//   const [allCurrencycustoms, setAllCurrencycustoms] = useState([
//     {
//       "id": 812,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "America",
//       "Code": "USD",
//       "ConvertedPriceRate": "5678.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "567822.00",
//       "RateDifference": "0.00",
//       "Symbol": "$",
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 813,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ADDada",
//       "Code": "AED",
//       "ConvertedPriceRate": "20838.26",
//       "ActualPrice": "5678",
//       "ChangedPrice": "20838.26",
//       "RateDifference": "15160.26",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 814,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "AFN",
//       "ConvertedPriceRate": "402002.40",
//       "ActualPrice": "5678",
//       "ChangedPrice": "402002.40",
//       "RateDifference": "396324.40",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 815,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ALL",
//       "ConvertedPriceRate": "522943.80",
//       "ActualPrice": "5678",
//       "ChangedPrice": "522943.80",
//       "RateDifference": "517265.80",
//       "Symbol": "Lek",
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 816,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "AMD",
//       "ConvertedPriceRate": "2204597.06",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2204597.06",
//       "RateDifference": "2198919.06",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 817,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ANG",
//       "ConvertedPriceRate": "10163.62",
//       "ActualPrice": "5678",
//       "ChangedPrice": "10163.62",
//       "RateDifference": "4485.62",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 818,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "AOA",
//       "ConvertedPriceRate": "5011232.46",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5011232.46",
//       "RateDifference": "5005554.46",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 819,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ARS",
//       "ConvertedPriceRate": "5262086.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5262086.50",
//       "RateDifference": "5256408.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 820,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "AUD",
//       "ConvertedPriceRate": "8460.22",
//       "ActualPrice": "5678",
//       "ChangedPrice": "8460.22",
//       "RateDifference": "2782.22",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 821,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "AWG",
//       "ConvertedPriceRate": "10163.62",
//       "ActualPrice": "5678",
//       "ChangedPrice": "10163.62",
//       "RateDifference": "4485.62",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 822,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "AZN",
//       "ConvertedPriceRate": "9652.60",
//       "ActualPrice": "5678",
//       "ChangedPrice": "9652.60",
//       "RateDifference": "3974.60",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 823,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BAM",
//       "ConvertedPriceRate": "10220.40",
//       "ActualPrice": "5678",
//       "ChangedPrice": "10220.40",
//       "RateDifference": "4542.40",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 824,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BBD",
//       "ConvertedPriceRate": "11356.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "11356.00",
//       "RateDifference": "5678.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 825,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BDT",
//       "ConvertedPriceRate": "667221.78",
//       "ActualPrice": "5678",
//       "ChangedPrice": "667221.78",
//       "RateDifference": "661543.78",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 826,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BGN",
//       "ConvertedPriceRate": "10220.40",
//       "ActualPrice": "5678",
//       "ChangedPrice": "10220.40",
//       "RateDifference": "4542.40",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 827,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BHD",
//       "ConvertedPriceRate": "2134.93",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2134.93",
//       "RateDifference": "-3543.07",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 828,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BIF",
//       "ConvertedPriceRate": "16354513.74",
//       "ActualPrice": "5678",
//       "ChangedPrice": "16354513.74",
//       "RateDifference": "16348835.74",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 829,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BMD",
//       "ConvertedPriceRate": "5678.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5678.00",
//       "RateDifference": "0.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 830,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BND",
//       "ConvertedPriceRate": "7608.52",
//       "ActualPrice": "5678",
//       "ChangedPrice": "7608.52",
//       "RateDifference": "1930.52",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 831,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BOB",
//       "ConvertedPriceRate": "39291.76",
//       "ActualPrice": "5678",
//       "ChangedPrice": "39291.76",
//       "RateDifference": "33613.76",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 832,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BRL",
//       "ConvertedPriceRate": "31456.12",
//       "ActualPrice": "5678",
//       "ChangedPrice": "31456.12",
//       "RateDifference": "25778.12",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 833,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BSD",
//       "ConvertedPriceRate": "5678.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5678.00",
//       "RateDifference": "0.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 834,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BTN",
//       "ConvertedPriceRate": "475532.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "475532.50",
//       "RateDifference": "469854.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 835,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BWP",
//       "ConvertedPriceRate": "76823.34",
//       "ActualPrice": "5678",
//       "ChangedPrice": "76823.34",
//       "RateDifference": "71145.34",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 836,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BYN",
//       "ConvertedPriceRate": "18510.28",
//       "ActualPrice": "5678",
//       "ChangedPrice": "18510.28",
//       "RateDifference": "12832.28",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 837,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "BZD",
//       "ConvertedPriceRate": "11356.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "11356.00",
//       "RateDifference": "5678.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 838,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CAD",
//       "ConvertedPriceRate": "7778.86",
//       "ActualPrice": "5678",
//       "ChangedPrice": "7778.86",
//       "RateDifference": "2100.86",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 839,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CDF",
//       "ConvertedPriceRate": "16073168.84",
//       "ActualPrice": "5678",
//       "ChangedPrice": "16073168.84",
//       "RateDifference": "16067490.84",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 840,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CHF",
//       "ConvertedPriceRate": "5047.74",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5047.74",
//       "RateDifference": "-630.26",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 841,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CLP",
//       "ConvertedPriceRate": "5350379.40",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5350379.40",
//       "RateDifference": "5344701.40",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 842,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CNY",
//       "ConvertedPriceRate": "41335.84",
//       "ActualPrice": "5678",
//       "ChangedPrice": "41335.84",
//       "RateDifference": "35657.84",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 843,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "COP",
//       "ConvertedPriceRate": "22880125.58",
//       "ActualPrice": "5678",
//       "ChangedPrice": "22880125.58",
//       "RateDifference": "22874447.58",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 844,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CRC",
//       "ConvertedPriceRate": "2992873.80",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2992873.80",
//       "RateDifference": "2987195.80",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 845,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CUP",
//       "ConvertedPriceRate": "136272.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "136272.00",
//       "RateDifference": "130594.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 846,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CVE",
//       "ConvertedPriceRate": "574897.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "574897.50",
//       "RateDifference": "569219.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 847,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "CZK",
//       "ConvertedPriceRate": "131559.26",
//       "ActualPrice": "5678",
//       "ChangedPrice": "131559.26",
//       "RateDifference": "125881.26",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 848,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "DJF",
//       "ConvertedPriceRate": "1009094.16",
//       "ActualPrice": "5678",
//       "ChangedPrice": "1009094.16",
//       "RateDifference": "1003416.16",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 849,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "DKK",
//       "ConvertedPriceRate": "38894.30",
//       "ActualPrice": "5678",
//       "ChangedPrice": "38894.30",
//       "RateDifference": "33216.30",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 850,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "DOP",
//       "ConvertedPriceRate": "335342.68",
//       "ActualPrice": "5678",
//       "ChangedPrice": "335342.68",
//       "RateDifference": "329664.68",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 851,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "DZD",
//       "ConvertedPriceRate": "764372.36",
//       "ActualPrice": "5678",
//       "ChangedPrice": "764372.36",
//       "RateDifference": "758694.36",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 852,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "EGP",
//       "ConvertedPriceRate": "274474.52",
//       "ActualPrice": "5678",
//       "ChangedPrice": "274474.52",
//       "RateDifference": "268796.52",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 853,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ERN",
//       "ConvertedPriceRate": "85170.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "85170.00",
//       "RateDifference": "79492.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 854,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ETB",
//       "ConvertedPriceRate": "328188.40",
//       "ActualPrice": "5678",
//       "ChangedPrice": "328188.40",
//       "RateDifference": "322510.40",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 855,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "EUR",
//       "ConvertedPriceRate": "5212.40",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5212.40",
//       "RateDifference": "-465.60",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 856,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "FJD",
//       "ConvertedPriceRate": "12661.94",
//       "ActualPrice": "5678",
//       "ChangedPrice": "12661.94",
//       "RateDifference": "6983.94",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 857,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "FKP",
//       "ConvertedPriceRate": "4394.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4394.77",
//       "RateDifference": "-1283.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 858,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "FOK",
//       "ConvertedPriceRate": "38894.30",
//       "ActualPrice": "5678",
//       "ChangedPrice": "38894.30",
//       "RateDifference": "33216.30",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 859,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GBP",
//       "ConvertedPriceRate": "4394.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4394.77",
//       "RateDifference": "-1283.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 860,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GEL",
//       "ConvertedPriceRate": "15330.60",
//       "ActualPrice": "5678",
//       "ChangedPrice": "15330.60",
//       "RateDifference": "9652.60",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 861,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GGP",
//       "ConvertedPriceRate": "4394.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4394.77",
//       "RateDifference": "-1283.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 862,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GHS",
//       "ConvertedPriceRate": "87781.88",
//       "ActualPrice": "5678",
//       "ChangedPrice": "87781.88",
//       "RateDifference": "82103.88",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 863,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GIP",
//       "ConvertedPriceRate": "4394.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4394.77",
//       "RateDifference": "-1283.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 864,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GMD",
//       "ConvertedPriceRate": "387069.26",
//       "ActualPrice": "5678",
//       "ChangedPrice": "387069.26",
//       "RateDifference": "381391.26",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 865,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GNF",
//       "ConvertedPriceRate": "49418359.44",
//       "ActualPrice": "5678",
//       "ChangedPrice": "49418359.44",
//       "RateDifference": "49412681.44",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 866,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GTQ",
//       "ConvertedPriceRate": "44004.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "44004.50",
//       "RateDifference": "38326.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 867,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "GYD",
//       "ConvertedPriceRate": "1187724.04",
//       "ActualPrice": "5678",
//       "ChangedPrice": "1187724.04",
//       "RateDifference": "1182046.04",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 868,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "HKD",
//       "ConvertedPriceRate": "44345.18",
//       "ActualPrice": "5678",
//       "ChangedPrice": "44345.18",
//       "RateDifference": "38667.18",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 869,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "HNL",
//       "ConvertedPriceRate": "140530.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "140530.50",
//       "RateDifference": "134852.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 870,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "HRK",
//       "ConvertedPriceRate": "39291.76",
//       "ActualPrice": "5678",
//       "ChangedPrice": "39291.76",
//       "RateDifference": "33613.76",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 871,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "HTG",
//       "ConvertedPriceRate": "748473.96",
//       "ActualPrice": "5678",
//       "ChangedPrice": "748473.96",
//       "RateDifference": "742795.96",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 872,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "HUF",
//       "ConvertedPriceRate": "2035676.56",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2035676.56",
//       "RateDifference": "2029998.56",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 873,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "IDR",
//       "ConvertedPriceRate": "92009889.14",
//       "ActualPrice": "5678",
//       "ChangedPrice": "92009889.14",
//       "RateDifference": "92004211.14",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 874,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ILS",
//       "ConvertedPriceRate": "20667.92",
//       "ActualPrice": "5678",
//       "ChangedPrice": "20667.92",
//       "RateDifference": "14989.92",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 875,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "IMP",
//       "ConvertedPriceRate": "4394.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4394.77",
//       "RateDifference": "-1283.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 876,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "INR",
//       "ConvertedPriceRate": "475532.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "475532.50",
//       "RateDifference": "469854.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 877,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "IQD",
//       "ConvertedPriceRate": "7446810.56",
//       "ActualPrice": "5678",
//       "ChangedPrice": "7446810.56",
//       "RateDifference": "7441132.56",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 878,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "IRR",
//       "ConvertedPriceRate": "238730942.20",
//       "ActualPrice": "5678",
//       "ChangedPrice": "238730942.20",
//       "RateDifference": "238725264.20",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 879,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ISK",
//       "ConvertedPriceRate": "781008.90",
//       "ActualPrice": "5678",
//       "ChangedPrice": "781008.90",
//       "RateDifference": "775330.90",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 880,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "JEP",
//       "ConvertedPriceRate": "4394.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4394.77",
//       "RateDifference": "-1283.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 881,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "JMD",
//       "ConvertedPriceRate": "887755.30",
//       "ActualPrice": "5678",
//       "ChangedPrice": "887755.30",
//       "RateDifference": "882077.30",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 882,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "JOD",
//       "ConvertedPriceRate": "4025.70",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4025.70",
//       "RateDifference": "-1652.30",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 883,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "JPY",
//       "ConvertedPriceRate": "894171.44",
//       "ActualPrice": "5678",
//       "ChangedPrice": "894171.44",
//       "RateDifference": "888493.44",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 884,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KES",
//       "ConvertedPriceRate": "742455.28",
//       "ActualPrice": "5678",
//       "ChangedPrice": "742455.28",
//       "RateDifference": "736777.28",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 885,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KGS",
//       "ConvertedPriceRate": "485469.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "485469.00",
//       "RateDifference": "479791.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 886,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KHR",
//       "ConvertedPriceRate": "23439011.12",
//       "ActualPrice": "5678",
//       "ChangedPrice": "23439011.12",
//       "RateDifference": "23433333.12",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 887,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KID",
//       "ConvertedPriceRate": "8460.22",
//       "ActualPrice": "5678",
//       "ChangedPrice": "8460.22",
//       "RateDifference": "2782.22",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 888,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KMF",
//       "ConvertedPriceRate": "2565036.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2565036.50",
//       "RateDifference": "2559358.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 889,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KRW",
//       "ConvertedPriceRate": "7885947.08",
//       "ActualPrice": "5678",
//       "ChangedPrice": "7885947.08",
//       "RateDifference": "7880269.08",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 890,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KWD",
//       "ConvertedPriceRate": "1737.47",
//       "ActualPrice": "5678",
//       "ChangedPrice": "1737.47",
//       "RateDifference": "-3940.53",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 891,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KYD",
//       "ConvertedPriceRate": "4729.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4729.77",
//       "RateDifference": "-948.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 892,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "KZT",
//       "ConvertedPriceRate": "2702273.76",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2702273.76",
//       "RateDifference": "2696595.76",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 893,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "LAK",
//       "ConvertedPriceRate": "124343714.38",
//       "ActualPrice": "5678",
//       "ChangedPrice": "124343714.38",
//       "RateDifference": "124338036.38",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 894,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "LBP",
//       "ConvertedPriceRate": "508181000.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "508181000.00",
//       "RateDifference": "508175322.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 895,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "LKR",
//       "ConvertedPriceRate": "1721967.06",
//       "ActualPrice": "5678",
//       "ChangedPrice": "1721967.06",
//       "RateDifference": "1716289.06",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 896,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "LRD",
//       "ConvertedPriceRate": "1106755.76",
//       "ActualPrice": "5678",
//       "ChangedPrice": "1106755.76",
//       "RateDifference": "1101077.76",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 897,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "LSL",
//       "ConvertedPriceRate": "103509.94",
//       "ActualPrice": "5678",
//       "ChangedPrice": "103509.94",
//       "RateDifference": "97831.94",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 898,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "LYD",
//       "ConvertedPriceRate": "27367.96",
//       "ActualPrice": "5678",
//       "ChangedPrice": "27367.96",
//       "RateDifference": "21689.96",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 899,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MAD",
//       "ConvertedPriceRate": "55871.52",
//       "ActualPrice": "5678",
//       "ChangedPrice": "55871.52",
//       "RateDifference": "50193.52",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 900,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MDL",
//       "ConvertedPriceRate": "100387.04",
//       "ActualPrice": "5678",
//       "ChangedPrice": "100387.04",
//       "RateDifference": "94709.04",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 901,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MGA",
//       "ConvertedPriceRate": "25526925.28",
//       "ActualPrice": "5678",
//       "ChangedPrice": "25526925.28",
//       "RateDifference": "25521247.28",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 902,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MKD",
//       "ConvertedPriceRate": "320523.10",
//       "ActualPrice": "5678",
//       "ChangedPrice": "320523.10",
//       "RateDifference": "314845.10",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 903,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MMK",
//       "ConvertedPriceRate": "11936461.94",
//       "ActualPrice": "5678",
//       "ChangedPrice": "11936461.94",
//       "RateDifference": "11930783.94",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 904,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MNT",
//       "ConvertedPriceRate": "19255347.16",
//       "ActualPrice": "5678",
//       "ChangedPrice": "19255347.16",
//       "RateDifference": "19249669.16",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 905,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MOP",
//       "ConvertedPriceRate": "45651.12",
//       "ActualPrice": "5678",
//       "ChangedPrice": "45651.12",
//       "RateDifference": "39973.12",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 906,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MRU",
//       "ConvertedPriceRate": "225814.06",
//       "ActualPrice": "5678",
//       "ChangedPrice": "225814.06",
//       "RateDifference": "220136.06",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 907,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MUR",
//       "ConvertedPriceRate": "264538.02",
//       "ActualPrice": "5678",
//       "ChangedPrice": "264538.02",
//       "RateDifference": "258860.02",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 908,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MVR",
//       "ConvertedPriceRate": "87611.54",
//       "ActualPrice": "5678",
//       "ChangedPrice": "87611.54",
//       "RateDifference": "81933.54",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 909,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MWK",
//       "ConvertedPriceRate": "9870578.42",
//       "ActualPrice": "5678",
//       "ChangedPrice": "9870578.42",
//       "RateDifference": "9864900.42",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 910,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MXN",
//       "ConvertedPriceRate": "102147.22",
//       "ActualPrice": "5678",
//       "ChangedPrice": "102147.22",
//       "RateDifference": "96469.22",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 911,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MYR",
//       "ConvertedPriceRate": "26629.82",
//       "ActualPrice": "5678",
//       "ChangedPrice": "26629.82",
//       "RateDifference": "20951.82",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 912,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "MZN",
//       "ConvertedPriceRate": "362710.64",
//       "ActualPrice": "5678",
//       "ChangedPrice": "362710.64",
//       "RateDifference": "357032.64",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 913,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "NAD",
//       "ConvertedPriceRate": "103509.94",
//       "ActualPrice": "5678",
//       "ChangedPrice": "103509.94",
//       "RateDifference": "97831.94",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 914,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "NGN",
//       "ConvertedPriceRate": "9053173.54",
//       "ActualPrice": "5678",
//       "ChangedPrice": "9053173.54",
//       "RateDifference": "9047495.54",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 915,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "NIO",
//       "ConvertedPriceRate": "208893.62",
//       "ActualPrice": "5678",
//       "ChangedPrice": "208893.62",
//       "RateDifference": "203215.62",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 916,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "NOK",
//       "ConvertedPriceRate": "61833.42",
//       "ActualPrice": "5678",
//       "ChangedPrice": "61833.42",
//       "RateDifference": "56155.42",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 917,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "NPR",
//       "ConvertedPriceRate": "760908.78",
//       "ActualPrice": "5678",
//       "ChangedPrice": "760908.78",
//       "RateDifference": "755230.78",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 918,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "NZD",
//       "ConvertedPriceRate": "9425.48",
//       "ActualPrice": "5678",
//       "ChangedPrice": "9425.48",
//       "RateDifference": "3747.48",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 919,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "OMR",
//       "ConvertedPriceRate": "2180.35",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2180.35",
//       "RateDifference": "-3497.65",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 920,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "PAB",
//       "ConvertedPriceRate": "5678.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "5678.00",
//       "RateDifference": "0.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 921,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "PEN",
//       "ConvertedPriceRate": "21235.72",
//       "ActualPrice": "5678",
//       "ChangedPrice": "21235.72",
//       "RateDifference": "15557.72",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 922,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "PGK",
//       "ConvertedPriceRate": "22087.42",
//       "ActualPrice": "5678",
//       "ChangedPrice": "22087.42",
//       "RateDifference": "16409.42",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 923,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "PHP",
//       "ConvertedPriceRate": "331368.08",
//       "ActualPrice": "5678",
//       "ChangedPrice": "331368.08",
//       "RateDifference": "325690.08",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 924,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "PKR",
//       "ConvertedPriceRate": "1578484.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "1578484.00",
//       "RateDifference": "1572806.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 925,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "PLN",
//       "ConvertedPriceRate": "22371.32",
//       "ActualPrice": "5678",
//       "ChangedPrice": "22371.32",
//       "RateDifference": "16693.32",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 926,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "PYG",
//       "ConvertedPriceRate": "43075352.08",
//       "ActualPrice": "5678",
//       "ChangedPrice": "43075352.08",
//       "RateDifference": "43069674.08",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 927,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "QAR",
//       "ConvertedPriceRate": "20667.92",
//       "ActualPrice": "5678",
//       "ChangedPrice": "20667.92",
//       "RateDifference": "14989.92",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:41.000000Z",
//       "updated_at": "2024-07-22T06:28:41.000000Z"
//     },
//     {
//       "id": 928,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "RON",
//       "ConvertedPriceRate": "25948.46",
//       "ActualPrice": "5678",
//       "ChangedPrice": "25948.46",
//       "RateDifference": "20270.46",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 929,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "RSD",
//       "ConvertedPriceRate": "610498.56",
//       "ActualPrice": "5678",
//       "ChangedPrice": "610498.56",
//       "RateDifference": "604820.56",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 930,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "RUB",
//       "ConvertedPriceRate": "498187.72",
//       "ActualPrice": "5678",
//       "ChangedPrice": "498187.72",
//       "RateDifference": "492509.72",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 931,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "RWF",
//       "ConvertedPriceRate": "7470431.04",
//       "ActualPrice": "5678",
//       "ChangedPrice": "7470431.04",
//       "RateDifference": "7464753.04",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 932,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SAR",
//       "ConvertedPriceRate": "21292.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "21292.50",
//       "RateDifference": "15614.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 933,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SBD",
//       "ConvertedPriceRate": "48319.78",
//       "ActualPrice": "5678",
//       "ChangedPrice": "48319.78",
//       "RateDifference": "42641.78",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 934,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SCR",
//       "ConvertedPriceRate": "78980.98",
//       "ActualPrice": "5678",
//       "ChangedPrice": "78980.98",
//       "RateDifference": "73302.98",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 935,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SDG",
//       "ConvertedPriceRate": "2602738.42",
//       "ActualPrice": "5678",
//       "ChangedPrice": "2602738.42",
//       "RateDifference": "2597060.42",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 936,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SEK",
//       "ConvertedPriceRate": "60527.48",
//       "ActualPrice": "5678",
//       "ChangedPrice": "60527.48",
//       "RateDifference": "54849.48",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 937,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SGD",
//       "ConvertedPriceRate": "7608.52",
//       "ActualPrice": "5678",
//       "ChangedPrice": "7608.52",
//       "RateDifference": "1930.52",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 938,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SHP",
//       "ConvertedPriceRate": "4394.77",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4394.77",
//       "RateDifference": "-1283.23",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 939,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SLE",
//       "ConvertedPriceRate": "133035.54",
//       "ActualPrice": "5678",
//       "ChangedPrice": "133035.54",
//       "RateDifference": "127357.54",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 940,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SLL",
//       "ConvertedPriceRate": "133036675.60",
//       "ActualPrice": "5678",
//       "ChangedPrice": "133036675.60",
//       "RateDifference": "133030997.60",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 941,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SOS",
//       "ConvertedPriceRate": "3243614.28",
//       "ActualPrice": "5678",
//       "ChangedPrice": "3243614.28",
//       "RateDifference": "3237936.28",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 942,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SRD",
//       "ConvertedPriceRate": "167217.10",
//       "ActualPrice": "5678",
//       "ChangedPrice": "167217.10",
//       "RateDifference": "161539.10",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 943,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SSP",
//       "ConvertedPriceRate": "11396938.38",
//       "ActualPrice": "5678",
//       "ChangedPrice": "11396938.38",
//       "RateDifference": "11391260.38",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 944,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "STN",
//       "ConvertedPriceRate": "127755.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "127755.00",
//       "RateDifference": "122077.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 945,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SYP",
//       "ConvertedPriceRate": "73023452.06",
//       "ActualPrice": "5678",
//       "ChangedPrice": "73023452.06",
//       "RateDifference": "73017774.06",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 946,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "SZL",
//       "ConvertedPriceRate": "103509.94",
//       "ActualPrice": "5678",
//       "ChangedPrice": "103509.94",
//       "RateDifference": "97831.94",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 947,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "THB",
//       "ConvertedPriceRate": "206168.18",
//       "ActualPrice": "5678",
//       "ChangedPrice": "206168.18",
//       "RateDifference": "200490.18",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 948,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TJS",
//       "ConvertedPriceRate": "60584.26",
//       "ActualPrice": "5678",
//       "ChangedPrice": "60584.26",
//       "RateDifference": "54906.26",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 949,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TMT",
//       "ConvertedPriceRate": "19873.00",
//       "ActualPrice": "5678",
//       "ChangedPrice": "19873.00",
//       "RateDifference": "14195.00",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 950,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TND",
//       "ConvertedPriceRate": "17601.80",
//       "ActualPrice": "5678",
//       "ChangedPrice": "17601.80",
//       "RateDifference": "11923.80",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 951,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TOP",
//       "ConvertedPriceRate": "13229.74",
//       "ActualPrice": "5678",
//       "ChangedPrice": "13229.74",
//       "RateDifference": "7551.74",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 952,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TRY",
//       "ConvertedPriceRate": "187941.80",
//       "ActualPrice": "5678",
//       "ChangedPrice": "187941.80",
//       "RateDifference": "182263.80",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 953,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TTD",
//       "ConvertedPriceRate": "38440.06",
//       "ActualPrice": "5678",
//       "ChangedPrice": "38440.06",
//       "RateDifference": "32762.06",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 954,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TVD",
//       "ConvertedPriceRate": "8460.22",
//       "ActualPrice": "5678",
//       "ChangedPrice": "8460.22",
//       "RateDifference": "2782.22",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 955,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TWD",
//       "ConvertedPriceRate": "186124.84",
//       "ActualPrice": "5678",
//       "ChangedPrice": "186124.84",
//       "RateDifference": "180446.84",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 956,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "TZS",
//       "ConvertedPriceRate": "15172467.70",
//       "ActualPrice": "5678",
//       "ChangedPrice": "15172467.70",
//       "RateDifference": "15166789.70",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 957,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "UAH",
//       "ConvertedPriceRate": "235580.22",
//       "ActualPrice": "5678",
//       "ChangedPrice": "235580.22",
//       "RateDifference": "229902.22",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 958,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "UGX",
//       "ConvertedPriceRate": "20923941.02",
//       "ActualPrice": "5678",
//       "ChangedPrice": "20923941.02",
//       "RateDifference": "20918263.02",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 959,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "UYU",
//       "ConvertedPriceRate": "228709.84",
//       "ActualPrice": "5678",
//       "ChangedPrice": "228709.84",
//       "RateDifference": "223031.84",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 960,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "UZS",
//       "ConvertedPriceRate": "71314260.50",
//       "ActualPrice": "5678",
//       "ChangedPrice": "71314260.50",
//       "RateDifference": "71308582.50",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 961,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "VES",
//       "ConvertedPriceRate": "207871.58",
//       "ActualPrice": "5678",
//       "ChangedPrice": "207871.58",
//       "RateDifference": "202193.58",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 962,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "VND",
//       "ConvertedPriceRate": "143889604.80",
//       "ActualPrice": "5678",
//       "ChangedPrice": "143889604.80",
//       "RateDifference": "143883926.80",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 963,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "VUV",
//       "ConvertedPriceRate": "673694.70",
//       "ActualPrice": "5678",
//       "ChangedPrice": "673694.70",
//       "RateDifference": "668016.70",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 964,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "WST",
//       "ConvertedPriceRate": "15557.72",
//       "ActualPrice": "5678",
//       "ChangedPrice": "15557.72",
//       "RateDifference": "9879.72",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 965,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "XAF",
//       "ConvertedPriceRate": "3420086.52",
//       "ActualPrice": "5678",
//       "ChangedPrice": "3420086.52",
//       "RateDifference": "3414408.52",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 966,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "XCD",
//       "ConvertedPriceRate": "15330.60",
//       "ActualPrice": "5678",
//       "ChangedPrice": "15330.60",
//       "RateDifference": "9652.60",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 967,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "XDR",
//       "ConvertedPriceRate": "4286.89",
//       "ActualPrice": "5678",
//       "ChangedPrice": "4286.89",
//       "RateDifference": "-1391.11",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 968,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "XOF",
//       "ConvertedPriceRate": "3420086.52",
//       "ActualPrice": "5678",
//       "ChangedPrice": "3420086.52",
//       "RateDifference": "3414408.52",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 969,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "XPF",
//       "ConvertedPriceRate": "622195.24",
//       "ActualPrice": "5678",
//       "ChangedPrice": "622195.24",
//       "RateDifference": "616517.24",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 970,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "YER",
//       "ConvertedPriceRate": "1419783.90",
//       "ActualPrice": "5678",
//       "ChangedPrice": "1419783.90",
//       "RateDifference": "1414105.90",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 971,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ZAR",
//       "ConvertedPriceRate": "103509.94",
//       "ActualPrice": "5678",
//       "ChangedPrice": "103509.94",
//       "RateDifference": "97831.94",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 972,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ZMW",
//       "ConvertedPriceRate": "145186.46",
//       "ActualPrice": "5678",
//       "ChangedPrice": "145186.46",
//       "RateDifference": "139508.46",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     },
//     {
//       "id": 973,
//       "user_id": 1,
//       "source_type": "1",
//       "source_id": "269",
//       "PPVType": "1",
//       "PriceType": "USD",
//       "IOSPrice": "1",
//       "Country": "ZWL",
//       "ConvertedPriceRate": "77902.16",
//       "ActualPrice": "5678",
//       "ChangedPrice": "77902.16",
//       "RateDifference": "72224.16",
//       "Symbol": null,
//       "created_at": "2024-07-22T06:28:42.000000Z",
//       "updated_at": "2024-07-22T06:28:42.000000Z"
//     }
//   ]);

//   const allCurrencylistfromappichoosed = {
//     "id": 1,
//     "country": "Albania",
//     "currencies": "Leke",
//     "code": "ALL",
//     "symbol": "Lek",
//     "created_at": null,
//     "updated_at": null
//   };


//   const [editUser, setEditUser] = useState({
//     source_type: "1",
//     PPVType: "1",
//     ios_ppv_price: "1",
//     ppv_gobal_price: "1000",
//     global_ppv: null,
//     ppv_price: "200",
//   });


//   const [showModal, setShowModal] = useState(false);
//   const [modalType, setModalType] = useState('');
//   const [step, setStep] = useState(1);

//   const [formDataglobalprice, setFormDataglobalprice] = useState({
//     priceType: '',
//     price: '',
//     convertedPrices: []
//   });

//   const [formDatacustomprice, setFormDatacustomprice] = useState({
//     priceType: '',
//     price: '',
//     convertedPrices: []
//   });

//   // const inputRef = useRef(null);

//   // Initialize the selected country in the state based on allCurrencylistfromappichoosed
//   useEffect(() => {
//     if (allCurrencylistfromappichoosed?.code) {
//       setFormDataglobalprice(prevState => ({
//         ...prevState,
//         priceType: allCurrencylistfromappichoosed?.code
//       }));
//       setFormDatacustomprice(prevState => ({
//         ...prevState,
//         priceType: allCurrencylistfromappichoosed?.code
//       }));
//     }
//   }, [allCurrencylistfromappichoosed?.code]);

//   const handleChange = (e) => {
//     setModalType(e.target.value);
//     setShowModal(true);
//     setStep(1); // Reset to step 1 when opening a new modal
//   };


//   const handleClose = () => {
//     setShowModal(false);
//     // Reset form data when closing the modal
//     setFormDataglobalprice({
//       priceType: '',
//       price: '',
//       convertedPrices: []
//     });
//     setFormDatacustomprice({
//       priceType: '',
//       price: '',
//       convertedPrices: []
//     });
//   };

//   const inputvaleedit = (e) => {
//     const { name, value } = e.target;
//     setFormDataglobalprice(prevState => ({
//       ...prevState,
//       [name]: value,
//     }));
//     setFormDatacustomprice(prevState => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const handleNextNext = async () => {
//     if (step === 1) {
//       const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

//       // Validate the first step fields
//       if (!formData?.priceType || !formData?.price) {
//         alert('Please fill in the required fields.');
//         return;
//       }

//       // Call API for currency conversion based on formData?.priceType (selected code)
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${formData?.priceType}`);
//         const rates = response?.data.rates;

//         // Map rates to an array of objects with country and rate
//         const dataCountry = Object.entries(rates)?.map(([country, rate]) => ({
//           country: country,
//           rate: rate
//         }));

//         // Map dataCountry to convertedPrices
//         const convertedPrices = dataCountry.map(item => {
//           let country_name = item?.country; // Default to item.country
//           let symbol = ''; // Default to empty symbol

//           // Check if allCurrencylistfromappi has a matching code with item.country
//           const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.country);
//           if (matchingCountry) {
//             country_name = matchingCountry.country; // Set country_name if code matches
//             symbol = matchingCountry.symbol; // Set symbol if code matches
//           }

//           return {
//             country: item?.country,
//             country_name: country_name,
//             symbol: symbol, // Add symbol to the object
//             convertedPrice: (formData.price * item?.rate).toFixed(2),
//             price: formData.price,
//             difference: ((formData.price * item?.rate) - formData.price).toFixed(2),
//             editPrice: (formData.price * item?.rate).toFixed(2), // Initialize editPrice with converted price
//             isEditable: true, // Editable
//           };
//         });

//         if (modalType === '1') {
//           setFormDataglobalprice(prevState => ({
//             ...prevState,
//             convertedPrices: convertedPrices
//           }));
//         } else {
//           setFormDatacustomprice(prevState => ({
//             ...prevState,
//             convertedPrices: convertedPrices
//           }));
//         }

//         setStep(2);
//       } catch (error) {
//         console.error('Error converting currency:', error);
//       }
//     }
//   };

//   const handleSubmit = async () => {
//     const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

//     // Validate all fields in step 2
//     if (formData?.convertedPrices.length === 0) {
//       alert('Please fill in all the fields.');
//       return;
//     }

//     // Prepare data to submit
//     const dataToSubmit = {
//       source_type: 1,  // Note -  1 (Videos) or 2 (Livestream) or  3 (Series)  or 4 (Episode) or 5 (Audios)
//       source_id: video_id,
//       PPVType: modalType,
//       PriceType: formData?.priceType, // Separate priceType outside of convertedPrices
//       IOSPrice: editUser?.ios_ppv_price,
//       ActualPrice: formData?.price,
//       ConvertedPriceRate: formData?.convertedPrices.map(item => (
//         {
//           country: item?.country,
//           country_name: item?.country_name,
//           ConvertedPriceRate: item?.convertedPrice,
//           difference: item?.difference,
//           convertedPrice: item?.editPrice,
//           Symbol: item?.symbol || formData?.symbol || "",
//         }
//       )
//       )
//     };
//     // Submit the final data
//     try {
//       const response = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/Video/PayPerViewPrice/StoreUpdate`, dataToSubmit, {
//         headers: headers
//       });
//       const result = response?.data;
//       console.log('Form submitted successfully:', result?.status);
//       handleClose();
//       setStep(1);
//       setFormDataglobalprice({
//         priceType: '',
//         price: '',
//         convertedPrices: []
//       });
//       setFormDatacustomprice({
//         priceType: '',
//         price: '',
//         convertedPrices: []
//       });
//     } catch (error) {
//       console.error('Error submitting form:', error);
//     }
//   };

//   const handleBackBack = () => {
//     setStep(1);
//   };

//   const handleInputChangeeditable = (index, fieldName, value) => {
//     const updatedConvertedPrices = [...(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices)];
//     updatedConvertedPrices[index][fieldName] = value;
//     if (modalType === '1') {
//       setFormDataglobalprice(prevState => ({
//         ...prevState,
//         convertedPrices: updatedConvertedPrices
//       }));
//     } else {
//       setFormDatacustomprice(prevState => ({
//         ...prevState,
//         convertedPrices: updatedConvertedPrices
//       }));
//     }
//   };



//   return (
//     <div className="mt-2">
//       <div className="col-sm-12">
//         <label className="m-0">Create Price Change</label>
//         <div className="">
//           <div className="mr-3 mt-2">
//             <input type="radio" value="1" name="priceChangeType" onChange={handleChange} /> Global Price Change
//           </div>
//           <div className="mt-2">
//             <input type="radio" value="2" name="priceChangeType" onChange={handleChange} /> Custom Price Change
//           </div>
//         </div>
//       </div>

//       <Modal show={showModal} onHide={handleClose} dialogClassName="modal-lg">
//         <Modal.Header closeButton>
//           <Modal.Title>{modalType === '1' ? 'Global Price Change' : 'Custom Price Change'}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {step === 1 && (
//             <Form>
//               <Form.Group>
//                 <Form.Label>Choose Country</Form.Label>
//                 <Form.Control
//                   as="select"
//                   value={modalType === '1' ? formDataglobalprice.priceType : formDatacustomprice.priceType}
//                   onChange={(e) => {
//                     const value = e.target.value;
//                     if (modalType === '1') {
//                       setFormDataglobalprice(prevState => ({ ...prevState, priceType: value }));
//                     } else {
//                       setFormDatacustomprice(prevState => ({ ...prevState, priceType: value }));
//                     }
//                   }}
//                 >
//                   <option value="">Select</option>
//                   {allCurrencylistfromappi?.map((item, index) => (
//                     <option
//                       key={index}
//                       value={item?.code}
//                       selected={
//                         (modalType === '1' && item?.code === formDataglobalprice.priceType) ||
//                         (modalType !== '1' && item?.code === formDatacustomprice.priceType)
//                       }
//                     >
//                       {item?.country}
//                     </option>
//                   ))}
//                 </Form.Control>
//               </Form.Group>
//               <Form.Group className="mt-2">
//                 <Form.Label>Price</Form.Label>
//                 <Form.Control
//                   type="number"
//                   name="price"
//                   value={modalType === '1' ? formDataglobalprice.price : formDatacustomprice.price}
//                   onChange={inputvaleedit}
//                 />
//               </Form.Group>

//               {/* <div className="col-sm-12 mt-2">
//                 <label className="m-0">IOS PPV Price</label>
//                 <p className="p1">Apply IOS PPV Price from Global Settings?</p>
//                 <div className="panel-body ppv_price_ios mt-2">
//                   <select
//                     name="ios_ppv_price"
//                     id="ios_ppv_price"
//                     onChange={handleInput}
//                     value={editUser?.ios_ppv_price}
//                     className="form-select"
//                   >
//                     <option value="">Select IOS Price</option>
//                     {inapppurchase?.map((item, key) => (
//                       <option
//                         key={key}
//                         value={item?.product_id}
//                         selected={item?.product_id === editUser?.ios_ppv_price}
//                       >
//                         {item?.plan_price}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               </div> */}
//             </Form>

//           )}
//           {step === 2 && (
//             <Form>
//               <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
//                 <div className="col-lg-12 row">
//                   <div className="col-3 col-sm-3 col-md-3 col-lg-3"> <p className="fw-bold">COUNTRIES OR REGION</p> </div>
//                   <div className="col-3 col-sm-3 col-md-3 col-lg-3"> <p className="fw-bold">CURRENT PRICE </p>  </div>
//                   <div className="col-3 col-sm-3 col-md-3 col-lg-3"> <p className="fw-bold">PRICE</p> </div>
//                   <div className="col-3 col-sm-3 col-md-3 col-lg-3"> <p className="fw-bold">DIFFERENCE</p> </div>
//                 </div>
//                 <hr></hr>
//                 {(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices)?.map((item, index) => (
//                   <div key={index}>
//                     <div className="d-flex justify-content-between align-items-center py-2">
//                       <div className="col-lg-12 row">
//                         <div className="col-3 col-sm-3 col-md-3 col-lg-3"> {item?.country_name} ({item?.country}) </div>
//                         <div className="col-3 col-sm-3 col-md-3 col-lg-3"> <Form.Control type="text" value={`${item?.symbol} ${item?.convertedPrice}`} readOnly /> </div>
//                         <div className="col-3 col-sm-3 col-md-3 col-lg-3"> <div className="d-flex align-items-center"><span className="me-1">{item?.symbol}</span> <Form.Control
//                           type="number"
//                           value={item?.editPrice}
//                           onChange={(e) => handleInputChangeeditable(index, 'editPrice', e.target.value)}
//                           readOnly={modalType === '1'}
//                         />  </div> </div>
//                         <div className="col-3 col-sm-3 col-md-3 col-lg-3"> <Form.Control type="number" value={item?.difference} readOnly /> </div>
//                       </div>
//                     </div>
//                     {/* <div>
//                         <Form.Control type="number" value={item?.editPrice} onChange={(e) => handleInputChangeeditable(index, 'editPrice', e.target.value)} />

//                         <Form.Group>
//                           <Form.Label>Converted Price in {item?.country}</Form.Label>
//                           <Form.Control type="number" value={item?.convertedPrice} readOnly />
//                         </Form.Group>
//                         <Form.Group>
//                           <Form.Label>Difference in {item?.country}</Form.Label>
//                           <Form.Control type="number" value={item?.difference} readOnly />
//                         </Form.Group>
//                       </div> */}
//                     <hr />
//                   </div>
//                 ))}
//               </div>
//             </Form>
//           )}
//         </Modal.Body>
//         <Modal.Footer>
//           {step === 1 && <Button variant="primary" onClick={handleNextNext}>Next</Button>}
//           {step === 2 && <Button variant="secondary" onClick={handleBackBack}>Back</Button>}
//           {step === 2 && <Button variant="primary" onClick={handleSubmit}>Submit</Button>}
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default FileManager;



// import React, { useState } from "react";

// const ModalExample = () => {
//   const [showFirstModal, setShowFirstModal] = useState(false);
//   const [showSecondModal, setShowSecondModal] = useState(false);
//   const [modalData, setModalData] = useState("");

//   const handleFirstModal = () => {
//     setShowFirstModal(true);
//   };

//   const handleSecondModal = (data) => {
//     setShowSecondModal(true);
//     setModalData(data);
    
//   };

//   const closeFirstModal = () => {
//     setShowFirstModal(false);
//   };

//   const closeSecondModal = () => {
//     setShowSecondModal(false);
//   };

  
//   return (
//     <div>
//       <button
//         className="btn btn-primary"
//         onClick={handleFirstModal}
//       >
//         Create Season
//       </button>

//       {/* First Modal */}
//       {showFirstModal && (
//         <div className="modal fade show" role="dialog" aria-modal="true" style={{ display: "block" }}>
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h5 className="modal-title">First Modal</h5>
//                 <button type="button" className="btn-close" onClick={closeFirstModal}></button>
//               </div>
//               <div className="modal-body">
//                 <p>First modal content...</p>
//                 <button
//                   className="btn btn-primary"
//                   onClick={() => handleSecondModal("Data from First Modal")}
//                 >
//                   Open Second Modal
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Second Modal */}
//       {showSecondModal && (
//         <div className="modal fade show" role="dialog" aria-modal="true" style={{ display: "block" }}>
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h5 className="modal-title">Second Modal</h5>
//                 <button type="button" className="btn-close" onClick={closeSecondModal}></button>
//               </div>
//               <div className="modal-body">
//                 <p>Second modal content: {modalData}</p>
//               </div>
//               <div className="modal-footer">
//                 <button
//                   className="btn btn-primary"
//                   onClick={closeSecondModal}
//                 >
//                   Save and Close
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ModalExample;
