import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.css";

import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";
import edititem from "../../../components/Images/edititem.svg";
import deleteitem from "../../../components/Images/deleteitem.svg";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function ContentPartnerView(props) {
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [itemToDelete, setItemToDelete] = useState([]);
  const [roles, setRoles] = useState([]);
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [modelClose, setModelClose] = useState(false);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    const idsToExclude = [1];
    setItemToDelete(
      selectAll
        ? []
        : filteredData
          .filter((item) => !idsToExclude.includes(item?.id))
          .map((item) => item?.id)
    );
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    nonSelectable: [1],
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDelete([...itemToDelete, row?.id]);
        } else {
          setItemToDelete(itemToDelete?.filter((id) => id !== row?.id));
        }
      }
    },
    selected: itemToDelete,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={selectAll}
          indeterminate={indeterminate}
          onChange={handleAll}
        />
      </div>
    ),
  };

  const openModal = () => {
    setModelClose(true);
  };
  const singleDelete = (id) => {
    setModelClose(true);
    setItemToDelete([id]);
  };
  const closeModal = () => {
    setModelClose(!closeModal);
  };
  const handleDeletedata = async () => {
    handleDelete();
    closeModal();
  };

  const fetchRole = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/content-partner/role-index`,
        { headers: headers }
      )
      .then((response) => {
        var result = response?.data?.ContentPartnerRoles;
        const updatedList = result?.map((item) => {
          return { ...item, created_at: formatDate(item?.created_at) };
        });
        setRoles(updatedList);
        setFilteredData(updatedList);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(async () => {
    fetchRole();
  }, []);
  const conditionalGeneratePDF = (get) => {
    const doc = new jsPDF();
    doc.text("CONTENT PARTNER ROLES LIST", 10, 10);

    const columns = ["Index", "ID", "ROLE NAME"];
    const allDataPDF = roles.map((item, index) => [
      index + 1,
      item?.id,
      item?.role_name,
    ]);
    const filterPDF = filteredData.map((item, index) => [
      index + 1,
      item?.id,
      item?.role_name,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("CONTENT-PARTNER-ROLES.pdf");
  };
  const generateXLSX = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? roles : filteredData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "CONTENT-PARTNER-ROLES.xlsx");
  };
  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/moderator/view/edit/${row.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Edit</span>
            </span>
          </Link>
          {row.id !== 1 && (
            <div
              onClick={() => singleDelete(row.id)}
              className="commonActionPadding"
            >
              <img src={deleteitem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Delete</span>
            </div>
          )}
        </div>
      </div>
    );
  };
  const userFormatter = (data, row) => {
    return (
      <div className="d-flex flex-column align-items-start">
        <span>{data}</span>
        <span className="dateText"> {formatDate(row.created_at)}</span>
      </div>
    );
  };
  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
    return `${formattedDate} ${formattedTime}`;
  }

  const exportHeaders = [{ label: "ROLE NAME", key: "role_name" }];

  const handleDelete = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("role_id", "[" + itemToDelete + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/content-partner/multi-role-delete`,
        formData,
        { headers: headers }
      );
      const result = response?.data;
      if (result?.status === true) {
        fetchRole();
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const handleFilterChange = (event) => {
    const searchTerm = event.target.value;
    setFilter(searchTerm);
    const searchTermLower = searchTerm?.toLowerCase();

    const filteredResults = roles?.filter((item) => {
      return (
        (typeof item?.created_at === "string" &&
          item?.created_at.toLowerCase().includes(searchTermLower)) ||
        item?.role_name.toLowerCase().includes(searchTermLower)
      );
    });
    setFilteredData(filteredResults);
  };
  const columns = [
    {
      dataField: "role_name",
      text: "Role Name",
      formatter: userFormatter,
      sort: true,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredData?.length },
    ],
  });
  const handleClear = () => {
    fetchRole();
    setFilter("");
  };
  return (
    <>
      <div
        className={`modal packageModel ${modelClose ? "show fade" : ""}`}
        tabindex="-1"
        style={{ display: `${modelClose ? "block" : "none"}` }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDelete.length === 1 ? (
                <p> Are you sure you want to delete this item ?</p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected items ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeletedata}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="row m-0 pb-3">
                  <div className="col-md-6">
                    <h4>
                      Roles{" "}
                      <span className="fs-6">
                        {" "}
                        (Display all {roles.length} roles)
                      </span>
                    </h4>
                  </div>
                  <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
                    {itemToDelete.length > 0 && (
                      <button
                        className="btn btn-danger d-inline-flex fw-bolder  "
                        onClick={openModal}
                      >
                        <MdDelete className="exportIcon " />
                        Delete{" "}
                      </button>
                    )}

                    <Link
                      className="btn btn-primary"
                      to="/moderator/role"
                    >
                      <i className="fa fa-plus-circle"></i> Add Role
                    </Link>
                  </div>
                </div>
                <div className="admin-section-title">
                  <div className="iq-card">
                    <div
                      className="panel panel-primary category-panel"
                      data-collapsed="0"
                    >
                      <div className="panel-body mt-3">
                        <div
                          id="nestable"
                          className="nested-list dd with-margins"
                        >
                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                            <div className="filterContainer">
                              <input
                                type="text"
                                placeholder="Filter all.."
                                className="filter form-control "
                                value={filter}
                                onChange={handleFilterChange}
                              />
                              {filter.length > 0 && (
                                <button
                                  type="button"
                                  className="closeBtn"
                                  onClick={handleClear}
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              )}
                            </div>
                            <div className="btn-group">
                              <div className="dropdown d-block " key={"2"}>
                                <button
                                  className="btn btn-success dropdown-toggle d-flex"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span className="fw-bolder">Export </span>
                                  <img
                                    src={ExportIcon}
                                    className="ms-2 exportIcon"
                                  />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li className="dropdown-item">
                                    <CSVLink
                                      headers={exportHeaders}
                                      data={filteredData}
                                      className="text-dark d-block"
                                      filename="CONTENT-PARTNER-ROLES.csv"
                                    >
                                      CSV
                                    </CSVLink>
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      conditionalGeneratePDF(0);
                                    }}
                                  >
                                    PDF
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      generateXLSX(0);
                                    }}
                                  >
                                    XLSX
                                  </li>
                                </ul>
                              </div>

                              <div className="dropdown d-block ">
                                <button
                                  className="btn btn-success dropdown-toggle d-flex"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span className="fw-bolder">Export All</span>
                                  <img
                                    src={ExportIcon}
                                    className="ms-2 exportIcon"
                                  />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li className="dropdown-item">
                                    <CSVLink
                                      headers={exportHeaders}
                                      data={roles}
                                      className="text-dark d-block"
                                      filename="CONTENT-PARTNER-ROLES.csv"
                                    >
                                      CSV
                                    </CSVLink>
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      conditionalGeneratePDF(1);
                                    }}
                                  >
                                    PDF
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      generateXLSX(1);
                                    }}
                                  >
                                    XLSX
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="bootstrapTable">
                            <BootstrapTable
                              keyField="id"
                              columns={columns}
                              data={filteredData}
                              pagination={pagination}
                              className="no-border-table"
                              noDataIndication={
                                <div className="noRecord">
                                  {loading ? (
                                    <label>
                                      {" "}
                                      <Response_Loading_processing></Response_Loading_processing>
                                      Loading...
                                    </label>
                                  ) : (
                                    filteredData.length <= 0 && (
                                      <p>No Record Found!</p>
                                    )
                                  )}
                                </div>
                              }
                              hover
                              headerClasses="custom-header"
                              selectRow={selectRow}
                              selectAll={selectAll}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ContentPartnerView;
