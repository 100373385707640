import React, { useEffect, useState } from 'react'
import { toast } from "react-toastify"
import axios from "axios"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MessageBox from '../../../components/CommonComponents/MessageBox'
import { audioOrderDefaultURL } from '../../../components/CommonApis/CommonAudioOrderApi'
import Response_Processing from '../../../layout/Others/Response_Processing'
import { FaEllipsisVertical } from 'react-icons/fa6';
import NoRecordTable from '../../../components/CommonComponents/NoRecordTable';
import { Link } from 'react-router-dom';
import AdminCustomButton from '../../../components/CommonComponents/AdminCustomButton';
import { getItemToken } from '../../../Utils/localStorageUtils';

const AudioSettings = () => {
    const [apiData, setApiData] = useState([]);
    const [enableSetting, setEnableSetting] = useState([])
    const [editSetting, setEditSetting] = useState(null)
    const [showOverlay, setShowOverlay] = useState(false);
    const [loading, setLoading] = useState(false)
    const [withOutRefresh, setWithOutRefresh] = useState(false)

    const HeaderToken = getItemToken('access_token')

    const fetData = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`${audioOrderDefaultURL?.listApi}`, { headers: HeaderToken })
            const result = response?.data?.Audio_HomePage_Order;
            if (response?.data?.status) {
                setApiData(result)
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (error) {
            const errMessage = error?.response?.data?.message
            console.error(errMessage)
        }
    }

    const enableData = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`${audioOrderDefaultURL?.enableSettingURL}`, { headers: HeaderToken })
            const result = response?.data
            if (response?.data?.status) {
                setEnableSetting(result?.Audio_HomePage_Order)
                setLoading(false)
            }
            else {
                setLoading(false)
            }

        }
        catch (error) {
            const errMessage = error?.response?.data?.message
            console.error(errMessage)
        }
    }

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedItems = Array?.from(apiData);
        const [movedItem] = reorderedItems?.splice(result?.source?.index, 1);
        reorderedItems.splice(result?.destination?.index, 0, movedItem);
        setApiData(reorderedItems);
        saveShuffleOrder(reorderedItems);
    };

    const saveShuffleOrder = async (updatedItems) => {
        setShowOverlay(true);
        if (!updatedItems || updatedItems?.length == 0) {
            console.error("Menu items are empty");
            return;
        }
        const positions = updatedItems?.map((menuItem) => menuItem?.id);
        const ids = updatedItems?.map((_, index) => index + 1);

        const requestData = {
            id: positions,
            position: ids,
        };

        try {
            const response = await axios.post(`${audioOrderDefaultURL?.orderApi}`, requestData, { headers: HeaderToken });
            const result = response.data;
            if (result?.status) {
                toast.success(result?.message);
                setShowOverlay(false);
                setWithOutRefresh(!withOutRefresh)
            }
            else {
                toast.error(result?.message);
                setShowOverlay(false);
            }
        } catch (err) {
            var errorMessage = err?.response?.data?.message;
            toast.error(errorMessage);
            setShowOverlay(false);
        }
    };

    const handleToggleSettings = async (name, value) => {
        const updatedEditSettings = enableSetting.map(item => ({
            [item.name]: item.name === name ? (value ? 0 : 1) : item.Audio_HomePage_status,
            'audio_home_settings': item.name === name ? (value ? 0 : 1) : item.Audio_HomePage_status,
        }));
        const updatedSetting = enableSetting.map((item) => item?.name === name ? { ...item, Audio_HomePage_status: value ? 0 : 1 } : item);
        setEnableSetting(updatedSetting)
    }

    const updateSetting = async () => {
        setShowOverlay(true)
        try {
            const formData = new FormData();
            enableSetting.forEach((item) => {
                const status = Number.isNaN(parseInt(item?.Audio_HomePage_status, 10)) ? 0 : parseInt(item?.Audio_HomePage_status, 10);
                formData.append(item?.name, status);
            });
            const response = await axios.post(`${audioOrderDefaultURL?.updateSettingURL}`, formData, { headers: HeaderToken });
            const result = response.data;
            if (result?.status) {
                toast.success(result?.message);
                setShowOverlay(false);
                setWithOutRefresh(!withOutRefresh)
            }
            else {
                toast.error(result?.message);
                setShowOverlay(false);
            }
        } catch (err) {
            var errorMessage = err?.response?.data?.message;
            toast.error(errorMessage);
            setShowOverlay(false);
        }
    }
    useEffect(() => {
        fetData()
        enableData()
    }, [withOutRefresh])
    return (
        <div className='px-2 px-md-0'>
            {showOverlay && (<Response_Processing></Response_Processing>)}
            <h4 className="admin-title mb-4 theme-text-color text-md-start ">
                <MessageBox text="Audio Page Menu Settings" classname="theme-text-color" />
            </h4>
            <div className='row mx-0 py-3 mb-4 theme-bg-color rounded-2'>
                {enableSetting?.map((item) => {
                    return <div className={`col-12 col-md-6 category-order-title px-2 px-md-4  `}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <MessageBox text={item?.header_name} classname="theme-text-color" />
                            <label className="switch">
                                <input name={item?.name} onChange={() => handleToggleSettings(item?.name, item?.Audio_HomePage_status)} checked={item?.Audio_HomePage_status} type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                })}
                <div className='col-12 text-end'>
                    <AdminCustomButton saveMethod={updateSetting} saveText="Save Setting" />
                </div>
            </div>
            <h4 className="admin-title mb-4 theme-text-color text-md-start">
                <MessageBox text="Order Audio Page" classname="theme-text-color" />
            </h4>
            <div className="bootstrapTable theme-bg-color p-3 rounded-2">
                <div className="">
                    <div className="row m-0 theme-border-bottom-secondary pb-3">
                        <div className="col-2 "> <label className="theme-text-color fw-bold fs-6"># </label> </div>
                        <div className="col-5 "><label className="theme-text-color fw-bold fs-6">Name</label></div>
                        <div className="col-5 "><label className="theme-text-color fw-bold fs-6"> Actions </label> </div>
                    </div>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="menu-items" className="theme-bg-color"   >
                            {(provided) => (
                                <ul  {...provided.droppableProps} ref={provided.innerRef} className="pb-5 px-0 theme-bg-color list-unstyled m-0 p-0">
                                    {loading ? <NoRecordTable result={apiData?.length} loader={true} /> :
                                        apiData?.map((item, index) => (
                                            <Draggable key={item?.id.toString()} draggableId={item?.id.toString()} index={index} >
                                                {(provided) => (
                                                    <li ref={provided.innerRef} {...provided.draggableProps}  {...provided.dragHandleProps} className={` theme-bg-color theme-text-color  orderborder ${item.order} index ${index + 1} w-100 theme-border-bottom-secondary`} >
                                                        <div className="row m-0 p-0 align-items-center">
                                                            <div className="col-2  category-order-title">
                                                                {item?.order_id}
                                                            </div>
                                                            <div className="col-5  category-order-title">
                                                                {item?.header_name}
                                                            </div>
                                                            <div className="col-5  category-order-title">
                                                                <div className="editdropdown">
                                                                    <span className="editdropdown-button">
                                                                        <span>
                                                                            <FaEllipsisVertical className="theme-text-color" />
                                                                        </span>
                                                                    </span>
                                                                    <div className="editdropdown-menu theme-bg-color">
                                                                        <Link to={`/edit-audio-order/${item?.id}`} className="theme-text-color  theme-bg-color">
                                                                            <span>
                                                                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                    <g>
                                                                                        <path class="svgIcon" d="M10.3,18.5l-4.74.64A.49.49,0,0,1,5,18.59l.64-4.74a.42.42,0,0,1,.14-.28L17.31,2a.59.59,0,0,1,.85,0L22.11,6a.6.6,0,0,1,0,.84L10.58,18.36A.53.53,0,0,1,10.3,18.5Z" />
                                                                                        <line class="svgIcon" x1="1.01" y1="21.58" x2="18.89" y2="21.58" />
                                                                                    </g>
                                                                                </svg>
                                                                                <MessageBox text="Edit" classname='ms-2 theme-text-color'></MessageBox>
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </Draggable>
                                        ))}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </div>

    )
}

export default AudioSettings