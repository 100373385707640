import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Table } from "react-bootstrap";
import edititem from "../../../../components/Images/edititem.svg";
import stripe_image from "../../../../components/Images/payment_logo/stripe.svg";
import paypal_image from "../../../../components/Images/payment_logo/paypal.svg";
import paystack_image from "../../../../components/Images/payment_logo/paystack.svg";
import razorpay_image from "../../../../components/Images/payment_logo/razorpay.svg";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import "./Paypal.css";

function Paypal(props) {
  const [paypal_payment, setPaypal_payment] = useState({
    id: "",
    user_id: "",
    payment_type: "",
    payment_mode: "",
    test_paypal_username: "",
    test_paypal_password: "",
    test_paypal_signature: "",
    live_paypal_username: "",
    live_paypal_password: "",
    live_paypal_signature: "",
    paypal_plan_name: "",
    paypal_label: "",
    status: "",
  });
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/paypal_payment/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.paypal_payment;
        setPaypal_payment(result);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setPaypal_payment({ ...paypal_payment, [e.target.name]: 1 });
    } else {
      setPaypal_payment({ ...paypal_payment, [e.target.name]: 0 });
    }
  };

  const handleInput = (e) => {
    setPaypal_payment({ ...paypal_payment, [e.target.name]: e.target.value });
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const paypalpayment = async (e) => {
    e.preventDefault();

    const payment_mode = Number.isNaN(parseInt(paypal_payment?.payment_mode, 10)) ? 0 : parseInt(paypal_payment?.payment_mode, 10);
    const status = Number.isNaN(parseInt(paypal_payment?.status, 10)) ? 0 : parseInt(paypal_payment?.status, 10);

    const editInputvalue = {
      id: paypal_payment?.id,
      user_id: paypal_payment?.user_id,
      payment_type: "PayPal",
      payment_mode: payment_mode,
      test_paypal_username: paypal_payment?.test_paypal_username,
      test_paypal_password: paypal_payment?.test_paypal_password,
      test_paypal_signature: paypal_payment?.test_paypal_signature,
      live_paypal_username: paypal_payment?.live_paypal_username,
      live_paypal_password: paypal_payment?.live_paypal_password,
      live_paypal_signature: paypal_payment?.live_paypal_signature,
      status: status,
      paypal_plan_name: paypal_payment?.paypal_plan_name,
      paypal_label: paypal_payment?.paypal_label,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/paypal_payment/store`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res?.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson?.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  const paypal_redirect = () => {
    window.open("https://www.paypal.com/us/home", "_blank");
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row mt-3">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <h3>PayPal Payment</h3>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className="text-end mb-3">
                <button onClick={paypalpayment} className="btn btn-primary">
                  Update PayPal
                </button>
              </div>

              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3 mb-3">
                  <h5>Create Payment</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <div className="row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <label className="m-0">Enable Payment Mode</label>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3 text-end">
                        <label className="switch">
                          <input
                            name="payment_mode"
                            id="payment_mode"
                            onChange={handleInputenable}
                            className="rs-input"
                            defaultChecked={
                              paypal_payment?.payment_mode == 1 ? true : false
                            }
                            checked={
                              paypal_payment?.payment_mode == 1 ? true : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="payment_mode"
                            id="payment_mode"
                            onChange={handleInputenable}
                            value={
                              paypal_payment?.payment_mode == 1 ? "1" : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Paypal Payment Plan</label>

                    <div className="mt-2">
                      <Link onClick={paypal_redirect}>
                        (https://www.paypal.com/us/home)
                      </Link>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Test PayPal Username</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="test_paypal_username"
                        name="test_paypal_username"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={paypal_payment?.test_paypal_username}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Test PayPal Signature</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="test_paypal_signature"
                        name="test_paypal_signature"
                        value={paypal_payment?.test_paypal_signature}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Live PayPal Password</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="live_paypal_password"
                        name="live_paypal_password"
                        value={paypal_payment?.live_paypal_password}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="text-start mb-3">
                  <button onClick={paypalpayment} className="btn btn-primary">
                    Update PayPal
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Live Key Details</h5>

                  <hr></hr>

                  <div className=" text-start">
                    <div className="row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <label className="m-0">Enable PayPal Mode</label>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3 text-end">
                        <label className="switch">
                          <input
                            name="status"
                            id="status"
                            onChange={handleInputenable}
                            className="rs-input"
                            defaultChecked={
                              paypal_payment?.status == 1 ? true : false
                            }
                            checked={paypal_payment?.status == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="status"
                            id="status"
                            onChange={handleInputenable}
                            value={paypal_payment?.status == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Test PayPal Password</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="test_paypal_password"
                        name="test_paypal_password"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={paypal_payment?.test_paypal_password}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Live PayPal Username</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="live_paypal_username"
                        name="live_paypal_username"
                        value={paypal_payment?.live_paypal_username}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Live PayPal Signature</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="live_paypal_signature"
                        name="live_paypal_signature"
                        value={paypal_payment?.live_paypal_signature}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">PayPal Label</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="paypal_label"
                        name="paypal_label"
                        value={paypal_payment?.paypal_label}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Paypal;
