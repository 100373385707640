import { useParams } from "react-router-dom";
import React, { useState, useRef } from "react";
import axios from "axios";
import "./EditEpisodeVideo.css";
import Dropzone from "react-dropzone";

export default function EditEpisodeVideo() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { id } = useParams();
  const { seriesid } = useParams();

  // console.log(seriesid)
  // console.log(id)

  const [file, setFile] = useState("");
  const access_token = localStorage.getItem("access_token");
  const [message, setMessage] = useState("");

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFile(selectedFile);
  };

  const getFileSizeInMB = () => {
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      return fileSizeInMB.toFixed(2);
    }
    return null;
  };

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleUpload = async (files) => {
    const uploadUrl = `${process.env.REACT_APP_Baseurl}/admin/video-upload-file`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    const uploaders = files.map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", id);

      return axios.post(uploadUrl, formData, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
    });

    try {
      await Promise.all(uploaders);
      const responses = await Promise.all(uploaders);
      // console.log("Responses after upload:", responses);

      responses.forEach((response, index) => {
        // console.log(`Response for video ${index + 1}:`, response.data.Videos);
        // setEditUser(response.data.Episode);
        // setEpisode_id(response.data.Episode_id);
        // Process the response data as needed for each video
      });
      const uploadedDetails = files.map((file, index) => ({
        name: file.name,
        size: (file.size / (1024 * 1024)).toFixed(2), // Convert bytes to MB with 2 decimal places
      }));

      setUploadedFiles(uploadedDetails);
      setUploadCompleted(true);
      setUploadProgress(0);
      alert("All videos uploaded successfully!");
    } catch (error) {
      alert("Error uploading videos. Please try again.");
      setUploadProgress(0);
    }
  };

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid pt-3">
          <div className="p-2">
            <div className="iq-card">
              <div className="modal-body">
                <div>
                  <div>
                    <div>
                      <h3>Upload Full Episode Here</h3>

                      <div>
                        <Dropzone
                          accept="video/*"
                          onDrop={(acceptedFiles) =>
                            handleUpload(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p className="draganddrop">
                                Drag and drop video files here, or click to
                                select files
                              </p>
                            </div>
                          )}
                        </Dropzone>
                        <div className="text-center">
                          {uploadProgress > 0 && (
                            <div>
                              <p>Uploading... {uploadProgress}%</p>
                              <progress value={uploadProgress} max="100" />
                            </div>
                          )}

                          {uploadCompleted && (
                            <div>
                              {uploadedFiles.length > 0 && (
                                <div className="text-center">
                                  <p>Uploaded Files:</p>
                                  <ul>
                                    {uploadedFiles.map((file, index) => (
                                      <li key={index}>
                                        {file.name} - {file.size} MB
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
