import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Main.css";
import Dropzone from "react-dropzone";
import Button from "@mui/material/Button";
import Video_Management_01 from "../../components/Images/sidebar_icon/Video_Management_01.svg";
import Manage_LiveStream_01 from "../../components/Images/sidebar_icon/Manage_LiveStream_01.svg";
import Audio_Management_01 from "../../components/Images/sidebar_icon/Audio_Management_01.svg";
import Series_Episode_01 from "../../components/Images/sidebar_icon/Series_Episode_01.svg";

function Main() {
  const access_token = localStorage.getItem("access_token");
  const [video_id, setVideo_id] = useState("");
  const [audio_id, setAudio_id] = useState("");
  const [editUser, setEditUser] = useState({
    title: "",
    duration: "",
    slug: "",
  });

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleUpload = async (files) => {
    const uploadUrl = `${process.env.REACT_APP_Baseurl}/admin/video-upload-file`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    const uploaders = files.map((file) => {
      const formData = new FormData();
      formData.append("file", file);

      return axios.post(uploadUrl, formData, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
    });

    try {
      await Promise.all(uploaders);
      const responses = await Promise.all(uploaders);
      console.log("Responses after upload:", responses);

      responses.forEach((response, index) => {
        console.log(`Response for video ${index + 1}:`, response.data.Videos);
        setEditUser(response.data.Videos);
        setVideo_id(response.data.video_id);
        // Process the response data as needed for each video
      });
      const uploadedDetails = files.map((file, index) => ({
        name: file.name,
        size: (file.size / (1024 * 1024)).toFixed(2), // Convert bytes to MB with 2 decimal places
      }));

      setUploadedFiles(uploadedDetails);
      setUploadCompleted(true);
      setUploadProgress(0);
      alert("All videos uploaded successfully!");
    } catch (error) {
      alert("Error uploading videos. Please try again.");
      setUploadProgress(0);
    }
  };

  const handleUploadaudio = async (files) => {
    const uploadUrl = `${process.env.REACT_APP_Baseurl}/admin/uploadAudio`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    const uploaders = files.map((file) => {
      const formData = new FormData();
      formData.append("file", file);

      return axios.post(uploadUrl, formData, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
    });

    try {
      await Promise.all(uploaders);
      const responses = await Promise.all(uploaders);
      // console.log("Responses after upload:", responses);

      responses.forEach((response, index) => {
        // console.log(`Response for video ${index + 1}:`, response.data.Audio);
        setEditUser(response.data.Audio);
        var result = response.data.Audio_id;
        var resultdata = response.data.message;
        setAudio_id(result);
        // Process the response data as needed for each video
        alert(response.data.message + "!");
      });
      const uploadedDetails = files.map((file, index) => ({
        name: file.name,
        size: (file.size / (1024 * 1024)).toFixed(2), // Convert bytes to MB with 2 decimal places
      }));

      setUploadedFiles(uploadedDetails);
      setUploadCompleted(true);
      setUploadProgress(0);
    } catch (error) {
      alert("Error uploading videos. Please try again.");
      setUploadProgress(0);
    }
  };

  const resetUploadState = () => {
    setUploadProgress(0);
    setUploadCompleted(false);
    setUploadedFiles([]);
  };

  const navigate = useNavigate();
  const reloadvideo = () => {
    setVideo_id("");
    navigate("/content-video/" + video_id);
    window.location.reload();
  };
  const reloadaudio = () => {
    setAudio_id("");
    navigate("/content-audio/" + audio_id);
    window.location.reload();
  };

  return (
    <>
      <div
        className="modal fade libraries"
        id="staticBackdropvideo"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h3>Upload Video</h3>
              <div>
                <Dropzone
                  accept="video/*"
                  onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className="draganddrop">
                        Drag and drop video files here, or click to select files
                      </p>
                    </div>
                  )}
                </Dropzone>
                <div className="text-center">
                  {uploadProgress > 0 && (
                    <div>
                      <p>Uploading... {uploadProgress}%</p>
                      <progress value={uploadProgress} max="100" />
                    </div>
                  )}

                  {uploadCompleted && (
                    <div>
                      {uploadedFiles.length > 0 && (
                        <div className="text-center">
                          <p>Uploaded Files:</p>
                          <ul>
                            {uploadedFiles.map((file, index) => (
                              <li key={index}>
                                {file.name} - {file.size} MB
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <Button
                        variant="contained"
                        // onClick={() => {
                        //   handleNext();
                        // }}
                        onClick={reloadvideo}
                        className="btn btn-primary"
                      >
                        Proceed to Next
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade libraries"
        id="staticBackdropaudio"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h3>Upload Audio</h3>
              <div>
                <Dropzone
                  accept="audio/*"
                  onDrop={(acceptedFiles) => handleUploadaudio(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone" {...getRootProps()}>
                      <input {...getInputProps()} accept="audio/*" />
                      <p className="draganddrop">
                        Drag and drop Audio files here, or click to select files
                      </p>
                    </div>
                  )}
                </Dropzone>
                <div className="text-center">
                  {uploadProgress > 0 && (
                    <div>
                      <p>Uploading... {uploadProgress}%</p>
                      <progress value={uploadProgress} max="100" />
                    </div>
                  )}

                  {uploadCompleted && (
                    <div>
                      {uploadedFiles.length > 0 && (
                        <div className="text-center">
                          <p>Uploaded Files:</p>
                          <ul>
                            {uploadedFiles.map((file, index) => (
                              <li key={index}>
                                {file.name} - {file.size} MB
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <Button
                        variant="contained"
                        onClick={reloadaudio}
                        className="btn btn-primary"
                      >
                        Proceed to Next
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div id="content-page" className="content-page">
              <div className="col-lg-12 row py-3">
                <div className="col-4 col-sm-4 col-lg-4">
                  <h3>New Product</h3>
                </div>
              </div>

              <div className="card p-3">
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <label>What do you want to create ?</label>

                    <div className="col-lg-12 row ">
                      <div className="col-6 col-sm-6 col-lg-6 p-2">
                        <Link
                          className="content-setting"
                          to=""
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropvideo"
                        >
                          <div className="contentname text-center">
                            <div className="col-lg-12">
                              <div className="card-body p-4">
                                <img
                                  src={Video_Management_01}
                                  alt="flogo"
                                  className="w-25"
                                />
                                <h5 className="card-title">VIDEO</h5>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6 p-2">
                        <Link
                          className="content-setting "
                          to=""
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropaudio"
                        >
                          <div className="sitecard text-center">
                            <div className="col-lg-12">
                              <div className="card-body p-4">
                                <img
                                  src={Audio_Management_01}
                                  alt="flogo"
                                  className="w-25"
                                />
                                <h5 className="card-title">AUDIO</h5>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6 p-2">
                        <Link
                          className="content-setting "
                          to="/add-livestream"
                        >
                          <div className="sitecard text-center">
                            <div className="col-lg-12">
                              <div className="card-body p-4">
                                <img
                                  src={Manage_LiveStream_01}
                                  alt="flogo"
                                  className="w-25"
                                />
                                <h5 className="card-title">LIVE STREAM</h5>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6 p-2">
                        <Link
                          className="content-setting "
                          to="/add-series"
                        >
                          <div className="contentname text-center">
                            <div className="col-lg-12">
                              <div className="card-body p-4">
                                <img
                                  src={Series_Episode_01}
                                  alt="flogo"
                                  className="w-25"
                                />
                                <h5 className="card-title">SERIES</h5>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Main;
