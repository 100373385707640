import './App.css';
import AllRoutes from './AllRoutes';
import './components/museo_font/stylesheet.css';
import 'rsuite/dist/rsuite.min.css';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import { GlobalStyles } from "./GlobalStyles";
import { ThemeProvider } from "styled-components";
import Metadata from './layout/Others/Metadata';
import { useEffect } from 'react';
import { adminThemeApiAction, defaultDarkTheme, defaultLightTheme } from './components/CommonMethod/CommonMethods';
import { useMyContext } from './components/Provider/CommonProvider';
import { useLocation } from 'react-router-dom';
import { setItem } from './Utils/localStorageUtils';
function App() {
  const { themeMode } = useMyContext();
  const location = useLocation()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminThemeApiAction();
        // console.log("response", response);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [])
  
  useEffect(() => {
    if (window.location.pathname !== "/login") {
      setItem('location', window.location.pathname);
    }
  }, [location])

  return (
    <>
      <Metadata></Metadata>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      <ThemeProvider theme={themeMode ? defaultDarkTheme : defaultLightTheme}>
        <GlobalStyles />
        <AllRoutes />
      </ThemeProvider>
    </>
  );
}

export default App;
