import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdDelete } from "react-icons/md";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";

function Slider(props) {
  const [getslider, setslider] = useState([]);
  const [itemToDeleteslider, setItemToDeleteslider] = useState([]);
  const [selectAllslider, setSelectAllslider] = useState(false);
  const [modalCloseslider, setModalCloseslider] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [loading, setLoading] = useState(true);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchDataslidervideo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/slider/index`,
        { headers: headers }
      );
      setslider(response?.data?.slider);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(async () => {
    fetchDataslidervideo();
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(getslider);
    const [movedItem] = reorderedItems?.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setslider(reorderedItems);
    saveShuffleOrder(reorderedItems);
  };

  const saveShuffleOrder = async (updatedItems) => {
    setProcessing(true);
    setShowOverlay(true);

    if (!updatedItems || updatedItems?.length === 0) {
      console.error("Menu items are empty");
      return;
    }

    const positions = updatedItems?.map((menuItem) => menuItem?.id);
    const ids = updatedItems?.map((_, index) => index + 1);

    const requestData = {
      id: JSON.stringify(positions),
      position: JSON.stringify(ids),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/slider/order`,
        requestData,
        { headers: headers }
      );

      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const handleSelectAllslider = () => {
    setSelectAllslider(!selectAllslider);
    setItemToDeleteslider(
      selectAllslider ? [] : getslider?.map((item) => item?.id)
    );
  };

  const handleMultiSelectslider = (itemId) => {
    if (itemToDeleteslider?.includes(itemId)) {
      setItemToDeleteslider(
        itemToDeleteslider?.filter((id) => id !== itemId)
      );
    } else {
      setItemToDeleteslider([...itemToDeleteslider, itemId]);
    }
  };

  const singleDeleteslider = (id) => {
    setItemToDeleteslider([id]);
    setModalCloseslider(true);
    setSelectAllslider(false);
  };

  const closesliderModal = () => {
    setModalCloseslider(!modalCloseslider);
  };

  const openModalMultiDeleteslider = () => {
    setModalCloseslider(true);
  };

  const handleDeletesliderData = () => {
    closesliderModal();
    sliderDeleteOperation();
  };

  const sliderDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteslider + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/slider/multi-delete
        `,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <>
      <div
        className={`modal  packageModel ${modalCloseslider ? "show" : ""
          }`}
        tabIndex="-1"
        style={{ display: modalCloseslider ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closesliderModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteslider?.length === 1 ? (
                <p className="fs-6">
                  Are you sure you want to delete this Slider?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected Slider ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={closesliderModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeletesliderData}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-6">
              <h4>
                Slider{" "}
                <span className="fs-6">
                  (Display all {getslider?.length} Slider)
                </span>
              </h4>
            </div>

            <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
              {itemToDeleteslider?.length > 0 && (
                <button
                  type="button"
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModalMultiDeleteslider}
                >
                  <MdDelete className="exportIcon " />
                  Delete
                </button>
              )}
              <Link
                to="/addslider"
                className="btn btn-primary fw-bolder ms-2"
              >
                <i className="fa fa-plus-circle"></i> Add Slider
              </Link>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="bootstrapTable ">
              <div className="col-12 p-0">
                <div className="row m-0 p-0">
                  <div className="col-lg-2 col-4 data">
                    {" "}
                    <label>
                      <input
                        type="checkbox"
                        checked={selectAllslider}
                        onChange={handleSelectAllslider}
                      />
                    </label>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 col-4 data">
                    {" "}
                    <div>SLIDER NAME</div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-4 data">
                    {" "}
                    <div>SLIDER IMAGE</div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-4 data">
                    {" "}
                    <div>PLAYER IMAGE</div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 col-4 data">
                    {" "}
                    <div>ACTION</div>
                  </div>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="menu-items">
                    {(provided) => (
                      <ul {...provided.droppableProps} ref={provided.innerRef}>
                        {loading ? (
                          <div className="noRecord">
                            {" "}
                            <Response_Loading_processing></Response_Loading_processing>
                            Loading...
                          </div>
                        ) : getslider?.length <= 0 ? (
                          <div className="noRecord">
                            {" "}
                            <p>No Sliders Available. </p>
                          </div>
                        ) : (
                          getslider?.map((item, index) => (
                            <Draggable
                              key={item?.id.toString()}
                              draggableId={item?.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`theme-bg-color orderborder ${item?.order
                                    } index ${index + 1}w-100`}
                                >
                                  <div className="row m-0 p-0 align-items-center">
                                    <div className="col-lg-2 col-4 data_th">
                                      <input
                                        type="checkbox"
                                        checked={itemToDeleteslider?.includes(
                                          item?.id
                                        )}
                                        onChange={() =>
                                          handleMultiSelectslider(
                                            item?.id
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-4 data_th">
                                      <div>{item?.title}</div>
                                    </div>{" "}
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-4 data_th">
                                      <div><img
                                        src={item?.slider_image_url}
                                        alt="Image"
                                        // className="w-100 "
                                        height="80px"
                                      /></div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-4 data_th">
                                      <div><img
                                        src={item?.slider_player_image_url}
                                        alt="Image"
                                        // className="w-100 "
                                        height="80px"
                                      /></div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-4 data_th">
                                      <div className="editdropdown ">
                                        <span className="editdropdown-button">
                                          <span>
                                            <i
                                              className="fa fa-ellipsis-v"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </span>
                                        <div className="editdropdown-menu ">
                                          <Link
                                            to={
                                              "/editslider/" +
                                              item?.id
                                            }
                                          >
                                            <span>
                                              <img
                                                src={edititem}
                                                alt="flogo"
                                                width={20}
                                                height={20}
                                              />
                                              <span className="ms-2">
                                                Edit Slider
                                              </span>
                                            </span>
                                          </Link>
                                          <div
                                            onClick={() =>
                                              singleDeleteslider(item?.id)
                                            }
                                            className="commonActionPadding"
                                          >
                                            <img
                                              src={deleteitem}
                                              alt="flogo"
                                              width={20}
                                              height={20}
                                            />
                                            <span className="ms-2">
                                              Delete Slider
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Slider;
