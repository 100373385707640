import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import backarrow from "../../../../components/Images/backarrow.svg";

function Registration_Setting(props) {
  const [message, setMessage] = useState("");

  const [setting, setSetting] = useState({
    // Advertisement_Setting
    ads_on_videos: "",
    cpc_advertiser: "",
    featured_pre_ad: "",
    featured_mid_ad: "",
    featured_post_ad: "",
    cpc_admin: "",
    cpv_advertiser: "",
    cpv_admin: "",
    default_ads_url: "",
    ads_play_unlimited_period: "",
    ads_on_livestream: "",
    ads_on_audios: "",
    ads_on_episodes: "",
    // Advertisement_Setting
    //coupon code
    new_subscriber_coupon: "",
    discount_percentage: "",
    coupon_code: "",
    //coupon code
    //email setting
    google_oauth_key: "",
    coupon_status: "",
    google_tracking_id: "",
    system_email: "",
    system_phone_number: "",
    system_address: "",
    //email setting
    //login setting
    notification_icon: "",
    notification_key: "",
    login_text: "",
    login_content: "",
    signature: "",
    //login setting
    //geo fencing
    id: "",
    geofencing: "",
    geofencing_status: "",
    //geo fencing
    // payout setting
    payout_method: "",
    //payout setting
    //ppv setting
    ppv_status: "",
    ppv_rent: "",
    ppv_price: "",
    ppv_hours: "",
    ios_product_id: "",
    ios_plan_price: "",
    expiry_time_started: "",
    expiry_day_notstarted: "",
    expiry_hours_notstarted: "",
    expiry_min_notstarted: "",
    PPV_Individual_Contents: "",
    //ppv setting
    // capcha setting
    captcha_site_key: "",
    captcha_secret_key: "",
    captcha: "",
    //capcha setting
    //registration settings
    free_registration: "",
    activation_email: "",
    premium_upgrade: "",
    access_free: "",
    enable_landing_page: "",
    //registration settings
    //search settings
    search_title_status: "",
    search_category_status: "",
    search_tags_status: "",
    search_description_status: "",
    search_details_status: "",
    //search settings
    //series settings
    series_season: "",
    series_networks_status: "",
    //series settings
    //site settings
    website_name: "",
    website_description: "",
    logo_height: "",
    logo_width: "",
    login_content: "",
    favicon: "",
    logo: "",
    //site settings
    //social network settings
    facebook_page_id: "",
    google_page_id: "",
    twitter_page_id: "",
    instagram_page_id: "",
    linkedin_page_id: "",
    whatsapp_page_id: "",
    skype_page_id: "",
    youtube_page_id: "",
    email_page_id: "",
    tiktok_page_id: "",
    //social network settings
    //video settings
    videos_per_page: "",
    posts_per_page: "",
    show_description: "",
    show_Links_and_details: "",
    show_genre: "",
    show_languages: "",
    show_subtitle: "",
    show_artist: "",
    show_recommended_videos: "",
    show_views: "",
    videos_expiry_status: "",
    epg_status: "",
    //video settings
    earn_amount:"",
    video_clip_enable:"",
    enable_ppv_rent: "",
  });

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, {
        headers: headers,
      })
      .then((response) => {
        var setting = response?.data?.settings[0];

        setSetting(setting);
      })
      .catch((error) => console.log(error));
  }, []);


  const handleInput = (e) => {
    if (e.target.checked === true) {
      setSetting({ ...setting, [e.target.name]: 1 });
    } else {
      setSetting({ ...setting, [e.target.name]: 0 });
    }
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
   
    
    const enable_ppv_rent = Number.isNaN(parseInt(setting?.enable_ppv_rent, 10)) ? 0 : parseInt(setting?.enable_ppv_rent, 10);

    // advertisement setting
    const ads_on_videos = Number.isNaN(parseInt(setting?.ads_on_videos, 10)) ? 0 : parseInt(setting?.ads_on_videos, 10);
    const ads_play_unlimited_period = Number.isNaN(parseInt(setting?.ads_play_unlimited_period, 10)) ? 0 : parseInt(setting?.ads_play_unlimited_period, 10);
    const ads_on_livestream = Number.isNaN(parseInt(setting?.ads_on_livestream, 10)) ? 0 : parseInt(setting?.ads_on_livestream, 10);
    const ads_on_audios = Number.isNaN(parseInt(setting?.ads_on_audios, 10)) ? 0 : parseInt(setting?.ads_on_audios, 10);
    const ads_on_episodes = Number.isNaN(parseInt(setting?.ads_on_episodes, 10)) ? 0 : parseInt(setting?.ads_on_episodes, 10);

    //coupon setting      
    const coupon_code = Number.isNaN(parseInt(setting?.coupon_code, 10)) ? 0 : parseInt(setting?.coupon_code, 10);
    
  
    //payout settings   
    const payout_method = Number.isNaN(parseInt(setting?.payout_method, 10)) ? 0 : parseInt(setting?.payout_method, 10);

  
    //registration settings
    const free_registration = Number.isNaN(parseInt(setting?.free_registration, 10)) ? 0 : parseInt(setting?.free_registration, 10);


    //search settings
    const search_title_status = Number.isNaN(parseInt(setting?.search_title_status, 10)) ? 0 : parseInt(setting?.search_title_status, 10);
        const search_category_status = Number.isNaN(parseInt(setting?.search_category_status, 10)) ? 0 : parseInt(setting?.search_category_status, 10);
    const search_tags_status = Number.isNaN(parseInt(setting?.search_tags_status, 10)) ? 0 : parseInt(setting?.search_tags_status, 10);
    const search_description_status = Number.isNaN(parseInt(setting?.search_description_status, 10)) ? 0 : parseInt(setting?.search_description_status, 10);
    const search_details_status = Number.isNaN(parseInt(setting?.search_details_status, 10)) ? 0 : parseInt(setting?.search_details_status, 10);


    //serires settings
    const series_networks_status = Number.isNaN(parseInt(setting?.series_networks_status, 10)) ? 0 : parseInt(setting?.series_networks_status, 10);
    
    // video settings 
    const videos_expiry_status = Number.isNaN(parseInt(setting?.videos_expiry_status, 10)) ? 0 : parseInt(setting?.videos_expiry_status, 10);
    const videos_per_page = Number.isNaN(parseInt(setting?.videos_per_page, 10)) ? 0 : parseInt(setting?.videos_per_page, 10);
    const epg_status = Number.isNaN(parseInt(setting?.epg_status, 10)) ? 0 : parseInt(setting?.epg_status, 10);
    const show_genre = Number.isNaN(parseInt(setting?.show_genre, 10)) ? 0 : parseInt(setting?.show_genre, 10);
    const show_languages = Number.isNaN(parseInt(setting?.show_languages, 10)) ? 0 : parseInt(setting?.show_languages, 10);
    const show_subtitle = Number.isNaN(parseInt(setting?.show_subtitle, 10)) ? 0 : parseInt(setting?.show_subtitle, 10);
    const show_artist = Number.isNaN(parseInt(setting?.show_artist, 10)) ? 0 : parseInt(setting?.show_artist, 10);
    const show_recommended_videos = Number.isNaN(parseInt(setting?.show_recommended_videos, 10)) ? 0 : parseInt(setting?.show_recommended_videos, 10);
    const show_views = Number.isNaN(parseInt(setting?.show_views, 10)) ? 0 : parseInt(setting?.show_views, 10);
    const show_Links_and_details = Number.isNaN(parseInt(setting?.show_Links_and_details, 10)) ? 0 : parseInt(setting?.show_Links_and_details, 10);
    const show_description = Number.isNaN(parseInt(setting?.show_description, 10)) ? 0 : parseInt(setting?.show_description, 10);
   
    const editInputvalue = {

      // advertisement setting
      ads_on_videos: ads_on_videos,
      default_ads_url: setting?.default_ads_url,
      featured_pre_ad: setting?.featured_pre_ad,
      featured_mid_ad: setting?.featured_mid_ad,
      featured_post_ad: setting?.featured_post_ad,
      cpv_admin: setting?.cpv_admin,
      cpv_advertiser: setting?.cpv_advertiser,
      cpc_admin: setting?.cpc_admin,
      cpc_advertiser: setting?.cpc_advertiser,
      ads_play_unlimited_period: ads_play_unlimited_period,
      ads_on_livestream: ads_on_livestream,
      ads_on_audios: ads_on_audios,
      ads_on_episodes: ads_on_episodes,

      //coupon setting      
      discount_percentage: setting?.discount_percentage,
      coupon_code: coupon_code,
      new_subscriber_coupon: setting?.new_subscriber_coupon,

      //email settings
      coupon_status: setting?.coupon_status,
      google_tracking_id: setting?.google_tracking_id,
      google_oauth_key: setting?.google_oauth_key,
      system_email: setting?.system_email,
      system_phone_number: setting?.system_phone_number,

      //login settings      
      login_text: setting?.login_text,
      signature: setting?.signature,
      login_content: setting?.login_content,
      notification_key: setting?.notification_key,

      //payout setting
      payout_method: payout_method,

      //ppv settings
      ppv_status: setting?.ppv_status,
      PPV_Individual_Contents: setting?.PPV_Individual_Contents,
      ppv_price: setting?.ppv_price,
      ppv_hours: setting?.ppv_hours,
      ios_plan_price: setting?.ios_plan_price,
      ios_product_id: setting?.ios_product_id,
      expiry_time_started: setting?.expiry_time_started,
      expiry_day_notstarted: setting?.expiry_day_notstarted,
      expiry_hours_notstarted: setting?.expiry_hours_notstarted,
      expiry_min_notstarted: setting?.expiry_min_notstarted,

      //registration settings
      enable_landing_page: setting?.enable_landing_page,
      free_registration: free_registration,
      activation_email: setting?.activation_email,
      premium_upgrade: setting?.premium_upgrade,
      access_free: setting?.access_free,

      //search settings
      search_title_status: search_title_status,
      search_category_status: search_category_status,
      search_tags_status: search_tags_status,
      search_description_status: search_description_status,
      search_details_status: search_details_status,

      //serires settings
      series_season: setting?.series_season,
      series_networks_status: series_networks_status,

      //site settings
      website_name: setting?.website_name,
      website_description: setting?.website_description,
      logo: setting?.logo,
      logo_height: setting?.logo_height,
      logo_width: setting?.logo_width,
      favicon: setting?.favicon,

      //social network settings
      facebook_page_id: setting?.facebook_page_id,
      google_page_id: setting?.google_page_id,
      twitter_page_id: setting?.twitter_page_id,
      instagram_page_id: setting?.instagram_page_id,
      linkedin_page_id: setting?.linkedin_page_id,
      whatsapp_page_id: setting?.whatsapp_page_id,
      skype_page_id: setting?.skype_page_id,
      youtube_page_id: setting?.youtube_page_id,
      tiktok_page_id: setting?.tiktok_page_id,
      email_page_id: setting?.email_page_id,

      //default_time_zone
      default_time_zone: setting?.default_time_zone,

      // video settings 
      videos_per_page: videos_per_page,
      posts_per_page: setting?.posts_per_page,
      show_description: show_description,
      show_Links_and_details: show_Links_and_details,
      show_genre: show_genre,
      show_languages: show_languages,
      show_subtitle: show_subtitle,
      show_artist: show_artist,
      show_recommended_videos: show_recommended_videos,
      show_views: show_views,
      videos_expiry_status: videos_expiry_status,
      epg_status: epg_status,

      epg_status: enable_ppv_rent,

      earn_amount: setting?.earn_amount ? parseInt(setting?.earn_amount, 10) : 0,
      video_clip_enable: setting?.video_clip_enable ? parseInt(setting?.video_clip_enable, 10) : 0,

    };


    setProcessing(true);
    setShowOverlay(true);

    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/setting/store`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );
    let resjson = await res?.json();

    if (resjson?.status === true) {
      var resultapi = resjson;

      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson?.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };


  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid">
          <div className="col-lg-12 row py-3">
            <Link to="/settings" className="backarrow">
              <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
              <span> Back</span>
            </Link>
            <div className="col-6 col-sm-6 col-lg-6">
              <h3>Registration Settings</h3>
            </div>

            <div className="col-6 col-sm-6 col-lg-6 text-end">
              <button onClick={handleUpdate} className="btn btn-primary">
                Update Settings
              </button>
            </div>
          </div>

          <div>
            {showOverlay && <Response_Processing></Response_Processing>}
          </div>

          <div className="iq-card">
            <div className="" id="registration">
              <div className="panel panel-primary " data-collapsed="0">
                {/* <div className="panel-heading">
                  <h4 className="panel-title mb-4">Registration Setting</h4>
                  <hr />
                </div> */}
                <div className="panel-body">
                  <div className="col-lg-12">
                    <div className="">
                      <Table>
                        <thead>
                          <tr className="title">
                            <th>Settings</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {" "}
                              <label className="mt-1">
                                Enable Free Registration{" "}
                              </label>
                            </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="free_registration"
                                    onChange={handleInput}
                                    defaultChecked={
                                      parseInt(setting?.free_registration) === 1
                                        ? true
                                        : false
                                    }
                                    checked={
                                      parseInt(setting?.free_registration) === 1
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="free_registration"
                                    onChange={handleInput}
                                    value={
                                      parseInt(setting?.free_registration) === 1
                                        ? "1"
                                        : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td>
                              {" "}
                              <label className="mt-1">
                                Require users to verify account by email
                              </label>{" "}
                            </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="activation_email"
                                    onChange={handleInput}
                                    defaultChecked={
                                      parseInt(setting?.activation_email) === 1
                                        ? true
                                        : false
                                    }
                                    checked={
                                      parseInt(setting?.activation_email) === 1
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="activation_email"
                                    onChange={handleInput}
                                    value={parseInt(setting?.activation_email)}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td>
                              <label className="mt-1">
                                Enable registered users ability to upgrade to
                                subscriber
                              </label>
                            </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="premium_upgrade"
                                    onChange={handleInput}
                                    defaultChecked={
                                      parseInt(setting?.premium_upgrade) === 1
                                        ? true
                                        : false
                                    }
                                    checked={
                                      parseInt(setting?.premium_upgrade) === 1
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="premium_upgrade"
                                    onChange={handleInput}
                                    value={
                                      parseInt(setting?.premium_upgrade) === 1
                                        ? "1"
                                        : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td>
                              <label className="mt-1">
                                Can Access Free Content
                              </label>
                            </td>
                            <td>
                              <div className="mt-1">
                                <div className="mt-1">
                                  <label className="switch">
                                    <input
                                      name="access_free"
                                      onChange={handleInput}
                                      defaultChecked={
                                        parseInt(setting?.access_free) === 1
                                          ? true
                                          : false
                                      }
                                      checked={
                                        parseInt(setting?.access_free) === 1
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="access_free"
                                      onChange={handleInput}
                                      value={
                                        parseInt(setting?.access_free) === 1
                                          ? "1"
                                          : "0"
                                      }
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td>
                              {" "}
                              <label className="mt-1">
                                Enable Landing Page
                              </label>
                            </td>
                            <td>
                              <div className="mt-1">
                                <label className="switch">
                                  <input
                                    name="enable_landing_page"
                                    onChange={handleInput}
                                    defaultChecked={
                                      parseInt(setting?.enable_landing_page) ===
                                        1
                                        ? true
                                        : false
                                    }
                                    checked={
                                      parseInt(setting?.enable_landing_page) ===
                                        1
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="enable_landing_page"
                                    onChange={handleInput}
                                    value={
                                      parseInt(setting?.enable_landing_page) ===
                                        1
                                        ? "1"
                                        : "0"
                                    }
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Registration_Setting;
