import React from "react";
import { Link } from "react-router-dom";

function ChannelPayout() {
  const datas = [
    {
      name: "Flicknexs",
      count: "10",
      commission: "20",
      admin: "30",
      moderator: "50",
      amount: "10",
    },
  ];

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div id="content-page" className="content-page">
              <div className=" py-3">
                <h4 className="card-title">Channel Partners Payouts</h4>
              </div>

              <div className="iq-card">
                <div className="iq-card-header  justify-content-between"></div>

                <div className="row p-3">
                  <div className="col-md-12">
                    <div>
                      <h4 className="card-title">Channel Partners</h4>
                    </div>

                    <div className="d-flex justify-content-between p-3">
                      <div className="d-flex">
                        <p>Show 10</p>
                        <p>&nbsp; &nbsp; entries</p>
                      </div>
                      <div className="d-flex">
                        <p>Search:</p>
                        <input
                          type="search"
                          id="gsearch"
                          name="gsearch"
                          className="border-0 search-rev"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <table className="table" id="user_tabledss">
                          <thead>
                            <tr className="r1">
                              <th>#</th>
                              <th>User Name</th>
                              <th>Purchase Count</th>
                              <th>Total Commission</th>
                              <th>Admin Commission</th>
                              <th>Moderator Commission</th>
                              <th>Paid Amount</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {/* {datas.map((item, key) => (
                            <thead>
                              <tr className="r1">
                              <td key={key}> {key + 1} </td>

                                <td>{item?.name}</td>
                                <td>{item?.count}</td>
                                <td>{item?.commission}</td>
                                <td>{item?.admin} </td>
                                <td>{item?.moderator}</td>
                                <td>{item?.amount}</td>
                                <td className="table-content">
                                   
                                    <Link to={""}>
                                      <i className="fa fa-pencil-square" aria-hidden="true"></i>
                                    </Link>
                                    <Link>
                                      <i className="fa fa-trash" aria-hidden="true"></i>
                                    </Link>
                                  </td>
                                
                               
                              </tr>
                            </thead>
                          ))} */}
                          <tr className="r1">
                            <td></td>
                            <td></td>
                            <td></td>

                            <td colSpan={2}>No data available in table</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>

                          <tbody></tbody>
                        </table>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ChannelPayout;
