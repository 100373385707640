// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iconBtn {
  width: 38px;
  height: 38px;
}
.iconBtn>i{
    transform: scale(1.2);
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Content_Partner/ContentPartnerApproval.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;AACA;IACI,qBAAqB;AACzB","sourcesContent":[".iconBtn {\n  width: 38px;\n  height: 38px;\n}\n.iconBtn>i{\n    transform: scale(1.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
