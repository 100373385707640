import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "./Video.css";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

import "./Mastervideolist.css";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";
import defaultImage from "../../../components/Images/loading/default_image.png";

function Mastervideolist(props) {
  // const [masterOverAllData, setMasterOverAllData] = useState([]);
  const [totalMastercount, setTotalMastercount] = useState();
  const [videoData, setVideoData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [liveData, setLiveData] = useState([]);
  const [episodeData, setEpisodeData] = useState([]);

  const [filteredVideoData, setFilteredVideoData] = useState([]);
  const [filteredAudioData, setFilteredAudioData] = useState([]);
  const [filteredLiveData, setFilteredLiveData] = useState([]);
  const [filteredEpisodeData, setFilteredEpisodeData] = useState([]);

  const [selectAllVideo, setSelectAllVideo] = useState(false);
  const [selectAllAudio, setSelectAllAudio] = useState(false);
  const [selectAllLive, setSelectAllLive] = useState(false);
  const [selectAllEpisode, setSelectAllEpisode] = useState(false);

  const [filterVideo, setFilterVideo] = useState("");
  const [filterAudio, setFilterAudio] = useState("");
  const [filterLive, setFilterLive] = useState("");
  const [filterEpisode, setFilterEpisode] = useState("");

  const [itemToDeleteVideo, setItemToDeleteVideo] = useState([]);
  const [itemToDeleteAudio, setItemToDeleteAudio] = useState([]);
  const [itemToDeleteLive, setItemToDeleteLive] = useState([]);
  const [itemToDeleteEpisode, setItemToDeleteEpisode] = useState([]);

  const [modelClose, setModelClose] = useState(false);
  const [modalCloseLive, setModalCloseLive] = useState(false);
  const [modalCloseAudio, setModalCloseAudio] = useState(false);
  const [modalCloseEpisode, setModalCloseEpisode] = useState(false);

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [page, setPage] = useState(1);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchApi = async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Master-list`, {
        headers: headers,
      })
      .then((response) => {
        // const masterAllList = response;
        const videoList = response?.data?.Videos;
        const audioList = response?.data?.audios;
        const liveList = response?.data?.LiveStream;
        const episodeList = response?.data?.Episode;
        // setMasterOverAllData(masterAllList);
        setTotalMastercount(response?.data?.master_count);
        const updatedVideoList = videoList?.map((item) => {
          const UploadStatus =
            item?.uploaded_by === "1"
              ? "Admin"
              : item?.uploaded_by === "4"
                ? "Channel Partner"
                : item?.uploaded_by === "5"
                  ? "Content Partner"
                  : "";
          const statusText =
            item?.active === 1 &&
              item?.publish_type === "publish_now" &&
              item?.status === 1 &&
              item?.draft === 1
              ? "PUBLISHED"
              : item?.active == null &&
                item?.publish_type === "publish_later" &&
                item?.status == null &&
                item?.draft == null
                ? "SCHEDULED"
                : "DRAFT";

          return { ...item, uploaded_by: UploadStatus, active: statusText };
        });
        const updatedLiveList = liveList?.map((item) => {
          const UploadStatus =
            item?.uploaded_by === "1"
              ? "Admin"
              : item?.uploaded_by === "4"
                ? "Channel Partner"
                : item?.uploaded_by === "5"
                  ? "Content Partner"
                  : "";
          const statusText =
            item?.active === 1 &&
              item?.publish_type === "publish_now" &&
              item?.status === 1
              ? // &&
              // item?.draft === 1
              "PUBLISHED"
              : item?.active == null &&
                item?.publish_type === "publish_later" &&
                item?.status == null
                ? //  &&
                // item?.draft == null
                "SCHEDULED"
                : "DRAFT";

          return { ...item, uploaded_by: UploadStatus, active: statusText };
        });
        const updatedAudioList = audioList?.map((item) => {
          const uploadBy =
            item?.uploaded_by === "1"
              ? "Admin"
              : item?.uploaded_by === "4"
                ? "Channel Partner"
                : item?.uploaded_by === "5"
                  ? "Content Partner"
                  : "";
          const statusText =
            item?.active === 1
              ? "PUBLISHED"
              : item?.active === 0 || item?.active === null
                ? "UNPUBLISHED"
                : "DRAFT";

          return { ...item, uploaded_by: uploadBy, active: statusText };
        });
        const updatedEpisodeList = episodeList?.map((item) => {
          const uploadBy =
            item?.uploaded_by === "1"
              ? "Admin"
              : item?.uploaded_by === "4"
                ? "Channel Partner"
                : item?.uploaded_by === "5"
                  ? "Content Partner"
                  : "";
          const statusText =
            item?.active === 1
              ? "PUBLISHED"
              : item?.active === 0 || item?.active === null
                ? "UNPUBLISHED"
                : "DRAFT";

          return { ...item, uploaded_by: uploadBy, active: statusText };
        });
        setVideoData(updatedVideoList);
        setAudioData(updatedAudioList);
        setLiveData(updatedLiveList);
        setEpisodeData(updatedEpisodeList);
        setFilteredVideoData(updatedVideoList);
        setFilteredLiveData(updatedLiveList);
        setFilteredAudioData(updatedAudioList);
        setFilteredEpisodeData(updatedEpisodeList);
        setLoading(false);
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  useEffect(async () => {
    fetchApi();
  }, []);
  ///General Pagination
  const commonPaginationConfig = (sizePerPageList) => {
    return paginationFactory({
      page: 1,
      sizePerPage: 10,
      nextPageText: "Next",
      prePageText: "Prev",
      firstPageText: <MdKeyboardDoubleArrowLeft />,
      lastPageText: <MdKeyboardDoubleArrowRight />,
      showTotal: true,
      alwaysShowAllBtns: true,
      sizePerPageList: sizePerPageList,
    });
  };
  const videoSizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "All", value: filteredVideoData?.length },
  ];
  const liveSizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "All", value: filteredLiveData?.length },
  ];
  const audioSizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "All", value: filteredAudioData?.length },
  ];
  const episodeSizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "All", value: filteredEpisodeData?.length },
  ];

  ////  Video Started

  const handleClear = () => {
    fetchApi();
    setFilterVideo("");
  };
  const handleFilterChange = (event) => {
    const searchTerm = event.target.value;
    setFilterVideo(searchTerm);
    const searchTermLower = searchTerm?.toLowerCase();
    const filteredResults = videoData?.filter((item) => {
      return (
        (typeof item?.title === "string" &&
          item?.title.toLowerCase().includes(searchTermLower)) ||
        item?.uploaded_by.toLowerCase().includes(searchTermLower) ||
        (typeof item?.active === "string" &&
          item?.active.toLowerCase().includes(searchTermLower)) ||
        item?.upload_on.toLowerCase().includes(searchTermLower)
      );
    });
    setFilteredVideoData(filteredResults);
  };
  const conditionalGeneratePDF = (get) => {
    const doc = new jsPDF();
    doc.text("VIDEO-LIST", 10, 10);
    const columns = ["Index", "ID", "uploaded_by", "status", "banner"];
    const allDataPDF = videoData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.uploaded_by,
      item?.active,
    ]);
    const filterPDF = filteredVideoData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.uploaded_by,
      item?.active,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save("VIDEO-LIST.pdf");
  };

  const generateXLSX = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? videoData : filteredVideoData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "VIDEO-LIST.xlsx");
  };

  const exportHeaders = [
    { label: "ID", key: "id" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "STATUS", key: "active" },
  ];

  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit-video/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Edit Video</span>
            </span>
          </Link>
          <div
            onClick={() => singleDelete(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2">Delete Video</span>
          </div>
        </div>
      </div>
    );
  };

  const videoFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <img src={data} className="profile" alt="image_all" />
        <div className="rightVideoTitle">
          <p>{row?.title}</p>
          <span className="me-1 text-dark dateText ">{row?.duration}</span>
          <span className="dateText"> {row?.upload_on}</span>
          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`${process.env.REACT_APP_Baseurl_Frontend}/videos/${row?.slug}`}
                      >
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const statusFormatter = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "SCHEDULED"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };
  const columns = [
    {
      dataField: "Thumbnail",
      text: "Videos",
      formatter: videoFormatter,
      sort: true,
    },
    {
      dataField: "uploaded_by",
      text: "Upload by",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusFormatter,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteVideo([...itemToDeleteVideo, row?.id]);
        } else {
          setItemToDeleteVideo(
            itemToDeleteVideo.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteVideo,
    selectionHeaderRenderer: ({ mode, checked }) => (
      <div>
        <input type="checkbox" checked={checked} onChange={handleAll} />
      </div>
    ),
  };
  const closeModalvideo = () => {
    setModelClose(!closeModalvideo);
  };
  const openModalvideo = () => {
    setModelClose(true);
  };
  const singleDelete = (id) => {
    setModelClose(true);
    setItemToDeleteVideo([id]);
  };
  const handleAll = () => {
    setSelectAllVideo(!selectAllVideo);
    setItemToDeleteVideo(
      selectAllVideo ? [] : filteredVideoData?.map((item) => item?.id)
    );
  };
  const handleDeleteVideoDatavideo = () => {
    handleConfirmDeletevideo();
    closeModalvideo();
  };
  const handleConfirmDeletevideo = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("video_id", "[" + itemToDeleteVideo + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/videos/multi-videos-delete`,
        formData,
        { headers: headers }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  //// video code end

  ///Live Stream Started
  const selectRowLive = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteLive([...itemToDeleteLive, row?.id]);
        } else {
          setItemToDeleteLive(itemToDeleteLive?.filter((id) => id !== row?.id));
        }
      }
    },
    selected: itemToDeleteLive,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAllLive}
        />
      </div>
    ),
  };
  const liveFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <img src={row?.Thumbnail} className="profile" alt="image_all" />
        <div className="rightVideoTitle">
          <p>{data}</p>
          <span className="me-1 text-dark dateText"> {row?.duration}</span>
          <span className="dateText">{row?.upload_on}</span>
          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`${process.env.REACT_APP_Baseurl_Frontend}/live/${row?.slug}`}
                      >
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  const statusLiveFormatter = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "SCHEDULED"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };

  const actionLiveFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit/add-livestream/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Edit Live</span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteLive(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2"> Delete Live</span>
          </div>
        </div>
      </div>
    );
  };

  const liveColumns = [
    {
      dataField: "title",
      text: "Live Videos",
      sort: true,
      formatter: liveFormatter,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded by",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusLiveFormatter,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionLiveFormatter,
    },
  ];

  const exportHeadersLive = [
    { label: "ID", key: "id" },
    { label: "VIDEOS", key: "title" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "STATUS", key: "active" },
    { label: "SLIDER", key: "banner" },
    { label: "UPLOAD ON", key: "upload_on" },
  ];
  const conditionalGeneratePDFLive = (get) => {
    const doc = new jsPDF();
    doc.text("ALL LIVE LIST ", 10, 10);
    const columns = [
      "Index",
      "ID",
      "VIDEOS",
      "UPLOADED BY",
      "STATUS",
      "BANNER",
      "UPLOAD ON",
    ];
    const allDataPDF = liveData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.uploaded_by,
      item?.active,
      item?.banner,
      item?.upload_on,
    ]);
    const filterPDF = filteredLiveData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.uploaded_by,
      item?.active,
      item?.banner,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save("ALL-LIVE-LIST.pdf");
  };
  const generateXLSXLIVE = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? liveData : filteredLiveData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "ALL-LIVE-LIST.xlsx");
  };
  const handleFilterChangeLive = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilterLive(searchTerm);
    const filteredResults = liveData.filter((item) =>
      ["id", "title", "uploaded_by", "active", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredLiveData(filteredResults);
  };
  const handleClearLive = () => {
    fetchApi();
    setFilterLive("");
  };
  const closeLiveModal = () => {
    setModalCloseLive(!modalCloseLive);
  };
  const openModalSingleDeleteLive = (id) => {
    setItemToDeleteLive([id]);
    setModalCloseLive(true);
  };
  const handleDeleteLiveData = () => {
    closeLiveModal();
    liveDeleteOperation();
  };
  const openModalMultiDeleteLive = () => {
    setModalCloseLive(true);
  };

  const handleAllLive = () => {
    setSelectAllLive(!selectAllLive);
    setItemToDeleteLive(
      selectAllLive ? [] : filteredLiveData?.map((item) => item?.id)
    );
  };

  const liveDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteLive + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/multi-delete/`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  ////Live Stream End

  ////Audio started
  const selectRowAudio = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteAudio([...itemToDeleteAudio, row?.id]);
        } else {
          setItemToDeleteAudio(
            itemToDeleteAudio.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteAudio,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAllAudio}
        />
      </div>
    ),
  };

  const audioFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <img
          src={row?.image_url ? row?.image_url : defaultImage}
          className="profile"
          alt="image_all"
        />

        <div className="rightVideoTitle">
          <p>{data}</p>
          <span className="me-1 text-dark dateText"> {row?.duration}</span>
          <span className="dateText">{row?.upload_on}</span>
          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`${process.env.REACT_APP_Baseurl_Frontend}/music/${row?.slug}`}
                      >
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  const statusFormatterAudio = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "DRAFT"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };

  const actionFormatterAudio = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit-audio/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2"> Edit Audio </span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteAudio(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2"> Delete Audio</span>
          </div>
        </div>
      </div>
    );
  };
  const columnsAudio = [
    {
      dataField: "title",
      text: "Audios",
      formatter: audioFormatter,
      sort: true,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded by",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusFormatterAudio,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatterAudio,
    },
  ];
  const exportHeadersAudio = [
    { label: "ID", key: "id" },
    { label: "AUDIOS", key: "title" },
    { label: "DURATION", key: "duration" },
    { label: "STATUS", key: "active" },
    { label: "BANNER", key: "banner" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD-ON", key: "upload_on" },
  ];
  const conditionalGeneratePDFAudio = (get) => {
    const doc = new jsPDF();
    doc.text("ALL AUDIO LIST ", 10, 10);
    const columns = [
      "INDEX",
      "ID",
      "AUDIOS NAME",
      "DURATION",
      "STATUS",
      "BANNER",
      "UPLOADED BY",
      "UPLOAD-ON",
    ];
    const allDataPDF = audioData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.duration,
      item?.active,
      item?.banner,
      item?.uploaded_by,
      item?.upload_on,
    ]);
    const filterPDF = filteredAudioData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.duration,
      item?.active,
      item?.banner,
      item?.uploaded_by,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save(get === 1 ? "ALL-AUDIO-LIST.pdf" : "AUDIO-FILTERED-LIST");
  };

  const generateXLSXAUDIO = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? audioData : filteredAudioData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(
      wb,
      get === 1 ? "ALL-AUDIO-LIST.xlsx" : "AUDIO-FILTERED-LIST.xlsx"
    );
  };

  const handleFilterChangeAudio = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilterAudio(searchTerm);
    const filteredResults = audioData.filter((item) =>
      ["title", "duration", "active", "uploaded_by", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredAudioData(filteredResults);
  };

  const closeAudioModal = () => {
    setModalCloseAudio(!modalCloseAudio);
  };

  const openModalSingleDeleteAudio = (id) => {
    setItemToDeleteAudio([id]);
    setModalCloseAudio(true);
  };

  const openModalMultiDeleteAudio = () => {
    setModalCloseAudio(true);
  };

  const handleDeleteAudioData = () => {
    closeAudioModal();
    audioDeleteOperation();
  };

  const audioDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteAudio + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/audio/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };
  const handleClearAudio = () => {
    fetchApi();
    setFilterAudio("");
  };
  const handleAllAudio = () => {
    setSelectAllAudio(!selectAllAudio);
    setItemToDeleteAudio(
      selectAllAudio ? [] : filteredAudioData?.map((item) => item?.id)
    );
  };

  ///Audio End

  /////Episode start
  const selectRowEpisode = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteEpisode([...itemToDeleteEpisode, row?.id]);
        } else {
          setItemToDeleteEpisode(
            itemToDeleteEpisode.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteEpisode,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAllEpisode}
        />
      </div>
    ),
  };

  const episodeFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <img
          src={row?.Thumbnail ? row?.Thumbnail : defaultImage}
          className="profile"
          alt="image_all"
        />

        <div className="rightVideoTitle">
          <p>{data}</p>
          <span className="me-1 text-dark dateText"> {row?.duration}</span>
          <span className="dateText">{row?.upload_on}</span>

          <div>
            {row?.slug !== null ? (
              <div className="text-start">
                <label className="m-0">
                  <div className="tooltip-container">
                    <p className="iq-bg-warning">
                      <Link
                        to={`${process.env.REACT_APP_Baseurl_Frontend}/episode/${row?.slug}`}
                      >
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </Link>
                      <span className="tooltip-text">View</span>
                    </p>
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div className="text-start">
                  <label className="m-0">
                    <div className="tooltip-container">
                      <p className="iq-bg-warning">
                        <i
                          className="fa fa-share-alt"
                          aria-hidden="true"
                          disabled
                        ></i>
                        <span className="tooltip-text">Diable View</span>
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  const statusFormatterEpisode = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "DRAFT"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };

  const actionFormatterEpisode = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit-episode/manage/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2"> Edit Episode </span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteEpisode(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2"> Delete Episode</span>
          </div>
        </div>
      </div>
    );
  };
  const columnsEpisode = [
    {
      dataField: "title",
      text: "Episodes",
      formatter: episodeFormatter,
      sort: true,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded by",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusFormatterEpisode,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatterEpisode,
    },
  ];
  const exportHeadersEpisode = [
    { label: "ID", key: "id" },
    { label: "EPISODE", key: "title" },
    { label: "DURATION", key: "duration" },
    { label: "STATUS", key: "active" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD-ON", key: "upload_on" },
  ];
  const conditionalGeneratePDFEpisode = (get) => {
    const doc = new jsPDF();
    doc.text("ALL EPISODE LIST ", 10, 10);
    const columns = [
      "INDEX",
      "ID",
      "EPISODE NAME",
      "DURATION",
      "STATUS",
      "UPLOADED BY",
      "UPLOAD-ON",
    ];
    const allDataPDF = episodeData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.duration,
      item?.active,
      item?.uploaded_by,
      item?.upload_on,
    ]);
    const filterPDF = filteredEpisodeData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.duration,
      item?.active,
      item?.uploaded_by,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get === 1 ? allDataPDF : filterPDF,
    });
    doc.save(get === 1 ? "ALL-EPISODE-LIST.pdf" : "FILTERED-EPISODE-LIST");
  };

  const generateXLSXEPISODE = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(
        get === 1 ? episodeData : filteredEpisodeData
      );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(
      wb,
      get === 1 ? "ALL-EPISODE-LIST.xlsx" : "FILTERED-EPISODE-LIST.xlsx"
    );
  };

  const handleFilterChangeEpisode = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilterEpisode(searchTerm);
    const filteredResults = episodeData?.filter((item) =>
      ["title", "duration", "active", "uploaded_by", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredEpisodeData(filteredResults);
  };

  const closeEpisodeModal = () => {
    setModalCloseEpisode(!modalCloseEpisode);
  };

  const openModalSingleDeleteEpisode = (id) => {
    setItemToDeleteEpisode([id]);
    setModalCloseEpisode(true);
  };

  const openModalMultiDeleteEpisode = () => {
    setModalCloseEpisode(true);
  };

  const handleDeleteEpisodeData = () => {
    closeEpisodeModal();
    episodeDeleteOperation();
  };

  const episodeDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteEpisode + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/episode/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };
  const handleClearEpisode = () => {
    fetchApi();
    setFilterEpisode("");
  };
  const handleAllEpisode = () => {
    setSelectAllEpisode(!selectAllEpisode);
    setItemToDeleteEpisode(
      selectAllEpisode ? [] : filteredEpisodeData?.map((item) => item?.id)
    );
  };

  return (
    <>
      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      {/* video modal */}
      <div
        className={`modal packageModel ${modelClose ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: `${modelClose ? "block" : "none"}` }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModalvideo}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteVideo?.length === 1 ? (
                <p> Are you sure you want to delete this item ?</p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected items ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModalvideo}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteVideoDatavideo}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* live modal */}
      <div
        className={`modal  packageModel ${modalCloseLive ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: modalCloseLive ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeLiveModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteLive?.length === 1 ? (
                <p className="fs-6">
                  {" "}
                  Are you sure you want to delete this live ?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected lives ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeLiveModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteLiveData}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* audio modal */}
      <div
        className={`modal  packageModel ${modalCloseAudio ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: modalCloseAudio ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeAudioModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteAudio?.length === 1 ? (
                <p className="fs-6">
                  {" "}
                  Are you sure you want to delete this Audio ?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected Audios ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeAudioModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteAudioData}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* episode modal */}
      <div
        className={`modal  packageModel ${modalCloseEpisode ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: modalCloseEpisode ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeEpisodeModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteEpisode?.length === 1 ? (
                <p className="fs-6">
                  {" "}
                  Are you sure you want to delete this Episode ?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected Episode ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeEpisodeModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteEpisodeData}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <section className="section Master container-fluid pt-3">
        <div className="row">
          <div className="col-sm-12">
            <div className="iq-card">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                  <h4 className="card-title">Master Lists</h4>
                </div>
              </div>
              <hr />

              <div className="row align-items-center ">
                <div className="col-md-4 col-lg-4 col-xl-3 col-sm-6 mb-2">
                  <div className="iq-card-body">
                    <div className="media align-items-center d-flex ">
                      <div className="iq-user-box1">
                        <p className="mb-0 text-white fw-bold">
                          {totalMastercount}
                        </p>
                      </div>
                      <div className="media-body ">
                        <p className="mb-0 font-size-14 line-height">
                          Total Master list Counts
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-3 col-xl-2 col-sm-6 mb-2">
                  <div className="iq-card-body">
                    <div className="media align-items-center d-flex ">
                      <div className="iq-user-box2">
                        <p className="mb-0 text-white fw-bold">
                          {videoData?.length}
                        </p>
                      </div>
                      <div className="media-body ">
                        <p className="mb-0 font-size-14 line-height">
                          Total Videos
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-4 col-xl-3 col-sm-6 mb-2">
                  <div className="iq-card-body">
                    <div className="media align-items-center d-flex ">
                      <div className="iq-user-box3">
                        <p className="mb-0 text-white fw-bold">
                          {liveData?.length}
                        </p>
                      </div>
                      <div className="media-body ">
                        <p className="mb-0 font-size-14 line-height">
                          Total Live Stream Videos
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-4 col-xl-2 col-sm-6 mb-2">
                  <div className="iq-card-body">
                    <div className="media align-items-center d-flex ">
                      <div className="iq-user-box5">
                        <p className="mb-0 text-white fw-bold">
                          {audioData?.length}
                        </p>
                      </div>
                      <div className="media-body ">
                        <p className="mb-0 font-size-14 line-height">
                          Total Audios
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-4 col-xl-2 col-sm-6 mb-2">
                  <div className="iq-card-body">
                    <div className="media align-items-center d-flex ">
                      <div className="iq-user-box4">
                        <p className="mb-0 text-white fw-bold">
                          {episodeData?.length}
                        </p>
                      </div>
                      <div className="media-body ">
                        <p className="mb-0 font-size-14 line-height">
                          Total Episodes
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="iq-card-body table-responsive iq-card">
              <div className="table-view">
                <ul
                  className="nav nav-pills justify-content-between row"
                  id="pills-tab "
                  role="tablist"
                >
                  <li
                    className="nav-item col-12 col-sm-6 col-md-3 "
                    role="presentation"
                  >
                    <button
                      className="nav-link active fs-6 fw-bold px-4 w-100  mx-auto "
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Videos
                    </button>
                  </li>
                  <li
                    className="nav-item col-12 col-sm-6 col-md-3 "
                    role="presentation"
                  >
                    <button
                      className="nav-link fs-6 fw-bold px-4 w-100  mx-auto"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Live Stream Videos
                    </button>
                  </li>
                  <li
                    className="nav-item col-6 col-sm-6 col-md-3 "
                    role="presentation"
                  >
                    <button
                      className="nav-link fs-6 fw-bold px-4 w-100  mx-auto"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Audios
                    </button>
                  </li>
                  <li
                    className="nav-item col-6 col-sm-6 col-md-3 "
                    role="presentation"
                  >
                    <button
                      className="nav-link fs-6 fw-bold px-4 w-100  mx-auto"
                      id="pills-episode-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-episode"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Episode
                    </button>
                  </li>
                </ul>
                <hr></hr>
                <div className="tab-content p-0" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className=" m-0 p-0">
                      <div className="container-fluid">
                        <section className="section all-video-stream ">
                          <div className="col-lg-12 ">
                            <div className="iq-card shadow-none p-0">
                              <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                                <div className="filterContainer">
                                  <input
                                    type="text"
                                    placeholder="Filter all.."
                                    className="filter form-control "
                                    value={filterVideo}
                                    onChange={handleFilterChange}
                                  />
                                  {filterVideo?.length > 0 && (
                                    <button
                                      type="button"
                                      className="closeBtn"
                                      onClick={handleClear}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  )}
                                </div>
                                <div className="btn-group flex-wrap justify-content-between justify-content-sm-end ">
                                  {itemToDeleteVideo?.length > 0 && (
                                    <button
                                      className="btn btn-danger d-flex d-inline-flex  fw-bolder  dropdown-toggle mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                                      onClick={openModalvideo}
                                    >
                                      <MdDelete className="exportIcon " />
                                      Delete
                                    </button>
                                  )}
                                  <div
                                    className="dropdown d-block mb-2 mb-sm-0 mx-0 "
                                    key={"2"}
                                  >
                                    <button
                                      className="btn btn-success dropdown-toggle d-flex mx-0"
                                      type="button"
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <span className="fw-bolder">Export </span>
                                      <img
                                        src={ExportIcon}
                                        className="ms-2 exportIcon"
                                        alt="exportIcon"
                                      />
                                    </button>
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton1"
                                    >
                                      <li className="dropdown-item">
                                        <CSVLink
                                          headers={exportHeaders}
                                          data={filteredVideoData}
                                          className="text-dark d-block"
                                          filename="VIDEO-LIST.csv"
                                        >
                                          CSV
                                        </CSVLink>
                                      </li>
                                      <li
                                        className="dropdown-item"
                                        onClick={() => {
                                          conditionalGeneratePDF(0);
                                        }}
                                      >
                                        PDF
                                      </li>
                                      <li
                                        className="dropdown-item"
                                        onClick={() => {
                                          generateXLSX(0);
                                        }}
                                      >
                                        XLSX
                                      </li>
                                    </ul>
                                  </div>

                                  <div className="dropdown d-block mx-0">
                                    <button
                                      className="btn btn-success dropdown-toggle d-flex "
                                      type="button"
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <span className="fw-bolder">
                                        Export All
                                      </span>
                                      <img
                                        src={ExportIcon}
                                        className="ms-2 exportIcon"
                                        alt="exportAllIcon"
                                      />
                                    </button>
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton1"
                                    >
                                      <li
                                        className="dropdown-item"
                                        onClick={() => setPage(0)}
                                      >
                                        <CSVLink
                                          headers={exportHeaders}
                                          data={videoData}
                                          className="text-dark d-block"
                                          filename="VIDEO-LIST.csv"
                                        >
                                          CSV
                                        </CSVLink>
                                      </li>
                                      <li
                                        className="dropdown-item"
                                        onClick={() => {
                                          conditionalGeneratePDF(1);
                                        }}
                                      >
                                        PDF
                                      </li>
                                      <li
                                        className="dropdown-item"
                                        onClick={() => {
                                          generateXLSX(1);
                                        }}
                                      >
                                        XLSX
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="bootstrapTable">
                                <BootstrapTable
                                  keyField="id"
                                  columns={columns}
                                  data={filteredVideoData}
                                  pagination={commonPaginationConfig(
                                    videoSizePerPageList
                                  )}
                                  className="no-border-table"
                                  noDataIndication={
                                    <div className="noRecord">
                                      {loading ? (
                                        <label>
                                          {" "}
                                          <Response_Loading_processing></Response_Loading_processing>
                                          Loading...
                                        </label>
                                      ) : (
                                        filteredVideoData?.length <= 0 && (
                                          <p>No Video Found!</p>
                                        )
                                      )}
                                    </div>
                                  }
                                  hover
                                  headerClasses="videoWidth"
                                  selectRow={selectRow}
                                  selectAll={selectAllVideo}
                                />
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <section className="section container-fluid ">
                      <div id="content-page" className="content-page">
                        <div className="iq-card  shadow-none p-0">
                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                            <div className="filterContainer">
                              <input
                                type="text"
                                placeholder="Filter all.."
                                className="filter form-control "
                                value={filterLive}
                                onChange={handleFilterChangeLive}
                              />
                              {filterLive?.length > 0 && (
                                <button
                                  type="button"
                                  className="closeBtn"
                                  onClick={handleClearLive}
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              )}
                            </div>
                            <div className="btn-group flex-wrap justify-content-between justify-content-sm-end">
                              {itemToDeleteLive?.length > 0 && (
                                <button
                                  className="btn btn-danger d-inline-flex  fw-bolder dropdown-toggle  mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                                  onClick={openModalMultiDeleteLive}
                                >
                                  <MdDelete className="exportIcon " />
                                  Delete
                                </button>
                              )}
                              <div
                                className="dropdown d-block  mb-2 mb-sm-0 mx-0 "
                                key={"2"}
                              >
                                <button
                                  className="btn btn-success dropdown-toggle d-flex mx-0"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span className="fw-bolder">Export </span>
                                  <img
                                    src={ExportIcon}
                                    alt="image_all"
                                    className="ms-2 exportIcon"
                                  />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li className="dropdown-item">
                                    <CSVLink
                                      headers={exportHeadersLive}
                                      data={filteredLiveData}
                                      className="text-dark d-block"
                                      filename="LIVE-FILTERED-LIST.csv"
                                    >
                                      CSV
                                    </CSVLink>
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      conditionalGeneratePDFLive(0);
                                    }}
                                  >
                                    PDF
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      generateXLSXLIVE(0);
                                    }}
                                  >
                                    XLSX
                                  </li>
                                </ul>
                              </div>

                              <div className="dropdown d-block ">
                                <button
                                  className="btn btn-success dropdown-toggle d-flex"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span className="fw-bolder">Export All</span>
                                  <img
                                    src={ExportIcon}
                                    alt="image_all"
                                    className="ms-2 exportIcon"
                                  />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li className="dropdown-item">
                                    <CSVLink
                                      headers={exportHeadersLive}
                                      data={liveData}
                                      className="text-dark d-block"
                                      filename="ALL-LIVES-LIST.csv"
                                    >
                                      CSV
                                    </CSVLink>
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      conditionalGeneratePDFLive(1);
                                    }}
                                  >
                                    PDF
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      generateXLSXLIVE(1);
                                    }}
                                  >
                                    XLSX
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="bootstrapTable">
                            <BootstrapTable
                              keyField="id"
                              columns={liveColumns}
                              data={filteredLiveData}
                              pagination={commonPaginationConfig(
                                liveSizePerPageList
                              )}
                              className="no-border-table"
                              noDataIndication={
                                <div className="noRecord">
                                  {loading ? (
                                    <label>
                                      {" "}
                                      <Response_Loading_processing></Response_Loading_processing>
                                      Loading...
                                    </label>
                                  ) : (
                                    filteredLiveData?.length <= 0 && (
                                      <p>No Live Streams Available. !!!</p>
                                    )
                                  )}
                                </div>
                              }
                              hover
                              headerClasses="videoWidth"
                              selectRow={selectRowLive}
                              selectAll={selectAllLive}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <section className="section container-fluid ">
                      <div id="content-page" className="content-page">
                        <div className="iq-card shadow-none p-0">
                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                            <div className="filterContainer">
                              <input
                                type="text"
                                placeholder="Filter all.."
                                className="filter form-control "
                                value={filterAudio}
                                onChange={handleFilterChangeAudio}
                              />
                              {filterAudio?.length > 0 && (
                                <button
                                  type="button"
                                  className="closeBtn"
                                  onClick={handleClearAudio}
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              )}
                            </div>
                            <div className="btn-group flex-wrap justify-content-between justify-content-sm-end">
                              {itemToDeleteAudio?.length > 0 && (
                                <button
                                  className="btn btn-danger d-inline-flex  fw-bolder dropdown-toggle mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                                  onClick={openModalMultiDeleteAudio}
                                >
                                  <MdDelete className="exportIcon " />
                                  Delete
                                </button>
                              )}
                              <div
                                className="dropdown d-block mb-2 mb-sm-0 mx-0  "
                                key={"2"}
                              >
                                <button
                                  className="btn btn-success dropdown-toggle d-flex mx-0"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span className="fw-bolder">Export </span>
                                  <img
                                    src={ExportIcon}
                                    alt="image_all"
                                    className="ms-2 exportIcon"
                                  />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li className="dropdown-item">
                                    <CSVLink
                                      headers={exportHeadersAudio}
                                      data={filteredAudioData}
                                      className="text-dark d-block"
                                      filename="AUDIO-FILTERED-LIST.csv"
                                    >
                                      CSV
                                    </CSVLink>
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      conditionalGeneratePDFAudio(0);
                                    }}
                                  >
                                    PDF
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      generateXLSXAUDIO(0);
                                    }}
                                  >
                                    XLSX
                                  </li>
                                </ul>
                              </div>

                              <div className="dropdown d-block ">
                                <button
                                  className="btn btn-success dropdown-toggle d-flex"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span className="fw-bolder">Export All</span>
                                  <img
                                    src={ExportIcon}
                                    alt="image_all"
                                    className="ms-2 exportIcon"
                                  />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li className="dropdown-item">
                                    <CSVLink
                                      headers={exportHeadersAudio}
                                      data={audioData}
                                      className="text-dark d-block"
                                      filename="ALL-AUDIO-LIST.csv"
                                    >
                                      CSV
                                    </CSVLink>
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      conditionalGeneratePDFAudio(1);
                                    }}
                                  >
                                    PDF
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      generateXLSXAUDIO(1);
                                    }}
                                  >
                                    XLSX
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="bootstrapTable">
                            <BootstrapTable
                              keyField="id"
                              columns={columnsAudio}
                              data={filteredAudioData}
                              pagination={commonPaginationConfig(
                                audioSizePerPageList
                              )}
                              className="no-border-table"
                              noDataIndication={
                                <div className="noRecord">
                                  {loading ? (
                                    <label>
                                      {" "}
                                      <Response_Loading_processing></Response_Loading_processing>
                                      Loading...
                                    </label>
                                  ) : (
                                    filteredAudioData?.length <= 0 && (
                                      <p>No Audios Available !!!</p>
                                    )
                                  )}
                                </div>
                              }
                              hover
                              headerClasses="videoWidth"
                              selectRow={selectRowAudio}
                              selectAll={selectAllAudio}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-episode"
                    role="tabpanel"
                    aria-labelledby="pills-episode-tab"
                  >
                    <section className="section container-fluid">
                      <div id="content-page" className="content-page">
                        <div className="iq-card shadow-none p-0">
                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                            <div className="filterContainer">
                              <input
                                type="text"
                                placeholder="Filter all.."
                                className="filter form-control "
                                value={filterEpisode}
                                onChange={handleFilterChangeEpisode}
                              />
                              {filterEpisode?.length > 0 && (
                                <button
                                  type="button"
                                  className="closeBtn"
                                  onClick={handleClearEpisode}
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              )}
                            </div>
                            <div className="btn-group flex-wrap justify-content-between justify-content-sm-end">
                              {itemToDeleteEpisode?.length > 0 && (
                                <button
                                  className="btn btn-danger d-inline-flex  fw-bolder dropdown-toggle mb-2 mb-sm-0 me-2 ms-0 deleteBtn "
                                  onClick={openModalMultiDeleteEpisode}
                                >
                                  <MdDelete className="exportIcon " />
                                  Delete
                                </button>
                              )}
                              <div
                                className="dropdown d-block mb-2 mb-sm-0 mx-0 "
                                key={"2"}
                              >
                                <button
                                  className="btn btn-success dropdown-toggle d-flex mx-0 "
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span className="fw-bolder">Export </span>
                                  <img
                                    src={ExportIcon}
                                    alt="image_all"
                                    className="ms-2 exportIcon"
                                  />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li className="dropdown-item">
                                    <CSVLink
                                      headers={exportHeadersEpisode}
                                      data={filteredEpisodeData}
                                      className="text-dark d-block"
                                      filename="FILTERED-EPISODE-LIST.csv"
                                    >
                                      CSV
                                    </CSVLink>
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      conditionalGeneratePDFEpisode(0);
                                    }}
                                  >
                                    PDF
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      generateXLSXEPISODE(0);
                                    }}
                                  >
                                    XLSX
                                  </li>
                                </ul>
                              </div>

                              <div className="dropdown d-block ">
                                <button
                                  className="btn btn-success dropdown-toggle d-flex"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span className="fw-bolder">Export All</span>
                                  <img
                                    src={ExportIcon}
                                    alt="image_all"
                                    className="ms-2 exportIcon"
                                  />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li className="dropdown-item">
                                    <CSVLink
                                      headers={exportHeadersEpisode}
                                      data={episodeData}
                                      className="text-dark d-block"
                                      filename="ALL-EPISODE-LIST.csv"
                                    >
                                      CSV
                                    </CSVLink>
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      conditionalGeneratePDFEpisode(1);
                                    }}
                                  >
                                    PDF
                                  </li>
                                  <li
                                    className="dropdown-item"
                                    onClick={() => {
                                      generateXLSXEPISODE(1);
                                    }}
                                  >
                                    XLSX
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="bootstrapTable">
                            <BootstrapTable
                              keyField="id"
                              columns={columnsEpisode}
                              data={filteredEpisodeData}
                              pagination={commonPaginationConfig(
                                episodeSizePerPageList
                              )}
                              className="no-border-table"
                              noDataIndication={
                                <div className="noRecord">
                                  {loading ? (
                                    <label>
                                      {" "}
                                      <Response_Loading_processing></Response_Loading_processing>
                                      Loading...
                                    </label>
                                  ) : (
                                    filteredEpisodeData?.length <= 0 && (
                                      <p>No Episode Available !!!</p>
                                    )
                                  )}
                                </div>
                              }
                              hover
                              headerClasses="videoWidth"
                              selectRow={selectRowEpisode}
                              selectAll={selectAllEpisode}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Mastervideolist;
