import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./HomePageSettings.css";
import edititem from "../../../components/Images/edititem.svg";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function HomePageSettings(props) {
  const [seriesid, setSeriesid] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  const [message, setMessage] = useState("");
  // const [seriesidvalue, setSeriesidvalue] = useState("");
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [homepagesetting, setHomepagesetting] = useState([]);
  const [editsingle_series_id, setEditsingle_series_id] = useState({
    single_series_id: "",
  });
  const [user_detailsidhome, setUser_detailsidhome] = useState();

  const [mobilepagesetting, setMobilepagesetting] = useState([]);
  const [user_detailsidmobile, setUser_detailsidmobile] = useState();

  const [showSingleSeriesModal, setShowSingleSeriesModal] = useState(false);

  const handleToggleChange = (itemName, currentValue) => {
    const updatedHomepagesetting = homepagesetting.map((item) =>
      item?.name === itemName
        ? { ...item, Web_HomePage_status: currentValue ? 0 : 1 }
        : item
    );
    setHomepagesetting(updatedHomepagesetting);

    if (itemName === "single_series" && currentValue === 0) {
      setShowSingleSeriesModal(true);
    } else if (itemName === "single_series" && currentValue === 1) {
      setShowSingleSeriesModal(false);
    }
  };


  const handleToggleChangemobile = (itemName, currentValue) => {
    const updatedHomepagesetting = mobilepagesetting.map((item) =>
      item?.name === itemName
        ? { ...item, Mobile_Homesetting_status: currentValue ? 0 : 1 }
        : item
    );
    setMobilepagesetting(updatedHomepagesetting);
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Web-HomePage/index`, {
        headers: headers,
      })
      .then((response) => {
        var homepagesetting = response?.data?.Web_HomePage_Order;
        var editsingle_seriesid = response?.data?.Web_HomePage;
        var resultid = response?.data?.list_of_series;
        var user_detailsid = response?.data?.user_details?.id;
        setSeriesid(resultid);
        setHomepagesetting(homepagesetting);
        setEditsingle_series_id(editsingle_seriesid);
        // console.log(editsingle_seriesid);
        setUser_detailsidhome(user_detailsid);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Mobile-HomePage/index`, {
        headers: headers,
      })
      .then((response) => {
        var homepagesetting = response?.data?.Mobile_HomePage_Order;
        var user_detailsid = response?.data?.user_details?.id;
        setMobilepagesetting(homepagesetting);
        setUser_detailsidmobile(user_detailsid);
      })
      .catch((error) => console.log(error));
  }, []);

  async function submitApihome() {
    const formData = new FormData();

    homepagesetting.forEach((item) => {
      const Web_HomePage_status = Number.isNaN(parseInt(item?.Web_HomePage_status, 10)) ? 0 : parseInt(item?.Web_HomePage_status, 10);
      formData.append(item?.name, Web_HomePage_status);
    });
    formData.append("user_id", user_detailsidhome);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Web-HomePage/update`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;
        resultapi = response.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        console.log(resultError);
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  async function submitApimobile() {
    const formData = new FormData();

    mobilepagesetting.forEach((item) => {
      formData.append(item?.name, item?.Mobile_Homesetting_status);
    });
    formData.append("user_id", user_detailsidmobile);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Mobile-HomePage/update`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;
        resultapi = response.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        console.log(resultError);
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }
  const [
    validationMessagesingle_series_id,
    setValidationMessagesingle_series_id,
  ] = useState("");
  const [
    isValidationHiddensingle_series_id,
    setIsValidationHiddensingle_series_id,
  ] = useState(true);
  // const [processing, setProcessing] = useState(false);
  // const [showOverlay, setShowOverlay] = useState(false);

  const handlesingleseriesid = (event) => {
    const selectedValue = event.target.value;
    setEditsingle_series_id({ single_series_id: selectedValue });
  };

  const single_series_idInputRef = useRef(null);

  async function seriesidsubmit() {
    let focusInputRef = null;

    if (editsingle_series_id.single_series_id === "") {
      setValidationMessagesingle_series_id("Single Series cannot be empty.");
      setIsValidationHiddensingle_series_id(false);
      focusInputRef = single_series_idInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const formData = new FormData();
    formData.append("single_series_id", editsingle_series_id.single_series_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token, // Assuming access_token is defined
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/Web-HomePage/Single_Series_Update`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;
        resultapi = response.data;
      }
    } catch (error) {
      // Handle API request error
    }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setValidationMessagesingle_series_id("");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;
        console.log(resultError);
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setIsValidationHiddensingle_series_id(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  const [editUser, seteditUser] = useState({
    user_id: "",
    id: "",
    featured_videos: "",
    latest_videos: "",
    category_videos: "",
    live_videos: "",
    series: "",
    audios: "",
    albums: "",
    Recommendation: "",
    AutoIntro_skip: "",
    continue_watching: "",
    theme_choosen: "",
    prevent_inspect: "",
    artist: "",
    pop_up: "",
    live_category: "",
    videoCategories: "",
    liveCategories: "",
    video_schedule: "",
    single_series: "",
    list_of_series: "",
    single_series_id: "",
    latest_series: "",
    scheduled_publish_video: "",
    scheduled_publish_LiveStream: "",
    content_partner: "",
    channel_partner: "",
    Individual_ContentPartner: "",
    Individual_channelPartner: "",
  });
  const [editUserdata, seteditUserdata] = useState({
    user_id: "",
    id: "",
    featured_videos: "",
    latest_videos: "",
    category_videos: "",
    live_videos: "",
    series: "",
    audios: "",
    albums: "",
    Recommendation: "",
    AutoIntro_skip: "",
    continue_watching: "",
    theme_choosen: "",
    prevent_inspect: "",
    artist: "",
    pop_up: "",
    live_category: "",
    videoCategories: "",
    liveCategories: "",
    video_schedule: "",
    single_series: "",
    list_of_series: "",
    single_series_id: "",
    latest_series: "",
    scheduled_publish_video: "",
    scheduled_publish_LiveStream: "",
    content_partner: "",
    channel_partner: "",
    Individual_ContentPartner: "",
    Individual_channelPartner: "",
  });

  const handleInput = (e) => {
    // console.log(e.target.checked, e.target.value);
    seteditUser({ ...editUser, [e.target.name]: e.target.value });
    if (e.target.checked === true) {
      seteditUser({ ...editUser, [e.target.name]: 1 });
      seteditUserdata({ ...editUserdata, [e.target.name]: 1 });
    } else {
      seteditUser({ ...editUser, [e.target.name]: 0 });
      seteditUserdata({ ...editUserdata, [e.target.name]: 0 });
    }
  };

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/index`,
        { headers: headers }
      );
      setMenuItems(response.data.Web_HomePage_Order);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(async () => {
    fetchMenuItems();
  }, []);
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(menuItems);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setMenuItems(reorderedItems);
    saveShuffleOrder(reorderedItems);
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function saveShuffleOrder(updatedItems) {
    if (!updatedItems || updatedItems.length === 0) {
      console.error("Menu items are empty");
      return;
    }

    const positions = updatedItems.map((menuItem) => menuItem.id);
    const ids = updatedItems.map((_, index) => index + 1);

    const requestData = {
      id: ids,
      position: positions,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/HomePage-Order/ordering`,
        requestData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;
        resultapi = response.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        console.log(resultError);
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  return (
    <>
      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="p-0">
              <div id="webhomesetting">
                <div className="iq-card home_mobile">
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        className="nav-link active"
                        id="nav-home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-home"
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        <h6>Web Home Page</h6>
                      </button>
                      <button
                        className="nav-link ms-2"
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        <h6>Mobile Home Page</h6>
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                      <div className="panel-body">
                        <h6>Home Page Menu Settings </h6>
                        <hr />

                        <div className="row align-items-center p-3 pt-1">
                          {homepagesetting?.map((item, key) => (
                            <div
                              className="col-12 col-md-12 col-lg-6 p-1"
                              key={key}
                            >
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 ">
                                      {item?.header_name}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name={item?.name}
                                        onChange={() =>
                                          handleToggleChange(
                                            item?.name,
                                            item?.Web_HomePage_status
                                          )
                                        }
                                        checked={
                                          item?.Web_HomePage_status === 1
                                        }
                                        type="checkbox"
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div
                          className={`modal fade ${showSingleSeriesModal ? "show" : ""
                            }`}
                          tabIndex="-1"
                          role="dialog"
                          style={{
                            display: showSingleSeriesModal ? "block" : "none",
                          }}
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">
                                  Single Series Modal
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() =>
                                    setShowSingleSeriesModal(false)
                                  }
                                >
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <select
                                  className="form-select"
                                  name="single_series_id"
                                  id="single_series_id"
                                  value={editsingle_series_id?.single_series_id}
                                  onChange={handlesingleseriesid}
                                  ref={single_series_idInputRef}
                                >
                                  <option value="">Choose a Series</option>
                                  {seriesid?.map((item, key) => (
                                    <option key={key} value={item?.id}>
                                      {item?.title}
                                    </option>
                                  ))}
                                </select>
                                <span className="errorred">
                                  {!isValidationHiddensingle_series_id && (
                                    <p>{validationMessagesingle_series_id}</p>
                                  )}
                                </span>


                              </div>
                              <div className="modal-footer">
                                {/* <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-dismiss="modal"
                                      onClick={() => setShowSingleSeriesModal(false)}
                                    >
                                      Close
                                    </button> */}
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={seriesidsubmit}
                                >
                                  Save Single Series
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-12 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary mt-3"
                              onClick={submitApihome}
                              name="submit"
                            >
                              Save Settings
                            </button>
                          </div>
                        </div>


                        {/* <div className="row ">
                          <div className="col-md-12 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary mt-3"
                              onClick={handleUpdate}
                              name="submit"
                            >
                              {" "}
                              Save Settings
                            </button>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <div className="panel-body">
                        <h6>Mobile Page Menu Settings </h6>
                        <hr />

                        <div className="row align-items-center p-3 pt-1">
                          {mobilepagesetting?.map((item, key) => (
                            <div className="col-sm-4 p-1" key={key}>
                              <div className="home">
                                <div className="input-group color-picker d-flex align-items-center justify-content-between">
                                  <div>
                                    <label className="mt-1 headingpop">
                                      {item?.header_name}
                                    </label>
                                  </div>

                                  <div className="mt-1 d-flex align-items-center justify-content-around">
                                    <div className="mr-2">OFF</div>
                                    <label className="switch">
                                      <input
                                        name={item?.name}
                                        onChange={() =>
                                          handleToggleChangemobile(
                                            item?.name,
                                            item?.Mobile_Homesetting_status
                                          )
                                        }
                                        checked={
                                          item?.Mobile_Homesetting_status === 1
                                        }
                                        type="checkbox"
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <div className="ml-2">ON</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="row ">
                          <div className="col-md-12 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary mt-3"
                              onClick={submitApimobile}
                              name="submit"
                            >
                              Save Settings
                            </button>
                          </div>
                        </div>


                        {/* <div className="row ">
                          <div className="col-md-12 d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary mt-3"
                              name="submit"
                            >
                              {" "}
                              Save Settings
                            </button>
                          </div>
                        </div>  */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="iq-card">
              <div className="col-md-8 d-flex justify-content-between ">
                <h4>
                  <i className="entypo-list"></i> Order Home Page
                </h4>
              </div>
              <div className="col-md-12 p-0">
                <div className="order_home text-center">
                  <div className="row">
                    <div className="col-lg-4 col-4 data">#</div>
                    <div className="col-lg-4 col-4 data">Name</div>
                    <div className="col-lg-4 col-4 data">Action</div>
                  </div>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="menu-items">
                      {(provided) => (
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {menuItems?.map((menuItem, index) => (
                            <Draggable
                              key={menuItem?.id.toString()}
                              draggableId={menuItem?.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="text-center">
                                    <div className="row">
                                      <div className="col-lg-4 col-4 data_th">
                                        {menuItem.order_id}
                                      </div>
                                      <div className="col-lg-4 col-4 data_th">
                                        {menuItem.header_name}
                                      </div>
                                      <div className="col-lg-4 col-4 data_th">
                                        <div className="editdropdownpage">
                                          <span className="editdropdownpage-button">
                                            <span>
                                              <i
                                                className="fa fa-ellipsis-v"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </span>
                                          <div className="editdropdownpage-menu">
                                            <Link
                                              to={
                                                "/edit-orderhome/" +
                                                menuItem?.id
                                              }
                                              className="edit"
                                            >
                                              <span>
                                                <img
                                                  src={edititem}
                                                  alt="flogo"
                                                  width={20}
                                                  height={20}
                                                />
                                                <span className="ms-2">
                                                  Edit Page
                                                </span>
                                              </span>
                                            </Link>
                                          </div>
                                        </div>

                                        {/* <Link
                                          to={
                                            "/edit-orderhome/" +
                                            menuItem?.id
                                          }
                                          className="edit ms-1"
                                        >
                                          <i
                                            className="fa fa-pencil-square"
                                            aria-hidden="true"
                                          ></i>
                                        </Link> */}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default HomePageSettings;
