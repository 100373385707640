const audioURL = [
    {
        url: '/audio-lists',
        message: 'All Audios'
    },
    {
        url: '/add-audio',
        message: 'Add New Audio'
    },
    {
        url: '/audio-categories',
        message: 'Manage Audio Categories'
    },
    {
        url: '/manage-albums',
        message: 'Manage Albums'
    },
    {
        url: '/audio-approval',
        message: 'Move Audio to Partner'
    },
    {
        url: '/audio-content-partner',
        message: 'Audio To Content Partner'
    },
    {
        url: '/audio-channel-partner',
        message: 'Audio To Channel Partner'
    }
]

const artistURL = [
    {
        url: '/all-artist',
        message: 'All Trainers'
    },
    {
        url: '/add-artist',
        message: 'Add New Trainer'
    },
    {
        url: '/artists-musician',
        message: 'Record holder Trainer'
    },
    // {
    //     url: '/artists-songwriter',
    //     message: 'Publisher/Songwriter'
    // }
]

const liveURL = [
    {
        url: '/livestream',
        message: 'All Live Streams'
    },
    {
        url: '/add-livestream',
        message: 'Add New Live Stream'
    },
    {
        url: '/CPPLiveVideosIndex',
        message: 'Move Live Stream To Partner'
    },
    {
        url: '/livestream-categories',
        message: 'Manage Live Stream Categories'
    },
    {
        url: '/live-content-partner',
        message: 'Live Stream To Content Partner'
    },
    {
        url: '/live-channel-partner',
        message: 'Live Stream To Channel Partner'
    },
    // {
    //     url: '/live-event-artist',
    //     message: 'Live Event Artist'
    // },
]

const userURL = [
    {
        url: '/users',
        message: 'All User'
    },
    {
        url: '/add-users',
        message: 'Add New User'
    },
    {
        url: '/import-users-view',
        message: 'Import User'
    },
    {
        url: '/MultiUser-limit',
        message: 'Multi User Management'
    }]

const menuURL = [
    {
        url: '/menu',
        message: 'Menus'
    },
    {
        url: '/footermenu',
        message: 'Footer Menus'
    },
    {
        url: '/mobilemenu',
        message: 'Mobile Side Menus'
    },
    {
        url: '/signup-menu',
        message: 'Signup Menus'
    },
    // {
    //     url: '/filemanager',
    //     message: 'File Manager'
    // }
]

const videoURL = [
    {
        url: '/video-lists',
        message: 'All Video'
    },
    {
        url: '/add-video',
        message: 'Add Video'
    },
    {
        url: '/video-schedule',
        message: 'Video Schedule'
    },
    {
        url: '/video-partner',
        message: 'Move Video To Partner'
    },
    {
        url: '/video-categories',
        message: 'Manage Video Categories'
    },
    {
        url: '/allplaylist',
        message: 'Manage Video Playlist'
    },
    {
        url: '/video-content-partner',
        message: 'Video To Content Partner'
    },
    {
        url: '/video-channel-partner',
        message: 'Video To Channel Partner'
    },
    // {
    //     url: '/video-for-approval',
    //     message: 'Video For Approval'
    // },
]

const seriesURL = [
    {
        url: '/series-list',
        message: 'All Series'
    },
    {
        url: '/add-series',
        message: 'Add New Series'
    },
    {
        url: '/Series-Genre',
        message: 'Manage Series Genre'
    },
    {
        url: '/CPPSeriesIndex',
        message: 'Move Series To Partner'
    },
    {
        url: '/series-content-partner',
        message: 'Series To Content Partner'
    },
    {
        url: '/series-channel-partner',
        message: 'Series To Channel Partner'
    },
    // {
    //     url: '/all-episode',
    //     message: 'All Episode'
    // },
    // {
    //     url: '/manage-episode',
    //     message: 'Manage Episode'
    // },
    // {
    //     url: '/all-season',
    //     message: 'All Season'
    // },
    // {
    //     url: '/Add-season',
    //     message: 'Add Season'
    // },
    // {
    //     url: '/edit-season',
    //     message: 'Edit Season'
    // },

]
const languageURL = [
    {
        url: '/admin-languages',
        message: 'Equipment'
    },
    {
        url: '/subtitlelanguage',
        message: 'Subtitle Languages'
    },
    {
        url: '/translate-languages',
        message: 'Manage Translate Languages'
    },
    {
        url: '/translate-languages-index',
        message: 'Manage Translations'
    }
]

const contentPartnerURL = [
    {
        url: '/moderator',
        message: 'Add Content Partner'
    },
    {
        url: '/allmoderator',
        message: 'View Content Partner'
    },
    {
        url: '/allmoderator-approval',
        message: 'Content Partner For Approval'
    },
    {
        url: '/moderator/role',
        message: 'Add Role'
    },
    {
        url: '/moderator/view',
        message: 'View Role'
    },
    {
        url: '/moderator/commission',
        message: 'Commission'
    },
    {
        url: '/moderator/payouts',
        message: 'Content Partners Payout'
    },
]
const channelPartnerURL = [
    {
        url: '/channel/user/create',
        message: 'Add Channel Partner'
    },
    {
        url: '/channel/view-channel-members',
        message: 'View Channel Partner'
    },
    {
        url: '/channel/partnerapproval',
        message: 'Channel Partner For Approval'
    },
    {
        url: '/channel/commission',
        message: 'Commission'
    },
    {
        url: '/channel/payouts',
        message: 'Channel Partners Payout'
    },
    {
        url: '/channel-package-index',
        message: 'Channel Package'
    }
]
const pagesURL = [
    {
        url: '/pages',
        message: 'All Pages'
    },
    {
        url: '/landing-page/index',
        message: 'Landing Pages'
    },
    // {
    //     url: '/landing-page/create',
    //     message: 'Create Landing Pages'
    // },
]
const plansURL = [
    {
        url: '/subscription-plans',
        message: 'Manage Subscription Plans'
    },
    {
        url: '/inapp-purchase',
        message: 'Manage In App Purchase Plans'
    },
    {
        url: '/Life-time-subscription',
        message: 'Life Time Subscription'
    },
    {
        url: '/devices',
        message: 'Devices'
    },
]
const paymentURL = [
    {
        url: '/payment/total_revenue',
        message: 'Total Revenues'
    },
    {
        url: '/payment/subscription',
        message: 'Subscription Payments'
    },
    {
        url: '/payment/payPerView',
        message: 'PayPerView Payments'
    }
]
const analyticsURL = [
    {
        url: '/analytics/users',
        message: 'Users Analytics'
    },
    {
        url: '/analytics/subscriber',
        message: 'Subscriber Analytics'
    },
    {
        url: '/analytics/ppv',
        message: 'PPV Analytics'
    },
    {
        url: '/users/revenue',
        message: 'Users Revenue'
    },
    {
        url: '/video/purchased-analytics',
        message: 'Purchased Video Analytics'
    },
    {
        url: '/cpp/analytics',
        message: 'CPP Analytics'
    },
    {
        url: '/cpp/video-analytics',
        message: 'CPP Video Analytics'
    },
    {
        url: '/cpp/revenue',
        message: 'CPP Revenue'
    },
    {
        url: '/analytics/ViewsRegion',
        message: 'View By Region'
    },
    {
        url: '/analytics/RevenueRegion',
        message: 'Revenue By Region'
    },
    {
        url: '/analytics/PlayerVideoAnalytics',
        message: 'Player Video Analytics'
    },
    {
        url: '/analytics/RegionVideoAnalytics',
        message: 'Region Video Analytics'
    },
    {
        url: '/analytics/PlayerUserAnalytics',
        message: 'Player User Analytics'
    },
    {
        url: '/analytics/livestream-analytics',
        message: 'CPP Live Video'
    },
    {
        url: '/live/purchased-analytics',
        message: 'Purchased LiveStream Analytics'
    }
]

const settingsURL = [
    {
        url: '/settings',
        message: 'Storefront Settings'
    },
    {
        url: '/home-settings',
        message: 'HomePage Settings'
    },
    {
        url: '/audio-settings',
        message: 'AudioPage Settings'
    },
    {
        url: '/movie-settings',
        message: 'Movie Settings'
    },
    {
        url: '/show-settings',
        message: 'Show Settings'
    },
    {
        url: '/linking_settings',
        message: 'Link Settings'
    },
    {
        url: '/age/index',
        message: 'Manage Age'
    },
    {
        url: '/theme_settings',
        message: 'Theme Settings'
    },
    {
        url: '/email_settings',
        message: 'Email Settings'
    },
    {
        url: '/payment_settings',
        message: 'Payment Settings'
    },
    {
        url: '/storage_settings',
        message: 'Storage Settings'
    },
    {
        url: '/mobileapp',
        message: 'Mobile App Settings'
    },
    {
        url: '/system_settings',
        message: 'Social Login Settings'
    },
    {
        url: '/currency_settings',
        message: 'Currency Settings'
    },
    {
        url: '/revenue_settings/index',
        message: 'Revenue Settings'
    },
    {
        url: '/ThumbnailSetting',
        message: 'Thumbnail Settings'
    },
    {
        url: '/ChooseProfileScreen',
        message: 'Profile Screen'
    },
    {
        url: '/compress-image-setting',
        message: 'Image Settings'
    },
    {
        url: '/pop-up-setting',
        message: 'Home Page Pop Up Settings'
    },
    {
        url: '/comment-section-setting',
        message: 'Comment Settings'
    },
    {
        url: '/site-meta-settings',
        message: 'Site Meta Settings'
    },
    {
        url: '/tv-settings',
        message: 'Tv Settings'
    },
    {
        url: '/dsp-settings',
        message: 'DSP Settings'
    },
    {
        url: '/labels',
        message: 'Labels Settings'
    },
    // {
    //     url: '/ThemeIntegration',
    //     message: 'Theme'
    // },
]

const advertiserURL = [
    {
        url: '/advertisers',
        message: 'Advertiser'
    }
]
const faqURL = [
    {
        url: '/faq',
        message: 'FAQ'
    },
    {
        url: '/faq-category',
        message: 'FAQ Category'
    }
]

export { artistURL, audioURL, faqURL, liveURL, userURL, menuURL, videoURL, seriesURL, languageURL, contentPartnerURL, channelPartnerURL, pagesURL, plansURL, paymentURL, analyticsURL, settingsURL, advertiserURL }