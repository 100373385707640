import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import "./AddMenu.css";
import AdminSaveHeader from "../../../components/CommonComponents/AdminSaveHeader";
import FormContainer from "../../../components/CommonComponents/FormContainer";
import LeftWrapper from "../../../components/CommonComponents/LeftWrapper";
import MessageHeading from "../../../components/CommonComponents/MessageHeading";
import Wrapper from "../../../components/CommonComponents/Wrapper";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import MessageLabel from "../../../components/CommonComponents/MessageLabel";
import AdminCustomButton from "../../../components/CommonComponents/AdminCustomButton";
import RightWrapper from "../../../components/CommonComponents/RightWrapper";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";

function AddMenu() {
  const { id } = useParams()

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const navigate = useNavigate()

  const menuNameRef = useRef(null)
  const siteUrlRef = useRef(null)
  const customUrlRef = useRef(null)
  const darkImageRef = useRef(null)
  const lightImageRef = useRef(null)
  const typeRef = useRef(null)

  const [editUser, setEditUser] = useState({
    name: "",
    url: "",
    in_menu: 0,
    in_side_menu: 0,
    custom_url: "",
    select_url: "",
    parent_id: 0,
    type: ""
  });

  const avoidKeys = ["updated_at", "created_at", "active", "dark_mode_image", "image", "user_id", "id"]

  const [editUserError, setEditUserError] = useState({})
  const [categories, setCategories] = useState([]);
  const [darkImageFile, setDarkImageFile] = useState('');
  const [lightImageFile, setLightImageFile] = useState('');
  const [showOverlay, setShowOverlay] = useState(false)
  const [domReload, setDomReload] = useState(false)


  const handleImageChange = async (event, type) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        if (type == "dark") {
          setDarkImageFile(selectedFile);
        }
        else {
          setLightImageFile(selectedFile)
        }
      };
      reader.readAsDataURL(selectedFile);
    }
    setEditUserError((editUserError) => ({
      ...editUserError,
      [type]: '',
    }));
  };

  const formValidation = () => {
    let formIsValid = true;
    if (!editUser?.name) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        name: "Menu title cannot be empty.",
      }));
      menuNameRef.current.focus();
      formIsValid = false;
    }
    if (!editUser?.url) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        url: "Menu url cannot be empty.",
      }));
      typeRef.current.focus();
      formIsValid = false;
    }


    if (editUser?.url == "site_url" && !editUser?.select_url) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        select_url: "Please provide site url.",
      }));
      siteUrlRef.current.focus();
      formIsValid = false;
    }
    if (editUser?.url == "custom_url" && !editUser?.custom_url) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        custom_url: "Please provide custom url.",
      }));
      customUrlRef.current.focus();
      formIsValid = false;
    }

    if (!id) {
      if (!lightImageFile) {
        setEditUserError((prevFormData) => ({
          ...prevFormData,
          light: "Please provide light mode icon.",
        }));
        lightImageRef.current.focus();
        formIsValid = false;
      }

      if (!darkImageFile) {
        setEditUserError((prevFormData) => ({
          ...prevFormData,
          dark: "Please provide dark mode icon.",
        }));
        darkImageRef.current.focus();
        formIsValid = false;
      }
    }
    return formIsValid
  }

  const handleSubmit = async () => {
    setShowOverlay(true)
    try {
      const formData = new FormData();
      for (const key in editUser) {
        formData.append(key, editUser[key]);
      }
      await axios.post(`${process.env.REACT_APP_Baseurl}/admin/menu/store`, formData, { headers: headers })
        .then((res) => {
          handleImageUpload(res?.data?.Menu?.id)
          toast.success(res?.data?.message)
          setShowOverlay(false)
          navigate("/menu");
        }).catch((err) => {
          toast.error(err?.data?.message)
        })
    }
    catch (err) {
      toast.error(err?.data?.message)
    }
  }

  const handleUpdate = async () => {
    setShowOverlay(true)
    try {
      const formData = new FormData();
      formData.append("type", editUser?.type);
      formData.append("name", editUser?.name);
      formData.append("url", editUser?.url);
      formData.append("in_menu", editUser?.in_menu);
      formData.append("in_side_menu", editUser?.in_side_menu);
      formData.append("custom_url", editUser?.custom_url);
      formData.append("select_url", editUser?.select_url);
      formData.append("parent_id", editUser?.parent_id);
      formData.append("order", editUser?.order);
      await axios.post(`${process.env.REACT_APP_Baseurl}/admin/menu/update/${id}`, formData, { headers: headers })
        .then((res) => {
          if (res?.data?.status) {
            handleImageUpload(res?.data?.Menu?.id)
            toast.success(res?.data?.message)
            setShowOverlay(false)
            setDomReload(!domReload)
          }
          else {
            toast.error(res?.data?.message)
            setShowOverlay(false)
          }
        }).catch((err) => {
          toast.error(err?.data?.message)
          setShowOverlay(false)
        })
    }
    catch (err) {
      toast.error(err?.data?.message)
      setShowOverlay(false)
    }
  }


  const handleImageApi = async (imageFormData) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/Image-upload-menus`, imageFormData, { headers: headers });
      if (res?.data?.status) {
        setDarkImageFile('');
        setLightImageFile('');
        toast.success("Images uploaded successfully!");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || "Image upload failed");
    }
  }
  const handleImageUpload = async (MenuId) => {
    if (id) {
      if (lightImageFile || darkImageFile) {
        const imageFormData = new FormData();
        imageFormData.append("menu_id", MenuId);
        if (lightImageFile) imageFormData.append("image", lightImageFile);
        if (darkImageFile) imageFormData.append("dark_mode_image", darkImageFile);
        handleImageApi(imageFormData)
      }
    }
    else if (lightImageFile && darkImageFile) {
      const imageFormData = new FormData();
      imageFormData.append("menu_id", MenuId);
      imageFormData.append("image", lightImageFile);
      imageFormData.append("dark_mode_image", darkImageFile);
      handleImageApi(imageFormData)
    } else {
      toast.error("Please select both light and dark mode images.");
    }
  };

  const handleValidate = async (e) => {
    e.preventDefault()
    if (formValidation()) {
      if (!id) {
        handleSubmit()
      }
      else {
        handleUpdate()
      }
    }
    else {
      console.log("Form validation failed ");
    }
  }

  const handleInput = (e) => {
    const { name, type, value, checked } = e.target;

    if (type === 'checkbox') {
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [name]: checked ? 1 : 0,
      }));
    } else {
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [name]: value,
      }));
    }
    if (!id) {
      const propertyToUpdate = name == 'select_url' ? 'custom_url' : 'select_url';
      setEditUser((prevEditUserError) => ({
        ...prevEditUserError,
        [propertyToUpdate]: '',
      }));
    }

    setEditUserError((prevEditUserError) => ({
      ...prevEditUserError,
      [name]: '',
    }));
  };

  useEffect(async () => {
    await axios.get(`${process.env.REACT_APP_Baseurl}/admin/menu/create`, { headers: headers, })
      .then((response) => {
        var result = response?.data?.categories;
        setCategories(result);
      })
      .catch((error) => console.log(error));

    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/menu/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Menu;
      var result = resData?.categories;
      setEditUser(res);
    };
    if (id) {
      getUser();
    }

  }, [id, domReload]);


  return (
    <>
      {showOverlay && <Response_Processing></Response_Processing>}
      <AdminSaveHeader heading={id ? "Edit Menu" : "Add Menu"} saveText={id ? "Update Menu" : "Save Menu"} saveMethod={handleValidate} />
      <form onSubmit={handleValidate}>
        <FormContainer>
          <LeftWrapper>
            <Wrapper>
              <MessageHeading text={id ? editUser?.name : "Create Menu"} />
              <MessageLabel text="Menu Name" className="mandatorySimple" />
              <input type="text" name="name" value={editUser?.name} onChange={handleInput} placeholder="Enter title" ref={menuNameRef} className="rs-input py-3 px-3 custom-placeholder theme-text-color border border-1 theme-border-color  mb-3" />
              {editUserError?.name && (<MessageBox text={`${editUserError?.name}`} classname='errorred mb-2 d-block' />)}

              <MessageLabel text="Menu Item URL" className="mandatorySimple" />
              <select name="url" value={editUser?.url} onChange={handleInput} className="form-select mb-3 custom-placeholder   border border-1 theme-border-color  theme-text-color" ref={typeRef}>
                <option value='' className="theme-bg-color theme-text-color" >Select URL Type</option>
                <option value='site_url' className="theme-bg-color theme-text-color">Site Url</option>
                <option value='custom_url' className="theme-bg-color theme-text-color">Custom Url</option>
              </select>
              {editUserError?.url && (<MessageBox text={`${editUserError?.url}`} classname='errorred mb-2 d-block' />)}

              {editUser?.url == "site_url" && <><MessageLabel text="Menu Item URL (ex. /site/url)" className="mandatorySimple" />
                <input type="text" name="select_url" value={editUser?.select_url} onChange={handleInput} placeholder="Enter site url" ref={siteUrlRef} className="rs-input py-3 px-3 custom-placeholder theme-text-color border border-1 theme-border-color  mb-3" />
                {editUserError?.select_url && (<MessageBox text={`${editUserError?.select_url}`} classname='errorred mb-2 d-block' />)}
              </>}

              {editUser?.url == "custom_url" && <><MessageLabel text="Custom URL (ex. full url)" className="mandatorySimple" />
                <input type="text" name="custom_url" value={editUser?.custom_url} onChange={handleInput} placeholder="Enter custom url" ref={customUrlRef} className="rs-input py-3 px-3 custom-placeholder theme-text-color border border-1 theme-border-color  mb-3" />
                {editUserError?.custom_url && (<MessageBox text={`${editUserError?.custom_url}`} classname='errorred mb-2 d-block' />)}
              </>}

              <MessageLabel text="or Dropdown for" className="" />
              <select name="type" value={editUser?.type} onChange={handleInput} className="form-select mb-3 custom-placeholder   border border-1 theme-border-color  theme-text-color" >
                {categories?.map((item, key) => (
                  <option key={item?.id} className="theme-bg-color theme-text-color" value={item?.value} >{item?.name}</option>
                ))}
              </select>
            </Wrapper>

            <Wrapper>
              <MessageHeading text="Status Settings" />
              <div className="row mx-0">
                <div className="col-9 ps-0">
                  <MessageLabel text="Show In Home " />
                </div>

                <div className="col-3 text-end">
                  <label className="switch">
                    <input name="in_menu" onChange={handleInput} checked={Number(editUser?.in_menu) === 1} type="checkbox" className="rs-input" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {/* <div className="row mx-0">
                <div className="col-9 ps-0">
                  <MessageLabel text="Show In SideMenu " />
                </div>
                <div className="col-3 text-end">
                  <label className="switch">
                    <input name="in_side_menu" onChange={handleInput} checked={editUser?.in_side_menu === 1} type="checkbox" className="rs-input" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div> */}
            </Wrapper>
          </LeftWrapper>
          <RightWrapper>
            <Wrapper>
              <MessageHeading text='Menu Icon' />
              <MessageLabel text="Dark Mode" />
              {id && <img src={editUser?.dark_mode_image} className="menuIconImageLoad object-fit-contain rounded-2" />}
              <div className="mx-0 my-3 row">
                <div className="col-6 ps-0">
                  <div className="imagedrop theme-border-color" >
                    <input type="file" ref={darkImageRef} onChange={(event) => handleImageChange(event, 'dark')} accept="image/*" className="opacity-0 w-100 h-100" key={darkImageFile ? darkImageFile : "inputKey"} />
                    <label className="theme-text-color position-absolute"><CameraRetroIcon className=" theme-text-color" /> </label>
                  </div>
                </div>
                <div className="col-6 pe-0">
                  <div>
                    {darkImageFile && (
                      <div className="imagedropcopy overflow-hidden rounded-2 theme-border-color text-end">
                        <div className="row mx-0">
                          <div className="col-10 p-0 ">
                            <img src={URL?.createObjectURL(darkImageFile)} alt="Uploaded" className="thumbnails-upload-images" />
                          </div>
                          <div className="col-2 p-0" style={{ alignSelf: "center" }}>
                            <button onClick={() => setDarkImageFile(null)} type="button" className="bg-transparent theme-text-color"><IoClose className="custom-close-btn"></IoClose></button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {editUserError?.dark && (<MessageBox text={`${editUserError?.dark}`} classname='errorred mb-2 d-block' />)}
              <MessageLabel text="Light Mode" />
              {id && <img src={editUser?.image} className="menuIconImageLoad object-fit-contain rounded-2" />}
              <div className="mx-0 my-3 row">
                <div className="col-6 ps-0">
                  <div className="imagedrop theme-border-color" >
                    <input type="file" ref={lightImageRef} onChange={(event) => handleImageChange(event, 'light')} accept="image/*" className="opacity-0 w-100 h-100" key={lightImageFile ? lightImageFile : "inputKey"} />
                    <label className="theme-text-color position-absolute"><CameraRetroIcon className=" theme-text-color" /> </label>
                  </div>
                </div>
                <div className="col-6 pe-0">
                  <div>
                    {lightImageFile && (
                      <div className="imagedropcopy overflow-hidden rounded-2 theme-border-color text-end">
                        <div className="row mx-0">
                          <div className="col-10 p-0 ">
                            <img src={URL?.createObjectURL(lightImageFile)} alt="Uploaded" className="thumbnails-upload-images" />
                          </div>
                          <div className="col-2 p-0" style={{ alignSelf: "center" }}>
                            <button onClick={() => setLightImageFile(null)} type="button" className="bg-transparent theme-text-color"><IoClose className="custom-close-btn"></IoClose></button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {editUserError?.light && (<MessageBox text={`${editUserError?.light}`} classname='errorred mb-2 d-block' />)}
            </Wrapper>

          </RightWrapper>
          <AdminCustomButton saveMethod={handleValidate} type="submit" saveText={id ? "Update Menu" : "Save Menu"} className="ms-auto" />
        </FormContainer>
      </form>
    </>
  );
}

export default AddMenu;
