import React from "react";
import ReactApexChart from "react-apexcharts";
import { BiMenu } from "react-icons/bi";
import "./TotalRevenue.css";

function TotalRevenue() {
  // Replace these values with your desired percentages
  const ppvPercentage = 65;
  const subscriptionPercentage = 50;

  const PPVPayment = {
    chart: {
      height: 350,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
      },
    },
    labels: ["PPV Payment"],
  };

  const SubscriptionPayment = {
    chart: {
      height: 350,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
      },
    },
    labels: ["Subscription Payment"],
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="iq-header-title p-0">
                <h4 className="card-title">Revenue System</h4>
              </div>

              <div className="iq-card mt-4">
                <div className="row p-3">
                  <div className="col-md-12">
                    <div className="row p-3">
                      <div className="col-md-6">
                        <div className="d-flex align-items-center justify-content-center">
                          <div>
                            <label>PPV Payment ({ppvPercentage}%)</label>
                          </div>
                        </div>
                        <div id="chart">
                          <ReactApexChart
                            options={PPVPayment}
                            series={[ppvPercentage]}
                            type="radialBar"
                            height={350}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex align-items-center justify-content-center">
                          <div>
                            <label>
                              Subscription Payment ({subscriptionPercentage}%)
                            </label>
                          </div>
                        </div>
                        <div id="chart">
                          <ReactApexChart
                            options={SubscriptionPayment}
                            series={[subscriptionPercentage]}
                            type="radialBar"
                            height={350}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default TotalRevenue;
