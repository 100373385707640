import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./EditSlider.css";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function EditSlider(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [slider, setSlider] = useState([]);
  const [editUser, setEditUser] = useState({
    user_id: "",
    title: "",
    link: "",
    trailer_link: "",
    active: "",
    order_position: "",
    slider_image_url: "",
    slider_player_image_url: "",
  });
  const [editname, setEditname] = useState({
    title: "",
  });

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/slider/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.slider[0];
      // console.log(res);
      setEditUser(res);
      setEditname(res);
      // {result.map((item) =>
      //   setImageurl(item.image_url),
      //   // console.log(setImageurl)
      //   )}
      // console.log(res)
    };
    getUser();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/slider/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.slider;
        setSlider(result);
        // console.log(result);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      case 3:
        setSelectedFile3(null);
        if (fileInputRef3.current) {
          fileInputRef3.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });

  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const titleInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      title: editUser?.title,
      slider_id: id,
      link: editUser?.link,
      trailer_link: editUser?.trailer_link,
      active: editUser?.active,
      order_position: editUser?.order_position,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/slider/update`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();
        formData.append("slider_id", id);
        if (selectedFile1) {
          formData.append("slider_image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("player_image", selectedFile2);
        }

        await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/slider/slider_image`,
          formData,
          { headers: headers }
        );
        secondApiStatus = true;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddentitle(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Slider</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Slider
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>{editname?.title}</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Slider Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="username"
                        name="title"
                        placeholder="Enter Title"
                        onChange={handleInput}
                        value={editUser?.title}
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Target Link</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        placeholder="Enter Link"
                        name="link"
                        // id="link"
                        value={editUser?.link}
                        onChange={handleInput}
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Trailer Link</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        placeholder="Enter Link"
                        name="trailer_link"
                        // id="trailer_link"
                        value={editUser?.trailer_link}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Status Setting</h5>
                  <hr></hr>

                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-body d-flex justify-content-between">
                      <label className="">User Active Status </label>
                      <label className="switch">
                        <input
                          name="active"
                          onChange={handleInputenable}
                          defaultChecked={editUser?.active === 1 ? true : false}
                          checked={editUser?.active === 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="active"
                          onChange={handleInputenable}
                          value={editUser?.active === 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Slider
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Slider Image</label>
                    <p className="t-1">
                      Select the Live stream image( 16:9 Ratio or 1280X720px )
                    </p>
                    <div>
                      {editUser?.slider_image_url ? (
                        <img
                          src={editUser?.slider_image_url}
                          alt="Image"
                          className="thumbnails-images"
                        />
                      ) : (
                        <Response_Loading_processing></Response_Loading_processing>
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(1)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Player Image Cover</label>
                    <p className="t-1">
                      Select the Live stream image( 16:9 Ratio or 1280X720px )
                    </p>
                    <div>
                      {editUser?.slider_player_image_url ? (
                        <img
                          src={editUser?.slider_player_image_url}
                          alt="Image"
                          className="thumbnails-images"
                        />
                      ) : (
                        <Response_Loading_processing></Response_Loading_processing>
                      )}
                    </div>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(2)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section  container-fluid pt-3">
            <div className="admin-section-title">
              <div className="iq-card">
                <div className="row align-items-center ">
                  <div className="modal-header">
                    <h4 className="modal-title">Edit Slider</h4>
                  </div>

                  <div className="modal-body">
                    <form>
                      <div className="form-group ">
                        <label>
                          Select the Slider Image{" "}
                          <span>(1280x720 px or 16:9 ratio)</span>
                        </label>
                        <div>
                          <img src={editUser?.slider_image_url} width="150" />
                        </div>

                        <input
                          type="file"
                          multiple="true"
                          className="form-control"
                          name="slider"
                          id="slider"
                          onChange={(e) => setSlider_image(e.target.files[0])}
                        />
                      </div>

                      <div className="form-group ">
                        <label className="mb-1">
                          Player Thumbnail{" "}
                          <span>(16:9 Ratio or 1280X720px)</span>
                        </label>
                        <br />
                        <div>
                          <img
                            src={editUser?.slider_player_image_url}
                            width="150"
                          />
                        </div>
                        <input
                          type="file"
                          name="player_image"
                          className="form-control"
                          id="player_image"
                          onChange={(e) => setPlayer_image(e.target.files[0])}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Target Link:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="link"
                          id="link"
                          value={editUser?.link}
                          onChange={handleInput}
                        />
                      </div>
                      <div className="form-group ">
                        <label>Trailer Link:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="trailer_link"
                          id="trailer_link"
                          value={editUser?.trailer_link}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Title:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          id="title"
                          value={editUser?.title}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="col-lg-6">
                                <label>Status:</label>
                              </div>
                              <div className="col-lg-6">
                                <label className="switch">
                                  <input
                                    name="active"
                                    onChange={handleInput}
                                    defaultChecked={
                                      editUser?.active === 1 ? true : false
                                    }
                                    checked={
                                      editUser?.active === 1 ? true : false
                                    }
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="active"
                                    onChange={handleInput}
                                    value={editUser?.active === 1 ? "1" : "0"}
                                  ></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={handleUpdate}
                          id="submit-new-cat"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}

export default EditSlider;
