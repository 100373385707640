
import { React, useState, useEffect } from "react";
// import loadings from "../../../src/components/Images/loading/gif_loadings.gif";
import axios from "axios"
import { Helmet } from 'react-helmet';

function Metadata() {
    const [metadetails, setMetadetails] = useState({
        website_name: "",
        website_description: "",
        website_description: "",
        favicon_url: "",
        logo_url: "",
    });
    const [theme_mode_details, setTheme_mode_details] = useState({
        logo_url: "",
    });
    const url = new URL(window.location.href);
    const segments = url.pathname.split('/');
    const lastSegment = segments.filter(segment => segment !== '').pop();

    useEffect(() => {
        const fetchData = async () => {
            const formData = new FormData();
            formData.append("Login_user_type", 9);
            formData.append("Login_user_id", null);
            formData.append("Login_device_type", "web");

            try {
                const reqData = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Header-index`, formData);
                setMetadetails(reqData?.data?.Setting);
                setTheme_mode_details(reqData?.data?.theme_mode_details);
                const gifVideo = reqData?.data?.loading_gif_video_url;
                localStorage.setItem('loadingGifVideo', gifVideo);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta property="og:url" content={lastSegment} />
                <meta property="og:title" content={metadetails?.website_name} />
                <meta property="og:description" content={metadetails?.website_description} />
                <meta property="og:image" content={theme_mode_details?.logo_url} />
                <title>{metadetails?.website_name}</title>
                <link rel="icon" type="image/x-icon" href={metadetails?.favicon_url}></link>
                {/* <link rel="canonical" href={window.location.path} /> */}
                <link rel="canonical" href={lastSegment} />
            </Helmet>
        </>
    );
}

export default Metadata;
