import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import JoditEditor from "jodit-react";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function Edit_Video_Playlist(props) {
  const { id } = useParams();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    title: "",
    slug: "",
    description: "",
    Thumbnail: "",
  });
  const [editname, setEditname] = useState({
    title: "",
  });

  const access_token = localStorage.getItem("access_token");
  const [loading, setLoading] = useState(true);
  // const [data, setData] = useState([]);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, description: e });
    // console.log(e, "onchnageddddd");
  };

  const fileInputRef1 = useRef(null);
  // const fileInputRef2 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  // const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/video/playlist-edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.AdminVideoPlayList[0];

      setEditUser(res);
      setEditname(res);
      setLoading(false);
    };
    getUser();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/video/playlist-index`, {
        headers: headers,
      })
      .then((response) => {
        // var result = response?.data?.AdminVideoPlayList[0];
        // setData(result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  // const handleInputenable = (e) => {
  //   if (e.target.checked === true) {
  //     setEditUser({ ...editUser, [e.target.name]: 1 });
  //   } else {
  //     setEditUser({ ...editUser, [e.target.name]: 0 });
  //   }
  // };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const titleInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagetitle("Name cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      title: editUser?.title,
      slug: editUser?.slug,
      description: editUser?.description,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/video/playlist-update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();
        formData.append("AdminVideoPlayList_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }

        await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-video-playlist`,
          formData,
          { headers: headers }
        );
        secondApiStatus = true;
      } else if (resjson?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      // Check if resultapifalse is defined and not null
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <section className="container-fluid">
          <div className="col-lg-12 row py-3">
            <div className="col-6 col-sm-6 col-lg-6">
              <h3>Edit Playlist</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Playlist
                </button>
              </div>
            </div>
          </div>

          <div>
            {showOverlay && <Response_Processing></Response_Processing>}
          </div>

          <div className="row col-lg-12 ">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
              <div className="card p-3">
                <h5>{editname?.title}</h5>
                <hr></hr>
                <div className=" text-start">
                  <label className="m-0">
                    Title<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="title"
                      name="title"
                      placeholder="Enter Name"
                      onChange={handleInput}
                      value={editUser?.title}
                      ref={titleInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddentitle && (
                      <p>{validationMessagetitle}</p>
                    )}
                  </span>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0">Slug</label>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg"
                      id="slug"
                      name="slug"
                      placeholder="Enter Slug"
                      onChange={handleInput}
                      value={editUser?.slug}
                    />
                  </div>
                </div>

                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0">Playlist Description</label>
                    <div className="mt-2">
                      <JoditEditor
                        className="rs-input"
                        value={editUser?.description}
                        onChange={(e) => {
                          handleChangeFirst(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-start mb-3">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Playlist
                </button>
              </div>
            </div>

            {/* Second Part of Section  */}

            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="card p-3">
                <h5>Thumbnails</h5>
                <hr></hr>

                <div className=" text-start mt-2">
                  <label className="m-0">Image</label>

                  <div>
                    {loading ? (
                      <Response_Loading_processing></Response_Loading_processing>
                    ) : (
                      <img
                        src={editUser?.Thumbnail}
                        alt="image_all"
                        className="thumbnails-images"
                      />
                    )}
                  </div>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef1.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef1}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef1,
                                setSelectedFile1
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div>
                          {selectedFile1 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL?.createObjectURL(selectedFile1)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() => handleDelete(1)}
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Edit_Video_Playlist;
