import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import edititem from "../../../components/Images/edititem.svg";

function CurrencySettings(props) {
  const [currency, setCurrency] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [modelClose, setModelClose] = useState(false);


  const [currencyItem, setcurrencyItem] = useState([]);


  const closeModal = () => {
    setShowModal(false);
  };

  const confirmToggle = async (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };


  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/currency_setting/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.CurrencySetting;
        setCurrency(result);
        setcurrencyItem(result);
      })
      .catch((error) => console.log(error));
  }, []);

  // const confirmToggle = async () => {
  //   setProcessing(true);
  //   setShowOverlay(true);
  //   try {
  //     setShowModal(false);
  //     const newStatus = currentItem?.banner === 1 ? 0 : 1;
  //     const response = await fetch(
  //       `${process.env.REACT_APP_Baseurl}/admin/curreny-slider`,
  //       {
  //         method: "POST",
  //         body: JSON.stringify({
  //           curreny_id: currentItem?.id,
  //           banner_status: newStatus,
  //         }),
  //         headers: headers,
  //       }
  //     );

  //     if (response.status === 200) {
  //       props?.setApiresponsealert({
  //         status: true,
  //         message: "Switched the Slider status successfully",
  //       });
  //       props?.setShowalert(true);
  //     } else {
  //       props?.setApiresponsealert({
  //         status: false,
  //         message: "Failed to switch slider status",
  //       });
  //       props?.setShowalert(true);
  //     }
  //     setCurrentItem(null);
  //     setProcessing(false);
  //     setShowOverlay(false);
  //   } catch (err) {
  //     var errorMessage = err?.response?.data?.message || 'An error occurred';
  //     props?.setApiresponsealert({ status: false, message: errorMessage });
  //     props?.setShowalert(true);
  //     setProcessing(false);
  //     setShowOverlay(false);
  //   }
  // };


  // const closeModal = () => {
  //   setModelClose(!closeModal);
  //   setShowModal(false);
  // };


  const handleConfirmation = async (confirmed) => {
    if (confirmed) {
      try {
        setProcessing(true);
        setShowOverlay(true);

        const newStatus = currentItem.enable_multi_currency === 1 ? 0 : 1;
        const response = await fetch(`${process.env.REACT_APP_Baseurl}/admin/currency_setting/enable_multi_currency`, {
          method: 'POST',
          body: JSON.stringify({
            menu_id: currentItem.id,
            enable_multi_currency: newStatus,
          }),
          headers: headers,
        });

        const responseData = await response.json();
        if (response.ok) {
          const updatedItems = currencyItem.map((menuItem) => {
            if (menuItem.id === currentItem.id) {
              return {
                ...menuItem,
                enable_multi_currency: newStatus,
              };
            }
            return menuItem;
          });

          setcurrencyItem(updatedItems);
          props?.setApiresponsealert(responseData);
          props?.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);

        } else {
          props?.setApiresponsealert(responseData);
          props?.setShowalert(true);
        }
      } catch (err) {
        var errorMessage = err.response ? err.response.data.message || 'An error occurred' : 'An error occurred';
        props?.setApiresponsealert({
          status: false,
          message: errorMessage
        });
        props?.setShowalert(true);
      } finally {
        setProcessing(false);
        setShowOverlay(false);
      }
    };

    setCurrentItem(null);
    setShowModal(false);
  };


  const handleToggle = async (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  return (
    <>

      <div
        className={`modal packageModel ${showModal ? 'show fade' : ''}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: `${showModal ? 'block' : 'none'}` }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirmation</h5>
              <button type="button" className="btn-close" onClick={closeModal}></button>
            </div>
            <div className="modal-body">Are you sure you want to switch the Slider status?</div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => handleConfirmation(false)}>
                No
              </button>
              <button type="button" className="btn btn-primary" onClick={() => handleConfirmation(true)}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="col-lg-12 row py-3">
              <div className="col-12 col-sm-12 col-lg-12">
                <h3>Currency Setting</h3>
              </div>
            </div>

            <div className="iq-card ">
              <div className="col-lg-12">
                <div className="">
                  <Table>
                    <thead>
                      <tr className="title">
                        <th>CURRENCY SYMBOL</th>
                        <th>CURRENCY COUNTRY</th>
                        <th>Enable Multi Currency</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currency?.map((item) => (
                        <tr className="dd" id="" key={item?.id}>
                          <td valign="bottom" className="text-center">
                            {item?.symbol}
                          </td>
                          <td valign="bottom" className="text-center">
                            {item?.country}
                          </td>
                          <td valign="bottom" className="text-center">
                            <div>

                              <label className="switch">
                                <input
                                  name="enable_multi_currency"
                                  id={`enable_multi_currency_${item.id}`}
                                  className={item.enable_multi_currency === 0 ? 'inactive' : null}
                                  checked={item.enable_multi_currency === 1}
                                  onChange={() => confirmToggle(item)}
                                  type="checkbox"
                                />
                                <span className="slider round"></span>
                              </label>

                            </div>
                          </td>

                          <td>
                            <div className="editdropdown">
                              <span className="editdropdown-button">
                                <span>
                                  <i
                                    className="fa fa-ellipsis-v"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </span>
                              <div className="editdropdown-menu">
                                <Link
                                  to={
                                    "/update_currency_settings/" +
                                    item?.id
                                  }
                                  className="edit"
                                >
                                  <span>
                                    <img
                                      src={edititem}
                                      alt="flogo"
                                      width={20}
                                      height={20}
                                    />
                                    <span className="ms-2">Edit Currency</span>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    </>
  );
}

export default CurrencySettings;
