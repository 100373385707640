// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Videopartner .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #0d6efd;
    border-color: #007fff #007fff #fff;
}
.Videopartner .nav-link {
    color: #000000;
    text-decoration: none;
}
.Videopartner .nav-tabs {
    border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Video/Video_Management/Videopartner.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,yBAAyB;IACzB,kCAAkC;AACtC;AACA;IACI,cAAc;IACd,qBAAqB;AACzB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".Videopartner .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {\n    color: #ffffff;\n    background-color: #0d6efd;\n    border-color: #007fff #007fff #fff;\n}\n.Videopartner .nav-link {\n    color: #000000;\n    text-decoration: none;\n}\n.Videopartner .nav-tabs {\n    border-bottom: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
