import React from "react";

const EditAdsList = () => {
  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card p-2 mt-3">
              <h4 className="p-2">
                <i className="entypo-plus"></i> Update Advertisement
              </h4>
              <hr />
              <div className="p-3">
                <div className="col-md-12 mb-3">
                  <label className="m-0">Company Name:</label>

                  <select id="in_name" name="in_name" className="form-control">
                    <option value="0">Select</option>
                    <option value="1">webnexs</option>
                    <option value="0">test</option>
                  </select>
                </div>

                <div className="col-md-12 mb-3" data-collapsed="0">
                  <label className="m-0">Ads Name:</label>
                  <div className="panel-body">
                    <input
                      type="text"
                      className="form-control"
                      name="text"
                      id="slug"
                      placeholder="Enter"
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3">
                  <label className="m-0">Ads Categories:</label>

                  <select id="in_cate" name="in_cate" className="form-control">
                    <option value="0">Select</option>
                    <option value="1">webnexs</option>
                    <option value="0">test</option>
                  </select>
                </div>

                <div className="col-md-12 mb-3" data-collapsed="0">
                  <label className="m-0">Ads position:</label>
                  <div className="panel-body">
                    <select id="in_ads" name="in_ads" className="form-control">
                      <option value="0">Select</option>
                      <option value="1">webnexs</option>
                      <option value="0">test</option>
                    </select>
                  </div>
                </div>

                <hr />

                <div className="row mt-3">
                  <div className="col-sm-12 text-end">
                    <input
                      type="submit"
                      value="Upload"
                      className="btn btn-primary "
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditAdsList;
