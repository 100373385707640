import React from "react";
import "../Signin/Header.css";
import { Link } from "react-router-dom";
import logo from "../Images/flicklogo.png";
function Header() {
  return (
    <section className="header1">
      <div className="container text-center p-3">
        <Link to="/admin">
          <img src={logo} className="p-1" alt="flogo" />
        </Link>
      </div>
    </section>
  );
}

export default Header;
