// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  /* header 1 start */

.header1 img{
  max-height: 50px;
}
.header1{
  background-color: rgb(0, 0, 0);
}
  /* header 1 end*/`, "",{"version":3,"sources":["webpack://./src/components/Signin/Header.css"],"names":[],"mappings":"EAAE,mBAAmB;;AAErB;EACE,gBAAgB;AAClB;AACA;EACE,8BAA8B;AAChC;EACE,gBAAgB","sourcesContent":["  /* header 1 start */\n\n.header1 img{\n  max-height: 50px;\n}\n.header1{\n  background-color: rgb(0, 0, 0);\n}\n  /* header 1 end*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
