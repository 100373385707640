import './MusicSettings.css'
import React, { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react';
import { toast } from "react-toastify"
import Response_Processing from '../../../layout/Others/Response_Processing';
const MusicSettings = () => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };
    const [musicData, setMusicData] = useState();
    const [showOverlay, setShowOverlay] = useState(false);
    const [domLoaded, setDomLoaded] = useState(false)

    const handleCheckBox = (e) => {
        if (e.target.checked == true) {
            setMusicData({ ...musicData, [e.target.name]: 1 });
        } else {
            setMusicData({ ...musicData, [e.target.name]: 0 });
        }
    };

    const handleCheckBoxLayout = (e) => {
        const { name } = e.target;
        const propertyToUpdate = name === 'enable_embed' ? 'enable_floating' : 'enable_embed';
        const newValue = e.target.checked ? 1 : 0;

        setMusicData((prevEditUser) => ({
            ...prevEditUser,
            [name]: newValue,
            [propertyToUpdate]: 0
        }));
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/music_setting/index`, { headers: headers });
            setMusicData(response.data.Music_Setting);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleSubmit = async () => {
        try {
            setShowOverlay(true)
            const response = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/music_setting/store`, musicData, { headers: headers });
            if (response.status) {
                setDomLoaded(!domLoaded)
                setShowOverlay(false)
                toast.success(response.data.message)
            }
        } catch (error) {
            setShowOverlay(false)
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        fetchData()
    }, [domLoaded])


    return (
        <section className="container-fluid p-0 trial-bg AddChannelLive">
            {showOverlay && <Response_Processing></Response_Processing>}

            <div className="d-flex align-items-center flex-wrap p-3">
                <div className="col-sm-6 col-12 ">
                    <h4 className="admin-title mb-0  theme-text-color  text-md-start "> Music Settings</h4>
                </div>
                <div className="col-sm-6 col-12 d-flex align-items-center justify-content-end" >
                    <button onClick={handleSubmit} className="btn btn-primary admin-button-text text-white rounded-2  py-2 px-3"> Save </button>
                </div>
            </div>
            <div className="row m-0 px-1">
                <div className="col-12 col-md-6 ">
                    <div className="rounded-3 mb-4 border-0 shadow p-3 theme-bg-color">
                        <h6 className="theme-text-color border-bottom  pb-3">Choose layout</h6>
                        <div className='d-flex align-items-center justify-content-start gap-3 mt-3'>
                            <div className={` p-2 rounded-2 position-relative  ${musicData?.enable_embed ? 'border border-primary activeTrack' : ''}`} >
                                <input type="checkbox" name="enable_embed" className='opacity-0 layoutCheckbox' onChange={handleCheckBoxLayout} checked={musicData?.enable_embed} />
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style={{ width: '100px', height: "100px", fill: `${musicData?.enable_embed ? '#0d6efd' : '#eee'}` }}><path fill-rule="evenodd" d="M20 27.035a2 2 0 0 0-2 2v12.41a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2v-12.41a2 2 0 0 0-2-2H20Zm10.812 3.572a.5.5 0 0 0-.765.424v3.73a.5.5 0 0 0 .765.423l2.983-1.864a.5.5 0 0 0 0-.848l-2.983-1.865Zm5.177 1.295a.5.5 0 0 1 .765-.424l.752.47v-.046a.5.5 0 0 1 .765-.424l1.57.981a.5.5 0 0 1 0 .848l-1.57.981a.5.5 0 0 1-.765-.424v-.046l-.752.47a.5.5 0 0 1-.765-.424v-1.962Zm-10.752 2.386a.5.5 0 0 0 .765-.424v-.038l.74.462a.5.5 0 0 0 .764-.424v-1.962a.5.5 0 0 0-.765-.424l-.739.462v-.038a.5.5 0 0 0-.765-.424l-1.57.981a.5.5 0 0 0 0 .848l1.57.981ZM21 39.946a.5.5 0 0 1 .5-.5h21a.5.5 0 1 1 0 1h-21a.5.5 0 0 1-.5-.5Z" clip-rule="evenodd"></path><path fill-rule="evenodd" d="M60 11a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v42a4 4 0 0 0 4 4h48a4 4 0 0 0 4-4V11ZM6 13h52v40l-.005.15A2 2 0 0 1 56 55H8l-.15-.005A2 2 0 0 1 6 53V13Zm5-2.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm-4 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm8 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" clip-rule="evenodd"></path></svg>
                                <p className='text-center'>Embed</p>
                            </div>
                            <div className={`p-2  rounded-2  position-relative ${musicData?.enable_floating ? 'border border-primary activeTrack' : ''} `} onClick={handleCheckBox}>
                                <input type="checkbox" name="enable_floating" className='opacity-0 layoutCheckbox' onChange={handleCheckBoxLayout} checked={musicData?.enable_floating} />
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style={{ width: '100px', height: "100px", fill: `${musicData?.enable_floating ? '#0d6efd' : '#eee'}` }}><path fill-rule="evenodd" d="M57.068 44h-50v9a1 1 0 0 0 1 1h48a1 1 0 0 0 1-1v-9Zm-40.256 2.607a.5.5 0 0 0-.765.424v3.73a.5.5 0 0 0 .765.423l2.983-1.864a.5.5 0 0 0 0-.848l-2.983-1.865Zm5.177 1.295a.5.5 0 0 1 .765-.424l.752.47v-.046a.5.5 0 0 1 .765-.424l1.57.981a.5.5 0 0 1 0 .848l-1.57.981a.5.5 0 0 1-.765-.424v-.046l-.752.47a.5.5 0 0 1-.765-.424v-1.962Zm-10.752 2.386a.5.5 0 0 0 .765-.424v-.038l.74.462a.5.5 0 0 0 .764-.424v-1.962a.5.5 0 0 0-.765-.424l-.739.462v-.038a.5.5 0 0 0-.765-.424l-1.57.981a.5.5 0 0 0 0 .848l1.57.981ZM30 48.946a.5.5 0 0 1 .5-.5h22a.5.5 0 0 1 0 1h-22a.5.5 0 0 1-.5-.5Z" clip-rule="evenodd"></path><path fill-rule="evenodd" d="M60 11a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v42a4 4 0 0 0 4 4h48a4 4 0 0 0 4-4V11ZM6 13h52v40l-.005.15A2 2 0 0 1 56 55H8l-.15-.005A2 2 0 0 1 6 53V13Zm5-2.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm-4 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm8 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" clip-rule="evenodd"></path></svg>
                                <p className='text-center'>Floating</p>
                            </div>
                        </div>
                        {musicData?.enable_floating ?
                            <>
                                <div className='d-flex align-items-center justify-content-between gap-3 py-3'>
                                    <p className='text-center'>Minimize Button</p>
                                    <div>
                                        <label className="switch">
                                            <input type="checkbox" name="enable_minimize_button" onChange={handleCheckBox} checked={musicData?.enable_minimize_button} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </> : ''
                        }
                    </div>
                    <div className="rounded-3 mb-4 border-0 shadow p-3 theme-bg-color">
                        <h6 className="theme-text-color border-bottom  pb-3">Player Controls</h6>
                        <div className='d-flex align-items-center justify-content-between gap-3 mt-3'>
                            <p className='text-center'>Track Controls</p>
                            <div>
                                <label className="switch">
                                    <input type="checkbox" name="enable_track_controls" onChange={handleCheckBox} checked={musicData?.enable_track_controls} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between gap-3 mt-3'>
                            <p className='text-center'>Volume </p>
                            <div>
                                <label className="switch">
                                    <input type="checkbox" name="enable_volume" onChange={handleCheckBox} checked={musicData?.enable_volume} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between gap-3 mt-3'>
                            <p className='text-center'>Loop</p>
                            <div>
                                <label className="switch">
                                    <input type="checkbox" name="enable_loop" onChange={handleCheckBox} checked={musicData?.enable_loop} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between gap-3 mt-3'>
                            <p className='text-center'>Shuffle</p>
                            <div>
                                <label className="switch">
                                    <input type="checkbox" name="enable_shuffle" onChange={handleCheckBox} checked={musicData?.enable_shuffle} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between gap-3 mt-3'>
                            <p className='text-center'>Progress Bar</p>
                            <div>
                                <label className="switch">
                                    <input type="checkbox" name="enable_progress_bar" onChange={handleCheckBox} checked={musicData?.enable_progress_bar} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-3 mb-4 border-0 shadow p-3 theme-bg-color">
                        <h6 className="theme-text-color border-bottom  pb-3">Track Info to Display</h6>
                        <div className='d-flex align-items-center justify-content-between gap-3 mt-3'>
                            <p className='text-center'>Image</p>
                            <div>
                                <label className="switch">
                                    <input type="checkbox" name="enable_track_image" onChange={handleCheckBox} checked={musicData?.enable_track_image} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between gap-3 mt-3'>
                            <p className='text-center'>Title </p>
                            <div>
                                <label className="switch">
                                    <input type="checkbox" name="enable_track_title" onChange={handleCheckBox} checked={musicData?.enable_track_title} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between gap-3 mt-3'>
                            <p className='text-center'>Artists</p>
                            <div>
                                <label className="switch">
                                    <input type="checkbox" name="enable_track_artists" onChange={handleCheckBox} checked={musicData?.enable_track_artists} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='col-12 col-md-6'>
                    <div className="rounded-3 mb-4 border-0 shadow p-3 theme-bg-color">
                        <h6 className="theme-text-color border-bottom  pb-3">Playlist Default</h6>
                        <div className='d-flex align-items-center justify-content-between gap-3 mt-3'>
                            <p className='text-center'>Show Playlist by Default</p>
                            <div>
                                <label className="switch">
                                    <input type="checkbox" name="enable_show_playlist" onChange={handleCheckBox} checked={musicData?.enable_show_playlist} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-3 mb-4 border-0 shadow p-3 theme-bg-color">
                        <h6 className="theme-text-color border-bottom  pb-3">Playlist Info to Display</h6>
                        <div className='d-flex align-items-center justify-content-between gap-3 mt-3'>
                            <p className='text-center'>Image</p>
                            <div>
                                <label className="switch">
                                    <input type="checkbox" name="enable_playlist_image" onChange={handleCheckBox} checked={musicData?.enable_playlist_image} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between gap-3 mt-3'>
                            <p className='text-center'>Title </p>
                            <div>
                                <label className="switch">
                                    <input type="checkbox" name="enable_playlist_title" onChange={handleCheckBox} checked={musicData?.enable_playlist_title} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-between gap-3 mt-3'>
                            <p className='text-center'>Artists</p>
                            <div>
                                <label className="switch">
                                    <input type="checkbox" name="enable_playlist_artists" onChange={handleCheckBox} checked={musicData?.enable_playlist_artists} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MusicSettings