import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { CiShare1 } from "react-icons/ci";
import { FaEllipsisVertical } from "react-icons/fa6";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import { adminApiHeader, audioDefaultURL } from "../../../components/CommonApis/CommonAudioApis";
import AdminDeleteModal from "../../../components/CommonComponents/AdminDeleteModal";
import { toast } from "react-toastify";
import AdminNavbar from "../../../components/CommonComponents/AdminNavbar";
import NoRecordTable from "../../../components/CommonComponents/NoRecordTable";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import { audioCategoryRoute } from "../../../components/CommonApis/CommonApiUrl";

function Manageaudiocategories() {
  const [withOutRefresh, setWithOutRefresh] = useState(false)
  const [apiData, setApiData] = useState();
  const [showOverlay, setShowOverlay] = useState(false);
  const [loading, setLoading] = useState(true)
  const [modalClose, setModalClose] = useState(false)
  const [selectAllData, setSelectAllData] = useState(false);
  const [itemDelete, setItemDelete] = useState([])

  // api start
  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${audioDefaultURL?.categoryAudio}`, { headers: adminApiHeader })
      const result = response?.data?.AllAudioGenre;
      if (response?.data?.status) {
        setLoading(false)
        setApiData(result)
      }
      else {
        console.error("audio category fetch error")
      }
    }
    catch (error) {
      const errMessage = error?.response?.data?.message
      console.error(errMessage)
    }
  }

  const handleDelete = async () => {
    setShowOverlay(true);
    handleCloseModal()
    try {
      const formData = new FormData();
      formData.append("id", "[" + itemDelete + "]");
      const response = await axios.post(`${audioDefaultURL?.categoryMultiDelete}`, formData, { headers: adminApiHeader })
      const result = response?.data;
      if (result?.status) {
        toast.success(result?.message)
        setWithOutRefresh(!withOutRefresh)
        setShowOverlay(false);
        setSelectAllData(false)
      }
      else {
        toast.error(result?.message)
        setShowOverlay(false);
      }
    }
    catch (err) {
      const errMessage = err?.response?.data?.message
      toast.error(errMessage)
      console.log(err)
    }
  }

  const saveShuffleOrder = async (updatedItems) => {
    setShowOverlay(true);

    if (!updatedItems || updatedItems?.length === 0) {
      toast.error("Menu items are empty");
      return;
    }
    else {
      const positions = updatedItems?.map((menuItem) => menuItem?.id);
      const ids = updatedItems?.map((_, index) => index + 1);
      const requestData = {
        id: JSON.stringify(positions),
        position: JSON.stringify(ids),
      };
      try {
        const response = await axios.post(`${audioDefaultURL?.categoryAudioOrderUpdate}`, requestData, { headers: adminApiHeader });
        const result = response?.data;
        if (result?.status) {
          toast.success(result?.message)
          setWithOutRefresh(!withOutRefresh)
          setShowOverlay(false);
        }
        else {
          setShowOverlay(false)
          toast.error(result?.message)
        }
      } catch (err) {
        setShowOverlay(false)
        console.error(err);
        var errorMessage = err?.response?.data?.message;
        toast.error(errorMessage)
      }
    }
  };

  // api end

  // function start

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(apiData);
    const [movedItem] = reorderedItems?.splice(result.source.index, 1);
    reorderedItems?.splice(result.destination.index, 0, movedItem);
    setApiData(reorderedItems);
    saveShuffleOrder(reorderedItems);
  };

  const handleSelectAllData = () => {
    setSelectAllData(!selectAllData);
    setItemDelete(selectAllData ? [] : apiData?.map((item) => item?.id));
  };

  const handleSingleFile = (itemId) => {
    if (itemDelete?.includes(itemId)) {
      setItemDelete(
        itemDelete?.filter((id) => id !== itemId)
      );
    } else {
      setItemDelete([...itemDelete, itemId]);
    }
  };

  const handleSingleDelete = (id) => {
    setItemDelete([id]);
    setModalClose(true);
    setSelectAllData(false);
  };

  const handleCloseModal = () => {
    setModalClose(!modalClose)
    setItemDelete([]);
  }
  const handleOpenDeleteModal = async () => {
    setModalClose(!modalClose)
  }
  // function end

  useEffect(() => {
    fetchData()
  }, [withOutRefresh])


  return (
    <>
      {showOverlay && <Response_Processing></Response_Processing>}
      {modalClose && <AdminDeleteModal modelClose={modalClose} deleteClose={handleCloseModal} deleteAction={handleDelete} buttonText={itemDelete?.length} />}
      <AdminNavbar heading="Audio Categories" totalDataLength={apiData?.length} deleteCount={itemDelete?.length} navigation={audioDefaultURL?.addRouteLinkCategory} deleteMethod={handleOpenDeleteModal} navText="Add Categories" />
      <div className="bootstrapTable theme-bg-color p-3 rounded-2">
        <div className="">
          <div className="row m-0 theme-border-bottom-secondary pb-3">
            <div className="rowCheckboxDrag"> <label><input type="checkbox" checked={selectAllData} onChange={handleSelectAllData} /> </label></div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4 "> <label className="theme-text-color fw-bold fs-6"> Category </label> </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 "><label className="theme-text-color fw-bold fs-6">Uploaded by</label></div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 "><label className="theme-text-color fw-bold fs-6"> Actions </label> </div>
          </div>
        </div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="menu-items" className="theme-bg-color"   >
          {(provided) => (
            <ul  {...provided.droppableProps} ref={provided.innerRef} className="pb-5 px-0 theme-bg-color m-0 p-0 list-unstyled">
              {loading ? <NoRecordTable result={apiData?.length} loader={true} /> :
                apiData?.map((item, index) => (
                  <Draggable key={item?.id.toString()} draggableId={item?.id.toString()} index={index} >
                    {(provided) => (
                      <li ref={provided.innerRef} {...provided.draggableProps}  {...provided.dragHandleProps} className={` theme-bg-color theme-text-color   ${item.order} index ${index + 1} w-100 theme-border-bottom-secondary px-3`} >
                        <div className="row m-0 p-0 align-items-center">
                          <div className="rowCheckboxDrag  category-order-title">
                            <input type="checkbox" checked={itemDelete.includes(item.id)} onChange={() => handleSingleFile(item.id)} />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-4 col-4  category-order-title">
                            <div className="d-flex justify-content-between align-items-center ">
                              <img src={item.image_url} className="profile" alt="image_all" />
                              <div className="rightVideoTitle">
                                <Link className="theme-text-color table-heading d-flex  align-items-center" target="_blank" to={`${audioCategoryRoute}/${item?.slug}`}> <span title={`${item.slug}`}>{`${item?.name.slice(0, 15)}...`} </span><CiShare1 style={{ width: "15px", height: "15px", strokeWidth: '1px', marginLeft: "6px" }} /> </Link>
                                <span className="dateText table-subheading "> {item?.upload_on}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-3 col-3  category-order-title">
                            <div className=" theme-text-color">{item?.uploaded_by == "1" ? "Admin" : item?.uploaded_by == "5" ? "Content Partner" : item?.uploaded_by == "4" ? "Channel Partner" : ""} </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-3 col-3  category-order-title">
                            <div className="editdropdown">
                              <span className="editdropdown-button">
                                <span>
                                  <FaEllipsisVertical className="theme-text-color" />
                                </span>
                              </span>
                              <div className="editdropdown-menu theme-bg-color">
                                <Link to={`${audioDefaultURL?.editRouteLinkCategory}${item?.id}`} className="theme-text-color  theme-bg-color">
                                  <span>
                                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                      <g>
                                        <path class="svgIcon" d="M10.3,18.5l-4.74.64A.49.49,0,0,1,5,18.59l.64-4.74a.42.42,0,0,1,.14-.28L17.31,2a.59.59,0,0,1,.85,0L22.11,6a.6.6,0,0,1,0,.84L10.58,18.36A.53.53,0,0,1,10.3,18.5Z" />
                                        <line class="svgIcon" x1="1.01" y1="21.58" x2="18.89" y2="21.58" />
                                      </g>
                                    </svg>
                                    <MessageBox text="Edit" classname='ms-2 theme-text-color'></MessageBox>
                                  </span>
                                </Link>
                                <div
                                  onClick={() => handleSingleDelete(item?.id)}
                                  className="commonActionPadding theme-bg-color"
                                >
                                  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g>
                                      <rect class="svgIcon" x="4.38" y="5.54" width="14.49" height="17.71" />
                                      <line class="svgIcon" x1="1.16" y1="5.54" x2="22.09" y2="5.54" />
                                      <g>
                                        <line class="svgIcon" x1="9.21" y1="9.56" x2="9.21" y2="19.06" />
                                        <line class="svgIcon" x1="14.04" y1="9.56" x2="14.04" y2="19.06" />
                                      </g>
                                      <path class="svgIcon" d="M7.45,5.22a2.72,2.72,0,0,1,0-.28,4.19,4.19,0,1,1,8.37,0c0,.09,0,.19,0,.29" />
                                    </g>
                                  </svg>
                                  <MessageBox text="Delete" classname='ms-2 theme-text-color'></MessageBox>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}

            </ul>
          )}
        </Droppable>
      </DragDropContext>

    </>
  );
}

export default Manageaudiocategories;