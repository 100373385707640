import { React } from "react";

function Videoforapproval() {
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid Videopartner">
            <div className="iq-card mt-4">
              <ul
                className="nav nav-tabs justify-content-around"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    CPP Uploaded Series Videos
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Channel Uploaded Series Videos
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="mb-3">
                          <h4>Content Series Video Lists</h4>
                        </div>

                        <div className="col-md-12">
                          <label for="">Choose Content Partner</label>
                          <select
                            name="channel_users"
                            className="form-select"
                            id="channel_users"
                          >
                            <option value="{{ $value->id }}"></option>
                          </select>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12">
                          <label for="">Choose Video (*To Be Moved)</label>
                          <select
                            name="video_data"
                            className="form-control"
                            id="video_data"
                          >
                            <option value="{{ $value->id }}"></option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="col-md-12 text-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        value="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="mb-3">
                          <h4>Channel Series Video Lists</h4>
                        </div>

                        <div className="col-md-12">
                          <label for="">Choose Content Partner</label>
                          <select
                            name="channel_users"
                            className="form-control"
                            id="channel_users"
                          >
                            <option value="{{ $value->id }}"></option>
                          </select>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12">
                          <label for="">Choose Video (*To Be Moved)</label>
                          <select
                            name="video_data"
                            className="form-control"
                            id="video_data"
                          >
                            <option value="{{ $value->id }}"></option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="col-md-12 text-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        value="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default Videoforapproval;
