import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import "./Razorpay.css";

function Razorpay(props) {

  const [razorpay_payment, setRazorpay_payment] = useState({
    payment_type: "",
    payment_mode: "",
    Razorpay_Name: "",
    Razorpay_Testkeyid: "",
    Razorpay_TestkeySecret: "",
    Razorpay_TestPublishableKey: "",
    Razorpay_LivekeySecret: "",
    status: "",
    Razorpay_Livekeyid: "",
    Razorpay_LivePublishableKey: "",
    Razorpay_label: "",
  });

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/razorpay_payment/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.razorpay_payment;
        var results = response?.data?.razorpay_payment;
        setRazorpay_payment(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setRazorpay_payment({ ...razorpay_payment, [e.target.name]: 1 });
    } else {
      setRazorpay_payment({ ...razorpay_payment, [e.target.name]: 0 });
    }
  };

  const handleInput = (e) => {
    setRazorpay_payment({
      ...razorpay_payment,
      [e.target.name]: e.target.value,
    });
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const razorpayment = async (e) => {
    e.preventDefault();

    const payment_mode = Number.isNaN(parseInt(razorpay_payment?.payment_mode, 10)) ? 0 : parseInt(razorpay_payment?.payment_mode, 10);
    const status = Number.isNaN(parseInt(razorpay_payment?.status, 10)) ? 0 : parseInt(razorpay_payment?.status, 10);

    const editInputvalue = {
      id: razorpay_payment?.id,
      payment_type: "RazorPay",
      payment_mode: payment_mode,
      Razorpay_Name: razorpay_payment?.Razorpay_Name,
      Razorpay_Testkeyid: razorpay_payment?.Razorpay_Testkeyid,
      Razorpay_TestkeySecret: razorpay_payment?.Razorpay_TestkeySecret,
      Razorpay_TestPublishableKey:
        razorpay_payment?.Razorpay_TestPublishableKey,
      Razorpay_LivekeySecret: razorpay_payment?.Razorpay_LivekeySecret,
      status: status,
      Razorpay_Livekeyid: razorpay_payment?.Razorpay_Livekeyid,
      Razorpay_LivePublishableKey:
        razorpay_payment?.Razorpay_LivePublishableKey,
      Razorpay_label: razorpay_payment?.Razorpay_label,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/razorpay_payment/store`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res?.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson?.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  const razorpay_redirect = () => {
    window.open("https://razorpay.com/", "_blank");
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row mt-3">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <h3>Razorpay Settings</h3>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className="text-end mb-3">
                <button onClick={razorpayment} className="btn btn-primary">
                  Update Razorpay
                </button>
              </div>

              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3 mb-3">
                  <h5>Create Razorpay</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <div className="row">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <label className="m-0">Enable Payment Mode</label>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6 text-end">
                        <div className="col-lg-12 row">
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            <p> TEST </p>
                          </div>
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            {" "}
                            <label className="switch">
                              <input
                                name="payment_mode"
                                id="payment_mode"
                                onChange={handleInputenable}
                                className="rs-input"
                                defaultChecked={
                                  razorpay_payment?.payment_mode == 1
                                    ? true
                                    : false
                                }
                                checked={
                                  razorpay_payment?.payment_mode == 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="payment_mode"
                                id="payment_mode"
                                onChange={handleInputenable}
                                value={
                                  razorpay_payment?.payment_mode == 1
                                    ? "1"
                                    : "0"
                                }
                              ></span>
                            </label>
                          </div>
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            <p> LIVE </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Razorpay Payment Plan</label>

                    <div className="mt-2">
                      <Link onClick={razorpay_redirect}>
                        ( https://razorpay.com/ )
                      </Link>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Razorpay Name</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="Razorpay_Name"
                        name="Razorpay_Name"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={razorpay_payment?.Razorpay_Name}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Razorpay Test Secret ID</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="Razorpay_Testkeyid"
                        name="Razorpay_Testkeyid"
                        value={razorpay_payment?.Razorpay_Testkeyid}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Razorpay Test Publishable Key</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="Razorpay_TestPublishableKey"
                        name="Razorpay_TestPublishableKey"
                        value={razorpay_payment?.Razorpay_TestPublishableKey}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Razorpay Test Secret Key</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="Razorpay_TestkeySecret"
                        name="Razorpay_TestkeySecret"
                        value={razorpay_payment?.Razorpay_TestkeySecret}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Live Key Details</h5>

                  <hr></hr>

                  <div className=" text-start">
                    <div className="row">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <label className="m-0">Enable Razorpay Mode</label>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6 text-end">
                        <div className="col-lg-12 row">
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            <p> OFF </p>
                          </div>
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            {" "}
                            <label className="switch">
                              <input
                                name="status"
                                id="status"
                                onChange={handleInputenable}
                                className="rs-input"
                                defaultChecked={
                                  razorpay_payment?.status == 1 ? true : false
                                }
                                checked={
                                  razorpay_payment?.status == 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="status"
                                id="status"
                                onChange={handleInputenable}
                                value={
                                  razorpay_payment?.status == 1 ? "1" : "0"
                                }
                              ></span>
                            </label>
                          </div>
                          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                            <p> ON </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">Razorpay Label</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="Razorpay_label"
                        name="Razorpay_label"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={razorpay_payment?.Razorpay_label}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Razorpay Live Secret ID</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="Razorpay_Livekeyid"
                        name="Razorpay_Livekeyid"
                        value={razorpay_payment?.Razorpay_Livekeyid}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Razorpay Live Publishable Key</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="Razorpay_LivePublishableKey"
                        name="Razorpay_LivePublishableKey"
                        value={razorpay_payment?.Razorpay_LivePublishableKey}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Razorpay Live Secret Key</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="Razorpay_LivekeySecret"
                        name="Razorpay_LivekeySecret"
                        value={razorpay_payment?.Razorpay_LivekeySecret}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    </>
  );
}

export default Razorpay;
