import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import "./PayperviewPayments.css";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";

function PayperviewPayments(props) {
  const [getPayperviewPayments, setPayperviewPayments] = useState([]);
  const [filteredPayperviewPayments, setFilteredPayperviewPayments] = useState(
    []
  );
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/list`,
        { headers: headers }
      );
      const liveData = response?.data?.LiveStream;
      const filterHelp = liveData?.map((item) => {
        const UploadStatus = "test";
        const statusText = "VIDEO_TITLE";
        const PAID_AMOUNT = "PAID AMOUNT";
        const PAYMENT_ID = "PAYMENT_ID";
        const PAYMENT_MODE = "PAYMENT_MODE";
        const ADMIN_AMOUNT = "ADMIN_AMOUNT";
        const MODERATOR_AMOUNT = "MODERATOR_AMOUNT";
        const STATUS = "STATUS";

        return {
          ...item,
          USERNAME: UploadStatus,
          VIDEO_TITLE: statusText,
          PAID_AMOUNT: PAID_AMOUNT,
          PAYMENT_ID: PAYMENT_ID,
          PAYMENT_MODE: PAYMENT_MODE,
          ADMIN_AMOUNT: ADMIN_AMOUNT,
          MODERATOR_AMOUNT: MODERATOR_AMOUNT,
          STATUS: STATUS,
        };
      });
      setPayperviewPayments(filterHelp);
      setFilteredPayperviewPayments(filterHelp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(async () => {
    fetchData();
  }, []);

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredPayperviewPayments?.length },
    ],
  });

  const subcriptionFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="rightVideoTitle">
          <p>{data}</p>
        </div>
      </div>
    );
  };

  const statusFormatter = (data, row) => {
    return <div>{data}</div>;
  };
  const USERNAMEFormatter = (data, row) => {
    return <div>{data}</div>;
  };
  const PAID_AMOUNTFormatter = (data, row) => {
    return <div>{data}</div>;
  };
  const PAYMENT_IDFormatter = (data, row) => {
    return <div>{data}</div>;
  };
  const PAYMENT_MODEFormatter = (data, row) => {
    return <div>{data}</div>;
  };
  const ADMIN_AMOUNTFormatter = (data, row) => {
    return <div>{data}</div>;
  };
  const MODERATOR_AMOUNTFormatter = (data, row) => {
    return <div>{data}</div>;
  };
  const STATUSFormatter = (data, row) => {
    return <div>{data}</div>;
  };

  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <Link
          to={`/payment/payPerView-view/${data}`}
          className="edit ms-1"
        >
          <AiOutlineEye />
        </Link>
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      formatter: subcriptionFormatter,
    },
    {
      dataField: "USERNAME",
      text: "USERNAME",
      sort: true,
      formatter: USERNAMEFormatter,
    },
    {
      dataField: "VIDEO_TITLE",
      text: "VIDEO_TITLE",
      formatter: statusFormatter,
    },

    {
      dataField: "PAYMENT_ID",
      text: "PAYMENT ID",
      formatter: PAYMENT_IDFormatter,
    },
    {
      dataField: "PAYMENT_MODE",
      text: "PAYMENT MODE",
      formatter: PAYMENT_MODEFormatter,
    },
    {
      dataField: "PAID_AMOUNT",
      text: "PAID AMOUNT",
      formatter: PAID_AMOUNTFormatter,
    },
    {
      dataField: "ADMIN_AMOUNT",
      text: "ADMIN AMOUNT",
      formatter: ADMIN_AMOUNTFormatter,
    },
    {
      dataField: "MODERATOR_AMOUNT",
      text: "MODERATOR AMOUNT",
      formatter: MODERATOR_AMOUNTFormatter,
    },
    {
      dataField: "STATUS",
      text: "STATUS",
      formatter: STATUSFormatter,
    },
    {
      dataField: "id",
      text: "ACTIONS",
      formatter: actionFormatter,
    },
  ];

  const exportHeaderssubcription = [
    { label: "ID", key: "id" },
    { label: "ID", key: "id" },
    { label: "USERNAME", key: "USERNAME" },
    { label: "VIDEO_TITLE", key: "VIDEO_TITLE" },
    { label: "PAID AMOUNT", key: "PAID_AMOUNT" },
    { label: "PAYMENT ID", key: "PAYMENT_ID" },
    { label: "PAYMENT MODE", key: "PAYMENT_MODE" },
    { label: "PAID AMOUNT", key: "ADMIN_AMOUNT" },
    { label: "MODERATOR AMOUNT", key: "MODERATOR_AMOUNT" },
    { label: "STATUS", key: "STATUS" },
    { label: "ACTIONS", key: "ACTIONS" },
  ];

  const conditionalGeneratePDFsubcription = (get) => {
    const doc = new jsPDF();
    doc.text("ALL LIVE LIST ", 10, 10);
    const columns = [
      "Index",
      "ID",
      "USERNAME",
      "VIDEO_TITLE",
      "PAID AMOUNT",
      "PAYMENT ID",
      "PAYMENT MODE",
      "PAID AMOUNT",
      "STATUS",
      "MODERATOR AMOUNT",
      "ACTIONS",
    ];
    const allDataPDF = getPayperviewPayments?.map((item, index) => [
      index + 1,
      item?.id,
      item?.USERNAME,
      item?.VIDEO_TITLE,
      item?.PAID_AMOUNT,
      item?.PAYMENT_ID,
      item?.PAYMENT_MODE,
      item?.ADMIN_AMOUNT,
      item?.MODERATOR_AMOUNT,
      item?.STATUS,
      item?.ACTIONS,
    ]);
    const filterPDF = filteredPayperviewPayments?.map((item, index) => [
      index + 1,
      item?.id,
      item?.USERNAME,
      item?.VIDEO_TITLE,
      item?.PAID_AMOUNT,
      item?.PAYMENT_ID,
      item?.PAYMENT_MODE,
      item?.ADMIN_AMOUNT,
      item?.MODERATOR_AMOUNT,
      item?.STATUS,
      item?.ACTIONS,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("ALL-LIVE-LIST.pdf");
  };

  const generateXLSXLIVE = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(
        get === 1 ? getPayperviewPayments : filteredPayperviewPayments
      );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "ALL-LIVE-LIST.xlsx");
  };

  const handleFilterChangesubcription = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = getPayperviewPayments.filter((item) =>
      [
        "id",
        "USERNAME",
        "VIDEO_TITLE",
        "PAID AMOUNT",
        "PAYMENT ID",
        "PAYMENT MODE",
        "PAID AMOUNT",
        "STATUS",
        "MODERATOR AMOUNT",
        "ACTIONS",
      ].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredPayperviewPayments(filteredResults);
  };

  const handleClear = () => {
    fetchData();
    setFilter("");
  };

  return (
    <>
      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-8">
              <h4>
                All PayPerView Payment{" "}
                <span className="fs-6">
                  (Display all {getPayperviewPayments?.length} Payment)
                </span>
              </h4>
            </div>
            <div className="col-md-4 col-12 mt-3 mt-md-0" align="right"></div>
          </div>
          <div className="iq-card mt-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
              <div className="filterContainer">
                <input
                  type="text"
                  placeholder="Filter all.."
                  className="filter form-control "
                  value={filter}
                  onChange={handleFilterChangesubcription}
                />
                {filter?.length > 0 && (
                  <button
                    type="button"
                    className="closeBtn"
                    onClick={handleClear}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                )}
              </div>
              <div className="btn-group">
                <div className="dropdown d-block " key={"2"}>
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export </span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeaderssubcription}
                        data={filteredPayperviewPayments}
                        className="text-dark d-block"
                        filename="LIVE-FILTERED-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFsubcription(0);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXLIVE(0);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>

                <div className="dropdown d-block ">
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export All</span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeaderssubcription}
                        data={getPayperviewPayments}
                        className="text-dark d-block"
                        filename="ALL-LIVES-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFsubcription(1);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXLIVE(1);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bootstrapTable">
              <BootstrapTable
                keyField="id"
                columns={columns}
                data={filteredPayperviewPayments}
                pagination={pagination}
                className="no-border-table"
                noDataIndication={
                  <div className="noRecord">
                    {loading ? (
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>
                        Loading...
                      </label>
                    ) : (
                      filteredPayperviewPayments?.length <= 0 && (
                        <p>No Subcription Payment Available. !!!</p>
                      )
                    )}
                  </div>
                }
                hover
                headerClasses="videoWidth"
                selectAll={selectAll}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PayperviewPayments;
