import React, { useState, useEffect, } from "react";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function RevenueSettings(props) {
  const [editUser, setEditUser] = useState({
    user_id: "",
    admin_commission: "",
    user_commission: "",
    vod_admin_commission: "",
    vod_user_commission: "",
  });
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Revenue-Setting/index`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      var res = resData?.Revenue_Setting;
      setEditUser(res);
      // console.log(resData)
      // console.log(res)
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const editInputvalue = {
      user_id: editUser.user_id,
      admin_commission: editUser.admin_commission,
      user_commission: editUser.user_commission,
      vod_admin_commission: editUser.vod_admin_commission,
      vod_user_commission: editUser.vod_user_commission,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Revenue-Setting/update`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Revenue Settings</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Settings
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Commission</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">Admin Commission in %</label>
                    <div className="mt-2">
                      <input
                        type="number"
                        id="admin_commission"
                        name="admin_commission"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.admin_commission}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Admin Commission in %</label>

                    <div className="mt-2">
                      <input
                        type="number"
                        id="vod_admin_commission"
                        name="vod_admin_commission"
                        value={editUser?.vod_admin_commission}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Settings
                  </button>
                </div> */}
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5> Commission Percentages</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">User Commission in %</label>
                    <div className="mt-2">
                      <input
                        type="number"
                        // id="user_commission"
                        name="user_commission"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.user_commission}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">User Commission in %</label>

                    <div className="mt-2">
                      <input
                        type="number"
                        // id="vod_user_commission"
                        name="vod_user_commission"
                        value={editUser?.vod_user_commission}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    </>
  );
}

export default RevenueSettings;
