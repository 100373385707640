// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userWidth>th:nth-child(3) {
  width: 260px;
}

.total_user_subcriber {
  font-size: 11px;
}

.userDesignBox {
  width: 160px !important;
}

.userProfileImageSize {
  width: 50px;
  height: 50px;
}

.rightUserTitle {
  width: 120px;
}

.userEmailBox {
  width: 200px !important;
}

.userNameProfile {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
}

.userDateText {
  font-size: 11px;
  line-height: 20px;
  font-weight: 500;
}

.countHead {
  font-size: 18px;
  font-weight: 400;
}

.countDes {
  font-size: 12px;
  font-weight: 300;
}

.rotate-load {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

}`, "",{"version":3,"sources":["webpack://./src/Pages/Account/User/AllUser.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;;AAEF","sourcesContent":[".userWidth>th:nth-child(3) {\n  width: 260px;\n}\n\n.total_user_subcriber {\n  font-size: 11px;\n}\n\n.userDesignBox {\n  width: 160px !important;\n}\n\n.userProfileImageSize {\n  width: 50px;\n  height: 50px;\n}\n\n.rightUserTitle {\n  width: 120px;\n}\n\n.userEmailBox {\n  width: 200px !important;\n}\n\n.userNameProfile {\n  font-size: 15px;\n  line-height: 20px;\n  font-weight: 400;\n}\n\n.userDateText {\n  font-size: 11px;\n  line-height: 20px;\n  font-weight: 500;\n}\n\n.countHead {\n  font-size: 18px;\n  font-weight: 400;\n}\n\n.countDes {\n  font-size: 12px;\n  font-weight: 300;\n}\n\n.rotate-load {\n  animation: rotate 2s linear infinite;\n}\n\n@keyframes rotate {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
