// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropzoneStyle{
    border: 2px dashed#cccccc;
    border-radius: 10px;
    height: 100px;
    align-items: center;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/Pages/Video/Series&Episode/EditSeriesList.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;EACjB","sourcesContent":[".dropzoneStyle{\n    border: 2px dashed#cccccc;\n    border-radius: 10px;\n    height: 100px;\n    align-items: center;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
