import React, { useState, useEffect, useRef } from "react";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function LifeTimeSubcription(props) {

  const [editUser, setEditUser] = useState({
    status: "",
    name: "",
    price: "",
    devices: "",
    id: "",
  });
  const [devices, setDevices] = useState([]);
  const [devices2, setDevices2] = useState([]);
  const [data1, setData1] = useState("");
  const [deviceIds, setDeviceIds] = useState("");
  const [symbol, setSymbol] = useState("");

  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Life-time-subscription`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      const res = resData?.AdminLifeTimeSubscription;
      const result = resData?.devices;
      setEditUser(res);
      setDevices(result);
      const deviceIds = res?.devices?.split(",");
      setDeviceIds(deviceIds);
    };

    getUser();

    const symbol = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/currency_setting/index`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      const res = resData?.CurrencySetting[0]?.symbol;
      setSymbol(res);
    };

    symbol();
  }, []);

  const handleInputs = (id) => {
    const deviceIds = editUser?.devices?.split(",");
    const index = deviceIds?.findIndex((val) => val.includes(id));
    if (index !== -1) {
      deviceIds?.splice(index, 1);
    } else {
      deviceIds?.push(id);
    }
    const devices = deviceIds?.length > 0 ? deviceIds?.join(",") : "";
    setEditUser({ ...editUser, devices });
  };

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.name]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const [validationMessagename, setValidationMessagename] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);

  const [validationMessageprice, setValidationMessageprice] = useState("");
  const [isValidationHiddenprice, setIsValidationHiddenprice] = useState(true);

  const nameInputRef = useRef(null);
  const priceInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagename("Name cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }
    if (editUser?.price === "") {
      setValidationMessageprice("Price cannot be empty.");
      setIsValidationHiddenprice(false);
      focusInputRef = priceInputRef;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      device_id: editUser?.id,
      name: editUser?.name,
      user_id: editUser?.user_id,
      price: editUser?.price,
      status: editUser?.status,
      devices: editUser?.devices,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Life-time-subscription/update`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      setValidationMessagename("");
      setValidationMessageprice("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson?.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddenname(false);
        setIsValidationHiddenprice(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  const myArray = [];
  const arrayvalue = myArray?.filter((item) => item !== null).length === 0;
  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Subscription Plan Update</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Plan
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  {/* <h5>{editname?.plans_name}</h5> */}
                  {/* <hr></hr> */}
                  <div className=" text-start">
                    <label className="m-0">
                      Life Time Subscription Name
                      <span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="username"
                        name="name"
                        placeholder="Enter Name"
                        onChange={handleInput}
                        value={editUser?.name}
                        ref={nameInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenname && (
                        <p>{validationMessagename}</p>
                      )}
                    </span>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">
                      Life Time Subscription Price ( {symbol} )
                      <span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="username"
                        name="price"
                        placeholder="Enter Name"
                        onChange={handleInput}
                        value={editUser?.price}
                        ref={priceInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenprice && (
                        <p>{validationMessageprice}</p>
                      )}
                    </span>
                  </div>
                </div>

                {/* <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Plan
                  </button>
                </div> */}
              </div>
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Status Settings</h5>
                  <hr></hr>

                  <div className="col-lg-12 row">
                    <div className="col-lg-10">
                      <label>Enable this Plan Status </label>
                    </div>
                    <div className="col-lg-2">
                      <div>
                        <label className="switch">
                          <input
                            name="status"
                            onChange={handleInput}
                            defaultChecked={
                              editUser?.status === 1 ? true : false
                            }
                            checked={editUser?.status === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="status"
                            onChange={handleInput}
                            value={editUser?.status === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 ">
                    <div className="mt-3 row">
                      <label>Devices </label>

                      {devices?.map((item, index) => {
                        const value = item?.devices_name;
                        const deviceIds = editUser?.devices?.split(",");
                        const devices = deviceIds?.filter((val) =>
                          val.includes(item?.id)
                        );
                        const array =
                          myArray.filter((item) => item !== null).length === 0;

                        return (
                          <div key={item?.id}>
                            <div className="col-lg-12 row mt-2">
                              <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                                <label>{value} :</label>{" "}
                              </div>
                              <div className="col-2 col-sm-2 col-md-2 col-lg-2">
                                {" "}
                                <label className="switch">
                                  <input
                                    name="devices"
                                    onChange={() => handleInputs(item?.id)}
                                    checked={devices?.length > 0}
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="devices"
                                    onChange={() => handleInputs(item?.id)}
                                    value={devices?.length > 0}
                                  ></span>
                                </label>
                              </div>
                            </div>

                            {/* <label>
                              {devices.length > 0 ? "Selected" : "Not selected"}{" "}
                              :
                            </label> */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {/* <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Plan
                  </button>
                </div> */}
              </div>
            </div>
          </section>
        </div>
      </div>

    </>
  );
}

export default LifeTimeSubcription;
