// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// // import Calendar from 'react-calendar';
// import "./CalenderEvent.css";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import interactionPlugin from "@fullcalendar/interaction";

// function CalenderEvent() {
//   // const [value, onChange] = useState(new Date());

//   const handleDateClick = (arg) => {
//     alert(arg.dateStr);
//   };

//   return (
//     <div className=" m-0 p-0">
//       <div className="">
//         <section className="section container-fluid pt-3">
//           <div id="content-page" className="content-page">
//             <div className="col-lg-12">
//               <div className="iq-card">
//                 <div className="iq-card-header d-flex justify-content-between">
//                   <div className="iq-header-title">
//                     <h4 className="card-title">Ads Surge Price</h4>
//                     <p className="text-muted">
//                       Notes: Adding Surge Pricing Will Increase The Price Of The
//                       Base By X Times.
//                     </p>
//                   </div>
//                 </div>

//                 {/* <Calendar onChange={onChange} value={value} /> */}

//                 <FullCalendar
//                   plugins={[dayGridPlugin, interactionPlugin]}
//                   initialView="dayGridMonth"
//                   // weekends={false}
//                   headerToolbar={{
//                     left: "prev, next, today",
//                     center: "title",
//                     right: "dayGridMonth, dayGridWeek, dayGridDay",
//                   }}
//                   dateClick={(e) => handleDateClick(e)}
//                   events={[
//                     { title: "event 1", date: "2021-05-07" },
//                     { title: "event 2", date: "2021-05-17" },
//                   ]}
//                   // eventContent={renderEventContent}
//                 />
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     </div>
//   );
// }

// export default CalenderEvent;

import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";

import "./CalenderEvent.css";

function CalenderEvent(props) {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [surgePrice, setSurgePrice] = useState("");

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDate(null);
    setSurgePrice("");
  };

  const handleDateClick = (info) => {
    setSelectedDate(info.date);
    setShowModal(true);
  };

  const handleSurgePriceChange = (e) => {
    setSurgePrice(e.target.value);
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleSaveEvent = async () => {
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/Advertisement-surge-price-store`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            date: selectedDate.toISOString(),
            surge_price: surgePrice,
          }),
        }
      );

      if (response.ok) {
        const resjson = await response.json();

        if (resjson.status === true) {
          setEvents([...events, resjson]);
          handleCloseModal();

          props.setApiresponsealert(resjson);
          props.setShowalert(true);

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else if (resjson.status === false) {
          props.setApiresponsealert(resjson);
          props.setShowalert(true);
        }
      } else {
        console.error("Failed to save event");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  // const handleSaveEvent = async () => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_Baseurl}/admin/Advertisement-surge-price-store`,
  //       {
  //         method: "POST",
  //         headers: headers,
  //         body: JSON.stringify({
  //           date: selectedDate.toISOString(),
  //           surge_price: surgePrice,
  //         }),
  //       }
  //     );

  //     if (response.ok) {
  //       const newEvent = await response.json();
  //       setEvents([...events, newEvent]);
  //       handleCloseModal();
  //     } else {
  //       // Handle error
  //       console.error("Failed to save event");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    // Fetch events from your API here
    async function fetchEvents() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/Advertisement-surge-price-index`,
          { headers: headers }
        );
        if (response.ok) {
          const data = await response.json();
          setEvents(data.Advertisement_SurgePrice);
          // console.log(data);
        } else {
          // Handle error
          console.error("Failed to fetch events");
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchEvents();
  }, []);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  useEffect(() => {
    fetchEventsFromApi().then((data) => {
      setEvents(data);
    });
  }, []);

  const fetchEventsFromApi = async () => {
    // Replace this with your API endpoint to fetch events
    const response = await fetch("/api/events");
    if (!response.ok) {
      throw new Error("Failed to fetch events");
    }
    const data = await response.json();
    return data;
  };

  const handleDeleteEvent = (eventId) => {
    // Make an API request to delete the event by its eventId
    fetch(`/api/delete-event/${eventId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          // Event deleted successfully, update the calendar
          setEvents((prevEvents) =>
            prevEvents.filter((event) => event.id !== eventId)
          );
        } else {
          // Handle error
          console.error("Failed to delete event");
        }
        setShowDeleteModal(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowDeleteModal(false);
      });
  };

  const handleEditEvent = (eventId) => {
    // Fetch event details using a get API call based on the eventId
    fetch(`/api/get-event/${eventId}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch event details");
        }
      })
      .then((data) => {
        setSelectedEvent(data);
        setShowEditModal(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleUpdateEvent = (updatedEvent) => {
    // Make an API request to update the event details
    fetch(`/api/update-event/${updatedEvent.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedEvent),
    })
      .then((response) => {
        if (response.ok) {
          // Event updated successfully, update the calendar
          setEvents((prevEvents) =>
            prevEvents.map((event) =>
              event.id === updatedEvent.id ? updatedEvent : event
            )
          );
        } else {
          // Handle error
          console.error("Failed to update event");
        }
        setShowEditModal(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowEditModal(false);
      });
  };
  

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);

  const openEditModal = (eventId) => {
    setSelectedEventId(eventId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEventId(null); // Clear the selected event ID
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-12 col-sm-12 col-lg-12">
                <h3>Calender Events</h3>
              </div>
            </div>
            <div>
              {showOverlay && (
                <div className="overlay-container">
                  <span className="overlay-icon">
                    {" "}
                    <Response_Loading_processing></Response_Loading_processing>
                    Processing ...
                  </span>
                </div>
              )}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-12 col-md-12 col-lg-12 ">
                <div className="card p-3">
                  <div className="App">
                  <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events?.map((event) => ({
          title: `Surge Price: ${event?.surge_price}`,
          date: event?.date,
          extendedProps: { eventId: event?.id },
        }))}
        dateClick={handleDateClick}
        eventContent={(arg) => {
          return (
            <div className="custom-event-container">
              <div className="custom-event-title">{arg.event.title}</div>
              <div className="custom-event-actions">
                <span className="edit" onClick={() => openEditModal(arg.event.extendedProps.eventId)}>
                  <span>
                    <img src={edititem} alt="Edit" width={11} height={11} />
                  </span>
                </span>
                <span
                  onClick={() => handleDeleteEvent(arg.event.extendedProps.eventId)}
                  className="delete"
                >
                  <img src={deleteitem} alt="Delete" width={11} height={11} />
                </span>
              </div>
            </div>
          );
        }}
      />


<Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Event Price Modal"
      >
        <h2>Edit Event Price</h2>
        {/* Add form inputs for editing the price */}
        <button onClick={closeEditModal}>Close</button>
      </Modal>



                    {/* 
<FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events?.map((event) => ({
          title: `Surge Price: ${event?.surge_price}`,
          date: event?.date,
          id: event?.id, // Include the event ID
        }))}
        eventContent={(arg) => {
          const deleteButton = document.createElement('button');
          deleteButton.className = 'delete-button';
          deleteButton.textContent = 'Delete';
          deleteButton.addEventListener('click', () => {
            handleDeleteEvent(arg.event.id); // Call the delete event function with the event ID
          });

          const eventTitle = document.createElement('div');
          eventTitle.className = 'custom-event-title';
          eventTitle.textContent = arg.event.title;

          const eventContent = document.createElement('div');
          eventContent.appendChild(eventTitle);
          eventContent.appendChild(deleteButton);

          return { domNodes: [eventContent] };
        }}
      /> */}

                    {/* <FullCalendar
                      plugins={[dayGridPlugin, interactionPlugin]}
                      initialView="dayGridMonth"
                      events={events.map((event) => ({
                        title: `Surge Price: ${event.surge_price}`,
                        date: event.date,
                        id: event.id,
                      }))}
                      eventContent={(arg) => {
                        return (
                          <>
                            <div className="custom-event-title">
                              {arg.event.title}
                            </div>

                            <Link className="edit">
                              <span>
                                <img
                                  src={edititem}
                                  alt="flogo"
                                  width={15}
                                  height={15}
                                />
                              </span>
                            </Link>
                            <Link to="" className="delete">
                              <span
                                onClick={() => handleDeleteEvent(arg.event.id)}
                                // onClick={() => openModaladscate(item?.id)}
                              >
                                <img
                                  src={deleteitem}
                                  alt="flogo"
                                  width={15}
                                  height={15}
                                />
                              </span>
                            </Link>

                          </>
                        );
                      }}
                    /> */}

                    <Modal show={showModal} onHide={handleCloseModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add Event</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="">
                          <label>Surge Price:</label>

                          <input
                            type="text"
                            className="rs-input form-control-lg"
                            id="surgePrice"
                            name="surgePrice"
                            value={surgePrice}
                            onChange={handleSurgePriceChange}
                          />
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        {/* <Button variant="secondary" onClick={handleCloseModal}>
                          Close
                        </Button> */}
                        <Button variant="primary" onClick={handleSaveEvent}>
                          Save Event
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>

                {/* <div className="text-start mb-3">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Menu
                </button>
              </div> */}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default CalenderEvent;
