// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-footer {
  padding: 15px;
}
a {
  text-decoration: none;
}
.copyrights .webnexs {
  color: #4895d1;
}
.copyrights .lable {
  color: #000000;
}
.copyrights1Link {
  color: #000000;
}

@media (max-width: 991px) {
  .admin-footer,
  body.sidebar-main .admin-footer {
    text-align: center;
    margin-left: 0;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/layout/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,qBAAqB;AACvB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;;AAEA;EACE;;IAEE,kBAAkB;IAClB,cAAc;IACd,WAAW;EACb;AACF","sourcesContent":[".admin-footer {\n  padding: 15px;\n}\na {\n  text-decoration: none;\n}\n.copyrights .webnexs {\n  color: #4895d1;\n}\n.copyrights .lable {\n  color: #000000;\n}\n.copyrights1Link {\n  color: #000000;\n}\n\n@media (max-width: 991px) {\n  .admin-footer,\n  body.sidebar-main .admin-footer {\n    text-align: center;\n    margin-left: 0;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
