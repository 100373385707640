// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edititem-color-payment {
    background-color: #cfcfcf !important;
    border-radius: 0;
}
.edititem-color-payment a{
    color: #000000 !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Settings/PaymentSetting_All/Iyzico.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,gBAAgB;AACpB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".edititem-color-payment {\n    background-color: #cfcfcf !important;\n    border-radius: 0;\n}\n.edititem-color-payment a{\n    color: #000000 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
