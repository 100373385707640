import React from 'react'

const AddManageVideoCategory = () => {
  return (
    <div>
         <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="admin-section-title">
                <div className="iq-card">
                  <div className="row">
                    <div className="col-md-6">
                      <h4>
                        <i className=""></i> Add Video Categories
                      </h4>
                    </div>
                  </div>
                  <hr />

                  <div className="">
                    <div>
                      <div className="form-group ">
                        <label>Name:</label>

                        <input
                          type="text"
                          id="name"
                          name="name"
                        //   onChange={(e) => setName(e.target.value)}
                          className="form-control"
                          placeholder="Enter Name"
                        />
                      </div>

                      <div className="form-group ">
                        <label>Slug:</label>

                        <input
                          type="text"
                          id="slug"
                          name="slug"
                        //   onChange={(e) => setSlug(e.target.value)}
                          className="form-control"
                          placeholder="Enter Slug"
                        />
                      </div>
                     
                      <div className="form-group ">
                        <label>Display In Home page:</label>

                        <label className="switch">
                          <input
                            name="in_home"
                            // onChange={handleInput}
                            // defaultChecked={
                            //   in_home.in_home === 1 ? true : false
                            // }
                            // checked={in_home.in_home === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="in_home"
                            // onChange={handleInput}
                            // value={in_home.in_home === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>

                      <div className="form-group ">
                        <label>Display In Menu :</label>
                        <label className="switch">
                          <input
                            name="in_menu"
                            // onChange={handleInput}
                            // defaultChecked={
                            //   in_menu.in_menu === 1 ? true : false
                            // }
                            // checked={in_menu.in_menu === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="in_menu"
                            // onChange={handleInput}
                            // value={in_menu.in_menu === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                      <div className="form-group ">
                        <label>Image:</label>
                        <input
                          type="file"
                          multiple="true"
                          className="form-control"
                          name="image"
                        //   onChange={(e) => setImage(e.target.files[0])}
                          id="image"
                        />
                      </div>     

                      <div className="col-md-12 mb-3">
                <label>Category:</label>
                    <select
                      className="form-control"
                      name="parent_id"
                      id="parent_id"
                    //   onChange={(e) => setParent_id(e.target.value)}
                    >
                      <option value="0">Select</option>
                      {/* {category.map((item) => (
                        <option value={item.parent_id}>{item.name}</option>
                      ))} */}
                    </select>
                 
                </div>                                                  

                      <div className=" text-end form-group">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          id="submit-new-cat"
                        //   onClick={AddManagevideocategories}
                        >
                          Add Category
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default AddManageVideoCategory