import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import "./AddVideoschedule.css";
import JoditEditor from "jodit-react";
import AddOutlineIcon from '@rsuite/icons/AddOutline';

function AddVideoschedule(props) {
  const [description, setBody] = useState("");
  const [in_home, setIn_home] = useState("");
  const [editUser, setEditUser] = useState({
    name: "",
    type: "",
  });

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);


  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/video-schedule/create`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var resdtat = resData?.VideoSchedules;
      setData(resdtat);
      setLoading(false);
    };
    getUser();
  }, []);

  const [autoFillValuename, setAutoFillValuename] = useState("");

  const handleInputKeyDownname = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(editUser?.name);
    }
  };
  const handleBlurname = () => {
    generateSlug(editUser?.name);
  };

  const handleAutoFillChangename = (event) => {
    setAutoFillValuename(event.target.value);
  };

  const generateSlug = (name) => {
    name = name.trim();
    const slug = name.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuename(slug);
  };

  const contentFieldChanaged = (data) => {
    setBody(data);
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagename("");
    setIsValidationHiddenname(true);

    setEditUser({ ...editUser, name: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setIn_home({ ...in_home, [e.target.name]: 1 });
    } else {
      setIn_home({ ...in_home, [e.target.name]: 0 });
    }
  };

  const [validationMessagename, setValidationMessagename] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);

  const nameInputRef = useRef(null);

  const [validationMessagetype, setValidationMessagetype] = useState("");
  const [isValidationHiddentype, setIsValidationHiddentype] = useState(true);

  const typeInputRef = useRef(null);


  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function Add_Video_Schedule() {
    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagename("Name cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }
    if (editUser?.type === "") {
      setValidationMessagetype("Type cannot be empty.");
      setIsValidationHiddentype(false);
      focusInputRef = typeInputRef;
    }



    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const editInputvalue = in_home?.in_home;
    var data = JSON.stringify(editInputvalue);
    var ain_homedata = data;

    const formData = new FormData();

    formData.append("name", editUser?.name);
    formData.append("type", editUser?.type);
    formData.append("slug", autoFillValuename);
    formData.append("description", description);
    formData.append("in_home", ain_homedata);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/video-schedule/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;

        resultapi = response?.data;

        var VideoSchedule_id = response?.data?.VideoSchedules?.id;

        const imageFormData = new FormData();
        imageFormData.append("VideoSchedule_id", VideoSchedule_id);

        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("player_image", selectedFile2);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-video-schedule`,
          imageFormData,
          { headers: headers }
        );

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }
      } else if (response?.data?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagename("");
      setValidationMessagetype("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        navigate("/video-schedule");
      }, 3000);
    } else if (resultapifalse) {
      props.setApiresponsealert(resultapifalse);
      props.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddenname(false);
      setIsValidationHiddentype(false);
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="video_schedule_media_tab">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Create Video Channel</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button
                    onClick={Add_Video_Schedule}
                    className="btn btn-primary"
                  >
                    Add Video Channel
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Video Channel</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.name}
                        onBlur={handleBlurname}
                        onKeyDown={handleInputKeyDownname}
                        placeholder="Name"
                        ref={nameInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenname && (
                        <p>{validationMessagename}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Slug</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg"
                        value={autoFillValuename}
                        onChange={handleAutoFillChangename}
                        placeholder="slug"
                      />
                    </div>
                  </div>



                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">Type<span className="mandatory">*</span></label>

                      <div className="mt-2">
                        <select
                          className="form-select"
                          name="parent_id"
                          id="parent_id"
                          value={editUser?.parent_id}
                          onChange={handleInput}
                          ref={typeInputRef}
                        >
                          {data?.map((item) => (
                            <option value={item?.id}>{item?.name}</option>
                          ))}
                        </select>

                        <span className="errorred">
                          {!isValidationHiddentype && (
                            <p>{validationMessagetype}</p>
                          )}
                        </span>

                      </div>

                      <div className="mt-2 text-start">
                        <label className="m-0">Description</label>

                        <div className="mt-2">
                          <JoditEditor
                            className="rs-input"
                            value={description}
                            onChange={(newContent) =>
                              contentFieldChanaged(newContent)
                            }
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                <div className="card p-3">
                  <h5>Status Settings</h5>
                  <hr></hr>

                  <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-body d-flex justify-content-between">
                      <label className="">Display on Home page </label>
                      <label className="switch">
                        <input
                          name="in_home"
                          onChange={handleInput}
                          defaultChecked={in_home?.in_home === 1 ? true : false}
                          checked={in_home?.in_home === 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="in_home"
                          onChange={handleInput}
                          value={in_home?.in_home === 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>


                <div className="text-start mb-3">
                  <button
                    onClick={Add_Video_Schedule}
                    className="btn btn-primary"
                  >
                    Add Video Channel
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Thumbnails</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">Home Thumbnail</label>
                    <p className="t-1">
                      Select the Home image( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(1)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Player Thumbnail</label>
                    <p className="t-1">
                      Select the Player image( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(2)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Upload</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        <div className="row mt-2 text-start">
                          <div className="col-12">
                            <label className="m-0">Type</label>

                            <div className="mt-2">
                              <select
                                className="form-select"
                                name="parent_id"
                                id="parent_id"
                                value={editUser?.parent_id}
                                onChange={handleInput}
                              >
                                {data?.map((item) => (
                                  <option value={item?.id}>{item?.name}</option>
                                ))}
                              </select>

                            </div>
                          </div>
                        </div>


                        <div className="col-lg-12 mt-2 ">
                          <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link active" id="pills-computer-tab" data-bs-toggle="pill" data-bs-target="#pills-computer" type="button" role="tab" aria-controls="pills-computer" aria-selected="true">Upload From Computer</button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link" id="pills-library-tab" data-bs-toggle="pill" data-bs-target="#pills-library" type="button" role="tab" aria-controls="pills-library" aria-selected="false">Choose From Library</button>
                            </li>
                          </ul>
                          <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-computer" role="tabpanel" aria-labelledby="pills-computer-tab">
                              <div className="">
                                <div className="col-lg-12 row">
                                  <div className="col-12 col-sm-12 col-lg-12">
                                    <div
                                      className="imagedrop"
                                    >
                                      <input
                                        type="file"
                                        accept="video/*"
                                        style={{ display: "none" }}
                                      />
                                      <label>
                                        <CameraRetroIcon />
                                      </label>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="pills-library" role="tabpanel" aria-labelledby="pills-library-tab">testing</div>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Media</h5>
                  <hr></hr>

                  <div className="mt-2 text-start">
                    <label className="m-0">Filler Media</label>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                          >
                            <input
                              type="file"
                              style={{ display: "none" }}
                            />
                            <button>
                              <AddOutlineIcon />
                            </button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>





              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AddVideoschedule;
