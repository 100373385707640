import React, { useState, useEffect, useRef } from "react";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import { useNavigate, useParams } from "react-router-dom";

const EditPurchasePlan = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    plan_price: "",
    user_id: "",
    id: "",
    product_id: "",
    enable: "",
  });
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/inapp-purchase/edit/${id}`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      var res = resData?.Inapp_Purchase;
      setEditUser(res);
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [validationMessageplan_price, setValidationMessageplan_price] =
    useState("");
  const [isValidationHiddenplan_price, setIsValidationHiddenplan_price] =
    useState(true);

  const [validationMessageproduct_id, setValidationMessageproduct_id] =
    useState("");
  const [isValidationHiddenproduct_id, setIsValidationHiddenproduct_id] =
    useState(true);

  const plan_priceInputRef = useRef(null);
  const product_idInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.plan_price === "") {
      setValidationMessageplan_price("Plan Price cannot be empty.");
      setIsValidationHiddenplan_price(false);
      focusInputRef = plan_priceInputRef;
    }
    if (editUser?.product_id === "") {
      setValidationMessageproduct_id("Product Id cannot be empty.");
      setIsValidationHiddenproduct_id(false);
      focusInputRef = product_idInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      id: editUser?.id,
      plan_price: editUser?.plan_price,
      user_id: editUser?.user_id,
      enable: editUser?.enable,
      product_id: editUser?.product_id,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/inapp-purchase/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      setValidationMessageplan_price("");
      setValidationMessageproduct_id("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson?.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddenplan_price(false);
        setIsValidationHiddenproduct_id(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit In App Purchase Plans</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Plan
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-12 col-md-12 col-lg-12 ">
                <div className="card p-3">
                  {/* <h5>{editname?.plans_name}</h5> */}
                  {/* <hr></hr> */}
                  <div className=" text-start">
                    <label className="m-0">
                      Plan Price<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        className="rs-input form-control-lg"
                        // id="username"
                        name="plan_price"
                        placeholder="Enter Name"
                        onChange={handleInput}
                        value={editUser?.plan_price}
                        ref={plan_priceInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenplan_price && (
                        <p>{validationMessageplan_price}</p>
                      )}
                    </span>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="m-0">
                      Product ID<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        className="rs-input form-control-lg"
                        // id="username"
                        name="product_id"
                        placeholder="Enter Name"
                        onChange={handleInput}
                        value={editUser?.product_id}
                        ref={product_idInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenproduct_id && (
                        <p>{validationMessageproduct_id}</p>
                      )}
                    </span>
                  </div>
                </div>

                {/* <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Plan
                  </button>
                </div> */}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default EditPurchasePlan;
