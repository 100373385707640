import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import "./AddFooterMenu.css";

function EditFooterMenu(props) {
  const { id } = useParams();
  // const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    user_id: "",
    id: "",
    link: "",
    name: "",
    order: "",
    column_position: "",
    parent_id: "",
  });
  const [editname, setEditname] = useState({
    name: "",
  });
  const [column, setColumn] = useState([]);
  // const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [image, setImage] = useState("");

  // console.log(imageurl)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/footer_menu/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.footer_menus;
      var result = resData?.columns;
      setEditUser(res);
      setEditname(res);
      setColumn(result);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [validationMessagename, setValidationMessagename] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);

  const nameInputRef = useRef(null);

  const [validationMessagelink, setValidationMessagelink] = useState("");
  const [isValidationHiddenlink, setIsValidationHiddenlink] = useState(true);

  const linkInputRef = useRef(null);

  const [
    validationMessagecolumn_position,
    setValidationMessagecolumn_position,
  ] = useState("");
  const [
    isValidationHiddencolumn_position,
    setIsValidationHiddencolumn_position,
  ] = useState(true);

  const column_positionInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagename("Title cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }
    if (editUser?.link === "") {
      setValidationMessagelink("URL cannot be empty.");
      setIsValidationHiddenlink(false);
      focusInputRef = linkInputRef;
    }
    if (editUser?.column_position === "") {
      setValidationMessagecolumn_position("Column Position cannot be empty.");
      setIsValidationHiddencolumn_position(false);
      focusInputRef = column_positionInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      name: editUser?.name,
      link: editUser?.link,
      order: editUser?.order,
      column_position: editUser?.column_position,
      parent_id: editUser?.parent_id,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/footer_menu/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      } else if (resjson?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      setValidationMessagename("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddenname(false);
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Menu</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Menu
                  </button>
                </div>
              </div>
            </div>
            <div>
              {showOverlay && (<Response_Processing></Response_Processing>)}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-12 col-md-12 col-lg-12 ">
                <div className="card p-3">
                  <h5>{editname?.name}</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Menu Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        id="name"
                        name="name"
                        placeholder="Enter Title"
                        onChange={handleInput}
                        value={editUser?.name}
                        ref={nameInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenname && (
                        <p>{validationMessagename}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Link<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        placeholder="Enter Title"
                        id="link"
                        name="link"
                        onChange={handleInput}
                        value={editUser?.link}
                        ref={linkInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenlink && (
                        <p>{validationMessagelink}</p>
                      )}
                    </span>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Column position<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <select
                        value={editUser?.column_position}
                        name="column_position"
                        id="column_position"
                        className="form-select"
                        onChange={handleInput}
                        ref={column_positionInputRef}
                      >
                        <option value="">Choose an Column</option>
                        {column?.map((item, key) => (
                          <option key={item?.id} value={item?.name}>
                            {" "}
                            {item?.Column}
                          </option>
                        ))}
                      </select>
                    </div>
                    <span className="errorred">
                      {!isValidationHiddencolumn_position && (
                        <p>{validationMessagecolumn_position}</p>
                      )}
                    </span>
                  </div>
                </div>

                {/* <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Menu
                  </button>
                </div> */}
              </div>
            </div>
          </section>
        </div>
      </div>

    </>
  );
}

export default EditFooterMenu;
