import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import "./Dsp_Settings.css";

function Dsp_Settings(props) {
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    DSP_Name: "",
    DSP_Identifier: "",
    Storefront: "",
    DSP_Recording_Identifier: "",
    Product_Distributor_Identifier: "",
    Distributor: "",
    user_id: "",
  });
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/dsp-setting/index`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      var res = resData?.DSP_Setting;
      setEditUser(res);
      //    console.log(resData)
      //    console.log(res)
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleDSP = async (e) => {
    e.preventDefault();

    const editInputvalue = {
      DSP_Name: editUser?.DSP_Name,
      DSP_Identifier: editUser?.DSP_Identifier,
      Storefront: editUser?.Storefront,
      DSP_Recording_Identifier: editUser?.DSP_Recording_Identifier,
      Product_Distributor_Identifier: editUser?.Product_Distributor_Identifier,
      Distributor: editUser?.Distributor,
      user_id: editUser?.user_id,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/dsp-setting/update`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Audio DSP Settings</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleDSP} className="btn btn-primary">
                    DSP Settings
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>Create Distributor</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">DSP Name</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        // id="DSP_Name"
                        name="DSP_Name"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.DSP_Name}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">Storefront</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="Storefront"
                        name="Storefront"
                        value={editUser?.Storefront}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Distributor</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="Distributor"
                        name="Distributor"
                        value={editUser?.Distributor}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="text-start mb-3">
                  <button onClick={handleDSP} className="btn btn-primary">
                    DSP Settings
                  </button>
                </div> */}
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Create Identifier</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">DSP Identifier</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        // id="DSP_Identifier"
                        name="DSP_Identifier"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.DSP_Identifier}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">DSP Recording Identifier</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="DSP_Recording_Identifier"
                        name="DSP_Recording_Identifier"
                        value={editUser?.DSP_Recording_Identifier}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Product Distributor Identifier
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="Product_Distributor_Identifier"
                        name="Product_Distributor_Identifier"
                        value={editUser?.Product_Distributor_Identifier}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Dsp_Settings;
