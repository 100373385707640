import React from "react";
import { Link } from "react-router-dom";
import gif from "./maintance.gif";
import "./Maintances.css";

function Maintances() {
  const site = () => {
    window.open("https://demo-flick.flicknexs.com/", "_blank");
  };

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="page_4044">
          <div className="container">
            <div className="row align-items-center mt-5">
              <div className="col-sm-6 ">
                <div className="text-start  mt-3">
                  <label className="text-start ">Under Maintenance</label>
                  <h1 className="text-start ">We'll Be Back Soon.</h1>
                  <label className="">
                    We're busy upgrading with new technology. We apologize for
                    the inconvenience.
                  </label>
                </div>
              </div>
              <div className="col-sm-6 ">
                <div>
                  <img src={gif} className="w-100" />
                </div>
              </div>
            </div>
            <div className="text-center mt-3">
              — The{" "}
              <span>
                <Link onClick={site}>webnexs.com</Link>
              </span>{" "}
              Team
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Maintances;
