import React, { useState, useEffect } from "react";
// import axios from "axios";
import { Table } from "react-bootstrap";
// import { Link } from "react-router-dom";
import "./SignupMenu.css";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";


function SignupMenu(props) {
  const [editUser, setEditUser] = useState({
    username: "",
    id: "",
    user_id: "",
    email: "",
    mobile: "",
    avatar: "",
    password: "",
    password_confirm: "",
    dob: "",
    country: "",
    state: "",
    city: "",
    support_username: "",
  });

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/signup_menu`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.signup_menu;
      setEditUser(res);
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const editInputvalue = {
      user_id: editUser?.user_id,
      id: editUser?.id,
      username: editUser?.username,
      email: editUser?.email,
      mobile: editUser?.mobile,
      avatar: editUser?.avatar,
      password: editUser?.password,
      password_confirm: editUser?.password_confirm,
      dob: editUser?.dob,
      country: editUser?.country,
      state: editUser?.state,
      city: editUser?.city,
      support_username: editUser?.support_username,
    };

    setProcessing(true);
    setShowOverlay(true);

    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/signup_menu_store`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );
    let resjson = await res.json();

    if (resjson?.status === true) {
      var resultapi = resjson;

      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson?.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Signup Menu</h3>
              </div>

              <div className="col-6 col-sm-6 col-lg-6 text-end">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>

            <div className="iq-card ">

              <div>
                {showOverlay && (<Response_Processing></Response_Processing>)}
              </div>

              <div className="col-lg-12">
                <div className="">
                  <Table>
                    <thead>
                      <tr className="title">
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> 1 </td>
                        <td> Profile Name </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="username"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.username === 1 ? true : false
                                }
                                checked={
                                  editUser?.username === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="username"
                                onChange={handleInput}
                                value={editUser?.username === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td> 2 </td>
                        <td> Profile Email </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="email"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.email === 1 ? true : false
                                }
                                checked={editUser?.email === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="email"
                                onChange={handleInput}
                                value={editUser?.email === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td> 3 </td>
                        <td> Profile Mobile Number </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="mobile"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.mobile === 1 ? true : false
                                }
                                checked={editUser?.mobile === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="mobile"
                                onChange={handleInput}
                                value={editUser?.mobile === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td> 4 </td>
                        <td> Profile Image </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="avatar"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.avatar === 1 ? true : false
                                }
                                checked={editUser?.avatar === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="avatar"
                                onChange={handleInput}
                                value={editUser?.avatar === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td> 5 </td>
                        <td> Profile Password </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="password"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.password === 1 ? true : false
                                }
                                checked={
                                  editUser?.password === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="password"
                                onChange={handleInput}
                                value={editUser?.password === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td> 6 </td>
                        <td> Profile Password Confirm </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="password_confirm"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.password_confirm === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  editUser?.password_confirm === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="password_confirm"
                                onChange={handleInput}
                                value={
                                  editUser?.password_confirm === 1 ? "1" : "0"
                                }
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td> 7 </td>
                        <td> Profile DOB </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="dob"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.dob === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  editUser?.dob === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="dob"
                                onChange={handleInput}
                                value={
                                  editUser?.dob === 1 ? "1" : "0"
                                }
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td> 8 </td>
                        <td> Profile Country </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="country"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.country === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  editUser?.country === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="country"
                                onChange={handleInput}
                                value={
                                  editUser?.country === 1 ? "1" : "0"
                                }
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td> 9 </td>
                        <td> Profile State </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="state"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.state === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  editUser?.state === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="state"
                                onChange={handleInput}
                                value={
                                  editUser?.state === 1 ? "1" : "0"
                                }
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td> 10 </td>
                        <td> Profile City </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="city"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.city === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  editUser?.city === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="city"
                                onChange={handleInput}
                                value={
                                  editUser?.city === 1 ? "1" : "0"
                                }
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td> 11 </td>
                        <td> Profile Support UserName</td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="support_username"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.support_username === 1
                                    ? true
                                    : false
                                }
                                checked={
                                  editUser?.support_username === 1
                                    ? true
                                    : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="support_username"
                                onChange={handleInput}
                                value={
                                  editUser?.support_username === 1 ? "1" : "0"
                                }
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default SignupMenu;