import React, { useState, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import axios from "axios";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
// import TimePicker from "react-bootstrap-time-picker";
import Select from "react-select";
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import "./EditManageCalendarschedule.css";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";

const videoList = [
  {
    id: 1,
    videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
    scheduledTime: 0,
    StartTime: "12:12:12",
    EndTime: "12:12:12",
  },
  {
    id: 2,
    videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
    scheduledTime: 0,
    StartTime: "12:12:12",
    EndTime: "12:12:12",
  },
];

const currentDate = moment();

const DragDrop = () => {
  const [boardData, setBoardData] = useState([
    {
      id: 1,
      videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
      scheduledDate: "2024-01-13T12:57:18.110Z",
      scheduledTime: 0,
      StartTime: "12:12:12",
      EndTime: "12:12:12",
    },
    {
      id: 3,
      videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
      scheduledDate: "2024-01-12T12:57:18.110Z",
      scheduledTime: 0,
      StartTime: "12:12:12",
      EndTime: "12:12:12",
    },
    {
      id: 3,
      videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
      scheduledDate: "2024-01-11T12:57:18.110Z",
      scheduledTime: 0,
      StartTime: "12:12:12",
      EndTime: "12:12:12",
    },
  ]);

  const [selectedDate, setSelectedDate] = useState(currentDate.toDate());
  const [selectedTime, setSelectedTime] = useState(new Date().setHours(12, 0, 0, 0));
  const [currentTimeZone, setCurrentTimeZone] = useState("Asia/Kolkata");
  const [editingIds, setEditingIds] = useState([]);
  const [selectedDateselectedDate, setSelectedDateselectedDate] = useState("");

  useEffect(() => {
    setSelectedDateselectedDate(selectedDate);
  }, []);

  console.log(selectedDate, selectedDateselectedDate, "selectedDateselectedDateselectedDateselectedDate");

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "video",
    drop: (item) => addVideoToBoard(item.id, selectedDate),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const updateSelectedDate = (newDate) => {
    setSelectedDate(newDate);
  };

  const Video = ({ id, videourl, scheduledDate }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "video",
      item: { id, scheduledDate },
      canDrag: () => !editingIds.includes(id),
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));

    return (
      <div className={`video ${isDragging ? "dragging" : ""}`} ref={drag} onClick={() => updateSelectedDate(scheduledDate)}>
        <img className="imgae" width={150} height={150} src={videourl} alt={`Video ${id}`} />
        {/* <p>{`Video ID: ${id}`}</p> */}
      </div>
    );
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  // const handleTimeChange = (time) => {
  //   setSelectedTime(time);
  // };

  const handleNext = () => {
    const nextDate = moment(selectedDate).add(1, "days").toDate();
    setSelectedDate(nextDate);
    clearBoardDataIfNoData(nextDate);
  };

  const handlePrevious = () => {
    const prevDate = moment(selectedDate).subtract(1, "days").toDate();
    setSelectedDate(prevDate);
    clearBoardDataIfNoData(prevDate);
  };

  const clearBoardDataIfNoData = (date) => {
    if (!hasBoardData(date)) {
      setBoardData([]);
    }
  };

  const fetchDataForDate = (date) => {
    return boardData.filter((video) => moment(video.scheduledDate).isSame(date, "day"));
  };

  const hasBoardData = (date) => {
    return fetchDataForDate(date).length > 0;
  };

  useEffect(() => {
    if (!hasBoardData(selectedDate)) {
      setBoardData([]);
    }
  }, [selectedDate]);

  const handleTimeZoneChange = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setCurrentTimeZone(selectedOption.value);
    } else {
      console.error("Invalid selected option:", selectedOption);
    }
  };

  const getTimeZoneOptions = () => {
    const timeZones = moment.tz.names();
    return timeZones.map((zone) => ({ value: zone, label: zone }));
  };

  const handleEditClick = (id) => {
    setEditingIds((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((editingId) => editingId !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  const handleDeleteClick = (id) => {
    setEditingIds((prevIds) => prevIds.filter((editingId) => editingId !== id));
  };

  const addVideoToBoard = (id) => {
    const video = videoList.find((v) => v.id === id);

    // Replace with your actual API endpoint
    axios
      .post("YOUR_API_ENDPOINT", {
        videoId: id,
        scheduledDate: selectedDate,
        scheduledTime: selectedTime,
      })
      .then((response) => {
        console.log("Video added successfully!", response.data);
      })
      .catch((error) => {
        console.error("Error adding video:", error);
      });

    setBoardData((prevBoardData) => [
      ...prevBoardData,
      {
        ...video,
        scheduledDate: selectedDate,
        scheduledTime: selectedTime,
        id: generateUniqueId(),
      },
    ]);
  };

  // Helper function to generate a unique ID
  const generateUniqueId = () => {
    return new Date().getTime(); // Use a more robust method for production
  };


  return (
    <div className="newdraganddrop_schedule p-3">
      <div className="card">
        <hr />
        <div className="col-lg-12 row  px-3">
          <div className="col-4 col-sm-4 col-md-4 col-lg-4">
            <div className="d-flex">
              <select
                className="form-select"
                name="schedule"
                id="schedule"
              >
                <option value="">schedule</option>
              </select>
            </div>
          </div>
          <div className="col-2 col-sm-2 col-md-2 col-lg-2">
          </div>

          <div className="col-2 col-sm-2 col-md-2 col-lg-2">
            <div className="d-flex">
              {/* <label>Scheduled Time:</label>
              <TimePicker value={selectedTime} onChange={handleTimeChange} /> */}
              {/* <label>Time Zone:</label> */}
              <DatePicker className="rs-input form-control-lg DatePicker" selected={selectedDate} onSelect={setSelectedDate} onChange={handleDateChange} dateFormat="yyyy-MM-dd" />
            </div>
          </div>

          <div className="col-3 col-sm-3 col-md-3 col-lg-3">
            <div className="d-flex" >
              <Select
                className="react-select-container form-select"
                classNamePrefix="react-select"
                value={{ value: currentTimeZone, label: currentTimeZone }}
                onChange={handleTimeZoneChange}
                options={getTimeZoneOptions()}
                isSearchable={true}
                placeholder="Search Time Zone"
              />
            </div>
          </div>

          <div className="col-1 col-sm-1 col-md-1 col-lg-1 text-end">
            <div className="d-flex next_previous">
              <button onClick={handlePrevious}>{"<"}</button>
              <button onClick={handleNext} className="next-11">{">"}</button>
            </div>
          </div>

        </div>

        <hr />

        <div className="col-8 col-sm-8 col-md-8 col-lg-8 row px-4">
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0">
            <div>
              <h5>Content Name</h5>
            </div>
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0">
            <div> <h5>Start</h5></div>
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0">
            <div> <h5>End</h5></div>
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0">
            <div> <h5>Action</h5></div>
          </div>

        </div>
        <div className="col-lg-12 row px-4">
          <div ref={drop} className="col-8 col-sm-8 col-md-8 col-lg-8 scroll_video_draganddrop">
            <div className={`board ${isOver ? "highlight" : ""}`} ref={drop}>
              {/* <h2>Board</h2> */}
              {fetchDataForDate(selectedDate)?.map((video) => (
                <>
                  <div key={video?.id}>
                    <div className="col-lg-12 row p-2" style={{ borderBottom: "1px solid #e5e5ea" }}>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0">
                        <Video
                          id={video?.id}
                          videourl={video?.videourl}
                          scheduledDate={video?.scheduledDate}
                          scheduledTime={video?.scheduledTime}
                        />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
                        <div>
                          <p>{video?.StartTime}</p>
                        </div>
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
                        <p>{video?.StartTime}</p>
                      </div>

                      <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
                        {/* <div className="d-flex">
                          <button onClick={() => handleEditClick(video.id)}>Edit</button>
                          <button onClick={() => handleDeleteClick(video.id)}>Delete</button>
                        </div> */}

                        <div className="editdropdowntest ">
                          <span className="editdropdowntest-button">
                            <span>
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </span>
                          <div className="editdropdowntest-menu">
                            {/* <Link onClick={() => handleEditClick(video.id)}>
                              <span>
                                <img
                                  src={edititem}
                                  alt="flogo"
                                  width={20}
                                  height={20}
                                />
                                <span className="ms-2">
                                  Edit</span>
                              </span>
                            </Link> */}

                            <Link
                              onClick={() => handleEditClick(video?.id)}
                              className="commonActionPaddingcommonActionPadding"
                            >
                              <img
                                src={edititem}
                                alt="flogo"
                                width={20}
                                height={20}
                              />
                              <span className="ms-2">
                                Gap
                              </span>
                            </Link>
                            <Link
                              // onClick={() => handleDeleteClick(video?.id)} 
                              className="commonActionPaddingcommonActionPadding"
                            >
                              <img
                                src={deleteitem}
                                alt="flogo"
                                width={20}
                                height={20}
                              />
                              <span className="ms-2">
                                Delete
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {editingIds?.includes(video?.id) && (
                      <>
                        <div className="col-lg-12 row p-2" style={{ borderBottom: "1px solid #e5e5ea" }}>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0">
                            <div className="col-lg-12 row">
                              <Video
                                id={video?.id}
                                videourl={video?.videourl}
                                scheduledDate={video?.scheduledDate}
                                scheduledTime={video?.scheduledTime}
                              />
                            </div>
                          </div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
                            <div>
                              <p>{video?.StartTime}</p>
                            </div>
                          </div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
                            <div>
                              <p>{video?.EndTime}</p>
                            </div>
                          </div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
                            <div>
                              {/* <button onClick={() => handleEditClick(video?.id)}>edit</button> */}
                              <button onClick={() => handleDeleteClick(video?.id)}>delete</button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                </>
              ))}
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-4 col-lg-4 scroll_video_draganddrop">
            <div className="videos">
              {/* <h2>Available Videos</h2> */}
              <div className="col-lg-12 row" style={{ display: "flex", flexWrap: "wrap" }}>
                {videoList?.map((video) => (
                  <div className="col-lg-4" key={video?.id} style={{ flex: "1 0 33.33%" }}>
                    <Video id={video?.id} videourl={video?.videourl} />
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditManageCalendarschedule = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <DragDrop />
    </DndProvider>
  );
};

export default EditManageCalendarschedule;






// 09/01/2024

// import React, { useState, useEffect } from "react";
// import { DndProvider, useDrag, useDrop } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import axios from "axios";
// import DatePicker from "react-datepicker";
// import TimePicker from "react-bootstrap-time-picker";
// import Select from "react-select";
// import moment from "moment-timezone";
// import "react-datepicker/dist/react-datepicker.css";
// import "./EditManageCalendarschedule.css";

// const videoList = [
//   {
//     id: 1,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-01T00:00:00Z"),
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
//   {
//     id: 2,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-05T00:00:00Z"),
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
//   {
//     id: 3,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
//   {
//     id: 4,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
//   {
//     id: 5,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
//   {
//     id: 6,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
//   {
//     id: 7,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
//   {
//     id: 8,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
//   {
//     id: 9,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
//   {
//     id: 10,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
//   {
//     id: 11,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
//   {
//     id: 12,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
//   {
//     id: 13,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
//   {
//     id: 14,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//     StartTime: "12:12:12",
//     EndTime: "12:12:12",
//   },
// ];

// const currentDate = moment();

// const DragDrop = () => {
//   const [boardData, setBoardData] = useState([
//     {
//       id: 13,
//       videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//       scheduledDate: new Date("2024-01-05T05:39:18.175Z"),
//       scheduledTime: 0,
//       StartTime: "10:10:10",
//       EndTime: "10:10:10",
//     },
//     {
//       id: 14,
//       videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//       scheduledDate: new Date("2024-01-05T05:39:18.175Z"),
//       scheduledTime: 0,
//       StartTime: "10:10:10",
//       EndTime: "10:10:10",
//     },
//     {
//       id: 15,
//       videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//       scheduledDate: new Date("2024-01-05T05:39:18.175Z"),
//       scheduledTime: 0,
//       StartTime: "10:10:10",
//       EndTime: "10:10:10",
//     },
//     {
//       id: 16,
//       videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//       scheduledDate: new Date("2024-01-06T05:39:18.175Z"),
//       scheduledTime: 0,
//       StartTime: "10:10:10",
//       EndTime: "10:10:10",
//     },
//     {
//       id: 17,
//       videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//       scheduledDate: new Date("2024-01-06T05:39:18.175Z"),
//       scheduledTime: 0,
//       StartTime: "10:10:10",
//       EndTime: "10:10:10",
//     },
//     {
//       id: 18,
//       videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//       scheduledDate: new Date("2024-01-06T05:39:18.175Z"),
//       scheduledTime: 0,
//       StartTime: "10:10:10",
//       EndTime: "10:10:10",
//     },
//   ].filter(video => moment(video.scheduledDate).isSame(currentDate, 'day')));

//   const [selectedDate, setSelectedDate] = useState(currentDate.toDate());
//   const [selectedDatetest, setSelectedDateselectedDatetest] = useState('');
//   const [selectedDateselectedDate, setSelectedDateselectedDate] = useState("");

//   useEffect(() => {
//     setSelectedDateselectedDate(selectedDate);
//   }, []);

//   console.log(selectedDate,selectedDateselectedDate, "selectedDateselectedDateselectedDateselectedDate")
//   console.log(selectedDatetest, "onchange event ")

//   const [selectedTime, setSelectedTime] = useState(new Date().setHours(12, 0, 0, 0));
//   const [currentTimeZone, setCurrentTimeZone] = useState("Asia/Kolkata");
//   const [editingIds, setEditingIds] = useState([]);

//   const [{ isOver }, drop] = useDrop(() => ({
//     accept: "video",
//     drop: (item) => addVideoToBoard(item.id, selectedDate),
//     collect: (monitor) => ({
//       isOver: !!monitor.isOver(),
//     }),
//   }));

//   const updateSelectedDate = (newDate) => {
//     setSelectedDate(newDate);
//   };

//   const Video = ({ id, videourl, scheduledDate }) => {
//     const [{ isDragging }, drag] = useDrag(() => ({
//       type: "video",
//       item: { id, scheduledDate },
//       canDrag: () => !editingIds.includes(id),
//       collect: (monitor) => ({
//         isDragging: !!monitor.isDragging(),
//       }),
//     }));

//     return (
//       <div className={`video ${isDragging ? "dragging" : ""}`} ref={drag} onClick={(selectedDate) => updateSelectedDate(selectedDate)}>
//         <img width={150} height={150} src={videourl} alt={`Video ${id}`} />
//         <p>{`Video ID: ${id}`}</p>
//       </div>
//     );
//   };

//   const handleDateChange = (tests) => {
//     setSelectedDate(tests)
//     setSelectedDateselectedDatetest(tests);
//   };



//   const addVideoToBoard = (id) => {
//     const video = videoList.find((v) => v.id === id);


//     console.log(selectedDatetest , "api reponse after onchange the event value of date")
//     axios.post("YOUR_API_ENDPOINT", {
//       videoId: id,
//       scheduledDate: selectedDatetest,
//       scheduledTime: selectedTime,
//     })
//       .then(response => {
//         console.log("Video added successfully!", response.data);
//       })
//       .catch(error => {
//         console.error("Error adding video:", error);
//       });

//     setBoardData((prevBoardData) => {
//       const updatedBoardData = { ...prevBoardData };
//       if (!updatedBoardData[selectedDate]) {
//         updatedBoardData[selectedDate] = [];
//       }

//       const existingVideoIndex = updatedBoardData[selectedDate].findIndex(
//         (v) => v.id === video.id
//       );

//       if (existingVideoIndex !== -1) {
//         updatedBoardData[selectedDate][existingVideoIndex] = {
//           ...updatedBoardData[selectedDate][existingVideoIndex],
//           ...video,
//         };
//       } else {
//         updatedBoardData[selectedDate].push(video);
//       }

//       return updatedBoardData;
//     });
//   };

//   const handleTimeChange = (time) => {
//     setSelectedTime(time);
//   };

//   const handleNext = () => {
//     const nextDate = moment(selectedDate).add(1, "days").toDate();
//     setSelectedDate(nextDate);
//   };

//   const handlePrevious = () => {
//     const prevDate = moment(selectedDate).subtract(1, "days").toDate();
//     setSelectedDate(prevDate);
//   };

//   const fetchDataForDate = (date) => {
//     return boardData[date] || [];
//   };

//   const hasBoardData = (date) => {
//     return boardData[date] && boardData[date].length > 0;
//   };

//   useEffect(() => {
//     const dataForDate = fetchDataForDate(selectedDate);
//     if (!hasBoardData(selectedDate)) {
//       setBoardData((prevBoardData) => {
//         const updatedBoardData = { ...prevBoardData };
//         updatedBoardData[selectedDate] = [...dataForDate,];
//         return updatedBoardData;
//       });
//     }
//   }, [selectedDate]);

//   const handleTimeZoneChange = (selectedOption) => {
//     if (selectedOption && selectedOption.value) {
//       setCurrentTimeZone(selectedOption);
//     } else {
//       console.error("Invalid selected option:", selectedOption);
//     }
//   };

//   const getTimeZoneOptions = () => {
//     const timeZones = moment.tz.names();
//     return timeZones.map((zone) => ({ value: zone, label: zone }));
//   };

//   const handleEditClick = (id) => {
//     setEditingIds((prevIds) => {
//       if (prevIds.includes(id)) {
//         return prevIds.filter((editingId) => editingId !== id);
//       } else {
//         return [...prevIds, id];
//       }
//     });
//   };

//   const handleDeleteClick = (id) => {
//     setEditingIds((prevIds) => prevIds.filter((editingId) => editingId !== id));
//   };

//   return (
//     <div className="newdraganddrop_schedule  p-3  ">

//       <div className="card">

//         <div className="col-lg-12 row py-3 p-2">
//           <div className="col-4 col-sm-4 col-md-4 col-lg-4">
//             <div className="d-flex">
//               <label>Scheduled Date:</label>
//               <DatePicker selected={selectedDate} onSelect={setSelectedDate} onChange={handleDateChange} />
//             </div>
//           </div>
//           {/* <div className="col-3 col-sm-3 col-md-3 col-lg-3"> <div className="d-flex">
//             <label>Scheduled Time:</label>
//             <TimePicker value={selectedTime} onChange={handleTimeChange} />
//           </div></div> */}

//           <div className="col-4 col-sm-4 col-md-4 col-lg-4"> <div className="d-flex">
//             <label>Time Zone:</label>
//             <Select
//               className="react-select-container"
//               classNamePrefix="react-select"
//               value={currentTimeZone}
//               onChange={handleTimeZoneChange}
//               options={getTimeZoneOptions()}
//               isSearchable={true}
//               placeholder="Search Time Zone"
//             />
//           </div></div>
//           <div className="col-4 col-sm-4 col-md-4 col-lg-4"> <div className="d-flex">
//             <button onClick={handlePrevious}>Previous</button>
//             <button onClick={handleNext}>Next</button>
//           </div></div>
//         </div>
//         <div className="col-lg-12 row">
//           <div ref={drop} className="col-7 col-sm-7 col-md-7 col-lg-7 scroll_video_draganddrop">
//             <div className={`board ${isOver ? "highlight" : ""}`} ref={drop}>
//               <h2>Board</h2>
//               {fetchDataForDate(selectedDate)?.map((video, index) => (
//                 <>
//                   <div key={video?.id}>
//                     <div className="col-lg-12 row p-2" style={{ borderBottom: "1px solid #e5e5ea" }}>
//                       <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0">
//                         <Video
//                           id={video?.id}
//                           videourl={video?.videourl}
//                           scheduledDate={video?.scheduledDate}
//                           scheduledTime={video?.scheduledTime}
//                         />
//                       </div>
//                       <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                         <div>
//                           <p>{video?.StartTime}</p>
//                         </div>
//                       </div>
//                       <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                         <p>{video?.EndTime}</p>
//                       </div>
//                       <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                         <div className="d-flex">
//                           <button onClick={() => handleEditClick(video?.id)}>Edit</button>
//                           <button onClick={() => handleDeleteClick(video?.id)}>Delete</button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>

//                   <div>
//                     {editingIds.includes(video?.id) && (
//                       <div className="col-lg-12 row p-2" style={{ borderBottom: "1px solid #e5e5ea" }}>
//                         <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0">
//                           <div className="col-lg-12 row">
//                             <Video
//                               id={video?.id}
//                               videourl={video?.videourl}
//                               scheduledDate={video?.scheduledDate}
//                               scheduledTime={video?.scheduledTime}
//                             />
//                           </div>
//                         </div>
//                         <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                           <div>
//                             <p>{video?.StartTime}</p>
//                           </div>
//                         </div>
//                         <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                           <div>
//                             <p>{video?.EndTime}</p>
//                           </div>
//                         </div>
//                         <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                           <div>
//                             {/* <button onClick={() => handleEditClick(video?.id)}>edit</button> */}
//                             <button onClick={() => handleDeleteClick(video?.id)}>delete</button>
//                           </div>
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                 </>
//               ))}
//             </div>
//           </div>
//           <div className="col-5 col-sm-5 col-md-5 col-lg-5 scroll_video_draganddrop">
//             <div className="videos">
//               <h2>Available Videos</h2>
//               <div className="col-lg-12 row" style={{ display: 'flex', flexWrap: 'wrap' }}>
//                 {videoList?.map((video) => (
//                   <div className="col-lg-4" key={video?.id} style={{ flex: '1 0 33.33%' }}>
//                     <Video id={video?.id} videourl={video?.videourl} />
//                     <hr />
//                   </div>

//                 ))}
//               </div>

//             </div>
//           </div>
//         </div>
//       </div >
//     </div >
//   );
// };

// const EditManageCalendarschedule = () => {
//   return (
//     <DndProvider backend={HTML5Backend}>
//       <DragDrop />
//     </DndProvider>
//   );
// };

// export default EditManageCalendarschedule;


// 09/01/2024





// next and previous button code is here this alll


// import React, { useState, useEffect } from "react";
// import { DndProvider, useDrag, useDrop } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import axios from "axios";
// import DatePicker from "react-datepicker";
// import TimePicker from "react-bootstrap-time-picker";
// import Select from "react-select";
// import moment from "moment-timezone";
// import "react-datepicker/dist/react-datepicker.css";
// import "./EditManageCalendarschedule.css";

// const videoList = [
//   {
//     id: 1,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-01T00:00:00Z"),
//     scheduledTime: 0,
//   },
//   {
//     id: 2,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-05T00:00:00Z"),
//     scheduledTime: 0,
//   },
//   {
//     id: 3,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//   },
//   {
//     id: 4,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//   },
//   {
//     id: 5,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//   },
//   {
//     id: 6,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//   },
//   {
//     id: 7,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//   },
//   {
//     id: 8,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//   },
//   {
//     id: 9,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//   },
//   {
//     id: 10,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//   },
//   {
//     id: 11,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//   },
//   {
//     id: 12,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//   },
//   {
//     id: 13,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//   },
//   {
//     id: 14,
//     videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//     scheduledDate: new Date("2022-01-10T00:00:00Z"),
//     scheduledTime: 0,
//   },
// ];

// const DragDrop = () => {
//   const [board, setBoard] = useState([
//     {
//       id: 1,
//       videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//       scheduledDate: new Date("2022-01-01T00:00:00Z"),
//       scheduledTime: 0,
//     },
//     {
//       id: 2,
//       videourl: "https://api.flicknexs.com/public/uploads/images/video-image-1703758129.webp",
//       scheduledDate: new Date("2022-01-05T00:00:00Z"),
//       scheduledTime: 0,
//     },
//   ]);
//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const [selectedTime, setSelectedTime] = useState(0);
//   const [currentTimeZone, setCurrentTimeZone] = useState("Asia/Kolkata");
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const [{ isOver }, drop] = useDrop(() => ({
//     accept: "video",
//     drop: (item) => addVideoToBoard(item.id),
//     collect: (monitor) => ({
//       isOver: !!monitor.isOver(),
//     }),
//   }));

//   const addVideoToBoard = (id) => {
//     const video = videoList.find((v) => v.id === id);

//     // Check if the video's scheduled date matches the selected date
//     if (moment(video.scheduledDate).isSame(selectedDate, 'day')) {
//       video.scheduledTime = selectedTime;

//       setBoard((prevBoard) => [...prevBoard, video]);

//       axios.post("YOUR_API_ENDPOINT", { videoId: id, scheduledDate: selectedDate, scheduledTime: selectedTime })
//         .then(response => {
//           console.log(response.data);
//         })
//         .catch(error => {
//           console.error(error);
//         });
//     } else {
//       console.log("Video does not match the selected date.");
//     }
//   };

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   const handleTimeChange = (time) => {
//     setSelectedTime(time);
//   };



//   const handleNext = () => {
//     // Clear the board and set the selected date to the next date
//     setBoard([]);
//     setCurrentIndex(0);
//     setSelectedDate((prevDate) => moment(prevDate).add(1, 'day').toDate());
//   };

//   const handlePrevious = () => {
//     // Clear the board and set the selected date to the previous date
//     setBoard([]);
//     setCurrentIndex(0);
//     setSelectedDate((prevDate) => moment(prevDate).subtract(1, 'day').toDate());
//   };

//   const clearBoard = () => {
//     // Clear the board and set the selected date to the current date
//     setBoard([]);
//     setCurrentIndex(0);
//     setSelectedDate(new Date());
//   };

//   useEffect(() => {
//     axios.get("YOUR_API_ENDPOINT")
//       .then(response => {
//         setBoard(response.data);
//       })
//       .catch(error => {
//         console.error(error);
//       });
//   }, []);


//   const handleTimeZoneChange = (selectedOption) => {
//     if (selectedOption && selectedOption.value) {
//       setCurrentTimeZone(selectedOption);
//     } else {
//       console.error("Invalid selected option:", selectedOption);
//     }
//   };

//   const getTimeZoneOptions = () => {
//     const timeZones = moment.tz.names();
//     return timeZones.map((zone) => ({ value: zone, label: zone }));
//   };

//   const Video = ({ id, videourl, scheduledDate, scheduledTime }) => {
//     const [{ isDragging }, drag] = useDrag(() => ({
//       type: "video",
//       item: { id },
//       collect: (monitor) => ({
//         isDragging: !!monitor.isDragging(),
//       }),
//     }));

//     return (
//       <div className={`video ${isDragging ? "dragging" : ""}`}>
//         <img ref={drag} width={150} height={150} src={videourl} alt={`Video ${id}`} controls onClick={clearBoard} />
//       </div>
//     );
//   };

//   return (
//     <div className="newdraganddrop_schedule  p-3 ">
//       <div className="card">
//         <div className="col-lg-12 row py-3 p-2">
//           <div className="col-4 col-sm-4 col-md-4 col-lg-4">
//             <div className="d-flex">
//               <label>Scheduled Date:</label>
//               <DatePicker selected={selectedDate} onChange={handleDateChange} />
//             </div>
//           </div>
//           <div className="col-4 col-sm-4 col-md-4 col-lg-4"> <div className="d-flex">
//             <label>Time Zone:</label>
//             <Select
//               className="react-select-container"
//               classNamePrefix="react-select"
//               value={currentTimeZone}
//               onChange={handleTimeZoneChange}
//               options={getTimeZoneOptions()}
//               isSearchable={true}
//               placeholder="Search Time Zone"
//             />
//           </div></div>
//           <div className="col-4 col-sm-4 col-md-4 col-lg-4"> <div className="d-flex">
//             <button onClick={handlePrevious}>Previous</button>
//             <button onClick={handleNext}>Next</button>
//           </div></div>
//         </div>
//         <div className="col-lg-12 row">
//           <div ref={drop} className="col-7 col-sm-7 col-md-7 col-lg-7 scroll_video_draganddrop">
//             <div className={`board ${isOver ? "highlight" : ""}`} ref={drop}>
//               <h2>Board</h2>
//               {board?.map((video, index) => (
//                 <div key={video?.id} className={`d-flex ${index === currentIndex ? "selected" : ""}`}>
//                   <div className="col-lg-12 row p-2" style={{ borderBottom: "1px solid #e5e5ea" }}>
//                     <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0">
//                       <Video
//                         id={video?.id}
//                         videourl={video?.videourl}
//                         scheduledDate={video?.scheduledDate}
//                         scheduledTime={video?.scheduledTime}
//                       />
//                     </div>
//                     <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                       <div>
//                         <p>adfas</p>
//                       </div>
//                     </div>
//                     <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                       <div>
//                         <p>adfas</p>
//                       </div>
//                     </div>
//                     <div className="col-3 col-sm-3 col-md-3 col-lg-3 p-0" style={{ alignSelf: "center" }}>
//                       <div>
//                         <p>adfas</p>
//                       </div>
//                     </div>
//                   </div>
//                   <hr />
//                 </div>
//               ))}

//             </div>
//           </div>
//           <div className="col-5 col-sm-5 col-md-5 col-lg-5 scroll_video_draganddrop">
//             <div className="videos">
//               <h2>Available Videos</h2>
//               <div className="col-lg-12 row" style={{ display: 'flex', flexWrap: 'wrap' }}>
//                 {videoList?.map((video) => (
//                   <div className="col-lg-4" key={video?.id} style={{ flex: '1 0 33.33%' }}>
//                     <Video id={video?.id} videourl={video?.videourl} />
//                     <hr />
//                   </div>

//                 ))}
//               </div>

//             </div>
//           </div>
//         </div>
//       </div >
//     </div >
//   );
// };

// const EditManageCalendarschedule = () => {
//   return (
//     <DndProvider backend={HTML5Backend}>
//       <DragDrop />
//     </DndProvider>
//   );
// };

// export default EditManageCalendarschedule;

// next and previous button code is here this alll







// // App.js
// import React, { useState, useEffect } from "react";
// import { DndProvider, useDrag, useDrop } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import axios from "axios";
// import "./EditManageCalendarschedule.css";

// const videoList = [
//   {
//     id: 1,
//     videourl: "https://api.flicknexs.com/storage/app/public/video_uploads/Yvl3g48d5T3FZGpQd48lRGsVh7z6Ou0tsiDjPcQe.mp4",
//   },
//   {
//     id: 2,
//     videourl: "https://api.flicknexs.com/storage/app/public/video_uploads/oTYqbhyCoqQH2NKTFqZyDz9tGUrLitApWggGUzLf.mp4",
//   },
//   {
//     id: 3,
//     videourl: "https://api.flicknexs.com/storage/app/public/video_uploads/795gMc518i224U4HLNwkWBsI5g1ORWGiTbEqkX1D.mp4",
//   },
// ];

// const Video = ({ id, videourl }) => {
//   const [{ isDragging }, drag] = useDrag(() => ({
//     type: "video",
//     item: { id },
//     collect: (monitor) => ({
//       isDragging: !!monitor.isDragging(),
//     }),
//   }));

//   return (
//     <video
//       ref={drag}
//       src={videourl}
//       alt={`Video ${id}`}
//       className={`video ${isDragging ? "dragging" : ""}`}
//       controls
//     />
//   );
// };

// const DragDrop = () => {
//   const [board, setBoard] = useState([]);

//   const [{ isOver }, drop] = useDrop(() => ({
//     accept: "video",
//     drop: (item) => addVideoToBoard(item.id),
//     collect: (monitor) => ({
//       isOver: !!monitor.isOver(),
//     }),
//   }));

//   const addVideoToBoard = (id) => {
//     const video = videoList?.find((v) => v.id === id);
//     setBoard((prevBoard) => [...prevBoard, video]);

//     // Make a POST request to your API endpoint with the dropped video
//     axios.post("YOUR_API_ENDPOINT", { videoId: id })
//       .then(response => {
//         // Handle the response if needed
//         console.log(response.data);
//       })
//       .catch(error => {
//         // Handle errors
//         console.error(error);
//       });
//   };

//   useEffect(() => {
//     axios.get("YOUR_API_ENDPOINT")  // Replace with your actual GET API endpoint
//       .then(response => {
//         setBoard(response.data);  // Assuming the API response is an array of videos
//       })
//       .catch(error => {
//         console.error(error);
//       });
//   }, []);

//   return (
//     <div className="newdraganddrop_schedule">
//       <div className="col-lg-12 row">
//         <div ref={drop} className="col-7 col-sm-7 col-md-7 col-lg-7">
//           <div className={`board ${isOver ? "highlight" : ""}`} ref={drop}>
//             <h2>Board</h2>
//             {board?.map((video) => (
//               <div className="d-flex">
//                 <Video key={video?.id} id={video?.id} videourl={video?.videourl} />
//                 <p>adfas</p>
//                 </div>
//             ))}
//           </div>
//         </div>
//         <div className="col-5 col-sm-5 col-md-5 col-lg-5">
//           <div className="videos">
//             <h2>Available Videos</h2>
//             {videoList?.map((video) => (
//               <Video key={video?.id} id={video?.id} videourl={video?.videourl} />
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const EditManageCalendarschedule = () => {
//   return (
//     <DndProvider backend={HTML5Backend}>
//       <DragDrop />
//     </DndProvider>
//   );
// };

// export default EditManageCalendarschedule;














// import React, { useState, useEffect, useRef } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
// import AddOutlineIcon from '@rsuite/icons/AddOutline';
// import loadings from "../../../components/Images/loading/loading.gif";
// import JoditEditor from "jodit-react";

// function EditManageCalendarschedule(props) {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const [editUser, setEditUser] = useState({
//     id: "",
//     user_id: "",
//     name: "",
//     type: "",
//     description: "",
//   });
//   const [editname, setEditname] = useState({
//     name: "",
//   });

//   const access_token = localStorage.getItem("access_token");
//   const [loading, setLoading] = useState(true);
//   const [data, setData] = useState([]);

//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + access_token,
//     Accept: "application/json",
//     "Access-Control-Allow-Origin": "*",
//   };

//   const fileInputRef1 = useRef(null);
//   const fileInputRef2 = useRef(null);

//   const [selectedFile1, setSelectedFile1] = useState(null);
//   const [selectedFile2, setSelectedFile2] = useState(null);

//   const handleFileChange = (event, fileInputRef, setSelectedFile) => {
//     const file = event.target.files[0];
//     setSelectedFile(file);
//   };

//   const handleDelete = (setSelectedFile1, setSelectedFile2) => {
//     setSelectedFile1(null);
//     if (fileInputRef1.current) {
//       fileInputRef1.current.value = null;
//     }
//     setSelectedFile2(null);
//     if (fileInputRef2.current) {
//       fileInputRef2.current.value = null;
//     }
//   };

//   useEffect(async () => {
//     const getUser = async () => {
//       const reqData = await fetch(
//         `${process.env.REACT_APP_Baseurl}/admin/manage/schedule/${id}`,
//         { headers: headers }
//       );
//       const resData = await reqData?.json();
//       var res = resData?.VideoVideo_Schedule;
//       setEditUser(res);
//       setEditname(res);
//       setLoading(false);
//     };
//     getUser();

//     axios
//       .get(`${process.env.REACT_APP_Baseurl}/admin/manage/schedule/create`, {
//         headers: headers,
//       })
//       .then((response) => {
//         var result = response?.data?.Video_Video_Schedule;
//         setData(result);
//         setLoading(false);
//       })
//       .catch((error) => console.log(error));
//   }, [id]);


//   const handleChangeFirstdes = (e) => {
//     setEditUser({ ...editUser, description: e });
//     // console.log(e, "onchnageddddd");
//   };
//   const handleInput = (e) => {
//     const { name, value } = e.target;
//     setEditUser((prevEditUser) => ({
//       ...prevEditUser,
//       [name]: value,
//     }));

//     setValidationMessagetitle("");
//     setIsValidationHiddentitle(true);
//     setEditUser({ ...editUser, [e.target.name]: e.target.value });

//   };

//   const handleInputenable = (e) => {
//     if (e.target.checked === true) {
//       setEditUser({ ...editUser, [e.target.name]: 1 });
//     } else {
//       setEditUser({ ...editUser, [e.target.name]: 0 });
//     }
//   };

//   const [validationMessagetitle, setValidationMessagetitle] = useState("");
//   const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

//   const [validationMessagetype, setValidationMessagetype] = useState("");
//   const [isValidationHiddentype, setIsValidationHiddentype] = useState(true);

//   const titleInputRef = useRef(null);
//   const typeInputRef = useRef(null);


//   const [processing, setProcessing] = useState(false);
//   const [showOverlay, setShowOverlay] = useState(false);

//   const handleUpdate = async (e) => {

//     let focusInputRef = null;

//     if (editUser?.name === "") {
//       setValidationMessagetitle("Name cannot be empty.");
//       setIsValidationHiddentitle(false);
//       focusInputRef = titleInputRef;
//     }
//     if (editUser?.type === "") {
//       setValidationMessagetype("Type cannot be empty.");
//       setIsValidationHiddentype(false);
//       focusInputRef = typeInputRef;
//     }

//     if (focusInputRef) {
//       focusInputRef?.current?.focus();
//       return;
//     }

//     e.preventDefault();

//     const editInputvalue = {
//       id: editUser?.id,
//       user_id: editUser?.user_id,
//       name: editUser?.name,
//       slug: editUser?.slug,
//       description: editUser?.description,
//     };

//     let firstApiStatus = false;
//     let secondApiStatus = false;
//     let resultapi = null;
//     let resultapifalse = null;
//     let resjson = null;
//     setProcessing(true);
//     setShowOverlay(true);

//     try {
//       const res = await fetch(
//         `${process.env.REACT_APP_Baseurl}/admin/manage/schedule/update/${id}`,
//         {
//           method: "POST",
//           headers: headers,
//           body: JSON.stringify(editInputvalue),
//         }
//       );

//       resjson = await res.json();

//       if (resjson?.status === true) {
//         firstApiStatus = true;

//         resultapi = resjson;

//         const formData = new FormData();
//         formData.append("manage_schedule_id", id);
//         if (selectedFile1) {
//           formData.append("image", selectedFile1);
//         }
//         if (selectedFile2) {
//           formData.append("banner_image", selectedFile2);
//         }

//         await axios.post(
//           `${process.env.REACT_APP_Baseurl}/admin/manage/schedule//image-upload`,
//           formData,
//           { headers: headers }
//         );
//         secondApiStatus = true;
//       } else if (resjson?.status === false) {
//         // Handle the case when the first API response status is false
//         resultapifalse = resjson;
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }

//     if (firstApiStatus && secondApiStatus) {
//       setValidationMessagetitle("");
//       setValidationMessagetype("");
//       props?.setApiresponsealert(resultapi);
//       props?.setShowalert(true);
//       setTimeout(() => {
//         window.location.reload();
//       }, 3000);
//     } else if (resultapifalse) {
//       // Check if resultapifalse is defined and not null
//       props?.setApiresponsealert(resultapifalse);
//       props?.setShowalert(true);
//       setProcessing(false);
//       setShowOverlay(false);
//       setIsValidationHiddentitle(false);
//       setValidationMessagetype(false);
//     }
//   };

//   return (
//     <>
//       <div className=" m-0 p-0">
//         <section className="container-fluid">
//           <div className="col-lg-12 row py-3">
//             <div className="col-6 col-sm-6 col-lg-6">
//               <h3>Edit Video Schedule</h3>
//             </div>
//             <div className="col-6 col-sm-6 col-lg-6">
//               <div className="text-end">
//                 <button onClick={handleUpdate} className="btn btn-primary">
//                   Update Video Schedule
//                 </button>
//               </div>
//             </div>
//           </div>

//           <div>
//             {showOverlay && (
//               <div className="overlay-container">
//                 <span className="overlay-icon">
//                   {" "}
//                   <img
//                     src={loadings}
//                     width={100}
//                   // alt="Animated GIF"
//                   />
//                   Processing ...
//                 </span>
//               </div>
//             )}
//           </div>

//           <div className="row col-lg-12 ">
//             <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
//               <div className="card p-3">
//                 <h5>{editname?.name}</h5>
//                 <hr></hr>
//                 <div className=" text-start">
//                   <label className="m-0">
//                     Name<span className="mandatory">*</span>
//                   </label>
//                   <div className="mt-2">
//                     <input
//                       type="text"
//                       className="rs-input form-control-lg"
//                       id="name"
//                       name="name"
//                       placeholder=""
//                       onChange={handleInput}
//                       value={editUser?.name}
//                       ref={titleInputRef}
//                     />
//                   </div>
//                   <span className="errorred">
//                     {!isValidationHiddentitle && (
//                       <p>{validationMessagetitle}</p>
//                     )}
//                   </span>
//                 </div>


//                 <div className="row mt-2 text-start">
//                   <div className="col-12">
//                     <label className="m-0">Type<span className="mandatory">*</span></label>

//                     <div className="mt-2">
//                       <select
//                         className="form-select"
//                         name="parent_id"
//                         id="parent_id"
//                         value={editUser?.parent_id}
//                         onChange={handleInput}
//                         ref={typeInputRef}
//                       >
//                         {data?.map((item) => (
//                           <option value={item?.id}>{item?.name}</option>
//                         ))}
//                       </select>

//                       <span className="errorred">
//                         {!isValidationHiddentype && (
//                           <p>{validationMessagetype}</p>
//                         )}
//                       </span>

//                     </div>

//                     <div className="mt-2 text-start">
//                       <label className="m-0">Description</label>

//                       <div className="mt-2">
//                         <JoditEditor
//                           className="rs-input"
//                           value={editUser?.description}
//                           onChange={(e) => {
//                             handleChangeFirstdes(e);
//                           }}
//                         />
//                       </div>
//                     </div>

//                   </div>
//                 </div>
//               </div>


//               <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
//                 <div className="modal-dialog">
//                   <div className="modal-content">
//                     <div className="modal-header">
//                       <h5 className="modal-title" id="staticBackdropLabel">Upload</h5>
//                       <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                     </div>
//                     <div className="modal-body">
//                       <div className="row mt-2 text-start">
//                         <div className="col-12">
//                           <label className="m-0">Type</label>

//                           <div className="mt-2">
//                             <select
//                               className="form-select"
//                               name="parent_id"
//                               id="parent_id"
//                               value={editUser?.parent_id}
//                               onChange={handleInput}
//                             >
//                               {data?.map((item) => (
//                                 <option value={item?.id}>{item?.name}</option>
//                               ))}
//                             </select>

//                           </div>
//                         </div>
//                       </div>


//                       <div className="col-lg-12 mt-2 ">
//                         <ul className="nav nav-pills" id="pills-tab" role="tablist">
//                           <li className="nav-item" role="presentation">
//                             <button className="nav-link active" id="pills-computer-tab" data-bs-toggle="pill" data-bs-target="#pills-computer" type="button" role="tab" aria-controls="pills-computer" aria-selected="true">Home</button>
//                           </li>
//                           <li className="nav-item" role="presentation">
//                             <button className="nav-link" id="pills-library-tab" data-bs-toggle="pill" data-bs-target="#pills-library" type="button" role="tab" aria-controls="pills-library" aria-selected="false">Profile</button>
//                           </li>
//                         </ul>
//                         <div className="tab-content" id="pills-tabContent">
//                           <div className="tab-pane fade show active" id="pills-computer" role="tabpanel" aria-labelledby="pills-computer-tab">
//                             <div className="">
//                               <div className="col-lg-12 row">
//                                 <div className="col-12 col-sm-12 col-lg-12">
//                                   <div
//                                     className="imagedrop"
//                                   >
//                                     <input
//                                       type="file"
//                                       accept="video/*"
//                                       style={{ display: "none" }}
//                                     />
//                                     <button>
//                                       <CameraRetroIcon />
//                                     </button>
//                                   </div>
//                                 </div>

//                               </div>
//                             </div>
//                           </div>
//                           <div className="tab-pane fade" id="pills-library" role="tabpanel" aria-labelledby="pills-library-tab">testing</div>
//                         </div>
//                       </div>

//                     </div>

//                   </div>
//                 </div>
//               </div>

//               <div className="card p-3">
//                 <h5>Media</h5>
//                 <hr></hr>

//                 <div className="mt-2 text-start">
//                   <label className="m-0">Filler Media</label>

//                   <div className="mt-2">
//                     <div className="col-lg-12 row">
//                       <div className="col-6 col-sm-6 col-lg-6">
//                         <div
//                           className="imagedrop"
//                           data-bs-toggle="modal" data-bs-target="#staticBackdrop"
//                         >
//                           <input
//                             type="file"
//                             style={{ display: "none" }}
//                           />
//                           <button>
//                             <AddOutlineIcon />
//                           </button>
//                         </div>
//                       </div>

//                     </div>
//                   </div>
//                 </div>

//               </div>

//               <div className="text-start mb-3">
//                 <button onClick={handleUpdate} className="btn btn-primary">
//                   Update Video Schedule
//                 </button>
//               </div>
//             </div>

//             {/* Second Part of Section  */}

//             <div className="col-6 col-sm-6 col-md-6 col-lg-6">
//               <div className="card p-3">
//                 <h5>Thumbnails</h5>
//                 <hr></hr>

//                 <div className=" text-start mt-2">
//                   <label className="m-0">Image</label>

//                   <div>
//                     {loading ? (
//                       <img src={loadings} className="thumbnails-images" />
//                     ) : (
//                       <img
//                         src={editUser?.image_url}
//                         alt="Image"
//                         className="thumbnails-images"
//                       />
//                     )}
//                   </div>

//                   <div className="mt-2">
//                     <div className="col-lg-12 row">
//                       <div className="col-6 col-sm-6 col-lg-6">
//                         <div
//                           className="imagedrop"
//                           onClick={() => fileInputRef1.current.click()}
//                         >
//                           <input
//                             type="file"
//                             ref={fileInputRef1}
//                             onChange={(event) =>
//                               handleFileChange(
//                                 event,
//                                 fileInputRef1,
//                                 setSelectedFile1
//                               )
//                             }
//                             accept="image/*"
//                             style={{ display: "none" }}
//                           />
//                           <button>
//                             <CameraRetroIcon />
//                           </button>
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-lg-6">
//                         <div>
//                           {selectedFile1 && (
//                             <div className="imagedropcopy text-end">
//                               <div className="col-lg-12 row p-0 m-0">
//                                 <div className=" col-sm-10 col-lg-10 p-0 m-0">
//                                   <img
//                                     src={URL?.createObjectURL(selectedFile1)}
//                                     alt="Uploaded"
//                                     className="thumbnails-upload-images"
//                                   />
//                                 </div>
//                                 <div
//                                   className="col-sm-2 col-lg-2 p-0 m-0"
//                                   style={{ alignSelf: "center" }}
//                                 >
//                                   <button
//                                     onClick={() =>
//                                       handleDelete(setSelectedFile1)
//                                     }
//                                     type="button"
//                                     className="btn-close"
//                                     data-bs-dismiss="modal"
//                                     aria-label="Close"
//                                   ></button>
//                                 </div>
//                               </div>
//                             </div>
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className=" text-start mt-2">
//                   <label className="m-0">Banner</label>

//                   {/* <div>
//                     <img
//                       src={editUser?.banner_image_url}
//                       width="150"
//                       height="100"
//                     />
//                   </div> */}

//                   <div>
//                     {loading ? (
//                       <img src={loadings} className="thumbnails-images" />
//                     ) : (
//                       <img
//                         src={editUser?.banner_image_url}
//                         alt="Image"
//                         className="thumbnails-images"
//                       />
//                     )}
//                   </div>

//                   <div className="mt-2">
//                     <div className="col-lg-12 row">
//                       <div className="col-6 col-sm-6 col-lg-6">
//                         <div
//                           className="imagedrop"
//                           onClick={() => fileInputRef2.current.click()}
//                         >
//                           <input
//                             type="file"
//                             ref={fileInputRef2}
//                             onChange={(event) =>
//                               handleFileChange(
//                                 event,
//                                 fileInputRef2,
//                                 setSelectedFile2
//                               )
//                             }
//                             accept="image/*"
//                             style={{ display: "none" }}
//                           />
//                           <button onClick={() => fileInputRef2.current.click()}>
//                             <CameraRetroIcon />
//                           </button>
//                         </div>
//                       </div>
//                       <div className="col-6 col-sm-6 col-lg-6">
//                         <div>
//                           {selectedFile2 && (
//                             <div className="imagedropcopy text-end">
//                               <div className="col-lg-12 row p-0 m-0">
//                                 <div className=" col-sm-10 col-lg-10 p-0 m-0">
//                                   <img
//                                     src={URL?.createObjectURL(selectedFile2)}
//                                     alt="Uploaded"
//                                     className="thumbnails-upload-images"
//                                   />
//                                 </div>
//                                 <div
//                                   className="col-sm-2 col-lg-2 p-0 m-0"
//                                   style={{ alignSelf: "center" }}
//                                 >
//                                   <button
//                                     onClick={() =>
//                                       handleDelete(setSelectedFile2)
//                                     }
//                                     type="button"
//                                     className="btn-close"
//                                     data-bs-dismiss="modal"
//                                     aria-label="Close"
//                                   ></button>
//                                 </div>
//                               </div>
//                             </div>
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     </>
//   );
// }

// export default EditManageCalendarschedule;
