// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r1{
    background: #F7F8FC;
    height: 60px;
}
.audioUploadIcon{
    width: 20px;
    height: 20px;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Audio/Audio_Management/AudioList.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".r1{\n    background: #F7F8FC;\n    height: 60px;\n}\n.audioUploadIcon{\n    width: 20px;\n    height: 20px;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
