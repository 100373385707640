import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function CommentSetting(props) {
  const [order, setOrder] = useState({});
  const [editUser, setEditUser] = useState({
    id: "",
    videos: "",
    livestream: "",
    episode: "",
    audios: "",
  });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Comment-section/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log("api checks", response.data.comment_section);
        var result = response?.data?.comment_section;
        setOrder(result);
        setEditUser(result);
        // console.log('result', result)
        // console.log('orders', order);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const videos = Number.isNaN(parseInt(editUser?.videos, 10)) ? 0 : parseInt(editUser?.videos, 10);
    const livestream = Number.isNaN(parseInt(editUser?.livestream, 10)) ? 0 : parseInt(editUser?.livestream, 10);
    const episode = Number.isNaN(parseInt(editUser?.episode, 10)) ? 0 : parseInt(editUser?.episode, 10);
    const audios = Number.isNaN(parseInt(editUser?.audios, 10)) ? 0 : parseInt(editUser?.audios, 10);

    const editInputvalue = {
      id: editUser?.id,
      videos: videos,
      livestream: livestream,
      episode: episode,
      audios: audios,
    };

    setProcessing(true);
    setShowOverlay(true);

    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Comment-section/update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );
    let resjson = await res.json();

    if (resjson.status === true) {
      var resultapi = resjson;

      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi && resultapi.status === false) {
        var resultError = resultapi;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Comment Section</h3>
              </div>

              <div className="col-6 col-sm-6 col-lg-6 text-end">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Save Comment
                </button>
              </div>
            </div>

            <div className="iq-card ">

              <div>
                {showOverlay && <Response_Processing></Response_Processing>}
              </div>

              <div className="col-lg-12">
                <div className="">
                  <Table>
                    <thead>
                      <tr className="title">
                        <th>COMMENT NAME</th>
                        <th>STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> Enable Comment Section for Videos </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="videos"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.videos === 1 ? true : false
                                }
                                checked={editUser?.videos === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="videos"
                                onChange={handleInput}
                                value={editUser?.videos === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>Enable Comment Section for LiveStream </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="livestream"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.livestream === 1 ? true : false
                                }
                                checked={
                                  editUser?.livestream === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="livestream"
                                onChange={handleInput}
                                value={editUser?.livestream === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>Enable Comment Section for Episode</td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="episode"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.episode === 1 ? true : false
                                }
                                checked={editUser?.episode === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="episode"
                                onChange={handleInput}
                                value={editUser?.episode === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td> Enable Comment Section for Audios </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="audios"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.audios === 1 ? true : false
                                }
                                checked={editUser?.audios === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="audios"
                                onChange={handleInput}
                                value={editUser?.audios === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    </>
  );
}

export default CommentSetting;
