import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

import "./Devices.css";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";

function Devices(props) {
  const [getDevices, setDevices] = useState([]);
  const [filteredDevicesData, setFilteredDevicesData] = useState([]);
  const [itemToDeleteDevices, setItemToDeleteDevices] = useState([]);
  const [modalCloseDevices, setModalCloseDevices] = useState(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [devices_name, setDevices_name] = useState("");

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchDataDevices = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/devices/index`,
        { headers: headers }
      );
      setDevices(response?.data?.devices);
      setFilteredDevicesData(response?.data?.devices);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataDevices();
  }, []);

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredDevicesData?.length },
    ],
  });

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteDevices([...itemToDeleteDevices, row.id]);
        } else {
          setItemToDeleteDevices(
            itemToDeleteDevices?.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteDevices,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAll}
        />
      </div>
    ),
  };

  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit-devices/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2">Edit Device</span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteDevices(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2"> Delete Device</span>
          </div>
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "devices_name",
      text: "Devices",
      sort: true,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const exportHeadersDevices = [
    { label: "ID", key: "id" },
    { label: "DEVICES", key: "devices_name" },
  ];

  const conditionalGeneratePDFDevices = (get) => {
    const doc = new jsPDF();
    doc.text("ALL DEVICES LIST ", 10, 10);
    const columns = ["INDEX", "ID", "DEVICES"];
    const allDataPDF = getDevices?.map((item, index) => [
      index + 1,
      item?.id,
      item?.devices_name,
    ]);
    const filterPDF = filteredDevicesData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.devices_name,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("ALL-DEVICES-LIST.pdf");
  };

  const generateXLSXDEVICES = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(
        get === 1 ? getDevices : filteredDevicesData
      );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "ALL-DEVICES-LIST.xlsx");
  };

  const handleFilterChangeDevices = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = getDevices.filter((item) =>
      ["id", "devices_name"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredDevicesData(filteredResults);
  };

  const handleClear = () => {
    fetchDataDevices();
    setFilter("");
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDeleteDevices(
      selectAll ? [] : filteredDevicesData?.map((item) => item?.id)
    );
  };

  const closeDevicesModal = () => {
    setModalCloseDevices(!modalCloseDevices);
  };

  const openModalSingleDeleteDevices = (id) => {
    setItemToDeleteDevices([id]);
    setModalCloseDevices(true);
  };

  const openModalMultiDeleteDevices = () => {
    setModalCloseDevices(true);
  };

  const handleDeleteDevicesData = () => {
    closeDevicesModal();
    devicesDeleteOperation();
  };

  const devicesDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteDevices + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/devices/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const [validationMessagedevices_name, setValidationMessagedevices_name] =
    useState("");
  const [isValidationHiddendevices_name, setIsValidationHiddendevices_name] =
    useState(true);

  const devices_nameInputRef = useRef(null);

  async function saveDevices() {
    let focusInputRef = null;

    if (devices_name === "") {
      setValidationMessagedevices_name("Device Name cannot be empty.");
      setIsValidationHiddendevices_name(false);
      focusInputRef = devices_nameInputRef;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    const formData = new FormData();

    formData.append("devices_name", devices_name);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/devices/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      setValidationMessagedevices_name("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        console.log(resultError);
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddendevices_name(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  return (
    <>
      <div
        className={`modal packageModel  ${modalCloseDevices ? "show" : ""}`}
        tabIndex="-1"
        style={{
          display: modalCloseDevices ? "block" : "none",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeDevicesModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteDevices?.length === 1 ? (
                <p className="fs-6">
                  {" "}
                  Are you sure you want to delete this device ?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected devices ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={closeDevicesModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteDevicesData}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="devices"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                New Devices
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <label>
                  Label Name<span className="mandatory">*</span>
                </label>

                <div className="mt-2">
                  <input
                    type="text"
                    name="devices_name"
                    placeholder=""
                    className="rs-input form-control-lg"
                    onChange={(e) => setDevices_name(e.target.value)}
                    ref={devices_nameInputRef}
                  />
                </div>

                <span className="errorred">
                  {!isValidationHiddendevices_name && (
                    <p>{validationMessagedevices_name}</p>
                  )}
                </span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary ml-3 "
                onClick={saveDevices}
              >
                Add Device
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-6">
              <h4>
                All Devices{" "}
                <span className="fs-6">
                  (Display all {getDevices?.length} Page)
                </span>
              </h4>
            </div>
            <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
              {itemToDeleteDevices?.length > 0 && (
                <button
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModalMultiDeleteDevices}
                >
                  <MdDelete className="exportIcon " />
                  Delete
                </button>
              )}
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#devices"
              >
                <i className="fa fa-plus-circle"></i> Add Device
              </button>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
              <div className="filterContainer">
                <input
                  type="text"
                  placeholder="Filter all.."
                  className="filter form-control "
                  value={filter}
                  onChange={handleFilterChangeDevices}
                />
                {filter?.length > 0 && (
                  <button
                    type="button"
                    className="closeBtn"
                    onClick={handleClear}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                )}
              </div>
              <div className="btn-group">
                <div className="dropdown d-block " key={"2"}>
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export </span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeadersDevices}
                        data={filteredDevicesData}
                        className="text-dark d-block"
                        filename="DEVICES-FILTERED-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFDevices(0);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXDEVICES(0);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>

                <div className="dropdown d-block ">
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export All</span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeadersDevices}
                        data={getDevices}
                        className="text-dark d-block"
                        filename="ALL-DEVICES-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFDevices(1);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXDEVICES(1);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bootstrapTable">
              <BootstrapTable
                keyField="id"
                columns={columns}
                data={filteredDevicesData}
                pagination={pagination}
                className="no-border-table"
                noDataIndication={
                  <div className="noRecord">
                    {loading ? (
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>
                        Loading...
                      </label>
                    ) : (
                      filteredDevicesData?.length <= 0 && (
                        <p>No Devices Available. !!!</p>
                      )
                    )}
                  </div>
                }
                hover
                headerClasses="videoWidth"
                selectRow={selectRow}
                selectAll={selectAll}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Devices;
