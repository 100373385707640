import React, { useState, useEffect, useRef } from "react";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import { useParams } from "react-router-dom";

const EditDevices = (props) => {
  const { id } = useParams();

  const [editUser, setEditUser] = useState({
    devices_name: "",
    user_id: "",
    id: "",
  });
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/devices/edit/${id}`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      var res = resData?.device;
      setEditUser(res);
      // console.log(resData);
      // console.log(res);
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [validationMessagedevices_name, setValidationMessagedevices_name] =
    useState("");
  const [isValidationHiddendevices_name, setIsValidationHiddendevices_name] =
    useState(true);

  const devices_nameInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.devices_name === "") {
      setValidationMessagedevices_name("Name cannot be empty.");
      setIsValidationHiddendevices_name(false);
      focusInputRef = devices_nameInputRef;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      device_id: editUser?.id,
      devices_name: editUser?.devices_name,
      user_id: editUser?.user_id,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/devices/update`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      setValidationMessagedevices_name("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson?.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddendevices_name(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Device</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Device
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-12 col-md-12 col-lg-12 ">
                <div className="card p-3">
                  {/* <h5>{editname?.plans_name}</h5> */}
                  {/* <hr></hr> */}
                  <div className=" text-start">
                    <label className="m-0">
                      Coupon Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="username"
                        name="devices_name"
                        placeholder="Enter Name"
                        onChange={handleInput}
                        value={editUser?.devices_name}
                        ref={devices_nameInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddendevices_name && (
                        <p>{validationMessagedevices_name}</p>
                      )}
                    </span>
                  </div>

                  {/* <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Plan
                  </button>
                </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default EditDevices;
