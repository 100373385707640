import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import "./View_Media_Library.css";

function View_Media_Library() {
  const [editUser, setEditUser] = useState({
    id: 166,
    title: "video (2160p).mp4",
    slug: "video-2160p",
    type: "mp4_video_upload",
    uploaded_by: "Admin",
    duration: "00:02:00",
    draft: 1,
    active: 1,
    status: 1,
    publish_type: "publish_now",
    mp4_url:
      "https://api.flicknexs.com/storage/app/public/video_uploads/F7kS4ckGstLVsYhiPl5i1Tis3sQUO8zZeYsUxNFd.mp4",

    Thumbnail:
      "https://api.flicknexs.com/public/uploads/images/video-image-1707116114.webp",
    upload_on: "January 19th 2024",
  });

  const convertToTitleCase = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const formattedDatatype = convertToTitleCase(editUser?.type);

  return (
    <div className="m-0 p-0">
      <section className="container-fluid">
        <div className="col-lg-12 row py-3">
          <div className="col-12 col-sm-12 col-lg-12">
            <h3>Media Library</h3>
          </div>
        </div>

        {/* <div>
            {showOverlay && (
              <div className="overlay-container">
                <span className="overlay-icon">
                  {" "}
                  <img src={loading} width={100} alt="Animated GIF" />
                  Processing ...
                </span>
              </div>
            )}
          </div> */}

        <div className="row col-lg-12 ">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
            <div className="card p-3">
              <h5>{editUser?.title}</h5>
              <hr></hr>
              <div className="row">
                <label className="m-0">Video Media</label>
                <div className="col-6 col-md-6 col-lg-6">
                  <div className=" text-start">
                    <div className="mt-2">
                      <img
                        className="w-auto h-100"
                        style={{ height: "250px" }}
                        src={editUser?.Thumbnail}
                        alt={editUser?.title}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-6 col-lg-6">
                  {editUser?.mp4_url ? (
                    <div className="text-start mt-2">
                      <ReactPlayer
                        url={editUser?.mp4_url}
                        controls={true} // Enable player controls
                        width="100%"
                        height="230px"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mt-2 text-start">
                <label className="m-0">Type</label>

                <div className="mt-2">
                  <input
                    type="text"
                    className="rs-input form-control-lg"
                    id="type"
                    name="type"
                    value={formattedDatatype}
                  />
                </div>
              </div>
              <div className=" text-start mt-2">
                <label className="m-0">Name</label>
                <div className="mt-2">
                  <input
                    type="text"
                    className="rs-input form-control-lg"
                    id="title"
                    name="title"
                    value={editUser?.title}
                  />
                </div>
              </div>
              <div className="mt-2 text-start">
                <label className="m-0">Thumbnail</label>

                <div className="mt-2">
                  <input
                    type="text"
                    className="rs-input form-control-lg"
                    id="Thumbnail"
                    name="Thumbnail"
                    value={editUser?.Thumbnail}
                  />
                </div>
              </div>

              <div className="mt-2 text-start">
                <label className="m-0">Video Url</label>

                <div className="mt-2">
                  <input
                    type="text"
                    className="rs-input form-control-lg"
                    id="mp4_url"
                    name="mp4_url"
                    value={editUser?.mp4_url}
                  />
                </div>
              </div>
              <div className="mt-2 text-start">
                <label className="m-0">Upload On</label>

                <div className="mt-2">
                  <input
                    type="text"
                    className="rs-input form-control-lg"
                    id="upload_on"
                    name="upload_on"
                    value={editUser?.upload_on}
                  />
                </div>
              </div>
            </div>

            {/* <div className="text-start mb-3">
              <button className="btn btn-primary">Save Category</button>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default View_Media_Library;
