import React from 'react'

const AddCategory = () => {
  return (
    <div>
         <div className=" m-0 p-0">
      <div className="">
        <section className="section  container-fluid">
          <div className="mt-4">
            <div className="iq-card">
              <h4>Add Category</h4>
              <hr />
              <div className="modal-body">
                <div>
                  <div className="form-group ">
                    <label>Name:</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                    //   onChange={(e) => setName(e.target.value)}
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-group ">
                    <label>Slug:</label>
                    <input
                      type="text"
                      id="slug"
                      name="slug"
                      className="form-control"
                    //   onChange={(e) => setSlug(e.target.value)}
                      placeholder="Enter Slug"
                    />
                  </div>
                  <div className="form-group ">
                    <label>Image:</label>
                    <input
                      type="file"
                      multiple="true"
                      className="form-control"
                      name="image"
                    //   onChange={(e) => setImage(e.target.files[0])}
                      id="image"
                    />
                  </div>

                  <div className="form-group ">
                    <label>Category:</label>
                    <select
                      className="form-control"
                      name="parent_id"
                      id="parent_id"
                    //   onChange={(e) => setParent_id(e.target.value)}
                    >
                      <option value="0">Select</option>
                      {/* {user.map((item) => (
                        <option value={item.parent_id}>{item.name}</option>
                      ))} */}
                    </select>
                  </div>

                  <div className="text-end p-3 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      id="submit-new-cat"
                    //   onClick={submitcategory}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    </div>
  )
}

export default AddCategory