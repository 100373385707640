import { finalRouteApi } from "./CommonApiUrl"


const artistDefaultURL = {
    listApi: `${finalRouteApi}/artist/index`,
    MultiDeleteApi: `${finalRouteApi}/artist/multi-delete`,
    createApi: `${finalRouteApi}/artist/create`,
    storeApi: `${finalRouteApi}/artist/store`,
    uploadImageApi: `${finalRouteApi}/Image-upload-artist`,
    editApi: `${finalRouteApi}/artist/edit`,
    updateApi: `${finalRouteApi}/artist/update`,
    addRouteLink: `/add-artist`,
    editRouteLink: `/edit-artist/`,
    viewTab: 'en/music/artist',
    headingText: "All Trainer",
    buttonText: "Add Trainer",
    exportFileName: "ARTISTS-LIST",
    fetchArrayName: "AllArtist",
    reqBodyName: {
        deleteName: "id"
    }
}

const musicianDefaultURL = {
    listApi: `${finalRouteApi}/musician/index`,
    MultiDeleteApi: `${finalRouteApi}/musician/multi-delete`,
    createApi: `${finalRouteApi}/musician/create`,
    storeApi: `${finalRouteApi}/musician/store`,
    uploadImageApi: `${finalRouteApi}/Image-upload-musician`,
    editApi: `${finalRouteApi}/musician/edit`,
    updateApi: `${finalRouteApi}/musician/update`,
    addRouteLink: `/add-artist-musician`,
    editRouteLink: `/edit-artist-musician/`,
    viewTab: 'en/music/artist',
    headingText: "Manage Trainer",
    buttonText: "Add Trainer",
    exportFileName: "MUSICIAN-LIST",
    fetchArrayName: "AllArtist",
    reqBodyName: {
        deleteName: "id"
    }
}

const writerDefaultURL = {
    listApi: `${finalRouteApi}/song-writer/index`,
    MultiDeleteApi: `${finalRouteApi}/song-writer/multi-delete`,
    createApi: `${finalRouteApi}/song-writer/create`,
    storeApi: `${finalRouteApi}/song-writer/store`,
    uploadImageApi: `${finalRouteApi}/Image-upload-song-writer`,
    editApi: `${finalRouteApi}/song-writer/edit`,
    updateApi: `${finalRouteApi}/song-writer/update`,
    addRouteLink: `/add-artist-songwriter`,
    editRouteLink: `/edit-artist-songwriter/`,
    viewTab: 'music',
    headingText: "Manage Publisher / Song Writer",
    buttonText: "Add Writer",
    exportFileName: "WRITER-LIST",
    fetchArrayName: "AllArtist",
    reqBodyName: {
        deleteName: "id"
    }
}
export { artistDefaultURL, musicianDefaultURL, writerDefaultURL }