import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import edititem from "../../../components/Images/edititem.svg";

function Tv_Settings(props) {
  const [data, setData] = useState([]);
  const [tvsetting, setTvsetting] = useState("");
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // const [previous, setPrevious] = useState([]);
  // const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  // const [toggle, setToggle] = useState(true);
  // const [isOff, setIsOff] = useState(false);

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const fetchDatanextpre = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/tv-settings/index?page=${page}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const tvsettingData = res?.data?.TVSetting;
        setTvsetting(tvsettingData);
        if (tvsettingData.length > 0) {
          setData(tvsettingData);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(async () => {
    fetchDatanextpre();
  }, [page]);

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div id="content-page" className="content-page">
              <div className="col-lg-12 row py-3">
                <div className="col-12 col-sm-12 col-lg-12">
                  <h3>TV Settings </h3>
                </div>
              </div>

              <div className="col-lg-12 ">
                <div className="card">
                  {loading ? (
                    <div className="p-2 text-start iq-card-body">
                      <Table>
                        <thead>
                          <tr className="title">
                            <th>NAME</th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                      </Table>

                      <div className="text-center">
                        <label>
                          {" "}
                          <Response_Loading_processing></Response_Loading_processing>{" "}
                          Loading...
                        </label>
                      </div>
                    </div>
                  ) : tvsetting?.length > 0 ? (
                    <div className="p-2 text-start iq-card-body">
                      {/* Render your table here with tvsetting */}
                      <Table>
                        <thead>
                          <tr className="title">
                            <th>NAME</th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                        {currentItems?.map((item, key) => (
                          <tbody>
                            <tr key={item?.id}>
                              <td className="">{item?.name}</td>

                              <td>
                                <div className="editdropdown">
                                  <span className="editdropdown-button">
                                    <span>
                                      <i
                                        className="fa fa-ellipsis-v"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </span>
                                  <div className="editdropdown-menu">
                                    <Link
                                      to={"/update_tvsetting/" + item?.id}
                                      className="edit"
                                    >
                                      <span>
                                        <img
                                          src={edititem}
                                          alt="flogo"
                                          width={20}
                                          height={20}
                                        />
                                        <span className="ms-2">Edit Tv</span>
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </div>
                  ) : (
                    <div className="p-2 text-start iq-card-body">
                      <Table>
                        <thead>
                          <tr className="title">
                            <th>NAME</th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                      </Table>

                      <div className="text-center">
                        <label>No TV Settings Available.</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Tv_Settings;
