import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

function UpdateCurrencySettings(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    country: "",
    symbol: "",
  });
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/currency_setting/edit/${id}`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      var res = resData?.CurrencySetting;
      setEditUser(res);
      // console.log(resData)
      console.log(res);

      await axios
        .get(`${process.env.REACT_APP_Baseurl}/admin/currency_setting/index`, {
          headers: headers,
        })
        .then((response) => {
          var result = response?.data?.allCurrency;
          setData(result);
          // console.log(result)
        })
        .catch((error) => console.log(error));
    };
    getUser();
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const editInputvalue = {
      country: editUser.country,
      symbol: editUser.symbol,
    };

    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/currency_setting/store`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        navigate("/currency_settings");
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };


  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Currency Setting</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button className="btn btn-primary " onClick={handleUpdate}>
                    Update Currency
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="iq-card ">
              <div className="col-lg-12">
                <div className="panel-body">
                  <div id="nestable" className="nested-list with-margins">
                    <select
                      className="form-select"
                      name="country"
                      aria-label="Default select example"
                      onChange={handleInput}
                      value={editUser?.country}
                    >
                      {data?.map((item) => (
                        <option name={item?.country} value={item?.country}>
                          {" "}
                          {item?.symbol} - {item?.country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>


    </>
  );
}

export default UpdateCurrencySettings;
