// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allpage .title{
    color: #4895d1;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Site/Site_Pages/EditPages.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".allpage .title{\n    color: #4895d1;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
