import React from 'react'

const AddManageCategories = () => {
  return (
    <div>
        <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="iq-card p-2 mt-3">
              <div className="p-3">
                <div className="col-md-12 mb-3">
                  <label className="m-0"> Name:</label>

                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    placeholder="Enter Name"
                    // onChange={(e) => setName(e.target.value)}

                  />
                </div>

                <div className="col-md-12 mb-3">
                  <label className="m-0"> Slug:</label>

                  <input
                    type="text"
                    id="slug"
                    name="slug"
                    className="form-control"
                    placeholder="Enter Slug"
                    // onChange={(e) => setSlug(e.target.value)}

                  />
                </div>
              

               
                <div className="d-flex align-items-center">
                  <label>Display In Home page:</label>
                  <div className="ms-3">
                        <label className="text-danger">No </label>
                        <label className="switch">
                          <input
                            name="active"
                           
                            type="checkbox"
                            // checked={in_menu}
                            // onChange={checkHandler}
                          />
                          <span
                            className="slider round"
                            name="active"
                            // value={item.id}
                          ></span>
                        </label>
                        <label className="text-success">Yes</label>

                      </div>
                      </div>
                <div className=" mt-3 form-group">
                  <label>Image:</label>
                  <input
                    type="file"
                    multiple="true"
                    className="form-control"
                    name="image"
                    id="image"
                    // onChange={(e) => setImage(e.target.files[0])}

                  />
                </div>
                
                <div className="col-md-12 mb-3">
                <label>Category:</label>
                    <select
                      className="form-control"
                      name="parent_id"
                      id="parent_id"
                    //   onChange={(e) => setParent_id(e.target.value)}
                    >
                      <option value="0">Select</option>
                      {/* {category.map((item) => (
                        <option value={item.parent_id}>{item.name}</option>
                      ))} */}
                    </select>
                 
                </div>
                <hr />

                <div className="row mt-3">
                  <div className="col-sm-12 text-end">
                    <input
                      type="submit"
                      value="Save"
                      className="btn btn-primary "
                    //   onClick={handleSave}

                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default AddManageCategories