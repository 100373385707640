import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Livechannelpartner() {
  const [cpp, setCpp] = useState([]);

  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);

  const [channel, setChannel] = useState([]);
  // console.log(channel);

  const [data1, setData1] = useState([]);
  const [previous1, setPrevious1] = useState([]);
  const [next1, setNext1] = useState([]);
  const [page1, setPage1] = useState(1);
  const [toggle1, setToggle1] = useState(true);
  const [isOff1, setIsOff1] = useState(false);

  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/content_partner/livestream-pending`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.pending_videos?.data;
        setCpp(result);
        // console.log(result)
      })
      .catch((error) => console.log(error));

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/livestream-pending`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.pending_videos?.data;
        setChannel(result);
      })
      .catch((error) => console.log(error));
  }, []);

  const rejected = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/content_partner/reject-livestream/` +
          id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Rejected Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  const approved = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/content_partner/approve-livestream/` +
          id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Approved Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClick() {
    // console.log('preview');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/content_partner/livestream-pending?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious(res?.data?.pending_videos?.data);
        setCpp(res?.data?.pending_videos?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpage() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/content_partner/livestream-pending?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious(res?.data?.pending_videos?.data);
        setCpp(res?.data?.pending_videos?.data);
        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClick() {
    // console.log('next');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/content_partner/livestream-pending?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.videos.data);
        setNext(res?.data?.pending_videos?.data);
        setCpp(res?.data?.pending_videos?.data);
        setPage(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // useEffect(async () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_Baseurl}/admin/channel_partner/videos-pending`,
  //       {
  //         headers: headers,
  //       }
  //     )
  //     .then((response) => {
  //       var result = response.data.pending_videos.data;
  //       setChannel(result);
  //       console.log(result)
  //     })
  //     .catch((error) => console.log(error));
  // }, []);

  const rejectedchannel = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/reject-livestream/` +
          id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Rejected Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  const approvedchannel = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/approve-livestream/` +
          id,
        { headers: headers }
      )
      .then((response) => {
        alert("Item Approved Successfully !");
        //  navigate('/allvideo')
        window.location.reload();
      });
  };

  function handlePreClickchannel() {
    // console.log('preview');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/channel_partner/livestream-pending?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious1(res?.data?.pending_videos?.data);
        setChannel(res?.data?.pending_videos?.data);
        setPage1(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlefirstpagechannel() {
    // console.log('handlefirstpage');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/channel_partner/livestream-pending?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        setPrevious1(res?.data?.pending_videos?.data);
        setChannel(res?.data?.pending_videos?.data);
        setPage1(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClickchannel() {
    // console.log('next');

    axios
      .get(
        `${
          process.env.REACT_APP_Baseurl
        }/admin/channel_partner/livestream-pending?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.videos.data);
        setNext1(res?.data?.pending_videos?.data);
        setChannel(res?.data?.pending_videos?.data);
        setPage1(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="">
              <div className="row">
                <div className="col-sm-12">
                  <div className="py-3">
                    <h4>Channel Uploaded Live Stream</h4>
                  </div>
                  <div className="iq-card">
                    <div className="iq-card-header ">
                      <div className="row ">
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                          >
                            {" "}
                            <div className="iq-card-body p-0">
                              <hr />

                              <Table>
                                <thead>
                                  <tr>
                                    <th>TITLES</th>
                                    <th>USER NAME</th>
                                    <th>YEAR</th>
                                    <th>VIDEO ACCESS</th>
                                    <th>STATUS</th>
                                    <th>ACTION</th>
                                  </tr>
                                </thead>
                                {channel?.map((item, key) => (
                                  <tbody>
                                    <tr>
                                      <td
                                        key={item?.id}
                                        className="table-content"
                                      >
                                        <p>{item?.title}</p>
                                      </td>
                                      <td className="table-content">
                                        <p>{item?.username}</p>
                                      </td>
                                      <td className="table-content">
                                        <p>{item?.year}</p>
                                      </td>
                                      <td className="table-content">
                                        <p>{item?.access}</p>
                                      </td>
                                      <td className="text-center">
                                        {item?.ppv_status === 0 ||
                                        item?.ppv_status === null ? (
                                          <td className="bg-warning video_active">
                                            Pending Approved Rejected
                                          </td>
                                        ) : item?.ppv_status === 1 &&
                                          item?.ppv_status === 1 &&
                                          item?.ppv_status === 1 ? (
                                          <td className="bg-success video_active">
                                            Approved
                                          </td>
                                        ) : (
                                          <td className="bg-warning video_active">
                                            Draft
                                          </td>
                                        )}
                                      </td>
                                      <td colSpan="2">
                                        <div className="flex align-items-center list-user-action fs-5">
                                          <Link
                                            className="iq-bg-warning"
                                            to=""
                                            onClick={() => approved(item?.id)}
                                          >
                                            <i className="fa fa-check-circle"></i>
                                          </Link>
                                          <Link
                                            className="iq-bg-success ms-2"
                                            to=""
                                            onClick={() => rejected(item?.id)}
                                          >
                                            <i className="fa fa-close"></i>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </Table>

                              <div className="row align-items-center text-center d-flex">
                                <div className="col-lg-4">
                                  <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={handlePreClickchannel}
                                  >
                                    {"<< Previous"}
                                  </button>
                                </div>
                                <div className="col-lg-4">
                                  <p className="">{page1}</p>
                                </div>
                                <div className="col-lg-4">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleNxtClickchannel}
                                  >
                                    {"Next >>"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Livechannelpartner;
