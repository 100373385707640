import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

const EditChannelPackage = (props) => {
  const { id } = useParams();
  const [channelPackage, setChennelPackage] = useState({});
  const [editPackagedata, setEditPackageData] = useState({});
  const [getOptions, setGetOption] = useState([]);

  const [errors, setErrors] = useState({});
  const [selectedValues, setSelectedValues] = useState();
  const [options, setOptions] = useState();
  const [getChannelId, setChannelId] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const chennelPackageRef = useRef(null);
  const packagePlanRef = useRef(null);
  const packageInterVal = useRef(null);
  const packagePrice = useRef(null);
  const selectInputRef = useRef(null);

  const navigate = useNavigate();

  const editFetchData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel-package/edit/${id}`,
        { headers: headers }
      )
      .then((res) => {
        setChennelPackage(res.data);
        setEditPackageData(res.data.Channel_package);
        setGetOption(res.data.channel_list_selected);
      })
      .catch((error) => {
        console.error("Error fetching data from the  API:", error);
      });
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/channel-package/create`,
        {
          headers: headers,
        }
      );

      const datalanguages = response?.data?.Channel_list;
      const formattedOptions = datalanguages?.map((item) => ({
        value: item?.id,
        label: item?.channel_name,
      }));

      const defaultSelectOption = datalanguages?.map((item) => {
        if (getOptions.includes(item?.id)) {
          return {
            value: item?.id,
            label: item?.channel_name,
          };
        }
        return null;
      });
      setSelectedValues(
        defaultSelectOption.filter((option) => option !== null)
      );
      setOptions(formattedOptions);
      setDataLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(async () => {
    if (dataLoaded) {
      const defaultSelectOption = options.filter((option) =>
        getOptions.includes(option.value)
      );
      setSelectedValues(defaultSelectOption);
    }
  }, [dataLoaded, options, getOptions]);

  const handleSelectChangeChannel = (selectedOptions) => {
    const finalSelect = selectedOptions?.map((option) => option.value);
    setSelectedValues(selectedOptions);
    setChannelId(finalSelect);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setEditPackageData((prevState) => {
      return { ...prevState, [name]: value };
    });
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!editPackagedata.channel_package_price) {
      newErrors.channel_package_price = "Package Price is required";
      packagePrice.current.focus();
    }
    if (!editPackagedata.channel_plan_interval) {
      newErrors.channel_plan_interval = "Plan Interval is required";
      packageInterVal.current.focus();
    }
    if (!editPackagedata.channel_package_plan_id) {
      newErrors.channel_package_plan_id = "Package plan is required";
      packagePlanRef.current.focus();
    }
    if (!editPackagedata.channel_package_name) {
      newErrors.channel_package_name = "Channel package is required";
      chennelPackageRef.current.focus();
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        setProcessing(true);
        setShowOverlay(true);
        const formDatas = new FormData();

        for (const key in editPackagedata) {
          formDatas.append(key, editPackagedata[key]);
        }
        formDatas.append("add_channels", getChannelId);
        const response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/channel-package/update/${id}`,
          formDatas,
          { headers }
        );
        let result = response?.data;
        if (result.status) {
          props?.setApiresponsealert(result);
          props?.setShowalert(true);
          setProcessing(false);
          setShowOverlay(false);
          window.location.reload();
        }
      } catch (err) {
        console.error(err);
        var errorMessage = "An error occurred while processing your request.";
        props?.setApiresponsealert({ status: false, message: errorMessage });
        props?.setShowalert(true);
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    setEditPackageData((prevState) => {
      return { ...prevState, status: prevState.status === 0 ? 1 : 0 };
    });
  };

  useEffect(async () => {
    fetchData();
    editFetchData();
  }, []);

  return (
    <div>
      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div id="content-page" className="content-page">
              <div className="">
                <div className="admin-section-title">
                  <div className="iq-card">
                    <div className="row">
                      <div className="col-md-6">
                        {" "}
                        <h4>
                          <i className="entypo-video"></i>Update Channel Package{" "}
                        </h4>{" "}
                      </div>

                      <div className="col-md-6"></div>
                    </div>
                    <hr />
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group position-relative mb-4 ">
                            <label for="channel_package_name">
                              Package Name
                            </label>
                            <p>
                              Add The Channel Package Name In The Textbox Below:
                            </p>
                            <input
                              type="text"
                              id="channel_package_name"
                              className="form-control"
                              placeholder="Package Name"
                              name="channel_package_name"
                              ref={chennelPackageRef}
                              onChange={handleChange}
                              value={editPackagedata.channel_package_name}
                            />
                            {errors.channel_package_name && (
                              <span className="errorred errorredspan p-0">
                                {errors.channel_package_name}
                              </span>
                            )}
                          </div>

                          <div className="form-group position-relative mb-4 ">
                            <label>Package Plan ID</label>
                            <p>
                              Add The Channel Package Plan Id In The Textbox
                              Below:
                            </p>
                            <input
                              type="text"
                              id="channel_package_plan_id"
                              className="form-control"
                              placeholder="Package Plan ID"
                              name="channel_package_plan_id"
                              ref={packagePlanRef}
                              onChange={handleChange}
                              value={editPackagedata.channel_package_plan_id}
                            />
                            {errors.channel_package_plan_id && (
                              <span className="errorred errorredspan p-0">
                                {errors.channel_package_plan_id}
                              </span>
                            )}
                          </div>

                          <div className="form-group position-relative mb-4">
                            <label>Plan Interval</label>
                            <p>
                              Add The Channel Plan Interval In The Textbox
                              Below:
                            </p>
                            <input
                              type="text"
                              id="channel_plan_interval"
                              className="form-control"
                              placeholder="Plan Interval"
                              name="channel_plan_interval"
                              ref={packageInterVal}
                              onChange={handleChange}
                              value={editPackagedata.channel_plan_interval}
                            />
                            {errors.channel_plan_interval && (
                              <span className="errorred errorredspan p-0">
                                {errors.channel_plan_interval}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label>Add Channel</label>
                            <p>
                              Select The List Of Channel In The Textbox Below:
                            </p>

                            <Select
                              options={options}
                              isMulti
                              className="form-control"
                              onChange={handleSelectChangeChannel}
                              value={selectedValues}
                              ref={selectInputRef}
                            />
                          </div>

                          <div className="form-group position-relative mb-4">
                            <label>Package Price</label>
                            <p>
                              Add The Channel Package Price In The Textbox
                              Below:
                            </p>
                            <input
                              type="text"
                              id="name"
                              className="form-control"
                              placeholder="Package Price"
                              name="channel_package_price"
                              ref={packagePrice}
                              onChange={handleChange}
                              value={editPackagedata.channel_package_price}
                            />
                            {errors.channel_package_price && (
                              <span className="errorred errorredspan p-0">
                                {errors.channel_package_price}
                              </span>
                            )}
                          </div>
                          <div className="form-group">
                            <label>Channel Package Status</label>
                            <p>Enable The Status For Channel Package:</p>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  editPackagedata.status === 0 ? false : true
                                }
                                onChange={handleCheckboxChange}
                                value={editPackagedata.status}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary text-right"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditChannelPackage;
