import React from 'react'
import { useTranslation } from 'react-i18next';

const MessageBox = ({ text, classname }) => {
    const { t } = useTranslation();
    return (
        <span className={`${classname}`}>{t(text)}</span>
    )
}

export default MessageBox