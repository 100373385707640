import React, { useState, useEffect, useRef } from "react";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import JoditEditor from "jodit-react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const EditSubscriptionPlan = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [plan_iddata, setPlans_iddata] = useState([]);
  const [paymentSetting, setPaymentSetting] = useState([]);
  const [subscriptionplanname, setSubscriptionplanname] = useState([]);
  const [paymentsetting, setPaymentsetting] = useState([]);

  const [devices, setDevices] = useState([]);
  const [devices2, setDevices2] = useState([]);
  const [data1, setData1] = useState("");
  const [deviceIds, setDeviceIds] = useState("");
  const [symbol, setSymbol] = useState("");

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, content: e });
    // console.log(e, "onchnageddddd");
  };

  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    plans_name: "",
    devices: "",
    plan_id: "",
    billing_interval: "",
    billing_type: "",
    payment_type: "",
    type: "",
    days: "",
    price: "",
    andriod_paystack_url: "",
    video_quality: "",
    plan_content: "",
    resolution: "",
    subscription_plan_name: "",
    ios_plan_price: "",
    ios_product_id: "",
    ads_status: "",
    plan_trail_status: "",
    plan_trail_days: "",
    plan_content: "",
  });

  const [editname, setEditname] = useState({
    title: "",
  });
  const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");

  const [loading, setLoading] = useState(true);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleChangeFirstdes = (e) => {
    setEditUser({ ...editUser, plan_content: e });
    // console.log(e, "onchnageddddd");
  };

  const handlePaste = (e) => {
    const pastedValue = e.clipboardData.getData("text");

    // Check if the pasted value is a positive integer greater than 0
    if (/^[1-9]\d*$/.test(pastedValue)) {
      setEditUser({
        ...editUser,
        plan_trail_days: pastedValue,
      });
    }

    // Prevent the default paste behavior
    e.preventDefault();
  };

  const handleInput = (e, fieldName) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    const { name, value } = e.target;
    const inputValue = e.target.value;
    if (/^[1-9]\d*$/.test(inputValue) || inputValue === "") {
      setEditUser({
        ...editUser,
        plan_trail_days: inputValue,
      });
    }
    // Create a copy of the plan_iddata array
    const updatedPlanIdData = [...plan_iddata];

    // Find the matching plan_iddata item and update its plan_id
    const matchingIndex = updatedPlanIdData.findIndex(
      (plan) => plan?.type === fieldName
    );

    if (matchingIndex !== -1) {
      updatedPlanIdData[matchingIndex] = {
        ...updatedPlanIdData[matchingIndex],
        plan_id: value,
      };

      // Update the plan_iddata state with the modified array
      setPlans_iddata(updatedPlanIdData);
    }

    setValidationMessageplan_name("");
    setIsValidationHiddenplan_name(true);

    setValidationMessageplan_id("");
    setIsValidationHiddenplan_id(true);

    setValidationMessageprice("");
    setIsValidationHiddenprice(true);

    setValidationMessagedays("");
    setIsValidationHiddendays(true);

    setValidationMessageresolution("");
    setIsValidationHiddenresolution(true);

    setValidationMessagebilling_interval("");
    setIsValidationHiddenbilling_interval(true);

    setValidationMessagebilling_type("");
    setIsValidationHiddenbilling_type(true);

    setValidationMessagevideo_quality("");
    setIsValidationHiddenvideo_quality(true);

    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 1 });
      setEditUser({
        ...editUser,
        [e.target.name]: e.target.value,
        [e.target.id]: 1,
      });
    } else {
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
      setEditUser({ ...editUser, [e.target.id]: 0 });
      setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  // const handleInput = (e) => {
  //   const { name, value } = e.target;
  //   setEditUser((prevEditUser) => ({
  //     ...prevEditUser,
  //     [name]: value,
  //   }));

  //   setValidationMessageplan_name("");
  //   setIsValidationHiddenplan_name(true);
  //   setEditUser({ ...editUser, [e.target.name]: e.target.value });

  //   if (e.target.checked === true) {
  //     setEditUser({ ...editUser, [e.target.name]: e.target.value });
  //     setEditUser({ ...editUser, [e.target.id]: 1 });
  //     setEditUser({
  //       ...editUser,
  //       [e.target.name]: e.target.value,
  //       [e.target.name]: 1,
  //     });
  //   } else {
  //     setEditUser({ ...editUser, [e.target.name]: e.target.value });
  //     setEditUser({ ...editUser, [e.target.id]: 0 });
  //     setEditUser({ ...editUser, [e.target.name]: e.target.value });
  //   }
  // };

  const [selectedDevices, setSelectedDevices] = useState([]);

  const handleInputs = (id) => {
    const deviceIds = editUser?.devices?.split(",");
    const index = deviceIds?.findIndex((val) => val.includes(id));
    if (index !== -1) {
      deviceIds?.splice(index, 1);
    } else {
      deviceIds?.push(id);
    }
    const devices = deviceIds?.length > 0 ? deviceIds?.join(",") : "";
    setEditUser({ ...editUser, devices });

    setSelectedDevices(deviceIds);
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/subscription/edit/${id}`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      const res = resData?.plans;
      const result = resData?.Devices;
      const plans_id = resData?.plans_id;
      const PaymentSetting = resData?.PaymentSetting;
      const subscriptionplanname = resData?.SubscriptionPlan_Name;
      setEditUser(res);
      setEditname(res);
      setDevices(result);
      setPlans_iddata(plans_id);
      setPaymentSetting(PaymentSetting);
      setSubscriptionplanname(subscriptionplanname);
      const deviceIds = res?.devices?.split(",");
      setDeviceIds(deviceIds);
    };

    getUser();

    const symbol = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/currency_setting/index`,
        { headers: headers }
      );

      const resData = await reqData?.json();
      const res = resData?.CurrencySetting[0].symbol;
      setSymbol(res);
    };

    symbol();
  }, []);

  const [validationMessageplan_name, setValidationMessageplan_name] =
    useState("");
  const [isValidationHiddenplan_name, setIsValidationHiddenplan_name] =
    useState(true);
  const [validationMessageplan_id, setValidationMessageplan_id] = useState("");
  const [isValidationHiddenplan_id, setIsValidationHiddenplan_id] =
    useState(true);
  const [validationMessageprice, setValidationMessageprice] = useState("");
  const [isValidationHiddenprice, setIsValidationHiddenprice] = useState(true);
  const [validationMessagedays, setValidationMessagedays] = useState("");
  const [isValidationHiddendays, setIsValidationHiddendays] = useState(true);

  const [validationMessageresolution, setValidationMessageresolution] =
    useState("");
  const [isValidationHiddenresolution, setIsValidationHiddenresolution] =
    useState(true);

  const [validationMessagebilling_type, setValidationMessagebilling_type] =
    useState("");
  const [isValidationHiddenbilling_type, setIsValidationHiddenbilling_type] =
    useState(true);

  const [
    validationMessagebilling_interval,
    setValidationMessagebilling_interval,
  ] = useState("");
  const [
    isValidationHiddenbilling_interval,
    setIsValidationHiddenbilling_interval,
  ] = useState(true);

  const [validationMessagevideo_quality, setValidationMessagevideo_quality] =
    useState("");
  const [isValidationHiddenvideo_quality, setIsValidationHiddenvideo_quality] =
    useState(true);

  const plan_nameInputRef = useRef(null);
  const plan_idInputRef = useRef(null);
  const priceInputRef = useRef(null);
  const daysInputRef = useRef(null);
  const video_qualityInputRef = useRef(null);
  const billing_intervalInputRef = useRef(null);
  const billing_typeInputRef = useRef(null);
  const resolutionInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const isValidationHiddenDevices = selectedDevices.length > 0;
  const validationMessageDevices = "Please select at least one device.";

  async function handleUpdate() {
    let focusInputRef = null;

    if (editUser?.plans_name === "") {
      setValidationMessageplan_name("Plan Name cannot be empty.");
      setIsValidationHiddenplan_name(false);
      focusInputRef = plan_nameInputRef;
    }
    if (editUser?.plan_id === "") {
      setValidationMessageplan_id(
        "Please Enable Payment Plan or Plan ID cannot be empty."
      );
      setIsValidationHiddenplan_id(false);
      focusInputRef = plan_idInputRef;
    }
    if (editUser?.billing_interval === "") {
      setValidationMessagebilling_interval("Billing Interval cannot be empty.");
      setIsValidationHiddenbilling_interval(false);
      focusInputRef = billing_intervalInputRef;
    }
    if (editUser?.billing_type === "") {
      setValidationMessagebilling_type("Billing Type cannot be empty.");
      setIsValidationHiddenbilling_type(false);
      focusInputRef = billing_typeInputRef;
    }
    if (editUser?.price === "") {
      setValidationMessageprice("Price cannot be empty.");
      setIsValidationHiddenprice(false);
      focusInputRef = priceInputRef;
    }
    if (editUser?.days === "") {
      setValidationMessagedays("Days cannot be empty.");
      setIsValidationHiddendays(false);
      focusInputRef = daysInputRef;
    }
    if (editUser?.video_quality === "") {
      setValidationMessagevideo_quality("Video Quality cannot be empty.");
      setIsValidationHiddenvideo_quality(false);
      focusInputRef = video_qualityInputRef;
    }
    if (editUser?.resolution === "") {
      setValidationMessageresolution("Resolution cannot be empty.");
      setIsValidationHiddenresolution(false);
      focusInputRef = resolutionInputRef;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    const formData = new FormData();

    formData.append("id", editUser?.id);
    formData.append("plans_name", editUser?.plans_name);
    // paymentSetting?.forEach((item, index) => {
    //   if (typeof item?.payment_type === "string") {
    //     var data = item?.payment_type;
    //     formData.append(`plan_id[${data}]`, plan_iddata);
    //   }
    // });

    plan_iddata?.forEach((item) => {
      formData.append(`plan_id[${item?.type}]`, item?.plan_id);
    });

    paymentSetting?.forEach((item, index) => {
      if (typeof item?.payment_type === "string") {
        var data = item?.payment_type;
        formData.append(
          `subscription_plan_name[${data}]`,
          `${editUser?.plans_name}${data}`
        );
      }
    });

    formData.append("billing_interval", editUser?.billing_interval);
    formData.append("billing_type", editUser?.billing_type);
    formData.append("payment_type", editUser?.payment_type);
    formData.append("type", editUser?.type);
    const daysdataa = Number.isNaN(parseInt(editUser?.days, 10)) ? 0 : parseInt(editUser?.days, 10);
    formData.append("days", daysdataa);
    formData.append("price", editUser?.price);
    formData.append("andriod_paystack_url", editUser?.andriod_paystack_url);
    formData.append("video_quality", editUser?.video_quality);
    formData.append("plan_content", editUser?.plan_content);
    formData.append("resolution", editUser?.resolution);
    formData.append("devices", editUser?.devices);
    formData.append("ios_plan_price", editUser?.ios_plan_price);
    formData.append("ios_product_id", editUser?.ios_product_id);
    const aactivedataactive = Number.isNaN(parseInt(editUser?.ads_status, 10)) ? 0 : parseInt(editUser?.ads_status, 10);
    formData.append("ads_status", aactivedataactive);
    const statusplan_trail_days = Number.isNaN(parseInt(editUser?.plan_trail_days, 10)) ? 0 : parseInt(editUser?.plan_trail_days, 10);
    formData.append("plan_trail_days", statusplan_trail_days);
    const statusplan_trail_statusdata = Number.isNaN(parseInt(editUser?.plan_trail_status, 10)) ? 0 : parseInt(editUser?.plan_trail_status, 10);
    formData.append("plan_trail_status", statusplan_trail_statusdata);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/subscription/update`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      setValidationMessageplan_name("");
      setValidationMessageprice("");
      setValidationMessagedays("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        console.log(resultError);
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddenplan_name(false);
        setIsValidationHiddenprice(false);
        setIsValidationHiddendays(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  const myArray = [];
  const arrayvalue = myArray.filter((item) => item !== null).length === 0;

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Subscription Plan</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Plan
                  </button>
                </div>

                <div className="row text-end">
                  <div className="col-lg-12">
                    <div className="mt-2">
                      <span className="errorred">
                        {!isValidationHiddenplan_id && (
                          <p>{validationMessageplan_id}</p>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className=" col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>{editname?.plans_name}</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      Plan name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="username"
                        name="plans_name"
                        placeholder="Enter Name"
                        onChange={handleInput}
                        value={editUser?.plans_name}
                        ref={plan_nameInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenplan_name && (
                        <p>{validationMessageplan_name}</p>
                      )}
                    </span>
                  </div>

                  <div className=" text-start mt-2">
                    <div className="mt-2">
                      {paymentSetting?.map((paymentSettingItem) => {
                        const value = paymentSettingItem?.payment_type;

                        // Find a matching plan_iddata item
                        const matchingPlan = plan_iddata.find(
                          (plan) => plan?.type === value
                        );

                        if (value && matchingPlan) {
                          return (
                            <div className="mt-2" key={paymentSettingItem?.id}>
                              <label>
                                {value} Plan ID
                                <span className="mandatory">*</span>
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name={value}
                                  value={matchingPlan.plan_id}
                                  onChange={(e) => handleInput(e, value)}
                                  className="rs-input form-control-lg"
                                  placeholder={`${value} Plan Id`}
                                  ref={plan_idInputRef}
                                />
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>

                    {paymentSetting?.map((subscriptionItem) => {
                      const value = subscriptionItem?.payment_type;

                      // Find a matching plan_iddata item
                      const matchingPlan = subscriptionplanname.find(
                        (plan) => plan?.subscription_plan_name === value
                      );

                      if (value && matchingPlan) {
                        return (
                          <div key={subscriptionItem?.id}>
                            <input
                              type="text"
                              id={value}
                              name={value}
                              value={matchingPlan.subscription_plan_name}
                              hidden
                              onChange={handleInput}
                              className="form-control"
                            />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">
                      Billing Interval<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="billing_interval"
                        name="billing_interval"
                        onChange={handleInput}
                        value={editUser?.billing_interval}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">
                      Billing Type<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="billing_type"
                        name="billing_type"
                        onChange={handleInput}
                        value={editUser?.billing_type}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">Andriod Paystack Url</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg"
                        // id="billing_type"
                        name="andriod_paystack_url"
                        onChange={handleInput}
                        value={editUser?.andriod_paystack_url}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">
                      Page Content<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <JoditEditor
                        className="rs-input"
                        value={editUser?.plan_content}
                        onChange={(e) => {
                          handleChangeFirstdes(e);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Status Settings</h5>
                  <hr></hr>

                  <div className="row">
                    <div className="col-lg-10">
                      <div className="">
                        <label>Enable this Page Ads </label>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div>
                        <label className="switch">
                          <input
                            name="ads_status"
                            id="ads_status"
                            onChange={handleInputenable}
                            defaultChecked={
                              editUser?.ads_status === 1 ? true : false
                            }
                            checked={editUser?.ads_status === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="ads_status"
                            id="ads_status"
                            onChange={handleInputenable}
                            value={editUser?.ads_status === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-lg-10">
                      <div className="">
                        <label>Enable this Plan Trail </label>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div>
                        <label className="switch">
                          <input
                            name="plan_trail_status"
                            onChange={handleInputenable}
                            defaultChecked={
                              editUser?.plan_trail_status === 1 ? true : false
                            }
                            checked={
                              editUser?.plan_trail_status === 1 ? true : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="plan_trail_status"
                            onChange={handleInputenable}
                            value={
                              editUser?.plan_trail_status === 1 ? "1" : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row text-start">
                  <div className="col-lg-12">
                    <div className="mt-2">
                      <span className="errorred">
                        {!isValidationHiddenplan_id && (
                          <p>{validationMessageplan_id}</p>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Plan
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className=" col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>Plan Description</h5>
                  <hr></hr>

                  <div className="text-start">
                    <label className="m-0">
                      Price ( {symbol} )<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="number"
                        // id="price"
                        name="price"
                        className="rs-input form-control-lg"
                        placeholder=""
                        onChange={handleInput}
                        value={editUser?.price}
                        ref={priceInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenprice && (
                        <p>{validationMessageprice}</p>
                      )}
                    </span>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Days <span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="number"
                        // id="days"
                        name="days"
                        className="rs-input form-control-lg"
                        placeholder=""
                        onChange={handleInput}
                        value={editUser?.days}
                        ref={daysInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddendays && (
                        <p>{validationMessagedays}</p>
                      )}
                    </span>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">Plan Trail Days</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        id="plan_trail_days"
                        name="plan_trail_days"
                        min="1"
                        onChange={handleInput}
                        onPaste={handlePaste}
                        className="rs-input form-control-lg"
                        placeholder=""
                        value={editUser?.plan_trail_days}
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Video Quality<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="days"
                        name="video_quality"
                        className="rs-input form-control-lg"
                        placeholder=""
                        onChange={handleInput}
                        value={editUser?.video_quality}
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="m-0">
                      Resolution<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="days"
                        name="resolution"
                        className="rs-input form-control-lg"
                        placeholder=""
                        onChange={handleInput}
                        value={editUser?.resolution}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">IOS Product ID</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="days"
                        name="ios_product_id"
                        className="rs-input form-control-lg"
                        placeholder=""
                        onChange={handleInput}
                        value={editUser?.ios_product_id}
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">IOS Plan Price ( {symbol} )</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        // id="days"
                        name="ios_plan_price"
                        className="rs-input form-control-lg"
                        placeholder=""
                        onChange={handleInput}
                        value={editUser?.ios_plan_price}
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Payment Status</h5>
                  <hr></hr>

                  <div className="text-start">
                    <label className="m-0">Payment Type</label>

                    <div className="mt-2">
                      <div className="row mt-2">
                        {/* <div className="form-check col-md-6 mt-2 ps-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="one_time"
                            name="payment_type"
                            id="payment_type1"
                            onChange={handleInput}
                            checked={editUser?.payment_type === "one_time"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="payment_type1"
                          >
                            One Time Payment
                          </label>
                        </div> */}
                        <div className="form-check col-md-6 mt-2  ps-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="payment_type"
                            id="payment_type2"
                            onChange={handleInput}
                            value="recurring"
                            checked={editUser?.payment_type === "recurring"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="payment_type2"
                          >
                            Recurring
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>Devices Settings</h5>
                  <hr></hr>

                  {/* <div className="row">
                    <div className="col-lg-10">
                      <div className="">
                        <label>Enable this Page Ads </label>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div>
                        <label className="switch">
                          <input
                            name="ads_status"
                            id="ads_status"
                            onChange={handleInput}
                            defaultChecked={
                              editUser?.ads_status === 1 ? true : false
                            }
                            checked={editUser?.ads_status === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="ads_status"
                            id="ads_status"
                            onChange={handleInput}
                            value={editUser?.ads_status === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="panel panel-primary" data-collapsed="0">
                    <div className="panel-body d-flex justify-content-between">
                      <label className="">Enable this page Ads </label>
                      <label className="switch">
                                <input
                                  name="ads_status"
                                  onChange={handleInputs}
                                  defaultChecked={
                                    ads_status?.ads_status === 1 ? true : false
                                  }
                                  checked={
                                    ads_status?.ads_status === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="ads_status"
                                  onChange={handleInputs}
                                  value={
                                    ads_status?.ads_status === 1 ? "1" : "0"
                                  }
                                ></span>
                              </label>
                    </div>
                  </div> */}

                  <div className=" text-start">
                    {/* <label className="m-0">Devices</label> */}

                    <div className="">
                      {devices?.map((item, index) => {
                        const value = item?.devices_name;
                        const deviceIds = editUser?.devices?.split(",");
                        const devices = deviceIds?.filter((val) =>
                          val.includes(item?.id)
                        );
                        const array =
                          myArray?.filter((item) => item !== null).length === 0;

                        return (
                          <div className="row" key={item?.id}>
                            <div className="col-lg-10">
                              <div className="mt-1">
                                <label>{value} </label>
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <div className="mt-2">
                                <label className="switch">
                                  <input
                                    name="devices"
                                    onChange={() => handleInputs(item?.id)}
                                    checked={devices?.length > 0}
                                    type="checkbox"
                                  />
                                  <span
                                    className="slider round"
                                    name="devices"
                                    onChange={() => handleInputs(item?.id)}
                                    value={devices?.length > 0}
                                  ></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          // <div key={item?.id}>
                          //   <label>{value} :</label>
                          //   <label className="switch">
                          //     <input
                          //       name="devices"
                          //       onChange={() => handleInputs(item?.id)}
                          //       checked={devices?.length > 0}
                          //       type="checkbox"
                          //     />
                          //     <span
                          //       className="slider round"
                          //       name="devices"
                          //       onChange={() => handleInputs(item?.id)}
                          //       value={devices?.length > 0}
                          //     ></span>
                          //   </label>
                          // </div>
                        );
                      })}
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenDevices && (
                        <p>{validationMessageDevices}</p>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div>
        <div className=" m-0 p-0">
          <div className="">
            <section className="section container-fluid pt-3">
              <div className="iq-card p-2 mt-3">
                <h4 className="p-2">
                  <i className="entypo-plus"></i>Subscription Plan Update
                </h4>
                <hr />
                <div className="p-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label>Plan Name:</label>
                        <input
                          type="text"
                          id="plans_name"
                          name="plans_name"
                          onChange={handleInput}
                          value={editUser?.plans_name}
                          className="form-control"
                          placeholder="Plan Name"
                        />
                      </div>

                      <div>
      {paymentSetting?.map((paymentSettingItem) => {
        const value = paymentSettingItem?.payment_type;

        // Find a matching plan_iddata item
        const matchingPlan = plan_iddata.find(
          (plan) => plan?.type === value
        );

        if (value && matchingPlan) {
          return (
            <div key={paymentSettingItem?.id}>
              <label>{value} Plan ID:</label>
              <input
                type="text"
                name={value}
                value={editUser[value]}
                onChange={handleInput}
                className="form-control"
                placeholder={`${value} Plan Id`}
              />
            </div>
          );
        } else {
          return null;
        }
      })}

      {paymentSetting?.map((subscriptionItem) => {
        const values = subscriptionItem?.payment_type;

        // Find a matching subscriptionplanname item
        const matchingPlan = subscriptionplanname.find(
          (plan) => plan?.type === values
        );

        if (values && matchingPlan) {
          return (
            <div key={subscriptionItem?.id}>
              <label>{values} Plan ID matchingPlan:</label>
              <input
                type="text"
                name={values}
                value={editUser[values]}
                onChange={handleInput}
                className="form-control"
                // placeholder={`${values} Plan Id`}
              />
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>

                      <div>
                        {paymentSetting?.map((paymentSettingItem) => {
                          const value = paymentSettingItem?.payment_type;

                          // Find a matching plan_iddata item
                          const matchingPlan = plan_iddata.find(
                            (plan) => plan?.type === value
                          );

                          if (value && matchingPlan) {
                            return (
                              <div key={paymentSettingItem?.id}>
                                <label>{value} Plan ID:</label>
                                <input
                                  type="text"
                                  // id={value}
                                  name={value}
                                  value={matchingPlan.plan_id}
                                  onChange={handleInput}
                                  className="form-control"
                                  placeholder={`${value} Plan Id`}
                                />
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}

                        {paymentSetting?.map((paymentSettingItem) => {
        const value = paymentSettingItem?.payment_type;

        // Find a matching subscriptionplanname item
        const matchingPlan = subscriptionplanname.find(
          (plan) => plan?.type === value
        );

        if (value && matchingPlan) {
          return (
            <div key={paymentSettingItem?.id}>
              <label>{value} Plan ID:</label>
              <input
                type="text"
                // id={value}
                name={value}
                value={matchingPlan.plan_id}
                onChange={(e) => handleInput(e, value)} 
                className="form-control"
                placeholder={`${value} Plan Id`}
              />
            </div>
          );
        } else {
          return null;
        }
      })}

                        {paymentSetting?.map((paymentSettingItem) => {
                          const value = paymentSettingItem?.payment_type;

                          // Find a matching plan_iddata item
                          const matchingPlan = plan_iddata.find(
                            (plan) => plan?.type === value
                          );

                          if (value && matchingPlan) {
                            return (
                              <div
                                className="mt-2"
                                key={paymentSettingItem?.id}
                              >
                                <label>{value} Plan ID:</label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    name={value}
                                    value={matchingPlan.plan_id}
                                    onChange={(e) => handleInput(e, value)} // Pass the value as a parameter
                                    className="form-control"
                                    placeholder={`${value} Plan Id`}
                                  />
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}

                        {paymentSetting?.map((subscriptionItem) => {
                          const value = subscriptionItem?.payment_type;

                          // Find a matching plan_iddata item
                          const matchingPlan = subscriptionplanname.find(
                            (plan) => plan?.subscription_plan_name === value
                          );

                          if (value && matchingPlan) {
                            return (
                              <div key={subscriptionItem?.id}>
                                <input
                                  type="text"
                                  id={value}
                                  name={value}
                                  value={matchingPlan.subscription_plan_name}
                                  hidden
                                  onChange={handleInput}
                                  className="form-control"
                                />
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>

                      <div className="">
                        {paymentSetting?.map((item, index) => {
                          const value = item?.payment_type;
                          const value2 = item?.payment_type;

                          if (value && value2) {
                            return (
                              <div className="">
                                <label key={item?.id}>{value} Plan ID:</label>
                                <input
                                  type="text"
                                  id="plan_id"
                                  name={value}
                                  onChange={handleInput}
                                  className="form-control"
                                  placeholder={value + " " + "Plan Id"}
                                />
                              </div>
                            );
                          } else {
                            return <div></div>;
                          }
                        })}

                        {paymentSetting.map((item, index) => {
                          const value = item?.Stripe;
                          const value2 = item?.payment_type;

                          return (
                            <div className="">
                              <label>{value} :</label>
                              <input
                            type="text"
                            id="plan_id"
                            name="plan_id"
                            value={value2}
                            className="form-control"
                            placeholder="Plan ID"
                          />
                            </div>
                          );
                        })}
                      </div>

                      <div className="">
                        {subscriptionplanname?.map((item, index) => {
                          const value = item?.type;
                          const value2 = item?.subscription_plan_name;

                          if (value && value2) {
                            return (
                              <div className="" key={item?.id}>
                                <input
                                  type="text"
                                  id="plan_id"
                                  name={value}
                                  value={value}
                                  hidden
                                  onChange={handleInput}
                                  className="form-control"
                                />
                              </div>
                            );
                          } else {
                            return <div></div>;
                          }
                        })}

                        {paymentSetting.map((item, index) => {
                          const value = item?.Stripe;
                          const value2 = item?.payment_type;

                          return (
                            <div className="">
                              <label>{value} :</label>
                              <input
                            type="text"
                            id="plan_id"
                            name="plan_id"
                            value={value2}
                            className="form-control"
                            placeholder="Plan ID"
                          />
                            </div>
                          );
                        })}
                      </div>

                      <div className="form-group ">
                          <label>Stripe Plan ID:</label>
                          <input
                            type="text"
                            id="plan_id"
                            name="plan_id"
                            className="form-control"
                            placeholder="Plan ID"
                          />
                        </div>
                      
                      <form>
                        <div className="form-group ">
                          <label>PayPal Plan ID:</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Plan ID"
                          />
                        </div>
                      </form>
                      <form>
                        <div className="form-group ">
                          <label>Paystack Plan ID:</label>
                          <input
                            type="text"
                            id="plan_id"
                            name="plan_id"
                            onChange={handleInput}
                            value={editUser?.plan_id}
                            className="form-control"
                            placeholder="Plan ID"
                          />
                        </div>
                      </form>
                      <form>
                        <div className="form-group ">
                          <label>Video Quality:</label>
                          <input
                            type="text"
                            id="video_quality"
                            name="video_quality"
                            onChange={handleInput}
                            value={editUser?.video_quality}
                            className="form-control"
                            placeholder="Quality"
                          />
                        </div>
                      </form>
                      <form>
                        <div className="form-group ">
                          <label>Price ( $ ):</label>
                          <input
                            type="text"
                            id="price"
                            value={editUser?.price}
                            onChange={handleInput}
                            name="price"
                            className="form-control"
                            placeholder="Price"
                          />
                        </div>
                      </form>
                    </div>

                    <div className="col-md-6 ">
                      <div className="row mt-2">
                        <label>Payment Type:</label>

                        <div className="form-check col-md-6 mt-2 ps-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="one_time"
                            name="payment_type"
                            id="payment_type1"
                            onChange={handleInput}
                            checked={editUser?.payment_type === "one_time"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="payment_type1"
                          >
                            One Time Payment
                          </label>
                        </div>
                        <div className="form-check col-md-6 mt-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="payment_type"
                            id="payment_type2"
                            onChange={handleInput}
                            value="recurring"
                            checked={editUser?.payment_type === "recurring"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="payment_type2"
                          >
                            Recurring
                          </label>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <label>Payment Type:</label>

                        <div className="form-check col-md-6 mt-2 ps-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={editUser?.payment_type}
                            name="payment_type"
                            id="payment_type1"
                            onChange={handleInput}
                          />
                          <label className="form-check-label" for="payment_type1">
                            One Time Payment
                          </label>
                        </div>
                        <div className="form-check col-md-6 mt-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="payment_type"
                            id="payment_type2"
                            onChange={handleInput}
                            value={editUser?.payment_type}
                          />
                          <label className="form-check-label" for="payment_type2">
                            Recurring
                          </label>
                        </div>
                      </div>
                      <form>
                        <div className="form-group ">
                          <label>Resolution :</label>
                          <input
                            type="text"
                            id="resolution"
                            name="resolution"
                            value={editUser?.resolution}
                            onChange={handleInput}
                            className="form-control"
                            placeholder="Resolution"
                          />
                        </div>
                      </form>
                      <form>
                        <div className="form-group ">
                          <label>IOS Product ID :</label>
                          <input
                            type="text"
                            id="ios_product_id"
                            value={editUser?.ios_product_id}
                            onChange={handleInput}
                            name="ios_product_id"
                            className="form-control"
                            placeholder="IOS Product ID"
                          />
                        </div>
                      </form>
                      <form>
                        <div className="form-group ">
                          <label>IOS Plan Price ( $ ):</label>
                          <input
                            type="text"
                            id="ios_plan_price"
                            value={editUser?.ios_plan_price}
                            onChange={handleInput}
                            name="ios_plan_price"
                            className="form-control"
                            placeholder="IOS Plan Price"
                          />
                        </div>
                      </form>

                      <div className="mt-3 row">
                        <label>Devices :</label>

                        {devices?.map((item, index) => {
                          const value = item?.devices_name;
                          const deviceIds = editUser?.devices?.split(",");
                          const devices = deviceIds?.filter((val) =>
                            val.includes(item?.id)
                          );
                          const array =
                            myArray?.filter((item) => item !== null).length ===
                            0;

                          return (
                            <div key={item?.id}>
                              <label>{value} :</label>
                              <label>
                              {devices.length > 0 ? "Selected" : "Not selected"}{" "}
                              :
                            </label>
                              <label className="switch">
                                <input
                                  name="devices"
                                  onChange={() => handleInputs(item?.id)}
                                  checked={devices?.length > 0}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="devices"
                                  onChange={() => handleInputs(item?.id)}
                                  value={devices?.length > 0}
                                ></span>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="colmd-12">
                    <div className="panel panel-primary" data-collapsed="0">
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title">
                          <label>Page Content</label>
                        </div>{" "}
                      </div>
                      <div className="panel-body mt-3">
                        <JoditEditor
                          // ref={editor}
                          value={editUser?.plan_content}
                          //   onChange={(newContent) => setContent(newContent)}
                          // onChange={handleInput}

                          onChange={(e) => {
                            handleChangeFirst(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mt-3">
                        <label>Enable Ads</label>
                        <div>
                          <label className="switch">
                            <input
                              name="ads_status"
                              defaultChecked={
                                editUser?.ads_status === 1 ? true : false
                              }
                              onChange={handleInput}
                              checked={
                                editUser?.ads_status === 1 ? true : false
                              }
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="ads_status"
                              onChange={handleInput}
                              value={editUser?.ads_status === 1 ? "1" : "0"}
                            ></span>
                          </label>

                         
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <form>
                        <div className="form-group ">
                          <label>Andriod Paystack Url</label>
                          <input
                            type="text"
                            id="andriod_paystack_url"
                            value={editUser?.andriod_paystack_url}
                            onChange={handleInput}
                            name="andriod_paystack_url"
                            className="form-control"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <hr />

                  <div className="row mt-3">
                    <div className="col-sm-12 text-end">
                      <input
                        type="submit"
                        value="Update"
                        onClick={handleUpdate}
                        className="btn btn-primary "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default EditSubscriptionPlan;
